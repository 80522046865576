import React from 'react';
import cn from 'classnames';

import classes from './EvaluationArrowImage.module.scss';
import { IconDeprecated } from '../../../../../../../common';

interface EvaluationArrowImageProps {
    condition: boolean;
    conditionTwo: boolean;
    gradientId: string;
}

export function EvaluationArrowImage({
    condition,
    conditionTwo,
    gradientId,
}: EvaluationArrowImageProps): JSX.Element {
    return (
        <svg
            viewBox="0 0 16 10"
            className={cn(classes.arrow, {
                [classes.arrow_isDisabled]:
                    condition,
                [classes.arrow_isOpen]: conditionTwo,
            })}
            fill={`url(#${gradientId})`}
        >
            <IconDeprecated
                id="arrow-block-up"
            />
            <linearGradient
                id={gradientId}
                x1="7.08858"
                y1="-0.0439975"
                x2="9.35558"
                y2="10.5039"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="13%" stopColor="#0B42DC" />
                <stop offset="73%" stopColor="#57B2C6" />
                <stop offset="93%" stopColor="#EED660" />
            </linearGradient>
        </svg>
    );
}
