import React, { useEffect, useState } from 'react';

import cn from 'classnames';
import classes from './Graph.module.scss';

interface Props {
    x: number;
    y: number;
    nodeName: string | undefined;
    nodeType: string | undefined;
}

interface Params {
    top: number | undefined;
    left: number | undefined;
    visible: boolean;
}

export function NodeTooltips({
    x,
    y,
    nodeName,
    nodeType,
}: Props): JSX.Element {
    const ref = React.useRef<any>(null);
    const [params, setParams] = useState<Params>({
        top: undefined,
        left: undefined,
        visible: false,
    });
    useEffect(() => {
        if (ref) {
            const width = ref.current.offsetWidth;
            const height = ref.current.offsetHeight;

            if (height > 80) {
                setParams({
                    ...params,
                    top: y - 55,
                    left: x - (width / 2),
                    visible: true,
                });
            } else if (height > 50) {
                setParams({
                    ...params,
                    top: y - 35,
                    left: x - (width / 2),
                    visible: true,
                });
            } else if (height > 40) {
                setParams({
                    ...params,
                    top: y - 15,
                    left: x - (width / 2),
                    visible: true,
                });
            } else {
                setParams({
                    ...params,
                    top: y,
                    left: x - (width / 2),
                    visible: true,
                });
            }
        }
    }, []);
    return (
        <div
            ref={ref}
            className={cn(classes.nodeTooltips, {
                [classes.nodeTooltips_display_none]: !params.visible,
                [classes.nodeTooltips_color_assignment]: nodeType === 'assignment',
                [classes.nodeTooltips_color_evaluationPoint]: nodeType === 'evaluationPoint',
            })}
            style={{ top: `${params.top}px`, left: `${params.left}px` }}
        >
            {nodeName}
        </div>
    );
}
