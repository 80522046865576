/* eslint-disable no-nested-ternary */
import React from 'react';
import { NavLink } from 'react-router-dom';

import {
    RefetchGetSpaces,
    SelectionStatusNew,
    SpaceBaseTimeInterval,
    SpaceBaseTimeIntervalParameters,
} from '@admin/EducationPeriodPage/EducationPeriodPageInterfaces';

import {
    formatDate,
    formatModuleAssessmentDates,
    getBtiInstance,
} from '@admin/EducationPeriodPage/EducationPeriodDates/EducationPeriodDatesView/EducationPeriodDatesAccordion';

import { ActionTypeOfButton } from '@common/ActionButton';
import cn from 'classnames';
import { SelectionPointButtonRedesign } from '@common/SelectionPointButtonRedesign';
import { ButtonName } from '@common/SelectionPointButtonRedesign/buttonHooks';
import { LoaderWithInfo } from '@admin/EducationPeriodPage/LoaderWithInfo';
import classes from './AccordionItems.module.scss';

interface Props {
    spaceId: string;
    baseTimeInterval: SpaceBaseTimeInterval;
    parameters?: SpaceBaseTimeIntervalParameters;
    refetchGetSpaces?: RefetchGetSpaces;
}

export function AccordionItems(
    {
        spaceId,
        baseTimeInterval,
        parameters,
        refetchGetSpaces,
    }: Props,
) {
    const btiInstance = getBtiInstance(baseTimeInterval, parameters);
    const selectionStatus = btiInstance?.selectionStatus;

    const btiSubspaceWithFork = baseTimeInterval.spaceBaseTimeIntervalSubspaces.find((subspace) => (
        subspace.forks.find((fork) => !!fork.id)
    ));

    const navLink = `${btiSubspaceWithFork?.forks[0].id ?? ''}?btiId=${baseTimeInterval.id}&&spaceId=${spaceId}&&btiIId=${btiInstance?.id}&&btiOrder=${baseTimeInterval.order}`;
    const modulePrioritiesNavLink = `${btiSubspaceWithFork?.forks[0].id ?? ''}?btiId=${baseTimeInterval.id}&&spaceId=${spaceId}&&btiIId=${btiInstance?.id}`;

    const prioritiesVisibleCondition = selectionStatus
        === SelectionStatusNew.selectionFinished
        || selectionStatus === SelectionStatusNew.moduleDistributionEnabled
        || selectionStatus === SelectionStatusNew.moduleDistributionInProcess
        || selectionStatus === SelectionStatusNew.moduleDistributionApprovalPending
        || selectionStatus === SelectionStatusNew.waveDistributionInProcess
        || selectionStatus === SelectionStatusNew.waveDistributionApprovalPending;

    const isSelectionNotInProgress = selectionStatus === SelectionStatusNew.selectionEnabled
        || selectionStatus === SelectionStatusNew.selectionPending;

    const isModuleSelection = prioritiesVisibleCondition
        || selectionStatus === SelectionStatusNew.moduleSelectionEnabled
        || selectionStatus === SelectionStatusNew.moduleSelectionInProcess;

    return (
        <div className={classes.items}>

            <div className={classes.items__container}>

                <div className={classes.items__item}>
                    Выбор подпространств
                </div>

                <div className={classes.items__item}>
                    {
                        !parameters?.subspaceSelectionStartDate
                        && !parameters?.subspaceSelectionEndDate ? (
                                'не задано'
                            ) : (
                                <>
                                    {
                                        formatDate(parameters.subspaceSelectionStartDate)
                                    }
                                    {' '}
                                    -
                                    {' '}
                                    {
                                        formatDate(parameters.subspaceSelectionEndDate)
                                    }
                                </>
                            )
                    }
                </div>

                <div className={classes.items__item}>

                    {
                        getElementForSubspaceDueToSelectionStatus(
                            btiInstance?.id ?? '',
                            isSelectionNotInProgress,
                            selectionStatus,
                            refetchGetSpaces,
                        )
                    }

                </div>

            </div>

            <div
                className={cn(classes.items__container, {
                    [classes.items__container_module]: prioritiesVisibleCondition,
                })}
            >

                <div className={classes.items__item}>
                    Выбор модулей
                </div>

                <div className={classes.items__item}>
                    {
                        !parameters?.moduleSelectionStartDate
                        && !parameters?.moduleSelectionEndDate ? (
                                'не задано'
                            ) : (
                                <>
                                    {
                                        formatDate(parameters.moduleSelectionStartDate)
                                    }
                                    {' '}
                                    -
                                    {' '}
                                    {
                                        formatDate(parameters.moduleSelectionEndDate)
                                    }
                                </>
                            )
                    }
                </div>

                <div className={classes.items__item}>

                    {
                        isModuleSelection ? (
                            <>
                                {
                                    getElementForModuleDueToSelectionStatus(
                                        btiInstance?.id ?? '',
                                        navLink,
                                        isSelectionNotInProgress,
                                        spaceId,
                                        selectionStatus,
                                        refetchGetSpaces,
                                    )
                                }
                            </>
                        ) : <div />
                    }

                </div>

            </div>

            {
                getModulePrioritiesElementIfVisibleDueToSelectionStatus(
                    modulePrioritiesNavLink,
                    prioritiesVisibleCondition,
                )
            }

            <div className={classes.items__container}>

                <div className={classes.items__item}>
                    СОП
                </div>

                <div className={classes.items__item}>
                    {
                        !parameters?.moduleAssessment?.startDate
                        && !parameters?.moduleAssessment?.endDate ? (
                                'не задано'
                            ) : (
                                <>
                                    {
                                        formatModuleAssessmentDates(
                                            parameters.moduleAssessment,
                                        )
                                    }
                                </>
                            )
                    }
                </div>

                <div className={classes.items__item} />

            </div>

        </div>
    );
}

function getElementForSubspaceDueToSelectionStatus(
    btiInstanceId: string,
    isSelectionNotInProgress: boolean,
    selectionStatus?: SelectionStatusNew,
    refetchGetSpaces?: RefetchGetSpaces,
): JSX.Element {
    if (!selectionStatus || isSelectionNotInProgress) {
        return <div />;
    }

    switch (selectionStatus) {
        case SelectionStatusNew.subspaceSelectionEnabled:
            return (
                <SelectionPointButtonRedesign
                    buttonName={
                        ButtonName.START_SUBSPACE_SELECTION
                    }
                    BTIId={btiInstanceId}
                    buttonActionType={ActionTypeOfButton.PRIMARY}
                    buttonText="Начать выборность подпространств"
                    selectionClassName={classes.selectionButton}
                    refetchGetSpaces={refetchGetSpaces}
                />
            );

        case SelectionStatusNew.subspaceSelectionInProcess:
            return (
                <LoaderWithInfo
                    text="Выборность подпространств..."
                    additionalInfoOnHover="Ожидайте. Процесс выборности подпространств может занять от n до n"
                />
            );

        case SelectionStatusNew.subspaceDistributionEnabled:
            return (
                <SelectionPointButtonRedesign
                    buttonName={
                        ButtonName.START_SUBSPACE_DISTRIBUTION
                    }
                    BTIId={btiInstanceId}
                    buttonActionType={ActionTypeOfButton.PRIMARY}
                    buttonText="Начать предварительное распределение"
                    selectionClassName={classes.selectionButton}
                    refetchGetSpaces={refetchGetSpaces}
                />
            );

        case SelectionStatusNew.subspaceDistributionInProcess:
            return (
                <LoaderWithInfo
                    text="Распределение по подпространствам..."
                    additionalInfoOnHover="Ожидайте. Процесс Распределение по подпространствам может занять от n до n"
                />
            );

        case SelectionStatusNew.subspaceDistributionApprovalPending:
            return (
                <SelectionPointButtonRedesign
                    buttonName={
                        ButtonName.APPROVE_STUDENT_SUBSPACE_DISTRIBUTION
                    }
                    BTIId={btiInstanceId}
                    buttonActionType={ActionTypeOfButton.PRIMARY}
                    buttonText="Подтвердить распределение"
                    selectionClassName={classes.selectionButton}
                    refetchGetSpaces={refetchGetSpaces}
                />
            );

        default:
            return (
                <SelectionPointButtonRedesign
                    buttonName={ButtonName.LOAD_SUBSPACE_DISTRIBUTION}
                    BTIId={btiInstanceId}
                    buttonActionType={ActionTypeOfButton.TRANSPARENT}
                    selectionClassName={cn(
                        classes.selectionButton, classes.selectionButton_noPadding,
                    )}
                >
                    <div className={classes.items__result}>
                        <span>
                            Результаты распределения
                        </span>
                        {' '}
                        <span className={classes.items__result_gray}>
                            /
                        </span>
                        {' '}
                        <span className={classes.items__result_green}>
                            скачать
                        </span>
                    </div>
                </SelectionPointButtonRedesign>
            );
    }
}

function getElementForModuleDueToSelectionStatus(
    btiInstanceId: string,
    navLink: string,
    isSelectionNotInProgress: boolean,
    spaceId: string,
    selectionStatus?: SelectionStatusNew,
    refetchGetSpaces?: RefetchGetSpaces,
): JSX.Element {
    if (!selectionStatus || isSelectionNotInProgress) {
        return <div />;
    }

    switch (selectionStatus) {
        case SelectionStatusNew.moduleSelectionEnabled:
            return (
                <SelectionPointButtonRedesign
                    buttonName={
                        ButtonName.START_MODULE_SELECTION
                    }
                    BTIId={btiInstanceId}
                    buttonActionType={ActionTypeOfButton.PRIMARY}
                    buttonText="Начать выборность по модулям"
                    refetchGetSpaces={refetchGetSpaces}
                    selectionClassName={classes.selectionButton}
                />
            );

        case SelectionStatusNew.moduleSelectionInProcess:
            return (
                <div className={classes.items__item_noVerticalPadding}>
                    <NavLink
                        className={classes.items__nav}
                        to={
                            `/education-period-module-priorities/${navLink}`
                        }
                    >
                        Идёт выборность по модулям →
                    </NavLink>
                </div>
            );

        case SelectionStatusNew.moduleDistributionEnabled:
            return (
                <SelectionPointButtonRedesign
                    buttonName={
                        ButtonName.START_MODULE_DISTRIBUTION
                    }
                    BTIId={btiInstanceId}
                    buttonActionType={ActionTypeOfButton.PRIMARY}
                    buttonText="Запустить распределение по модулям"
                    refetchGetSpaces={refetchGetSpaces}
                    selectionClassName={classes.selectionButton}
                />
            );

        case SelectionStatusNew.moduleDistributionInProcess:
            return (
                <LoaderWithInfo
                    text="Распределение по модулям..."
                    additionalInfoOnHover="Ожидайте. Процесс распределения по модулям может занять от 1 до 3 минут"
                />
            );

        case SelectionStatusNew.moduleDistributionApprovalPending:
            return (
                <NavLink
                    className={classes.items__nav}
                    to={
                        `/module-selection/${navLink}`
                    }
                >
                    Посмотреть распределение по модулям  →
                </NavLink>
            );

        case SelectionStatusNew.waveDistributionInProcess:
            return (
                <LoaderWithInfo
                    text="Распределение по потокам..."
                    additionalInfoOnHover="Ожидайте. Процесс распределения по потокам может занять от 1 до 3 часов"
                />
            );

        case SelectionStatusNew.waveDistributionApprovalPending:
            return (
                <NavLink
                    className={classes.items__nav}
                    to={
                        `/wave-selection/${navLink}`
                    }
                >
                    Посмотреть распределение по потокам  →
                </NavLink>
            );

        default:
            return (
                <SelectionPointButtonRedesign
                    buttonName={
                        ButtonName.LOAD_MODULE_DISTRIBUTION
                    }
                    BTIId={btiInstanceId}
                    buttonActionType={ActionTypeOfButton.TRANSPARENT}
                    selectionClassName={cn(
                        classes.selectionButton, classes.selectionButton_noPadding,
                    )}
                >
                    <div className={classes.items__result}>
                        <span className={classes.items__result_blue}>
                            Результаты распределения
                        </span>
                        {' '}
                        <span className={classes.items__result_gray}>
                            /
                        </span>
                        {' '}
                        <span className={classes.items__result_green}>
                            скачать
                        </span>
                    </div>
                </SelectionPointButtonRedesign>
            );
    }
}

function getModulePrioritiesElementIfVisibleDueToSelectionStatus(
    navLink: string,
    prioritiesVisibleCondition?: boolean,
): JSX.Element | null {
    if (!prioritiesVisibleCondition) {
        return null;
    }

    return (
        <div
            className={cn(
                classes.items__container,
                classes.items__container_priorities,
            )}
        >

            <div className={classes.items__item} />

            <div className={classes.items__item} />

            <div className={classes.items__item}>
                <NavLink
                    className={classes.items__nav}
                    to={
                        `/education-period-module-priorities/${navLink}`
                    }
                >
                    Приоритеты студентов
                </NavLink>
            </div>

        </div>
    );
}
