import React, { useState } from 'react';
import { gql, useQuery, useMutation } from '@apollo/client';
import { nanoid } from 'nanoid';
import { Alert } from '@common/Alert';
import { UniversitySpecialDays } from './UniversitySpecialDays';
import { ExtraDays, University } from './UniversitySpecialDaysInterfaces';

const VIEW_MODE = 1;

const GET_UNIVERSITY = gql`
query oneUniversity {
    oneUniversity {
      id,
      name,
      extraDays {
        holidays {
          id,
          date
        },
        extraWorkdays {
          id,
          date
        }
      }
    }
  }`;
const UPDATE_EXTRADAYS = gql`
mutation updateExtraDays($updateExtraDaysInput: UpdateExtraDaysInput!) {
    updateExtraDays(updateExtraDaysInput: $updateExtraDaysInput) {
        id,
        name,
        extraDays {
            holidays {
                id,
                date
            },
            extraWorkdays {
                id,
                date
            }
        }
    }
}`;

export function UniversitySpecialDaysApollo(): JSX.Element|null {
    const [mode, setMode] = useState(VIEW_MODE);
    const [
        errorAlerts,
        setErrorAlerts] = useState<JSX.Element[] | []>([]);
    const { data } = useQuery<University>(GET_UNIVERSITY, {
        fetchPolicy: 'cache-and-network',
        onError: (error) => {
            setErrorAlerts((arr) => [...arr, (<Alert
                key={nanoid()}
                message={error.message}
            />)]);
        },
    });
    const [updateExtraDaysMutation] = useMutation(UPDATE_EXTRADAYS, {
        refetchQueries: [{ query: GET_UNIVERSITY }],
        onError: (error) => {
            setErrorAlerts((arr) => [...arr, (<Alert
                key={nanoid()}
                message={error.message}
            />)]);
        },
        onCompleted: () => {
            setMode(VIEW_MODE);
        },
    });
    const update = (extraDays: ExtraDays) => updateExtraDaysMutation({
        variables: {
            updateExtraDaysInput: {
                universityId: data?.oneUniversity?.id,
                holidays: extraDays.holidays,
                extraWorkdays: extraDays.extraWorkdays,
            },
        },
    }).catch(() => { });

    if (!data?.oneUniversity?.extraDays) {
        return null;
    }

    return (
        <div>
            <UniversitySpecialDays
                extraDays={data?.oneUniversity?.extraDays}
                update={update}
                mode={mode}
                setMode={setMode}
            />
            {errorAlerts}
        </div>
    );
}
