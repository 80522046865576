import React from 'react';
import {
    TeacherRolesConflict,
} from '../../RoleConflictsDashboardInterfaces';
import classes from './TimeSlots.module.scss';

interface Props {
    teacherTimeSlots: TeacherRolesConflict,
}

const RED_COLOR = 'rgb(255, 205, 210)';
const GRAY_COLOR = '#D0D0D0';
const WHITE_COLOR = 'white';
const ORANGE_COLOR = 'rgb(255, 220, 161)';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const TimeSlots = ({ teacherTimeSlots }: Props): JSX.Element => (
    <>
        {
            teacherTimeSlots.conflictsCountByScaleUnits.map(
                (conflicts, index) => (
                    <td
                        className={classes.timeSlot}
                        style={{
                            backgroundColor: getTimeSlotColor(conflicts.count),
                        }}
                        title={`${conflicts.date} ${conflicts.startTime}-${conflicts.endTime}`}
                        // eslint-disable-next-line react/no-array-index-key
                        key={index}
                    >
                        {conflicts.count ? conflicts.count : null}
                    </td>
                ),
            )
        }
    </>
);

function getTimeSlotColor(count: number) {
    if (count > 5) {
        return RED_COLOR; // красный - в отпуске/болеет/еще что-то
    }
    if (count > 2 && count <= 5) {
        return ORANGE_COLOR;
    }
    if (count >= 1 && count <= 2) {
        return GRAY_COLOR;
    }
    return WHITE_COLOR;
}

// (
//     <div
//         className={classes.timeSlot}
//         style={{
//             backgroundColor: getTimeSlotColor(timeSlot),
//         }}
//         title={`${timeSlot.date} ${timeSlot.startTime}-${timeSlot.endTime}`}
//     >
//         {
//             WEEK_DAYS[timeSlot.dayOfWeek]
//         }
//     </div>
// )
