import React, { useEffect, useState } from 'react';
import { orderBy, reject } from 'lodash';
import cn from 'classnames';
import { ApolloError } from '@apollo/client';

import { Alert } from '@common/Alert';
import { BorderPlusIcon } from '@common/svg';
import { EdibleOnlineRoom } from './EdibleOnlineRoom';
import { OnlineRoom } from './OnlineRoom';
import { OnlineRoom as OnlineRoomInterface } from './interfaces';
import { useUpdateOnlineRoomMutation } from './useUpdateOnlineRoomMutation';

import classes from './OnlineRoom.module.scss';

interface Props {
    onlineRooms: OnlineRoomInterface[];
    refetchList(): void;
}

export function OnlineRoomList({ onlineRooms, refetchList }: Props) {
    const [errors, setErrors] = useState<JSX.Element[] | []>([]);
    const [creation, setCreation] = useState(false);
    const changeCreation = () => setCreation(prev => !prev);
    const [onlineRoomList, setOnlineRoomList] = useState(onlineRooms);
    useEffect(() => setOnlineRoomList(onlineRooms), [onlineRooms]);

    function onError(error: ApolloError) {
        const normalError = error.message.split('Cannot return null');
        setErrors([...errors, (<Alert message={normalError[0]} />)]);
    }

    const { updateOnlineRoom, loading } = useUpdateOnlineRoomMutation(refetchList, onError);

    return (
        <div className={classes.onlineRoomList}>
            <div className={cn(classes.onlineRoom, classes.title)}>
                Ссылка на онлайн помещение
            </div>
            <div className={cn(classes.onlineRoom, classes.title)}>
                Вместимость
            </div>
            <div className={cn(classes.onlineRoom, classes.title)}>
                Редактирование
            </div>
            {onlineRoomList.map(onlineRoom => (
                <OnlineRoom
                    key={onlineRoom.id}
                    onlineRoom={onlineRoom}
                    setOnlineRoom={(item?: OnlineRoomInterface) => {
                        const newItems = item ? [item] : [];
                        const items = [
                            ...onlineRoomList.filter(({ id }) => id !== onlineRoom.id),
                            ...newItems,
                        ];
                        setOnlineRoomList(getOrderedOnlineRooms(items));
                    }}
                    refetchList={refetchList}
                    onError={onError}
                />
            ))}
            {creation
                ? (
                    <EdibleOnlineRoom
                        onSubmit={(onlineRoom: OnlineRoomInterface) => {
                            updateOnlineRoom(onlineRoom)
                                .then(res => {
                                    if (res.errors) {
                                        reject(new Error());
                                    } else {
                                        changeCreation();
                                        setOnlineRoomList(
                                            prev => getOrderedOnlineRooms([...prev, onlineRoom]),
                                        );
                                    }
                                })
                                .catch();
                        }}
                        onCancel={changeCreation}
                        loading={loading}
                    />
                ) : (
                    <div className={classes.addOnlineRoom} onClick={changeCreation}>
                        <BorderPlusIcon /> Добавить онлайн-помещение
                    </div>
                )
            }
            {errors}
        </div>
    );
}

function getOrderedOnlineRooms(onlineRooms: OnlineRoomInterface[]) {
    return orderBy(onlineRooms, ['capacity', 'url'], ['asc', 'asc']);
}
