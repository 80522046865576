import React, { useState } from 'react';

import classes from './PlusIcon.module.scss';

export function PlusIcon() {
    const [isHovered, setIsHovered] = useState(false);
    return (
        <div
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            className={classes.plus}
        >
            {
                isHovered
                    ? (
                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect width="18" height="18" rx="2" fill="#0B42DC" fillOpacity="0.06" />
                            <path fillRule="evenodd" clipRule="evenodd" d="M9 3C8.44772 3 8 3.44772 8 4V8H4C3.44772 8 3 8.44771 3 9C3 9.55228 3.44771 10 4 10H8L8 14C8 14.5523 8.44772 15 9 15C9.55228 15 10 14.5523 10 14V10H14C14.5523 10 15 9.55228 15 9C15 8.44772 14.5523 8 14 8H10V4C10 3.44772 9.55228 3 9 3Z" fill="#0B42DC" />
                        </svg>

                    )
                    : (
                        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd" d="M6 0C5.44772 0 5 0.447715 5 1V5H1C0.447715 5 0 5.44771 0 6C0 6.55228 0.447715 7 1 7H5L5 11C5 11.5523 5.44772 12 6 12C6.55228 12 7 11.5523 7 11L7 7L11 7C11.5523 7 12 6.55228 12 6C12 5.44772 11.5523 5 11 5L7 5V1C7 0.447715 6.55228 0 6 0Z" />
                        </svg>
                    )
            }
        </div>
    );
}
