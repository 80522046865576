import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import { gql, useQuery } from '@apollo/client';
import { observer } from 'mobx-react-lite';
import { SubSpacePage } from './SubSpacePage';
import SkillType from '../store/SkillType';

const GET_SUBSPACE_PAGE_DATA = gql`
  query getSubSpacePageData(
    $spaceId: String!
    $subspaceId: String!
    $subspaceTypeId: String!
  ) {
    space(id: $spaceId) {
      id
      name
      minCreditCount
      maxCreditCount
      isDifferentSpaceBaseTimeIntervals
      spaceBaseTimeIntervals {
        id
        order
        minCreditCount
        maxCreditCount
      }
      baseTimeIntervalType {
        id
        name
      }
    }
    subspace(id: $subspaceId) {
      id
      name
      minStudentCount
      maxStudentCount
      description
      owner {
        id
      }
      type {
        id
        isDescriptionEnabled
      }
      prerequisiteSpecializedSkills {
        skill {
          id
          name
          fullName
          typeId
        }
        level
      }
      outputSpecializedSkills {
        skill {
          id
          name
          fullName
          typeId
        }
        level
      }
      slotDiagram {
        slots {
          id
          spaceBaseTimeIntervalId
          row
          column
          nextSlots {
            id
            spaceBaseTimeIntervalId
            row
            column
            prerequisiteSkills {
              skill {
                id
                name
                fullName
                typeId
              }
              level
            }
            outputSkills {
              skill {
                id
                name
                fullName
                typeId
              }
              level
            }
          }
          nextForks {
            id
            spaceBaseTimeIntervalId
            row
            column
          }
          nextGroupSlots {
            id
            spaceBaseTimeIntervalId
            row
            column
          }
          module {
            id
            name
            creditCount
          }
          prerequisiteSkills {
            skill {
              id
              name
              fullName
              typeId
            }
            level
          }
          outputSkills {
            skill {
              id
              name
              fullName
              typeId
            }
            level
          }
        }
        forks {
          id
          spaceBaseTimeIntervalId
          setting {
            distributionType
            moduleSelectionCount
            overbooking
            studentPickingType
            transitionalEmptyModule
            transitionalUnderfilledModule
            studentPriorityCount
            distributionModule {
              id
              name
            }
          }
          row
          column
          nextSlots {
            id
            spaceBaseTimeIntervalId
            row
            column
            module {
                id
                name
                creditCount
            }
            prerequisiteSkills {
              skill {
                id
                name
                typeId
              }
              level
            }
            outputSkills {
              skill {
                id
                name
                typeId
              }
              level
            }
          }
          nextForks {
            id
            spaceBaseTimeIntervalId
            row
            column
          }
          nextGroupSlots {
            id
            spaceBaseTimeIntervalId
            row
            column
          }
        }
        groupSlots {
          id
          spaceBaseTimeIntervalId
          row
          column
          nextSlots {
            id
            spaceBaseTimeIntervalId
            row
            column
            prerequisiteSkills {
              skill {
                id
                name
                typeId
              }
              level
            }
            outputSkills {
              skill {
                id
                name
                typeId
              }
              level
            }
          }
          nextForks {
            id
            spaceBaseTimeIntervalId
            row
            column
          }
          nextGroupSlots {
            id
            spaceBaseTimeIntervalId
            row
            column
          }
        }
      }
      type {
        id
        subspaceTypeLayouts {
          spaceBaseTimeInterval {
            id
            order
            minCreditCount
            maxCreditCount
            space {
              baseTimeIntervalType {
                name
              }
            }
          }
        }
      }
    }
    subspaceType(id: $subspaceTypeId) {
      id
      name
      subspaceTypeLayouts {
        id
        minCreditCount
        maxCreditCount
        spaceBaseTimeInterval {
          id
          minCreditCount
          maxCreditCount
        }
      }
      subspaces {
        id
        name
        description
      }
    }
  }
`;

const GET_ALL_SKILLS = gql`
  query GetAllSkills {
    skills {
      id
      name
      fullName
      typeId
      maxLevel
      children {
        id
        name
        typeId
        maxLevel
        children {
          id
          name
          typeId
          maxLevel
        }
      }
    }
  }
`;

interface Props {
    routerPath: string;
}

export const SubSpacePageApollo = observer(
    ({ routerPath }: Props): JSX.Element => {
        const { params }: any = useRouteMatch();
        const skillTypes = SkillType.getSkillTypesWithoutParentId();

        const {
            error,
            loading,
            data,
            refetch: refetchGetSubspacePageData,
        } = useQuery(GET_SUBSPACE_PAGE_DATA, {
            fetchPolicy: 'cache-and-network',
            variables: params,
        });

        const {
            error: allSkillsError,
            loading: allSkillsLoading,
            data: allSkillsData,
        } = useQuery(GET_ALL_SKILLS, {
            fetchPolicy: 'cache-and-network',
        });

        if (loading) return <></>;
        if (error) return <>`Error! ${error.message}`</>;
        if (allSkillsLoading) return <></>;
        if (allSkillsError) return <>`Error! ${allSkillsError.message}`</>;

        return (
            <SubSpacePage
                refetchGetSubspacePageData={refetchGetSubspacePageData}
                skillTypes={skillTypes}
                allSkillsList={allSkillsData.skills}
                space={data.space}
                subspace={data.subspace}
                subspaceType={data.subspaceType}
                routerPath={routerPath}
            />
        );
    },
);
