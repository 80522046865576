import { makeAutoObservable } from 'mobx';

import {
    TeacherRole, PointSkill, Duration, EventType, ModuleSkill,
} from './moduleStoreTypes';
/* eslint import/no-cycle: "off" */
import { moduleStore } from './moduleStore';
import { dictionaryStore } from './dictionaryStore';

export class EvaluationPoint {
    id!: string;

    type = EventType.EvaluationPoint;

    topic?: string;

    description?: string;

    previousEvent?: {
        id: string;
        type: string;
    };

    evaluationPointSkills?: PointSkill[];

    teacherRoles: TeacherRole[] = [];

    duration?: Duration;

    order!: number;

    constructor() {
        makeAutoObservable(this);
    }

    get evaluationPointNumber(): number {
        const { evaluationPoints } = moduleStore.moduleModel;
        return moduleStore.getNumberOfEvent(evaluationPoints, this.order);
    }

    get evaluationPointTeacherRoles(): TeacherRole[] {
        return this.teacherRoles ?? [];
    }

    set evaluationPointTeacherRoles(teacherRoles: TeacherRole[]) {
        this.teacherRoles = moduleStore.removeNonexistentRoles(teacherRoles);
    }

    updateEvaluationPointSkills(newSkills: PointSkill[], typeId: string): void {
        this.evaluationPointSkills = moduleStore.getTotalSkills(
            this.evaluationPointSkills ?? [],
            newSkills,
            dictionaryStore.getFinalSkillTypeId(typeId),
        ).map(skill => ({ ...skill, pointId: this.id }));
    }

    updateEvaluationPointSkillLevel(skillId: string, level: number): void {
        const soughtSkill = this.evaluationPointSkills?.find(({ skill }) => skill.id === skillId);
        if (soughtSkill) soughtSkill.level = level;
    }

    addEvaluationPointSkills(moduleSkills: ModuleSkill[]) {
        this.evaluationPointSkills = [
            ...this.evaluationPointSkills ?? [],
            ...moduleSkills.map((skill): PointSkill => ({ ...skill, pointId: this.id })),
        ];
    }
}
