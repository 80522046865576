import React from 'react';
import cn from 'classnames';
import { observer } from 'mobx-react';

import store, { AssessmentQuestion } from '../../module-assessment-config-store';
import classes from './ModuleAssessment.module.scss';
import { StudentAssessmentStatus } from '../../graphql-query-types';
import { Answer, AssessmentError } from './interface';

interface Props {
    assessmentQuestions: AssessmentQuestion[];
    assessmentAnswers?: Answer[] | null;
    assessmentStatus?: StudentAssessmentStatus;
    errors?: AssessmentError[] | null;
    onChange(answer: Answer): void;
}

export const ClosedTypeQuestionTable = observer(({
    assessmentQuestions,
    assessmentAnswers,
    assessmentStatus,
    errors,
    onChange,
}: Props) => {
    const answersMap = assessmentAnswers
        ? new Map(assessmentAnswers.map(({ questionId, optionId }) => [questionId, optionId]))
        : null;
    const errorsMap = errors
        ? new Map(errors.map(error => [error.questionId, error.message]))
        : null;

    function onClick(event: React.MouseEvent<HTMLDivElement>) {
        if (assessmentStatus === StudentAssessmentStatus.finished) {
            return;
        }
        const { questionId, optionId } = event.currentTarget.dataset;
        if (questionId && optionId) {
            onChange({ questionId, optionId, value: null });
        }
    }

    return (
        <table
            className={cn(
                classes['module-assessment-tab__table'],
                classes['assessment-table'],
            )}
        >
            <thead>
                <tr>
                    <td>Критерий оценки / Шкала</td>
                    {store.assessmentOptions!.map(({ id, description }) => (
                        <td key={id}>{description}</td>
                    ))}
                </tr>
            </thead>
            <tbody>
                {assessmentQuestions.map(({
                    id: questionId,
                    title: questionTitle,
                }) => (
                    <tr
                        key={questionId}
                        className={errorsMap?.get(questionId) && classes['assessment-table__row_error']}
                    >
                        <td>{questionTitle}</td>
                        {store.assessmentOptions!.map(({ id, title }) => {
                            const isSelected = answersMap?.get(questionId) === id;
                            const cellClassName = cn(
                                classes['assessment-table__cell'], {
                                    [classes['assessment-table__cell_active']]: isSelected,
                                },
                            );

                            return (
                                <td key={`${id}-${questionId}`}>
                                    <div
                                        data-question-id={questionId}
                                        data-option-id={id}
                                        className={cellClassName}
                                        onClick={onClick}
                                    >
                                        {title}
                                    </div>
                                </td>
                            );
                        })}
                    </tr>
                ))}
            </tbody>
        </table>
    );
});

export const MobileClosedTypeQuestionTable = observer(({
    assessmentQuestions,
    assessmentAnswers,
    assessmentStatus,
    onChange,
}: Props) => {
    const answersMap = assessmentAnswers
        ? new Map(assessmentAnswers.map(({ questionId, optionId }) => [questionId, optionId]))
        : null;

    function onClick(event: React.MouseEvent<HTMLDivElement>) {
        if (assessmentStatus === StudentAssessmentStatus.finished) {
            return;
        }
        const { questionId, optionId } = event.currentTarget.dataset;
        if (questionId && optionId) {
            onChange({ questionId, optionId, value: null });
        }
    }

    return (
        <div className={classes['assessment-poll']}>
            {assessmentQuestions.map(({
                id: questionId,
                title: questionTitle,
            }, index) => (
                <React.Fragment key={questionId}>
                    <div className={classes['assessment-closed-type-question__title']}>
                        Критерий {index + 1}. {questionTitle}
                    </div>
                    <div className={classes['assessment-closed-type-question__placeholder']}>
                        Выберите оценку:
                    </div>
                    <div className={classes['assessment-closed-type-question__option-list']}>
                        {store.assessmentOptions!.map(({ id, title }) => {
                            const isSelected = answersMap?.get(questionId) === id;
                            const cellClassName = cn(
                                classes['assessment-closed-type-question__option'], {
                                    [classes['assessment-closed-type-question__option_active']]: isSelected,
                                },
                            );
                            return (
                                <div
                                    key={`${id}-${questionId}`}
                                    data-question-id={questionId}
                                    data-option-id={id}
                                    className={cellClassName}
                                    onClick={onClick}
                                >
                                    {title}
                                </div>
                            );
                        })}
                    </div>
                </React.Fragment>
            ))}
        </div>
    );
});
