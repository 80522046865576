import React from 'react';

import sprite from '../../assets/sprite.svg';

interface Props {
    id: string;
    className?: string;
    click?(): void
}

export function IconDeprecated({
    click,
    id,
    className,
}: Props): JSX.Element {
    return (
        <svg className={className} onClick={click}>
            <use
                xlinkHref={`${sprite}#${id}`}
            />
        </svg>
    );
}
