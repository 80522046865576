import React from 'react';
import { useHistory } from 'react-router-dom';
import { observer } from 'mobx-react';
import cn from 'classnames';

import { FilterType } from '../../Interfaces';
import { eventsStore } from '../../Store';

import classes from './Assignments.module.scss';

interface Props {
    className?: string;
}

export const Assignments = observer((className: Props): JSX.Element | null => {
    const assignments = eventsStore.getAssignmentInstances();
    const assignmentsLength = assignments.length;
    const firstAssignment = assignments[0];
    const history = useHistory();

    function isOnlyOneAssignment(): boolean {
        return assignmentsLength === 1;
    }

    function handleSelectAssignment(): void {
        eventsStore.showAssignmentsList(true);
        history.push('/schedule/assignments');
    }

    return (
        <>
            {assignmentsLength && (
                <div
                    className={cn(classes.assignments, className.className,
                        {
                            [classes.assignments_selected]:
                                eventsStore.toggleAssignmentList
                                || eventsStore.selectedEvent?.type === FilterType.ASSIGNMENT,
                        })
                    }
                    onClick={handleSelectAssignment}
                >
                    {isOnlyOneAssignment()
                        ? `Сам. работа "${firstAssignment.assignmentInstance.assignment.topic}"`
                        : `Сам. работ: ${assignmentsLength}`}
                </div>
            )}
        </>
    );
});
