import React from 'react';
import {
    TeacherRole,
} from '../../../../RoleConflictsDashboardInterfaces';
import classes from './Role.module.scss';

interface Props {
    role: TeacherRole,
    index: number,
}

const ROLE_NAME_START_COLUMN_LINE = 1;
const ROLE_NAME_END_COLUMN_LINE = 2;
const DIFF_BETWEEN_ROLE_INDEX_AND_START_ROW_LINE = 1;
const DIFF_BETWEEN_ROLE_INDEX_AND_END_ROW_LINE = 1 + DIFF_BETWEEN_ROLE_INDEX_AND_START_ROW_LINE;

export const Role = ({ role, index }: Props): JSX.Element | null => {
    if (role) {
        return (
            <>
                <div
                    className={classes.role__name}
                    style={{
                        gridColumnStart: ROLE_NAME_START_COLUMN_LINE,
                        gridColumnEnd: ROLE_NAME_END_COLUMN_LINE,
                        gridRowStart: index + DIFF_BETWEEN_ROLE_INDEX_AND_START_ROW_LINE,
                        gridRowEnd: index + DIFF_BETWEEN_ROLE_INDEX_AND_END_ROW_LINE,
                        height: '19px',
                    }}
                >
                    <div className={classes.role__tooltip}>
                        {role.name}
                    </div>
                </div>
            </>
        );
    }
    return null;
};
