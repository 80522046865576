import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { nanoid } from 'nanoid';
import cn from 'classnames';

import { ActionButton, ActionTypeOfButton } from '@common/ActionButton';
import { IconDeprecated } from '@common/IconDeprecated';
import { Preview } from './Preview';
import { EventsList } from './EventsList';
import { ModuleOptions } from './ModuleOptions';
import { Validator, getMutationModuleState, moduleStore } from './Store';

import styles from './NewModule.module.scss';

interface Props {
    createModule(newModule: any): void; // TODO any -> CreateModuleInput
    createModuleDraft(newModule: any): void;

    disableButtons: boolean;
}

export const NewModule = observer(({ createModule, createModuleDraft, disableButtons }: Props) => {
    const module = moduleStore.moduleModel;
    const { eventsWithoutTest } = module;
    const ref = React.useRef<HTMLDivElement | null>(null);
    useEffect(() => {
        module.updateDependentEvents();
    }, [eventsWithoutTest.length]);

    const [validateResult, setValidateResult] = useState({ error: '', valid: true });

    return (
        <div className={styles.newModule} ref={ref}>
            <ModuleOptions />
            <EventsList />
            <Preview />

            {!validateResult.valid && (
                <div className={styles.newModule__errors}>
                    <div className={cn(styles.newModule__error, styles.newModule__error_title)}>
                        Ошибки:
                    </div>

                    {validateResult.error.split(';').map((error) => (
                        <div key={nanoid(8)} className={styles.newModule__error}>{error}</div>
                    ))}
                </div>
            )
            }

            <div className={styles.newModule__buttons}>
                <ActionButton
                    actionType={ActionTypeOfButton.SECONDARY}
                    className={styles.newModule__buttons_margin_right}
                    disabled={disableButtons}
                    onClick={() => {
                        if (Validator.validateModule().valid) {
                            return setValidateResult({ valid: false, error: 'Ошибки валидации не найдены' });
                        }
                        return setValidateResult(Validator.validateModule());
                    }}
                >
                    Показать ошибки <br />
                    валидации
                </ActionButton>
                <ActionButton
                    actionType={ActionTypeOfButton.SECONDARY}
                    className={styles.newModule__buttons_margin_right}
                    disabled={disableButtons}
                    onClick={() => createModuleDraft({ module: getMutationModuleState() })}
                >
                    Сохранить модуль <br />
                    в черновики
                </ActionButton>
                <ActionButton
                    disabled={disableButtons}
                    onClick={() => {
                        const result = Validator.validateModule();
                        if (result.valid) {
                            createModule(getMutationModuleState());
                        } else {
                            setValidateResult(result);
                        }
                    }}
                >
                    Опубликовать <br />
                    модуль
                </ActionButton>
            </div>
            <div
                className={styles.newModule__upButton}
                onClick={() => window.scroll({ top: 0, behavior: 'smooth' })}
            >
                <IconDeprecated
                    id="blackArrowUp"
                    className={styles.newModule__upButtonIcon}
                />
                наверх
            </div>
        </div>
    );
});
