import React, { useState } from 'react';

import { observer } from 'mobx-react';
import { Student } from '@admin/Attendance/AttendanceIterfaces';
import cn from 'classnames';
import classes from '../TableRowCell/TableRowCell.module.scss';

interface Props {
    isLastRow: boolean;
    shouldAdditionalBeTop: boolean;
    shouldAdditionalBeRight: boolean;
    // missingStartDate: string;
    student: Student;
    // meetingInstance: MeetingInstance;
}

export const MissingMeetingRowCell = observer((
    {
        isLastRow,
        shouldAdditionalBeTop,
        shouldAdditionalBeRight,
        // missingStartDate,
        student,
        // meetingInstance,
    }: Props,
): JSX.Element => {
    const [isAdditionalVisible, setAdditionalVisible] = useState(false);
    const { user } = student;
    // const currentStudentMissingMeetingTable = getCurrentStudentTableWithCurrentMeetingInstance(
    //     meetingInstance,
    //     user.studentId,
    //     attendanceStore.tables,
    // );

    // const meetingStartDateInfo = format(new Date(missingStartDate), 'dd.MM.yyyy');

    const missingMeetingInfo = `${user.lastName} ${user.firstName[0]}. ${user.patronymic ? `${user.patronymic[0]}.` : ''} не находился в этом потоке`;

    const missingMeetingAdditionalInfo = (
        <div
            className={cn(classes.cell__additional, {
                [classes.cell__additional_missingTop]: shouldAdditionalBeTop,
                [classes.cell__additional_positionRight]: shouldAdditionalBeRight,
            })}
        >
            <div className={cn(classes.cell__additional_info, classes.meetingInfo)}>
                {
                    missingMeetingInfo
                }
            </div>
        </div>
    );

    return (
        <div
            className={cn(classes.cell, classes.cell_empty, {
                [classes.borderBottom_none]: isLastRow,
            })}
            onMouseOver={() => setAdditionalVisible(true)}
            onMouseLeave={() => setAdditionalVisible(false)}
            onFocus={() => {}}
        >

            {
                isAdditionalVisible ? (
                    missingMeetingAdditionalInfo
                ) : null
            }

        </div>
    );
});

// function getCurrentStudentTableWithCurrentMeetingInstance(
//     currentMeetingInstance: MeetingInstance,
//     currentStudentId: string,
//     tables: Table[],
// ) {
//     const currentTable = tables.find((table) => (
//         table.columns.find((column) => {
//             const meetingInstanceStartDateToLocal = getDateToLocaleDateString(
//                 column.meetingInstance.startDate,
//             );
//
//             const currentMeetingInstanceStartDateToLocal = getDateToLocaleDateString(
//                 currentMeetingInstance.startDate,
//             );
//
//             const isStudentsInCurrentTable = table.wave.students.some(
//                 (student) => student.user.studentId === currentStudentId,
//             );
//
//             const isSameMeetingInstance = column
//             .meetingInstance.id === currentMeetingInstance.id;
//
//             return meetingInstanceStartDateToLocal
//             === currentMeetingInstanceStartDateToLocal
//             && isStudentsInCurrentTable && !isSameMeetingInstance;
//         })
//     ));
//
//     return currentTable;
// }
//
// function getDateToLocaleDateString(
//     dateString: string,
// ) {
//     return new Date(dateString).toLocaleDateString();
// }
