import React from 'react';
import trimStart from 'lodash/trimStart';
import { Input } from '@common/Input';
import { CreateBuildingInput, TerritorialZone } from '@admin/UniversityPage/types';
import classes from './UniversityBuildingFormGroup.module.scss';

interface Props {
    territorialZones: TerritorialZone[];
    building: CreateBuildingInput;
    handleInputChange: ({
        target,
    }:
    | React.ChangeEvent<HTMLInputElement>
    | React.ChangeEvent<HTMLSelectElement>) => void;
}

export function UniversityBuildingFormGroup({
    territorialZones,
    building,
    handleInputChange,
}: Props): JSX.Element {
    const { id, name, location } = building;
    return (
        <div className={classes.buildingFormGroup}>
            <div className={classes.buildingFormGroup__input}>
                <Input
                    id={id}
                    label="Название:"
                    placeholder="Введите название корпуса"
                    name="name"
                    value={trimStart(name)}
                    onChange={handleInputChange}
                />
            </div>
            <div className={classes.buildingFormGroup__input}>
                <Input
                    id={id}
                    label="Расположение:"
                    placeholder="Введите адрес корпуса"
                    name="location"
                    value={trimStart(location)}
                    onChange={handleInputChange}
                />
            </div>
            <label className={classes.formGroupName__label} htmlFor={id}>
                Территориальная зона:
                <select
                    id={id}
                    className={classes.formGroupZone__select}
                    name="zoneId"
                    value={building.zoneId}
                    onChange={handleInputChange}
                >
                    {territorialZones.map((zone) => (
                        <option id={zone.id} key={zone.id}>
                            {zone.name}
                        </option>
                    ))}
                </select>
            </label>
        </div>
    );
}
