import React from 'react';

import { IconDeprecated } from '@common/IconDeprecated';

import { ParamsList } from './ParamsList/ParamsList';

import classes from './SubSpaceOptions.module.scss';

import {
    Subspace,
} from '../subSpaceTypes';

import {
    skills_skills,
    skillTypesData_skillTypes,
} from '../../deprecated-graphql-query-types';

interface Props {
    editState: boolean;
    setEditState: (flag: boolean) => void;
    subspace: Subspace;
    skillTypes: skillTypesData_skillTypes[];
    sortedSkillTypes: skillTypesData_skillTypes[];
    allSkillsList: skills_skills[];
}

export function PrerequisiteAndOutputParams({
    editState,
    setEditState,
    subspace,
    skillTypes,
    sortedSkillTypes,
    allSkillsList,
}: Props): JSX.Element {
    const noCaptivity = subspace.minStudentCount === null && subspace.maxStudentCount === null;
    const subspaceCaptivityInfo = noCaptivity ? 'Не задано' : `min = ${subspace.minStudentCount === null ? 'не задано' : subspace.minStudentCount}, max = ${subspace.maxStudentCount === null ? 'не задано' : subspace.maxStudentCount}`;
    return (
        <>
            <ul className={classes.subSpaceOptions__commonParamsList}>
                <li
                    className={classes.subSpaceOptions__captivity}
                >
                    <span className={classes.subSpaceOptions__captivity_bold}>
                        Вместимость подпространства:
                    </span>
                    <span className={classes.subSpaceOptions__captivity_info}>
                        {subspaceCaptivityInfo}
                    </span>
                </li>
                <li className={classes.subSpaceOptions__commonParamsItem}>
                    <ParamsList
                        paramsType="profile"
                        skillsParamsType="prerequisite"
                        given={subspace.prerequisiteSpecializedSkills!.length}
                        allSkillsList={allSkillsList}
                        paramsList={subspace.prerequisiteSpecializedSkills}
                        sortedSkillTypes={sortedSkillTypes}
                        notSortedSkillTypes={skillTypes}
                    />
                </li>
                <li className={classes.subSpaceOptions__commonParamsItem}>
                    <ParamsList
                        paramsType="profile"
                        skillsParamsType="output"
                        given={subspace.outputSpecializedSkills!.length}
                        allSkillsList={allSkillsList}
                        paramsList={subspace.outputSpecializedSkills}
                        sortedSkillTypes={sortedSkillTypes}
                        notSortedSkillTypes={skillTypes}
                    />
                </li>
            </ul>
            <div className={classes.subSpaceOptions__penIcon}>
                <IconDeprecated
                    id="editPenBlack"
                    className={classes['edit-pen-subspace-options']}
                    click={() => setEditState(!editState)}
                />
            </div>
        </>
    );
}
