import React from 'react';

import { useQuery } from '@apollo/client';
import { GET_SKILLS_TYPE } from '@common/qraphql/queries';
import { SkillTypeShow } from './SkillType';
import * as Types from '../profileModels';

import classes from './StudentProfile.module.scss';

export interface SkillType {
    parentId: string;
    id: string;
    name: {
        nominativePlural: string;
    }
    children?: SkillType[];
}

export interface SkillTypeWhithChildren extends SkillType {
    children: SkillType[]
}

interface Props {
    studentData: Types.Student;
}

export function StudentProfile({ studentData }: Props) {
    const { data } = useQuery<{ skillTypes: SkillType[] }>(GET_SKILLS_TYPE);
    const { targetSkills, maxSkills } = studentData;

    const skillTypesWhithNoParent = data?.skillTypes.filter(type => type.parentId === null);
    const allSkillTypesWhithChildren = skillTypesWhithNoParent
        ?.map(item => getTypeWhithChildren(item));

    const notEvaluatedModuleSkills = targetSkills
        .filter(item => getStudentSkill(item.skill.id)?.skill.id !== item.skill.id);
    const allStudentSkillsWhithParents: Types.Skill[] = [];
    const allModulesSkillWhithParent: Types.Skill[] = [];

    maxSkills.forEach(({ skill }) => getAllStudentSkillParents(skill));
    targetSkills.forEach(({ skill }) => getAllModulesSkillsWhithParents(skill));

    function getAllStudentSkillParents(skill: Types.Skill): void {
        if (skill.parent) {
            allStudentSkillsWhithParents.push(skill);
            getAllStudentSkillParents(skill.parent);
        }
        allStudentSkillsWhithParents.push(skill);
    }

    function getAllModulesSkillsWhithParents(skill: Types.Skill): void {
        if (skill.parent) {
            allModulesSkillWhithParent.push(skill);
            getAllModulesSkillsWhithParents(skill.parent);
        }
        allModulesSkillWhithParent.push(skill);
    }

    function getStudentSkill(id: string) {
        return maxSkills.find(item => item.skill.id === id);
    }

    function getTypeWhithChildren(type: SkillType): SkillTypeWhithChildren {
        const newType: SkillTypeWhithChildren = { ...type, children: [] };
        newType.children = getSkillTypeChildren(type.id);
        if (getSkillTypeChildren(type.id).length > 0) {
            newType.children = newType.children.map(child => getTypeWhithChildren(child));
            return newType;
        }
        return newType;
    }
    function getSkillTypeChildren(id: string) {
        return data?.skillTypes?.filter((item) => item.parentId === id) ?? [];
    }

    return (
        <>
            {data && (
                <div className={classes.student__skills_container}>
                    {allSkillTypesWhithChildren?.map(item => (
                        <SkillTypeShow
                            key={item.id}
                            skillType={item}
                            maxSkills={maxSkills}
                            targetSkills={targetSkills}
                            notEvaluatedModuleSkills={notEvaluatedModuleSkills}
                            allStudentSkillsWhithParents={allStudentSkillsWhithParents}
                            allModulesSkillWhithParent={allModulesSkillWhithParent}
                        />
                    ))}

                </div>
            )}
        </>
    );
}
