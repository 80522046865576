import React from 'react';
import { format } from 'date-fns';

import { Button } from '@admin/Users/ToolBar/Tools/CustomButton';
import { Calendar } from './Calendar';

import classes from './Filters.module.scss';

interface Props {
    startDate: null | Date;
    endDate: null | Date;
    onChange(date: Date | [Date, Date] | null): void;
}

export function CalendarComponent({
    startDate,
    endDate,
    onChange,
}: Props): JSX.Element {
    return (
        <div className={classes.filters__item}>
            <div className={classes.filters__itemName}>
                Дата и время:
            </div>
            <Calendar
                onChange={onChange}
                startDate={startDate}
                endDate={endDate}
                customInput={(
                    <Button
                        modifier={!startDate ? 'filterCalendar' : 'filterCalendarBlack'}
                    >
                        {startDate === null && endDate === null
                            ? 'Выбрать дату'
                            : (
                                `${startDate ? `${format(startDate, 'dd.MM.yyyy')} 00:00` : ''}${' '}
                                ${endDate ? `${format(endDate, '- dd.MM.yyyy')} 23:59` : ''}`
                            )
                        }
                    </Button>
                )}
                shouldCloseOnSelect
            />
        </div>
    );
}
