/* eslint-disable import/no-cycle */
import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';

import { TabsWithoutRoute } from '@common/TabsWithoutRoute';
import { GetSkillTypesApollo } from '../EvaluationStudents/EvaluationStudent/GetSkillTypesApollo/getSkillTypesApollo';
import { GradeRange } from './Grades/GradeRange';
import { Comment } from './Comment';
import { GradeCheckbox } from './Grades/GradeCheckbox';
import { EvaluationMutation } from '../EvaluationStudents/EvaluationMutation/EvaluationMutation';
import { FilterType } from '../../../../../../Interfaces';
import { eventsStore } from '../../../../../../Store';
import { evaluationStore, StudentsTab } from '../../Store';

import classes from './GradingMenu.module.scss';

export interface Grade {
    skillId: string;
    skillName: string;
    isEvaluated: boolean;
    level: number | null;
    maxLevel: number;
    isCredited: boolean;
    creditedLevel: number | null;
}

interface Props {
    id?: string;
    note?: string;
}

export const GradingMenu = observer(({ id, note }: Props): JSX.Element => {
    const editingMode = !evaluationStore.isTabSelected(StudentsTab.EVALUATED);
    const {
        isTabSkillsLevelIncreased,
        getSkillsDueToTab,
        setSkillTree,
        getResultsEvaluationGrades,
    } = evaluationStore;

    const [isEditing, setIsEditing] = useState<boolean>(editingMode);
    const [grades, setGrades] = useState<Grade[]>([]);
    const [commentForStudent, setCommentForStudent] = useState<string>(note || '');
    const [skillTabId, setSkillTabId] = useState<string>('');

    function changeGrade(skillId: string, level: number | null, isCredited: boolean): void {
        setGrades((prevGrades) => prevGrades.map(
            (grade) => (grade.skillId === skillId
                ? { ...grade, level, isCredited }
                : grade),
        ));
    }

    function toggleGradeEvaluating(skillId: string): void {
        setGrades((prevGrades) => prevGrades.map(
            (grade) => (grade.skillId === skillId
                ? { ...grade, isEvaluated: !grade.isEvaluated }
                : grade),
        ));
    }

    function enableEditing(): void { setIsEditing(true); }
    function disableEditing(): void { setIsEditing(false); }

    function getGradesByTab() {
        const skillsDueToTab = getSkillsDueToTab(skillTabId);
        return grades
            .filter((grade) => skillsDueToTab
                .some((skill) => skill.skill.id === grade.skillId));
    }

    useEffect(() => {
        setSkillTree();
        evaluationStore.setSkillTabs();
    }, [evaluationStore.skillTypes]);

    useEffect(() => {
        if (evaluationStore.skillTypes.length) {
            setSkillTabId(evaluationStore.skillTypes[0].id);
            setGrades(getResultsEvaluationGrades(id as string));
        }
    }, [evaluationStore.skillTypes]);

    return (
        <div className={classes.gradingMenu}>
            <GetSkillTypesApollo />
            <TabsWithoutRoute setTabId={setSkillTabId} skillTabId={skillTabId} />
            {
                getGradesByTab()
                    .map(({
                        isCredited,
                        isEvaluated,
                        level,
                        creditedLevel,
                        maxLevel,
                        skillId,
                        skillName,
                    }) => (isTabSkillsLevelIncreased(skillTabId)
                        ? (
                            <GradeRange
                                status={
                                    eventsStore.selectedEvent?.type === FilterType.EVALUATION_POINT
                                    && eventsStore.selectedEvent.isGraded
                                }
                                isEditing={isEditing}
                                skillId={skillId}
                                skillName={skillName}
                                maxLevel={maxLevel}
                                isEvaluated={isEvaluated}
                                level={level || 0}
                                creditedLevel={creditedLevel || 0}
                                changeGrade={changeGrade}
                                toggleGradeEvaluating={toggleGradeEvaluating}
                                key={skillId}
                            />
                        )
                        : (
                            <GradeCheckbox
                                status={
                                    eventsStore.selectedEvent?.type === FilterType.EVALUATION_POINT
                                    && eventsStore.selectedEvent.isGraded
                                }
                                isEditing={isEditing}
                                isCredited={isCredited}
                                skillId={skillId}
                                skillName={skillName}
                                isEvaluated={isEvaluated}
                                changeGrade={changeGrade}
                                toggleGradeEvaluating={toggleGradeEvaluating}
                                key={skillId}
                            />
                        )
                    ))
            }

            {(isEditing || commentForStudent) && (
                <div className={classes.comments}>
                    <Comment
                        status={
                            eventsStore.selectedEvent?.type === FilterType.EVALUATION_POINT
                        && eventsStore.selectedEvent.isGraded
                        }
                        isEditing={isEditing}
                        label={`Комментарий для студент${id ? 'а' : 'ов'}:`}
                        comment={commentForStudent}
                        setComment={setCommentForStudent}
                    />
                </div>
            )}

            <EvaluationMutation
                id={id || ''}
                commentForStudent={commentForStudent}
                grades={grades}
                isEditing={isEditing}
                enableEditing={enableEditing}
                disableEditing={disableEditing}
            />
        </div>
    );
});
