import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';

import {
    GetEquipmentsEquipmentPage_equipment_list_rooms,
    GetEquipmentsEquipmentPage_equipment_list_rooms_room,
} from 'src/deprecated-graphql-query-types';

import classes from '../RegularEquipment.module.scss';

interface Props {
    rooms: GetEquipmentsEquipmentPage_equipment_list_rooms[]
}

interface LinkProps {
    room: GetEquipmentsEquipmentPage_equipment_list_rooms_room | null,
    countEquipments: number;
    isLastElement: boolean;
}

const viewRoomsIndex = 4;

export const Rooms = ({ rooms }: Props) => {
    const viewRooms = rooms.slice(0, viewRoomsIndex);
    const hiddenRooms = rooms.slice(viewRoomsIndex);
    const viewRoomLinksJSX = (<RoomLinks rooms={viewRooms} />);
    const hiddenRoomsLinksJSX = (<RoomLinks rooms={hiddenRooms} />);
    return (
        <div
            className={classes.regularEquipment__equipmentRoomsContainer}
        >
            {viewRoomLinksJSX}
            <HiddenRoomsLinks
                hiddenRoomsLength={hiddenRooms.length}
            >
                {hiddenRoomsLinksJSX}
            </HiddenRoomsLinks>
        </div>
    );
};

function HiddenRoomsLinks(
    {
        hiddenRoomsLength,
        children,
    }: {
        hiddenRoomsLength: number,
        children: ReactElement,
    },
) {
    const hiddenRoomsRef = useRef<HTMLInputElement>(null);
    const [showHiddenRooms, setShowHiddenRooms] = useState<boolean>(false);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (!hiddenRoomsRef?.current?.contains(event.target as Node)) {
                setShowHiddenRooms(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [hiddenRoomsRef]);

    const isShowMoreButton = hiddenRoomsLength !== 0;
    return (
        <>
            {isShowMoreButton && (
                <span
                    className={classes.equipmentRoom__moreButton}
                    onClick={() => setShowHiddenRooms(pre => !pre)}
                >
                    еще {hiddenRoomsLength}
                </span>
            )}
            {showHiddenRooms && (
                <div
                    className={classes.equipmentRoom__hiddenRooms}
                    ref={hiddenRoomsRef}
                >
                    {children}
                </div>
            )}
        </>
    );
}

function RoomLinks({ rooms }: Props) {
    const hasRooms = rooms.length > 0;
    const isLastElement = (index: number) => index === rooms.length - 1;
    return (
        <>
            {rooms.map(({ room, countEquipments }, index) => (
                <RoomLink
                    key={room?.id}
                    room={room}
                    countEquipments={countEquipments}
                    isLastElement={isLastElement(index)}
                />
            ))}
            {!hasRooms && '—'}
        </>
    );
}

function RoomLink({ room, countEquipments, isLastElement }: LinkProps) {
    return (
        <Link
            to={`/university/room/${room?.id}/zone`}
            className={classes.equipmentRoom__link}
            key={room?.id}
            target="_blank"

        >

            {`${room?.title}`}
            <span
                className={classes.equipmentRoom__count}
            >
                {` [${countEquipments}]${isLastElement ? '' : ','}  `}
            </span>
        </Link>
    );
}
