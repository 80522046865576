import React, { useState } from 'react';
import { Suggestion } from '@common/SuggestionDeprecated';
import { map } from 'lodash';
import { AddRemoveButton } from '@common/AddRemoveButton';
import { SpriteSvg } from '@common/SpriteSvgDeprecated';
import { Alert } from '@common/Alert';
import { skillTypesData_skillTypes } from '../../deprecated-graphql-query-types';
import { SubspaceSkill } from '../subSpaceTypes';
import { transformSpecializedSkillsToSuggestionSkills } from '../SlotParameters/utilities';
import classes from './SlotParametersFormField.module.scss';

interface Props {
    skillInput: SpecializedSkillInputValue
    englishSkillName: string
    specializedSkillsType: 'prerequisite' | 'output'
    selectedSkills: SpecializedSkillsInput
    suggestionSkillsList: SubspaceSkill[]
    specializedName: string
    skillType: skillTypesData_skillTypes
    index: number
    skillsForSelect: SubspaceSkill[]

    handleSetSkillInput(
        inputValue: string,
        specializedSkillsType: 'prerequisite' | 'output',
        englishSkillName: string,
    ): void

    handleRemoveSelectedSkill(
        skillName: string,
        specializedSkillsType: 'prerequisite' | 'output',
        englishSkillName: string): void

    handleSetSelectedSkills(
        inputValue: string,
        specializedSkillsType: 'prerequisite' | 'output',
        englishSkillName: string): void
}

interface SpecializedSkillsInput {
    [name: string]: string[]
}

interface SpecializedSkillInputValue {
    [name: string]: string
}

export function SlotParametersFormField({
    skillInput,
    handleSetSkillInput,
    englishSkillName,
    handleRemoveSelectedSkill,
    handleSetSelectedSkills,
    specializedSkillsType,
    selectedSkills,
    suggestionSkillsList,
    specializedName,
    skillType,
    index,
    skillsForSelect,
}: Props): JSX.Element {
    const [isFieldEmpty, setIsFieldEmpty] = useState<boolean>(false);
    const [isSkillNotExist, setIsSkillNotExist] = useState<boolean>(false);
    const isInputEqualWithSkill = (
        inputValue: string,
        skillList: SubspaceSkill[],
    ): boolean => {
        let isExist: boolean = false;
        if (inputValue) {
            skillList.forEach(skillItem => {
                const { skill, level } = skillItem;
                const { name, fullName } = skill;
                if (inputValue === name || inputValue === `${name} ${level}` || inputValue === `${fullName} ${level}`) {
                    isExist = true;
                }
            });
        }

        return isExist;
    };

    return (
        <>
            <div className={classes.field}>
                <label
                    className={classes.field__label}
                >Укажите {specializedName} {skillType.name.genitiveSingular.toLowerCase()}:
                </label>
                <div className={classes.field__suggestionRow}>
                    <Suggestion
                        cnInput={classes.field__input}
                        cnListContainer={classes.suggestionList}
                        cnSuggestionElem={classes.suggestionList__item}
                        array={transformSpecializedSkillsToSuggestionSkills(suggestionSkillsList)}
                        addedElementArray={selectedSkills[englishSkillName]}
                        stateValue={skillInput[englishSkillName]}
                        setState={(skillName: string) => handleSetSkillInput(skillName,
                            specializedSkillsType,
                            englishSkillName)}
                        placeholder={`Введите ${skillType.name.nominativeSingular.toLowerCase()}`}
                        forMany={index + 1000}
                    />
                    <AddRemoveButton
                        disabled={
                            !isInputEqualWithSkill(
                                skillInput[englishSkillName],
                                suggestionSkillsList,
                            )
                        }
                        type="add"
                        className={classes.field__addButton}
                        onClick={() => {
                            handleSetSelectedSkills(
                                skillInput[englishSkillName],
                                specializedSkillsType,
                                englishSkillName,
                            );

                            if (!isInputEqualWithSkill(
                                skillInput[englishSkillName],
                                suggestionSkillsList,
                            )) {
                                setIsSkillNotExist(true);
                            }

                            if (!skillInput[englishSkillName]) {
                                setIsFieldEmpty(true);
                            }
                        }}
                    />
                </div>

                <ul className={classes.selectedList}>
                    {map(skillsForSelect, skill => (
                        <li className={classes.selectedList__item} key={skill.skill.id}>
                            <span className={classes.selectedList__name}>
                                {skill.skill.name === skill.skill.fullName
                                    ? skill.skill.name
                                    : skill.skill.fullName
                                }
                            </span>
                            <span className={classes.selectedList__level}>
                                {skill.skill.name === skill.skill.fullName
                                    ? skill.level
                                    : ''
                                }
                            </span>
                            <SpriteSvg
                                className={classes.selectedList__icon}
                                name="circleCross_grey_20"
                                onClick={() => handleRemoveSelectedSkill(
                                    `${skill.skill.fullName} ${skill.level}`,
                                    specializedSkillsType,
                                    englishSkillName,
                                )}
                            />
                        </li>
                    ))
                    }
                </ul>
            </div>
            {isFieldEmpty && <Alert message="Нельзя добавить пустое поле" />}
            {isSkillNotExist
            && <Alert message={`"${skillInput[englishSkillName]}" не существует`} />}
        </>

    );
}
