import { useCallback, useEffect, useRef, useState } from 'react';
import { gql, useLazyQuery } from '@apollo/client';
import { cloneDeep, debounce } from 'lodash';

import { SearchParams } from './util';

import { ListUser, ListUserVariables, ListUser_users_list, Type } from './apollo-types';

export const GET_USER_LIST = gql`
    query ListUser($findParams: FindParameters, $paginationParams: PaginationParameters, $includeStudent: Boolean!) {
        users(findParams: $findParams, paginationParams: $paginationParams) {
            list {
                id
                firstName
                patronymic
                lastName
                teacherId
                email
                birthday
                roles {
                    roleId
                    name
                    enabled
                    text
                }
                student @include(if: $includeStudent) {
                    id
                    space {
                        id
                        name
                    }
                    activeBaseTimeIntervalInstance {
                        baseTimeIntervalInstance {
                            id
                            spaceBaseTimeInterval {
                                order
                                space {
                                    id
                                    name
                                    baseTimeIntervalType{
                                        name
                                    }
                                }
                            }
                        }
                    }
                    skills {
                        level
                        skill{
                            fullName
                        }
                    }
                }
            }
            totalCount
        }
    }
`;

const LIMIT = 20;
const DEBOUNCE_INTERVAL = 500;

export const useUserList = (initialUserType: Type) => {
    const [searchParams, setSearchParams] = useState<SearchParams>({ userType: initialUserType });
    const prevSearchParams = usePrevious<SearchParams>(searchParams);
    const [offset, setOffset] = useState<number>(0);

    const [users, setUsers] = useState<ListUser_users_list[]>([]);
    const [selectedUsers, setSelectedUsers] = useState<ListUser_users_list[]>([]);

    const [
        listUsers,
        { data, loading, error },
    ] = useLazyQuery<ListUser, ListUserVariables>(
        GET_USER_LIST,
        { fetchPolicy: 'no-cache' },
    );

    const debouncedListUsers = useCallback(
        debounce(listUsers, DEBOUNCE_INTERVAL),
        [],
    );

    const { fullName, status, userType, ...studentFindParameters } = searchParams;
    const variables = {
        findParams: { fullName, status, studentFindParameters, userType },
        paginationParams: { limit: LIMIT, offset },
        includeStudent: userType === Type.student,
    };

    useEffect(() => {
        listUsers({ variables });
    }, []);

    useEffect(() => {
        if (prevSearchParams?.fullName !== searchParams.fullName
            || prevSearchParams?.skillName !== searchParams.skillName) {
            debouncedListUsers({ variables });
        } else {
            listUsers({ variables });
        }
    }, [searchParams]);

    useEffect(() => {
        if (data?.users.list.length) {
            setUsers([...users, ...data.users.list]);
            setOffset(offset + LIMIT);
        }
    }, [data]);

    async function onMoreButtonClick() {
        listUsers({ variables });
    }

    function onChangeSearchParams(
        state: SearchParams | ((prevState: SearchParams) => SearchParams),
    ) {
        setOffset(0);
        setUsers([]);
        setSelectedUsers([]);
        setSearchParams(state);
    }

    function refetchUserList() {
        setUsers([]);
        setSelectedUsers([]);
        listUsers({
            variables: {
                ...variables,
                paginationParams: { limit: offset, offset: 0 },
            },
        });
    }

    function isUserSelected(user: ListUser_users_list) {
        return !!selectedUsers.find(({ id }) => id === user.id);
    }

    function onSelect(user: ListUser_users_list) {
        const selectedUsersCopy = cloneDeep(selectedUsers);
        const userIndex = selectedUsersCopy.findIndex(({ id }) => id === user.id);
        if (userIndex !== -1) {
            selectedUsersCopy.splice(userIndex, 1);
        } else {
            selectedUsersCopy.push(user);
        }
        setSelectedUsers(selectedUsersCopy);
    }

    function onSelectAll() {
        const usersCopy = cloneDeep(users);
        setSelectedUsers(usersCopy);
    }

    function clearPreviousSelected() {
        setSelectedUsers([]);
    }

    const isAllSelected = users.length === selectedUsers.length && users.length > 0;

    return {
        loading,
        error,
        users,
        isMoreButtonVisible: (data?.users.totalCount ?? 0) > users.length,
        selectedUsers,
        isAllSelected,
        onChangeSearchParams,
        onMoreButtonClick,
        onSelect,
        onSelectAll,
        clearPreviousSelected,
        isUserSelected,
        refetchUserList,
    };
};

function usePrevious<T>(value: T) {
    const ref = useRef<T>();
    useEffect(() => {
        ref.current = value;
    }, [value]);
    return ref.current;
}
