import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';

import { WeekScheduleHeader } from './WeekScheduleHeader';
import { TimeRuler } from '../../TimeRuler';
import { WeekScheduleGrid } from './WeekScheduleGrid';
import { Assignments } from '../../Assignments';
import { accountStore } from '../../../../Store';

import classes from './WeekSchedule.module.scss';
import { eventsStore } from '../../../Store';

export const WeekSchedule = observer(() => {
    const [isDidMountTimeRule, setIsDidMountTimeRule] = useState(false);
    useEffect(() => {
        document.getElementById('startTime')?.scrollIntoView();
    }, [isDidMountTimeRule]);
    return (
        <div className={classes.weekSchedule}>
            <WeekScheduleHeader />

            <div className={classes.weekSchedule__block}>
                <div className={classes.weekSchedule__gridWrapper}>
                    <TimeRuler
                        startTime={7}
                        setIsDidMount={setIsDidMountTimeRule}
                    />
                    <WeekScheduleGrid />
                </div>

                {(accountStore.isStudent() && !!eventsStore.getAssignmentInstances().length) && (
                    <Assignments className={classes.weekSchedule__assignments} />
                )}
            </div>
        </div>
    );
});
