import React, { useEffect } from 'react';

import { useHistory, useLocation } from 'react-router-dom';
import { gql, useLazyQuery, useQuery } from '@apollo/client';

import { Loader } from '@common/Loader';

import { AuthPage } from './AuthPage';

const GET_LOGIN_TYPE = gql`
    query loginTypes {
        loginTypes
    }
`;

const GET_OAUTH_LINK = gql`
    query oAuthLink {
        oAuthLink
    }
`;

const BASIC_LOGIN = gql`
    query basicLogin($login: String!, $password: String!) {
        basicLogin(basicLoginInput: {
            login: $login
            password: $password
        })
    }
`;

const OAUTH_LOGIN = gql`
    query oAuthLogin($code: String!) {
        oAuthLogin(code: $code)
    }
`;

const getParams = () => new URLSearchParams(useLocation().search);

export function AuthPageApollo(): JSX.Element {
    const history = useHistory();
    const params = getParams();
    const PARAMS_CODE = params.get('code');
    const PARAMS_REDIRECT = decodeURI(params.get('redirect') || '');
    const LS_TOKEN = localStorage.getItem('token');

    useEffect(() => {
        if (PARAMS_CODE) {
            oAuthLogin({ variables: { code: PARAMS_CODE } });
        }
    }, []);

    // TODO: очень плохо что у нас есть логика на редирект тут, и внутри AuthPage.
    // Явно надо переписать
    useEffect(() => {
        if (LS_TOKEN !== null && LS_TOKEN !== '') {
            if (PARAMS_REDIRECT) {
                // TODO: Почепму то history.push не работает корректно :(
                window.location.href = PARAMS_REDIRECT;
                // history.push(`/${PARAMS_REDIRECT}`);
            } else {
                history.push('/');
            }
        }
    }, []);

    const {
        error: loginTypesError,
        loading: loginTypesLoading,
        data: loginTypesData,
    } = useQuery(GET_LOGIN_TYPE, {
        fetchPolicy: 'cache-and-network',
    });
    const {
        error: oAuthLinkError,
        loading: oAuthLinkLoading,
        data: oAuthLinkData,
    } = useQuery(GET_OAUTH_LINK, {
        fetchPolicy: 'cache-and-network',
    });

    const [basicLogin, {
        error: basicLoginError,
        loading: basicLoginLoading,
        data: basicLoginData,
    }] = useLazyQuery(BASIC_LOGIN, {
        fetchPolicy: 'cache-and-network',
    });

    const [oAuthLogin, {
        error: oAuthLoginError,
        loading: oAuthLoginLoading,
        data: oAuthLoginData,
    }] = useLazyQuery(OAUTH_LOGIN, {
        fetchPolicy: 'cache-and-network',
    });

    if (loginTypesLoading) return <><Loader /></>;
    if (loginTypesError) return <>`Error! ${loginTypesError.message}`</>;
    if (oAuthLinkLoading) return <><Loader /></>;
    if (oAuthLinkError) return <>`Error! ${oAuthLinkError.message}`</>;
    if (basicLoginLoading) return <></>;
    if (basicLoginError) return <>`Error! ${basicLoginError.message}`</>;
    if (oAuthLoginLoading) return <><Loader /></>;
    if (oAuthLoginError) return <>`Error! ${oAuthLoginError.message}`</>;
    return (
        <AuthPage
            loginTypes={loginTypesData.loginTypes}
            oAuthLink={oAuthLinkData.oAuthLink}
            basicLogin={basicLogin}
            basicLoginData={basicLoginData}
            oAuthLoginData={oAuthLoginData}
            redirect={PARAMS_REDIRECT}
        />
    );
}
