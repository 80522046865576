import React, { useEffect, useRef, useState } from 'react';
import { gql, useQuery } from '@apollo/client';

import { useUrlQuery } from '@common/hooks';
import { GetSkikillTypes, GetSkikillTypes_skillTypes } from '../graphql-types';
import { SkillTypeOption } from '../ModuleStatisticsTypes';
import { SkillTypeSelect } from './SkillTypeSelect';

const GET_SKILL_TYPES = gql`
    query GetSkikillTypes {
        skillTypes {
            id
            parentId
            name {
                nominativeSingular
            }
        }
    }
`;

export function SkillTypeSelectApollo() {
    const { data } = useQuery<GetSkikillTypes>(GET_SKILL_TYPES, { fetchPolicy: 'network-only' });
    const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
    const [selectedSkillType, setSelectedSkillType] = useState<SkillTypeOption>();
    const { setUrlQuery, getUrlQuery } = useUrlQuery();
    const wrapperRef: React.MutableRefObject<null | HTMLDivElement> = useRef(null);

    const skillTypeOptions = data
        ? formatSkillTypeOptions(data.skillTypes)
        : [];

    useEffect(() => {
        if (data?.skillTypes) {
            const rawSkillTypeId = getUrlQuery('skillTypeId');
            const skillTypeId: string | null = rawSkillTypeId
              && decodeURIComponent(rawSkillTypeId);
            if (skillTypeId) {
                setSelectedSkillType(skillTypeOptions.find(({ id }) => id === skillTypeId));
            } else {
                setSelectedSkillType(skillTypeOptions[0]);
            }
        }
    }, [data]);

    useEffect(() => {
        function handleClickOutside(event: Event) {
            if (wrapperRef.current && !wrapperRef.current.contains(event.target as Node)) {
                setIsMenuOpen(false);
            }
        }
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [wrapperRef]);

    function onSkillTypeChange(option: SkillTypeOption) {
        setUrlQuery({ skillTypeId: option.id === '-1' ? undefined : option.id });
        setSelectedSkillType(option);
    }

    if (data) {
        const skillOptions = selectedSkillType
            ? skillTypeOptions.filter(({ id }) => id !== selectedSkillType.id)
            : skillTypeOptions;
        return (
            <SkillTypeSelect
                wrapperRef={wrapperRef}
                isMenuOpen={isMenuOpen}
                setIsMenuOpen={setIsMenuOpen}
                onSkillTypeChange={onSkillTypeChange}
                selectedSkillType={selectedSkillType}
                skillTypeOptions={skillOptions}
            />
        );
    }
    return null;
}

function formatSkillTypeOptions(skillTypes: GetSkikillTypes_skillTypes[]) {
    const options = skillTypes.filter(({ parentId }) => parentId === null)
        .map(skillType => ({
            name: skillType.name.nominativeSingular,
            id: getTerminalSkillTypeChild(skillType, skillTypes).id,
        }));
    options.splice(0, 0, { id: '-1', name: 'Не выбран' });
    return options;
}

function getTerminalSkillTypeChild(
    skillType: GetSkikillTypes_skillTypes,
    skillTypeList: GetSkikillTypes_skillTypes[],
): GetSkikillTypes_skillTypes {
    const childSkillType = skillTypeList.find(({ parentId }) => parentId === skillType.id);

    if (!childSkillType) {
        return skillType;
    }

    return getTerminalSkillTypeChild(childSkillType, skillTypeList);
}
