import React from 'react';
import cn from 'classnames';
import styles from './Select.module.scss';

interface Option {
    id: string;
    name: string;
}

interface SelectProps {
    name?: string;
    value?: string;
    label?: string;
    onChange?: (event: any) => void;
    options: Option[];
    isNumber?: boolean;
    placeholder?: string;
}

export const Select = ({
    label,
    name,
    value,
    onChange = () => { },
    placeholder = '',
    options,
    isNumber,
}: SelectProps): JSX.Element => {
    const selectOptions = options.length ? options : [{ id: '', name: '' }];
    return (
        <div className={cn(styles.select, { [styles.select_number]: isNumber })}>
            {label
            && (
                <label
                    className={
                        cn(styles.select__label, { [styles.select__label_number]: isNumber })
                    }
                    htmlFor={name}
                >
                    {label}
                </label>
            )}
            <select
                className={cn(styles.select__item, {
                    [styles.select__item_number]: isNumber,
                    [styles.select__item_empty]: !value,
                })}
                name={name}
                value={value ?? ''}
                onChange={onChange}
            >
                <option className={styles.select__defaultOption} value="" disabled>
                    {placeholder}
                </option>
                {selectOptions.map((item: Option) => (
                    <option
                        key={item.id}
                        value={item.id}
                        className={styles.select__option}
                    >
                        {item.name}
                    </option>

                ))}
            </select>
        </div>
    );
};
