import React, { useEffect } from 'react';

import { Loader } from '@common/Loader';
import { useLocation } from 'react-router-dom';

export function OAuthRedirectPage(): JSX.Element {
    const getParams = () => new URLSearchParams(useLocation().search);
    const params = getParams();
    useEffect(() => {
        const parent = window.opener?.parent.location;
        parent?.assign(`${parent.href}?&code=${params.get('code')}`);
        window.close();
    }, []);
    return (
        <div><Loader /></div>
    );
}
