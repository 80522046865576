import { makeAutoObservable } from 'mobx';

import {
    TeacherRole, ModuleTeacher, ModuleSkill, BaseItem,
} from './moduleStoreTypes';
/* eslint import/no-cycle: "off" */
import { moduleStore } from './moduleStore';
import { dictionaryStore } from './dictionaryStore';
import { Assignment } from './Assignment';
import { Meeting } from './Meeting';

export const defaultOptions = {
    id: '',
    name: '',
    description: '',
    minStudentCount: 0,
    maxStudentCount: 0,
    maxWaveCount: 0,
    teachersRoles: [],
    moduleTeachers: [],
    prerequisiteSkills: [],
    outputSkills: [],
    imageUrl: '',
};

export class Options {
    public id!: string;

    public name!: string;

    public description!: string;

    public minStudentCount?: number;

    public maxStudentCount?: number;

    public maxWaveCount!: number;

    public imageUrl!: string;

    private optionsTeachersRoles: TeacherRole[] = [];

    private optionsModuleTeachers: ModuleTeacher[] = [];

    public prerequisiteSkills!: ModuleSkill[];

    public outputSkills!: ModuleSkill[];

    constructor() {
        makeAutoObservable(this);
    }

    public get teachersRoles(): TeacherRole[] {
        return this.optionsTeachersRoles;
    }

    public set teachersRoles(newRoles: TeacherRole[]) {
        const uniqueRoles = newRoles
            .map(role => role.teacherRole.name.toLowerCase().trim())
            .filter((name, nameIndex, nameArray) => nameArray.indexOf(name) === nameIndex)
            .map(name => {
                const newRole = newRoles
                    .find(role => role.teacherRole.name.toLowerCase() === name)!;
                return { ...newRole, maxCount: 0 };
            });
        this.optionsTeachersRoles = uniqueRoles;
        const moduleTeachers = this.optionsModuleTeachers.map(teacher => {
            const haveRole = (option: BaseItem) => newRoles
                .some(role => role.teacherRole.id === option.id);
            const roles = teacher.teacherRoles.filter(haveRole);
            return { ...teacher, teacherRoles: roles };
        })
            .filter(teacher => teacher.teacherRoles.length > 0);

        this.moduleTeachers = moduleTeachers;
    }

    public set moduleTeachers(newTeachers: ModuleTeacher[]) {
        this.optionsModuleTeachers = newTeachers.map(teacher => ({
            ...teacher,
            teacherRoles: teacher.teacherRoles
                .filter(({ id }) => this.teachersRoles.some(role => role.teacherRole.id === id)),
        })).filter(teacher => teacher.teacherRoles.length > 0);

        this.optionsTeachersRoles = this.teachersRoles.map(role => {
            const maxCount = newTeachers.reduce((prev, cur) => (
                cur.teacherRoles.find(curRole => curRole.id === role.teacherRole.id)
                    ? prev + 1
                    : prev
            ), 0);
            return { ...role, maxCount };
        });
        const module = moduleStore.moduleModel;
        module.meetings
            .forEach(({ id }) => module.meetingList.updateMeetingTeacherRoles(id));
        module.evaluationPoints
            .forEach(({ id }) => module.evaluationPointList.updateEvaluationPointTeacherRoles(id));
    }

    public get moduleTeachers(): ModuleTeacher[] {
        return this.optionsModuleTeachers;
    }

    updateModuleRequiredSkills = (newSkills: ModuleSkill[], typeID: string): void => {
        this.prerequisiteSkills = moduleStore.getTotalSkills(
            this.prerequisiteSkills, newSkills, typeID,
        );
    };

    updateModuleOutputSkills = (newSkills: ModuleSkill[], typeID: string): void => {
        this.outputSkills = moduleStore.getTotalSkills(this.outputSkills, newSkills, typeID);
        moduleStore.moduleModel.saveValidAllEvaluationPointsSkills();
    };

    getRole(id: string): TeacherRole | undefined {
        return this.teachersRoles.find(item => item.teacherRole.id === id);
    }

    getEventsPrerequisiteSkillsForPreview = (typeId: string): ModuleSkill[] => {
        const { isLevelIncreased } = dictionaryStore.getSkillType(typeId)!;

        return isLevelIncreased
            ? this.getPrereqWithLevel(typeId)
            : this.getPrereqWithoutLevel(typeId);
    };

    private getUniqueSkillIds = (skills: ModuleSkill[]): string[] => {
        const skillsMap = new Map(skills.map(skill => [skill.skill.id, skill]));
        const uniqueSkillsIds = Array.from(skillsMap.values()).map(({ skill: { id } }) => id);
        return uniqueSkillsIds;
    };

    private getSkillsFromEventsWithoutTest(
        typeId: string,
        normalSkills: (skills: ModuleSkill, meeting: Meeting | Assignment) => ModuleSkill & any,
        isPrerequisites = true,
    ): ModuleSkill[] {
        const module = moduleStore.moduleModel;
        const allSkills = module.eventsWithoutTest.reduce((accumulator, event) => {
            const skills = isPrerequisites
                ? event.prerequisiteSkills ?? []
                : event.outputSkills ?? [];
            const normalizeSkills = skills.map(skill => normalSkills(skill, event));
            return accumulator.concat(normalizeSkills);
        }, ([] as any[]));
        const filteredByTypeSkills = allSkills
            .filter(({ skill }) => skill.typeId === dictionaryStore.getFinalSkillTypeId(typeId));
        return filteredByTypeSkills;
    }

    private getSkillsWithMinParam = (
        skills: (ModuleSkill & any)[], param: string,
    ): any[] => {
        const uniqueIds = this.getUniqueSkillIds(skills);
        const realSkills = uniqueIds.map(skillId => {
            const minValue = skills.reduce((prev, skill) => {
                if (skill.skill.id === skillId) { // @ts-ignore
                    return Math.min(prev, skill[param]);
                }
                return prev;
            }, Number.MAX_SAFE_INTEGER);
            const minValueSkill = skills
                .find(skill => {
                    const isThatSkill = skill.skill.id === skillId;
                    // @ts-ignore
                    return isThatSkill && skill[param] === minValue;
                })!;
            return minValueSkill;
        });
        return realSkills;
    };

    private getPrereqWithLevel(typeId: string): ModuleSkill[] {
        const normalSkill = (skill: ModuleSkill) => skill;
        const prerequisiteSkills = this.getSkillsFromEventsWithoutTest(typeId, normalSkill, true);
        const prereqSkillsWithMinLevel = this.getSkillsWithMinParam(prerequisiteSkills, 'level');

        const module = moduleStore.moduleModel;
        const outputSkills = module.evaluationPoints.reduce((accumulator, point) => {
            const skills = point.evaluationPointSkills ?? [];
            return accumulator.concat(skills);
        }, ([] as ModuleSkill[]))
            .filter(({ skill }) => skill.typeId === dictionaryStore.getFinalSkillTypeId(typeId));
        const outputSkillsWithMinLevel = this.getSkillsWithMinParam(outputSkills, 'level');

        return prereqSkillsWithMinLevel.filter(skill => {
            const evaluatedSkill = outputSkillsWithMinLevel
                .find(item => item?.skill?.id === skill?.skill?.id);
            return !evaluatedSkill || skill.level < evaluatedSkill.level;
        });
    }

    private getPrereqWithoutLevel(typeId: string): ModuleSkill[] {
        const normalSkill = (skill: ModuleSkill, event: Meeting | Assignment) => ({
            ...skill, eventOrder: event.order,
        });

        const prerequisiteSkills = this.getSkillsFromEventsWithoutTest(typeId, normalSkill, true);
        const prereqSkillsWithFirstEntry = this.getSkillsWithMinParam(
            prerequisiteSkills, 'eventOrder',
        );

        const outputSkills = this.getSkillsFromEventsWithoutTest(typeId, normalSkill, false);
        const outputSkillsWithFirstEntry = this.getSkillsWithMinParam(
            outputSkills, 'eventOrder',
        );

        return prereqSkillsWithFirstEntry.filter(skill => {
            const outputSkill = outputSkillsWithFirstEntry
                .find(({ skill: { id } }) => id === skill.skill.id);
            return !outputSkill || skill.eventOrder < outputSkill.eventOrder;
        });
    }
}
