import React from 'react';
import { ApolloError } from '@apollo/client';
import { Alert } from '@common/Alert';
import { observer } from 'mobx-react-lite';
import { SlotParametersList } from '../SlotParametersList';
import { SlotParametersForm } from '../SlotParametersForm';
import { SubspaceSkill, UpdateModuleSlotSkillInput } from '../subSpaceTypes';
import { skillTypesData_skillTypes } from '../../deprecated-graphql-query-types';
import { filterSkillTypesForSlots, parseSpecializedSkills } from './utilities';
import Diagram from '../store/Diagram';
import classes from './SlotParameters.module.scss';

interface Props {
    skillTypes: skillTypesData_skillTypes[]
    prerequisiteSkills?: SubspaceSkill[]
    outputSkills?: SubspaceSkill[]
    prerequisiteSpecializedSkills?: SubspaceSkill[]
    outputSpecializedSkills?: SubspaceSkill[]
    prerequisiteSpecializedSkillsLoading: boolean
    outputSpecializedSkillsLoading: boolean
    prerequisiteSpecializedSkillsError?: ApolloError
    outputSpecializedSkillsError?: ApolloError

    updateModuleSlotSkills(moduleSlotSkills: UpdateModuleSlotSkillInput): void;
}

export const SlotParameters = observer(({
    skillTypes,
    prerequisiteSkills = [],
    outputSkills = [],
    prerequisiteSpecializedSkills = [],
    outputSpecializedSkills = [],
    prerequisiteSpecializedSkillsLoading,
    outputSpecializedSkillsLoading,
    prerequisiteSpecializedSkillsError,
    outputSpecializedSkillsError,
    updateModuleSlotSkills,
}: Props): JSX.Element => {
    const { diagramSettings } = Diagram;
    const { showSlotParametersMode } = diagramSettings;
    const prerequisiteAndOutputGroupNames = ['входные', 'выходные'];
    return (
        <>
            {prerequisiteSpecializedSkillsError?.message
            && <Alert message={prerequisiteSpecializedSkillsError.message} />}
            {outputSpecializedSkillsError?.message
            && <Alert message={outputSpecializedSkillsError.message} />}

            {prerequisiteSpecializedSkillsLoading || outputSpecializedSkillsLoading
                ? <p>{' '}</p>
                : (
                    <div className={classes.slotParameters}>
                        {/* <div className={classes.slotParameters__moduleSize}> */}
                        {/*    Размер модуля: ~2 ЗЕТ */}
                        {/* </div> */}
                        {
                            (
                                (
                                    prerequisiteSkills.length > 0 || outputSkills.length > 0
                                )
                                && showSlotParametersMode
                            )
                        && !diagramSettings.editSlotParametersMode
                                ? (
                                    <SlotParametersList
                                        skillTypes={skillTypes}
                                        prerequisiteSkills={prerequisiteSkills}
                                        outputSkills={outputSkills}
                                        rootSkillTypes={filterSkillTypesForSlots(skillTypes)}
                                        prerequisiteAndOutputGroupNames={
                                            prerequisiteAndOutputGroupNames
                                        }
                                    />
                                )
                                : (
                                    <SlotParametersForm
                                        slotPrerequisiteSkills={prerequisiteSkills}
                                        slotOutputSkills={outputSkills}
                                        skillTypes={skillTypes}
                                        rootSkillTypes={filterSkillTypesForSlots(skillTypes)}
                                        prerequisiteSpecializedSkills={
                                            parseSpecializedSkills(prerequisiteSpecializedSkills)
                                        }
                                        outputSpecializedSkills={
                                            parseSpecializedSkills(outputSpecializedSkills)
                                        }
                                        prerequisiteAndOutputGroupNames={
                                            prerequisiteAndOutputGroupNames
                                        }
                                        updateModuleSlotSkills={updateModuleSlotSkills}
                                    />
                                )}
                    </div>
                )
            }
        </>

    );
});
