import { makeAutoObservable } from 'mobx';
import { nanoid } from 'nanoid';

/* eslint import/no-cycle: "off" */
import { moduleStore } from './moduleStore';
import { Assignment } from './Assignment';

export class AssignmentListModel {
    assignments: Assignment[] = [];

    constructor() {
        makeAutoObservable(this);
    }

    getAssignment(id: string): Assignment | undefined {
        return this.assignments.find(item => item.id === id);
    }

    updateAllAssignments(assignments: Assignment[]): void {
        this.assignments = assignments;
    }

    updateAssignment = (id: string, data: Partial<Assignment>): void => {
        const assignment = this.getAssignment(id)!;
        Object.keys(data).forEach(key => {
            // @ts-ignore
            assignment[key] = data[key];
        });
    };

    addAssignment = (): void => {
        const module = moduleStore.moduleModel;
        const lastOrder = module.events.reduce((acc, { order }) => Math.max(acc, order), 0);

        const newAssignment = new Assignment();
        newAssignment.id = nanoid();
        newAssignment.order = lastOrder + 1;

        this.assignments.push(newAssignment);
    };

    removeAssignment = (id: string): void => {
        const module = moduleStore.moduleModel;
        const assignment = module.getAssignment(id);
        if (!assignment) return;
        const newAssignmentsData = this.assignments.filter(item => item.id !== id);
        this.assignments = newAssignmentsData;
        module.changeOrder(
            module.events.find(({ order }) => order === assignment.order + 1)?.id ?? '',
            assignment?.order,
        );
        const dependentPoints = module.evaluationPoints
            .filter(point => point.previousEvent?.id === id);
        dependentPoints.forEach((point, index) => {
            dependentPoints[index].previousEvent = undefined;
        });
    };
}
