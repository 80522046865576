import React, { useEffect, useState } from 'react';
import { ApolloError } from '@apollo/client';
import { IconDeprecated } from '@common';
import { MultiAutosuggest } from '@common/MultiAutosuggest';
import { useUrlQuery } from '@common/hooks';
import { Alert } from '@common/Alert';

import { EventStatus } from '@admin/ScheduleGenerationPage/utils';

import { useUpdateMeetingWavesMutation } from '../apolloHooks';

import classes from './Waves.module.scss';

type Wave = {
    id: string;
    index: number;
    waveId?: string;
};
interface Props {
    status?: string;
    waves?: Wave[];
    meetingInstanceId?: string;
    eventType?: string;
    moduleWaves?: Wave[];
}

export const Waves = ({ status, waves, meetingInstanceId, eventType, moduleWaves }: Props) => {
    const { getUrlQuery } = useUrlQuery();
    const [isEditWavesOpen, setIsEditWavesOpen] = useState(false);
    const [suggestWaves, setSuggestWaves] = useState(
        normalizeWaves(waves),
    );
    const [errors, setErrors] = useState<JSX.Element[] | []>([]);

    function setErrorsFunction(error: ApolloError) {
        const normalError = error.message.split('Cannot return null');
        setErrors([...errors, (<Alert message={normalError[0]} />)]);
    }

    const { updateWaves } = useUpdateMeetingWavesMutation(
        getUrlQuery('detailedEventId') as string, getUrlQuery('detailedEventType') as string, setErrorsFunction,
    );

    useEffect(() => {
        setSuggestWaves(
            normalizeWaves(waves),
        );
    }, [waves]);

    function normalizeWaves(items: Wave[] = []) {
        return items
            .map((wave) => ({ id: `${wave.index + 1} поток`, index: wave.index, waveId: wave.id }))
            .sort((wave1, wave2) => wave1.index - wave2.index);
    }

    return isEditWavesOpen ? (
        <div className={classes.waves__edit}>
            <MultiAutosuggest
                placeholder="Выберите поток"
                onChange={setSuggestWaves}
                selectedOptions={suggestWaves.sort((a, b) => a.index - b.index)}
                options={normalizeWaves(moduleWaves)}
            />
            <IconDeprecated
                id="check"
                click={() => {
                    setIsEditWavesOpen(false);
                    updateWaves({
                        meetingInstanceId: meetingInstanceId ?? '',
                        newWaveIds: suggestWaves?.map(({ waveId }) => waveId) ?? [],
                    });
                }}
                className={classes.waves__icon}
            />
            {errors}
        </div>
    ) : (
        <>
            {suggestWaves && suggestWaves.length > 0 && (
                <>
                    <div className={classes.waves__title}>{suggestWaves.length > 1 ? ' Потоки' : ' Поток'}</div>
                    <span className={classes.waves__infoItem}>
                        {suggestWaves.map((wave) => wave.index + 1).join(', ')}
                        {suggestWaves.length > 1 ? ' потоки' : ' поток'}
                    </span>
                    {(status !== EventStatus.DELETED && eventType === 'meeting') && (
                        <IconDeprecated
                            id="editPenBlack"
                            click={() => setIsEditWavesOpen(true)}
                            className={classes.waves__icon}
                        />
                    )}
                    {errors}
                </>
            )}
        </>
    );
};
