import React from 'react';
import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import { head, upperFirst, words } from 'lodash';
import { ModuleSlot } from '../subSpaceTypes';
import classes from './Slot.module.scss';
import Diagram from '../store/Diagram';
import { findBtiIndex } from '../SubSpaceSlotDiagram/utilities';

interface Props {
    moduleSlot: ModuleSlot
}

export const Slot = observer(({
    moduleSlot,
}: Props): JSX.Element => {
    const {
        diagramSettings,
        slotSettings,
        diagramEntityRelationSettings,
        groupSlotSettings,
        forkSettings,
        btiSettings,
    } = Diagram;
    const {
        saveRelationDiagramEntityMode,
        removeDiagramEntityMode,
        relationDiagramEntityMode,
        createSlotMode,
        createGroupMode,
        createForkMode,
        editMode,
        showSlotParametersMode,
        showSuitableModules,
    } = diagramSettings;
    const { id, isSlotCanMove } = slotSettings;
    const { isGroupSlotCanMove } = groupSlotSettings;
    const { isForkCanMove } = forkSettings;
    const {
        nextEntityId,
        previousEntityId,
    } = diagramEntityRelationSettings;
    const { module } = moduleSlot;
    const mobuleNameRegex = /^([а-яё]+|[a-z]+)$/i;

    const getFirstAlphabetCharacter = (
        string: string,
    ): string => upperFirst(head(words(string).filter(word => mobuleNameRegex.test(word)).join()));

    const currentBtiIndex: number = findBtiIndex(
        btiSettings,
        moduleSlot.spaceBaseTimeIntervalId,
    );

    const selectedEntityBtiIndex: number = findBtiIndex(
        btiSettings,
        diagramEntityRelationSettings.spaceBaseTimeIntervalId,
    );

    return (
        <div
            className={cn(classes.slot, {
                [classes.slot_active]: (slotSettings.id === moduleSlot.id
                    && isSlotCanMove)
                || (id === moduleSlot.id && showSlotParametersMode)
                || (id === moduleSlot.id && showSuitableModules),
                [classes.slot_withoutPointer]:
                createSlotMode
                || isGroupSlotCanMove
                || createGroupMode
                || createForkMode
                || isForkCanMove
                || (
                    diagramEntityRelationSettings.entityType === 'ModuleSlot'
                    && currentBtiIndex < selectedEntityBtiIndex
                )
                || (diagramEntityRelationSettings.entityType === 'GroupSlot'
                    && diagramEntityRelationSettings.spaceBaseTimeIntervalId
                    !== moduleSlot.spaceBaseTimeIntervalId
                )
                || (
                    diagramEntityRelationSettings.entityType === 'Fork'
                    && diagramEntityRelationSettings.spaceBaseTimeIntervalId
                    !== moduleSlot.spaceBaseTimeIntervalId
                ),
                [classes.slot_readyToRemove]: removeDiagramEntityMode,
                [classes.slot_relationMode]: relationDiagramEntityMode,
                [classes.slot_setPrevious]: relationDiagramEntityMode
                && moduleSlot.id === previousEntityId
                && !nextEntityId,
                [classes.slot_readyToConnect]: (
                    relationDiagramEntityMode
                    && diagramEntityRelationSettings.entityType === 'ModuleSlot'
                    && !saveRelationDiagramEntityMode
                    && previousEntityId !== moduleSlot.id
                    && currentBtiIndex >= selectedEntityBtiIndex
                ) || (
                    relationDiagramEntityMode
                    && diagramEntityRelationSettings.entityType === 'GroupSlot'
                    && moduleSlot.spaceBaseTimeIntervalId
                    === diagramEntityRelationSettings.spaceBaseTimeIntervalId
                    && !saveRelationDiagramEntityMode
                    && previousEntityId !== moduleSlot.id
                ) || (
                    relationDiagramEntityMode
                    && diagramEntityRelationSettings.entityType === 'Fork'
                    && moduleSlot.spaceBaseTimeIntervalId
                    === diagramEntityRelationSettings.spaceBaseTimeIntervalId
                    && !saveRelationDiagramEntityMode
                    && previousEntityId !== moduleSlot.id
                ),
                [classes.slot_showSelectedRelatedSlots]: relationDiagramEntityMode
                && saveRelationDiagramEntityMode
                && (previousEntityId === moduleSlot.id || nextEntityId === moduleSlot.id),
                [classes.slot_filledByModule]: moduleSlot.module,
            })}
            onClick={(event) => {
                if (createGroupMode || createSlotMode) {
                    event.stopPropagation();
                }

                if (removeDiagramEntityMode && !createSlotMode) {
                    Diagram.removeEntity(
                        moduleSlot.id,
                        moduleSlot.spaceBaseTimeIntervalId,
                    );
                    Diagram.removeEntityRelation(moduleSlot.id);
                }

                if (editMode
                    && !createSlotMode
                    && !createGroupMode
                    && !createForkMode
                    && !relationDiagramEntityMode
                    && !removeDiagramEntityMode
                    && !isGroupSlotCanMove
                    && !isForkCanMove
                ) {
                    Diagram.setSlotCanMove(
                        event,
                        {
                            id: moduleSlot.id,
                            spaceBaseTimeIntervalId: moduleSlot.spaceBaseTimeIntervalId,
                        },
                    );
                }
                if (!editMode) {
                    Diagram.setSlotSettings(
                        {
                            id: moduleSlot.id,
                            spaceBaseTimeIntervalId: moduleSlot.spaceBaseTimeIntervalId,
                            hasModule: !!moduleSlot.module,
                            hasPrerequisiteSkills: !!moduleSlot.prerequisiteSkills,
                            hasOutputSkills: !!moduleSlot.outputSkills,
                        },
                    );
                    Diagram.setShowParametersSlotMode();
                }

                if (
                    (
                        relationDiagramEntityMode
                        && diagramEntityRelationSettings.entityType === 'ModuleSlot'
                        && !saveRelationDiagramEntityMode
                        && previousEntityId !== moduleSlot.id
                        && currentBtiIndex >= selectedEntityBtiIndex
                    ) || (
                        relationDiagramEntityMode
                        && diagramEntityRelationSettings.entityType === 'GroupSlot'
                        && moduleSlot.spaceBaseTimeIntervalId
                        === diagramEntityRelationSettings.spaceBaseTimeIntervalId
                        && !saveRelationDiagramEntityMode
                        && previousEntityId !== moduleSlot.id
                    ) || (
                        relationDiagramEntityMode
                        && diagramEntityRelationSettings.entityType === 'Fork'
                        && moduleSlot.spaceBaseTimeIntervalId
                        === diagramEntityRelationSettings.spaceBaseTimeIntervalId
                        && !saveRelationDiagramEntityMode
                        && previousEntityId !== moduleSlot.id
                    ) || (
                        !diagramEntityRelationSettings.entityType
                    )
                ) {
                    Diagram.setDiagramEntityRelationSettings(
                        moduleSlot.id,
                        moduleSlot.spaceBaseTimeIntervalId,
                        'ModuleSlot',
                    );
                }
            }}
        >
            {
                module?.name && (
                    <span className={classes.slot__moduleName}>
                        {getFirstAlphabetCharacter(module.name)}
                    </span>
                )
            }
            <span className={cn(classes.slot__content, {
                [classes.slot__content_readyToConnect]:
                (
                    relationDiagramEntityMode
                    && diagramEntityRelationSettings.entityType === 'ModuleSlot'
                    && !saveRelationDiagramEntityMode
                    && previousEntityId !== moduleSlot.id
                    && currentBtiIndex >= selectedEntityBtiIndex
                ) || (
                    relationDiagramEntityMode
                    && diagramEntityRelationSettings.entityType === 'GroupSlot'
                    && moduleSlot.spaceBaseTimeIntervalId
                    === diagramEntityRelationSettings.spaceBaseTimeIntervalId
                    && !saveRelationDiagramEntityMode
                    && previousEntityId !== moduleSlot.id
                ) || (
                    relationDiagramEntityMode
                    && diagramEntityRelationSettings.entityType === 'Fork'
                    && moduleSlot.spaceBaseTimeIntervalId
                    === diagramEntityRelationSettings.spaceBaseTimeIntervalId
                    && !saveRelationDiagramEntityMode
                    && previousEntityId !== moduleSlot.id
                ),

            })}
            />
            {
                moduleSlot.module?.name && (
                    <span className={classes.slot__toolTipText}>
                        {moduleSlot.module?.name}
                    </span>
                )
            }

        </div>
    );
});
