import React from 'react';
import { format } from 'date-fns';

import classes from './ScheduleGeneration.module.scss';
import {
    ScheduleGenerationProcessStatus,
    ScheduleGenerationProcessesSchedulePage_scheduleGenerationProcesses,
} from '../../../graphql-query-types';

interface Props {
    process: ScheduleGenerationProcessesSchedulePage_scheduleGenerationProcesses;
}

export const ScheduleGenerationInfo = ({ process }: Props) => (
    <div className={classes.scheduleGenerationInfo__wrapper}>
        <span className={classes.additionalGeneration_name}>
            {process.name}
        </span>
        <br />
        {formatDate(process.from)} - {formatDate(process.to)}
        <br />
        Статус: {getStatusName(process.status)}
    </div>
);

function formatDate(date: Date | string | number) {
    return format(new Date(date), 'dd.MM.yyyy');
}

function getStatusName(status: ScheduleGenerationProcessStatus) {
    switch (status) {
        case ScheduleGenerationProcessStatus.correction:
            return 'Запуск валидации';
        case ScheduleGenerationProcessStatus.preparing:
            return 'Подготовка данных';
        case ScheduleGenerationProcessStatus.generationPhase1:
            return 'Идет процесс генерации первой фазы';
        case ScheduleGenerationProcessStatus.generationPhase2:
            return 'Идет процесс генерации второй фазы';
        case ScheduleGenerationProcessStatus.generationReady:
            return 'Расписание сгенерировано';
        case ScheduleGenerationProcessStatus.generationFailed:
            return 'Ошибки генерации';
        case ScheduleGenerationProcessStatus.uploading:
            return 'Процесс загрузки расписания';
        case ScheduleGenerationProcessStatus.uploaded:
            return 'Генерации расписания завершена';
        case ScheduleGenerationProcessStatus.generated:
            return 'Генерации расписания завершена';
        default:
            return '';
    }
}
