import { makeAutoObservable } from 'mobx';
import { Dictionary, groupBy } from 'lodash';

export interface Skill {
    id: string;
    name: string;
    typeId: string;
    maxLevel: number;
    parent: {
        id: string;
    } | null;
}

export interface SkillNode {
    skill: Skill;
    children: SkillNode[];
}

class SkillHierarchy {
    private nodes: SkillNode[] = [];

    constructor() {
        makeAutoObservable(this);
    }

    public setSkills(skills: Skill[]): void {
        const children = groupBy(skills, 'parent.id');
        const roots = skills.filter(skill => !skill.parent);

        this.nodes = roots.map(root => this.createNode(root, children));
    }

    public filterBySkillIds(
        skillIds: string[],
        nodes: SkillNode[] = this.nodes,
    ): SkillNode[] {
        return nodes
            .filter(node => this.hasSkills(node, skillIds))
            .map(node => ({
                ...node,
                children: this.filterBySkillIds(skillIds, node.children),
            }));
    }

    private hasSkills(
        { skill, children }: SkillNode,
        skillIds: string[],
    ): boolean {
        return skillIds.includes(skill.id)
            || children.some(child => this.hasSkills(child, skillIds));
    }

    private createNode(skill: Skill, children: Dictionary<Skill[]>): SkillNode {
        const node: SkillNode = {
            skill,
            children: [],
        };
        node.children = children[skill.id]
            ?.map(item => this.createNode(item, children)) ?? [];

        return node;
    }
}

export default new SkillHierarchy();
