import React from 'react';
import { Subspace } from '@admin/Trajectories/types';
import { colors } from '@admin/Trajectories/utils';
import classes from './TrajectoryCard.module.scss';

interface Props {
    subspaces: Subspace[] | undefined;
}
export const Subspaces = ({ subspaces }: Props): JSX.Element => (
    <div className={classes.trajectoryCard__subspaces}>
        {subspaces?.map((subspace: Subspace, idx) => (
            <div className={classes.trajectoryCard__subspace} key={subspace.id}>
                <div
                    className={classes.trajectoryCard__SubspaceColorSign}
                    style={{ backgroundColor: colors[idx] }}
                />
                <h3 className={classes.trajectoryCard__text}>
                    <span className={classes.trajectoryCard__textAccent}>
                        {`${subspace.type?.name.toUpperCase()}:`}
                    </span>
                    {subspace.name}
                </h3>
            </div>
        ))}
    </div>
);
