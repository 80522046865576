import React from 'react';

import cn from 'classnames';

import { WeekDaysRussian } from '@common/WeekDaysPicker';
import classes from './WeekDay.module.scss';

interface Props {
    dayName: WeekDaysRussian;
    isActive: boolean;
    addActiveWeekDay: (day: WeekDaysRussian) => void;
    removeActiveWeekDay: (day: WeekDaysRussian) => void;
}

export function WeekDay(
    {
        dayName,
        isActive,
        addActiveWeekDay,
        removeActiveWeekDay,
    }: Props,
) {
    return (
        <div
            className={cn(classes.weekDay, {
                [classes.weekDay_active]: isActive,
            })}
            onClick={() => {
                if (isActive) {
                    removeActiveWeekDay(dayName);
                } else {
                    addActiveWeekDay(dayName);
                }
            }}
        >
            {dayName}
        </div>
    );
}
