import React from 'react';
import Select, { ActionMeta } from 'react-select';
import cn from 'classnames';

import colors from '../../store/ColorConstans';

import './Suggest.scss';

type CustomOption = {
    id: string;
    name: string;
    label?: string;
};

type Option = {
    id: string;
    name: string;
};

type OnChange = (
    (
        (value: CustomOption | null,
            actionMeta: ActionMeta<CustomOption>) => void
    ) & (
        (value: CustomOption | null,
            action: ActionMeta<CustomOption>) => void)) | undefined;

interface Props {
    value?: Option | null;
    label?: string;
    placeholder?: string;
    options?: Option[];
    noOptionMessage?: string;
    onChange?: OnChange
    action?: {};
    size?: string;
    customOptions?: any
}

export const Suggest = ({
    value,
    options,
    label,
    placeholder,
    onChange,
    action,
    size,
    noOptionMessage,
    customOptions,
}: Props): JSX.Element => {
    const customOptionsDefault = options?.map(option => ({ ...option, label: option.name }));
    const customValue = value ? { ...value, label: value.name } : null;
    const selectStyle = cn('suggest', { [`suggest_${size}`]: size });

    const customStyles = {
        control: (provided: any) => ({
            ...provided,
            minHeight: '28px',
            height: '28px',
            border: 'none',
            cursor: 'pointer',
            boxShadow: 'none',
        }),
        valueContainer: (provided: any) => ({
            ...provided,
            height: '30px',
            padding: '0 2px',
            display: 'flex',
            alignItems: 'center',
        }),
        singleValue: (provided: any) => ({
            ...provided,
            color: '#OOO',
            fontWeight: 'normal',
            fontSize: '16px',
            lineHeight: '19px',
            fontFamily: 'Arial',
        }),
        indicatorsContainer: (provided: any) => ({
            ...provided,
            display: 'none',
            outline: 'none',
        }),
        indicatorSeparator: (provided: any) => ({
            ...provided,
            display: 'none',
        }),
        placeholder: (provided: any) => ({
            ...provided,
            color: '#C4C4C4',
            fontWeight: 'normal',
            fontSize: '16px',
            lineHeight: '19px',
            fontFamily: 'Arial',
        }),
        option: (provided: any, state: any) => ({
            ...provided,
            color: '#000',
            background: state.isSelected && '#FFF',
            cursor: 'pointer',
        }),
        menuPortal: (provided: any) => ({ ...provided, zIndex: 9999 }),
        menu: (provided: any) => ({ ...provided, zIndex: 9999 }),
    };
    return (
        <div className="suggest-input">
            {label && <label htmlFor="Suggest" className="suggest-input__label">{label}</label>}
            <Select
                styles={customStyles}
                value={customValue}
                action={action}
                className={selectStyle}
                placeholder={placeholder}
                onChange={onChange}
                classNamePrefix="suggest"
                options={customOptions || customOptionsDefault}
                noOptionsMessage={() => noOptionMessage || 'No options'}
                theme={theme => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                        ...theme.colors,
                        primary25: colors.mockColor,
                        primary: colors.mockColor,
                    },
                })}
            />
        </div>
    );
};
