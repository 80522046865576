import React from 'react';

import classes from './ModuleCardInfo.module.scss';

import {
    EventType,
    EvaluationPoint,
} from '../../Config/interfaces';

interface Props {
    eventName: string;
    events: EventType[] | EvaluationPoint[];
    passedEventCount: number;
}

export const EventProgress = ({
    eventName,
    events,
    passedEventCount,
}: Props) => (
    <div className={classes.moduleCardInfo__progressEvent}>
        <p>{eventName}</p>
        <p>
            <span className={classes.moduleCardInfo__passedEvents}>
                {Math.min(passedEventCount, events.length)}
            </span>/{events.length}
        </p>
    </div>
);
