import React from 'react';
import { Input, InputTypes } from '@common/Input';
import { Suggest } from '@common';
import styles from './Section.module.scss';

type OptionSuggest = {
    id: string;
    name: string;
    count: number;
};

type OptionData = {
    id: string;
    name: string;
    inputValue: string;
    count: number;
};

export const SuggestInputFormInitialData = {
    id: '',
    name: '',
    inputValue: '',
    categoryId: '',
    count: 0,
};

interface Props {
    mainOptions: OptionSuggest[];
    label?: string;
    placeholderSuggest: string;
    placeholderInput: string;
    labelInput?: string;
    extraText?: string;
    data: OptionData;
    onChange?(id: string): void;
    setData(data: OptionData): void;
}

export function SuggestInputForm({
    mainOptions,
    label,
    placeholderSuggest,
    placeholderInput,
    labelInput,
    extraText,
    data,
    onChange,
    setData,
}: Props) {
    return (
        <>
            <div className={styles.suggestInput__suggest}>
                <Suggest
                    value={data.id ? data : null}
                    options={mainOptions}
                    label={label}
                    placeholder={placeholderSuggest}
                    onChange={value => {
                        if (onChange) onChange(value?.id ?? '');
                        setData({ ...data, ...value, inputValue: '' });
                    }}
                />
            </div>
            <Input
                value={data.inputValue}
                label={labelInput}
                type={InputTypes.Number}
                placeholder={placeholderInput}
                onChange={({ target }) => setData({
                    ...data,
                    inputValue: String(Math.min(Number(target.value), data.count)),
                })}
                min={1}
            />
            {extraText && <p className={styles.suggestInput__extraText}>{extraText}</p>}
        </>
    );
}
