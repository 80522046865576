import { observer } from 'mobx-react';
import React from 'react';

import colors from '../../store/ColorConstans';

interface Props {
    className?: string;
    size?: number;
    firstColor?: string;
    secondColor?: string;
    thirdColor?: string;
    handler?(): void;
}

export const FilledCheckIcon = observer(({
    size = 24,
    className,
    firstColor = colors.firstColor,
    secondColor = colors.secondColor,
    thirdColor = colors.thirdColor,
    handler,
}: Props) => (
    <div className={className} style={{ height: `${size}px`, width: `${size}px`, cursor: 'pointer' }} onClick={handler}>
        <svg width={size} height={size} viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M30 15C30 23.2843 23.2843 30 15 30C6.71573 30 0 23.2843 0 15C0 6.71573 6.71573 0 15 0C23.2843 0 30 6.71573 30 15ZM11.6165 16.7265C12.0095 17.1463 12.6747 17.1489 13.071 16.7323L18.3884 11.1429C18.7308 10.783 19.2955 10.7555 19.6712 11.0804C20.0695 11.4249 20.1029 12.031 19.7448 12.4171L13.0739 19.6095C12.6782 20.0361 12.0032 20.0361 11.6075 19.6095L9.15473 16.965C8.75393 16.5329 8.82247 15.8473 9.30087 15.5031C9.70072 15.2153 10.252 15.2692 10.5887 15.6287L11.6165 16.7265Z" fill="url(#paint0_linear_108_1023)" />
            <defs>
                <linearGradient id="paint0_linear_108_1023" x1="32.1687" y1="13.2911" x2="-0.441638" y2="26.0205" gradientUnits="userSpaceOnUse">
                    <stop stopColor={firstColor} />
                    <stop offset="0.0416667" stopColor={firstColor} />
                    <stop offset="0.130208" stopColor={firstColor} />
                    <stop offset="0.734375" stopColor={secondColor} />
                    <stop offset="0.932292" stopColor={thirdColor} />
                </linearGradient>
            </defs>
        </svg>
    </div>
));
