import React, { useState } from 'react';
import { observer } from 'mobx-react';

import { User } from '../../../../Interfaces';
import { UserList } from './UserList';

import classes from './Users.module.scss';
import { ShowAllStudentBtn } from './ShowAllStudentBtn';

interface UsersListProps {
    userList: User[];
    listName: string;
}

export const Users = observer(({
    userList,
    listName,
}: UsersListProps): JSX.Element => {
    const [showAllStudents, setShowAllStudents] = useState<boolean>(false);

    function toggleShowAllStudents(): void {
        setShowAllStudents((prevEditStudentMode) => !prevEditStudentMode);
    }

    return (
        <>
            <div className={classes.schedulePage_eventsSection_usersBlock}>
                <div className={classes.schedulePage_eventsSection_userText}>
                    {listName}
                </div>
            </div>

            <div className={classes.schedulePage_eventsSection_usersList}>
                {
                    !showAllStudents
                    && (
                        userList.slice(0, 4).map((user) => (
                            <UserList
                                user={user}
                                key={user.id}
                            />
                        ))
                    )
                }
                {
                    showAllStudents
                    && (
                        userList.map((user) => (
                            <UserList
                                user={user}
                                key={user.id}
                            />
                        ))
                    )
                }
            </div>

            {
                userList.length > 4
                && (
                    <ShowAllStudentBtn
                        showAllStudents={showAllStudents}
                        toggleShowAllStudents={toggleShowAllStudents}
                    />
                )
            }
        </>
    );
});
