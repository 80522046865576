import React, { useState } from 'react';

import { Select } from '@admin/NewModule/Select';
import { SectionList } from '@admin/NewModule/Section';
import { moduleStore, Meeting } from '@admin/NewModule/Store';
import { observer } from 'mobx-react-lite';
import { normalBoldJSX } from '@admin/NewModule';

import style from '../Meeting.module.scss';

interface Props {
    meeting: Meeting;
}

export const RolesFromMeeting = observer(({ meeting }: Props): JSX.Element => {
    const module = moduleStore.moduleModel;
    const { meetings } = module;

    const [selectMeetingId, setSelectMeetingId] = useState(meeting?.meetingIdForTeacherStaff ?? '');

    const [errors, setErrors] = useState<{ textError: React.ReactNode, nameError: string }[]>([]);

    const selectMeeting = module.getMeeting(selectMeetingId);
    const addRolesFromMeeting = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
        const meetingIdForTeacherStaff = target?.value;
        module.updateMeeting(meeting.id, {
            meetingTeacherRoles: module.getMeeting(meetingIdForTeacherStaff)?.meetingTeacherRoles,
            meetingIdForTeacherStaff,
        });
        setErrors([{ textError: '', nameError: '' }]);
        setSelectMeetingId(meetingIdForTeacherStaff);
        module.meetingList.updateAllMeetingRoles();
    };

    if (module.meetingList.hasRolesCollision(meeting.id, selectMeetingId!)) {
        const textError = (
            <div className={style.form__warning}>
                Нельзя наследовать преподавательский состав из этой встречи.
                <br />
                Встреча номер {selectMeeting?.meetingNumber} наследует
                преподавателей из текущей встречи.
            </div>
        );
        setErrors([...errors, { textError, nameError: 'collision' }]);
        setSelectMeetingId('');
    }

    const meetingsHaveRoles = meetings
        .filter(({ meetingTeacherRoles }) => meetingTeacherRoles?.length! > 0);

    const meetingNumbers = meetings
        .filter(item => item.id !== meeting.id).map(item => ({
            id: item.id,
            name: String(item.meetingNumber),
        })).sort((a, b) => Number(a.name) - Number(b.name));

    const dataList = meeting?.meetingTeacherRoles?.map(role => ({
        id: role.teacherRole.id,
        name: role.teacherRole.name,
        inputValue: String(role.count),
    })) ?? [];
    const hasSelectData = dataList?.length > 0 || selectMeetingId === '';
    const errorTextCollision = errors.find(error => error.nameError === 'collision')?.textError;
    return (
        meetingsHaveRoles.length > 0 ? (
            <>
                <div className={style.form__container}>
                    <Select
                        onChange={addRolesFromMeeting}
                        options={meetingNumbers}
                        value={selectMeeting?.id!}
                        label="Укажите номер встречи для использования ее преподавательского состава:"
                        isNumber
                    />
                </div>
                {hasSelectData ? (
                    <SectionList
                        list={dataList!}
                        hasRemove={false}
                        formatItem={({ inputValue, name }) => normalBoldJSX(name, inputValue)}
                    />
                ) : (
                    <p className={style.form__warning}>
                        У данной встречи не указан преподавательский состав.
                    </p>
                )}
                {errorTextCollision}
            </>
        ) : (
            <p className={style.form__warning}>
                Ни в одной из встреч не указан преподавательский состав.
            </p>
        )
    );
});
