import React from 'react';

import styles from './CheckBox.module.scss';

interface CheckBoxProps {
    id: string;
    checked: boolean;
    handleChangeCheckBox?: () => void;
}

export const CheckBox = ({
    id,
    checked,
    handleChangeCheckBox,
}: CheckBoxProps) => {
    const checkBoxId = `chackbox_${id}`;
    return (
        <>
            <input
                type="checkbox"
                className={styles.customCheckbox}
                id={checkBoxId}
                name="checkbox"
                checked={checked}
                onChange={handleChangeCheckBox}
            />
            <label htmlFor={checkBoxId} />
        </>
    );
};
