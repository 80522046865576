import React, { useState } from 'react';
import { format } from 'date-fns';

import { IconDeprecated } from '@common';
import { CheckboxRedesign } from '@common/CheckboxRedesign';
import { ShowEquipmentShortageViolation, ShowMeetingViolation, ShowStudentLimitViolation, ShowTeacherLimitViolation } from './shcheduleViolations';
import {
    BaseTimeIntervalInstanceForProcess,
    FiltrationBTI, MeetingViolation,
    ScheduleGenerationConflicts, StudentLimitViolation,
} from './scheduleViolationInterface';

import classes from './scheduleGenerationConflicts.module.scss';

interface Props {
    teacherMaxWeekWorkloadMinutes: number;
    resetConflicts: () => void;
    conflicts: ScheduleGenerationConflicts;
}

const formatDate = (date: string) => format(new Date(date), 'dd.MM.yy');

export function ScheduleGenerationConflict(
    {
        teacherMaxWeekWorkloadMinutes,
        conflicts,
        resetConflicts,
    }: Props,
) {
    const {
        filtrationBTIInstances,
        handleChangeFilteredBTIInstances,
    } = useGetConflictsFiltration(conflicts.baseTimeIntervalInstancesForProcess);

    const filtrationList = filtrationBTIInstances.map(
        (btiInstance) => (
            <CheckboxRedesign
                key={btiInstance.id}
                id={btiInstance.id}
                handleChangeCheckbox={() => handleChangeFilteredBTIInstances(btiInstance.id)}
                checked={btiInstance.isChecked}
            >
                <div className={classes.schedule__filtration_checkbox}>
                    {btiInstance.longName}
                </div>
            </CheckboxRedesign>
        ),
    );

    return (
        <div className={classes.schedule__score}>
            <IconDeprecated
                id="Cross"
                className={classes.schedule__closeScore}
                click={resetConflicts}
            />
            <div className={classes.schedule__scoreValue}>
                Результат проверки конфликтов
            </div>

            <div className={classes.schedule__filtration}>
                {filtrationList}
            </div>

            <div className={classes.schedule__conflict_info}>
                {`За период: ${formatDate(conflicts.fromDate)} - ${conflicts.toDate ? formatDate(conflicts.toDate) : 'конец сгенерированного расписания'}`}
            </div>
            <div className={classes.schedule__scoreValue}>
                {conflicts.isSuccess ? 'Нарушений правил не найдено' : 'Нарушены следующие правила:'}
            </div>
            <div className={classes.schedule__scoreExplanation}>
                {conflicts.meetingViolations?.map(meetingViolation => (
                    <ShowMeetingViolation
                        key={meetingViolation.violationName}
                        baseTimeIntervalsInstances={conflicts.baseTimeIntervalInstancesForProcess}
                        meetingViolation={
                            getMeetingViolationWithFilteredConflicts(
                                filtrationBTIInstances,
                                meetingViolation,
                            )
                        }
                    />
                ))}
                <ShowStudentLimitViolation
                    studentLimitViolation={
                        getStudentsViolationWithFilteredConflicts(
                            filtrationBTIInstances,
                            conflicts.studentMeetingLimitDurationViolation,
                        )
                    }
                    baseTimeIntervalsInstances={conflicts.baseTimeIntervalInstancesForProcess}
                />
                <ShowStudentLimitViolation
                    studentLimitViolation={
                        getStudentsViolationWithFilteredConflicts(
                            filtrationBTIInstances,
                            conflicts.studentBreakLimitDurationViolation,
                        )
                    }
                    baseTimeIntervalsInstances={conflicts.baseTimeIntervalInstancesForProcess}
                />
                <ShowEquipmentShortageViolation
                    equipmentShortageViolation={conflicts.equipmentShortageViolation}
                />
                <ShowTeacherLimitViolation
                    teacherMaxWeekWorkloadMinutes={teacherMaxWeekWorkloadMinutes}
                    teacherViolation={conflicts.teacherWeekMeetingsDurationLimitViolations}
                />
            </div>
        </div>
    );
}

export function getBaseTimeIntervalInstanceById(
    id: string,
    baseTimeIntervalInstances: BaseTimeIntervalInstanceForProcess[],
) {
    return baseTimeIntervalInstances.find((instance) => instance.id === id);
}

function getStudentsViolationWithFilteredConflicts(
    filtrationBTIInstances: FiltrationBTI[],
    studentLimitViolation: StudentLimitViolation | null,
): StudentLimitViolation | null {
    if (!studentLimitViolation) {
        return null;
    }

    const filteredStudentDurationLimitsByDate = studentLimitViolation
        .studentDurationLimitsByDate.map(
            (durationLimitsByDate) => {
                const filteredStudentDurationLimits = durationLimitsByDate
                    .studentDurationLimits.filter(
                        (durationLimit) => (
                            filtrationBTIInstances.some(
                                (instance) => instance.id
                                    === durationLimit.baseTimeIntervalInstanceId
                                    && instance.isChecked,
                            )
                        ),
                    );

                return {
                    ...durationLimitsByDate,
                    studentDurationLimits: filteredStudentDurationLimits,
                };
            },
        );

    return {
        ...studentLimitViolation,
        studentDurationLimitsByDate: filteredStudentDurationLimitsByDate,
    };
}

function getMeetingViolationWithFilteredConflicts(
    filtrationBTIInstances: FiltrationBTI[],
    meetingViolation: MeetingViolation,
): MeetingViolation {
    const filteredMeetingViolationConflicts = meetingViolation.conflicts.filter(
        (conflict) => {
            const conflictCanBeShown = conflict.baseTimeIntervalInstancesIds.some(
                (btiInstanceId) => filtrationBTIInstances.some(
                    (filtrationInstance) => filtrationInstance.id === btiInstanceId
                        && filtrationInstance.isChecked,
                ),
            );

            return conflictCanBeShown;
        },
    );

    return {
        ...meetingViolation,
        conflicts: filteredMeetingViolationConflicts,
    };
}

function useGetConflictsFiltration(
    baseTimeIntervalInstances: BaseTimeIntervalInstanceForProcess[],
) {
    const initialFiltrationBTIInstances: FiltrationBTI[] = baseTimeIntervalInstances.map(
        (baseTimeIntervalInstance) => ({
            ...baseTimeIntervalInstance,
            isChecked: true,
        }),
    );

    const [filtrationBTIInstances,
        setFiltrationBTIInstances] = useState<FiltrationBTI[]>(initialFiltrationBTIInstances);

    const handleChangeFilteredBTIInstances = (id: string) => {
        const filtrationBTIInstancesWithChangedCurrentActive = filtrationBTIInstances.map(
            (btiInstance) => {
                if (btiInstance.id === id) {
                    return {
                        ...btiInstance,
                        isChecked: !btiInstance.isChecked,
                    };
                }

                return btiInstance;
            },
        );

        setFiltrationBTIInstances(filtrationBTIInstancesWithChangedCurrentActive);
    };

    return {
        filtrationBTIInstances,
        handleChangeFilteredBTIInstances,
    };
}
