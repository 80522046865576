import React, { useState } from 'react';
import {
    useQuery,
    useLazyQuery,
} from '@apollo/client';

import { Alert } from '@common/Alert';

import {
    PaginationApolloProps as Props,
    defaultQuery,
} from './PaginationInterfaces';
import { Pagination } from './Pagination';

export function PaginationApollo(
    {
        className,
        pagesCount,
        currentPage,
        urlParameterName,
        forcePage,
        goPage,
        getPagesCountQuery = defaultQuery,
        goPageQuery = defaultQuery,
    } : Props,
): JSX.Element | null {
    const [errorAlerts, setErrorAlerts] = useState<JSX.Element[] | []>([]);

    function addError(message: string) {
        setErrorAlerts((arr) => [...arr, (<Alert
            key={new Date().toISOString()}
            message={message}
        />)]);
    }

    const {
        data: remotePagesCount,
        loading: loadingRemotePagesCount,
    } = useQuery<number>(
        getPagesCountQuery,
        {
            skip: !!(pagesCount),
            onError: (error) => addError(error.message),
            fetchPolicy: 'cache-and-network',
        },
    );

    const [goPageQueryCallback] = useLazyQuery(
        goPageQuery,
        {
            onError: (error) => addError(error.message),
            fetchPolicy: 'cache-and-network',
        },
    );

    function goPageCallBack(page: number) {
        goPageQueryCallback(
            {
                variables: {
                    page,
                },
            },
        );
    }

    if (loadingRemotePagesCount) {
        return null;
    }

    if (!pagesCount && !remotePagesCount) {
        addError('Не задано количество страниц');
        return <div>{errorAlerts}</div>;
    }

    return (
        <>
            <Pagination
                forcePage={forcePage}
                className={className}
                pagesCount={pagesCount ?? remotePagesCount!}
                currentPage={currentPage}
                urlParameterName={urlParameterName}
                goPage={goPage ?? goPageCallBack}
            />
            {errorAlerts}
        </>
    );
}
