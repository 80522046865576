/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: SpaceBaseTimeInterval
// ====================================================

export interface SpaceBaseTimeInterval_spaceBaseTimeInterval_spaceBaseTimeIntervalSubspaces_subspace {
  __typename: "Subspace";
  id: string;
  name: string;
}

export interface SpaceBaseTimeInterval_spaceBaseTimeInterval_spaceBaseTimeIntervalSubspaces_slots_module {
  __typename: "Module";
  id: string;
  name: string | null;
  minStudentCount: number;
  maxStudentCount: number;
}

export interface SpaceBaseTimeInterval_spaceBaseTimeInterval_spaceBaseTimeIntervalSubspaces_slots {
  __typename: "ModuleSlot";
  id: string;
  module: SpaceBaseTimeInterval_spaceBaseTimeInterval_spaceBaseTimeIntervalSubspaces_slots_module | null;
}

export interface SpaceBaseTimeInterval_spaceBaseTimeInterval_spaceBaseTimeIntervalSubspaces_forks_nextSlots_module {
  __typename: "Module";
  id: string;
  name: string | null;
  minStudentCount: number;
  maxStudentCount: number;
}

export interface SpaceBaseTimeInterval_spaceBaseTimeInterval_spaceBaseTimeIntervalSubspaces_forks_nextSlots {
  __typename: "ModuleSlot";
  id: string;
  module: SpaceBaseTimeInterval_spaceBaseTimeInterval_spaceBaseTimeIntervalSubspaces_forks_nextSlots_module | null;
}

export interface SpaceBaseTimeInterval_spaceBaseTimeInterval_spaceBaseTimeIntervalSubspaces_forks {
  __typename: "Fork";
  id: string;
  nextSlots: SpaceBaseTimeInterval_spaceBaseTimeInterval_spaceBaseTimeIntervalSubspaces_forks_nextSlots[];
}

export interface SpaceBaseTimeInterval_spaceBaseTimeInterval_spaceBaseTimeIntervalSubspaces {
  __typename: "SpaceBaseTimeIntervalSubspace";
  subspace: SpaceBaseTimeInterval_spaceBaseTimeInterval_spaceBaseTimeIntervalSubspaces_subspace;
  slots: SpaceBaseTimeInterval_spaceBaseTimeInterval_spaceBaseTimeIntervalSubspaces_slots[];
  forks: SpaceBaseTimeInterval_spaceBaseTimeInterval_spaceBaseTimeIntervalSubspaces_forks[];
}

export interface SpaceBaseTimeInterval_spaceBaseTimeInterval {
  __typename: "SpaceBaseTimeInterval";
  id: string;
  spaceBaseTimeIntervalSubspaces: SpaceBaseTimeInterval_spaceBaseTimeInterval_spaceBaseTimeIntervalSubspaces[];
}

export interface SpaceBaseTimeInterval {
  spaceBaseTimeInterval: SpaceBaseTimeInterval_spaceBaseTimeInterval;
}

export interface SpaceBaseTimeIntervalVariables {
  btiId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Waves
// ====================================================

export interface Waves_waves_students_user {
  __typename: "User";
  id: string;
  studentId: string | null;
  firstName: string;
  lastName: string;
  patronymic: string | null;
}

export interface Waves_waves_students {
  __typename: "Student";
  user: Waves_waves_students_user;
}

export interface Waves_waves_module {
  __typename: "Module";
  id: string;
  name: string | null;
  maxStudentCount: number;
  minStudentCount: number;
}

export interface Waves_waves {
  __typename: "Wave";
  id: string;
  index: number | null;
  students: Waves_waves_students[];
  module: Waves_waves_module;
}

export interface Waves {
  waves: Waves_waves[];
}

export interface WavesVariables {
  WaveInput: WavesInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ForkUndistributedStudents
// ====================================================

export interface ForkUndistributedStudents_forkUndistributedStudents_student_user {
  __typename: "User";
  id: string;
  studentId: string | null;
  firstName: string;
  lastName: string;
  patronymic: string | null;
}

export interface ForkUndistributedStudents_forkUndistributedStudents_student {
  __typename: "Student";
  user: ForkUndistributedStudents_forkUndistributedStudents_student_user;
}

export interface ForkUndistributedStudents_forkUndistributedStudents {
  __typename: "ForkUndistributedStudent";
  student: ForkUndistributedStudents_forkUndistributedStudents_student;
  remainingSelectionCount: number;
}

export interface ForkUndistributedStudents {
  forkUndistributedStudents: ForkUndistributedStudents_forkUndistributedStudents[];
}

export interface ForkUndistributedStudentsVariables {
  ForkUndistributedStudentsInput: ForkUndistributedStudentsInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteStudentWave
// ====================================================

export interface DeleteStudentWave {
  deleteStudentWave: string;
}

export interface DeleteStudentWaveVariables {
  DeleteStudentWaveInput: DeleteStudentWaveInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateStudentWave
// ====================================================

export interface UpdateStudentWave_updateStudentWave {
  __typename: "Wave";
  id: string;
}

export interface UpdateStudentWave {
  updateStudentWave: UpdateStudentWave_updateStudentWave[];
}

export interface UpdateStudentWaveVariables {
  UpdateStudentWaveInput: UpdateStudentWaveInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: StudentsRatings
// ====================================================

export interface StudentsRatings_studentsRating_student {
  __typename: "Student";
  id: string;
}

export interface StudentsRatings_studentsRating {
  __typename: "StudentRating";
  student: StudentsRatings_studentsRating_student;
  value: number;
}

export interface StudentsRatings {
  studentsRating: StudentsRatings_studentsRating[];
}

export interface StudentsRatingsVariables {
  studentsRatingInput: StudentsRatingInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: PreviousBaseTimeIntervalInstance
// ====================================================

export interface PreviousBaseTimeIntervalInstance_previousBaseTimeIntervalInstance {
  __typename: "BaseTimeIntervalInstance";
  id: string;
}

export interface PreviousBaseTimeIntervalInstance {
  previousBaseTimeIntervalInstance: PreviousBaseTimeIntervalInstance_previousBaseTimeIntervalInstance | null;
}

export interface PreviousBaseTimeIntervalInstanceVariables {
  baseTimeIntervalInstanceId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetIsValidStudentsWaveDistribution
// ====================================================

export interface GetIsValidStudentsWaveDistribution {
  isValidStudentsWaveDistribution: boolean;
}

export interface GetIsValidStudentsWaveDistributionVariables {
  baseTimeIntervalInstanceId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: BaseTimeIntervalInstance
// ====================================================

export interface BaseTimeIntervalInstance_baseTimeIntervalInstance {
  __typename: "BaseTimeIntervalInstance";
  id: string;
  selectionStatus: SelectionStatus;
}

export interface BaseTimeIntervalInstance {
  baseTimeIntervalInstance: BaseTimeIntervalInstance_baseTimeIntervalInstance;
}

export interface BaseTimeIntervalInstanceVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum SelectionStatus {
  moduleDistributionApprovalPending = "moduleDistributionApprovalPending",
  moduleDistributionEnabled = "moduleDistributionEnabled",
  moduleDistributionInProcess = "moduleDistributionInProcess",
  moduleSelectionEnabled = "moduleSelectionEnabled",
  moduleSelectionInProcess = "moduleSelectionInProcess",
  selectionEnabled = "selectionEnabled",
  selectionFinished = "selectionFinished",
  selectionPending = "selectionPending",
  subspaceDistributionApprovalPending = "subspaceDistributionApprovalPending",
  subspaceDistributionEnabled = "subspaceDistributionEnabled",
  subspaceDistributionInProcess = "subspaceDistributionInProcess",
  subspaceSelectionEnabled = "subspaceSelectionEnabled",
  subspaceSelectionInProcess = "subspaceSelectionInProcess",
  waveDistributionApprovalPending = "waveDistributionApprovalPending",
  waveDistributionInProcess = "waveDistributionInProcess",
}

export interface DeleteStudentWaveInput {
  baseTimeIntervalInstanceId: string;
  studentId: string;
  forkId: string;
  waveId: string;
}

export interface ForkUndistributedStudentsInput {
  baseTimeIntervalInstanceId: string;
  forkId: string;
}

export interface StudentsRatingInput {
  baseTimeIntervalInstanceId?: string | null;
  studentIds: string[];
}

export interface UpdateStudentWaveInput {
  baseTimeIntervalInstanceId: string;
  studentId: string;
  forkId?: string | null;
  waveId: string;
  previousWaveId?: string | null;
}

export interface WavesInput {
  baseTimeIntervalInstanceId: string;
  moduleIds?: string[] | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
