import React from 'react';

import cn from 'classnames';
import classes from './MeetingInfo.module.scss';

interface Props {
    order: number;
    dateStart: string;
    startTime: string;
    endTime: string;
    meetingName: string;
    teacherName: string;
    className?: string;
}

export function MeetingInfo(
    {
        order,
        dateStart,
        startTime,
        endTime,
        meetingName,
        teacherName,
        className,
    }: Props,
) {
    return (
        <div className={cn(classes.info, className)}>

            <div className={classes.info__time}>
                {dateStart} {startTime}-{endTime}
            </div>

            <div className={classes.info__meeting}>
                {order} встреча /
                <span className={cn(classes.info__meeting, classes.info__meeting_dark)}>
                    {' '}
                    { meetingName }
                </span>
            </div>

            <div className={classes.info__teacher}>
                {
                    teacherName
                }
            </div>

        </div>
    );
}
