import React, { useEffect, useState } from 'react';
import { Alert } from '@common/Alert';
import { ApolloError, useLazyQuery } from '@apollo/client';

import {
    EquipmentFilterParams,
    EquipmentPaginationParams,
    GetEquipmentsEquipmentPage_equipment_list,
    UpdateEquipmentEquipmentPage_updateEquipment,
    UpdateEquipmentInput,
    TerritorialZonesEquipmentPage_territorialZones,
} from 'src/deprecated-graphql-query-types';
import { GetRoomsEquipmentPage, RoomsInput } from 'src/graphql-query-types';
import { RegularEquipmentList } from './RegularEquipmentList';
import {
    useEquipmentQuery,
    useRemoveEquipmentMutation,
} from '../graphqlHooks';
import { GET_ALL_ROOMS } from '../queries';
import { NewEquipmentPosition, equipmentStore } from '../store/EquipmentStore';
import { AddEquipmentsDropDown } from '../AddEquipments';

interface Props {
    zones?: TerritorialZonesEquipmentPage_territorialZones[],
    updateEquipmentHandler(
        input: UpdateEquipmentInput,
        callBack: (params: UpdateEquipmentEquipmentPage_updateEquipment) => void,
    ): void;
}

export const LIMIT_EQUIPMENT = 10;

export const RegularEquipmentListApollo = ({
    zones,
    updateEquipmentHandler,
}: Props) => {
    const { removeEquipment, error: errorRemove } = useRemoveEquipmentMutation();
    const { data, error: equipmentQueryError, loading, refetch } = useEquipmentQuery(
        { isComputer: false },
        { limit: LIMIT_EQUIPMENT },
    );

    const [getRooms, { data: roomsData }] = useLazyQuery<GetRoomsEquipmentPage, RoomsInput>(
        GET_ALL_ROOMS,
    );

    const [
        newRegularEquipment, setRegularEquipment,
    ] = useState<GetEquipmentsEquipmentPage_equipment_list[]>([]);

    useEffect(
        () => {
            if (data) {
                setRegularEquipment(data.equipment.list);
            }
        },
        [loading],
    );
    function filterOrPaginationEquipment(
        filterParams?: EquipmentFilterParams,
        paginationParams?: EquipmentPaginationParams,
    ) {
        refetch({
            filterParams: filterParams ?? { isComputer: false },
            paginationParams: paginationParams ?? {},
        }).then((request) => setRegularEquipment(request.data.equipment.list));
    }

    function createClassicEquipmentHandler(position?: NewEquipmentPosition) {
        const newEquipment: GetEquipmentsEquipmentPage_equipment_list = {
            __typename: 'Equipment',
            id: equipmentStore.newEquipmentId,
            category: {
                __typename: 'EquipmentCategory',
                id: '',
                name: '',
            },
            features: [],
            movability: [],
            rooms: [],
        };

        if (position === NewEquipmentPosition.TOP) {
            setRegularEquipment((pre => ([newEquipment, ...pre])));
        } else {
            setRegularEquipment((pre => ([...pre, newEquipment])));
        }
        equipmentStore.setNewEquipmentViewParameters(
            true,
            newEquipment.id,
        );
    }

    function removeEquipmentFromState(id: string) {
        setRegularEquipment((pre) => pre.filter(equipment => equipment.id !== id));
    }
    function deleteNewRegularEquipment(id: string) {
        removeEquipment({
            variables: {
                id,
            },
        }).then(() => removeEquipmentFromState(id));
    }
    const hasPagination = newRegularEquipment.length < (data?.equipment.totalCount ?? 0);
    const error = errorRemove || equipmentQueryError;

    useEffect(() => {
        if (errorRemove?.graphQLErrors[0].extensions?.roomIds) {
            const ids = errorRemove?.graphQLErrors[0].extensions?.roomIds;
            getRooms({ variables: { ids } });
        }
    }, [errorRemove]);

    function customMessageForError(apolloError?: ApolloError) {
        const removeErrorFromTypeORM = removeErrorStringFromTypeORM(apolloError?.message ?? '');
        if (errorRemove?.graphQLErrors[0].extensions?.roomIds) {
            const ids = errorRemove?.graphQLErrors[0].extensions?.roomIds;
            const roomTitles = ids.map((roomId: string) => roomsData && roomsData.rooms.find((room) => room.id === roomId)?.title).join(', ');
            return `${removeErrorFromTypeORM} ${roomTitles}`;
        }
        return removeErrorFromTypeORM;
    }
    return (
        <>
            <AddEquipmentsDropDown
                addNewRegularEquipment={
                    () => createClassicEquipmentHandler(NewEquipmentPosition.TOP)
                }
            />
            <RegularEquipmentList
                loading={loading}
                hasPagination={hasPagination}
                zones={zones}
                equipments={newRegularEquipment ?? []}
                removeEquipmentFromState={removeEquipmentFromState}
                filterOrPaginationEquipment={filterOrPaginationEquipment}
                createClassicEquipment={createClassicEquipmentHandler}
                updateEquipment={updateEquipmentHandler}
                deleteNewRegularEquipment={deleteNewRegularEquipment}
            />
            {error && <Alert message={customMessageForError(error)} />}
        </>
    );
};

export function removeErrorStringFromTypeORM(error = '') {
    const customError = error.split('Cannot return');
    return customError[0];
}
