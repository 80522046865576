import React, { useEffect, useState } from 'react';
import cn from 'classnames';

import { PlusIcon } from '@common/svg';

import { AssessmentQuestion } from '../../module-assessment-config-store';
import classes from './ModuleAssessment.module.scss';
import { StudentAssessmentStatus } from '../../graphql-query-types';
import { Answer, AssessmentError } from './interface';

interface OpenEndedAssessmentQuestionProps {
    question: AssessmentQuestion;
    answer?: string | null;
    assessmentStatus: StudentAssessmentStatus | undefined;
    error: AssessmentError | undefined;
    onChange(answer: Answer): void;
}

export function OpenEndedQuestion({
    question: { title, id },
    answer,
    assessmentStatus,
    error,
    onChange,
}: OpenEndedAssessmentQuestionProps) {
    const [isOpened, setIsOpened] = useState<boolean>(!!answer);

    useEffect(() => {
        if (error) {
            setIsOpened(true);
        } else {
            setIsOpened(!!answer);
        }
    }, [error]);

    function onTextareaChange(event: React.ChangeEvent<HTMLTextAreaElement>) {
        if (assessmentStatus === StudentAssessmentStatus.finished) {
            return;
        }
        onChange({
            questionId: id,
            value: event.target.value,
            optionId: null,
        });
    }

    const iconClassName = cn(
        classes['assessment-question__icon'], {
            [classes['assessment-question__icon_active']]: isOpened,
        },
    );
    return (
        <div
            className={classes['module-assessment-tab__open-ended-question']}
        >
            <div
                className={classes['assessment-open-ended-question']}
                onClick={assessmentStatus === StudentAssessmentStatus.active
                    ? () => setIsOpened(!isOpened)
                    : undefined
                }
            >
                <PlusIcon className={iconClassName} />
                <span className={classes['assessment-open-ended-question__title']}>
                    {title}
                </span>
            </div>
            {isOpened && (
                <textarea
                    value={answer || ''}
                    placeholder="Введите текст"
                    className={cn(
                        classes['assessment-answer'],
                        { [classes['assessment-answer_invalid']]: !!error },
                    )}
                    onChange={onTextareaChange}
                />
            )}
        </div>
    );
}
