import React, { useState } from 'react';

import { IconDeprecated } from '@common';
import { WorkingIntervals } from './WorkingIntervals/WorkingIntervals';
import { DaysNavigation } from './DaysNavigator/DaysNavigator';
import { DaySchedule, getWeekDaysWithAdditionSchedule, WeekType } from './modelTimePicker';

import classes from './timePicker.module.scss';

interface Props {
    daySchedules: DaySchedule[],
    update: (typeWeek: WeekType, daySchedules: DaySchedule[]) => void,
    typeWeek: WeekType,

}

function getCurrentDaySchedule(daySchedules: DaySchedule[], currentDay: string) {
    return daySchedules.find((daySchedule) => daySchedule.weekDay === currentDay)
        || { weekDay: currentDay, workingIntervals: [] };
}

export function TimePicker({ update, daySchedules, typeWeek }: Props) {
    const [dayScheduleswhithCurrent, setDaySchedules] = useState<DaySchedule[]>(daySchedules);
    const weekDays = getWeekDaysWithAdditionSchedule(dayScheduleswhithCurrent);
    const [currentDay, setCurrentDay] = useState<string>('Monday');
    return (
        <>
            <div className={classes.timePicker}>
                <div className={classes.timePicker__daysNavigation}>
                    <DaysNavigation
                        currentDay={currentDay}
                        setCurrentDay={setCurrentDay}
                        weekDays={weekDays}
                    />
                </div>
                <div className={classes.timePicker__workIntervals}>
                    <WorkingIntervals
                        typeWeek={typeWeek}
                        daySchedule={getCurrentDaySchedule(dayScheduleswhithCurrent, currentDay)}
                        daySchedules={dayScheduleswhithCurrent}
                        setDaySchedules={setDaySchedules}
                    />
                    <IconDeprecated
                        id="check"
                        className={classes.timePicker__saveButton}
                        click={() => update(typeWeek, dayScheduleswhithCurrent)}
                    />
                </div>
            </div>
        </>
    );
}
