import { gql, useQuery } from '@apollo/client';
import { ScheduleGenerationProcess } from '@admin/ScheduleGenerationPage/store/BaseTimeIntervalInstanceModel';

const ADDITIONAL_GENERATION_PROCESSES = gql`
    query AdditionalGenerationProcesses($baseTimeIntervalInstanceIds: [String!]!) {
        additionalScheduleGenerationProcesses(baseTimeIntervalInstanceIds: $baseTimeIntervalInstanceIds) {
            id
            name
            from
            to
            status
            constraintMatch {
                constraintMatchCountList {
                    constraintName
                    scoreType
                    matchCount
                }
            }
        }
    }
`;

export function useAdditionalGenerationsQuery(baseTimeIntervalInstanceId: string) {
    const { data, loading, refetch } = useQuery<{
        additionalScheduleGenerationProcesses: ScheduleGenerationProcess[];
    }>(ADDITIONAL_GENERATION_PROCESSES, {
        variables: { baseTimeIntervalInstanceIds: [baseTimeIntervalInstanceId] },
        fetchPolicy: 'network-only',
        pollInterval: 4500,
    });
    const { additionalScheduleGenerationProcesses } = data ?? {};
    return { additionalScheduleGenerationProcesses, loading, refetch };
}
