import { useQuery } from '@apollo/client';
import { GetBTIByIds, GetBTIByIdsVariables } from 'src/graphql-query-types';
import { GET_BTI_BY_IDS } from '../queries';

export const useGetBaseTimeIntervalInstance = (ids: string[]) => {
    const {
        data,
        loading,
        error,
    } = useQuery<GetBTIByIds, GetBTIByIdsVariables>(
        GET_BTI_BY_IDS,
        {
            variables: { ids },
        },
    );
    return { data, loading, error };
};
