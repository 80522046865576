import React, { useEffect, useState } from 'react';

import { TimeInput } from '@common/TimeInput';

import { WeekDaysPicker, WeekDaysRussian } from '@common/WeekDaysPicker';
import classes from './WeekDaysPickerWithTime.module.scss';

export interface WeekDayWithTime {
    fromTime: string;
    toTime: string;
    weekDay: keyof typeof WeekDaysRussian;
}

interface Props {
    getWeekDaysWithTime: (newWeekDaysWithTime: WeekDayWithTime[]) => void;
}

export function WeekDaysPickerWithTime(
    {
        getWeekDaysWithTime,
    }: Props,
) {
    const {
        weekDaysWithTime,
        getFromTime,
        getToTime,
        getActiveWeekDays,
    } = useGetWeekDaysWithTime();

    useEffect(() => {
        getWeekDaysWithTime(weekDaysWithTime);
    }, [
        weekDaysWithTime,
    ]);

    return (
        <div className={classes.weekDaysPickerWithTime}>

            <div className={classes.weekDaysPickerWithTime__time}>
                <TimeInput
                    onChange={(e) => getFromTime(e)}
                />

                <span className={classes.weekDaysPickerWithTime__time_separator}>
                    —
                </span>

                <TimeInput
                    onChange={(e) => getToTime(e)}
                />
            </div>

            <WeekDaysPicker
                getActiveWeekDays={(days) => getActiveWeekDays(days)}
            />

        </div>
    );
}

function useGetWeekDaysWithTime() {
    const [weekDaysWithTime, setWeekDaysWithTime] = useState<WeekDayWithTime[]>([]);
    const [activeWeekDays, setActiveWeekDays] = useState<WeekDaysRussian[]>([]);
    const [fromTime, setFromTime] = useState('');
    const [toTime, setToTime] = useState('');

    const getFromTime = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFromTime(event.target.value);
    };
    const getToTime = (event: React.ChangeEvent<HTMLInputElement>) => {
        setToTime(event.target.value);
    };

    const getActiveWeekDays = (
        newActiveWeekDays: WeekDaysRussian[],
    ) => {
        setActiveWeekDays(newActiveWeekDays);
    };

    useEffect(() => {
        const newWeekDaysWithTime: WeekDayWithTime[] = activeWeekDays.map((day) => {
            const weekDay = Object.keys(
                WeekDaysRussian,
            )[Object.values(WeekDaysRussian).indexOf(day)] as keyof typeof WeekDaysRussian;

            return {
                fromTime,
                toTime,
                weekDay,
            };
        });

        setWeekDaysWithTime(newWeekDaysWithTime);
    }, [
        fromTime,
        toTime,
        activeWeekDays,
    ]);

    return {
        weekDaysWithTime,
        getFromTime,
        getToTime,
        getActiveWeekDays,
    };
}
