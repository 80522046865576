import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useQuery } from '@apollo/client';

import UserView from '@store/UserView';
import { Loader } from '@common/Loader';

import { GET_TEACHER } from './TeacherAssignmentsQueries';
import { SpaceMap } from './TeacherAssignmentsTypes';

import {
    GetTeacher,
    GetTeacherVariables,
    GetTeacher_teacher_baseTimeIntervalInstancesModules,
} from './TeacherAssignmentsApolloTypes';
import { TeacherAssignmentsPage } from './TeacherAssignmentsPage';

export const TeacherAssignmentsApollo = observer(() => {
    const [activeSpaceId, setActiveSpaceId] = useState<string>();
    const [activeBTIId, setActiveBTIId] = useState<string>();

    const { data, loading } = useQuery<GetTeacher, GetTeacherVariables>(
        GET_TEACHER,
        {
            variables: {
                id: UserView.teacherId!,
            },
            fetchPolicy: 'cache-and-network',
        },
    );

    const spaceTabs = data?.teacher?.baseTimeIntervalInstancesModules
        ? getSpaceTabs(data?.teacher.baseTimeIntervalInstancesModules)
        : [];

    useEffect(() => {
        if (spaceTabs.length) {
            const defaultAtiveSpace = spaceTabs[0];

            setActiveSpaceId(defaultAtiveSpace.id);

            setActiveBTIId(
                defaultAtiveSpace.baseTimeIntervalInstances[0].baseTimeIntervalInstance.id,
            );
        }
    }, [spaceTabs]);

    if (loading) {
        return <Loader />;
    }

    const activeSpace = spaceTabs.find(({ id }) => id === activeSpaceId);
    const baseTimeIntervalInstances = activeSpace?.baseTimeIntervalInstances ?? [];

    return (
        <TeacherAssignmentsPage
            spaceTabs={spaceTabs}
            btiTabs={baseTimeIntervalInstances}
            activeSpaceId={activeSpaceId}
            activeBTIId={activeBTIId}
        />
    );
});

// eslint-disable-next-line max-len
function getSpaceTabs(baseTimeIntervalInstancesModules: GetTeacher_teacher_baseTimeIntervalInstancesModules[]) {
    const spaceMap: SpaceMap = {};

    baseTimeIntervalInstancesModules.forEach((baseTimeIntervalInstanceModules) => {
        const {
            baseTimeIntervalInstance: {
                spaceBaseTimeInterval: {
                    space,
                },
            },
        } = baseTimeIntervalInstanceModules;

        if (spaceMap[space.id]) {
            spaceMap[space.id].baseTimeIntervalInstances.push(baseTimeIntervalInstanceModules);
        } else {
            spaceMap[space.id] = {
                ...space,
                baseTimeIntervalInstances: [baseTimeIntervalInstanceModules],
            };
        }
    });

    return Object.values(spaceMap);
}
