import React from 'react';

export function ActiveArrow() {
    return (
        <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="19" height="19" rx="2" fill="#F8F9FE" />
            <path d="M6 8L9.5 11L13 8" stroke="#0B42DC" strokeWidth="1.5" strokeLinecap="round" />
        </svg>

    );
}
