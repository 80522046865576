import React, { useState, useEffect } from 'react';

import { Select } from '@common/Select';
import { Pagination } from '@common/Pagination';
import { useUrlQuery } from '@common/hooks';

import classes from './SchedulePagination.module.scss';

interface Props {
    pagesCount?: number,
    onChange(): void,
    refetchPagesCount(): void,
}

const DELIMITERS = [
    {
        id: 'day',
        name: 'День',
    },
    {
        id: 'week',
        name: 'Неделя',
    },
    {
        id: 'month',
        name: 'Месяц',
    },
];

export function SchedulePagination({
    pagesCount = 0,
    onChange,
    refetchPagesCount,
}: Props): JSX.Element | null {
    const { setUrlQuery, getUrlQuery } = useUrlQuery();
    const pageCountFromUrl = getUrlQuery('page') ?? '1';
    const defaultCurrentPAge = Number(pageCountFromUrl) - 1;
    const [currentPage, setCurrentPage] = useState(defaultCurrentPAge);
    useEffect(() => {
        setUrlQuery({ page: pageCountFromUrl ?? '1' });
        setCurrentPage(defaultCurrentPAge);
    }, [pagesCount]);

    if (!pagesCount) {
        return (
            <>
                Событий не найдено
            </>
        );
    }

    return (
        <div className={classes.schedulePagination}>
            <div className={classes.schedulePagination__select}>
                {/* <div className={classes.schedulePagination__label}>
                    Группировать по:
                </div> */}
                <Select
                    urlParameterName="delimiter"
                    options={DELIMITERS}
                    formatOption={(item: { id: string, name: string }) => item.name}
                    onChange={() => {
                        setCurrentPage(0);
                        setUrlQuery({ page: 1 });
                        setCurrentPage(0);
                        onChange();
                        refetchPagesCount();
                    }}
                />
            </div>

            <Pagination
                className={classes.schedulePagination__pagination}
                forcePage={currentPage}
                pagesCount={pagesCount}
                urlParameterName="page"
                goPage={(page: number) => { setCurrentPage(page - 1); onChange(); }}
            />
        </div>
    );
}
