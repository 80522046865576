import React from 'react';

export function CsvIcon() {
    return (
        <svg width="21" height="28" viewBox="0 0 21 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 5C0 2.23858 2.23858 0 5 0H13L16.5 4.5L21 8.5V23C21 25.7614 18.7614 28 16 28H5C2.23858 28 0 25.7614 0 23V5Z" fill="url(#paint0_linear_1089_2598)" />
            <path d="M21 8.5002L13 8.5002L13 0L21 8.5002Z" fill="url(#paint1_linear_1089_2598)" />
            <path d="M8.40129 19.7062H7.48933C7.46332 19.5607 7.41537 19.4317 7.34547 19.3194C7.27557 19.2055 7.1886 19.109 7.08456 19.0299C6.98052 18.9508 6.86185 18.8915 6.72856 18.8519C6.59688 18.8108 6.45464 18.7902 6.30184 18.7902C6.03036 18.7902 5.78978 18.8567 5.58007 18.9896C5.37037 19.1209 5.20619 19.3139 5.08752 19.5686C4.96885 19.8217 4.90952 20.131 4.90952 20.4964C4.90952 20.8682 4.96885 21.1815 5.08752 21.4362C5.20781 21.6893 5.372 21.8807 5.58007 22.0104C5.78978 22.1386 6.02955 22.2027 6.2994 22.2027C6.44895 22.2027 6.58875 22.1837 6.7188 22.1457C6.85047 22.1062 6.96833 22.0484 7.07237 21.9725C7.17803 21.8965 7.26663 21.8032 7.33815 21.6925C7.4113 21.5817 7.4617 21.4551 7.48933 21.3128L8.40129 21.3175C8.36715 21.5485 8.29319 21.7652 8.1794 21.9677C8.06723 22.1702 7.92011 22.349 7.73805 22.504C7.55598 22.6575 7.34303 22.7777 7.09919 22.8647C6.85535 22.9502 6.58469 22.9929 6.28721 22.9929C5.8483 22.9929 5.45653 22.894 5.1119 22.6963C4.76728 22.4985 4.49581 22.2129 4.29748 21.8396C4.09916 21.4662 4 21.0185 4 20.4964C4 19.9728 4.09997 19.5251 4.29992 19.1533C4.49987 18.7799 4.77216 18.4944 5.11678 18.2966C5.46141 18.0989 5.85155 18 6.28721 18C6.56518 18 6.82365 18.038 7.06261 18.1139C7.30158 18.1898 7.51453 18.3014 7.70147 18.4485C7.88841 18.5941 8.04203 18.7728 8.16233 18.9848C8.28425 19.1952 8.3639 19.4357 8.40129 19.7062Z" fill="white" />
            <path d="M11.9437 19.4025C11.9209 19.1952 11.825 19.0339 11.6559 18.9184C11.4885 18.8029 11.2707 18.7451 11.0024 18.7451C10.8139 18.7451 10.6521 18.7728 10.5172 18.8282C10.3823 18.8836 10.279 18.9587 10.2075 19.0536C10.136 19.1486 10.0994 19.2569 10.0978 19.3787C10.0978 19.48 10.1214 19.5678 10.1685 19.6421C10.2173 19.7165 10.2831 19.7798 10.366 19.832C10.4489 19.8826 10.5408 19.9253 10.6416 19.9601C10.7423 19.9949 10.8439 20.0242 10.9464 20.0479L11.4145 20.1618C11.6031 20.2046 11.7843 20.2623 11.9583 20.3351C12.1338 20.4078 12.2907 20.4996 12.4289 20.6103C12.5687 20.7211 12.6792 20.8548 12.7605 21.0114C12.8418 21.168 12.8824 21.3515 12.8824 21.5619C12.8824 21.8467 12.8077 22.0975 12.6581 22.3142C12.5085 22.5293 12.2923 22.6978 12.0095 22.8196C11.7283 22.9399 11.3877 23 10.9878 23C10.5993 23 10.262 22.9415 9.97588 22.8244C9.6914 22.7073 9.46869 22.5365 9.30776 22.3118C9.14845 22.0872 9.06229 21.8135 9.04929 21.4907H9.9393C9.95231 21.66 10.0059 21.8008 10.1002 21.9131C10.1945 22.0255 10.3173 22.1093 10.4684 22.1647C10.6212 22.2201 10.7919 22.2477 10.9805 22.2477C11.1772 22.2477 11.3495 22.2193 11.4974 22.1623C11.647 22.1038 11.764 22.0231 11.8486 21.9203C11.9331 21.8159 11.9762 21.694 11.9778 21.5548C11.9762 21.4283 11.938 21.3238 11.8632 21.2416C11.7884 21.1577 11.6836 21.0881 11.5486 21.0327C11.4153 20.9758 11.2593 20.9252 11.0805 20.8809L10.5123 20.7385C10.101 20.6357 9.77593 20.4798 9.53697 20.271C9.29963 20.0606 9.18096 19.7814 9.18096 19.4333C9.18096 19.147 9.26062 18.8962 9.41992 18.6811C9.58086 18.4659 9.7995 18.299 10.0759 18.1804C10.3522 18.0601 10.6651 18 11.0146 18C11.369 18 11.6795 18.0601 11.9461 18.1804C12.2143 18.299 12.4248 18.4643 12.5776 18.6763C12.7304 18.8867 12.8093 19.1288 12.8142 19.4025H11.9437Z" fill="white" />
            <path d="M14.357 18.0664L15.6567 21.8918H15.7079L17.0051 18.0664H18L16.2395 22.9264H15.1227L13.3646 18.0664H14.357Z" fill="white" />
            <path d="M8.40401 19.7696H7.35737C7.33825 19.635 7.29922 19.5154 7.24028 19.4109C7.18134 19.3048 7.10567 19.2146 7.01327 19.1401C6.92087 19.0657 6.81414 19.0087 6.69307 18.9691C6.57359 18.9295 6.44375 18.9097 6.30356 18.9097C6.05027 18.9097 5.82963 18.9723 5.64165 19.0974C5.45367 19.2209 5.3079 19.4014 5.20435 19.639C5.1008 19.8749 5.04903 20.1615 5.04903 20.4988C5.04903 20.8456 5.1008 21.137 5.20435 21.3729C5.30949 21.6089 5.45606 21.787 5.64404 21.9074C5.83202 22.0277 6.04947 22.0879 6.29639 22.0879C6.43499 22.0879 6.56323 22.0697 6.68112 22.0333C6.8006 21.9968 6.90654 21.9438 6.99893 21.8741C7.09133 21.8029 7.1678 21.7165 7.22833 21.6152C7.29046 21.5139 7.33348 21.3983 7.35737 21.2684L8.40401 21.2732C8.37693 21.4964 8.30922 21.7118 8.2009 21.9192C8.09416 22.1251 7.94999 22.3096 7.76838 22.4727C7.58837 22.6342 7.3733 22.7625 7.12319 22.8575C6.87467 22.9509 6.5935 22.9976 6.27967 22.9976C5.84317 22.9976 5.45287 22.8994 5.10877 22.7031C4.76626 22.5067 4.49544 22.2225 4.29631 21.8504C4.09877 21.4782 4 21.0277 4 20.4988C4 19.9683 4.10036 19.517 4.30109 19.1449C4.50181 18.7728 4.77423 18.4893 5.11833 18.2945C5.46243 18.0982 5.84954 18 6.27967 18C6.56323 18 6.82609 18.0396 7.06823 18.1188C7.31197 18.1979 7.52783 18.3135 7.71581 18.4656C7.90379 18.616 8.05672 18.8005 8.17461 19.019C8.29409 19.2375 8.37056 19.4877 8.40401 19.7696Z" fill="white" />
            <path d="M11.8008 19.4656C11.7817 19.274 11.6997 19.1251 11.5547 19.019C11.4097 18.9129 11.213 18.8599 10.9645 18.8599C10.7956 18.8599 10.653 18.8836 10.5367 18.9311C10.4204 18.977 10.3312 19.0412 10.2691 19.1235C10.2085 19.2059 10.1783 19.2993 10.1783 19.4038C10.1751 19.4909 10.1934 19.5669 10.2332 19.6318C10.2747 19.6968 10.3312 19.753 10.4029 19.8005C10.4746 19.8464 10.5574 19.8868 10.6514 19.9216C10.7454 19.9549 10.8458 19.9834 10.9525 20.0071L11.3922 20.1116C11.6057 20.1591 11.8016 20.2225 11.98 20.3017C12.1585 20.3808 12.313 20.4782 12.4436 20.5938C12.5742 20.7094 12.6754 20.8456 12.7471 21.0024C12.8204 21.1591 12.8578 21.3389 12.8594 21.5416C12.8578 21.8393 12.7813 22.0974 12.63 22.3159C12.4802 22.5329 12.2636 22.7015 11.98 22.8219C11.6981 22.9406 11.3579 23 10.9597 23C10.5646 23 10.2205 22.9398 9.92737 22.8195C9.63584 22.6991 9.40804 22.521 9.24395 22.285C9.08146 22.0475 8.99623 21.7538 8.98827 21.4038H9.9895C10.0007 21.5669 10.0476 21.7031 10.1305 21.8124C10.2149 21.92 10.3272 22.0016 10.4674 22.057C10.6092 22.1108 10.7693 22.1378 10.9477 22.1378C11.123 22.1378 11.2751 22.1124 11.4041 22.0618C11.5348 22.0111 11.6359 21.9406 11.7076 21.8504C11.7793 21.7601 11.8151 21.6564 11.8151 21.5392C11.8151 21.4299 11.7825 21.3381 11.7172 21.2637C11.6535 21.1892 11.5595 21.1259 11.4352 21.0736C11.3125 21.0214 11.162 20.9739 10.9836 20.9311L10.4507 20.7981C10.0381 20.6983 9.71231 20.5424 9.47335 20.3302C9.23439 20.118 9.11571 19.8321 9.1173 19.4727C9.11571 19.1781 9.19457 18.9208 9.35387 18.7007C9.51477 18.4806 9.73541 18.3088 10.0158 18.1853C10.2962 18.0618 10.6148 18 10.9716 18C11.3348 18 11.6519 18.0618 11.9227 18.1853C12.1951 18.3088 12.407 18.4806 12.5583 18.7007C12.7097 18.9208 12.7877 19.1758 12.7925 19.4656H11.8008Z" fill="white" />
            <path d="M14.4395 18.0665L15.6224 21.7625H15.6678L16.853 18.0665H18L16.313 22.9311H14.9796L13.2901 18.0665H14.4395Z" fill="white" />
            <defs>
                <linearGradient id="paint0_linear_1089_2598" x1="21.0513" y1="13.8526" x2="1.28874e-07" y2="13.8526" gradientUnits="userSpaceOnUse">
                    <stop offset="0.572917" stopColor="#00733B" />
                    <stop offset="1" stopColor="#00AA57" />
                </linearGradient>
                <linearGradient id="paint1_linear_1089_2598" x1="18.8105" y1="-9.57049e-08" x2="18.8105" y2="8.52096" gradientUnits="userSpaceOnUse">
                    <stop offset="0.572917" stopColor="#00A253" />
                    <stop offset="1" stopColor="#00BE6E" />
                </linearGradient>
            </defs>
        </svg>
    );
}
