import React from 'react';
import cn from 'classnames';

import colors from '../../store/ColorConstans';
import classes from './Loader.module.scss';

export enum LoaderSizes {
    small = 'la_sm',
    default = '',
    medium = 'la_2x',
    large = 'la_3x',
}

interface Props {
    color?: string;
    size?: LoaderSizes;
    modifier?: string // 'center', '...' is availible now
}

export function Loader({
    color = colors.mockColor,
    size = LoaderSizes.large,
    modifier = 'center',
}: Props): JSX.Element {
    return (
        <div className={cn(modifier && classes[modifier])}>
            <div style={{ color }} className={cn(classes.loader, size && classes[size])}>
                <div />
                <div />
                <div />
                <div />
                <div />
                <div />
                <div />
                <div />
            </div>
        </div>
    );
}
