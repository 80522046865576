import React from 'react';
import { ActionButton } from '@common';
import {
    ScheduleGenerationProcessStatus,
    ScheduleGenerationProcessesSchedulePage_scheduleGenerationProcesses,
} from '../../../graphql-query-types';
import { UploadButton } from '../UploadButton';
import { useGetScheduleGenerationConfigQuery, useImportScheduleGenerationResult } from '../graphql';

import classes from './ScheduleGeneration.module.scss';

const GENERATED_STATUSES: ScheduleGenerationProcessStatus[] = [
    ScheduleGenerationProcessStatus.generated,
    ScheduleGenerationProcessStatus.generationFailed,
];

interface Props {
    process: ScheduleGenerationProcessesSchedulePage_scheduleGenerationProcesses;
}

export const Secret = ({ process }: Props) => {
    const { baseTimeIntervalInstances } = process;
    const BTIInstanceIds = baseTimeIntervalInstances.map(({ id }) => id);
    const {
        downloadConfig,
        loading: configLoading,
    } = useGetScheduleGenerationConfigQuery(BTIInstanceIds);
    const { importResult, called: resultImported } = useImportScheduleGenerationResult(process.id);
    return (
        <div className={classes.additionalGeneration_actions}>
            {!GENERATED_STATUSES.includes(process.status) && (
                <>
                    <ActionButton
                        onClick={downloadConfig}
                        disabled={configLoading}
                        className={classes.button}
                    >
                        Скачать конфиг
                    </ActionButton>
                    <br />
                    {!resultImported && (
                        <UploadButton
                            onLoad={importResult}
                            className={classes.button}
                        >
                            Загрузить результат догенерации на сайт
                        </UploadButton>
                    )}
                </>
            )}
        </div>
    );
};
