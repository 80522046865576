import React from 'react';
import { observer } from 'mobx-react';

import { Accordion } from '@common/Accordion';
import {
    NotVotedAccordionItems,
} from '@admin/EducationPeriodStatistic/EducationPeriodStatisticNotVoted/NotVotedAccordion/notVotedAccordionItems';
import { educationPeriodStatisticStore } from '@admin/EducationPeriodStatistic/EducationPeriodStatisticStore';

import classes from './NotVotedAccordion.module.scss';

export const NotVotedAccordion = observer((): JSX.Element => {
    const amountOfNotVotedStudents = (
        (educationPeriodStatisticStore.notVotedStudentsVotes
            / educationPeriodStatisticStore.allStudents.length) * 100
    ).toFixed(1);
    return (
        <div className={classes.notVotedAccordion}>
            <Accordion
                headerClassnames={classes.accordion__header}
                shouldClickOnHeaderOpen
                isDefaultOpen
            >
                <div className={classes.notVotedAccordion__header}>
                    {
                        +amountOfNotVotedStudents > 0 ? (
                            <>
                                {amountOfNotVotedStudents}
                                % не проголосовали
                            </>
                        ) : (
                            'все студенты проголосовали'
                        )
                    }
                </div>
                <div className={classes.notVotedAccordion__children}>
                    <NotVotedAccordionItems
                        studentsWithRating={educationPeriodStatisticStore.notVotedStudents}
                    />
                </div>
            </Accordion>
        </div>
    );
});
