import { gql } from '@apollo/client';

export const GET_BTI_BY_IDS = gql`
    query GetBTIByIds($ids: [String!]!){
        baseTimeIntervalInstancesByIds(ids: $ids) {
            longName
            startDate
            endDate
        }
    }
`;

export const GET_VALIDATE_SCHEDULE = gql`
    query ValidateScheduleSchedulePage($validateScheduleInput: ValidateScheduleInput!) {
        validateSchedule(validateScheduleInput: $validateScheduleInput) {
            errors {
                message
                subject
                errorTypeFilter
                object {
                    moduleId
                    moduleName
                    meetingId
                    meetingName
                    module2Id
                    module2Name
                    meeting2Id
                    meeting2Name
                    studentId
                    teacherId
                    teacherName
                    teacherRoleId
                    teacherRoleName
                    equipmentCategoryAndFeaturesNames
                    meetingFormatId
                    meetingFormatName
                    btiInstanceId
                    btiInstanceName
                }
            }
            warnings {
                message
                subject
                errorTypeFilter
                object {
                    moduleId
                    moduleName
                    meetingId
                    meetingName
                    module2Id
                    module2Name
                    meeting2Id
                    meeting2Name
                    studentId
                    teacherId
                    teacherName
                    teacherRoleId
                    teacherRoleName
                    equipmentCategoryAndFeaturesNames
                    meetingFormatId
                    meetingFormatName
                    btiInstanceId
                    btiInstanceName
                }
            }
        }
    }
`;

export const GET_PAGES_COUNT = gql`
    query GetSchedulePagesCountSchedulePage($scheduleFilterInput: ScheduleFilterInput!) {
        countOfPageForFilteredSchedule(scheduleFilterInput: $scheduleFilterInput)
    }
`;

export const GET_FILTERED_SCHEDULE = gql`
    query FilteredScheduleSchedulePage($scheduleFilterInput: ScheduleFilterInput!) {
        filteredSchedule(scheduleFilterInput: $scheduleFilterInput) {
            status
            events {
                id
                type
                pinned
                startTime
                endTime
                status
                module {
                    id
                    name
                }
                meeting {
                    id
                    topic
                    format {
                        name
                    }
                    duration {
                        academicHours
                    }
                }
                assignment {
                    id
                    topic
                }
                evaluationPoint {
                    id
                    topic
                }
                room {
                    id
                    title
                    location {
                        location
                    }
                    equipmentToRoom {
                        equipmentId
                        roomId
                        countEquipments
                        equipment {
                            id
                            category {
                                name
                            }
                        }
                    }
                }
                roomType
                onlineRoom {
                    id
                    url
                }
                students {
                    id
                    user {
                        id
                        lastName
                        firstName
                        patronymic
                    }
                }
                teachers {
                    id
                    user {
                        id
                        lastName
                        firstName
                        patronymic
                    }
                }
                waves {
                    id
                    index
                }
                startTime
                endTime
                scheduleGenerationProcessId
            }
        }
    }
`;

export const GET_FILTERED_SCHEDULE_FOR_TABLE_VIEW = gql`
    query FilteredTableScheduleSchedulePage($scheduleFilterInput: ScheduleFilterInput!) {
        filteredSchedule(scheduleFilterInput: $scheduleFilterInput) {
            events {
                id
                type
                pinned
                status
                startTime
                endTime
                module {
                    id
                    name
                }
                meeting {
                    id
                    topic
                    format {
                        name
                    }
                    duration {
                        academicHours
                    }
                }
                waves {
                    id
                    index
                }
                scheduleGenerationProcessId
            }
        }
    }
`;

export const GET_AVAILABLE_TEACHERS = gql`
    query GetAvailableTeachersSchedulePage($availableTeacherInput: AvailableTeacherInput!) {
        availableTeachers(availableTeacherInput: $availableTeacherInput) {
            id
            status
            user {
                id
                lastName
                firstName
                patronymic
                teacherId
                studentId
                status
            }
        }
    }
`;

export const GET_DURATION_LIST = gql`
query GetDurationsSchedulePage {
    durations {
        id
        name
        academicHours
    }
}
`;

export const GET_STUDENTS_ATTENDANCE = gql`
    query StudentsAttendanceSchedulePage($input: AttendanceInput!) {
        attendanceReport(input: $input)
    }
`;

export const GET_AVAILABLE_ROOMS = gql`
    query GetAvailableRoomsSchedulePage($availableRoomInput: AvailableRoomInput!) {
        availableRooms(availableRoomInput: $availableRoomInput) {
            id
            title
            capacity
            location {
                location
            }
        }
    }
`;

export const GET_AVAILABLE_ONLINE_ROOMS = gql`
    query GetAvailableOnlineRoomsSchedulePage($input: AvailableOnlineRoomInput!) {
        availableOnlineRooms(availableOnlineRoomInput: $input) {
            id
            url
        }
    }
`;

export const CHECK_SCHEDULE = gql`
    mutation CheckScheduleSchedulePage($scheduleScoreInput: CheckScheduleConflictsInput!) {
        checkScheduleConflicts(input: $scheduleScoreInput) {
            fromDate
            toDate
            isSuccess
            baseTimeIntervalInstancesForProcess {
                id
                longName
            }
            meetingViolations {
                violationName
                conflicts {
                    baseTimeIntervalInstancesIds
                    meetingInstances {
                    id
                    meeting {
                        topic
                        format {
                            name
                        }
                        module {
                            name
                        }
                    }
                    meetingInstanceToTeachers {
                        teacher {
                            user {
                              firstName
                              lastName
                              patronymic
                            }
                        }
                    }
                    room {
                        id
                        title
                    }
                    onlineRoom {
                        id
                        url
                    }
                    roomType
                    waves {
                        index
                    }
                    startDate
                    endDate
                },
                }
            },
            studentMeetingLimitDurationViolation {
                violationName
                studentDurationLimitsByDate {
                    date
                    studentDurationLimits {
                        baseTimeIntervalInstanceId
                        extraDurationMinutes
                        student {
                            id
                            user {
                                id
                                lastName
                                firstName
                                patronymic
                            }
                        }  
                    }
                }
            },
            studentBreakLimitDurationViolation {
                violationName
                studentDurationLimitsByDate {
                    date
                    studentDurationLimits {
                        baseTimeIntervalInstanceId
                        extraDurationMinutes,
                        student {
                            id
                            user {
                                id
                                lastName
                                firstName
                                patronymic
                            }
                        }  
                    }
                }
            }
            equipmentShortageViolation {
                violationName
                equipmentShortagesByDate {
                    date
                    startTime
                    equipmentShortages {
                        count
                        equipment {
                            id
                            category {
                                name
                            }
                        }
                    }
                }
            }
            teacherWeekMeetingsDurationLimitViolations {
                violationName
                    conflictsByWeek {
                        week
                        conflicts {
                            extraDurationMinutes
                            teacher {
                                id
                                user {
                                    id
                                    firstName
                                    lastName
                                    patronymic
                                }
                        }
                    }
                }
            }
        }
    }
`;

export const UPDATE_MEETING_TEACHER = gql`
    mutation UpdateTeacherSchedulePage($updateMeetingInstanceTeacherInput: UpdateMeetingInstanceTeacherInput!) {
        updateMeetingInstanceTeacher(updateMeetingInstanceTeacherInput: $updateMeetingInstanceTeacherInput) {
            id
        }
    }
`;

export const UPDATE_MEETING_ROOM = gql`
    mutation UpdateMeetingInstanceRoomSchedulePage($updateMeetingInstanceRoomInput: UpdateMeetingInstanceRoomInput!) {
        updateMeetingInstanceRoom(updateMeetingInstanceRoomInput: $updateMeetingInstanceRoomInput) {
            id
        }
    }
`;

export const UPDATE_DURATION = gql`
    mutation UpdateMeetingInstanceDurationSchedulePage($updateMeetingInstanceDurationInput: UpdateMeetingInstanceDurationInput) {
        updateMeetingInstanceDuration(updateMeetingInstanceDurationInput: $updateMeetingInstanceDurationInput) {
            id
            startDate
            endDate
        }
    }
`;

export const GET_CONFIG_FOR_PROCES = gql`
    query ScheduleGenerationConfigForProcessSchedulePage($scheduleGenerationConfigForProcessInput: ScheduleGenerationConfigForProcessInput!) {
        scheduleGenerationConfigForProcess(scheduleGenerationConfigForProcessInput: $scheduleGenerationConfigForProcessInput)
    }
`;

export const GET_CONFIG_FOR_BTI = gql`
    query GetScheduleGenerationConfigSchedulePage($baseTimeIntervalInstanceIds: [String!]!) {
        getScheduleGenerationConfig(baseTimeIntervalInstanceIds: $baseTimeIntervalInstanceIds)
    }
`;

export const REPLACE_MEETING = gql`
    mutation ReplaceMeetingSchedulePage($replaceMeetingInput: ReplaceMeetingInput) {
        replaceMeeting(replaceMeetingInput: $replaceMeetingInput) {
            id
        }
    }
`;

export const UPDATE_MEETING_DATE = gql`
mutation UpdateMeetingInstanceDateSchedulePage($updateMeetingInstanceDateInput: UpdateMeetingInstanceDateInput!) {
    updateMeetingInstanceDate(updateMeetingInstanceDateInput: $updateMeetingInstanceDateInput) {
        id
        startDate
    }
}
`;

export const UPDATE_MEETING_WAVES = gql`
mutation UpdateMeetingInstanceWavesSchedulePage($updateMeetingInstanceWavesInput: UpdateMeetingInstanceWavesInput!) {
    updateMeetingInstanceWaves(updateMeetingInstanceWavesInput: $updateMeetingInstanceWavesInput) {
        id
    }
}
`;

export const UPDATE_MEETING_INSTANCE_STATUS = gql`
mutation ChangeMeetingInstanceStatusSchedulePage($changeMeetingInstanceStatusInput: ChangeMeetingInstanceStatusInput!) {
    changeMeetingInstanceStatus(changeMeetingInstanceStatusInput: $changeMeetingInstanceStatusInput) {
        id
    }
}
`;

export const UPDATE_ASSIGNMENT_INSTANCE = gql`
    mutation UpdateAssignmentInstanceSchedulePage($updateAssignmentInstanceInput: UpdateAssignmentInstanceInput!) {
        updateAssignmentInstance(updateAssignmentInstanceInput: $updateAssignmentInstanceInput) {
            id
        }
    }
`;

export const PIN_MEETING_INSTANCE = gql`
    mutation PinMeetingInstanceSchedulePage($id: String!, $pin: Boolean!) {
        pinMeetingInstance(id: $id, pin: $pin) {
            id
        }
    }
`;

export const UPDATE_EVALUATION_POINT_INSTANCE = gql`
    mutation UpdateEvaluationPointInstanceSchedulePage($updateEvaluationPointInstanceInput: UpdateEvaluationPointInstanceInput!) {
        updateEvaluationPointInstance(updateEvaluationPointInstanceInput: $updateEvaluationPointInstanceInput) {
            id
        }
    }
`;

export const CHANGE_ASSIGNMENT_INSTANCE_STATUS = gql`
    mutation ChangeAssignmentInstanceStatusSchedulePage($changeAssignmentInstanceStatusInput: ChangeAssignmentInstanceStatusInput!) {
        changeAssignmentInstanceStatus(changeAssignmentInstanceStatusInput: $changeAssignmentInstanceStatusInput) {
            id
        }
    }
`;
export const CHANGE_EVALUATION_POINT_INSTANCE_STATUS = gql`
    mutation ChangeEvaluationPointInstanceStatusSchedulePage($changeEvaluationPointInstanceStatusInput: ChangeEvaluationPointInstanceStatusInput!) {
        changeEvaluationPointInstanceStatus(changeEvaluationPointInstanceStatusInput: $changeEvaluationPointInstanceStatusInput) {
            id
        }
    }
`;

export const GET_EVENT_DATA = gql`
    query GetEventDataSchedulePage($tmpMeetingInstanceInput: TMPMeetingInstanceInput!) {
        TMPMeetingInstanceExcludingStatus(tmpMeetingInstanceInput: $tmpMeetingInstanceInput) {
            id
            type
            pinned
            status
            module {
                id
                name
            }
            waves {
                id
                index
                baseTimeIntervalInstances {
                    id
                }
            }
            room {
                id
                title
                location {
                    location
                }
            }
            roomType
            onlineRoom {
                id
                url
            }
            students {
                id
                user {
                    id
                    lastName
                    firstName
                    patronymic
                }
            }
            teachers {
                id
                user {
                    id
                    firstName
                    lastName
                    patronymic
                }
            }
            teacherToTeacherRoles {
                teacher {
                    id
                }
                teacherRole {
                    id
                }
            }
            startTime
            endTime
            meeting {
                id
                topic
                format {
                    name
                }
                module {
                    waves {
                        id
                        index
                    }
                }
                duration {
                    academicHours
                }
                equipmentPerMeeting {
                    id
                    count
                }
                equipmentPerStudent {
                    id
                    count
                }
                meetingInstances {
                    id
                    startDate
                    endDate
                    meetingInstanceToStudents {
                        student {
                            id
                            user {
                                id
                                lastName
                                firstName
                                patronymic
                            }
                        }
                    }
                }
            }
            assignment {
                id
                topic
            }
            evaluationPoint {
                id
                topic
            }
        }
    }
`;

export const BTI_SPACE_EDUCATION_PERIOD = gql`
    query GetBaseTimeIntervalInstanceSchedulePage($id: String!) {
        baseTimeIntervalInstance(id: $id) {
            startDate
            endDate
        }
    }
`;

export const BASE_TIME_INTERVAL_INSTANCES = gql`
    query GetBaseTimeIntervalInstancesSchedulePage($input: BaseTimeIntervalInstancesInput) {
        baseTimeIntervalInstances(input: $input) {
            id
            longName
        }
    }
`;

export const SCHEDULE_GENERATION_PROCESSES = gql`
    query ScheduleGenerationProcessesSchedulePage($baseTimeIntervalInstanceIds: [String!]!) {
        scheduleGenerationProcesses(baseTimeIntervalInstanceIds: $baseTimeIntervalInstanceIds) {
            id
            name
            from
            to
            status
            baseTimeIntervalInstances {
                id
            }
            constraintMatch {
                constraintMatchCountList {
                    constraintName
                    scoreType
                    matchCount
                }
            }
        }
    }
`;

export const SCHEDULE_GENERATION_SETTINGS = gql`
    query ScheduleGenerationSettingsSchedulePage {
        scheduleGenerationSettings {
            teacherMaxWeekWorkloadMinutes
        }
    }
`;
