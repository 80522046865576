import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';

import { AbsenceIcon } from '@admin/Users/UserCard/UserCommonInfo/UserAbsences/AbsenceIcon';

import { AbsencesAccordion } from '@admin/Users/UserCard/UserCommonInfo/UserAbsences/AbsencesAccordion';
import { usersStore } from '@admin/Users/UsersStore';
import { ApolloPromise, BaseTimeIntervalInstanceWithExcusedAbsences } from '@admin/Users/UsersInterfaces';
import {
    UpdateStudentExcusedAbsencesVariables,
    UserWithAbsences_user_student_excusedAbsences
    as ExcusedAbsence,
} from '@admin/Users/UserCard/UserCommonInfo/UserAbsences/graphql-types';
import { flatMap } from 'lodash';

import { Alert } from '@common';
import classes from './UserAbsences.module.scss';

interface Props {
    updateStudentAbsences(
        input: UpdateStudentExcusedAbsencesVariables,
    ): ApolloPromise;
}

export const UserAbsences = observer((
    {
        updateStudentAbsences,
    }: Props,
): JSX.Element => {
    const [updateErrors, setUpdateErrors] = useState<JSX.Element[]>([]);
    const userWithAbsences = usersStore.getUserWithAbsences();
    const studentId = userWithAbsences?.user.student?.id ?? '';

    const baseTimeIntervalTypeName = userWithAbsences?.user.student?.space?.baseTimeIntervalType.name ?? 'семестр';
    const absencesAccordionsList = usersStore.getBaseTimeIntervalInstancesWithExcusedAbsences().map(
        ({ baseTimeIntervalInstance, excusedAbsences }) => (
            <AbsencesAccordion
                key={baseTimeIntervalInstance.id}
                baseTimeIntervalTypeName={baseTimeIntervalTypeName}
                baseTimeIntervalInstance={baseTimeIntervalInstance}
                excusedAbsences={excusedAbsences}
            />
        ),
    );

    const addUpdateError = (message: string) => {
        setUpdateErrors(
            (prevState) => [...prevState, <Alert message={message} time={5000} />],
        );
    };

    useEffect(() => {
        if (!usersStore.getIsAbsenceCreating() && usersStore.getAreAbsencesReadyToEdit()) {
            const excusedAbsences = getExcusedAbsencesFromGroupedAbsences(
                usersStore.getBaseTimeIntervalInstancesWithExcusedAbsences(),
            );
            updateStudentAbsences(
                {
                    input: {
                        studentId,
                        excusedAbsences,
                    },
                },
            )
                .catch((error) => {
                    addUpdateError(error.message);
                });
        }
    }, [
        usersStore.getBaseTimeIntervalInstancesWithExcusedAbsences(),
    ]);

    return (
        <div className={classes.userAbsences}>

            <div className={classes.userAbsences__title}>

                <AbsenceIcon />

                <span className={classes.userAbsences__title_text}>
                    Пропуски по болезни / ув. причины
                </span>

            </div>

            <div className={classes.userAbsences__accordions}>

                {
                    absencesAccordionsList
                }

            </div>

            {
                updateErrors
            }

        </div>
    );
});

function getExcusedAbsencesFromGroupedAbsences(
    groupedAbsences: BaseTimeIntervalInstanceWithExcusedAbsences[],
): Omit<ExcusedAbsence, '__typename'>[] {
    const excusedAbsences: Omit<ExcusedAbsence, '__typename'>[] = flatMap(
        groupedAbsences,
        (group) => group.excusedAbsences.map(
            ({ startDate, endDate, id }) => (
                {
                    id,
                    startDate,
                    endDate,
                }
            ),
        ),
    );

    return excusedAbsences;
}
