interface WordForms {
    one: string,
    oneTwoFive: string,
    tenToTwenty: string,
}

export function UseGetWordEnding(
    amount: number,
    wordForms: WordForms,
) {
    const n = Math.abs(amount) % 100;
    const n1 = n % 10;
    if (n > 10 && n < 20) { return wordForms.tenToTwenty; }
    if (n1 > 1 && n1 < 5) { return wordForms.oneTwoFive; }
    if (n1 === 1) { return wordForms.one; }
    return wordForms.tenToTwenty;
}
