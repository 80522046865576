import React from 'react';
import { nanoid } from 'nanoid';
import { ExtraWorkday, Holiday } from './UniversitySpecialDaysInterfaces';
import { UniversitySpecialDaysItem } from './UniversitySpecialDaysItem';
import classes from './UniversitySpecialDaysList.module.scss';

interface Props {
    dates: ExtraWorkday[]|Holiday[],
    deleteHandler?: (date: ExtraWorkday|Holiday)=>void,
}

type GetMonthRowsFunction = {
    (
        monthes: string[],
        dates: ExtraWorkday[]|Holiday[],
        deleteHandler?: (date: ExtraWorkday|Holiday) => void,
    ): (JSX.Element|null)[];
};
type GetRequiredDates = {
    (
        dates: ExtraWorkday[]|Holiday[],
        index: number,
    ): ExtraWorkday[]|Holiday[];
};

const MONTHES = [
    'январь',
    'февраль',
    'март',
    'апрель',
    'май',
    'июнь',
    'июль',
    'август',
    'сентябрь',
    'октябрь',
    'ноябрь',
    'декабрь',
];

const getRequiredDates: GetRequiredDates = (
    dates,
    index,
) => dates.filter(
    (date: ExtraWorkday|Holiday) => new Date(date.date).getMonth() === index,
);

const getMonthRows: GetMonthRowsFunction = (
    monthes,
    dates,
    deleteHandler,
) => monthes.map(
    (month: string, index:number) => {
        const requiredDates = getRequiredDates(dates, index);

        return requiredDates.length ? (
            <UniversitySpecialDaysItem
                month={month}
                dates={requiredDates}
                deleteHandler={deleteHandler}
                key={nanoid()}
            />
        ) : null;
    },
);

function isEmpty(arr:(JSX.Element|null)[]) {
    return !!(arr?.find((element) => element !== null));
}

export function UniversitySpecialDaysList(
    { dates, deleteHandler }: Props,
) {
    const monthRows = getMonthRows(MONTHES, dates, deleteHandler);

    return (
        <div>
            {isEmpty(monthRows) ? monthRows : (
                <div className={classes.universitySpecialDaysList__text}>Не задано</div>
            )}
        </div>
    );
}
