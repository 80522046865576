import { useQuery } from '@apollo/client';
import { GET_SUBSPACE_TYPES } from '@common/qraphql/queries';
import { SubspaceType } from '../types';

export function useSubspaceTypesQuery(spaceId: string): SubspaceType[] | undefined {
    const { data, loading } = useQuery(GET_SUBSPACE_TYPES, {
        fetchPolicy: 'no-cache',
        variables: { subspaceTypesInput: { spaceId } },
    });
    return loading ? [] : data.subspaceTypes;
}
