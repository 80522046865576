import React from 'react';

import classes from './EquipmentCard.module.scss';
import { EquipmentCardItem } from './EquipmentCardItem';

interface Equipment {
    id: string;
    name: string;
    unit: string;
}

interface Props {
    cardEquipments: [string, Equipment[]];
    editState: boolean;
    removeConsumableEquipment: (id: string) => void;
    updateConsumableEquipment: (
        id: string,
        name: string,
        unit: string,
    ) => void;
}

export function EquipmentCard({
    cardEquipments,
    editState,
    removeConsumableEquipment,
    updateConsumableEquipment,
}: Props): JSX.Element {
    const sortedEquipments = cardEquipments[1].sort((a, b) => (a.name > b.name ? 1 : -1));
    return (
        <div className={classes.equipmentCard}>
            <div className={classes.equipmentCard__content}>
                <div className={classes.equipmentCard__letter}>
                    {cardEquipments[0]}
                </div>
                <ul className={classes.equipmentCard__list}>
                    {sortedEquipments.map((item: Equipment) => (
                        <li className={classes.equipmentCard__item} key={item.id}>
                            <EquipmentCardItem
                                item={item}
                                editState={editState}
                                removeConsumableEquipment={removeConsumableEquipment}
                                updateConsumableEquipment={updateConsumableEquipment}
                            />
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
}
