import React, { useEffect, useState } from 'react';
import { gql, useMutation, useQuery } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import { nanoid } from 'nanoid';

import { Loader, LoaderSizes } from '@common/Loader';
import { ActionButton, ActionTypeOfButton } from '@common/ActionButton';
import { Alert } from '@common/Alert';
import { ArrowSvg } from '@admin/ScheduleGenerationPage/FiltrationComponents/ArrowSvg';

import {
    getExternalModule,
    getExternalModuleVariables,
    ExternalModuleStatus,
    updateExternalModuleStatusVariables,
    updateExternalModuleStatus,
} from '../apollo-types';
import { ExternalModuleForm } from '../../../../common/ExternalModuleForm/ExternalModuleForm';
import { removeNulls } from '../../../../ExternalModule/helpers';
import classes from './ExternalModule.module.scss';
import { useBasePathAndParams } from './useBasePathAndParams';

export const GET_EXTERNAL_MODULE = gql`
    query getExternalModule($externalModuleInput: ExternalModuleInput!) {
        externalModule(externalModuleInput: $externalModuleInput) {
            id
            status
            ownerFullName
            ownerEmail
            ownerInfo
            name
            description
            minStudentCount
            maxStudentCount
            maxWaveCount
            creditCount
            syllabusReference
            invitationId
        }
    }
`;

const UPDATE_EXTERNAL_MODULE_STATUS = gql`
    mutation updateExternalModuleStatus($updateExternalModuleStatusInput: UpdateExternalModuleStatusInput!) {
        updateExternalModuleStatus(updateExternalModuleStatusInput: $updateExternalModuleStatusInput) {
            id
            status
        }
    }
`;

interface RouteParams {
    externalModuleId: string;
}

export function ExternalModuleApollo() {
    const history = useHistory();
    const { basePath, params: { externalModuleId } } = useBasePathAndParams<RouteParams>();
    const [alerts, setAlerts] = useState<JSX.Element[]>([]);

    const {
        data: getExternalModuleData,
        loading: getExternalModuleLoading,
        error: getExternalModuleError,
    } = useQuery<getExternalModule, getExternalModuleVariables>(
        GET_EXTERNAL_MODULE,
        {
            variables: { externalModuleInput: { externalModuleId } },
            fetchPolicy: 'network-only',
        },
    );

    const [
        updateStatus,
        {
            data: updateStatusData,
            error: updateStatusError,
            loading: updateStatusLoading,
        },
    ] = useMutation<updateExternalModuleStatus, updateExternalModuleStatusVariables>(
        UPDATE_EXTERNAL_MODULE_STATUS,
    );

    useEffect(() => {
        if (updateStatusError) {
            const message = updateStatusError.graphQLErrors[0]?.message
                ?? updateStatusError.message;
            setAlerts((arr) => [
                ...arr,
                <Alert
                    key={nanoid()}
                    message={`Произошла ошибка. Не удалось сохранить модуль. ${message}`}
                    time={6000}
                />,
            ]);
        }
    }, [updateStatusError]);

    if (getExternalModuleError) {
        return (
            <div>Произошла ошибка:{' '}
                {getExternalModuleError.graphQLErrors[0]?.message || getExternalModuleError.message}
            </div>
        );
    }

    if (getExternalModuleLoading) {
        return <Loader />;
    }

    function onExternalModuleStatusUpdate(status: ExternalModuleStatus) {
        updateStatus({
            variables: {
                updateExternalModuleStatusInput: {
                    status, externalModuleId,
                },
            },
        });
    }

    function handleGoBack() {
        history.push(basePath);
    }

    const { externalModule } = getExternalModuleData!;

    const status = updateStatusData?.updateExternalModuleStatus.status
        ?? externalModule.status;

    return (
        <div>
            <div className={classes['external-module__back']} onClick={handleGoBack}>
                <ArrowSvg />
                <p className={classes['external-module__back-title']}>
                    К списку модулей
                </p>
            </div>
            <ExternalModuleForm
                externalModule={{
                    ...removeNulls(getExternalModuleData?.externalModule),
                    status,
                }}
                isEditModeDisabled
                isCurrentUserAvailbleToAddReview={status === ExternalModuleStatus.verification}
            >
                {status === ExternalModuleStatus.verification && (
                    <div className={classes['external-module__action-panel']}>
                        <ActionButton
                            actionType={ActionTypeOfButton.SECONDARY}
                            onClick={
                                () => onExternalModuleStatusUpdate(ExternalModuleStatus.revision)
                            }
                            disabled={updateStatusLoading}
                        >
                            Отправить на доработку
                        </ActionButton>
                        <ActionButton
                            actionType={ActionTypeOfButton.PRIMARY}
                            onClick={
                                () => onExternalModuleStatusUpdate(ExternalModuleStatus.approved)
                            }
                            disabled={updateStatusLoading}
                        >
                            Одобрить
                        </ActionButton>
                        {updateStatusLoading && (
                            <div className={classes['external-module__action-panel-loader']}>
                                <Loader size={LoaderSizes.default} />
                            </div>
                        )}
                    </div>
                )}
            </ExternalModuleForm>
            {alerts}
        </div>
    );
}
