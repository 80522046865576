import React, { ReactNode } from 'react';

import classes from './CheckboxRedesign.module.scss';

interface Props {
    id: string;
    children: ReactNode;
    checked?: boolean;
    gap?: string;
    handleChangeCheckbox?: () => void;
}

export function CheckboxRedesign(
    {
        id,
        children,
        checked,
        gap,
        handleChangeCheckbox,
    } : Props,
) {
    return (
        <label
            className={classes.checkbox}
            style={{
                gap: gap ?? '8px',
            }}
            htmlFor={id}
        >
            <input
                className={classes.checkbox__input}
                type="checkbox"
                id={id}
                name="checkbox"
                checked={checked}
                onChange={handleChangeCheckbox}
            />
            <div className={classes.checkbox__checkmark}>
                <div className={classes.checkbox__checkmark_inside} />
            </div>
            { children }
        </label>
    );
}
