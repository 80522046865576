import React from 'react';

import { gql, useMutation, useQuery } from '@apollo/client';

import { Alert } from '@common/Alert';

import { Formats } from './Formats';

const GET_MEETING_FORMATS = gql`
    query meetingFormats {
        meetingFormats {
            id
            name
        }
    }
`;

const CREATE_MEETING_FORMAT = gql`
    mutation createMeetingFormat(
        $id: String!
        $name: String
    ) {
        createMeetingFormat(
            createMeetingFormatInput: {
                id: $id
                name: $name
            }
        ) {
            id
        }
    }
`;

const UPDATE_MEETING_FORMAT = gql`
    mutation updateMeetingFormat(
        $id: String!
        $name: String
    ) {
        updateMeetingFormat(
            updateMeetingFormatInput: {
                id: $id
                name: $name
            }
        ) {
            id
        }
    }
`;

const DELETE_MEETING_FORMAT = gql`
    mutation deleteMeetingFormat(
        $id: String!
    ) {
        deleteMeetingFormat(id: $id) {
            result
        }
    }
`;

export function FormatsApollo():JSX.Element {
    const { data, loading, error } = useQuery(GET_MEETING_FORMATS, {
        fetchPolicy: 'cache-and-network',
    });

    const [createMeetingFormat, { error: createError }] = useMutation(CREATE_MEETING_FORMAT, {
        refetchQueries: [{ query: GET_MEETING_FORMATS }],
    });

    const [updateMeetingFormat, { error: updateError }] = useMutation(UPDATE_MEETING_FORMAT, {
        refetchQueries: [{ query: GET_MEETING_FORMATS }],
    });

    const [deleteMeetingFormat, { error: deleteError }] = useMutation(DELETE_MEETING_FORMAT, {
        refetchQueries: [{ query: GET_MEETING_FORMATS }],
    });

    if (loading) return <></>;
    if (error) return <>`Error! ${error.message}`</>;

    return (
        <>
            <Formats
                meetingFormats={data.meetingFormats}
                createMeetingFormat={(
                    id,
                    name,
                ) => createMeetingFormat({
                    variables: {
                        id,
                        name,
                    },
                }).catch(() => { })}
                updateMeetingFormat={(
                    id,
                    name,
                ) => updateMeetingFormat({
                    variables: {
                        id,
                        name,
                    },
                }).catch(() => { })}
                deleteMeetingFormat={(id) => deleteMeetingFormat({
                    variables: {
                        id,
                    },
                }).catch(() => { })}
            />
            {(createError || deleteError || updateError) && (
                <Alert
                    message={
                        createError?.message
                        ?? deleteError?.message
                        ?? updateError?.message
                    }
                    time={3000}
                />
            )}
        </>
    );
}
