import React from 'react';

import { Alert } from '@common/Alert';
import {
    UpdateEquipmentEquipmentPage_updateEquipment,
    UpdateEquipmentInput,
} from 'src/deprecated-graphql-query-types';
import { EquipmentPage } from './EquipmentPage';
import {
    useTerritorialZonesQuery,
    useUpdateEquipmentMutation,
} from './graphqlHooks';

export const EquipmentPageApollo = () => {
    const {
        updateEquipment,
        error: errorUpdateEquipment,
    } = useUpdateEquipmentMutation();

    const {
        data: territorialZonesData,
        error: territorialZonesError,
    } = useTerritorialZonesQuery();

    function updateEquipmentHandler(
        input: UpdateEquipmentInput,
        callBack: (params: UpdateEquipmentEquipmentPage_updateEquipment,
        ) => void,
    ) {
        updateEquipment(input).then(({ data: request }) => callBack(request!.updateEquipment));
    }

    const errors = territorialZonesError
        || errorUpdateEquipment;

    const territorialZones = territorialZonesData?.territorialZones;

    return (
        <>
            {errors && <Alert message={errors.message} />}
            {territorialZones && (
                <EquipmentPage
                    territorialZones={territorialZones}
                    updateEquipmentHandler={updateEquipmentHandler}
                />
            )}
        </>
    );
};
