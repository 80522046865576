import React, { FormEvent, ReactChild } from 'react';
import { ContextMenuToolButton } from './ContextMenuToolButton';
import { SimpleToolButton } from './SimpleToolButton';

interface Props {
    contextMenu?: ReactChild;
    text?: string;
    icon: string;
    disable?: boolean;
    modifier?: string;
    isLoader?: boolean;
    onClick?: () => void;
    onSubmit?(event: FormEvent<HTMLFormElement>): void;
    handlerLoad?(event: React.ChangeEvent<HTMLInputElement>): void;
}
export const ToolButton = ({
    contextMenu,
    text,
    icon,
    disable,
    modifier,
    isLoader,
    onClick,
    onSubmit,
    handlerLoad,
}: Props): JSX.Element => (
    <>
        {contextMenu
            ? (
                <ContextMenuToolButton
                    text={text}
                    icon={icon}
                    contextMenu={contextMenu}
                    modifier={modifier}
                    isLoader={isLoader}
                    onSubmit={onSubmit}
                    handlerLoad={handlerLoad}
                />
            )
            : (
                <SimpleToolButton
                    text={text}
                    icon={icon}
                    onClick={onClick}
                    disable={disable}
                    modifier={modifier}
                />
            )
        }
    </>
);
