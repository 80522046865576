import React from 'react';

import cn from 'classnames';
import classes from './Suggestion.module.scss';

interface Props {
    array: string[];
    addedElementArray: string[];
    stateValue: string;
    setState: (value: string) => void;
    placeholder?: string;
    cnInput?: string;
    cnListContainer?: string;
    cnSuggestionElem?: string;
    forMany?: number | string;
}

export function Suggestion({
    array,
    addedElementArray,
    stateValue,
    setState,
    placeholder,
    cnInput,
    cnListContainer,
    cnSuggestionElem,
    forMany,
}: Props): JSX.Element {
    function isMatching(full: string, chunk: string) {
        return full.toLowerCase().includes(chunk.toLowerCase());
    }
    const sortedArray = array.slice().sort((a: string, b: string) => (
        a.toLowerCase() > b.toLowerCase() ? 1 : -1
    ));
    const updateFilter = (filterValue: any) => {
        const newArray: string[] = [];
        sortedArray.forEach((item: string) => {
            if (!addedElementArray.includes(item)) {
                newArray.push(item);
            }
        });

        const suggestionDiv = document.getElementById(`suggestionDiv${forMany}`);
        if (suggestionDiv) suggestionDiv.innerHTML = '';
        const ul = document.createElement('ul');

        newArray.forEach((item: string) => {
            if ((filterValue && isMatching(item, filterValue)) || filterValue === '') {
                const li = document.createElement('li');
                li.classList.add(classes.suggestion__item);
                if (cnSuggestionElem) li.classList.add(`${cnSuggestionElem}`);
                li.textContent = item;
                li.onclick = () => {
                    setState(item);
                    if (suggestionDiv) suggestionDiv.innerHTML = '';
                };
                ul.append(li);
            }
        });
        suggestionDiv?.append(ul);
    };
    const changeValue = ({ currentTarget }: React.ChangeEvent<HTMLInputElement>) => {
        setState(currentTarget.value);
    };
    return (
        <div className={classes.suggestion}>
            <input
                value={stateValue}
                onChange={changeValue}
                onInput={(e: any) => (e.currentTarget.value
                    ? updateFilter(e.currentTarget.value)
                    : updateFilter('')
                )}
                onFocus={(e: any) => (e.currentTarget.value
                    ? updateFilter(e.currentTarget.value)
                    : updateFilter('')
                )}
                onBlur={() => setTimeout(() => updateFilter('{`~/.,]'), 150)}
                className={cn(classes.suggestion__input, {
                    [`${cnInput}`]: cnInput,
                })}
                placeholder={placeholder}
            />
            <div
                id={`suggestionDiv${forMany}`}
                className={cn(classes.suggestion__listContainer, {
                    [`${cnListContainer}`]: cnListContainer,
                })}
            />
        </div>
    );
}
