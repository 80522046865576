import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { differenceBy } from 'lodash';

import { Accordion } from '@common/Accordion';

import { NotDistributedItem } from '@admin/ModuleSelectionPage/NotDistribStudentsAccordion/NotDistribItem';

import {
    checkIfCanSortByRating,
    getSortedStudents,
    resetStudentsWithAdditionalFields,
} from '@admin/ModuleSelectionPage/ModuleSelectionPage';
import {
    ApolloPromise,
    ModuleGroupWithAdditionalFields, SortTypes,
    StudentWithAdditionalFields,
} from '@admin/ModuleSelectionPage/Interfaces';

import { DeleteStudentModuleInput } from '@admin/ModuleSelectionPage/graphql-types';
import { moduleSelectionStore } from '@admin/ModuleSelectionPage/ModuleSelectionStore';
import cn from 'classnames';
import { useGetSortButtonText } from '@admin/ModuleSelectionPage/UseGetSortButtonText';
import classes from './NotDistribStudentsAccordion.module.scss';

interface Props {
    deleteStudentModule(input: DeleteStudentModuleInput): ApolloPromise;
}

export const NotDistribStudentsAccordion = observer((
    {
        deleteStudentModule,
    }: Props,
): JSX.Element => {
    const [sortTypes, setSortTypes] = useState<SortTypes>(
        {
            isSortAz: true,
            isSortZa: false,
            isSortByRating: false,
        },
    );

    const { sortButtonText } = useGetSortButtonText(sortTypes);

    const notDistributedStudentsLength = moduleSelectionStore
        .getNotDistribStudentsWithAdditionalFields().length
        - moduleSelectionStore.getMovedStudents().length;

    const resetButtonHandleChange = () => {
        moduleSelectionStore.setModuleGroupsWithAdditionalFields(
            getModulesStudentsWithoutNotDistrib(
                moduleSelectionStore
                    .getNotDistribStudentsWithAdditionalFields(),
                moduleSelectionStore
                    .getModuleGroupsWithAdditionalFields(),
            ),
        );
        moduleSelectionStore.setNotDistribStudentsWithAdditionalFields(
            resetStudentsWithAdditionalFields(
                moduleSelectionStore
                    .getNotDistribStudentsWithAdditionalFields(),
            ),
        );
    };

    const sortButtonHandleClick = () => {
        const sortedStudents = getSortedStudents(
            sortTypes,
            moduleSelectionStore.getStudentsWithRating(),
            moduleSelectionStore.getNotDistribStudentsWithAdditionalFields(),
        );
        moduleSelectionStore.setNotDistribStudentsWithAdditionalFields(
            sortedStudents,
        );
    };

    const changeSortType = () => {
        const initialSortTypes: SortTypes = {
            isSortAz: false,
            isSortZa: false,
            isSortByRating: false,
        };
        const canSortByRating = checkIfCanSortByRating(
            moduleSelectionStore.getStudentsWithRating(),
            moduleSelectionStore.getNotDistribStudentsWithAdditionalFields(),
        );
        if (sortTypes.isSortAz) {
            setSortTypes({
                ...initialSortTypes,
                isSortZa: true,
            });
        }

        if (sortTypes.isSortZa && canSortByRating) {
            setSortTypes({
                ...initialSortTypes,
                isSortByRating: true,
            });
        }

        if (sortTypes.isSortZa && !canSortByRating) {
            setSortTypes({
                ...initialSortTypes,
                isSortAz: true,
            });
        }

        if (sortTypes.isSortByRating) {
            setSortTypes({
                ...initialSortTypes,
                isSortAz: true,
            });
        }
    };

    const notDistributedStudentsList = moduleSelectionStore
        .getNotDistribStudentsWithAdditionalFields()
        .map((student, index) => (
            <NotDistributedItem
                key={student?.user?.id}
                order={index}
                currentStudent={student}
                deleteStudentModule={deleteStudentModule}
            />
        ));
    return (
        <div className={classes.notDistributed}>
            <Accordion
                shouldClickOnHeaderOpen
                isDefaultOpen
                headerClassnames={classes.notDistributed__accordionHeader}
            >
                <div className={cn(classes.notDistributed__header, {
                    [classes.notDistributed__header_valid]: !notDistributedStudentsLength,
                })}
                >
                    Не распределены
                </div>

                <div className={classes.notDistributed__items}>

                    <div className={classes.notDistributed__item}>

                        <div className={cn(classes.notDistributed__item_title, {
                            [classes.notDistributed__item_valid]: !notDistributedStudentsLength,
                        })}
                        >
                            {
                                notDistributedStudentsLength
                            } студентов
                        </div>

                        <div className={classes.notDistributed__item_additional}>

                            <div className={classes.notDistributed__sort}>

                                {
                                    checkIfNotDistribStudentIsSelected(
                                        moduleSelectionStore
                                            .getNotDistribStudentsWithAdditionalFields(),
                                    ) && (
                                        <button
                                            className={classes.notDistributed__item_cancel}
                                            type="button"
                                            onClick={() => {
                                                resetButtonHandleChange();
                                            }}
                                        >
                                            Сбросить
                                        </button>
                                    )
                                }

                                <span className={classes.notDistributed__sort_txt}>
                                    { sortButtonText }
                                </span>

                                <button
                                    type="button"
                                    className={classes.notDistributed__sort_btn}
                                    onClick={() => {
                                        changeSortType();
                                        sortButtonHandleClick();
                                    }}
                                >
                                    <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <rect width="19" height="19" fill="#FFF" />
                                        <path d="M5.35059 4H7.34551V10.254H5.35059V4Z" fill="#4E5258" />
                                        <path d="M9.34049 4H11.3354V11.8174H14.3631L10.3734 15L6.3836 11.8174H9.34049V4Z" fill="#4E5258" />
                                    </svg>
                                </button>

                            </div>

                        </div>

                    </div>

                    <div className={classes.notDistributed__scroll}>
                        {
                            notDistributedStudentsList
                        }
                    </div>

                </div>
            </Accordion>
        </div>
    );
});

function checkIfNotDistribStudentIsSelected(
    noDtostribStudents: StudentWithAdditionalFields[],
) {
    return noDtostribStudents.some(
        (student) => student.isSelected,
    );
}

function getModulesStudentsWithoutNotDistrib(
    notDistribStudents: StudentWithAdditionalFields[],
    moduleGroups: ModuleGroupWithAdditionalFields[],
) {
    return moduleGroups.map((module) => ({
        ...module,
        additionalStudents: differenceBy(
            module.additionalStudents,
            getSelectedNotDistribStudents(notDistribStudents),
            student => student.user.id,
        ),
    }));
}

function getSelectedNotDistribStudents(
    students: StudentWithAdditionalFields[],
) {
    return students.filter((student) => student.isSelected);
}
