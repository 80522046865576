import { makeAutoObservable } from 'mobx';

import {
    AnswerType,
    GetModuleAssessmentConfig_moduleAssessmentConfig,
    GetModuleAssessmentConfig_moduleAssessmentConfig_assessmentOptions,
    GetModuleAssessmentConfig_moduleAssessmentConfig_moduleAssessmentQuestions,
} from './graphql-query-types';

export type AssessmentQuestion = Omit<GetModuleAssessmentConfig_moduleAssessmentConfig_moduleAssessmentQuestions, '__typename'>;
type AssessmentOption = Omit<GetModuleAssessmentConfig_moduleAssessmentConfig_assessmentOptions, '__typename'>;

class ModuleAssessmentConfigStore {
    public moduleClosedTypeAssessmentQuestions: AssessmentQuestion[] | undefined;

    public moduleOpenEndedAssessmentQuestions: AssessmentQuestion[] | undefined;

    public teacherClosedTypeAssessmentQuestions: AssessmentQuestion[] | undefined;

    public teacherOpenEndedAssessmentQuestions: AssessmentQuestion[] | undefined;

    public assessmentOptions: AssessmentOption[] | undefined;

    private moduleAssessmentConfig: GetModuleAssessmentConfig_moduleAssessmentConfig | undefined;

    constructor() {
        makeAutoObservable(this);
    }

    public setModuleAssessmentConfig(config: GetModuleAssessmentConfig_moduleAssessmentConfig) {
        this.moduleAssessmentConfig = config;

        const { moduleAssessmentQuestions, teacherAssessmentQuestions, assessmentOptions } = config;

        const moduleClosedTypeQuestions = moduleAssessmentQuestions?.filter(
            ({ answerType }) => answerType === AnswerType.option,
        );
        const moduleOpenEndedQuestions = moduleAssessmentQuestions?.filter(
            ({ answerType }) => answerType === AnswerType.textarea,
        );
        const teacherClosedTypeQuestions = teacherAssessmentQuestions?.filter(
            ({ answerType }) => answerType === AnswerType.option,
        );
        const teacherOpenEndedQuestions = teacherAssessmentQuestions?.filter(
            ({ answerType }) => answerType === AnswerType.textarea,
        );

        this.assessmentOptions = assessmentOptions;
        this.moduleClosedTypeAssessmentQuestions = moduleClosedTypeQuestions;
        this.moduleOpenEndedAssessmentQuestions = moduleOpenEndedQuestions;
        this.teacherClosedTypeAssessmentQuestions = teacherClosedTypeQuestions;
        this.teacherOpenEndedAssessmentQuestions = teacherOpenEndedQuestions;
    }

    public isOpenEndedAssessmentQuestion(questionId: string) {
        return !!(
            this.moduleOpenEndedAssessmentQuestions?.find(({ id }) => id === questionId)
                || this.teacherOpenEndedAssessmentQuestions?.find(({ id }) => id === questionId)
        );
    }

    public getAssessmentQuestion(questionId: string): AssessmentQuestion | undefined {
        const moduleQuestion = this.moduleAssessmentConfig?.moduleAssessmentQuestions.find(
            ({ id }) => id === questionId,
        );
        if (moduleQuestion) {
            return moduleQuestion;
        }

        const teacherQuestion = this.moduleAssessmentConfig?.teacherAssessmentQuestions.find(
            ({ id }) => id === questionId,
        );
        if (teacherQuestion) {
            return teacherQuestion;
        }

        return undefined;
    }
}

export default new ModuleAssessmentConfigStore();
