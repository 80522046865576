/* eslint-disable import/no-cycle */
import React from 'react';
import { useQuery } from '@apollo/client';
import { observer } from 'mobx-react';
import { GET_SKILLS_TYPE } from '../../../../gql/skillTypes';

import classes from './getSkillTypesApollo.module.scss';
import { Loader } from '../../../../../../../../../../common/Loader';
import { Alert } from '../../../../../../../../../../common/Alert';
import { evaluationStore } from '../../../../Store';
import { SkillTypeData } from '../../../../../../../../Interfaces/scheduleEvents/evaluationPoints/skillTypes/skillTypes';

export const GetSkillTypesApollo = observer(() => {
    const {
        loading: loadingSkillsTypes,
        error: errorSkillsTypes,
    } = useQuery<SkillTypeData>(GET_SKILLS_TYPE, {
        fetchPolicy: 'cache-and-network',
        onCompleted: (data) => {
            evaluationStore.setSkillTypes(data);
        },
    });

    if (loadingSkillsTypes) {
        return (
            <div className={classes.loader}>
                <Loader />
            </div>
        );
    }

    if (errorSkillsTypes) {
        return (
            <Alert
                message={errorSkillsTypes?.message}
                time={2500}
            />
        );
    }

    return null;
});
