import { SortTypes } from '@admin/ModuleSelectionPage/Interfaces';
import { useEffect, useState } from 'react';

export function useGetSortButtonText(
    sortTypes: SortTypes,
) {
    const [sortButtonText, setSortButtonText] = useState('А-я');
    useEffect(() => {
        if (sortTypes.isSortAz) {
            setSortButtonText('А-я');
        }

        if (sortTypes.isSortZa) {
            setSortButtonText('Я-а');
        }

        if (sortTypes.isSortByRating) {
            setSortButtonText('Рейт.');
        }
    }, [sortTypes]);

    return {
        sortButtonText,
    };
}
