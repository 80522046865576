import React, { useState } from 'react';
import cn from 'classnames';

import { Input } from '../Input';

import classes from './InputFilter.module.scss';

interface InputFilterProps {
    placeholder: string,
    style?: object,
    onChange: (value: string) => void,
}

export function InputFilter(
    {
        placeholder,
        style,
        onChange,
    }: InputFilterProps,
) {
    const [value, setValue] = useState<string | null>(null);
    function saveInputResult(result: string) {
        setValue(result);
        onChange(result);
    }
    function deleteValue() {
        setValue(null);
        onChange('');
    }
    return (
        <div
            className={
                cn(
                    classes.inputFilter,
                    {
                        [classes.inputFilter_valueTyped]: value,
                    },
                )
            }
            style={style}
        >
            {
                value
                    ? (
                        <div
                            className={classes.inputFilter__typedValueShowplace}
                        >
                            <div
                                className={classes.inputFilter__value}
                            >
                                {value}
                            </div>
                            <div
                                className={classes.inputFilter__delete}
                                onClick={deleteValue}
                            >
                                [x]
                            </div>
                        </div>
                    )
                    : (
                        <Input
                            className={classes.inputFilter_active}
                            placeholder={placeholder}
                            onChange={saveInputResult}
                        />
                    )
            }
        </div>
    );
}
