import React from 'react';
import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import sortBy from 'lodash/sortBy';
import { NavLink } from 'react-router-dom';

import { IconDeprecated } from '@common';

import UserView from '../../../store/UserView';
import classes from './UserTypeMenu.module.scss';

interface Props {
    handleSetUserRoleMode(): void;
    handleCloseProfileMenu(): void;
}

export const UserTypeMenu = observer(({
    handleSetUserRoleMode,
    handleCloseProfileMenu,
}: Props): JSX.Element => {
    const { userViewType, userViewTypes } = UserView;

    return (
        <div className={classes.userTypeMenu}>
            <div className={classes.userTypeMenu__selectionDescription}>
                <p className={classes.userTypeMenu__text}>Смотреть как:</p>

                <IconDeprecated
                    id="crossInCircle"
                    className={classes.userTypeMenu__icon}
                    click={handleSetUserRoleMode}
                />
            </div>

            <div className={classes.userTypeMenu__selectionList}>
                {
                    sortBy(userViewTypes, 'text').map(viewType => (
                        <NavLink
                            to="/"
                            key={viewType.roleId}
                            className={classes.userTypeMenu_selectionItem}
                            activeClassName={cn(classes.userTypeMenu_selectionItem, {
                                [classes.userTypeMenu_selectionItem_active]:
                                    viewType.roleId === userViewType?.roleId,
                            })}
                            onClick={() => {
                                UserView.setUserViewType(viewType);
                                handleCloseProfileMenu();
                            }}
                        >
                            {viewType.text}
                        </NavLink>
                    ))
                }
            </div>
        </div>
    );
});
