/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getSuggesTeachers
// ====================================================

export interface getSuggesTeachers_users_list {
  __typename: "User";
  firstName: string;
  lastName: string;
  patronymic: string | null;
  teacherId: string | null;
}

export interface getSuggesTeachers_users {
  __typename: "UserList";
  list: getSuggesTeachers_users_list[];
}

export interface getSuggesTeachers {
  users: getSuggesTeachers_users;
}

export interface getSuggesTeachersVariables {
  value: FindParameters;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getSuggestedStudents
// ====================================================

export interface getSuggestedStudents_users_list {
  __typename: "User";
  studentId: string | null;
  firstName: string;
  lastName: string;
  patronymic: string | null;
}

export interface getSuggestedStudents_users {
  __typename: "UserList";
  list: getSuggestedStudents_users_list[];
}

export interface getSuggestedStudents {
  users: getSuggestedStudents_users;
}

export interface getSuggestedStudentsVariables {
  value: FindParameters;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getSuggestedRooms
// ====================================================

export interface getSuggestedRooms_roomsByTitlePattern_location {
  __typename: "Building";
  name: string;
}

export interface getSuggestedRooms_roomsByTitlePattern {
  __typename: "Room";
  id: string;
  title: string;
  location: getSuggestedRooms_roomsByTitlePattern_location;
}

export interface getSuggestedRooms {
  roomsByTitlePattern: getSuggestedRooms_roomsByTitlePattern[];
}

export interface getSuggestedRoomsVariables {
  value: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getSuggestedModules
// ====================================================

export interface getSuggestedModules_modulesByFilters {
  __typename: "Module";
  id: string;
  name: string | null;
}

export interface getSuggestedModules {
  modulesByFilters: getSuggestedModules_modulesByFilters[];
}

export interface getSuggestedModulesVariables {
  value: ModulesByFiltersInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getSuggestedWaves
// ====================================================

export interface getSuggestedWaves {
  wavesUniqueIndexesByIndexPattern: (number | null)[];
}

export interface getSuggestedWavesVariables {
  value: WavesUniqueIndexesInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: events
// ====================================================

export interface events_events_meetings {
  __typename: "Meeting";
  id: string;
  topic: string | null;
}

export interface events_events_assignments {
  __typename: "Assignment";
  id: string;
  topic: string | null;
}

export interface events_events_evaluationPoints {
  __typename: "EvaluationPoint";
  id: string;
  topic: string | null;
}

export interface events_events {
  __typename: "ModuleEvents";
  meetings: events_events_meetings[];
  assignments: events_events_assignments[];
  evaluationPoints: events_events_evaluationPoints[];
}

export interface events {
  events: events_events;
}

export interface eventsVariables {
  value: EventsInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum Type {
  admin = "admin",
  all = "all",
  none = "none",
  student = "student",
  teacher = "teacher",
}

export enum UserStatus {
  active = "active",
  deleted = "deleted",
  inactive = "inactive",
}

export interface EventsInput {
  baseTimeIntervalInstanceId: string;
  eventNamePatterns?: string[] | null;
  moduleIds?: string[] | null;
}

export interface FindParameters {
  userType?: Type | null;
  fullName?: string | null;
  status?: UserStatus | null;
  studentFindParameters?: StudentFindParameters | null;
}

export interface ModulesByFiltersInput {
  baseTimeIntervalInstanceId: string;
  namePattern?: string | null;
}

export interface StudentFindParameters {
  baseTimeIntervalInstanceId?: string | null;
  skillName?: string | null;
  spaceId?: string | null;
}

export interface WavesUniqueIndexesInput {
  indexPattern?: string | null;
  baseTimeIntervalInstanceId: string;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
