import { useMutation } from '@apollo/client';
import { UPDATE_TRAJECTORY } from '@common/qraphql/mutations';
import { GET_TRAJECTORIES } from '@common/qraphql/queries';
import { TrajectoryInput } from '../types';

export const useUpdateTrajectoryMutation = (spaceId: string) => {
    const [updateTrajectory, { error: updateTrajectoryError }] = useMutation(UPDATE_TRAJECTORY, {
        refetchQueries: [{ query: GET_TRAJECTORIES, variables: { spaceId } }],
    });

    return {
        updateTrajectory: (updateTrajectoryInput: TrajectoryInput) => updateTrajectory({
            variables: { updateTrajectoryInput },
        }),
        updateTrajectoryError,
    };
};
