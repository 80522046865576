import React, { useLayoutEffect } from 'react';
import { flatten, groupBy, map, sortBy, difference, uniqBy } from 'lodash';
import { observer } from 'mobx-react-lite';
import { SpriteSvg } from '@common/SpriteSvgDeprecated';
import cn from 'classnames';
import { BTIDiagram } from '../BTIDiagram';
import { DiagramOperationPanel } from '../DiagramOperationPanel';
import {
    BTISettings,
    DiagramEntity,
    Fork,
    GroupSlot,
    ModuleSlot,
    SpaceBaseTimeInterval, SubspaceType,
    UpdateSlotDiagramInput,
} from '../subSpaceTypes';
import Diagram from '../store/Diagram';

import {
    createArrayFromDiagramEntities,
    filterEntitiesByBtiId,
    removeEmptySlots,
    setEntitiesColumnsRange,
    setEntitiesRowsRange,
    shouldSetStartPositionOfDiagram,
    updateForkSettingInput,
} from './utilities';
import classes from './SubSpaceSlotDiagram.module.scss';
import { SVGCanvas } from '../SVGCanvas';
import { calculateMaxHeightOfCanvas, calculateWidthOfCanvas } from '../SVGCanvas/utilities';

interface ForkSlot {
    forkId: string,
    slot: ModuleSlot,
    prevSlotId?: string,
}

interface Props {
    spaceBaseTimeIntervals: SpaceBaseTimeInterval[]
    subSpaceSlots: ModuleSlot[]
    subSpaceGroupSlots: GroupSlot[]
    subSpaceForks: Fork[]
    subspace: any;
    subspaceType: SubspaceType;

    updateSlotDiagram(updateSlotDiagramInput: UpdateSlotDiagramInput): void
}

export const SubSpaceSlotDiagram = observer(({
    spaceBaseTimeIntervals,
    subSpaceSlots = [],
    subSpaceGroupSlots = [],
    subSpaceForks = [],
    subspace,
    subspaceType,
    updateSlotDiagram,
}: Props): JSX.Element => {
    const {
        diagramSettings,
        btiSettings,
        diagramEntityRelationSettings,
    } = Diagram;

    const countTimeIntervalCreditCount = (slots: ModuleSlot[], forks: Fork[]) => {
        const allForksSlotsWithForksIds: ForkSlot[] = uniqBy(
            getAllForksSlots(slots, forks), (forkSlot) => forkSlot.slot.id,
        );

        const forksSlotsNoForksIds = allForksSlotsWithForksIds.map((forkSlot) => forkSlot.slot);
        const slotsNoForksSlots: ModuleSlot[] = difference(slots, forksSlotsNoForksIds);

        const forksCreditCount = countForkSlotsCreditCount(allForksSlotsWithForksIds);
        const slotsNoForksCreditCount = countNotForkSlotsCreditCount(slotsNoForksSlots);

        const timeIntervalCreditCount = forksCreditCount.minCreditCount + slotsNoForksCreditCount
            === forksCreditCount.maxCreditCount + slotsNoForksCreditCount
            ? `
                ${forksCreditCount.maxCreditCount + slotsNoForksCreditCount}
                ` : `
                ${forksCreditCount.minCreditCount + slotsNoForksCreditCount} 
                -
                ${forksCreditCount.maxCreditCount + slotsNoForksCreditCount}`;
        return timeIntervalCreditCount;
    };

    const countForkSlotsCreditCount = (forksSlots: ForkSlot[]) => {
        const forksCreditCounts: {
            minCreditCount: number,
            maxCreditCount: number,
        }[] = [];
        const groupedForksSlots = groupBy(forksSlots, 'forkId');
        Object.keys(groupedForksSlots).forEach((key) => {
            let minCreditCount = 0;
            let maxCreditCount = 0;
            groupedForksSlots[key].forEach((forkSlot, index) => {
                if (forkSlot.slot.module?.creditCount) {
                    const forkSlotCreditCount = Math.round(forkSlot.slot.module.creditCount);

                    if (index === 1) {
                        minCreditCount = forkSlotCreditCount;
                        maxCreditCount = forkSlotCreditCount;
                    } else if (forkSlotCreditCount < minCreditCount) {
                        minCreditCount = forkSlotCreditCount;
                    } else if (forkSlotCreditCount > maxCreditCount) {
                        maxCreditCount = forkSlotCreditCount;
                    }
                }
            });

            forksCreditCounts.push({
                minCreditCount,
                maxCreditCount,
            });
        });

        let sumMinCreditCount = 0;
        let sumMaxCreditCount = 0;

        forksCreditCounts.forEach((forkCreditCount) => {
            sumMinCreditCount += Math.round(forkCreditCount.minCreditCount);
            sumMaxCreditCount += Math.round(forkCreditCount.maxCreditCount);
        });

        return {
            minCreditCount: sumMinCreditCount,
            maxCreditCount: sumMaxCreditCount,
        };
    };

    const countNotForkSlotsCreditCount = (filteredSlots: ModuleSlot[]) => {
        const creditCount = filteredSlots.reduce(
            (
                previousValue,
                currentValue,
            ) => Math.round(previousValue)
                + Math.round(currentValue.module?.creditCount ?? 0), 0,
        );

        return creditCount;
    };

    const getAllForksSlots = (
        slots: ModuleSlot[],
        forks: Fork[],
    ) => {
        const allForksSlots: ForkSlot[] = [];
        forks.forEach((fork) => {
            if (fork && fork.nextSlots) {
                fork.nextSlots.forEach((nextSlot) => {
                    allForksSlots.push(
                        ...getAllForkSlots(
                            nextSlot,
                            slots,
                            allForksSlots,
                            fork.id,
                        ),
                    );
                });
            }
        });

        return allForksSlots;
    };

    function getAllForkSlots(
        forkSlot: ModuleSlot,
        allSlots: ModuleSlot[],
        forkSlots: ForkSlot[],
        forkId: string,
    ) {
        const currentSlot = getForkSlotFromAllSlots(allSlots, forkSlot.id);
        const forkSlotsArr: ForkSlot[] = [];

        if (checkIfForkSlotMarked(forkSlots, forkSlot.id) || !currentSlot) {
            return [];
        }

        forkSlotsArr.push(
            {
                forkId,
                slot: currentSlot,
                prevSlotId: '',
            },
        );

        if (currentSlot.nextSlots.length) {
            forkSlotsArr.push(...DFSForkSlots(currentSlot, allSlots, forkId));
        }

        return forkSlotsArr;
    }

    function DFSForkSlots(
        forkCurrentSlotWithNextSlots: ModuleSlot,
        allSlots: ModuleSlot[],
        forkId: string,
    ) {
        let prevSlotId = forkCurrentSlotWithNextSlots.id;
        const slotStack: ModuleSlot[] = [];
        const forkSlots: ForkSlot[] = [];

        slotStack.push(...forkCurrentSlotWithNextSlots.nextSlots);

        while (slotStack.length) {
            const firstInStack = slotStack.pop();
            const currentStackSlot = getForkSlotFromAllSlots(allSlots, firstInStack ? firstInStack.id : '');

            if (!currentStackSlot || checkIfForkSlotMarked(forkSlots, currentStackSlot.id)) {
                // eslint-disable-next-line no-continue
                continue;
            }

            forkSlots.push(
                {
                    forkId,
                    slot: currentStackSlot,
                    prevSlotId,
                },
            );

            if (currentStackSlot?.nextSlots?.length) {
                prevSlotId = currentStackSlot.id;
                slotStack.push(...currentStackSlot.nextSlots);
            }
        }

        return forkSlots;
    }

    function checkIfForkSlotMarked(allForkSlots: ForkSlot[], forkSlotId: string) {
        return allForkSlots.find((oldForkSlot) => oldForkSlot.slot.id === forkSlotId);
    }

    function getForkSlotFromAllSlots(allSlots: ModuleSlot[], forkSlotId: string) {
        return allSlots.find((slot) => slot.id === forkSlotId);
    }

    useLayoutEffect(() => {
        Diagram.setSlotSettings({
            id: '',
            spaceBaseTimeIntervalId: '',
            moveSlotTo: undefined,
            isSlotCanMove: false,
            hasModule: false,
            hasPrerequisiteSkills: false,
            hasOutputSkills: false,
        });

        const diagramEntities = createArrayFromDiagramEntities({
            moduleSlots: subSpaceSlots,
            groupSlots: subSpaceGroupSlots,
            forks: subSpaceForks,
        });

        const baseTimeIntervals: BTISettings[] = map(
            sortBy(spaceBaseTimeIntervals, 'order'),
            interval => (
                {
                    id: interval.id,
                    subspaceId: subspace.id,
                    order: interval.order,
                    name: interval.space.baseTimeIntervalType.name,
                    maxCreditCount:
                        subspaceType.subspaceTypeLayouts?.find(
                            ({ spaceBaseTimeInterval }) => interval.id === spaceBaseTimeInterval.id,
                        )?.minCreditCount === subspaceType.subspaceTypeLayouts?.find(
                            ({ spaceBaseTimeInterval }) => interval.id === spaceBaseTimeInterval.id,
                        )?.maxCreditCount
                            ? `${subspaceType.subspaceTypeLayouts?.find(
                                ({
                                    spaceBaseTimeInterval,
                                }) => interval.id === spaceBaseTimeInterval.id,
                            )?.minCreditCount}`
                            : `${subspaceType.subspaceTypeLayouts?.find(
                                ({
                                    spaceBaseTimeInterval,
                                }) => interval.id === spaceBaseTimeInterval.id,
                            )?.minCreditCount} - ${subspaceType.subspaceTypeLayouts?.find(
                                ({
                                    spaceBaseTimeInterval,
                                }) => interval.id === spaceBaseTimeInterval.id,
                            )?.maxCreditCount}`
                            ?? '0',
                    columnsRange: setEntitiesColumnsRange(
                        filterEntitiesByBtiId<DiagramEntity>(diagramEntities, interval.id),
                    ),
                    rowsRange: setEntitiesRowsRange(
                        filterEntitiesByBtiId<DiagramEntity>(diagramEntities, interval.id),
                    ),
                    moduleSlots: filterEntitiesByBtiId<ModuleSlot>(subSpaceSlots, interval.id),
                    groupSlots: filterEntitiesByBtiId<GroupSlot>(subSpaceGroupSlots, interval.id),
                    forks: filterEntitiesByBtiId<Fork>(subSpaceForks, interval.id),
                }
            ),
        );

        Diagram.setBtiSettings(baseTimeIntervals);
    }, [subSpaceForks, subspace, spaceBaseTimeIntervals]);

    if (diagramSettings.editMode) Diagram.addAdditionalBtiRowsAndColumns();
    if (!diagramSettings.editMode) Diagram.addAdditionalBtiLeftColumn();

    const handleUpdateDiagramEntities = (): void => {
        const slots = flatten(map(btiSettings, bti => (bti.moduleSlots))) || [];
        const groups = flatten(map(btiSettings, bti => (bti.groupSlots))) || [];
        const forks = flatten(map(btiSettings, bti => (bti.forks))) || [];

        updateSlotDiagram({
            subspaceId: subspace.id,
            slots: slots.map(
                slot => ({
                    id: slot.id,
                    spaceBaseTimeIntervalId: slot.spaceBaseTimeIntervalId,
                    row: slot.row,
                    column: slot.column,
                    nextSlots: slot.nextSlots.map(
                        nextSlot => ({
                            id: nextSlot.id,
                            spaceBaseTimeIntervalId: nextSlot.spaceBaseTimeIntervalId,
                            row: nextSlot.row,
                            column: nextSlot.column,
                        }),
                    ) || [],
                    nextForks: slot.nextForks.map(
                        nextFork => ({
                            id: nextFork.id,
                            spaceBaseTimeIntervalId: nextFork.spaceBaseTimeIntervalId,
                            row: nextFork.row,
                            column: nextFork.column,
                        }),
                    ) || [],
                    nextGroupSlots: slot.nextGroupSlots.map(
                        nextGroupSlot => ({
                            id: nextGroupSlot.id,
                            spaceBaseTimeIntervalId: nextGroupSlot.spaceBaseTimeIntervalId,
                            row: nextGroupSlot.row,
                            column: nextGroupSlot.column,
                        }),
                    ) || [],
                }),
            ),
            forks: updateForkSettingInput(forks),
            groupSlots: map(groups, group => ({
                id: group.id,
                spaceBaseTimeIntervalId: group.spaceBaseTimeIntervalId,
                row: group.row,
                column: group.column,
                nextSlots: group.nextSlots.map(
                    nextSlot => ({
                        id: nextSlot.id,
                        spaceBaseTimeIntervalId: nextSlot.spaceBaseTimeIntervalId,
                        row: nextSlot.row,
                        column: nextSlot.column,
                    }),
                ) || [],
                nextForks: group.nextForks.map(
                    nextFork => ({
                        id: nextFork.id,
                        spaceBaseTimeIntervalId: nextFork.spaceBaseTimeIntervalId,
                        row: nextFork.row,
                        column: nextFork.column,
                    }),
                ) || [],
                nextGroupSlots: group.nextGroupSlots.map(
                    nextGroupSlot => ({
                        id: nextGroupSlot.id,
                        spaceBaseTimeIntervalId: nextGroupSlot.spaceBaseTimeIntervalId,
                        row: nextGroupSlot.row,
                        column: nextGroupSlot.column,
                    }),
                ) || [],
            })),
        });
    };

    return (
        <div className={cn(classes.subSpaceSlotDiagram, {
            [classes.subSpaceSlotDiagram_withOverflow]: shouldSetStartPositionOfDiagram(
                btiSettings,
            ),
        })}
        >
            <div className={cn(classes.subSpaceSlotDiagram__settings)}>
                <div className={classes.subSpaceSlotDiagram__header}>
                    <h4 className={classes.subSpaceSlotDiagram__title}>Прохождение подпространства:
                    </h4>
                    <SpriteSvg
                        className={classes.subSpaceSlotDiagram_editButton}
                        name={diagramSettings.editMode ? 'editPen_active' : 'editPen_disabled'}
                        onClick={() => {
                            Diagram.setDiagramEditMode();

                            if (diagramSettings.editMode) {
                                handleUpdateDiagramEntities();
                                Diagram.setBtiSettings(removeEmptySlots(btiSettings));
                            }
                        }}
                    />
                </div>

                <div className={classes.subSpaceSlotDiagram__controlPanel}>

                    {diagramSettings.editMode && <DiagramOperationPanel />}

                    {
                        diagramSettings.createSlotMode
                        && (
                            <p className={classes.subSpaceSlotDiagram__prompt}>
                                Укажите, где создать слот для модуля:
                            </p>
                        )
                    }
                    {
                        diagramSettings.createGroupMode
                        && (
                            <p className={classes.subSpaceSlotDiagram__prompt}>
                                Укажите, где создать группу для модулей:
                            </p>
                        )
                    }
                    {
                        diagramSettings.createForkMode
                        && (
                            <p className={classes.subSpaceSlotDiagram__prompt}>
                                Укажите, где создать развилку для модулей:
                            </p>
                        )
                    }

                    {
                        (
                            diagramSettings.relationDiagramEntityMode
                            && !diagramEntityRelationSettings.previousEntityId
                        )
                        && (
                            <p className={classes.subSpaceSlotDiagram__prompt}>Выберите слот,
                                группу или развилку от которой зададите связи
                            </p>
                        )

                    }

                    {
                        (
                            diagramSettings.relationDiagramEntityMode
                            && diagramEntityRelationSettings.previousEntityId
                        )
                        && (
                            <p className={classes.subSpaceSlotDiagram__prompt}>Выберите слот,
                                группу или развилку к
                                которой зададите связь:
                            </p>
                        )

                    }
                </div>
            </div>

            <div className={cn(classes.diagram, {
                [classes.diagram_centered]: !shouldSetStartPositionOfDiagram(btiSettings),
                [classes.diagram_flexStart]: shouldSetStartPositionOfDiagram(btiSettings),
            })}
            >
                {map(btiSettings, (baseTimeInterval, index) => (
                    <BTIDiagram
                        subspaceId={subspace.id}
                        hasBothBorders={index === 0}
                        hasRightBorder={index !== 0}
                        updateModuleSlots={updateSlotDiagram}
                        spaceBaseTimeIntervalId={
                            baseTimeInterval.id
                        }
                        subSpaceSlots={subSpaceSlots}
                        btiSlots={baseTimeInterval.moduleSlots}
                        btiGroupSlots={baseTimeInterval.groupSlots}
                        btiForks={baseTimeInterval.forks}
                        name={baseTimeInterval.name}
                        order={baseTimeInterval.order}
                        minCreditCount={
                            countTimeIntervalCreditCount(
                                baseTimeInterval.moduleSlots,
                                baseTimeInterval.forks,
                            )
                        }
                        maxCreditCount={baseTimeInterval.maxCreditCount}
                        rowsRange={baseTimeInterval.rowsRange}
                        columnsRange={baseTimeInterval.columnsRange}
                        key={baseTimeInterval.id}
                    />
                ))
                }
                <SVGCanvas
                    width={calculateWidthOfCanvas(btiSettings)}
                    height={calculateMaxHeightOfCanvas(btiSettings)}
                />
            </div>
        </div>
    );
});
