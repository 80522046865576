import React from 'react';
import {
    IgnoreFlag,
    IgnoreFlagCheckbox,
    ConstraintMatchCount,
} from './interfaces';

import classes from './ScheduleGeneration.module.scss';
import {
    ScheduleGenerationProcessStatus,
    ScheduleGenerationProcessesSchedulePage_scheduleGenerationProcesses,
} from '../../../graphql-query-types';

interface Props {
    process: ScheduleGenerationProcessesSchedulePage_scheduleGenerationProcesses;
    ignoreFlags: Partial<IgnoreFlagCheckbox>;
    isScheduleGeneration: () => boolean;
}

export const ScheduleValidation = ({
    process,
    ignoreFlags,
    isScheduleGeneration,
}: Props) => (
    <>
        {
            getTrueKeysFromObject(ignoreFlags).length > 0
            && process.status !== ScheduleGenerationProcessStatus.generationPhase1
            && process.status !== ScheduleGenerationProcessStatus.generationPhase2
            && (
                <div className={classes.warning}>
                    <div>
                        Были проигнорированы следующие
                        ресурсы при валидации расписания:
                    </div>
                    <ul>{
                        getTrueKeysFromObject(ignoreFlags).map(
                            (flag) => (
                                <li key={flag}>{
                                    Object.getOwnPropertyDescriptor(
                                        IgnoreFlag,
                                        flag,
                                    )?.value
                                }
                                </li>
                            ),
                        )
                    }
                    </ul>
                    <div>
                        Игнорирование данных ресурсов может
                        привести к конфликтам в генерации расписания
                    </div>
                    <br />
                </div>
            )
        }
        {
            !isScheduleGeneration() && (
                <>
                    Валидация прошла успешно, вы можете начать
                    процесс генерации. Нажмите на кнопку «Начать генерацию»
                    <br />
                    <br />
                </>
            )
        }
        {
            isScoreVisible(process) && (
                <>
                    <br />
                    <ul className={classes.score}>
                        {
                            process?.constraintMatch?.constraintMatchCountList.map(
                                (item: ConstraintMatchCount) => item.scoreType === 'HARD' && (
                                    <li>
                                        {item.constraintName}&nbsp;&nbsp;&nbsp;
                                        {item.matchCount}
                                    </li>
                                ),
                            )
                        }
                    </ul>
                    <br />
                </>
            )
        }
        {
            process.status === ScheduleGenerationProcessStatus.correction
            && !isScheduleGeneration()
            && (
                <>
                    <br />
                    <div>
                        Перед запуском генерации необходимо произвести валидацию
                        ошибок. <br />
                        Пожалуйста, нажмите на кнопку &quot;Валидация&quot;
                        <br />
                        <br />
                    </div>
                </>
            )
        }
    </>
);

function getTrueKeysFromObject(obj: { [index: string]: boolean; }) {
    return Object.entries(obj).filter(([, value]) => value).map(([key]) => key);
}

function isScoreVisible(
    process: ScheduleGenerationProcessesSchedulePage_scheduleGenerationProcesses,
) {
    return (process.status === ScheduleGenerationProcessStatus.generationPhase1
        || process.status === ScheduleGenerationProcessStatus.generationPhase2
        || process.status === ScheduleGenerationProcessStatus.generationReady)
        && !!process.constraintMatch?.constraintMatchCountList?.length;
}
