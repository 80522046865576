import { SkillTypeExtended } from '../../../ToolBar/Tools/SkillsButton/PopupWithSkills/interfaces/skillType';
import { Skill } from '../../models';

interface SkillTypeWithSkills {
    id: string;
    skills: Skill[];
    name: string;
    isLevelIncreased: boolean;
    childrenId: null | string;
    parentId: string | null;
}

export type SkillTypeTree = SkillTypeWithSkills[];

export function getSkillTypeTree(
    skills: Skill[],
    skillTypesExtended?: SkillTypeExtended[],
): SkillTypeTree | undefined {
    const skillTypeTree: SkillTypeTree | undefined = skillTypesExtended
        ?.map(({ id, name, isLevelIncreased, parentId }) => ({
            id,
            skills: [],
            name: name.nominativePlural,
            isLevelIncreased,
            childrenId: null,
            parentId,
        }));

    skills.forEach((skill: Skill) => {
        const foundSkillType = skillTypeTree
            ?.find((skillType) => skillType.id === skill.skill.typeId);
        if (foundSkillType) {
            foundSkillType.skills.push(skill);
        }
    });

    skillTypeTree?.forEach((skillType) => {
        if (skillType.parentId !== null) {
            const foundSkillType = skillTypeTree.find(
                (skillTypeToFound) => (
                    skillTypeToFound.id === skillType.parentId
                ),
            );

            if (foundSkillType) {
                foundSkillType.childrenId = skillType.id;
            }
        }
    });

    return skillTypeTree;
}

export function findSkillsFromTree(
    skillType: SkillTypeWithSkills,
    skillTypeTree: SkillTypeTree,
): Skill[] {
    if (skillType.childrenId === null) {
        return skillType.skills;
    }

    const foundChildrenSkillType = skillTypeTree.find(
        (skillTypeToFind) => skillTypeToFind.id === skillType.childrenId,
    );

    if (foundChildrenSkillType) {
        return findSkillsFromTree(foundChildrenSkillType, skillTypeTree);
    }

    return [];
}
