import React from 'react';

import { userCardStore } from '@admin/Users/store/userCardStore';
import { KeyValue } from '@common/KeyValue';
import { filterWavesByBaseTimeInterval } from '@common';
import { Spoiler } from '../Spoiler';
import { Student } from '../models';
import { Loader } from '../../../../common/Loader';
import { findSkillsFromTree, SkillTypeTree } from './mapping/skillTypes';
import { SkillResult } from './SkillResult';

import classes from './StudentInfo.module.scss';

interface Props {
    student: Student;
    edit?: boolean,
    loadingSkillTypes: boolean;
    skillTypeTree?: SkillTypeTree;
}

export const StudentInfo = ({
    student,
    edit,
    loadingSkillTypes,
    skillTypeTree,
}: Props): JSX.Element => {
    const studentModulesToString = userCardStore.studentModules?.map(module => `${module.name} ${module.waveIndex} поток`) ?? [];
    const { activeBaseTimeIntervalInstance } = student;
    const editStudentWaves = userCardStore.studentModules?.map(module => {
        const moduleName = `${module.name}`;
        const studentWaves = filterWavesByBaseTimeInterval(
            module.waves,
            activeBaseTimeIntervalInstance.baseTimeIntervalInstance.id,
        ).sort((a, b) => a.index - b.index);
        return (
            <div className={classes.studentInfo__module_item}>
                {moduleName}
                <div className={classes.studentInfo__select_wave}>
                    <select
                        className={classes.studentInfo__select}
                        onChange={({ target }) => userCardStore.updateStudentModule(
                            module.id,
                            target[target.selectedIndex].id,
                        )}
                    >
                        {studentWaves.map(option => (
                            <option
                                selected={option.id === module.waveId}
                                id={option.id}
                                key={option.id}
                            >{
                                    `${option.index} поток`
                                }
                            </option>
                        ))}
                    </select>
                </div>
            </div>
        );
    });

    return (
        <div>
            <div className={classes.userCard__block}>
                {userCardStore.roles.length > 1
                    && (
                        <h2 className={classes.studentInfo__title}>
                            Параметры роли Студент:
                        </h2>
                    )
                }
                <KeyValue
                    title="Пространство: "
                >
                    {student.space === null ? 'не задано' : student.space.name}
                </KeyValue>
            </div>
            <div className={classes.studentInfo__block}>
                <KeyValue
                    title="Временной интервал: "
                >
                    {student.activeBaseTimeIntervalInstance && (
                        `${student.activeBaseTimeIntervalInstance.baseTimeIntervalInstance
                            .spaceBaseTimeInterval.order} 
                ${student.activeBaseTimeIntervalInstance.baseTimeIntervalInstance
                            .spaceBaseTimeInterval.space.baseTimeIntervalType?.name}`
                    )}
                </KeyValue>
            </div>
            <div className={classes.studentInfo__block}>
                <div className={classes.studentInfo__spoiler_title}>
                    Образовательные результаты студента:
                </div>
                {loadingSkillTypes
                    ? <Loader />
                    : skillTypeTree?.map((skillType) => {
                        if (skillType.parentId === null) {
                            return (
                                <Spoiler
                                    isModuleitems
                                    key={skillType.id}
                                    title={skillType.name}
                                    list={
                                        findSkillsFromTree(skillType, skillTypeTree)
                                            .map((skill) => (
                                                <SkillResult
                                                    isLevelIncreased={skillType.isLevelIncreased}
                                                    skill={skill}
                                                />
                                            ))
                                    }
                                />
                            );
                        }
                        return null;
                    })
                }
            </div>
            <div className={classes.studentInfo__block}>
                <div className={classes.studentInfo__spoiler_title}>
                    Учебные модули студента:
                </div>
                <Spoiler
                    title="Актуальные"
                    list={!edit ? studentModulesToString : editStudentWaves}
                />
            </div>
        </div>
    );
};
