import React from 'react';
import { Link } from 'react-router-dom';

import { EditIcon } from '@common/svg';

import { Space } from '../../../../subSpaceAdmin/subSpaceTypes';

import classes from '../../EducationSpacePage.module.scss';

interface Subspace {
    id: string;
    name: string;
    ownerId: string;
    ownerFullName: string;
    typeId: string;
}

interface Props {
    space: Space;
    subspace: Subspace;
    name: string;
    administrator: string;
    index: number;
    setActiveIndex(arg0: number): void;
    routerPath: string;
}

export function EducationSubspaceCard({
    space,
    subspace,
    name,
    administrator,
    index,
    setActiveIndex,
    routerPath,
}: Props) {
    return (
        <div className={classes.educationSubspaceCard__container}>
            <div className={classes.educationSubspaceCard__textContainer}>
                <Link to={`${routerPath}/subspace/${space.id}/${subspace.typeId}/${subspace.id}`}>
                    <div
                        className={classes.educationSubspaceCard__subspaceName}
                    >
                        Подпространство {name}
                    </div>
                </Link>
                <p>Администратор <em
                    className={classes.educationSubspaceCard__subspaceAdministrator}
                > {administrator}</em>
                </p>
            </div>
            <EditIcon
                size={25}
                handler={() => setActiveIndex(index)}
            />
        </div>
    );
}
