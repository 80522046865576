import React, { useState } from 'react';
import cn from 'classnames';

import classes from './External.module.scss';
import { InvitationList } from './InvitationList/InvitationList';
import { CreateInvitationForm } from './CreateInvitationForm';
import { ExternalModuleList } from './ExternalModuleList';

enum SubTab {
    EXTERANL_MODULES = 'Список модулей',
    EXTERANL_INVITATIONS = 'Список приглашений',
}

export function External() {
    const [activeTab, setActiveTab] = useState<SubTab>(SubTab.EXTERANL_MODULES);
    const [isEditModeOn, setIsEditModeOn] = useState<boolean>(false);

    return (
        <div className={classes.tabs}>
            <ul className={classes.tabs__list}>
                {[SubTab.EXTERANL_MODULES, SubTab.EXTERANL_INVITATIONS].map(tab => (
                    <li
                        key={tab}
                        className={classes.tabs__item}
                    >
                        <div
                            onClick={() => {
                                setActiveTab(tab);
                                setIsEditModeOn(false);
                            }}
                            className={
                                cn(classes.tabs__tab, {
                                    [classes.tabs__tab_selected]:
                                    tab === activeTab,
                                })
                            }
                        >
                            {tab}
                        </div>
                    </li>
                ))}
            </ul>
            {activeTab === SubTab.EXTERANL_MODULES && (
                <ExternalModuleList />
            )}
            {activeTab === SubTab.EXTERANL_INVITATIONS && (
                isEditModeOn
                    ? <CreateInvitationForm onClose={() => setIsEditModeOn(false)} />
                    : <InvitationList onCreateInviteClick={() => setIsEditModeOn(true)} />
            )}
        </div>
    );
}
