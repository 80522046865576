import React, { useState } from 'react';
import { Accordion } from '@common/Accordion';

import { StudentSubspace } from '../Config/interfaces';
import { ModuleCards } from '../ModuleCard';

import classes from './Subspaces.module.scss';

interface Props {
    studentSubspace?: Partial<StudentSubspace>;
}

export function FutureSubspace({ studentSubspace }: Props) {
    const [isOpen, setIsOpen] = useState(true);
    return (
        <Accordion
            additionalSetState={() => setIsOpen(!isOpen)}
            isOpen={isOpen}
            headerClassnames={isOpen ? classes.subspace__open : classes.subspace}
        >
            <div className={classes.subspace__title}>
                {
                    `${studentSubspace?.subspace?.type.name}: ${studentSubspace?.subspace?.name},
                            ${getModuleDeclension(studentSubspace?.studentModules?.length ?? 0)}`
                }
            </div>
            <ModuleCards
                modules={studentSubspace?.studentModules}
            />
        </Accordion>
    );
}

function getModuleDeclension(count: number) {
    if (count === 1) {
        return `${count} модуль`;
    }
    if ((count > 1) && (count < 5)) {
        return `${count} модуля`;
    }

    return `${count} модулей`;
}
