import { observer } from 'mobx-react';
import React from 'react';

import colors from '../../store/ColorConstans';

interface Props {
    handler?: () => void;
    size?: number;
    firstColor?: string;
    secondColor?: string;
    thirdColor?: string;
    className?: string;
}

export const EditIcon = observer(({
    handler,
    size = 24,
    firstColor = colors.firstColor,
    secondColor = colors.secondColor,
    thirdColor = colors.thirdColor,
    className,
}: Props): JSX.Element => (
    <div className={className} style={{ height: `${size}px`, width: `${size}px`, cursor: 'pointer' }} onClick={handler}>
        <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.86304 13.7582L5.00266 17.4714C4.97298 17.6071 4.974 17.7478 5.00564 17.8831C5.03728 18.0184 5.09875 18.1449 5.18554 18.2534C5.27234 18.3619 5.38228 18.4496 5.50733 18.5102C5.63238 18.5708 5.76938 18.6026 5.90832 18.6035C5.97306 18.61 6.0383 18.61 6.10304 18.6035L9.83889 17.7431L17.0117 10.5974L13.0087 6.60346L5.86304 13.7582Z" fill="url(#paint0_linear_1858_7829)" />
            <path d="M19.2668 7.02004L16.5951 4.34834C16.4194 4.17358 16.1817 4.07547 15.934 4.07547C15.6862 4.07547 15.4485 4.17358 15.2728 4.34834L13.7876 5.83362L17.786 9.83212L19.2713 8.34683C19.3582 8.25947 19.4271 8.15583 19.4739 8.04183C19.5207 7.92784 19.5446 7.80573 19.5442 7.6825C19.5438 7.55926 19.5191 7.43732 19.4715 7.32365C19.4239 7.20997 19.3543 7.1068 19.2668 7.02004Z" fill="url(#paint1_linear_1858_7829)" />
            <path fillRule="evenodd" clipRule="evenodd" d="M12 22.6415C17.8771 22.6415 22.6415 17.8771 22.6415 12C22.6415 6.12286 17.8771 1.35849 12 1.35849C6.12286 1.35849 1.35849 6.12286 1.35849 12C1.35849 17.8771 6.12286 22.6415 12 22.6415ZM12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z" fill="url(#paint2_linear_1858_7829)" />
            <defs>
                <linearGradient id="paint0_linear_1858_7829" x1="25.7349" y1="10.6329" x2="-0.35331" y2="20.8164" gradientUnits="userSpaceOnUse">
                    <stop offset="0.130208" stopColor={firstColor} />
                    <stop offset="0.734375" stopColor={secondColor} />
                    <stop offset="0.932292" stopColor={thirdColor} />
                </linearGradient>
                <linearGradient id="paint1_linear_1858_7829" x1="25.7349" y1="10.6329" x2="-0.35331" y2="20.8164" gradientUnits="userSpaceOnUse">
                    <stop offset="0.130208" stopColor={firstColor} />
                    <stop offset="0.734375" stopColor={secondColor} />
                    <stop offset="0.932292" stopColor={thirdColor} />
                </linearGradient>
                <linearGradient id="paint2_linear_1858_7829" x1="25.7349" y1="10.6329" x2="-0.35331" y2="20.8164" gradientUnits="userSpaceOnUse">
                    <stop offset="0.130208" stopColor={firstColor} />
                    <stop offset="0.734375" stopColor={secondColor} />
                    <stop offset="0.932292" stopColor={thirdColor} />
                </linearGradient>
            </defs>
        </svg>
    </div>
));
