import { observer } from 'mobx-react';
import React from 'react';

import colors from '../../store/ColorConstans';

interface Props {
    firstColor?: string;
    secondColor?: string;
    size?: number;
    className?: string;
    handler?: () => void;
}

export const BorderPlusDisabledIcon = observer(({
    handler,
    size = 26,
    className,
    firstColor = colors.firstDisableColor,
    secondColor = colors.secondDisableColor,
}: Props): JSX.Element => (
    <div className={className} style={{ height: `${size}px`, width: `${size}px`, cursor: 'pointer' }} onClick={handler}>
        <svg width={size} height={size} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.03727 14.9438C8.76361 14.9535 8.50438 15.069 8.31419 15.266C8.12401 15.463 8.01772 15.7262 8.01772 16C8.01772 16.2738 8.12401 16.537 8.31419 16.734C8.50438 16.931 8.76361 17.0465 9.03727 17.0562L14.9428 17.0572V22.9617C14.9378 23.1036 14.9614 23.245 15.0123 23.3775C15.0631 23.51 15.1401 23.6309 15.2386 23.733C15.3372 23.8351 15.4553 23.9163 15.586 23.9718C15.7166 24.0273 15.8571 24.0558 15.999 24.0558C16.1409 24.0558 16.2814 24.0273 16.412 23.9718C16.5427 23.9163 16.6608 23.8351 16.7594 23.733C16.8579 23.6309 16.9349 23.51 16.9857 23.3775C17.0366 23.245 17.0602 23.1036 17.0552 22.9617L17.0562 17.0562L22.9617 17.0572C23.2388 17.0525 23.5029 16.9392 23.6973 16.7416C23.8916 16.5441 24.0006 16.2781 24.0007 16.001C24.0008 15.7239 23.8921 15.4578 23.698 15.2601C23.5038 15.0623 23.2398 14.9488 22.9627 14.9438L17.0572 14.9428L17.0562 9.03727C17.0612 8.89543 17.0376 8.75404 16.9867 8.62153C16.9359 8.48902 16.8589 8.36811 16.7604 8.266C16.6618 8.16389 16.5437 8.08268 16.413 8.02721C16.2824 7.97175 16.1419 7.94316 16 7.94316C15.8581 7.94316 15.7176 7.97175 15.587 8.02721C15.4563 8.08268 15.3382 8.16389 15.2396 8.266C15.1411 8.36811 15.0641 8.48902 15.0133 8.62153C14.9624 8.75404 14.9388 8.89543 14.9438 9.03727L14.9428 14.9428L9.03727 14.9438Z" fill="url(#paint0_linear_2702_31254)" />
            <path fillRule="evenodd" clipRule="evenodd" d="M5.03977 5.03977C-1.01326 11.0928 -1.01326 20.9072 5.03977 26.9602C11.0928 33.0133 20.9072 33.0133 26.9602 26.9602C33.0133 20.9072 33.0133 11.0928 26.9602 5.03977C20.9072 -1.01326 11.0928 -1.01326 5.03977 5.03977ZM6.53435 25.4656C4.0239 22.9552 2.61355 19.5503 2.61355 16C2.61355 12.4497 4.0239 9.0448 6.53435 6.53435C9.0448 4.0239 12.4497 2.61355 16 2.61355C19.5503 2.61355 22.9552 4.0239 25.4656 6.53435C27.9761 9.0448 29.3864 12.4497 29.3864 16C29.3864 19.5503 27.9761 22.9552 25.4656 25.4656C22.9552 27.9761 19.5503 29.3864 16 29.3864C12.4497 29.3864 9.0448 27.9761 6.53435 25.4656Z" fill="url(#paint1_linear_2702_31254)" />
            <defs>
                <linearGradient id="paint0_linear_2702_31254" x1="-1.92771" y1="13.5475" x2="34.8616" y2="13.8141" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#C4C4C4" stopOpacity="0.04" />
                    <stop offset="0.453381" stopColor={firstColor} stopOpacity="0.85" />
                    <stop offset="0.901043" stopColor={secondColor} stopOpacity="0.43" />
                </linearGradient>
                <linearGradient id="paint1_linear_2702_31254" x1="-1.92771" y1="13.5475" x2="34.8616" y2="13.8141" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#C4C4C4" stopOpacity="0.04" />
                    <stop offset="0.453381" stopColor={firstColor} stopOpacity="0.85" />
                    <stop offset="0.901043" stopColor={secondColor} stopOpacity="0.43" />
                </linearGradient>
            </defs>
        </svg>
    </div>
));
