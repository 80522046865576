import React, { useState } from 'react';
import { nanoid } from 'nanoid';
import range from 'lodash/range';
import times from 'lodash/times';

import { BorderPlusIcon } from '@common/svg';
import { Switcher } from '@common/Switcher';
import { EducationPageMinMax } from '../EducationPageMinMax';

import classes from '../EducationSpacePage.module.scss';

interface TimeMesureTypes {
    id: string;
    name: string;
}

interface Interval {
    id: string;
    minCreditCount: number;
    maxCreditCount: number;
    order: number;
}

interface Space {
    id: string;
    name: string;
    minCreditCount: number;
    maxCreditCount: number;
    timeMesureId: string;
    quantity: number;
    isDifferentIntervals: boolean;
    spaceBaseTimeIntervals: Interval[];
}

interface Props {
    space: Space;
    setSpace(space: Space): void;
    timeMesureTypes: TimeMesureTypes[];
    localQuantity: number;
    setLocalQuantity(quantity: number): void;
}

export const EducationSpaceTimeMesurement = ({
    space,
    setSpace,
    timeMesureTypes,
    localQuantity,
    setLocalQuantity,
}: Props) => {
    const [typeId, setTypeId] = useState(timeMesureTypes[0].id);
    let labels: string[] = [];

    const fullLabels = () => {
        const label: string[] = [];
        let i = 0;
        while (i < space.quantity) {
            label.push(`Укажите количество ЗЕТ для ${i + 1} временного промежутка:`);
            i += 1;
        }
        return label;
    };

    labels = fullLabels();

    const onChangeQuantity = (quantity: number) => {
        setLocalQuantity(quantity);
        let currentQuantity = 1;
        if (quantity && quantity > 0) {
            currentQuantity = quantity;
        }
        let currentSpaceBTI = [...space.spaceBaseTimeIntervals];
        if (currentQuantity > space.quantity) {
            currentSpaceBTI = [
                ...currentSpaceBTI,
                ...times(currentQuantity - space.quantity, order => ({
                    id: nanoid(),
                    minCreditCount: space.spaceBaseTimeIntervals[0].minCreditCount,
                    maxCreditCount: space.spaceBaseTimeIntervals[0].maxCreditCount,
                    order: space.quantity + order + 1,
                })),
            ];
        } else {
            currentSpaceBTI = currentSpaceBTI.filter(spaceBTI => spaceBTI.order <= currentQuantity);
        }
        setSpace({
            ...space,
            quantity: currentQuantity,
            spaceBaseTimeIntervals: currentSpaceBTI,
        });
        labels = fullLabels();
    };

    const setTimeMesureId = (id: string) => {
        const timeMesureType = timeMesureTypes.find(item => item.id === id)!;

        setTypeId(timeMesureType.id);
        setSpace({ ...space, timeMesureId: timeMesureType.id });
    };

    const onChangeMinMax = (value: number, type: string, index: number, isDelete?: boolean) => {
        if (isDelete) {
            setLocalQuantity(localQuantity - 1);
            const updateBaseTimeIntervals = space.spaceBaseTimeIntervals.map(spaceBTI => {
                if (spaceBTI.order > space.spaceBaseTimeIntervals[index].order) {
                    return {
                        ...spaceBTI,
                        order: spaceBTI.order - 1,
                    };
                }
                return spaceBTI;
            });
            setSpace({
                ...space,
                quantity: space.quantity - 1,
                spaceBaseTimeIntervals: [
                    ...updateBaseTimeIntervals.slice(0, index),
                    ...updateBaseTimeIntervals.slice(index + 1),
                ],
            });
        } else {
            const copy = { ...space };
            if (type === 'min') {
                copy.spaceBaseTimeIntervals[index].minCreditCount = value;
            } else {
                copy.spaceBaseTimeIntervals[index].maxCreditCount = value;
            }
            setSpace(copy);
        }
        if (!space.isDifferentIntervals) {
            setSpace({
                ...space,
                spaceBaseTimeIntervals: [
                    ...times(space.quantity, order => ({
                        ...space.spaceBaseTimeIntervals[order],
                        minCreditCount: space.spaceBaseTimeIntervals[0].minCreditCount,
                        maxCreditCount: space.spaceBaseTimeIntervals[0].maxCreditCount,
                    })),
                ],
            });
        }
    };

    const changeIsDifferent = () => {
        const { minCreditCount, maxCreditCount } = space.spaceBaseTimeIntervals[0];
        const { length } = space.spaceBaseTimeIntervals;
        if (space.quantity > length) {
            let currentSpaceBTI = [...space.spaceBaseTimeIntervals];
            currentSpaceBTI = [
                ...currentSpaceBTI,
                ...times(length - space.quantity, order => ({
                    id: nanoid(),
                    minCreditCount: space.spaceBaseTimeIntervals[0].minCreditCount,
                    maxCreditCount: space.spaceBaseTimeIntervals[0].maxCreditCount,
                    order: space.quantity + order + 1,
                })),
            ];
            setSpace({
                ...space,
                isDifferentIntervals: !space.isDifferentIntervals,
                spaceBaseTimeIntervals: currentSpaceBTI,
            });
        } else if (space.isDifferentIntervals) {
            setSpace({
                ...space,
                isDifferentIntervals: !space.isDifferentIntervals,
                spaceBaseTimeIntervals: range(1, space.quantity + 1).map((order) => ({
                    ...space.spaceBaseTimeIntervals[order - 1],
                    minCreditCount,
                    maxCreditCount,
                })),
            });
        } else {
            setSpace({
                ...space,
                isDifferentIntervals: !space.isDifferentIntervals,
            });
        }
    };

    const addSubspace = () => {
        setLocalQuantity(space.spaceBaseTimeIntervals.length + 1);
        setSpace({
            ...space,
            quantity: space.spaceBaseTimeIntervals.length + 1,
            spaceBaseTimeIntervals: [
                ...space.spaceBaseTimeIntervals,
                {
                    id: nanoid(),
                    minCreditCount: 0,
                    maxCreditCount: 0,
                    order: space.spaceBaseTimeIntervals.length + 1,
                },
            ],
        });
    };

    return (
        <div className={classes.educationSpaceTimeMesurment}>
            <label className={classes.educationSpaceForm__label}>
                Тип временного промежутка:
                <select
                    className={classes.educationSpaceTimeMesurment__select}
                    value={typeId}
                    onChange={({ currentTarget }) => setTimeMesureId(currentTarget.value)}
                    disabled={space.isDifferentIntervals}
                >
                    {timeMesureTypes.map((item) => (
                        <option
                            key={item.id}
                            value={item.id}
                        >
                            {item.name}
                        </option>
                    ))}
                </select>
            </label>
            <label className={classes.educationSpaceTimeMesurment__label}>
                <div className={classes.educationSpaceTimeMesurment_textContainer}>
                    Общее количество временных промежутков в пространстве
                </div>
                <input
                    className={classes.educationSpaceTimeMesurment__selectQuantity}
                    type="number"
                    onChange={({ currentTarget }) => onChangeQuantity(Number(currentTarget.value))}
                    disabled={space.isDifferentIntervals}
                    value={localQuantity && localQuantity > 0 ? localQuantity : undefined}
                />
            </label>
            <div className={classes.educationSpace__switcherBlock}>
                <Switcher
                    onClick={() => changeIsDifferent()}
                    label="Разное количество ЗЕТ во временных промежутках"
                    checked={space.isDifferentIntervals}
                />
                <div>
                    <EducationPageMinMax
                        labels={labels}
                        quantity={space.isDifferentIntervals ? space.quantity : 1}
                        onChangeMinMax={onChangeMinMax}
                        isDelete={space.isDifferentIntervals}
                        values={space.spaceBaseTimeIntervals}
                    />
                    <div
                        className={space.isDifferentIntervals
                            ? classes.timeMesurament__show
                            : classes.timeMesurament__hidden}
                        onClick={() => addSubspace()}
                    >
                        <BorderPlusIcon />
                        <div className={classes.timeMesurament__text}>
                            Добавить временной промежуток
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
