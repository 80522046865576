import React, {
    useEffect,
    useState,
} from 'react';

import { MultiAutosuggest } from '@common/MultiAutosuggest';
import { SortDownIcon } from '@common/svg/SortDownIcon';
import { Select } from '@common/Select';
import { SortUpIcon } from '@common/svg/SortUpIcon';
import { Autosuggest } from '@common/Autosuggest';
import { BorderCrossIcon } from '@common/svg';
import { TeacherAssignmentsToggle } from '../TeacherAssignmentsToggle';

import { TeacherAssignmentsList } from '../TeacherAssignmentsList/TeacherAssignmentsList';

import {
    GetTeacher_teacher_baseTimeIntervalInstancesModules_modules_assignments,
    GetTeacher_teacher_baseTimeIntervalInstancesModules_modules_waves,
    AssignmentInstanceOrder,
    filteredTeacherAssignmentInstances_filteredTeacherAssignmentInstances,
    TeacherAssignmentInstanceStatus,
} from '../../TeacherAssignmentsApolloTypes';

import classes from './TeacherAssignmentsModule.module.scss';

interface FilterAssignmentsFunction {
    (
        waveIds?: string[],
        orderBy?: AssignmentInstanceOrder,
        isCurrentTeacher?: boolean,
        assignmentId?: string,
        assignmentInstanceStatus?: TeacherAssignmentInstanceStatus,
    ): void;
}

interface Props {
    assignments?: filteredTeacherAssignmentInstances_filteredTeacherAssignmentInstances[],
    // eslint-disable-next-line max-len
    assignmentsOptions: GetTeacher_teacher_baseTimeIntervalInstancesModules_modules_assignments[] | null,
    waves: GetTeacher_teacher_baseTimeIntervalInstancesModules_modules_waves[] | null,
    filterAssignments: FilterAssignmentsFunction,
    onClick: () => void,
    // eslint-disable-next-line max-len
    setAssignment: (assignment: filteredTeacherAssignmentInstances_filteredTeacherAssignmentInstances) => void,
}

function getOppositeSort(sort: AssignmentInstanceOrder) {
    if (sort === AssignmentInstanceOrder.startAsc) {
        return AssignmentInstanceOrder.startDesc;
    }

    return AssignmentInstanceOrder.startAsc;
}

function isAscSort(sort: AssignmentInstanceOrder) {
    if (sort === AssignmentInstanceOrder.startAsc) {
        return true;
    }

    return false;
}

export function TeacherAssignmentsModule(
    {
        assignments,
        assignmentsOptions,
        waves,
        onClick,
        setAssignment,
        filterAssignments,
    }: Props,
) {
    const [
        sort,
        setSort,
    ] = useState<AssignmentInstanceOrder>(AssignmentInstanceOrder.startDesc);

    const [
        isCurrentTeacher,
        setIsCurrentTeacher,
    ] = useState(false);

    const [
        selectedAssignment,
        setSelectedAssignment,
        // eslint-disable-next-line max-len
    ] = useState<GetTeacher_teacher_baseTimeIntervalInstancesModules_modules_assignments | null>(null);

    const [
        selectedWaves,
        setSelectedWaves,
    ] = useState<GetTeacher_teacher_baseTimeIntervalInstancesModules_modules_waves[]>([]);

    const [
        selectedStatus,
        setSelectedStatus,
    ] = useState(TeacherAssignmentInstanceStatus.actual);

    useEffect(() => filterAssignments(
        selectedWaves.map(wave => wave.id),
        sort,
        isCurrentTeacher,
        selectedAssignment?.id,
        selectedStatus,
    ), [
        sort,
        isCurrentTeacher,
        selectedAssignment,
        selectedWaves,
        selectedStatus,
    ]);

    return (
        <div className={classes.article__content}>
            <h2 className={classes.article__title}>
                Самостоятельные работы
            </h2>

            <div className={classes.article__wrapper}>
                <div className={classes.sort}>
                    {
                        isAscSort(sort)
                            ? (
                                <SortDownIcon
                                    className={classes.sort__icon}
                                    width={24}
                                    height={24}
                                />
                            )
                            : (
                                <SortUpIcon
                                    className={classes.sort__icon}
                                    width={24}
                                    height={24}
                                />
                            )
                    }

                    <input
                        className={classes.sort__button}
                        type="button"
                        value="Дата окончания"
                        onClick={() => setSort((oldSort) => getOppositeSort(oldSort))}
                    />
                </div>

                <TeacherAssignmentsToggle
                    label="Только с моим участием"
                    className={classes.switcher}
                    checked={isCurrentTeacher}
                    onClick={() => {
                        setIsCurrentTeacher((oldIsCurrentTeacher) => !oldIsCurrentTeacher);
                    }}
                />
            </div>

            <div className={classes.sort__section}>
                <div className={classes.controls}>
                    {
                        selectedAssignment
                            ? (
                                <div className={classes.selectAssigment__selectedOption}>
                                    <h5>{selectedAssignment.topic}</h5>

                                    <button
                                        className={classes.selectAssigment__crossButton}
                                        type="button"
                                        onClick={() => setSelectedAssignment(null)}
                                    >
                                        <BorderCrossIcon
                                            className={classes.popup__icon}
                                            size={24}
                                        />
                                    </button>
                                </div>
                            )
                            : (
                                <Autosuggest
                                    placeholder="Выбрать тему"
                                    options={assignmentsOptions ?? []}
                                    formatOption={(item) => item.topic || ''}
                                    onChange={(item) => setSelectedAssignment(item)}
                                />
                            )
                    }

                    <div className={classes.article__wrap}>
                        <MultiAutosuggest
                            placeholder="Выбрать потоки"
                            options={waves ?? []}
                            onChange={(item) => setSelectedWaves(item)}
                            selectedOptions={selectedWaves}
                            formatOption={(item) => {
                                if (!('index' in item) || item.index === null) return '';
                                return (item.index + 1).toString(10);
                            }}
                        />

                        <Select
                            className={classes.article__select}
                            options={[
                                { id: TeacherAssignmentInstanceStatus.actual, name: 'Актуальные' },
                                { id: TeacherAssignmentInstanceStatus.archived, name: 'Архивные' },
                                { id: TeacherAssignmentInstanceStatus.future, name: 'Будущие' },
                            ]}
                            formatOption={item => item.name}
                            onChange={item => setSelectedStatus(item.id)}
                        />
                    </div>
                </div>
            </div>

            {
                assignments?.length === 0
                    ? (
                        <p className={classes.article__error}>
                            Нет самостоятельных работ
                        </p>
                    )
                    : (
                        <TeacherAssignmentsList
                            assignments={assignments}
                            onClick={onClick}
                            setAssignment={setAssignment}
                        />
                    )
            }
        </div>
    );
}
