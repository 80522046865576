import React from 'react';
import { ModuleMainCharacters } from '../ModuleMainCharacters';
import { ModuleMeetingAndEvalPoint } from '../ModuleMeetingAndEvalPoint';
import { ModuleSkillsList } from '../ModuleSkillsList';

import classes from './ModuleTabsContent.module.scss';

import {
    Module as ModuleType,
} from '../../subSpaceTypes';
import { skills_skills, skillTypesData_skillTypes } from '../../../deprecated-graphql-query-types';

interface Props {
    activeTabs: string;
    currentModule: ModuleType;
    skillTypes: skillTypesData_skillTypes[];
    allSkillsList: skills_skills[];
}

export function ModuleTabsContent({
    activeTabs,
    currentModule,
    skillTypes,
    allSkillsList,
}: Props):JSX.Element {
    return (
        <div className={classes.moduleTabsContent}>
            {activeTabs === 'first' && (
                <ModuleMainCharacters
                    currentModule={currentModule}
                />
            )}
            {activeTabs === 'second' && (
                <ModuleSkillsList
                    currentModule={currentModule}
                    skillTypes={skillTypes}
                    allSkillsList={allSkillsList}
                />
            )}
            {activeTabs === 'third' && (
                <ModuleMeetingAndEvalPoint
                    currentModule={currentModule}
                    skillTypes={skillTypes}
                    allSkillsList={allSkillsList}
                />
            )}
        </div>
    );
}
