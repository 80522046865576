import React from 'react';
import { observer } from 'mobx-react-lite';
import findIndex from 'lodash/findIndex';
import classes from './SVGCanvas.module.scss';
import {
    ArrowEntityCoordinates,
    BTISettings,
    DiagramEntity,
    DiagramEntityCoordinate,
    Fork,
    GroupSlot,
    ModuleSlot,
} from '../subSpaceTypes';
import {
    calculateExtraAbscissaWidth,
    changeCoordinatesOfStartAndEndPoints,
    findEntitiesWithoutIncomingRelations,
    findCoordinatesOfUpperLeftSlotCorner, calculateMaxHeightOfCanvas, calculateWidthOfBTI,
} from './utilities';
import { SVGArrow } from './SVGArrow';
import Diagram from '../store/Diagram';
import { changeCoordinatesToLefMiddleSide } from './utilities/utilities';

interface Props {
    width?: number
    height?: number
}

export const SVGCanvas = observer(({
    width,
    height,
}: Props): JSX.Element => {
    const { btiSettings } = Diagram;

    // eslint-disable-next-line max-len
    const renderNextEntitiesOfEntity = <T extends ModuleSlot | GroupSlot | Fork, N extends ModuleSlot | GroupSlot | Fork>(entity: T, nextEntities: N[] = [], startPoint: DiagramEntityCoordinate, btis: BTISettings[], btiIndex: number)
    : JSX.Element[] => {
        let arrowsFromEntities: JSX.Element[] = [];
        let endPoint: DiagramEntityCoordinate = [0, 0];
        let arrowCoordinates: ArrowEntityCoordinates = {
            startPoint, endPoint,
        };

        nextEntities.forEach(nextEntity => {
            if (entity.spaceBaseTimeIntervalId === nextEntity.spaceBaseTimeIntervalId) {
                endPoint = findCoordinatesOfUpperLeftSlotCorner<N>(
                    btis,
                    btiIndex,
                    nextEntity,
                );

                arrowCoordinates = changeCoordinatesOfStartAndEndPoints<T, N>(
                    {
                        startPoint,
                        endPoint,
                    },
                    entity,
                    nextEntity,
                );

                arrowsFromEntities = [...arrowsFromEntities, (
                    <SVGArrow
                        isRemoveSlotsArrow
                        key={`${entity.id}&&${nextEntity.id}`}
                        id={`${entity.id}&&${nextEntity.id}`}
                        startPoint={arrowCoordinates.startPoint}
                        endPoint={arrowCoordinates.endPoint}
                    />)];
            }

            if (entity.spaceBaseTimeIntervalId !== nextEntity.spaceBaseTimeIntervalId) {
                const nextEntityBtiId: string = nextEntity.spaceBaseTimeIntervalId;
                const nextSlotBtiIndex: number | undefined = findIndex(
                    btis,
                    { id: nextEntityBtiId },
                );

                if (nextSlotBtiIndex) {
                    endPoint = findCoordinatesOfUpperLeftSlotCorner(
                        btis,
                        nextSlotBtiIndex,
                        nextEntity,
                    );

                    arrowCoordinates = changeCoordinatesOfStartAndEndPoints<T, N>(
                        {
                            startPoint,
                            endPoint,
                        },
                        entity,
                        nextEntity,
                        nextSlotBtiIndex,
                    );

                    arrowsFromEntities = [...arrowsFromEntities, (
                        <SVGArrow
                            isRemoveSlotsArrow
                            key={`${entity.id}&&${nextEntity.id}`}
                            id={`${entity.id}&&${nextEntity.id}`}
                            startPoint={arrowCoordinates.startPoint}
                            endPoint={arrowCoordinates.endPoint}
                        />)];
                }
            }
        });

        return arrowsFromEntities;
    };

    const renderArrowsFromEntities = <T extends ModuleSlot | GroupSlot | Fork>(
        btis: BTISettings[],
        entities: T[] = [],
        btiIndex: number,
    ): JSX.Element[] => {
        let arrowsFromEntities: JSX.Element[] = [];
        let startPoint: DiagramEntityCoordinate = [0, 0];

        entities.forEach(entity => {
            if (
                (
                    entity.nextSlots
                    || entity.nextGroupSlots
                    || entity.nextForks
                ) && (
                    entity.nextSlots.length > 0
                    || entity.nextGroupSlots.length > 0
                    || entity.nextForks.length > 0
                )
            ) {
                startPoint = findCoordinatesOfUpperLeftSlotCorner(
                    btis,
                    btiIndex,
                    entity,
                );

                arrowsFromEntities = [
                    ...arrowsFromEntities,
                    ...renderNextEntitiesOfEntity<T, ModuleSlot>(
                        entity,
                        entity.nextSlots,
                        startPoint,
                        btis,
                        btiIndex,
                    )];
                arrowsFromEntities = [
                    ...arrowsFromEntities,
                    ...renderNextEntitiesOfEntity<T, GroupSlot>(
                        entity,
                        entity.nextGroupSlots,
                        startPoint,
                        btis,
                        btiIndex,
                    )];
                arrowsFromEntities = [
                    ...arrowsFromEntities,
                    ...renderNextEntitiesOfEntity<T, Fork>(
                        entity,
                        entity.nextForks,
                        startPoint,
                        btis,
                        btiIndex,
                    )];
            }
        });

        return arrowsFromEntities;
    };

    const renderArrowsFormBtiToEntityWithoutIncomingEntity = (
        btis: BTISettings[],
        bti: BTISettings,
        btiIndex: number,
    ): JSX.Element[] => {
        let arrowsFromBtiToEntities: JSX.Element[] = [];
        let startPoint: DiagramEntityCoordinate = [0, 0];
        let endPoint: DiagramEntityCoordinate = [0, 0];
        // eslint-disable-next-line max-len
        const entitiesWithoutIncomingRelations: DiagramEntity[] = findEntitiesWithoutIncomingRelations(btis);

        entitiesWithoutIncomingRelations.forEach(entity => {
            if (btiIndex === 0 && bti.id === entity.spaceBaseTimeIntervalId) {
                endPoint = findCoordinatesOfUpperLeftSlotCorner(
                    btis,
                    btiIndex,
                    entity,
                );

                endPoint = changeCoordinatesToLefMiddleSide(endPoint);

                const y2 = endPoint[1];

                startPoint = [0, y2];

                arrowsFromBtiToEntities = [...arrowsFromBtiToEntities, (
                    <SVGArrow
                        isRemoveSlotsArrow
                        key={`${entity.id}&&${entity.spaceBaseTimeIntervalId}`}
                        id={`${entity.id}&&${entity.spaceBaseTimeIntervalId}`}
                        startPoint={startPoint}
                        endPoint={endPoint}
                        color="#C4C4C4"
                    />)];
            }

            if (btiIndex > 0 && bti.id === entity.spaceBaseTimeIntervalId) {
                endPoint = findCoordinatesOfUpperLeftSlotCorner(
                    btis,
                    btiIndex,
                    entity,
                );

                endPoint = changeCoordinatesToLefMiddleSide(endPoint);

                const y2 = endPoint[1];

                const x: number = calculateExtraAbscissaWidth(btis, btiIndex);
                startPoint = [x, y2];

                arrowsFromBtiToEntities = [...arrowsFromBtiToEntities, (
                    <SVGArrow
                        isRemoveSlotsArrow
                        key={`${entity.id}&&${entity.spaceBaseTimeIntervalId}`}
                        id={`${entity.id}&&${entity.spaceBaseTimeIntervalId}`}
                        startPoint={startPoint}
                        endPoint={endPoint}
                        color="#C4C4C4"
                    />)];
            }
        });

        return arrowsFromBtiToEntities;
    };

    const renderArrows = (btis: BTISettings[]): JSX.Element[] => {
        let arrows: JSX.Element[] = [];

        btis.forEach((bti, btiIndex) => {
            const slotArrows: JSX.Element[] = renderArrowsFromEntities<ModuleSlot>(
                btis,
                bti.moduleSlots,
                btiIndex,
            );

            const groupsArrows: JSX.Element[] = renderArrowsFromEntities<GroupSlot>(
                btis,
                bti.groupSlots,
                btiIndex,
            );
            const forksArrows: JSX.Element[] = renderArrowsFromEntities<Fork>(
                btis,
                bti.forks,
                btiIndex,
            );
            const arrowsFromEmptyEntities = renderArrowsFormBtiToEntityWithoutIncomingEntity(
                btis,
                bti,
                btiIndex,
            );

            arrows = [...arrows,
                ...slotArrows,
                ...groupsArrows,
                ...forksArrows,
                ...arrowsFromEmptyEntities,
            ];
        });

        return arrows;
    };

    const renderBtiVerticalLines = (btis: BTISettings[]):JSX.Element[] => {
        let verticalLines: JSX.Element[] = [];
        const y2:number = calculateMaxHeightOfCanvas(btis);

        verticalLines = [...verticalLines, (
            <svg>
                <line stroke="rgb(0, 0, 0)" strokeDasharray="2, 2" width={1} x1="0" y1="0" x2="0" y2={y2} />
            </svg>
        )];

        btis.forEach((bti, btiIndex) => {
            const x2 = calculateWidthOfBTI(bti.rowsRange) + calculateExtraAbscissaWidth(
                btis,
                btiIndex,
            );

            verticalLines = [...verticalLines, (
                <svg key={bti.id}>
                    <line stroke="rgb(0, 0, 0)" strokeDasharray="2, 2" width={1} x1={x2} y1="0" x2={x2} y2={y2} />
                </svg>
            )];
        });
        return verticalLines;
    };

    return (
        <svg width={width} height={height} className={classes.svgCanvas}>
            <>
                {renderArrows(btiSettings)}
                {renderBtiVerticalLines(btiSettings)}
                {/* {selectedSlotsForSelectionPoint.length > 0 && renderBTISelectionPointsArrow( */}
                {/*    btiSettings, */}
                {/*    selectedSlotsForSelectionPoint, */}
                {/* )} */}
                {/* {selectedSlotsForSelectionPoint.length === 0 */}
                {/* && renderSelectionPointsArrows(btiSettings)} */}
            </>
        </svg>
    );
});
