/* eslint-disable @typescript-eslint/lines-between-class-members */
export class Rectangle {
    private topLeftX: number;
    private topLeftY: number;
    private bottomRightX: number;
    private bottomRightY: number;
    private width: number;
    private height: number;

    constructor(topLeftX: number, topLeftY: number, width: number, height: number) {
        this.topLeftX = topLeftX;
        this.topLeftY = topLeftY;
        this.bottomRightX = topLeftX + width;
        this.bottomRightY = topLeftY + height;
        this.width = width;
        this.height = height;
    }

    public intercectsWith(rect: Rectangle): boolean {
        const leftInsideRect: boolean = this.topLeftX >= rect.topLeftX
        && this.topLeftX <= rect.bottomRightX;

        const leftRectInside: boolean = rect.topLeftX >= this.topLeftX
        && rect.topLeftX <= this.bottomRightX;

        const topInsideRect: boolean = this.topLeftY >= rect.topLeftY
        && this.topLeftY <= rect.bottomRightY;

        const topRectInside: boolean = rect.topLeftY >= this.topLeftY
        && rect.topLeftY <= this.bottomRightY;

        return (leftInsideRect || leftRectInside) && (topInsideRect || topRectInside);
    }

    public copy(): Rectangle {
        return new Rectangle(
            this.topLeftX,
            this.topLeftY,
            this.width,
            this.height,
        );
    }

    public getCenterY(): number {
        return this.topLeftY + this.height / 2;
    }

    public getTopLeftX() {
        return this.topLeftX;
    }

    public getTopLeftY() {
        return this.topLeftY;
    }

    public setTopLeftY(val: number) {
        this.topLeftY = val;
        this.bottomRightY = val + this.height;
    }

    public getBottomRightX() {
        return this.bottomRightX;
    }

    public getBottomRightY() {
        return this.bottomRightY;
    }

    public getWidth() {
        return this.width;
    }

    public getHeight() {
        return this.height;
    }
}
