import { find, map, trim } from 'lodash';
import { CreateBuildingInput, TerritorialZone } from '@admin/UniversityPage/types';

export const trimStateBuildingsValues = (
    buildings: CreateBuildingInput[],
): CreateBuildingInput[] => map(buildings, (building) => ({
    ...building,
    name: trim(building.name),
    location: trim(building.location),
}));

export const addZoneIdToBuildings = (
    zones: TerritorialZone[],
    buildings: CreateBuildingInput[],
): CreateBuildingInput[] => map(buildings, (building) => ({
    ...building,
    zoneId: find(zones, { name: building.zoneId })!.id,
}));
