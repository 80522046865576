import { useLazyQuery } from '@apollo/client';
import {
    GetBaseTimeIntervalInstancesSchedulePage,
    GetBaseTimeIntervalInstancesSchedulePageVariables,
} from 'src/graphql-query-types';
import { BASE_TIME_INTERVAL_INSTANCES } from '../queries';

export function useBaseTimeIntervalInstancesLazyQuery() {
    const [handler, { data, error, loading }] = useLazyQuery<
    GetBaseTimeIntervalInstancesSchedulePage,
    GetBaseTimeIntervalInstancesSchedulePageVariables
    >(BASE_TIME_INTERVAL_INSTANCES);
    return {
        error,
        loading,
        handler,
        baseTimeIntervalInstances: data?.baseTimeIntervalInstances,
    };
}
