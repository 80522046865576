/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
import { makeAutoObservable } from 'mobx';
import groupBy from 'lodash/groupBy';

import { skillTypesData_skillTypes_name } from '../../../../../../../deprecated-graphql-query-types';
import { FilterType, Skill, Student } from '../../../../../Interfaces';
import {
    SkillType,
    SkillTypeData,
    TypeSettings,
} from '../../../../../Interfaces/scheduleEvents/evaluationPoints/skillTypes/skillTypes';
// eslint-disable-next-line import/no-cycle
import { Grade } from '../EvaluationSection/GradingMenu';
// eslint-disable-next-line import/no-cycle
import { eventsStore } from '../../../../../Store';

interface SkillTypeExtended {
    skills: Skill[];
    childrenId: null | string;
    id: string;
    name: skillTypesData_skillTypes_name;
    parentId: string | null;
}

export enum StudentsTab {
    NOT_EVALUATED,
    EVALUATED,
}

class EvaluationStore {
    selectedStudentsTab: StudentsTab = StudentsTab.NOT_EVALUATED;

    skillTypes: SkillType[] = [];

    skillModuleSettings: TypeSettings[] = [];

    skillTree: SkillTypeExtended[] = [];

    gradeSkills: Grade[] = [];

    tabId: string = '';

    skillTabs: SkillType[] = [];

    skillSettings: TypeSettings[] = [];

    allStudents: Student[] = [];

    notEvaluatedStudents: Student[] = [];

    evaluatedStudents: Student[] = [];

    searchQuery: string = '';

    constructor() {
        makeAutoObservable(this);
        this.setupStudents();
    }

    setupStudents = (): void => {
        if (eventsStore?.selectedEvent?.type === FilterType.EVALUATION_POINT) {
            const allStudents = eventsStore.selectedEvent?.evaluationPointInstanceToStudents;

            this.allStudents = allStudents;
            this.notEvaluatedStudents = allStudents.filter((student) => !student.status);
            this.evaluatedStudents = allStudents.filter((student) => student.status);
        }
    };

    getTabStudents = (): Student[] => {
        if (this.selectedStudentsTab === StudentsTab.NOT_EVALUATED) {
            return this.notEvaluatedStudents;
        }
        if (this.selectedStudentsTab === StudentsTab.EVALUATED) {
            return this.evaluatedStudents;
        }

        return [];
    };

    selectStudentsTab = (studentsTab: StudentsTab): void => {
        this.selectedStudentsTab = studentsTab;
    };

    isTabSelected = (studentsTab: StudentsTab): boolean => (
        this.selectedStudentsTab === studentsTab
    );

    isTabSkillsLevelIncreased = (skillTypeId: string): boolean => {
        const parentTypeId = this.getInitSkillTypeId(skillTypeId);
        const currentSkillSetting = this.skillModuleSettings
            .find((skillModuleSetting) => (skillModuleSetting.id === parentTypeId));

        if (currentSkillSetting) {
            return currentSkillSetting.isLevelIncreased;
        }

        return false;
    };

    getInitSkillTypeId = (skillTypeId: string) => {
        const skillTypeGroups = groupBy(this.skillTypes, 'id');
        let parent = skillTypeId;
        let initId = skillTypeId;
        while (skillTypeGroups[parent]) {
            const [child] = skillTypeGroups[parent];
            parent = child.parentId || '';
            initId = child.id;
        }

        return initId;
    };

    setSkillTypes = (skill: SkillTypeData) => {
        this.skillTypes = skill.skillTypes;
        this.skillModuleSettings = [
            ...skill.moduleSettings.moduleSkillTypes,
            ...skill.moduleSettings.meetingSkillTypes,
        ];
    };

    setSkillTree = () => {
        if (eventsStore.selectedEvent?.type === FilterType.EVALUATION_POINT) {
            const skills = eventsStore.selectedEvent.evaluationPoint.evaluationPointSkills;
            const skillTree: SkillTypeExtended[] = this.skillTypes
                .map((skillType) => ({ ...skillType, skills: [], childrenId: null }));
            skills.forEach((skill: Skill) => {
                const foundSkillType = skillTree
                    .find((skillType) => skillType.id === skill.skill.typeId);
                if (foundSkillType) {
                    foundSkillType.skills.push(skill);
                }
            });
            skillTree.forEach((skillTypeExtended) => {
                if (skillTypeExtended.parentId !== null) {
                    const foundSkillType = skillTree.find(
                        (skillTypeExtendedToFound) => (
                            skillTypeExtendedToFound.id === skillTypeExtended.parentId
                        ),
                    );
                    if (foundSkillType) {
                        foundSkillType.childrenId = skillTypeExtended.id;
                    }
                }
            });
            this.skillTree = skillTree;
        }
    };

    getDefaultEvaluationGrades = () => {
        if (eventsStore.selectedEvent?.type === FilterType.EVALUATION_POINT) {
            const gradeSkills = eventsStore
                .selectedEvent.evaluationPoint.evaluationPointSkills
                .map(({
                    level: creditedLevel,
                    skill: {
                        maxLevel,
                        fullName,
                        id: skillId,
                        typeId,
                    },
                }) => ({
                    level: this.isTabSkillsLevelIncreased(typeId) ? 0 : null,
                    skillId,
                    skillName: fullName,
                    maxLevel,
                    creditedLevel,
                    isEvaluated: true,
                    isCredited: false,
                }));
            return gradeSkills;
        }
        return [];
    };

    getResultsEvaluationGrades = (studentId: string) => {
        if (eventsStore.selectedEvent?.type === FilterType.EVALUATION_POINT) {
            const defaultEvaluationGrades = this.getDefaultEvaluationGrades();
            eventsStore.selectedEvent.evaluationResults
                .forEach((skillResult) => {
                    const foundDefaultSkill = defaultEvaluationGrades
                        .find((defaultSkill) => (
                            skillResult.skillId === defaultSkill.skillId
                            && skillResult.student.id === studentId
                        ));
                    if (foundDefaultSkill) {
                        foundDefaultSkill.level = skillResult.level;
                        foundDefaultSkill.isEvaluated = skillResult.isEvaluated;
                        foundDefaultSkill.isCredited = skillResult.isCredited;
                    }
                });
            return defaultEvaluationGrades;
        }
        return [];
    };

    setSkillTabs = () => {
        const tabs = this.skillTree.filter((item) => item.parentId === null);
        this.skillTabs = tabs;
    };

    getSkillsDueToTab = (tabId: string): Skill[] => {
        const skillType = this.skillTree.find((skillTypeExtended) => (
            skillTypeExtended.id === tabId));
        if (skillType) {
            if (skillType.childrenId === null) {
                return skillType.skills;
            }
            return this.getSkillsDueToTab(skillType.childrenId);
        }
        return [];
    };

    setSearchQuery = (searchQuery: string): void => {
        this.searchQuery = searchQuery.replace(/[^a-zA-Zа-яА-Я ]*/g, '');
    };

    getFilteredStudentsBySearchQuery = (): Student[] => [...this.getTabStudents()]

        .sort((student) => {
            const { user } = student.student;
            const { lastName, firstName, patronymic } = user;
            const fullName = `${lastName} ${firstName} ${patronymic}`;

            return fullName.toLowerCase().includes(this.searchQuery.toLowerCase()) ? -1 : 1;
        });

    isStudentEvaluated = (id: string): boolean => (
        this.evaluatedStudents.some(({ student }) => student.id === id)
    );
}

export const evaluationStore = new EvaluationStore();
