import React from 'react';

import { Alert } from '@common/Alert';

import { Loader } from '@common/Loader';
import { gql, useQuery, useMutation } from '@apollo/client';

import { observer } from 'mobx-react-lite';
import { SkillPage } from './SkillPage';

import { skillTypesData_skillTypes } from '../../deprecated-graphql-query-types';
import SkillType from '../../store/SkillType';

interface Props {
    skillType: skillTypesData_skillTypes;
}

const GET_SKILL_TYPE_CHILD = gql`
  query skillsChild($typeIds: [String!]) {
    skills(
      options: {typeIds: $typeIds}
    ) {
      id
      name
      typeId
      maxLevel
      description
      children {
        id
        name
        typeId
        maxLevel
        description
        children {
          id
          name
          typeId
          maxLevel
          description
          children {
            id
            name
            typeId
            maxLevel
            description
          }
        }
      }
    }
  }
`;

const CREATE_SKILL = gql`
  mutation createSkill(
    $id: String!
    $name: String!
    $typeId: String!
    $description: String! 
    $maxLevel: Int!
  ) {
    createSkill(
      createSkillInput: {
        id: $id
        name: $name
        typeId: $typeId
        description: $description
        maxLevel: $maxLevel
      }
    ) {
      name
      description
    }
  }
`;

const CREATE_NEST_SKILL = gql`
  mutation createNestedSkill(
    $id: String!
    $name: String!
    $typeId: String!
    $children: [CreateSkillInput!]
    $dependencies: [CreateSkillDependencyInput!]
  ) {
    createSkill(
      createSkillInput: {
        id: $id
        name: $name
        typeId: $typeId
        children: $children
        dependencies: $dependencies
      }
    ) {
      id
      name
      typeId
    }
  }
`;

const REMOVE_SKILLS = gql`
  mutation deleteSkill($id: String!) {
    deleteSkill(id: $id) {
        result
    }
  }
`;

const UPDATE_SKILL = gql`
  mutation updateSkill(
    $id: String!
    $name: String!
    $typeId: String!
    $maxLevel: Int
    $description: String
  ) {
    updateSkill(
      updateSkillInput: {
        id: $id
        name: $name
        typeId: $typeId
        description: $description
        maxLevel: $maxLevel
      }
    ) {
      id
    }
  }
`;

const UPDATE_NEST_SKILL = gql`
mutation updateNestSkill(
  $id: String!
  $name: String!
  $typeId: String!
  $children: [UpdateSkillInput!]
  $maxLevel: Int
  $dependencies: [UpdateSkillDependencyInput!]
) {
  updateSkill(
    updateSkillInput: {
      id: $id
      name: $name
      typeId: $typeId
      children: $children
      dependencies: $dependencies
      maxLevel: $maxLevel
    }
  ) {
    id
    name
    typeId
  }
}
`;

export const SkillPageApollo = observer(({
    skillType,
}: Props): JSX.Element => {
    const { skillTypes } = SkillType;

    const [createSkillInput] = useMutation(CREATE_SKILL, {
        refetchQueries: [{ query: GET_SKILL_TYPE_CHILD, variables: { typeIds: skillType.id } }],
    });
    const [createNestSkillInput] = useMutation(CREATE_NEST_SKILL, {
        refetchQueries: [{ query: GET_SKILL_TYPE_CHILD, variables: { typeIds: skillType.id } }],
    });
    const [removeSkill, { error: removeSkillError }] = useMutation(REMOVE_SKILLS, {
        refetchQueries: [{ query: GET_SKILL_TYPE_CHILD, variables: { typeIds: skillType.id } }],
    });
    const [updateSkill] = useMutation(UPDATE_SKILL, {
        refetchQueries: [{ query: GET_SKILL_TYPE_CHILD, variables: { typeIds: skillType.id } }],
    });
    const [updateNestSkill] = useMutation(UPDATE_NEST_SKILL, {
        refetchQueries: [{ query: GET_SKILL_TYPE_CHILD, variables: { typeIds: skillType.id } }],
    });

    const { error, loading, data } = useQuery(GET_SKILL_TYPE_CHILD, {
        variables: { typeIds: skillType.id },
    });
    if (loading) return <Loader />;
    if (error) return <>`Error! ${error.message}`</>;

    return (
        <>
            <SkillPage
                skillType={skillType}
                skillTypes={skillTypes}
                skillsList={data.skills}
                addSkill={(id, name, typeId, description?, maxLevel?) => createSkillInput({
                    variables: { id, name, typeId, description, maxLevel },
                }).catch(() => { })}
                addNestSkill={(id, name, typeId, children, dependencies) => createNestSkillInput({
                    variables: { id, name, typeId, children, dependencies },
                }).catch(() => { })}
                removeSkill={(id: string) => removeSkill({
                    variables: { id },
                }).catch(() => { })}
                updateSkill={(id, name, typeId, description, maxLevel) => updateSkill({
                    variables: { id, name, typeId, description, maxLevel },
                }).catch(() => { })}
                updateNestSkill={(
                    id, name, typeId, children, dependencies, maxLevel,
                ) => updateNestSkill({
                    variables: { id, name, typeId, children, dependencies, maxLevel },
                }).catch(() => { })}
            />

            {removeSkillError && <Alert message={removeSkillError.message} time={3000} />}
        </>
    );
});
