import { Wysiwyg } from '@common/Wysiwyg';
import React, { useState } from 'react';
// @ts-ignore
import ReactHtmlParser from 'react-html-parser';
import { Preview } from './Preview';
import { Textarea } from './Textarea';
import classes from './TextPreview.module.scss';

interface Props {
    value?: string;
    name: string;
    label?: string
    placeholder: string;
    modifier?: string;
    isWysiwyg?: boolean;
    onChange?(event: React.ChangeEvent<HTMLInputElement>): void;
    onChangeWysiwyg?: any;
    previewName?: string;
}

export const TextPreview = ({
    value, name, label, modifier, previewName,
    placeholder, onChange, isWysiwyg = false, onChangeWysiwyg,
}: Props):JSX.Element => {
    const [isPreview, setIsPreview] = useState<boolean>(true);
    const handleEditClick = () => {
        setIsPreview(true);
    };
    const handleSaveClick = () => {
        if (value !== '') setIsPreview(false);
    };
    return (
        <>
            {isPreview
                ? (
                    <div className={classes.textPreview}>
                        {isWysiwyg
                            ? (
                                <Wysiwyg
                                    placeholder={placeholder}
                                    label={label}
                                    value={value}
                                    onChange={onChangeWysiwyg}
                                />
                            )
                            : (
                                <Textarea
                                    placeholder={placeholder}
                                    label={label}
                                    modifier={modifier}
                                    name={name}
                                    value={value}
                                    onChange={onChange}
                                />
                            )
                        }
                        <button
                            type="button"
                            className={classes.textPreview__button}
                            onClick={handleSaveClick}
                        >
                            Предпросмотр {previewName}
                        </button>
                    </div>
                )
                : (
                    <Preview
                        previewText={value ? ReactHtmlParser(value) : ''}
                        onEdit={handleEditClick}
                        label={label}
                    />
                )
            }
        </>
    );
};
