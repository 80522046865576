import React from 'react';
import { observer } from 'mobx-react-lite';
import { ActionButton, ActionTypeOfButton } from '@common/ActionButton';
import { Checkbox } from '@admin/Trajectories/Checkbox';
import { Alert } from '@common/Alert';
import { usePublishTrajectoriesMutation } from '@admin/Trajectories/hooks/usePublishTrajectoriesMutation';
import { trajectoryStore } from '../TrajectoryForm/Store/TrajectoryStore';

import classes from './TrajectoriesList.module.scss';

export const TrajectoriesListSettings = observer((): JSX.Element => {
    const {
        selectedTrajectoriesIds, trajectories, allTrajectoriesSelected,
        unselectAll, spaceId, toggleAllTrajectoriesSelected,
    } = trajectoryStore;
    const { publishTrajectories, publishError } = usePublishTrajectoriesMutation(spaceId);
    const handlePublish = () => {
        publishTrajectories(selectedTrajectoriesIds)
            .catch(() => { });
        unselectAll();
    };
    return (
        <>
            <div className={classes.trajectoriesListSettings}>
                <Checkbox
                    checked={allTrajectoriesSelected}
                    onChange={() => toggleAllTrajectoriesSelected()}
                    label="Выбрать все"
                    modifier={selectedTrajectoriesIds.length !== 0
                        && selectedTrajectoriesIds.length !== trajectories.length ? 'minus' : ''}
                />
                <ActionButton
                    onClick={handlePublish}
                    disabled={(!allTrajectoriesSelected && !selectedTrajectoriesIds.length)}
                    actionType={ActionTypeOfButton.SECONDARY}
                >
                    Опубликовать
                </ActionButton>
            </div>
            {publishError && <Alert message={publishError.message} time={3000} />}
        </>
    );
});
