import React from 'react';
import { ApolloError } from '@apollo/client';

import { Loader } from '@common/Loader';
import { Alert } from '@common/Alert';

import { Conflicts } from './RoleConflictsDashboardInterfaces';
import classes from './RoleConflictsDashboard.module.scss';
import { ModulesList } from './ModulesList';
import { Filters } from './Filters';
import { TimeSlotsList } from './TimeSlotsList';
import { Legend } from './Legend';

interface Props {
    occupation?: Conflicts;
    getOccupation(
        fromDate: string,
        toDate: string,
        conflictTypes: string[],
        scaleType: string
    ): void;
    loading?: boolean;
    error?: ApolloError;
}

export const OccupationDashboard = (
    {
        occupation,
        getOccupation,
        loading,
        error,
    }: Props,
): JSX.Element => (
    <div className={classes.occupationDashboard}>
        <Filters getOccupation={getOccupation} />
        {loading
            ? <Loader />
            : occupation && (
                <div className={classes.occupationDashboard__table}>
                    <div className={classes.occupationDashboard__modules}>
                        <Legend />
                        <ModulesList occupation={occupation} />
                    </div>
                    <TimeSlotsList
                        teacherTimeSlotsList={occupation?.teacherRolesConflictsList}
                    />
                </div>
            )
        }
        {error && (
            <Alert
                message={error?.graphQLErrors?.[0].message || error.message}
                time={3000}
            />
        )}
    </div>
);
