/* eslint-disable react/prop-types */
import React from 'react';
import {
    GetEquipmentsOnRoomPage_equipment_list, RepeatingAvailableIntervalInput,
    RoomAvailableIntervalInput, RoomsPage_Rooms_rooms_location,
} from 'src/graphql-query-types';

import { RoomAvailableNew } from '@admin/RoomsPage/RoomForm/RoomItems/RoomAvailableNew';
import { RoomZones } from '../RoomForm/RoomZones';
import { RoomTitle } from '../RoomForm/RoomTitle';
import { RoomSpace } from '../RoomForm/RoomSpace';
import { RoomTypes } from '../RoomForm/RoomTypes';
import { AddItemsContainer } from '../RoomForm/RoomItems/AddItemsContainer';

import {
    RoomCreationEquipment,
    RoomMeetingFormat,
    Building,
} from '../RoomTypes';

interface RoomCreationProps {
    id?: string;
    universityTimeZone: string;
    types: RoomMeetingFormat[];
    title: string | number;
    buildings: Building[];
    defaultAddress: string;
    capacity: number;
    storeEquipment?: GetEquipmentsOnRoomPage_equipment_list[];
    equipmentsRoom: RoomCreationEquipment[];
    isAvailable: boolean;
    location?: RoomsPage_Rooms_rooms_location;
    availableIntervals: Omit<RoomAvailableIntervalInput, '__typename'>[];
    repeatingAvailableIntervals: RepeatingAvailableIntervalInput[];
    chooseZone(value: string): void;
    handleRoomTitle(event: React.ChangeEvent<HTMLInputElement>): void;
    handleRoomCapacity(event: React.ChangeEvent<HTMLInputElement>): void;
    handleRoomType(event: RoomMeetingFormat[]): void;
    addRoomItems({ id, count, name }: RoomCreationEquipment): void;
    removeRoomItems(id: string): void;
    handlerAvailable: React.Dispatch<React.SetStateAction<boolean>>;
    handlerRepeatingAvailableIntervals: (
        newIntervals: RepeatingAvailableIntervalInput[]
    ) => void;
    handlerAvailableIntervals: React.Dispatch<React.SetStateAction<
    Omit<RoomAvailableIntervalInput, '__typename'>[]
    >>
}

export function RoomCreation({
    id,
    universityTimeZone,
    title,
    types,
    capacity,
    buildings,
    defaultAddress,
    storeEquipment,
    equipmentsRoom,
    isAvailable,
    location,
    availableIntervals,
    repeatingAvailableIntervals,
    handlerAvailable,
    chooseZone,
    addRoomItems,
    handleRoomType,
    handleRoomTitle,
    handleRoomCapacity,
    removeRoomItems,
    handlerAvailableIntervals,
    handlerRepeatingAvailableIntervals,
}: RoomCreationProps): JSX.Element {
    return (
        <>
            <RoomZones
                chooseZone={chooseZone}
                buildings={buildings}
                defaultAddress={defaultAddress}
                id={id}
            />
            <RoomTitle
                title={title}
                handleRoomTitle={handleRoomTitle}
            />
            <RoomSpace
                capacity={capacity}
                handleRoomCapacity={handleRoomCapacity}
            />
            <RoomTypes
                types={types}
                handleRoomType={handleRoomType}
            />
            <AddItemsContainer
                storeEquipment={storeEquipment}
                equipmentsRoom={equipmentsRoom}
                addRoomItems={addRoomItems}
                removeRoomItems={removeRoomItems}
            />
            <RoomAvailableNew
                universityTimeZone={universityTimeZone}
                isAvailable={isAvailable}
                location={location}
                availableIntervals={availableIntervals}
                repeatingAvailableIntervals={repeatingAvailableIntervals}
                handlerAvailable={handlerAvailable}
                handlerAvailableIntervals={handlerAvailableIntervals}
                handlerRepeatingAvailableIntervals={handlerRepeatingAvailableIntervals}
            />
        </>
    );
}
