import React, { useState } from 'react';
import {
    useQuery,
    OperationVariables,
    LazyQueryHookOptions,
} from '@apollo/client';

import { Alert } from '@common/Alert';

import {
    SourceTypeInterface,
    SelectApolloProps as Props,
    defaultQuery,
} from './SelectInterfaces';
import { Select } from './Select';

export function SelectApollo<SourceType extends SourceTypeInterface>(
    {
        selectedOption,
        className,
        options,
        urlParameterName,
        query = defaultQuery,
        onChange = () => {},
        formatOption = (option) => option.id,
        transformResponse = (items) => items,
    } : Props<SourceType>,
): JSX.Element | null {
    const [errorAlerts, setErrorAlerts] = useState<JSX.Element[] | []>([]);

    function addError(message: string) {
        setErrorAlerts((arr) => [...arr, (<Alert
            key={new Date().toISOString()}
            message={message}
        />)]);
    }

    const {
        data: remoteData,
        loading,
    } = useQuery<{ options: SourceType[] }>(
        query,
        {
            skip: !!(options),
            onError: (error) => addError(error.message),
            fetchPolicy: 'cache-and-network',
        } as LazyQueryHookOptions<{ options: SourceType[]; }, OperationVariables>,
    );

    if (loading) {
        return null;
    }

    return (
        <>
            <Select
                className={className}
                options={options ?? transformResponse(remoteData)}
                selectedOption={selectedOption}
                urlParameterName={urlParameterName}
                addError={addError}
                formatOption={formatOption}
                onChange={onChange}
            />
            {errorAlerts}
        </>
    );
}
