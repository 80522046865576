/* eslint-disable max-len */
import React, { useState } from 'react';
import { addDays, format, isAfter, nextMonday } from 'date-fns';

import { useCreateAdditionalGenerationMutation } from '@admin/ScheduleGenerationPage/graphql';
import { ActionButton } from '@common/ActionButton';
import { Alert } from '@common/Alert';

const DEFAULT_START_DATE = nextMonday(new Date());
const DEFAULT_END_DATE = addDays(DEFAULT_START_DATE, 6);

type Props = {
    baseTimeIntervalInstanceId: string;
    refetchProcesses(): void;
};

export function CreateAdditionalGenerationForm({
    baseTimeIntervalInstanceId, refetchProcesses,
}: Props) {
    const [
        createAdditionalGeneration,
        mutationError,
    ] = useCreateAdditionalGenerationMutation(onCompleted);
    function onCompleted() {
        refetchProcesses();
        setOpen(false);
    }
    const [name, setName] = useState('');
    const [period, setPeriod] = useState({
        from: format(DEFAULT_START_DATE, 'yyyy-MM-dd'),
        to: format(DEFAULT_END_DATE, 'yyyy-MM-dd'),
    });
    const [error, setError] = useState('');
    const [isOpen, setOpen] = useState(false);
    return (
        <>
            {
                isOpen ? (
                    <div>
                        Название периода:
                        <br />
                        <input type="text" value={name} onChange={({ target }) => setName(target.value)} />
                        <br />
                        <br />
                        <input
                            type="date"
                            value={period.from}
                            onChange={({ target }) => setPeriod({ ...period, from: target.value })}
                        />
                        <span> — </span>
                        <input
                            type="date"
                            value={period.to}
                            onChange={({ target }) => setPeriod({ ...period, to: target.value })}
                        />
                        <br />
                        <br />
                        <ActionButton
                            onClick={() => {
                                const from = new Date(period.from).toISOString().replace('.000Z', '');
                                const to = new Date(period.to).toISOString().replace('00:00:00.000Z', '23:59:59');
                                if (validate(from, to, name, setError)) {
                                    createAdditionalGeneration({
                                        baseTimeIntervalInstanceIds: [baseTimeIntervalInstanceId],
                                        name,
                                        from,
                                        to,
                                    });
                                }
                            }}
                        >
                            Создать
                        </ActionButton>
                        {error && <div style={{ color: 'red' }}>{error}</div>}
                    </div>
                ) : (
                    <ActionButton onClick={() => setOpen(true)}>Создать период</ActionButton>
                )
            }
            {
                mutationError
                && (<Alert message={mutationError.graphQLErrors[0].message} time={15000} />)
            }
        </>
    );
}

function validate(
    from: string,
    to: string,
    name: string,
    setError: (text: string) => void,
): boolean {
    if (!isAfter(new Date(to), new Date(from))) {
        setError('Дата начала не может быть позже даты конца или равна ей');
        return false;
    }
    if (!name) {
        setError('Введите название периода догенерации');
        return false;
    }
    return true;
}
