import React from 'react';
import { IconDeprecated } from '@common';

import classes from './EquipmentSuggestion.module.scss';

import { ByRoom } from '../equipmentTypes';

interface Props {
    equipment: ByRoom;
    index: number;
    handlerRemoveRooms: (index: number) => void;
}

export function EquipmentSuggestionItem({
    equipment,
    index,
    handlerRemoveRooms,
}: Props): JSX.Element {
    return (
        <div className={classes.equipmentSuggestion__itemContainer}>
            <div>
                {equipment.room.title}
            </div>
            <div className={classes.equipmentSuggestion__crossIconContainer}>
                <IconDeprecated
                    id="Cross"
                    className={classes.equipmentSuggestion__crossIcon}
                    click={() => handlerRemoveRooms(index)}
                />
            </div>
        </div>
    );
}
