import React, { useState } from 'react';
import { NotDistributedAccordion } from '@admin/WaveSelectionPage/NotDistribAccordion/NotDistributedAccordion';
import { Alert } from '@common/Alert';
import { nanoid } from 'nanoid';
import { useMutation } from '@apollo/client';
import { DeleteStudentWaveInput, DeleteStudentWaveVariables } from '@admin/WaveSelectionPage/graphql-types';
import { DELETE_STUDENT_WAVE } from '@admin/WaveSelectionPage/WaveSelectionQueries';

export function NotDistribAccordionApollo() {
    const [
        deleteStudentWaveError,
        setDeleteStudentWaveError,
    ] = useState<JSX.Element[]>([]);
    const addError = (message: string) => setDeleteStudentWaveError((arr) => [...arr, (<Alert
        key={nanoid()}
        message={message}
        time={7000}
    />)]);

    const [deleteStudentWaveMutation] = useMutation<DeleteStudentWaveVariables>(
        DELETE_STUDENT_WAVE, {
            onError: (error) => addError(error.message),
        },
    );

    const deleteStudentModule = (input: DeleteStudentWaveInput) => deleteStudentWaveMutation({
        variables: {
            DeleteStudentWaveInput: {
                ...input,
            },
        },
    });

    return (
        <>
            <NotDistributedAccordion
                deleteStudentWave={deleteStudentModule}
            />
            {
                deleteStudentWaveError
            }
        </>
    );
}
