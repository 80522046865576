import React from 'react';
import classes from './SwitcherTemp.module.scss';

interface Props {
    label?: string;
    onClick?(): void;
    checked?: boolean;
}

export function SwitcherTemp({
    label,
    onClick,
    checked,
}: Props): JSX.Element {
    return (
        <label className={classes.switcher}>
            <input
                type="checkbox"
                defaultChecked={checked}
            />
            <div
                className={classes.switcher__slider}
                onClick={onClick}
            />
            {label && (
                <div
                    className={classes.switcher__text}
                    onClick={onClick}
                >
                    {label}
                </div>
            )}
        </label>
    );
}
