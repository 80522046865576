import { sortBy } from 'lodash';

interface Wave {
    index: number;
}

const MIN_DISTANCE = 2;

export function formatWaves(waves: Wave[]): string {
    const indices = sortBy(waves, 'index').map(wave => wave.index + 1);
    const series = makeSeries(indices);

    return indices.length > 1
        ? `${seriesToString(series)} потоки`
        : `${indices[0]} поток`;
}

function makeSeries(indices: number[]) {
    const result = [];
    let series: number[] = [indices[0]];
    let lastIndex;
    let index;

    for (let i = 1; i < indices.length; i += 1) {
        index = indices[i];
        lastIndex = series[series.length - 1];

        if (index === lastIndex + 1) {
            series.push(index);
        } else {
            result.push(series);
            series = [index];
        }
    }

    result.push(series);

    return result;
}

function seriesToString(series: number[][]): string {
    return series
        .map(item => (item.length > MIN_DISTANCE ? `${item[0]}—${item[item.length - 1]}` : item.join(', ')))
        .join(', ');
}
