import React from 'react';

import { ModuleCard } from '@common/ModuleCard';
import styles from './ModulesList.module.scss';

import { Module as ModuleType } from '../../../../subSpaceAdmin/subSpaceTypes';

interface Props {
    path: string;
    modules: ModuleType[];

    removeModule(id: string): void;
}

export const ModulesList = ({
    modules, removeModule, path,
}: Props) => (
    <div className={styles.modulesList}>
        {modules.map(module => (
            <div className={styles.modulesList__item} key={module.id}>
                <ModuleCard
                    currentModule={module}
                    path={path}
                    handleRemoveModule={() => removeModule(module.id)}
                />
            </div>
        ))}
    </div>
);
