import React from 'react';
import { observer } from 'mobx-react';

import { Title, TitleTypes } from '@common/Title';
import { Pendant, PendantType } from '@admin/NewModule/Pendant';
import { Input, InputTypes } from '@admin/NewModule/CustomInput';

import styles from './MinMax.module.scss';

interface Props {
    min?: number | string;
    max?: number | string;
    title: string;
    minName?: string;
    maxName?: string;
    placeholder?: string;
    canBeZero?: boolean;
    validate?: boolean;

    onChangeMin(event: any): void;

    onChangeMax(event: any): void;
}

const validateNumber = (num: number | string, canBeZero: boolean) => (canBeZero
    ? String((num >= 0) ? num : '')
    : String((num && num > 0) ? num : ''));

export const MinMax = observer(({
    min,
    max,
    title,
    minName = '',
    maxName = '',
    onChangeMin,
    onChangeMax,
    placeholder = 'Кол-во',
    canBeZero = false,
    validate = true,
}: Props) => (
    <>
        <Title type={TitleTypes.Subtitle}>
            {title}
        </Title>
        <div className={styles.minmax}>
            <Input
                name={minName}
                label="min:"
                placeholder={placeholder}
                type={InputTypes.Number}
                value={validateNumber(min ?? '', canBeZero)}
                onChange={onChangeMin}
            />
            <div className={styles.minmax__max}>
                <Input
                    name={maxName}
                    label="max:"
                    placeholder={placeholder}
                    type={InputTypes.Number}
                    value={validateNumber(max ?? '', canBeZero)}
                    onChange={onChangeMax}
                />
            </div>
        </div>
        {(validate || (!validate && min !== undefined && max !== undefined))
        && (Number(min ?? 0) > Number(max ?? 0) && (
            <div className={styles.minmax__warning}>
                <Pendant type={PendantType.Danger}>
                    <div className={styles.minmax__warningText}>
                        Минимальное значение не может быть больше максимального
                    </div>
                </Pendant>
            </div>
        ))}
    </>
));
