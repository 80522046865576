import React from 'react';

// import { Button } from '@admin/NewModule/CustomButton/CustomButton';
import { Icon } from '@admin/NewModule/CustomIcon/Icon';
import { Button } from '../Tools/CustomButton/CustomButton';

import classes from './ToolButton.module.scss';

export interface SimpleToolButtonProps {
    text?: string;
    icon: string;
    disable?: boolean;
    modifier?: string
    onClick?: () => void;
}

export const SimpleToolButton = ({
    text = '',
    icon,
    disable = false,
    modifier = 'add_border',
    onClick,
}: SimpleToolButtonProps) => (
    <Button modifier={modifier} onClick={onClick} disabled={disable}>
        <div className={classes.toolButton__icon}>
            <Icon id={icon} />
        </div>
        {text && (
            <span className={classes.toolButton__text}>
                {text}
            </span>
        )}
    </Button>
);
