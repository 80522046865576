import React from 'react';

import {
    IntervalInfo,
    ModuleGroupWithAdditionalFields,
} from '@admin/ModuleSelectionPage/Interfaces';

import classes
    from '@admin/ModuleSelectionPage/ModuleStudentsAccordion/ModuleStudentItem/ModuleStudentItem.module.scss';
import { MinInterval } from '@admin/ModuleSelectionPage/MinInterval';
import { MaxInterval } from '@admin/ModuleSelectionPage/MaxInterval';
import cn from 'classnames';

interface Props {
    order: number;
    currentModuleGroup: ModuleGroupWithAdditionalFields,
    minIntervalInfo: IntervalInfo;
    maxIntervalInfo: IntervalInfo;
}

export function EmptyStudentModuleItem(
    {
        order,
        currentModuleGroup,
        minIntervalInfo,
        maxIntervalInfo,
    }: Props,
) {
    const isMinIntervalRed = currentModuleGroup.students.length
        < currentModuleGroup.module.minStudentCount;

    const isMaxIntervalRed = currentModuleGroup.students.length
        > currentModuleGroup.module.maxStudentCount
        && currentModuleGroup.module.maxWaveCount === currentModuleGroup.module.currentWaveIndex;

    return (
        <>
            <div
                className={cn(classes.item, classes.item_empty)}
            >

                <div
                    className={cn(classes.item__static_block, {
                        [classes.item_borderRed]:
                        currentModuleGroup.module.minStudentCount === order,
                        [classes.item_borderBlue]:
                        currentModuleGroup.module.maxStudentCount === order && !isMinIntervalRed,
                    })}
                >

                    <div
                        className={cn(classes.item__name, {
                            [classes.item__name_red]:
                            order <= currentModuleGroup.module.minStudentCount,
                        })}
                    >
                        {order}
                    </div>

                    <div className={classes.item__rating} />

                </div>

            </div>

            <MinInterval
                interval={currentModuleGroup.module.minStudentCount}
                isRed
                isVisible={currentModuleGroup.module.minStudentCount === order
                    && isMinIntervalRed}
                studentInfo={minIntervalInfo.studentInfo}
                wavesInfo={minIntervalInfo.wavesInfo}
            />

            <MaxInterval
                interval={currentModuleGroup.module.maxStudentCount}
                isRed={false}
                isVisible={currentModuleGroup.module.maxStudentCount === order
                    && !isMaxIntervalRed && !isMinIntervalRed}
                studentInfo={maxIntervalInfo.studentInfo}
                wavesInfo={maxIntervalInfo.wavesInfo}
            />
        </>
    );
}
