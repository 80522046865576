import React, { useState } from 'react';

import { StudentWorkload as WorkLoad } from '@admin/EducationPeriodPage/EducationPeriodPageInterfaces';
import { InputWithFocusWithin } from '@common/InputWithFocusWithin';

import cn from 'classnames';
import classes from './StudentWorkload.module.scss';

interface Props {
    workLoad: WorkLoad;
    setStudentWorkLoad: (newWorkLoad: WorkLoad) => void;
}

export function StudentWorkload(
    {
        workLoad,
        setStudentWorkLoad,
    }: Props,
) {
    const setNewStudentWorkLoadHourPerDay = (value: string) => {
        setStudentWorkLoad({
            ...workLoad,
            maxHoursPerDay: +value,
        });
    };
    const setNewStudentWorkLoadDaysPerWeek = (value: string) => {
        setStudentWorkLoad({
            ...workLoad,
            maxDaysPerWeek: +value,
        });
    };
    const setNewStudentWorkLoadBTI = (value: string) => {
        setStudentWorkLoad({
            ...workLoad,
            maxHoursPerSpaceBaseTimeInterval: +value,
        });
    };

    const [isHourActive, setHourActive] = useState(false);
    const [isDaysActive, setDaysActive] = useState(false);
    const [isBTIActive, setBTIActive] = useState(false);

    return (
        <>
            <div
                className={cn(classes.studentWorkload, {
                    [classes.studentWorkload_active]: isHourActive,
                })}
            >

                <div className={classes.studentWorkload__title}>
                    Мах часов в день
                </div>

                <InputWithFocusWithin
                    id="hoursPerDay"
                    label={workLoad.maxHoursPerDay ?? 'Не указано'}
                    defaultValue={workLoad.maxHoursPerDay ? workLoad.maxHoursPerDay : ''}
                    labelClassName={classes.studentWorkload__label}
                    inputClassName={classes.studentWorkload__input}
                    onBlur={(value) => {
                        setNewStudentWorkLoadHourPerDay(value);
                        setHourActive(false);
                    }}
                    onFocus={() => setHourActive(true)}
                />

            </div>
            <div
                className={cn(classes.studentWorkload, {
                    [classes.studentWorkload_active]: isDaysActive,
                })}
            >

                <div className={classes.studentWorkload__title}>
                    Мах дней в неделю
                </div>

                <InputWithFocusWithin
                    id="maxDaysPerWeek"
                    label={workLoad.maxDaysPerWeek ?? 'Не указано'}
                    defaultValue={workLoad.maxDaysPerWeek ? workLoad.maxDaysPerWeek : ''}
                    labelClassName={classes.studentWorkload__label}
                    inputClassName={classes.studentWorkload__input}
                    onBlur={(value) => {
                        setNewStudentWorkLoadDaysPerWeek(value);
                        setDaysActive(false);
                    }}
                    onFocus={() => setDaysActive(true)}
                />

            </div>
            <div
                className={cn(classes.studentWorkload, {
                    [classes.studentWorkload_active]: isBTIActive,
                })}
            >

                <div className={classes.studentWorkload__title}>
                    Мах часов в семестр
                </div>

                <InputWithFocusWithin
                    id="maxHoursBTI"
                    label={workLoad.maxHoursPerSpaceBaseTimeInterval ?? 'Не указано'}
                    defaultValue={workLoad.maxHoursPerSpaceBaseTimeInterval ? workLoad.maxHoursPerSpaceBaseTimeInterval : ''}
                    labelClassName={classes.studentWorkload__label}
                    inputClassName={classes.studentWorkload__input}
                    onBlur={(value) => {
                        setNewStudentWorkLoadBTI(value);
                        setBTIActive(false);
                    }}
                    onFocus={() => setBTIActive(true)}
                />

            </div>
        </>
    );
}
