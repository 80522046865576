import React from 'react';
import cn from 'classnames';
import styles from './SavedEvent.module.scss';

export enum WarningType {
    Success = 'success',
    Warning = 'warning',
    Danger = 'danger',
}

interface Props {
    numberOfEvent?: number;
    topic: string;
    title: string;
    valid?: boolean;
    warning?: WarningType;

    handleEditEvent?(): void;
}

export const SavedEvent = ({
    numberOfEvent,
    title,
    topic = '',
    valid = true,
    handleEditEvent,
    warning,
}: Props): JSX.Element => (
    <div
        className={cn(styles.savedEvent, {
            [styles.savedEvent_valid]: valid, [styles.savedEvent_error]: !valid,
        })}
        onClick={handleEditEvent}
    >
        {warning && <div className={cn(styles.savedEvent__warning, styles[`savedEvent__warning_${warning}`])} />}
        <h3 className={styles.savedEvent__title}>{`${title}: ${numberOfEvent ?? ''} `}
            <span className={cn(styles.savedEvent__title, styles.savedEvent__title_name)}>
                {topic}
            </span>
        </h3>
    </div>
);
