import React, { FunctionComponent } from 'react';
import {
    Space, SubspaceTypeLayout,
} from '@common/SpaceSubspaceDiagram';
import classes from './EducationSpaceInfoColumns.module.scss';

export const EducationSpaceInfoColumns: FunctionComponent<{ space:Space }> = (
    { space }: { space:Space },
) => {
    const { name } = space.baseTimeIntervalType;
    const intervalItems = space.spaceBaseTimeIntervals.map(
        (element, index) => {
            const intervalIndex = index + 1;
            const intervalTitle = (
                <p className={classes.infoColumns__intervalTitle}>
                    {intervalIndex} {name}
                </p>
            );
            const intervalCredits = (
                <p className={classes.infoColumns__intervalCredits}>
                    {element.minCreditCount}{element.minCreditCount !== element.maxCreditCount ? `-${element.maxCreditCount}` : ''} ЗЕТ
                </p>
            );
            const subTypes = element?.subspaceTypeLayouts?.map(
                (layout: SubspaceTypeLayout) => (
                    <div className={classes.infoColumns__itemSubtype} key={layout.id}>
                        { layout.subspaceType?.name }:&nbsp;&nbsp;
                        {
                            layout.minCreditCount
                        }
                        {
                            layout.minCreditCount !== layout.maxCreditCount ? `-${layout.maxCreditCount} ЗЕТ` : ' ЗЕТ'
                        }
                    </div>
                ),
            );
            return (
                <div className={classes.infoColumns__item} key={element.id}>
                    <div className={classes.infoColumns__selectPoint}>
                        точка выбора
                    </div>
                    <div className={classes.infoColumns__itemTitle}>
                        {intervalTitle}{intervalCredits}
                    </div>
                    <div className={classes.infoColumns__itemSubtypeList}>
                        {subTypes}
                    </div>
                </div>
            );
        },
    );
    return (
        <div className={classes.infoColumns}>
            {intervalItems}
        </div>
    );
};
