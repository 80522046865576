import React, { useState } from 'react';
import cn from 'classnames';
import { ArrowIcon } from '@common/svg/ArrowIcon';
import {
    filteredTeacherAssignmentInstances_filteredTeacherAssignmentInstances,
    GetTeacher_teacher_baseTimeIntervalInstancesModules_modules_assignments,
    GetTeacher_teacher_baseTimeIntervalInstancesModules_modules_waves,
} from '../../TeacherAssignmentsApolloTypes';
import { TeacherAssignmentsModule } from '../TeacherAssignmentsModule';
import classes from './TeacherAssignmentsModules.module.scss';

interface Props {
    moduleId: string;
    moduleName: string | null;
    baseTimeIntervalInstanceId: string;
    assigments: GetTeacher_teacher_baseTimeIntervalInstancesModules_modules_assignments[] | null;
    waves: GetTeacher_teacher_baseTimeIntervalInstancesModules_modules_waves[] | null;
    setRefetchFilteredAssignments: any;
    onClick: () => void;
    // eslint-disable-next-line max-len
    setAssignment: (assignment: filteredTeacherAssignmentInstances_filteredTeacherAssignmentInstances) => void,
}

export function TeacherAssignmentsModules({
    moduleId,
    moduleName,
    baseTimeIntervalInstanceId,
    assigments,
    waves,
    setRefetchFilteredAssignments,
    onClick,
    setAssignment,
}: Props) {
    const [openAssignment, setOpenAssignment] = useState(true);

    return (
        <article
            key={`${module.id}_article`}
            className={cn(classes.article, {
                [classes.article_active]: !openAssignment,
            })}
        >
            <header
                className={classes.article__header}
                onClick={() => setOpenAssignment(!openAssignment)}
            >
                <h2 className={classes.article__title}>{moduleName}</h2>
                <ArrowIcon isOpen={!openAssignment} />
            </header>

            {
                !openAssignment && (
                    <TeacherAssignmentsModule
                        setRefetchFilteredAssignments={setRefetchFilteredAssignments}
                        baseTimeIntervalInstanceId={baseTimeIntervalInstanceId}
                        setAssignment={setAssignment}
                        onClick={onClick}
                        assigments={assigments}
                        moduleId={moduleId}
                        waves={waves}
                    />
                )
            }
        </article>
    );
}
