import { useMutation } from '@apollo/client';
import { UPDATE_TRAJECTORY_DRAFT } from '@common/qraphql/mutations';
import { GET_TRAJECTORIES } from '@common/qraphql/queries';
import { TrajectoryInput } from '../types';

export const useUpdateTrajectoryDraftMutation = (spaceId: string) => {
    const [updateTrajectoryDraft, { error: updateTrajectoryDraftError }] = useMutation(
        UPDATE_TRAJECTORY_DRAFT, {
            refetchQueries: [{ query: GET_TRAJECTORIES, variables: { spaceId } }],
        },
    );
    return {
        updateTrajectoryDraft: (updateTrajectoryInput: TrajectoryInput) => updateTrajectoryDraft({
            variables: { updateTrajectoryInput },
        }),
        updateTrajectoryDraftError,
    };
};
