export function useCheckIfProductionEnv() {
    const regEx = new RegExp('q.+\\.edhoc\\.ru');
    const currentHostname = window.location.hostname;

    const isDevEnv = regEx.test(currentHostname) || currentHostname === 'localhost';

    return {
        isProductionEnv: !isDevEnv,
    };
}
