import React from 'react';
import { observer } from 'mobx-react';

import cn from 'classnames';
import { evaluationStore } from '../../account/schedule/ScheduleEventBox/ScheduleEvent/ScheduleEventSection/Evaluation/Store';

import classes from './TabsWithoutRoute.module.scss';

interface Props {
    setTabId(id: string): void;
    skillTabId: string;
}

export const TabsWithoutRoute = observer(({ setTabId, skillTabId }: Props): JSX.Element => {
    const chooseTab = (id: string) => {
        const chosenTab = evaluationStore.skillTabs
            .find((tab) => (tab.id === id));
        if (chosenTab) {
            setTabId(chosenTab.id);
            evaluationStore.getSkillsDueToTab(id);
        }
    };

    return (
        <div className={classes.tabsComponent__list}>
            {
                skillTabId && (
                    evaluationStore.skillTabs.map((tab) => (
                        <div
                            key={tab.id}
                            onClick={() => chooseTab(tab.id)}
                            className={cn(
                                classes.tabsComponent__listItem,
                                {
                                    [classes.tabsComponent__listItem_chosen]:
                                        tab.id === skillTabId,
                                },
                            )
                            }
                        >
                            <div
                                className={classes.tabsComponent__value}
                            >
                                <div className={cn(
                                    classes.tabsComponent__listItem,
                                    {
                                        [classes.tabsComponent__listItem_itemChosen]:
                                            tab.id === skillTabId,
                                    },
                                )}
                                >
                                    {tab.name.nominativeSingular}
                                </div>
                            </div>
                        </div>
                    ))
                )
            }
        </div>
    );
});
