import React from 'react';
import { CreateBuildingInput, TerritorialZoneAvailableInterval } from '@admin/UniversityPage/types';
import { formatInTimeZone } from 'date-fns-tz';

import { EditIcon } from '@common/svg';
import classes from './UniversityBuildingsListItem.module.scss';

interface Props {
    universityTimeZone: string;
    building?: CreateBuildingInput;
    availableIntervals?: TerritorialZoneAvailableInterval[];

    handleSetEditedBuilding(building: CreateBuildingInput): void;
}

export function UniversityBuildingsListItem({
    universityTimeZone,
    building,
    availableIntervals,
    handleSetEditedBuilding,
}: Props): JSX.Element {
    const { name, location } = building!;

    const availableIntervalsList = (availableIntervals && availableIntervals.length)
        ? availableIntervals.map(
            (interval) => (
                <div key={interval.id}>
                    {formatInTimeZone(new Date(interval.from), universityTimeZone, 'dd.MM.yyyy HH:mm')}
                    {formatInTimeZone(new Date(interval.to), universityTimeZone, ' — dd.MM.yyyy HH:mm ')}
                </div>
            ),
        ) : [];

    return (
        <div className={classes.listItem__container}>

            <div className={classes.listItem}>
                <button
                    type="button"
                    onClick={() => handleSetEditedBuilding(building!)}
                    className={classes.listItem__button}
                >
                    <EditIcon size={20} />
                </button>
                <div className={classes.address}>
                    <div className={classes.address__buildingName}>{`${name}, `}</div>
                    <div className={classes.address__buildingLocation}>
                        {`${location}`}
                    </div>
                </div>
            </div>

            {
                availableIntervalsList.length ? (
                    <div className={classes.listItem__intervals}>

                        <div className={classes.listItem__intervals_title}>
                            Даты доступности территориальной зоны:
                        </div>

                        {
                            availableIntervalsList
                        }

                    </div>
                ) : null
            }

        </div>
    );
}
