import { useMutation } from '@apollo/client';
import {
    CreateEquipmentEquipmentPage,
    CreateEquipmentEquipmentPageVariables,
    CreateEquipmentInput,
} from 'src/graphql-query-types';
import { CREATE_EQUIPMENT } from '../queries';

export const useCreateEquipmentMutation = () => {
    const [
        createEquipment,
        { data, error, loading },
    ] = useMutation<CreateEquipmentEquipmentPage, CreateEquipmentEquipmentPageVariables>(
        CREATE_EQUIPMENT,
    );
    return {
        data,
        loading,
        error,
        createEquipment: (input: CreateEquipmentInput) => createEquipment({
            variables: {
                createEquipmentInput: input,
            },
        }),
    };
};
