import React from 'react';

interface Props {
    isHover?: boolean;
    setIsHover?: () => void;
}

export const QuestionMarkIcon = ({
    isHover = false,
    setIsHover = () => { },
}: Props) => (
    <>
        {isHover && (
            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="11" cy="11" r="11" fill="#0B42DC" />
                <path d="M14.4667 8.31667C14.4667 7.53333 14.2 6.75 13.7 6.18333C13 5.33333 11.8667 5 10.6667 5C9.85 5 9.01667 5.23333 8.36667 5.71667C7.58333 6.28333 7.1 7.28333 7 8.35H8.98333C8.96667 7.86667 9.11667 7.45 9.46667 7.16667C9.76667 6.9 10.2333 6.76667 10.7333 6.76667C11.3 6.76667 11.85 6.93333 12.1833 7.35C12.4 7.61667 12.4833 7.96667 12.4833 8.35C12.4833 8.93333 12.3667 9.4 11.9333 9.9C11.1 10.7667 10.45 10.9667 10.0167 11.85C9.65 12.4833 9.53333 13.1833 9.53333 13.9667H11.5333C11.5333 13.3167 11.5167 13.0333 11.8333 12.5167C12.1167 12.0333 12.6833 11.6667 13.2167 11.1C13.95 10.3333 14.4667 9.41667 14.4667 8.31667ZM9.55 17.2333H11.5333V15.1167H9.55V17.2333Z" fill="white" />
            </svg>
        )}
        {!isHover && (
            <svg
                width="22"
                height="22"
                viewBox="0 0 22 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                onMouseOver={() => setIsHover()}
            >
                <circle
                    cx="11"
                    cy="11"
                    r="10"
                    fill="white"
                    stroke="#DBE1F0"
                    strokeWidth="2"
                />
                <path
                    d="M14.4667 8.31667C14.4667 7.53333 14.2 6.75 13.7 6.18333C13 5.33333 11.8667 5 10.6667 5C9.85 5 9.01667 5.23333 8.36667 5.71667C7.58333 6.28333 7.1 7.28333 7 8.35H8.98333C8.96667 7.86667 9.11667 7.45 9.46667 7.16667C9.76667 6.9 10.2333 6.76667 10.7333 6.76667C11.3 6.76667 11.85 6.93333 12.1833 7.35C12.4 7.61667 12.4833 7.96667 12.4833 8.35C12.4833 8.93333 12.3667 9.4 11.9333 9.9C11.1 10.7667 10.45 10.9667 10.0167 11.85C9.65 12.4833 9.53333 13.1833 9.53333 13.9667H11.5333C11.5333 13.3167 11.5167 13.0333 11.8333 12.5167C12.1167 12.0333 12.6833 11.6667 13.2167 11.1C13.95 10.3333 14.4667 9.41667 14.4667 8.31667ZM9.55 17.2333H11.5333V15.1167H9.55V17.2333Z"
                    fill="#0B42DC"
                />
            </svg>
        )}
    </>
);
