import React, { Fragment, useState } from 'react';
import { format } from 'date-fns';

import { IconDeprecated } from '@common';
import { useUrlQuery } from '@common/hooks';
import { RoomType } from '@admin/ScheduleGenerationPage/types';
import { getBaseTimeIntervalInstanceById } from '@admin/ScheduleGenerationPage/ScheduleGenerationConflicts';
import { ArrowInShowViolation } from '../ArrowInShowViolation';
import { BaseTimeIntervalInstanceForProcess, MeetingViolation } from '../../scheduleViolationInterface';

import classes from '../scheduleViolation.module.scss';

interface Props {
    meetingViolation: MeetingViolation;
    baseTimeIntervalsInstances: BaseTimeIntervalInstanceForProcess[],
}

export const ShowMeetingViolation = (
    {
        meetingViolation,
        baseTimeIntervalsInstances,
    }: Props,
) => {
    const { setUrlQuery } = useUrlQuery();
    const [isOpen, setIsOpen] = useState(false);

    const meetingConflictsList = meetingViolation.conflicts
        .map((violationsMeetings, index) => {
            const baseTimeIntervalsUsedInMeeting = violationsMeetings
                .baseTimeIntervalInstancesIds.map(
                    (instanceId) => (
                        getBaseTimeIntervalInstanceById(
                            instanceId,
                            baseTimeIntervalsInstances,
                        )
                    ),
                );

            const meetingInstanceConflictsList = violationsMeetings.meetingInstances
                .map((meetingInstance) => {
                    const teachers = meetingInstance.meetingInstanceToTeachers
                        .map(instanceToTeacher => {
                            const { firstName } = instanceToTeacher.teacher.user;
                            const { lastName } = instanceToTeacher.teacher.user;
                            const { patronymic } = instanceToTeacher.teacher.user;
                            return `${lastName} ${firstName} ${patronymic}`;
                        });
                    return (
                        <div
                            key={meetingInstance.id}
                            className={classes.schedule__violation_conflict}
                        >
                            <div>Модуль: {`${meetingInstance.meeting.module.name} `}</div>
                            <div className={classes.schedule__violation_meeting}>
                                Встреча:
                                <div
                                    onClick={() => {
                                        setUrlQuery({ detailedEventId: meetingInstance.id, detailedEventType: 'meeting' });
                                    }}
                                    className={classes.schedule__violation_meeting_name}
                                >{` ${meetingInstance.meeting.topic}`}
                                    <IconDeprecated
                                        id="editPenBlack"
                                        className={classes.violation__meeting_icon}
                                    />
                                </div>
                            </div>
                            <div className={classes.schedule__violation_value}>Формат: {`${meetingInstance.meeting.format?.name} `}</div>
                            <div className={classes.schedule__violation_value}>Потоки: {`${meetingInstance.waves.map(wave => `${wave.index ? wave.index + 1 : 1}`)} `}</div>
                            <div className={classes.schedule__violation_value}>Преподаватель: {`${teachers} `}</div>

                            <div className={classes.schedule__violation_value}>
                                {meetingInstance.meeting.format?.name
                                === RoomType.Indoor
                                    ? `Помещение: ${meetingInstance.room?.title ?? ''}`
                                    : `Ссылка: ${meetingInstance.onlineRoom?.url ?? ''}`}
                            </div>

                            <div className={classes.schedule__violation_value}>
                                Время проведения: {format(new Date(meetingInstance.startDate), ' HH:mm dd.MM.yy -')}
                                {meetingInstance.endDate ? format(new Date(meetingInstance.endDate), ' HH:mm dd.MM.yy ') : ''}
                            </div>
                        </div>
                    );
                });

            return (
                <Fragment key={`${violationsMeetings.meetingInstances.map(ell => ell.id)}`}>
                    <div className={classes.schedule__violation_conflict_title}>
                        Конфликт {`${index + 1}: `}
                    </div>
                    <div className={classes.schedule__violation_value}>
                        <span>
                            Учебный период:
                        </span>
                        {' '}
                        {
                            baseTimeIntervalsUsedInMeeting.map(
                                (instance, ind) => (
                                    <Fragment key={`${instance?.id}${instance?.longName}`}>
                                        {instance?.longName ?? ''}{(baseTimeIntervalsUsedInMeeting.length > 1 && ind + 1 !== baseTimeIntervalsUsedInMeeting.length) ? ', ' : ''}
                                    </Fragment>
                                ),
                            )
                        }
                    </div>
                    {meetingInstanceConflictsList}
                </Fragment>
            );
        });

    return (
        <div className={classes.schedule__violation_container}>
            <div
                className={classes.schedule__violation_title}
                onClick={() => {
                    if (meetingViolation.conflicts.length) {
                        setIsOpen(!isOpen);
                    }
                }}
            >
                <div className={classes.schedule__violation_content}>
                    <div
                        className={classes.schedule__violation_count}
                    >
                        {`${meetingViolation.conflicts.length} `}
                    </div>
                    {meetingViolation.violationName}
                </div>
                <ArrowInShowViolation isOpen={isOpen} />
            </div>
            {isOpen && (meetingConflictsList)}
        </div>
    );
};
