import React from 'react';
import { format } from 'date-fns';
import classNames from 'classnames';
import { observer } from 'mobx-react';

import { scheduleGenerationStore } from '@admin/ScheduleGenerationPage/store';
import { getColor } from '../ScheduleTable/Legend';

import classes from './ProcessChooser.module.scss';

interface Params {
    className?: string;
}

export const ProcessChooser = observer(({
    className,
}: Params): JSX.Element => {
    const { baseTimeIntervalInstance } = scheduleGenerationStore;

    return (
        <div className={classNames(classes.processChooser, className)}>
            {baseTimeIntervalInstance && (
                <>
                    {baseTimeIntervalInstance.selectedProcess && (
                        <div className={classes.processChooser__description}>
                            Выбран процесс: {baseTimeIntervalInstance.selectedProcess.name} (
                            {format(baseTimeIntervalInstance.selectedProcessStartDate!, 'dd.MM')}
                            <> — </>
                            {format(baseTimeIntervalInstance.selectedProcessEndDate!, 'dd.MM')})
                        </div>
                    )}
                    <div className={classes.processChooser__processes}>
                        {baseTimeIntervalInstance.scheduleGenerationProcesses.map(process => {
                            const numOfProcess = baseTimeIntervalInstance.getNumOfProcess(
                                process.id,
                            );
                            return (
                                <div
                                    key={process.id}
                                    className={classNames(classes.processChooser__process, {
                                        [classes.processChooser__process_selected]:
                                        baseTimeIntervalInstance.selectedProcess?.id === process.id,
                                    })}
                                    style={{
                                        background: getColor(numOfProcess),
                                    }}
                                    onClick={() => baseTimeIntervalInstance.setSelectedProcess(
                                        process,
                                    )}
                                >
                                    {numOfProcess}
                                </div>
                            );
                        })}
                    </div>
                </>
            )}
        </div>
    );
});
