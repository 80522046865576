import { useMutation } from '@apollo/client';
import { RemoveEquipmentEquipmentPage, RemoveEquipmentVariables } from 'src/deprecated-graphql-query-types';
import { REMOVE_EQUIPMENT } from '../queries';

export const useRemoveEquipmentMutation = () => {
    const [removeEquipment, {
        data,
        error,
        loading,
    }] = useMutation<RemoveEquipmentEquipmentPage, RemoveEquipmentVariables>(
        REMOVE_EQUIPMENT,
    );
    return { data, loading, error, removeEquipment };
};
