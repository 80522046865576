import React from 'react';
import { Icon } from '@admin/NewModule/CustomIcon/Icon';
import classnames from 'classnames';

import classes from './SelectedSubspaceCard.module.scss';

interface Props {
    description: string;
    subspaceName: string;
    onDelete: any;
}
export const SelectedSubspaceCard = ({
    description,
    subspaceName,
    onDelete,
}: Props): JSX.Element => (
    <div className={classes.selectedSubspaceCard}>
        <div className={classes.selectedSubspaceCard__container}>
            <div className={classes.selectedSubspaceCard__icon} onClick={onDelete}>
                <Icon id="closeIcon" modifier="newClose" />
            </div>
            <p className={classes.selectedSubspaceCard__name}>{subspaceName}</p>
        </div>
        {description
            ? (
                <div className={classes.selectedSubspaceCard__container}>{description}</div>
            )
            : (
                <div className={classnames(classes.selectedSubspaceCard__container,
                    classes.selectedSubspaceCard__container_noData)}
                >
                    У этого подпространства нет описания
                </div>
            )
        }
    </div>
);
