import { PlainModuleSettings } from '../interfaces/moduleSettings';
import { SkillType, SkillTypeExtended, SkillTypeOption } from '../interfaces/skillType';

export const findFirstParent = (skillTypes: SkillType[], skillType: SkillType): SkillType => {
    if (skillType.parentId !== null) {
        const parentSkillType = skillTypes.find(({ id }) => id === skillType.parentId);
        if (parentSkillType) {
            return findFirstParent(skillTypes, parentSkillType);
        }
    }

    return skillType;
};

export const getSkillTypesExtended = (
    plainModuleSettings?: PlainModuleSettings,
    skillTypes?: SkillType[],
): SkillTypeExtended[] | undefined => {
    if (skillTypes && plainModuleSettings) {
        return skillTypes.map((skillType) => {
            const skillTypeSetting = plainModuleSettings.find(
                ({ id }) => id === findFirstParent(skillTypes, skillType).id,
            );

            return {
                ...skillType,
                isLevelIncreased: skillTypeSetting!.isLevelIncreased,
            };
        });
    }

    return undefined;
};

export const getFinalSkillTypes = (
    skillTypes?: SkillTypeExtended[],
): SkillTypeExtended[] | undefined => {
    if (skillTypes) {
        return skillTypes.filter(
            (skillType) => !skillTypes.some(
                (someSkillType) => someSkillType.parentId === skillType.id,
            ),
        );
    }

    return undefined;
};

export const getOptionsSkillTypes = (skillTypes: SkillType[]): SkillTypeOption[] => (
    skillTypes.map(
        (skillType) => ({ id: skillType.id, name: skillType.name.nominativePlural }),
    )
);
