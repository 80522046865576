import React, { useState } from 'react';

import { TableColumn } from '@admin/Attendance/AttendanceIterfaces';
import { MeetingInfo } from '@admin/Attendance/AttendanceTable/MeetingInfo';
import cn from 'classnames';
import { format } from 'date-fns';
import { attendanceStore } from '@admin/Attendance/AttendanceStore';
import { observer } from 'mobx-react';

import classes from './TableDates.module.scss';

interface Props {
    order: number;
    isAccordionOpen: boolean;
    shouldAdditionalBeRight: boolean;
    isNewMonth: boolean,
    tableColumn: TableColumn;
}

const months = ['янв', 'фев', 'март', 'апр', 'май', 'июнь', 'июль', 'авг', 'сент', 'окт', 'нояб', 'дек'];

export const TableDates = observer((
    {
        order,
        isAccordionOpen,
        shouldAdditionalBeRight,
        isNewMonth,
        tableColumn,
    } : Props,
): JSX.Element => {
    const [isAdditionalVisible, setIsAdditionalVisible] = useState(false);
    const { meetingInstance } = tableColumn;
    const isMonthShown = isNewMonth || order === 1;

    const meetingStartDate = new Date(meetingInstance.startDate);
    const meetingEndDate = new Date(meetingInstance.endDate);

    const meetingStartDateDay = format(meetingStartDate, 'd');
    const meetingStartDateMonth = months[+format(meetingStartDate, 'M') - 1];

    const meetingStartDateInfo = format(meetingStartDate, 'dd.MM.yyyy');

    const { teacher } = meetingInstance.meetingInstanceToTeachers[0];
    const teacherName = `${teacher?.user?.lastName} ${teacher?.user?.firstName} ${teacher?.user?.patronymic}`;

    const meetingStartTime = format(meetingStartDate, 'HH:mm');
    const meetingEndTime = format(meetingEndDate, 'HH:mm');

    const attendanceByDates = (
        <div
            className={cn(classes.date__info, {
                [classes.date__info_newMonth]: isNewMonth,
            })}
        >
            {
                meetingStartDateDay
            }

            {
                isMonthShown ? (
                    <div className={classes.date__info_month}>
                        {
                            meetingStartDateMonth
                        }
                    </div>
                ) : null
            }
        </div>
    );

    const additional = (
        <div
            className={cn(classes.date__additional, {
                [classes.date__additional_positionRight]: shouldAdditionalBeRight,
            })}
        >
            <MeetingInfo
                order={order}
                dateStart={meetingStartDateInfo}
                startTime={meetingStartTime}
                endTime={meetingEndTime}
                meetingName={meetingInstance.meeting.topic ?? ''}
                teacherName={teacherName}
                className={classes.meetingInfo}
            />
        </div>
    );

    const attendanceByOrder = (
        <div className={classes.date__info}>
            {
                order
            }
        </div>
    );

    return (
        <div
            className={cn(classes.date, {
                [classes.date_disabled]: !isAccordionOpen,
                [classes.date_active]: attendanceStore.activeTableColumnId
                === tableColumn.columnId,
            })}
            onMouseOver={() => setIsAdditionalVisible(true)}
            onMouseLeave={() => setIsAdditionalVisible(false)}
            onFocus={() => {}}
        >

            {
                attendanceStore.getIsAttendanceByDates() ? (
                    attendanceByDates
                ) : (
                    attendanceByOrder
                )
            }

            {
                isAdditionalVisible && (
                    <>
                        {additional}
                    </>
                )
            }

        </div>
    );
});
