import { SubspaceType, SubspaceTypeLayout } from '../interfaces';

export function getMinIntervalOrder(subspaceType: SubspaceType): number {
    const layouts: SubspaceTypeLayout[] = subspaceType.subspaceTypeLayouts;
    let minIntervalOrder: number = layouts[0].spaceBaseTimeInterval.order;

    for (let i = 1; i < layouts.length; i += 1) {
        const curIntervalOrder: number = layouts[i].spaceBaseTimeInterval.order;
        if (curIntervalOrder < minIntervalOrder) {
            minIntervalOrder = curIntervalOrder;
        }
    }

    return minIntervalOrder;
}

export function getMaxIntervalOrder(subspaceType: SubspaceType): number {
    const layouts: SubspaceTypeLayout[] = subspaceType.subspaceTypeLayouts;
    let maxIntervalOrder: number = layouts[0].spaceBaseTimeInterval.order;

    for (let i = 1; i < layouts.length; i += 1) {
        const curIntervalOrder: number = layouts[i].spaceBaseTimeInterval.order;
        if (curIntervalOrder > maxIntervalOrder) {
            maxIntervalOrder = curIntervalOrder;
        }
    }

    return maxIntervalOrder;
}

export function copySubspaceTypeLayouts(layouts: SubspaceTypeLayout[]): SubspaceTypeLayout[] {
    return layouts.reduce((acc: SubspaceTypeLayout[], layout) => {
        const spaceBaseTimeInterval = { ...layout.spaceBaseTimeInterval };
        const copiedLayout: SubspaceTypeLayout = { spaceBaseTimeInterval };
        return [...acc, copiedLayout];
    }, []);
}

export function copySubspaceType(type: SubspaceType): SubspaceType {
    return {
        id: type.id,
        name: type.name,
        subspaceTypeLayouts: copySubspaceTypeLayouts(type.subspaceTypeLayouts),
        subspaces: [...type.subspaces],
    };
}

export function getSubspaceType(
    subspaceId: string, types: SubspaceType[],
): SubspaceType | undefined {
    const subspaceType: SubspaceType | undefined = types.find((type: SubspaceType) => {
        const isSubspaceType = type.subspaces.find(subspace => subspace.id === subspaceId);
        return isSubspaceType;
    });

    return subspaceType ? copySubspaceType(subspaceType) : undefined;
}
