import React, { useState } from 'react';

import cn from 'classnames';

import { Accordion } from '@common/Accordion';
import { Skill } from '@store/SkillHierarchy';
import { skillTypesData_skillTypes } from 'src/deprecated-graphql-query-types';

import classes from './EventItem.module.scss';

interface Props {
    skillListWithParents: Skill[][];
    type: skillTypesData_skillTypes;
    classNames?: string;
    skillClassNames?: string
}

export const SkillTypeTemplate = ({
    skillListWithParents,
    type,
    classNames,
    skillClassNames,
}: Props) => {
    const [isOpen, setIsOpen] = useState(true);
    const skills = skillListWithParents
        .filter(skill => skill.some(item => item.typeId === type.id));
    return (
        <div className={cn(classes.skillType, {
            [classNames ?? '']: classNames,
        })}
        >
            <Accordion
                additionalSetState={() => setIsOpen(!isOpen)}
                isOpen={isOpen}
                headerClassnames={classes.skillType__name}
            >
                <div
                    className={classes.skillType__name}
                >
                    {type.name.nominativePlural}
                </div>
                <>
                    {skills.map(([skill, ...arg]) => (
                        <ul key={skill.id} className={classes.skillType__skillContainer}>
                            <li className={cn(classes.skillType__skill, {
                                [classes.skillType__skill_withParents]: (arg.length > 0),
                                [skillClassNames ?? '']: skillClassNames,
                            })}
                            >
                                {skill.name}
                            </li>
                            {arg.length > 0 && (
                                <div
                                    className={classes.skillType__parents}
                                >
                                    {arg.map((parent) => parent.name).join('/')}
                                </div>
                            )}
                        </ul>
                    ))}
                </>
            </Accordion>
        </div>
    );
};
