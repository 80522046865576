import React, { useState } from 'react';
import { isEmpty } from 'lodash';
import * as XLSX from 'xlsx';

import {
    Loader,
    Alert,
    HorizontalArrowIcon,
    HorizontalVector,
    ActionButton,
    ActionTypeOfButton,
} from '@common';
import {
    IgnoreErrorsFlag,
    ScheduleGenerationProcessStatus,
    ScheduleGenerationProcessesSchedulePage_scheduleGenerationProcesses,
    ScheduleValidationReportItemErrorTypeFilter,
    ValidateScheduleSchedulePage_validateSchedule_errors,
    ValidateScheduleSchedulePage_validateSchedule_warnings,
} from '../../../graphql-query-types';
import {
    useValidateScheduleLazyQuery,
    useBeginScheduleGeneration,
    useFinishScheduleGenerationMutation,
    useGetBaseTimeIntervalInstance,
} from '../graphql';
import { IgnoreFlagsForValidation } from './IgnoreFlagsForValidation';
import { ScheduleGenerationInfo } from './ScheduleGenerationInfo';
import { ScheduleValidation } from './ScheduleValidation';
import { Secret } from './Secret';
import {
    IgnoreFlag,
    IgnoreFlagCheckbox,
} from './interfaces';

import classes from './ScheduleGeneration.module.scss';

interface Props {
    isSecret: boolean;
    process: ScheduleGenerationProcessesSchedulePage_scheduleGenerationProcesses;
    baseTimeIntervalInstanceId: string;
    goBack: () => void;
    refetchProcesses: () => void;
}

export const ScheduleGeneration = ({
    isSecret,
    process,
    baseTimeIntervalInstanceId,
    goBack,
    refetchProcesses,
}: Props) => {
    const [ignoreFlags, setIgnoreFlags] = useState<Partial<IgnoreFlagCheckbox>>({
        ignoreTeachers: false,
        ignoreStudents: false,
        ignoreEquipments: false,
        ignoreRooms: false,
    });

    const {
        data: BTIData,
        error: BTIError,
    } = useGetBaseTimeIntervalInstance([baseTimeIntervalInstanceId]);
    const {
        startScheduleGeneration,
        error: errorStart,
        loading: loadingStart,
    } = useBeginScheduleGeneration(refetchProcesses);
    const {
        validate,
        loading: loadingValidation,
        error: validateError,
        validationResult,
    } = useValidateScheduleLazyQuery();

    const saveXLSX = () => {
        if (validationResult) {
            const errorsSheet = XLSX.utils
                .json_to_sheet(validationResult.errors.map((item) => errorAssemblyForXLSX(item)));
            const warningsSheet = XLSX.utils
                .json_to_sheet(validationResult.warnings.map((item) => errorAssemblyForXLSX(item)));

            const workBook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workBook, errorsSheet, 'Ошибки');
            XLSX.utils.book_append_sheet(workBook, warningsSheet, 'Предупреждения');
            const BTIName = BTIData?.baseTimeIntervalInstancesByIds[0].longName;
            const BTIStartDate = BTIData?.baseTimeIntervalInstancesByIds[0].startDate;
            const BTIEndDate = BTIData?.baseTimeIntervalInstancesByIds[0].endDate;
            XLSX.writeFile(workBook, `Ошибки валидации ${BTIName} ${BTIStartDate} - ${BTIEndDate}.xlsx`);
        }
    };

    const isCorrectionStatus = process.status === ScheduleGenerationProcessStatus.correction;
    function isScheduleGenerationDisabled() {
        return !validationResult || !(isCorrectionStatus && isEmpty(validationResult.errors));
    }

    const finishScheduleGeneration = useFinishScheduleGenerationMutation();

    const handleIgnoreFlagsChange = (ignoreFlag: keyof typeof IgnoreFlag) => () => setIgnoreFlags(
        (oldConflictTypes: Partial<IgnoreFlagCheckbox>) => (
            { ...oldConflictTypes, [ignoreFlag]: !oldConflictTypes[ignoreFlag] }
        ),
    );

    const hasIgnoreFlags = (validationResult && !isEmpty(validationResult.errors)) || isSecret;

    const loadValidationButton = (
        <ActionButton
            onClick={() => saveXLSX()}
            className={classes.button}
        >
            Скачать отчет по ошибкам валидации в xlsx
        </ActionButton>
    );

    const error = BTIError || validateError || validateError || errorStart;
    return (
        <>
            <div onClick={goBack} className={classes.additionalGeneration_back}>
                <HorizontalArrowIcon horizontalVector={HorizontalVector.LEFT} width={20} />
                Список периодов генерации
            </div>
            <div className={classes.additionalGeneration}>
                <ScheduleGenerationInfo process={process} />
                {hasIgnoreFlags && (
                    <IgnoreFlagsForValidation
                        flags={Object.keys(IgnoreFlag)}
                        ignoreFlags={ignoreFlags}
                        validationResult={(validationResult
                            ? [
                                ...validationResult.errors.map(({ message }) => message),
                                ...validationResult.warnings.map(({ message }) => `(предупреждение) ${message}`),
                            ]
                            : [])}
                        isSecret={isSecret}
                        loadValidationButton={loadValidationButton}
                        handleIgnoreFlagsChange={handleIgnoreFlagsChange}
                    />
                )}
                <div className={classes.additionalGeneration_actions}>
                    <ScheduleValidation
                        process={process}
                        ignoreFlags={ignoreFlags}
                        isScheduleGeneration={isScheduleGenerationDisabled}
                    />
                    <div className={classes.additionalGeneration_mainActions}>
                        {!isSecret && isCorrectionStatus
                            && (loadingValidation ? (
                                <div>
                                    Процесс валидации займет около 3 минут
                                    <Loader />
                                </div>
                            ) : (
                                <ActionButton
                                    onClick={() => validate({
                                        variables: {
                                            validateScheduleInput: {
                                                generationProcessId: process.id,
                                                ignoreFlags: getTrueKeysFromObject(ignoreFlags),
                                            },
                                        },
                                    })}
                                    className={classes.button}
                                >
                                    Валидация
                                </ActionButton>
                            ))}
                        {isSaveButtonVisible(process)
                            ? (
                                <div className={classes.additionalGeneration__finish}>
                                    <ActionButton
                                        actionType={ActionTypeOfButton.SECONDARY}
                                        onClick={() => finishScheduleGeneration(process.id, false)}
                                        className={classes.button}
                                    >
                                        Отменить генерацию
                                    </ActionButton>
                                    <ActionButton
                                        actionType={ActionTypeOfButton.PRIMARY}
                                        onClick={() => finishScheduleGeneration(process.id, true)}
                                        className={classes.button}
                                    >
                                        Загрузить расписание
                                    </ActionButton>
                                </div>
                            ) : isCorrectionStatus
                            && (
                                loadingStart ? <Loader /> : (
                                    <ActionButton
                                        onClick={() => startScheduleGeneration(
                                            process.id,
                                            getTrueKeysFromObject(ignoreFlags),
                                        )}
                                        className={classes.button}
                                        disabled={!isSecret
                                            ? isScheduleGenerationDisabled()
                                            : false}
                                    >
                                        Начать генерацию
                                    </ActionButton>
                                )
                            )
                        }
                    </div>
                </div>
                {isSecret && <Secret process={process} />}
                {error && <Alert message={error.message} />}
            </div>
        </>
    );
};
function getTrueKeysFromObject(obj: { [index: string]: boolean; }): IgnoreErrorsFlag[] {
    return Object.entries(obj)
        .filter(([, value]) => value).map(([key]) => key) as IgnoreErrorsFlag[];
}
function isSaveButtonVisible(
    process: ScheduleGenerationProcessesSchedulePage_scheduleGenerationProcesses,
) {
    return process.status === ScheduleGenerationProcessStatus.generationReady;
}

function errorAssemblyForXLSX(
    error: ValidateScheduleSchedulePage_validateSchedule_warnings
    | ValidateScheduleSchedulePage_validateSchedule_errors,
) {
    return ({
        /* eslint-disable quote-props */
        'Ошибка': error.message,

        'Учебный период': error.object?.btiInstanceName,
        'Учебный период ID': error.object?.btiInstanceId,

        'Категория ошибки': getErrorTypeFilterName(error.errorTypeFilter),

        'Модуль': error.object?.moduleName,
        'Модуль ID': error.object?.moduleId,

        'Встреча': error.object?.meetingName,
        'Встреча ID': error.object?.meetingId,

        'Студент ID': error.object?.studentId,

        'Преподаватель': error.object?.teacherName,
        'Преподаватель ID': error.object?.teacherId,

        'Роль преподавателя': error.object?.teacherRoleName,
        'Роль преподавателя ID': error.object?.teacherRoleId,

        'Формат встречи': error.object?.meetingFormatName,
        'Формат встречи ID': error.object?.meetingFormatId,

        'Оборудование': error.object?.equipmentCategoryAndFeaturesNames,

        'Модуль 2': error.object?.module2Name,
        'Модуль 2 ID': error.object?.module2Id,

        'Встреча 2': error.object?.meeting2Name,
        'Встреча 2 ID': error.object?.meeting2Id,

        'Код ошибки': error.subject,
    });
}

function getErrorTypeFilterName(errorTypeFilter: ScheduleValidationReportItemErrorTypeFilter) {
    return ERROR_TYPE_FILTER_NAMES[errorTypeFilter];
}

const ERROR_TYPE_FILTER_NAMES: {
    [index in ScheduleValidationReportItemErrorTypeFilter]: string;
} = {
    [ScheduleValidationReportItemErrorTypeFilter.STUDENT]: 'студент',
    [ScheduleValidationReportItemErrorTypeFilter.TEACHER]: 'преподаватель',
    [ScheduleValidationReportItemErrorTypeFilter.EQUIPMENT]: 'оборудование',
    [ScheduleValidationReportItemErrorTypeFilter.ROOM]: 'помещение',
    [ScheduleValidationReportItemErrorTypeFilter.MODULE]: 'модуль',
    [ScheduleValidationReportItemErrorTypeFilter.MODULE_MINMAX_INTERVALS]: 'модуль min/max интервал',
    [ScheduleValidationReportItemErrorTypeFilter.CONFIG]: 'конфигурация',
    [ScheduleValidationReportItemErrorTypeFilter.ADDITIONAL]: 'догенерация',
};
