import React, { useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';
import UserView from '@store/UserView';

import { GET_TEACHER_ASSIGNMENTS } from '../../TeacherAssignmentsQueries';
import {
    GetTeacher_teacher_baseTimeIntervalInstancesModules_modules_assignments,
    GetTeacher_teacher_baseTimeIntervalInstancesModules_modules_waves,
    filteredTeacherAssignmentInstancesVariables,
    filteredTeacherAssignmentInstances,
    TeacherAssignmentInstancesInput,
    TeacherAssignmentInstanceStatus,
    AssignmentInstanceOrder,
    filteredTeacherAssignmentInstances_filteredTeacherAssignmentInstances,
} from '../../TeacherAssignmentsApolloTypes';
import { TeacherAssignmentsModule } from './TeacherAssignmentsModule';

interface Props {
    moduleId: string,
    baseTimeIntervalInstanceId?: string,
    assigments: GetTeacher_teacher_baseTimeIntervalInstancesModules_modules_assignments[] | null,
    waves: GetTeacher_teacher_baseTimeIntervalInstancesModules_modules_waves[] | null,
    setRefetchFilteredAssignments: any,
    onClick: () => void,
    // eslint-disable-next-line max-len
    setAssignment: (assignment: filteredTeacherAssignmentInstances_filteredTeacherAssignmentInstances) => void,
}

export function TeacherAssignmentsModuleApollo({
    moduleId,
    baseTimeIntervalInstanceId,
    assigments,
    waves,
    setRefetchFilteredAssignments,
    onClick,
    setAssignment,
}: Props) {
    if (!UserView.teacherId) {
        return null;
    }

    const [
        getFilteredAssignments,
        { data, refetch },
        // eslint-disable-next-line max-len
    ] = useLazyQuery<filteredTeacherAssignmentInstances, filteredTeacherAssignmentInstancesVariables>(
        GET_TEACHER_ASSIGNMENTS,
        {
            variables: {
                assignmentInstancesInput: {
                    baseTimeIntervalInstanceId: baseTimeIntervalInstanceId as string,
                    orderBy: AssignmentInstanceOrder.startAsc,
                    isCurrentTeacher: false,
                    moduleId,
                    assignmentInstanceStatus: TeacherAssignmentInstanceStatus.actual,
                },
            },
        },
    );

    useEffect(
        () => refetch && setRefetchFilteredAssignments(() => refetch), [refetch],
    );

    function filterAssignments(
        waveIds: string[] = [],
        orderBy: AssignmentInstanceOrder = AssignmentInstanceOrder.startAsc,
        isCurrentTeacher: boolean = false,
        assignmentId: string = '',
        // eslint-disable-next-line max-len
        assignmentInstanceStatus: TeacherAssignmentInstanceStatus = TeacherAssignmentInstanceStatus.actual,
    ) {
        const variables: TeacherAssignmentInstancesInput = {
            baseTimeIntervalInstanceId: baseTimeIntervalInstanceId as string,
            orderBy,
            isCurrentTeacher,
            moduleId,
            assignmentInstanceStatus,
        };

        if (assignmentId) {
            variables.assignmentId = assignmentId;
        }

        if (waveIds.length) {
            variables.waveIds = waveIds;
        }

        getFilteredAssignments(
            {
                variables: {
                    assignmentInstancesInput: variables,
                },
            },
        );
    }

    return (
        <TeacherAssignmentsModule
            assignments={data?.filteredTeacherAssignmentInstances}
            assignmentsOptions={assigments}
            waves={waves}
            filterAssignments={filterAssignments}
            onClick={onClick}
            setAssignment={setAssignment}
        />
    );
}
