import React from 'react';

interface Props {
    className?: string;
    onClick?(): void;
}
export const CloseIconGradient = ({ className, onClick }: Props): JSX.Element => (
    <svg className={className} onClick={onClick} width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11.8033 10.7428L7.56069 6.50019L11.8034 2.25752C12.096 1.96484 12.096 1.4896 11.8033 1.19682C11.5106 0.904145 11.0354 0.904145 10.7427 1.19682L6.5 5.4395L2.25733 1.19682C1.96465 0.904145 1.48941 0.904145 1.19673 1.19682C0.903955 1.4896 0.903955 1.96484 1.19663 2.25752L5.43931 6.50019L1.19673 10.7428C0.903955 11.0355 0.903955 11.5108 1.19663 11.8035C1.48941 12.0962 1.96465 12.0962 2.25742 11.8035L6.5 7.56088L10.7426 11.8035C11.0354 12.0962 11.5106 12.0962 11.8034 11.8035C12.096 11.5108 12.096 11.0355 11.8033 10.7428Z" fill="url(#paint0_linear)" />
        <defs>
            <linearGradient id="paint0_linear" x1="13.1742" y1="11.9661" x2="-2.85581" y2="4.93708" gradientUnits="userSpaceOnUse">
                <stop stopColor="#0B42DC" />
                <stop offset="0.0416667" stopColor="#0B42DC" />
                <stop offset="0.130208" stopColor="#0B42DC" />
                <stop offset="0.734375" stopColor="#57B2C6" />
                <stop offset="0.932292" stopColor="#EED660" />
            </linearGradient>
        </defs>
    </svg>
);
