import React from 'react';
import { gql, useQuery } from '@apollo/client';
import { SuitableModules } from './SuitableModules';
import { UpdateSlotDiagramInput } from '../subSpaceTypes';
import Diagram from '../store/Diagram';

interface Props {
    subspaceId: string
    slotId: string
    skillTypes: any;
    allSkillsList: any;
    updateSlotModule: (item: any) => void;
    setModuleState: (flag: boolean) => void;

    updateSlotDiagram(moduleSlotInput: UpdateSlotDiagramInput): void
}

const MODULE = `
    id
    name
    description
    minStudentCount
    maxStudentCount
    maxWaveCount
    creditCount
    teachersRoles {
        id
        name
    }
    prerequisiteSkills {
        skill {
            id
            name
            fullName
            typeId
        }
        level
    }
    outputSkills {
        skill {
            id
            name
            fullName
            typeId
        }
        level
    }
    meetings {
        id
        topic
        format {
            id
            name
        }
        duration {
            id
            name
            academicHours
        }
        minStudentCount
        maxStudentCount
        prerequisiteSkills {
            skill {
                id
                name
                fullName
                typeId
            }
            level
        }
        outputSkills {
            skill {
                id
                name
                fullName
                typeId
            }
            level
        }
        order
    }
    evaluationPoints {
        id
        topic
        description
        previousEvent {
            id
            type
        }
        evaluationPointSkills {
            skill {
                id
                name
                typeId
            }
            level
        }
        duration {
            id
            name
            academicHours
        }
        order
    }
`;

const GET_OPTIMAL_MODULES = gql`
    query optimalModules($optimalModulesInput: OptimalModulesInput!) {
        optimalModules(optimalModulesInput: $optimalModulesInput) {
            suitable {
                ${MODULE}
            }
            differing {
                module {
                    ${MODULE}
                }
                prerequisiteSkillsDifference {
                    skill {
                        id
                        name
                    }
                }
                outputSkillsDifference {
                    skill {
                        id
                        name
                    }
                }
            }
        }
    }
`;

export const SuitableModulesApollo = ({
    subspaceId,
    slotId,
    skillTypes,
    allSkillsList,
    updateSlotModule,
    setModuleState,
    updateSlotDiagram,
}: Props): JSX.Element => {
    const { slotSettings, btiSettings } = Diagram;

    const targetBTI = btiSettings.find(
        bti => (bti.id === slotSettings.spaceBaseTimeIntervalId),
    );
    const targetModuleSlot = targetBTI?.moduleSlots.find(slot => slot.id === slotId);

    const prerequisiteSkills = targetModuleSlot?.prerequisiteSkills.map((item: any) => (
        { id: item.skill.id, level: item.level }
    )) || [];
    const outputSkills = targetModuleSlot?.outputSkills.map((item: any) => (
        { id: item.skill.id, level: item.level }
    )) || [];

    const { data, loading, error } = useQuery(GET_OPTIMAL_MODULES, {
        fetchPolicy: 'cache-and-network',
        variables: {
            optimalModulesInput: {
                prerequisiteSkills,
                outputSkills,
            },
        },
    });

    if (loading) return <>`Loading...`</>;
    if (error) return <>`Error! ${error.message}`</>;

    return (
        <SuitableModules
            subspaceId={subspaceId}
            slotId={slotId}
            optimalModules={data.optimalModules}
            skillTypes={skillTypes}
            allSkillsList={allSkillsList}
            updateSlotModule={updateSlotModule}
            setModuleState={setModuleState}
            updateSlotDiagram={updateSlotDiagram}
        />
    );
};
