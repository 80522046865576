import React, { useState } from 'react';
import cn from 'classnames';
import { nanoid } from 'nanoid';

import { Accordion } from '@common/Accordion';
import { Absence } from '@admin/Users/UserCard/UserCommonInfo/UserAbsences/AbsencesAccordion/Absence';
import {
    UserWithAbsences_user_student_baseTimeIntervalInstances_baseTimeIntervalInstance
    as BaseTimeIntervalInstance, UserWithAbsences_user_student_excusedAbsences as ExcusedAbsence,
} from '@admin/Users/UserCard/UserCommonInfo/UserAbsences/graphql-types';
import { usersStore } from '@admin/Users/UsersStore';
import { BaseTimeIntervalInstanceWithExcusedAbsences } from '@admin/Users/UsersInterfaces';

import { differenceInDays } from 'date-fns';
import classes from './AbsencesAccordion.module.scss';

interface Props {
    baseTimeIntervalTypeName: string;
    baseTimeIntervalInstance: BaseTimeIntervalInstance;
    excusedAbsences: ExcusedAbsence[],
}

export function AbsencesAccordion(
    {
        baseTimeIntervalTypeName,
        baseTimeIntervalInstance,
        excusedAbsences,
    }: Props,
) {
    const [isAccordionOpen, setIsAccordionOpen] = useState(false);
    const [isFirstAbsenceEdit, setIsFirstAbsenceEdit] = useState(false);
    const {
        spaceBaseTimeInterval,
    } = baseTimeIntervalInstance;

    const hasNoAbsences = excusedAbsences.length === 0;
    const firstAbsence = excusedAbsences[0];
    const hiddenAbsences = new Array(...excusedAbsences).slice(1);

    const accordionHeaderInfo = `${spaceBaseTimeInterval.order} ${baseTimeIntervalTypeName}`;

    const excusedAbsencesAmountInfo = `[${getBaseTimeIntervalAbsencesAmount(excusedAbsences)} дн.]`;

    const isAbsenceCreating = checkIfAbsenceIsCreating(excusedAbsences);

    const addAbsenceButtonHandleClickIfAbsenceIsCreating = () => {
        // eslint-disable-next-line max-len
        const newBaseTimeIntervalsWithRemovedAbsence = getGroupedAbsencesWithRemovedCreatedAbsence(
            baseTimeIntervalInstance.id,
            usersStore.getBaseTimeIntervalInstancesWithExcusedAbsences(),
        );

        usersStore.setBaseTimeIntervalInstancesWithExcusedAbsences(
            newBaseTimeIntervalsWithRemovedAbsence,
        );
    };

    const addAbsenceButtonHandleClick = () => {
        if (usersStore.getIsAbsenceCreating()) {
            addAbsenceButtonHandleClickIfAbsenceIsCreating();
        }

        const newBaseTimeIntervalsWithAddedAbsence = getGroupedAbsencesWithAddedCreatedAbsence(
            baseTimeIntervalInstance.id,
            usersStore.getBaseTimeIntervalInstancesWithExcusedAbsences(),
        );

        usersStore.setBaseTimeIntervalInstancesWithExcusedAbsences(
            newBaseTimeIntervalsWithAddedAbsence,
        );

        usersStore.setIsAbsenceCreating(true);
    };

    const hiddenAbsencesList = hiddenAbsences.map((hiddenAbsence) => (
        <Absence
            key={hiddenAbsence.id}
            isFirstAbsence={false}
            baseTimeIntervalInstance={baseTimeIntervalInstance}
            absence={hiddenAbsence}
            setIsFirstAbsenceEdit={setIsFirstAbsenceEdit}
        />
    ));

    const addAbsenceButton = (
        <button
            type="button"
            className={classes.absencesAccordion__absence_add}
            onClick={() => addAbsenceButtonHandleClick()}
        >
            Добавить запись
        </button>
    );

    return (
        <div className={classes.absencesAccordion}>
            <Accordion
                headerClassnames={
                    isFirstAbsenceEdit ? classes.headerClassname_active : classes.headerClassname
                }
                disabled={hasNoAbsences}
                isDefaultOpen
                additionalSetState={() => setIsAccordionOpen(!isAccordionOpen)}
            >

                <div
                    className={cn(classes.absencesAccordion__header, {
                        [classes.absencesAccordion__header_noBorder]:
                        spaceBaseTimeInterval.order === 1 && !isAccordionOpen,
                    })}
                >

                    <div className={classes.absencesAccordion__header_bti}>

                        <span className={classes.absencesAccordion__header_dark}>
                            {accordionHeaderInfo}
                        </span>

                        <span className={classes.absencesAccordion__header_gray}>
                            {excusedAbsencesAmountInfo}
                        </span>

                    </div>

                    <div className={classes.absencesAccordion__header_absence}>

                        {
                            firstAbsence ? (
                                <Absence
                                    isFirstAbsence
                                    baseTimeIntervalInstance={baseTimeIntervalInstance}
                                    absence={firstAbsence}
                                    setIsFirstAbsenceEdit={setIsFirstAbsenceEdit}
                                />
                            ) : (
                                <div className={classes.absencesAccordion__header_add}>
                                    {addAbsenceButton}
                                </div>
                            )
                        }

                    </div>

                </div>

                <div className={classes.absencesAccordion__items}>

                    {
                        hiddenAbsencesList
                    }

                    {
                        (excusedAbsences.length > 0 && !isAbsenceCreating) && (
                            <div
                                className={cn(
                                    classes.absencesAccordion__absence,
                                    classes.absencesAccordion__absence_lighterBorder,
                                )}
                            >
                                <div />

                                {addAbsenceButton}
                            </div>
                        )
                    }

                </div>

            </Accordion>
        </div>
    );
}

function getBaseTimeIntervalAbsencesAmount(
    excusedAbsences: ExcusedAbsence[],
) {
    let daysAmount = 0;

    excusedAbsences.forEach((absence) => {
        if (!checkIfAbsenceIsCreating([absence])) {
            const currentAbsenceDaysAmount = differenceInDays(
                new Date(absence.endDate),
                new Date(absence.startDate),
            );

            daysAmount += currentAbsenceDaysAmount;
        }
    });

    return daysAmount;
}

function getCreatedAbsence(
    absences: ExcusedAbsence[],
) {
    return absences.find((absence) => (
        new Date(absence.startDate).getFullYear() === 1970
        || new Date(absence.endDate).getFullYear() === 1970
    ));
}

function checkIfAbsenceIsCreating(
    absences: ExcusedAbsence[],
) {
    return absences.some((absence) => (
        new Date(absence.startDate).getFullYear() === 1970
        || new Date(absence.endDate).getFullYear() === 1970
    ));
}

function getGroupedAbsencesWithAddedCreatedAbsence(
    currentBaseTimeIntervalInstanceId: string,
    groupedAbsences: BaseTimeIntervalInstanceWithExcusedAbsences[],
): BaseTimeIntervalInstanceWithExcusedAbsences[] {
    const newGroupedAbsences = groupedAbsences.map((
        group,
    ) => {
        if (group.baseTimeIntervalInstance.id !== currentBaseTimeIntervalInstanceId) {
            return group;
        }
        const newExcusedAbsences: ExcusedAbsence[] = [
            ...group.excusedAbsences,
            {
                __typename: 'StudentExcusedAbsence',
                id: nanoid(),
                startDate: '1970',
                endDate: '1970',
            },
        ];

        return {
            ...group,
            excusedAbsences: newExcusedAbsences,
        };
    });

    return newGroupedAbsences;
}

function getGroupedAbsencesWithRemovedCreatedAbsence(
    currentBaseTimeIntervalInstanceId: string,
    groupedAbsences: BaseTimeIntervalInstanceWithExcusedAbsences[],
): BaseTimeIntervalInstanceWithExcusedAbsences[] {
    const newGroupedAbsences = groupedAbsences.map((
        group,
    ) => {
        if (group.baseTimeIntervalInstance.id === currentBaseTimeIntervalInstanceId) {
            return group;
        }

        const createdAbsence = getCreatedAbsence(group.excusedAbsences);

        const newExcusedAbsences: ExcusedAbsence[] = group.excusedAbsences.filter(
            (absence) => absence.id !== createdAbsence?.id,
        );

        return {
            ...group,
            excusedAbsences: newExcusedAbsences,
        };
    });

    return newGroupedAbsences;
}
