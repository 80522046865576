import React, { Fragment, useState } from 'react';

import {
    TeacherWeekMeetingsDurationLimitViolations,
} from '@admin/ScheduleGenerationPage/ScheduleGenerationConflicts/scheduleViolationInterface';
import {
    ArrowInShowViolation,
} from '@admin/ScheduleGenerationPage/ScheduleGenerationConflicts/shcheduleViolations/ArrowInShowViolation';

import classes from '../scheduleViolation.module.scss';

interface Props {
    teacherMaxWeekWorkloadMinutes: number;
    teacherViolation: TeacherWeekMeetingsDurationLimitViolations | null;
}

export function ShowTeacherLimitViolation(
    {
        teacherMaxWeekWorkloadMinutes,
        teacherViolation,
    }: Props,
) {
    const [isOpen, setIsOpen] = useState(false);
    const violationNameTitle = ` Суммарное время встреч преподавателя за неделю превышает ограничение равное ${teacherMaxWeekWorkloadMinutes} минутам`;

    return (
        <>
            {teacherViolation && (
                <div className={classes.schedule__violation_container}>
                    <div
                        className={classes.schedule__violation_title}
                        onClick={() => {
                            setIsOpen(!isOpen);
                        }}
                    >
                        <div className={classes.schedule__violation_content}>
                            {violationNameTitle}
                        </div>

                        <ArrowInShowViolation isOpen={isOpen} />
                    </div>

                    {isOpen && teacherViolation?.conflictsByWeek
                        .map((conflictByWeek, index) => (
                            <Fragment key={JSON.stringify(`${conflictByWeek.week}${index}`)}>
                                {
                                    conflictByWeek.conflicts.length ? (
                                        <>
                                            <div
                                                className={
                                                    classes.schedule__violation_conflict_title
                                                }
                                            >
                                                Неделя: {conflictByWeek.week}
                                            </div>

                                            {conflictByWeek.conflicts.map((conflict) => {
                                                const { user } = conflict.teacher;
                                                return (
                                                    <div
                                                        key={`${conflict.extraDurationMinutes} ${user.id}`}
                                                        className={
                                                            classes.schedule__violation_conflict
                                                        }
                                                    >
                                                        Преподаватель: {`${user.lastName} ${user.firstName} ${user.patronymic} `}
                                                        <div>Превышение: {`${conflict.extraDurationMinutes} минут`}</div>
                                                    </div>
                                                );
                                            })}
                                        </>
                                    ) : null
                                }
                            </Fragment>
                        ))}
                </div>
            )}
        </>
    );
}
