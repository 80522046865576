import React, { useEffect, useState } from 'react';
import { saveAs } from 'file-saver';
import { gql, useLazyQuery } from '@apollo/client';

import { Alert, Loader, LoaderSizes } from '@common';
import { CsvIcon } from '@common/LoadAttendanceReportButton/CsvIcon';
import classes from './LoadAttendanceReportButton.module.scss';

interface AttendanceReportVariables {
    moduleId: string;
    baseTimeIntervalInstanceId: string;
}

interface Props extends AttendanceReportVariables {}

const GET_ATTENDANCE_REPORT = gql`
    query Attendance_AttendanceReport(
        $input: AttendanceInput!
    ) {
        attendanceReport(
            input: $input
        )
    }
`;

export function LoadAttendanceReportButton(
    {
        moduleId,
        baseTimeIntervalInstanceId,
    }: Props,
) {
    const [file, setFile] = useState<{ value: string, name: string }>({ value: '', name: '' });
    const [error, setError] = useState<JSX.Element | null>(null);

    const [loadAttendanceReport, {
        loading: isReportLoading,
    }] = useLazyQuery<
    {
        attendanceReport: string,
    }
    >(GET_ATTENDANCE_REPORT, {
        fetchPolicy: 'no-cache',
        variables: {
            input: {
                moduleId,
                baseTimeIntervalInstanceId,
            },
        },
        onCompleted: (data) => {
            setFile({
                name: 'Посещаемость',
                value: data.attendanceReport,
            });
        },
        onError: (newError) => setError(<Alert message={newError.message} time={5000} />),
    });

    useEffect(() => {
        if (file.value) {
            const blob = new Blob([file.value], {
                type: 'text/csv;charset=utf-8',
            });
            saveAs(blob, `${file.name}`);
        }
    }, [
        file,
    ]);

    return (
        <div className={classes.loadReportButton_wrapper}>
            {!isReportLoading ? (
                <button
                    type="button"
                    onClick={() => {
                        loadAttendanceReport();
                    }}
                    className={classes.loadReportButton}
                >
                    <span className={classes.loadReportButton_text}>
                        <CsvIcon /> Выгрузить посещаемость [csv]
                    </span>

                </button>
            ) : (
                <Loader size={LoaderSizes.small} />
            )}

            {error}
        </div>
    );
}
