import React from 'react';

interface Props {
    size?: number;
    className?: string;
    onClick?(): void;
}

export function PinIcon({
    size = 20,
    className,
    onClick,
}: Props) {
    return (
        <div className={className} style={{ height: `${size}px`, width: `${size}px` }} onClick={onClick}>
            <svg width={size} height={size} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path d="M14 4v5c0 1.12.37 2.16 1 3H9c.65-.86 1-1.9 1-3V4h4m3-2H7c-.55 0-1 .45-1 1s.45 1 1 1h1v5c0 1.66-1.34 3-3 3v2h5.97v7l1 1 1-1v-7H19v-2c-1.66 0-3-1.34-3-3V4h1c.55 0 1-.45 1-1s-.45-1-1-1z" />
            </svg>
        </div>
    );
}
