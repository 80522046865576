import React from 'react';
import {
    TeacherRolesConflict,
    TeacherRoleScaleUnitConflicts,
} from '../../RoleConflictsDashboardInterfaces';
import classes from './Ruler.module.scss';

interface Props {
    timeSlots: TeacherRolesConflict,
}

const WEEK_DAYS = ['ВС', 'ПН', 'ВТ', 'СР', 'ЧТ', 'ПТ', 'СБ'];

const GRAY_COLOR = '#E5E5E5';
const WHITE_COLOR = 'white';

export const Ruler = ({ timeSlots }: Props): JSX.Element => {
    if (timeSlots) {
        return (
            <>
                {
                    timeSlots.conflictsCountByScaleUnits.map(
                        conflict => (
                            <th
                                className={classes.ruler__timeSlot}
                                key={`${conflict.date}-${conflict.startTime}-${conflict.endTime}`}
                                style={{
                                    backgroundColor: getRulerItemColor(conflict),
                                }}
                            >
                                <div className={classes.ruler__timeSlotContent}>
                                    <div className={classes.ruler__timeSlotContentText}>
                                        {getRulerText(conflict)}
                                    </div>
                                </div>
                            </th>
                        ),
                    )
                }
            </>
        );
    }
    return (<></>);
};

function getRulerItemColor(unitConflicts: TeacherRoleScaleUnitConflicts) {
    if (unitConflicts.dayOfWeek && unitConflicts.dayOfWeek % 2 === 0) {
        return GRAY_COLOR;
    }
    return WHITE_COLOR;
}

function getRulerText(conflict: TeacherRoleScaleUnitConflicts) {
    if (conflict.dayOfWeek && conflict.startTime) {
        return `${conflict.startTime.split(':')[0]}:${conflict.startTime.split(':')[1]} ${WEEK_DAYS[conflict.dayOfWeek]} ${conflict.date.split('-')[2]}/${conflict.date.split('-')[1]}`;
    }
    if (conflict.dayOfWeek) {
        return `${WEEK_DAYS[conflict.dayOfWeek]} ${conflict.date.split('-')[2]}/${conflict.date.split('-')[1]}`;
    }
    return `${conflict.week}`;
}
