import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import classes from './Alert.module.scss';

interface Props {
    className?: string;
    root?: string;
    message?: string;
    time?: number;
    onTimeout?(): void;
}

export function Alert({
    message, className, root, time, onTimeout = () => { },
}: Props): JSX.Element {
    const [styles, setStyles] = useState({});

    useEffect(() => {
        const alertTimeout = setTimeout(() => {
            setStyles((prevSimpleStyles) => ({
                ...prevSimpleStyles,
                display: 'none',
            }));
            onTimeout();
        }, time ?? 5000);
        return () => clearTimeout(alertTimeout);
    }, [message]);

    return (
        ReactDOM.createPortal(
            <div className={className ?? classes.alert} style={styles}>
                <svg width="29" height="26" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M14.5 1 28 25H1L14.5 1ZM14.5 16V8.5" stroke="#FFCC4D" />
                    <path d="M14.5 22a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3Z" fill="#FFCC4D" />
                </svg>

                <p className={classes.alert__message}>{message || 'Произошла ошибка'}</p>
            </div>,
            document.getElementsByClassName(root ?? 'alertContainer')[0] as Element,
        )
    );
}
