import React from 'react';
import { useMutation } from '@apollo/client';

import { Alert } from '@common/Alert/Alert';
import { Loader } from '@common/Loader';
import { StatusButtonToolBar } from './StatusButtonToolBar';
import { editStatus, Type, UserStatus } from '../apollo-types';
import { ToolBarButtonProps } from '../interface';
import { EDIT_STATUS } from '../gql';

export const StatusButtonAppolo = ({
    selectedUsers,
    refetchUserList,
}: ToolBarButtonProps): JSX.Element => {
    const [
        updateStatus,
        { loading, error },
    ] = useMutation<editStatus>(EDIT_STATUS, {
        onCompleted: () => { refetchUserList(); },
        // eslint-disable-next-line no-console
        onError: (err) => console.error(err),
    });

    if (loading) return <Loader />;

    const handleSubmit = (status: UserStatus, types: Type[]) => {
        updateStatus({
            variables: {
                data: {
                    ids: selectedUsers.map(({ id }) => id),
                    types,
                    status,
                },
            },
        });
    };

    return (
        <>
            <StatusButtonToolBar onSubmit={handleSubmit} />
            {error && <Alert message={error.message} time={3000} />}
        </>
    );
};
