import React from 'react';

import { ActionButtonRedesign, ActionTypeOfButton } from '@common/ActionButtonRedesign';
import cn from 'classnames';
import { DateWithTime } from '@common/DatePickerWithTime';
import { format } from 'date-fns-tz';
import { NavLink } from 'react-router-dom';
import classes from './ConfirmPopUp.module.scss';

interface Props {
    zoneId: string;
    intervalName: string;
    universityTimeZone: string;
    serialNumber: number;
    amountOfRoomsToCurrentZone: number;
    dateWithTime: DateWithTime;
    setPopUpVisible: (isVisible: boolean) => void;
    setCanAddInterval: (canAdd: boolean) => void;
    updateAvailableIntervals: () => void;
}

export function ConfirmPopUp(
    {
        zoneId,
        intervalName,
        serialNumber,
        universityTimeZone,
        dateWithTime,
        amountOfRoomsToCurrentZone,
        setPopUpVisible,
        updateAvailableIntervals,
        setCanAddInterval,
    }: Props,
) {
    const {
        fromTime,
        fromDate,
        toTime,
        toDate,
    } = dateWithTime;

    const startDateInfo = format(new Date(fromDate ?? ''), 'dd.MM.yyyy', { timeZone: universityTimeZone });
    const endaDateInfo = format(new Date(toDate ?? ''), 'dd.MM.yyyy', { timeZone: universityTimeZone });

    const fromNormalizedTime = fromTime.length ? fromTime : '00:00';
    const toNormalizedDate = toTime.length ? toTime : '00:00';

    const dateInfo = `c ${startDateInfo} ${fromNormalizedTime} до ${endaDateInfo} ${toNormalizedDate}`;

    return (
        <div className={classes.confirmPopUp}>

            <div className={classes.confirmPopUp__title}>
                Во всех
                {' '}
                <NavLink
                    to={`/university/room/zone/${zoneId}`}
                    target="_blank"
                    className={classes.confirmPopUp__title_blue}
                >
                    {amountOfRoomsToCurrentZone} помещениях
                </NavLink>
                {' '}
                тер. зоны {serialNumber}
                {' '}
                «
                <span className={classes.confirmPopUp__title_bold}>
                    { intervalName }
                </span>
                »
                {' '}
                изменятся даты доступности в соответствии
                с новыми датами:
            </div>

            <div className={classes.confirmPopUp__date}>
                {dateInfo}
            </div>

            <div className={classes.confirmPopUp__buttons}>
                <ActionButtonRedesign
                    actionType={ActionTypeOfButton.GREY}
                    onClick={() => {
                        setPopUpVisible(false);
                    }}
                >
                    <span
                        className={cn(classes.confirmPopUp__buttonsText,
                            classes.confirmPopUp__buttonsText_balck)}
                    >
                        Отменить
                    </span>
                </ActionButtonRedesign>

                <ActionButtonRedesign
                    onClick={() => {
                        updateAvailableIntervals();
                        setCanAddInterval(false);
                        setPopUpVisible(false);
                    }}
                    actionType={ActionTypeOfButton.PRIMARY}
                >
                    <span className={classes.confirmPopUp__buttonsText}>
                        Подтверждаю
                    </span>
                </ActionButtonRedesign>

            </div>

        </div>
    );
}
