import React, { ChangeEvent, Fragment } from 'react';
import { RadioButton } from '../RadioButton';
import { StatusCheckboxes } from '../StatusCheckboxes';
import styles from './RadioList.module.scss';

export interface ListOptions {
    id?: string;
    name: string;
    value: string;
}

interface Props {
    listOptions: ListOptions[];
    type: string;
    checked?: string;
    checkboxChecked?: boolean;
    onChange?(event: ChangeEvent<HTMLInputElement>): void;
    onChangeType?(value: string): void;
    children?: React.ReactNode;
    // onChangeAll?(event: React.ChangeEvent<HTMLInputElement>): void;
}

export const RadioList = ({
    listOptions, type, checked, onChange, onChangeType, checkboxChecked, children,
}: Props): JSX.Element => (
    <>
        <ul className={styles.radioList__list}>
            {listOptions.map((item) => (
                <Fragment key={item.id ?? item.name}>
                    <RadioButton
                        name={type}
                        label={
                            (checked === item.value && type === 'status')
                                ? `${item.name} как`
                                : `${item.name}`
                        }
                        value={item.value}
                        onChange={onChange}
                        checked={checked === item.value}
                    >
                        {(checked === item.value && type === 'status')
                        && (
                            <StatusCheckboxes
                                onChangeType={onChangeType}
                                // onChangeAll={onChangeAll}
                                checkboxChecked={checkboxChecked}
                            />
                        )
                        }
                        {checked === item.value && children}
                    </RadioButton>
                </Fragment>
            ))}
        </ul>
    </>
);
