import React, { useState } from 'react';
import classnames from 'classnames';
import { observer } from 'mobx-react-lite';
import { BorderPlusIcon } from '@common/svg';
import { Button } from '@admin/Users/ToolBar/Tools/CustomButton';
import { Calendar } from '@admin/Users/Calendar';
import { format } from 'date-fns';
import { userCardStore } from '@admin/Users/store/userCardStore';
import { SelectedItem } from '@admin/NewModule/SelectedItem';
import { nanoid } from 'nanoid';
import { PeriodChooser } from '../../WorkTimePicker/PeriodChooser';
import classes from './NonWorkingHours.module.scss';

enum DefaultTime {
    fromTime = '00:00',
    toTime = '23:59',
}

export const EditNonWorkingHours = observer((): JSX.Element => {
    const [dateRange, setDateRange] = useState([]);
    const [startDate, endDate] = dateRange;
    const onChangeCalendar = (update: []) => setDateRange(update);
    const [inputValues, setInputValues] = useState({ fromTime: DefaultTime.fromTime, toTime: DefaultTime.toTime, reason: '' });
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value, name } = event.target;
        setInputValues({ ...inputValues, [name]: value });
    };
    const defaultTime = inputValues.fromTime === DefaultTime.fromTime
        && inputValues.toTime === DefaultTime.toTime;

    const isDisabled = () => {
        const startValues = userCardStore.nonWorkingHours?.map((item) => item.from);
        const endValues = userCardStore.nonWorkingHours?.map((item) => item.to);
        if (
            startValues?.some(item => item === `${format(startDate, 'yyyy-MM-dd')}T${inputValues.fromTime}:00.000Z`)
            || endValues?.some(item => item === `${format(endDate, 'yyyy-MM-dd')}T${inputValues.toTime}:00.000Z`)
            || (format(startDate, 'yyyy-MM-dd') === format(endDate, 'yyyy-MM-dd') && inputValues.fromTime > inputValues.toTime)
            || (startValues?.some(item => item < `${format(startDate, 'yyyy-MM-dd')}T${inputValues.fromTime}:00.000Z`)
                && endValues?.some(item => item > `${format(startDate, 'yyyy-MM-dd')}T${inputValues.fromTime}:00.000Z`))
        ) { return true; }
        return false;
    };
    const handleAddNonWorkingHours = () => {
        if (startDate && endDate && !isDisabled()) {
            userCardStore.addNonWorkingHours({
                id: nanoid(),
                reason: inputValues.reason,
                from: `${format(startDate, 'yyyy-MM-dd')}T${inputValues.fromTime}:00.000Z`,
                to: `${format(endDate, 'yyyy-MM-dd')}T${inputValues.toTime}:00.000Z`,
            });
        }
        setDateRange([]);
        setInputValues({ fromTime: DefaultTime.fromTime, toTime: DefaultTime.toTime, reason: '' });
    };
    return (
        <div className={classes.nonWorkingHours}>
            <h3 className={
                classnames(classes.nonWorkingHours__title, classes.nonWorkingHours__title_bold)
            }
            >
                Нерабочая занятость:
            </h3>
            <h3 className={classes.nonWorkingHours__title}>
                Запланировать нерабочую занятость
            </h3>
            <input
                className={classes.nonWorkingHours__input}
                placeholder="Написать причину"
                type="text"
                name="reason"
                value={inputValues.reason ?? ''}
                onChange={handleChange}
            />
            <div className={classes.nonWorkingHours__container}>
                <Calendar
                    onChange={onChangeCalendar}
                    startDate={startDate}
                    endDate={endDate}
                    showTimeInput
                    customInput={(
                        <Button
                            modifier="calendar"
                        >
                            {startDate ? `${format(startDate, 'dd.MM.yyyy')} 
                            ${!defaultTime ? `${inputValues.fromTime} ` : ''} 
                            ${endDate ? `${format(endDate, '— dd.MM.yyyy')} ` : ''}
                            ${!defaultTime ? `${inputValues.toTime} ` : ''} ` : 'Выбрать даты и время'}
                        </Button>
                    )}
                    customTimeInput={(
                        <PeriodChooser
                            fromTime={inputValues.fromTime ?? ''}
                            toTime={inputValues.toTime ?? ''}
                            onChangeFromTime={handleChange}
                            onChangeToTime={handleChange}
                        />
                    )}
                    shouldCloseOnSelect={false}
                    modifier="timer"
                />
                <BorderPlusIcon
                    size={20}
                    handler={handleAddNonWorkingHours}
                />
            </div>
            {userCardStore.nonWorkingHours?.map((item) => {
                const from = new Date(item.from);
                from.setTime(from.getTime() + from.getTimezoneOffset() * 60 * 1000);
                const to = new Date(item.to);
                to.setTime(to.getTime() + to.getTimezoneOffset() * 60 * 1000);
                const newNonWorkingHours = { ...item, from, to };
                return (
                    <SelectedItem
                        key={item.id}
                        text={`
                        ${format(new Date(newNonWorkingHours.from), 'dd.MM.yyyy HH:mm')}
                        ${format(new Date(newNonWorkingHours.to), '— dd.MM.yyyy HH:mm ')}
                        ${item.reason}
                    `}
                        hasRemove
                        onRemoveItem={() => { userCardStore.removeNonWorkingHours(item.id); }}
                    />
                );
            })}
        </div>
    );
});
