import React from 'react';
import { observer } from 'mobx-react';

import { MonthSchedule } from './UnitsOfTime/MonthSchedule';
import { WeekSchedule } from './UnitsOfTime/WeekSchedule';
import { DaySchedule } from './UnitsOfTime/DaySchedule';
import { timeStore, UnitOfTime } from '../Store';

export const ScheduleTable = observer(() => (
    <>
        {timeStore.unitOfTime === UnitOfTime.MONTH && <MonthSchedule />}
        {timeStore.unitOfTime === UnitOfTime.WEEK && <WeekSchedule />}
        {timeStore.unitOfTime === UnitOfTime.DAY && <DaySchedule />}
    </>
));
