import React from 'react';
import { useQuery, useMutation, gql } from '@apollo/client';

import { Alert } from '@common/Alert';
import { Loader } from '@common/Loader';
import { PROPERTIES_FOR_MODULE_LIST } from '@admin/NewModule';
import { ModulesPage as ModulesPageComponent } from './ModulesPage';

export const GET_MODULES = gql`
    query GetModulesModulesPage {
        modules(ids: null) {
            ${PROPERTIES_FOR_MODULE_LIST}
        }
    }
`;

export const GET_MODULE_DRAFTS = gql`
    query GetModuleDraftsModulesPage {
        moduleDrafts(ids: null) {
            ${PROPERTIES_FOR_MODULE_LIST}
        }
    }
`;

export const REMOVE_MODULE = gql`
    mutation RemoveModule($id: String!){
        removeModule(id: $id){
            id
        }
    }
`;

const GetModules = () => {
    const [stateModules, setStateModules] = React.useState<any>();
    const [stateModuleDrafts, setStateModuleDrafts] = React.useState<any>();
    const { data: modules, loading: modulesLoading, error: modulesError } = useQuery(
        GET_MODULES,
        { fetchPolicy: 'no-cache' },
    );
    const { data: moduleDrafts, loading: moduleDraftsLoading, error: moduleDraftsError } = useQuery(
        GET_MODULE_DRAFTS, { fetchPolicy: 'cache-and-network' },
    );
    React.useEffect(() => setStateModules(modules), [modules]);
    React.useEffect(() => setStateModuleDrafts(moduleDrafts), [moduleDrafts]);
    const [removeModule, { error: errorRemove, data: dataRemove }] = useMutation(REMOVE_MODULE);

    const isLoading = modulesLoading || moduleDraftsLoading;
    const error = modulesError || moduleDraftsError || errorRemove;

    return (
        <>
            {isLoading ? (<Loader />) : (
                <ModulesPageComponent
                    publicModules={stateModules?.modules ?? []}
                    moduleDrafts={stateModuleDrafts?.moduleDrafts ?? []}
                    removeModule={(id: string) => removeModule({ variables: { id } })}
                    removeId={dataRemove?.removeModule?.id}
                />
            )}
            {error && <Alert message={error.message} time={5000} />}
        </>
    );
};

export { GetModules as ModulesPage };
