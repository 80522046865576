import React from 'react';
import { SkillCardSkillItem } from './SkillCardSkillItem';

import classes from './SkillCardWithoutNest.module.scss';

import {
    skillTypesData_skillTypes,
    skills_skills,
} from '../../../../deprecated-graphql-query-types';

interface Props {
    skillId: string,
    skillData: any;
    editState: boolean;
    skillType: skillTypesData_skillTypes;
    removeSkill(id: string): void;
    updateSkill: (
        id: string,
        name: string,
        typeId: string,
        description: string,
        maxLevel: number
    ) => void;
}

export function SkillCardWithoutNest({
    skillId,
    skillData,
    editState,
    skillType,
    removeSkill,
    updateSkill,
}: Props): JSX.Element {
    const letter = skillData[0].name.slice(0, 1).toUpperCase();
    return (
        <div className={classes.skillCardContent}>
            <div className={classes.skillCardContent__letter}>{letter}</div>
            <ul className={classes.skillCardContent__list}>
                {skillData.map((skill: skills_skills) => (
                    <li
                        id={skillId}
                        key={skill.id}
                    >
                        <SkillCardSkillItem
                            skillId={skill.id}
                            skillType={skillType}
                            item={skill}
                            editState={editState}
                            removeSkill={removeSkill}
                            updateSkill={updateSkill}
                        />
                    </li>
                ))}
            </ul>
        </div>
    );
}
