import { observer } from 'mobx-react';
import React from 'react';
import colors from '../../store/ColorConstans';

interface Props {
    size?: number;
    firstColor?: string;
    secondColor?: string;
    className?: string;
}

export const PointDisabledIcon = observer(({
    size = 6,
    firstColor = colors.firstDisableColor,
    secondColor = colors.secondDisableColor,
    className,
}: Props) => (
    <div className={className} style={{ height: `${size}px`, width: `${size}px`, cursor: 'default' }}>
        <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="3" cy="3" r="3" fill="url(#paint0_linear_3452_16630)" />
            <defs>
                <linearGradient id="paint0_linear_3452_16630" x1="-0.46988" y1="2.52532" x2="6.65064" y2="2.57692" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#C4C4C4" stopOpacity="0.04" />
                    <stop offset="0.453381" stopColor={firstColor} stopOpacity="0.85" />
                    <stop offset="0.901043" stopColor={secondColor} stopOpacity="0.43" />
                </linearGradient>
            </defs>
        </svg>
    </div>
));
