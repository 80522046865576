import React from 'react';
import cn from 'classnames';

import classes from './ButtonTemp.module.scss';

interface Props extends React.HTMLProps<HTMLButtonElement> {
    text: string;
}

export function ButtonTemp({
    text,
    form,
    onClick,
    className,
}: Props): JSX.Element {
    return (
        <button
            form={form || 'default'}
            type="submit"
            className={cn(classes.button, className)}
            onClick={onClick}
        >
            {text}
        </button>
    );
}
