/* eslint-disable @typescript-eslint/no-unused-vars */
import React, {
    useCallback,
    useState,
    useEffect,
    useRef,
    MouseEvent,
} from 'react';
import { debounce, cloneDeep, differenceWith } from 'lodash';
import cn from 'classnames';

import { IconDeprecated } from '@common';
import { useUrlQuery } from '@common/hooks';

import { Arrow } from './Arrow';
import { Option } from './Option';

import { SourceTypeInterface, SelectProps as Props } from './SelectInterfaces';

import classes from './Select.module.scss';

export function Select<SourceType extends SourceTypeInterface>(
    {
        options,
        urlParameterName,
        selectedOption: selectedOptionProp,
        className = classes.select_default,
        formatOption,
        onChange,
        addError,
    }: Props<SourceType>,
): JSX.Element | null {
    const {
        setUrlQuery,
        getUrlQuery,
    } = useUrlQuery();
    const [
        selectedOption, setSelectedOption] = useState<SourceType>(getInitialOption());
    const [
        targetElementRef,
        isSelectOptionsVisible,
        setIsSelectOptionsVisible,
    ] = useOnBlurHook();

    function getInitialOption() {
        if (urlParameterName) {
            const parameterValue = getUrlQuery(urlParameterName);
            if (parameterValue) {
                const initialOptionFromUrl = getOptionById(parameterValue, options) as SourceType;
                if (initialOptionFromUrl) return initialOptionFromUrl;
                addError('Параметр из адресной строки отсутствует');
            }
        }
        return selectedOptionProp ?? options[0] as SourceType;
    }

    function selectOption(option: SourceType) {
        if (urlParameterName) setUrlQuery({ [urlParameterName]: option.id });
        setIsSelectOptionsVisible(false);
        onChange(option);
        setSelectedOption(option);
    }

    function onClickOnOption(option: SourceType) {
        return (event: MouseEvent<HTMLDivElement>) => {
            event.stopPropagation();
            selectOption(option);
        };
    }

    return (
        <div
            className={cn(classes.select, className)}
            ref={targetElementRef}
            onClick={() => setIsSelectOptionsVisible(visibility => !visibility)}
        >
            <div className={
                isSelectOptionsVisible
                    ? cn(classes.select__header, classes.select__header_active)
                    : classes.select__header
            }
            >
                <div className={classes.select__selectedOption}>
                    {formatOption(selectedOption)}
                </div>

                <div className={cn(classes.select__arrow,
                    { [`${classes.select__arrow_reverse}`]: isSelectOptionsVisible })}
                >
                    <Arrow />
                </div>
            </div>

            {
                isSelectOptionsVisible && (
                    <div className={classes.list}>
                        {getAllElementsExceptOne(options, selectedOption).map(
                            (option) => (
                                <Option
                                    onClick={onClickOnOption(option)}
                                    name={formatOption(option)}
                                    key={`${option.id}`}
                                />
                            ),
                        )}
                    </div>
                )
            }
        </div>
    );
}

function getOptionById(id: string, array: SourceTypeInterface[]) {
    return array.find(
        (element) => element.id === id,
    );
}

export function getAllElementsExceptOne<SourceType extends SourceTypeInterface>(
    array: SourceType[],
    exception: SourceType,
): SourceType[] {
    return array.filter(element => element.id !== exception.id);
}

function useOnBlurHook(): [
    React.MutableRefObject<HTMLDivElement>,
    boolean,
    React.Dispatch<React.SetStateAction<boolean>>] {
    const targetElementRef = useRef<HTMLDivElement | undefined>(
        undefined,
    ) as React.MutableRefObject<HTMLDivElement>;
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        function handleClickOutside(event: Event) {
            if (targetElementRef.current
                && !targetElementRef.current.contains(event.target as Node)) {
                setIsVisible(false);
            }
        }
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [targetElementRef]);

    return [targetElementRef, isVisible, setIsVisible];
}
