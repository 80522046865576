import React from 'react';
import cn from 'classnames';

import classes from './InputWithFocusWithin.module.scss';

interface Props {
    id: string;
    defaultValue: string | number;
    onBlur: (value: string) => void;
    label?: string | number;
    inputClassName?: string;
    labelClassName?: string;
    onFocus?: () => void;
}

export function InputWithFocusWithin(
    {
        label,
        id,
        defaultValue,
        inputClassName,
        labelClassName,
        onBlur,
        onFocus,
    }: Props,
) {
    return (
        <label
            className={cn(labelClassName, {
                [classes.label]: !labelClassName,
            })}
            htmlFor={id}
        >
            {label}
            <input
                type="text"
                id={id}
                className={cn(inputClassName, {
                    [classes.input]: !inputClassName,
                })}
                defaultValue={defaultValue}
                onKeyPress={(key) => {
                    if (key.code === 'Enter') {
                        key.currentTarget.blur();
                    }
                }}
                onFocus={() => onFocus && onFocus()}
                onBlur={(e) => onBlur(e.target.value)}
            />
        </label>
    );
}
