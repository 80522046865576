import React from 'react';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import ru from 'date-fns/locale/ru';

import './Calendar.scss';

interface Props {
    startDate: null | Date;
    endDate: null | Date;
    onChange(date: Date | [(Date | null), (Date | null)] | null): void;
    customTimeInput?: JSX.Element;
    customInput?: JSX.Element;
    showTimeInput?: boolean;
    shouldCloseOnSelect?: boolean;
    modifier?: string;
}

export function Calendar({
    startDate, endDate, onChange,
    customTimeInput, showTimeInput, shouldCloseOnSelect, modifier, customInput,
}: Props): JSX.Element {
    return (
        <div className="calendar">
            <DatePicker
                selectsRange
                startDate={startDate}
                endDate={endDate}
                onChange={onChange}
                isClearable
                placeholderText="Выбрать даты"
                dateFormat="dd.MM.yyyy"
                locale={ru}
                className={`calendar__input calendar__input_${modifier}`}
                autoComplete="on"
                popperClassName="calendar__popperFiltration"
                wrapperClassName="calendar__wrapperFiltration"
                showYearDropdown
                dropdownMode="scroll"
                customTimeInput={customTimeInput}
                showTimeInput={showTimeInput}
                shouldCloseOnSelect={shouldCloseOnSelect}
                timeInputLabel="Время:"
                customInput={customInput}
                calendarStartDay={1}
            />
        </div>
    );
}
