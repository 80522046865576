import React from 'react';
import { observer } from 'mobx-react';

import {
    IntervalInfo,
    ModuleGroupWithAdditionalFields,
    StudentWithAdditionalFields,
} from '@admin/ModuleSelectionPage/Interfaces';
import { CheckboxRedesign } from '@common/CheckboxRedesign';
import cn from 'classnames';

import { moduleSelectionStore } from '@admin/ModuleSelectionPage/ModuleSelectionStore';
import { moduleSelectionDataStore } from '@admin/ModuleSelectionPage/ModuleSelectionDataStore';
import { MinInterval } from '@admin/ModuleSelectionPage/MinInterval';
import { MaxInterval } from '@admin/ModuleSelectionPage/MaxInterval';
import { checkIfStudentIsUnique, getStudentRating } from '@admin/ModuleSelectionPage/ModuleSelectionPage';
import classes from './ModuleStudentItem.module.scss';

interface Props {
    order: number;
    minIntervalInfo: IntervalInfo;
    maxIntervalInfo: IntervalInfo;
    currentModuleGroup: ModuleGroupWithAdditionalFields;
    currentStudent: StudentWithAdditionalFields;
}

export const ModuleStudentItem = observer((
    {
        order,
        minIntervalInfo,
        maxIntervalInfo,
        currentModuleGroup,
        currentStudent,
    } : Props,
): JSX.Element => {
    const isMinIntervalRed = currentModuleGroup.students.length
        < currentModuleGroup.module.minStudentCount;

    const isMaxIntervalRed = currentModuleGroup.students.length
        > currentModuleGroup.module.maxStudentCount
        && currentModuleGroup.module.maxWaveCount === currentModuleGroup.module.currentWaveIndex;

    const isItemBorderBlue = (currentModuleGroup.module.maxStudentCount === order
            && !isMaxIntervalRed);

    const isItemBorderRed = (isMinIntervalRed
            && currentModuleGroup.module.minStudentCount === order)
        || (!isMaxIntervalRed
            && currentModuleGroup.module.maxStudentCount === order);

    const isItemRed = order > currentModuleGroup.module.maxStudentCount && !isMaxIntervalRed;

    const { user } = currentStudent;

    const studentRating = getStudentRating(
        user.studentId ?? '',
        moduleSelectionStore.getStudentsWithRating(),
    );

    const isStudentUnique = checkIfStudentIsUnique(
        currentStudent,
        moduleSelectionStore.getNotDistribStudentsWithAdditionalFields(),
        moduleSelectionStore.getModuleGroupsWithAdditionalFields(),
    );

    const studentInfoWithOrder = `${order} ${user.lastName} ${user.firstName} ${user.patronymic ?? ''} ${isStudentUnique ? '' : user.studentId}`;
    const studentInfo = `${order} ${user.lastName} ${user.firstName} ${user.patronymic ?? ''} ${isStudentUnique ? '' : user.studentId}`;

    const handleChangeIfStudentIsSelected = () => {
        const movedStudentsRemovedCurrentStudent = moduleSelectionStore.getMovedStudents().filter(
            (student) => student.user.studentId !== currentStudent.user.studentId,
        );

        const notDistributedStudentsRemovedCurrentStudent = moduleSelectionStore
            .getNotDistribStudentsWithAdditionalFields().filter(
                (student) => student.user.id !== currentStudent.user.id,
            );

        moduleSelectionStore.setNotDistribStudentsWithAdditionalFields(
            notDistributedStudentsRemovedCurrentStudent,
        );
        moduleSelectionStore.setModuleGroupsWithAdditionalFields(
            getModulesWithRemovedAdditionalStudents(
                currentModuleGroup.module.id,
                currentStudent,
                moduleSelectionStore.getModuleGroupsWithAdditionalFields(),
            ),
        );
        moduleSelectionStore.setMovedStudents(
            movedStudentsRemovedCurrentStudent,
        );
    };

    const handleChangeIfStudentIsNotSelected = () => {
        moduleSelectionStore.setModuleGroupsWithAdditionalFields(
            getModulesAddedStudentToAdditional(
                currentModuleGroup.module.id,
                currentStudent,
                moduleSelectionStore.getModuleGroupsWithAdditionalFields(),
            ),
        );
        moduleSelectionStore.setMovedStudents(
            [...moduleSelectionStore.getMovedStudents(), currentStudent],
        );
        moduleSelectionStore.setNotDistribStudentsWithAdditionalFields(
            [
                {
                    ...currentStudent,
                    isSelected: false,
                    wasMoved: true,
                },
                ...moduleSelectionStore.getNotDistribStudentsWithAdditionalFields(),
            ],
        );
    };

    const handleChange = () => {
        if (currentStudent.isSelected) {
            handleChangeIfStudentIsSelected();
        } else {
            handleChangeIfStudentIsNotSelected();
        }
    };
    return (
        <>
            <div
                className={cn(classes.item, {
                    [classes.item_disabled]:
                    moduleSelectionDataStore.getURLParameters().isNotStudentChoice
                    || moduleSelectionStore.getIsDisabled(),
                    [classes.item_selected]: currentStudent.isSelected,
                    [classes.item_wasMoved]: currentStudent.wasMoved,
                })}
            >

                {
                    !currentStudent.isSelected && (
                        <div
                            className={cn(classes.item__static, {
                                [classes.item__static_red]: isItemRed,
                                [classes.item_borderTop]: order === 1,
                                [classes.item_borderRed]: isItemBorderRed,
                                [classes.item_borderBlue]: isItemBorderBlue,
                            })}
                        >

                            <div
                                className={cn(classes.item__name, {
                                    [classes.item__name_red]: isItemRed,
                                })}
                            >
                                { studentInfoWithOrder }
                            </div>

                            <div className={classes.item__rating}>
                                { studentRating }
                            </div>

                        </div>
                    )
                }

                <div
                    className={cn(classes.item__active, {
                        [classes.item_borderTop]: order === 1,
                        [classes.item_borderRed]: isItemBorderRed,
                        [classes.item_borderBlue]: isItemBorderBlue,
                    })}
                >
                    <CheckboxRedesign
                        id={`${currentStudent.user.id}_${module.id}`}
                        handleChangeCheckbox={() => handleChange()}
                        gap="7px"
                    >
                        <div className={classes.item__label}>

                            <div className={classes.item__name}>
                                { studentInfo }
                            </div>

                            <div className={classes.item__rating}>
                                { studentRating }
                            </div>

                        </div>
                    </CheckboxRedesign>
                </div>

            </div>

            <MinInterval
                interval={currentModuleGroup.module.minStudentCount}
                isRed={isMinIntervalRed}
                isVisible={currentModuleGroup.module.minStudentCount === order
                    && isMinIntervalRed}
                studentInfo={minIntervalInfo.studentInfo}
                wavesInfo={minIntervalInfo.wavesInfo}
            />

            <MaxInterval
                interval={currentModuleGroup.module.maxStudentCount}
                isRed={isMaxIntervalRed}
                isVisible={currentModuleGroup.module.maxStudentCount === order && !isMaxIntervalRed}
                studentInfo={maxIntervalInfo.studentInfo}
                wavesInfo={maxIntervalInfo.wavesInfo}
            />
        </>
    );
});

function getModulesWithRemovedAdditionalStudents(
    currentModuleId: string,
    currentStudent: StudentWithAdditionalFields,
    moduleGroups: ModuleGroupWithAdditionalFields[],
) {
    return moduleGroups.map((moduleGroup) => {
        if (moduleGroup.module.id !== currentModuleId) {
            return {
                ...moduleGroup,
                additionalStudents: moduleGroup.additionalStudents.filter(
                    (student) => student.user.studentId !== currentStudent.user.studentId,
                ),
            };
        }

        return {
            ...moduleGroup,
            students: moduleGroup.students.map((student) => {
                if (student.user.studentId !== currentStudent.user.studentId) return student;
                return {
                    ...student,
                    isSelected: !student.isSelected,
                };
            }),
        };
    });
}

function getModulesAddedStudentToAdditional(
    currentModuleId: string,
    currentStudent: StudentWithAdditionalFields,
    moduleGroups: ModuleGroupWithAdditionalFields[],
) {
    return moduleGroups.map((moduleGroup) => {
        if (moduleGroup.module.id !== currentModuleId) {
            if (
                checkIfStudentInModule(
                    currentStudent.user.studentId ?? '',
                    moduleGroup,
                )
            ) return moduleGroup;

            return {
                ...moduleGroup,
                additionalStudents: [...moduleGroup.additionalStudents, currentStudent],
            };
        }

        return {
            ...moduleGroup,
            students: moduleGroup.students.map((student) => {
                if (student.user.studentId !== currentStudent.user.studentId) return student;
                return {
                    ...student,
                    isSelected: !student.isSelected,
                };
            }),
        };
    });
}
function checkIfStudentInModule(
    studentId: string,
    moduleGroup: ModuleGroupWithAdditionalFields,
) {
    return moduleGroup.students.some(
        (student) => student.user.studentId === studentId,
    );
}
