import React, { useState } from 'react';
import { useQuery } from '@apollo/client';

import { PopupWithSkills } from './PopupWithSkills';
import { GET_SKILL_TYPES_WITH_MODULE_SETTINGS, SkillTypesWithModuleSettings } from '../../../../gql/skillTypesWithModuleSettings';
import { SkillTypeExtended } from './interfaces/skillType';
import { Skill } from './interfaces/skill';
import { getFinalSkillTypes, getSkillTypesExtended } from './mapping/skillTypes';
import { getPlainModuleSettings } from './mapping/moduleSettings';
import { ToolBarButtonProps } from '../../interface';
import { GET_SKILLS } from '../../gql';

interface Props extends ToolBarButtonProps {
    isOpen: boolean;
    onClose(): void;
}

export const PopupWithSkillsApollo = ({
    isOpen,
    onClose,
    selectedUsers,
    refetchUserList,
}: Props): JSX.Element => {
    const [selectedSkillType, setSelectedSkillType] = useState<null | SkillTypeExtended>(null);

    const {
        data: skillTypesWithModuleSettings,
        loading: loadingSkillTypes,
    } = useQuery<SkillTypesWithModuleSettings>(
        GET_SKILL_TYPES_WITH_MODULE_SETTINGS, {
            fetchPolicy: 'cache-and-network',
        },
    );

    const {
        data: dataSkills,
        loading: loadingSkills,
    } = useQuery<{ skills: Skill[] }>(GET_SKILLS, {
        skip: selectedSkillType === null,
        variables: {
            options: {
                typeIds: selectedSkillType?.id,
            },
        },
        fetchPolicy: 'cache-and-network',
    });

    return (
        <PopupWithSkills
            loadingSkillTypes={loadingSkillTypes}
            skillTypes={
                getFinalSkillTypes(
                    getSkillTypesExtended(
                        getPlainModuleSettings(
                            skillTypesWithModuleSettings?.moduleSettings,
                        ),
                        skillTypesWithModuleSettings?.skillTypes,
                    ),
                )
            }
            selectedSkillType={selectedSkillType}
            loadingSkills={loadingSkills}
            skills={dataSkills?.skills}
            isOpen={isOpen}
            setSelectedSkillType={setSelectedSkillType}
            onClose={onClose}
            selectedUsers={selectedUsers}
            refetchUserList={refetchUserList}
        />
    );
};
