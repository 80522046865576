/* eslint-disable indent */
import React from 'react';
import { useMutation } from '@apollo/client';
import { observer } from 'mobx-react';

import {
    EditAssignmentInstance,
    EditEvaluationPointNote,
    EditMeetingInstanceAsStudent,
    EditMeetingInstanceAsTeacher,
    FilterType,
} from '../../../Interfaces';
import {
    EDIT_MEETING_INSTANCE_NOTE_BY_STUDENT,
    EDIT_MEETING_INSTANCE_NOTE_BY_TEACHER,
    GET_MEETING_INSTANCES_BY_STUDENT,
    GET_MEETING_INSTANCES_BY_TEACHER,
} from '../../../gql/meetingInstance';
import {
    EDIT_ASSIGNMENT_INSTANCE_NOTE,
    GET_ASSIGNMENT_INSTANCES,
} from '../../../gql/assignmentInstance';
import {
    EDIT_EVALUATION_POINT_TEACHER_NOTE,
    GET_EVALUATION_POINT_INSTANCES,
} from '../../../gql/evaluationPointInstance';
import { accountStore } from '../../../../Store';
import { eventsStore, timeStore } from '../../../Store';

import { NoteButton } from './Note/NoteButton';

interface Props {
    children: string;
    apolloData: string;
    showNote: boolean;
    click(): void;
}

interface EventType {
    meeting: EditMeetingInstanceAsTeacher | EditMeetingInstanceAsStudent;
    evaluation: EditEvaluationPointNote;
    assignment: EditAssignmentInstance;
}

export const ScheduleEventSectionApollo = observer(({
    children,
    apolloData,
    showNote,
    click,
}: Props): JSX.Element => {
    const [updateMeetingNoteAsTeacher] = useMutation<EditMeetingInstanceAsTeacher>(
        EDIT_MEETING_INSTANCE_NOTE_BY_TEACHER,
        {
            refetchQueries: [
                {
                    query: GET_MEETING_INSTANCES_BY_TEACHER,
                    variables: {
                        getMeetingInstanceInput: {
                            // teacherId: accountStore.teacherId,
                            fromDate: timeStore.getScheduleStart(),
                            toDate: timeStore.getScheduleEnd(),
                        },
                    },
                },
            ],
        },
    );

    const [updateMeetingNoteAsStudent] = useMutation<EditMeetingInstanceAsStudent>(
        EDIT_MEETING_INSTANCE_NOTE_BY_STUDENT,
        {
            refetchQueries: [
                {
                    query: GET_MEETING_INSTANCES_BY_STUDENT,
                    variables: {
                        getMeetingInstanceInput: {
                            fromDate: timeStore.getScheduleStart(),
                            toDate: timeStore.getScheduleEnd(),
                        },
                    },
                },
            ],
        },
    );

    const [editAssignmentNote] = useMutation<EditAssignmentInstance>(
        EDIT_ASSIGNMENT_INSTANCE_NOTE,
        {
            refetchQueries: [
                {
                    query: GET_ASSIGNMENT_INSTANCES,
                    variables: {
                        assignmentInstanceInput: {
                            // studentId: accountStore.studentId,
                            fromDate: timeStore.getScheduleStart(),
                            toDate: timeStore.getScheduleEnd(),
                        },
                    },
                },
            ],
        },
    );

    const [editEvaluationPointTeacherNote] = useMutation<EditEvaluationPointNote>(
        EDIT_EVALUATION_POINT_TEACHER_NOTE,
        {
            refetchQueries: [
                {
                    query: GET_EVALUATION_POINT_INSTANCES,
                    variables: {
                        getEvaluationPointInstanceInput: {
                            teacherId: accountStore.teacherId,
                            fromDate: timeStore.getScheduleStart(),
                            toDate: timeStore.getScheduleEnd(),
                        },
                    },
                },
            ],
        },
    );

    function setDataForMeetingAsTeacher(): EditMeetingInstanceAsTeacher {
        return {
            meetingInstanceId: !eventsStore.isAssignment(eventsStore.selectedEvent)
                && eventsStore.selectedEvent?.id,
            note: apolloData,
        } as EditMeetingInstanceAsTeacher;
    }

    function setDataForMeetingAsStudent(): EditMeetingInstanceAsStudent {
        return {
            meetingInstanceId: !eventsStore.isAssignment(eventsStore.selectedEvent)
                && eventsStore.selectedEvent?.id,
            note: apolloData,
        } as EditMeetingInstanceAsStudent;
    }

    function setDataForEvaluation(): EditEvaluationPointNote {
        return {
            evaluationPointInstanceId: !eventsStore.isAssignment(eventsStore.selectedEvent)
                && eventsStore.selectedEvent?.id,
            note: apolloData,
            teacherId: accountStore.teacherId,
        } as EditEvaluationPointNote;
    }

    function setDataForAssignment(): EditAssignmentInstance {
        return {
            assignmentInstanceId: !eventsStore.isAssignment(eventsStore.selectedEvent)
                && eventsStore.selectedEvent?.id,
            studentId: accountStore.studentId,
            note: apolloData,
        } as EditAssignmentInstance;
    }

    function getDataAccordingToSelectedEvent(): EventType {
        const eventType = {
            meeting: chooseUserType(),
            evaluation: setDataForEvaluation(),
            assignment: setDataForAssignment(),
        };
        return eventType;
    }

    function chooseUserType(): EditMeetingInstanceAsTeacher | EditMeetingInstanceAsStudent {
        if (accountStore.userType === 'teacher') {
            return setDataForMeetingAsTeacher();
        }
        return setDataForMeetingAsStudent();
    }

    function updateMeetingNoteByUserType(
        data: EditMeetingInstanceAsTeacher | EditMeetingInstanceAsStudent,
    ) {
        if (accountStore.userType === 'teacher') {
            updateMeetingNoteAsTeacher({ variables: { data } });
        } else {
            updateMeetingNoteAsStudent({ variables: { data } });
        }
    }

    function updateAssignmentNote(data: EditAssignmentInstance) {
        editAssignmentNote({ variables: { data } });
    }

    function updateEvaluationNote(data: EditEvaluationPointNote) {
        editEvaluationPointTeacherNote({ variables: { data } });
    }

    function chooseSaveNoteMutation() {
        const data = getDataAccordingToSelectedEvent();
        switch (eventsStore.selectedEvent?.type) {
            case FilterType.MEETING:
                updateMeetingNoteByUserType(data.meeting);
                break;
            case FilterType.ASSIGNMENT:
                updateAssignmentNote(data.assignment);
                break;
            case FilterType.EVALUATION_POINT:
                updateEvaluationNote(data.evaluation);
                break;
            default:
                break;
        }
    }

    function sendData(): void {
        click();
        chooseSaveNoteMutation();
    }
    return (
        <NoteButton text={children} onClick={sendData} showNote={showNote} note={apolloData} />
    );
});
