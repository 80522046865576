import { useQuery } from '@apollo/client';
import { GET_TRAJECTORIES } from '@common/qraphql/queries';
import { Trajectory } from '../types';

export function useTrajectoriesQuery(spaceId: string | undefined): Trajectory[] {
    const { data, loading } = useQuery(GET_TRAJECTORIES, {
        fetchPolicy: 'network-only',
        variables: { spaceId },
    });
    return loading ? [] : data?.trajectories;
}
