import React, { useEffect, useRef } from 'react';

import classes from './TrajectoriesMarks.module.scss';

import { MarksSelectItem } from './MarksSelectItem';
import MarksStore from '../Store/MarksStore';
import { CloseIconGradient } from '../../assets/CloseIconGradient';

export const MarksSelect = (): JSX.Element => {
    const ref = useRef<null | HTMLDivElement>(null);

    useEffect(() => {
        const handleClickOutside = (event: any) => {
            if (ref.current && !ref.current.contains(event.target)) {
                MarksStore.changeShowMarksList();
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [ref]);
    return (
        <div className={classes.trajectoriesMarks__marksSelect} ref={ref}>
            <div
                className={classes.trajectoriesMarks__icon}
                onClick={() => { MarksStore.changeShowMarksList(); }}
            >
                <CloseIconGradient />
            </div>
            <ul className={classes.trajectoriesMarks__marksList}>
                <li className={classes.trajectoriesMarks__marksItem}>
                    <MarksSelectItem
                        text="Опубликованные"
                        markType="publicTrajectories"
                        color="#FFA07A"
                    />
                </li>
                <li className={classes.trajectoriesMarks__marksItem}>
                    <MarksSelectItem
                        text="Неопубликованные"
                        markType="privateTrajectories"
                        color="#FFC452"
                    />
                </li>
                {/* <li className={classes.trajectoriesMarks__marksItem}>
                <MarksSelectItem
                    text="Персонажи"
                    markType="personTrajectories"
                    color="#000"
                />
            </li>
            <li className={classes.trajectoriesMarks__marksItem}>
                <MarksSelectItem
                    text="Пресет"
                    markType="presetTrajectories"
                    color="#C4C4C4"
                />
            </li> */}
            </ul>
        </div>
    );
};
