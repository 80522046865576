import { cloneDeep } from 'lodash';

export enum WeekDaysRussian {
    Monday = 'ПН',
    Tuesday = 'ВТ',
    Wednesday = 'СР',
    Thursday = 'ЧТ',
    Friday = 'ПТ',
    Saturday = 'СБ',
    Sunday = 'ВС',
}

export enum WeekType {
    even = 'even',
    odd = 'odd',
    every = 'every',
}
export interface WorkingInterval {
    id: string,
    start: string,
    end: string,
    weekType: WeekType,
}

export interface DaySchedule {
    weekDay: string,
    workingIntervals: WorkingInterval[],
}

export const WEEK_DAYS: WeekDays = [
    ['Monday', []],
    ['Tuesday', []],
    ['Wednesday', []],
    ['Thursday', []],
    ['Friday', []],
    ['Saturday', []],
    ['Sunday', []],
];

export type WeekDay = ([(keyof typeof WeekDaysRussian), WorkingInterval[]]);

export type WeekDays = ([(keyof typeof WeekDaysRussian), WorkingInterval[]])[];

function getWeekDayIndex(daySchedule: DaySchedule, weekDays: WeekDays) {
    return weekDays.findIndex(([name]) => name === daySchedule.weekDay);
}

export function getWeekDaysWithAdditionSchedule(daySchedules: DaySchedule[]) {
    const weekDays = cloneDeep(WEEK_DAYS) as WeekDay[];

    daySchedules.forEach((daySchedule: DaySchedule) => {
        const weekDayIndex = getWeekDayIndex(daySchedule, weekDays);

        if (weekDayIndex > -1) {
            weekDays[weekDayIndex][1] = daySchedule.workingIntervals;
        }
    });

    return weekDays;
}

export function getFormatedTime(date: string) {
    if (date === '') {
        return date;
    }

    const splittedTime = date.split(':');
    const minutes = splittedTime.length === 3 ? `:${splittedTime[1]}` : '';
    const hours = splittedTime[0];

    return `${hours}${minutes}`;
}

export function getFormatedDate(time: string) {
    if (!(/^[0-2]?[0-9]?[:]?[0-6]?[0-9]?$/.test(time)) || time === '') {
        return '';
    }

    return `${time}:00.000Z`;
}
