import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import cn from 'classnames';

import { Alert } from '@common/Alert';
import { Loader } from '@common/Loader';
import {
    GetEquipmentsOnRoomPage_equipment_list,
    RepeatingAvailableIntervalInput,
    UpdateRoomInput,
} from 'src/graphql-query-types';
import { uniqBy } from 'lodash';
import { AddRoomButton } from './AddRoomButton';
import { RoomCreationContainer } from './RoomCreation';
import { RoomContainer } from './RoomsList';
import {
    AlertMessage,
    Building, Day,
    RoomBackendData,
    RoomMeetingFormat,
    TerritorialZone,
} from './RoomTypes';

import classes from './Rooms.module.scss';

interface RoomPageProps {
    universityTimeZone: string;
    buildings?: Building[];
    allRooms?: RoomBackendData[];
    territorialZoneRooms?: RoomBackendData[];
    meetingFormats?: RoomMeetingFormat[];
    storeEquipment?: GetEquipmentsOnRoomPage_equipment_list[];
    alert: AlertMessage;
    territorialZones?: TerritorialZone[];
    isLoading: boolean;
    tabsState: [string, React.Dispatch<React.SetStateAction<string>>];
    createRoomHandler(createRoomData: UpdateRoomInput): void;
    editRoomHandler(editRoomData: UpdateRoomInput): void;
    deleteRoomHandler(id: string): void;
    getRoomsHandler: () => void;
}

export function RoomsPage({
    universityTimeZone,
    buildings,
    allRooms,
    territorialZoneRooms,
    meetingFormats,
    storeEquipment,
    alert,
    territorialZones = [],
    isLoading,
    tabsState,
    createRoomHandler,
    editRoomHandler,
    deleteRoomHandler,
    getRoomsHandler,
}: RoomPageProps): JSX.Element {
    const { id }: { id: string } = useParams();
    const [newRoom, setNewRoom] = useState<boolean>(false);
    const [changeRoom, setChangeRoom] = useState<boolean>(false);
    const [roomID, setRoomID] = useState<string>('');
    const [rooms, setRooms] = useState(allRooms);
    const [activeTab, setActiveTab] = tabsState;

    const history = useHistory();

    useEffect(() => getRoomsHandler(), [activeTab]);

    useEffect(() => {
        document.getElementById(id)?.scrollIntoView();
    }, [rooms]);

    useEffect(() => {
        if (activeTab === 'all') {
            setRooms(allRooms);
            history.push(id ? `/university/room/${id}/zone` : '/university/room/zone');
        } else {
            setRooms(territorialZoneRooms);
            history.push(id ? `/university/room/${id}/zone/${activeTab}` : `/university/room/zone/${activeTab}`);
        }
    }, [activeTab, allRooms, territorialZoneRooms]);

    const addRoomButton = () => {
        setNewRoom((prevStateButton) => !prevStateButton);
    };

    const editRoomButton = (roomId: string) => {
        if (roomID !== roomId) {
            setChangeRoom(true);
            setRoomID(roomId);
        } else {
            setChangeRoom(!changeRoom);
        }
    };

    const tabs = [['all', 'Все'], ...territorialZones?.map(zone => ([zone.id, zone.name]))];

    return (
        <>
            <h2>Помещения университета:</h2>
            <div className={classes.tabs}>
                <ul className={classes.tabs__list}>
                    {tabs.map((tab) => (
                        <li
                            key={tab[0]}
                            className={classes.tabs__item}
                        >
                            <div
                                onClick={() => {
                                    setActiveTab(tab[0]);
                                }}
                                className={
                                    cn(classes.tabs__tab, {
                                        [classes.tabs__tab_selected]:
                                            tab[0] === activeTab,
                                    })
                                }
                            >
                                {tab[1]}
                            </div>
                        </li>
                    ))}
                </ul>
            </div>
            {(!newRoom && !changeRoom) && (<AddRoomButton addRoomButton={addRoomButton} />)}
            {isLoading && <Loader />}

            {
                (rooms && meetingFormats && buildings && storeEquipment) && (
                    <>
                        {
                            (newRoom) && (
                                <RoomCreationContainer
                                    universityTimeZone={universityTimeZone}
                                    activeTab={activeTab}
                                    buildings={buildings}
                                    meetingFormats={meetingFormats}
                                    storeEquipment={storeEquipment}
                                    createRoomHandler={createRoomHandler}
                                    editRoomHandler={editRoomHandler}
                                    addRoomButton={addRoomButton}
                                />
                            )
                        }
                        <div className={classes.list}>
                            {
                                (rooms.length > 0 && !newRoom) && (
                                    rooms.map((room: RoomBackendData) => (
                                        <RoomContainer
                                            activeTab={activeTab}
                                            universityTimeZone={universityTimeZone}
                                            roomID={roomID === room.id}
                                            roomInfo={room}
                                            buildings={buildings}
                                            meetingFormats={meetingFormats}
                                            storeEquipment={storeEquipment}
                                            deleteRoomHandler={deleteRoomHandler}
                                            editRoomHandler={editRoomHandler}
                                            createRoomHandler={createRoomHandler}
                                            addRoomButton={addRoomButton}
                                            editRoomButton={editRoomButton}
                                            key={room.id}
                                        />
                                    ))
                                )
                            }
                            {
                                alert.alert && (<Alert message={alert.message} time={2500} />)
                            }
                        </div>
                    </>
                )
            }
        </>
    );
}

export function getGroupedRepeatingIntervals(
    intervals?: RepeatingAvailableIntervalInput[],
) {
    if (!intervals || !intervals.length) {
        return [];
    }

    const uniqIntervals = getUniqIntervals(intervals);

    const groupedIntervals: RepeatingAvailableIntervalInput[][] = uniqIntervals.map(
        (uniqInterval) => (
            intervals.filter(
                (
                    { from, to, weekType },
                ) => (
                    from === uniqInterval.from && to === uniqInterval.to
                && weekType === uniqInterval.weekType
                ),
            )
        ),
    );

    const groupedIntervalsWithUniqDaysInGroups = groupedIntervals.map(
        (intervalsGroup) => uniqBy(intervalsGroup, interval => interval.weekDay),
    );

    return groupedIntervalsWithUniqDaysInGroups;
}

function getUniqIntervals(
    intervals: RepeatingAvailableIntervalInput[],
) {
    const uniqIntervals: RepeatingAvailableIntervalInput[] = [];

    intervals.forEach((interval) => {
        const isIntervalNotUniq = uniqIntervals.some((uniqInterval) => (
            interval.from === uniqInterval.from
            && interval.to === uniqInterval.to
            && interval.weekType === uniqInterval.weekType
        ));

        if (!isIntervalNotUniq) {
            uniqIntervals.push(interval);
        }
    });

    return uniqIntervals;
}

export function getWeekDaysInfoString(
    repeatingIntervals: RepeatingAvailableIntervalInput[],
) {
    let weekDaysInfoString = '';

    repeatingIntervals.forEach((interval, index) => {
        const hasComma = index !== repeatingIntervals.length - 1;
        weekDaysInfoString += ` ${Day[interval.weekDay]}${hasComma ? ',' : ''}`;
    });

    return weekDaysInfoString;
}
