import React, { useState } from 'react';

import { BorderCrossIcon } from '@common/svg';
import { Dialog } from '@common/Dialog';

import classes from './FormatsCardItem.module.scss';

import { FormatsCardDialog } from './FormatsCardDialog';

interface Format {
    id: string;
    name: string;
}

interface Props {
    item: Format;
    editState: boolean;
    updateMeetingFormat: (id: string, name: string) => void;
    deleteMeetingFormat: (id: string) => void;
}

export function FormatsCardItem({
    item,
    editState,
    updateMeetingFormat,
    deleteMeetingFormat,
}: Props): JSX.Element {
    const [dialog, toggleDialog] = useState(false);
    return (
        <div className={classes.formatsCardItem}>
            <div
                className={classes.formatsCardItem__title}
                onClick={editState ? () => toggleDialog(!dialog) : () => null}
            >
                {item.name}
            </div>
            {editState && (
                <BorderCrossIcon
                    size={18}
                    handler={() => deleteMeetingFormat(item.id)}
                />
            )}
            {dialog && (
                <Dialog
                    id="portal-root"
                    dom={(
                        <FormatsCardDialog
                            item={item}
                            dialog={dialog}
                            toggleDialog={toggleDialog}
                            updateMeetingFormat={updateMeetingFormat}
                        />
                    )}
                />
            )}
        </div>
    );
}
