import { ApolloError, useMutation } from '@apollo/client';
import { GET_EVENT_DATA, CHANGE_EVALUATION_POINT_INSTANCE_STATUS } from '../../queries';

export const useUpdateEvaluationPointStatusMutation = (
    id: string,
    type: string,
    setErrorsFunction: (error: ApolloError) => void,
) => {
    const [
        changeEvaluationPointInstanceStatus,
    ] = useMutation(CHANGE_EVALUATION_POINT_INSTANCE_STATUS, {
        refetchQueries: [{
            query: GET_EVENT_DATA,
            variables: {
                tmpMeetingInstanceInput: { id, type },
            },
        }],
        onError: (error) => setErrorsFunction(error),
    });

    return {
        updateEvaluationPointStatus: (
            evaluationPointInstanceId: string,
            newStatus: string,
        ) => changeEvaluationPointInstanceStatus({
            variables: {
                changeEvaluationPointInstanceStatusInput:
                { evaluationPointInstanceId, newStatus },
            },
        }),
    };
};
