import React from 'react';

import { observer } from 'mobx-react';
import { Subspace, SubspaceType } from '@admin/Trajectories/types';
import classnames from 'classnames';
// import { ExtraDetail } from '../ExtraDetail';
// import { PhotoLoader } from '../PhotoLoader';

import classes from './TrajectoryContent.module.scss';
import { trajectoryStore } from '../Store/TrajectoryStore';
import { TextPreview } from '../TextPreview';
import { Textarea } from '../TextPreview/Textarea';

interface Props {
    selectedSubspaces: (Subspace|undefined)[];
    subspaceTypes: (SubspaceType[]|undefined);
    isEdit?: boolean;
}
export const TrajectoryContent = observer(({
    selectedSubspaces, subspaceTypes, isEdit,
}: Props):JSX.Element => (
    <section className={classes.trajectoryContent}>
        <h2 className={classes.trajectoryContent__title}>Траектория</h2>
        <div className={classnames(classes.trajectoryContent__area, {
            [classes.trajectoryContent__area_active]: selectedSubspaces.length !== 0,
            [classes.trajectoryContent__area_edit]: isEdit,
        })}
        >
            {selectedSubspaces.length < subspaceTypes!.length
                ? (
                    <p className={classes.trajectoryContent__disabled}>
                        Для создания траектории выберите подпространство из типа
                    </p>
                )
                : (
                    <div className={classes.trajectoryContent__inputs}>
                        <div className={classes.trajectoryContent__input}>
                            <Textarea
                                placeholder="Введите текст"
                                label="Название траектории:"
                                name="name"
                                value={trajectoryStore.name}
                                onChange={({ target }) => trajectoryStore.setName(target.value)}
                            />
                        </div>
                        <TextPreview
                            placeholder="Введите текст"
                            label="Описание всей траектории:"
                            modifier="description"
                            name="description"
                            previewName="описания"
                            value={trajectoryStore.description}
                            onChangeWysiwyg={(newValue: string) => {
                                trajectoryStore.setDescription(newValue);
                            }}
                            isWysiwyg
                        />
                        {/* <ExtraDetail
                            textToOpen="Добавить изображение"
                            textToClose="Удалить изображение"
                            content={<PhotoLoader />}
                        /> */}
                    </div>
                )
            }
        </div>
    </section>
));
