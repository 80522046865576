import { FetchResult, MutationFunctionOptions, OperationVariables } from '@apollo/client';
import { userCardStore } from '../store/userCardStore';
import { WeekType } from './TimePicker/modelTimePicker';

interface EditUser {
    (
        userId: string,
        teacherId: string,
        studentId: string,
        dispatchedWorkingHours: (never[] | {
            id: string;
            from: string;
            to: string;
            day: string;
            weekType: WeekType;
        })[],
        setRoles: (options?: MutationFunctionOptions<any, OperationVariables> | undefined) =>
        Promise<FetchResult<any, Record<string, any>, Record<string, any>>>,
        setAdminRoles: (options?: MutationFunctionOptions<any, OperationVariables> | undefined) =>
        Promise<FetchResult<any, Record<string, any>, Record<string, any>>>,
        setWorkingInterval: (options?: MutationFunctionOptions<any, OperationVariables>
        | undefined) =>
        Promise<FetchResult<any, Record<string, any>, Record<string, any>>>,
        setStudentWaves: (options?: MutationFunctionOptions<any, OperationVariables> | undefined) =>
        Promise<FetchResult<any, Record<string, any>, Record<string, any>>>
    )
    : void;
}

const compare = (el1: { [key: string]: any; }, el2: { [key: string]: any; }, key: string) => {
    if (el1[key] < el2[key]) return -1;
    if (el1[key] > el2[key]) return 1;
    return 0;
};

export const editUser: EditUser = async (
    userId,
    teacherId,
    studentId,
    dispatchedWorkingHours,
    setRoles,
    setAdminRoles,
    setWorkingInterval,
    setStudentWaves,
) => {
    const promises = [];
    const { adminRoles, roles, originalRoles } = userCardStore;
    const roleNames = roles.map(role => role.enabled && role.name);
    const hasNotRoles = JSON.stringify(
        roles.sort((el1, el2) => compare(el1, el2, 'name')),
    ) !== JSON.stringify(
        originalRoles.sort((el1, el2) => compare(el1, el2, 'name')),
    );
    if (hasNotRoles) {
        promises.push(
            setRoles({
                variables: {
                    setList: {
                        userId,
                        // eslint-disable-next-line max-len
                        roleTypes: roles.filter(role => role.enabled).map(role => role.name.toLowerCase()),
                    },
                },
            }).then(
                () => {
                    if (roleNames.includes('Admin') && adminRoles.length !== 0) {
                        promises.push(
                            setAdminRoles({
                                variables: {
                                    adminRoles: {
                                        userId,
                                        // eslint-disable-next-line max-len
                                        roles: adminRoles.map(role => ({ id: role.roleId, enabled: role.enabled })),
                                    },
                                },
                            }),
                        );
                    }
                },
            ),
        );
    } else if (roleNames.includes('Admin') && adminRoles.length !== 0) {
        promises.push(
            setAdminRoles({
                variables: {
                    adminRoles: {
                        userId,
                        // eslint-disable-next-line max-len
                        roles: adminRoles.map(role => ({ id: role.roleId, enabled: role.enabled })),
                    },
                },
            }),
        );
    }

    if (userCardStore.selectedWaveIds.length > 0 && studentId) {
        promises.push(setStudentWaves({
            variables: {
                updateStudentWaveInput: {
                    studentId,
                    newWaveIds: userCardStore.selectedWaveIds,
                },
            },
        }));
    }

    // if (roleNames.includes('Admin') && adminRoles.length !== 0) {
    //     promises.push(
    //         setAdminRoles({
    //             variables: {
    //                 adminRoles: {
    //                     userId,
    // eslint-disable-next-line max-len
    //                     roles: adminRoles.map(role => ({ id: role.roleId, enabled: role.enabled })),
    //                 },
    //             },
    //         }),
    //     );
    // }

    if (roleNames.includes('Teacher') && teacherId) {
        promises.push(
            setWorkingInterval({
                variables: {
                    updateWorkload: {
                        teacherId,
                        workingHours: dispatchedWorkingHours,
                        nonWorkingHours: userCardStore.nonWorkingHours,
                    },
                },
            }),
        );
        userCardStore.resetWorkingIntervals();
    }

    if (!roleNames.includes('Teacher') && teacherId) {
        promises.push(
            setWorkingInterval({
                variables: {
                    updateWorkload: {
                        teacherId,
                        workingHours: [],
                        nonWorkingHours: [],
                    },
                },
            }),
        );
        userCardStore.resetWorkingIntervals();
    }

    return Promise.all(promises);
};
