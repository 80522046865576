import React from 'react';

import smallXls from './smallXlsIcon.svg';
import bigXlsIcon from './bigXlsIcon.svg';

import classes from './XlsIcon.module.scss';

interface Props {
    text?: string;
    isSmallIcon?: boolean;
}

export function XlsIcon(
    {
        text,
        isSmallIcon,
    }: Props,
) {
    return (
        <div className={classes.icon}>
            <img
                src={
                    isSmallIcon ? smallXls : bigXlsIcon
                }
                alt="xls"
            />

            <div className={classes.icon__text}>
                {text}
            </div>
        </div>
    );
}
