import moment, { Moment } from 'moment';

import { TimeReservingEvent } from '../../../Interfaces';
import { DAYS_IN_WEEK, Weekday } from '../../../Store';

export interface Cell {
    id: number;
    date: Moment | null;
    dateEvents: TimeReservingEvent[];
}

interface MonthProperties {
    firstDay: Moment;
    weekdayOfFirstDay: number;
    numberOfDays: number;
}

const ROWS = 5;

export function getCells(
    selectedDate: Moment,
    timeReservingEvents: TimeReservingEvent[],
): Cell[] {
    const cells: Cell[] = [];
    const monthProperties: MonthProperties = getMonthProperties(selectedDate);

    const day: Moment = monthProperties.firstDay.clone();

    for (let cellId = 0; cellId < getRows(selectedDate) * DAYS_IN_WEEK; cellId += 1) {
        const cellDate = day.clone().add(cellId - monthProperties.weekdayOfFirstDay, 'days');

        cells.push({
            id: cellId,
            date: cellDate,
            dateEvents: timeReservingEvents.filter(
                (timeReservingEvent: TimeReservingEvent) => (
                    moment(timeReservingEvent.startDate).isSame(cellDate, 'day')
                ),
            ),
        });
    }

    return cells;
}

// (cellId >= monthProperties.weekdayOfFirstDay)
// && (cellId + 1
// <= monthProperties.numberOfDays + monthProperties.weekdayOfFirstDay)

function getRows(selectedDate: Moment): number {
    if (!isDaysFit(selectedDate)) {
        return ROWS + 1;
    }

    return ROWS;
}

function getMonthProperties(selectedDate: Moment): MonthProperties {
    const firstDay: Moment = selectedDate.clone().startOf('month');

    return {
        firstDay,
        weekdayOfFirstDay: firstDay.weekday(),
        numberOfDays: selectedDate.daysInMonth(),
    };
}

export function isDaysFit(selectedDate: Moment): boolean {
    const monthProperties: MonthProperties = getMonthProperties(selectedDate);
    return isDaysFitOnSaturday(monthProperties) && isDaysFitOnSunday(monthProperties);
}

function isDaysFitOnSaturday(monthProperties: MonthProperties): boolean {
    return !(
        monthProperties.numberOfDays === 31
        && monthProperties.weekdayOfFirstDay === Weekday.SATURDAY
    );
}

function isDaysFitOnSunday(monthProperties: MonthProperties): boolean {
    return !(
        monthProperties.numberOfDays >= 30
        && monthProperties.weekdayOfFirstDay === Weekday.SUNDAY
    );
}
