import React from 'react';
import cn from 'classnames';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import classes from './Legend.module.scss';

export const Legend = (): JSX.Element => (
    <div className={classes.legend}>
        <div className={classes.legend__firstColumnCell}>
            <div className={classes.legend__item}>
                <div
                    className={
                        cn(classes.legend_color, classes.legend_color_red)
                    }
                />
                <div className={classes.legend__description}>
                    - запланированная нерабочая занятость
                </div>
            </div>
            <div className={classes.legend__item}>
                <div
                    className={
                        cn(classes.legend_color, classes.legend_color_orange)
                    }
                />
                <div className={classes.legend__description}>
                    - запрещено календарем преподавателя
                </div>
            </div>
            <div className={classes.legend__item}>
                <div
                    className={
                        cn(classes.legend_color, classes.legend_striped)
                    }
                />
                <div className={classes.legend__description}>
                    - встреча Т-университета
                </div>
            </div>
        </div>
        <div className={classes.legend__secondColumnCell}>
            <div className={classes.legend__item}>
                <div
                    className={
                        cn(classes.legend_color, classes.legend_color_gray)
                    }
                />
                <div className={classes.legend__description}>
                    - бронирование классического университета
                </div>
            </div>
            <div className={classes.legend__item}>
                <div
                    className={
                        cn(classes.legend_color, classes.legend_color_white)
                    }
                />
                <div className={classes.legend__description}>
                    - свободное время для Т-университета
                </div>
            </div>
            <div className={classes.legend__item}>
                <div
                    className={
                        cn(classes.legend_color, classes.legend_dotted)
                    }
                />
                <div className={classes.legend__description}>
                    - бронирование Т-университета
                </div>
            </div>
        </div>
        <div className={classes.legend__rowCell}>
            <div className={classes.legend__columnName} style={{ width: 149 }}>
                Модули
            </div>
            <div className={classes.legend__columnName} style={{ width: 180 }}>
                Роли
            </div>
            <div className={classes.legend__columnName} style={{ width: 251 }}>
                Преподаватели
            </div>
        </div>
    </div>
);
