import React, { useLayoutEffect } from 'react';
import CSS from 'csstype';
import {
    SpaceBaseTimeInterval,
    BaseTimeIntervalType,
    SubspacesTypesRectangles,
} from '../interfaces';

interface Props {
    spaceBaseTimeIntervals: SpaceBaseTimeInterval[];
    baseTimeIntervalType: BaseTimeIntervalType;
    subspacesTypesRects: SubspacesTypesRectangles;
    setDiagramSize: any;
}

export function DiagramBackground({
    spaceBaseTimeIntervals,
    baseTimeIntervalType,
    subspacesTypesRects,
    setDiagramSize,
}: Props): JSX.Element {
    const hasSubspacesTypesRects = !!Object.keys(subspacesTypesRects)?.length;
    let btiListStyles: CSS.Properties = {};
    let contentIntervals: JSX.Element[] = [];

    useLayoutEffect(() => {
        if (!hasSubspacesTypesRects) {
            return;
        }

        setDiagramSize({
            width: getMaxBtiListWidth(),
            height: getMaxBtiListHeight(),
        });
    }, [subspacesTypesRects]);

    function getSortedIntervals(intervals: SpaceBaseTimeInterval[]): SpaceBaseTimeInterval[] {
        return intervals.map(a => ({ ...a })).sort((a, b) => a.order - b.order);
    }

    function getContentIntervals(): JSX.Element[] {
        const sortedIntervals = getSortedIntervals(spaceBaseTimeIntervals);

        return sortedIntervals.map((interval: SpaceBaseTimeInterval) => {
            const btiTitle = `${interval.order} ${baseTimeIntervalType.name}`;

            return (
                <li className="bti_item" key={interval.id}>
                    <div className="bti_title">{btiTitle}</div>
                    <div className="bti_divider" />
                    <div className="bti_title">{btiTitle}</div>
                </li>
            );
        });
    }

    function getMaxBtiListHeight() {
        const typeIds = Object.keys(subspacesTypesRects);
        let maxBtiListHeight = subspacesTypesRects[typeIds[0]].getBottomRightY();

        for (let i = 1; i < typeIds.length; i += 1) {
            const curBtiListHeight = subspacesTypesRects[typeIds[i]].getBottomRightY();

            if (maxBtiListHeight < curBtiListHeight) {
                maxBtiListHeight = curBtiListHeight;
            }
        }

        return maxBtiListHeight + 38;
    }

    function getMaxBtiListWidth() {
        return 180 * spaceBaseTimeIntervals.length;
    }

    function getBtiListStyles(): CSS.Properties {
        return { height: `${getMaxBtiListHeight()}px` };
    }

    if (hasSubspacesTypesRects) {
        btiListStyles = getBtiListStyles();
        contentIntervals = getContentIntervals();
    }

    return (
        <ul className="bti_list" style={btiListStyles}>
            {contentIntervals}
        </ul>
    );
}
