import { gql } from '@apollo/client';

export const GET_TEACHER_ASSIGNMENTS = gql`
    query filteredTeacherAssignmentInstances($assignmentInstancesInput: TeacherAssignmentInstancesInput!) {
        filteredTeacherAssignmentInstances(assignmentInstancesInput: $assignmentInstancesInput) {
            assignmentInstance {
                id
                status
                deadline
                start

                nextMeetingInstance {
                    meeting {
                        topic
                    }
                }

                wave {
                    id
                    index
                }

                teacherAssignmentInstances {
                    note
                    isCurrentTeacher

                    teacher {
                        user {
                            lastName
                            firstName
                            patronymic
                            status
                        }
                    }
                }

                assignment {
                    id
                    topic
                    description
                    duration {
                        name
                    }

                    module {
                        id
                        name
                    }
                }
            }

            note
            isCurrentTeacher
        }
    }
`;

export const GET_TEACHER = gql`
    query GetTeacher($id: String!) {
        teacher(id: $id) {
            baseTimeIntervalInstancesModules {
                baseTimeIntervalInstance {
                    id

                    spaceEducationPeriod {
                        id
                        name
                    }

                    spaceBaseTimeInterval {
                        id
                        order

                        space {
                            id
                            name

                            baseTimeIntervalType {
                                id
                                name
                            }
                        }
                    }
                }

                modules {
                    id
                    name

                    assignments {
                        id
                        topic
                        description
                        duration {
                            name
                        }
                    }

                    waves {
                        id
                        index
                        baseTimeIntervalInstances {
                            id
                        }
                    }
                }
            }
        }
    }
`;
