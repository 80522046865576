import React, { useState } from 'react';
import { BorderCrossIcon } from '@common/svg';
import { ActionButton } from '@common/ActionButton';

import classes from './FormatsCardDialog.module.scss';

interface Format {
    id: string;
    name: string;
}

interface Props {
    item: Format;
    dialog: boolean;
    toggleDialog: (flag: boolean) => void;
    updateMeetingFormat: (id: string, name: string) => void;
}

export function FormatsCardDialog({
    item,
    dialog,
    toggleDialog,
    updateMeetingFormat,
}: Props): JSX.Element {
    const [name, setName] = useState(item.name);
    const changeName = (e: any) => {
        e.preventDefault();
        setName(e.currentTarget.value.trim());
    };

    const updateMeeting = () => {
        updateMeetingFormat(item.id, name);
    };

    return (
        <div className={classes.formatsCardDialog} onClick={() => toggleDialog(!dialog)}>
            <div
                className={classes.formatsCardDialog__container}
                onClick={e => e.stopPropagation()}
            >
                <div className={classes.formatsCardDialog__content}>
                    <div className={classes.formatsCardDialog__title}>
                        Редактирование формата встреч
                    </div>
                    <div className={classes.formatsCardDialog__inputContainer}>
                        <input
                            type="text"
                            className={classes.formatsCardDialog__input}
                            defaultValue={name}
                            onChange={changeName}
                        />
                    </div>
                    <div className={classes.formatsCardDialog__button} onClick={updateMeeting}>
                        <ActionButton>
                            Изменить
                        </ActionButton>
                    </div>
                    <BorderCrossIcon
                        size={30}
                        className={classes.formatsCardDialog__icon}
                        handler={() => toggleDialog(!dialog)}
                    />
                </div>
            </div>
        </div>
    );
}
