import React from 'react';

import { Input, InputTypes } from '@common/Input';
import { BorderPlusIcon } from '@common/svg';

import { ListItems } from './ListItems';
import { RoomCreationEquipment } from '../../RoomTypes';
import classes from './AddItems.module.scss';

interface Props {
    equipmentList: RoomCreationEquipment[];
    item: RoomCreationEquipment;
    removeItems(id: string): void;
    handleChangeEquip({ currentTarget }: React.ChangeEvent<HTMLInputElement>): void
    handleChangeCount({ currentTarget }: React.ChangeEvent<HTMLInputElement>): void
    addItemsInRoom(): void
    showListItems(): void
}

export function AddItems({
    item,
    equipmentList,
    removeItems,
    handleChangeCount,
    handleChangeEquip,
    addItemsInRoom,
    showListItems,
}: Props) {
    const showItems = showListItems();
    const count = item.count === 0 ? '' : item.count;
    return (
        <div className={classes.equipmentList}>
            <h3 className={classes.equipmentList_title}>Наличие оборудования:</h3>
            <div className={classes.equipmentList_container}>
                <div className={classes.equipmentList_wrapper}>
                    <Input
                        onChange={handleChangeEquip}
                        placeholder="Введите наименование"
                        value={item.name}
                    />
                </div>
                <Input
                    type={InputTypes.Number}
                    onChange={handleChangeCount}
                    placeholder="Кол-во"
                    value={count}
                    min={1}
                />
                <BorderPlusIcon handler={addItemsInRoom} />
            </div>
            {showItems}
            {
                equipmentList.length > 0
                && (
                    <ListItems
                        equipments={equipmentList}
                        removeItems={removeItems}
                    />
                )
            }
        </div>
    );
}
