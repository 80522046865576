import React, { useState } from 'react';

import cn from 'classnames';

import { Polygon } from './Polygon';

import classes from './Accordion.module.scss';

interface AccordionProps {
    children: JSX.Element[],
    isOpen?: boolean,
    isDefaultOpen?: boolean,
    shouldClickOnHeaderOpen?: boolean,
    isHighlightedPolygon?: boolean,
    headerClassnames?: string,
    disabled?: boolean,
    additionalSetState?: () => void,
}

export function Accordion(props: AccordionProps) {
    const {
        children,
        isOpen,
        isDefaultOpen = false,
        shouldClickOnHeaderOpen,
        isHighlightedPolygon,
        disabled = false,
        headerClassnames,
        additionalSetState,
    } = props;
    const [isOpenLocal, setIsOpenLocal] = useState(isDefaultOpen);
    const [header, ...body] = React.Children.toArray(children);

    function toggle() {
        setIsOpenLocal(oldIsOpenLocal => !oldIsOpenLocal);
    }

    function getHeaderOnclick() {
        return 'shouldClickOnHeaderOpen' in props && shouldClickOnHeaderOpen === true ? toggle() : undefined;
    }

    function getPolygonOnclick() {
        return 'isOpen' in props ? undefined : toggle();
    }

    function isAccordionOpen() {
        if ('isOpen' in props) {
            return isOpen;
        }
        return isOpenLocal;
    }

    return (
        <div className={classes.accordion}>
            <div
                className={cn(headerClassnames, {
                    [classes.accordion__header]: !headerClassnames,
                })}
                onClick={() => {
                    if (!disabled && shouldClickOnHeaderOpen) {
                        getHeaderOnclick();
                        if (additionalSetState) {
                            additionalSetState();
                        }
                    }
                }}
                style={{
                    cursor: shouldClickOnHeaderOpen ? 'pointer' : 'default',
                }}
            >
                <button
                    type="button"
                    onClick={() => {
                        if (!shouldClickOnHeaderOpen) {
                            getPolygonOnclick();
                            if (additionalSetState) {
                                additionalSetState();
                            }
                        }
                    }}
                    className={
                        cn(
                            classes.accordion__polygon,
                            {
                                [classes.accordion__polygon_open]: isAccordionOpen(),
                                [classes.accordion__polygon_highlight]: isHighlightedPolygon,
                            },
                        )
                    }
                >
                    <Polygon />
                </button>
                {header}
            </div>
            <div
                className={
                    cn(
                        classes.accordion__body,
                        {
                            [classes.accordion__body_close]: !isAccordionOpen(),
                        },
                    )
                }
            >
                {body}
            </div>
        </div>
    );
}
