import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { observer } from 'mobx-react';
import moment from 'moment';
import { ScheduleHeader } from './ScheduleHeader';
import { ScheduleTable } from './ScheduleTable';
import { ScheduleEventBox } from './ScheduleEventBox';
import { SchedulePageMobile } from './SchedulePageMobile';
import { eventsStore, timeStore, UnitOfTime } from './Store';

import classes from './SchedulePage.module.scss';
import useCheckMobileScreen from './Hooks/useCheckMobileScreen';

interface Params {
    selectedEventId: string;
    unitOfTime: UnitOfTime;
    selectedDate: string;
}

const WINDOW_BREAKPOINT = 1200;

export const SchedulePage = observer((): JSX.Element => {
    const history = useHistory();
    const { selectedEventId, unitOfTime, selectedDate } = useParams<Params>();
    const windowSize = useCheckMobileScreen(WINDOW_BREAKPOINT);

    const {
        evaluationPointInstances,
        meetingInstances,
        assignmentInstances,
        getSelectedEventId,
        unselectEvent,
        selectEventById,
    } = eventsStore;

    const eventId = getSelectedEventId();

    useEffect(() => {
        selectEventById(selectedEventId);
    }, [
        meetingInstances,
        evaluationPointInstances,
        assignmentInstances]);

    useEffect(() => {
        if (!selectedEventId) {
            unselectEvent();
        }
        eventsStore.showAssignmentsList(false);

        timeStore.setSelectedEventDateIfSelected();
    }, [selectedEventId]);

    useEffect(() => {
        history.push(`/schedule/${timeStore.unitOfTime}/${moment(timeStore.selectedDate).format('MM-DD-YYYY')}/${eventId ?? selectedEventId ?? ''}`);
    }, [timeStore.selectedDate, timeStore.unitOfTime]);

    useEffect(() => {
        if (!unitOfTime) {
            timeStore.unitOfTime = UnitOfTime.DAY;
        }
        if (selectedDate) {
            timeStore.setSelectedDate(moment(selectedDate, 'MM-DD-YYYY'));
        }
    }, []);
    return (
        <>
            {
                !windowSize
                    ? (
                        <div className={classes.schedulePage}>
                            <ScheduleHeader />
                            <div className={classes.schedulePage__mainBlock}>
                                <ScheduleTable />
                                <ScheduleEventBox />
                            </div>
                        </div>
                    )
                    : <SchedulePageMobile />
            }
        </>
    );
});
