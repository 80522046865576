import React, { useState } from 'react';
import { NavLink, useRouteMatch } from 'react-router-dom';
import cn from 'classnames';
import { Dialog } from '@common/Dialog';
import { EditIcon, NoteSheetDisabledIcon, NoteSheetIcon, PaintingDisabledIcon, PaintingIcon } from '@common/svg';
import { Switcher } from '@common/Switcher';
import { Space, SpaceSubspaceDiagram } from '@common/SpaceSubspaceDiagram';
// import range from 'lodash/range';
// import { fill } from 'lodash';
import { EducationSpaceInfoColumns } from './EducationSpaceInfoColumns';
import { EducationSpaceDialog } from './EducationSpaceDialog';
import classes from './EducationSpaceInfo.module.scss';

interface Props {
    space: Space;
    hasSubspaces: boolean;

    setIsEdit(isEdit: string): void;
}

export function EducationSpaceInfo({ space, hasSubspaces, setIsEdit }: Props): JSX.Element {
    const [dialog, setDialog] = useState(false);
    // const callbacksArray = fill(
    //     range(space.spaceBaseTimeIntervals.length), () => setDialog(!dialog),
    // );
    if (!space) {
        return (
            <div>
                Данные не загружены
            </div>
        );
    }

    const { path } = useRouteMatch();

    const [infoMode, setInfoMode] = useState(false);

    const {
        name,
        minCreditCount,
        maxCreditCount,
        spaceBaseTimeIntervals,
    } = { ...space };

    const connectionsLinkClass = cn(
        classes.spaceInfo__subspaceConnections,
        { [classes.subspaceConnections_disable]: !hasSubspaces },
    );

    return (
        <div className={classes.spaceInfo}>
            <div className={classes.spaceInfo__header}>
                <div className={classes.spaceInfo__spaceTitle}>
                    <p className={classes.spaceInfo__spaceName}>{name}</p>
                    <div style={{ display: 'flex' }}>
                        <div>\</div>
                        <NavLink
                            to={`${path}/connections`}
                            exact
                            className={connectionsLinkClass}
                        >
                            Связи подпространств
                        </NavLink>
                    </div>
                </div>

                <button type="button" className={classes.spaceInfo__editButton}>
                    <EditIcon
                        size={33}
                        handler={() => setIsEdit(path)}
                    />
                </button>
            </div>

            <div className={classes.spaceInfo__spacecharacteristics}>
                <p>Общее количество ЗЕТ пространства: {minCreditCount}-{maxCreditCount}</p>
                <p>Продолжительность обучения: {spaceBaseTimeIntervals.length} семестров</p>
            </div>

            <div className={classes.spaceInfo__switcher}>
                <div className={classes.spaceInfo__switcherDiagramIcon_marginRight}>
                    {!infoMode ? <NoteSheetIcon /> : <NoteSheetDisabledIcon />}
                </div>

                <Switcher
                    checked={infoMode}
                    onClick={() => setInfoMode((prevValue) => !prevValue)}
                />

                <div className={classes.spaceInfo__switcherDiagramIcon_marginLeft}>
                    {infoMode ? <PaintingIcon /> : <PaintingDisabledIcon />}
                </div>
            </div>

            <div className={classes.spaceInfo__info}>
                {infoMode
                    ? (
                        <SpaceSubspaceDiagram
                            space={space}
                        // callBacks={callbacksArray}
                        />
                    )
                    : <EducationSpaceInfoColumns space={space} />}
            </div>

            {
                dialog && (
                    <Dialog
                        id="education-space-dialog"
                        dom={(
                            <EducationSpaceDialog
                                closeDialog={() => setDialog(!dialog)}
                            />
                        )}
                    />
                )
            }
        </div>
    );
}
