import React from 'react';
import { Module } from '../ModuleCard';

import classes from './SelectedModule.module.scss';

import {
    Module as ModuleType,
    UpdateModuleInput,
    UpdateSlotDiagramInput,
} from '../subSpaceTypes';
import { skills_skills, skillTypesData_skillTypes } from '../../deprecated-graphql-query-types';

interface Props {
    subspaceId: string
    slotId: string
    skillTypes: skillTypesData_skillTypes[];
    allSkillsList: skills_skills[];
    selectedModule: ModuleType;
    updateSlotModule: (item: UpdateModuleInput) => void;
    setModuleState: (flag: boolean) => void;
    updateSlotDiagram(moduleSlotInput: UpdateSlotDiagramInput): void
}

export function SelectedModule({
    subspaceId,
    slotId,
    selectedModule,
    skillTypes,
    allSkillsList,
    updateSlotModule,
    setModuleState,
    updateSlotDiagram,
}: Props):JSX.Element {
    return (
        <div className={classes.selectedModule}>
            <Module
                subspaceId={subspaceId}
                slotId={slotId}
                currentModule={selectedModule}
                skillTypes={skillTypes}
                allSkillsList={allSkillsList}
                selectedModule={selectedModule}
                updateSlotModule={updateSlotModule}
                setModuleState={setModuleState}
                updateSlotDiagram={updateSlotDiagram}
            />
        </div>
    );
}
