import React, { Fragment } from 'react';

import classes from './FormatsCard.module.scss';

import { FormatsCardItem } from './FormatsCardItem';

interface Format {
    id: string;
    name: string;
}

interface Props {
    cardFormats: any;
    editState: boolean;
    updateMeetingFormat: (id: string, name: string) => void;
    deleteMeetingFormat: (id: string) => void;
}

export function FormatsCard({
    cardFormats,
    editState,
    updateMeetingFormat,
    deleteMeetingFormat,
}: Props): JSX.Element {
    const sortedItems = cardFormats[1].sort((a: any, b: any) => (a.name > b.name ? 1 : -1));
    return (
        <div className={classes.formatsCard}>
            <div className={classes.formatsCard__content}>
                <div className={classes.formatsCard__letter}>
                    {cardFormats[0]}
                </div>
                <ul className={classes.formatsCard__list}>
                    {
                        sortedItems.map((item: Format) => (
                            <Fragment key={item.id}>
                                <FormatsCardItem
                                    item={item}
                                    editState={editState}
                                    updateMeetingFormat={updateMeetingFormat}
                                    deleteMeetingFormat={deleteMeetingFormat}
                                />
                            </Fragment>
                        ))
                    }
                </ul>
            </div>
        </div>
    );
}
