import React from 'react';

import { DayScheduleWhithWeekType } from '@admin/Users/store/userCardStore';
import { PreviewTimeIntervals } from './PreviewTimeIntervals';

import classes from './workingHours.module.scss';

interface Props {
    workingIntervals: DayScheduleWhithWeekType;
    isEveryWeek: boolean;
}
export const PreviewWorkingHours = ({ workingIntervals, isEveryWeek }: Props) => (
    <div className={classes['prewiew_box-interval']}>
        <h3 className={classes.workingHours__title}>
            Рабочая занятость:
        </h3>

        <div className={classes.workingHours__wrapper}>
            <PreviewTimeIntervals
                intervals={isEveryWeek ? workingIntervals.every!
                    : workingIntervals.even!}
                title={isEveryWeek ? 'Неделя' : 'Четная неделя'}
            />
            {!isEveryWeek && (
                <PreviewTimeIntervals
                    intervals={workingIntervals.odd!}
                    title="Нечетная неделя"
                />
            )}
        </div>
    </div>
);
