/* eslint-disable no-param-reassign */
import React, { useState, useEffect } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { Redirect, useParams } from 'react-router-dom';
import { nanoid } from 'nanoid';
import { cloneDeep, isEmpty, omit } from 'lodash';

import { Loader } from '@common/Loader';
import { Alert } from '@common/Alert';

import {
    ExternalModuleStatus,
    getExternalModule,
    getExternalModuleVariables,
    isInviteLinkValid,
    isInviteLinkValidVariables,
    saveExternalModule,
    saveExternalModuleVariables,
} from './apollo-types';
import classes from './ExternalModule.module.scss';
import { ExternalModuleState, removeNulls, ValidationErrors } from './helpers';
import { GET_EXTERNAL_MODULE, IS_INVITE_LINK_VALID, SAVE_EXTERNAL_MODULE } from './gql';
import { ExternalModule } from './ExternalModule';

interface RouteParams {
    invitationId: string;
    externalModuleId: string;
}

type Status = ExternalModuleStatus.draft
| ExternalModuleStatus.verification
| ExternalModuleStatus.revision;

const RequiredFieldsMap = {
    [ExternalModuleStatus.revision]: ['ownerFullName', 'ownerEmail', 'name'],
    [ExternalModuleStatus.draft]: ['ownerFullName', 'ownerEmail', 'name'],
    [ExternalModuleStatus.verification]: [
        'ownerFullName', 'ownerEmail', 'name', 'description', 'minStudentCount', 'maxStudentCount', 'maxWaveCount', 'creditCount',
    ],
};

export function ExternalModuleApollo() {
    const { invitationId, externalModuleId } = useParams<RouteParams>();

    const {
        data: isInviteLinkValidData,
        loading: isInviteLinkValidLoading,
        error: isInviteLinkValidError,
    } = useQuery<isInviteLinkValid, isInviteLinkValidVariables>(
        IS_INVITE_LINK_VALID,
        {
            skip: typeof externalModuleId !== 'undefined',
            variables: { invitationId },
            fetchPolicy: 'network-only',
        },
    );

    const {
        data: getExternalModuleData,
        loading: getExternalModuleLoading,
        error: getExternalModuleError,
    } = useQuery<getExternalModule, getExternalModuleVariables>(
        GET_EXTERNAL_MODULE,
        {
            skip: typeof externalModuleId === 'undefined',
            variables: { externalModuleInput: { invitationId, externalModuleId } },
            fetchPolicy: 'no-cache',
        },
    );

    const [save, {
        loading: loadingOnSave,
        data: saveExternalModuleResponse,
        error: onSaveError,
    }] = useMutation<saveExternalModule, saveExternalModuleVariables>(SAVE_EXTERNAL_MODULE);

    const [newModuleId] = useState<string>(nanoid());
    const [externalModule, setExternalModule] = useState<ExternalModuleState>({
        invitationId,
        id: externalModuleId || newModuleId,
    });
    const [alerts, setAlerts] = useState<JSX.Element[]>([]);
    const [validationErrors, setValidationErrors] = useState<ValidationErrors>();

    useEffect(() => {
        if (onSaveError) {
            let message: string;
            const [graphQLError] = onSaveError.graphQLErrors;
            if (graphQLError?.extensions?.validationErrors) {
                message = Object.values(graphQLError.extensions.validationErrors)
                    .map(validationErrorMessage => validationErrorMessage).join('. ');
            } else {
                message = onSaveError.graphQLErrors[0]?.message ?? onSaveError.message;
            }
            addAlertMessage(`Произошла ошибка. Не удалось сохранить модуль. ${message}`);
        }
    }, [onSaveError]);

    useEffect(() => {
        if (getExternalModuleData?.externalModule) {
            setExternalModule({
                ...removeNulls(omit(getExternalModuleData?.externalModule, 'reviews')),
                invitationId,
            });
        }
    }, [getExternalModuleData]);

    useEffect(() => {
        if (saveExternalModuleResponse?.saveExternalModule) {
            setExternalModule({
                ...removeNulls(omit(saveExternalModuleResponse?.saveExternalModule, 'reviews')),
                invitationId,
            });
        }
    }, [saveExternalModuleResponse]);

    const error = isInviteLinkValidError || getExternalModuleError;
    if (error) {
        return (
            <div>Произошла ошибка:{' '}
                {error.graphQLErrors[0]?.message || error.message}
            </div>
        );
    }

    if (isInviteLinkValidLoading || getExternalModuleLoading) {
        return <Loader />;
    }

    if (isInviteLinkValidData?.isValidExternalModuleInvitation === false) {
        return (
            <div>Ссылка-приглашение не валидна</div>
        );
    }

    if (!externalModuleId && saveExternalModuleResponse?.saveExternalModule) {
        const { id } = saveExternalModuleResponse.saveExternalModule;
        return (
            <Redirect to={`/invitation/${invitationId}/module/${id}`} />
        );
    }

    function onExternalModuleChange(updatedModuleFields: Partial<ExternalModuleState>) {
        if (validationErrors) {
            const validationErrorsCopy = cloneDeep(validationErrors);
            Object.keys(updatedModuleFields).forEach((key) => {
                validationErrorsCopy[key as keyof ExternalModuleState] = undefined;
            });
            setValidationErrors(validationErrorsCopy);
        }
        setExternalModule(prevState => ({
            ...prevState,
            ...updatedModuleFields,
        }));
    }

    function onSaveButtonClick(status: Status) {
        const errors = RequiredFieldsMap[status].reduce((err, key) => {
            if (!externalModule[key as keyof ExternalModuleState]) {
                err[key as keyof ExternalModuleState] = 'Поле обязательно для заполнения';
            }
            return err;
        }, {} as ValidationErrors);

        if (!isEmpty(errors)) {
            setValidationErrors(errors);
        } else {
            setValidationErrors(undefined);
            save({
                variables: {
                    saveExternalModuleInput: {
                        ...externalModule,
                        status,
                        ownerFullName: externalModule.ownerFullName!.trim(),
                        ownerEmail: externalModule.ownerEmail!.trim(),
                        name: externalModule.name!.trim(),
                        link: `${window.location.origin}/invitation/${externalModule.invitationId}/module/${externalModule.id}`,
                    },
                },
            });
        }
    }

    function addAlertMessage(message: string) {
        setAlerts((arr) => [
            ...arr,
            <Alert key={nanoid()} message={message} time={5000} />,
        ]);
    }

    return (
        <div className={classes['external-module']}>
            <ExternalModule
                externalModule={externalModule}
                onSaveButtonClick={onSaveButtonClick}
                onExternalModuleChange={onExternalModuleChange}
                validationErrors={validationErrors}
                loadingOnSave={loadingOnSave}
            />
            {alerts}
        </div>
    );
}
