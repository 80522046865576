import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';

import { baseTimeIntervalInstances, baseTimeIntervalInstances_baseTimeIntervalInstances } from '@admin/Users/ToolBar/Tools/apollo-types';
import { Calendar } from '@admin/Users/Calendar';
import { SelectSearchForm } from './SelectSearchForm';
import { spaces } from '../../apollo-types';
import { formatBTIName, SearchParams } from '../../util';
import { GET_BASE_TIME_INTERVAL_INSTANCES, GET_SPACES } from './queries';

export enum SearchType {
    input = 'input',
    select = 'select',
    selectSpace = 'selectSpace',
    baseTimeInterval = 'baseTimeInterval',
    calendar = 'calendar',
}

interface Props {
    searchType: SearchType;
    selectOptions?: {
        name: string;
        id: string;
        text?: string;
    }[];
    name?: string;
    setSearchParams?: React.Dispatch<React.SetStateAction<SearchParams>>;
}

export function SearchForm({
    searchType,
    selectOptions,
    name,
    setSearchParams,
}: Props) {
    const [inputValue, setInputValue] = useState('');
    const [dateRange, setDateRange] = useState([null, null]);
    const [startDate, endDate] = dateRange;
    const onChangeCalendar = (update: []) => setDateRange(update);

    const { data: btiData } = useQuery<baseTimeIntervalInstances>(
        GET_BASE_TIME_INTERVAL_INSTANCES,
        { skip: searchType !== SearchType.baseTimeInterval },
    );

    const { data: spacesData } = useQuery<spaces>(
        GET_SPACES,
        { skip: searchType !== SearchType.selectSpace },
    );

    useEffect(() => {
        if (searchType === SearchType.calendar) {
            if (startDate && endDate) {
                setSearchParams!(prev => ({
                    ...prev,
                    birthday: { startAt: startDate!, endAt: endDate! },
                }));
            } else if (dateRange.every(date => date === null)) {
                setSearchParams!(prev => ({
                    ...prev,
                    birthday: null,
                }));
            }
        }
    }, [dateRange]);

    if (searchType === SearchType.calendar) {
        return (
            <Calendar
                onChange={onChangeCalendar}
                startDate={startDate}
                endDate={endDate}
            />
        );
    }

    if (
        [SearchType.select,
            SearchType.selectSpace,
            SearchType.baseTimeInterval,
        ].includes(searchType)) {
        let options = selectOptions;
        if (!options && btiData) {
            options = formatBaseTimeIntervalOptions(btiData.baseTimeIntervalInstances);
        } else if (!options && spacesData) {
            options = spacesData.spaces;
        }
        return (
            <SelectSearchForm
                type={searchType}
                selectOptions={options ?? []}
                name={name}
                setSearchParams={setSearchParams}
            />
        );
    }
    return (
        <input
            name={name}
            value={inputValue}
            type="text"
            placeholder="Поиск"
            onChange={({ target }) => {
                setInputValue(target.value.replace(/[\\%+=&№$^*()!#"]/g, ''));
                return setSearchParams && setSearchParams(prev => ({
                    ...prev,
                    [target.name]: target.value.replace(/[\\%+=№$&^*()!#"]/g, ''),
                }));
            }}
        />
    );
}

function formatBaseTimeIntervalOptions(
    btis: baseTimeIntervalInstances_baseTimeIntervalInstances[],
) {
    return btis.map(bti => ({
        id: bti.id,
        name: formatBTIName(bti),
    }));
}
