import { makeAutoObservable } from 'mobx';

interface Colors {
    firstColor: string;
    secondColor: string;
    thirdColor: string;
}
class ColorConstants {
    firstColor: string = '#000';

    secondColor: string = '#000';

    thirdColor: string = '#000';

    firstDisableColor = '#BEBEBE';

    secondDisableColor = '#98A4A5';

    mockColor = '#AFD6F0';

    constructor() {
        makeAutoObservable(this);
    }

    public setColors({ firstColor, secondColor, thirdColor }: Colors) {
        this.firstColor = firstColor;
        this.secondColor = secondColor;
        this.thirdColor = thirdColor;
    }
}

export default new ColorConstants();
