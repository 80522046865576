import { makeAutoObservable } from 'mobx';
import * as _ from 'lodash';
import {
    DaySchedule,
    Space, SpaceBaseTimeIntervalParameters, StudentWorkload,
    UpdateBTIParametersData,
} from '@admin/EducationPeriodPage/EducationPeriodPageInterfaces';

class EducationPeriodStore {
    educationSpace: Space | null = null;

    updateBTIParametersData: UpdateBTIParametersData | null = null;

    updateStudentWorkLoad: StudentWorkload | null = null;

    updateDaysSchedule: DaySchedule[] = [];

    updateSpaceBaseTimeIntervalParameters: SpaceBaseTimeIntervalParameters[] = [];

    isUpdateError: boolean = false;

    constructor() {
        makeAutoObservable(this);
    }

    setEducationSpace = (space: Space) => {
        this.educationSpace = space;
    };

    setUpdateBTIParametersData = (newUpdateBTIData: UpdateBTIParametersData) => {
        this.updateBTIParametersData = newUpdateBTIData;
    };

    setUpdateStudentWorkLoad = (newStudentWorkLoad: StudentWorkload) => {
        this.updateStudentWorkLoad = newStudentWorkLoad;
    };

    setUpdateDaysSchedule = (newDaysSchedule: DaySchedule[]) => {
        this.updateDaysSchedule = newDaysSchedule;
    };

    setUpdateSpaceBaseTimeIntervalParameters = (
        newSpaceBaseTimeIntervalParameters: SpaceBaseTimeIntervalParameters,
    ) => {
        this.updateSpaceBaseTimeIntervalParameters.push(newSpaceBaseTimeIntervalParameters);
    };

    clearUpdateParameters = () => {
        this.updateBTIParametersData = null;
        this.updateDaysSchedule = [];
        this.updateSpaceBaseTimeIntervalParameters = [];
        this.updateSpaceBaseTimeIntervalParameters = [];
    };

    removeCurrentSpaceBaseTimeIntervalParameters = (
        removedParametersId: string,
    ) => {
        _.remove(
            this.updateSpaceBaseTimeIntervalParameters,
            (parameter) => parameter.spaceBaseTimeIntervalId === removedParametersId,
        );
    };

    setIsUpdateError = (
        isUpdateError: boolean,
    ) => {
        this.isUpdateError = isUpdateError;
    };
}

export const educationPeriodStore = new EducationPeriodStore();
