import { gql, useQuery } from '@apollo/client';
import {
    ScheduleGenerationProcess,
} from '@admin/ScheduleGenerationPage/store/BaseTimeIntervalInstanceModel';

const BASE_TIME_INTERVAL_INSTANCE = gql`
    query getBaseTimeIntervalInstance($id: String!) {
        baseTimeIntervalInstance(id: $id) {
            id
            startDate
            endDate
            scheduleGenerationProcesses {
                id
                name
                from
                to
                status
            }
        }
    }
`;

export interface BaseTimeIntervalInstance {
    id: string;
    startDate: string;
    endDate: string;
    scheduleGenerationProcesses: ScheduleGenerationProcess[];
}

interface QueryResponse {
    baseTimeIntervalInstance?: BaseTimeIntervalInstance;
}

export function useBaseTimeIntervalInstanceQuery(id: string): QueryResponse {
    const { data } = useQuery(BASE_TIME_INTERVAL_INSTANCE, {
        variables: { id },
    });

    return { baseTimeIntervalInstance: data?.baseTimeIntervalInstance };
}
