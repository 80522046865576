import React, { useState } from 'react';

import { EditIcon, FilledEditIcon } from '@common/svg';
import { SkillCard } from '../SkillCard';
import { checkChild } from '../SkillAddPage/SkillAddWithNest/SkillAddFunctions';

import classes from './SkillListPage.module.scss';
import {
    skillTypesData_skillTypes,
} from '../../../deprecated-graphql-query-types';

interface Skill {
    id: string;
    name: string;
    typeId: string;
    maxLevel?: number;
    description?: string;
    children?: Skill[];
    dependencies?: Skill[];
}

interface Props {
    skillType: skillTypesData_skillTypes;
    skillTypes: skillTypesData_skillTypes[];
    skillsList: Skill[];
    removeSkill(id: string): void;
    updateSkill: (
        id: string,
        name: string,
        typeId: string,
        description: string,
        maxLevel: number
    ) => void;
    updateNestSkill: (
        id: string,
        name: string,
        typeId: string,
        children: Skill[],
        dependencies: string[],
    ) => void;
}

export function SkillListPage({
    skillType,
    skillTypes,
    skillsList,
    removeSkill,
    updateSkill,
    updateNestSkill,
}: Props): JSX.Element {
    const [edit, toggleEdit] = useState(true);
    const { hasChild } = checkChild(skillTypes, skillType.id);

    function sortByLetters(arr: Skill[]) {
        return arr.slice().sort((a: Skill, b: Skill) => (a.name > b.name ? 1 : -1));
    }

    const skillsArrays = sortByLetters(skillsList);
    const newArray = skillsArrays.reduce((object: any, value: Skill) => {
        const item = { ...object };
        const key = value.name.slice(0, 1);
        if (item[key] == null) item[key] = [];

        item[key].push(value);
        return item;
    }, {});

    return (
        <div className={classes.skillList}>
            <div className={classes.skillList__title}>
                Список {(skillType.name.genitivePlural)?.toLowerCase()}:

                {
                    !hasChild && (
                        <div
                            className={classes.skillList__editIcon}
                            onClick={() => toggleEdit(!edit)}
                        >
                            {
                                !edit
                                    ? <FilledEditIcon size={33} />
                                    : <EditIcon size={33} />
                            }
                        </div>
                    )
                }
            </div>
            <div className={classes.skillList__container}>
                <div className={classes.skillList__cardsRoster}>
                    {
                        hasChild
                            ? (
                                Object.values(skillsArrays).map((skillData: any) => (
                                    <SkillCard
                                        key={skillData.id}
                                        skillId={skillData.id}
                                        currentSkill={skillData}
                                        skillType={skillType}
                                        skillTypes={skillTypes}
                                        skillsList={skillsList}
                                        editState={edit}
                                        removeSkill={removeSkill}
                                        updateSkill={updateSkill}
                                        updateNestSkill={updateNestSkill}
                                    />
                                ))
                            )
                            : (
                                Object.values(newArray).map((skillData: any) => (
                                    <SkillCard
                                        key={skillData[0].id}
                                        skillId={skillData[0].id}
                                        currentSkill={skillData}
                                        skillType={skillType}
                                        skillTypes={skillTypes}
                                        skillsList={skillsList}
                                        editState={edit}
                                        removeSkill={removeSkill}
                                        updateSkill={updateSkill}
                                        updateNestSkill={updateNestSkill}
                                    />
                                ))
                            )
                    }
                </div>
            </div>
        </div>
    );
}
