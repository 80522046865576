import React, { useState, useMemo } from 'react';
import { ActionButton, ActionTypeOfButton } from '@common/ActionButton';
import Modal from './modal';
import classes from './confirm-alert.module.scss';

interface Props {
    title: string
    message?: string
    hasConfirmMessage?: boolean
    confirmMessage?: string
    isOpen: boolean
    onAccept: (...args: any[]) => any
    onAcceptButtonText?: string
    onReject: (...args: any[]) => any
    onRejectButtonText?: string
    children?: React.ReactNode
}

const ConfirmAlert = ({
    title,
    message,
    hasConfirmMessage,
    confirmMessage = 'УДАЛИТЬ',
    isOpen,
    onAccept,
    onAcceptButtonText,
    onReject,
    onRejectButtonText,
    children,
}: Props): JSX.Element | null => {
    const [inputValue, setInputValue] = useState('');

    const canBeAccept = useMemo(() => {
        if (hasConfirmMessage) {
            return inputValue === confirmMessage;
        }

        return true;
    }, [inputValue]);

    function onConfirmReject() {
        if (hasConfirmMessage) {
            setInputValue('');
        }

        onReject();
    }

    return (
        <Modal isOpen={isOpen} onCancel={onConfirmReject}>
            <div className={classes.confirmContainer}>
                <h3 className={classes.confirmTitle}>
                    {title}
                </h3>

                {message && (
                    <p className={classes.simpleMessage}>
                        {message}
                    </p>
                )}

                {children}

                {hasConfirmMessage && (
                    <>
                        <p className={classes.confirmMessage}>
                            Введите {confirmMessage} для продолжения
                        </p>

                        <input
                            className={classes.confirmInput}
                            type="text"
                            placeholder="Введите текст"
                            onChange={e => setInputValue(e.target.value)}
                        />
                    </>
                )}

                <div>
                    <ActionButton
                        className={classes.rejectButton}
                        onClick={onConfirmReject}
                        actionType={ActionTypeOfButton.SECONDARY}
                    >
                        {onAcceptButtonText || 'Нет'}
                    </ActionButton>

                    <ActionButton
                        onClick={onAccept}
                        disabled={!canBeAccept}
                    >
                        {onRejectButtonText || 'Да'}
                    </ActionButton>
                </div>
            </div>
        </Modal>
    );
};

export default ConfirmAlert;
