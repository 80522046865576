import React, { useState } from 'react';

import { useMutation, useQuery } from '@apollo/client';
import { Alert } from '@common/Alert/Alert';
import { RadioList } from '@admin/Users/RadioList';
import { Select } from '@admin/Users/Select';
import { ToolBarButtonProps } from '@admin/Users/ToolBar/Tools/interface';
import { ToolButton } from '../../ToolButton';
import {
    baseTimeIntervalInstances,
} from '../apollo-types';
import { GET_BASE_TIME_INTERVAL_INSTANCES, IMPORT_STUDENTS_CSV } from '../gql';

export const ImportStudentsButton = (
    {
        refetchUserList,
    }: Omit<ToolBarButtonProps, 'selectedUsers'>,
) => {
    const { data: btiData } = useQuery<baseTimeIntervalInstances>(GET_BASE_TIME_INTERVAL_INSTANCES);
    const [fileData, setFileData] = useState<{
        file: string | ArrayBuffer | null;
    }>({ file: '' });

    const [spaceId, setSpaceId] = useState<string>();
    const [baseTimeIntervalInstanceId, setBaseTimeIntervalInstanceId] = useState<string>();

    const [updateStudentRating, { error }] = useMutation(IMPORT_STUDENTS_CSV, {
        onCompleted: () => { refetchUserList(); },
    });
    function onSpaceChange(event: React.ChangeEvent<HTMLInputElement>) {
        const { value } = event.target;
        setSpaceId(value);
        setBaseTimeIntervalInstanceId(undefined);
    }

    function onBaseTimeIntervalInstanceChange(event: React.ChangeEvent<HTMLInputElement>) {
        const { value } = event.target;
        setBaseTimeIntervalInstanceId(value);
    }

    function handlerLoad(event: React.ChangeEvent<HTMLInputElement>) {
        event.preventDefault();
        const reader = new FileReader();
        const file = event.target.files![0] ?? '';
        reader.onloadend = () => {
            setFileData({
                file: reader.result,
            });
        };
        return file && reader.readAsText(file);
    }

    const spaces = btiData?.baseTimeIntervalInstances
        .map(({ spaceEducationPeriodId, spaceBaseTimeInterval: { space } }) => ({
            id: spaceEducationPeriodId,
            name: space.name,
            value: spaceEducationPeriodId,
        }))
        .filter((space, index, self) => (
            index === self.findIndex(s => s.id === space.id)
        ));

    const baseTimeIntervals = btiData?.baseTimeIntervalInstances
        .filter(({ spaceEducationPeriodId }) => spaceEducationPeriodId === spaceId)
        .map(
            ({ id, spaceBaseTimeInterval: { order, space } }) => ({
                id,
                name: `${order} ${space.baseTimeIntervalType?.name}`,
            }),
        );

    const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        await updateStudentRating({
            variables: {
                importStudentsInput: {
                    usersCsvInput: fileData.file,
                    baseTimeIntervalInstanceId,
                },

            },
        });
    };

    return (
        <>
            <ToolButton
                text="Загрузить студентов"
                icon="export"
                isLoader
                contextMenu={baseTimeIntervals && (
                    <RadioList
                        listOptions={spaces!}
                        type="role"
                        checked={spaceId}
                        onChange={onSpaceChange}
                    >
                        <Select
                            options={baseTimeIntervals}
                            value={baseTimeIntervalInstanceId}
                            onChange={onBaseTimeIntervalInstanceChange}
                            placeholder="Сделайте выбор"
                        />
                    </RadioList>
                )}
                handlerLoad={handlerLoad}
                onSubmit={onSubmit}
            />
            {error && <Alert message={error.message} time={9000} />}
        </>
    );
};
