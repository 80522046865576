import React from 'react';
import cn from 'classnames';
import { orderBy } from 'lodash';

import { MultiAutosuggest } from '@common/MultiAutosuggest';
import { TabsOption } from '@common/TabsRedesign/TabsRedesign';
import { HeaderRedesign } from '@common/HeaderRedesign';
import { useHistory } from 'react-router-dom';
import {
    GetModuleFilters_module,
    GetModuleFilters_moduleWavesByBaseTimeIntervalInstanceId,
    GetModuleFilters_moduleWavesByBaseTimeIntervalInstanceId_students,
} from './graphql-types';
import { Switcher } from './Switcher';
import { EvaluationPointOption, StudentOption, WaveOption } from './ModuleStatisticsTypes';
import { SkillTypeSelect } from './SkillTypeSelect';
import { ModuleStatisticsTable } from './ModuleStatisticsTable';
import arrowBack from './arrow.svg';

import classes from './ModuleStatistics.module.scss';

interface Props {
    isDetailedModeOn: boolean;
    isDetailedModeEnabled: boolean;
    baseTimeIntervalInstanceId: string;
    spaceId: string;
    skillTypeId: string;
    module: GetModuleFilters_module;
    waves: GetModuleFilters_moduleWavesByBaseTimeIntervalInstanceId[];
    students: GetModuleFilters_moduleWavesByBaseTimeIntervalInstanceId_students[];
    selectedEvaluationPoints: EvaluationPointOption[];
    selectedWaves: WaveOption[];
    selectedStudents: StudentOption[];
    onDetailedModeActivityToggle(): void;
    handleGoBack(): void;
    onEvaluationPointChange(options: EvaluationPointOption[]): void;
    onWaveChange(options: WaveOption[]): void;
    onStudentChange(options: StudentOption[]): void;
}

export function ModuleStatistics({
    isDetailedModeOn,
    isDetailedModeEnabled,
    baseTimeIntervalInstanceId,
    spaceId,
    skillTypeId,
    module,
    waves,
    students,
    selectedEvaluationPoints,
    selectedWaves,
    selectedStudents,
    onDetailedModeActivityToggle,
    handleGoBack,
    onEvaluationPointChange,
    onWaveChange,
    onStudentChange,
}: Props) {
    const { id: moduleId, name, evaluationPoints: unOrderedEvaluationPoints } = module;
    const evaluationPoints = orderBy(unOrderedEvaluationPoints, 'order');
    const moduleLink = `/module/public/${moduleId}`;

    return (
        <div className={classes['module-statistics__container']}>
            <ModuleStatisticsHeader
                moduleId={moduleId}
                moduleName={name ?? ''}
                moduleLink={moduleLink}
                btiIID={baseTimeIntervalInstanceId}
                spaceId={spaceId}
                skillTypeId={skillTypeId}
                handleGoBack={handleGoBack}
            />
            <div className={classes['module-statistics__filters']}>
                <SkillTypeSelect />
                <div className={cn(classes['module-statistics__mode-switcher'],
                    {
                        [classes['module-statistics__mode-switcher_disabled']]: !isDetailedModeEnabled,
                    })}
                >
                    <Switcher
                        onClick={onDetailedModeActivityToggle}
                        checked={isDetailedModeOn}
                        label="Детализация по образовательным результатам"
                    />
                </div>
            </div>
            <div className={classes['module-statistics__filters']}>
                <div className={classes['module-statistics__filter']}>
                    <span className={classes['module-statistics__filter-label']}>
                        Точки оценки
                    </span>
                    <MultiAutosuggest
                        placeholder="Выберите точку оценки"
                        onChange={onEvaluationPointChange}
                        options={evaluationPoints}
                        selectedOptions={selectedEvaluationPoints}
                        formatOption={formatOption}
                        isSuggestableOnFocus
                    />
                </div>
                <div className={classes['module-statistics__filter']}>
                    <span className={classes['module-statistics__filter-label']}>
                        Потоки
                    </span>
                    <MultiAutosuggest
                        placeholder="Выберите поток"
                        onChange={onWaveChange}
                        options={waves}
                        selectedOptions={selectedWaves}
                        formatOption={formatWaveOption}
                        isSuggestableOnFocus
                    />
                </div>
                <div className={classes['module-statistics__filter']}>
                    <span className={classes['module-statistics__filter-label']}>
                        ФИО студента
                    </span>
                    <MultiAutosuggest
                        placeholder="Укажите Ф.И.О."
                        onChange={onStudentChange}
                        options={students}
                        selectedOptions={selectedStudents}
                        formatOption={formatStudentOption}
                        isSuggestableOnFocus
                    />
                </div>
            </div>
            <ModuleStatisticsTable
                isDetailedModeOn={isDetailedModeOn}
                moduleLink={moduleLink}
            />
        </div>
    );
}

function formatOption(option: EvaluationPointOption) {
    return option.topic!;
}

function formatWaveOption(option: WaveOption) {
    return `${option.index! + 1} поток`;
}

function formatStudentOption({ user }: StudentOption) {
    return `${user.lastName} ${user.firstName} ${user.patronymic}`;
}

interface ModuleStatisticTabsProps {
    btiIID: string;
    moduleId: string;
    moduleName: string;
    moduleLink: string;
    spaceId: string;
    skillTypeId: string;
    handleGoBack(): void;
}

function ModuleStatisticsHeader(
    {
        btiIID,
        moduleId,
        moduleName,
        moduleLink,
        spaceId,
        skillTypeId,
        handleGoBack,
    }: ModuleStatisticTabsProps,
) {
    const attendanceNavLink = `/attendance/?btiIId=${btiIID}&&moduleId=${moduleId}&&spaceId=${spaceId}&&skillTypeId=${skillTypeId}`;
    const journalNavLink = `/module-statistics/space/${spaceId}/base-time-interval/${btiIID}/module/${moduleId}`;
    const history = useHistory();

    const titleOnCLick = () => {
        history.push(moduleLink);
    };

    const tabsOptions: TabsOption[] = [
        {
            title: 'Журнал оценок',
            link: journalNavLink,
            shouldReplaceLink: true,
        },
        {
            title: 'Посещаемость',
            link: attendanceNavLink,
            shouldReplaceLink: true,
        },
    ];

    return (
        <HeaderRedesign
            title={moduleName}
            titleClassname={classes.header__title}
            tabsOptions={tabsOptions}
            imageSrc={arrowBack}
            titleOnClickEvent={titleOnCLick}
            imageOnClickEvent={handleGoBack}
        />
    );
}
