import React from 'react';
import { Icon } from '@admin/NewModule/CustomIcon';
import classnames from 'classnames';
import styles from './SelectedItem.module.scss';

interface SelectedItemProps {
    text?: JSX.Element | string;
    id?: string;
    onRemoveItem?: any;
    hasRemove: boolean;
    title?: boolean;
}

export const SelectedItem = ({
    text,
    onRemoveItem,
    id,
    hasRemove,
    title,
}:SelectedItemProps): JSX.Element => {
    const onClick = () => {
        onRemoveItem(id);
    };
    return (
        <li className={styles.selectedItem}>
            <div className={
                classnames(styles.selectedItem__item,
                    { [styles.selectedItem__item_title]: title })}
            >
                <p className={classnames(styles.selectedItem__text,
                    { [styles.selectedItem__text_title]: title })}
                >
                    {text}
                </p>
            </div>
            {hasRemove ? (<Icon id="remove" onClick={onClick} />) : null}
        </li>
    );
};
