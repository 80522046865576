/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Autosuggest } from '@common/Autosuggest/Autosuggest';
import { useUrlQuery } from '@common/hooks';
import { IconDeprecated, Switcher } from '@common';
import { EventStatus } from '@admin/ScheduleGenerationPage/utils';
import { ScheduleEvent, UpdateEventTeacherFunc } from '@admin/ScheduleGenerationPage/types';
import { useLoadAvailableTeachersLazyQuery } from '../apolloHooks';
import {
    Teacher as TeacherInterface,
    EventTeacher,
} from '../../ScheduleGenerationPageInterfaces';

import classes from './Teacher.module.scss';

interface User {
    id: string,
    user: {
        id: string,
        lastName: string,
        firstName: string,
        patronymic: string,
    },
}

interface TeacherToTeacherRoles {
    teacher: {
        id: string;
    },
    teacherRole: {
        id: string;
    },
}

interface Props {
    data: ScheduleEvent;
    user: User,
    edibleTeacher?: string,
    setEdibleRoom: React.Dispatch<React.SetStateAction<boolean>>,
    setEdibleTeacher: React.Dispatch<React.SetStateAction<string>>,
    getUpdateTeachersFunction: (type: string) => UpdateEventTeacherFunc,
}

export function Teacher({
    data,
    user: teacher,
    edibleTeacher,
    setEdibleTeacher,
    getUpdateTeachersFunction,
    setEdibleRoom,
}: Props): JSX.Element | null {
    // TODO suggestion сейчас принимает только строки => мы не можем
    // получить выбранный пользователем объект teacher => будем сверять
    // имя которое выбрал юзер в suggestion со всеми именами в объектах
    // teachers. Могут быть проблемы если:
    // 1) Преподы имеют одинаковые имя и фамилию
    // 2) Имя или фамилия состоит из нескольких слов разделенных пробелами
    const [name, setName] = useState<string>(`${teacher.user.lastName} ${teacher.user.firstName} ${teacher.user.patronymic}`);

    const { getUrlQuery } = useUrlQuery();

    const { status, startTime, endTime, teacherToTeacherRoles } = data;

    const { loadAvailableTeachers, availableTeachers } = useLoadAvailableTeachersLazyQuery();

    const [selectedTeacher, setSelectedTeacher] = useState<TeacherInterface>();
    const [eventTeachers, setEventTeachers] = useState<EventTeacher[]>([]);
    const teacherName = `${teacher.user.lastName} ${teacher.user.firstName} ${teacher.user.patronymic}`;
    const selectedTeacherName = `
        ${selectedTeacher?.user.lastName} 
        ${selectedTeacher?.user.firstName} 
        ${selectedTeacher?.user.patronymic}`;

    const [isOnlyAvailableTeacher, setIsOnlyAvailableTeacher] = useState(true);
    const editMode = (
        <div className={classes.teacher__name}>
            <div className={classes.teacher__switcher}>
                <Switcher
                    label="Только свободные преподаватели"
                    checked={isOnlyAvailableTeacher}
                    onClick={
                        () => {
                            loadAvailableTeachers(startTime, endTime, !isOnlyAvailableTeacher);
                            setIsOnlyAvailableTeacher((oldState) => !oldState);
                        }
                    }
                />
            </div>
            <div className={classes.teacher__suggestion}>
                <Autosuggest
                    options={availableTeachers?.availableTeachers}
                    formatOption={(item) => `${item.user.lastName!} ${item.user.firstName!} ${item.user.patronymic!} (${item.user.id?.slice(0, 4).toLowerCase()})`}
                    onChange={(item: TeacherInterface) => {
                        const teachersArray = teacherToTeacherRoles
                            .map((teacherItem: TeacherToTeacherRoles) => {
                                const newTeacherId = teacherItem.teacher.id === teacher.id
                                    ? item.id
                                    : teacherItem.teacher.id;
                                return {
                                    currentTeacherId: teacherItem.teacher.id,
                                    newTeacherId,
                                    meetingInstanceId: getUrlQuery('detailedEventId')!,
                                    teacherRole: teacherItem.teacherRole.id,
                                };
                            });
                        setEventTeachers(teachersArray);
                        setSelectedTeacher(item);
                    }}
                    placeholder="Выберите преподавателя"
                />
                <IconDeprecated
                    id="check"
                    click={() => {
                        if (selectedTeacher) {
                            getUpdateTeachersFunction(getUrlQuery('detailedEventType')!)(teacher.id, eventTeachers);
                            setEdibleTeacher('-1');
                            setName(selectedTeacherName);
                        } setEdibleTeacher('-1');
                    }}
                    className={classes.teacher__penIcon}
                />
            </div>
            <div className={classes.teacher__container}>
                <p className={classes.teacher__text}>
                    {!selectedTeacher ? teacherName : selectedTeacherName}
                </p>
            </div>
        </div>
    );
    const viewMode = (
        <div>
            <div className={classes.teacher__name}>
                <Link to={`/users/user/${!selectedTeacher ? teacher.user.id : selectedTeacher?.user.id}`}>
                    {name}
                </Link>
                {status !== EventStatus.DELETED && (
                    <IconDeprecated
                        id="editPenBlack"
                        click={() => {
                            setEdibleTeacher(teacher.id);
                            setEdibleRoom(false);
                            loadAvailableTeachers(startTime, endTime, false);
                        }}
                        className={classes.teacher__penIcon}
                    />
                )}
            </div>
        </div>
    );
    return (
        <div className={classes.teacher}>
            {
                edibleTeacher === teacher.id
                    ? editMode
                    : viewMode
            }
        </div>
    );
}
