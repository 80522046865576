import React from 'react';

interface Props {
    onClick?: () => void;
}

export function ArrowRightIcon({
    onClick = () => { },
}: Props) {
    return (
        <svg width="13" height="8" onClick={onClick}>
            <path
                d="M0 4c0-.118.049-.23.136-.314a.475.475 0 0 1 .328-.13h10.95L8.492.76a.436.436 0 0
                    1-.136-.315c0-.118.049-.231.136-.315a.476.476 0 0 1 .658 0l3.714 3.555A.444.444 0 0
                    1 13 4a.426.426 0 0 1-.136.315L9.15 7.87a.476.476 0 0 1-.658 0 .436.436 0 0
                    1-.136-.315c0-.118.049-.231.136-.315l2.922-2.796H.464a.475.475 0 0 1-.328-.13A.435.435 0 0 1 0 4Z"
                fill="url(#a)"
            />
            <defs>
                <linearGradient
                    id="a"
                    x1="5.759"
                    y1="-.578"
                    x2="8.035"
                    y2="8.896"
                    gradientUnits="userSpaceOnUse"
                />
            </defs>
        </svg>
    );
}
