import React from 'react';

import classes from './EducationSpaceInfo.module.scss';

export function SubspaceSelect(): JSX.Element {
    return (
        <div className={classes.subspaceSelect}>
            <div className={classes.subspaceTypeSelector__label}>
                Укажите тип подпространств для выбора
            </div>
            <select
                className={classes.subspaceTypeSelector__select}
            >
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
            </select>
        </div>
    );
}
