import React from 'react';
import { useHistory } from 'react-router-dom';
import cn from 'classnames';
import { observer } from 'mobx-react';
import moment from 'moment';

import { FilterType, TimeReservingEvent } from '../../Interfaces';
import { getScheduleEventSizesAndPositioningStyle } from './helpers';
import { eventsStore, timeStore } from '../../Store';

import classes from './DateEvent.module.scss';

interface Props {
    dateEvent: TimeReservingEvent;
    allDateEvents: TimeReservingEvent[];
}

export const DayMeeting = observer(({
    dateEvent,
    allDateEvents,
}: Props): JSX.Element => {
    const history = useHistory();

    function handleOnSelectScheduleEvent(scheduleEvent: TimeReservingEvent): void {
        const { getSelectedEventId } = eventsStore;
        if (getSelectedEventId() === dateEvent.id) {
            eventsStore.unselectEvent();
            history.push(`/schedule/${timeStore.unitOfTime}/${moment(timeStore.selectedDate).format('MM-DD-YYYY')}`);
        } else {
            timeStore.selectEventDateIfItInSameTimePeriod(scheduleEvent.startDate);
            eventsStore.selectEvent(scheduleEvent);
            history.push(`/schedule/${timeStore.unitOfTime}/${moment(timeStore.selectedDate).format('MM-DD-YYYY')}/${scheduleEvent.id}`);
        }
    }

    return (
        <div
            className={classes.eventWrapper}
            onClick={() => handleOnSelectScheduleEvent(dateEvent)}
            style={getScheduleEventSizesAndPositioningStyle(dateEvent, allDateEvents)}
        >
            <div
                className={cn(
                    classes.dayEvent,
                    classes[`dayEvent_filter_${dateEvent.type}`],
                    {
                        [classes[`dayEvent_inPast_${dateEvent.type}`]]: dateEvent.endDate < new Date(),
                        [classes.dayEvent_selected]: eventsStore.isEventSelected(dateEvent),
                    },
                )}
                key={dateEvent.id}
            >
                <p className={classes.dayEvent__text}>
                    {dateEvent.type === FilterType.MEETING
                        ? `${dateEvent.meeting.module.name} ${dateEvent.room?.title ?? ''}`
                        : dateEvent.evaluationPoint.topic
                    }
                </p>
            </div>
        </div>
    );
});
