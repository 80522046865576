import React, { useState } from 'react';

import cn from 'classnames';

import { Alert } from '@common/Alert';
import { ActionButton } from '@common/ActionButton';
import { BorderCrossIcon } from '@common/svg';

import NestedSkill from '@admin/SkillPage/SkillAddPage/store/NestedSkill';
import { SkillCardWithNestChildrenRoot } from './SkillCardWithNestChildrenRoot';
import classes from './SKillCardWithNestEdit.module.scss';

interface IProps {
    size?: string
    skillTypes: any;
    skillsList: any;
    closePortal(): void;
    updateNestSkill: (
        id: string,
        name: string,
        typeId: string,
        children: any,
        dependencies: any,
        maxLevel?: number,
    ) => void;
}

export const SkillCardWithNestChildrenEdit = ({
    size,
    skillTypes,
    skillsList,
    closePortal,
    updateNestSkill,
}: IProps) => {
    const [error, setError] = useState('');

    const submitNestedSkill = () => {
        const { skills }: any = NestedSkill;
        if (NestedSkill.isEmptyNameValidator(NestedSkill.skills)) {
            if (NestedSkill.isUniqueValidator(skillsList, NestedSkill.skills)) {
                updateNestSkill(
                    skills.id,
                    skills.name,
                    skills.typeId,
                    skills.children,
                    skills.dependencies,
                    skills.maxLevel,
                );
                NestedSkill.clear();
                closePortal();
            } else {
                setError('Все имена должны быть уникальны');
            }
        } else {
            setError('Перед сохранением надо заполнить обязательные поля');
        }
    };

    const closeModalWindow = () => {
        NestedSkill.clear();
        closePortal();
    };

    return (
        <>
            <div
                className={classes['skill-add-portal-container']}
            >
                <div
                    className={cn(classes['skill-add-portal'], [classes[`skill-add-portal-${size}`]])}
                >
                    <div
                        className={size === 'little'
                            ? classes[`skill-add-portal__wrapper-${size}`]
                            : classes['skill-add-portal__wrapper']
                        }
                    >
                        <div className={classes['skill-add-portal__container']}>
                            <div className={classes['skill-add-portal__title']}>
                                Редактирование
                            </div>
                            <div className={classes['skill-add-portal__contant']}>
                                <SkillCardWithNestChildrenRoot
                                    skillTypes={skillTypes}
                                />
                            </div>
                            <div className={classes['skill-add-portal__button']}>
                                <ActionButton
                                    className={classes.newModule__buttons_margin_right}
                                    onClick={submitNestedSkill}
                                >
                                    Сохранить
                                </ActionButton>
                            </div>
                        </div>
                        <BorderCrossIcon
                            handler={closeModalWindow}
                            size={30}
                            className={classes.blackCrossAddSkillEditPortal}
                        />
                    </div>
                </div>
            </div>

            {error && <Alert message={error} onTimeout={() => setError('')} />}
        </>
    );
};
