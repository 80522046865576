import {
    ApolloError,
    DocumentNode,
    MutationHookOptions,
    MutationTuple,
    OperationVariables,
    TypedDocumentNode,
    useMutation,
} from '@apollo/client';

export function useMutationWithErrorHandling<TData = any, TVariables = OperationVariables>(
    mutation: DocumentNode | TypedDocumentNode<TData, TVariables>,
    options: MutationHookOptions<TData, TVariables> = {},
): MutationTuple<TData, TVariables> {
    return useMutation(mutation, {
        onError: defaultEventHandler,
        ...options,
    });
}

function defaultEventHandler(error: ApolloError): void {
    // eslint-disable-next-line no-console
    console.error(error.name, error.message, error.graphQLErrors);
}
