import React from 'react';
import cn from 'classnames';
import { Link, useHistory } from 'react-router-dom';
import { format } from 'date-fns';

import { TeacherTimeslot, TeacherUser } from '../../OccupationDashboardInterfaces';
import classes from './TimeslotCell.module.scss';

interface TimeslotCelProps {
    timeslot: TeacherTimeslot;
    teacherRoleId: string;
    moduleId: string;
    teacher: TeacherUser; // id is actually a teacherId
    isTUniversityMeetingsVisible: boolean;
}

export function TimeslotCell({
    timeslot,
    teacherRoleId,
    moduleId,
    teacher,
    isTUniversityMeetingsVisible,
}: TimeslotCelProps) {
    const { location: { pathname } } = useHistory();

    function getTimeslotLink() {
        const UrlQueryParams = new URLSearchParams();

        const timeslotDate = format(new Date(timeslot.date), 'yyyy.MM.dd');
        UrlQueryParams.set('from', encodeURIComponent(`${timeslotDate} 00:00`));
        UrlQueryParams.set('to', encodeURIComponent(`${timeslotDate} 23:59`));

        UrlQueryParams.set('teachers', encodeURIComponent(JSON.stringify([teacher])));
        const types = [{ id: 'meeting', name: 'Встреча' }];
        UrlQueryParams.set('types', encodeURIComponent(JSON.stringify(types)));

        return `${pathname}?${UrlQueryParams.toString()}`;
    }

    return (
        <td
            className={getTimeslotClassNames(
                timeslot,
                isTUniversityMeetingsVisible,
                teacherRoleId,
                moduleId,
            )}
            title={`${timeslot.date} ${timeslot.startTime}-${timeslot.endTime}`}
        >
            {timeslot.isReservedByTUniversity && isTUniversityMeetingsVisible && (
                <Link
                    className={classes.timeSlot__link}
                    to={getTimeslotLink}
                    target="_blank"
                />
            )}
        </td>
    );
}

function getTimeslotClassNames(
    timeSlot: TeacherTimeslot,
    isTUniversityMeetingsVisible: boolean,
    teacherRoleId: string,
    moduleId: string,
) {
    const classNames = [];
    if (timeSlot.isNonWorking) {
        classNames.push(classes.timeSlot_isNonWorking);
    } else if (timeSlot.isWorking) {
        if (timeSlot.isReservedByClassicUniversity) {
            classNames.push(classes.timeSlot_isReservedByClassicUniversity);
        } else {
            classNames.push(classes.timeSlot_isWorking);
        }
    }
    if (timeSlot.isReservedByTUniversity && isTUniversityMeetingsVisible) {
        if (isThereMeetingInstanceInTimeslot(timeSlot, teacherRoleId, moduleId)) {
            classNames.push(classes.timeSlot_hasTUniversityMeetingInstance);
        } else {
            classNames.push(classes.timeSlot_isReservedByTUniversity);
        }
    }
    return cn(...classNames, classes.timeSlot);
}

function isThereMeetingInstanceInTimeslot(
    timeSlot: TeacherTimeslot,
    teacherRoleId: string,
    moduleId: string,
) {
    return timeSlot.meetingInstances?.find(({ meeting, meetingInstanceToTeachers }) => (
        meeting.module.id === moduleId
            && meetingInstanceToTeachers.find(({ meetingTeacherRole }) => (
                meetingTeacherRole.id === teacherRoleId
            ))
    )) !== undefined;
}
