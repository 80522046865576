import { gql, useQuery } from '@apollo/client';

const GET_UNIVERSITY_TIME_ZONE = gql`
    query RoomsPageGetUniversityTimeZone {
        universityTimezone
    }
`;

export function useGetUniversityTimeZone() {
    const {
        data: universityTimeZoneData,
        error: universityTimeZoneError,
    } = useQuery<{ universityTimezone: string }>(GET_UNIVERSITY_TIME_ZONE);

    return {
        universityTimeZone: universityTimeZoneData?.universityTimezone,
        universityTimeZoneError,
    };
}
