import React from 'react';
import { gql, useQuery } from '@apollo/client';

import { Loader } from '@common/Loader';
import { GetSpacesForModuleStatistics } from './graphql-types';
import { SpacesModuleList } from './SpacesModuleList';

const GET_SPACES = gql`
  query GetSpacesForModuleStatistics {
    spaces {
      id
      name
    }
  }
`;

export function SpacesModuleListApollo() {
    const { data, error, loading } = useQuery<GetSpacesForModuleStatistics>(
        GET_SPACES,
        { fetchPolicy: 'network-only' },
    );

    if (loading) {
        return <Loader />;
    }

    if (error) {
        return (
            <div>
                Произошла ошибка: {error.graphQLErrors[0]?.message || error.message}
            </div>
        );
    }

    return data ? <SpacesModuleList spaces={data.spaces} /> : null;
}
