import React, { useState } from 'react';
import { Input } from '@common';
import { BorderCrossIcon } from '@common/svg';
import { ActionButton } from '@common/ActionButton';

import classes from './EquipmentCardDialog.module.scss';

interface Equipment {
    id: string;
    name: string;
    unit: string;
}

interface Props {
    item: Equipment;
    dialog: boolean;
    toggleDialog: (flag: boolean) => void;
    updateConsumableEquipment: (
        id: string,
        name: string,
        unit: string,
    ) => void;
}

export function EquipmentCardDialog({
    item,
    dialog,
    toggleDialog,
    updateConsumableEquipment,
}: Props): JSX.Element {
    const [equipmentName, setEquipmentName] = useState(item.name);
    const [equipmentUnit, setEquipmentUnit] = useState(item.unit);

    const onChangeName = (e: React.FormEvent<HTMLInputElement>) => {
        e.preventDefault();
        setEquipmentName(e.currentTarget.value);
    };
    const onChangeUnit = (e: React.FormEvent<HTMLInputElement>) => {
        e.preventDefault();
        setEquipmentUnit(e.currentTarget.value);
    };

    return (
        <div
            className={classes.equipmentCardDialog}
            onClick={() => toggleDialog(!dialog)}
        >
            <div
                className={classes.equipmentCardDialog__container}
                onClick={e => e.stopPropagation()}
            >
                <div className={classes.equipmentCardDialog__content}>
                    <h2>Редактировать расходный материал</h2>
                    <div className={classes.equipmentCardDialog__inputs}>
                        <div className={classes.equipmentCardDialog__inputContainer}>
                            <Input
                                placeholder="Введите название расходного материала"
                                onChange={onChangeName}
                                label="Название:"
                                value={equipmentName}
                            />
                        </div>
                        <div className={classes.equipmentCardDialog__inputContainer}>
                            <Input
                                placeholder="шт"
                                onChange={onChangeUnit}
                                label="Ед. измерения:"
                                value={equipmentUnit}
                            />
                        </div>
                    </div>
                    <div
                        className={classes.equipmentCardDialog__button}
                        onClick={
                            () => updateConsumableEquipment(
                                item.id,
                                equipmentName.trim(),
                                equipmentUnit.trim(),
                            )}
                    >
                        <ActionButton>
                            Изменить
                        </ActionButton>
                    </div>
                    <div
                        className={classes.equipmentCardDialog__iconContainer}
                        onClick={() => toggleDialog(!dialog)}
                    >
                        <BorderCrossIcon
                            className={classes.equipmentCardDialog__icon}
                            size={30}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}
