import React from 'react';
import cn from 'classnames';

import { AvatarIcon } from '@common/svg';
import { User } from '../../../../../Interfaces';
import { accountStore } from '../../../../../../Store';

import classes from './UserList.module.scss';

interface Props {
    user: User;
}

export function UserList({ user }: Props): JSX.Element {
    return (
        <div
            className={classes.schedulePage_eventsSection_users}
            key={user.id}
        >
            <div className={classes.schedulePage_eventsSection_userContainer}>
                <div className={classes.schedulePage_eventsSection_userInfo}>
                    <div className={classes.schedulePage_eventsSection_userPicture}>
                        <div className={classes.user__avatar}>
                            <AvatarIcon size={40} />
                        </div>
                    </div>
                    <div
                        className={cn(classes.userName__data, {
                            [classes.userName__data_currentUser]:
                                accountStore.userId === user.id,
                        })}
                    >
                        <div>
                            {user.lastName}&nbsp; {user.firstName}
                        </div>
                        <div>
                            {user.patronymic}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
