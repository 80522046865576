import React from 'react';
import { observer } from 'mobx-react';

import { Title } from '@common';
import { GetEquipmentsEquipmentPage_equipment_list_movability } from 'src/graphql-query-types';
import { SuggestInputForm, SuggestInputFormInitialData } from '@admin/NewModule';
import { Section, CheckboxForm } from '../Section';
import { normalBoldJSX } from '../utils';

import styles from './Meeting.module.scss';

import {
    ModuleEquipment, ModuleConsumableEquipment, moduleStore, dictionaryStore, Meeting,
} from '../Store';

interface EquipmentView {
    id: string;
    name: string;
    categoryId?: string;
    inputValue?: string;
}

interface Props {
    meeting: Meeting;

    setRef(ref: React.MutableRefObject<HTMLDivElement | null>): void;
}

const getUnit = (equipmentId: string) => dictionaryStore.consumableEquipment
    .find(({ id }) => id === equipmentId)?.unit ?? '';

export const MeetingEquipments = observer(({ meeting, setRef }: Props) => {
    const validateCount = (item: EquipmentView) => item.id !== '' && item.inputValue !== ''
        && Number(item.inputValue) > 0;

    const validateEquipmentCountPerStudent = (equipmentId: string, count: number): number => {
        const maxCount = 10000;
        return count > maxCount ? maxCount : count;
    };

    const normalEquipmentsDataPerMeeting = (equipmentViews: EquipmentView[]) => equipmentViews
        .map(item => ({
            id: item.id,
            category: {
                id: item.categoryId!,
                name: item.name,
            },
            count: Number(item.inputValue),
        }));

    const normalEquipmentsDataPerStudent = (equipmentViews: EquipmentView[]) => equipmentViews
        .map(item => {
            const count = validateEquipmentCountPerStudent(item.id, Number(item.inputValue));
            return { id: item.id, category: { id: item.categoryId!, name: item.name }, count };
        });

    const normalConsumableEquipments = (
        equipmentViews: EquipmentView[],
    ): ModuleConsumableEquipment[] => equipmentViews
        .map(item => {
            const count = Number(item.inputValue);
            return {
                consumableEquipment: { id: item.id, name: item.name, unit: getUnit(item.id) },
                count,
            };
        });

    const normalListData = (equipments: ModuleEquipment[]) => equipments?.map(item => ({
        id: item.id,
        name: item.category.name,
        inputValue: String(item?.count),
        categoryId: item.category.id,
    })) ?? [];

    const normalConsumableListData = (equipments: ModuleConsumableEquipment[]) => equipments
        ?.map(item => ({
            id: item.consumableEquipment.id,
            name: item.consumableEquipment.name,
            inputValue: String(item.count),
        })) ?? [];

    const module = moduleStore.moduleModel;

    const dataEquipmentPerMeetingList = normalListData(meeting?.equipmentPerMeeting!);
    const dataEquipmentPerStudent = normalListData(meeting?.equipmentPerStudent!);
    const dataConsumableEquipmentPerMeeting = normalConsumableListData(
        meeting?.consumableEquipmentPerMeeting!,
    );
    const dataConsumableEquipmentPerStudent = normalConsumableListData(
        meeting?.consumableEquipmentPerStudent!,
    );
    const sortEquipments = [...dictionaryStore.equipment?.list].sort((a, b) => {
        if (a.category.name < b.category.name) return -1;
        if (a.category.name > b.category.name) return 1;
        return 0;
    }).map(({ id, category, movability }) => ({
        id,
        name: category.name,
        categoryId: category.id,
        category: {
            id: category.id,
        },
        count: getMovabilityTotalCount(movability),
    }));

    const sortConsumableEquipments = [...dictionaryStore.consumableEquipment].sort((a, b) => {
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
        return 0;
    });

    const ref = React.useRef<HTMLDivElement | null>(null);
    React.useEffect(() => setRef(ref), []);

    const [unitOfEquipmentPerMeeting, setUnitOfEquipmentPerMeeting] = React
        .useState<string | null | undefined>();
    const [unitOfEquipmentPerStudent, setUnitOfEquipmentPerStudent] = React
        .useState<string | null | undefined>();

    return (
        <div className={styles.form__equipments} ref={ref}>
            <div className={styles.form__title}>
                <Title>Оборудование и расходные материалы для проведения встречи:</Title>
            </div>
            <CheckboxForm
                checked={dataEquipmentPerMeetingList.length > 0}
                onChangeCheck={() => module.updateMeeting(meeting.id, { equipmentPerMeeting: [] })}
                checkboxLabel="Оборудование, не зависящее от количества студентов"
                title="Укажите оборудование и его количество необходимое для проведения встречи:"
            >
                <Section
                    initialData={SuggestInputFormInitialData}
                    list={dataEquipmentPerMeetingList}
                    form={SuggestInputForm}
                    formProps={{
                        mainOptions: sortEquipments,
                        placeholderSuggest: 'Укажите наименование оборудования',
                        placeholderInput: 'Кол-во',
                    }}
                    onChange={equipments => {
                        const equipmentPerMeeting = normalEquipmentsDataPerMeeting(equipments);
                        module.updateMeeting(meeting.id, { equipmentPerMeeting });
                    }}
                    formatItem={item => normalBoldJSX(item.name, item.inputValue)}
                    validate={validateCount}
                />
            </CheckboxForm>
            <div className={styles.form__line} />
            <CheckboxForm
                checked={dataEquipmentPerStudent.length > 0}
                onChangeCheck={() => module.updateMeeting(meeting.id, { equipmentPerStudent: [] })}
                checkboxLabel="Оборудование, зависящее от количества студентов"
                title="Укажите оборудование и количество студентов на 1 единицу этого оборудования:"
            >
                <Section
                    initialData={SuggestInputFormInitialData}
                    list={dataEquipmentPerStudent}
                    form={SuggestInputForm}
                    formProps={{
                        mainOptions: sortEquipments
                            .map(equipment => ({ ...equipment, count: 10000 })),
                        placeholderSuggest: 'Укажите наименование оборудования',
                        placeholderInput: 'Кол-во',
                        labelInput: 'на',
                        extraText: 'студентов',
                    }}
                    onChange={equipments => {
                        const equipmentPerStudent = normalEquipmentsDataPerStudent(equipments);
                        module.updateMeeting(meeting.id, { equipmentPerStudent });
                    }}
                    formatItem={item => normalBoldJSX(`${item.name} на`, `${item.inputValue} студентов`)}
                    validate={validateCount}
                />
            </CheckboxForm>
            <div className={styles.form__line} />
            <CheckboxForm
                checked={dataConsumableEquipmentPerMeeting.length > 0}
                onChangeCheck={() => module.updateMeeting(
                    meeting.id, { consumableEquipmentPerMeeting: [] },
                )}
                checkboxLabel="Расходные материалы, не зависящее от количества студентов"
                title="Укажите название расходного материала и количество необходимое для проведения встречи:"
            >
                <Section
                    initialData={SuggestInputFormInitialData}
                    list={dataConsumableEquipmentPerMeeting}
                    form={SuggestInputForm}
                    formProps={{
                        mainOptions: sortConsumableEquipments
                            .map(equip => ({ ...equip, count: Number.MAX_SAFE_INTEGER })),
                        placeholderSuggest: 'Укажите наименование оборудования',
                        placeholderInput: 'Кол-во',
                        extraText: unitOfEquipmentPerMeeting ?? '',
                        onChange: (equipmentId: string) => {
                            setUnitOfEquipmentPerMeeting(getUnit(equipmentId));
                        },
                    }}
                    onChange={equipments => {
                        const consumableEquipmentPerMeeting = normalConsumableEquipments(
                            equipments,
                        );
                        module.updateMeeting(meeting.id, { consumableEquipmentPerMeeting });
                    }}
                    formatItem={item => normalBoldJSX(item.name, `${item.inputValue} ${getUnit(item.id)}`)}
                    validate={validateCount}
                />
            </CheckboxForm>
            <div className={styles.form__line} />
            <CheckboxForm
                checked={dataConsumableEquipmentPerStudent.length > 0}
                onChangeCheck={() => module.updateMeeting(
                    meeting.id, { consumableEquipmentPerStudent: [] },
                )}
                checkboxLabel="Расходные материалы, зависящие от количества студентов"
                title="Укажите название расходного материала и количество студентов на 1 единицу этого расходного материала:"
            >
                <Section
                    initialData={SuggestInputFormInitialData}
                    list={dataConsumableEquipmentPerStudent}
                    form={SuggestInputForm}
                    formProps={{
                        mainOptions: sortConsumableEquipments
                            .map(equipment => ({ ...equipment, count: 10000 })),
                        placeholderSuggest: 'Укажите наименование оборудования',
                        placeholderInput: 'Кол-во',
                        labelInput: `${unitOfEquipmentPerStudent ?? ''} на`,
                        extraText: 'cтудентов',
                        onChange: (equipmentId: string) => {
                            setUnitOfEquipmentPerStudent(getUnit(equipmentId));
                        },
                    }}
                    onChange={equipments => {
                        const consumableEquipmentPerStudent = normalConsumableEquipments(
                            equipments,
                        );
                        module.updateMeeting(meeting.id, { consumableEquipmentPerStudent });
                    }}
                    formatItem={item => normalBoldJSX(`${item.name} ${getUnit(item.id)} на`, `${item.inputValue} студентов`)}
                    validate={validateCount}
                />
            </CheckboxForm>
        </div>
    );
});

function getMovabilityTotalCount(
    movability: GetEquipmentsEquipmentPage_equipment_list_movability[],
) {
    return movability.reduce((acc, item) => acc + item.count, 0);
}
