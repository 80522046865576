import React from 'react';

interface Props {
    onClick?(): void;
    className?: string;
}
export const ForwardIcon = ({ onClick, className }: Props): JSX.Element => (
    <svg className={className} onClick={onClick} width="21" height="14" viewBox="0 0 21 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M-1.02362e-06 6.99997C-1.05378e-06 6.80107 0.0790115 6.61032 0.219655 6.46968C0.360298 6.32903 0.551051 6.25002 0.749951 6.25002L18.4383 6.25002L13.7181 1.53132C13.5773 1.3905 13.4982 1.19951 13.4982 1.00035C13.4982 0.801204 13.5773 0.610209 13.7181 0.469389C13.8589 0.328567 14.0499 0.249456 14.2491 0.249456C14.4482 0.249455 14.6392 0.328567 14.7801 0.469389L20.7797 6.46901C20.8495 6.53867 20.9049 6.62143 20.9427 6.71254C20.9805 6.80365 21 6.90133 21 6.99997C21 7.09862 20.9805 7.19629 20.9427 7.2874C20.9049 7.37852 20.8495 7.46127 20.7797 7.53094L14.7801 13.5306C14.6392 13.6714 14.4482 13.7505 14.2491 13.7505C14.0499 13.7505 13.8589 13.6714 13.7181 13.5306C13.5773 13.3897 13.4982 13.1987 13.4982 12.9996C13.4982 12.8004 13.5773 12.6094 13.7181 12.4686L18.4383 7.74992L0.749951 7.74992C0.551052 7.74992 0.360298 7.67091 0.219655 7.53027C0.0790116 7.38962 -9.93458e-07 7.19887 -1.02362e-06 6.99997Z" fill="url(#paint0_linear_1460:7174)" />
        <defs>
            <linearGradient id="paint0_linear_1460:7174" x1="9.30379" y1="-0.726521" x2="13.2965" y2="15.1834" gradientUnits="userSpaceOnUse">
                <stop stopColor="#0B42DC" />
                <stop offset="0.0416667" stopColor="#0B42DC" />
                <stop offset="0.130208" stopColor="#0B42DC" />
                <stop offset="0.734375" stopColor="#57B2C6" />
                <stop offset="0.932292" stopColor="#EED660" />
            </linearGradient>
        </defs>
    </svg>
);
