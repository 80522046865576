import { makeAutoObservable } from 'mobx';
import { sortBy } from 'lodash';
import {
    DistributedStudentsGroupedByModule_distributedStudentsGroupedByModule
    as ModuleGroup,
    DistributedStudentsGroupedByModule_distributedStudentsGroupedByModule_students
    as Student,
    SpaceBaseTimeInterval_spaceBaseTimeInterval_spaceBaseTimeIntervalSubspaces_slots_module
    as Module,
    StudentsRatings_studentsRating
    as StudentWithRating,
} from '@admin/ModuleSelectionPage/graphql-types';
import { ModuleGroupWithAdditionalFields, StudentWithAdditionalFields } from '@admin/ModuleSelectionPage/Interfaces';

class ModuleSelectionStore {
    private notDistribStudentsWithAdditionalFields: StudentWithAdditionalFields[] = [];

    private moduleGroupsWithAdditionalFields: ModuleGroupWithAdditionalFields[] = [];

    private movedStudents: StudentWithAdditionalFields[] = [];

    private isAllDisabled: boolean = false;

    private studentsWithRatings: StudentWithRating[] = [];

    constructor() {
        makeAutoObservable(this);
    }

    setNotDistribStudentsWithAdditionalFieldsFromDefaultStudents = (
        newStudents: Student[],
    ) => {
        this.setNotDistribStudentsWithAdditionalFields(
            getStudentsWithAdditionalFields(
                newStudents,
            ),
        );
    };

    setNotDistribStudentsWithAdditionalFields = (
        newNotDistribStudents: StudentWithAdditionalFields[],
    ) => {
        this.notDistribStudentsWithAdditionalFields = newNotDistribStudents;
    };

    setModuleGroupsWithAdditionalFieldsFromModuleGroups = (
        moduleGroups: ModuleGroup[],
    ) => {
        this.setModuleGroupsWithAdditionalFields(
            getModuleGroupsWithAdditionalFields(
                moduleGroups,
                this.moduleGroupsWithAdditionalFields,
            ),
        );
    };

    setModuleGroupsFromForkModules = (
        modules: Module[],
    ) => {
        const moduleGroupsFromForkModules = getModuleGroupsFromForkModules(
            modules,
        );

        this.setModuleGroupsWithAdditionalFields(moduleGroupsFromForkModules);
    };

    setModuleGroupsWithAdditionalFields = (
        newModuleGroupsWithAdditionalFields: ModuleGroupWithAdditionalFields[],
    ) => {
        this.moduleGroupsWithAdditionalFields = newModuleGroupsWithAdditionalFields;
    };

    setMovedStudents = (
        newMovedStudents: StudentWithAdditionalFields[],
    ) => {
        this.movedStudents = newMovedStudents;
    };

    setStudentsWithRatings = (
        studentsWithRating: StudentWithRating[],
    ) => {
        this.studentsWithRatings = studentsWithRating;
    };

    setIsAllDisabled = (
        isDisabled: boolean,
    ) => {
        this.isAllDisabled = isDisabled;
    };

    getNotDistribStudentsWithAdditionalFields = () => this.notDistribStudentsWithAdditionalFields;

    getModuleGroupsWithAdditionalFields = () => this.moduleGroupsWithAdditionalFields;

    getMovedStudents = () => this.movedStudents;

    getStudentsWithRating = () => this.studentsWithRatings;

    getIsDisabled = () => this.isAllDisabled;
}

export const moduleSelectionStore = new ModuleSelectionStore();

function getModuleGroupsWithAdditionalFields(
    moduleGroups: ModuleGroup[],
    existingModuleGroups: ModuleGroupWithAdditionalFields[],
): ModuleGroupWithAdditionalFields[] {
    return existingModuleGroups.map((existingGroup) => {
        const currentModuleGroup = moduleGroups.find(
            (moduleGroup) => moduleGroup.module.id === existingGroup.module.id,
        );
        if (!currentModuleGroup) {
            return existingGroup;
        }

        const students
        :StudentWithAdditionalFields[] = currentModuleGroup.students.map((student) => (
            {
                ...student,
                isSelected: false,
                wasMoved: false,
            }
        ));

        const sortedStudents = sortBy(students, student => student.user.lastName);

        return {
            ...currentModuleGroup,
            module: {
                ...currentModuleGroup.module,
                defMinStudentsCount: currentModuleGroup.module.minStudentCount,
                defMaxStudentsCount: currentModuleGroup.module.maxStudentCount,
                currentWaveIndex: 1,
            },
            students: sortedStudents,
            additionalStudents: [],
        };
    });
}

function getModuleGroupsFromForkModules(
    modules: Module[],
): ModuleGroupWithAdditionalFields[] {
    return modules.map((module) => (
        {
            __typename: 'ModuleStudents',
            module: {
                ...module,
                defMinStudentsCount: module.minStudentCount,
                defMaxStudentsCount: module.maxStudentCount,
                maxWaveCount: module.maxWaveCount,
                currentWaveIndex: 1,
            },
            students: [],
            additionalStudents: [],
        }
    ));
}

function getStudentsWithAdditionalFields(
    students: Student[],
): StudentWithAdditionalFields[] {
    const studentsWithAF = students.map((student) => (
        {
            ...student,
            isSelected: false,
            wasMoved: false,
        }
    ));

    return sortBy(studentsWithAF, student => student.user.lastName);
}
