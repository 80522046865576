import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import ru from 'date-fns/locale/ru';

// import 'react-datepicker/dist/react-datepicker.css';

import './CalendarNedoshkovskiy.scss';

export interface ExcludedDateInterval {
    start: Date;
    end: Date;
}

interface Props {
    onChange: (date: Date) => void;
    modifier?: string;
    autoFocus?: boolean;
    isOpen?: boolean;
    selectedDate?: Date;
    excludedDateIntervals?: ExcludedDateInterval[];
    includedDateIntervals?: ExcludedDateInterval[];
    onCalendarClose?: () => void;
    onCalendarOpen?: () => void;
    onClickOutside?: () => void;
    onBlur?: () => void;
}

export function CalendarNedoshkovskiy(
    {
        onChange,
        modifier,
        autoFocus,
        isOpen,
        selectedDate,
        excludedDateIntervals,
        includedDateIntervals,
        onCalendarClose,
        onClickOutside,
        onCalendarOpen,
        onBlur,
    }: Props,
) {
    const [date, setDate] = useState<Date>(selectedDate || new Date());

    return (
        <div className="commonCalendar">

            <DatePicker
                onChange={(newDate: Date) => {
                    setDate(newDate);
                    onChange(newDate);
                }}
                shouldCloseOnSelect={false}
                locale={ru}
                selected={Array.isArray(date) ? date[0] : date}
                showYearDropdown
                onCalendarClose={onCalendarClose}
                dateFormatCalendar="LLLL"
                autoFocus={autoFocus}
                includeDateIntervals={includedDateIntervals}
                dateFormat="dd.MM.yyyy"
                className={`calendar__input calendar__input_${modifier}`}
                popperClassName="calendar__popperCommon"
                wrapperClassName="calendar__wrapperCommon"
                excludeDateIntervals={excludedDateIntervals}
                open={isOpen}
                onClickOutside={onClickOutside}
                onCalendarOpen={() => {
                    if (onCalendarOpen) {
                        onCalendarOpen();
                    }
                }}
                onBlur={onBlur}
            />

        </div>
    );
}
