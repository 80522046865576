import React from 'react';

import classes from './ModuleSkillsListSpecificSkills.module.scss';

interface Props {
    skillList: any;
}

export function ModuleSkillsListSpecificSkills({ skillList }: Props):JSX.Element {
    return (
        <div className={classes.moduleSkillsListSpecificSkills}>
            <ul className={classes.moduleSkillsListSpecificSkills__list}>
                {skillList.map((skill: any) => (
                    <li
                        key={skill.id}
                        className={classes.moduleSkillsListSpecificSkills__item}
                    >
                        <div className={classes.moduleSkillsListSpecificSkills__currentSkillName}>
                            {skill.name}
                        </div>
                        <div className={classes.moduleSkillsListSpecificSkills__currentSkillLevel}>
                            {skill.level}
                        </div>
                    </li>
                ))}
            </ul>
        </div>
    );
}
