import React, { useEffect, useRef, useState } from 'react';

import cn from 'classnames';
import { NavLink, useRouteMatch } from 'react-router-dom';

import { TabsOption } from '@common/TabsRedesign/TabsRedesign';
import { StudentTargetName, useSendMetriks } from '@common/hooks';
import { ActiveArrow } from '@common/TabsRedesignWithHiddenTabs/HiddenTabs/ActiveArrow';
import { DefaultArrow } from '@common/TabsRedesignWithHiddenTabs/HiddenTabs/DefaultArrow';
import { ViewType } from '../../../deprecated-graphql-query-types';

import classes from './HiddenTabs.module.scss';

interface Props {
    visibleTabsAmount: number;
    tabsOptions: TabsOption[];
}
interface UseGetButtonTextProps {
    tabsOptions: TabsOption[],
}

export function HiddenTabs(
    {
        visibleTabsAmount,
        tabsOptions,
    }: Props,
) {
    const [isOpen, setOpen] = useState(false);
    const button = useRef(null);
    const currentOptionLink = window.location.href.split('/').splice(4).join('/');
    const { path } = useRouteMatch();
    const { openButtonText } = useGetButtonText({ tabsOptions });

    const currentOption = getCurrentOption(
        openButtonText,
        tabsOptions,
    );

    const { sendYandexMetrika } = useSendMetriks([ViewType.Student]);

    useEffect(() => {
        const closeTabs = (event: MouseEvent) => {
            if (event.target !== button.current) {
                setOpen(false);
            }
        };

        document.body.addEventListener('click', (event) => closeTabs(event));

        return () => document.body.removeEventListener('click', closeTabs);
    }, []);

    const isHiddenActive = checkIfHiddenTabActive(
        currentOptionLink,
        tabsOptions,
    );

    return (
        <div className={classes.hidden}>
            <button
                type="button"
                className={cn(classes.hiddenTabs, {
                    [classes.hiddenTabs_open]: isOpen,
                    [classes.hiddenTabs_active]: isHiddenActive && !isOpen,
                })}
                ref={button}
                onClick={() => setOpen(!isOpen)}
            >
                <span
                    className={classes.hiddenTabs__title}
                >
                    {
                        getTitle(
                            currentOption?.title ?? `еще ${tabsOptions.length}`,
                            currentOption?.isNotStudentChoiceFork ?? false,
                        )
                    }
                </span>

                {
                    isOpen ? (
                        <ActiveArrow />
                    ) : (
                        <DefaultArrow />
                    )
                }

            </button>

            {
                isOpen && (
                    <div
                        className={cn(classes.hiddenOptions, {
                            [classes.hiddenOptions_single]: visibleTabsAmount === 1,
                        })}
                    >

                        {
                            tabsOptions.map((option) => (

                                <NavLink
                                    key={option.link}
                                    className={classes.hiddenOption__link}
                                    activeClassName={classes.hiddenOption__link_selected}
                                    to={`${path}/${option.link}`}
                                    onClick={() => {
                                        if (path === '/modules') {
                                            sendYandexMetrika(StudentTargetName.modules, {
                                                statusModule: option.selectionStatus,
                                            });
                                        }
                                    }}
                                >
                                    {option.title}
                                </NavLink>

                            ))
                        }

                    </div>
                )
            }
        </div>
    );
}

function useGetButtonText(
    {
        tabsOptions,
    }: UseGetButtonTextProps,
) {
    const currentOptionLink = window.location.href.split('/').splice(4).join('/');

    const [openButtonText, setOpenButtonText] = useState(
        getActiveHiddenTabTitle(currentOptionLink, tabsOptions)
        ?? `ещё ${tabsOptions.length}`,
    );

    useEffect(() => {
        const currentOpenButtonText = getActiveHiddenTabTitle(currentOptionLink, tabsOptions) ?? `ещё ${tabsOptions.length}`;
        setOpenButtonText(currentOpenButtonText);
    }, [window.location.href]);

    return {
        openButtonText,
    };
}

function getActiveHiddenTabTitle(
    currentLink: string,
    tabs: TabsOption[],
) {
    return tabs.find((tab) => tab.link === currentLink)?.title;
}

function checkIfHiddenTabActive(
    currentLink: string,
    tabs: TabsOption[],
) {
    return tabs.some((tab) => tab.link === currentLink);
}

function getTitle(
    title: string,
    isNotStudentChoice: boolean,
) {
    if (!isNotStudentChoice) {
        return title;
    }

    const newTitle = title.split('/').reverse().join(' / ');

    return newTitle;
}

function getCurrentOption(
    currentOptionTitle: string,
    options: TabsOption[],
) {
    return options.find((option) => option.title === currentOptionTitle);
}
