import React, { useState } from 'react';

import style from './Loader.module.scss';

interface Props {
    BGImage?: string;
    disabled?: boolean;
}

interface State {
    file: string;
    imagePreviewUrl?: string | ArrayBuffer | null;
}

export const Loader = ({ BGImage, disabled }: Props) => {
    const [fileData, setFileData] = useState<State>({ file: '' });

    const handleImageChange = (event: any) => {
        event.preventDefault();
        const reader = new FileReader();
        const file = event.target.files[0];
        reader.onloadend = () => {
            setFileData({
                file,
                imagePreviewUrl: reader.result,
            });
        };
        return file && reader.readAsDataURL(file);
    };

    return (
        <div
            className={style.cover}
            style={fileData.imagePreviewUrl
                ? { backgroundImage: `url(${fileData.imagePreviewUrl})` }
                : { backgroundImage: BGImage }}
        >
            <input
                className={style.cover__input}
                type="file"
                onChange={handleImageChange}
                disabled={disabled}
            />
        </div>
    );
};
