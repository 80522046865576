/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetEquipmentsEquipmentPage
// ====================================================

export interface GetEquipmentsEquipmentPage_equipment_list_category {
  __typename: "EquipmentCategory";
  id: string;
  name: string;
}

export interface GetEquipmentsEquipmentPage_equipment_list_features {
  __typename: "EquipmentFeature";
  id: string;
  name: string;
}

export interface GetEquipmentsEquipmentPage_equipment_list_movability {
  __typename: "EquipmentMovability";
  id: string;
  isMovable: boolean;
  count: number;
  territorialZoneId: string | null;
}

export interface GetEquipmentsEquipmentPage_equipment_list_rooms_room {
  __typename: "Room";
  id: string;
  title: string;
}

export interface GetEquipmentsEquipmentPage_equipment_list_rooms {
  __typename: "EquipmentToRoom";
  countEquipments: number;
  room: GetEquipmentsEquipmentPage_equipment_list_rooms_room | null;
}

export interface GetEquipmentsEquipmentPage_equipment_list {
  __typename: "Equipment";
  id: string;
  category: GetEquipmentsEquipmentPage_equipment_list_category;
  features: GetEquipmentsEquipmentPage_equipment_list_features[];
  movability: GetEquipmentsEquipmentPage_equipment_list_movability[];
  rooms: GetEquipmentsEquipmentPage_equipment_list_rooms[];
}

export interface GetEquipmentsEquipmentPage_equipment {
  __typename: "EquipmentResponse";
  list: GetEquipmentsEquipmentPage_equipment_list[];
  totalCount: number;
}

export interface GetEquipmentsEquipmentPage {
  equipment: GetEquipmentsEquipmentPage_equipment;
}

export interface GetEquipmentsEquipmentPageVariables {
  filterParams: EquipmentFilterParams;
  paginationParams: EquipmentPaginationParams;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetRoomsEquipmentPage
// ====================================================

export interface GetRoomsEquipmentPage_rooms {
  __typename: "Room";
  id: string;
  title: string;
}

export interface GetRoomsEquipmentPage {
  rooms: GetRoomsEquipmentPage_rooms[];
}

export interface GetRoomsEquipmentPageVariables {
  roomsInput?: RoomsInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: RemoveEquipmentEquipmentPage
// ====================================================

export interface RemoveEquipmentEquipmentPage {
  removeEquipment: boolean;
}

export interface RemoveEquipmentEquipmentPageVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: TerritorialZonesEquipmentPage
// ====================================================

export interface TerritorialZonesEquipmentPage_territorialZones {
  __typename: "TerritorialZone";
  id: string;
  name: string;
}

export interface TerritorialZonesEquipmentPage {
  territorialZones: TerritorialZonesEquipmentPage_territorialZones[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateEquipmentEquipmentPage
// ====================================================

export interface UpdateEquipmentEquipmentPage_updateEquipment_category {
  __typename: "EquipmentCategory";
  id: string;
  name: string;
}

export interface UpdateEquipmentEquipmentPage_updateEquipment_features {
  __typename: "EquipmentFeature";
  id: string;
  name: string;
}

export interface UpdateEquipmentEquipmentPage_updateEquipment_movability {
  __typename: "EquipmentMovability";
  id: string;
  isMovable: boolean;
  count: number;
  territorialZoneId: string | null;
}

export interface UpdateEquipmentEquipmentPage_updateEquipment_rooms_room {
  __typename: "Room";
  id: string;
  title: string;
}

export interface UpdateEquipmentEquipmentPage_updateEquipment_rooms {
  __typename: "EquipmentToRoom";
  countEquipments: number;
  room: UpdateEquipmentEquipmentPage_updateEquipment_rooms_room | null;
}

export interface UpdateEquipmentEquipmentPage_updateEquipment {
  __typename: "Equipment";
  id: string;
  category: UpdateEquipmentEquipmentPage_updateEquipment_category;
  features: UpdateEquipmentEquipmentPage_updateEquipment_features[];
  movability: UpdateEquipmentEquipmentPage_updateEquipment_movability[];
  rooms: UpdateEquipmentEquipmentPage_updateEquipment_rooms[];
}

export interface UpdateEquipmentEquipmentPage {
  updateEquipment: UpdateEquipmentEquipmentPage_updateEquipment;
}

export interface UpdateEquipmentEquipmentPageVariables {
  updateEquipmentInput: UpdateEquipmentInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateEquipmentCategoryEquipmentPage
// ====================================================

export interface UpdateEquipmentCategoryEquipmentPage_updateEquipmentCategory {
  __typename: "EquipmentCategory";
  id: string;
  name: string;
}

export interface UpdateEquipmentCategoryEquipmentPage {
  updateEquipmentCategory: UpdateEquipmentCategoryEquipmentPage_updateEquipmentCategory;
}

export interface UpdateEquipmentCategoryEquipmentPageVariables {
  updateEquipmentCategoryInput: UpdateEquipmentCategoryInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateEquipmentEquipmentPage
// ====================================================

export interface CreateEquipmentEquipmentPage_createEquipment_category {
  __typename: "EquipmentCategory";
  id: string;
  name: string;
}

export interface CreateEquipmentEquipmentPage_createEquipment_features {
  __typename: "EquipmentFeature";
  id: string;
  name: string;
}

export interface CreateEquipmentEquipmentPage_createEquipment_movability {
  __typename: "EquipmentMovability";
  id: string;
  isMovable: boolean;
  count: number;
  territorialZoneId: string | null;
}

export interface CreateEquipmentEquipmentPage_createEquipment_rooms_room {
  __typename: "Room";
  id: string;
  title: string;
}

export interface CreateEquipmentEquipmentPage_createEquipment_rooms {
  __typename: "EquipmentToRoom";
  countEquipments: number;
  room: CreateEquipmentEquipmentPage_createEquipment_rooms_room | null;
}

export interface CreateEquipmentEquipmentPage_createEquipment {
  __typename: "Equipment";
  id: string;
  category: CreateEquipmentEquipmentPage_createEquipment_category;
  features: CreateEquipmentEquipmentPage_createEquipment_features[];
  movability: CreateEquipmentEquipmentPage_createEquipment_movability[];
  rooms: CreateEquipmentEquipmentPage_createEquipment_rooms[];
}

export interface CreateEquipmentEquipmentPage {
  createEquipment: CreateEquipmentEquipmentPage_createEquipment;
}

export interface CreateEquipmentEquipmentPageVariables {
  createEquipmentInput: CreateEquipmentInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: TerritorialZones
// ====================================================

export interface TerritorialZones_territorialZones {
  __typename: "TerritorialZone";
  id: string;
  name: string;
}

export interface TerritorialZones {
  territorialZones: TerritorialZones_territorialZones[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: TerritorialZone
// ====================================================

export interface TerritorialZone_territorialZone_rooms_availableIntervals {
  __typename: "RoomAvailableInterval";
  id: string;
  from: any;
  to: any;
}

export interface TerritorialZone_territorialZone_rooms_meetingFormats {
  __typename: "MeetingFormat";
  id: string;
  name: string;
}

export interface TerritorialZone_territorialZone_rooms_location {
  __typename: "Building";
  id: string;
  name: string;
  location: string;
}

export interface TerritorialZone_territorialZone_rooms_equipmentToRoom_equipment_category {
  __typename: "EquipmentCategory";
  name: string;
}

export interface TerritorialZone_territorialZone_rooms_equipmentToRoom_equipment {
  __typename: "Equipment";
  category: TerritorialZone_territorialZone_rooms_equipmentToRoom_equipment_category;
}

export interface TerritorialZone_territorialZone_rooms_equipmentToRoom {
  __typename: "EquipmentToRoom";
  roomId: string;
  equipmentId: string;
  equipment: TerritorialZone_territorialZone_rooms_equipmentToRoom_equipment | null;
  countEquipments: number;
}

export interface TerritorialZone_territorialZone_rooms {
  __typename: "Room";
  id: string;
  isAvailable: boolean;
  availableIntervals: TerritorialZone_territorialZone_rooms_availableIntervals[];
  meetingFormats: TerritorialZone_territorialZone_rooms_meetingFormats[];
  title: string;
  capacity: number;
  location: TerritorialZone_territorialZone_rooms_location;
  equipmentToRoom: TerritorialZone_territorialZone_rooms_equipmentToRoom[];
}

export interface TerritorialZone_territorialZone {
  __typename: "TerritorialZone";
  rooms: TerritorialZone_territorialZone_rooms[];
}

export interface TerritorialZone {
  territorialZone: TerritorialZone_territorialZone;
}

export interface TerritorialZoneVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Buildings
// ====================================================

export interface Buildings_buildings {
  __typename: "Building";
  id: string;
  name: string;
  location: string;
}

export interface Buildings {
  buildings: Buildings_buildings[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: RoomsPage_Rooms
// ====================================================

export interface RoomsPage_Rooms_rooms_location_territorialZone_availableIntervals {
  __typename: "TerritorialZoneAvailableInterval";
  from: any;
  to: any;
  id: string;
}

export interface RoomsPage_Rooms_rooms_location_territorialZone {
  __typename: "TerritorialZone";
  availableIntervals: RoomsPage_Rooms_rooms_location_territorialZone_availableIntervals[];
}

export interface RoomsPage_Rooms_rooms_location {
  __typename: "Building";
  territorialZone: RoomsPage_Rooms_rooms_location_territorialZone;
  id: string;
  name: string;
  location: string;
}

export interface RoomsPage_Rooms_rooms_availableIntervals {
  __typename: "RoomAvailableInterval";
  id: string;
  from: any;
  to: any;
  isEveryMonth: boolean;
}

export interface RoomsPage_Rooms_rooms_repeatingAvailableIntervals {
  __typename: "RepeatingAvailableInterval";
  from: string;
  to: string;
  id: string;
  weekDay: Day;
  weekType: WeekType;
}

export interface RoomsPage_Rooms_rooms_meetingFormats {
  __typename: "MeetingFormat";
  id: string;
  name: string;
}

export interface RoomsPage_Rooms_rooms_equipmentToRoom_equipment_category {
  __typename: "EquipmentCategory";
  name: string;
}

export interface RoomsPage_Rooms_rooms_equipmentToRoom_equipment {
  __typename: "Equipment";
  category: RoomsPage_Rooms_rooms_equipmentToRoom_equipment_category;
}

export interface RoomsPage_Rooms_rooms_equipmentToRoom {
  __typename: "EquipmentToRoom";
  roomId: string;
  equipmentId: string;
  equipment: RoomsPage_Rooms_rooms_equipmentToRoom_equipment | null;
  countEquipments: number;
}

export interface RoomsPage_Rooms_rooms {
  __typename: "Room";
  id: string;
  isAvailable: boolean;
  location: RoomsPage_Rooms_rooms_location;
  availableIntervals: RoomsPage_Rooms_rooms_availableIntervals[];
  repeatingAvailableIntervals: RoomsPage_Rooms_rooms_repeatingAvailableIntervals[];
  meetingFormats: RoomsPage_Rooms_rooms_meetingFormats[];
  title: string;
  capacity: number;
  equipmentToRoom: RoomsPage_Rooms_rooms_equipmentToRoom[];
}

export interface RoomsPage_Rooms {
  rooms: RoomsPage_Rooms_rooms[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteRoom
// ====================================================

export interface DeleteRoom_removeRoom {
  __typename: "RemoveStatus";
  status: boolean | null;
}

export interface DeleteRoom {
  removeRoom: DeleteRoom_removeRoom | null;
}

export interface DeleteRoomVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateRoom
// ====================================================

export interface UpdateRoom_updateRoom_availableIntervals {
  __typename: "RoomAvailableInterval";
  from: any;
  to: any;
  id: string;
}

export interface UpdateRoom_updateRoom {
  __typename: "Room";
  id: string;
  availableIntervals: UpdateRoom_updateRoom_availableIntervals[];
}

export interface UpdateRoom {
  updateRoom: UpdateRoom_updateRoom;
}

export interface UpdateRoomVariables {
  editRoomData: UpdateRoomInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateRoom
// ====================================================

export interface CreateRoom_createRoom {
  __typename: "Room";
  id: string;
}

export interface CreateRoom {
  createRoom: CreateRoom_createRoom;
}

export interface CreateRoomVariables {
  createRoomData: CreateRoomInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: MeetingFormats
// ====================================================

export interface MeetingFormats_meetingFormats {
  __typename: "MeetingFormat";
  id: string;
  name: string;
}

export interface MeetingFormats {
  meetingFormats: MeetingFormats_meetingFormats[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetEquipmentsOnRoomPage
// ====================================================

export interface GetEquipmentsOnRoomPage_equipment_list_category {
  __typename: "EquipmentCategory";
  id: string;
  name: string;
}

export interface GetEquipmentsOnRoomPage_equipment_list_movability {
  __typename: "EquipmentMovability";
  isMovable: boolean;
  count: number;
}

export interface GetEquipmentsOnRoomPage_equipment_list_rooms {
  __typename: "EquipmentToRoom";
  countEquipments: number;
}

export interface GetEquipmentsOnRoomPage_equipment_list {
  __typename: "Equipment";
  id: string;
  category: GetEquipmentsOnRoomPage_equipment_list_category;
  movability: GetEquipmentsOnRoomPage_equipment_list_movability[];
  rooms: GetEquipmentsOnRoomPage_equipment_list_rooms[];
}

export interface GetEquipmentsOnRoomPage_equipment {
  __typename: "EquipmentResponse";
  list: GetEquipmentsOnRoomPage_equipment_list[];
}

export interface GetEquipmentsOnRoomPage {
  equipment: GetEquipmentsOnRoomPage_equipment;
}

export interface GetEquipmentsOnRoomPageVariables {
  filterParams: EquipmentFilterParams;
  paginationParams: EquipmentPaginationParams;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: RoomsPageGetUniversityTimeZone
// ====================================================

export interface RoomsPageGetUniversityTimeZone {
  universityTimezone: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetBTIByIds
// ====================================================

export interface GetBTIByIds_baseTimeIntervalInstancesByIds {
  __typename: "BaseTimeIntervalInstance";
  longName: string;
  startDate: string;
  endDate: string;
}

export interface GetBTIByIds {
  baseTimeIntervalInstancesByIds: GetBTIByIds_baseTimeIntervalInstancesByIds[];
}

export interface GetBTIByIdsVariables {
  ids: string[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ValidateScheduleSchedulePage
// ====================================================

export interface ValidateScheduleSchedulePage_validateSchedule_errors_object {
  __typename: "ScheduleValidationReportItemObject";
  moduleId: string | null;
  moduleName: string | null;
  meetingId: string | null;
  meetingName: string | null;
  module2Id: string | null;
  module2Name: string | null;
  meeting2Id: string | null;
  meeting2Name: string | null;
  studentId: string | null;
  teacherId: string | null;
  teacherName: string | null;
  teacherRoleId: string | null;
  teacherRoleName: string | null;
  equipmentCategoryAndFeaturesNames: string | null;
  meetingFormatId: string | null;
  meetingFormatName: string | null;
  btiInstanceId: string | null;
  btiInstanceName: string | null;
}

export interface ValidateScheduleSchedulePage_validateSchedule_errors {
  __typename: "ScheduleValidationReportItem";
  message: string;
  subject: string;
  errorTypeFilter: ScheduleValidationReportItemErrorTypeFilter;
  object: ValidateScheduleSchedulePage_validateSchedule_errors_object | null;
}

export interface ValidateScheduleSchedulePage_validateSchedule_warnings_object {
  __typename: "ScheduleValidationReportItemObject";
  moduleId: string | null;
  moduleName: string | null;
  meetingId: string | null;
  meetingName: string | null;
  module2Id: string | null;
  module2Name: string | null;
  meeting2Id: string | null;
  meeting2Name: string | null;
  studentId: string | null;
  teacherId: string | null;
  teacherName: string | null;
  teacherRoleId: string | null;
  teacherRoleName: string | null;
  equipmentCategoryAndFeaturesNames: string | null;
  meetingFormatId: string | null;
  meetingFormatName: string | null;
  btiInstanceId: string | null;
  btiInstanceName: string | null;
}

export interface ValidateScheduleSchedulePage_validateSchedule_warnings {
  __typename: "ScheduleValidationReportItem";
  message: string;
  subject: string;
  errorTypeFilter: ScheduleValidationReportItemErrorTypeFilter;
  object: ValidateScheduleSchedulePage_validateSchedule_warnings_object | null;
}

export interface ValidateScheduleSchedulePage_validateSchedule {
  __typename: "ScheduleValidationReport";
  errors: ValidateScheduleSchedulePage_validateSchedule_errors[];
  warnings: ValidateScheduleSchedulePage_validateSchedule_warnings[];
}

export interface ValidateScheduleSchedulePage {
  validateSchedule: ValidateScheduleSchedulePage_validateSchedule;
}

export interface ValidateScheduleSchedulePageVariables {
  validateScheduleInput: ValidateScheduleInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetSchedulePagesCountSchedulePage
// ====================================================

export interface GetSchedulePagesCountSchedulePage {
  countOfPageForFilteredSchedule: number;
}

export interface GetSchedulePagesCountSchedulePageVariables {
  scheduleFilterInput: ScheduleFilterInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: FilteredScheduleSchedulePage
// ====================================================

export interface FilteredScheduleSchedulePage_filteredSchedule_events_module {
  __typename: "Module";
  id: string;
  name: string | null;
}

export interface FilteredScheduleSchedulePage_filteredSchedule_events_meeting_format {
  __typename: "MeetingFormat";
  name: string;
}

export interface FilteredScheduleSchedulePage_filteredSchedule_events_meeting_duration {
  __typename: "MeetingDuration";
  academicHours: number;
}

export interface FilteredScheduleSchedulePage_filteredSchedule_events_meeting {
  __typename: "Meeting";
  id: string;
  topic: string | null;
  format: FilteredScheduleSchedulePage_filteredSchedule_events_meeting_format | null;
  duration: FilteredScheduleSchedulePage_filteredSchedule_events_meeting_duration | null;
}

export interface FilteredScheduleSchedulePage_filteredSchedule_events_assignment {
  __typename: "Assignment";
  id: string;
  topic: string | null;
}

export interface FilteredScheduleSchedulePage_filteredSchedule_events_evaluationPoint {
  __typename: "EvaluationPoint";
  id: string;
  topic: string | null;
}

export interface FilteredScheduleSchedulePage_filteredSchedule_events_room_location {
  __typename: "Building";
  location: string;
}

export interface FilteredScheduleSchedulePage_filteredSchedule_events_room_equipmentToRoom_equipment_category {
  __typename: "EquipmentCategory";
  name: string;
}

export interface FilteredScheduleSchedulePage_filteredSchedule_events_room_equipmentToRoom_equipment {
  __typename: "Equipment";
  id: string;
  category: FilteredScheduleSchedulePage_filteredSchedule_events_room_equipmentToRoom_equipment_category;
}

export interface FilteredScheduleSchedulePage_filteredSchedule_events_room_equipmentToRoom {
  __typename: "EquipmentToRoom";
  equipmentId: string;
  roomId: string;
  countEquipments: number;
  equipment: FilteredScheduleSchedulePage_filteredSchedule_events_room_equipmentToRoom_equipment | null;
}

export interface FilteredScheduleSchedulePage_filteredSchedule_events_room {
  __typename: "Room";
  id: string;
  title: string;
  location: FilteredScheduleSchedulePage_filteredSchedule_events_room_location;
  equipmentToRoom: FilteredScheduleSchedulePage_filteredSchedule_events_room_equipmentToRoom[];
}

export interface FilteredScheduleSchedulePage_filteredSchedule_events_onlineRoom {
  __typename: "OnlineRoom";
  id: string;
  url: string;
}

export interface FilteredScheduleSchedulePage_filteredSchedule_events_students_user {
  __typename: "User";
  id: string;
  lastName: string;
  firstName: string;
  patronymic: string | null;
}

export interface FilteredScheduleSchedulePage_filteredSchedule_events_students {
  __typename: "Student";
  id: string;
  user: FilteredScheduleSchedulePage_filteredSchedule_events_students_user;
}

export interface FilteredScheduleSchedulePage_filteredSchedule_events_teachers_user {
  __typename: "User";
  id: string;
  lastName: string;
  firstName: string;
  patronymic: string | null;
}

export interface FilteredScheduleSchedulePage_filteredSchedule_events_teachers {
  __typename: "Teacher";
  id: string;
  user: FilteredScheduleSchedulePage_filteredSchedule_events_teachers_user;
}

export interface FilteredScheduleSchedulePage_filteredSchedule_events_waves {
  __typename: "Wave";
  id: string;
  index: number | null;
}

export interface FilteredScheduleSchedulePage_filteredSchedule_events {
  __typename: "TmpEventInstance";
  id: string;
  type: string;
  pinned: boolean;
  startTime: string | null;
  endTime: string | null;
  status: InstanceStatus | null;
  module: FilteredScheduleSchedulePage_filteredSchedule_events_module | null;
  meeting: FilteredScheduleSchedulePage_filteredSchedule_events_meeting | null;
  assignment: FilteredScheduleSchedulePage_filteredSchedule_events_assignment | null;
  evaluationPoint: FilteredScheduleSchedulePage_filteredSchedule_events_evaluationPoint | null;
  room: FilteredScheduleSchedulePage_filteredSchedule_events_room | null;
  roomType: RoomType | null;
  onlineRoom: FilteredScheduleSchedulePage_filteredSchedule_events_onlineRoom | null;
  students: (FilteredScheduleSchedulePage_filteredSchedule_events_students | null)[] | null;
  teachers: FilteredScheduleSchedulePage_filteredSchedule_events_teachers[] | null;
  waves: FilteredScheduleSchedulePage_filteredSchedule_events_waves[] | null;
  scheduleGenerationProcessId: string | null;
}

export interface FilteredScheduleSchedulePage_filteredSchedule {
  __typename: "Schedule";
  status: string;
  events: FilteredScheduleSchedulePage_filteredSchedule_events[];
}

export interface FilteredScheduleSchedulePage {
  filteredSchedule: FilteredScheduleSchedulePage_filteredSchedule;
}

export interface FilteredScheduleSchedulePageVariables {
  scheduleFilterInput: ScheduleFilterInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: FilteredTableScheduleSchedulePage
// ====================================================

export interface FilteredTableScheduleSchedulePage_filteredSchedule_events_module {
  __typename: "Module";
  id: string;
  name: string | null;
}

export interface FilteredTableScheduleSchedulePage_filteredSchedule_events_meeting_format {
  __typename: "MeetingFormat";
  name: string;
}

export interface FilteredTableScheduleSchedulePage_filteredSchedule_events_meeting_duration {
  __typename: "MeetingDuration";
  academicHours: number;
}

export interface FilteredTableScheduleSchedulePage_filteredSchedule_events_meeting {
  __typename: "Meeting";
  id: string;
  topic: string | null;
  format: FilteredTableScheduleSchedulePage_filteredSchedule_events_meeting_format | null;
  duration: FilteredTableScheduleSchedulePage_filteredSchedule_events_meeting_duration | null;
}

export interface FilteredTableScheduleSchedulePage_filteredSchedule_events_waves {
  __typename: "Wave";
  id: string;
  index: number | null;
}

export interface FilteredTableScheduleSchedulePage_filteredSchedule_events {
  __typename: "TmpEventInstance";
  id: string;
  type: string;
  pinned: boolean;
  status: InstanceStatus | null;
  startTime: string | null;
  endTime: string | null;
  module: FilteredTableScheduleSchedulePage_filteredSchedule_events_module | null;
  meeting: FilteredTableScheduleSchedulePage_filteredSchedule_events_meeting | null;
  waves: FilteredTableScheduleSchedulePage_filteredSchedule_events_waves[] | null;
  scheduleGenerationProcessId: string | null;
}

export interface FilteredTableScheduleSchedulePage_filteredSchedule {
  __typename: "Schedule";
  events: FilteredTableScheduleSchedulePage_filteredSchedule_events[];
}

export interface FilteredTableScheduleSchedulePage {
  filteredSchedule: FilteredTableScheduleSchedulePage_filteredSchedule;
}

export interface FilteredTableScheduleSchedulePageVariables {
  scheduleFilterInput: ScheduleFilterInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetAvailableTeachersSchedulePage
// ====================================================

export interface GetAvailableTeachersSchedulePage_availableTeachers_user {
  __typename: "User";
  id: string;
  lastName: string;
  firstName: string;
  patronymic: string | null;
  teacherId: string | null;
  studentId: string | null;
  status: string;
}

export interface GetAvailableTeachersSchedulePage_availableTeachers {
  __typename: "Teacher";
  id: string;
  status: string;
  user: GetAvailableTeachersSchedulePage_availableTeachers_user;
}

export interface GetAvailableTeachersSchedulePage {
  availableTeachers: GetAvailableTeachersSchedulePage_availableTeachers[];
}

export interface GetAvailableTeachersSchedulePageVariables {
  availableTeacherInput: AvailableTeacherInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetDurationsSchedulePage
// ====================================================

export interface GetDurationsSchedulePage_durations {
  __typename: "MeetingDuration";
  id: string;
  name: string | null;
  academicHours: number;
}

export interface GetDurationsSchedulePage {
  durations: GetDurationsSchedulePage_durations[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: StudentsAttendanceSchedulePage
// ====================================================

export interface StudentsAttendanceSchedulePage {
  attendanceReport: string | null;
}

export interface StudentsAttendanceSchedulePageVariables {
  input: AttendanceInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetAvailableRoomsSchedulePage
// ====================================================

export interface GetAvailableRoomsSchedulePage_availableRooms_location {
  __typename: "Building";
  location: string;
}

export interface GetAvailableRoomsSchedulePage_availableRooms {
  __typename: "Room";
  id: string;
  title: string;
  capacity: number;
  location: GetAvailableRoomsSchedulePage_availableRooms_location;
}

export interface GetAvailableRoomsSchedulePage {
  availableRooms: GetAvailableRoomsSchedulePage_availableRooms[];
}

export interface GetAvailableRoomsSchedulePageVariables {
  availableRoomInput: AvailableRoomInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetAvailableOnlineRoomsSchedulePage
// ====================================================

export interface GetAvailableOnlineRoomsSchedulePage_availableOnlineRooms {
  __typename: "OnlineRoom";
  id: string;
  url: string;
}

export interface GetAvailableOnlineRoomsSchedulePage {
  availableOnlineRooms: GetAvailableOnlineRoomsSchedulePage_availableOnlineRooms[];
}

export interface GetAvailableOnlineRoomsSchedulePageVariables {
  input: AvailableOnlineRoomInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CheckScheduleSchedulePage
// ====================================================

export interface CheckScheduleSchedulePage_checkScheduleConflicts_baseTimeIntervalInstancesForProcess {
  __typename: "BaseTimeIntervalInstance";
  id: string;
  longName: string;
}

export interface CheckScheduleSchedulePage_checkScheduleConflicts_meetingViolations_conflicts_meetingInstances_meeting_format {
  __typename: "MeetingFormat";
  name: string;
}

export interface CheckScheduleSchedulePage_checkScheduleConflicts_meetingViolations_conflicts_meetingInstances_meeting_module {
  __typename: "Module";
  name: string | null;
}

export interface CheckScheduleSchedulePage_checkScheduleConflicts_meetingViolations_conflicts_meetingInstances_meeting {
  __typename: "Meeting";
  topic: string | null;
  format: CheckScheduleSchedulePage_checkScheduleConflicts_meetingViolations_conflicts_meetingInstances_meeting_format | null;
  module: CheckScheduleSchedulePage_checkScheduleConflicts_meetingViolations_conflicts_meetingInstances_meeting_module;
}

export interface CheckScheduleSchedulePage_checkScheduleConflicts_meetingViolations_conflicts_meetingInstances_meetingInstanceToTeachers_teacher_user {
  __typename: "User";
  firstName: string;
  lastName: string;
  patronymic: string | null;
}

export interface CheckScheduleSchedulePage_checkScheduleConflicts_meetingViolations_conflicts_meetingInstances_meetingInstanceToTeachers_teacher {
  __typename: "Teacher";
  user: CheckScheduleSchedulePage_checkScheduleConflicts_meetingViolations_conflicts_meetingInstances_meetingInstanceToTeachers_teacher_user;
}

export interface CheckScheduleSchedulePage_checkScheduleConflicts_meetingViolations_conflicts_meetingInstances_meetingInstanceToTeachers {
  __typename: "MeetingInstanceTeacher";
  teacher: CheckScheduleSchedulePage_checkScheduleConflicts_meetingViolations_conflicts_meetingInstances_meetingInstanceToTeachers_teacher;
}

export interface CheckScheduleSchedulePage_checkScheduleConflicts_meetingViolations_conflicts_meetingInstances_room {
  __typename: "Room";
  id: string;
  title: string;
}

export interface CheckScheduleSchedulePage_checkScheduleConflicts_meetingViolations_conflicts_meetingInstances_onlineRoom {
  __typename: "OnlineRoom";
  id: string;
  url: string;
}

export interface CheckScheduleSchedulePage_checkScheduleConflicts_meetingViolations_conflicts_meetingInstances_waves {
  __typename: "Wave";
  index: number | null;
}

export interface CheckScheduleSchedulePage_checkScheduleConflicts_meetingViolations_conflicts_meetingInstances {
  __typename: "MeetingInstance";
  id: string;
  meeting: CheckScheduleSchedulePage_checkScheduleConflicts_meetingViolations_conflicts_meetingInstances_meeting;
  meetingInstanceToTeachers: CheckScheduleSchedulePage_checkScheduleConflicts_meetingViolations_conflicts_meetingInstances_meetingInstanceToTeachers[];
  room: CheckScheduleSchedulePage_checkScheduleConflicts_meetingViolations_conflicts_meetingInstances_room | null;
  onlineRoom: CheckScheduleSchedulePage_checkScheduleConflicts_meetingViolations_conflicts_meetingInstances_onlineRoom | null;
  roomType: RoomType;
  waves: CheckScheduleSchedulePage_checkScheduleConflicts_meetingViolations_conflicts_meetingInstances_waves[];
  startDate: any;
  endDate: any;
}

export interface CheckScheduleSchedulePage_checkScheduleConflicts_meetingViolations_conflicts {
  __typename: "ScheduleMeetingViolationItem";
  baseTimeIntervalInstancesIds: string[];
  meetingInstances: CheckScheduleSchedulePage_checkScheduleConflicts_meetingViolations_conflicts_meetingInstances[];
}

export interface CheckScheduleSchedulePage_checkScheduleConflicts_meetingViolations {
  __typename: "ScheduleMeetingViolation";
  violationName: string;
  conflicts: CheckScheduleSchedulePage_checkScheduleConflicts_meetingViolations_conflicts[];
}

export interface CheckScheduleSchedulePage_checkScheduleConflicts_studentMeetingLimitDurationViolation_studentDurationLimitsByDate_studentDurationLimits_student_user {
  __typename: "User";
  id: string;
  lastName: string;
  firstName: string;
  patronymic: string | null;
}

export interface CheckScheduleSchedulePage_checkScheduleConflicts_studentMeetingLimitDurationViolation_studentDurationLimitsByDate_studentDurationLimits_student {
  __typename: "Student";
  id: string;
  user: CheckScheduleSchedulePage_checkScheduleConflicts_studentMeetingLimitDurationViolation_studentDurationLimitsByDate_studentDurationLimits_student_user;
}

export interface CheckScheduleSchedulePage_checkScheduleConflicts_studentMeetingLimitDurationViolation_studentDurationLimitsByDate_studentDurationLimits {
  __typename: "ScheduleStudentDurationLimit";
  baseTimeIntervalInstanceId: string;
  extraDurationMinutes: number;
  student: CheckScheduleSchedulePage_checkScheduleConflicts_studentMeetingLimitDurationViolation_studentDurationLimitsByDate_studentDurationLimits_student;
}

export interface CheckScheduleSchedulePage_checkScheduleConflicts_studentMeetingLimitDurationViolation_studentDurationLimitsByDate {
  __typename: "ScheduleStudentDurationLimitByDate";
  date: string;
  studentDurationLimits: CheckScheduleSchedulePage_checkScheduleConflicts_studentMeetingLimitDurationViolation_studentDurationLimitsByDate_studentDurationLimits[];
}

export interface CheckScheduleSchedulePage_checkScheduleConflicts_studentMeetingLimitDurationViolation {
  __typename: "ScheduleStudentDurationLimitViolation";
  violationName: string;
  studentDurationLimitsByDate: CheckScheduleSchedulePage_checkScheduleConflicts_studentMeetingLimitDurationViolation_studentDurationLimitsByDate[];
}

export interface CheckScheduleSchedulePage_checkScheduleConflicts_studentBreakLimitDurationViolation_studentDurationLimitsByDate_studentDurationLimits_student_user {
  __typename: "User";
  id: string;
  lastName: string;
  firstName: string;
  patronymic: string | null;
}

export interface CheckScheduleSchedulePage_checkScheduleConflicts_studentBreakLimitDurationViolation_studentDurationLimitsByDate_studentDurationLimits_student {
  __typename: "Student";
  id: string;
  user: CheckScheduleSchedulePage_checkScheduleConflicts_studentBreakLimitDurationViolation_studentDurationLimitsByDate_studentDurationLimits_student_user;
}

export interface CheckScheduleSchedulePage_checkScheduleConflicts_studentBreakLimitDurationViolation_studentDurationLimitsByDate_studentDurationLimits {
  __typename: "ScheduleStudentDurationLimit";
  baseTimeIntervalInstanceId: string;
  extraDurationMinutes: number;
  student: CheckScheduleSchedulePage_checkScheduleConflicts_studentBreakLimitDurationViolation_studentDurationLimitsByDate_studentDurationLimits_student;
}

export interface CheckScheduleSchedulePage_checkScheduleConflicts_studentBreakLimitDurationViolation_studentDurationLimitsByDate {
  __typename: "ScheduleStudentDurationLimitByDate";
  date: string;
  studentDurationLimits: CheckScheduleSchedulePage_checkScheduleConflicts_studentBreakLimitDurationViolation_studentDurationLimitsByDate_studentDurationLimits[];
}

export interface CheckScheduleSchedulePage_checkScheduleConflicts_studentBreakLimitDurationViolation {
  __typename: "ScheduleStudentDurationLimitViolation";
  violationName: string;
  studentDurationLimitsByDate: CheckScheduleSchedulePage_checkScheduleConflicts_studentBreakLimitDurationViolation_studentDurationLimitsByDate[];
}

export interface CheckScheduleSchedulePage_checkScheduleConflicts_equipmentShortageViolation_equipmentShortagesByDate_equipmentShortages_equipment_category {
  __typename: "EquipmentCategory";
  name: string;
}

export interface CheckScheduleSchedulePage_checkScheduleConflicts_equipmentShortageViolation_equipmentShortagesByDate_equipmentShortages_equipment {
  __typename: "Equipment";
  id: string;
  category: CheckScheduleSchedulePage_checkScheduleConflicts_equipmentShortageViolation_equipmentShortagesByDate_equipmentShortages_equipment_category;
}

export interface CheckScheduleSchedulePage_checkScheduleConflicts_equipmentShortageViolation_equipmentShortagesByDate_equipmentShortages {
  __typename: "ScheduleEquipmentShortage";
  count: number;
  equipment: CheckScheduleSchedulePage_checkScheduleConflicts_equipmentShortageViolation_equipmentShortagesByDate_equipmentShortages_equipment;
}

export interface CheckScheduleSchedulePage_checkScheduleConflicts_equipmentShortageViolation_equipmentShortagesByDate {
  __typename: "ScheduleEquipmentShortageByDate";
  date: string;
  startTime: string;
  equipmentShortages: CheckScheduleSchedulePage_checkScheduleConflicts_equipmentShortageViolation_equipmentShortagesByDate_equipmentShortages[];
}

export interface CheckScheduleSchedulePage_checkScheduleConflicts_equipmentShortageViolation {
  __typename: "ScheduleEquipmentShortageViolation";
  violationName: string;
  equipmentShortagesByDate: CheckScheduleSchedulePage_checkScheduleConflicts_equipmentShortageViolation_equipmentShortagesByDate[];
}

export interface CheckScheduleSchedulePage_checkScheduleConflicts_teacherWeekMeetingsDurationLimitViolations_conflictsByWeek_conflicts_teacher_user {
  __typename: "User";
  id: string;
  firstName: string;
  lastName: string;
  patronymic: string | null;
}

export interface CheckScheduleSchedulePage_checkScheduleConflicts_teacherWeekMeetingsDurationLimitViolations_conflictsByWeek_conflicts_teacher {
  __typename: "Teacher";
  id: string;
  user: CheckScheduleSchedulePage_checkScheduleConflicts_teacherWeekMeetingsDurationLimitViolations_conflictsByWeek_conflicts_teacher_user;
}

export interface CheckScheduleSchedulePage_checkScheduleConflicts_teacherWeekMeetingsDurationLimitViolations_conflictsByWeek_conflicts {
  __typename: "ScheduleTeacherWeekMeetingsDurationLimitViolation";
  extraDurationMinutes: number;
  teacher: CheckScheduleSchedulePage_checkScheduleConflicts_teacherWeekMeetingsDurationLimitViolations_conflictsByWeek_conflicts_teacher;
}

export interface CheckScheduleSchedulePage_checkScheduleConflicts_teacherWeekMeetingsDurationLimitViolations_conflictsByWeek {
  __typename: "ScheduleTeacherWeekMeetingsDurationLimitViolationsByWeek";
  week: number;
  conflicts: CheckScheduleSchedulePage_checkScheduleConflicts_teacherWeekMeetingsDurationLimitViolations_conflictsByWeek_conflicts[];
}

export interface CheckScheduleSchedulePage_checkScheduleConflicts_teacherWeekMeetingsDurationLimitViolations {
  __typename: "ScheduleTeacherWeekMeetingsDurationLimitViolations";
  violationName: string;
  conflictsByWeek: CheckScheduleSchedulePage_checkScheduleConflicts_teacherWeekMeetingsDurationLimitViolations_conflictsByWeek[];
}

export interface CheckScheduleSchedulePage_checkScheduleConflicts {
  __typename: "ScheduleConflictExplanation";
  fromDate: string;
  toDate: string | null;
  isSuccess: boolean;
  baseTimeIntervalInstancesForProcess: CheckScheduleSchedulePage_checkScheduleConflicts_baseTimeIntervalInstancesForProcess[];
  meetingViolations: CheckScheduleSchedulePage_checkScheduleConflicts_meetingViolations[];
  studentMeetingLimitDurationViolation: CheckScheduleSchedulePage_checkScheduleConflicts_studentMeetingLimitDurationViolation | null;
  studentBreakLimitDurationViolation: CheckScheduleSchedulePage_checkScheduleConflicts_studentBreakLimitDurationViolation | null;
  equipmentShortageViolation: CheckScheduleSchedulePage_checkScheduleConflicts_equipmentShortageViolation | null;
  teacherWeekMeetingsDurationLimitViolations: CheckScheduleSchedulePage_checkScheduleConflicts_teacherWeekMeetingsDurationLimitViolations | null;
}

export interface CheckScheduleSchedulePage {
  checkScheduleConflicts: CheckScheduleSchedulePage_checkScheduleConflicts;
}

export interface CheckScheduleSchedulePageVariables {
  scheduleScoreInput: CheckScheduleConflictsInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateTeacherSchedulePage
// ====================================================

export interface UpdateTeacherSchedulePage_updateMeetingInstanceTeacher {
  __typename: "MeetingInstance";
  id: string;
}

export interface UpdateTeacherSchedulePage {
  updateMeetingInstanceTeacher: UpdateTeacherSchedulePage_updateMeetingInstanceTeacher;
}

export interface UpdateTeacherSchedulePageVariables {
  updateMeetingInstanceTeacherInput: UpdateMeetingInstanceTeacherInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateMeetingInstanceRoomSchedulePage
// ====================================================

export interface UpdateMeetingInstanceRoomSchedulePage_updateMeetingInstanceRoom {
  __typename: "MeetingInstance";
  id: string;
}

export interface UpdateMeetingInstanceRoomSchedulePage {
  updateMeetingInstanceRoom: UpdateMeetingInstanceRoomSchedulePage_updateMeetingInstanceRoom;
}

export interface UpdateMeetingInstanceRoomSchedulePageVariables {
  updateMeetingInstanceRoomInput: UpdateMeetingInstanceRoomInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateMeetingInstanceDurationSchedulePage
// ====================================================

export interface UpdateMeetingInstanceDurationSchedulePage_updateMeetingInstanceDuration {
  __typename: "MeetingInstance";
  id: string;
  startDate: any;
  endDate: any;
}

export interface UpdateMeetingInstanceDurationSchedulePage {
  updateMeetingInstanceDuration: UpdateMeetingInstanceDurationSchedulePage_updateMeetingInstanceDuration;
}

export interface UpdateMeetingInstanceDurationSchedulePageVariables {
  updateMeetingInstanceDurationInput?: UpdateMeetingInstanceDurationInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ScheduleGenerationConfigForProcessSchedulePage
// ====================================================

export interface ScheduleGenerationConfigForProcessSchedulePage {
  scheduleGenerationConfigForProcess: any;
}

export interface ScheduleGenerationConfigForProcessSchedulePageVariables {
  scheduleGenerationConfigForProcessInput: ScheduleGenerationConfigForProcessInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetScheduleGenerationConfigSchedulePage
// ====================================================

export interface GetScheduleGenerationConfigSchedulePage {
  getScheduleGenerationConfig: any;
}

export interface GetScheduleGenerationConfigSchedulePageVariables {
  baseTimeIntervalInstanceIds: string[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ReplaceMeetingSchedulePage
// ====================================================

export interface ReplaceMeetingSchedulePage_replaceMeeting {
  __typename: "MeetingInstance";
  id: string;
}

export interface ReplaceMeetingSchedulePage {
  replaceMeeting: ReplaceMeetingSchedulePage_replaceMeeting;
}

export interface ReplaceMeetingSchedulePageVariables {
  replaceMeetingInput?: ReplaceMeetingInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateMeetingInstanceDateSchedulePage
// ====================================================

export interface UpdateMeetingInstanceDateSchedulePage_updateMeetingInstanceDate {
  __typename: "MeetingInstance";
  id: string;
  startDate: any;
}

export interface UpdateMeetingInstanceDateSchedulePage {
  updateMeetingInstanceDate: UpdateMeetingInstanceDateSchedulePage_updateMeetingInstanceDate;
}

export interface UpdateMeetingInstanceDateSchedulePageVariables {
  updateMeetingInstanceDateInput: UpdateMeetingInstanceDateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateMeetingInstanceWavesSchedulePage
// ====================================================

export interface UpdateMeetingInstanceWavesSchedulePage_updateMeetingInstanceWaves {
  __typename: "MeetingInstance";
  id: string;
}

export interface UpdateMeetingInstanceWavesSchedulePage {
  updateMeetingInstanceWaves: UpdateMeetingInstanceWavesSchedulePage_updateMeetingInstanceWaves[];
}

export interface UpdateMeetingInstanceWavesSchedulePageVariables {
  updateMeetingInstanceWavesInput: UpdateMeetingInstanceWavesInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ChangeMeetingInstanceStatusSchedulePage
// ====================================================

export interface ChangeMeetingInstanceStatusSchedulePage_changeMeetingInstanceStatus {
  __typename: "MeetingInstance";
  id: string;
}

export interface ChangeMeetingInstanceStatusSchedulePage {
  changeMeetingInstanceStatus: ChangeMeetingInstanceStatusSchedulePage_changeMeetingInstanceStatus | null;
}

export interface ChangeMeetingInstanceStatusSchedulePageVariables {
  changeMeetingInstanceStatusInput: ChangeMeetingInstanceStatusInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateAssignmentInstanceSchedulePage
// ====================================================

export interface UpdateAssignmentInstanceSchedulePage_updateAssignmentInstance {
  __typename: "AssignmentInstance";
  id: string;
}

export interface UpdateAssignmentInstanceSchedulePage {
  updateAssignmentInstance: UpdateAssignmentInstanceSchedulePage_updateAssignmentInstance | null;
}

export interface UpdateAssignmentInstanceSchedulePageVariables {
  updateAssignmentInstanceInput: UpdateAssignmentInstanceInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: PinMeetingInstanceSchedulePage
// ====================================================

export interface PinMeetingInstanceSchedulePage_pinMeetingInstance {
  __typename: "MeetingInstance";
  id: string;
}

export interface PinMeetingInstanceSchedulePage {
  pinMeetingInstance: PinMeetingInstanceSchedulePage_pinMeetingInstance;
}

export interface PinMeetingInstanceSchedulePageVariables {
  id: string;
  pin: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateEvaluationPointInstanceSchedulePage
// ====================================================

export interface UpdateEvaluationPointInstanceSchedulePage_updateEvaluationPointInstance {
  __typename: "EvaluationPointInstance";
  id: string;
}

export interface UpdateEvaluationPointInstanceSchedulePage {
  updateEvaluationPointInstance: UpdateEvaluationPointInstanceSchedulePage_updateEvaluationPointInstance;
}

export interface UpdateEvaluationPointInstanceSchedulePageVariables {
  updateEvaluationPointInstanceInput: UpdateEvaluationPointInstanceInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ChangeAssignmentInstanceStatusSchedulePage
// ====================================================

export interface ChangeAssignmentInstanceStatusSchedulePage_changeAssignmentInstanceStatus {
  __typename: "AssignmentInstance";
  id: string;
}

export interface ChangeAssignmentInstanceStatusSchedulePage {
  changeAssignmentInstanceStatus: ChangeAssignmentInstanceStatusSchedulePage_changeAssignmentInstanceStatus | null;
}

export interface ChangeAssignmentInstanceStatusSchedulePageVariables {
  changeAssignmentInstanceStatusInput: ChangeAssignmentInstanceStatusInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ChangeEvaluationPointInstanceStatusSchedulePage
// ====================================================

export interface ChangeEvaluationPointInstanceStatusSchedulePage_changeEvaluationPointInstanceStatus {
  __typename: "EvaluationPointInstance";
  id: string;
}

export interface ChangeEvaluationPointInstanceStatusSchedulePage {
  changeEvaluationPointInstanceStatus: ChangeEvaluationPointInstanceStatusSchedulePage_changeEvaluationPointInstanceStatus | null;
}

export interface ChangeEvaluationPointInstanceStatusSchedulePageVariables {
  changeEvaluationPointInstanceStatusInput: ChangeEvaluationPointInstanceStatusInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetEventDataSchedulePage
// ====================================================

export interface GetEventDataSchedulePage_TMPMeetingInstanceExcludingStatus_module {
  __typename: "Module";
  id: string;
  name: string | null;
}

export interface GetEventDataSchedulePage_TMPMeetingInstanceExcludingStatus_waves_baseTimeIntervalInstances {
  __typename: "BaseTimeIntervalInstance";
  id: string;
}

export interface GetEventDataSchedulePage_TMPMeetingInstanceExcludingStatus_waves {
  __typename: "Wave";
  id: string;
  index: number | null;
  baseTimeIntervalInstances: GetEventDataSchedulePage_TMPMeetingInstanceExcludingStatus_waves_baseTimeIntervalInstances[];
}

export interface GetEventDataSchedulePage_TMPMeetingInstanceExcludingStatus_room_location {
  __typename: "Building";
  location: string;
}

export interface GetEventDataSchedulePage_TMPMeetingInstanceExcludingStatus_room {
  __typename: "Room";
  id: string;
  title: string;
  location: GetEventDataSchedulePage_TMPMeetingInstanceExcludingStatus_room_location;
}

export interface GetEventDataSchedulePage_TMPMeetingInstanceExcludingStatus_onlineRoom {
  __typename: "OnlineRoom";
  id: string;
  url: string;
}

export interface GetEventDataSchedulePage_TMPMeetingInstanceExcludingStatus_students_user {
  __typename: "User";
  id: string;
  lastName: string;
  firstName: string;
  patronymic: string | null;
}

export interface GetEventDataSchedulePage_TMPMeetingInstanceExcludingStatus_students {
  __typename: "Student";
  id: string;
  user: GetEventDataSchedulePage_TMPMeetingInstanceExcludingStatus_students_user;
}

export interface GetEventDataSchedulePage_TMPMeetingInstanceExcludingStatus_teachers_user {
  __typename: "User";
  id: string;
  firstName: string;
  lastName: string;
  patronymic: string | null;
}

export interface GetEventDataSchedulePage_TMPMeetingInstanceExcludingStatus_teachers {
  __typename: "Teacher";
  id: string;
  user: GetEventDataSchedulePage_TMPMeetingInstanceExcludingStatus_teachers_user;
}

export interface GetEventDataSchedulePage_TMPMeetingInstanceExcludingStatus_teacherToTeacherRoles_teacher {
  __typename: "Teacher";
  id: string;
}

export interface GetEventDataSchedulePage_TMPMeetingInstanceExcludingStatus_teacherToTeacherRoles_teacherRole {
  __typename: "TeacherRole";
  id: string;
}

export interface GetEventDataSchedulePage_TMPMeetingInstanceExcludingStatus_teacherToTeacherRoles {
  __typename: "TeacherToTeacherRole";
  teacher: GetEventDataSchedulePage_TMPMeetingInstanceExcludingStatus_teacherToTeacherRoles_teacher;
  teacherRole: GetEventDataSchedulePage_TMPMeetingInstanceExcludingStatus_teacherToTeacherRoles_teacherRole;
}

export interface GetEventDataSchedulePage_TMPMeetingInstanceExcludingStatus_meeting_format {
  __typename: "MeetingFormat";
  name: string;
}

export interface GetEventDataSchedulePage_TMPMeetingInstanceExcludingStatus_meeting_module_waves {
  __typename: "Wave";
  id: string;
  index: number | null;
}

export interface GetEventDataSchedulePage_TMPMeetingInstanceExcludingStatus_meeting_module {
  __typename: "Module";
  waves: GetEventDataSchedulePage_TMPMeetingInstanceExcludingStatus_meeting_module_waves[];
}

export interface GetEventDataSchedulePage_TMPMeetingInstanceExcludingStatus_meeting_duration {
  __typename: "MeetingDuration";
  academicHours: number;
}

export interface GetEventDataSchedulePage_TMPMeetingInstanceExcludingStatus_meeting_equipmentPerMeeting {
  __typename: "EquipmentPerMeeting";
  id: string;
  count: number;
}

export interface GetEventDataSchedulePage_TMPMeetingInstanceExcludingStatus_meeting_equipmentPerStudent {
  __typename: "EquipmentPerStudent";
  id: string;
  count: number;
}

export interface GetEventDataSchedulePage_TMPMeetingInstanceExcludingStatus_meeting_meetingInstances_meetingInstanceToStudents_student_user {
  __typename: "User";
  id: string;
  lastName: string;
  firstName: string;
  patronymic: string | null;
}

export interface GetEventDataSchedulePage_TMPMeetingInstanceExcludingStatus_meeting_meetingInstances_meetingInstanceToStudents_student {
  __typename: "Student";
  id: string;
  user: GetEventDataSchedulePage_TMPMeetingInstanceExcludingStatus_meeting_meetingInstances_meetingInstanceToStudents_student_user;
}

export interface GetEventDataSchedulePage_TMPMeetingInstanceExcludingStatus_meeting_meetingInstances_meetingInstanceToStudents {
  __typename: "MeetingInstanceToStudent";
  student: GetEventDataSchedulePage_TMPMeetingInstanceExcludingStatus_meeting_meetingInstances_meetingInstanceToStudents_student;
}

export interface GetEventDataSchedulePage_TMPMeetingInstanceExcludingStatus_meeting_meetingInstances {
  __typename: "MeetingInstance";
  id: string;
  startDate: any;
  endDate: any;
  meetingInstanceToStudents: GetEventDataSchedulePage_TMPMeetingInstanceExcludingStatus_meeting_meetingInstances_meetingInstanceToStudents[];
}

export interface GetEventDataSchedulePage_TMPMeetingInstanceExcludingStatus_meeting {
  __typename: "Meeting";
  id: string;
  topic: string | null;
  format: GetEventDataSchedulePage_TMPMeetingInstanceExcludingStatus_meeting_format | null;
  module: GetEventDataSchedulePage_TMPMeetingInstanceExcludingStatus_meeting_module;
  duration: GetEventDataSchedulePage_TMPMeetingInstanceExcludingStatus_meeting_duration | null;
  equipmentPerMeeting: GetEventDataSchedulePage_TMPMeetingInstanceExcludingStatus_meeting_equipmentPerMeeting[] | null;
  equipmentPerStudent: GetEventDataSchedulePage_TMPMeetingInstanceExcludingStatus_meeting_equipmentPerStudent[] | null;
  meetingInstances: GetEventDataSchedulePage_TMPMeetingInstanceExcludingStatus_meeting_meetingInstances[];
}

export interface GetEventDataSchedulePage_TMPMeetingInstanceExcludingStatus_assignment {
  __typename: "Assignment";
  id: string;
  topic: string | null;
}

export interface GetEventDataSchedulePage_TMPMeetingInstanceExcludingStatus_evaluationPoint {
  __typename: "EvaluationPoint";
  id: string;
  topic: string | null;
}

export interface GetEventDataSchedulePage_TMPMeetingInstanceExcludingStatus {
  __typename: "TmpEventInstance";
  id: string;
  type: string;
  pinned: boolean;
  status: InstanceStatus | null;
  module: GetEventDataSchedulePage_TMPMeetingInstanceExcludingStatus_module | null;
  waves: GetEventDataSchedulePage_TMPMeetingInstanceExcludingStatus_waves[] | null;
  room: GetEventDataSchedulePage_TMPMeetingInstanceExcludingStatus_room | null;
  roomType: RoomType | null;
  onlineRoom: GetEventDataSchedulePage_TMPMeetingInstanceExcludingStatus_onlineRoom | null;
  students: (GetEventDataSchedulePage_TMPMeetingInstanceExcludingStatus_students | null)[] | null;
  teachers: GetEventDataSchedulePage_TMPMeetingInstanceExcludingStatus_teachers[] | null;
  teacherToTeacherRoles: (GetEventDataSchedulePage_TMPMeetingInstanceExcludingStatus_teacherToTeacherRoles | null)[] | null;
  startTime: string | null;
  endTime: string | null;
  meeting: GetEventDataSchedulePage_TMPMeetingInstanceExcludingStatus_meeting | null;
  assignment: GetEventDataSchedulePage_TMPMeetingInstanceExcludingStatus_assignment | null;
  evaluationPoint: GetEventDataSchedulePage_TMPMeetingInstanceExcludingStatus_evaluationPoint | null;
}

export interface GetEventDataSchedulePage {
  TMPMeetingInstanceExcludingStatus: GetEventDataSchedulePage_TMPMeetingInstanceExcludingStatus;
}

export interface GetEventDataSchedulePageVariables {
  tmpMeetingInstanceInput: TMPMeetingInstanceInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetBaseTimeIntervalInstanceSchedulePage
// ====================================================

export interface GetBaseTimeIntervalInstanceSchedulePage_baseTimeIntervalInstance {
  __typename: "BaseTimeIntervalInstance";
  startDate: string;
  endDate: string;
}

export interface GetBaseTimeIntervalInstanceSchedulePage {
  baseTimeIntervalInstance: GetBaseTimeIntervalInstanceSchedulePage_baseTimeIntervalInstance;
}

export interface GetBaseTimeIntervalInstanceSchedulePageVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetBaseTimeIntervalInstancesSchedulePage
// ====================================================

export interface GetBaseTimeIntervalInstancesSchedulePage_baseTimeIntervalInstances {
  __typename: "BaseTimeIntervalInstance";
  id: string;
  longName: string;
}

export interface GetBaseTimeIntervalInstancesSchedulePage {
  baseTimeIntervalInstances: GetBaseTimeIntervalInstancesSchedulePage_baseTimeIntervalInstances[];
}

export interface GetBaseTimeIntervalInstancesSchedulePageVariables {
  input?: BaseTimeIntervalInstancesInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ScheduleGenerationProcessesSchedulePage
// ====================================================

export interface ScheduleGenerationProcessesSchedulePage_scheduleGenerationProcesses_baseTimeIntervalInstances {
  __typename: "BaseTimeIntervalInstance";
  id: string;
}

export interface ScheduleGenerationProcessesSchedulePage_scheduleGenerationProcesses_constraintMatch_constraintMatchCountList {
  __typename: "ConstraintMatchCount";
  constraintName: string;
  scoreType: ScoreType;
  matchCount: number;
}

export interface ScheduleGenerationProcessesSchedulePage_scheduleGenerationProcesses_constraintMatch {
  __typename: "ConstraintMatch";
  constraintMatchCountList: ScheduleGenerationProcessesSchedulePage_scheduleGenerationProcesses_constraintMatch_constraintMatchCountList[];
}

export interface ScheduleGenerationProcessesSchedulePage_scheduleGenerationProcesses {
  __typename: "ScheduleGenerationProcess";
  id: string;
  name: string;
  from: any;
  to: any;
  status: ScheduleGenerationProcessStatus;
  baseTimeIntervalInstances: ScheduleGenerationProcessesSchedulePage_scheduleGenerationProcesses_baseTimeIntervalInstances[];
  constraintMatch: ScheduleGenerationProcessesSchedulePage_scheduleGenerationProcesses_constraintMatch | null;
}

export interface ScheduleGenerationProcessesSchedulePage {
  scheduleGenerationProcesses: ScheduleGenerationProcessesSchedulePage_scheduleGenerationProcesses[];
}

export interface ScheduleGenerationProcessesSchedulePageVariables {
  baseTimeIntervalInstanceIds: string[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ScheduleGenerationSettingsSchedulePage
// ====================================================

export interface ScheduleGenerationSettingsSchedulePage_scheduleGenerationSettings {
  __typename: "ScheduleGenerationSettings";
  teacherMaxWeekWorkloadMinutes: number;
}

export interface ScheduleGenerationSettingsSchedulePage {
  scheduleGenerationSettings: ScheduleGenerationSettingsSchedulePage_scheduleGenerationSettings;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum Day {
  friday = "friday",
  monday = "monday",
  saturday = "saturday",
  sunday = "sunday",
  thursday = "thursday",
  tuesday = "tuesday",
  wednesday = "wednesday",
}

export enum IgnoreErrorsFlag {
  ignoreEquipments = "ignoreEquipments",
  ignoreMinMaxIntervals = "ignoreMinMaxIntervals",
  ignoreRooms = "ignoreRooms",
  ignoreStudents = "ignoreStudents",
  ignoreTeachers = "ignoreTeachers",
}

export enum InstanceStatus {
  active = "active",
  deleted = "deleted",
  generation = "generation",
}

export enum RoomType {
  indoor = "indoor",
  online = "online",
}

export enum ScheduleGenerationProcessStatus {
  correction = "correction",
  generated = "generated",
  generationFailed = "generationFailed",
  generationPhase1 = "generationPhase1",
  generationPhase2 = "generationPhase2",
  generationReady = "generationReady",
  preparing = "preparing",
  uploaded = "uploaded",
  uploading = "uploading",
}

export enum SchedulePaginationDelimiter {
  day = "day",
  month = "month",
  week = "week",
}

export enum ScheduleValidationReportItemErrorTypeFilter {
  ADDITIONAL = "ADDITIONAL",
  CONFIG = "CONFIG",
  EQUIPMENT = "EQUIPMENT",
  MODULE = "MODULE",
  MODULE_MINMAX_INTERVALS = "MODULE_MINMAX_INTERVALS",
  ROOM = "ROOM",
  STUDENT = "STUDENT",
  TEACHER = "TEACHER",
}

export enum ScoreType {
  HARD = "HARD",
  MEDIUM = "MEDIUM",
  SOFT = "SOFT",
}

export enum TypeInstance {
  assignment = "assignment",
  evaluationPoint = "evaluationPoint",
  meeting = "meeting",
}

export enum WeekType {
  even = "even",
  every = "every",
  odd = "odd",
}

export interface AttendanceInput {
  baseTimeIntervalInstanceId: string;
  fromDate?: any | null;
  moduleId?: string | null;
  toDate?: any | null;
}

export interface AvailableOnlineRoomInput {
  from: string;
  isAvailable: boolean;
  to: string;
}

export interface AvailableRoomInput {
  doesEquipmentMatch: boolean;
  equipments: EquipmentToRoomInput[];
  from: string;
  isAvailable: boolean;
  to: string;
}

export interface AvailableTeacherInput {
  from: string;
  isAvailable: boolean;
  to: string;
}

export interface BaseTimeIntervalInstancesInput {
  fromDate?: any | null;
  spaceEducationPeriodId?: string | null;
  toDate?: any | null;
}

export interface ChangeAssignmentInstanceStatusInput {
  assignmentInstanceId: string;
  newStatus: InstanceStatus;
}

export interface ChangeEvaluationPointInstanceStatusInput {
  evaluationPointInstanceId: string;
  newStatus: InstanceStatus;
}

export interface ChangeMeetingInstanceStatusInput {
  meetingInstanceId: string;
  newStatus: InstanceStatus;
}

export interface CheckScheduleConflictsInput {
  baseTimeIntervalInstanceId: string;
  fromDate: string;
  toDate?: string | null;
}

export interface CreateEquipmentInput {
  category: EquipmentCategoryInput;
  features?: EquipmentFeatureInput[] | null;
  isComputer: boolean;
  movability?: EquipmentMovabilityInput[] | null;
}

export interface CreateEquipmentToRoomInput {
  count: number;
  id: string;
}

export interface CreateRoomInput {
  availableIntervals: RoomAvailableIntervalInput[];
  buildingId: string;
  capacity: number;
  equipments: CreateEquipmentToRoomInput[];
  id: string;
  isAvailable: boolean;
  meetingFormatIds: string[];
  repeatingAvailableIntervals?: RepeatingAvailableIntervalInput[] | null;
  title: string;
}

export interface EquipmentCategoryInput {
  id?: string | null;
  name?: string | null;
}

export interface EquipmentFeatureInput {
  id?: string | null;
  name?: string | null;
}

export interface EquipmentFilterParams {
  isComputer: boolean;
  movability?: EquipmentMovabilityParam[] | null;
  namePattern?: string | null;
  roomId?: string | null;
}

export interface EquipmentMovabilityInput {
  count: number;
  isMovable: boolean;
  territorialZoneId?: string | null;
}

export interface EquipmentMovabilityParam {
  isMovable: boolean;
  territorialZoneId?: string | null;
}

export interface EquipmentPaginationParams {
  limit?: number | null;
  offset?: number | null;
}

export interface EquipmentToRoomInput {
  count: number;
  id: string;
}

export interface RepeatingAvailableIntervalInput {
  from: string;
  id: string;
  to: string;
  weekDay: Day;
  weekType: WeekType;
}

export interface ReplaceMeetingInput {
  meetingInstanceId: string;
  meetingInstanceToSwapId?: string | null;
  newMeetingId?: string | null;
}

export interface RoomAvailableIntervalInput {
  from: string;
  id: string;
  isEveryMonth?: boolean | null;
  to: string;
}

export interface RoomsInput {
  capacity?: number | null;
  ids?: string[] | null;
  meetingFormatIds?: string[] | null;
  namePattern?: string | null;
}

export interface ScheduleFilterInput {
  baseTimeIntervalInstanceId: string;
  eventPatterns?: string[] | null;
  from?: string | null;
  moduleIds?: (string | null)[] | null;
  paginationParameters?: SchedulePaginationParams | null;
  roomIds?: (string | null)[] | null;
  roomTypes?: RoomType[] | null;
  statuses?: (InstanceStatus | null)[] | null;
  studentIds?: (string | null)[] | null;
  teacherIds?: (string | null)[] | null;
  to?: string | null;
  types?: TypeInstance[] | null;
  waveIndexes?: (number | null)[] | null;
}

export interface ScheduleGenerationConfigForProcessInput {
  doMeetingsRemoval: boolean;
  generationProcessId: string;
}

export interface SchedulePaginationParams {
  delimiter: SchedulePaginationDelimiter;
  isPaginate?: boolean | null;
  page?: number | null;
}

export interface TMPMeetingInstanceInput {
  id: string;
  type: TypeInstance;
}

export interface TeacherForUpdateInstanceInput {
  teacherId: string;
  teacherRoleId: string;
}

export interface UpdateAssignmentInstanceInput {
  duration?: number | null;
  id: string;
  startDate?: any | null;
}

export interface UpdateEquipmentCategoryInput {
  id: string;
  name: string;
}

export interface UpdateEquipmentFeatureInput {
  id: string;
  name?: string | null;
}

export interface UpdateEquipmentInput {
  features?: UpdateEquipmentFeatureInput[] | null;
  id: string;
  isComputer: boolean;
  movability?: UpdateEquipmentMovabilityInput[] | null;
}

export interface UpdateEquipmentMovabilityInput {
  count: number;
  id?: string | null;
  isMovable: boolean;
  territorialZoneId?: string | null;
}

export interface UpdateEquipmentToRoomInput {
  count: number;
  id: string;
}

export interface UpdateEvaluationPointInstanceInput {
  duration?: number | null;
  id: string;
  teachers?: TeacherForUpdateInstanceInput[] | null;
}

export interface UpdateMeetingInstanceDateInput {
  meetingInstanceId: string;
  startDate: string;
}

export interface UpdateMeetingInstanceDurationInput {
  meetingInstanceId: string;
  newDuration: number;
}

export interface UpdateMeetingInstanceRoomInput {
  meetingInstanceId: string;
  roomId: string;
  roomType: RoomType;
}

export interface UpdateMeetingInstanceTeacherInput {
  currentTeacherId: string;
  meetingInstanceId: string;
  newTeacherId: string;
}

export interface UpdateMeetingInstanceWavesInput {
  meetingInstanceId: string;
  newWaveIds: string[];
}

export interface UpdateRoomInput {
  availableIntervals: RoomAvailableIntervalInput[];
  buildingId: string;
  capacity: number;
  equipments: UpdateEquipmentToRoomInput[];
  id: string;
  isAvailable: boolean;
  meetingFormatIds: string[];
  repeatingAvailableIntervals?: RepeatingAvailableIntervalInput[] | null;
  title: string;
}

export interface ValidateScheduleInput {
  generationConfig?: any | null;
  generationProcessId: string;
  ignoreFlags: IgnoreErrorsFlag[];
}

//==============================================================
// END Enums and Input Objects
//==============================================================
