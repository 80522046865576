import React from 'react';
import { IconDeprecated } from '@common/IconDeprecated';
import { ActionButton } from '@common/ActionButton';

import classes from './SkillCardWithNest.module.scss';

interface Props {
    closePortal(): void
    removeSkill(id: string): void
    skillId: string
    text: string
}

export function PortalPopUpSkillAddWithNest({
    closePortal,
    removeSkill,
    skillId,
    text,
}: Props): JSX.Element {
    const clickOnYesBtn = () => {
        removeSkill(skillId);
    };

    return (
        <div className={classes['skill-add-portal']}>
            <div className={classes['skill-add-portal__wrapper']}>
                <div className={classes['skill-add-portal__container']}>
                    <div>Вы точно хотите удалить {text}?</div>

                    <div className={classes['skill-add-portal__buttons']}>
                        <ActionButton onClick={clickOnYesBtn}>
                            Да
                        </ActionButton>

                        <ActionButton onClick={closePortal}>
                            Нет
                        </ActionButton>
                    </div>
                </div>

                <IconDeprecated id="Cross" className={classes.blackCrossAddSkillPopUp} click={closePortal} />
            </div>
        </div>
    );
}
