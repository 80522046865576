import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import { observer } from 'mobx-react-lite';

import { AvatarIcon } from '@common/svg';
import { UserTypeMenu } from '@common/Menu/UserTypeMenu';
import { CommonTargetName, useSendMetriks } from '@common/hooks/useSendMetriks';
import UserView from '../../../store/UserView';
import { ViewType } from '../../../deprecated-graphql-query-types';

import classes from './MenuProfile.module.scss';

interface UserRole {
    roleId: string;
    name: string;
    text: string;
}

interface CurrentUser {
    firstName: string;
    lastName: string;
    roles: UserRole[];
}

interface Props {
    currentUser: CurrentUser;
}

export const MenuProfile = observer(({ currentUser }: Props): JSX.Element => {
    const { userViewType } = UserView;
    const { roles } = currentUser;
    const [userRolesMode, setUserRolesMode] = useState<boolean>(false);
    const { sendYandexMetrika } = useSendMetriks([ViewType.Student, ViewType.Teacher]);

    const handleSetUserRoleMode = (): void => {
        setUserRolesMode(!userRolesMode);
    };

    const handleCloseProfileMenu = (): void => {
        setUserRolesMode(false);
    };

    useEffect(() => {
        document.addEventListener('click', handleCloseProfileMenu);

        return () => document.removeEventListener('click', handleCloseProfileMenu);
    });

    return (
        <div
            className={
                cn(classes.menuProfile, {
                    [classes.menuProfile_userTypesMode]: userRolesMode && roles.length > 1,
                })
            }
        >
            <p className={classes.menuProfile__userType}>{userViewType?.text}</p>

            <div
                className={
                    cn(classes.menuProfile__photo, {
                        [classes.menuProfile__photo_userTypesMode]:
                            userRolesMode && roles.length > 1,
                    })
                }
                onClick={(event) => event.stopPropagation()}
            >
                <div
                    className={classes.menuProfile__gradientCircle}
                    onClick={() => {
                        handleSetUserRoleMode();
                        sendYandexMetrika(CommonTargetName.avatar);
                    }}
                >
                    <div
                        className={cn(classes.menuProfile_avatarCircle, {
                            [classes.menuProfile_avatarCircle_cursorPointer]: roles.length > 1,
                        })}
                    >
                        <AvatarIcon />
                    </div>
                </div>

                {
                    (userRolesMode && roles.length > 1) && (
                        <UserTypeMenu
                            handleSetUserRoleMode={handleSetUserRoleMode}
                            handleCloseProfileMenu={handleCloseProfileMenu}
                        />
                    )
                }
            </div>

            <div className={classes.menuProfile__name}>
                {currentUser.firstName || 'Иван'}
                <br />
                {currentUser.lastName || 'Иванов'}
            </div>
        </div>
    );
});
