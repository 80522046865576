import React from 'react';
import { observer } from 'mobx-react';
import groupBy from 'lodash/groupBy';

import { Title } from '@common/Title';
import { moduleStore, dictionaryStore, EventWithoutEvaluation, Meeting as NormalMeeting } from '@admin/NewModule/Store';
import { SavedEvent } from '@admin/NewModule/SavedEvent';
import { Dictionary } from 'lodash';
import { PreviewSkills } from './PreviewSkills';

import classes from './Preview.module.scss';

import { Meeting } from '../../../subSpaceAdmin/subSpaceTypes';

const FRACTION_DIGITS = 3;

interface RolesWithHours {
    role: string,
    duration: number,
}

const numOfHours = (events: EventWithoutEvaluation[]) => events
    .reduce((acc, { duration }) => acc + (duration?.academicHours ?? 0), 0);

const numOfHoursView = (events: EventWithoutEvaluation[]) => numOfHours(events)
    .toFixed(FRACTION_DIGITS);

const numOfZET = (events: EventWithoutEvaluation[]) => numOfHours(events) / 36;

const numOfZETView = (events: EventWithoutEvaluation[]) => numOfZET(events)
    .toFixed(FRACTION_DIGITS);

const getTeacherRolesWithHours = (meetings: NormalMeeting[] | Meeting[]) => {
    const roles: RolesWithHours[] = [];

    meetings.forEach((meeting) => {
        meeting.meetingTeacherRoles.forEach((role) => {
            roles.push({
                duration: meeting.duration?.academicHours ?? 0,
                role: role.teacherRole.name,
            });
        });
    });

    return roles;
};

export const countTeachersRolesHours = (meetings: Meeting[] | NormalMeeting[]) => {
    const groupedRoles = groupBy(getTeacherRolesWithHours(meetings), 'role');
    const rolesWithHours: RolesWithHours[] = getRolesWithHours(groupedRoles);

    return rolesWithHours;
};

function getRolesWithHours(
    groupedRoles: Dictionary<RolesWithHours[]>,
) {
    return Object.entries(groupedRoles).map(([key, value]) => ({
        role: key,
        duration: getRoleHours(value),
    }));
}

function getRoleHours(value: RolesWithHours[]) {
    return value.reduce(
        (previousValue, currentValue) => previousValue + currentValue?.duration, 0,
    );
}

export const Preview = observer(() => {
    const module = moduleStore.moduleModel;
    const [isSaved, setSaved] = React.useState(true);
    return (
        <>
            {isSaved
                ? (
                    <div className={classes.preview_saved}>
                        <SavedEvent
                            title="Проверьте итоговые параметры модуля"
                            topic=""
                            handleEditEvent={() => setSaved(false)}
                        />
                    </div>
                )
                : (
                    <div className={classes.preview_notSaved}>
                        <Title>Проверьте итоговые параметры модуля:</Title>
                        <div className={classes.preview__info}>
                            <p className={classes.preview__infoElement}>
                                Количество ЗЕТ, на которое рассчитан модуль:
                                {` ${numOfZETView(module.eventsWithoutTest)}`}
                            </p>
                            <p className={classes.preview__infoElement}>
                                Количество ЗЕТ, на которое рассчитаны встречи:
                                {` ${numOfZETView(module.meetings)}`}
                            </p>
                            <p className={classes.preview__infoElement}>
                                Количество ЗЕТ для сам. работы: {numOfZETView(module.assignments)}
                            </p>
                        </div>
                        <div className={classes.preview__info}>
                            <p className={classes.preview__infoElement}>
                                Количество академических часов студента, на которое рассчитан модуль
                                {`: ${numOfHoursView(module.eventsWithoutTest)}`}
                            </p>
                            <p className={classes.preview__infoElement}>
                                Количество академических часов, на которое рассчитаны встречи:
                                {` ${numOfHoursView(module.meetings)}`}
                            </p>

                            <p className={classes.preview__infoElement}>
                                Количество академических
                                часов, на которое рассчитаны встречи для каждой роли:
                                {
                                    countTeachersRolesHours(module.meetings).map((teacherInfo) => (
                                        <p
                                            key={teacherInfo.role}
                                            style={{
                                                paddingLeft: '10px',
                                            }}
                                        >
                                            {teacherInfo.role}
                                            :
                                            {teacherInfo.duration.toFixed(3)}
                                        </p>
                                    ))
                                }
                            </p>

                            <p className={classes.preview__infoElement}>
                                Количество академических часов для сам. работы:
                                {` ${numOfHoursView(module.assignments)}`}
                            </p>
                        </div>
                        <div className={classes.preview__info}>
                            <p className={classes.preview__infoElement}>
                                Всего встреч в модуле: {module.meetings.length}
                            </p>
                            <p className={classes.preview__infoElement}>
                                Всего самостоятельных работ в модуле: {module.assignments.length}
                            </p>
                            <p className={classes.preview__infoElement}>
                                Всего точек оценки в модуле: {module.evaluationPoints.length}
                            </p>
                        </div>
                        <br />
                        <div className={classes.preview__info}>
                            <Title>Пререквизиты модуля:</Title>
                            <PreviewSkills
                                skillTypes={dictionaryStore.moduleSettings.moduleSkillTypes}
                                getSkills={(id: string) => module.options.prerequisiteSkills
                                    ?.filter(skill => skill.skill.typeId === id)}
                            />
                            <PreviewSkills
                                skillTypes={dictionaryStore.moduleSettings.meetingSkillTypes}
                                getSkills={module.options.getEventsPrerequisiteSkillsForPreview}
                            />
                        </div>
                    </div>
                )}
        </>
    );
});
