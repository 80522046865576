import React from 'react';
import times from 'lodash/times';

import { BorderCrossIcon } from '@common/svg';

import classes from './EducationMinMax.module.scss';

interface Interval {
    id?: string;
    minCreditCount: number;
    maxCreditCount: number;
}

interface Props {
    labels: string[];
    quantity: number;
    onChangeMinMax(value: number, type: string, index?: number, isDelete?: boolean): void;
    isDelete?: boolean;
    values: Interval[] | Interval;
}

const chooseMinCreditCountValue = (values: Interval[] | Interval, index: number) => {
    if (Array.isArray(values)) {
        if (values[index].minCreditCount) {
            return values[index].minCreditCount;
        }
        return undefined;
    }
    if (values.minCreditCount) {
        return values.minCreditCount;
    }
    return undefined;
};

const chooseMaxCreditCountValue = (values: Interval[] | Interval, index: number) => {
    if (Array.isArray(values)) {
        if (values[index].maxCreditCount) {
            return values[index].maxCreditCount;
        }
        return undefined;
    }
    if (values.maxCreditCount) {
        return values.maxCreditCount;
    }
    return undefined;
};

export const EducationPageMinMax = ({
    labels,
    quantity,
    onChangeMinMax,
    isDelete,
    values,
}: Props) => (
    <div className={classes.minMax}>
        {times(quantity, (index) => (
            // eslint-disable-next-line react/no-array-index-key
            <div
                key={Array.isArray(values) ? values[index].id : values.id}
                className={classes.minMax__container}
            >
                <div>
                    <div className={classes.minMax__label}>
                        {labels[index]}
                    </div>
                    <div>
                        <label>
                            min:
                            <input
                                type="number"
                                className={classes.minMax__input}
                                value={chooseMinCreditCountValue(values, index)}
                                onChange={({
                                    currentTarget,
                                }) => onChangeMinMax(Number(currentTarget.value), 'min', index)}
                            />
                        </label>
                        <label>
                            max:
                            <input
                                type="number"
                                className={classes.minMax__input}
                                value={chooseMaxCreditCountValue(values, index)}
                                onChange={({
                                    currentTarget,
                                }) => onChangeMinMax(Number(currentTarget.value), 'max', index)}
                            />
                        </label>
                    </div>
                </div>
                {isDelete && quantity > 1
                    && (
                        <BorderCrossIcon
                            size={26}
                            className={index > 8
                                ? classes.blackCrossSpaceMinMax_moreThanTen
                                : classes.blackCrossSpaceMinMax_lessThanTen
                            }
                            handler={() => onChangeMinMax(0, 'max', index, isDelete)}
                        />
                    )
                }
            </div>
        ))}
    </div>
);
