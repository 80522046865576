import React from 'react';

import { observer } from 'mobx-react-lite';
import Diagram from '../store/Diagram';
import { StudentPickingType } from '../subSpaceTypes';

import classes from './ForkCard.module.scss';

interface Props {
    nextSlotscount?: number;
    studentPickingType: StudentPickingType | string;
}

export const StudentChoice = observer(({
    nextSlotscount = 1,
    studentPickingType,
}: Props): JSX.Element => {
    const { forkSettingForm } = Diagram;
    const { studentPriorityCount } = forkSettingForm;
    return (
        <div className={classes.studentChoice}>
            <div className={classes.forkCard__subtitle}>
                Порядок учета приоритетов студентов формируется
            </div>
            <select
                className={classes.forkCard__select}
                name="studentPickingType"
                defaultValue={studentPickingType}
                onChange={({ target }) => {
                    Diagram.setForkSettingsForm<string>(target.value, 'studentPickingType');
                }}
            >
                <option value="randomly">Случайно</option>
                <option value="byRating">По рейтингу</option>
                <option value="bySkill">По уровню образовательных результатов</option>
            </select>
            <div className={classes.forkCard__subtitle}>
                Количество приоритетов для выбора студентом:
            </div>
            <input
                className={classes.forkCard__moduleInput}
                placeholder="Введите количество"
                onChange={({ target }) => {
                    const value = Number(target.value);
                    if (Number.isNaN(value)) {
                        Diagram.setForkSettingsForm<number>(0,
                            'studentPriorityCount');
                    }
                    if (nextSlotscount >= value) {
                        Diagram.setForkSettingsForm<number>(value,
                            'studentPriorityCount');
                    } else {
                        Diagram.setForkSettingsForm<number>(nextSlotscount,
                            'studentPriorityCount');
                    }
                }}
                value={studentPriorityCount}
                type="number"
                min={0}
                max={nextSlotscount}
            />
        </div>
    );
});
