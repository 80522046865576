import React from 'react';
import { observer } from 'mobx-react';

import { Accordion } from '@common/Accordion';
import { SpaceBaseTimeInterval, SpaceBaseTimeIntervalParameters } from '@admin/EducationPeriodPage/EducationPeriodPageInterfaces';
import { format } from 'date-fns';

import {
    AccordionHeader,
} from './AccordionHeader';
import {
    AccordionItems,
} from './AccordionItems';

interface Props {
    baseTimeInterval: SpaceBaseTimeInterval;
    name: string;
    parameters?: SpaceBaseTimeIntervalParameters;
}

export const EducationPeriodDatesAccordion = observer((
    {
        baseTimeInterval,
        name,
        parameters,
    }: Props,
): JSX.Element => (
    <Accordion
        shouldClickOnHeaderOpen
        isDefaultOpen
    >

        <AccordionHeader
            baseTimeInterval={baseTimeInterval}
            name={name}
        />

        <AccordionItems
            name={name}
            baseTimeInterval={baseTimeInterval}
            parameters={parameters}
        />

    </Accordion>
));

export function formatDate(date: (string | undefined)) {
    const dateParts = date && date?.split('-');
    if (dateParts) {
        return `${dateParts[2]}.${dateParts[1]}.${dateParts[0]}`;
    }
    return 'не задано';
}

export function formatModuleAssessmentDate(
    date: string,
) {
    const dateWithoutTime = format(new Date(date!), 'yyyy-MM-dd');
    // if (isDynamicStartDate && start) {
    //     return 'по окончанию модуля';
    // }
    return formatDate(dateWithoutTime);
}
