import React, { Fragment } from 'react';
import { IgnoreFlag, IgnoreFlagCheckbox } from './interfaces';

import classes from './ScheduleGeneration.module.scss';

interface Props {
    flags: string[];
    ignoreFlags: Partial<IgnoreFlagCheckbox>;
    validationResult?: string[];
    isCustom?: boolean;
    isSecret?: boolean;
    loadValidationButton?: JSX.Element;
    handleIgnoreFlagsChange: (ignoreFlag: Partial<keyof typeof IgnoreFlag>) => () => void;
}

export const IgnoreFlagsForValidation = ({
    flags,
    ignoreFlags,
    validationResult,
    isSecret = false,
    isCustom = false,
    loadValidationButton,
    handleIgnoreFlagsChange,
}: Props) => (
    <div className={classes.filters__conflictTypes}>
        {!isCustom && (
            <>
                <div
                    className={classes.filters__itemName}
                >
                    <b>
                        Процесс генерации невозможен без решения текущих ошибок. <br />
                        Пожалуйста, решите указанные ошибки и повторите запрос. <br />
                    </b>
                </div>

                <div
                    className={classes.filters__itemName}
                >
                    Ресурсов недостаточно, это может привести
                    к нарушениям в генерации расписания.
                    <br />
                    Можно проигнорировать отсутствие следующих ресурсов:
                </div>
            </>
        )}
        {isCustom && (
            <div>Игнорировать следующие флаги:</div>
        )}
        {flags.map((flag) => {
            const typedFlag = flag as keyof typeof IgnoreFlag;
            if (!isSecret && flag === 'ignoreMinMaxIntervals') {
                return null;
            }
            return (
                <div
                    key={flag}
                    className={classes.filters__checkbox}
                    onClick={handleIgnoreFlagsChange(typedFlag)}
                >
                    <input
                        type="checkbox"
                        id={flag}
                        name={flag}
                        value={flag}
                        checked={ignoreFlags[typedFlag]}
                    />
                    <div>
                        {IgnoreFlag[typedFlag]}
                    </div>
                </div>
            );
        })}
        {loadValidationButton}
        <ul className={classes.conflicts}>
            {
                validationResult?.map(
                    (item: string) => (
                        <li key={item}>
                            {item}
                        </li>
                    ),
                )
            }
        </ul>
    </div>
);
