import { action, makeObservable, observable } from 'mobx';
import { sortBy } from 'lodash';
import find from 'lodash/find';

import { USER_VIEW_TYPE_NAME } from '@common/constants';
import { getCurrentUser_getCurrentUser, getCurrentUser_getCurrentUser_roles } from '@common/qraphql/graphql-types';
import { UserRole, ViewType } from '../deprecated-graphql-query-types';

class UserView {
    user: getCurrentUser_getCurrentUser | null = null;

    userViewType: UserRole | null = null;

    userViewTypes: UserRole[] = [];

    studentId: string | null = null;

    teacherId: string | null = null;

    acceptedAgreement: boolean = false;

    constructor() {
        makeObservable(this, {
            userViewType: observable,
            userViewTypes: observable,
            studentId: observable,
            teacherId: observable,
            acceptedAgreement: observable,
            setUserViewType: action,
            setUserViewTypes: action,
            checkAndUpdateCurrentViewType: action,
            clearViewType: action,
            setStudentId: action,
            setTeacherId: action,
            setAcceptedAgreement: action,
        });
    }

    private getViewTypeByViewTypeName(
        viewTypes: getCurrentUser_getCurrentUser_roles[],
        typeName: ViewType,
    ): UserRole {
        return find(viewTypes, { name: typeName }) as UserRole;
    }

    public setAcceptedAgreement(accepted: boolean) {
        this.acceptedAgreement = accepted;
    }

    public setUser(user: getCurrentUser_getCurrentUser | null): void {
        this.user = user;
    }

    public setUserViewTypes(viewTypes: getCurrentUser_getCurrentUser_roles[]): void {
        this.userViewTypes = viewTypes.filter(({ name, enabled }) => (name === ViewType.Teacher
            || name === ViewType.Admin
            || name === ViewType.Student)
                && enabled) as UserRole[];
    }

    public checkAndUpdateCurrentViewType(currentUser: getCurrentUser_getCurrentUser) {
        // нужно делать проверку на наличие currentUser.roles и если их нет,
        // то нафиг редиректить и не выполнять логику ниже
        const viewTypeInMobx = this.userViewType?.name;
        const viewTypeInLocalStorage = <ViewType>localStorage.getItem(USER_VIEW_TYPE_NAME);

        try {
            if (viewTypeInMobx) {
                this.readViewTypeFromMobX(currentUser, viewTypeInMobx);
            } else if (viewTypeInLocalStorage) {
                this.readViewTypeFromLocalStorage(currentUser, viewTypeInLocalStorage);
            } else {
                this.readViewTypeFromServer(
                    currentUser, this.getFirstPriorityViewType(currentUser.roles),
                );
            }
        } catch (error: any) {
            if (error.meetings === 'VIEW_TYPE_NOT_FOUND_IN_SERVER_DATA') {
                // TODO: надо решить что делать логаут или переключение на другой вью тайп
                // TODO: если логаут, то надо долделать логаут
                this.clearViewType();
            } else {
                throw error;
            }
        }
    }

    public clearViewType() {
        localStorage.removeItem(USER_VIEW_TYPE_NAME);
        this.setUserViewType(null);
        this.setUserViewTypes([]);
        this.setUser(null);
        this.setStudentId(null);
        this.setTeacherId(null);
    }

    public setTeacherId(teacherId: string | null) {
        this.teacherId = teacherId;

        // if (teacherId && this.teacherId === null) {
        //     this.teacherId = teacherId;
        // }
    }

    private readViewTypeFromMobX(currentUser: getCurrentUser_getCurrentUser, viewType: ViewType) {
        this.checkCurrentViewTypeInFreshDataFromServer(currentUser, viewType);

        this.setUserViewTypes(currentUser.roles);
        this.setUser(currentUser);
    }

    private readViewTypeFromLocalStorage(
        currentUser: getCurrentUser_getCurrentUser,
        viewType: ViewType,
    ) {
        this.checkCurrentViewTypeInFreshDataFromServer(currentUser, viewType);

        this.setUserViewType(
            this.getViewTypeByViewTypeName(currentUser.roles, viewType),
        );
        this.setUserViewTypes(currentUser.roles);
        this.setUser(currentUser);
    }

    private readViewTypeFromServer(currentUser: getCurrentUser_getCurrentUser, viewType: ViewType) {
        this.checkCurrentViewTypeInFreshDataFromServer(currentUser, viewType);

        this.setUserViewType(
            this.getViewTypeByViewTypeName(currentUser.roles, viewType),
        );
        this.setUserViewTypes(currentUser.roles);
        this.setUser(currentUser);
    }

    private checkCurrentViewTypeInFreshDataFromServer(
        currentUser: getCurrentUser_getCurrentUser, viewType: ViewType,
    ) {
        const isCurrentViewTypeInFreshDataFromServer = this.checkViewTypeInViewTypes(
            currentUser.roles, viewType,
        );

        if (!isCurrentViewTypeInFreshDataFromServer) {
            throw new Error('VIEW_TYPE_NOT_FOUND_IN_SERVER_DATA');
        }
    }

    private getFirstPriorityViewType(viewTypes: getCurrentUser_getCurrentUser_roles[]): ViewType {
        return sortBy(viewTypes, 'text')[0].name as ViewType;
    }

    private checkViewTypeInViewTypes(
        viewTypes: getCurrentUser_getCurrentUser_roles[],
        typeName: ViewType,
    ): boolean {
        return !!find(viewTypes, { name: typeName });
    }

    public setStudentId(studentId: string | null) {
        this.studentId = studentId;

        // if (studentId && this.studentId === null) {
        //     this.studentId = studentId;
        // }
    }

    public setUserViewType(viewType: UserRole | null): void {
        this.userViewType = viewType;

        if (viewType) {
            const viewTypeInLS = localStorage.getItem(USER_VIEW_TYPE_NAME);

            if (viewTypeInLS !== viewType.name) {
                localStorage.setItem(USER_VIEW_TYPE_NAME, viewType.name);
            }
        } else {
            localStorage.removeItem(USER_VIEW_TYPE_NAME);
        }
    }
}

export default new UserView();
