import React, { useState } from 'react';

import cn from 'classnames';
import { BorderPlusIcon, EditIcon } from '@common/svg';
import { EducationSubspaceCard } from './EducationSubspaceCard';
import { EducationSubspaceEditApollo } from './EducationSubspaceEditApollo';
import classes from '../../EducationSpacePage.module.scss';
import { Space } from '../../../../subSpaceAdmin/subSpaceTypes';
import { subspaces_subspaces } from './apollo-types';

interface Subspace {
    id: string;
    name: string;
    ownerId: string;
    ownerFullName: string;
    typeId: string;
}

interface SubspaceTypeLayout {
    id: string;
    spaceBaseTimeIntervalId: string;
    minCreditCount: number;
    maxCreditCount: number;
}

interface SubspaceType {
    id: string;
    name: string;
    isSelective: boolean;
    isDescriptionEnabled: boolean;
    subspaceTypeLayouts: SubspaceTypeLayout[];
    subspaces: { id: string; }[];
}

interface Props {
    space: Space;
    name: string;
    subspaceType: SubspaceType;
    typeId: string;
    typeIndex: number;
    subspaceList: subspaces_subspaces[];
    setActiveIndexType(index: number): void;
    createSubspace: (
        id: string,
        name: string,
        ownerId: string,
        typeId: string,
    ) => void;
    removeSubspace: (
        id: string,
    ) => void;
    routerPath: string;
}

export const EducationSubspaceForm = ({
    name,
    subspaceType,
    typeId,
    typeIndex,
    subspaceList,
    setActiveIndexType,
    createSubspace,
    removeSubspace,
    space,
    routerPath,
}: Props) => {
    const [activeIndex, setActiveIndex] = useState(-1);
    const [subspaces, setSubspaces] = useState<Subspace[]>(formatSubspaces(subspaceList));
    return (
        <div className={classes.educationSubspaceForm}>
            <div className={classes.educationSubspaceForm__head}>
                Тип подпространств {name}
                <EditIcon size={33} handler={() => setActiveIndexType(typeIndex)} />
            </div>

            {
                subspaces.map((subspace, index: number) => {
                    if (activeIndex === index) {
                        return (
                            <EducationSubspaceEditApollo
                                key={subspace.id}
                                index={index}
                                typeId={typeId}
                                subspaces={subspaces}
                                setSubspaces={setSubspaces}
                                setActiveIndex={setActiveIndex}
                                createSubspace={createSubspace}
                                removeSubspace={removeSubspace}
                            />
                        );
                    }
                    return (
                        <EducationSubspaceCard
                            key={subspace.id}
                            name={subspace.name}
                            subspace={subspace}
                            administrator={subspace.ownerFullName}
                            index={index}
                            setActiveIndex={setActiveIndex}
                            space={space}
                            routerPath={routerPath}
                        />
                    );
                })
            }

            {
                activeIndex === subspaces.length
                && (
                    <EducationSubspaceEditApollo
                        index={-1}
                        typeId={typeId}
                        subspaces={subspaces}
                        setSubspaces={setSubspaces}
                        setActiveIndex={setActiveIndex}
                        createSubspace={createSubspace}
                        removeSubspace={removeSubspace}
                    />
                )
            }

            <div
                className={cn(classes.educationSubspace__IconAddSubspaceContainer, {
                    [classes.educationSubspace__IconAddSubspaceContainer_displayNone]:
                        !subspaceType.isSelective
                        && subspaces.length === 1,
                })}
                onClick={() => setActiveIndex(subspaces.length)}
            >

                <div className={classes.educationSubspace__textAddSubspace}>
                    <BorderPlusIcon />
                    Добавить подпространство
                </div>
            </div>
        </div>
    );
};

function formatSubspaces(subspaces: subspaces_subspaces[]) {
    return subspaces.map(({ id, name, owner, type }) => ({
        id,
        name,
        ownerId: owner.id,
        ownerFullName: `${owner.lastName} ${owner.firstName} ${owner.patronymic}`,
        typeId: type.id,
    }));
}
