import React from 'react';
import { random, range } from 'lodash';
import classNames from 'classnames';

import classes from './Legend.module.scss';

const colors = new Map<number, string>([
    [1, '#FFE5A6'],
    [2, '#B5FFF1'],
    [3, '#AACCFF'],
    [4, '#E3CDFF'],
    [5, '#FFC9FC'],
    [6, '#FFF7A2'],
    [7, '#EEFFA2'],
    [8, '#FFBDAA'],
    [9, '#FFDCA1'],
    [10, '#CDFFE4'],
    [11, '#b2dfdb'],
    [12, '#f0f4c3'],
    [13, '#ffecb3'],
    [14, '#e1bee7'],
    [15, '#bbdefb'],
    [16, '#ffcdd2'],
    [17, '#d1c4e9'],
    [18, '#81d4fa'],
    [19, '#c8e6c9'],
]);

export const Legend = (): JSX.Element => (
    <div className={classes.legend}>
        <div className={classes.legend__title}>
            Легенда
        </div>

        {range(1, colors.size + 1).map(index => (
            <div
                key={index}
                className={classes.legend__item}
                style={{ backgroundColor: getColor(index) }}
            >
                <span className={classes.legend__text}>{index} неделя</span>
            </div>
        ))}

        <div className={classNames(classes.legend__item, classes.legend__item_planned)}>
            <span className={classes.legend__text}>Запланированная неделя</span>
        </div>
    </div>
);

export function getColor(processOrder: number = 0): string | undefined {
    if (processOrder && !colors.has(processOrder)) {
        colors.set(processOrder, `rgba(${random(255)}, ${random(255)}, ${random(255)}, 0.5)`);
    }

    return colors.get(processOrder);
}
