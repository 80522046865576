import { makeAutoObservable } from 'mobx';
import { differenceBy } from 'lodash';

import {
    Fork,
    URLParameters,
} from '@admin/WaveSelectionPage/Interfaces';
import {
    SpaceBaseTimeInterval_spaceBaseTimeInterval_spaceBaseTimeIntervalSubspaces
    as SpaceBaseTimeIntervalSubspace,
    SpaceBaseTimeInterval_spaceBaseTimeInterval_spaceBaseTimeIntervalSubspaces_forks_nextSlots
    as Slot,
    ForkUndistributedStudents_forkUndistributedStudents
    as UndistributedStudent,
    Waves_waves
    as Wave,
} from '@admin/WaveSelectionPage/graphql-types';

class WaveSelectionDataStore {
    private currentForkModulesIds: string[] = [];

    private forks: Fork[] = [];

    private allStudentsIds: string[] = [];

    private previousBTIId: string = '';

    private isValidStudentWaveDistribution: boolean = false;

    private URLParameters: URLParameters = {
        baseTimeIntervalInstanceId: '',
        forkId: '',
        baseTimeIntervalId: '',
        spaceId: '',
        btiOrder: '',
        isNotStudentChoice: false,
    };

    constructor() {
        makeAutoObservable(this);
    }

    setCurrentForkModulesIds = (
        forkModulesIds: string[],
    ) => {
        this.currentForkModulesIds = forkModulesIds;
    };

    setForksFromSubspaceSpaceBaseTimeIntervalSubspaces = (
        spaceBaseTimeIntervalSubspaces: SpaceBaseTimeIntervalSubspace[],
    ) => {
        this.forks = getForksFromSubspaceSpaceBaseTimeIntervalSubspaces(
            spaceBaseTimeIntervalSubspaces,
        );
    };

    setAllStudentsIdsFromUndistributedStudents = (
        undistributedStudents: UndistributedStudent[],
    ) => {
        const studentsIds = getStudentsIdsFromUndistributedStudents(undistributedStudents);

        this.allStudentsIds = [...this.allStudentsIds, ...studentsIds];
    };

    setAllStudentsIdsFromWaves = (
        waves: Wave[],
    ) => {
        const studentsIds = getStudentsIdsFromWaves(waves);

        this.allStudentsIds = [...this.allStudentsIds, ...studentsIds];
    };

    setPreviousBTIId = (
        id: string,
    ) => {
        this.previousBTIId = id;
    };

    setIsValidStudentWaveDistribution = (
        isValid: boolean,
    ) => {
        this.isValidStudentWaveDistribution = isValid;
    };

    updateURLParameters = () => {
        const params = new URLSearchParams(window.location.search);

        this.URLParameters = {
            baseTimeIntervalInstanceId: params.get('btiIId') ?? '',
            forkId: params.get('forkId') ?? '',
            isNotStudentChoice: params.get('isRequired') === 'true',
            baseTimeIntervalId: params.get('btiId') ?? '',
            spaceId: params.get('spaceId') ?? '',
            btiOrder: params.get('btiOrder') ?? '',
        };
    };

    getCurrentForkModulesIds = () => this.currentForkModulesIds;

    getForks = () => this.forks;

    getAllStudentsIds = () => this.allStudentsIds;

    getPreviousBTIid = () => this.previousBTIId;

    getURLParameters = () => this.URLParameters;

    getIsValidStudentWaveDistribution = () => this.isValidStudentWaveDistribution;
}

export const waveSelectionDataStore = new WaveSelectionDataStore();

function getStudentsIdsFromUndistributedStudents(
    undistributedStudents: UndistributedStudent[],
): string[] {
    return undistributedStudents.map(
        (undistributedStudent) => undistributedStudent.student.user.studentId,
    ).flatMap((id) => (id ? [id] : []));
}

function getStudentsIdsFromWaves(
    waves: Wave[],
): string[] {
    return waves.flatMap(
        (wave) => (
            wave.students.map((student) => student.user.studentId)
        ),
    ).flatMap((id) => (id ? [id] : []));
}

function getForksFromSubspaceSpaceBaseTimeIntervalSubspaces(
    spaceBaseTimeIntervalSubspaces: SpaceBaseTimeIntervalSubspace[],
): Fork[] {
    const forks: Fork[] = [];

    spaceBaseTimeIntervalSubspaces.forEach((subspace) => {
        const notRequiredSlots: Slot[] = [];

        subspace.forks.forEach((fork) => {
            forks.push(
                {
                    id: fork.id,
                    subspace: subspace.subspace,
                    isNotStudentChoice: false,
                    slots: fork.nextSlots,
                },
            );

            notRequiredSlots.push(...fork.nextSlots);
        });
        const requiredSlots = differenceBy(subspace.slots, notRequiredSlots, (slot) => slot.id);
        if (requiredSlots.length) {
            forks.push(
                {
                    id: subspace.subspace.id,
                    subspace: subspace.subspace,
                    isNotStudentChoice: true,
                    slots: requiredSlots,
                },
            );
        }
    });

    return forks;
}
