import React from 'react';
import { Meeting } from '@admin/ScheduleGenerationPage/types';
import { gql, useQuery } from '@apollo/client';
import { Alert } from '@common/Alert';
import { MeetingEdit } from '@admin/ScheduleGenerationPage/MeetingDetails/MeetingEdit/MeetingEdit';

const GET_MEETINGS_FOR_REPLACE = gql`
 query getMeetingsForReplace($meetingForReplace: MeetingsForReplaceInput!) {
    meetingsForReplace(meetingsForReplaceInput: $meetingForReplace) {
        meeting {
            id
            topic
            order
        }
        meetingInstances {
            id
            startDate
            endDate
        }
    }
}
`;

interface Props {
    moduleId: string;
    waveIds: string[];
    meeting: Meeting;
    meetingInstanceId: string;
    date: string;

    setMeeting(newMeeting: Meeting): void;
    setEditable(editable: boolean): void;
}

export function MeetingEditApollo({
    moduleId,
    waveIds,
    meeting,
    meetingInstanceId,
    date,
    setMeeting,
    setEditable,
}: Props): JSX.Element {
    const {
        data,
        error,
    } = useQuery(GET_MEETINGS_FOR_REPLACE, {
        fetchPolicy: 'cache-first',
        variables: {
            meetingForReplace: {
                moduleId,
                waveIds,
            },
        },
    });

    return (
        <>
            <MeetingEdit
                meeting={meeting}
                meetingInstanceId={meetingInstanceId}
                meetingsForReplace={data?.meetingsForReplace}
                date={date}
                setMeeting={setMeeting}
                setEditable={setEditable}
            />
            {error?.message && <Alert message={error.message} />}
        </>
    );
}
