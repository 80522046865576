import { SubspacesView, SubspacesStatuses, SubspaceStatus } from './subspacesView';
import { ConnectionsView, ConnectionsStatuses, ConnectionStatus } from './connectionsView';
import { Subspace, SubspaceType } from '../interfaces';
import { isValidConnection } from './subspacesConnection';

export interface DiagramGraph {
    [sourceId: string]: {
        sourcesIds: string[],
        targetsIds: string[],
    };
}

export enum TraversalDirection { Targets, Sources }

export class DiagramView {
    private subspaces: Subspace[];

    private diagramGraph: DiagramGraph;

    private subspacesView: SubspacesView;

    private connectionsView: ConnectionsView;

    constructor(subspaces: Subspace[]) {
        this.subspaces = subspaces;
        this.diagramGraph = this.initDiagramGraph(subspaces);
        this.subspacesView = new SubspacesView(subspaces);
        this.connectionsView = new ConnectionsView(subspaces);
    }

    private initDiagramGraph(subspaces: Subspace[]): DiagramGraph {
        return subspaces.reduce((acc: DiagramGraph, subspace: Subspace) => {
            const diagramSubgraph = {
                [subspace.id]: {
                    sourcesIds: subspace.sourceSubspaces.map(({ id }) => id),
                    targetsIds: subspace.targetSubspaces.map(({ id }) => id),
                },
            };

            return { ...acc, ...diagramSubgraph };
        },
        {});
    }

    private recurciveSetSubspacesStatus(
        subspaceId: string, forward: boolean, status: SubspaceStatus,
    ): void {
        const subspaceNode = this.diagramGraph[subspaceId];
        const linkedIds = forward ? 'targetsIds' : 'sourcesIds';

        if (!subspaceNode) {
            return;
        }

        this.subspacesView.setSubspaceStatus(subspaceId, status);

        subspaceNode[linkedIds].forEach(id => {
            this.recurciveSetSubspacesStatus(id, forward, status);
        });
    }

    private recursiveSetConnectionsStatus(
        subspaceId: string, forward: boolean, status: ConnectionStatus,
    ): void {
        const subspaceNode = this.diagramGraph[subspaceId];
        const linkedIds = forward ? 'targetsIds' : 'sourcesIds';

        if (!subspaceNode) {
            return;
        }

        subspaceNode[linkedIds].forEach(id => {
            const connection: [string, string] = forward ? [subspaceId, id] : [id, subspaceId];
            this.connectionsView.setConnectionStatus(...connection, status);
            this.recursiveSetConnectionsStatus(id, forward, status);
        });
    }

    private setSubspacesStatus(
        subspaceId: string, forward: boolean, status: SubspaceStatus,
    ): void {
        const subspaceNode = this.diagramGraph[subspaceId];
        const linkedIds = forward ? 'targetsIds' : 'sourcesIds';

        subspaceNode[linkedIds].forEach(id => {
            this.subspacesView.setSubspaceStatus(id, status);
        });
    }

    private setConnectionsStatus(
        subspaceId: string, forward: boolean, status: ConnectionStatus,
    ): void {
        const subspaceNode = this.diagramGraph[subspaceId];
        const linkedIds = forward ? 'targetsIds' : 'sourcesIds';

        subspaceNode[linkedIds].forEach(id => {
            const connection: [string, string] = forward ? [subspaceId, id] : [id, subspaceId];
            this.connectionsView.setConnectionStatus(...connection, status);
        });
    }

    private copyStatuses(sourceView: DiagramView): void {
        this.subspacesView.setValue(sourceView.getSubspacesStatuses());
        this.connectionsView.setValue(sourceView.getConnectionsStatuses());
    }

    public createAllLinks(subspaceId: string): DiagramView {
        const view = new DiagramView(this.subspaces);

        view.subspacesView.setSubspacesStatus(SubspaceStatus.Muted);
        view.recurciveSetSubspacesStatus(subspaceId, true, SubspaceStatus.Normal);
        view.recurciveSetSubspacesStatus(subspaceId, false, SubspaceStatus.Normal);

        view.connectionsView.setConnectionsStatus(ConnectionStatus.Muted);
        view.recursiveSetConnectionsStatus(subspaceId, true, ConnectionStatus.Normal);
        view.recursiveSetConnectionsStatus(subspaceId, false, ConnectionStatus.Normal);

        return view;
    }

    public createLinks(subspaceId: string): DiagramView {
        const view = new DiagramView(this.subspaces);

        view.subspacesView.setSubspacesStatus(SubspaceStatus.Muted);
        view.subspacesView.setSubspaceStatus(subspaceId, SubspaceStatus.Active);
        view.setSubspacesStatus(subspaceId, true, SubspaceStatus.Normal);
        view.setSubspacesStatus(subspaceId, false, SubspaceStatus.Normal);

        view.connectionsView.setConnectionsStatus(ConnectionStatus.Muted);
        view.setConnectionsStatus(subspaceId, true, ConnectionStatus.Normal);
        view.setConnectionsStatus(subspaceId, false, ConnectionStatus.Normal);

        return view;
    }

    public pushLink(sourceId: string, targetId: string): DiagramView {
        const view = new DiagramView(this.subspaces);

        view.copyStatuses(this);

        view.setSubspacesStatus(sourceId, true, SubspaceStatus.Muted);
        view.subspacesView.setSubspaceStatus(targetId, SubspaceStatus.Active);
        view.setSubspacesStatus(targetId, true, SubspaceStatus.Normal);

        view.setConnectionsStatus(sourceId, true, ConnectionStatus.Muted);
        view.connectionsView.setConnectionStatus(sourceId, targetId, ConnectionStatus.Normal);
        view.setConnectionsStatus(targetId, true, ConnectionStatus.Normal);

        return view;
    }

    public popLink(sourceId: string, targetId: string): DiagramView {
        const view = new DiagramView(this.subspaces);

        view.copyStatuses(this);

        view.setSubspacesStatus(targetId, true, SubspaceStatus.Muted);
        view.setSubspacesStatus(sourceId, true, SubspaceStatus.Normal);

        view.setConnectionsStatus(targetId, true, ConnectionStatus.Muted);
        view.setConnectionsStatus(sourceId, true, ConnectionStatus.Normal);

        return view;
    }

    public unshiftLink(sourceId: string, targetId: string): DiagramView {
        const view = new DiagramView(this.subspaces);

        view.copyStatuses(this);

        view.setSubspacesStatus(targetId, false, SubspaceStatus.Muted);
        view.subspacesView.setSubspaceStatus(sourceId, SubspaceStatus.Active);
        view.setSubspacesStatus(sourceId, false, SubspaceStatus.Normal);

        view.setConnectionsStatus(targetId, false, ConnectionStatus.Muted);
        view.connectionsView.setConnectionStatus(sourceId, targetId, ConnectionStatus.Normal);
        view.setConnectionsStatus(sourceId, false, ConnectionStatus.Normal);

        return view;
    }

    public shiftLink(sourceId: string, targetId: string): DiagramView {
        const view = new DiagramView(this.subspaces);

        view.copyStatuses(this);

        view.setSubspacesStatus(targetId, false, SubspaceStatus.Normal);
        view.setSubspacesStatus(sourceId, false, SubspaceStatus.Muted);

        view.setConnectionsStatus(targetId, false, ConnectionStatus.Normal);
        view.setConnectionsStatus(sourceId, false, ConnectionStatus.Muted);

        return view;
    }

    public isTargetSubspace(subspaceId: string, verifiableId: string): boolean {
        return this.diagramGraph[subspaceId].targetsIds.includes(verifiableId);
    }

    public getConnectionsStatuses(): ConnectionsStatuses {
        return this.connectionsView.getValue();
    }

    public getSubspacesStatuses(): SubspacesStatuses {
        return this.subspacesView.getValue();
    }

    public setSubspaceStatus(subspaceId: string, status: SubspaceStatus): DiagramView {
        const view = new DiagramView(this.subspaces);

        view.copyStatuses(this);
        view.subspacesView.setSubspaceStatus(subspaceId, status);

        return view;
    }

    public hasConnection(sourceId: string, targetId: string): boolean {
        return this.diagramGraph[sourceId].targetsIds.includes(targetId)
            || this.diagramGraph[targetId].sourcesIds.includes(sourceId);
    }

    public hasConnections(): boolean {
        return this.connectionsView.hasConnections();
    }

    public hasConnectionId(connectionId: string): boolean {
        return this.connectionsView.has(connectionId);
    }

    // показать все возможные связи (на шаг в соседние БВИ)
    public showPossibleSubspacesConnections(
        subspaceId: string, types: SubspaceType[],
    ): DiagramView {
        const view = new DiagramView(this.subspaces);

        view.subspacesView.setSubspacesStatus(SubspaceStatus.Muted);
        view.connectionsView.setConnectionsStatus(ConnectionStatus.Muted);
        view.subspacesView.setSubspaceStatus(subspaceId, SubspaceStatus.Active);

        view.subspaces.forEach(({ id }: Subspace) => {
            const subspaceIdIsSource = isValidConnection(subspaceId, id, types);
            const subspaceIdIsTarget = isValidConnection(id, subspaceId, types);
            if (subspaceIdIsSource || subspaceIdIsTarget) {
                view.subspacesView.setSubspaceStatus(id, SubspaceStatus.Normal);

                if (subspaceIdIsSource) {
                    view.connectionsView.setConnectionStatus(
                        subspaceId,
                        id,
                        ConnectionStatus.Normal,
                    );
                } else {
                    view.connectionsView.setConnectionStatus(
                        id,
                        subspaceId,
                        ConnectionStatus.Normal,
                    );
                }
            }
        });

        return view;
    }

    public hasSubspaceStatus(subspaceId: string, status: SubspaceStatus): boolean {
        return this.subspacesView.hasSubspaceStatus(subspaceId, status);
    }
}
