import React from 'react';

import cn from 'classnames';
import classes from './IntervalAdditional.module.scss';

interface Props {
    wavesInfo: string;
    studentsInfo: string;
    className: string;
}

export function IntervalAdditional(
    {
        wavesInfo,
        studentsInfo,
        className,
    } : Props,
) {
    return (
        <div className={cn(classes.additional, className)}>
            <div className={classes.additional__info}>
                {
                    wavesInfo
                }
            </div>
            <div className={classes.additional__info}>
                {
                    studentsInfo
                }
            </div>
        </div>
    );
}
