import forEach from 'lodash/forEach';
import range from 'lodash/range';
import sortBy from 'lodash/sortBy';
import {
    BTICheck,
    BTISettings,
    DiagramEntities,
    DiagramEntity,
    Fork,
    GroupSlot,
    ModuleSlot,
    UpdateForkInput,
} from 'src/subSpaceAdmin/subSpaceTypes';
import filter from 'lodash/filter';
import find from 'lodash/find';
import { findIndex } from 'lodash';

const getMinMaxRowValuesOfEntities = (diagramEntities: DiagramEntity[]): number[] => {
    const sortedEntities = sortBy(diagramEntities, 'row');
    const firstEntity = sortedEntities[0];
    const lastEntity = sortedEntities[sortedEntities.length - 1];
    const minRowValue = firstEntity.row;
    const maxRowValue = lastEntity.row;

    return [minRowValue, maxRowValue];
};

const getMinMaxColumnValuesOfEntities = (diagramEntities: DiagramEntity[]): number[] => {
    const sortedEntities = sortBy(diagramEntities, 'column');
    const firstEntity = sortedEntities[0];
    const lastEntity = sortedEntities[sortedEntities.length - 1];
    const minColumnValue = firstEntity.column;
    const maxColumnValue = lastEntity.column;

    return [minColumnValue, maxColumnValue];
};

export const setEntitiesColumnsRange = (diagramEntities: DiagramEntity[]): number[] => {
    if (diagramEntities.length > 0) {
        const [min, max] = getMinMaxRowValuesOfEntities(diagramEntities);
        if (min === max) {
            return [min - 2, min, min + 1, min + 2];
        }
        if (max - min === 1) {
            return [min - 1, min, max, max + 1];
        }
        if (max - min === 2) {
            return [min - 1, min, min + 1, max];
        }
        if (max - min > 2) {
            return range(min, max + 1);
        }
    }

    return range(4);
};

export const setEntitiesRowsRange = (diagramEntities: DiagramEntity[]): number[] => {
    if (diagramEntities.length > 0) {
        const [min, max] = getMinMaxColumnValuesOfEntities(diagramEntities);
        if (min === max) {
            return [min - 2, min, min + 1, min + 2];
        }
        if (max - min === 1) {
            return [min - 1, min, max, max + 1];
        }
        if (max - min === 2) {
            return [min - 1, min, min + 1, max];
        }
        if (max - min > 2) {
            return range(min, max + 1);
        }
    }

    return range(4);
};

export const shouldSetStartPositionOfDiagram = (btiSettings: BTISettings[]): boolean => {
    let rowsAmount: number = 0;

    forEach(btiSettings, bti => {
        rowsAmount += bti.rowsRange.length;
    });

    return rowsAmount > 16;
};

export const filterEntitiesByBtiId = <T extends ModuleSlot | GroupSlot | Fork | DiagramEntity>(
    diagramEntities: T[],
    baseTimeIntervalId: string,
): T[] | [] => {
    if (diagramEntities.length === 0 || !baseTimeIntervalId) {
        return [];
    }
    return filter(
        diagramEntities,
        diagramEntity => diagramEntity.spaceBaseTimeIntervalId === baseTimeIntervalId,
    ) || [];
};

export const checkOutAdditionalEntitiesOnTheTop = ({
    diagramEntities,
    rowsRange,
    columnsRange,
}: BTICheck):
boolean => {
    let result: boolean = false;

    const numberOfFirstRow = columnsRange[0];
    const firstNumberOfColumns = rowsRange[0];

    forEach(diagramEntities, entity => {
        for (
            let columnNumber = firstNumberOfColumns;
            columnNumber < columnsRange.length;
            columnNumber += 1
        ) {
            if (entity.row === numberOfFirstRow && entity.column === columnNumber) {
                result = true;
            }
        }
    });

    return result;
};

export const checkOutAdditionalEntitiesOnTheBottom = ({
    diagramEntities,
    rowsRange,
    columnsRange,
}: BTICheck): boolean => {
    let result: boolean = false;

    const indexOfBottomRow = columnsRange.length - 1;
    const numberOfBottomRow = columnsRange[indexOfBottomRow];
    const firstNumberOfColumns = rowsRange[0];

    forEach(diagramEntities, entity => {
        for (
            let columnNumber = firstNumberOfColumns;
            columnNumber < columnsRange.length;
            columnNumber += 1
        ) {
            if (entity.row === numberOfBottomRow && entity.column === columnNumber) {
                result = true;
            }
        }
    });

    return result;
};

export const checkOutAdditionalEntitiesOnTheLeft = ({
    diagramEntities,
    rowsRange,
    columnsRange,
}: BTICheck): boolean => {
    let result: boolean = false;

    const numberOfLeftColumn = rowsRange[0];
    const firstNumberOfColumn = columnsRange[0];

    forEach(diagramEntities, entity => {
        for (
            let rowNumber = firstNumberOfColumn;
            rowNumber < rowsRange.length;
            rowNumber += 1
        ) {
            if (entity.row === rowNumber && entity.column === numberOfLeftColumn) {
                result = true;
            }
        }
    });

    return result;
};

export const checkOutAdditionalEntitiesOnTheRight = ({
    diagramEntities,
    rowsRange,
    columnsRange,
}: BTICheck): boolean => {
    let result: boolean = false;
    const indexOfLastRowElement = rowsRange.length - 1;
    const numberOfLastRowElement = rowsRange[indexOfLastRowElement];
    const firstNumberOfColumn = columnsRange[0];

    forEach(diagramEntities, entity => {
        for (
            let rowNumber = firstNumberOfColumn;
            rowNumber < columnsRange.length;
            rowNumber += 1
        ) {
            if (entity.row === rowNumber && entity.column === numberOfLastRowElement) {
                result = true;
            }
        }
    });

    return result;
};

export const removeEmptySlots = (btis: BTISettings[]): BTISettings[] => btis.map(bti => {
    const diagramEntities = createArrayFromDiagramEntities({
        moduleSlots: bti.moduleSlots,
        groupSlots: bti.groupSlots,
        forks: bti.forks,
    });

    return {
        ...bti,
        rowsRange: setEntitiesRowsRange(diagramEntities),
        columnsRange: setEntitiesColumnsRange(diagramEntities),
    };
});

export const createArrayFromDiagramEntities = ({
    moduleSlots = [],
    groupSlots = [],
    forks = [],
}: DiagramEntities): DiagramEntity[] => [
    ...moduleSlots.map(slot => ({
        id: slot.id,
        spaceBaseTimeIntervalId: slot.spaceBaseTimeIntervalId,
        row: slot.row,
        column: slot.column,
        nextSlots: slot.nextSlots,
        nextForks: slot.nextForks,
        nextGroupSlots: slot.nextGroupSlots,
    })),
    ...groupSlots.map(group => ({
        id: group.id,
        spaceBaseTimeIntervalId: group.spaceBaseTimeIntervalId,
        row: group.row,
        column: group.column,
        nextSlots: group.nextSlots,
        nextForks: group.nextForks,
        nextGroupSlots: group.nextGroupSlots,
    })),
    ...forks.map(fork => ({
        id: fork.id,
        spaceBaseTimeIntervalId: fork.spaceBaseTimeIntervalId,
        row: fork.row,
        column: fork.column,
        nextSlots: fork.nextSlots,
        nextForks: fork.nextForks,
        nextGroupSlots: fork.nextGroupSlots,
    })),
];

export const findFork = (btis: BTISettings[],
    id: string,
    spaceBaseTimeIntervalId: string): Fork | undefined => {
    const targetBti: BTISettings | undefined = find(btis, { id: spaceBaseTimeIntervalId });
    return find(targetBti?.forks, { id });
};

export const updateForkSettingInput = (forks: Fork[]): UpdateForkInput[] => forks.map(fork => ({
    id: fork.id,
    spaceBaseTimeIntervalId: fork.spaceBaseTimeIntervalId,
    row: fork.row,
    column: fork.column,
    nextSlots: fork.nextSlots.map(
        nextSlot => ({
            id: nextSlot.id,
            spaceBaseTimeIntervalId: nextSlot.spaceBaseTimeIntervalId,
            row: nextSlot.row,
            column: nextSlot.column,
        }),
    ) || [],
    nextForks: fork.nextForks.map(
        nextFork => ({
            id: nextFork.id,
            spaceBaseTimeIntervalId: nextFork.spaceBaseTimeIntervalId,
            row: nextFork.row,
            column: nextFork.column,
        }),
    ) || [],
    nextGroupSlots: fork.nextGroupSlots.map(
        nextGroupSlot => ({
            id: nextGroupSlot.id,
            spaceBaseTimeIntervalId: nextGroupSlot.spaceBaseTimeIntervalId,
            row: nextGroupSlot.row,
            column: nextGroupSlot.column,
        }),
    ) || [],
}));

export const findBtiIndex = (
    btis: BTISettings[],
    spaceBaseTimeIntervalId: string | undefined,
): number => findIndex(
    btis,
    { id: spaceBaseTimeIntervalId },
);
