import React, { useEffect, useState } from 'react';
import { nanoid } from 'nanoid';

import { Accordion } from '@common/Accordion';
import {
    SpaceParameters,
    WeekDaysRussian,
    StudentWorkload as IStudentWorkLoad, IntervalWithWeek, DaySchedule, WorkingInterval,
} from '@admin/EducationPeriodPage/EducationPeriodPageInterfaces';

import { StudentWorkload } from '@admin/EducationPeriodPage/EducationPeriodDaySHeduleEdit/StudentWorkLoad';
import {
    WeekDayInterval,
} from '@admin/EducationPeriodPage/EducationPeriodDaySHeduleEdit/WeekDayIntervals/WeekDayInterval';
import { educationPeriodStore } from '@admin/EducationPeriodPage/EducationPeriodStore';

import classes from './EducationPeriodDayScheduleEdit.module.scss';

interface Props {
    spaceParameters: SpaceParameters;
    intervalsWithWeek: IntervalWithWeek[];
}

type WeekDayInRussian = (keyof typeof WeekDaysRussian);

const allWeekDays: WeekDayInRussian[] = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

export function EducationPeriodDayScheduleEdit({
    spaceParameters,
    intervalsWithWeek,
}: Props) {
    const [weekIntervals, setWeekIntervals] = useState<
    IntervalWithWeek[]
    >(intervalsWithWeek);

    const [studentWorkLoad, setStudentWorkLoad] = useState<
    IStudentWorkLoad
    >(spaceParameters.studentWorkload);

    useEffect(() => {
        educationPeriodStore.setUpdateStudentWorkLoad(studentWorkLoad);
        educationPeriodStore.setUpdateDaysSchedule(getDaysScheduleFromWeekIntervals(weekIntervals));
    }, [studentWorkLoad, weekIntervals]);

    return (
        <div className={classes.educationPeriodSchedule}>
            <Accordion
                isOpen
            >
                <div className={classes.educationPeriodSchedule__header}>
                    График работы
                </div>

                <div className={classes.educationPeriodSchedule__items}>

                    <div className={classes.educationPeriodSchedule__schedule}>

                        <div className={classes.educationPeriodSchedule__interval}>
                            {
                                weekIntervals.map((weekInterval) => (
                                    <WeekDayInterval
                                        key={weekInterval.interval.id}
                                        week={weekInterval.week}
                                        interval={weekInterval.interval}
                                        weekIntervals={weekIntervals}
                                        setWeekIntervals={setWeekIntervals}
                                    />
                                ))
                            }
                            <button
                                type="button"
                                className={classes.educationPeriodSchedule__addInterval}
                                onClick={() => setWeekIntervals(
                                    [...weekIntervals, getDefaultWeekInterval(allWeekDays)],
                                )}
                            >
                                Добавить промежуток
                            </button>
                        </div>
                    </div>
                </div>
            </Accordion>
            <Accordion
                isOpen
            >
                <div className={classes.educationPeriodSchedule__header}>
                    Занятость студента
                </div>
                <div className={classes.educationPeriodSchedule__items}>

                    <div className={classes.educationPeriodSchedule__schedule}>
                        <div className={classes.educationPeriodSchedule__interval}>
                            <StudentWorkload
                                workLoad={studentWorkLoad}
                                setStudentWorkLoad={setStudentWorkLoad}
                            />
                        </div>
                    </div>
                </div>
            </Accordion>
        </div>
    );
}

function getDaysScheduleFromWeekIntervals(weekIntervals: IntervalWithWeek[]): DaySchedule[] {
    const filteredWeekIntervalActiveDays = getFilteredWeekIntervalActiveDays(weekIntervals);

    return allWeekDays.map(
        (weekDay) => {
            const currentDayAllIntervals: WorkingInterval[] = [];

            filteredWeekIntervalActiveDays.forEach((weekInterval) => {
                if (checkIfIntervalHasCurrentDay(weekInterval, weekDay)) {
                    currentDayAllIntervals.push({
                        ...weekInterval.interval,
                        id: nanoid(21),
                    });
                }
            });

            return {
                weekDay,
                workingIntervals: currentDayAllIntervals,
            };
        },
    );
}

function getFilteredWeekIntervalActiveDays(weekIntervals: IntervalWithWeek[]) {
    return weekIntervals.map((weekInterval) => (
        {
            ...weekInterval,
            week: weekInterval.week.filter((dayInWeek) => dayInWeek.isActive),
        }
    ));
}

function checkIfIntervalHasCurrentDay(weekInterval: IntervalWithWeek, weekDay: WeekDayInRussian) {
    return weekInterval.week.some((dayInWeek) => dayInWeek.weekDay === weekDay);
}

function getDefaultWeekInterval(weekDaysRussia: WeekDayInRussian[]) {
    const id = nanoid(21);
    const defaultWeekInterval: IntervalWithWeek = {
        interval: {
            start: '09:00:00+00',
            end: '18:00:00+00',
            id,
        },
        week: weekDaysRussia.map((day) => {
            if (day === 'Sunday') {
                return {
                    weekDay: day,
                    isActive: false,
                };
            }
            return {
                weekDay: day,
                isActive: true,
                interval: {
                    start: '09:00:00+00',
                    end: '18:00:00+00',
                    id,
                },
            };
        }),
    };
    return defaultWeekInterval;
}
