import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';

import { ActionButton, ActionTypeOfButton } from '@common/ActionButton';
import { ArrowIcon } from '@common/svg/ArrowIcon';

import store from '../../module-assessment-config-store';
import classes from './ModuleAssessment.module.scss';
import { AnswerType, StudentAssessmentStatus } from '../../graphql-query-types';
import { OpenEndedQuestion } from './OpenEndedQuestion';
import { ClosedTypeQuestionTable, MobileClosedTypeQuestionTable } from './ClosedTypeQuestionTable';
import { Answer, Assessment, AssessmentError, TeacherAssessmentError, TeacherMeetingInstance } from './interface';
import { StudentTeacherAssessment } from './TeacherAssessment';

interface Props {
    teacherMeetingInstances?: TeacherMeetingInstance[];
    moduleAssessment: Assessment;
    moduleAssessmentStatus?: StudentAssessmentStatus;
    onModuleAssessmentChange(answer: Answer): void;
    onTeacherAssessmentChange(teacherId: string, answer: Answer): void;
    finishStudentModuleAssessment(): void;
    moduleAssessmentErrors: AssessmentError[] | undefined;
    teacherAssessmentErrors: TeacherAssessmentError[] | undefined;
}

function ModuleAssessmentTab({
    teacherMeetingInstances,
    moduleAssessment,
    moduleAssessmentStatus,
    moduleAssessmentErrors,
    teacherAssessmentErrors,
    onModuleAssessmentChange,
    onTeacherAssessmentChange,
    finishStudentModuleAssessment,
}: Props) {
    const isFinishButtonDisabled = !!teacherAssessmentErrors?.length
        || !!moduleAssessmentErrors?.length;
    const [isOpened, setIsOpened] = useState<boolean>(false);

    useEffect(() => {
        if (teacherAssessmentErrors?.length) {
            setIsOpened(true);
        }
    }, [teacherAssessmentErrors]);

    return (
        <div className={classes['module-assessment-tab']}>
            <DesktopModuleAssessment
                moduleAssessment={moduleAssessment}
                moduleAssessmentStatus={moduleAssessmentStatus}
                moduleAssessmentErrors={moduleAssessmentErrors}
                onModuleAssessmentChange={onModuleAssessmentChange}
            />
            <MobileModuleAssessment
                moduleAssessment={moduleAssessment}
                moduleAssessmentStatus={moduleAssessmentStatus}
                moduleAssessmentErrors={moduleAssessmentErrors}
                onModuleAssessmentChange={onModuleAssessmentChange}
            />
            {teacherMeetingInstances && (
                <div className={classes['teacher-assessment']}>
                    <div className={classes['module-assessment-tab__title']}>
                        Ваша оценка преподавателей
                    </div>
                    {teacherMeetingInstances.map((teacherMeetingInstance) => {
                        const teacherAssessment = moduleAssessment.teacherAssessments?.find(
                            ({ teacherId }) => teacherId === teacherMeetingInstance.teacher.id,
                        );
                        const teacherAssessmentError = teacherAssessmentErrors?.find(
                            ({ teacherId }) => teacherId === teacherMeetingInstance.teacher.id,
                        );
                        return (
                            <StudentTeacherAssessment
                                key={teacherMeetingInstance.teacher.id}
                                teacherAssessment={teacherAssessment}
                                moduleAssessmentStatus={moduleAssessmentStatus}
                                onTeacherAssessmentChange={onTeacherAssessmentChange}
                                teacherMeetingInstance={teacherMeetingInstance}
                                teacherAssessmentErrors={teacherAssessmentError}
                            />
                        );
                    })}
                </div>
            )}
            {teacherMeetingInstances && (
                <div className={classes['teacher-assessment_mobile']}>
                    <div
                        className={classes['module-assessment__accordion']}
                        onClick={() => setIsOpened(!isOpened)}
                    >
                        <div className={classes['module-assessment-tab__title']}>
                            ОЦЕНКА ПРЕПОДАВАТЕЛЕЙ
                        </div>
                        <ArrowIcon
                            isOpen={isOpened}
                            className={classes.eventView_arrow}
                        />
                    </div>
                    {isOpened && teacherMeetingInstances.map((teacherMeetingInstance) => {
                        const teacherAssessment = moduleAssessment.teacherAssessments?.find(
                            ({ teacherId }) => teacherId === teacherMeetingInstance.teacher.id,
                        );
                        const errors = teacherAssessmentErrors?.find(({ teacherId }) => (
                            teacherId === teacherMeetingInstance.teacher.id
                        ));
                        return (
                            <StudentTeacherAssessment
                                key={teacherMeetingInstance.teacher.id}
                                teacherAssessment={teacherAssessment}
                                moduleAssessmentStatus={moduleAssessmentStatus}
                                onTeacherAssessmentChange={onTeacherAssessmentChange}
                                teacherMeetingInstance={teacherMeetingInstance}
                                teacherAssessmentErrors={errors}
                            />
                        );
                    })}
                </div>
            )}
            {moduleAssessmentStatus === StudentAssessmentStatus.active ? (
                <div className={classes['module-assessment-tab__finish-button-container']}>
                    {isFinishButtonDisabled && (
                        <div className={classes['module-assessment-finish-button__error-message']}>
                            Для завершения оценки исправьте ошибки
                        </div>
                    )}
                    <ActionButton
                        className={classes['module-assessment-finish-button']}
                        type="button"
                        actionType={ActionTypeOfButton.PRIMARY}
                        onClick={finishStudentModuleAssessment}
                        // disabled={isFinishButtonDisabled}
                    >
                        Завершить оценку модуля
                    </ActionButton>
                </div>
            ) : (
                <div className={classes['module-assessment-tab__title_finished']}>
                    Оценка модуля успешно завершена!
                </div>
            )}
        </div>
    );
}

interface ModuleAssessmentProps {
    moduleAssessment: Assessment;
    moduleAssessmentStatus?: StudentAssessmentStatus;
    onModuleAssessmentChange(answer: Answer): void;
    moduleAssessmentErrors: AssessmentError[] | undefined;
}

const DesktopModuleAssessment = observer(({
    moduleAssessment,
    moduleAssessmentStatus,
    moduleAssessmentErrors,
    onModuleAssessmentChange,
}: ModuleAssessmentProps) => {
    const { moduleOpenEndedAssessmentQuestions, moduleClosedTypeAssessmentQuestions } = store;
    return (
        <div className={classes['module-assessment']}>
            <div className={classes['module-assessment-tab__title']}>
                Ваша оценка учебного модуля
            </div>
            {moduleClosedTypeAssessmentQuestions && (
                <ClosedTypeQuestionTable
                    assessmentAnswers={moduleAssessment.answers}
                    assessmentStatus={moduleAssessmentStatus}
                    onChange={onModuleAssessmentChange}
                    assessmentQuestions={moduleClosedTypeAssessmentQuestions}
                    errors={moduleAssessmentErrors}
                />
            )}
            {moduleOpenEndedAssessmentQuestions?.map(question => {
                const answer = moduleAssessment.answers?.find(
                    ({ questionId }) => question.id === questionId,
                );
                const error = moduleAssessmentErrors?.find(
                    ({ questionId }) => question.id === questionId,
                );
                return (
                    <OpenEndedQuestion
                        key={question.id}
                        question={question}
                        answer={answer?.value}
                        assessmentStatus={moduleAssessmentStatus}
                        onChange={onModuleAssessmentChange}
                        error={error}
                    />
                );
            })}
            {moduleAssessmentErrors && (
                <div className={classes['module-assessment-tab__errors-container']}>
                    {moduleAssessmentErrors?.map(({ questionId, message }) => {
                        const question = store.getAssessmentQuestion(questionId);
                        return question && (
                            <div
                                key={questionId}
                                className={classes['module-assessment-tab__error']}
                            >
                                {question.answerType === AnswerType.option && (
                                    <>
                                        <span>
                                            Критерий
                                        </span> «{question.title}»:{' '}
                                    </>
                                )}
                                <span className={classes['module-assessment-tab__error-message']}>
                                    {message}
                                </span>
                            </div>
                        );
                    })}
                </div>
            )}
        </div>
    );
});

const MobileModuleAssessment = observer(({
    moduleAssessment,
    moduleAssessmentStatus,
    moduleAssessmentErrors,
    onModuleAssessmentChange,
}: ModuleAssessmentProps) => {
    const { moduleOpenEndedAssessmentQuestions, moduleClosedTypeAssessmentQuestions } = store;
    const [isOpened, setIsOpened] = useState<boolean>(false);

    useEffect(() => {
        if (moduleAssessmentErrors?.length) {
            setIsOpened(true);
        }
    }, [moduleAssessmentErrors]);

    return (
        <div className={classes['module-assessment_mobile']}>
            <div
                className={classes['module-assessment__accordion']}
                onClick={() => setIsOpened(!isOpened)}
            >
                <div className={classes['module-assessment-tab__title']}>
                    ОЦЕНКА МОДУЛЯ
                </div>
                <ArrowIcon
                    isOpen={isOpened}
                    className={classes.eventView_arrow}
                />
            </div>
            {isOpened && (
                <>
                    {moduleClosedTypeAssessmentQuestions && (
                        <MobileClosedTypeQuestionTable
                            assessmentAnswers={moduleAssessment.answers}
                            assessmentStatus={moduleAssessmentStatus}
                            onChange={onModuleAssessmentChange}
                            assessmentQuestions={moduleClosedTypeAssessmentQuestions}
                            errors={moduleAssessmentErrors}
                        />
                    )}
                    {moduleOpenEndedAssessmentQuestions?.map(question => {
                        const answer = moduleAssessment.answers?.find(
                            ({ questionId }) => question.id === questionId,
                        );
                        const error = moduleAssessmentErrors?.find(
                            ({ questionId }) => question.id === questionId,
                        );
                        return (
                            <OpenEndedQuestion
                                key={question.id}
                                question={question}
                                answer={answer?.value}
                                assessmentStatus={moduleAssessmentStatus}
                                onChange={onModuleAssessmentChange}
                                error={error}
                            />
                        );
                    })}
                    {moduleAssessmentErrors && (
                        <div className={classes['module-assessment-tab__errors-container']}>
                            {moduleAssessmentErrors?.map(({ questionId, message }) => {
                                const question = store.getAssessmentQuestion(questionId);
                                return question && (
                                    <div
                                        key={questionId}
                                        className={classes['module-assessment-tab__error']}
                                    >
                                        {question.answerType === AnswerType.option && (
                                            <>
                                                <span>
                                                    Критерий
                                                </span> «{question.title}»:{' '}
                                            </>
                                        )}
                                        <span className={classes['module-assessment-tab__error-message']}>
                                            {message}
                                        </span>
                                    </div>
                                );
                            })}
                        </div>
                    )}
                </>
            )}
        </div>
    );
});

export const ModuleAssessment = observer(ModuleAssessmentTab);
