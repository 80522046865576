import React, { FormEvent, useEffect, useState } from 'react';
import { range } from 'lodash';

import { BorderCrossIcon } from '@common/svg';

import { skillTypesData_skillTypes } from '../../../../deprecated-graphql-query-types';

import classes from './SkillAddWithoutNest.module.scss';

interface SkillFormData {
    id: string
    name: string
    description: string
    maxLevel: number
}

interface Props {
    skillType: skillTypesData_skillTypes;
    formList: SkillFormData[];
    indexForm: number;
    removeForm(id: number): void;
    changeInputData: (index: number, type: string, text: string) => void;
    nameValidate: boolean;
}

export function SkillAddWithoutNest({
    skillType,
    formList,
    indexForm,
    removeForm,
    changeInputData,
    nameValidate,
}: Props): JSX.Element {
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [maxLevel, setMaxLevel] = useState('1');
    const [nameDirty, setNameDirty] = useState(false);

    useEffect(() => {
        if (nameValidate) {
            setNameDirty(true);
        }
    }, [nameValidate]);

    const onNameChange = (e: any) => {
        e.preventDefault();
        if (e.target.value) {
            setNameDirty(false);
        }
        setName(e.currentTarget.value);
        changeInputData(
            indexForm,
            'name',
            e.currentTarget.value,
        );
    };

    const onDescriptionChange = (e: FormEvent<HTMLTextAreaElement>) => {
        e.preventDefault();
        setDescription(e.currentTarget.value);
        changeInputData(
            indexForm,
            'description',
            e.currentTarget.value,
        );
    };

    const onMaxLevelChange = (e: FormEvent<HTMLSelectElement>) => {
        e.preventDefault();
        setMaxLevel(e.currentTarget.value);
        changeInputData(
            indexForm,
            'maxLevel',
            e.currentTarget.value,
        );
    };

    const blurHandler = (e: any) => {
        if (e.target.name === 'name' && e.currentTarget.value.length === 0) {
            setNameDirty(true);
        }
    };
    return (
        <form className={classes.skillAddWithoutNest__form}>
            <label className={classes.skillAddWithoutNest__label}>
                <div style={{ color: nameDirty ? 'red' : 'black' }}>
                    Название {(skillType.name.genitiveSingular)?.toLowerCase()}:
                </div>
                <input
                    type="text"
                    name="name"
                    className={!nameDirty
                        ? classes.skillAddWithoutNest__input
                        : classes.skillAddWithoutNest__input_border_red
                    }
                    value={name || formList[indexForm].name}
                    onChange={onNameChange}
                    onBlur={(e) => blurHandler(e)}
                />
            </label>
            <label className={classes.skillAddWithoutNest__label}>
                Описание {(skillType.name.genitiveSingular)?.toLowerCase()}:
                <textarea
                    name="description"
                    className={classes.skillAddWithoutNest__textarea}
                    value={description || formList[indexForm].description}
                    onChange={onDescriptionChange}
                />
            </label>
            <label className={classes.skillAddWithoutNest__selectLabel}>
                Укажите максимальный уровень развития:
                <select
                    name="maxLevel"
                    className={classes.skillAddWithoutNest__select}
                    value={formList[indexForm].maxLevel || maxLevel}
                    onChange={onMaxLevelChange}
                >
                    {range(1, Number(skillType.properties!.maxLevel) + 1)
                        .map(value => (
                            <option key={value} value={value}>
                                {value}
                            </option>
                        ))}
                </select>
            </label>
            {indexForm > 0 && (
                <BorderCrossIcon
                    size={25}
                    handler={() => removeForm(indexForm)}
                    className={classes.blackCrossSkillAddForm}
                />
            )}
        </form>
    );
}
