import React from 'react';
import { KeyValue } from '@common/KeyValue';

import * as TeacherModel from '../../models';
import classes from './NonWorkingHours.module.scss';

interface Props {
    nonWorkingHours: TeacherModel.NonWorkingHours[];
}

export function NonWorkingHours({ nonWorkingHours }: Props): JSX.Element {
    return (
        <div className={classes.nonWorkingHours}>
            <div className={classes.nonWorkingHours__titiles}>
                <div className={classes.nonWorkingHours__title}>
                    Запланированная нерабочая занятость:
                </div>
                {nonWorkingHours.length === 0 && (
                    <div className={classes.nonWorkingHours__subtitle}>Не назначена</div>
                )}
            </div>
            <div className={classes.nonWorkingHours__preview}>
                {nonWorkingHours!.map((item) => (
                    <div className={classes.nonWorkingHours__item} key={item.id}>
                        <KeyValue
                            title={item.reason ? `${item.reason}: ` : ''}
                        >
                            {`${item.from!.slice(0, 10)}
                    ${item.from! !== '00:00' ? item.from!.slice(11, 16) : ''} —
                    ${item.to!.slice(0, 10)}
                    ${item.to! !== '23:59' ? item.to!.slice(11, 16) : ''}`}
                        </KeyValue>
                    </div>
                ))}
            </div>
        </div>
    );
}
