import React, { useState } from 'react';
import { format } from 'date-fns';
import { sortBy } from 'lodash';

import { ApolloError } from '@apollo/client';
import { Alert } from '@common/Alert';
import { useUrlQuery } from '@common/hooks';
import { IconDeprecated } from '@common';
import { Autosuggest } from '@common/Autosuggest';
import { Meeting, MeetingForReplace } from '@admin/ScheduleGenerationPage/types';
import { MeetingConfirm } from '@admin/ScheduleGenerationPage/MeetingDetails/MeetingConfirm';
import { useUpdateMeetingMutation } from '../apolloHooks';

import classes from './MeetingEdit.module.scss';

interface Props {
    meeting: Meeting
    meetingsForReplace?: MeetingForReplace[]
    meetingInstanceId: string;
    date: string;

    setMeeting(newMeeting: Meeting): void;
    setEditable(editable: boolean): void;
}

type MeetingForReplaceState = {
    id: string;
    name: string;
    meetingId: string;
    meetingInstanceId?: string;
};

export const MeetingEdit = ({
    meeting,
    meetingsForReplace,
    meetingInstanceId,
    date,
    setMeeting,
    setEditable,
}: Props): JSX.Element => {
    const [newMeeting, setNewMeeting] = useState({
        id: meetingInstanceId,
        name: meeting.topic,
        meetingInstanceId: undefined,
        meetingId: meeting.id,
    } as MeetingForReplaceState);
    const [showConfirm, setShowConfirm] = useState(false);
    const [errors, setErrors] = useState<JSX.Element[]>([]);

    const { getUrlQuery } = useUrlQuery();

    function setErrorsFunction(error: ApolloError) {
        const normalError = error.message.split('Cannot return null');
        setErrors([...errors, (<Alert message={normalError[0]} time={6000} />)]);
    }

    const { updateMeeting } = useUpdateMeetingMutation(
        getUrlQuery('detailedEventId') as string, getUrlQuery('detailedEventType') as string, setErrorsFunction,
    );

    const availableMeetingsList: MeetingForReplaceState[] = sortBy(meetingsForReplace ?? [],
        item => item.meeting.order).reduce(
        (acc, availableMeeting) => {
            if (availableMeeting.meetingInstances.length) {
                const meetingInstances = availableMeeting.meetingInstances.map(meetingInstance => {
                    const startDate = new Date(Number(meetingInstance.startDate));
                    const endDate = new Date(Number(meetingInstance.endDate));
                    const formattedStartDate = format(startDate, 'dd.MM.yyyy HH-mm');
                    const formattedEndDate = format(endDate, 'dd.MM.yyyy HH-mm');
                    return {
                        id: meetingInstance.id,
                        format: meeting.format,
                        name: `${availableMeeting.meeting.topic} (формат: ${meeting.format.name}) ${formattedStartDate} - ${formattedEndDate}`,
                        meetingId: availableMeeting.meeting.id,
                        meetingInstanceId: meetingInstance.id,
                    };
                });

                return [...acc, ...meetingInstances];
            }
            return [...acc, {
                id: availableMeeting.meeting.id,
                name: `${availableMeeting.meeting.topic} (формат: ${meeting.format.name})`,
                meetingId: availableMeeting.meeting.id,
            }];
        },
        [] as MeetingForReplaceState[],
    ) ?? [];

    const updateMeetingTopic = async ({ newMeetingId, meetingInstanceToSwapId }: {
        newMeetingId?: string; meetingInstanceToSwapId?: string;
    }) => {
        let data;
        if (meetingInstanceToSwapId) {
            data = await updateMeeting({ meetingInstanceId, meetingInstanceToSwapId });
        } else {
            data = await updateMeeting({ meetingInstanceId, newMeetingId });
        }
        if (data.data) {
            setEditable(false);
            setMeeting({
                ...meeting,
                topic: [...(meetingsForReplace ?? []), { meeting }]
                    ?.find(({ meeting: { id } }) => id === newMeeting.meetingId)?.meeting.topic ?? '',
            });
        }
        setShowConfirm(false);
    };

    return (
        <>
            <div className={classes.meetingEdit}>
                <div className={classes.suggestion}>
                    <Autosuggest
                        onChange={meetingForReplace => setNewMeeting(meetingForReplace)}
                        options={availableMeetingsList}
                        formatOption={option => option.name}
                        selectedOptions={[newMeeting]}
                        isSuggestableOnFocus
                    />
                    <IconDeprecated
                        id="check"
                        click={() => {
                            if (newMeeting.meetingInstanceId) {
                                setShowConfirm(true);
                            } else {
                                updateMeetingTopic({ newMeetingId: newMeeting.meetingId });
                            }
                        }}
                        className={classes.meetingEdit__check}
                    />
                </div>
                <div className={classes.suggestion__list}>{newMeeting.name}</div>
            </div>
            {showConfirm && (
                <MeetingConfirm
                    currentMeeting={meeting}
                    currentMeetingDate={date}
                    newMeeting={newMeeting}
                    onSwapBtnClick={() => updateMeetingTopic({
                        meetingInstanceToSwapId: newMeeting.meetingInstanceId,
                    })}
                    onOutClick={() => setShowConfirm(false)}
                />
            )}
            {errors}
        </>
    );
};
