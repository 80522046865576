import { useMutation } from '@apollo/client';
import { PUBLISH_TRAJECTORIES } from '@common/qraphql/mutations';
import { GET_TRAJECTORIES } from '@common/qraphql/queries';

export function usePublishTrajectoriesMutation(spaceId: string) {
    const [publishTrajectories, { error: publishError }] = useMutation(PUBLISH_TRAJECTORIES, {
        refetchQueries: [{ query: GET_TRAJECTORIES, variables: { spaceId } }],
    });
    return {
        publishTrajectories: (ids: string[]) => publishTrajectories({ variables: { ids } }),
        publishError,
    };
}
