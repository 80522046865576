import React, { useEffect, useState } from 'react';

import { IconDeprecated, Switcher } from '@common';
import { Autosuggest } from '@common/Autosuggest';
import { RoomType, ScheduleEvent } from '@admin/ScheduleGenerationPage/types';

import {
    UpdateRoomFunc,
    useLoadAvailableOnlineRoomsLazyQuery,
} from '../apolloHooks';

import classes from './Room.module.scss';

interface Props {
    event: ScheduleEvent;
    edibleRoom: boolean;
    setEdibleRoom: React.Dispatch<React.SetStateAction<boolean>>;

    updateRoom: UpdateRoomFunc;
    ViewMode(input: { name: string; link: string }): JSX.Element;
}

export function OnlineRoom({
    event,
    edibleRoom,
    setEdibleRoom,
    ViewMode,
    updateRoom,
}: Props): JSX.Element {
    const { startTime, endTime, onlineRoom } = event;
    const name = onlineRoom?.url;
    const isEdible = edibleRoom || !onlineRoom;

    const {
        loadAvailableOnlineRooms, availableOnlineRooms = [],
    } = useLoadAvailableOnlineRoomsLazyQuery();
    const [newOnlineRoom, setNewOnlineRoom] = useState(
        onlineRoom?.id ? { id: onlineRoom.id, name } : null,
    );
    const onlineRoomId = newOnlineRoom?.id;
    const [isOnlyAvailableRoom, setOnlyAvailableRoom] = useState(false);

    const options = availableOnlineRooms.map(certainRoom => ({
        id: certainRoom.id,
        name: certainRoom.url,
    }));

    useEffect(() => {
        if (isEdible) {
            loadAvailableOnlineRooms(
                startTime,
                endTime,
                isOnlyAvailableRoom,
            );
        }
    }, [isEdible]);

    return (
        <>
            {!isEdible
                ? <ViewMode name={name ?? ''} link={`/university/online/${onlineRoomId}?`} />
                : (
                    <div className={classes.room__name}>
                        <Switcher
                            label="Только свободные помещения"
                            checked={isOnlyAvailableRoom}
                            onClick={
                                () => {
                                    loadAvailableOnlineRooms(
                                        startTime,
                                        endTime,
                                        !isOnlyAvailableRoom,
                                    );
                                    setOnlyAvailableRoom((oldState) => !oldState);
                                }
                            }
                        />
                        <div className={classes.room__suggestion}>
                            <Autosuggest
                                options={options}
                                onChange={item => setNewOnlineRoom(item)}
                                selectedOptions={newOnlineRoom ? [newOnlineRoom] : []}
                                formatOption={item => item.name ?? ''}
                                isSuggestableOnFocus
                            />
                            <IconDeprecated
                                id="check"
                                click={() => {
                                    setEdibleRoom(false);
                                    updateRoom({
                                        roomId: newOnlineRoom!.id,
                                        meetingInstanceId: event.id,
                                        roomType: RoomType.Online,
                                    });
                                }}
                                className={classes.room__penIcon}
                            />
                        </div>
                        {newOnlineRoom && (
                            <div className={classes.room__suggestion__list}>
                                {newOnlineRoom.name}
                            </div>
                        )}
                    </div>
                )
            }
        </>
    );
}
