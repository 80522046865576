import React from 'react';

export function ArrowSvg(): JSX.Element {
    return (
        <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.51585 14.8158C7.7997 15.0862 8.24982 15.0755 8.52018 14.7913C8.79089 14.5071 8.77952 14.0573 8.49567 13.7866L3.04522 8.61717L17.2426 8.61717C17.6348 8.61717 17.9531 8.29886 17.9531 7.90665C17.9531 7.51444 17.6348 7.19612 17.2426 7.19612L3.06796 7.19612L8.49567 2.02633C8.77988 1.75562 8.79089 1.30586 8.52018 1.02165C8.38056 0.875279 8.19334 0.801385 8.00576 0.801385C7.82955 0.801385 7.65334 0.866398 7.51585 0.99749L1.31686 6.90196C1.04828 7.17019 0.900495 7.52687 0.900495 7.90665C0.900495 8.28642 1.04828 8.64311 1.3293 8.92341L7.51585 14.8158Z" fill="url(#gachiArrow)" />
            <defs>
                <linearGradient id="gachiArrow" x1="-0.332225" y1="8.716" x2="17.1849" y2="0.510543" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#0B42DC" />
                    <stop offset="0.0416667" stopColor="#0B42DC" />
                    <stop offset="0.130208" stopColor="#0B42DC" />
                    <stop offset="0.734375" stopColor="#57B2C6" />
                    <stop offset="0.932292" stopColor="#EED660" />
                </linearGradient>
            </defs>
        </svg>
    );
}
