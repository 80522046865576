import { ApolloError, gql, useMutation } from '@apollo/client';
import { OnlineRoom } from './interfaces';

const UPDATE_ONLINE_ROOMS = gql`
    mutation updateOnlineRooms($input: UpdateOnlineRoomInput!) {
        updateOnlineRoom(updateOnlineRoomInput: $input) {
            id
            url
            capacity
        }
    }
`;

export function useUpdateOnlineRoomMutation(
    onCompleted?: () => void,
    onError?: (error: ApolloError) => void,
) {
    const [updateOnlineRoom, { loading }] = useMutation(
        UPDATE_ONLINE_ROOMS,
        { onCompleted, onError },
    );
    return {
        updateOnlineRoom: (onlineRoom: OnlineRoom) => updateOnlineRoom(
            { variables: { input: onlineRoom } },
        ),
        loading,
    };
}
