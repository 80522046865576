/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: isInviteLinkValid
// ====================================================

export interface isInviteLinkValid {
  isValidExternalModuleInvitation: boolean;
}

export interface isInviteLinkValidVariables {
  invitationId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: saveExternalModule
// ====================================================

export interface saveExternalModule_saveExternalModule_reviews_reviewer {
  __typename: "User";
  id: string;
  firstName: string;
  lastName: string;
  patronymic: string | null;
}

export interface saveExternalModule_saveExternalModule_reviews {
  __typename: "ExternalModuleReview";
  id: string;
  message: string;
  reviewer: saveExternalModule_saveExternalModule_reviews_reviewer;
}

export interface saveExternalModule_saveExternalModule {
  __typename: "ExternalModule";
  id: string;
  status: ExternalModuleStatus;
  ownerFullName: string;
  ownerEmail: string;
  ownerInfo: string | null;
  name: string;
  description: string | null;
  minStudentCount: number | null;
  maxStudentCount: number | null;
  maxWaveCount: number | null;
  creditCount: number | null;
  syllabusReference: string | null;
  reviews: saveExternalModule_saveExternalModule_reviews[];
}

export interface saveExternalModule {
  saveExternalModule: saveExternalModule_saveExternalModule;
}

export interface saveExternalModuleVariables {
  saveExternalModuleInput: SaveExternalModuleInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getExternalModule
// ====================================================

export interface getExternalModule_externalModule {
  __typename: "ExternalModule";
  id: string;
  status: ExternalModuleStatus;
  ownerFullName: string;
  ownerEmail: string;
  ownerInfo: string | null;
  name: string;
  description: string | null;
  minStudentCount: number | null;
  maxStudentCount: number | null;
  maxWaveCount: number | null;
  creditCount: number | null;
  syllabusReference: string | null;
}

export interface getExternalModule {
  externalModule: getExternalModule_externalModule;
}

export interface getExternalModuleVariables {
  externalModuleInput: ExternalModuleInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum ExternalModuleStatus {
  approved = "approved",
  deleted = "deleted",
  draft = "draft",
  revision = "revision",
  verification = "verification",
}

export interface ExternalModuleInput {
  externalModuleId: string;
  invitationId?: string | null;
}

export interface SaveExternalModuleInput {
  id: string;
  invitationId: string;
  status: ExternalModuleStatus;
  ownerFullName: string;
  ownerEmail: string;
  name: string;
  description?: string | null;
  minStudentCount?: number | null;
  maxStudentCount?: number | null;
  maxWaveCount?: number | null;
  creditCount?: number | null;
  ownerInfo?: string | null;
  syllabusReference?: string | null;
  link: string;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
