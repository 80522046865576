import React from 'react';

import { FullName, Roles } from './Table/userCellInfo';
import { Tool, ToolBar } from '../ToolBar';
import { columnConfigs, Table } from './Table';
import classes from './Table/table.module.scss';
import { Type } from './apollo-types';
import { useUserList } from './useUserList';
import { UserRow } from './Table/UserRow';

export function AllRolesTable() {
    const {
        error,
        loading,
        users,
        isMoreButtonVisible,
        onMoreButtonClick,
        onChangeSearchParams,
        onSelect,
        isUserSelected,
        selectedUsers,
        refetchUserList,
        onSelectAll,
        clearPreviousSelected,
        isAllSelected,
    } = useUserList(Type.all);

    if (error) {
        return (
            <div>Произошла ошибка: {error.graphQLErrors[0]?.message || error.message}</div>
        );
    }

    return (
        <>
            <div className={classes.toolBar}>
                <ToolBar
                    tools={[Tool.STATUS_UPDATE]}
                    selectedUsers={selectedUsers}
                    refetchUserList={refetchUserList}
                />
            </div>

            <Table
                setSearchParams={onChangeSearchParams}
                columnConfigs={[columnConfigs.fullName, columnConfigs.role]}
                onShowMore={onMoreButtonClick}
                isMoreButtonVisible={isMoreButtonVisible}
                clearPreviousSelected={clearPreviousSelected}
                onSelectAll={onSelectAll}
                isAllSelected={isAllSelected}
                loading={loading}
            >
                {users.map((user) => (
                    <UserRow
                        key={user.id}
                        userId={user.id}
                        onChange={() => onSelect(user)}
                        checked={isUserSelected(user)}
                    >
                        <FullName user={user} />
                        <Roles user={user} />
                    </UserRow>
                ))}
            </Table>
        </>
    );
}
