import React, { useState } from 'react';

import { nanoid } from 'nanoid';
import { useHistory } from 'react-router-dom';

import { BorderPlusIcon } from '@common/svg';
import { ActionButton } from '@common/ActionButton';

import classes from './FormatsForm.module.scss';

import { FormatFormInput } from './FormatFormInput';

interface Format {
    id: string;
    name: string;
}

interface Props {
    createMeetingFormat: (id: string, name: string) => void;
}

export function FormatsForm({
    createMeetingFormat,
}: Props): JSX.Element {
    const history = useHistory();
    const formatsFormItem = {
        id: nanoid(),
        name: '',
    };
    const [formList, setFormList] = useState([formatsFormItem]);

    const addOneMoreForm = () => {
        const newFormatsFormItem = {
            id: nanoid(),
            name: '',
        };
        setFormList([...formList, newFormatsFormItem]);
    };

    const deleteForm = (id: number) => {
        const formatForms = formList;
        formatForms.splice(id, 1);
        setFormList([...formatForms]);
    };

    const saveFormats = () => {
        formList.forEach((item: Format) => {
            const name = item.name[0].toUpperCase() + item.name.slice(1);
            createMeetingFormat(item.id, name);
        });
        history.push('/university/formats/list');
    };
    return (
        <div className={classes.formatForm}>
            <div className={classes.formatForm__container}>
                <div className={classes.formatForm__title}>
                    Добавить формат встреч
                </div>
                <div className={classes.formatForm__subtitle}>
                    Название формата:
                </div>
                <ul className={classes.formatForm__list}>
                    {formList.map((item: Format, index: number) => (
                        <li
                            key={item.id}
                            className={classes.formatForm__item}
                        >
                            <FormatFormInput
                                index={index}
                                deleteForm={deleteForm}
                                formList={formList}
                                setFormList={setFormList}
                            />
                        </li>
                    ))}
                </ul>
                <div
                    className={classes.formatForm__iconContainer}
                    onClick={() => addOneMoreForm()}
                >
                    <BorderPlusIcon />
                    <div className={classes.formatForm__iconText}>
                        Еще формат
                    </div>
                </div>
                <div className={classes.formatForm__button}>
                    <ActionButton
                        onClick={() => saveFormats()}
                    >
                        Сохранить
                    </ActionButton>
                </div>
            </div>
        </div>
    );
}
