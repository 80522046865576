import React, { ReactNode } from 'react';
import map from 'lodash/map';
import { find } from 'lodash';
import { observer } from 'mobx-react-lite';
import cn from 'classnames';
import Diagram from '../store/Diagram';
import { EmptySlot } from '../EmptySlot';
import { Slot } from '../Slot';
import {
    Fork, GroupSlot, ModuleSlot, UpdateSlotDiagramInput,
} from '../subSpaceTypes';
import { SlotGroup } from '../SlotGroup';
import classes from './BTIDiagram.module.scss';
import { DiagramFork } from '../DiagramFork';

interface Props {
    subspaceId: string
    hasBothBorders: boolean
    hasRightBorder: boolean
    spaceBaseTimeIntervalId: string
    subSpaceSlots: ModuleSlot[]
    rowsRange: number[]
    columnsRange: number[]
    btiSlots: ModuleSlot[]
    btiForks: Fork[]
    btiGroupSlots: GroupSlot[]
    name: string
    order: number
    minCreditCount: string
    maxCreditCount: string

    updateModuleSlots(moduleSlotInput: UpdateSlotDiagramInput): void
}

export const BTIDiagram = observer(({
    subspaceId,
    hasBothBorders,
    hasRightBorder,
    spaceBaseTimeIntervalId,
    rowsRange,
    columnsRange,
    btiSlots,
    btiForks,
    btiGroupSlots,
    name,
    order,
    minCreditCount,
    maxCreditCount,
}: Props): JSX.Element => {
    const {
        slotSettings, groupSlotSettings, forkSettings,
    } = Diagram;
    const { isSlotCanMove, moveSlotTo } = slotSettings;
    const { isGroupSlotCanMove, moveGroupSlotTo } = groupSlotSettings;
    const { isForkCanMove, moveForkTo } = forkSettings;

    if (isSlotCanMove && slotSettings.id && moveSlotTo) {
        Diagram.setSlotCantMove();
    }

    if (isGroupSlotCanMove && groupSlotSettings.id && moveGroupSlotTo) {
        Diagram.setGroupSlotCantMove();
    }

    if (isForkCanMove && forkSettings.id && moveForkTo) {
        Diagram.setForkCantMove();
    }

    const renderDiagramEntities = (
        subspaceBaseTimeIntervalId: string,
        [row, col]: [number, number],
        slots: ModuleSlot[],
        groupSlots: GroupSlot[],
        forks: Fork[],
    ): ReactNode => {
        const isSlotHere = find(
            slots,
            foundSlot => (
                foundSlot.row === row
                && foundSlot.column === col
            ),
        );

        const isGroupSlotHere = find(
            groupSlots,
            foundGroupSlot => (
                foundGroupSlot.row === row
                && foundGroupSlot.column === col
            ),
        );

        const isForkHere = find(
            forks,
            foundFork => (
                foundFork.row === row
                && foundFork.column === col
            ),
        );

        const isEntity = (isSlotHere && (
            <Slot moduleSlot={isSlotHere} />
        ))
            || (isGroupSlotHere && (
                <SlotGroup groupSlot={isGroupSlotHere} />
            ))
            || (isForkHere && (
                <DiagramFork
                    subspaceId={subspaceId}
                    fork={isForkHere}
                />
            ))
            || null;

        return (
            <EmptySlot
                emptySlotPosition={[
                    row, col]
                }
                baseTimeIntervalId={subspaceBaseTimeIntervalId}
            >
                {isEntity}
            </EmptySlot>
        );
    };

    return (
        <div className={classes.BTIDiagram}>
            <div className={cn(classes.BTIDiagram__slots, {
                [classes.BTIDiagram__slots_hasBothBorders]: hasBothBorders,
                [classes.BTIDiagram__slots_hasRightBorder]: hasRightBorder,
            })}
            >
                {map(columnsRange, row => (
                    <div className={classes.BTIDiagram__row}>
                        {map(rowsRange, col => renderDiagramEntities(
                            spaceBaseTimeIntervalId,
                            [row, col],
                            btiSlots,
                            btiGroupSlots,
                            btiForks,
                        ))}
                    </div>
                ))}

            </div>
            <div className={classes.BTIDiagram__description}>
                <span className={classes.BTIDiagram__order}>
                    {order}
                </span>
                <span className={classes.BTIDiagram__name}>
                    {name}
                </span>
                <span className={classes.BTIDiagram__creditCount}>
                    {minCreditCount}/{maxCreditCount}
                </span>
            </div>
        </div>

    );
});
