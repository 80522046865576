import { differenceBy, flatten, max } from 'lodash';
import map from 'lodash/map';
import {
    ArrowEntityCoordinates,
    BTISettings,
    DiagramEntity,
    Fork,
    GroupSlot,
    ModuleSlot,
} from '../../subSpaceTypes';

const SLOT_WIDTH = 24;
const SLOT_HEIGHT = 24;
const SLOT_MARGIN = 12;
const SLOTS_INDENT = SLOT_MARGIN * 2;

export const calculateWidthOfBTI = (rowsRange: number[]): number => {
    const rowWidth = rowsRange.length * SLOT_WIDTH;
    const amountOfSlotsIndents = rowsRange.length - 1;
    const indentBetweenSlotsWidth = amountOfSlotsIndents * SLOTS_INDENT;
    const widthOFBTIIndents = SLOT_MARGIN * 2;
    return rowWidth + indentBetweenSlotsWidth + widthOFBTIIndents;
};

export const calculateWidthOfCanvas = (btiSettings: BTISettings[]): number => {
    let resultsWidth = 0;
    for (let index = 0; index < btiSettings.length; index += 1) {
        resultsWidth += calculateWidthOfBTI(btiSettings[index].rowsRange);
    }

    return resultsWidth;
};

export const calculateHeightOfBTI = (columnsRange: number[]): number => {
    const columnsHeight = columnsRange.length * SLOT_HEIGHT;
    const amountOfSlotsIndents = columnsRange.length - 1;
    const indentBetweenSlotsWidth = amountOfSlotsIndents * SLOTS_INDENT;
    const heightOFBTIIndents = SLOT_MARGIN * 2;
    return columnsHeight + indentBetweenSlotsWidth + heightOFBTIIndents;
};

export const calculateMaxHeightOfCanvas = (btiSettings: BTISettings[]): number => {
    let resultsMaxHeight = 0;
    let currentHeight = 0;
    for (let index = 0; index < btiSettings.length; index += 1) {
        currentHeight = calculateHeightOfBTI(btiSettings[index].columnsRange);

        if (currentHeight > resultsMaxHeight) {
            resultsMaxHeight = currentHeight;
        }
    }

    return resultsMaxHeight;
};

export const findXOfUpperLeftCornerSlotCorner = (
    rowsRange: number[],
    column: number,
): number => {
    const amountOfSlotsBeforeCurrentSlot = rowsRange.indexOf(column);
    const amountOfIndentsBetweenSlots = amountOfSlotsBeforeCurrentSlot * SLOTS_INDENT;
    return amountOfSlotsBeforeCurrentSlot * SLOT_WIDTH
        + SLOT_MARGIN
        + amountOfIndentsBetweenSlots;
};

export const findYOfUpperLeftCornerSlotCorner = (
    columnsRange: number[],
    row: number,
): number => {
    const amountOfSlotsBeforeCurrentSlot = columnsRange.indexOf(row);
    const amountOfIndentsBetweenSlots = amountOfSlotsBeforeCurrentSlot * SLOTS_INDENT;
    return amountOfSlotsBeforeCurrentSlot * SLOT_HEIGHT
        + SLOT_MARGIN
        + amountOfIndentsBetweenSlots;
};

export const findCoordinatesOfUpperLeftSlotCorner = <T extends ModuleSlot | GroupSlot | Fork>(
    btis: BTISettings[],
    btiIndex: number,
    diagramEntity: T,
): [number, number] => {
    const { rowsRange, columnsRange } = btis[btiIndex];
    const { row, column } = diagramEntity;

    const extraWidth = calculateExtraAbscissaWidth(btis, btiIndex);
    const extraHeight = calculateExtraOrdinateHeight(btis, btiIndex);
    const x = findXOfUpperLeftCornerSlotCorner(rowsRange, column);
    const y = findYOfUpperLeftCornerSlotCorner(columnsRange, row);
    return [
        x + extraWidth,
        y + extraHeight,
    ];
};

export const calculateExtraAbscissaWidth = (
    btiSettings: BTISettings[],
    currentIndexOfBTI: number,
): number => {
    let extraWidth = 0;

    if (currentIndexOfBTI === 0) {
        return extraWidth;
    }
    if (currentIndexOfBTI > 0) {
        for (let currentIndex = 0; currentIndex < currentIndexOfBTI; currentIndex += 1) {
            const { rowsRange } = btiSettings[currentIndex];
            extraWidth += calculateWidthOfBTI(rowsRange);
        }
    }

    return extraWidth;
};

export const calculateExtraOrdinateHeight = (
    btiSettings: BTISettings[],
    currentIndexOfBTI: number,
): number => {
    const maxColumnsHeight: number = max(map(
        btiSettings,
        bti => calculateHeightOfBTI(bti.columnsRange),
    )) || 0;
    const currentBTIHeight: number = calculateHeightOfBTI(
        btiSettings[currentIndexOfBTI].columnsRange,
    );

    return maxColumnsHeight - currentBTIHeight;
};

export const changeCoordinatesOfStartAndEndPoints = <T extends ModuleSlot | GroupSlot | Fork,
    N extends ModuleSlot | GroupSlot | Fork,
// eslint-disable-next-line max-len
>({ startPoint, endPoint }: ArrowEntityCoordinates, previousEntity: T, nextEntity: N, nextEntityBtiIndex?: number): ArrowEntityCoordinates => {
    const [x1, y1] = startPoint;
    const [x2, y2] = endPoint;
    const previousX1 = previousEntity.row;
    const previousY1 = previousEntity.column;
    const nextX2 = nextEntity.row;
    const nextY2 = nextEntity.column;

    if (nextEntityBtiIndex) {
        return {
            startPoint: [x1 + SLOT_WIDTH, y1 + SLOT_HEIGHT / 2],
            endPoint: [x2, y2 + SLOT_HEIGHT / 2],
        };
    }

    if (previousY1 > nextY2) {
        return {
            startPoint: [x1, y1 + SLOT_HEIGHT / 2],
            endPoint: [x2 + SLOT_WIDTH, y2 + SLOT_HEIGHT / 2],
        };
    }

    if (previousY1 === nextY2 && previousX1 > nextX2) {
        return {
            startPoint: [x1 + SLOT_WIDTH / 2, y1],
            endPoint: [x2 + SLOT_WIDTH / 2, y2 + SLOT_HEIGHT],
        };
    }

    if (previousY1 === nextY2 && previousX1 < nextX2) {
        return {
            startPoint: [x1 + SLOT_WIDTH / 2, y1 + SLOT_HEIGHT],
            endPoint: [x2 + SLOT_WIDTH / 2, y2],
        };
    }

    if (previousY1 < nextY2) {
        return {
            startPoint: [x1 + SLOT_WIDTH, y1 + SLOT_HEIGHT / 2],
            endPoint: [x2, y2 + SLOT_HEIGHT / 2],
        };
    }

    return {
        startPoint,
        endPoint,
    };
};

export const changeSlotsCoordinatesToLeftSide = (
    [x, y]: [number, number],
): [number, number] => [x, y + SLOT_HEIGHT / 2];

const findNextEntitiesOfEntity = (entities: DiagramEntity[]):DiagramEntity[] => {
    let nextEntitiesOfEntity: DiagramEntity[] = [];

    entities.forEach(entity => {
        if (entity.nextSlots.length > 0) {
            nextEntitiesOfEntity = [...nextEntitiesOfEntity, ...entity.nextSlots];
        }
        if (entity.nextGroupSlots.length > 0) {
            nextEntitiesOfEntity = [...nextEntitiesOfEntity, ...entity.nextGroupSlots];
        }
        if (entity.nextForks.length > 0) {
            nextEntitiesOfEntity = [...nextEntitiesOfEntity, ...entity.nextForks];
        }
    });

    return nextEntitiesOfEntity;
};

export const findBtiEntitiesWithNextEntities = (btiSettings: BTISettings[]): DiagramEntity[] => {
    let entitiesWithNextEntity: DiagramEntity[] = [];

    btiSettings.forEach(bti => {
        entitiesWithNextEntity = [
            ...entitiesWithNextEntity,
            ...findNextEntitiesOfEntity(bti.moduleSlots),
            ...findNextEntitiesOfEntity(bti.groupSlots),
            ...findNextEntitiesOfEntity(bti.forks),
        ];
    });

    return entitiesWithNextEntity;
};

export const findEntitiesWithoutIncomingRelations = (
    btiSettings: BTISettings[],
): DiagramEntity[] => {
    const allBtiEntities: DiagramEntity[] = [
        ...flatten(map(btiSettings, bti => (bti.moduleSlots))) || [],
        ...flatten(map(btiSettings, bti => (bti.groupSlots))) || [],
        ...flatten(map(btiSettings, bti => (bti.forks))) || [],
    ];

    const entitiesWithNextEntities: DiagramEntity[] = findBtiEntitiesWithNextEntities(btiSettings);

    return differenceBy(allBtiEntities, entitiesWithNextEntities, 'id');
};

export const changeCoordinatesToLefMiddleSide = (
    [x, y]: [number, number],
): [number, number] => [x, y + SLOT_HEIGHT / 2];
