import React from 'react';
import cn from 'classnames';

import { TabsOption, TabsRedesign } from '@common/TabsRedesign/TabsRedesign';
import { TabsRedesignWithHiddenTabs } from '@common/TabsRedesignWithHiddenTabs/TabsRedesignWithHiddenTabs';
import classes from './HeaderRedesign.module.scss';

interface Props {
    title: string;
    titleClassname?: string;
    tabsOptions?: TabsOption[];
    imageSrc?: string;
    titleOnClickEvent?: () => void;
    withHiddenTabs?: boolean;
    imageOnClickEvent?: () => void;
}

export function HeaderRedesign(
    {
        title,
        titleClassname = '',
        tabsOptions,
        imageSrc,
        titleOnClickEvent,
        withHiddenTabs,
        imageOnClickEvent,
    }: Props,
) {
    return (
        <div
            className={classes.header}
        >

            <div
                className={cn(classes.header__image, {
                    [classes.header__image_hasImage]: imageSrc,
                })}
            >
                {
                    imageSrc && (
                        <button
                            className={classes.header__image__btn}
                            type="button"
                            onClick={() => {
                                if (imageOnClickEvent) {
                                    imageOnClickEvent();
                                }
                            }}
                        >
                            <img
                                src={imageSrc}
                                alt="content"
                            />
                        </button>
                    )
                }
            </div>

            <div className={classes.header__content}>
                <button
                    type="button"
                    className={cn(classes.header__title, {
                        [titleClassname]: titleClassname,
                    })}
                    onClick={() => {
                        if (titleOnClickEvent) {
                            titleOnClickEvent();
                        }
                    }}
                >
                    {title}
                </button>

                {tabsOptions && (
                    <div className={classes.header__tabs}>
                        {
                            withHiddenTabs ? (
                                <TabsRedesignWithHiddenTabs
                                    tabsOptions={tabsOptions}
                                />
                            ) : (
                                <TabsRedesign
                                    tabsOptions={tabsOptions}
                                />
                            )
                        }
                    </div>
                )}
            </div>

        </div>
    );
}
