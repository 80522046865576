import React from 'react';
import cn from 'classnames';

import {
    formatDate,
    getBtiInstance,
} from '@admin/EducationPeriodPage/EducationPeriodDates/EducationPeriodDatesView/EducationPeriodDatesAccordion';
import {
    RefetchGetSpaces,
    SelectionStatusNew,
    SpaceBaseTimeInterval,
    SpaceBaseTimeIntervalParameters,
} from '@admin/EducationPeriodPage/EducationPeriodPageInterfaces';
import { NavLink } from 'react-router-dom';
import { SelectionPointButtonRedesign } from '@common/SelectionPointButtonRedesign';
import { ButtonName } from '@common/SelectionPointButtonRedesign/buttonHooks';

import classes from './AccordionHeader.module.scss';

interface Props {
    name: string;
    baseTimeInterval: SpaceBaseTimeInterval;
    isAccorionOpen?: boolean;
    parameters?: SpaceBaseTimeIntervalParameters;
    refetchGetSpaces?: RefetchGetSpaces;
}

export function AccordionHeader(
    {
        baseTimeInterval,
        name,
        isAccorionOpen,
        parameters,
        refetchGetSpaces,
    }: Props,
) {
    const btiInstance = getBtiInstance(baseTimeInterval, parameters);
    const isScheduleVisible = btiInstance
            && btiInstance.selectionStatus === SelectionStatusNew.selectionFinished;
    const isSelectionStart = btiInstance
        && btiInstance.selectionStatus === SelectionStatusNew.selectionEnabled;

    return (
        <div className={classes.header}>

            <div
                className={cn(
                    classes.header__item, classes.header__item_title,
                )}
            >
                {baseTimeInterval.order}
                {' '}
                {name}
            </div>

            <div
                className={cn(
                    classes.header__item, classes.header__item_date,
                )}
            >
                {
                    !parameters?.spaceBaseTimeIntervalStart
                    && !parameters?.spaceBaseTimeIntervalEnd
                        ? (
                            <>
                                {
                                    !isAccorionOpen && 'не задано'
                                }
                            </>
                        ) : (
                            <>
                                {
                                    formatDate(parameters?.spaceBaseTimeIntervalStart)
                                }
                                -
                                {
                                    formatDate(parameters?.spaceBaseTimeIntervalEnd)
                                }
                            </>
                        )
                }
            </div>

            <div className={classes.header__item}>
                {
                    // eslint-disable-next-line no-nested-ternary
                    btiInstance && !isScheduleVisible && isSelectionStart ? (
                        <SelectionPointButtonRedesign
                            buttonName={ButtonName.START_SELECTION}
                            BTIId={btiInstance.id ?? ''}
                            refetchGetSpaces={refetchGetSpaces}
                        />
                    ) : (btiInstance && isScheduleVisible) ? (
                        <NavLink
                            onClick={(event) => !isScheduleVisible && event.preventDefault()}
                            to={`/base-time-interval/${btiInstance?.id}/schedule`}
                        >
                            <div
                                className={classes.header__schedule}
                            >
                                <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M8.66667 10.1111C9.46441 10.1111 10.1111 9.46441 10.1111 8.66667C10.1111 7.86892 9.46441 7.22222 8.66667 7.22222C7.86892 7.22222 7.22222 7.86892 7.22222 8.66667C7.22222 9.46441 7.86892 10.1111 8.66667 10.1111Z" fill="#0B42DC" />
                                    <path fillRule="evenodd" clipRule="evenodd" d="M2.16667 0C0.97005 0 0 0.97005 0 2.16667V10.8333C0 12.03 0.97005 13 2.16667 13H10.8333C12.03 13 13 12.03 13 10.8333V2.16667C13 0.97005 12.03 0 10.8333 0H2.16667ZM1.44444 10.8333V3.61111H11.5556V10.8333C11.5556 11.2322 11.2322 11.5556 10.8333 11.5556H2.16667C1.76779 11.5556 1.44444 11.2322 1.44444 10.8333Z" fill="#0B42DC" />
                                </svg>

                                <span
                                    className={classes.header__schedule_txt}
                                >
                                    Расписание {baseTimeInterval.order} семестр
                                </span>
                            </div>
                        </NavLink>
                    ) : <div />
                }
            </div>

        </div>
    );
}
