import React, { useState } from 'react';

import { BorderCrossIcon } from '@common/svg';
import { Dialog } from '@common/Dialog';

import { EquipmentCardDialog } from './EquipmentCardDialog';
import classes from './EquipmentCardItem.module.scss';

interface Equipment {
    id: string;
    name: string;
    unit: string;
}

interface Props {
    item: Equipment;
    editState: boolean;
    removeConsumableEquipment: (id: string) => void;
    updateConsumableEquipment: (
        id: string,
        name: string,
        unit: string,
    ) => void;
}

export function EquipmentCardItem({
    item,
    editState,
    removeConsumableEquipment,
    updateConsumableEquipment,
}: Props): JSX.Element {
    const [dialog, toggleDialog] = useState(false);
    return (
        <div className={classes.equipmentCardItem}>
            <div
                className={classes.equipmentCardItem__title}
                onClick={editState ? () => toggleDialog(!dialog) : () => null}
            >
                {`${item.name} (${item.unit})`}
            </div>
            {editState && (
                <BorderCrossIcon
                    handler={() => removeConsumableEquipment(item.id)}
                    size={18}
                    className={classes.equipmentCardItem__icon}
                />
            )}
            {dialog && (
                <Dialog
                    id="portal-root"
                    dom={(
                        <EquipmentCardDialog
                            item={item}
                            dialog={dialog}
                            toggleDialog={toggleDialog}
                            updateConsumableEquipment={updateConsumableEquipment}
                        />
                    )}
                />
            )}
        </div>
    );
}
