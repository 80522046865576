import React, { useMemo } from 'react';
import { format } from 'date-fns';
import { groupBy, map } from 'lodash';
import { TeacherAssignment } from '../TeacherAssignment/TeacherAssignment';
import {
    filteredTeacherAssignmentInstances_filteredTeacherAssignmentInstances,
} from '../../TeacherAssignmentsApolloTypes';
import classes from './TeacherAssignmentsList.module.scss';

interface Props {
    assignments?: filteredTeacherAssignmentInstances_filteredTeacherAssignmentInstances[],
    onClick: () => void;
    // eslint-disable-next-line max-len
    setAssignment: (assignment: filteredTeacherAssignmentInstances_filteredTeacherAssignmentInstances) => void,
}

export function TeacherAssignmentsList({
    assignments,
    onClick,
    setAssignment,
}: Props) {
    const deadlineDict = useMemo(() => {
        const formatDeadlines = assignments?.map((assignment) => ({
            ...assignment,
            deadline: assignment.assignmentInstance.deadline
                ? format(new Date(assignment.assignmentInstance.deadline), 'dd.MM')
                : 'Не определен',
        }));

        return groupBy(formatDeadlines, 'deadline');
    }, [assignments]);

    return (
        <>
            {
                map(deadlineDict, (assignment, deadline) => (
                    <div className={classes.article} key={deadline}>
                        <div className={classes.article__date}>
                            {deadline}
                        </div>

                        <TeacherAssignment
                            onClick={onClick}
                            assignments={assignment}
                            setAssignment={setAssignment}
                        />
                    </div>
                ))
            }
        </>
    );
}
