import React from 'react';
import { useOnlineRoomsQuery } from '@admin/OnlinePage/useOnlineRoomsQuery';
import { Loader } from '@common/Loader';
import { OnlineRoomList } from './OnlineRoomList';

function OnlinePageApollo() {
    const { onlineRooms, loading, refetch } = useOnlineRoomsQuery();
    return (
        <>
            {loading && <Loader />}
            {onlineRooms && (
                <OnlineRoomList
                    onlineRooms={onlineRooms}
                    refetchList={refetch}
                />
            )}
        </>
    );
}

export { OnlinePageApollo as OnlinePage };
