import React, { useEffect } from 'react';
import moment from 'moment';

import { getHoursRange } from './helpers';

import classes from './TimeRuler.module.scss';

interface Props {
    startTime?: number;
    setIsDidMount?: React.Dispatch<React.SetStateAction<boolean>>;
}

export function TimeRuler({ startTime = 8, setIsDidMount }: Props): JSX.Element {
    function getHourView(hour: number): string {
        return moment(hour, 'H').format('HH[:]mm');
    }
    useEffect(() => setIsDidMount && setIsDidMount(true), []);
    return (
        <div className={classes.timeRuler}>
            <div className={classes.timeRuler__hours}>
                {getHoursRange().map((hour) => (
                    <div id={startTime === hour ? 'startTime' : ''} className={classes.timeRuler__time} key={hour}>
                        {getHourView(hour)}
                    </div>
                ))}
            </div>
        </div>
    );
}
