import React from 'react';
import classnames from 'classnames';

import classes from './Pendant.module.scss';

export enum PendantType {
    Success = 'success',
    Warning = 'warning',
    Danger = 'danger',
}

interface Props {
    type: PendantType;
    children: JSX.Element | string;
}

export const Pendant = ({ type, children }: Props) => (
    <div className={classnames(classes.pendant, classes[`pendant_${type}`])}>
        {children}
    </div>
);
