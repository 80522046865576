import React from 'react';
import { Subspace } from '../../interfaces';
import { DiagramSubspace } from './DiagramSubspace';
import { SubspacesStatuses } from '../../services/subspacesView';

interface Props {
    subspaces: Subspace[];
    subspaceTypeName: string;
    subspaceTypeColor: string;
    isElectiveSubspaceType: boolean;
    subspacesStatuses: SubspacesStatuses;
    hoverSubspace(subspaceId: string): void;
    clearDiagramView(): void;
    showTrajectory(subspaceId: string): void;
    isEditMode: boolean;
    updateTempConnections(subspaceId: string): void;
}

export function DiagramSubspaces({
    subspaces,
    subspaceTypeName,
    subspaceTypeColor,
    isElectiveSubspaceType,
    subspacesStatuses,
    hoverSubspace,
    clearDiagramView,
    showTrajectory,
    isEditMode,
    updateTempConnections,
}: Props): JSX.Element {
    const contentSubspaces = subspaces.map(subspace => {
        const { id } = subspace;
        const subspaceStatus = subspacesStatuses[id];

        return (
            <DiagramSubspace
                key={id}
                subspace={subspace}
                subspaceTypeName={subspaceTypeName}
                subspaceTypeColor={subspaceTypeColor}
                isElectiveSubspaceType={isElectiveSubspaceType}
                subspaceStatus={subspaceStatus}
                hoverSubspace={hoverSubspace}
                clearDiagramView={clearDiagramView}
                showTrajectory={showTrajectory}
                isEditMode={isEditMode}
                updateTempConnections={updateTempConnections}
            />
        );
    });

    return <div className="subspaces">{contentSubspaces}</div>;
}
