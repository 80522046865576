import React from 'react';
import cn from 'classnames';

import classes from './GradientText.module.scss';

interface Props {
    text: string;
    className?: string
}

export function GradientText({ text, className = '' }: Props): JSX.Element {
    return (
        <div
            className={cn(classes.gradientText, {
                [className]: className,
            })}
        >
            {text}
        </div>
    );
}
