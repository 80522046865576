import React, { Dispatch, SetStateAction } from 'react';

import cn from 'classnames';
import classes from './ModuleSkillsListSwitchParams.module.scss';

interface Props {
    paramsState: boolean;
    setParamsState: Dispatch<SetStateAction<boolean>>;
}

export function ModuleSkillsListSwitchParams({
    paramsState,
    setParamsState,
}: Props):JSX.Element {
    return (
        <div className={classes.moduleSkillsListSwitchParams}>
            <ul className={classes.moduleSkillsListSwitchParams__list}>
                <li className={classes.moduleSkillsListSwitchParams__item}>
                    <div
                        className={cn(classes.moduleSkillsListSwitchParams__params, {
                            [classes.moduleSkillsListSwitchParams__params_active]: paramsState,
                        })}
                        onClick={() => setParamsState(true)}
                    >
                        Входные
                    </div>
                </li>
                <li className={classes.moduleSkillsListSwitchParams__item}>
                    <div
                        className={cn(classes.moduleSkillsListSwitchParams__params, {
                            [classes.moduleSkillsListSwitchParams__params_active]: !paramsState,
                        })}
                        onClick={() => setParamsState(false)}
                    >
                        Выходные
                    </div>
                </li>
            </ul>
        </div>
    );
}
