import React, { useLayoutEffect } from 'react';
import cn from 'classnames';
import { ApolloError } from '@apollo/client';
import { observer } from 'mobx-react-lite';
import { find, flatten, map } from 'lodash';
import { SlotParameters } from '../SlotParameters';
import { Modules } from '../Modules';
import {
    ModuleSlot,
    SubspaceSkill,
    UpdateSlotDiagramInput,
    UpdateModuleSlotSkillInput,
} from '../subSpaceTypes';
import { skills_skills, skillTypesData_skillTypes } from '../../deprecated-graphql-query-types';
import Diagram from '../store/Diagram';
import classes from './SlotAndModuleParameters.module.scss';

interface Props {
    skillTypes: skillTypesData_skillTypes[];
    subspaceId: string
    allSkillsList: skills_skills[];
    prerequisiteSpecializedSkills?: SubspaceSkill[];
    outputSpecializedSkills?: SubspaceSkill[];
    prerequisiteSpecializedSkillsLoading: boolean;
    outputSpecializedSkillsLoading: boolean;
    prerequisiteSpecializedSkillsError?: ApolloError;
    outputSpecializedSkillsError?: ApolloError;
    subSpaceSlots?: ModuleSlot[]

    updateModuleSlotSkills(moduleSlotSkills: UpdateModuleSlotSkillInput): void;

    updateSlotDiagram(updateSlotDiagramInput: UpdateSlotDiagramInput): void
}

export const SlotAndModuleParameters = observer(({
    subspaceId,
    skillTypes,
    allSkillsList,
    prerequisiteSpecializedSkills = [],
    outputSpecializedSkills = [],
    prerequisiteSpecializedSkillsLoading,
    outputSpecializedSkillsLoading,
    prerequisiteSpecializedSkillsError,
    outputSpecializedSkillsError,
    updateModuleSlotSkills,
    updateSlotDiagram,
}: Props): JSX.Element => {
    const { slotSettings, diagramSettings, btiSettings } = Diagram;
    const { showSlotParametersMode, showSuitableModules, editMode } = diagramSettings;
    const slots = flatten(map(btiSettings, bti => (bti.moduleSlots)));
    const slot: ModuleSlot | undefined = find(slots, { id: slotSettings.id });

    useLayoutEffect(() => {
        if (slot?.module) {
            Diagram.setShowSuitableModules();
        }
    }, [slotSettings.id]);

    return (
        <div className={classes.slotAndModuleParameters}>
            <div className={classes.slotAndModuleParameters__buttonGroup}>
                <button
                    className={cn(classes.slotStepButton, {
                        [classes.slotStepButton_active]: showSlotParametersMode,
                    })}
                    disabled={editMode || !slotSettings.id}
                    type="button"
                    onClick={() => {
                        if (!showSlotParametersMode) {
                            Diagram.setShowParametersSlotMode();
                        }
                    }}
                >
                    Параметры слота в пространстве
                </button>
                <button
                    className={cn(classes.moduleStepButton, {
                        [classes.moduleStepButton_active]:
                        showSuitableModules && slotSettings.id,
                    })}
                    disabled={editMode || !slotSettings.id}
                    type="button"
                    onClick={() => Diagram.setShowSuitableModules()}
                >
                    {!slot?.module && '/ Подходящие модули'}
                    {slot?.module && '/ Выбранный модуль'}
                </button>
            </div>

            {showSlotParametersMode && (
                <SlotParameters
                    prerequisiteSkills={slot?.prerequisiteSkills}
                    outputSkills={slot?.outputSkills}
                    skillTypes={skillTypes}
                    prerequisiteSpecializedSkills={prerequisiteSpecializedSkills}
                    outputSpecializedSkills={outputSpecializedSkills
                    }
                    prerequisiteSpecializedSkillsLoading={prerequisiteSpecializedSkillsLoading}
                    outputSpecializedSkillsLoading={outputSpecializedSkillsLoading}
                    prerequisiteSpecializedSkillsError={prerequisiteSpecializedSkillsError}
                    outputSpecializedSkillsError={outputSpecializedSkillsError}
                    updateModuleSlotSkills={updateModuleSlotSkills}
                />
            )}
            {(showSuitableModules && slot?.id && !editMode) && (
                <Modules
                    subspaceId={subspaceId}
                    slotId={slot.id}
                    skillTypes={skillTypes}
                    allSkillsList={allSkillsList}
                    updateSlotDiagram={updateSlotDiagram}
                />
            )}
        </div>
    );
});
