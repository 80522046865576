import React, { useState, useMemo, useEffect } from 'react';
import {
    Space,
    SubspaceType,
    Subspace,
    SubspacesTypesRectangles,
    EditorStatus,
} from '../interfaces';
import { DiagramTypes } from './diagramTypes/DiagramTypes';
import { DiagramConnections } from './DiagramConnections';
import { DiagramBackground } from './DiagramBackground';
import { DiagramView } from '../services/diagramView';
import { getValidConnectionId } from '../services/subspacesConnection';
import { ConnectionStatus, ConnectionsView } from '../services/connectionsView';

interface Props {
    space: Space;
    subspaceTypes: SubspaceType[];
    isEditMode: boolean;
    tempConnections: ConnectionsView;
    setTempConnections: React.Dispatch<React.SetStateAction<ConnectionsView>>;
    diagramView: DiagramView;
    setDiagramView: React.Dispatch<React.SetStateAction<DiagramView>>;
    subspaces: Subspace[];
    setEditorStatus: React.Dispatch<React.SetStateAction<EditorStatus>>;
}

export function Diagram({
    space: {
        spaceBaseTimeIntervals,
        baseTimeIntervalType,
    },
    subspaceTypes,
    isEditMode,
    tempConnections,
    setTempConnections,
    diagramView,
    setDiagramView,
    subspaces,
    setEditorStatus,
}: Props): JSX.Element {
    const [subspacesTypesRects, setSubspacesTypesRects] = useState<SubspacesTypesRectangles>({});
    const [trajectory, setTrajectory] = useState<string[]>([]);
    const [startSubspaceId, setStartSubspaceId] = useState<string | null>(null);
    const [diagramSize, setDiagramSize] = useState({ width: 0, height: 0 });

    const hasTrajectory = useMemo(() => trajectory.length > 0, [trajectory]);
    const electiveSubspacesTypesRects = useMemo(getElectiveTypesRects, [subspacesTypesRects]);
    const diagramStyle = useMemo(getDiagramStyle, [diagramSize]);

    useEffect(() => {
        if (isEditMode) {
            clearLinks();
        }
    }, [isEditMode]);

    useEffect(() => {
        if (!isEditMode) {
            setEditorStatus(EditorStatus.Disable);
            return;
        }

        const hasStartSubspaceId = startSubspaceId !== null;

        if (tempConnections.hasConnections()) {
            setEditorStatus(EditorStatus.HasFirstSelectedConnection);
        } else if (hasStartSubspaceId) {
            setEditorStatus(EditorStatus.HasFirstSelectedSubspace);
        } else if (!hasStartSubspaceId) {
            setEditorStatus(EditorStatus.BeginningOfWork);
        }
    }, [startSubspaceId, isEditMode, tempConnections]);

    function getDiagramStyle() {
        return {
            width: `${diagramSize.width}px`,
            height: `${diagramSize.height}px`,
        };
    }

    function getElectiveTypesRects() {
        return Object.keys(subspacesTypesRects).reduce((acc, id) => {
            const type: SubspaceType | undefined = subspaceTypes.find(t => t.id === id);

            if (!hasElectiveType(type)) {
                return { ...acc, [id]: subspacesTypesRects[id].copy() };
            }

            return acc;
        }, {});
    }

    function hasElectiveType(subspaceType: SubspaceType | undefined): boolean {
        return subspaceType ? subspaceType.subspaces.length === 1 : false;
    }

    function hoverSubspace(subspaceId: string): void {
        if (hasTrajectory || isEditMode) {
            return;
        }

        const view = diagramView.createAllLinks(subspaceId);
        setDiagramView(view);
    }

    function clearDiagramView(): void {
        if (hasTrajectory || isEditMode) {
            return;
        }

        const view = new DiagramView(subspaces);
        setDiagramView(view);
    }

    function showTrajectory(subspaceId: string): void {
        if (isEditMode) {
            return;
        }

        if (!hasTrajectory) {
            createLinks(subspaceId);
        } else {
            const trajectoryLength = trajectory.length;
            const trajectoryLeftId = trajectory[0];
            const trajectoryRightId = trajectory[trajectoryLength - 1];

            const toPushLink = diagramView.isTargetSubspace(trajectoryRightId, subspaceId);
            const toPopLink = trajectoryRightId === subspaceId && trajectoryLength > 1;
            const toClearLinks = trajectoryLength === 1 && trajectoryRightId === subspaceId;
            const toUnshiftLinks = diagramView.isTargetSubspace(subspaceId, trajectoryLeftId);
            const toShiftLinks = trajectoryLeftId === subspaceId && trajectoryLength > 1;

            if (toPushLink) {
                pushLink(trajectoryRightId, subspaceId);
            } else if (toPopLink) {
                const sourceId = trajectory[trajectory.length - 2];
                popLink(sourceId, subspaceId);
            } else if (toClearLinks) {
                clearLinks();
            } else if (toUnshiftLinks) {
                unshiftLinks(subspaceId, trajectoryLeftId);
            } else if (toShiftLinks) {
                const targetId = trajectory[1];
                shiftLinks(subspaceId, targetId);
            }
        }
    }

    function createLinks(subspaceId: string) {
        const newTrajectory = [subspaceId];
        const newView = diagramView.createLinks(subspaceId);
        setTrajectoryAndDiagramView(newTrajectory, newView);
    }

    function pushLink(sourceId: string, targetId: string) {
        const newTrajectory = [...trajectory, targetId];
        const newView = diagramView.pushLink(sourceId, targetId);
        setTrajectoryAndDiagramView(newTrajectory, newView);
    }

    function popLink(sourceId: string, targetId: string) {
        const newView = diagramView.popLink(sourceId, targetId);
        const newTrajectory = [...trajectory]; newTrajectory.pop();
        setTrajectoryAndDiagramView(newTrajectory, newView);
    }

    function clearLinks() {
        const newView = new DiagramView(subspaces);
        const newTrajectory: string[] = [];
        setTrajectoryAndDiagramView(newTrajectory, newView);
    }

    function unshiftLinks(sourceId: string, targetId: string) {
        const newTrajectory = [sourceId, ...trajectory];
        const newView = diagramView.unshiftLink(sourceId, targetId);
        setTrajectoryAndDiagramView(newTrajectory, newView);
    }

    function shiftLinks(sourceId: string, targetId: string) {
        const newTrajectory = [...trajectory]; newTrajectory.shift();
        const newView = diagramView.shiftLink(sourceId, targetId);
        setTrajectoryAndDiagramView(newTrajectory, newView);
    }

    function setTrajectoryAndDiagramView(newTrajectory: string[], newView: DiagramView) {
        setTrajectory(newTrajectory);
        setDiagramView(newView);
    }

    function prepareForEditing(): void {
        const newView = new DiagramView(subspaces);
        const newTempConnections = tempConnections.replaceStatuses(
            ConnectionStatus.Muted,
            ConnectionStatus.Add,
        );

        setDiagramView(newView);
        setTempConnections(newTempConnections);
        setStartSubspaceId(null);
    }

    function prepareForCreatingConnection(subspaceId: string): void {
        const newView = diagramView.showPossibleSubspacesConnections(
            subspaceId,
            subspaceTypes,
        );
        const newTempConnections = tempConnections.showSubspaceLinks(subspaceId);

        setStartSubspaceId(subspaceId);
        setDiagramView(newView);
        setTempConnections(newTempConnections);
    }

    function updateTempConnection(connectionId: string, status: ConnectionStatus): void {
        const newTempConnections = tempConnections
            .setConnectionIdStatus(connectionId, status)
            .replaceStatuses(ConnectionStatus.Muted, ConnectionStatus.Add);

        setTempConnections(newTempConnections);
        setStartSubspaceId(null);
    }

    function deleteTempConnection(connectionId: string): void {
        const newTempConnections = tempConnections
            .deleteConnectionId(connectionId)
            .replaceStatuses(ConnectionStatus.Muted, ConnectionStatus.Add);
        const newView = new DiagramView(subspaces);

        setDiagramView(newView);
        setTempConnections(newTempConnections);
        setStartSubspaceId(null);
    }

    function addTempConnection(connectionId: string): void {
        const newView = new DiagramView(subspaces);

        setDiagramView(newView);

        if (newView.hasConnectionId(connectionId)) {
            updateTempConnection(connectionId, ConnectionStatus.Delete);
        } else {
            updateTempConnection(connectionId, ConnectionStatus.Add);
        }
    }

    function finishCreatingConnection(subspaceId: string): void {
        if (!startSubspaceId) return;

        const connectionId = getValidConnectionId(
            startSubspaceId,
            subspaceId,
            subspaceTypes,
        );

        if (!connectionId) return;

        if (tempConnections.has(connectionId)) {
            deleteTempConnection(connectionId);
        } else {
            addTempConnection(connectionId);
        }
    }

    function updateTempConnections(subspaceId: string): void {
        if (isEditMode) {
            if (!startSubspaceId) {
                prepareForCreatingConnection(subspaceId);
            } else if (subspaceId === startSubspaceId) {
                prepareForEditing();
            } else if (subspaceId !== startSubspaceId) {
                finishCreatingConnection(subspaceId);
            }
        }
    }

    return (
        <div className="diagram" style={diagramStyle}>
            {spaceBaseTimeIntervals && (
                <DiagramBackground
                    spaceBaseTimeIntervals={spaceBaseTimeIntervals}
                    baseTimeIntervalType={baseTimeIntervalType}
                    subspacesTypesRects={subspacesTypesRects}
                    setDiagramSize={setDiagramSize}
                />
            )}

            <DiagramConnections
                subspaceTypes={subspaceTypes}
                subspacesTypesRects={electiveSubspacesTypesRects}
                connectionsStatuses={diagramView.getConnectionsStatuses()}
                tempConnectionsStatuses={tempConnections.getValue()}
            />

            <DiagramTypes
                subspaceTypes={subspaceTypes}
                setSubspacesTypesRects={setSubspacesTypesRects}
                subspacesStatuses={diagramView.getSubspacesStatuses()}
                hoverSubspace={hoverSubspace}
                clearDiagramView={clearDiagramView}
                showTrajectory={showTrajectory}
                isEditMode={isEditMode}
                updateTempConnections={updateTempConnections}
                hasElectiveType={hasElectiveType}
            />
        </div>
    );
}
