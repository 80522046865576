import React from 'react';

import cn from 'classnames';
import classes from './ModuleSkillsListSkillTypes.module.scss';

import {
    SkillType,
} from '../../subSpaceTypes';

interface Props {
    skillType: string;
    setSkillType(id: string): void;
    sortedSkillTypes: SkillType[];
}

export function ModuleSkillsListSkillTypes({
    skillType,
    setSkillType,
    sortedSkillTypes,
}: Props):JSX.Element {
    return (
        <div className={classes.moduleSkillsListSkillTypes}>
            <ul className={classes.moduleSkillsListSkillTypes__list}>
                {sortedSkillTypes.map((item: SkillType) => (
                    <li
                        key={item.id}
                        className={classes.moduleSkillsListSkillTypes__item}
                    >
                        <div
                            className={cn(classes.moduleSkillsListSkillTypes__title, {
                                [classes.moduleSkillsListSkillTypes__title_active]:
                                    skillType === item.id,
                            })}
                            onClick={() => setSkillType(item.id)}
                        >
                            {item.name.genitiveSingular}
                        </div>
                    </li>
                ))}
            </ul>
        </div>
    );
}
