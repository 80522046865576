import React, { useEffect, useState } from 'react';
import { Attendance } from '@admin/Attendance/Attendance';
import { attendanceStore } from '@admin/Attendance/AttendanceStore';
import { gql, useQuery } from '@apollo/client';
import { Alert } from '@common/Alert';
import { nanoid } from 'nanoid';
import { Loader } from '@common/Loader';
import menuClasses from '@common/Menu/Menu.module.scss';
import classes from '@admin/Attendance/Attendance.module.scss';

import { observer } from 'mobx-react';
import { Attendance as IAttendance } from './AttendanceIterfaces';

const GET_ATTENDANCE = gql`
    query Attendance_Attendance(
        $AttendanceInput: AttendanceInput!
    ) {
        attendance(
            input: $AttendanceInput
        ) {
            wave {
                id
                index
                module {
                    id
                    name
                }
                students {
                    user {
                        id
                        studentId
                        lastName
                        firstName
                        patronymic
                    }
                }
            }
            meetingInstances {
                id
                startDate
                endDate
                    meetingInstanceToTeachers {
                        teacher {
                            id
                            user {
                                id
                                firstName
                                lastName
                                patronymic
                            }
                        }
                    }
                meeting {
                    id
                    topic
                    duration {
                        academicHours
                    }
                    module {
                        id
                        name
                    }
                }
            }
            studentsWithAbsencesCount {
                absencesCount
                student {
                    user {
                        id
                        studentId
                        firstName
                        lastName
                        patronymic
                    }
                }
            }
            meetingInstancesAttendance {
                meetingInstanceId
                studentId
                wasAttended
                isHaveValidReason
            }
        }
    }
`;

export const AttendanceApollo = observer(() : JSX.Element => {
    const {
        moduleId,
        btiInstanceId,
    } = attendanceStore.queryParams;

    const [
        attendanceErrors,
        setAttendanceErrors,
    ] = useState<JSX.Element[]>([]);

    const addError = (message: string) => setAttendanceErrors((arr) => [...arr, (<Alert
        key={nanoid()}
        message={message}
        time={7000}
    />)]);

    useEffect(() => {
        attendanceStore.updateQueryParams();
    }, []);

    useEffect(() => {
        const menu = document.querySelector('.Menu_menu__1lMOX');

        menu?.removeAttribute('class');
        menu?.setAttribute('class', classes.menu_hide);

        return () => {
            menu?.removeAttribute('class');
            menu?.setAttribute('class', menuClasses.menu);
        };
    }, []);

    const { loading: attendanceLoading } = useQuery<
    {
        attendance: IAttendance[],
    }
    >(
        GET_ATTENDANCE,
        {
            skip: !btiInstanceId,
            fetchPolicy: 'cache-and-network',
            variables: {
                AttendanceInput: {
                    baseTimeIntervalInstanceId: btiInstanceId,
                    moduleId,
                },
            },
            onCompleted: (data) => {
                const { attendance } = data;
                if (attendance) {
                    attendanceStore.setAttendances(attendance);
                    attendanceStore.setTablesFromAttendance(attendance);
                }
            },
            onError: (error) => addError(error.message),
        },
    );

    const isLoading = attendanceLoading;

    return (
        <div>
            {
                !isLoading ? (
                    <Attendance />
                ) : (
                    <Loader />
                )
            }
            {
                attendanceErrors
            }
        </div>
    );
});
