import React from 'react';

import { Accordion } from '@common/Accordion';

import {
    VotedChartAccordionItems,
} from '@admin/EducationPeriodStatistic/EducationPeriodStatisticVoted/VotedChartAccordion/VotedChartAccordionItems';
import { ModulesStatistics } from '@admin/EducationPeriodStatistic/EducationPeriodStatisticVoted/ModulesStatistics';

import { observer } from 'mobx-react';
import { educationPeriodStatisticStore } from '@admin/EducationPeriodStatistic/EducationPeriodStatisticStore';
import { getWordEnding } from '@admin/EducationPeriodStatistic/EducationPeriodStatistic';
import { SelectionStatus } from '@admin/EducationPeriodStatistic/EducationPeriodStatisticInterfaces';
import classes from './VotedChartAccordion.module.scss';

interface Props {
    isChartOpen: boolean;
    dateDiff: number;
    dateEnd: string;
    setChartOpen: (isOpen: boolean) => void;
    selectionStatus?: SelectionStatus;
}

const months = ['января', 'февраля', 'марта', 'апреля', 'мая', 'июня', 'июля', 'августа', 'сентября', 'октября', 'ноября', 'декабря'];

export const VotedChartAccordion = observer((
    {
        isChartOpen,
        dateDiff,
        dateEnd,
        setChartOpen,
        selectionStatus,
    }: Props,
): JSX.Element => {
    const hasVotingEnded = selectionStatus
        === SelectionStatus.selectionFinished
        || selectionStatus === SelectionStatus.moduleDistributionEnabled
        || selectionStatus === SelectionStatus.moduleDistributionInProcess
        || selectionStatus === SelectionStatus.moduleDistributionApprovalPending
        || selectionStatus === SelectionStatus.waveDistributionInProcess
        || selectionStatus === SelectionStatus.waveDistributionApprovalPending;

    const currentDateDifference = dateDiff > 0 ? dateDiff : 0;
    // eslint-disable-next-line no-nested-ternary
    const votingInfo = hasVotingEnded ? 'голосование закрыто'
        : (!hasVotingEnded && dateDiff >= 0) ? `осталось ${currentDateDifference} ${getWordEnding(
            currentDateDifference,
            {
                one: 'день',
                oneTwoFive: 'дня',
                tenToTwenty: 'дней',
            },
        )}`
            : 'голосование открыто';

    return (
        <div className={classes.votedChartAccordion}>
            <Accordion
                headerClassnames={classes.accordion__header}
                additionalSetState={() => setChartOpen(!isChartOpen)}
                shouldClickOnHeaderOpen
                isHighlightedPolygon
                isDefaultOpen
                disabled={educationPeriodStatisticStore.votedStudentsVotes === 0}
            >
                <div className={classes.votedChartAccordion__header}>
                    {
                        (
                            (educationPeriodStatisticStore.votedStudentsVotes
                                / educationPeriodStatisticStore.allStudents.length) * 100
                        ).toFixed(1)
                    }% проголосовало
                    {' '}
                    <span className={classes.votedChartAccordion__header_small}>
                        {
                            votingInfo
                        }
                        {' '}
                        (Официальная дата завершения -
                        {' '}
                        {dateEnd.split('/')[1]} {months[new Date(dateEnd).getMonth()]})
                    </span>
                </div>
                <div className={classes.votedChartAccordion__items}>

                    <div className={classes.votedChartAccordion__scroll}>

                        <VotedChartAccordionItems />

                        <ModulesStatistics
                            isChartVisible={isChartOpen}
                        />

                    </div>

                </div>
            </Accordion>
        </div>
    );
});
