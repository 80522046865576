import { observer } from 'mobx-react';
import React from 'react';

import colors from '../../store/ColorConstans';

interface Props {
    handler?: () => void;
    size?: number;
    firstColor?: string;
    secondColor?: string;
    thirdColor?: string;
    className?: string;
}

export const BorderCrossIcon = observer(({
    handler,
    size = 20,
    firstColor = colors.firstColor,
    secondColor = colors.secondColor,
    thirdColor = colors.thirdColor,
    className,
}: Props): JSX.Element => (
    <div className={className} style={{ height: `${size}px`, width: `${size}px`, cursor: 'pointer' }} onClick={handler}>
        <svg width={size} height={size} viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7 13.5C3.40588 13.5 0.5 10.5941 0.5 7C0.5 3.40588 3.40588 0.5 7 0.5C10.5941 0.5 13.5 3.40588 13.5 7C13.5 10.5941 10.5941 13.5 7 13.5ZM7 1.26471C3.82647 1.26471 1.26471 3.82647 1.26471 7C1.26471 10.1735 3.82647 12.7353 7 12.7353C10.1735 12.7353 12.7353 10.1735 12.7353 7C12.7353 3.82647 10.1735 1.26471 7 1.26471Z" fill="url(#paint0_linear_2635_37001)" />
            <path d="M9.51417 3.94482C9.66355 3.79543 9.90577 3.79547 10.0551 3.94491C10.2044 4.09427 10.2043 4.33633 10.055 4.48564L4.48565 10.055C4.33635 10.2043 4.09429 10.2043 3.945 10.055C3.7957 9.9057 3.7957 9.66365 3.94499 9.51435L9.51417 3.94482Z" fill="url(#paint1_linear_2635_37001)" />
            <path d="M3.9449 3.9449C4.09424 3.79547 4.33645 3.79542 4.48584 3.94481L7.27032 6.72929L10.055 9.51397C10.2043 9.66328 10.2044 9.90535 10.0551 10.0547C9.90576 10.2042 9.66355 10.2042 9.51416 10.0548L6.72968 7.27032L3.945 4.48565C3.79569 4.33634 3.79565 4.09427 3.9449 3.9449Z" fill="url(#paint2_linear_2635_37001)" />
            <defs>
                <linearGradient id="paint0_linear_2635_37001" x1="14.4398" y1="6.25949" x2="0.308624" y2="11.7756" gradientUnits="userSpaceOnUse">
                    <stop offset="0.130208" stopColor={firstColor} />
                    <stop offset="0.734375" stopColor={secondColor} />
                    <stop offset="0.932292" stopColor={thirdColor} />
                </linearGradient>
                <linearGradient id="paint1_linear_2635_37001" x1="14.4398" y1="6.25949" x2="0.308624" y2="11.7756" gradientUnits="userSpaceOnUse">
                    <stop offset="0.130208" stopColor={firstColor} />
                    <stop offset="0.734375" stopColor={secondColor} />
                    <stop offset="0.932292" stopColor={thirdColor} />
                </linearGradient>
                <linearGradient id="paint2_linear_2635_37001" x1="14.4398" y1="6.25949" x2="0.308624" y2="11.7756" gradientUnits="userSpaceOnUse">
                    <stop offset="0.130208" stopColor={firstColor} />
                    <stop offset="0.734375" stopColor={secondColor} />
                    <stop offset="0.932292" stopColor={thirdColor} />
                </linearGradient>
            </defs>
        </svg>
    </div>
));
