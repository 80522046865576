import React from 'react';
import cn from 'classnames';
import { observer } from 'mobx-react';
import SkillHierarchy, { Skill, SkillNode } from '@store/SkillHierarchy';
import SkillType from '@store/SkillType';
import { ArrowIcon } from '@common/svg/ArrowIcon';

import EventStore from '../store';
import { EventType } from '../Config/interfaces';
import { SVGEvent } from './SVGEvent';
import { SkillTypeTemplate } from './SkillType';

import classes from './EventItem.module.scss';

enum EventTypeEnum {
    Meeting = 'Встреча',
    Assignments = 'Самостоятельная работа',
    EvaluationPoints = 'Точка оценки',
}

interface Props {
    event: EventType;
}
const EventItem = observer(({
    event,
}: Props): JSX.Element => {
    const { selectedEvent } = EventStore;
    const isSelected = selectedEvent?.id === event.id;
    const { outputSkills } = event;
    const skillFiltredHierarchy = SkillHierarchy
        .filterBySkillIds(outputSkills.map(({ skill }) => skill.id));
    const getSkillList = (skill: SkillNode, list: SkillNode[]): SkillNode[] => {
        list.push(skill);
        if (skill.children.length === 0) {
            return list;
        }
        return skill.children.map((item) => getSkillList(item, list)).flat();
    };
    const skillList = skillFiltredHierarchy.map((key) => getSkillList(key, [])).flat();
    const getParentIds = (skillId: string, list: Skill[]): Skill[] => {
        const skill = skillList.find((item) => item.skill.id === skillId)?.skill;
        const newList = [...list, skill!];
        if (!skill?.parent?.id) {
            return newList;
        }
        return getParentIds(skill?.parent.id, newList);
    };
    const skillListWithParents = outputSkills.map(({ skill }) => getParentIds(skill.id, []));
    const filteredSkillType = SkillType.skillTypes
        .filter((type) => skillFiltredHierarchy.some(item => item.skill.typeId === type.id));
    const isMeeting = (event.eventName === 'meetings');
    return (
        <div className={cn(classes.eventItem__container, {
            [classes.eventItem__container_selected]: isSelected,
        })}
        >
            <SVGEvent
                eventType={event.eventName}
            />
            <div className={classes.eventItem__content}>
                <div
                    className={cn(classes.eventItem, {
                        [classes.eventItem_selected]: isSelected,
                    })}
                    onClick={() => EventStore.setSelectedEvent(isSelected ? null : event)}
                >
                    <div className={classes.eventItem__info}>
                        <div className={classes.eventItem__text}>
                            <span className={cn(classes.eventItem__title, {
                                [classes.eventItem__title_meeting]: isMeeting,
                                [classes.eventItem__title_assignment]: !isMeeting,
                            })}
                            >
                                {isMeeting && (`${EventTypeEnum.Meeting} ${event.eventOrder} `)}
                                {!isMeeting && (`${EventTypeEnum.Assignments} ${event.eventOrder}`)}
                            </span>
                            <span className={cn(classes.eventItem__description, {
                                [classes.eventItem__description_meeting]: isMeeting,
                                [classes.eventItem__description_assignment]: !isMeeting,
                            })}
                            >
                                {event.topic}
                            </span>
                        </div>
                    </div>
                    <ArrowIcon
                        isOpen={isSelected}
                        className={classes.eventView__arrow}
                        openClassName={classes.eventView__arrow_open}
                        isNewDesing
                    />
                </div>
                {isSelected && (
                    <div className={classes.eventItem__informationContainer}>
                        <div className={classes.eventItem__information}>
                            <div className={classes.eventView__infoItem}>
                                <div className={classes.eventView__infoItem_isTitle}>
                                    Продолжительность:
                                </div>
                                <div>{`${event.duration.academicHours * 45} мин`}</div>
                            </div>
                            {event.format && (
                                <div className={classes.eventView__infoItem}>
                                    <div className={classes.eventView__infoItem_isTitle}>
                                        Формат:
                                    </div>
                                    <div>{event.format.name}</div>
                                </div>
                            )}
                        </div>
                        <div>
                            <div
                                className={classes.eventView__infoItem_isTitle}
                            >
                                Образовательные результаты после прохождения:
                            </div>
                            {filteredSkillType.length < 1 && <>—</>}
                            {filteredSkillType.map(type => (
                                <SkillTypeTemplate
                                    key={type.id}
                                    classNames={classes.eventView__skillType}
                                    skillClassNames={classes.eventView__skill}
                                    skillListWithParents={skillListWithParents}
                                    type={type}
                                />
                            ))
                            }
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
});

export default React.memo(EventItem);
