import React from 'react';
import { NavLink, useRouteMatch } from 'react-router-dom';

import classes from './EquipmentPlan.module.scss';

export function EquipmentPlanTabs(): JSX.Element {
    const { path } = useRouteMatch();
    return (
        <ul className={classes.equipmentPlan__list}>
            <li className={classes.equipmentPlan__item}>
                <NavLink
                    to={`${path}/all`}
                    activeClassName={classes.equipmentPlan__item_color_black}
                >
                    Все
                </NavLink>
            </li>
            <li className={classes.equipmentPlan__item}>
                <NavLink
                    to={`${path}/zones`}
                    activeClassName={classes.equipmentPlan__item_color_black}
                >
                    По территориальным зонам
                </NavLink>
            </li>
            <li className={classes.equipmentPlan__item}>
                <NavLink
                    to={`${path}/rooms`}
                    activeClassName={classes.equipmentPlan__item_color_black}
                >
                    По помещениям
                </NavLink>
            </li>
        </ul>
    );
}
