import React from 'react';
import { Link } from 'react-router-dom';

import classes from '../ModuleStatistics.module.scss';
import { GetModuleStatistics_moduleWavesByFilters } from '../graphql-types';
import { WaveModuleStatistics } from '../WaveModuleStatistics/WaveModuleStatistics';
import { EvaluationPointResults } from '../ModuleStatisticsTypes';

interface ModuleStatisticsTableProps {
    isDetailedModeOn: boolean;
    evaluationPoints: EvaluationPointResults[];
    moduleLink: string;
    waves: GetModuleStatistics_moduleWavesByFilters[];
}

export function ModuleStatisticsTable({
    evaluationPoints,
    isDetailedModeOn,
    moduleLink,
    waves,
}: ModuleStatisticsTableProps) {
    return (
        <table className={classes['module-statistics__report']}>
            <thead className={classes['module-statistics-report__header']}>
                <tr>
                    <td rowSpan={isDetailedModeOn ? 2 : 1}>ФИО студента</td>
                    {evaluationPoints.map(({
                        id,
                        topic,
                        skills,
                    }) => (
                        <td
                            className={classes['module-statistics-report__evaluation-point']}
                            colSpan={isDetailedModeOn ? skills?.length : 1}
                            key={id}
                        >
                            <Link
                                to={`${moduleLink}/${id}`}
                                target="_blank"
                            >
                                {topic}
                            </Link>
                        </td>
                    ))}
                </tr>
                {isDetailedModeOn && (
                    <tr>
                        {evaluationPoints.map(point => (
                            point.skills?.length
                                ? (
                                    point.skills.map(evaluationPointSkill => (
                                        <td
                                            className={classes['module-statistics-report__evaluation-skill']}
                                            key={`${evaluationPointSkill?.skill.id}}`}
                                        >
                                            {evaluationPointSkill?.skill.name || ''}
                                        </td>
                                    ))
                                ) : (
                                    <td
                                        key={`${point.id}-without-skills`}
                                        className={classes['module-statistics-report__evaluation-skill']}
                                    >
                                        Образовательные результаты выбранного типа не заданы
                                    </td>
                                )
                        ))}
                    </tr>
                )}
            </thead>
            <tbody className={classes['module-statistics-report__content']}>
                {waves.map(wave => (
                    <WaveModuleStatistics
                        key={wave.id}
                        wave={wave}
                        evaluationPoints={evaluationPoints}
                        isDetailedModeOn={isDetailedModeOn}
                    />
                ))}
            </tbody>
        </table>
    );
}
