export enum IgnoreFlag {
    ignoreTeachers = 'преподаватели',
    ignoreStudents = 'студенты',
    ignoreEquipments = 'оборудование',
    ignoreRooms = 'помещения',
    ignoreMinMaxIntervals = 'мин/макс интервалы',
}

export interface ScheduleGenerationProcess {
    id: string;
    name: string;
    from: Date;
    to: Date;
    status: ScheduleGenerationProcessStatus;
    validationErrors?: string[];
    scheduleGeneratorErrors?: ScheduleGeneratorErrors;
    constraintMatch: ConstraintMatch;
}

export type IgnoreFlagCheckbox = {
    [Key in keyof typeof IgnoreFlag]: boolean;
};

export enum ScheduleGenerationProcessStatus {
    Correction = 'correction',
    Preparing = 'preparing',
    GenerationPhase1 = 'generationPhase1',
    GenerationPhase2 = 'generationPhase2',
    GenerationReady = 'generationReady',
    GenerationFailed = 'generationFailed',
    Uploading = 'uploading',
    Uploaded = 'uploaded',
    Generated = 'generated',
}

export interface ScheduleGeneratorErrors {
    scoreLog: SolutionScoreLog[];
    status: string;
    errorMessage: string;
}

interface SolutionScoreLog {
    time: Date;
    score: string;
}

export interface ConstraintMatch {
    constraintMatchCountList: ConstraintMatchCount[],
}

export interface ConstraintMatchCount {
    constraintName: string,
    scoreType: string,
    matchCount: number,
}
