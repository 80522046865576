import React, { useEffect } from 'react';
import { observer } from 'mobx-react';

import { Section } from '@admin/NewModule/Section';
import { normalBoldJSX } from '@admin/NewModule/utils';
import {
    moduleStore, TeacherRole, Validator, Meeting,
} from '@admin/NewModule/Store';
import { Title, TitleTypes } from '@common/Title';
import { RolesForm, RolesFormInitialData } from './RolesForm';

import style from '../Meeting.module.scss';

interface Props {
    meeting: Meeting;
}

type Role = {
    id: string;
    name: string;
    inputValue: string;
    maxCount?: number;
};

export const TeachersRolesForm = observer(({ meeting }: Props): JSX.Element => {
    const module = moduleStore.moduleModel;
    const normalRoles = (roles: Role[]) => roles?.map(role => ({
        teacherRole: { id: role.id, name: role.name },
        count: Number(role.inputValue) > role.maxCount! ? role.maxCount : Number(role.inputValue),
        maxCount: role.maxCount,
    }));

    const normalList = (roles: TeacherRole[]) => roles?.map(role => ({
        id: role.teacherRole.id,
        name: role.teacherRole.name,
        inputValue: String(role.count),
        maxCount: role.maxCount,
    }));

    const updateMeetingTeacherRoles = (roles: Role[]) => module.updateMeeting(meeting.id, {
        meetingTeacherRoles: normalRoles(roles),
    });

    const getDataList = (roles: TeacherRole[], options: TeacherRole[]) => normalList(
        roles?.map(item => ({
            ...item,
            maxCount: options.find(role => role.teacherRole.id === item.teacherRole.id)?.maxCount,
        })),
    );

    const moduleOptions = module.options;
    const meetingRoles = meeting.meetingTeacherRoles;
    const selectOptions = moduleOptions.teachersRoles
        ?.map(role => ({ ...role, ...role.teacherRole }));
    const dataList = getDataList(meetingRoles, selectOptions);

    useEffect(() => {
        updateMeetingTeacherRoles(dataList);
        module.meetingList.updateAllMeetingRoles();
    }, [moduleOptions.teachersRoles.length]);

    return (
        <>
            <div className={style.form__container}>
                <Title type={TitleTypes.Subtitle}>
                    Укажите роли преподавателей для проведения встречи:
                </Title>
            </div>
            {selectOptions.length === 0 ? (
                <p className={style.form__warning}>
                    В модуль не добавлен ни один преподаватель.
                </p>
            ) : (
                <>
                    <Section
                        initialData={RolesFormInitialData}
                        list={dataList ?? []}
                        form={RolesForm}
                        formProps={{ mainOptions: selectOptions, event: meeting }}
                        onChange={roles => updateMeetingTeacherRoles(roles)}
                        formatItem={({ inputValue, name }) => normalBoldJSX(name, inputValue)}
                        validate={(item) => item.id !== '' && item.inputValue !== ''}
                    />
                    {!Validator.validateEventTeacherRoles(meeting, []).valid && (
                        <div className={style.form__warning}>
                            {Validator.validateEventTeacherRoles(meeting, []).error}
                        </div>
                    )}
                </>
            )}
        </>
    );
});
