import classnames from 'classnames';
import React from 'react';

import classes from './Checkbox.module.scss';

interface Props {
    label?: string;
    checked: boolean;
    modifier?: string;
    onChange: () => void;
}
export const Checkbox = ({ checked, onChange, label, modifier }: Props): JSX.Element => (
    <div className={classes.checkbox}>
        <label className={classes.checkbox__label}>
            <input
                type="checkbox"
                className={classes.checkbox__input}
                checked={checked}
                onChange={onChange}
            />
            <div className={classnames(classes.checkbox__customInput, {
                [classes[`checkbox__customInput_${modifier}`]]: modifier,
            })}
            />
            {label}
        </label>
    </div>
);
