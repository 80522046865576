import React, { useEffect, useState } from 'react';

import { CheckboxRedesign } from '@common/CheckboxRedesign';
import {
    IntervalByWeekDays,
} from '@admin/RoomsPage/RoomForm/RoomItems/RoomAvailableNew/AddInterval/IntervalByWeekDays';
import { IntervalByDates } from '@admin/RoomsPage/RoomForm/RoomItems/RoomAvailableNew/AddInterval/IntervalByDates';

import { AvailableInterval } from '@admin/RoomsPage/RoomForm/RoomItems/RoomAvailableNew/AvailableInterval';
import {
    RepeatingAvailableIntervals,
} from '@admin/RoomsPage/RoomForm/RoomItems/RoomAvailableNew/RepeatingAvailableIntervals';
import { format as tzFormat } from 'date-fns-tz';
import { getGroupedRepeatingIntervals } from '@admin/RoomsPage';
import classes from './AddInterval.module.scss';
import {
    RepeatingAvailableIntervalInput,
    RoomAvailableIntervalInput, RoomsPage_Rooms_rooms_location,
} from '../../../../../../graphql-query-types';

interface Props {
    universityTimeZone: string;
    location?: RoomsPage_Rooms_rooms_location;
    defaultAvailableIntervals: Omit<RoomAvailableIntervalInput, '__typename'>[];
    handlerAvailableIntervals: React.Dispatch<React.SetStateAction<
    Omit<RoomAvailableIntervalInput, '__typename'>[]
    >>;
    defaultRepeatingAvailableIntervals: RepeatingAvailableIntervalInput[],
    handlerRepeatingAvailableIntervals: (
        newIntervals: RepeatingAvailableIntervalInput[]
    ) => void;
}

export function AddInterval(
    {
        universityTimeZone,
        location,
        defaultAvailableIntervals,
        handlerAvailableIntervals,
        defaultRepeatingAvailableIntervals,
        handlerRepeatingAvailableIntervals,
    }: Props,
) {
    const [isByDates, setIsByDates] = useState(false);
    const [isEndDateDisabled, setIsEndDateDisabled] = useState(false);
    const [repeatingAvailableIntervals,
        setRepeatingAvailableIntervals] = useState<
    RepeatingAvailableIntervalInput[]
    >(defaultRepeatingAvailableIntervals);
    const [availableIntervals,
        setAvailableIntervals] = useState<RoomAvailableIntervalInput[]>(defaultAvailableIntervals);

    useEffect(() => {
        handlerAvailableIntervals(availableIntervals);
        handlerRepeatingAvailableIntervals(repeatingAvailableIntervals);
    }, [availableIntervals, repeatingAvailableIntervals]);

    const formattedTimeZone = tzFormat(new Date(), 'xxx', { timeZone: universityTimeZone });

    const availableIntervalsList = availableIntervals.map(
        (availableInterval) => (
            <AvailableInterval
                key={availableInterval.id}
                universityTimeZone={universityTimeZone}
                availableInterval={availableInterval}
                availableIntervals={availableIntervals}
                setAvailableIntervals={setAvailableIntervals}
            />
        ),
    );

    const repeatingAvailableIntervalsList = getGroupedRepeatingIntervals(
        repeatingAvailableIntervals,
    ).map(
        (repeatingIntervals) => (
            <RepeatingAvailableIntervals
                key={repeatingIntervals[0].id}
                repeatingIntervals={repeatingIntervals}
                repeatingAvailableIntervals={repeatingAvailableIntervals}
                setRepeatingAvailableIntervals={setRepeatingAvailableIntervals}
            />
        ),
    );

    return (
        <div>

            <div className={classes.addInterval__availableIntervals}>

                <div className={classes.addInterval__existingIntervals}>
                    {availableIntervalsList}
                </div>

                {repeatingAvailableIntervalsList}
            </div>

            <div className={classes.addInterval}>

                <div className={classes.addInterval__dates}>

                    {
                        isByDates ? (
                            <IntervalByDates
                                universityTimeZone={formattedTimeZone}
                                isEndDateDisabled={isEndDateDisabled}
                                location={location}
                                availableIntervals={availableIntervals}
                                setAvailableIntervals={setAvailableIntervals}
                            />
                        ) : (
                            <IntervalByWeekDays
                                universityTimeZone={formattedTimeZone}
                                repeatingAvailableIntervals={repeatingAvailableIntervals}
                                setRepeatingAvailableIntervals={setRepeatingAvailableIntervals}
                            />
                        )
                    }

                </div>

                <div className={classes.addInterval__options}>

                    <CheckboxRedesign
                        id="onlyDates"
                        checked={isByDates}
                        handleChangeCheckbox={() => setIsByDates(!isByDates)}
                    >
                        <span className={classes.addInterval__options_text}>указать даты</span>
                    </CheckboxRedesign>

                    {
                        isByDates && (
                            <CheckboxRedesign
                                id="onlyOneDate"
                                checked={isEndDateDisabled}
                                handleChangeCheckbox={() => {
                                    setIsEndDateDisabled(!isEndDateDisabled);
                                }}
                            >
                                <span className={classes.addInterval__options_text}>
                                    только один день
                                </span>
                            </CheckboxRedesign>
                        )
                    }

                </div>

            </div>

        </div>
    );
}
