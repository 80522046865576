import React from 'react';

interface Props {
    fill?: string;
    onClick?: () => void;
}

export function MessageIcon({
    fill = '#000',
    onClick = () => { },
}: Props) {
    return (
        <svg width="18" height="16" onClick={onClick}>
            <path
                d="M.875.75v12.5h5.977l1.699 1.7.45.429.45-.43 1.697-1.699h5.977V.75H.875ZM2.125
                   2h13.75v10h-5.254l-.195.175L9 13.602l-1.425-1.425L7.379 12H2.125V2Zm2.5
                   1.875v1.25h8.75v-1.25h-8.75Zm0 2.5v1.25h8.75v-1.25h-8.75Zm0 2.5v1.25h6.25v-1.25h-6.25Z"
                fill={fill}
            />
        </svg>

    );
}
