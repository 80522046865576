/* eslint react/no-danger: 0 */
import React from 'react';
import cn from 'classnames';
import { Link, useRouteMatch } from 'react-router-dom';

import { AvatarIcon, HatIcon } from '@common/svg';
import { ActionButton, ActionTypeOfButton } from '@common/ActionButton';
import { Dialog } from '@common/Dialog';
import { PopUp } from '@common/ModuleCard/PopUp';

import { ExternalModuleStatus, listExternalModules_externalModules } from '../apollo-types';
import classes from './ExternalModuleList.module.scss';

const ExternalModuleStatusMap = {
    [ExternalModuleStatus.verification]: 'ожидает проверки',
    [ExternalModuleStatus.revision]: 'на доработке',
    [ExternalModuleStatus.approved]: 'одобрен',
    [ExternalModuleStatus.draft]: 'черновик',
    [ExternalModuleStatus.deleted]: 'удален',
};

interface Props {
    moduleList?: listExternalModules_externalModules[];
    isPopupVisible: boolean;
    onConfirmRemove: () => void;
    onCancelRemove: () => void;
    onRemoveButtonClick: (moduleId: string) => void;
}

export function ExternalModuleList({
    moduleList,
    isPopupVisible,
    onCancelRemove,
    onConfirmRemove,
    onRemoveButtonClick,
}: Props) {
    const { path } = useRouteMatch();
    return (
        <div className={classes['external-module-list']}>
            {moduleList?.map(module => (
                <div
                    className={cn(
                        classes['external-module-list__item'],
                        classes['external-module'],
                    )}
                    key={module.id}
                >
                    <div className={classes['external-module__header']}>
                        <div className={classes['external-module-header__icon-container']}>
                            <HatIcon />
                        </div>
                        <div className={classes['external-module-header__title']}>
                            {module.name}
                        </div>
                        <div className={cn(
                            classes['external-module__status'],
                            classes[`external-module__status_${module.status}`],
                        )}
                        >
                            <div className={cn(
                                classes['external-module__status-point'],
                                classes[`external-module__status-point_${module.status}`],
                            )}
                            />
                            {ExternalModuleStatusMap[module.status]}
                        </div>
                    </div>
                    <div className={classes['external-module__content']}>
                        <ul className={classes['external-module-content__tabs']}>
                            <li
                                className={cn(
                                    classes['external-module-content__tab-item'],
                                    classes['external-module-content__tab-item_active'],
                                )}
                            >
                                Основные характеристики
                            </li>
                            <li className={classes['external-module-content__tab-stub']} />
                        </ul>
                        <div className={classes['external-module-content']}>
                            <div className={classes['external-module-content__title']}>
                                Описание:
                            </div>
                            {module.description ? (
                                <div
                                    className={classes['external-module-content__description']}
                                    dangerouslySetInnerHTML={{ __html: module.description }}
                                />
                            ) : (
                                <div className={classes['external-module-content__description']}>
                                    Описание отсутствует
                                </div>
                            )}
                            <ExternalModuleCharacteristics module={module} />
                            <div className={classes['external-module__owner']}>
                                <div className={classes['external-module-owner__title']}>
                                    Владелец модуля:
                                </div>
                                <div className={classes['external-module-owner__card']}>
                                    <div className={classes['external-module-owner__avatar']}>
                                        <AvatarIcon size={40} />
                                    </div>
                                    <div className={classes['external-module-owner__full-name']}>
                                        {module.ownerFullName}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={classes['external-module__buttons']}>
                            <ActionButton
                                type="button"
                                actionType={ActionTypeOfButton.SECONDARY}
                                className={classes['external-module__button']}
                                onClick={() => onRemoveButtonClick(module.id)}
                            >
                                Удалить
                            </ActionButton>
                            <Link to={`${path}/${module.id}`}>
                                <div className={classes['external-module__button']}>
                                    <ActionButton type="button">
                                        Редактировать
                                    </ActionButton>
                                </div>
                            </Link>
                        </div>
                        {isPopupVisible && (
                            <Dialog
                                id="deleteModuleId"
                                dom={(
                                    <PopUp
                                        text="Вы уверены, что хотите удалить этот модуль?"
                                        closePortal={onCancelRemove}
                                        deleteAction={onConfirmRemove}
                                    />
                                )}
                            />
                        )}
                    </div>
                </div>
            ))}
        </div>
    );
}

interface ExternalModuleCharacteristicsProps {
    module: listExternalModules_externalModules;
}

export function ExternalModuleCharacteristics({ module }: ExternalModuleCharacteristicsProps) {
    return (
        <div className={classes['external-module-content__features']}>
            <ul className={classes['external-module-features']}>
                <li className={classes['external-module-feature__value']}>
                    {formatStudentCount(module.minStudentCount, module.maxStudentCount)}
                </li>
                <li className={classes['external-module-feature__value']}>
                    {module.maxWaveCount || '-'}
                </li>
                <li className={classes['external-module-feature__value']}>
                    {module.creditCount || '-'}
                </li>
                <li className={classes['external-module-feature__value']}>
                    {module.creditCount ? module.creditCount * 36 : '-'}
                </li>
            </ul>
            <ul>
                <li className={classes['external-module-feature__title']}>
                    Количество студентов
                </li>
                <li className={classes['external-module-feature__title']}>
                    Потоков модуля в семестр
                </li>
                <li className={classes['external-module-feature__title']}>
                    ЗЕТ
                </li>
                <li className={classes['external-module-feature__title']}>
                    Количество академических часов студента
                </li>
            </ul>
        </div>
    );
}

function formatStudentCount(minStudentCount: number | null, maxStudentCount: number | null) {
    if (!(minStudentCount && maxStudentCount)) {
        return '-';
    }

    if (!minStudentCount) {
        return `? - ${maxStudentCount}`;
    }

    if (!maxStudentCount) {
        return `${minStudentCount} - ?`;
    }

    if (maxStudentCount !== minStudentCount) {
        return `${minStudentCount} -  ${maxStudentCount}`;
    }

    return minStudentCount;
}
