import { gql } from '@apollo/client';

export const TEACHER_LOAD = gql`
query getTeacherRoleConflicts($teacherRoleConflictsInput: TeacherRoleConflictsInput!) {
  teacherRoleConflicts(teacherRoleConflictsInput: $teacherRoleConflictsInput) {
    modules {
      id
      name
      teachersRoles {
        id
        name
      }
    }
    teacherRolesConflicts {
      id
      conflictsCountByScaleUnits {
        week
        dayOfWeek
        date
        startTime
        endTime
        count
      }
    }
    markup {
      week
      dayOfWeek
      date
      startTime
      endTime
    }
  }
}
`;

export const GET_SUGGESTED_MODULES = gql`
  query getModulesByNamePattern($value: ModulesByNamePatternInput!) {
    modulesByNamePattern(modulesByNamePatternInput: $value) {
      id
      name
    }
  }
`;
