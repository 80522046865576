import React from 'react';
import { KeyValue } from '@common/KeyValue';
import { BorderCrossIcon } from '@common/svg';
import { ExtraWorkday, Holiday } from './UniversitySpecialDaysInterfaces';
import classes from './UniversitySpecialDaysItem.module.scss';

interface Props {
    dates: ExtraWorkday[]|Holiday[],
    month: string,
    deleteHandler?: (date: ExtraWorkday|Holiday)=>void,
}

type ParseDateFunction = {
    (
        date: ExtraWorkday|Holiday,
    ): string;
};
type GetDateElementsFunction = {
    (
        dates: ExtraWorkday[]|Holiday[],
        deleteHandler?: (date: ExtraWorkday|Holiday) => void,
    ): (JSX.Element)[];
};

const parseDate: ParseDateFunction = (date) => {
    let finalDate = '';
    const tempDate = new Date(date.date);

    finalDate += `${tempDate.getDate() < 10 ? '0' : ''}${tempDate.getDate()}.`;
    finalDate += `${tempDate.getMonth() + 1 < 10 ? '0' : ''}${tempDate.getMonth() + 1}.`;
    finalDate += `${tempDate.getFullYear()}`;

    return finalDate;
};

const getDateElements: GetDateElementsFunction = (dates, deleteHandler) => (
    dates.map((date) => (
        <div key={date.id} className={classes.universitySpecialDaysItem__date}>
            {parseDate(date)}
            {deleteHandler && (
                <BorderCrossIcon
                    handler={() => deleteHandler(date)}
                    size={15}
                    className={classes.universitySpecialDaysItem__deleteButton}
                />
            )}
        </div>
    ))
);

export function UniversitySpecialDaysItem(
    { dates, month, deleteHandler }: Props,
) {
    return (
        <div className={classes.universitySpecialDaysItem}>
            <KeyValue
                title={`${month}: `}
            >
                <div className={classes.universitySpecialDaysItem__dates}>
                    {getDateElements(dates, deleteHandler)}
                </div>
            </KeyValue>
        </div>
    );
}
