import { makeAutoObservable } from 'mobx';
import { BaseTimeIntervalInstanceWithExcusedAbsences } from '@admin/Users/UsersInterfaces';
import { UserWithAbsences } from '@admin/Users/UserCard/UserCommonInfo/UserAbsences/graphql-types';

class UsersStore {
    private isAbsenceCreating: boolean = false;

    private areAbsencesReadyToEdit: boolean = false;

    private baseTimeIntervalInstancesWithExcusedAbsences
    : BaseTimeIntervalInstanceWithExcusedAbsences[] = [];

    private userWithAbsences: UserWithAbsences | null = null;

    constructor() {
        makeAutoObservable(this);
    }

    setBaseTimeIntervalInstancesWithExcusedAbsences = (
        newBaseTimeIntervalInstancesWithExcusedAbsences
        : BaseTimeIntervalInstanceWithExcusedAbsences[],
    ) => {
        // eslint-disable-next-line max-len
        this.baseTimeIntervalInstancesWithExcusedAbsences = newBaseTimeIntervalInstancesWithExcusedAbsences;
    };

    setAreAbsencesReadyToEdit = (
        wasEdited: boolean,
    ) => {
        this.areAbsencesReadyToEdit = wasEdited;
    };

    setIsAbsenceCreating = (
        newIsAbsenceCreating: boolean,
    ) => {
        this.isAbsenceCreating = newIsAbsenceCreating;
    };

    setUserWithAbsences = (
        newUserWithAbsences: UserWithAbsences,
    ) => {
        this.userWithAbsences = newUserWithAbsences;
    };

    getIsAbsenceCreating = () => this.isAbsenceCreating;

    getAreAbsencesReadyToEdit = () => this.areAbsencesReadyToEdit;

    getBaseTimeIntervalInstancesWithExcusedAbsences =
    () => this.baseTimeIntervalInstancesWithExcusedAbsences;

    getUserWithAbsences = () => this.userWithAbsences;
}

export const usersStore = new UsersStore();
