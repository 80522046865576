import React from 'react';
import { Link } from 'react-router-dom';

import {
    Teacher as TeacherInterface,
} from '../../../../../../OccupationDashboardInterfaces';
import classes from './Teacher.module.scss';

interface Props {
    index: number;
    teacher?: TeacherInterface;
    isTUniversityMeetingsVisible: boolean;
}

const TEACHER_NAME_START_COLUMN_LINE = 1;
const TEACHER_NAME_END_COLUMN_LINE = 2;
const DIFF_BETWEEN_TEACHER_INDEX_AND_START_ROW_LINE = 1;
const DIFF_BETWEEN_TEACHER_INDEX_AND_END_ROW_LINE = 1
+ DIFF_BETWEEN_TEACHER_INDEX_AND_START_ROW_LINE;

export const Teacher = ({
    index,
    teacher,
    isTUniversityMeetingsVisible,
}: Props): JSX.Element | null => {
    if (teacher) {
        return (
            <Link
                to={`/users/user/${teacher.user.id}`}
                target="_blank"
                className={classes.teacher__name}
                style={{
                    gridColumnStart: TEACHER_NAME_START_COLUMN_LINE,
                    gridColumnEnd: TEACHER_NAME_END_COLUMN_LINE,
                    gridRowStart: index + DIFF_BETWEEN_TEACHER_INDEX_AND_START_ROW_LINE,
                    gridRowEnd: index + DIFF_BETWEEN_TEACHER_INDEX_AND_END_ROW_LINE,
                }}
            >
                <div className={classes.teacher__tooltip}>
                    {teacher.user.lastName} {teacher.user.firstName} {teacher.user.patronymic}{' '}
                    ({isTUniversityMeetingsVisible
                        ? teacher.availableHoursCountWithoutReservations
                        : teacher.availableHoursCountInTUniversity
                    }&nbsp;ак.ч.)
                </div>
            </Link>
        );
    }
    return null;
};
