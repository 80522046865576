import { gql } from '@apollo/client';

export const GET_STUDENT_MODULE_ASSESSMENT = gql`
    query GetStudentModuleAssessment($input: StudentModuleMeetingInstancesInput!, $studentModuleId: String!) {
        studentModuleMeetingInstances(studentModuleMeetingInstancesInput: $input) {
            id
            startDate
            endDate
            meeting {
                topic
            }
            meetingInstanceToTeachers {
                teacher {
                    id
                    user {
                        lastName
                        firstName
                        patronymic
                    }
                }
            }
        }
        studentModuleAssessment(studentModuleId: $studentModuleId) {
            id
            status
            studentModuleId
            answers {
                optionId
                questionId
                value
            }
            teacherAssessments {
                id
                teacherId
                answers {
                    optionId
                    questionId
                    value
                }
            }
        }
    }
`;

export const UPDATE_MODULE_ASSESSMENT = gql`
    mutation UpdateModuleAssessment($input: UpdateStudentModuleAssessmentInput!) {
        updateStudentModuleAssessment(updateStudentModuleAssessmentInput: $input) {
            id
        }
    }
`;

export const FINISH_MODULE_ASSESSMENT = gql`
    mutation FinishModuleAssessment($studentModuleId: String!) {
        finishStudentModuleAssessment(studentModuleId: $studentModuleId) {
            id
            status
        }
    }
`;
