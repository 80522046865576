import React, { useEffect, useRef } from 'react';
import cn from 'classnames';

import classes from './Input.module.scss';

interface InputProps {
    placeholder: string,
    className?: string;
    isOnChangeActivateOnType?: boolean,
    startingValue?: string | number,
    autoFocus?: boolean,
    type?: 'number' | 'text';
    hasFocus?: boolean;
    onBlur?: (value?: string) => void,
    onEnterHandler?: (value?: string) => void,
    onChange: (value: string) => void,
}

export function Input(
    {
        placeholder,
        className,
        startingValue,
        isOnChangeActivateOnType = false,
        autoFocus = false,
        type = 'text',
        hasFocus = false,
        onBlur,
        onChange,
        onEnterHandler,
    }: InputProps,
) {
    const inputRef = useRef<HTMLInputElement>(null);
    useEffect(() => (autoFocus ? inputRef.current?.focus() : undefined), []);
    useEffect(() => (hasFocus ? inputRef.current?.focus() : undefined), [hasFocus]);
    useEffect(
        () => {
            if (startingValue && inputRef.current) {
                inputRef.current.value = String(startingValue);
            }
            addSubmitEventListener();
        },
        [inputRef, startingValue],
    );
    function addSubmitEventListener() {
        inputRef.current?.addEventListener('keypress', enterPressHandler);
        return () => inputRef.current?.removeEventListener('keypress', enterPressHandler);
    }
    function enterPressHandler(event: KeyboardEvent) {
        if (event.key === 'Enter' && inputRef.current) {
            if (onEnterHandler) {
                onEnterHandler(inputRef.current.value);
            }
            onChange(inputRef?.current?.value);
        }
    }
    return (
        <div
            className={classes.input}
        >
            <input
                ref={inputRef}
                type={type}
                className={cn(classes.input__inputItself, className)}
                placeholder={placeholder}
                onBlur={() => {
                    if (onBlur) onBlur(inputRef.current?.value);
                }}
                onChange={() => isOnChangeActivateOnType && onChange(inputRef.current?.value ?? '')}
                min="0"
            />
        </div>
    );
}
