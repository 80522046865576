/* eslint-disable max-len */
import React from 'react';
import {
    UpdateEquipmentEquipmentPage_updateEquipment,
    UpdateEquipmentInput,
    TerritorialZonesEquipmentPage_territorialZones,
} from 'src/deprecated-graphql-query-types';
import { RegularEquipmentListApollo } from './RegularEquipmentList/RegularEquipmentListApollo';

interface Props {
    territorialZones?: TerritorialZonesEquipmentPage_territorialZones[];
    updateEquipmentHandler(
        input: UpdateEquipmentInput,
        callBack: (params: UpdateEquipmentEquipmentPage_updateEquipment) => void,
    ): void;
}

export function EquipmentPage({
    territorialZones,
    updateEquipmentHandler,
}: Props) {
    return (
        <RegularEquipmentListApollo
            zones={territorialZones}
            updateEquipmentHandler={updateEquipmentHandler}
        />
    );
}
