import React, { useState } from 'react';

import { WaveSelectionPage } from '@admin/WaveSelectionPage/WaveSelectionPage';
import { useQuery } from '@apollo/client';
import { Loader } from '@common/Loader';
import { Alert } from '@common/Alert';
import groupBy from 'lodash/groupBy';
import { nanoid } from 'nanoid';
import {
    GET_ALL_STUDENTS_RATING,
    GET_BTI_INSTANCE,
    GET_FORK_UNDISTRIB_STUDENTS,
    GET_IS_VALID_STUDENTS_WAVE_DISTRIBUTION,
    GET_PREVIOUS_BTI,
    GET_SPACE_BTI,
    GET_WAVES,
} from '@admin/WaveSelectionPage/WaveSelectionQueries';
import {
    BaseTimeIntervalInstance,
    BaseTimeIntervalInstanceVariables,
    ForkUndistributedStudents,
    ForkUndistributedStudentsVariables,
    GetIsValidStudentsWaveDistribution,
    GetIsValidStudentsWaveDistributionVariables,
    PreviousBaseTimeIntervalInstance,
    PreviousBaseTimeIntervalInstanceVariables,
    SelectionStatus,
    SpaceBaseTimeInterval,
    StudentsRatings,
    StudentsRatingsVariables,
    Waves,
    WavesInput,
} from '@admin/WaveSelectionPage/graphql-types';
import { waveSelectionDataStore } from '@admin/WaveSelectionPage/WaveSelectionDataStore';
import { observer } from 'mobx-react';
import { waveSelectionStore } from '@admin/WaveSelectionPage/WaveSelectionStore';
import { useHistory } from 'react-router-dom';

export const WaveSelectionPageApollo = observer((): JSX.Element => {
    const {
        baseTimeIntervalId,
        baseTimeIntervalInstanceId,
        forkId,
        isNotStudentChoice,
        spaceId,
    } = waveSelectionDataStore.getURLParameters();

    const history = useHistory();

    const [
        queryErrors,
        setQueryErrors,
    ] = useState<JSX.Element[]>([]);
    const addError = (message: string) => setQueryErrors((arr) => [...arr, (<Alert
        key={nanoid()}
        message={message}
        time={7000}
    />)]);

    const {
        loading: isBTIInstanceLoading,
    } = useQuery<
    BaseTimeIntervalInstance,
    BaseTimeIntervalInstanceVariables>(GET_BTI_INSTANCE, {
        skip: !baseTimeIntervalInstanceId,
        variables: {
            id: baseTimeIntervalInstanceId,
        },
        onCompleted: (data) => {
            if (data.baseTimeIntervalInstance.selectionStatus
                !== SelectionStatus.waveDistributionApprovalPending) {
                waveSelectionStore.setIsAllDisabled(true);
                addError('Распределение по потокам закончилось');
                setTimeout(() => history.push(`/education-period/${spaceId}`), 4000);
            }
        },
        onError: error => addError(error.message),
    });

    const {
        refetch: refetchIsValidStudentWaveDistribution,
    } = useQuery<
    GetIsValidStudentsWaveDistribution,
    GetIsValidStudentsWaveDistributionVariables
    >(GET_IS_VALID_STUDENTS_WAVE_DISTRIBUTION, {
        skip: !baseTimeIntervalInstanceId,
        variables: {
            baseTimeIntervalInstanceId,
        },
        onCompleted: (data) => waveSelectionDataStore
            .setIsValidStudentWaveDistribution(data.isValidStudentsWaveDistribution),
        onError: (error) => addError(error.message),
    });

    const {
        loading: spaceBtiLoading,
    } = useQuery<SpaceBaseTimeInterval>(
        GET_SPACE_BTI,
        {
            skip: !baseTimeIntervalId,
            variables: {
                btiId: baseTimeIntervalId,
            },
            onCompleted: (data) => {
                waveSelectionDataStore
                    .setForksFromSubspaceSpaceBaseTimeIntervalSubspaces(
                        data.spaceBaseTimeInterval.spaceBaseTimeIntervalSubspaces,
                    );
            },
            onError: (spaceBtiError) => addError(spaceBtiError.message),
        },
    );

    const {
        loading: undistribLoading,
    } = useQuery<ForkUndistributedStudents, ForkUndistributedStudentsVariables>(
        GET_FORK_UNDISTRIB_STUDENTS,
        {
            skip: isNotStudentChoice || !baseTimeIntervalInstanceId || !forkId,
            variables: {
                ForkUndistributedStudentsInput: {
                    baseTimeIntervalInstanceId,
                    forkId,
                },
            },
            fetchPolicy: 'no-cache',
            onCompleted: (data) => {
                waveSelectionStore.setNotDistribStudentsFromForkStudents(
                    data.forkUndistributedStudents.map((student) => student.student),
                );
                waveSelectionDataStore.setAllStudentsIdsFromUndistributedStudents(
                    data.forkUndistributedStudents,
                );
            },
            onError: (error) => addError(error.message),
        },
    );

    const {
        loading: wavesLoading,
    } = useQuery<Waves, { WaveInput: WavesInput }>(
        GET_WAVES,
        {
            skip: !baseTimeIntervalInstanceId
                || !waveSelectionDataStore.getCurrentForkModulesIds().length,
            variables: {
                WaveInput: {
                    baseTimeIntervalInstanceId,
                    moduleIds: waveSelectionDataStore.getCurrentForkModulesIds(),
                },
            },
            fetchPolicy: 'no-cache',
            onCompleted: (data) => {
                waveSelectionStore.setModuleWavesWithSelectedStudentsFromGroupedWaves(
                    groupBy(data.waves, (wave) => wave.module.id),
                );
                waveSelectionDataStore.setAllStudentsIdsFromWaves(
                    data.waves,
                );
                refetchIsValidStudentWaveDistribution();
            },
            onError: (error) => addError(error.message),
        },
    );

    const {
        loading: isPreviousBTIILoading,
    } = useQuery<
    PreviousBaseTimeIntervalInstance,
    PreviousBaseTimeIntervalInstanceVariables
    >(GET_PREVIOUS_BTI, {
        skip: !baseTimeIntervalInstanceId,
        variables: {
            baseTimeIntervalInstanceId,
        },
        onCompleted: data => {
            waveSelectionDataStore.setPreviousBTIId(
                data.previousBaseTimeIntervalInstance?.id ?? '',
            );
        },
        onError: (error) => addError(error.message),
    });

    const {
        loading: isRatingLoading,
    } = useQuery<
    StudentsRatings,
    StudentsRatingsVariables
    >(GET_ALL_STUDENTS_RATING, {
        skip: !waveSelectionDataStore.getPreviousBTIid()
            || !waveSelectionDataStore.getAllStudentsIds().length,
        variables: {
            studentsRatingInput: {
                baseTimeIntervalInstanceId: waveSelectionDataStore.getPreviousBTIid() ?? '',
                studentIds: waveSelectionDataStore.getAllStudentsIds(),
            },
        },
        onCompleted: data => {
            waveSelectionStore.setStudentsWithRatings(
                data.studentsRating,
            );
        },
        onError: error => addError(error.message),
    });

    const isWaveLoading = wavesLoading || undistribLoading
    || isRatingLoading || isPreviousBTIILoading || isBTIInstanceLoading;

    return (
        <>
            {
                !spaceBtiLoading ? (
                    <WaveSelectionPage
                        isWaveLoading={isWaveLoading}
                    />
                ) : (
                    <Loader />
                )
            }

            {
                queryErrors
            }
        </>
    );
});
