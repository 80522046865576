import React from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Event from '@ckeditor/ckeditor5-utils/src/eventinfo';

import './Wysiwyg.css';

interface Props {
    placeholder?: string;
    label?: string;
    value?: string;
    disabled?: boolean;
    onChange(newValue: string): void;
}

export const Wysiwyg = ({ label, placeholder, value, disabled, onChange }: Props) => {
    const config = {
        placeholder,
        toolbar: {
            items: [
                'bold',
                'italic',
                '|',
                'numberedList',
                'bulletedList',
                'heading',
                '|',
                'link',
                'undo',
                'redo',
            ],
        },
        heading: {
            options: [{
                model: 'heading1',
                view: 'h1',
                title: 'Заголовок',
                class: 'ck-heading_heading1',
            }, {
                model: 'heading2',
                view: 'h2',
                title: 'Подзаголовок',
                class: 'ck-heading_heading2',
            }],
        },
    };
    const [state, setState] = React.useState(value);
    const handleChange = (_: Event, editor: ClassicEditor) => {
        const data = editor.getData();
        onChange(data);
    };

    return (
        <section className="wysiwyg">
            {label && <div className="wysiwyg__label">{label}</div>}
            <CKEditor
                editor={ClassicEditor}
                data={state ?? ''}
                onReady={() => setState(value)}
                onChange={handleChange}
                config={config}
                disabled={disabled}
            />
        </section>
    );
};
