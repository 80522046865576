import React from 'react';
import classes from './KeyValue.module.scss';

interface Props {
    title: string;
    children?: React.ReactNode;
}

export const KeyValue = ({
    title, children,
}: Props): JSX.Element => (
    <>
        <h4 className={classes.KeyValue__title}>{title}</h4>
        {children}
    </>
);
