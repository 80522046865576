/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { ReactElement, useState } from 'react';
import { observer } from 'mobx-react';
import cn from 'classnames';

import { Accordion } from '@common/Accordion';
import { Loader } from '@common/Loader';

import { PlusIcon } from '../PlusIcon';
import { RegularEquipment } from '../RegularEquipment';
import { NewEquipmentPosition } from '../store/EquipmentStore';
import { Filters } from '../Filters';
import {
    EquipmentListProps,
    FilterOrPaginationEquipment,
    RegularEquipmentListProps,
} from './CustomInterfacesEquipmentList';

import classes from './RegularEquipmentList.module.scss';

export const RegularEquipmentList = observer(({
    zones,
    equipments,
    hasPagination,
    loading,
    deleteNewRegularEquipment,
    removeEquipmentFromState,
    updateEquipment,
    filterOrPaginationEquipment,
    createClassicEquipment,
}: RegularEquipmentListProps) => (
    <>
        <Filters filterOrPaginationEquipment={filterOrPaginationEquipment} />
        <div className={classes.regularEquipmentList}>
            <Accordion
                shouldClickOnHeaderOpen
                isDefaultOpen
                headerClassnames={classes.regularEquipmentList__accordionHeader}
            >
                <TitleWithChildren>
                    <CreateEquipmentPlusButton
                        onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
                            e.stopPropagation();
                            createClassicEquipment(NewEquipmentPosition.TOP);
                        }}

                    />
                </TitleWithChildren>
                <div className={classes.regularEquipmentList__list}>
                    <EquipmentList
                        loading={loading}
                        zones={zones}
                        equipments={equipments}
                        updateEquipment={updateEquipment}
                        deleteNewRegularEquipment={deleteNewRegularEquipment}
                        removeEquipmentFromState={removeEquipmentFromState}
                    />
                    {hasPagination ? (
                        <PaginationButton
                            filterOrPaginationEquipment={filterOrPaginationEquipment}
                        />
                    ) : (
                        <CreateEquipmentButton
                            onClick={() => createClassicEquipment(NewEquipmentPosition.BOTTOM)}
                        />
                    )}
                </div>
            </Accordion>
        </div>
    </>
));

function CreateEquipmentPlusButton({ onClick }: { onClick: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void }) {
    return (
        <div
            className={classes.regularEquipmentList__plusButton}
            onClick={(e) => onClick(e)}
        >
            <PlusIcon />
        </div>
    );
}

function TitleWithChildren({ children }: { children: ReactElement }) {
    return (
        <div
            className={classes.regularEquipmentList__title}
        >
            <div className={classes.regularEquipmentList__titleLabel}>
                Оборудование
            </div>
            {children}
        </div>
    );
}

function PaginationButton(
    {
        filterOrPaginationEquipment,
    }: {
        filterOrPaginationEquipment: FilterOrPaginationEquipment;
    },
) {
    return (
        <div
            className={classes.regularEquipmentList__addEquipment}
            onClick={() => filterOrPaginationEquipment()}
        >
            <div
                className={classes.regularEquipmentList__addEquipmentLabel}
            >
                развернуть...
            </div>
        </div>
    );
}

function CreateEquipmentButton({ onClick }: { onClick: () => void }) {
    return (
        (
            <div
                className={classes.regularEquipmentList__addEquipment}
                onClick={onClick}
            >
                <div
                    className={classes.regularEquipmentList__addEquipmentLabel}
                >
                    добавить оборудование...
                </div>
            </div>
        )
    );
}

function EquipmentList({
    loading,
    equipments,
    zones,
    deleteNewRegularEquipment,
    removeEquipmentFromState,
    updateEquipment,
}: EquipmentListProps) {
    const [hoveredElementIndex, setHoveredElementIndex] = useState<number>(-1);
    const isHiddenSeparateLine = (index: number) => {
        const currentElementIndex = hoveredElementIndex;
        const prevuesElementIndex = currentElementIndex - 1;
        return currentElementIndex === index || prevuesElementIndex === index;
    };
    return (
        <>
            {
                loading ? <Loader /> : equipments.map(
                    (equipment, index) => (
                        <div
                            key={equipment.id}
                            onMouseMove={() => setHoveredElementIndex(index)}
                            onMouseLeave={() => setHoveredElementIndex(-1)}
                        >
                            <RegularEquipment
                                key={equipment.id}
                                zones={zones}
                                equipment={equipment}
                                updateEquipmentHandler={updateEquipment}
                                removeEquipmentFromState={removeEquipmentFromState}
                                deleteNewRegularEquipment={deleteNewRegularEquipment}
                            />
                            <SeparateLine isHidden={isHiddenSeparateLine(index)} />
                        </div>
                    ),
                )
            }
        </>
    );
}

function SeparateLine({ isHidden }: { isHidden: boolean }) {
    return (
        <div
            className={classes.regularEquipment__lineContainer}
        >
            <div className={cn(
                classes.regularEquipment__line,
                {
                    [classes.regularEquipment__line_hidden]: isHidden,
                },
            )}
            />
        </div>
    );
}
