import React, { useState } from 'react';

import cn from 'classnames';

import { BorderCrossIcon, EditIcon, FilledEditIcon } from '@common/svg';
import { Dialog } from '@common/Dialog';

import NestedSkill from '@admin/SkillPage/SkillAddPage/store/NestedSkill';
import { SkillCardWithNestChildren } from './SkillCardWithNestChildren';
import { PortalPopUpSkillAddWithNest } from './PortalPopUpSkillAddWithNest';
import { SKillCardWithNestEdit } from './SkillCardWithNestEdit';
import classes from './SkillCardWithNest.module.scss';

import { skillTypesData_skillTypes, skills_skills } from '../../../../deprecated-graphql-query-types';

interface IProps {
    skillType: skillTypesData_skillTypes;
    skillTypes: skillTypesData_skillTypes[];
    currentSkill: any;
    skillsList: any;
    removeSkill(id: string): void;
    updateNestSkill: (
        id: string,
        name: string,
        typeId: string,
        children: any,
        dependencies: string[],
        maxLevel?: number,
    ) => void;
}

export const SkillCardWithNest = ({
    currentSkill,
    skillType,
    skillTypes,
    skillsList,
    removeSkill,
    updateNestSkill,
}: IProps) => {
    const [isEdit, setIsEdit] = useState(false);
    const [popUpPortal, togglePopUpPortal] = useState(false);
    const [isEditPortal, toggleIsEditPortal] = useState(false);
    const skill = currentSkill;
    NestedSkill.rootId = skill.id;

    const onEdit = () => {
        if (isEdit) {
            NestedSkill.full(currentSkill);
            toggleIsEditPortal(!isEditPortal);
        }
    };

    const checkAndRemoveSkill = () => {
        togglePopUpPortal(!popUpPortal);
    };

    return (
        <>
            <div className={classes['skill-nested-card__content']}>
                <div className={classes['skill-nested-card__skill-name-container']}>
                    <div
                        className={cn(classes['skill-nested-card__skill-name'], {
                            [classes['skill-nested-card__skill-name--edit']]: isEdit,
                        })}
                        onClick={() => onEdit()}
                    >
                        {skill.name}
                    </div>
                    {isEdit && (
                        <BorderCrossIcon
                            size={18}
                            handler={checkAndRemoveSkill}
                            className={classes['yellow-cross-15px']}
                        />
                    )}
                </div>
                <ul className={classes['skill-nested-card__skill-children-list']}>
                    {skill.children.map((item: skills_skills) => (
                        <li key={item.id} className={classes['skill-nested-card__skill-children']}>
                            <SkillCardWithNestChildren
                                skillTypes={skillTypes}
                                currentSkill={item}
                                isEdit={isEdit}
                                skillsList={skillsList}
                                removeSkill={removeSkill}
                                updateNestSkill={updateNestSkill}
                            />
                        </li>
                    ))}
                </ul>
                <div className={classes['skill-nested-card__button-edit']} onClick={() => setIsEdit(!isEdit)}>
                    {!isEdit ? <EditIcon size={17} /> : <FilledEditIcon size={17} />}
                </div>
            </div>
            {popUpPortal && (
                <Dialog
                    id="portal-edit-root"
                    dom={(
                        <PortalPopUpSkillAddWithNest
                            closePortal={() => togglePopUpPortal(!popUpPortal)}
                            text={`весь образовательный результат «${skill.name}»`}
                            removeSkill={removeSkill}
                            skillId={skill.id}
                        />
                    )}
                />
            )}
            {isEditPortal && (
                <Dialog
                    id="portal-edit-root"
                    dom={(
                        <SKillCardWithNestEdit
                            skillType={skillType}
                            skillTypes={skillTypes}
                            skillsList={skillsList}
                            closePortal={() => toggleIsEditPortal(!isEditPortal)}
                            updateNestSkill={updateNestSkill}
                        />
                    )}
                />
            )}
        </>
    );
};
