import { useEffect } from 'react';
import { gql, useLazyQuery } from '@apollo/client';
import { downloadJson } from '@admin/ScheduleGenerationPage/utils';

const ADDITIONAL_GENERATION_CONFIG = gql`
    query additionalGenerationConfig($id: String!) {
        getAdditionalGenerationConfig(generationProcessId: $id)
    }
`;

export function useDownloadConfigForAdditionalGenerationQuery(id: string) {
    const [downloadConfig, { data, loading }] = useLazyQuery(ADDITIONAL_GENERATION_CONFIG, {
        variables: { id }, fetchPolicy: 'network-only',
    });
    useEffect(() => {
        if (data) {
            downloadJson('config.json', data.getAdditionalGenerationConfig);
        }
    }, [data]);

    return { downloadConfig, loading };
}
