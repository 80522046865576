import React, { useState } from 'react';
import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import { NavigationItems } from './NavigationItems';
import { MenuProfile } from './MenuProfile';
import classes from './Menu.module.scss';
import UserView from '../../store/UserView';

export const Menu = observer((): JSX.Element => {
    const { user } = UserView;

    const [toggleBurger, setToggleBurger] = useState<boolean>(false);
    const toggleBurgerMenu = () => setToggleBurger((prev) => !prev);

    return (
        <div className={classes.menu}>
            <div className={classes.menu__profile}>
                {user && (<MenuProfile currentUser={user} />)}
            </div>

            <div className={cn(classes.menu__navigation, {
                [classes.menu__navigation_active]: toggleBurger,
            })}
            >
                <NavigationItems toggleBurgerMenu={toggleBurgerMenu} />
            </div>

            {/* <ExitButton /> */}

            <button
                type="button"
                className={cn(classes.menu__burger, {
                    [classes.menu__burger_active]: toggleBurger,
                })}
                onClick={() => toggleBurgerMenu()}
            >
                <span className={classes.menu__line} />
                <span className={classes.menu__line} />
                <span className={classes.menu__line} />
            </button>
        </div>
    );
});
