import React, { Fragment } from 'react';

import { ListUser_users_list } from '../../apollo-types';

enum CorrectRoles {
    Teacher = 'Teacher',
    Student = 'Student',
    Admin = 'Admin',
}
interface Props {
    user: ListUser_users_list;
}
export const Roles = ({ user }: Props) => {
    const roles = user.roles?.filter(role => Object.keys(CorrectRoles).includes(role.name));
    return (
        <td>
            {roles?.map((item, _index, array) => (
                <Fragment key={item.text}>
                    {`${item.text}${_index + 1 < array.length ? ',\u00A0' : ''}`}
                </Fragment>
            ))}
        </td>
    );
};
