/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetSubspacesForInviteLink
// ====================================================

export interface GetSubspacesForInviteLink_ownSubspaces {
  __typename: "Subspace";
  id: string;
  name: string;
}

export interface GetSubspacesForInviteLink {
  ownSubspaces: GetSubspacesForInviteLink_ownSubspaces[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: createExternalModuleInvitation
// ====================================================

export interface createExternalModuleInvitation_createExternalModuleInvitation {
  __typename: "ExternalModuleInvitation";
  id: string;
}

export interface createExternalModuleInvitation {
  createExternalModuleInvitation: createExternalModuleInvitation_createExternalModuleInvitation;
}

export interface createExternalModuleInvitationVariables {
  createExternalModuleInvitationInput: CreateExternalModuleInvitationInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getExternalModule
// ====================================================

export interface getExternalModule_externalModule {
  __typename: "ExternalModule";
  id: string;
  status: ExternalModuleStatus;
  ownerFullName: string;
  ownerEmail: string;
  ownerInfo: string | null;
  name: string;
  description: string | null;
  minStudentCount: number | null;
  maxStudentCount: number | null;
  maxWaveCount: number | null;
  creditCount: number | null;
  syllabusReference: string | null;
  invitationId: string;
}

export interface getExternalModule {
  externalModule: getExternalModule_externalModule;
}

export interface getExternalModuleVariables {
  externalModuleInput: ExternalModuleInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateExternalModuleStatus
// ====================================================

export interface updateExternalModuleStatus_updateExternalModuleStatus {
  __typename: "ExternalModule";
  id: string;
  status: ExternalModuleStatus;
}

export interface updateExternalModuleStatus {
  updateExternalModuleStatus: updateExternalModuleStatus_updateExternalModuleStatus;
}

export interface updateExternalModuleStatusVariables {
  updateExternalModuleStatusInput: UpdateExternalModuleStatusInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: listExternalModules
// ====================================================

export interface listExternalModules_externalModules_reviews_reviewer {
  __typename: "User";
  id: string;
  firstName: string;
  lastName: string;
  patronymic: string | null;
}

export interface listExternalModules_externalModules_reviews {
  __typename: "ExternalModuleReview";
  id: string;
  message: string;
  reviewer: listExternalModules_externalModules_reviews_reviewer;
}

export interface listExternalModules_externalModules {
  __typename: "ExternalModule";
  id: string;
  status: ExternalModuleStatus;
  ownerFullName: string;
  ownerEmail: string;
  ownerInfo: string | null;
  name: string;
  description: string | null;
  minStudentCount: number | null;
  maxStudentCount: number | null;
  maxWaveCount: number | null;
  creditCount: number | null;
  syllabusReference: string | null;
  reviews: listExternalModules_externalModules_reviews[];
}

export interface listExternalModules {
  externalModules: listExternalModules_externalModules[];
}

export interface listExternalModulesVariables {
  externalModulesInput?: ExternalModulesInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: deleteExternalModule
// ====================================================

export interface deleteExternalModule_updateExternalModuleStatus {
  __typename: "ExternalModule";
  id: string;
}

export interface deleteExternalModule {
  updateExternalModuleStatus: deleteExternalModule_updateExternalModuleStatus;
}

export interface deleteExternalModuleVariables {
  externalModuleId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetExternalInvitationList
// ====================================================

export interface GetExternalInvitationList_externalModuleInvitations_subspace {
  __typename: "Subspace";
  id: string;
  name: string;
}

export interface GetExternalInvitationList_externalModuleInvitations {
  __typename: "ExternalModuleInvitation";
  id: string;
  subspace: GetExternalInvitationList_externalModuleInvitations_subspace;
}

export interface GetExternalInvitationList {
  externalModuleInvitations: GetExternalInvitationList_externalModuleInvitations[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum ExternalModuleStatus {
  approved = "approved",
  deleted = "deleted",
  draft = "draft",
  revision = "revision",
  verification = "verification",
}

export interface CreateExternalModuleInvitationInput {
  id: string;
  subspaceId: string;
}

export interface ExternalModuleInput {
  externalModuleId: string;
  invitationId?: string | null;
}

export interface ExternalModulesInput {
  externalModuleId?: string | null;
  status?: ExternalModuleStatus | null;
  ownerFullName?: string | null;
}

export interface UpdateExternalModuleStatusInput {
  externalModuleId: string;
  status: ExternalModuleStatus;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
