import React from 'react';
import {
    filteredTeacherAssignmentInstances_filteredTeacherAssignmentInstances,
} from '../../TeacherAssignmentsApolloTypes';

import {
    TeacherAssignmentAddNote,
} from '../TeacherAssignmentAddNote/TeacherAssignmentAddNote';

import {
    TeacherAssignmentNotes,
} from '../TeacherAssignmentNotes/TeacherAssignmentNotes';

import classes from './TeacherAssignmentSidebar.module.scss';

interface Props {
    // eslint-disable-next-line max-len
    assignment?: filteredTeacherAssignmentInstances_filteredTeacherAssignmentInstances;
    updateNote: (teacherNote: string) => void;
}

export function TeacherAssignmentSidebar({
    assignment,
    updateNote,
}: Props) {
    return (
        <div className={classes.teacherAssignment__sidebar}>
            <TeacherAssignmentAddNote
                assignment={assignment}
                updateNote={updateNote}
            />

            <TeacherAssignmentNotes
                assignment={assignment}
            />
        </div>
    );
}
