import React from 'react';

import { EvaluationHeader } from './EvaluationHeader/EvaluationHeader';
import { EvaluationSection } from './EvaluationSection';

import classes from './Evaluation.module.scss';

export const Evaluation = (): JSX.Element => (
    <div className={classes.evaluation}>
        <EvaluationHeader />
        <EvaluationSection />
    </div>
);
