import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import { EventStatus } from '@admin/ScheduleGenerationPage/utils';
import { IconDeprecated } from '@common';
import { ScheduleEvent } from '@admin/ScheduleGenerationPage/types';
import { Alert } from '@common/Alert';
import { useUpdateMeetingRoomMutation } from '@admin/ScheduleGenerationPage/MeetingDetails/apolloHooks';
import { ApolloError } from '@apollo/client';
import { IndoorRoom } from './IndoorRoom';
import { OnlineRoom } from './OnlineRoom';

import classes from './Room.module.scss';

interface Props {
    event: ScheduleEvent;
    isOnline: boolean;
    edibleRoom: boolean,
    setEdibleRoom: React.Dispatch<React.SetStateAction<boolean>>,
    setEdibleTeacher: React.Dispatch<React.SetStateAction<string>>,
}

export function Room({
    isOnline,
    setEdibleTeacher,
    ...rest
}: Props): JSX.Element {
    const [errors, setErrors] = useState<JSX.Element[] | []>([]);
    function setErrorsFunction(error: ApolloError) {
        const normalError = error.message.split('Cannot return null');
        setErrors([...errors, (<Alert message={normalError[0]} />)]);
    }
    const { updateMeetingRoom } = useUpdateMeetingRoomMutation(
        rest.event.id, rest.event.type, setErrorsFunction,
    );

    const ViewRoom = ({ name, link }: { name: string; link: string }) => (
        <div className={classes.room__name}>
            <Link to={link} className={classes.link}>
                {name}
            </Link>
            {rest.event.status !== EventStatus.DELETED && (
                <IconDeprecated
                    id="editPenBlack"
                    className={classes.room__penIcon}
                    click={() => {
                        rest.setEdibleRoom(true);
                        setEdibleTeacher('-1');
                    }}
                />
            )}
        </div>
    );

    return (
        <div className={classes.room}>
            {isOnline
                // eslint-disable-next-line react/jsx-props-no-spreading
                ? <OnlineRoom {...rest} ViewMode={ViewRoom} updateRoom={updateMeetingRoom} />
                // eslint-disable-next-line react/jsx-props-no-spreading
                : <IndoorRoom {...rest} ViewMode={ViewRoom} updateRoom={updateMeetingRoom} />}
            {errors}
        </div>
    );
}
