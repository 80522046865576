import React from 'react';
import { Dictionary, map } from 'lodash';

import { ScheduleCard } from './ScheduleCard';
import { ScheduleEvent } from './types';

import classes from './ScheduleGenerationPage.module.scss';

interface Props {
    dateMap: Map<string, Dictionary<ScheduleEvent[]>>;
    setDialog: (flag: boolean) => void;
}

function keyFrom({
    id,
    type,
}: ScheduleEvent): string {
    return `${id}_${type}`;
}

export const ShowEventCards = React.memo(({ dateMap, setDialog }: Props) => (
    <>
        {Array.from(dateMap.keys()).map(day => (
            <div key={day} className={classes.schedule__day}>
                <div className={classes.schedule__dayTitle}>{day}</div>
                <div className={classes.schedule__events}>
                    {map(dateMap.get(day), (items, time) => (
                        <div key={time} className={classes.schedule__time}>
                            <div className={classes.schedule__timeTitle}>{time}</div>
                            <div className={classes.schedule__meetings}>
                                {items.map(item => (
                                    <ScheduleCard
                                        key={keyFrom(item)}
                                        event={item}
                                        setDialog={setDialog}
                                    />
                                ))}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        ))}
    </>
));
