import { observer } from 'mobx-react';
import React from 'react';

import colors from '../../store/ColorConstans';

interface Props {
    handler?: () => void;
    size?: number;
    firstColor?: string;
    secondColor?: string;
    thirdColor?: string;
    className?: string;
}

export const FilledPlusIcon = observer(({
    size = 42,
    firstColor = colors.firstColor,
    secondColor = colors.secondColor,
    thirdColor = colors.thirdColor,
    className,
    handler,
}: Props): JSX.Element => (
    <div className={className} style={{ height: `${size}px`, width: `${size}px`, cursor: 'pointer' }} onClick={handler}>
        <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_iiiii_108_1021)">
                <path d="M21 21H13H21Z" fill="url(#paint0_linear_108_1021)" />
                <path d="M21 41C32.0457 41 41 32.0457 41 21C41 9.9543 32.0457 1 21 1C9.9543 1 1 9.9543 1 21C1 32.0457 9.9543 41 21 41Z" fill="url(#paint1_linear_108_1021)" />
            </g>
            <path d="M22 13C22 12.4477 21.5523 12 21 12C20.4477 12 20 12.4477 20 13H22ZM20 29C20 29.5523 20.4477 30 21 30C21.5523 30 22 29.5523 22 29H20ZM29 22C29.5523 22 30 21.5523 30 21C30 20.4477 29.5523 20 29 20V22ZM13 20C12.4477 20 12 20.4477 12 21C12 21.5523 12.4477 22 13 22V20ZM20 13V21H22V13H20ZM20 21V29H22V21H20ZM21 22H29V20H21V22ZM21 20H13V22H21V20ZM40 21C40 31.4934 31.4934 40 21 40V42C32.598 42 42 32.598 42 21H40ZM21 40C10.5066 40 2 31.4934 2 21H0C0 32.598 9.40202 42 21 42V40ZM2 21C2 10.5066 10.5066 2 21 2V0C9.40202 0 0 9.40202 0 21H2ZM21 2C31.4934 2 40 10.5066 40 21H42C42 9.40202 32.598 0 21 0V2Z" fill="white" />
            <defs>
                <filter id="filter0_iiiii_108_1021" x="-4" y="-4" width="48" height="48" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dx="2" dy="2" />
                    <feGaussianBlur stdDeviation="3" />
                    <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                    <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.2 0" />
                    <feBlend mode="normal" in2="shape" result="effect1_innerShadow_108_1021" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dx="-4" dy="-4" />
                    <feGaussianBlur stdDeviation="3" />
                    <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                    <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.2 0" />
                    <feBlend mode="normal" in2="effect1_innerShadow_108_1021" result="effect2_innerShadow_108_1021" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dx="1" dy="1" />
                    <feGaussianBlur stdDeviation="1" />
                    <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                    <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.2 0" />
                    <feBlend mode="normal" in2="effect2_innerShadow_108_1021" result="effect3_innerShadow_108_1021" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dx="-2" dy="-2" />
                    <feGaussianBlur stdDeviation="2" />
                    <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                    <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.2 0" />
                    <feBlend mode="normal" in2="effect3_innerShadow_108_1021" result="effect4_innerShadow_108_1021" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dx="-1" dy="-1" />
                    <feGaussianBlur stdDeviation="0.5" />
                    <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                    <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.2 0" />
                    <feBlend mode="normal" in2="effect4_innerShadow_108_1021" result="effect5_innerShadow_108_1021" />
                </filter>
                <linearGradient id="paint0_linear_108_1021" x1="43.8916" y1="18.7215" x2="0.41115" y2="35.694" gradientUnits="userSpaceOnUse">
                    <stop offset="0.130208" stopColor={firstColor} />
                    <stop offset="0.734375" stopColor={secondColor} />
                    <stop offset="0.932292" stopColor={thirdColor} />
                </linearGradient>
                <linearGradient id="paint1_linear_108_1021" x1="43.8916" y1="18.7215" x2="0.41115" y2="35.694" gradientUnits="userSpaceOnUse">
                    <stop offset="0.130208" stopColor={firstColor} />
                    <stop offset="0.734375" stopColor={secondColor} />
                    <stop offset="0.932292" stopColor={thirdColor} />
                </linearGradient>
            </defs>
        </svg>
    </div>
));
