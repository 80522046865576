import React from 'react';
import { nanoid } from 'nanoid';
import { Input, InputTypes } from '../CustomInput';

import styles from './Section.module.scss';

type Option = {
    id: string;
    name: string;
};

export const InputFormInitialData = {
    id: '',
    name: '',
};

interface Props {
    label: string;
    placeholder: string;
    data: Option;

    onEnter(): void;
    setData(data: Option): void;
}

export function InputForm({
    label,
    placeholder,
    data,
    setData,
    onEnter,
}: Props) {
    return (
        <div className={styles.inputForm}>
            <Input
                label={label}
                placeholder={placeholder}
                value={data.name}
                type={InputTypes.Input}
                onChange={event => setData({ name: event.target.value, id: nanoid() })}
                onEnter={onEnter}
            />
        </div>
    );
}
