import React, { useEffect, useState } from 'react';
import { Trajectory } from '@admin/Trajectories/types';
import { observer } from 'mobx-react';
import { trajectoryStore } from '../TrajectoryForm/Store/TrajectoryStore';
import classes from './TrajectoriesList.module.scss';

import { TrajectoriesListHeader } from './TrajectoriesListHeader';
import { TrajectoriesListSettings } from './TrajectoriesListSettings';
import { Props } from './TrajectoriesListTypes';
import { TrajectoriesMarks } from './TrajectoriesMarks';
import { TrajectoryCard } from './TrajectoryCard';

export const TrajectoriesList = observer(({
    handleFormOpen, spaceId, handleUpdatePublish, handleUpdateSave,
}: Props): JSX.Element => {
    useEffect(() => {
        trajectoryStore.updateSpaceId(spaceId);
    }, []);
    const [trajectoryId, setTtrajectoryId] = useState('');

    const editTrajectory = (id: string) => {
        if (trajectoryId !== id) {
            setTtrajectoryId(id);
        }
    };
    return (
        <div className={classes.trajectoriesList}>
            <TrajectoriesListHeader handleFormOpen={handleFormOpen} />
            <TrajectoriesMarks />
            {trajectoryStore.trajectories.length
                ? (
                    <>
                        <TrajectoriesListSettings />
                    </>
                )
                : (
                    <p className={classes.trajectoriesList__disabled}>
                        Тут будет отображаться список траекторий
                    </p>
                )}
            {trajectoryStore.trajectories?.map((trajectory: Trajectory) => (
                <TrajectoryCard
                    key={trajectory.id}
                    trajectory={trajectory}
                    spaceId={spaceId}
                    handleUpdateSave={handleUpdateSave}
                    handleUpdatePublish={handleUpdatePublish}
                    trajectoryId={trajectoryId === trajectory.id}
                    editTrajectory={editTrajectory}
                />
            ))}
        </div>
    );
});
