import React from 'react';
import { BorderPlusIcon } from '@common/svg';
import { Calendar } from '../Calendar';
import classes from './DateSelector.module.scss';

interface Props {
    currentDate: Date|null,
    onChange(data:any): void,
    addHandler():void,
}

export function DateSelector(
    { currentDate, onChange, addHandler }: Props,
) {
    return (
        <div className={classes.dateSelector}>
            <Calendar
                onChange={onChange}
                currentDate={currentDate}
            />
            <BorderPlusIcon
                handler={addHandler}
                className={classes.dateSelector__plus}
            />
        </div>
    );
}
