import {
    Attendance_Attendance_attendance_wave_module,
    Attendance_Attendance_attendance_wave_students_user,
    Attendance_Attendance_attendance_wave_students,
    Attendance_Attendance_attendance_meetingInstances_meetingInstanceToTeachers_teacher,
    Attendance_Attendance_attendance_wave,
    Attendance_Attendance_attendance_meetingInstances_meeting_duration,
    Attendance_Attendance_attendance_meetingInstances,
    Attendance_Attendance_attendance_studentsWithAbsencesCount,
    Attendance_Attendance_attendance_meetingInstancesAttendance,
    Attendance_Attendance_attendance_meetingInstances_meeting,
    Attendance_Attendance_attendance,
} from './graphql-types';

export interface QueryParams {
    moduleId: string;
    btiInstanceId: string;
    skillTypeId: string;
    spaceId: string;
}

export interface Table {
    id: string;
    wave: Wave;
    rows: TableRow[];
    columns: TableColumn[];
}

export interface TableRow {
    rowId: string;
    studentWithAbsencesCount: StudentWithAbsencesCount;
    meetingInstancesAttendance: MeetingInstanceAttendance[];
}

export interface TableColumn {
    columnId: string;
    meetingInstance: MeetingInstance;
    meetingInstancesAttendance: MeetingInstanceAttendance[];
}

export interface AttendanceInput {
    baseTimeIntervalInstanceId: string;
    moduleId: string;
}

export interface Attendance extends Attendance_Attendance_attendance {}

export interface MeetingInstance extends Attendance_Attendance_attendance_meetingInstances {}

export interface Meeting extends Attendance_Attendance_attendance_meetingInstances_meeting {}

export interface StudentWithAbsencesCount
    extends Attendance_Attendance_attendance_studentsWithAbsencesCount{}

export interface Wave extends Attendance_Attendance_attendance_wave {}

export interface Teacher
    extends Attendance_Attendance_attendance_meetingInstances_meetingInstanceToTeachers_teacher {}

export interface Student extends Attendance_Attendance_attendance_wave_students {}

export interface MeetingInstanceAttendance
    extends Attendance_Attendance_attendance_meetingInstancesAttendance {}

export interface User extends Attendance_Attendance_attendance_wave_students_user {}

export interface Duration
    extends Attendance_Attendance_attendance_meetingInstances_meeting_duration {}

export interface Module extends Attendance_Attendance_attendance_wave_module {}

export enum AttendanceMode {
    'byDates' = 'Посещаемость по датам',
    'byMeetings' = 'Посещаемость по встречам',
}

export interface MissingMeetingInstance extends MeetingInstance {
    waveIndex: number;
}
