/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetStudentModuleAssessment
// ====================================================

export interface GetStudentModuleAssessment_studentModuleMeetingInstances_meeting {
  __typename: "Meeting";
  topic: string | null;
}

export interface GetStudentModuleAssessment_studentModuleMeetingInstances_meetingInstanceToTeachers_teacher_user {
  __typename: "User";
  lastName: string;
  firstName: string;
  patronymic: string | null;
}

export interface GetStudentModuleAssessment_studentModuleMeetingInstances_meetingInstanceToTeachers_teacher {
  __typename: "Teacher";
  id: string;
  user: GetStudentModuleAssessment_studentModuleMeetingInstances_meetingInstanceToTeachers_teacher_user;
}

export interface GetStudentModuleAssessment_studentModuleMeetingInstances_meetingInstanceToTeachers {
  __typename: "MeetingInstanceTeacher";
  teacher: GetStudentModuleAssessment_studentModuleMeetingInstances_meetingInstanceToTeachers_teacher;
}

export interface GetStudentModuleAssessment_studentModuleMeetingInstances {
  __typename: "MeetingInstance";
  id: string;
  startDate: string;
  endDate: string;
  meeting: GetStudentModuleAssessment_studentModuleMeetingInstances_meeting;
  meetingInstanceToTeachers: GetStudentModuleAssessment_studentModuleMeetingInstances_meetingInstanceToTeachers[];
}

export interface GetStudentModuleAssessment_studentModuleAssessment_answers {
  __typename: "StudentAssessmentAnswer";
  optionId: string | null;
  questionId: string;
  value: string | null;
}

export interface GetStudentModuleAssessment_studentModuleAssessment_teacherAssessments_answers {
  __typename: "StudentAssessmentAnswer";
  optionId: string | null;
  questionId: string;
  value: string | null;
}

export interface GetStudentModuleAssessment_studentModuleAssessment_teacherAssessments {
  __typename: "StudentTeacherAssessment";
  id: string;
  teacherId: string;
  answers: GetStudentModuleAssessment_studentModuleAssessment_teacherAssessments_answers[] | null;
}

export interface GetStudentModuleAssessment_studentModuleAssessment {
  __typename: "StudentModuleAssessment";
  id: string;
  status: StudentAssessmentStatus;
  studentModuleId: string;
  answers: GetStudentModuleAssessment_studentModuleAssessment_answers[] | null;
  teacherAssessments: GetStudentModuleAssessment_studentModuleAssessment_teacherAssessments[] | null;
}

export interface GetStudentModuleAssessment {
  studentModuleMeetingInstances: GetStudentModuleAssessment_studentModuleMeetingInstances[];
  studentModuleAssessment: GetStudentModuleAssessment_studentModuleAssessment | null;
}

export interface GetStudentModuleAssessmentVariables {
  input: StudentModuleMeetingInstancesInput;
  studentModuleId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateModuleAssessment
// ====================================================

export interface UpdateModuleAssessment_updateStudentModuleAssessment {
  __typename: "StudentModuleAssessment";
  id: string;
}

export interface UpdateModuleAssessment {
  updateStudentModuleAssessment: UpdateModuleAssessment_updateStudentModuleAssessment;
}

export interface UpdateModuleAssessmentVariables {
  input: UpdateStudentModuleAssessmentInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: FinishModuleAssessment
// ====================================================

export interface FinishModuleAssessment_finishStudentModuleAssessment {
  __typename: "StudentModuleAssessment";
  id: string;
  status: StudentAssessmentStatus;
}

export interface FinishModuleAssessment {
  finishStudentModuleAssessment: FinishModuleAssessment_finishStudentModuleAssessment;
}

export interface FinishModuleAssessmentVariables {
  studentModuleId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: SelectionInstruction
// ====================================================

export interface SelectionInstruction {
  selectionInstruction: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetAllSkills
// ====================================================

export interface GetAllSkills_skills_parent {
  __typename: "Skill";
  id: string;
}

export interface GetAllSkills_skills {
  __typename: "Skill";
  id: string;
  name: string;
  typeId: string;
  parent: GetAllSkills_skills_parent | null;
}

export interface GetAllSkills {
  skills: GetAllSkills_skills[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: StudentsModulePriorityCount
// ====================================================

export interface StudentsModulePriorityCount_studentsModulePriorityCount {
  __typename: "StudentsModulePriorityCount";
  moduleId: string;
  count: number;
}

export interface StudentsModulePriorityCount {
  studentsModulePriorityCount: StudentsModulePriorityCount_studentsModulePriorityCount[];
}

export interface StudentsModulePriorityCountVariables {
  baseTimeIntervalInstanceId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetStudentInfo
// ====================================================

export interface GetStudentInfo_studentSchedule_meetingInstances_meeting {
  __typename: "Meeting";
  id: string;
}

export interface GetStudentInfo_studentSchedule_meetingInstances_meetingInstanceToStudents_student {
  __typename: "Student";
  id: string;
}

export interface GetStudentInfo_studentSchedule_meetingInstances_meetingInstanceToStudents {
  __typename: "MeetingInstanceToStudent";
  attendance: boolean;
  student: GetStudentInfo_studentSchedule_meetingInstances_meetingInstanceToStudents_student;
}

export interface GetStudentInfo_studentSchedule_meetingInstances {
  __typename: "MeetingInstance";
  id: string;
  startDate: string;
  endDate: string;
  meeting: GetStudentInfo_studentSchedule_meetingInstances_meeting;
  meetingInstanceToStudents: GetStudentInfo_studentSchedule_meetingInstances_meetingInstanceToStudents[];
}

export interface GetStudentInfo_studentSchedule_evaluationPointInstances_evaluationPoint {
  __typename: "EvaluationPoint";
  id: string;
}

export interface GetStudentInfo_studentSchedule_evaluationPointInstances {
  __typename: "EvaluationPointInstance";
  id: string;
  startDate: string;
  endDate: string;
  evaluationPoint: GetStudentInfo_studentSchedule_evaluationPointInstances_evaluationPoint;
}

export interface GetStudentInfo_studentSchedule_assignmentInstances_assignment {
  __typename: "Assignment";
  id: string;
}

export interface GetStudentInfo_studentSchedule_assignmentInstances {
  __typename: "AssignmentInstance";
  id: string;
  startDate: string;
  endDate: string;
  assignment: GetStudentInfo_studentSchedule_assignmentInstances_assignment;
}

export interface GetStudentInfo_studentSchedule {
  __typename: "Events";
  meetingInstances: GetStudentInfo_studentSchedule_meetingInstances[];
  evaluationPointInstances: GetStudentInfo_studentSchedule_evaluationPointInstances[];
  assignmentInstances: GetStudentInfo_studentSchedule_assignmentInstances[];
}

export interface GetStudentInfo_student_subspaces_subspace {
  __typename: "Subspace";
  id: string;
}

export interface GetStudentInfo_student_subspaces {
  __typename: "StudentSubspace";
  subspace: GetStudentInfo_student_subspaces_subspace;
}

export interface GetStudentInfo_student_modules_module_moduleTeachers_teacher_user {
  __typename: "User";
  firstName: string;
  lastName: string;
  patronymic: string | null;
}

export interface GetStudentInfo_student_modules_module_moduleTeachers_teacher {
  __typename: "Teacher";
  user: GetStudentInfo_student_modules_module_moduleTeachers_teacher_user;
}

export interface GetStudentInfo_student_modules_module_moduleTeachers_teacherRoles {
  __typename: "TeacherRole";
  name: string | null;
}

export interface GetStudentInfo_student_modules_module_moduleTeachers {
  __typename: "ModuleTeacher";
  teacher: GetStudentInfo_student_modules_module_moduleTeachers_teacher | null;
  teacherRoles: GetStudentInfo_student_modules_module_moduleTeachers_teacherRoles[] | null;
}

export interface GetStudentInfo_student_modules_module_prerequisiteSkills_skill {
  __typename: "Skill";
  id: string;
}

export interface GetStudentInfo_student_modules_module_prerequisiteSkills {
  __typename: "ModuleSkill";
  skill: GetStudentInfo_student_modules_module_prerequisiteSkills_skill;
  level: number | null;
}

export interface GetStudentInfo_student_modules_module_outputSkills_skill {
  __typename: "Skill";
  id: string;
}

export interface GetStudentInfo_student_modules_module_outputSkills {
  __typename: "ModuleSkill";
  skill: GetStudentInfo_student_modules_module_outputSkills_skill;
  level: number | null;
}

export interface GetStudentInfo_student_modules_module_meetings_format {
  __typename: "MeetingFormat";
  name: string;
}

export interface GetStudentInfo_student_modules_module_meetings_duration {
  __typename: "MeetingDuration";
  id: string;
  name: string | null;
  academicHours: number;
}

export interface GetStudentInfo_student_modules_module_meetings_prerequisiteSkills_skill {
  __typename: "Skill";
  id: string;
  name: string;
  fullName: string;
  typeId: string;
}

export interface GetStudentInfo_student_modules_module_meetings_prerequisiteSkills {
  __typename: "ModuleSkill";
  skill: GetStudentInfo_student_modules_module_meetings_prerequisiteSkills_skill;
  level: number | null;
}

export interface GetStudentInfo_student_modules_module_meetings_outputSkills_skill {
  __typename: "Skill";
  id: string;
  name: string;
  fullName: string;
  typeId: string;
}

export interface GetStudentInfo_student_modules_module_meetings_outputSkills {
  __typename: "ModuleSkill";
  skill: GetStudentInfo_student_modules_module_meetings_outputSkills_skill;
  level: number | null;
}

export interface GetStudentInfo_student_modules_module_meetings {
  __typename: "Meeting";
  id: string;
  topic: string | null;
  format: GetStudentInfo_student_modules_module_meetings_format | null;
  duration: GetStudentInfo_student_modules_module_meetings_duration | null;
  prerequisiteSkills: GetStudentInfo_student_modules_module_meetings_prerequisiteSkills[] | null;
  outputSkills: GetStudentInfo_student_modules_module_meetings_outputSkills[] | null;
  order: number | null;
}

export interface GetStudentInfo_student_modules_module_evaluationPoints_duration {
  __typename: "MeetingDuration";
  id: string;
  name: string | null;
  academicHours: number;
}

export interface GetStudentInfo_student_modules_module_evaluationPoints_evaluationPointSkills_skill {
  __typename: "Skill";
  id: string;
  name: string;
  fullName: string;
  typeId: string;
}

export interface GetStudentInfo_student_modules_module_evaluationPoints_evaluationPointSkills {
  __typename: "ModuleSkill";
  skill: GetStudentInfo_student_modules_module_evaluationPoints_evaluationPointSkills_skill;
  level: number | null;
}

export interface GetStudentInfo_student_modules_module_evaluationPoints {
  __typename: "EvaluationPoint";
  id: string;
  topic: string | null;
  description: string | null;
  order: number | null;
  duration: GetStudentInfo_student_modules_module_evaluationPoints_duration | null;
  evaluationPointSkills: GetStudentInfo_student_modules_module_evaluationPoints_evaluationPointSkills[] | null;
}

export interface GetStudentInfo_student_modules_module_assignments_duration {
  __typename: "MeetingDuration";
  id: string;
  name: string | null;
  academicHours: number;
}

export interface GetStudentInfo_student_modules_module_assignments_prerequisiteSkills_skill {
  __typename: "Skill";
  id: string;
  name: string;
  fullName: string;
  typeId: string;
}

export interface GetStudentInfo_student_modules_module_assignments_prerequisiteSkills {
  __typename: "ModuleSkill";
  skill: GetStudentInfo_student_modules_module_assignments_prerequisiteSkills_skill;
  level: number | null;
}

export interface GetStudentInfo_student_modules_module_assignments_outputSkills_skill {
  __typename: "Skill";
  id: string;
  name: string;
  fullName: string;
  typeId: string;
}

export interface GetStudentInfo_student_modules_module_assignments_outputSkills {
  __typename: "ModuleSkill";
  skill: GetStudentInfo_student_modules_module_assignments_outputSkills_skill;
  level: number | null;
}

export interface GetStudentInfo_student_modules_module_assignments {
  __typename: "Assignment";
  id: string;
  topic: string | null;
  duration: GetStudentInfo_student_modules_module_assignments_duration | null;
  prerequisiteSkills: GetStudentInfo_student_modules_module_assignments_prerequisiteSkills[] | null;
  outputSkills: GetStudentInfo_student_modules_module_assignments_outputSkills[] | null;
  order: number | null;
}

export interface GetStudentInfo_student_modules_module {
  __typename: "Module";
  id: string;
  name: string | null;
  description: string | null;
  moduleTeachers: GetStudentInfo_student_modules_module_moduleTeachers[] | null;
  prerequisiteSkills: GetStudentInfo_student_modules_module_prerequisiteSkills[] | null;
  outputSkills: GetStudentInfo_student_modules_module_outputSkills[] | null;
  meetings: GetStudentInfo_student_modules_module_meetings[] | null;
  evaluationPoints: GetStudentInfo_student_modules_module_evaluationPoints[] | null;
  assignments: GetStudentInfo_student_modules_module_assignments[] | null;
}

export interface GetStudentInfo_student_modules_subspace_type {
  __typename: "SubspaceType";
  id: string;
  name: string;
}

export interface GetStudentInfo_student_modules_subspace {
  __typename: "Subspace";
  id: string;
  name: string;
  type: GetStudentInfo_student_modules_subspace_type;
}

export interface GetStudentInfo_student_modules {
  __typename: "StudentModule";
  module: GetStudentInfo_student_modules_module;
  subspace: GetStudentInfo_student_modules_subspace;
}

export interface GetStudentInfo_student_activeBaseTimeIntervalInstance_baseTimeIntervalInstance {
  __typename: "BaseTimeIntervalInstance";
  id: string;
}

export interface GetStudentInfo_student_activeBaseTimeIntervalInstance {
  __typename: "StudentBaseTimeIntervalInstance";
  baseTimeIntervalInstance: GetStudentInfo_student_activeBaseTimeIntervalInstance_baseTimeIntervalInstance;
}

export interface GetStudentInfo_student_baseTimeIntervalInstances_baseTimeIntervalInstance_spaceBaseTimeInterval_space_baseTimeIntervalType {
  __typename: "BaseTimeIntervalType";
  name: string;
}

export interface GetStudentInfo_student_baseTimeIntervalInstances_baseTimeIntervalInstance_spaceBaseTimeInterval_space {
  __typename: "Space";
  baseTimeIntervalType: GetStudentInfo_student_baseTimeIntervalInstances_baseTimeIntervalInstance_spaceBaseTimeInterval_space_baseTimeIntervalType;
}

export interface GetStudentInfo_student_baseTimeIntervalInstances_baseTimeIntervalInstance_spaceBaseTimeInterval {
  __typename: "SpaceBaseTimeInterval";
  order: number;
  space: GetStudentInfo_student_baseTimeIntervalInstances_baseTimeIntervalInstance_spaceBaseTimeInterval_space;
}

export interface GetStudentInfo_student_baseTimeIntervalInstances_baseTimeIntervalInstance {
  __typename: "BaseTimeIntervalInstance";
  id: string;
  selectionStatus: SelectionStatus;
  startDate: string;
  endDate: string;
  spaceBaseTimeInterval: GetStudentInfo_student_baseTimeIntervalInstances_baseTimeIntervalInstance_spaceBaseTimeInterval;
}

export interface GetStudentInfo_student_baseTimeIntervalInstances {
  __typename: "StudentBaseTimeIntervalInstance";
  baseTimeIntervalInstance: GetStudentInfo_student_baseTimeIntervalInstances_baseTimeIntervalInstance;
}

export interface GetStudentInfo_student {
  __typename: "Student";
  subspaces: GetStudentInfo_student_subspaces[];
  modules: GetStudentInfo_student_modules[];
  activeBaseTimeIntervalInstance: GetStudentInfo_student_activeBaseTimeIntervalInstance | null;
  baseTimeIntervalInstances: GetStudentInfo_student_baseTimeIntervalInstances[];
}

export interface GetStudentInfo_moduleSettings_meetingSkillTypes {
  __typename: "SkillTypeSettings";
  id: string;
  isLevelIncreased: boolean;
}

export interface GetStudentInfo_moduleSettings_moduleSkillTypes {
  __typename: "SkillTypeSettings";
  id: string;
  isLevelIncreased: boolean;
}

export interface GetStudentInfo_moduleSettings {
  __typename: "ModuleSettings";
  meetingSkillTypes: GetStudentInfo_moduleSettings_meetingSkillTypes[] | null;
  moduleSkillTypes: GetStudentInfo_moduleSettings_moduleSkillTypes[] | null;
}

export interface GetStudentInfo {
  studentSchedule: GetStudentInfo_studentSchedule;
  student: GetStudentInfo_student | null;
  moduleSettings: GetStudentInfo_moduleSettings;
}

export interface GetStudentInfoVariables {
  studentId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetCurrentOrPastBTI
// ====================================================

export interface GetCurrentOrPastBTI_student_requestedBaseTimeIntervalInstance_baseTimeIntervalInstance_moduleAssessment {
  __typename: "ModuleAssessment";
  id: string;
  startDate: any | null;
  isDynamicStartDate: boolean;
  endDate: any;
}

export interface GetCurrentOrPastBTI_student_requestedBaseTimeIntervalInstance_baseTimeIntervalInstance {
  __typename: "BaseTimeIntervalInstance";
  id: string;
  startDate: string;
  moduleAssessment: GetCurrentOrPastBTI_student_requestedBaseTimeIntervalInstance_baseTimeIntervalInstance_moduleAssessment | null;
}

export interface GetCurrentOrPastBTI_student_requestedBaseTimeIntervalInstance_studentSubspaces_subspace_type {
  __typename: "SubspaceType";
  name: string;
}

export interface GetCurrentOrPastBTI_student_requestedBaseTimeIntervalInstance_studentSubspaces_subspace {
  __typename: "Subspace";
  id: string;
  name: string;
  type: GetCurrentOrPastBTI_student_requestedBaseTimeIntervalInstance_studentSubspaces_subspace_type;
}

export interface GetCurrentOrPastBTI_student_requestedBaseTimeIntervalInstance_studentSubspaces_studentModules_module_moduleTeachers_teacher_user {
  __typename: "User";
  firstName: string;
  lastName: string;
  patronymic: string | null;
}

export interface GetCurrentOrPastBTI_student_requestedBaseTimeIntervalInstance_studentSubspaces_studentModules_module_moduleTeachers_teacher {
  __typename: "Teacher";
  user: GetCurrentOrPastBTI_student_requestedBaseTimeIntervalInstance_studentSubspaces_studentModules_module_moduleTeachers_teacher_user;
}

export interface GetCurrentOrPastBTI_student_requestedBaseTimeIntervalInstance_studentSubspaces_studentModules_module_moduleTeachers_teacherRoles {
  __typename: "TeacherRole";
  name: string | null;
}

export interface GetCurrentOrPastBTI_student_requestedBaseTimeIntervalInstance_studentSubspaces_studentModules_module_moduleTeachers {
  __typename: "ModuleTeacher";
  teacher: GetCurrentOrPastBTI_student_requestedBaseTimeIntervalInstance_studentSubspaces_studentModules_module_moduleTeachers_teacher | null;
  teacherRoles: GetCurrentOrPastBTI_student_requestedBaseTimeIntervalInstance_studentSubspaces_studentModules_module_moduleTeachers_teacherRoles[] | null;
}

export interface GetCurrentOrPastBTI_student_requestedBaseTimeIntervalInstance_studentSubspaces_studentModules_module_prerequisiteSkills_skill {
  __typename: "Skill";
  id: string;
}

export interface GetCurrentOrPastBTI_student_requestedBaseTimeIntervalInstance_studentSubspaces_studentModules_module_prerequisiteSkills {
  __typename: "ModuleSkill";
  skill: GetCurrentOrPastBTI_student_requestedBaseTimeIntervalInstance_studentSubspaces_studentModules_module_prerequisiteSkills_skill;
  level: number | null;
}

export interface GetCurrentOrPastBTI_student_requestedBaseTimeIntervalInstance_studentSubspaces_studentModules_module_outputSkills_skill {
  __typename: "Skill";
  id: string;
}

export interface GetCurrentOrPastBTI_student_requestedBaseTimeIntervalInstance_studentSubspaces_studentModules_module_outputSkills {
  __typename: "ModuleSkill";
  skill: GetCurrentOrPastBTI_student_requestedBaseTimeIntervalInstance_studentSubspaces_studentModules_module_outputSkills_skill;
  level: number | null;
}

export interface GetCurrentOrPastBTI_student_requestedBaseTimeIntervalInstance_studentSubspaces_studentModules_module_meetings_format {
  __typename: "MeetingFormat";
  name: string;
}

export interface GetCurrentOrPastBTI_student_requestedBaseTimeIntervalInstance_studentSubspaces_studentModules_module_meetings_duration {
  __typename: "MeetingDuration";
  id: string;
  name: string | null;
  academicHours: number;
}

export interface GetCurrentOrPastBTI_student_requestedBaseTimeIntervalInstance_studentSubspaces_studentModules_module_meetings_prerequisiteSkills_skill {
  __typename: "Skill";
  id: string;
  name: string;
  fullName: string;
  typeId: string;
}

export interface GetCurrentOrPastBTI_student_requestedBaseTimeIntervalInstance_studentSubspaces_studentModules_module_meetings_prerequisiteSkills {
  __typename: "ModuleSkill";
  skill: GetCurrentOrPastBTI_student_requestedBaseTimeIntervalInstance_studentSubspaces_studentModules_module_meetings_prerequisiteSkills_skill;
  level: number | null;
}

export interface GetCurrentOrPastBTI_student_requestedBaseTimeIntervalInstance_studentSubspaces_studentModules_module_meetings_outputSkills_skill {
  __typename: "Skill";
  id: string;
  name: string;
  fullName: string;
  typeId: string;
}

export interface GetCurrentOrPastBTI_student_requestedBaseTimeIntervalInstance_studentSubspaces_studentModules_module_meetings_outputSkills {
  __typename: "ModuleSkill";
  skill: GetCurrentOrPastBTI_student_requestedBaseTimeIntervalInstance_studentSubspaces_studentModules_module_meetings_outputSkills_skill;
  level: number | null;
}

export interface GetCurrentOrPastBTI_student_requestedBaseTimeIntervalInstance_studentSubspaces_studentModules_module_meetings {
  __typename: "Meeting";
  id: string;
  topic: string | null;
  format: GetCurrentOrPastBTI_student_requestedBaseTimeIntervalInstance_studentSubspaces_studentModules_module_meetings_format | null;
  duration: GetCurrentOrPastBTI_student_requestedBaseTimeIntervalInstance_studentSubspaces_studentModules_module_meetings_duration | null;
  prerequisiteSkills: GetCurrentOrPastBTI_student_requestedBaseTimeIntervalInstance_studentSubspaces_studentModules_module_meetings_prerequisiteSkills[] | null;
  outputSkills: GetCurrentOrPastBTI_student_requestedBaseTimeIntervalInstance_studentSubspaces_studentModules_module_meetings_outputSkills[] | null;
  order: number | null;
}

export interface GetCurrentOrPastBTI_student_requestedBaseTimeIntervalInstance_studentSubspaces_studentModules_module_evaluationPoints_duration {
  __typename: "MeetingDuration";
  id: string;
  name: string | null;
  academicHours: number;
}

export interface GetCurrentOrPastBTI_student_requestedBaseTimeIntervalInstance_studentSubspaces_studentModules_module_evaluationPoints_evaluationPointSkills_skill {
  __typename: "Skill";
  id: string;
  name: string;
  fullName: string;
  typeId: string;
}

export interface GetCurrentOrPastBTI_student_requestedBaseTimeIntervalInstance_studentSubspaces_studentModules_module_evaluationPoints_evaluationPointSkills {
  __typename: "ModuleSkill";
  skill: GetCurrentOrPastBTI_student_requestedBaseTimeIntervalInstance_studentSubspaces_studentModules_module_evaluationPoints_evaluationPointSkills_skill;
  level: number | null;
}

export interface GetCurrentOrPastBTI_student_requestedBaseTimeIntervalInstance_studentSubspaces_studentModules_module_evaluationPoints {
  __typename: "EvaluationPoint";
  id: string;
  topic: string | null;
  description: string | null;
  order: number | null;
  duration: GetCurrentOrPastBTI_student_requestedBaseTimeIntervalInstance_studentSubspaces_studentModules_module_evaluationPoints_duration | null;
  evaluationPointSkills: GetCurrentOrPastBTI_student_requestedBaseTimeIntervalInstance_studentSubspaces_studentModules_module_evaluationPoints_evaluationPointSkills[] | null;
}

export interface GetCurrentOrPastBTI_student_requestedBaseTimeIntervalInstance_studentSubspaces_studentModules_module_assignments_duration {
  __typename: "MeetingDuration";
  id: string;
  name: string | null;
  academicHours: number;
}

export interface GetCurrentOrPastBTI_student_requestedBaseTimeIntervalInstance_studentSubspaces_studentModules_module_assignments_prerequisiteSkills_skill {
  __typename: "Skill";
  id: string;
  name: string;
  fullName: string;
  typeId: string;
}

export interface GetCurrentOrPastBTI_student_requestedBaseTimeIntervalInstance_studentSubspaces_studentModules_module_assignments_prerequisiteSkills {
  __typename: "ModuleSkill";
  skill: GetCurrentOrPastBTI_student_requestedBaseTimeIntervalInstance_studentSubspaces_studentModules_module_assignments_prerequisiteSkills_skill;
  level: number | null;
}

export interface GetCurrentOrPastBTI_student_requestedBaseTimeIntervalInstance_studentSubspaces_studentModules_module_assignments_outputSkills_skill {
  __typename: "Skill";
  id: string;
  name: string;
  fullName: string;
  typeId: string;
}

export interface GetCurrentOrPastBTI_student_requestedBaseTimeIntervalInstance_studentSubspaces_studentModules_module_assignments_outputSkills {
  __typename: "ModuleSkill";
  skill: GetCurrentOrPastBTI_student_requestedBaseTimeIntervalInstance_studentSubspaces_studentModules_module_assignments_outputSkills_skill;
  level: number | null;
}

export interface GetCurrentOrPastBTI_student_requestedBaseTimeIntervalInstance_studentSubspaces_studentModules_module_assignments {
  __typename: "Assignment";
  id: string;
  topic: string | null;
  duration: GetCurrentOrPastBTI_student_requestedBaseTimeIntervalInstance_studentSubspaces_studentModules_module_assignments_duration | null;
  prerequisiteSkills: GetCurrentOrPastBTI_student_requestedBaseTimeIntervalInstance_studentSubspaces_studentModules_module_assignments_prerequisiteSkills[] | null;
  outputSkills: GetCurrentOrPastBTI_student_requestedBaseTimeIntervalInstance_studentSubspaces_studentModules_module_assignments_outputSkills[] | null;
  order: number | null;
}

export interface GetCurrentOrPastBTI_student_requestedBaseTimeIntervalInstance_studentSubspaces_studentModules_module {
  __typename: "Module";
  id: string;
  name: string | null;
  description: string | null;
  moduleTeachers: GetCurrentOrPastBTI_student_requestedBaseTimeIntervalInstance_studentSubspaces_studentModules_module_moduleTeachers[] | null;
  prerequisiteSkills: GetCurrentOrPastBTI_student_requestedBaseTimeIntervalInstance_studentSubspaces_studentModules_module_prerequisiteSkills[] | null;
  outputSkills: GetCurrentOrPastBTI_student_requestedBaseTimeIntervalInstance_studentSubspaces_studentModules_module_outputSkills[] | null;
  meetings: GetCurrentOrPastBTI_student_requestedBaseTimeIntervalInstance_studentSubspaces_studentModules_module_meetings[] | null;
  evaluationPoints: GetCurrentOrPastBTI_student_requestedBaseTimeIntervalInstance_studentSubspaces_studentModules_module_evaluationPoints[] | null;
  assignments: GetCurrentOrPastBTI_student_requestedBaseTimeIntervalInstance_studentSubspaces_studentModules_module_assignments[] | null;
}

export interface GetCurrentOrPastBTI_student_requestedBaseTimeIntervalInstance_studentSubspaces_studentModules_subspace_type {
  __typename: "SubspaceType";
  name: string;
}

export interface GetCurrentOrPastBTI_student_requestedBaseTimeIntervalInstance_studentSubspaces_studentModules_subspace {
  __typename: "Subspace";
  type: GetCurrentOrPastBTI_student_requestedBaseTimeIntervalInstance_studentSubspaces_studentModules_subspace_type;
  id: string;
  name: string;
}

export interface GetCurrentOrPastBTI_student_requestedBaseTimeIntervalInstance_studentSubspaces_studentModules {
  __typename: "StudentModule";
  id: string;
  isStudentModuleAssessmentActive: boolean;
  module: GetCurrentOrPastBTI_student_requestedBaseTimeIntervalInstance_studentSubspaces_studentModules_module;
  subspace: GetCurrentOrPastBTI_student_requestedBaseTimeIntervalInstance_studentSubspaces_studentModules_subspace;
}

export interface GetCurrentOrPastBTI_student_requestedBaseTimeIntervalInstance_studentSubspaces {
  __typename: "StudentSubspace";
  subspace: GetCurrentOrPastBTI_student_requestedBaseTimeIntervalInstance_studentSubspaces_subspace;
  studentModules: GetCurrentOrPastBTI_student_requestedBaseTimeIntervalInstance_studentSubspaces_studentModules[];
}

export interface GetCurrentOrPastBTI_student_requestedBaseTimeIntervalInstance {
  __typename: "StudentBaseTimeIntervalInstance";
  baseTimeIntervalInstance: GetCurrentOrPastBTI_student_requestedBaseTimeIntervalInstance_baseTimeIntervalInstance;
  studentSubspaces: GetCurrentOrPastBTI_student_requestedBaseTimeIntervalInstance_studentSubspaces[];
}

export interface GetCurrentOrPastBTI_student {
  __typename: "Student";
  id: string;
  requestedBaseTimeIntervalInstance: GetCurrentOrPastBTI_student_requestedBaseTimeIntervalInstance | null;
}

export interface GetCurrentOrPastBTI {
  student: GetCurrentOrPastBTI_student | null;
}

export interface GetCurrentOrPastBTIVariables {
  studentId: string;
  baseTimeIntervalInstanceId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetFutureBTI
// ====================================================

export interface GetFutureBTI_baseTimeIntervalInstance_selectionPoint {
  __typename: "SelectionPoint";
  moduleSelectionStartDate: string;
  moduleSelectionEndDate: string;
}

export interface GetFutureBTI_baseTimeIntervalInstance_spaceBaseTimeInterval_subspaceTypeLayouts_subspaceType_subspaces_type {
  __typename: "SubspaceType";
  name: string;
}

export interface GetFutureBTI_baseTimeIntervalInstance_spaceBaseTimeInterval_subspaceTypeLayouts_subspaceType_subspaces_slotDiagram_forks_setting {
  __typename: "ForkSetting";
  distributionType: DistributionType;
  studentPickingType: StudentPickingType | null;
  studentPriorityCount: number | null;
  moduleSelectionCount: number;
}

export interface GetFutureBTI_baseTimeIntervalInstance_spaceBaseTimeInterval_subspaceTypeLayouts_subspaceType_subspaces_slotDiagram_forks_nextSlots_module_moduleTeachers_teacher_user {
  __typename: "User";
  firstName: string;
  lastName: string;
  patronymic: string | null;
}

export interface GetFutureBTI_baseTimeIntervalInstance_spaceBaseTimeInterval_subspaceTypeLayouts_subspaceType_subspaces_slotDiagram_forks_nextSlots_module_moduleTeachers_teacher {
  __typename: "Teacher";
  user: GetFutureBTI_baseTimeIntervalInstance_spaceBaseTimeInterval_subspaceTypeLayouts_subspaceType_subspaces_slotDiagram_forks_nextSlots_module_moduleTeachers_teacher_user;
}

export interface GetFutureBTI_baseTimeIntervalInstance_spaceBaseTimeInterval_subspaceTypeLayouts_subspaceType_subspaces_slotDiagram_forks_nextSlots_module_moduleTeachers_teacherRoles {
  __typename: "TeacherRole";
  name: string | null;
}

export interface GetFutureBTI_baseTimeIntervalInstance_spaceBaseTimeInterval_subspaceTypeLayouts_subspaceType_subspaces_slotDiagram_forks_nextSlots_module_moduleTeachers {
  __typename: "ModuleTeacher";
  teacher: GetFutureBTI_baseTimeIntervalInstance_spaceBaseTimeInterval_subspaceTypeLayouts_subspaceType_subspaces_slotDiagram_forks_nextSlots_module_moduleTeachers_teacher | null;
  teacherRoles: GetFutureBTI_baseTimeIntervalInstance_spaceBaseTimeInterval_subspaceTypeLayouts_subspaceType_subspaces_slotDiagram_forks_nextSlots_module_moduleTeachers_teacherRoles[] | null;
}

export interface GetFutureBTI_baseTimeIntervalInstance_spaceBaseTimeInterval_subspaceTypeLayouts_subspaceType_subspaces_slotDiagram_forks_nextSlots_module_prerequisiteSkills_skill {
  __typename: "Skill";
  id: string;
}

export interface GetFutureBTI_baseTimeIntervalInstance_spaceBaseTimeInterval_subspaceTypeLayouts_subspaceType_subspaces_slotDiagram_forks_nextSlots_module_prerequisiteSkills {
  __typename: "ModuleSkill";
  skill: GetFutureBTI_baseTimeIntervalInstance_spaceBaseTimeInterval_subspaceTypeLayouts_subspaceType_subspaces_slotDiagram_forks_nextSlots_module_prerequisiteSkills_skill;
  level: number | null;
}

export interface GetFutureBTI_baseTimeIntervalInstance_spaceBaseTimeInterval_subspaceTypeLayouts_subspaceType_subspaces_slotDiagram_forks_nextSlots_module_outputSkills_skill {
  __typename: "Skill";
  id: string;
}

export interface GetFutureBTI_baseTimeIntervalInstance_spaceBaseTimeInterval_subspaceTypeLayouts_subspaceType_subspaces_slotDiagram_forks_nextSlots_module_outputSkills {
  __typename: "ModuleSkill";
  skill: GetFutureBTI_baseTimeIntervalInstance_spaceBaseTimeInterval_subspaceTypeLayouts_subspaceType_subspaces_slotDiagram_forks_nextSlots_module_outputSkills_skill;
  level: number | null;
}

export interface GetFutureBTI_baseTimeIntervalInstance_spaceBaseTimeInterval_subspaceTypeLayouts_subspaceType_subspaces_slotDiagram_forks_nextSlots_module_meetings_format {
  __typename: "MeetingFormat";
  name: string;
}

export interface GetFutureBTI_baseTimeIntervalInstance_spaceBaseTimeInterval_subspaceTypeLayouts_subspaceType_subspaces_slotDiagram_forks_nextSlots_module_meetings_duration {
  __typename: "MeetingDuration";
  id: string;
  name: string | null;
  academicHours: number;
}

export interface GetFutureBTI_baseTimeIntervalInstance_spaceBaseTimeInterval_subspaceTypeLayouts_subspaceType_subspaces_slotDiagram_forks_nextSlots_module_meetings_prerequisiteSkills_skill {
  __typename: "Skill";
  id: string;
  name: string;
  fullName: string;
  typeId: string;
}

export interface GetFutureBTI_baseTimeIntervalInstance_spaceBaseTimeInterval_subspaceTypeLayouts_subspaceType_subspaces_slotDiagram_forks_nextSlots_module_meetings_prerequisiteSkills {
  __typename: "ModuleSkill";
  skill: GetFutureBTI_baseTimeIntervalInstance_spaceBaseTimeInterval_subspaceTypeLayouts_subspaceType_subspaces_slotDiagram_forks_nextSlots_module_meetings_prerequisiteSkills_skill;
  level: number | null;
}

export interface GetFutureBTI_baseTimeIntervalInstance_spaceBaseTimeInterval_subspaceTypeLayouts_subspaceType_subspaces_slotDiagram_forks_nextSlots_module_meetings_outputSkills_skill {
  __typename: "Skill";
  id: string;
  name: string;
  fullName: string;
  typeId: string;
}

export interface GetFutureBTI_baseTimeIntervalInstance_spaceBaseTimeInterval_subspaceTypeLayouts_subspaceType_subspaces_slotDiagram_forks_nextSlots_module_meetings_outputSkills {
  __typename: "ModuleSkill";
  skill: GetFutureBTI_baseTimeIntervalInstance_spaceBaseTimeInterval_subspaceTypeLayouts_subspaceType_subspaces_slotDiagram_forks_nextSlots_module_meetings_outputSkills_skill;
  level: number | null;
}

export interface GetFutureBTI_baseTimeIntervalInstance_spaceBaseTimeInterval_subspaceTypeLayouts_subspaceType_subspaces_slotDiagram_forks_nextSlots_module_meetings {
  __typename: "Meeting";
  id: string;
  topic: string | null;
  format: GetFutureBTI_baseTimeIntervalInstance_spaceBaseTimeInterval_subspaceTypeLayouts_subspaceType_subspaces_slotDiagram_forks_nextSlots_module_meetings_format | null;
  duration: GetFutureBTI_baseTimeIntervalInstance_spaceBaseTimeInterval_subspaceTypeLayouts_subspaceType_subspaces_slotDiagram_forks_nextSlots_module_meetings_duration | null;
  prerequisiteSkills: GetFutureBTI_baseTimeIntervalInstance_spaceBaseTimeInterval_subspaceTypeLayouts_subspaceType_subspaces_slotDiagram_forks_nextSlots_module_meetings_prerequisiteSkills[] | null;
  outputSkills: GetFutureBTI_baseTimeIntervalInstance_spaceBaseTimeInterval_subspaceTypeLayouts_subspaceType_subspaces_slotDiagram_forks_nextSlots_module_meetings_outputSkills[] | null;
  order: number | null;
}

export interface GetFutureBTI_baseTimeIntervalInstance_spaceBaseTimeInterval_subspaceTypeLayouts_subspaceType_subspaces_slotDiagram_forks_nextSlots_module_evaluationPoints_duration {
  __typename: "MeetingDuration";
  id: string;
  name: string | null;
  academicHours: number;
}

export interface GetFutureBTI_baseTimeIntervalInstance_spaceBaseTimeInterval_subspaceTypeLayouts_subspaceType_subspaces_slotDiagram_forks_nextSlots_module_evaluationPoints_evaluationPointSkills_skill {
  __typename: "Skill";
  id: string;
  name: string;
  fullName: string;
  typeId: string;
}

export interface GetFutureBTI_baseTimeIntervalInstance_spaceBaseTimeInterval_subspaceTypeLayouts_subspaceType_subspaces_slotDiagram_forks_nextSlots_module_evaluationPoints_evaluationPointSkills {
  __typename: "ModuleSkill";
  skill: GetFutureBTI_baseTimeIntervalInstance_spaceBaseTimeInterval_subspaceTypeLayouts_subspaceType_subspaces_slotDiagram_forks_nextSlots_module_evaluationPoints_evaluationPointSkills_skill;
  level: number | null;
}

export interface GetFutureBTI_baseTimeIntervalInstance_spaceBaseTimeInterval_subspaceTypeLayouts_subspaceType_subspaces_slotDiagram_forks_nextSlots_module_evaluationPoints {
  __typename: "EvaluationPoint";
  id: string;
  topic: string | null;
  description: string | null;
  order: number | null;
  duration: GetFutureBTI_baseTimeIntervalInstance_spaceBaseTimeInterval_subspaceTypeLayouts_subspaceType_subspaces_slotDiagram_forks_nextSlots_module_evaluationPoints_duration | null;
  evaluationPointSkills: GetFutureBTI_baseTimeIntervalInstance_spaceBaseTimeInterval_subspaceTypeLayouts_subspaceType_subspaces_slotDiagram_forks_nextSlots_module_evaluationPoints_evaluationPointSkills[] | null;
}

export interface GetFutureBTI_baseTimeIntervalInstance_spaceBaseTimeInterval_subspaceTypeLayouts_subspaceType_subspaces_slotDiagram_forks_nextSlots_module_assignments_duration {
  __typename: "MeetingDuration";
  id: string;
  name: string | null;
  academicHours: number;
}

export interface GetFutureBTI_baseTimeIntervalInstance_spaceBaseTimeInterval_subspaceTypeLayouts_subspaceType_subspaces_slotDiagram_forks_nextSlots_module_assignments_prerequisiteSkills_skill {
  __typename: "Skill";
  id: string;
  name: string;
  fullName: string;
  typeId: string;
}

export interface GetFutureBTI_baseTimeIntervalInstance_spaceBaseTimeInterval_subspaceTypeLayouts_subspaceType_subspaces_slotDiagram_forks_nextSlots_module_assignments_prerequisiteSkills {
  __typename: "ModuleSkill";
  skill: GetFutureBTI_baseTimeIntervalInstance_spaceBaseTimeInterval_subspaceTypeLayouts_subspaceType_subspaces_slotDiagram_forks_nextSlots_module_assignments_prerequisiteSkills_skill;
  level: number | null;
}

export interface GetFutureBTI_baseTimeIntervalInstance_spaceBaseTimeInterval_subspaceTypeLayouts_subspaceType_subspaces_slotDiagram_forks_nextSlots_module_assignments_outputSkills_skill {
  __typename: "Skill";
  id: string;
  name: string;
  fullName: string;
  typeId: string;
}

export interface GetFutureBTI_baseTimeIntervalInstance_spaceBaseTimeInterval_subspaceTypeLayouts_subspaceType_subspaces_slotDiagram_forks_nextSlots_module_assignments_outputSkills {
  __typename: "ModuleSkill";
  skill: GetFutureBTI_baseTimeIntervalInstance_spaceBaseTimeInterval_subspaceTypeLayouts_subspaceType_subspaces_slotDiagram_forks_nextSlots_module_assignments_outputSkills_skill;
  level: number | null;
}

export interface GetFutureBTI_baseTimeIntervalInstance_spaceBaseTimeInterval_subspaceTypeLayouts_subspaceType_subspaces_slotDiagram_forks_nextSlots_module_assignments {
  __typename: "Assignment";
  id: string;
  topic: string | null;
  duration: GetFutureBTI_baseTimeIntervalInstance_spaceBaseTimeInterval_subspaceTypeLayouts_subspaceType_subspaces_slotDiagram_forks_nextSlots_module_assignments_duration | null;
  prerequisiteSkills: GetFutureBTI_baseTimeIntervalInstance_spaceBaseTimeInterval_subspaceTypeLayouts_subspaceType_subspaces_slotDiagram_forks_nextSlots_module_assignments_prerequisiteSkills[] | null;
  outputSkills: GetFutureBTI_baseTimeIntervalInstance_spaceBaseTimeInterval_subspaceTypeLayouts_subspaceType_subspaces_slotDiagram_forks_nextSlots_module_assignments_outputSkills[] | null;
  order: number | null;
}

export interface GetFutureBTI_baseTimeIntervalInstance_spaceBaseTimeInterval_subspaceTypeLayouts_subspaceType_subspaces_slotDiagram_forks_nextSlots_module {
  __typename: "Module";
  id: string;
  name: string | null;
  description: string | null;
  moduleTeachers: GetFutureBTI_baseTimeIntervalInstance_spaceBaseTimeInterval_subspaceTypeLayouts_subspaceType_subspaces_slotDiagram_forks_nextSlots_module_moduleTeachers[] | null;
  prerequisiteSkills: GetFutureBTI_baseTimeIntervalInstance_spaceBaseTimeInterval_subspaceTypeLayouts_subspaceType_subspaces_slotDiagram_forks_nextSlots_module_prerequisiteSkills[] | null;
  outputSkills: GetFutureBTI_baseTimeIntervalInstance_spaceBaseTimeInterval_subspaceTypeLayouts_subspaceType_subspaces_slotDiagram_forks_nextSlots_module_outputSkills[] | null;
  meetings: GetFutureBTI_baseTimeIntervalInstance_spaceBaseTimeInterval_subspaceTypeLayouts_subspaceType_subspaces_slotDiagram_forks_nextSlots_module_meetings[] | null;
  evaluationPoints: GetFutureBTI_baseTimeIntervalInstance_spaceBaseTimeInterval_subspaceTypeLayouts_subspaceType_subspaces_slotDiagram_forks_nextSlots_module_evaluationPoints[] | null;
  assignments: GetFutureBTI_baseTimeIntervalInstance_spaceBaseTimeInterval_subspaceTypeLayouts_subspaceType_subspaces_slotDiagram_forks_nextSlots_module_assignments[] | null;
}

export interface GetFutureBTI_baseTimeIntervalInstance_spaceBaseTimeInterval_subspaceTypeLayouts_subspaceType_subspaces_slotDiagram_forks_nextSlots {
  __typename: "ModuleSlot";
  id: string;
  spaceBaseTimeIntervalId: string;
  module: GetFutureBTI_baseTimeIntervalInstance_spaceBaseTimeInterval_subspaceTypeLayouts_subspaceType_subspaces_slotDiagram_forks_nextSlots_module | null;
}

export interface GetFutureBTI_baseTimeIntervalInstance_spaceBaseTimeInterval_subspaceTypeLayouts_subspaceType_subspaces_slotDiagram_forks {
  __typename: "Fork";
  id: string;
  spaceBaseTimeIntervalId: string;
  setting: GetFutureBTI_baseTimeIntervalInstance_spaceBaseTimeInterval_subspaceTypeLayouts_subspaceType_subspaces_slotDiagram_forks_setting | null;
  nextSlots: GetFutureBTI_baseTimeIntervalInstance_spaceBaseTimeInterval_subspaceTypeLayouts_subspaceType_subspaces_slotDiagram_forks_nextSlots[];
}

export interface GetFutureBTI_baseTimeIntervalInstance_spaceBaseTimeInterval_subspaceTypeLayouts_subspaceType_subspaces_slotDiagram_slots_module_moduleTeachers_teacher_user {
  __typename: "User";
  firstName: string;
  lastName: string;
  patronymic: string | null;
}

export interface GetFutureBTI_baseTimeIntervalInstance_spaceBaseTimeInterval_subspaceTypeLayouts_subspaceType_subspaces_slotDiagram_slots_module_moduleTeachers_teacher {
  __typename: "Teacher";
  user: GetFutureBTI_baseTimeIntervalInstance_spaceBaseTimeInterval_subspaceTypeLayouts_subspaceType_subspaces_slotDiagram_slots_module_moduleTeachers_teacher_user;
}

export interface GetFutureBTI_baseTimeIntervalInstance_spaceBaseTimeInterval_subspaceTypeLayouts_subspaceType_subspaces_slotDiagram_slots_module_moduleTeachers_teacherRoles {
  __typename: "TeacherRole";
  name: string | null;
}

export interface GetFutureBTI_baseTimeIntervalInstance_spaceBaseTimeInterval_subspaceTypeLayouts_subspaceType_subspaces_slotDiagram_slots_module_moduleTeachers {
  __typename: "ModuleTeacher";
  teacher: GetFutureBTI_baseTimeIntervalInstance_spaceBaseTimeInterval_subspaceTypeLayouts_subspaceType_subspaces_slotDiagram_slots_module_moduleTeachers_teacher | null;
  teacherRoles: GetFutureBTI_baseTimeIntervalInstance_spaceBaseTimeInterval_subspaceTypeLayouts_subspaceType_subspaces_slotDiagram_slots_module_moduleTeachers_teacherRoles[] | null;
}

export interface GetFutureBTI_baseTimeIntervalInstance_spaceBaseTimeInterval_subspaceTypeLayouts_subspaceType_subspaces_slotDiagram_slots_module_prerequisiteSkills_skill {
  __typename: "Skill";
  id: string;
}

export interface GetFutureBTI_baseTimeIntervalInstance_spaceBaseTimeInterval_subspaceTypeLayouts_subspaceType_subspaces_slotDiagram_slots_module_prerequisiteSkills {
  __typename: "ModuleSkill";
  skill: GetFutureBTI_baseTimeIntervalInstance_spaceBaseTimeInterval_subspaceTypeLayouts_subspaceType_subspaces_slotDiagram_slots_module_prerequisiteSkills_skill;
  level: number | null;
}

export interface GetFutureBTI_baseTimeIntervalInstance_spaceBaseTimeInterval_subspaceTypeLayouts_subspaceType_subspaces_slotDiagram_slots_module_outputSkills_skill {
  __typename: "Skill";
  id: string;
}

export interface GetFutureBTI_baseTimeIntervalInstance_spaceBaseTimeInterval_subspaceTypeLayouts_subspaceType_subspaces_slotDiagram_slots_module_outputSkills {
  __typename: "ModuleSkill";
  skill: GetFutureBTI_baseTimeIntervalInstance_spaceBaseTimeInterval_subspaceTypeLayouts_subspaceType_subspaces_slotDiagram_slots_module_outputSkills_skill;
  level: number | null;
}

export interface GetFutureBTI_baseTimeIntervalInstance_spaceBaseTimeInterval_subspaceTypeLayouts_subspaceType_subspaces_slotDiagram_slots_module_meetings_format {
  __typename: "MeetingFormat";
  name: string;
}

export interface GetFutureBTI_baseTimeIntervalInstance_spaceBaseTimeInterval_subspaceTypeLayouts_subspaceType_subspaces_slotDiagram_slots_module_meetings_duration {
  __typename: "MeetingDuration";
  id: string;
  name: string | null;
  academicHours: number;
}

export interface GetFutureBTI_baseTimeIntervalInstance_spaceBaseTimeInterval_subspaceTypeLayouts_subspaceType_subspaces_slotDiagram_slots_module_meetings_prerequisiteSkills_skill {
  __typename: "Skill";
  id: string;
  name: string;
  fullName: string;
  typeId: string;
}

export interface GetFutureBTI_baseTimeIntervalInstance_spaceBaseTimeInterval_subspaceTypeLayouts_subspaceType_subspaces_slotDiagram_slots_module_meetings_prerequisiteSkills {
  __typename: "ModuleSkill";
  skill: GetFutureBTI_baseTimeIntervalInstance_spaceBaseTimeInterval_subspaceTypeLayouts_subspaceType_subspaces_slotDiagram_slots_module_meetings_prerequisiteSkills_skill;
  level: number | null;
}

export interface GetFutureBTI_baseTimeIntervalInstance_spaceBaseTimeInterval_subspaceTypeLayouts_subspaceType_subspaces_slotDiagram_slots_module_meetings_outputSkills_skill {
  __typename: "Skill";
  id: string;
  name: string;
  fullName: string;
  typeId: string;
}

export interface GetFutureBTI_baseTimeIntervalInstance_spaceBaseTimeInterval_subspaceTypeLayouts_subspaceType_subspaces_slotDiagram_slots_module_meetings_outputSkills {
  __typename: "ModuleSkill";
  skill: GetFutureBTI_baseTimeIntervalInstance_spaceBaseTimeInterval_subspaceTypeLayouts_subspaceType_subspaces_slotDiagram_slots_module_meetings_outputSkills_skill;
  level: number | null;
}

export interface GetFutureBTI_baseTimeIntervalInstance_spaceBaseTimeInterval_subspaceTypeLayouts_subspaceType_subspaces_slotDiagram_slots_module_meetings {
  __typename: "Meeting";
  id: string;
  topic: string | null;
  format: GetFutureBTI_baseTimeIntervalInstance_spaceBaseTimeInterval_subspaceTypeLayouts_subspaceType_subspaces_slotDiagram_slots_module_meetings_format | null;
  duration: GetFutureBTI_baseTimeIntervalInstance_spaceBaseTimeInterval_subspaceTypeLayouts_subspaceType_subspaces_slotDiagram_slots_module_meetings_duration | null;
  prerequisiteSkills: GetFutureBTI_baseTimeIntervalInstance_spaceBaseTimeInterval_subspaceTypeLayouts_subspaceType_subspaces_slotDiagram_slots_module_meetings_prerequisiteSkills[] | null;
  outputSkills: GetFutureBTI_baseTimeIntervalInstance_spaceBaseTimeInterval_subspaceTypeLayouts_subspaceType_subspaces_slotDiagram_slots_module_meetings_outputSkills[] | null;
  order: number | null;
}

export interface GetFutureBTI_baseTimeIntervalInstance_spaceBaseTimeInterval_subspaceTypeLayouts_subspaceType_subspaces_slotDiagram_slots_module_evaluationPoints_duration {
  __typename: "MeetingDuration";
  id: string;
  name: string | null;
  academicHours: number;
}

export interface GetFutureBTI_baseTimeIntervalInstance_spaceBaseTimeInterval_subspaceTypeLayouts_subspaceType_subspaces_slotDiagram_slots_module_evaluationPoints_evaluationPointSkills_skill {
  __typename: "Skill";
  id: string;
  name: string;
  fullName: string;
  typeId: string;
}

export interface GetFutureBTI_baseTimeIntervalInstance_spaceBaseTimeInterval_subspaceTypeLayouts_subspaceType_subspaces_slotDiagram_slots_module_evaluationPoints_evaluationPointSkills {
  __typename: "ModuleSkill";
  skill: GetFutureBTI_baseTimeIntervalInstance_spaceBaseTimeInterval_subspaceTypeLayouts_subspaceType_subspaces_slotDiagram_slots_module_evaluationPoints_evaluationPointSkills_skill;
  level: number | null;
}

export interface GetFutureBTI_baseTimeIntervalInstance_spaceBaseTimeInterval_subspaceTypeLayouts_subspaceType_subspaces_slotDiagram_slots_module_evaluationPoints {
  __typename: "EvaluationPoint";
  id: string;
  topic: string | null;
  description: string | null;
  order: number | null;
  duration: GetFutureBTI_baseTimeIntervalInstance_spaceBaseTimeInterval_subspaceTypeLayouts_subspaceType_subspaces_slotDiagram_slots_module_evaluationPoints_duration | null;
  evaluationPointSkills: GetFutureBTI_baseTimeIntervalInstance_spaceBaseTimeInterval_subspaceTypeLayouts_subspaceType_subspaces_slotDiagram_slots_module_evaluationPoints_evaluationPointSkills[] | null;
}

export interface GetFutureBTI_baseTimeIntervalInstance_spaceBaseTimeInterval_subspaceTypeLayouts_subspaceType_subspaces_slotDiagram_slots_module_assignments_duration {
  __typename: "MeetingDuration";
  id: string;
  name: string | null;
  academicHours: number;
}

export interface GetFutureBTI_baseTimeIntervalInstance_spaceBaseTimeInterval_subspaceTypeLayouts_subspaceType_subspaces_slotDiagram_slots_module_assignments_prerequisiteSkills_skill {
  __typename: "Skill";
  id: string;
  name: string;
  fullName: string;
  typeId: string;
}

export interface GetFutureBTI_baseTimeIntervalInstance_spaceBaseTimeInterval_subspaceTypeLayouts_subspaceType_subspaces_slotDiagram_slots_module_assignments_prerequisiteSkills {
  __typename: "ModuleSkill";
  skill: GetFutureBTI_baseTimeIntervalInstance_spaceBaseTimeInterval_subspaceTypeLayouts_subspaceType_subspaces_slotDiagram_slots_module_assignments_prerequisiteSkills_skill;
  level: number | null;
}

export interface GetFutureBTI_baseTimeIntervalInstance_spaceBaseTimeInterval_subspaceTypeLayouts_subspaceType_subspaces_slotDiagram_slots_module_assignments_outputSkills_skill {
  __typename: "Skill";
  id: string;
  name: string;
  fullName: string;
  typeId: string;
}

export interface GetFutureBTI_baseTimeIntervalInstance_spaceBaseTimeInterval_subspaceTypeLayouts_subspaceType_subspaces_slotDiagram_slots_module_assignments_outputSkills {
  __typename: "ModuleSkill";
  skill: GetFutureBTI_baseTimeIntervalInstance_spaceBaseTimeInterval_subspaceTypeLayouts_subspaceType_subspaces_slotDiagram_slots_module_assignments_outputSkills_skill;
  level: number | null;
}

export interface GetFutureBTI_baseTimeIntervalInstance_spaceBaseTimeInterval_subspaceTypeLayouts_subspaceType_subspaces_slotDiagram_slots_module_assignments {
  __typename: "Assignment";
  id: string;
  topic: string | null;
  duration: GetFutureBTI_baseTimeIntervalInstance_spaceBaseTimeInterval_subspaceTypeLayouts_subspaceType_subspaces_slotDiagram_slots_module_assignments_duration | null;
  prerequisiteSkills: GetFutureBTI_baseTimeIntervalInstance_spaceBaseTimeInterval_subspaceTypeLayouts_subspaceType_subspaces_slotDiagram_slots_module_assignments_prerequisiteSkills[] | null;
  outputSkills: GetFutureBTI_baseTimeIntervalInstance_spaceBaseTimeInterval_subspaceTypeLayouts_subspaceType_subspaces_slotDiagram_slots_module_assignments_outputSkills[] | null;
  order: number | null;
}

export interface GetFutureBTI_baseTimeIntervalInstance_spaceBaseTimeInterval_subspaceTypeLayouts_subspaceType_subspaces_slotDiagram_slots_module {
  __typename: "Module";
  id: string;
  name: string | null;
  description: string | null;
  moduleTeachers: GetFutureBTI_baseTimeIntervalInstance_spaceBaseTimeInterval_subspaceTypeLayouts_subspaceType_subspaces_slotDiagram_slots_module_moduleTeachers[] | null;
  prerequisiteSkills: GetFutureBTI_baseTimeIntervalInstance_spaceBaseTimeInterval_subspaceTypeLayouts_subspaceType_subspaces_slotDiagram_slots_module_prerequisiteSkills[] | null;
  outputSkills: GetFutureBTI_baseTimeIntervalInstance_spaceBaseTimeInterval_subspaceTypeLayouts_subspaceType_subspaces_slotDiagram_slots_module_outputSkills[] | null;
  meetings: GetFutureBTI_baseTimeIntervalInstance_spaceBaseTimeInterval_subspaceTypeLayouts_subspaceType_subspaces_slotDiagram_slots_module_meetings[] | null;
  evaluationPoints: GetFutureBTI_baseTimeIntervalInstance_spaceBaseTimeInterval_subspaceTypeLayouts_subspaceType_subspaces_slotDiagram_slots_module_evaluationPoints[] | null;
  assignments: GetFutureBTI_baseTimeIntervalInstance_spaceBaseTimeInterval_subspaceTypeLayouts_subspaceType_subspaces_slotDiagram_slots_module_assignments[] | null;
}

export interface GetFutureBTI_baseTimeIntervalInstance_spaceBaseTimeInterval_subspaceTypeLayouts_subspaceType_subspaces_slotDiagram_slots {
  __typename: "ModuleSlot";
  id: string;
  spaceBaseTimeIntervalId: string;
  module: GetFutureBTI_baseTimeIntervalInstance_spaceBaseTimeInterval_subspaceTypeLayouts_subspaceType_subspaces_slotDiagram_slots_module | null;
}

export interface GetFutureBTI_baseTimeIntervalInstance_spaceBaseTimeInterval_subspaceTypeLayouts_subspaceType_subspaces_slotDiagram {
  __typename: "SlotDiagram";
  forks: GetFutureBTI_baseTimeIntervalInstance_spaceBaseTimeInterval_subspaceTypeLayouts_subspaceType_subspaces_slotDiagram_forks[];
  slots: GetFutureBTI_baseTimeIntervalInstance_spaceBaseTimeInterval_subspaceTypeLayouts_subspaceType_subspaces_slotDiagram_slots[];
}

export interface GetFutureBTI_baseTimeIntervalInstance_spaceBaseTimeInterval_subspaceTypeLayouts_subspaceType_subspaces {
  __typename: "Subspace";
  id: string;
  name: string;
  type: GetFutureBTI_baseTimeIntervalInstance_spaceBaseTimeInterval_subspaceTypeLayouts_subspaceType_subspaces_type;
  slotDiagram: GetFutureBTI_baseTimeIntervalInstance_spaceBaseTimeInterval_subspaceTypeLayouts_subspaceType_subspaces_slotDiagram | null;
}

export interface GetFutureBTI_baseTimeIntervalInstance_spaceBaseTimeInterval_subspaceTypeLayouts_subspaceType {
  __typename: "SubspaceType";
  isSelective: boolean;
  subspaces: GetFutureBTI_baseTimeIntervalInstance_spaceBaseTimeInterval_subspaceTypeLayouts_subspaceType_subspaces[];
}

export interface GetFutureBTI_baseTimeIntervalInstance_spaceBaseTimeInterval_subspaceTypeLayouts {
  __typename: "SubspaceTypeLayout";
  subspaceType: GetFutureBTI_baseTimeIntervalInstance_spaceBaseTimeInterval_subspaceTypeLayouts_subspaceType;
}

export interface GetFutureBTI_baseTimeIntervalInstance_spaceBaseTimeInterval {
  __typename: "SpaceBaseTimeInterval";
  id: string;
  subspaceTypeLayouts: GetFutureBTI_baseTimeIntervalInstance_spaceBaseTimeInterval_subspaceTypeLayouts[];
}

export interface GetFutureBTI_baseTimeIntervalInstance {
  __typename: "BaseTimeIntervalInstance";
  id: string;
  selectionPoint: GetFutureBTI_baseTimeIntervalInstance_selectionPoint;
  spaceBaseTimeInterval: GetFutureBTI_baseTimeIntervalInstance_spaceBaseTimeInterval;
}

export interface GetFutureBTI {
  baseTimeIntervalInstance: GetFutureBTI_baseTimeIntervalInstance;
}

export interface GetFutureBTIVariables {
  baseTimeIntervalInstanceId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetStudentPriorities
// ====================================================

export interface GetStudentPriorities_studentPriorities {
  __typename: "StudentPriority";
  studentId: string;
  slotId: string;
  moduleId: string;
  forkId: string;
  selectionIterationId: string;
  priority: number;
}

export interface GetStudentPriorities {
  studentPriorities: GetStudentPriorities_studentPriorities[];
}

export interface GetStudentPrioritiesVariables {
  baseTimeIntervalInstanceId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateStudentPriority
// ====================================================

export interface UpdateStudentPriority_updateStudentPriority {
  __typename: "StudentPriority";
  studentId: string;
}

export interface UpdateStudentPriority {
  updateStudentPriority: UpdateStudentPriority_updateStudentPriority;
}

export interface UpdateStudentPriorityVariables {
  updateStudentPriorityInput: UpdateStudentPriorityInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteStudentPriority
// ====================================================

export interface DeleteStudentPriority {
  deleteStudentPriority: string;
}

export interface DeleteStudentPriorityVariables {
  deleteStudentPriorityInput: DeleteStudentPriorityInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetModuleAssessmentConfig
// ====================================================

export interface GetModuleAssessmentConfig_moduleAssessmentConfig_assessmentOptions {
  __typename: "AssessmentOption";
  id: string;
  title: string;
  description: string;
}

export interface GetModuleAssessmentConfig_moduleAssessmentConfig_moduleAssessmentQuestions {
  __typename: "AssessmentQuestion";
  id: string;
  title: string;
  answerType: AnswerType;
}

export interface GetModuleAssessmentConfig_moduleAssessmentConfig_teacherAssessmentQuestions {
  __typename: "AssessmentQuestion";
  id: string;
  title: string;
  answerType: AnswerType;
}

export interface GetModuleAssessmentConfig_moduleAssessmentConfig {
  __typename: "ModuleAssessmentConfig";
  assessmentOptions: GetModuleAssessmentConfig_moduleAssessmentConfig_assessmentOptions[];
  moduleAssessmentQuestions: GetModuleAssessmentConfig_moduleAssessmentConfig_moduleAssessmentQuestions[];
  teacherAssessmentQuestions: GetModuleAssessmentConfig_moduleAssessmentConfig_teacherAssessmentQuestions[];
}

export interface GetModuleAssessmentConfig {
  moduleAssessmentConfig: GetModuleAssessmentConfig_moduleAssessmentConfig | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: Skill
// ====================================================

export interface Skill_skill {
  __typename: "Skill";
  id: string;
  name: string;
  fullName: string;
  typeId: string;
}

export interface Skill {
  __typename: "ModuleSkill";
  skill: Skill_skill;
  level: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ModuleFragment
// ====================================================

export interface ModuleFragment_moduleTeachers_teacher_user {
  __typename: "User";
  firstName: string;
  lastName: string;
  patronymic: string | null;
}

export interface ModuleFragment_moduleTeachers_teacher {
  __typename: "Teacher";
  user: ModuleFragment_moduleTeachers_teacher_user;
}

export interface ModuleFragment_moduleTeachers_teacherRoles {
  __typename: "TeacherRole";
  name: string | null;
}

export interface ModuleFragment_moduleTeachers {
  __typename: "ModuleTeacher";
  teacher: ModuleFragment_moduleTeachers_teacher | null;
  teacherRoles: ModuleFragment_moduleTeachers_teacherRoles[] | null;
}

export interface ModuleFragment_prerequisiteSkills_skill {
  __typename: "Skill";
  id: string;
}

export interface ModuleFragment_prerequisiteSkills {
  __typename: "ModuleSkill";
  skill: ModuleFragment_prerequisiteSkills_skill;
  level: number | null;
}

export interface ModuleFragment_outputSkills_skill {
  __typename: "Skill";
  id: string;
}

export interface ModuleFragment_outputSkills {
  __typename: "ModuleSkill";
  skill: ModuleFragment_outputSkills_skill;
  level: number | null;
}

export interface ModuleFragment_meetings_format {
  __typename: "MeetingFormat";
  name: string;
}

export interface ModuleFragment_meetings_duration {
  __typename: "MeetingDuration";
  id: string;
  name: string | null;
  academicHours: number;
}

export interface ModuleFragment_meetings_prerequisiteSkills_skill {
  __typename: "Skill";
  id: string;
  name: string;
  fullName: string;
  typeId: string;
}

export interface ModuleFragment_meetings_prerequisiteSkills {
  __typename: "ModuleSkill";
  skill: ModuleFragment_meetings_prerequisiteSkills_skill;
  level: number | null;
}

export interface ModuleFragment_meetings_outputSkills_skill {
  __typename: "Skill";
  id: string;
  name: string;
  fullName: string;
  typeId: string;
}

export interface ModuleFragment_meetings_outputSkills {
  __typename: "ModuleSkill";
  skill: ModuleFragment_meetings_outputSkills_skill;
  level: number | null;
}

export interface ModuleFragment_meetings {
  __typename: "Meeting";
  id: string;
  topic: string | null;
  format: ModuleFragment_meetings_format | null;
  duration: ModuleFragment_meetings_duration | null;
  prerequisiteSkills: ModuleFragment_meetings_prerequisiteSkills[] | null;
  outputSkills: ModuleFragment_meetings_outputSkills[] | null;
  order: number | null;
}

export interface ModuleFragment_evaluationPoints_duration {
  __typename: "MeetingDuration";
  id: string;
  name: string | null;
  academicHours: number;
}

export interface ModuleFragment_evaluationPoints_evaluationPointSkills_skill {
  __typename: "Skill";
  id: string;
  name: string;
  fullName: string;
  typeId: string;
}

export interface ModuleFragment_evaluationPoints_evaluationPointSkills {
  __typename: "ModuleSkill";
  skill: ModuleFragment_evaluationPoints_evaluationPointSkills_skill;
  level: number | null;
}

export interface ModuleFragment_evaluationPoints {
  __typename: "EvaluationPoint";
  id: string;
  topic: string | null;
  description: string | null;
  order: number | null;
  duration: ModuleFragment_evaluationPoints_duration | null;
  evaluationPointSkills: ModuleFragment_evaluationPoints_evaluationPointSkills[] | null;
}

export interface ModuleFragment_assignments_duration {
  __typename: "MeetingDuration";
  id: string;
  name: string | null;
  academicHours: number;
}

export interface ModuleFragment_assignments_prerequisiteSkills_skill {
  __typename: "Skill";
  id: string;
  name: string;
  fullName: string;
  typeId: string;
}

export interface ModuleFragment_assignments_prerequisiteSkills {
  __typename: "ModuleSkill";
  skill: ModuleFragment_assignments_prerequisiteSkills_skill;
  level: number | null;
}

export interface ModuleFragment_assignments_outputSkills_skill {
  __typename: "Skill";
  id: string;
  name: string;
  fullName: string;
  typeId: string;
}

export interface ModuleFragment_assignments_outputSkills {
  __typename: "ModuleSkill";
  skill: ModuleFragment_assignments_outputSkills_skill;
  level: number | null;
}

export interface ModuleFragment_assignments {
  __typename: "Assignment";
  id: string;
  topic: string | null;
  duration: ModuleFragment_assignments_duration | null;
  prerequisiteSkills: ModuleFragment_assignments_prerequisiteSkills[] | null;
  outputSkills: ModuleFragment_assignments_outputSkills[] | null;
  order: number | null;
}

export interface ModuleFragment {
  __typename: "Module";
  id: string;
  name: string | null;
  description: string | null;
  moduleTeachers: ModuleFragment_moduleTeachers[] | null;
  prerequisiteSkills: ModuleFragment_prerequisiteSkills[] | null;
  outputSkills: ModuleFragment_outputSkills[] | null;
  meetings: ModuleFragment_meetings[] | null;
  evaluationPoints: ModuleFragment_evaluationPoints[] | null;
  assignments: ModuleFragment_assignments[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum AnswerType {
  option = "option",
  textarea = "textarea",
}

export enum DistributionType {
  automatically = "automatically",
  manually = "manually",
  studentChoice = "studentChoice",
}

export enum SelectionStatus {
  moduleDistributionEnabled = "moduleDistributionEnabled",
  moduleDistributionInProcess = "moduleDistributionInProcess",
  moduleDistributionPending = "moduleDistributionPending",
  moduleSelectionEnabled = "moduleSelectionEnabled",
  moduleSelectionInProcess = "moduleSelectionInProcess",
  selectionEnabled = "selectionEnabled",
  selectionFinished = "selectionFinished",
  selectionPending = "selectionPending",
  subspaceDistributionEnabled = "subspaceDistributionEnabled",
  subspaceDistributionInProcess = "subspaceDistributionInProcess",
  subspaceDistributionPending = "subspaceDistributionPending",
  subspaceSelectionEnabled = "subspaceSelectionEnabled",
  subspaceSelectionInProcess = "subspaceSelectionInProcess",
}

export enum StudentAssessmentStatus {
  active = "active",
  finished = "finished",
}

export enum StudentPickingType {
  byRating = "byRating",
  bySkill = "bySkill",
  randomly = "randomly",
}

export interface AssessmentAnswerInput {
  questionId: string;
  optionId?: string | null;
  value?: string | null;
}

export interface DeleteStudentPriorityInput {
  baseTimeIntervalInstanceId: string;
  forkId: string;
  moduleId: string;
}

export interface StudentModuleMeetingInstancesInput {
  moduleId: string;
  baseTimeIntervalInstanceId: string;
}

export interface StudentTeacherAssessmentInput {
  id: string;
  teacherId: string;
  answers?: AssessmentAnswerInput[] | null;
}

export interface UpdateStudentModuleAssessmentInput {
  studentModuleId: string;
  id: string;
  answers?: AssessmentAnswerInput[] | null;
  teacherAssessments?: StudentTeacherAssessmentInput[] | null;
}

export interface UpdateStudentPriorityInput {
  baseTimeIntervalInstanceId: string;
  forkId: string;
  moduleId: string;
  priority: number;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
