import React from 'react';
import { gql, useMutation, useQuery } from '@apollo/client';
import { Alert } from '@common/Alert';
import { Loader } from '@common/Loader';
import { SubspacesConnectionsDiagram } from './SubspacesConnectionsDiagram';
import { Space, SubspaceLinkAction, SubspaceType } from './interfaces';

const GET_SUBSPACES_TYPES = gql`
    query SubspaceTypes ($spaceId: String!) {
        subspaceTypes(subspaceTypesInput: { spaceId:$spaceId }) {
            id
            name
            subspaceTypeLayouts {
                spaceBaseTimeInterval {
                    id
                    order
                }
            }
            subspaces {
                id
                name
                orderInSubspaceType
                sourceSubspaces {
                    id
                    name
                }
                targetSubspaces {
                    id
                    name
                }
            }
        }
    }
`;

const GET_SPACE = gql`
    query Space ($spaceId: String!) {
        space (id: $spaceId) {
            name
            spaceBaseTimeIntervals {
                id
                order
            }
            baseTimeIntervalType {
                name
            }
        }
    }
`;

const UPDATE_SUBSPACES_LINKS = gql`
    mutation UpdateSubspacesLinks($updateSubspacesLinksInput: UpdateSubspacesLinksInput!) {
    updateSubspacesLinks(updateSubspacesLinksInput: $updateSubspacesLinksInput) {
            id
            name
        }
    }
`;

interface SpaceData {
    space: Space | undefined;
}

interface SpaceVars {
    spaceId: string;
}

interface SubspaceTypesData {
    subspaceTypes: SubspaceType[] | undefined;
}

interface SubspaceTypesVars {
    spaceId: string;
}

interface Props {
    spaceId?: string
}

export function ApolloConnectionsDiagram({ spaceId = '' }: Props): JSX.Element {
    const {
        data: spaceData,
        error: errorSpaceData,
        loading: loadingSpace,
    } = useQuery<SpaceData, SpaceVars>(
        GET_SPACE,
        { variables: { spaceId }, fetchPolicy: 'network-only' },
    );

    const {
        data: subspaceTypesData,
        error: errorSubspaceTypesData,
        loading,
    } = useQuery<SubspaceTypesData, SubspaceTypesVars>(
        GET_SUBSPACES_TYPES,
        { variables: { spaceId }, fetchPolicy: 'network-only' },
    );

    const [
        updateSubspacesLinks,
        { error: errorUpdateSubspace },
    ] = useMutation(UPDATE_SUBSPACES_LINKS, {
        refetchQueries: [{
            query: GET_SUBSPACES_TYPES,
            variables: { spaceId },
        }],
    });

    const space: Space | undefined = spaceData?.space;
    const subspaceTypes: SubspaceType[] | undefined = subspaceTypesData?.subspaceTypes;
    const error = errorSpaceData || errorSubspaceTypesData || errorUpdateSubspace;

    return (
        <>
            {error && <Alert message={error.message} />}
            {(!loading && !loadingSpace && space && subspaceTypes)
                ? (
                    <SubspacesConnectionsDiagram
                        space={space}
                        subspaceTypes={subspaceTypes}
                        updateSubspacesLinks={(actions: SubspaceLinkAction[]) => {
                            const updateSubspacesLinksInput = { actions };

                            updateSubspacesLinks({
                                variables: {
                                    updateSubspacesLinksInput,
                                },
                            });
                        }}
                    />
                ) : (
                    <Loader />
                )}
        </>
    );
}
