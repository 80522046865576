import moment, { Moment } from 'moment';

import { TimeReservingEvent } from '../../../Interfaces';
import { DAYS_IN_WEEK } from '../../../Store';

interface WeekColumn {
    id: number;
    dateEvents: TimeReservingEvent[];
}

export function getWeekColumns(
    selectedDate: Moment,
    scheduleEvents: TimeReservingEvent[],
): WeekColumn[] {
    const weekColumns: WeekColumn[] = [];

    for (let weekColumn = 0; weekColumn < DAYS_IN_WEEK; weekColumn += 1) {
        const weekColumnDate = selectedDate.clone().weekday(weekColumn);

        weekColumns.push({
            id: weekColumn,
            dateEvents: scheduleEvents.filter(
                (scheduleEvent) => moment(scheduleEvent.startDate).isSame(weekColumnDate, 'day'),
            ),
        });
    }

    return weekColumns;
}
