import React from 'react';
import cn from 'classnames';
import sprite from './sprite.svg';
import classes from './SpriteSvg.module.scss';

type SvgName = (
    | 'pencil'
    | 'circlePlus'
    | 'circleCross'
    | 'circleCross_grey_20'
    | 'circleCross_13px'
    | 'printer'
    | 'checkmark'
    | 'magnifier'
    | 'arrow'
    | 'leftArrow'
    | 'arrowLeft'
    | 'arrowRight'
    | 'editPen_active'
    | 'editPen_disabled'
)
    ;

interface Props {
    className?: string;
    disabled?: boolean
    name: SvgName;

    onClick?(): void;

}

export function SpriteSvg({
    className, name, disabled, onClick,
}: Props): JSX.Element {
    return (
        <svg
            onClick={onClick}
            className={cn({
                [`${classes[name]}`]: !className,
                [`${className} ${classes[name]} `]: className && !disabled,
                [`${className} ${classes[`${name}_disabled`]}`]: className && disabled,
            })}
        >
            <use href={`${sprite}#${name}`} />
        </svg>
    );
}
