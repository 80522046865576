import React from 'react';
import { observer } from 'mobx-react-lite';
import classnames from 'classnames';
import classes from './TrajectoriesMarks.module.scss';
import { MarksSelect } from './MarksSelect';
import MarksStore from '../Store/MarksStore';

export const TrajectoriesMarks = observer((): JSX.Element => (
    <div className={classes.trajectoriesMarks}>
        <div
            className={classes.trajectoriesMarks__content}
            onClick={() => MarksStore.changeShowMarksList()}
        >
            <div className={classes.trajectoriesMarks__selectCircle} />
            <div className={classes.trajectoriesMarks__marksAndButtons}>
                <div className={classes.trajectoriesMarks__showAllButton}>
                    {!MarksStore.markFilters.publicTrajectories
                    && !MarksStore.markFilters.privateTrajectories
                    && (
                        <span className={classes.trajectoriesMarks__applyFiltersButtons}>
                            Фильтры
                        </span>
                    )
                    }
                    {MarksStore.markFilters.publicTrajectories
                    && (
                        <div className={classes.trajectoriesMarks__item}>
                            <div className={classes.trajectoriesMarks__colorSign} />
                            <span className={classes.trajectoriesMarks__statusText}>
                                Опубликованные
                            </span>
                        </div>
                    )
                    }
                    {MarksStore.markFilters.privateTrajectories
                    && (
                        <div className={classes.trajectoriesMarks__item}>
                            <div
                                className={classnames(classes.trajectoriesMarks__colorSign,
                                    classes.trajectoriesMarks__colorSign_generation)}
                            />
                            <span className={classes.trajectoriesMarks__statusText}>
                                Неопубликованные
                            </span>
                        </div>
                    )
                    }
                </div>
            </div>
        </div>
        {MarksStore.showMarksList && (<MarksSelect />)}
    </div>
));
