import React, { useCallback, useEffect, useState } from 'react';
import { ApolloError, useMutation, useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { nanoid } from 'nanoid';
import { cloneDeep, debounce, orderBy } from 'lodash';

import { Loader } from '@common/Loader';
import { Alert } from '@common/Alert';
import { ModuleAssessment } from './ModuleAssessmentTab';
// import UserView from '../../../../store/UserView';
import {
    FinishModuleAssessment,
    FinishModuleAssessmentVariables,
    GetStudentModuleAssessment,
    GetStudentModuleAssessmentVariables,
    GetStudentModuleAssessment_studentModuleMeetingInstances,
    StudentAssessmentStatus,
    UpdateModuleAssessment,
    UpdateModuleAssessmentVariables,
} from '../../graphql-query-types';
import { FINISH_MODULE_ASSESSMENT, GET_STUDENT_MODULE_ASSESSMENT, UPDATE_MODULE_ASSESSMENT } from './queries';
import { Answer, Assessment, AssessmentError, TeacherAssessment, TeacherAssessmentError, TeacherMeetingInstance } from './interface';
import store from '../../module-assessment-config-store';
import classes from './ModuleAssessment.module.scss';

interface Props {
    moduleId: string;
    studentModuleId?: string;
}

interface HistoryParams {
    id: string;
}
const DEBOUNCE_INTERVAL = 800;

export function ModuleAssessmentTabApollo({ moduleId, studentModuleId }: Props) {
    const { id: baseTimeIntervalInstanceId } = useParams<HistoryParams>();

    const { data, loading, error } = useQuery<
    GetStudentModuleAssessment,
    GetStudentModuleAssessmentVariables
    >(GET_STUDENT_MODULE_ASSESSMENT, {
        variables: {
            input: {
                moduleId,
                baseTimeIntervalInstanceId,
                // studentId: UserView.studentId!,
            },
            studentModuleId: studentModuleId!,
        },
        fetchPolicy: 'network-only',
    });

    const [finishStudentModuleAssessment] = useMutation<
    FinishModuleAssessment,
    FinishModuleAssessmentVariables
    >(
        FINISH_MODULE_ASSESSMENT,
        {
            variables: { studentModuleId: studentModuleId! },
            onError: (finishAssessmentError: ApolloError) => {
                if (finishAssessmentError?.graphQLErrors[0].extensions) {
                    const { extensions } = finishAssessmentError?.graphQLErrors[0];
                    setModuleAssessmentErrors(extensions?.moduleAssessmentErrors);
                    setTeacherAssessmentErrors(extensions?.teacherAssessmentErrors);
                }
            },
        },
    );

    const [updateAssessment, { error: updateError }] = useMutation<
    UpdateModuleAssessment,
    UpdateModuleAssessmentVariables
    >(UPDATE_MODULE_ASSESSMENT);

    const [assessment, setAssessment] = useState<Assessment>();
    const [assessmentStatus, setAssessmentStatus] = useState<StudentAssessmentStatus>(
        StudentAssessmentStatus.active,
    );
    const [moduleAssessmentErrors, setModuleAssessmentErrors] = useState<AssessmentError[]>();
    const [
        teacherAssessmentErrors,
        setTeacherAssessmentErrors,
    ] = useState<TeacherAssessmentError[]>();

    useEffect(() => {
        if (data) {
            if (data.studentModuleAssessment) {
                const { status, ...rest } = data.studentModuleAssessment;
                setAssessment(rest);
                setAssessmentStatus(status);
            } else {
                setAssessment({
                    id: nanoid(),
                    studentModuleId: studentModuleId!,
                    answers: null,
                });
            }
        }
    }, [data]);

    const debouncedAssessmentUpdate = useCallback(
        debounce(updateAssessment, DEBOUNCE_INTERVAL),
        [updateAssessment],
    );

    function onModuleAssessmentChange(answer: Answer) {
        const answers = assessment!.answers
            ? getUpdatedAnswers(answer, assessment!.answers)
            : [answer];
        if (moduleAssessmentErrors) {
            const errorIndex = moduleAssessmentErrors.findIndex(({ questionId }) => (
                questionId === answer.questionId
            ));
            if (errorIndex !== -1) {
                const moduleErrorsCopy = cloneDeep(moduleAssessmentErrors);
                moduleErrorsCopy.splice(errorIndex, 1);
                setModuleAssessmentErrors(moduleErrorsCopy);
            }
        }
        debouncedAssessmentUpdate({ variables: { input: { ...assessment!, answers } } });
        setAssessment({ ...assessment!, answers });
    }

    function onTeacherAssessmentChange(teacherId: string, answer: Answer) {
        if (!assessment?.teacherAssessments) {
            const teacherAssessments = [{ teacherId, id: nanoid(), answers: [answer] }];
            updateTeacherAssessments(teacherAssessments, teacherId, answer);
            return;
        }
        const teacherAssessmentsCopy = cloneDeep(assessment.teacherAssessments);
        const teacherPosition = teacherAssessmentsCopy.findIndex(
            item => item.teacherId === teacherId,
        );
        if (teacherPosition === -1) {
            teacherAssessmentsCopy.push({ teacherId, id: nanoid(), answers: [answer] });
            updateTeacherAssessments(teacherAssessmentsCopy, teacherId, answer);
            return;
        }
        const teacherAssessment = teacherAssessmentsCopy.splice(teacherPosition, 1)[0];
        const teacherAssessmentAnswers = teacherAssessment.answers
            ? getUpdatedAnswers(answer, teacherAssessment.answers)
            : [answer];
        teacherAssessment.answers = teacherAssessmentAnswers;
        teacherAssessmentsCopy.push(teacherAssessment);
        updateTeacherAssessments(teacherAssessmentsCopy, teacherId, answer);
    }

    function updateTeacherAssessments(
        teacherAssessments: TeacherAssessment[],
        teacherId: string,
        answer: Answer,
    ) {
        const updatedAssessment = { ...assessment!, teacherAssessments };

        setAssessment(updatedAssessment);
        debouncedAssessmentUpdate({ variables: { input: updatedAssessment } });

        if (!teacherAssessmentErrors) {
            return;
        }
        const teacherIndex = teacherAssessmentErrors.findIndex(teacherError => (
            teacherError.teacherId === teacherId
        ));
        if (teacherIndex === -1) {
            return;
        }
        const teacherAssessmentErrorsCopy = cloneDeep(teacherAssessmentErrors);
        if (!teacherAssessmentErrors[teacherIndex].errors) {
            teacherAssessmentErrorsCopy.splice(teacherIndex, 1);
            setTeacherAssessmentErrors(teacherAssessmentErrorsCopy);
            return;
        }
        const errorIndex = teacherAssessmentErrors[teacherIndex].errors!.findIndex(er => (
            er.questionId === answer.questionId
        ));
        if (errorIndex === -1) {
            return;
        }
        teacherAssessmentErrorsCopy[teacherIndex].errors!.splice(errorIndex, 1);
        if (teacherAssessmentErrorsCopy[teacherIndex].errors?.length === 0) {
            teacherAssessmentErrorsCopy.splice(teacherIndex, 1);
        }
        setTeacherAssessmentErrors(
            teacherAssessmentErrorsCopy.length === 0
                ? undefined
                : teacherAssessmentErrorsCopy,
        );
    }

    async function onStudentModuleAssessmentFinish() {
        await updateAssessment({ variables: { input: assessment! } });
        const { data: result } = await finishStudentModuleAssessment();
        if (result) {
            setAssessmentStatus(result.finishStudentModuleAssessment.status);
            setModuleAssessmentErrors(undefined);
            setTeacherAssessmentErrors(undefined);
        }
    }

    if (loading) {
        return <Loader />;
    }

    if (error) {
        return (
            <div>
                Произошла ошибка: {error.graphQLErrors[0]?.message || error.message}
            </div>
        );
    }

    if (data?.studentModuleAssessment?.status === StudentAssessmentStatus.finished) {
        return (
            <div className={classes['module-assessment-tab__title_finished']}>
                Оценка модуля завершена
            </div>
        );
    }

    const teacherMeetingInstances = data
        && formatTeacherMeetingsInstances(data.studentModuleMeetingInstances);

    return assessment
        ? (
            <>
                <ModuleAssessment
                    teacherMeetingInstances={teacherMeetingInstances}
                    moduleAssessment={assessment}
                    moduleAssessmentStatus={assessmentStatus}
                    onModuleAssessmentChange={onModuleAssessmentChange}
                    onTeacherAssessmentChange={onTeacherAssessmentChange}
                    finishStudentModuleAssessment={onStudentModuleAssessmentFinish}
                    moduleAssessmentErrors={moduleAssessmentErrors}
                    teacherAssessmentErrors={teacherAssessmentErrors}
                />
                {updateError && (
                    <Alert
                        message={updateError?.graphQLErrors[0]?.message || updateError.message}
                        time={5000}
                    />
                )}
            </>
        )
        : null;
}

function formatTeacherMeetingsInstances(
    meetingInstancesWithTeachers: GetStudentModuleAssessment_studentModuleMeetingInstances[],
): TeacherMeetingInstance[] {
    const teacherMeetingInstanceMap = new Map<string, TeacherMeetingInstance>();
    meetingInstancesWithTeachers.forEach(({ meetingInstanceToTeachers, ...rest }) => {
        meetingInstanceToTeachers.forEach(({ teacher }) => {
            const current = teacherMeetingInstanceMap.get(teacher.id);
            if (current) {
                const { meetingInstances } = current;
                teacherMeetingInstanceMap.set(teacher.id, {
                    teacher,
                    meetingInstances: [...meetingInstances, rest],
                });
            } else {
                teacherMeetingInstanceMap.set(teacher.id, {
                    teacher,
                    meetingInstances: [rest],
                });
            }
        });
    });
    const teacherMeetingInstances = Array.from(teacherMeetingInstanceMap.values());
    return orderBy(teacherMeetingInstances, ({ teacher }) => teacher.id, 'asc');
}

function getUpdatedAnswers(newAnswer: Answer, answersToUpdate: Answer[]) {
    const previousAnswers = cloneDeep(answersToUpdate);
    const previousAnswerPosition = previousAnswers.findIndex(
        ({ questionId }) => questionId === newAnswer.questionId,
    );
    if (previousAnswerPosition === -1) {
        previousAnswers.push(newAnswer);
        return previousAnswers;
    }
    const previousAnswer = previousAnswers.splice(previousAnswerPosition, 1)[0];
    if (store.isOpenEndedAssessmentQuestion(newAnswer.questionId)
        || newAnswer.optionId !== previousAnswer.optionId) {
        previousAnswers.push(newAnswer);
    }
    return previousAnswers;
}
