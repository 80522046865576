import React from 'react';
import { observer } from 'mobx-react-lite';
import { Checkbox } from '@admin/Trajectories/Checkbox';
import MarksStore from '../Store/MarksStore';

import classes from './TrajectoriesMarks.module.scss';

interface Props {
    text: string;
    markType: string;
    color?: string;
}

export const MarksSelectItem = observer(({
    text,
    markType,
    color,
}: Props): JSX.Element => (
    <label className={classes.marksSelectItem}>
        <Checkbox
            checked={MarksStore.markFilters[markType]}
            onChange={() => MarksStore.toggleMarksOnFilter(markType)}
        />
        <div className={classes.marksSelectItem__type}>
            {color && (
                <div
                    className={classes.marksSelectItem__typeColor}
                    style={{ background: color }}
                />
            )}
            <div className={classes.marksSelectItem__typeText}>
                {text}
            </div>
        </div>
    </label>
));
