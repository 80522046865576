import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import { observer } from 'mobx-react';

import { Subspace, SubspaceType } from '@admin/Trajectories/types';
import { Autosuggest } from '@common/Autosuggest/Autosuggest';
import classes from './SubspaceChooser.module.scss';
import { SelectedSubspaceCard } from '../SelectedSubspaceCard';
import { trajectoryStore } from '../Store/TrajectoryStore';

interface Props {
    idx: number;
    colors: string[];
    subspaceType: SubspaceType;
    isEdit?: boolean;
}

export const SubspaceChooser = observer(({
    idx, subspaceType, colors, isEdit,
}: Props): JSX.Element => {
    const { isSelective, isDescriptionEnabled, name, subspaces } = subspaceType;
    const [selectedSubspace, setSelectedSubspace] = useState<Subspace>();
    const hasDescription = subspaces.every((item: Subspace) => item.description);
    const handleRemove = () => {
        trajectoryStore.removeSubspaces(selectedSubspace!);
        setSelectedSubspace(undefined);
    };
    useEffect(() => {
        trajectoryStore.selectedSubspaces.map((item: Subspace) => (
            (item.type?.name === name) && setSelectedSubspace(item)
        ));
    }, [isEdit]);
    return (
        <div className={classes.subspaceChooser}>
            <div className={classes.subspaceChooser__name}>
                <div
                    className={classes.subspaceChooser__colorSign}
                    style={{ backgroundColor: colors[idx] }}
                />
                <h3 className={classes.subspaceChooser__text}>
                    {isSelective
                        ? `${name}`
                        : `${name}: ${subspaces.map((item: Subspace) => item.name)}`
                    }
                </h3>
            </div>
            {!isSelective
                && (hasDescription ? (
                    <div className={classes.subspaceChooser__description}>
                        {subspaces.map((item: Subspace) => item.description)}
                    </div>
                ) : (
                    <div className={classnames(classes.subspaceChooser__description,
                        classes.subspaceChooser__description_noData)}
                    >
                        {isDescriptionEnabled ? 'У этого подпространства нет описания' : 'У этого типа не задается описание'}
                    </div>
                )
                )
            }
            {isSelective
                && (selectedSubspace ? (
                    <SelectedSubspaceCard
                        subspaceName={selectedSubspace.name}
                        description={selectedSubspace.description}
                        onDelete={handleRemove}
                    />
                ) : (
                    <Autosuggest
                        placeholder="Выберите подпространство из типа"
                        onChange={(item: Subspace) => {
                            setSelectedSubspace(item);
                            trajectoryStore.updateSubspaces(item);
                        }}
                        formatOption={(subspace: Subspace) => subspace.name}
                        options={subspaces}
                        className={classes.subspaceChooser__autosuggest}
                    />
                ))
            }
        </div>
    );
});
