import React, { useEffect } from 'react';
import { format } from 'date-fns';
import { useParams } from 'react-router-dom';

import {
    OperationVariables,
    QueryLazyOptions,
} from '@apollo/client';
import { Loader } from '@common/Loader';
import { GetStudentInfo_student_baseTimeIntervalInstances_baseTimeIntervalInstance, SelectionStatus } from './graphql-query-types';
import {
    StudentSchedule,
    CurOrPastBtiData,
    FutureBTI,
} from './Config/interfaces';
import store, { PrioritySetMode } from './store';
import UserView from '../../store/UserView';
import { CurrentSubspace, FutureSubspaceList } from './Subspaces';

import classes from './StudentModulesPage.module.scss';

interface Props {
    studentSubspacesIds?: string[];
    futureBtiData?: FutureBTI;
    curOrPastBtiData?: CurOrPastBtiData;
    studentSchedule?: StudentSchedule;
    baseTimeIntervalInstances?:
    GetStudentInfo_student_baseTimeIntervalInstances_baseTimeIntervalInstance[],
    loading: boolean;
    instruction?: string;
    studentPrioritiesData?: [];
    getCurrentOrPastBTI: (options?: QueryLazyOptions<OperationVariables> | undefined) => void;
    getFutureBTI: (options?: QueryLazyOptions<OperationVariables> | undefined) => void;
    getStudentPriorities: (options?: QueryLazyOptions<OperationVariables> | undefined) => void;
    setStudentPriority: (
        setMode: PrioritySetMode,
        baseTimeIntervalInstanceId: string,
        forkId: string,
        moduleId: string,
        priority?: number,
    ) => void;
}

export function StudentModulesPage({
    studentSubspacesIds,
    curOrPastBtiData,
    studentSchedule,
    baseTimeIntervalInstances,
    loading,
    instruction = '',
    futureBtiData,
    studentPrioritiesData = [],
    setStudentPriority,
    getCurrentOrPastBTI,
    getFutureBTI,
    getStudentPriorities,
}: Props): JSX.Element {
    const { id } = useParams<{ id: string; }>();
    const { studentId } = UserView;

    const formatDateBTIToString = `${format(new Date(getBTI()?.startDate ?? 0), 'dd.MM.yy')} - ${format(new Date(getBTI()?.endDate ?? 0), 'dd.MM.yy')}`;

    const isFinishedBTI = getBTI()?.selectionStatus === SelectionStatus.selectionFinished;

    store.setBaseTimeIntervalSelectionStatus(
        getBTI()?.selectionStatus ?? SelectionStatus.selectionPending,
    );

    function getBTI() {
        return baseTimeIntervalInstances?.find(item => item?.id === id);
    }

    const getFutureBaseTimeInterval = () => {
        getStudentPriorities({
            variables: {
                baseTimeIntervalInstanceId: id,
            },
        });

        getFutureBTI({
            variables:
            {
                baseTimeIntervalInstanceId: id,
            },
        });
    };

    useEffect(() => {
        if (isFinishedBTI && studentId) {
            getCurrentOrPastBTI({
                variables: {
                    studentId,
                    baseTimeIntervalInstanceId: id,
                },
            });
        } else {
            getFutureBaseTimeInterval();
        }
    }, [studentId, id]);

    store.setStudentPriorities(studentPrioritiesData);
    store.sortForkSlotsByPriorities();

    const setStudentPriorities = async (
        setMode: PrioritySetMode,
        forkId: string,
        moduleId: string,
        priority?: number,
    ) => {
        await setStudentPriority(setMode, id, forkId, moduleId, priority);
        await getStudentPriorities({
            variables: {
                baseTimeIntervalInstanceId: id,
            },
        });
        store.setStudentPriorities(studentPrioritiesData);
        store.sortForkSlotsByPriorities();
        store.updateForksOnStudentPriorities();
    };

    return (
        <div className={classes.studentModulesPage}>
            {getBTI() && (
                loading ? <Loader /> : (
                    <>
                        {isFinishedBTI ? (
                            <>
                                <div className={classes.studentModulesPage__period}>
                                    Учебный период: {formatDateBTIToString}
                                </div>
                                {curOrPastBtiData
                                    ?.requestedBaseTimeIntervalInstance?.studentSubspaces
                                    .map(item => (
                                        <CurrentSubspace
                                            key={item.subspace.id}
                                            studentSubspace={item}
                                            studentSchedule={studentSchedule}
                                        />
                                    ))}
                            </>
                        ) : (
                            <FutureSubspaceList
                                futureBtiData={futureBtiData}
                                studentSubspacesIds={studentSubspacesIds}
                                instruction={instruction}
                                formatDateBTIToString={formatDateBTIToString}
                                setStudentPriorities={setStudentPriorities}
                            />
                        )
                        }
                    </>
                )
            )}
        </div>

    );
}
