import React from 'react';
// import React, { useState } from 'react';

import 'moment/locale/ru';
import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import { BrowserRouter as Router } from 'react-router-dom';

import { ReactHelmet } from '@common/ReactHelmet/ReactHelmet';
// import { PopupApollo } from '@common/Popup';
import { AlertContainer } from '@common/AlertContainer';
import { AuthRouter } from '@common/AuthRouter/AuthRouter';
import { Menu } from '@common/Menu';

import UserView from './store/UserView';

import './normalize.css';
import classes from './App.module.scss';

const App = observer((): JSX.Element => {
    const { userViewType } = UserView;

    // const { userViewType, acceptedAgreement } = UserView;
    // const [changeContent, setChangeContent] = useState(0);

    return (
        <div className={classes.app}>
            <ReactHelmet />

            <Router>
                {/* Спрятал popup до лучших времен */}
                {/* {
                    !!localStorage.getItem('token') && !acceptedAgreement
                        ? (
                            <PopupApollo
                                maxWidth={640}
                                change={changeContent}
                                changeHandler={() => setChangeContent(0)}
                                title="Соглашение на обработку персональных данных"
                                text={[
                                    'Нажимая на кнопку “Согласен”, вы даете согласие на ',
                                    <span
                                        className={classes.pseudolink}
                                        onClick={() => setChangeContent(1)}
                                        key="acceptButton"
                                    >
                                        обработку персональных данных
                                    </span>,
                                    ' и соглашаетесь c ',
                                    <span
                                        className={classes.pseudolink}
                                        onClick={() => setChangeContent(2)}
                                        key="denyButton"
                                    >
                                        политикой конфиденциальности
                                    </span>,
                                ]}
                            />
                        )
                        : null
                } */}

                {(userViewType) && <Menu />}

                <div
                    className={
                        cn(classes.app__content,
                            {
                                [classes.app__content_showAuth]: !userViewType,
                            })
                    }
                >
                    <AuthRouter />
                </div>
            </Router>

            <AlertContainer />
        </div>
    );
});

export default App;
