import { observer } from 'mobx-react';
import React from 'react';

import colors from '../../store/ColorConstans';

interface Props {
    size?: number;
    firstColor?: string;
    secondColor?: string;
    thirdColor?: string;
    className?: string;
    handler?: () => void;
}

export const QuestionIcon = observer(({
    size = 36,
    firstColor = colors.firstColor,
    secondColor = colors.secondColor,
    thirdColor = colors.thirdColor,
    className,
    handler,
}: Props): JSX.Element => (
    <div className={className} style={{ height: `${size}px`, width: `${size}px`, cursor: 'pointer' }} onClick={handler}>
        <svg width={size} height={size} viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M18 33.3125C13.9389 33.3125 10.0441 31.6992 7.17243 28.8276C4.30078 25.9559 2.6875 22.0611 2.6875 18C2.6875 13.9389 4.30078 10.0441 7.17243 7.17243C10.0441 4.30078 13.9389 2.6875 18 2.6875C22.0611 2.6875 25.9559 4.30078 28.8276 7.17243C31.6992 10.0441 33.3125 13.9389 33.3125 18C33.3125 22.0611 31.6992 25.9559 28.8276 28.8276C25.9559 31.6992 22.0611 33.3125 18 33.3125ZM18 35.5C22.6413 35.5 27.0925 33.6563 30.3744 30.3744C33.6563 27.0925 35.5 22.6413 35.5 18C35.5 13.3587 33.6563 8.90752 30.3744 5.62563C27.0925 2.34374 22.6413 0.5 18 0.5C13.3587 0.5 8.90752 2.34374 5.62563 5.62563C2.34374 8.90752 0.5 13.3587 0.5 18C0.5 22.6413 2.34374 27.0925 5.62563 30.3744C8.90752 33.6563 13.3587 35.5 18 35.5Z" fill="url(#paint0_linear_177_529)" />
            <path d="M11.9953 13.1569C11.9923 13.2275 12.0038 13.298 12.0291 13.3641C12.0543 13.4301 12.0928 13.4903 12.1422 13.5409C12.1916 13.5915 12.2508 13.6315 12.3162 13.6583C12.3816 13.6852 12.4518 13.6984 12.5225 13.6972H14.3272C14.6291 13.6972 14.8697 13.45 14.9091 13.1503C15.1059 11.7153 16.0903 10.6697 17.8447 10.6697C19.3453 10.6697 20.7191 11.42 20.7191 13.2247C20.7191 14.6138 19.9009 15.2525 18.6081 16.2238C17.1359 17.2934 15.97 18.5425 16.0531 20.5703L16.0597 21.045C16.062 21.1885 16.1206 21.3254 16.2229 21.4261C16.3252 21.5267 16.463 21.5831 16.6066 21.5831H18.3806C18.5257 21.5831 18.6648 21.5255 18.7673 21.4229C18.8699 21.3204 18.9275 21.1813 18.9275 21.0362V20.8066C18.9275 19.2359 19.5247 18.7787 21.1369 17.5559C22.4691 16.5431 23.8581 15.4188 23.8581 13.0584C23.8581 9.75313 21.0669 8.15625 18.0109 8.15625C15.2394 8.15625 12.2031 9.44687 11.9953 13.1569ZM15.4012 25.7634C15.4012 26.9294 16.3309 27.7913 17.6106 27.7913C18.9428 27.7913 19.8594 26.9294 19.8594 25.7634C19.8594 24.5559 18.9406 23.7072 17.6084 23.7072C16.3309 23.7072 15.4012 24.5559 15.4012 25.7634Z" fill="url(#paint1_linear_177_529)" />
            <defs>
                <linearGradient id="paint0_linear_177_529" x1="38.0301" y1="16.0063" x2="-0.0152438" y2="30.8573" gradientUnits="userSpaceOnUse">
                    <stop offset="0.130208" stopColor={firstColor} />
                    <stop offset="0.734375" stopColor={secondColor} />
                    <stop offset="0.932292" stopColor={thirdColor} />
                </linearGradient>
                <linearGradient id="paint1_linear_177_529" x1="38.0301" y1="16.0063" x2="-0.0152438" y2="30.8573" gradientUnits="userSpaceOnUse">
                    <stop offset="0.130208" stopColor={firstColor} />
                    <stop offset="0.734375" stopColor={secondColor} />
                    <stop offset="0.932292" stopColor={thirdColor} />
                </linearGradient>
            </defs>
        </svg>
    </div>
));
