import React, { useEffect, useRef, useState } from 'react';
import cn from 'classnames';

import { Tooltip } from '@common';
import { Input } from '@admin/EquipmentPage/Input';
import {
    GetEquipmentsEquipmentPage_equipment_list_movability,
    TerritorialZonesEquipmentPage_territorialZones,
} from 'src/deprecated-graphql-query-types';

import classes from '../RegularEquipment.module.scss';

interface Props {
    count: number;
    movability: GetEquipmentsEquipmentPage_equipment_list_movability[];
    zones?: TerritorialZonesEquipmentPage_territorialZones[];
    equipmentInRoomsCount: number;
    setIsEquipmentEditing: React.Dispatch<React.SetStateAction<boolean>>;
    onChangeMovabilityCount: (
        equipmentCount: number,
        isMovable: boolean, zoneId: string | null,
        setIsValidCount: React.Dispatch<React.SetStateAction<boolean>>) => void;
}

interface StationaryEquipmentCountProps extends Partial<Props> {
    isValidCount: boolean;
    activeInput: string;
    notMovableEquipment: GetEquipmentsEquipmentPage_equipment_list_movability | {
        isMovable: false;
        territorialZoneId: null;
        count: number;
    }
    setActiveInput: React.Dispatch<React.SetStateAction<string>>;
    setIsValidCount: React.Dispatch<React.SetStateAction<boolean>>;
    onChangeMovabilityCount: (equipmentCount: number) => void;
}

export const Mobility = ({
    count,
    movability,
    zones,
    equipmentInRoomsCount,
    setIsEquipmentEditing,
    onChangeMovabilityCount,
}: Props) => {
    const movabilityRef = useRef<HTMLInputElement>(null);

    const [activeInput, setActiveInput] = useState('');
    const [isCountEditing, setIsCountEditing] = useState(false);
    const [isValidCount, setIsValidCount] = useState(true);
    const [isShowZones, setIsShowZones] = useState(false);

    const notMovableEquipment = movability
        ?.find(
            ({ isMovable, territorialZoneId }) => (!isMovable && !territorialZoneId),
        ) ?? {
        isMovable: false,
        territorialZoneId: null,
        count: 0,
    };

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (!movabilityRef?.current?.contains(event.target as Node)) {
                setIsCountEditing(false);
                setIsShowZones(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [movabilityRef]);

    useEffect(() => {
        setIsEquipmentEditing(isCountEditing);
    }, [isCountEditing, activeInput]);

    return (
        <div
            ref={movabilityRef}
            className={classes.regularEquipment__countContainer}
        >
            <div
                onClick={() => setIsCountEditing(true)}
                className={classes.regularEquipment__equipmentCount}
            >
                {count > 0 ? count
                    : (
                        <div
                            className={classes.regularEquipment__defaultValue}
                        >
                            Кол-во
                        </div>
                    )}
            </div>
            {isCountEditing
                && (
                    <div className={classes.movability__container}>
                        <div
                            className={classes.regularEquipment__equipmentCountEdit}
                        >
                            <StationaryEquipment
                                count={count}
                                isValidCount={isValidCount}
                                activeInput={activeInput}
                                notMovableEquipment={notMovableEquipment}
                                equipmentInRoomsCount={equipmentInRoomsCount}
                                setActiveInput={setActiveInput}
                                setIsValidCount={setIsValidCount}
                                onChangeMovabilityCount={(value) => onChangeMovabilityCount(
                                    value,
                                    false,
                                    null,
                                    setIsValidCount,
                                )}
                            />
                            {!isShowZones && (
                                <div
                                    className={classes.movability__option}
                                    onClick={() => setIsShowZones(true)}
                                >
                                    <div
                                        className={classes.movability__subtitle}
                                    >
                                        Перемещаемое
                                    </div>
                                    <div>
                                        <div
                                            className={classes.movability__moveableCount}
                                        >
                                            {count - notMovableEquipment?.count ?? ''}
                                        </div>
                                    </div>
                                </div>
                            )}
                            {isShowZones && (
                                <MovableEquipment
                                    movability={movability}
                                    activeInput={activeInput}
                                    setActiveInput={setActiveInput}
                                    onChangeMovabilityCount={(value) => onChangeMovabilityCount(
                                        value,
                                        true,
                                        null,
                                        setIsValidCount,
                                    )}
                                />
                            )}
                            {isShowZones && zones?.map(({ id, name }) => (
                                <div
                                    key={id}
                                    className={cn(
                                        classes.movability__option,
                                        {
                                            [classes.movability__option_selected]:
                                                activeInput === id,
                                        },
                                    )}
                                    onClick={() => setActiveInput(id)}
                                >
                                    <div
                                        className={cn(
                                            classes.movability__territorialZone,
                                            {
                                                [classes.movability__territorialZone_selected]:
                                                    activeInput === id,
                                            },
                                        )}
                                    >
                                        {name}
                                    </div>
                                    <Input
                                        placeholder=""
                                        type="number"
                                        className={classes.movability__input}
                                        hasFocus={activeInput === id}
                                        isOnChangeActivateOnType
                                        startingValue={movability
                                            .find(({ territorialZoneId: zoneId }) => zoneId === id)
                                            ?.count ?? 0
                                        }
                                        onChange={(value) => onChangeMovabilityCount(
                                            Number(value),
                                            true,
                                            id,
                                            setIsValidCount,
                                        )}
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                )}
        </div>

    );
};

function StationaryEquipment({
    count,
    isValidCount,
    activeInput,
    notMovableEquipment,
    equipmentInRoomsCount,
    setActiveInput,
    setIsValidCount,
    onChangeMovabilityCount,
}: StationaryEquipmentCountProps) {
    useEffect(() => {
        if (notMovableEquipment.count < equipmentInRoomsCount!) {
            setIsValidCount(false);
        }
    }, [count]);
    return (
        <div
            className={classes.movability__option}
            onClick={() => setActiveInput('stationary')}
        >
            <div className={classes.movability__subtitle_bold}>
                Стационарное
            </div>
            <Tooltip
                title={`${equipmentInRoomsCount} шт. данного оборудования уже используется,`}
                text={'чтобы уменьшить количество,'
                    + ' вначале измените данные во вкладке "Помещения"'
                    + ' или уменьшите количество перемещаемого оборудования'}
                isWarning={!isValidCount}
                isShow={!isValidCount}
                hasExternalState
            >
                <Input
                    placeholder=""
                    className={classes.movability__input}
                    type="number"
                    startingValue={notMovableEquipment?.count ?? 0}
                    isOnChangeActivateOnType
                    autoFocus
                    hasFocus={activeInput === 'stationary'}
                    onChange={(value) => onChangeMovabilityCount(Number(value))}
                />
            </Tooltip>
        </div>
    );
}

function MovableEquipment({
    movability,
    activeInput,
    setActiveInput,
    onChangeMovabilityCount,
}: Partial<StationaryEquipmentCountProps>) {
    return (
        <div
            className={cn(
                classes.movability__option,
                {
                    [classes.movability__option_selected]:
                        activeInput === 'movable',
                },
            )}
            onClick={() => setActiveInput!('movable')}
        >
            <div
                className={cn(
                    classes.movability__territorialZone,
                    {
                        [classes.movability__territorialZone_selected]:
                            activeInput === 'movable',
                    },
                )}
            >
                Перемещаемое везде
            </div>
            <Input
                isOnChangeActivateOnType
                hasFocus={activeInput === 'movable'}
                placeholder=""
                className={classes.movability__input}
                type="number"
                startingValue={movability!.find(({
                    territorialZoneId,
                    isMovable,
                }) => isMovable && !territorialZoneId)?.count ?? 0}
                onChange={(value) => onChangeMovabilityCount!(
                    Number(value),

                )}
            />
        </div>
    );
}
