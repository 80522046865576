import React, { useEffect } from 'react';
import { Redirect, Switch, useRouteMatch } from 'react-router-dom';
import { sortBy } from 'lodash';

import { Alert } from '@common/Alert';
import { Loader } from '@common/Loader';
import { AuthRoute } from '@common/AuthRoute';
import { HeaderRedesign } from '@common/HeaderRedesign';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { TabsOption } from '@common/TabsRedesign/TabsRedesign';

import UserView from '../../store/UserView';
import SkillHierarchy from '../../store/SkillHierarchy';
import { ViewType } from '../../deprecated-graphql-query-types';
import { StudentModulesPage } from './StudentModulesPage';
import {
    GET_ALL_SKILLS,
    GET_FUTURE_BTI,
    GET_MODULE_ASSESSMENT_CONFIG,
    GET_PAST_OR_CURRENT_BTI,
    GET_STUDENT_INFO,
    GET_STUDENT_PRIORITIES,
    UPDATE_STUDENT_PRIORITY,
    DELETE_STUDENT_PRIORITY,
    GET_SELECTION_INSTRUCTION,
} from './queries';
import ModuleAssessmentStore from './module-assessment-config-store';
import {
    GetModuleAssessmentConfig,
    GetStudentInfo,
    GetStudentInfo_student_baseTimeIntervalInstances,
    SelectionInstruction,
} from './graphql-query-types';
import { PrioritySetMode } from './store';

import classes from './StudentModulesPage.module.scss';

export const StudentModulesPageApollo = (): JSX.Element => {
    const { studentId } = UserView;
    const { path } = useRouteMatch();

    const {
        data: allSkills,
        loading: loadingSkills,
        error: errorGetSkills,
    } = useQuery(GET_ALL_SKILLS, {
        fetchPolicy: 'cache-first',
    });

    const {
        data: instruction,
        error: errorGetSelectionInstruction,
    } = useQuery<SelectionInstruction>(GET_SELECTION_INSTRUCTION, {
        fetchPolicy: 'cache-first',
    });
    const {
        data: assessmentConfigData,
        error: errorGetAssessment,
    } = useQuery<GetModuleAssessmentConfig>(
        GET_MODULE_ASSESSMENT_CONFIG,
        { fetchPolicy: 'network-only' },
    );

    const { data, loading, error: errorGetStudentInfo } = useQuery<GetStudentInfo>(
        GET_STUDENT_INFO,
        {
            fetchPolicy: 'no-cache',
            variables: { studentId },
            skip: !studentId,
        },
    );

    const [getCurrentOrPastBTI, {
        loading: loadingCurrentBTI,
        data: curOrPastBtiData,
    }] = useLazyQuery(GET_PAST_OR_CURRENT_BTI, {
        fetchPolicy: 'no-cache',
    });

    const [getFutureBTI, {
        loading: loadingFutureBTI,
        data: futureBtiData,
        error: errorGetFutureBTI,
    }] = useLazyQuery(GET_FUTURE_BTI, {
        fetchPolicy: 'no-cache',
    });

    const [getStudentPriorities, {
        data: studentPrioritiesData,
        error: errorGetPriority,
    }] = useLazyQuery(GET_STUDENT_PRIORITIES, {
        fetchPolicy: 'no-cache',
    });

    const [updatePriority, { error: errorUpdatePriority }] = useMutation(UPDATE_STUDENT_PRIORITY);
    const [deletePriority, { error: errorDeletePriority }] = useMutation(DELETE_STUDENT_PRIORITY);

    if (allSkills) SkillHierarchy.setSkills(allSkills.skills);

    useEffect(() => {
        if (assessmentConfigData?.moduleAssessmentConfig) {
            ModuleAssessmentStore.setModuleAssessmentConfig(
                assessmentConfigData?.moduleAssessmentConfig,
            );
        }
    }, [assessmentConfigData]);

    useEffect(() => {
        const currentBG = document.body.style.background;
        document.body.style.background = '#FFFFFF';
        return () => {
            document.body.style.background = currentBG;
        };
    }, []);

    if (loading) return <Loader />;
    const student = data?.student;

    const baseTimeIntervalInstances = student?.baseTimeIntervalInstances;
    const activeBaseTimeIntervalInstance = student?.activeBaseTimeIntervalInstance;
    const BTIs = baseTimeIntervalInstances
        ?.map((item: GetStudentInfo_student_baseTimeIntervalInstances) => item
            .baseTimeIntervalInstance);
    const tabs: TabsOption[] = sortBy(baseTimeIntervalInstances, 'baseTimeIntervalInstance.spaceBaseTimeInterval.order')
        ?.map((item: GetStudentInfo_student_baseTimeIntervalInstances): TabsOption => {
            const { baseTimeIntervalInstance } = item;
            const { spaceBaseTimeInterval } = baseTimeIntervalInstance;
            return {
                title: `${spaceBaseTimeInterval.order ?? ''} ${spaceBaseTimeInterval.space.baseTimeIntervalType.name ?? ''}`,
                link: `${baseTimeIntervalInstance.id}`,
                selectionStatus: baseTimeIntervalInstance.selectionStatus,
            };
        });
    const activeBTI = tabs.find(
        tab => tab.link === activeBaseTimeIntervalInstance?.baseTimeIntervalInstance.id,
    );
    const error = errorGetStudentInfo
        || errorUpdatePriority
        || errorDeletePriority
        || errorGetPriority
        || errorGetFutureBTI
        || errorGetSkills
        || errorGetSelectionInstruction
        || errorGetAssessment;

    const setStudentPriority = async (
        setMode: PrioritySetMode,
        baseTimeIntervalInstanceId: string,
        forkId: string,
        moduleId: string,
        priority?: number,
    ) => {
        if (setMode === PrioritySetMode.UPDATE) {
            await updatePriority({
                variables: {
                    updateStudentPriorityInput: {
                        baseTimeIntervalInstanceId,
                        forkId,
                        moduleId,
                        priority,
                    },
                },
            });
        } else {
            await deletePriority({
                variables: {
                    deleteStudentPriorityInput: {
                        baseTimeIntervalInstanceId,
                        forkId,
                        moduleId,
                    },
                },
            });
        }
    };
    return data ? (
        <>

            {error && <Alert message={error?.message} time={8000} />}
            <div className={classes.studentModulesPage__header}>
                <HeaderRedesign title="Модули" tabsOptions={tabs ?? []} />
            </div>
            <Switch>
                <AuthRoute
                    path="/modules/:id"
                    requiredUserTypes={[ViewType.Student]}
                >
                    <StudentModulesPage
                        studentSubspacesIds={student?.subspaces
                            .map((item: any) => item.subspace.id)}
                        curOrPastBtiData={curOrPastBtiData?.student}
                        futureBtiData={{
                            ...futureBtiData
                                ?.baseTimeIntervalInstance.spaceBaseTimeInterval,
                            selectionPoint: futureBtiData
                                ?.baseTimeIntervalInstance.selectionPoint,
                        }}
                        instruction={instruction?.selectionInstruction}
                        loading={loadingCurrentBTI
                            || loadingFutureBTI
                            || loadingSkills}
                        studentPrioritiesData={studentPrioritiesData?.studentPriorities}
                        studentSchedule={data?.studentSchedule}
                        baseTimeIntervalInstances={BTIs}
                        setStudentPriority={setStudentPriority}
                        getCurrentOrPastBTI={getCurrentOrPastBTI}
                        getFutureBTI={getFutureBTI}
                        getStudentPriorities={getStudentPriorities}
                    />
                </AuthRoute>
                {(activeBTI || tabs.length) ? (
                    <AuthRoute
                        path="/modules/"
                        requiredUserTypes={[ViewType.Student]}
                    >
                        <Redirect to={`${path}/${activeBTI ? activeBTI.link : tabs[tabs.length - 1]?.link}`} />
                    </AuthRoute>
                ) : (
                    <>
                        модули отсутствуют
                    </>
                )}
            </Switch>
        </>
    ) : (
        <>
            <div className={classes.studentModulesPage__header}>
                <HeaderRedesign title="Модули" tabsOptions={tabs ?? []} />
            </div>
            модули отсутствуют
        </>
    );
};
