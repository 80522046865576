import React, { useState } from 'react';
import { SubSpaceOptions } from './SubSpaceOptionsPage';
import { SubSpaceSlotSettings } from './SubSpaceSlotSettings';

import {
    Space,
    Subspace,
    SubspaceType,
} from './subSpaceTypes';

import { skills_skills, skillTypesData_skillTypes } from '../deprecated-graphql-query-types';
import { BackToTheSpaces } from './BackToTheSpaces';

interface Props {
    skillTypes: skillTypesData_skillTypes[];
    allSkillsList: skills_skills[];
    space: Space;
    subspace: Subspace;
    subspaceType: SubspaceType;
    routerPath: string;
    refetchGetSubspacePageData(params: any):void
}

export function SubSpacePage({
    skillTypes,
    allSkillsList,
    space,
    subspace,
    subspaceType,
    routerPath,
    refetchGetSubspacePageData,
}: Props): JSX.Element {
    const [editState, setEditState] = useState(false);
    return (
        <>
            <BackToTheSpaces
                space={space}
                routerPath={routerPath}
            />
            <SubSpaceOptions
                refetchGetSubspacePageData={refetchGetSubspacePageData}
                editState={editState}
                setEditState={setEditState}
                allSkillsList={allSkillsList}
                space={space}
                subspace={subspace}
                subspaceType={subspaceType}
            />
            {!editState && (
                <SubSpaceSlotSettings
                    skillTypes={skillTypes}
                    allSkillsList={allSkillsList}
                    subspace={subspace}
                    subspaceType={subspaceType}
                />
            )}
        </>
    );
}
