import React from 'react';
import { NavLink } from 'react-router-dom';

import { ListUser_users_list } from '../../apollo-types';

interface Props {
    user: ListUser_users_list;
}

export const FullName = ({ user }: Props) => (
    <td>
        <NavLink to={`/users/user/${user.id}`}>
            {` ${user.lastName ?? ''} ${user.firstName ?? ''}  ${user.patronymic ?? ''}`}
        </NavLink>
    </td>
);
