import {
    RoomsPage_Rooms_rooms,
    RoomsPage_Rooms_rooms_location_territorialZone_availableIntervals,
} from 'src/graphql-query-types';

// BACKEND DATA
export interface Building {
    id: string;
    name: string;
    location: string;
}

export interface TerritorialZone {
    id: string;
    name: string;
    rooms: RoomBackendData[];

}
export interface RoomBackendData extends RoomsPage_Rooms_rooms {}

export interface EquipmentToRoom {
    equipments: RoomEquipment
}

export interface RoomLocation {
    id: string;
    location: string;
    name: string;
}
export interface RoomMeetingFormat {
    id: string;
    name: string;
    status: Status;
}

export enum Status {
    check = 'check',
    checked = 'checked',
}
export interface RoomEquipment {
    equipmentId: string;
    countEquipments: number;
    equipment: EquipCategory;
}

export interface EquipCategory {
    category: {
        name: string;
    }
}

export interface TerritorialZoneAvailableIntervals
    extends RoomsPage_Rooms_rooms_location_territorialZone_availableIntervals {}

export interface StoreEquipmentTypes {
    equipments: RoomCreationEquipment
}

// FRONTEND DATA

export enum Day {
    friday = 'Пт',
    monday = 'Пн',
    saturday = 'Сб',
    sunday = 'Вс',
    thursday = 'Чт',
    tuesday = 'Вт',
    wednesday = 'Ср',
}

export enum WeekTypes {
    even = 'четную неделю',
    every = 'каждую неделю',
    odd = 'нечетную неделю',
}

export interface RoomCreation {
    id: string
    title: number | string
    capacity: number | undefined
    types: string[]
    location: string
    equipments: RoomCreationEquipment[]
}
export interface RoomCreationEquipment {
    id: string
    count: number
    name: string
}
export interface RoomFormSend {
    id: string;
    title: number | string;
    capacity: number;
    meetingFormatIds: string[];
    buildingId: string;
    equipments: RoomSendEquipment[];
    isAvailable: boolean;
    availableIntervals: AvailableInterval[];
}

export interface RoomSendEquipment {
    id: string;
    count: number;
}

export interface AlertMessage {
    alert: boolean;
    message: string;
}

export interface AvailableInterval {
    id: string;
    from: Date;
    to: Date;
}
