import React, { useState } from 'react';
import {
    NotDistribStudentsAccordion,
} from '@admin/ModuleSelectionPage/NotDistribStudentsAccordion/NotDistribStudentsAccordion';
import { useMutation } from '@apollo/client';
import { Alert } from '@common/Alert';
import { nanoid } from 'nanoid';
import { DELETE_STUDENT_MODULE } from '@admin/ModuleSelectionPage/ModuleSelectionQueries';
import { DeleteStudentModuleInput } from '@admin/ModuleSelectionPage/graphql-types';

export function NotDistribStudentsAccordionApollo() {
    const [
        deleteStudentModuleError,
        setDeleteStudentModuleError,
    ] = useState<JSX.Element[]>([]);
    const addError = (message: string) => setDeleteStudentModuleError((arr) => [...arr, (<Alert
        key={nanoid()}
        message={message}
        time={7000}
    />)]);

    const [deleteStudentModuleMutation] = useMutation(DELETE_STUDENT_MODULE, {
        onError: (error) => addError(error.message),
    });

    const deleteStudentModule = (input: DeleteStudentModuleInput) => deleteStudentModuleMutation({
        variables: {
            DeleteStudentModuleInput: {
                ...input,
            },
        },
    });

    return (
        <>
            <NotDistribStudentsAccordion
                deleteStudentModule={deleteStudentModule}
            />
            { deleteStudentModuleError }
        </>
    );
}
