import React from 'react';
import { Suggest } from '@common';
import styles from './Section.module.scss';

type Option = {
    id: string;
    name: string;
};

export const SuggestFormInitialData = {
    id: '',
    name: '',
};

interface Props {
    label: string;
    placeholder: string;
    mainOptions?: Option[];
    data: Option;

    setData(data: Option): void;
}

export function SuggestForm({
    mainOptions,
    label,
    placeholder,
    data,
    setData,
}: Props) {
    return (
        <div className={styles.suggestForm}>
            <Suggest
                value={data.id ? data : null}
                options={mainOptions}
                label={label}
                placeholder={placeholder}
                onChange={value => setData({ ...data, ...value })}
            />
        </div>
    );
}
