/* eslint-disable import/no-cycle */
import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { observer } from 'mobx-react';
import cn from 'classnames';

import { Alert } from '@common/Alert';
import {
    CREATE_STUDENTS_EVALUATION,
    EDIT_STUDENT_EVALUATION,
    GET_EVALUATION_POINT_INSTANCES,
} from '../../../../../../../gql/evaluationPointInstance';
import { Grade } from '../../GradingMenu';
import { ApolloAlert, FilterType } from '../../../../../../../Interfaces';
import { accountStore } from '../../../../../../../../Store';
import { eventsStore, timeStore } from '../../../../../../../Store';
import { ButtonTemp } from '../../../../../ButtonTemp';
import { evaluationStore, multipleEvaluationStore, StudentsTab } from '../../../Store';

import classes from './EvaluationMutation.module.scss';

interface CreateEvaluationMutation {
    evaluationPointInstanceId: string;
    studentIds: string[];
    note: string;
    updateStudentsSkills: StudentSkills[],
}

type StudentsIDs = Omit<CreateEvaluationMutation, 'studentIds'>;

interface UpdateEvaluationMutation extends StudentsIDs {
    studentID: string;
}

interface StudentSkills {
    level: number;
    isEvaluated: boolean;
    skillId: string;
}

interface Props {
    id: string;
    commentForStudent: string;
    grades: Grade[];
    isEditing: boolean;
    enableEditing(): void;
    disableEditing(): void;
}

export const EvaluationMutation = observer(({
    id,
    commentForStudent,
    grades,
    isEditing,
    enableEditing,
    disableEditing,
}: Props): JSX.Element => {
    const [alert, setAlert] = useState<ApolloAlert>({
        alert: false,
        message: '',
    });

    const [sendEvaluationData] = useMutation<CreateEvaluationMutation>(
        CREATE_STUDENTS_EVALUATION,
        {
            onError: (error) => errorHandling(error.toString()),
            refetchQueries: [
                {
                    query: GET_EVALUATION_POINT_INSTANCES,
                    variables: {
                        getEvaluationPointInstanceInput: {
                            teacherId: accountStore.teacherId,
                            fromDate: timeStore.getScheduleStart(),
                            toDate: timeStore.getScheduleEnd(),
                        },
                    },
                },
            ],
            fetchPolicy: 'no-cache',
        },
    );

    const [editEvaluationData] = useMutation<UpdateEvaluationMutation>(
        EDIT_STUDENT_EVALUATION,
        {
            onError: (error) => errorHandling(error.toString()),
            refetchQueries: [
                {
                    query: GET_EVALUATION_POINT_INSTANCES,
                    variables: {
                        getEvaluationPointInstanceInput: {
                            teacherId: accountStore.teacherId,
                            fromDate: timeStore.getScheduleStart(),
                            toDate: timeStore.getScheduleEnd(),
                        },
                    },
                },
            ],
            fetchPolicy: 'no-cache',
        },
    );

    function errorHandling(error: string): void {
        setAlert({ alert: true, message: error.toString() });
        const clearId = setTimeout(() => setAlert({ alert: false, message: '' }), 2500);
        clearTimeout(clearId);
    }

    function prepareGradesForMutation() {
        return grades
            .map(({
                skillId,
                level,
                isCredited,
                isEvaluated,
            }) => ({
                skillId, level, isCredited, isEvaluated,
            }));
    }

    function sendEvaluation(): void {
        const IDs = multipleEvaluationStore.students.map((student) => student.student.id);
        disableEditing();
        const queryInput = {
            evaluationPointInstanceId: !eventsStore.isAssignment(eventsStore.selectedEvent)
                && eventsStore.selectedEvent?.id,
            studentIds: id || IDs,
            note: commentForStudent,
            updateStudentsSkills: prepareGradesForMutation(),
        };
        sendEvaluationData({
            variables: { data: queryInput },
        });
        if (multipleEvaluationStore.students.length) {
            multipleEvaluationStore.removeAllStudentsFromMultipleEvaluation();
        }
    }

    function getEditModeInRevaluation(): void {
        if (isEditing) return sendEditEvaluation();
        return enableEditing();
    }

    function sendEditEvaluation(): void {
        disableEditing();
        const queryInput = {
            evaluationPointInstanceId: !eventsStore.isAssignment(eventsStore.selectedEvent)
                && eventsStore.selectedEvent?.id,
            studentId: id,
            note: commentForStudent,
            updateStudentSkills: prepareGradesForMutation(),
        };
        editEvaluationData({
            variables: { data: queryInput },
        });
    }

    let buttonText;

    if (isEditing) {
        if (evaluationStore.isStudentEvaluated(id)) {
            buttonText = 'Сохранить';
        } else {
            buttonText = 'Оценить';
        }
    } else {
        buttonText = 'Редактировать';
    }

    return (
        <>
            {(eventsStore.selectedEvent?.type === FilterType.EVALUATION_POINT
                && !eventsStore.selectedEvent.isGraded)
                && (
                    <ButtonTemp
                        text={buttonText}
                        className={cn(classes.gradingMenu__button, {
                            [classes.gradingMenu__button_isEditButton]: !isEditing,
                        })}
                        onClick={
                            evaluationStore.isTabSelected(StudentsTab.EVALUATED)
                                ? getEditModeInRevaluation : sendEvaluation
                        }
                    />
                )
            }

            {alert.alert && <Alert message={alert.message} time={2500} />}
        </>
    );
});
