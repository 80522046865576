import React from 'react';
import moment, { Moment } from 'moment';
import { observer } from 'mobx-react';
import cn from 'classnames';

import {
    timeStore,
    UnitOfTime,
} from '../../../../../Store';
import { TimeReservingEvent, FilterType } from '../../../../../Interfaces';

import classes from './DayOfMonthCellMobile.module.scss';

interface Props {
    date: Moment | null;
    dateEvents: TimeReservingEvent[];
}

export const DayOfMonthCellMobile = observer(({ date, dateEvents }: Props): JSX.Element => {
    function handleRedirectToDay(): void {
        if (date) {
            timeStore.setSelectedDate(date);
            timeStore.setUnitOfTime(UnitOfTime.DAY);
        }
    }

    return (
        <div
            className={classes.dayOfMonthCellMobile}
            onClick={handleRedirectToDay}
        >
            <div
                className={cn(
                    classes.dayOfMonth, {
                        [classes.dayOfMonth_isToday]: date?.isSame(moment(), 'day'),
                        [classes.dayOfMonth_isOutsideMonth]: !date?.isSame(timeStore.selectedDate, 'month'),
                    },
                )}
            >
                {date?.format('DD')}
            </div>

            <div className={classes.meetings}>
                {dateEvents.some((meeting) => meeting.type === FilterType.MEETING) && (
                    <div
                        className={cn(
                            classes.meetingCircle,
                            classes.meetingCircle_filter_meeting,
                        )}
                    />
                )}
                {dateEvents.some((meeting) => meeting.type === FilterType.EVALUATION_POINT) && (
                    <div
                        className={cn(
                            classes.meetingCircle,
                            classes.meetingCircle_filter_evaluationPoint,
                        )}
                    />
                )}
            </div>
        </div>
    );
});
