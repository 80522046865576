import React from 'react';
import { observer } from 'mobx-react';
import { Title } from '@common';
import styles from './Assignment.module.scss';

import { AssignmentInterface, moduleStore, dictionaryStore } from '../Store';
import { SkillsSection } from '../Section';

interface Props {
    assignmentData: AssignmentInterface
}

export const AssignmentSkills = observer(({ assignmentData }: Props) => {
    const module = moduleStore.moduleModel;
    return (
        <div className={styles.form__skills}>
            <Title>Образовательные результаты:</Title>
            <br />
            <SkillsSection
                requiredTitle="которые необходимы для попадания"
                outputTitle="которые дает сам. работа при прохождении"
                skillTypes={dictionaryStore.moduleSettings.meetingSkillTypes}
                requiredSkills={assignmentData.prerequisiteSkills ?? []}
                outputSkills={assignmentData.outputSkills ?? []}
                onChangeRequired={(items, typeID) => module.updateAssignmentRequiredSkills({
                    assignmentID: assignmentData.id,
                    newSkills: items.map(item => ({
                        skill: { ...item, fullName: item.name },
                        level: Number(item.selectOption?.name),
                    })),
                    typeID,
                })}
                onChangeOutput={(items, typeID) => module.updateAssignmentOutputSkills({
                    assignmentID: assignmentData.id,
                    newSkills: items.map(item => ({
                        skill: { ...item, fullName: item.name },
                        level: Number(item.selectOption?.name),
                    })),
                    typeID,
                })}
            />
        </div>
    );
});
