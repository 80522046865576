import React from 'react';

import classes from './Select.module.scss';

interface Zone {
    id: string;
    name: string;
}

interface Props {
    zones: Zone[]
    setSelectedZone: (id: string) => void;
}

export function Select({
    zones,
    setSelectedZone,
}: Props): JSX.Element {
    return (
        <select
            className={classes.select}
            defaultValue="0"
            onChange={({ currentTarget }) => setSelectedZone(currentTarget.value)}
        >
            <option value="0" disabled>Выберите территориальную зону</option>
            {zones.map((item: Zone) => (
                <option value={item.id}>{item.name}</option>
            ))}
        </select>
    );
}
