import React, { useRef } from 'react';
import { IconDeprecated } from '@common';
import { Student } from '../../../../../Interfaces';
import classes from './FilterStudents.module.scss';

interface Props {
    students: Student[];
    search: string;
    handleSuggestion(foundSimilarities: Student[]): void;
    setSearch(searchValue: string): void;
}

export function FilterStudents({
    students,
    search,
    handleSuggestion,
    setSearch,
}: Props): JSX.Element {
    const inputEl = useRef<HTMLInputElement>(null);
    const refSearchInput = useRef<null | HTMLInputElement>(null);

    const scrollTo = () => {
        if (refSearchInput && refSearchInput.current) {
            refSearchInput.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    };

    const findResemblance = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const { value } = event.target;

        const filteredSuggestionsArray: Student[] = [...students]
            .sort((student) => {
                const { user } = student.student;
                const { lastName, firstName, patronymic } = user;
                const fullName = `${lastName} ${firstName} ${patronymic}`;

                return fullName.toLowerCase().includes(value.toLowerCase()) ? -1 : 1;
            });
        setSearch(value);
        handleSuggestion(filteredSuggestionsArray);

        if (!value) {
            handleSuggestion(filteredSuggestionsArray
                .sort((a, b) => {
                    if (a.student.user.lastName[0] > b.student.user.lastName[0]) {
                        return 1;
                    }
                    if (a.student.user.lastName[0] < b.student.user.lastName[0]) {
                        return -1;
                    }
                    return 0;
                }));
        }
    };

    return (
        <div className={classes.filterStudents__container}>
            <IconDeprecated
                id="magnifier"
                className={classes.schedulePage_eventsSection_searchImg}
            />
            <input
                value={search}
                type="text"
                onChange={(e) => findResemblance(e)}
                ref={inputEl}
                onFocus={scrollTo}
                className={classes.schedulePage_eventsSection_searchStudents}
                placeholder="Поиск студента"
            />
        </div>
    );
}
