import React, { useState } from 'react';
import { ModuleSelectionPage } from '@admin/ModuleSelectionPage/ModuleSelectionPage';
import { useQuery } from '@apollo/client';
import { Loader } from '@common/Loader';
import { observer } from 'mobx-react';
import { Alert } from '@common/Alert';
import { nanoid } from 'nanoid';

import { moduleSelectionDataStore } from '@admin/ModuleSelectionPage/ModuleSelectionDataStore';
import {
    GET_ALL_STUDENTS_RATING,
    GET_BTI_INSTANCE,
    GET_DISTRIB_STUDENTS_GROUPED_BY_MODULE,
    GET_FORK_UNDISTRIB_STUDENTS,
    GET_IS_VALID_STUDENTS_MODULE_DISTRIBUTION,
    GET_PREVIOUS_BTI,
    GET_SPACE_BTI,
} from '@admin/ModuleSelectionPage/ModuleSelectionQueries';
import { moduleSelectionStore } from '@admin/ModuleSelectionPage/ModuleSelectionStore';
import { useHistory } from 'react-router-dom';
import {
    BaseTimeIntervalInstance,
    BaseTimeIntervalInstanceVariables,
    DistributedStudentsGroupedByModule,
    DistributedStudentsGroupedByModuleVariables,
    getForkUndistributedStudents,
    GetIsValidStudentsModuleDistribution,
    GetIsValidStudentsModuleDistributionVariables,
    PreviousBaseTimeIntervalInstance,
    PreviousBaseTimeIntervalInstanceVariables,
    SelectionStatus,
    SpaceBaseTimeInterval,
    StudentsRatings,
    StudentsRatingsVariables,
} from './graphql-types';

export const ModuleSelectionPageApollo = observer((): JSX.Element => {
    const {
        baseTimeIntervalId,
        baseTimeIntervalInstanceId,
        forkId,
        isNotStudentChoice,
        spaceId,
    } = moduleSelectionDataStore.getURLParameters();

    const history = useHistory();

    const [
        queryErrors,
        setQueryErrors,
    ] = useState<JSX.Element[]>([]);
    const addError = (message: string) => setQueryErrors((arr) => [...arr, (<Alert
        key={nanoid()}
        message={message}
        time={7000}
    />)]);

    const {
        loading: isBTIInstanceLoading,
    } = useQuery<
    BaseTimeIntervalInstance,
    BaseTimeIntervalInstanceVariables
    >(GET_BTI_INSTANCE, {
        skip: !baseTimeIntervalInstanceId,
        variables: {
            id: baseTimeIntervalInstanceId,
        },
        onCompleted: (data) => {
            if (data.baseTimeIntervalInstance.selectionStatus
            !== SelectionStatus.moduleDistributionApprovalPending) {
                addError('Распределение по модулям закончилось');
                setTimeout(() => history.push(`/education-period/${spaceId}`), 4000);
                moduleSelectionStore.setIsAllDisabled(true);
            }
        },
        onError: (error) => addError(error.message),
    });

    const {
        refetch: refetchIsValidStudentsModuleDistribution,
    } = useQuery<
    GetIsValidStudentsModuleDistribution,
    GetIsValidStudentsModuleDistributionVariables
    >(GET_IS_VALID_STUDENTS_MODULE_DISTRIBUTION, {
        skip: !moduleSelectionDataStore.getURLParameters().baseTimeIntervalInstanceId,
        variables: {
            baseTimeIntervalInstanceId:
            moduleSelectionDataStore.getURLParameters().baseTimeIntervalInstanceId,
        },
        onCompleted: (data) => moduleSelectionDataStore
            .setIsModuleDistributionValid(data.isValidStudentsModuleDistribution),
        onError: (error) => addError(error.message),
    });

    const {
        loading: spaceBtiLoading,
    } = useQuery<SpaceBaseTimeInterval>(
        GET_SPACE_BTI,
        {
            skip: !baseTimeIntervalId,
            variables: {
                btiId: baseTimeIntervalId,
            },
            onCompleted: (data) => {
                moduleSelectionDataStore
                    .setForksFromSubspaceSpaceBaseTimeIntervalSubspaces(
                        data.spaceBaseTimeInterval.spaceBaseTimeIntervalSubspaces,
                    );
            },
            onError: (spaceBtiError) => addError(spaceBtiError.message),
        },
    );

    const {
        loading: undistribLoading,
    } = useQuery<getForkUndistributedStudents>(GET_FORK_UNDISTRIB_STUDENTS, {
        fetchPolicy: 'no-cache',
        skip: isNotStudentChoice || !forkId,
        variables: {
            ForkUndistributedStudentsInput: {
                baseTimeIntervalInstanceId,
                forkId,
            },
        },
        onCompleted: data => {
            moduleSelectionStore
                .setNotDistribStudentsWithAdditionalFieldsFromDefaultStudents(
                    data.forkUndistributedStudents.map((forkStudent) => forkStudent.student),
                );
            moduleSelectionDataStore
                .setAllStudentsIdsFromUndistributedStudents(
                    data.forkUndistributedStudents,
                );
        },
        onError: (error) => addError(error.message),
    });

    const {
        loading: moduleLoading,
    } = useQuery<
    DistributedStudentsGroupedByModule,
    DistributedStudentsGroupedByModuleVariables
    >(GET_DISTRIB_STUDENTS_GROUPED_BY_MODULE, {
        fetchPolicy: 'no-cache',
        skip: !baseTimeIntervalInstanceId || !moduleSelectionDataStore.getCurrentModuleIds(),
        variables: {
            DistributedStudentsGroupedByModuleInput: {
                baseTimeIntervalInstanceId,
                moduleIds: moduleSelectionDataStore.getCurrentModuleIds(),
            },
        },
        onCompleted: data => {
            moduleSelectionStore
                .setModuleGroupsWithAdditionalFieldsFromModuleGroups(
                    data.distributedStudentsGroupedByModule,
                );
            moduleSelectionDataStore.setAllStudentsIdsFromModuleGroups(
                data.distributedStudentsGroupedByModule,
            );
            refetchIsValidStudentsModuleDistribution();
        },
        onError: (error) => addError(error.message),
    });

    const {
        loading: previousBTIILoading,
    } = useQuery<
    PreviousBaseTimeIntervalInstance,
    PreviousBaseTimeIntervalInstanceVariables
    >(GET_PREVIOUS_BTI, {
        skip: !baseTimeIntervalInstanceId,
        variables: {
            baseTimeIntervalInstanceId,
        },
        onCompleted: data => {
            moduleSelectionDataStore.setPreviousBTIInstanceId(
                data.previousBaseTimeIntervalInstance?.id ?? '',
            );
        },
        onError: (error) => addError(error.message),
    });

    const {
        loading: isRatingLoading,
    } = useQuery<
    StudentsRatings,
    StudentsRatingsVariables
    >(GET_ALL_STUDENTS_RATING, {
        skip: !moduleSelectionDataStore.getPreviousBTIInstanceId()
            || !moduleSelectionDataStore.getAllStudentsIds().length,
        variables: {
            studentsRatingInput: {
                baseTimeIntervalInstanceId:
                    moduleSelectionDataStore.getPreviousBTIInstanceId() ?? null,
                studentIds: moduleSelectionDataStore.getAllStudentsIds() ?? [],
            },
        },
        onCompleted: data => moduleSelectionStore.setStudentsWithRatings(data.studentsRating),
        onError: error => addError(error.message),
    });

    const isLoading = undistribLoading
    || moduleLoading || previousBTIILoading || isRatingLoading
    || isBTIInstanceLoading;

    return (
        <>
            {
                // eslint-disable-next-line no-nested-ternary
                spaceBtiLoading ? (
                    <Loader />
                ) : (
                    <ModuleSelectionPage
                        isLoading={isLoading}
                    />
                )
            }
            {
                queryErrors
            }
        </>
    );
});
