import React, { useState, useEffect } from 'react';
import { useMutation } from '@apollo/client';
import UserView from '@store/UserView';
import { TeacherAssignmentPage } from './TeacherAssignmentPage';
import { UPDATE_NOTES } from './mutation';
import { filteredTeacherAssignmentInstances_filteredTeacherAssignmentInstances } from '../../TeacherAssignmentsApolloTypes';

interface Props {
    // eslint-disable-next-line max-len
    assignment?: filteredTeacherAssignmentInstances_filteredTeacherAssignmentInstances;
    refetchFilteredAssignments?: () => void;
    setAssignmentOpen?: (assignmentOpen: boolean) => void;
}

export function TeacherAssignmentPageApollo({
    assignment,
    setAssignmentOpen = () => { },
    refetchFilteredAssignments = () => { },
}: Props) {
    const [currentAssigment, setCurrentAssigment] = useState(assignment);

    const [updateNoteMutation] = useMutation(
        UPDATE_NOTES,
        {
            onCompleted: (res) => {
                setCurrentAssigment(
                    { ...res.updateTeacherAssignmentInstanceNote },
                );

                refetchFilteredAssignments();
            },
        },
    );

    useEffect(() => setCurrentAssigment(assignment), [assignment]);

    function updateNote(teacherNote: string) {
        updateNoteMutation(
            {
                variables: {
                    updateTeacherAssignmentInstanceNoteInput: {
                        teacherId: UserView.teacherId,
                        assignmentInstanceId: assignment?.assignmentInstance.id,
                        note: teacherNote,
                    },
                },
            },
        );
    }

    return (
        <TeacherAssignmentPage
            onClick={() => setAssignmentOpen(false)}
            assignment={currentAssigment}
            updateNote={updateNote}
        />
    );
}
