/* eslint-disable no-nested-ternary */
import React from 'react';
import { Link } from 'react-router-dom';

import { useUrlQuery } from '@common/hooks';
import classes from './WaveModuleStatistics.module.scss';
import {
    GetModuleStatistics_moduleWavesByFilters, GetModuleStatistics_moduleWavesByFilters_students,
} from '../graphql-types';
import { EvaluationPointResults } from '../ModuleStatisticsTypes';

interface WaveModuleStatisticsProps {
    isDetailedModeOn: boolean;
    wave: GetModuleStatistics_moduleWavesByFilters;
    evaluationPoints: EvaluationPointResults[];
}

export function WaveModuleStatistics({
    wave,
    evaluationPoints,
    isDetailedModeOn,
}: WaveModuleStatisticsProps) {
    return (
        <>
            <tr>
                <td
                    className={classes['module-statistics-report__wave-cell']}
                    colSpan={isDetailedModeOn
                        ? calcWaveRowColSpan(evaluationPoints)
                        : evaluationPoints?.length + 1
                    }
                >
                    {wave.index! + 1} поток
                </td>
            </tr>
            {wave.students.map(student => (
                <StudentModuleStatistics
                    key={student.id}
                    student={student}
                    isDetailedModeOn={isDetailedModeOn}
                    evaluationPoints={evaluationPoints}
                />
            ))}
        </>
    );
}

interface StudentModuleStatisticsProps {
    student: GetModuleStatistics_moduleWavesByFilters_students;
    isDetailedModeOn: boolean;
    evaluationPoints: EvaluationPointResults[];
}

function StudentModuleStatistics({
    student,
    isDetailedModeOn,
    evaluationPoints,
}: StudentModuleStatisticsProps) {
    return (
        <tr>
            <th className={classes['module-statistics-report__student-cell']}>
                <Link to={`/users/user/${student.user.id}`} target="_blank">
                    {`${student.user.lastName} ${student.user.firstName} ${student.user.patronymic}`}
                </Link>
            </th>
            {evaluationPoints.map(evaluationPoint => (
                <StudentEvaluationPontResult
                    key={`${evaluationPoint.id}-${student.id}`}
                    evaluationPoint={evaluationPoint}
                    isDetailedModeOn={isDetailedModeOn}
                    student={student}
                />
            ))}
        </tr>
    );
}

interface StudentEvaluationPontResultProps {
    student: GetModuleStatistics_moduleWavesByFilters_students;
    isDetailedModeOn: boolean;
    evaluationPoint: EvaluationPointResults;
}

function StudentEvaluationPontResult({
    student,
    isDetailedModeOn,
    evaluationPoint: { id, skills, studentResultsMap },
}: StudentEvaluationPontResultProps) {
    const { getUrlQuery } = useUrlQuery();
    const skillTypeId = getUrlQuery('skillTypeId');
    const studentEvaluationPoint = studentResultsMap.get(student.id);
    const studentSkillResults = studentEvaluationPoint?.skillResults;

    if (isDetailedModeOn) {
        const studentSkillsResultsMap = new Map(
            studentSkillResults?.map(result => [result.skillId, result]),
        );
        return (
            <>
                {skills?.length
                    ? skills.map(({ skill, level }) => (
                        <td
                            className={classes['module-statistics-report__result-cell']}
                            key={`${id}-${student.id}-${skill.id}`}
                        >
                            {studentSkillsResultsMap.get(skill.id)
                                ? `${studentSkillsResultsMap.get(skill.id)!.level} / ${level}`
                                : '—'
                            }
                        </td>
                    ))
                    : (
                        <td
                            className={classes['module-statistics-report__result-cell']}
                        />
                    )
                }
            </>
        );
    }

    if (!skills?.length) {
        return (
            <td className={classes['module-statistics-report__result-cell']} />
        );
    }

    if (!skillTypeId && studentEvaluationPoint) {
        const { isCredited, isRated } = studentEvaluationPoint;
        return (
            <td className={classes['module-statistics-report__result-cell']}>
                {isRated
                    ? isCredited ? 'Зачет' : 'Не зачет'
                    : 'Не оценен'
                }
            </td>
        );
    }

    if (!studentSkillResults?.length) {
        return (
            <td className={classes['module-statistics-report__result-cell']}>
                —
            </td>
        );
    }

    const sumSkillLevel = studentSkillResults.reduce(
        (acc, curr) => acc + Number(curr.level),
        0,
    );
    const maxSkillLevelSum = skills.reduce(
        (acc, curr) => acc + Number(curr.level),
        0,
    );

    const averageSkillLevelRatio = sumSkillLevel / studentSkillResults.length;
    const maxAverageLevelRatio = maxSkillLevelSum / skills.length;

    const averageSkillLevel = averageSkillLevelRatio - Math.floor(averageSkillLevelRatio) <= 0.5
        ? Math.floor(averageSkillLevelRatio)
        : Math.floor(averageSkillLevelRatio) + 1;
    const maxAverageLevel = maxAverageLevelRatio - Math.floor(maxAverageLevelRatio) <= 0.5
        ? Math.floor(maxAverageLevelRatio)
        : Math.floor(maxAverageLevelRatio) + 1;

    return (
        <td className={classes['module-statistics-report__result-cell']}>
            {averageSkillLevel}
            /
            {maxAverageLevel}
        </td>
    );
}

function calcWaveRowColSpan(evaluationPoints: EvaluationPointResults[]) {
    return evaluationPoints.reduce((colSpan, evaluationPoint) => {
        if (evaluationPoint.skills?.length) {
            return colSpan + evaluationPoint.skills.length;
        }
        return colSpan + 1;
    }, 1);
}
