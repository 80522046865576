import React from 'react';

interface Props {
    fill?: string;
    onClick?: () => void;
}

export function ProfileIcon({
    fill = '#000',
    onClick = () => { },
}: Props) {
    return (
        <svg width="18" height="18" onClick={onClick}>
            <path
                d="M3.6 15.6v.6h1.2v-.6H3.6Zm9.6 0v.6h1.2v-.6h-1.2Zm-8.4 0V15H3.6v.6h1.2Zm3-3.6h2.4v-1.2H7.8V12Zm5.4
                       3v.6h1.2V15h-1.2Zm-3-3a3 3 0 0 1 3 3h1.2a4.2 4.2 0 0 0-4.2-4.2V12Zm-5.4 3a3 3 0 0 1 3-3v-1.2A4.2 4.2 0 0 0
                       3.6 15h1.2ZM9 3.6a3 3 0 0 0-3 3h1.2A1.8 1.8 0 0 1 9 4.8V3.6Zm3 3a3 3 0 0 0-3-3v1.2a1.8 1.8 0 0 1 1.8
                       1.8H12Zm-3 3a3 3 0 0 0 3-3h-1.2A1.8 1.8 0 0 1 9 8.4v1.2Zm0-1.2a1.8 1.8 0 0 1-1.8-1.8H6a3 3 0 0 0 3
                       3V8.4Zm0 8.4A7.8 7.8 0 0 1 1.2 9H0a9 9 0 0 0 9 9v-1.2ZM16.8 9A7.801 7.801 0 0 1 9 16.8V18a9 9 0 0 0
                       9-9h-1.2ZM9 1.2A7.8 7.8 0 0 1 16.8 9H18a9 9 0 0 0-9-9v1.2ZM9 0a9 9 0 0 0-9 9h1.2A7.8 7.8 0 0 1 9 1.2V0Z"
                fill={fill}
            />
        </svg>
    );
}
