import React, { useRef } from 'react';
import { observer } from 'mobx-react';
import classnames from 'classnames';
import sortBy from 'lodash/sortBy';
import minBy from 'lodash/minBy';
import maxBy from 'lodash/maxBy';

import { EvaluationPoint } from '@admin/NewModule/EvaluationPoint';
import { Title, TitleTypes } from '@common';
import { EventWithoutEvaluation as EventWithoutTestType, moduleStore } from '@admin/NewModule/Store';
import { useParams } from 'react-router-dom';
import { EventWithoutTest } from './EventWithoutTest';
import { Arrows } from './Arrows';
import { ActionButton, State } from './ActionButton';
import { EventListTabs } from './EventListTabs';
import { Graph } from './Graph';

import classes from './EventsList.module.scss';

interface RouteParams {
    id: string
    eventId: string
}

export const EventsList = observer(() => {
    const { eventId } = useParams<RouteParams>();
    const [state, setState] = React.useState(State.Start);
    const [opened, setOpened] = React.useState(eventId || '');
    const [viewMode, setViewMode] = React.useState('list');
    const module = moduleStore.moduleModel;
    const sortedEvents = sortBy(module.events, 'order');
    const minOrder = minBy(module.eventsWithoutTest, 'order')?.order;
    const maxOrder = maxBy(module.eventsWithoutTest, 'order')?.order;

    const isPotentialLooping = (event: EventWithoutTestType): boolean => {
        const editableEvent = module.eventsWithoutTest.find(({ isEditable }) => isEditable);
        if (!editableEvent) return false;
        if (editableEvent.id === event.id) return false;
        const alreadyDependent = editableEvent.dependentEvents.some(({ id }) => id === event.id);
        if (alreadyDependent) return false;

        const hasLooping = module.hasLoopingEventDependencies(
            editableEvent.id, event.id,
        );

        return hasLooping;
    };

    const eventListRef = useRef<null | HTMLDivElement>(null);
    const eventRefs: { ref: React.MutableRefObject<HTMLDivElement | null>, id: string }[] = [];
    const setEventRef = ({ ref, id }: {
        ref: React.MutableRefObject<HTMLDivElement | null>,
        id: string,
    }) => eventRefs.push({ ref, id });

    return (
        <>
            {module.events.length > 0 && (
                <div className={classes.eventsList__header}>
                    <div className={classes.eventsList__title}>
                        <Title type={TitleTypes.Title}>Содержание модуля:</Title>
                    </div>
                    <EventListTabs
                        viewMode={viewMode}
                        setViewMode={setViewMode}
                    />
                </div>
            )}
            {viewMode === 'list' && (
                <>
                    <div className={classes.eventsList__container}>
                        <div className={classes.eventsList__mainBlock} ref={eventListRef}>
                            {sortedEvents.map(event => {
                                if (moduleStore.isMeeting(event)
                                    || moduleStore.isAssignment(event)) {
                                    if (state === State.EditEventConnections) {
                                        const isLooping = isPotentialLooping(event);
                                        return Object.assign(event, { canBeChosen: !isLooping });
                                    }
                                }
                                return Object.assign(event, { canBeChosen: true });
                            }).map(event => {
                                if (moduleStore.isMeeting(event)
                                    || moduleStore.isAssignment(event)) {
                                    return (
                                        <EventWithoutTest
                                            key={event.id}
                                            event={event}
                                            canUp={event.order !== minOrder}
                                            canDown={event.order !== maxOrder}
                                            canBeChosen={event.canBeChosen}
                                            state={state}
                                            opened={opened}
                                            setState={setState}
                                            setEventRef={setEventRef}
                                            setOpened={setOpened}
                                        />
                                    );
                                }

                                if (moduleStore.isEvaluationPoint(event)) {
                                    const { id } = event;
                                    const transparentStyle = classes.eventsList__event_transparent;
                                    return (
                                        <div
                                            key={id}
                                            className={classnames(classes.eventsList__event, {
                                                [transparentStyle]:
                                                    state === State.EditEventConnections,
                                            })}
                                        >
                                            <EvaluationPoint
                                                id={id}
                                                setEventRef={setEventRef}
                                                opened={opened === id}
                                                setOpened={setOpened}
                                            />
                                        </div>
                                    );
                                }
                                return null;
                            })}
                        </div>
                        <Arrows eventListRef={eventListRef} eventRefs={eventRefs} opened={opened} />
                    </div>
                    <ActionButton
                        state={state}
                        setState={setState}
                        setOpened={setOpened}
                    />
                </>
            )}
            {viewMode === 'graph' && (
                <Graph />
            )}
        </>
    );
});
