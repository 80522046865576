import React from 'react';
import { useQuery } from '@apollo/client';
import { Loader } from '@common/Loader';
import { GET_ADMIN_ROLES } from '@common/qraphql/queries';

import { AdminInfo } from './AdminInfo';

interface Props {
    id: string;
    edit: boolean;
}

export const AdminInfoApollo = ({ id: userId, edit }: Props): JSX.Element => {
    const { error, loading, data } = useQuery(GET_ADMIN_ROLES, {
        fetchPolicy: 'cache-and-network',
        variables: { userId },
    });
    if (loading) return <Loader />;
    if (error) return <>`Error! ${error.message}`</>;
    return (
        <AdminInfo adminRoles={data.adminRoles} edit={edit} />
    );
};
