import React from 'react';

import cn from 'classnames';
import classes from './ModuleTabs.module.scss';

interface Props {
    activeTabs: string;
    setActiveTabs(tab: string): void;
}

export function ModuleTabs({ activeTabs, setActiveTabs }: Props):JSX.Element {
    return (
        <div className={classes.moduleTabs}>
            <ul className={classes.moduleTabs__list}>
                <li className={cn(classes.moduleTabs__item, {
                    [classes.moduleTabs__item_active]: activeTabs === 'first',
                })}
                >
                    <div
                        className={classes.moduleTabs__text}
                        onClick={() => setActiveTabs('first')}
                    >
                        Основные характеристики
                    </div>
                </li>
                <li className={cn(classes.moduleTabs__item, {
                    [classes.moduleTabs__item_active]: activeTabs === 'second',
                })}
                >
                    <div
                        className={classes.moduleTabs__text}
                        onClick={() => setActiveTabs('second')}
                    >
                        Образовательные результаты
                    </div>
                </li>
                <li className={cn(classes.moduleTabs__item, {
                    [classes.moduleTabs__item_active]: activeTabs === 'third',
                })}
                >
                    <div
                        className={classes.moduleTabs__text}
                        onClick={() => setActiveTabs('third')}
                    >
                        Содержание модуля
                    </div>
                </li>
            </ul>
        </div>
    );
}
