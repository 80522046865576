import React from 'react';
import cn from 'classnames';

import styles from './Input.module.scss';

interface Props {
    name?: string;
    value?: string | number;
    label?: string;
    placeholder?: string;
    type?: InputTypes;
    labelModifier?: string;

    onEnter?(): void;
    onChange?(event: any): void;
}

export enum InputTypes {
    Input = 'input',
    TextArea = 'textarea',
    Number = 'number',
}

/**
 * @param type          -- Possible options: 'input', 'number', 'textarea', Default option: 'input'
 * @param labelModifier -- Possible options: 'bold', Default option: ''
 */

export const Input: React.FC<Props> = ({
    name,
    value,
    label,
    placeholder,
    type = InputTypes.Input,
    labelModifier = '',
    onChange = () => {},
    onEnter = () => {},
}: Props) => {
    const inputClass = cn(styles.input, [styles[`${type}-input`]]);
    const blockClass = cn(styles.container, [styles[`${type}-container`]]);
    const labelClass = cn(styles.label, [styles[`${type}-label`]], styles[`${labelModifier}`]);

    return (
        <div className={blockClass}>
            {label && (
                <label htmlFor={name} className={labelClass}>
                    {label}
                </label>
            )}
            <input
                className={inputClass}
                name={name}
                value={value}
                type={type === InputTypes.Number ? 'number' : 'input'}
                placeholder={placeholder}
                onChange={onChange}
                min={0}
                autoComplete="off"
                onKeyDown={e => {
                    if (e.key === 'Enter') {
                        onEnter();
                    }
                }}
            />
        </div>
    );
};
