import { action, makeObservable, observable } from 'mobx';
import { SyntheticEvent } from 'react';
import { nanoid } from 'nanoid';
import filter from 'lodash/filter';
import map from 'lodash/map';
import find from 'lodash/find';

import forEach from 'lodash/forEach';
import { flatten, uniqBy } from 'lodash';
import {
    BTISettings,
    DiagramEntityCoordinate,
    DiagramEntityRelationSettings,
    DiagramSettings,
    DistributionType,
    EntityType,
    Fork,
    ForkSettingFormInput,
    ForkSettings,
    GroupSlot,
    GroupSlotSettings,
    Module,
    ModuleSlot,
    SlotSettings,
    SubspaceSkill,
} from '../subSpaceTypes';

import {
    checkOutAdditionalEntitiesOnTheBottom,
    checkOutAdditionalEntitiesOnTheLeft,
    checkOutAdditionalEntitiesOnTheRight,
    checkOutAdditionalEntitiesOnTheTop,
    createArrayFromDiagramEntities,
    findFork,
} from '../SubSpaceSlotDiagram/utilities';

class Diagram {
    currentBTIId: string = '';

    diagramSettings: DiagramSettings = {
        editMode: false,
        createSlotMode: false,
        createGroupMode: false,
        createForkMode: false,
        relationDiagramEntityMode: false,
        saveRelationDiagramEntityMode: false,
        removeDiagramEntityMode: false,
        showSlotParametersMode: false,
        showSuitableModules: false,
        showForkDialog: false,
        editSlotParametersMode: false,
        rowsRange: [0, 1, 2, 3],
        columnsRange: [0, 1, 2, 3],
    };

    btiSettings: BTISettings[] = [];

    slotSettings: SlotSettings = {
        id: '',
        spaceBaseTimeIntervalId: '',
        isSlotCanMove: false,
        moveSlotTo: undefined,
        hasModule: false,
        hasPrerequisiteSkills: false,
        hasOutputSkills: false,
    };

    groupSlotSettings: GroupSlotSettings = {
        id: '',
        spaceBaseTimeIntervalId: '',
        isGroupSlotCanMove: false,
        moveGroupSlotTo: undefined,
    };

    forkSettings: ForkSettings = {
        id: '',
        spaceBaseTimeIntervalId: '',
        isForkCanMove: false,
        moveForkTo: undefined,
    };

    diagramEntityRelationSettings: DiagramEntityRelationSettings = {};

    spaceBaseTimeIntervalId: string = '';

    forkSettingForm: ForkSettingFormInput = {
        distributionType: DistributionType.AUTOMATICALLY,
        moduleSelectionCount: 1,
        studentPriorityCount: 1,
        studentPickingType: 'randomly',
        transitionalUnderfilledModule: false,
        transitionalEmptyModule: false,
        overbooking: false,
        automaticallyDistributeRemainingStudents: true,
    };

    constructor() {
        makeObservable(this, {
            currentBTIId: observable,
            diagramSettings: observable,
            btiSettings: observable,
            slotSettings: observable,
            groupSlotSettings: observable,
            forkSettings: observable,
            diagramEntityRelationSettings: observable,
            forkSettingForm: observable,
            updateSlotSkills: action,
            setDiagramEditMode: action,
            setCreateSlotMode: action,
            setCreateGroupMode: action,
            setCreateForkMode: action,
            setRelationEntityMode: action,
            setRemoveDiagramEntityMode: action,
            setSlotSettings: action,
            setForkSettings: action,
            setEditSlotParametersMode: action,
            setShowParametersSlotMode: action,
            setShowSuitableModules: action,
            setShowForkDialog: action,
            setDiagramEntityRelationSettings: action,
            saveRelatedBtiEntities: action,
            saveRelatedDiagramEntities: action,
            createSlot: action,
            createGroupSlot: action,
            createFork: action,
            removeEntity: action,
            removeEntityRelation: action,
            updateModule: action,
            setBtiSettings: action,
            setSlotCantMove: action,
            setGroupSlotCantMove: action,
            setGroupSlotCanMove: action,
            setForkCantMove: action,
            changeRowAndColumnOfNextEntities: action,
            changeRowAndColumnOfOneOfEntities: action,
            changeRowAndColumnOfPreviousEntity: action,
            setForkCanMove: action,
            setSlotCanMove: action,
            moveEntityTo: action,
            addColumnLeft: action,
            addColumnRight: action,
            addRowTop: action,
            addRowBottom: action,
            addAdditionalBtiRowsAndColumns: action,
            addAdditionalBtiLeftColumn: action,
            setDistributionType: action,
            initForkSettingForm: action,
            setForkSettingsForm: action,
            saveForkToBtiSettings: action,
        });
    }

    updateSlotSkills(
        slotId: string,
        prerequisiteSkills: SubspaceSkill[],
        outputSkills: SubspaceSkill[],
    ): void {
        this.btiSettings = this.btiSettings.map(bti => ({
            ...bti,
            moduleSlots: bti.moduleSlots.map(slot => (slot.id === slotId
                ? {
                    ...slot,
                    prerequisiteSkills: prerequisiteSkills && prerequisiteSkills.map(skill => ({
                        skill: {
                            id: skill.skill.id,
                            name: skill.skill.name,
                            typeId: skill.skill.typeId,
                            fullName: skill.skill.fullName,
                        },
                        level: skill.level,
                    })),
                    outputSkills: outputSkills && outputSkills.map(skill => ({
                        skill: {
                            id: skill.skill.id,
                            name: skill.skill.name,
                            typeId: skill.skill.typeId,
                            fullName: skill.skill.fullName,
                        },
                        level: skill.level,
                    })),
                }
                : slot)),
        }));
    }

    setDiagramEditMode(): void {
        this.diagramSettings = {
            ...this.diagramSettings,
            editMode: !this.diagramSettings.editMode,
            createSlotMode: false,
            createGroupMode: false,
            createForkMode: false,
            relationDiagramEntityMode: false,
            removeDiagramEntityMode: false,
            saveRelationDiagramEntityMode: false,
            showSlotParametersMode: false,
        };

        this.slotSettings = {
            id: '',
            isSlotCanMove: false,
            spaceBaseTimeIntervalId: '',
            moveSlotTo: undefined,
            hasModule: false,
        };

        this.groupSlotSettings = {
            id: '',
            isGroupSlotCanMove: false,
            spaceBaseTimeIntervalId: '',
            moveGroupSlotTo: undefined,
        };
    }

    setCreateSlotMode(): void {
        this.diagramSettings = {
            ...this.diagramSettings,
            createSlotMode: !this.diagramSettings.createSlotMode,
            createGroupMode: false,
            createForkMode: false,
            relationDiagramEntityMode: false,
            saveRelationDiagramEntityMode: false,
            removeDiagramEntityMode: false,
            showSlotParametersMode: false,
        };

        this.diagramEntityRelationSettings = {};
    }

    setCreateGroupMode(): void {
        this.diagramSettings = {
            ...this.diagramSettings,
            createSlotMode: false,
            createGroupMode: !this.diagramSettings.createGroupMode,
            createForkMode: false,
            relationDiagramEntityMode: false,
            saveRelationDiagramEntityMode: false,
            removeDiagramEntityMode: false,
            showSlotParametersMode: false,
        };

        this.diagramEntityRelationSettings = {};
    }

    setCreateForkMode(): void {
        this.diagramSettings = {
            ...this.diagramSettings,
            createSlotMode: false,
            createGroupMode: false,
            createForkMode: !this.diagramSettings.createForkMode,
            relationDiagramEntityMode: false,
            saveRelationDiagramEntityMode: false,
            removeDiagramEntityMode: false,
            showSlotParametersMode: false,
        };

        this.diagramEntityRelationSettings = {};
    }

    setRelationEntityMode(): void {
        this.diagramSettings = {
            ...this.diagramSettings,
            createSlotMode: false,
            createGroupMode: false,
            createForkMode: false,
            relationDiagramEntityMode: !this.diagramSettings.relationDiagramEntityMode,
            removeDiagramEntityMode: false,
            showSlotParametersMode: false,
        };

        this.diagramEntityRelationSettings = {};

        this.slotSettings = {
            id: '',
            spaceBaseTimeIntervalId: '',
            isSlotCanMove: false,
            moveSlotTo: undefined,
            hasModule: false,
            hasPrerequisiteSkills: false,
            hasOutputSkills: false,
        };

        this.groupSlotSettings = {
            id: '',
            spaceBaseTimeIntervalId: '',
            isGroupSlotCanMove: false,
            moveGroupSlotTo: undefined,
        };

        this.forkSettings = {
            id: '',
            spaceBaseTimeIntervalId: '',
            isForkCanMove: false,
            moveForkTo: undefined,
        };
    }

    setRemoveDiagramEntityMode(): void {
        this.diagramSettings = {
            ...this.diagramSettings,
            createSlotMode: false,
            createGroupMode: false,
            createForkMode: false,
            relationDiagramEntityMode: false,
            removeDiagramEntityMode: !this.diagramSettings.removeDiagramEntityMode,
            showSlotParametersMode: false,
            saveRelationDiagramEntityMode: false,
        };
    }

    setShowParametersSlotMode(): void {
        this.diagramSettings = {
            ...this.diagramSettings,
            showSlotParametersMode: true,
            showSuitableModules: false,
            editSlotParametersMode: false,
        };
    }

    setEditSlotParametersMode(): void {
        this.diagramSettings = {
            ...this.diagramSettings,
            showSuitableModules: false,
            editSlotParametersMode: true,
        };
    }

    setSlotSettings({
        id,
        spaceBaseTimeIntervalId,
        hasModule,
        hasPrerequisiteSkills,
        hasOutputSkills,
    }: SlotSettings): void {
        this.slotSettings = {
            ...this.slotSettings,
            id,
            spaceBaseTimeIntervalId,
            hasModule,
            hasPrerequisiteSkills,
            hasOutputSkills,
        };
    }

    setForkSettings({
        id, spaceBaseTimeIntervalId,
    }: ForkSettings): void {
        this.forkSettings = {
            ...this.forkSettings,
            id,
            spaceBaseTimeIntervalId,
        };
    }

    setShowSuitableModules(): void {
        this.diagramSettings = {
            ...this.diagramSettings,
            showSlotParametersMode: false,
            showSuitableModules: true,
            showForkDialog: false,
        };
    }

    setShowForkDialog(): void {
        this.diagramSettings = {
            ...this.diagramSettings,
            showSlotParametersMode: false,
            showSuitableModules: false,
            showForkDialog: !this.diagramSettings.showForkDialog,
        };

        this.forkSettingForm = {
            distributionType: DistributionType.AUTOMATICALLY,
            moduleSelectionCount: 1,
            studentPriorityCount: 1,
            studentPickingType: 'randomly',
            transitionalUnderfilledModule: false,
            transitionalEmptyModule: false,
            overbooking: false,
            automaticallyDistributeRemainingStudents: true,
        };
    }

    setDiagramEntityRelationSettings(
        id: string,
        spaceBaseTimeIntervalId: string,
        entityType: EntityType,
    ) {
        if (
            (
                this.diagramEntityRelationSettings.entityType === 'GroupSlot'
                || this.diagramEntityRelationSettings.entityType === 'Fork'
            )
            && (
                this.diagramEntityRelationSettings.spaceBaseTimeIntervalId
                === spaceBaseTimeIntervalId
            )
        ) {
            this.diagramEntityRelationSettings = {
                ...this.diagramEntityRelationSettings,
                nextEntityId: undefined,
            };
        }

        if (this.diagramEntityRelationSettings.spaceBaseTimeIntervalId) {
            this.diagramEntityRelationSettings = {
                ...this.diagramEntityRelationSettings,
                previousEntityId: this.diagramEntityRelationSettings.previousEntityId || id,
                nextEntityId: this.diagramEntityRelationSettings.previousEntityId === undefined
                    ? undefined
                    : id,
            };
        }

        if (!this.diagramEntityRelationSettings.spaceBaseTimeIntervalId) {
            this.diagramEntityRelationSettings = {
                ...this.diagramEntityRelationSettings,
                spaceBaseTimeIntervalId,
                previousEntityId: this.diagramEntityRelationSettings.previousEntityId || id,
                nextEntityId: this.diagramEntityRelationSettings.previousEntityId === undefined
                    ? undefined
                    : id,
                entityType,
            };
        }

        if (
            this.diagramEntityRelationSettings.previousEntityId
            && this.diagramEntityRelationSettings.nextEntityId
        ) {
            this.diagramSettings = {
                ...this.diagramSettings,
                saveRelationDiagramEntityMode: true,
            };
        }
    }

    saveRelatedBtiEntities<T extends ModuleSlot | GroupSlot | Fork>(
        entities: T[],
        previousEntityId?: string,
        nextEntityId?: string,
    ): T[] {
        const slots = flatten(map(this.btiSettings, bti => (bti.moduleSlots))) || [];
        const groups = flatten(map(this.btiSettings, bti => (bti.groupSlots))) || [];
        const forks = flatten(map(this.btiSettings, bti => (bti.forks))) || [];

        const targetSlot: ModuleSlot | undefined = find(slots, { id: nextEntityId });
        const targetGroup: GroupSlot | undefined = find(groups, { id: nextEntityId });
        const targetFork: Fork | undefined = find(forks, { id: nextEntityId });

        return entities.map(entity => (
            entity.id === previousEntityId
                ? {
                    ...entity,
                    nextSlots: targetSlot ? uniqBy([
                        ...entity.nextSlots,
                        targetSlot,
                    ], 'id')
                        : entity.nextSlots,
                    nextGroupSlots: targetGroup ? uniqBy([
                        ...entity.nextGroupSlots,
                        targetGroup,
                    ], 'id')
                        : entity.nextGroupSlots,
                    nextForks: targetFork ? uniqBy([
                        ...entity.nextForks,
                        targetFork,
                    ], 'id')
                        : entity.nextForks,
                }
                : entity
        ));
    }

    saveRelatedDiagramEntities(): void {
        const {
            spaceBaseTimeIntervalId,
            previousEntityId,
            nextEntityId,
        } = this.diagramEntityRelationSettings;

        this.btiSettings = map(this.btiSettings, bti => {
            if (bti.id === spaceBaseTimeIntervalId) {
                return {
                    ...bti,
                    moduleSlots: this.saveRelatedBtiEntities<ModuleSlot>(
                        bti.moduleSlots,
                        previousEntityId,
                        nextEntityId,
                    ),
                    groupSlots: this.saveRelatedBtiEntities<GroupSlot>(
                        bti.groupSlots,
                        previousEntityId,
                        nextEntityId,
                    ),
                    forks: this.saveRelatedBtiEntities<Fork>(
                        bti.forks,
                        previousEntityId,
                        nextEntityId,
                    ),
                };
            }
            return bti;
        });

        this.diagramEntityRelationSettings = {};

        this.diagramSettings = {
            ...this.diagramSettings,
            relationDiagramEntityMode: false,
            saveRelationDiagramEntityMode: false,
        };
    }

    filterBtiEntities<T extends ModuleSlot | GroupSlot | Fork>(
        diagramEntities: T[] = [],
        id: string,
    ): T[] {
        return diagramEntities.map(diagramEntity => (diagramEntity.id !== id
            ? {
                ...diagramEntity,
                nextSlots: diagramEntity.nextSlots
                    && diagramEntity.nextSlots.filter(nextSlot => nextSlot.id !== id),
                nextGroupSlots: diagramEntity.nextGroupSlots
                    && diagramEntity.nextGroupSlots.filter(
                        nextGroupSlot => nextGroupSlot.id !== id,
                    ),
                nextForks: diagramEntity.nextForks
                    && diagramEntity.nextForks.filter(nextFork => nextFork.id !== id),
            }
            : diagramEntity));
    }

    removeEntity(id: string, spaceBaseTimeIntervalId: string): void {
        this.btiSettings = map(this.btiSettings, bti => {
            if (bti.id === spaceBaseTimeIntervalId) {
                return {
                    ...bti,
                    moduleSlots: filter(bti.moduleSlots, slot => slot.id !== id),
                    groupSlots: filter(bti.groupSlots, groupSlot => groupSlot.id !== id),
                    forks: filter(bti.forks, fork => fork.id !== id),
                };
            }
            return bti;
        });

        this.btiSettings = map(this.btiSettings, bti => ({
            ...bti,
            moduleSlots: this.filterBtiEntities<ModuleSlot>(bti.moduleSlots, id),
            groupSlots: this.filterBtiEntities<GroupSlot>(bti.groupSlots, id),
            forks: this.filterBtiEntities<Fork>(bti.forks, id),
        }));
    }

    removeNextEntityRelations<T extends ModuleSlot | GroupSlot | Fork>(
        entities: T[],
        previousSlotId: string,
        nextSlotId: string,
    ): T[] {
        return entities.map(entity => (entity.id === previousSlotId
            ? {
                ...entity,
                nextSlots: entity.nextSlots.filter(
                    nextSlot => nextSlot.id !== nextSlotId,
                ),
                nextGroupSlots: entity.nextGroupSlots.filter(
                    nextGroupSlot => nextGroupSlot.id !== nextSlotId,
                ),
                nextForks: entity.nextForks.filter(
                    nextFork => nextFork.id !== nextSlotId,
                ),
            }
            : entity));
    }

    removeEntityRelation(id: string) {
        const [previousSlotId, nextSlotId] = id.split('&&');

        this.btiSettings = this.btiSettings.map(bti => ({
            ...bti,
            moduleSlots: this.removeNextEntityRelations<ModuleSlot>(
                bti.moduleSlots,
                previousSlotId,
                nextSlotId,
            ),
            groupSlots: this.removeNextEntityRelations<GroupSlot>(
                bti.groupSlots,
                previousSlotId,
                nextSlotId,
            ),
            forks: this.removeNextEntityRelations<Fork>(
                bti.forks,
                previousSlotId,
                nextSlotId,
            ),
        }));
    }

    updateModule(slotId: string, module: Module) {
        this.btiSettings = this.btiSettings.map(bti => ({
            ...bti,
            moduleSlots: bti.moduleSlots.map(slot => (slot.id === slotId
                ? {
                    ...slot,
                    module,
                }
                : slot)),
        }));
    }

    setBtiSettings(btiSettings: BTISettings[]) {
        this.btiSettings = [...btiSettings];
    }

    setSlotCantMove(): void {
        this.slotSettings = {
            ...this.slotSettings,
            isSlotCanMove: false,
            moveSlotTo: undefined,
        };
    }

    setGroupSlotCantMove(): void {
        this.groupSlotSettings = {
            ...this.groupSlotSettings,
            isGroupSlotCanMove: false,
            moveGroupSlotTo: undefined,
        };
    }

    setSlotCanMove(event: SyntheticEvent, { id, spaceBaseTimeIntervalId }: SlotSettings): void {
        event.stopPropagation();
        this.slotSettings = {
            ...this.slotSettings,
            id,
            spaceBaseTimeIntervalId,
            isSlotCanMove: !this.slotSettings.isSlotCanMove,
        };
    }

    setGroupSlotCanMove(
        event: SyntheticEvent,
        { id, spaceBaseTimeIntervalId }: GroupSlotSettings,
    ): void {
        event.stopPropagation();
        this.groupSlotSettings = {
            ...this.groupSlotSettings,
            id,
            spaceBaseTimeIntervalId,
            isGroupSlotCanMove: !this.groupSlotSettings.isGroupSlotCanMove,
        };
    }

    setForkCanMove(
        event: SyntheticEvent,
        { id, spaceBaseTimeIntervalId }: ForkSettings,
    ): void {
        event.stopPropagation();
        this.forkSettings = {
            ...this.groupSlotSettings,
            id,
            spaceBaseTimeIntervalId,
            isForkCanMove: !this.forkSettings.isForkCanMove,
        };
    }

    setForkCantMove(): void {
        this.forkSettings = {
            ...this.groupSlotSettings,
            isForkCanMove: false,
            moveForkTo: undefined,
        };
    }

    changeRowAndColumnOfNextEntities<T extends ModuleSlot | GroupSlot | Fork>(
        entityId: string,
        nextEntities: T[] = [],
        newCoordinates: DiagramEntityCoordinate,
    ): T[] {
        const [row, column] = newCoordinates;

        return nextEntities.map(nextEntity => (nextEntity.id === entityId ? {
            ...nextEntity,
            row,
            column,
        }
            : nextEntity));
    }

    changeRowAndColumnOfOneOfEntities<T extends ModuleSlot | GroupSlot | Fork>(
        entityId: string,
        diagramEntities: T[] = [],
        newCoordinates: DiagramEntityCoordinate,
    ): T[] {
        return diagramEntities.map(diagramEntity => ({
            ...diagramEntity,
            nextSlots: this.changeRowAndColumnOfNextEntities<ModuleSlot>(
                entityId,
                diagramEntity.nextSlots,
                newCoordinates,
            ),
            nextGroupSlots: this.changeRowAndColumnOfNextEntities<GroupSlot>(
                entityId,
                diagramEntity.nextGroupSlots,
                newCoordinates,
            ),
            nextForks: this.changeRowAndColumnOfNextEntities<Fork>(
                entityId,
                diagramEntity.nextForks,
                newCoordinates,
            ),
        }));
    }

    changeRowAndColumnOfPreviousEntity(entityId: string, newCoordinates: DiagramEntityCoordinate) {
        this.btiSettings = this.btiSettings.map(bti => ({
            ...bti,
            moduleSlots: this.changeRowAndColumnOfOneOfEntities<ModuleSlot>(
                entityId,
                bti.moduleSlots,
                newCoordinates,
            ),
            groupSlots: this.changeRowAndColumnOfOneOfEntities<GroupSlot>(
                entityId,
                bti.groupSlots,
                newCoordinates,
            ),
            forks: this.changeRowAndColumnOfOneOfEntities<Fork>(
                entityId,
                bti.forks,
                newCoordinates,
            ),
        }));
    }

    changeTargetEntityToNewCoordinates<T extends ModuleSlot | GroupSlot | Fork>(
        diagramEntities: T[],
        targetEntityId: string,
        newCoordinates: DiagramEntityCoordinate,
    ): T[] {
        const [row, column] = newCoordinates;

        return diagramEntities.map(diagramEntity => (
            diagramEntity.id === targetEntityId
                ? {
                    ...diagramEntity,
                    row,
                    column,
                }
                : diagramEntity
        ));
    }

    moveEntityTo(
        selectedEmptySlotCoordinates: [number, number],
        baseTimeIntervalId: string,
    ) {
        if (this.slotSettings.isSlotCanMove && this.diagramSettings.editMode) {
            this.slotSettings = {
                ...this.slotSettings,
                moveSlotTo: selectedEmptySlotCoordinates,
                spaceBaseTimeIntervalId: baseTimeIntervalId,
            };

            const { id } = this.slotSettings;

            if (id) {
                this.btiSettings = this.btiSettings.map(bti => (
                    bti.id === baseTimeIntervalId
                        ? {
                            ...bti,
                            moduleSlots: this.changeTargetEntityToNewCoordinates<ModuleSlot>(
                                bti.moduleSlots,
                                id,
                                selectedEmptySlotCoordinates,
                            ),
                        }
                        : bti
                ));

                this.changeRowAndColumnOfPreviousEntity(
                    id,
                    selectedEmptySlotCoordinates,
                );
            }
        }

        if (this.forkSettings.isForkCanMove && this.diagramSettings.editMode) {
            this.forkSettings = {
                ...this.forkSettings,
                moveForkTo: selectedEmptySlotCoordinates,
                spaceBaseTimeIntervalId: baseTimeIntervalId,
            };

            const { id } = this.forkSettings;

            if (id) {
                this.btiSettings = this.btiSettings.map(bti => (
                    bti.id === baseTimeIntervalId
                        ? {
                            ...bti,
                            forks: this.changeTargetEntityToNewCoordinates<Fork>(
                                bti.forks,
                                id,
                                selectedEmptySlotCoordinates,
                            ),
                        }
                        : bti
                ));

                this.changeRowAndColumnOfPreviousEntity(
                    id,
                    selectedEmptySlotCoordinates,
                );
            }
        }

        if (this.groupSlotSettings.isGroupSlotCanMove && this.diagramSettings.editMode) {
            this.groupSlotSettings = {
                ...this.groupSlotSettings,
                moveGroupSlotTo: selectedEmptySlotCoordinates,
                spaceBaseTimeIntervalId: baseTimeIntervalId,
            };

            const { id } = this.groupSlotSettings;

            if (id) {
                this.btiSettings = this.btiSettings.map(bti => (
                    bti.id === baseTimeIntervalId
                        ? {
                            ...bti,
                            groupSlots: this.changeTargetEntityToNewCoordinates<GroupSlot>(
                                bti.groupSlots,
                                id,
                                selectedEmptySlotCoordinates,
                            ),
                        }
                        : bti
                ));

                this.changeRowAndColumnOfPreviousEntity(
                    id,
                    selectedEmptySlotCoordinates,
                );
            }
        }
    }

    createSlot(
        selectedEmptySlotCoordinates: [number, number],
        spaceBaseTimeIntervalId: string,
    ): void {
        const [row, column] = selectedEmptySlotCoordinates;

        this.btiSettings = map(this.btiSettings, bti => {
            if (bti.id === spaceBaseTimeIntervalId) {
                return {
                    ...bti,
                    moduleSlots: [...bti.moduleSlots, {
                        ...bti.moduleSlots,
                        id: nanoid(),
                        spaceBaseTimeIntervalId,
                        row,
                        column,
                        prerequisiteSkills: [],
                        outputSkills: [],
                        nextSlots: [],
                        module: undefined,
                        nextForks: [],
                        nextGroupSlots: [],
                    }],
                };
            }
            return bti;
        });
    }

    createGroupSlot(
        selectedEmptySlotCoordinates: [number, number],
        spaceBaseTimeIntervalId: string,
    ): void {
        const [row, column] = selectedEmptySlotCoordinates;

        this.btiSettings = map(this.btiSettings, bti => {
            if (bti.id === spaceBaseTimeIntervalId) {
                return {
                    ...bti,
                    groupSlots: [...bti.groupSlots, {
                        ...bti.groupSlots,
                        id: nanoid(),
                        spaceBaseTimeIntervalId,
                        row,
                        column,
                        nextSlots: [],
                        nextForks: [],
                        nextGroupSlots: [],
                    }],
                };
            }
            return bti;
        });
    }

    createFork(
        selectedEmptySlotCoordinates: [number, number],
        spaceBaseTimeIntervalId: string,
    ): void {
        const [row, column] = selectedEmptySlotCoordinates;

        this.btiSettings = map(this.btiSettings, bti => {
            if (bti.id === spaceBaseTimeIntervalId) {
                return {
                    ...bti,
                    forks: [...bti.forks, {
                        ...bti.groupSlots,
                        id: nanoid(),
                        spaceBaseTimeIntervalId,
                        row,
                        column,
                        setting: null,
                        nextSlots: [],
                        nextForks: [],
                        nextGroupSlots: [],
                    }],
                };
            }
            return bti;
        });
    }

    addColumnLeft(btiId: string): void {
        this.btiSettings = map(this.btiSettings, bti => {
            if (bti.id === btiId) {
                const firstElementOfRange: number = bti.rowsRange[0];
                const newFirstNumberOfRange: number = firstElementOfRange - 1;
                return {
                    ...bti,
                    rowsRange: [newFirstNumberOfRange, ...bti.rowsRange],
                };
            }
            return bti;
        });
    }

    addColumnRight(btiId: string): void {
        this.btiSettings = map(this.btiSettings, bti => {
            if (bti.id === btiId) {
                const indexOfLastElementOfRange = bti.rowsRange.length - 1;
                const lastElementOfRange: number = bti.rowsRange[
                    indexOfLastElementOfRange];
                const newLastNumberOfRange: number = lastElementOfRange + 1;

                return {
                    ...bti,
                    rowsRange: [...bti.rowsRange, newLastNumberOfRange],
                };
            }
            return bti;
        });
    }

    addRowTop(btiId: string): void {
        this.btiSettings = map(this.btiSettings, bti => {
            if (bti.id === btiId) {
                const firstElementOfRange: number = bti.columnsRange[0];
                const newFirstNumberOfColumn: number = firstElementOfRange - 1;
                return {
                    ...bti,
                    columnsRange: [newFirstNumberOfColumn, ...bti.columnsRange],
                };
            }
            return bti;
        });
    }

    addRowBottom(btiId: string): void {
        this.btiSettings = map(this.btiSettings, bti => {
            if (bti.id === btiId) {
                const indexOfLastElementOfRange = bti.columnsRange.length - 1;
                const lstElementOfRange: number = bti.columnsRange[indexOfLastElementOfRange];
                const newLastNumberOfRange: number = lstElementOfRange + 1;
                return {
                    ...bti,
                    columnsRange: [
                        ...bti.columnsRange,
                        newLastNumberOfRange,
                    ],
                };
            }
            return bti;
        });
    }

    addAdditionalBtiRowsAndColumns(): void {
        forEach(this.btiSettings, bti => {
            const {
                id: spaceBaseTimeIntervalId,
                moduleSlots,
                groupSlots,
                forks,
                rowsRange,
                columnsRange,
            } = bti;

            const diagramEntities = createArrayFromDiagramEntities({
                moduleSlots,
                groupSlots,
                forks,
            });

            if (checkOutAdditionalEntitiesOnTheLeft({
                diagramEntities,
                rowsRange,
                columnsRange,
            })) {
                this.addColumnLeft(spaceBaseTimeIntervalId);
            }
            if (checkOutAdditionalEntitiesOnTheRight({
                diagramEntities,
                rowsRange,
                columnsRange,
            })) {
                this.addColumnRight(spaceBaseTimeIntervalId);
            }
            if (checkOutAdditionalEntitiesOnTheTop({
                diagramEntities,
                rowsRange,
                columnsRange,
            })) {
                this.addRowTop(spaceBaseTimeIntervalId);
            }
            if (checkOutAdditionalEntitiesOnTheBottom({
                diagramEntities,
                rowsRange,
                columnsRange,
            })) {
                this.addRowBottom(spaceBaseTimeIntervalId);
            }
        });
    }

    addAdditionalBtiLeftColumn(): void {
        forEach(this.btiSettings, bti => {
            const {
                id: spaceBaseTimeIntervalId,
                moduleSlots,
                groupSlots,
                forks,
                rowsRange,
                columnsRange,
            } = bti;

            const diagramEntities = createArrayFromDiagramEntities({
                moduleSlots,
                groupSlots,
                forks,
            });

            if (checkOutAdditionalEntitiesOnTheLeft({
                diagramEntities,
                rowsRange,
                columnsRange,
            })) {
                this.addColumnLeft(spaceBaseTimeIntervalId);
            }
        });
    }

    setDistributionType(distributionType: DistributionType): void {
        this.forkSettingForm = {
            ...this.forkSettingForm,
            distributionType,
        };
    }

    setStudentPriorityCount(studentPriorityCount: number): void {
        this.forkSettingForm = {
            ...this.forkSettingForm,
            studentPriorityCount,
        };
    }

    initForkSettingForm(id: string, spaceBaseTimeIntervalId: string) {
        const fork: Fork | undefined = findFork(this.btiSettings, id, spaceBaseTimeIntervalId);

        if (fork) {
            this.forkSettingForm = {
                ...this.forkSettingForm,
                ...fork.setting,
            };
        }
    }

    setForkSettingsForm<T extends string | boolean | number>(
        value: T,
        fieldName: string,
    ): void {
        if (fieldName === 'moduleSelectionCount' && typeof value === 'string') {
            this.forkSettingForm = {
                ...this.forkSettingForm,
                moduleSelectionCount: parseInt(value, 10),
            };
        }

        if (fieldName === 'studentPriorityCount' && typeof value === 'number') {
            this.forkSettingForm = {
                ...this.forkSettingForm,
                studentPriorityCount: value,
            };
        }

        if (fieldName === 'studentPickingType' && typeof value === 'string') {
            this.forkSettingForm = {
                ...this.forkSettingForm,
                studentPickingType: value,
            };
        }

        if (fieldName === 'overbooking' && typeof value === 'boolean') {
            this.forkSettingForm = {
                ...this.forkSettingForm,
                overbooking: !value,
            };
        }

        if (fieldName === 'transitionalEmptyModule' && typeof value === 'boolean') {
            this.forkSettingForm = {
                ...this.forkSettingForm,
                transitionalEmptyModule: !value,
            };
        }

        if (fieldName === 'transitionalUnderfilledModule' && typeof value === 'boolean') {
            this.forkSettingForm = {
                ...this.forkSettingForm,
                transitionalUnderfilledModule: !value,
            };
        }

        // if (
        //     fieldName === 'automaticallyDistributeRemainingStudents'
        //     && typeof value === 'boolean'
        // ) {
        //     this.forkSettingForm = {
        //         ...this.forkSettingForm,
        //         automaticallyDistributeRemainingStudents: !value,
        //     };
        // }
    }

    saveForkToBtiSettings(id: string, spaceBaseTimeIntervalId: string): void {
        const {
            distributionType,
            moduleSelectionCount,
            transitionalUnderfilledModule,
            studentPickingType,
            transitionalEmptyModule,
            overbooking,
        } = this.forkSettingForm;

        if (this.forkSettingForm.distributionType === 'automatically') {
            this.btiSettings = this.btiSettings.map(bti => (
                bti.id === spaceBaseTimeIntervalId
                    ? {
                        ...bti,
                        forks: bti.forks.map(fork => (
                            fork.id === id
                                ? {
                                    ...fork,
                                    setting: {
                                        distributionType,
                                        moduleSelectionCount,
                                        studentPickingType,
                                        transitionalUnderfilledModule: undefined,
                                        transitionalEmptyModule: undefined,
                                        overbooking: undefined,
                                        distributionModuleId: null,
                                    },
                                }
                                : fork
                        )),
                    }
                    : bti
            ));
        }

        if (this.forkSettingForm.distributionType === DistributionType.STUDENTCHOICE) {
            this.btiSettings = this.btiSettings.map(bti => (
                bti.id === spaceBaseTimeIntervalId
                    ? {
                        ...bti,
                        forks: bti.forks.map(fork => (
                            fork.id === id
                                ? {
                                    ...fork,
                                    setting: {
                                        distributionType,
                                        moduleSelectionCount,
                                        studentPickingType,
                                        transitionalUnderfilledModule,
                                        transitionalEmptyModule,
                                        overbooking,
                                        distributionModuleId: null,
                                    },
                                }
                                : fork
                        )),
                    }
                    : bti
            ));
        }

        if (this.forkSettingForm.distributionType === 'manually') {
            this.btiSettings = this.btiSettings.map(bti => (
                bti.id === spaceBaseTimeIntervalId
                    ? {
                        ...bti,
                        forks: bti.forks.map(fork => (
                            fork.id === id
                                ? {
                                    ...fork,
                                    setting: {
                                        distributionType,
                                        moduleSelectionCount,
                                        studentPickingType: undefined,
                                        transitionalUnderfilledModule: undefined,
                                        transitionalEmptyModule: undefined,
                                        overbooking: undefined,
                                        distributionModuleId: null,
                                    },
                                }
                                : fork
                        )),
                    }
                    : bti
            ));
        }

        this.setShowForkDialog();
    }
}

export default new Diagram();
