import React, { ChangeEvent, useState } from 'react';
import { nanoid } from 'nanoid';

import { Input, InputTypes } from '@common/Input';
import { UpdateTerritorialZoneInput } from '@admin/UniversityPage/types';
import { findIndex, map, sortBy, trimStart } from 'lodash';
import { BorderCrossIcon, QuestionMarkIcon } from '@common/svg';
import { Confirm } from '@common/Confirm';
import { ZonesAddAvailableIntervals } from '@admin/UniversityPage/UniversityZonesFormGroup/ZonesAddAvailableIntervals';
import { SwitcherRedesign } from '@common/SwitcherRedesign';
import { AvailableInterval } from '@admin/UniversityPage/UniversityZonesFormGroup/AvailableInterval';

import classes from './UniversityZonesFormGroup.module.scss';

interface Props {
    universityTimeZone: string;
    territorialZonesLength: number;
    amountOfRoomsToCurrentZone: number;
    serialNumber: number;
    zones: UpdateTerritorialZoneInput[];
    zone: UpdateTerritorialZoneInput;

    handleInputChange(event: ChangeEvent<HTMLInputElement>): void;
    onSwitcherChange: (id: string) => void;
    handleRemoveZone(id: string): void;
    onDateChange: (id: string, intervals: any) => void;

}

export function UniversityZonesFormGroup({
    universityTimeZone,
    territorialZonesLength,
    amountOfRoomsToCurrentZone,
    serialNumber,
    zones,
    zone,
    onDateChange,
    handleInputChange,
    onSwitcherChange,
    handleRemoveZone,
}: Props): JSX.Element {
    const [showConfirm, setShowConfirm] = useState(false);
    const [isAdditionalInfoVisible, setIsAdditionalInfoVisible] = useState(false);
    const {
        id,
        name: intervalName,
        travelTimeBetweenZones,
        availableIntervals,
        isAvailable,
    } = zone;

    const handleSetShowConfirm = (): void => {
        setShowConfirm(!showConfirm);
    };

    const handleAvailableIntervals = (
        fromDate: string,
        toDate: string,
    ) => {
        onDateChange(id, [...availableIntervals || [], {
            id: nanoid(),
            from: fromDate,
            to: toDate,
        }]);
    };

    const handleRemoveAvailableInterval = (intervalId: string) => {
        const newAvailableTimes = availableIntervals?.filter(item => item.id !== intervalId);
        onDateChange(id, newAvailableTimes);
    };

    const additionalInfo = 'Все помещения, привязанные к этой территориальной зоне, будут доступны в соответствии с датами указанными в настройках к этой территориальной зоне';

    const sortedIntervals = sortBy(
        availableIntervals, (interval) => interval.from,
    ).reverse();

    const availableIntervalsList = (
        <ul className={classes.available_list}>
            {
                sortedIntervals.map((interval) => (
                    <AvailableInterval
                        key={interval.id}
                        universityTimeZone={universityTimeZone}
                        interval={interval}
                        handleRemoveAvailableInterval={handleRemoveAvailableInterval}
                    />
                ))
            }
        </ul>
    );

    return (
        <>
            {showConfirm && (
                <Confirm
                    headerText="Внимание"
                    descriptionText="Вы действительно хотите удалить корпус?"
                    secondaryBtnText="Нет"
                    primaryBtnText="Да"
                    onSecondaryBtnClick={handleSetShowConfirm}
                    onOutClick={handleSetShowConfirm}
                    onPrimaryBtnClick={() => handleRemoveZone(id)}
                />
            )}

            <div className={classes.formGroup}>
                <div className={classes.formGroup__name}>
                    <Input
                        id={id}
                        placeholder="Введите название"
                        name="name"
                        value={trimStart(intervalName)}
                        onChange={handleInputChange}
                        label={`Территориальная зона ${serialNumber}:`}
                        inline
                    />
                    {
                        territorialZonesLength > 1
                        && (
                            <BorderCrossIcon
                                size={25}
                                className={classes.formGroup__removeBtn}
                                handler={handleSetShowConfirm}
                            />
                        )
                    }

                </div>

                {map(travelTimeBetweenZones, (travelTimeZone, index) => {
                    const currentTimeZone = findIndex(zones, {
                        id: travelTimeZone.id,
                    });
                    return (
                        <div
                            key={`${id}$${travelTimeZone.id}`}
                            className={classes.formGroup__timeTravel}
                        >
                            <Input
                                id={`${id}&&${travelTimeZone.id}`}
                                type={InputTypes.Number}
                                placeholder="Кол-во"
                                name="travelTime"
                                value={travelTimeZone.travelTime === 0
                                    ? undefined
                                    : travelTimeZone.travelTime.toString()
                                }
                                labelModifier="formGroup__label"
                                onChange={handleInputChange}
                                label={index === 0
                                    ? 'Время перемещения внутри зоны:'
                                    : `Удаленность от территориальной зоны ${currentTimeZone + 1
                                    }:`}
                            />
                            <p className={classes.formGroup__minutes}>мин</p>
                        </div>
                    );
                })}

                <div className={classes.formGroup__available}>

                    <div className={classes.formGroup__available_title}>
                        <SwitcherRedesign
                            label="Выставить ограничения доступа к территориальной зоне"
                            checked={!isAvailable}
                            onClick={() => {
                                onSwitcherChange(id);
                            }}
                        />

                        <div
                            className={classes.formGroup__available_additional}
                            onMouseOver={() => setIsAdditionalInfoVisible(true)}
                            onMouseLeave={() => setIsAdditionalInfoVisible(false)}
                            onFocus={() => {}}
                        >
                            <QuestionMarkIcon
                                isHover={isAdditionalInfoVisible}
                            />

                            {
                                isAdditionalInfoVisible && (
                                    <div className={classes.formGroup__available_additionalInfo}>
                                        {additionalInfo}
                                    </div>
                                )
                            }
                        </div>
                    </div>

                    {
                        !isAvailable && (
                            <>
                                <ZonesAddAvailableIntervals
                                    zoneId={id}
                                    universityTimeZone={universityTimeZone}
                                    amountOfRoomsToCurrentZone={amountOfRoomsToCurrentZone}
                                    intervalName={intervalName}
                                    serialNumber={serialNumber}
                                    availableIntervals={availableIntervals}
                                    handleAvailableIntervals={handleAvailableIntervals}
                                />
                                {availableIntervalsList}
                            </>
                        )
                    }

                </div>
            </div>
        </>
    );
}
