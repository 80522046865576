import React from 'react';
import { format, formatInTimeZone } from 'date-fns-tz';

import { BorderCrossBlueIcon } from '@common';
import { ZoneAvailableInterval } from '@admin/UniversityPage/types';

import { isMatch } from 'date-fns';
import classes from './AvailableInterval.module.scss';

interface Props {
    universityTimeZone: string;
    interval: ZoneAvailableInterval;
    handleRemoveAvailableInterval: (id: string) => void;
}

export function AvailableInterval(
    {
        universityTimeZone,
        interval,
        handleRemoveAvailableInterval,
    }: Props,
) {
    const {
        formattedFrom,
        formattedTo,
        formattedFromTime,
        formattedToTime,
    } = getAvailableInterval(
        interval,
        universityTimeZone,
    );

    const intervalInfo = (
        <div className={classes.interval__info}>
            с
            {' '}
            <span className={classes.interval__info_bold}>{formattedFrom}</span> {formattedFromTime}
            {' '}
            до
            {' '}
            <span className={classes.interval__info_bold}>{formattedTo}</span> {formattedToTime}
        </div>
    );

    return (
        <li
            className={classes.interval}
            key={interval.id}
        >
            <div className={classes.interval__nameGroup}>
                {intervalInfo}
            </div>

            <button
                type="button"
                className={classes.interval__btn}
                onClick={() => handleRemoveAvailableInterval(interval.id)}
            >
                <BorderCrossBlueIcon />
            </button>
        </li>
    );
}

function getAvailableInterval(
    interval: ZoneAvailableInterval,
    universityTimeZone: string,
) {
    let formattedFrom = '';
    let formattedTo = '';
    let formattedFromTime = '';
    let formattedToTime = '';

    const isDateIntervalFromUi = isMatch(`${interval.from}`, 'yyyy-MM-dd HH:mmXXX');

    if (isDateIntervalFromUi) {
        formattedFrom = format(new Date(interval.from), 'dd.MM.yyy');
        formattedTo = format(new Date(interval.to), 'dd.MM.yyy');
        [formattedFromTime] = `${interval.from}`.split(' ')[1].split('+');
        [formattedToTime] = `${interval.to}`.split(' ')[1].split('+');
    } else {
        formattedFromTime = formatInTimeZone(new Date(interval.from), universityTimeZone, 'HH:mm');
        formattedToTime = formatInTimeZone(new Date(interval.to), universityTimeZone, 'HH:mm');
        formattedFrom = formatInTimeZone(new Date(interval.from), universityTimeZone, 'dd.MM.yyyy');
        formattedTo = formatInTimeZone(new Date(interval.to), universityTimeZone, 'dd.MM.yyyy');
    }

    return {
        formattedFrom,
        formattedTo,
        formattedFromTime,
        formattedToTime,
    };
}
