import React from 'react';
import sprite from '@common/sprite.svg';
import cn from 'classnames';

import styles from './Icon.module.scss';

interface Props {
    id: string;
    modifier?: string;
    onClick?(): void
    onHoverEnter?: () => void;
    onHoverLeave?: () => void;
}

export const Icon = ({
    id, modifier, onHoverEnter, onHoverLeave, onClick,
}: Props): JSX.Element => (
    <svg
        className={cn(styles.icon, { [styles[`icon_${modifier}`]]: modifier })}
        onClick={onClick}
        onMouseEnter={onHoverEnter}
        onMouseLeave={onHoverLeave}
    >
        <use
            xlinkHref={`${sprite}#${id}`}
        />
    </svg>
);
