import React from 'react';
import { Module } from '../ModuleCard';
import classes from './SuitableModules.module.scss';
import { UpdateSlotDiagramInput } from '../subSpaceTypes';

interface Props {
    subspaceId:string
    slotId: string
    optimalModules: any;
    skillTypes: any;
    allSkillsList: any;
    updateSlotModule: (item :any) => void;
    setModuleState: (flag: boolean) => void;
    updateSlotDiagram(moduleSlotInput: UpdateSlotDiagramInput): void
}

export function SuitableModules({
    subspaceId,
    slotId,
    optimalModules,
    skillTypes,
    allSkillsList,
    updateSlotModule,
    setModuleState,
    updateSlotDiagram,
}: Props): JSX.Element {
    return (
        <div className={classes.suitableModules}>
            <div className={classes.suitableModules__title}>
                Подходящих модулей {optimalModules.suitable.length}
            </div>
            {optimalModules.suitable && (
                <ul className={classes.suitableModules__list}>
                    {optimalModules.suitable.map((item: any) => (
                        <li
                            key={item.id}
                            className={classes.suitableModules__listItem}
                        >
                            <Module
                                subspaceId={subspaceId}
                                slotId={slotId}
                                currentModule={item}
                                skillTypes={skillTypes}
                                allSkillsList={allSkillsList}
                                updateSlotModule={updateSlotModule}
                                setModuleState={setModuleState}
                                updateSlotDiagram={updateSlotDiagram}
                            />
                        </li>
                    ))}
                </ul>
            )}
            <div className={classes.suitableModules__title}>
                Модули с отличиями от заданных параметров {optimalModules.differing.length}
            </div>
            {optimalModules.differing && (
                <ul className={classes.suitableModules__list}>
                    {optimalModules.differing.map((item: any) => (
                        <li
                            key={item.id}
                            className={classes.suitableModules__listItem}
                        >
                            <Module
                                subspaceId={subspaceId}
                                slotId={slotId}
                                currentModule={item.module}
                                skillTypes={skillTypes}
                                allSkillsList={allSkillsList}
                                updateSlotModule={updateSlotModule}
                                setModuleState={setModuleState}
                                updateSlotDiagram={updateSlotDiagram}
                            />
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
}
