/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import cn from 'classnames';

import { MultipleStudent } from './MultipleStudent';
import { AllMultipleStudentsCheckbox } from './AllMultipleStudentsCheckbox';
import { evaluationStore, multipleEvaluationStore } from '../../Store';
import { GradingMenu } from '../GradingMenu';
import { EvaluationArrowImage } from '../../EvaluationArrowImage';

import classes from './EvaluationMultipleStudents.module.scss';

const MULTIPLE_STUDENTS_HEADING = {
    STUDENTS_NOT_SELECTED: 'Выберите студентов, которых хотите оценить',
    STUDENTS_SELECTED: 'Выбранные студенты',
};

export const EvaluationMultipleStudents = observer((): JSX.Element => {
    const [isGradingMenuOpen, setIsGradingMenuOpen] = useState<boolean>(false);

    function toggleGradingMenu(): void {
        if (multipleEvaluationStore.students.length) {
            setIsGradingMenuOpen((prevIsGradingMenuOpen) => !prevIsGradingMenuOpen);
        }
    }

    function disableGradingMenuIfStudentsNotSelected(): void {
        if (multipleEvaluationStore.isMultipleStudentsNotSelected()) {
            setIsGradingMenuOpen(false);
        }
    }

    useEffect(() => {
        disableGradingMenuIfStudentsNotSelected();
    }, [multipleEvaluationStore.students]);

    return (
        <>
            <div className={classes.evaluationMultipleStudents}>
                {!!evaluationStore.getFilteredStudentsBySearchQuery().length && (
                    <>
                        <div className={cn(classes.evaluationSection__studentsChoose, {
                            [classes.evaluation__arrowBlock_open]:
                                multipleEvaluationStore.students.length,
                        })}
                        >
                            {
                                multipleEvaluationStore.students.length
                                    ? (
                                        <>
                                            <div
                                                className={classes.evaluation_arrowBlock}
                                                onClick={toggleGradingMenu}
                                            >
                                                <EvaluationArrowImage
                                                    condition={multipleEvaluationStore
                                                        .isMultipleStudentsNotSelected()}
                                                    conditionTwo={isGradingMenuOpen}
                                                    gradientId="arrow-block-up-linear-multiple"
                                                />
                                                <div className={classes
                                                    .evaluationSection__chooseBlock}
                                                >
                                                    <p
                                                        className={classes
                                                            .evaluationSection__studentsChoose_textBold}
                                                    >
                                                        {
                                                            MULTIPLE_STUDENTS_HEADING
                                                                .STUDENTS_SELECTED
                                                        }
                                                    </p>
                                                    <p
                                                        className={classes
                                                            .multipleEvaluationQuantity}
                                                    >
                                                        Оценить&nbsp;
                                                        {multipleEvaluationStore.students.length}
                                                    </p>
                                                </div>
                                            </div>
                                        </>
                                    )
                                    : (
                                        <div className={classes.evaluationSection__chooseBlock}>
                                            <p
                                                className={
                                                    classes.evaluationSection__studentsChoose_text
                                                }
                                            >
                                                {
                                                    MULTIPLE_STUDENTS_HEADING
                                                        .STUDENTS_NOT_SELECTED

                                                }
                                            </p>
                                        </div>
                                    )
                            }

                        </div>
                    </>
                )}

            </div>
            {isGradingMenuOpen && <GradingMenu />}
            <div>
                <AllMultipleStudentsCheckbox />
                <ul>
                    {
                        evaluationStore.getFilteredStudentsBySearchQuery()
                            .map(({ student: {
                                id,
                                user: {
                                    firstName,
                                    lastName,
                                    patronymic,
                                } },
                            }) => (
                                <MultipleStudent
                                    id={id}
                                    firstName={firstName}
                                    lastName={lastName}
                                    patronymic={patronymic}
                                    key={id}
                                />
                            ))
                    }
                </ul>
            </div>
        </>
    );
});
