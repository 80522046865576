import { useEffect } from 'react';
import { gql, useLazyQuery } from '@apollo/client';
import { downloadJson } from '@admin/ScheduleGenerationPage/utils';

export const GET_CONFIG_FOR_BTI = gql`
    query getScheduleGenerationConfig($baseTimeIntervalInstanceIds: [String!]!) {
        getScheduleGenerationConfig(baseTimeIntervalInstanceIds: $baseTimeIntervalInstanceIds)
    }
`;

export function useGetScheduleGenerationConfigQuery(ids: string[]) {
    const [downloadConfig, { data, loading }] = useLazyQuery(GET_CONFIG_FOR_BTI, {
        variables: { baseTimeIntervalInstanceIds: ids }, fetchPolicy: 'network-only',
    });
    useEffect(() => {
        if (data) {
            downloadJson('config.json', data.getScheduleGenerationConfig);
        }
    }, [data]);

    return { downloadConfig, loading };
}
