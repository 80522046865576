import React, { useState } from 'react';

import { useMutation, useQuery } from '@apollo/client';
import { Alert } from '@common/Alert/Alert';
import { RadioList } from '@admin/Users/RadioList';
import { Select } from '@admin/Users/Select';
import { ToolButton } from '../../ToolButton';
import {
    updateStudentsBTIVariables,
    updateStudentsBTI,
    baseTimeIntervalInstances,
} from '../apollo-types';
import { ToolBarButtonProps } from '../interface';
import { GET_BASE_TIME_INTERVAL_INSTANCES, UPDATE_STUDENTS_BTI } from '../gql';

export const BaseTimeIntervalInstanceButton = ({
    selectedUsers,
    refetchUserList,
}: ToolBarButtonProps) => {
    const { data: btiData } = useQuery<baseTimeIntervalInstances>(GET_BASE_TIME_INTERVAL_INSTANCES);
    const [updateBTI, { error }] = useMutation<
    updateStudentsBTI,
    updateStudentsBTIVariables
    >(UPDATE_STUDENTS_BTI, {
        onCompleted: () => {
            refetchUserList();
            setSpaceId(undefined);
            setBaseTimeIntervalInstanceId(undefined);
        },
        // eslint-disable-next-line no-console
        onError: (err) => { console.error(err); },
    });

    const [spaceId, setSpaceId] = useState<string>();
    const [baseTimeIntervalInstanceId, setBaseTimeIntervalInstanceId] = useState<string>();

    function onSpaceChange(event: React.ChangeEvent<HTMLInputElement>) {
        const { value } = event.target;
        setSpaceId(value);
        setBaseTimeIntervalInstanceId(undefined);
    }

    function onBaseTimeIntervalInstanceChange(event: React.ChangeEvent<HTMLInputElement>) {
        const { value } = event.target;
        setBaseTimeIntervalInstanceId(value);
    }

    function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();
        const studentIds = selectedUsers
            .filter(({ student }) => !!student)
            .map(({ student }) => student?.id);
        if (baseTimeIntervalInstanceId && studentIds.length) {
            updateBTI({
                variables: {
                    input: {
                        ids: studentIds as string[],
                        baseTimeIntervalInstanceId,
                    },
                },
            });
        }
    }

    const spaces = btiData?.baseTimeIntervalInstances
        .map(({ spaceBaseTimeInterval: { space } }) => ({
            id: space.id,
            name: space.name,
            value: space.id,
        }))
        .filter((space, index, self) => (
            index === self.findIndex(s => s.id === space.id)
        ));

    const baseTimeIntervals = btiData?.baseTimeIntervalInstances
        .filter(({ spaceBaseTimeInterval: { space } }) => space.id === spaceId)
        .map(
            ({ id, spaceBaseTimeInterval: { order, space } }) => ({
                id,
                name: `${order} ${space.baseTimeIntervalType?.name}`,
            }),
        );

    return (
        <>
            <ToolButton
                text="Временной интервал"
                icon="blackPlus"
                onSubmit={handleSubmit}
                contextMenu={baseTimeIntervals && (
                    <RadioList
                        listOptions={spaces!}
                        type="role"
                        checked={spaceId}
                        onChange={onSpaceChange}
                    >
                        <Select
                            options={baseTimeIntervals}
                            value={baseTimeIntervalInstanceId}
                            onChange={onBaseTimeIntervalInstanceChange}
                            placeholder="Сделайте выбор"
                        />
                    </RadioList>
                )}
            />
            {error && <Alert message={error.message} time={3000} />}
        </>
    );
};
