import React, { useEffect, useRef } from 'react';

import classes from './InputRange.module.scss';

interface Props {
    maxLevel: number;
    level: number;
    handleChangeGrade(event: React.ChangeEvent<HTMLInputElement>): void;
}

export function InputRange({
    maxLevel,
    level,
    handleChangeGrade,
}: Props): JSX.Element {
    const refInputRange = useRef<null | HTMLInputElement>(null);

    function countInputRange(inputRange: HTMLInputElement): string {
        const value = ((+inputRange.value - +inputRange.min)
            / (+inputRange.max - +inputRange.min)) * 100;
        return `linear-gradient(to right, rgba(11, 66, 220, 0.5) 0%, rgba(87, 178, 198, 0.5) ${value}%, #fff ${value}%, white 100%)`;
    }

    function onChangeInputRange(event: React.ChangeEvent<HTMLInputElement>): void {
        handleChangeGrade(event);
        const inputRange = refInputRange.current;

        if (inputRange) {
            countInputRange(inputRange);
        }
    }

    useEffect(() => {
        const inputRange = refInputRange.current;
        if (inputRange) {
            inputRange.style.background = countInputRange(inputRange);
        }
    }, [level]);

    return (
        <input
            type="range"
            min="0"
            max={maxLevel}
            step="1"
            className={classes.inputRange}
            value={level}
            onChange={onChangeInputRange}
            ref={refInputRange}
        />
    );
}
