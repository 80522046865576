import { SubspaceType } from '../interfaces';
import { getSubspaceType, getMaxIntervalOrder, getMinIntervalOrder } from './subspaceType';

export function isValidConnection(
    sourceSubspaceId: string, targetSubspaceId: string,
    types: SubspaceType[],
): boolean {
    let res = false;

    const sourceType = getSubspaceType(sourceSubspaceId, types);
    const targetType = getSubspaceType(targetSubspaceId, types);

    if (sourceType && targetType) {
        const sourceTypeIntervalOrder = getMaxIntervalOrder(sourceType);
        const targetTypeIntervalOrder = getMinIntervalOrder(targetType);
        const isTargetOrderNext = (targetTypeIntervalOrder - sourceTypeIntervalOrder) === 1;

        res = isTargetOrderNext;
    }

    return res;
}

export function getValidConnectionId(
    firstSubspaceId: string, secondSubspaceId: string, types: SubspaceType[],
): string | null {
    let res: string | null = null;

    const firstIdIsSource = isValidConnection(firstSubspaceId, secondSubspaceId, types);
    const secondIdIsSource = isValidConnection(secondSubspaceId, firstSubspaceId, types);

    if (firstIdIsSource) {
        res = getConnectionId(firstSubspaceId, secondSubspaceId);
    } else if (secondIdIsSource) {
        res = getConnectionId(secondSubspaceId, firstSubspaceId);
    }

    return res;
}

export function getConnectionId(sourceSubspaceId: string, targetSubspaceId: string): string {
    return `${sourceSubspaceId}-connect-${targetSubspaceId}`;
}

export function getSourceId(connectionId: string): string {
    return connectionId.split('-connect-')[0];
}

export function getTargetId(connectionId: string): string {
    return connectionId.split('-connect-')[1];
}
