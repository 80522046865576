import React from 'react';
import cn from 'classnames';

import classes from './TabsWithoutRouteRedesign.module.scss';

interface Tab {
    id: string;
    name: string;
    hasNotification?: boolean;
}

interface Props {
    tabs: Tab[];
    activeTabId: string;
    hasBottomBorder?: boolean;
    setActiveTabId: (id: string) => void;
}

export const TabsWithoutRouteRedesign = (
    {
        tabs,
        activeTabId,
        hasBottomBorder,
        setActiveTabId,
    }: Props,
) => (
    <div
        className={classes.tabs}
        style={{
            borderBottom: hasBottomBorder ? '1px solid #ECEAE9' : 'none',
        }}
    >
        <ul className={classes.tabs__list}>
            {
                tabs.map((tab) => (
                    <li
                        key={tab.id}
                        className={
                            cn(classes.tabs__item, {
                                [classes.tabs__item_active]:
                                    tab.id === activeTabId,
                            })
                        }
                    >
                        <div
                            onClick={() => setActiveTabId(tab.id)}
                            className={
                                cn(classes.tabs__tab, {
                                    [classes.tabs__tab_selected]:
                                        tab.id === activeTabId,
                                })
                            }
                        >
                            {tab.name}
                        </div>
                    </li>
                ))
            }
        </ul>
    </div>
);
