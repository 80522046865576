import React, { useEffect, useState } from 'react';
import { range, sortBy } from 'lodash';
import { observer } from 'mobx-react';
import cn from 'classnames';

import { StudentTargetName, useSendMetriks } from '@common/hooks';

import {
    EventType,
    Module,
    StudentSchedule,
} from '../Config/interfaces';
import store, { PrioritySetMode } from '../store';
import { EventList } from '../EventsList';
import SkillHierarchy from '../../../store/SkillHierarchy';
import { ModuleCardInfo } from '../ModuleCard/ModuleCardInfo';
import { ModuleCardHeader } from '../ModuleCard/ModuleCardHeader';
import { ModuleSkills } from '../ModuleCard/ModulesSkills/ModuleSkills';
import { ViewType } from '../../../deprecated-graphql-query-types';
import { ModuleAssessmentTab } from '../ModuleCard/ModuleCardAssessment';

import classes from './ModuleContent.module.scss';

interface Props {
    module: Module;
    studentSchedule?: StudentSchedule;
    isElectiveFork: boolean;
    forkId?: string;
    slotId?: string;
    isStudentModuleAssessmentActive?: boolean;
    studentModuleId?: string;
    studentsModulePriorityCount?: number;
    setStudentPriorities?: (
        setMode: PrioritySetMode,
        forkId: string,
        moduleId: string,
        priority?: number,
    ) => void;
}

enum Tabs {
    ASSESSMENT = 'Оценка модуля и преподавателей',
    DESCRIPTION = 'Описание',
    CONTENT = 'Содержание',
    SKILLS = 'Обр. результаты',
    TEACHERS = 'Преподаватели',
}

export const ModuleContent = observer(({
    module,
    studentSchedule,
    isElectiveFork,
    forkId,
    slotId,
    isStudentModuleAssessmentActive,
    studentModuleId,
    studentsModulePriorityCount,
    setStudentPriorities,
}: Props): JSX.Element => {
    const priorities = range(1, (store.getForkPriorityCount(forkId) ?? 0) + 1);
    const slot = store.getSlot(forkId, slotId);
    const { prerequisiteSkills, outputSkills } = module;
    const moduleMaxStudentCount = module.maxWaveCount * module.maxStudentCount;
    const [contentMode, setContentMode] = useState<boolean>(false);
    const [activeTab, setActiveTab] = useState(
        isStudentModuleAssessmentActive
            ? Tabs.ASSESSMENT
            : Tabs.DESCRIPTION,
    );

    const { sendYandexMetrika } = useSendMetriks([ViewType.Student]);

    const sortedEvents = [
        ...(updateEvents(
            sortBy(
                module.meetings,
                meeting => {
                    const instance = studentSchedule?.meetingInstances
                        .find(meetingInstance => meetingInstance.meeting.id === meeting.id);
                    return Number(
                        !(instance && new Date(Number(instance.startDate)) < new Date()),
                    ) * 1000 + meeting.order;
                },
            ), 'meetings',
        )),
        ...(updateEvents(sortBy(module.assignments, 'order'), 'assignments')),
    ].sort((a, b) => a.order - b.order);

    useEffect(() => {
        store.sortForkSlotsByPriorities();
    }, [contentMode]);

    const tabs = isStudentModuleAssessmentActive
        ? Object.entries(Tabs)
        : Object.entries(Tabs).filter((tab) => tab[1] !== Tabs.ASSESSMENT);

    return (
        <li
            key={module.id}
            className={cn(classes.moduleCards__item, {
                [classes.moduleCards__item_open]: contentMode,
            })}
        >
            <ModuleCardHeader
                forkId={forkId}
                moduleId={module.id}
                moduleName={module.name}
                moduleMaxStudentCount={moduleMaxStudentCount}
                isElectiveFork={isElectiveFork}
                studentsModulePriorityCount={studentsModulePriorityCount}
                slot={slot}
                contentMode={contentMode}
                priorities={priorities}
                setStudentPriorities={setStudentPriorities}
                setContentMode={setContentMode}
            />
            {contentMode && (
                <div className={classes.moduleContent}>
                    <div className={classes.tabs}>
                        <ul className={classes.tabs__list}>
                            {tabs.map((tab) => (
                                <li
                                    key={tab[1]}
                                    className={classes.tabs__item}
                                >
                                    <div
                                        id={`${module.id}/${tab[1]}`}
                                        onClick={() => {
                                            const element = document.getElementById(`${module.id}/${tab[1]}`);
                                            setActiveTab(tab[1]);
                                            element?.scrollIntoView({
                                                behavior: 'smooth',
                                                block: 'nearest',
                                                inline: 'center',
                                            });
                                            sendYandexMetrika(StudentTargetName.modules, {
                                                moduleTab: tab[1],
                                            });
                                        }}
                                        className={
                                            cn(classes.tabs__tab, {
                                                [classes.tabs__tab_selected]:
                                                    tab[1] === activeTab,
                                            })
                                        }
                                    >
                                        {tab[1]}
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </div>
                    {Tabs.DESCRIPTION === activeTab && (
                        <ModuleCardInfo
                            module={module}
                            studentSchedule={studentSchedule}
                        />
                    )}
                    {Tabs.CONTENT === activeTab && (
                        <div className={classes.moduleContent__listOfEvents}>
                            <EventList
                                sortedEvents={sortedEvents}
                            />
                        </div>
                    )}
                    {Tabs.SKILLS === activeTab && (
                        <div className={classes.moduleContent__skills}>
                            <ModuleSkills
                                title="Для попадания"
                                skillsTree={SkillHierarchy.filterBySkillIds(prerequisiteSkills
                                    .map(({ skill }) => skill.id))}
                                skills={prerequisiteSkills}
                                isPrerequisiteSkills
                            />
                            <ModuleSkills
                                title="После прохождения"
                                skillsTree={SkillHierarchy.filterBySkillIds(outputSkills
                                    .map(({ skill }) => skill.id))}
                                skills={outputSkills}
                                isPrerequisiteSkills={false}
                            />
                        </div>
                    )}
                    {Tabs.TEACHERS === activeTab && (
                        <div className={classes.moduleContent__teacher_list}>
                            {module.moduleTeachers.map(({ teacher, teacherRoles }) => (
                                <div
                                    key={`${teacher.user.lastName}${teacher.user.firstName}`}
                                    className={classes.moduleContent__teacher}
                                >
                                    <div>
                                        <b>{teacher.user.lastName}</b>
                                        {` ${teacher.user.firstName} ${teacher.user.patronymic}`}
                                    </div>
                                    <div className={classes.moduleContent__teacher_roles}>
                                        {teacherRoles.map(({ name }) => `${name}, `)}
                                    </div>
                                </div>
                            ))}
                        </div>
                    )}
                    {Tabs.ASSESSMENT === activeTab && (
                        <ModuleAssessmentTab
                            moduleId={module.id}
                            studentModuleId={studentModuleId}
                        />
                    )}
                </div>
            )}
        </li>
    );
});

function updateEvents(events: EventType[], type: string) {
    const updatedEvents = events.map((event: EventType, index: number) => ({
        ...event,
        eventName: type,
        eventOrder: Number(index + 1),
    }));
    return updatedEvents;
}
