import React from 'react';
import { useHistory } from 'react-router-dom';
import { format } from 'date-fns';

import { useMutation } from '@apollo/client';
import { observer } from 'mobx-react';

import { ActionButton, ActionTypeOfButton } from '@common/ActionButton';
import { HorizontalArrowIcon, HorizontalVector } from '@common/svg';
import { AssignmentInstanceTyped } from '../../../Interfaces';
import { eventsStore } from '../../../Store';
import classes from './Assignment.module.scss';
import { SET_ASSIGNMENT_STATUS } from './mutations';

export const AssignmentEvent = observer(() => {
    const { selectedEvent,
        selectEvent,
        isAssignment,
        showAssignmentsList,
        setAssignmentInstances,
        getAssignmentInstances,
    } = eventsStore;
    const { isCompleted, assignmentInstance } = selectedEvent as AssignmentInstanceTyped;
    const { id, assignment, nextMeetingInstance, deadline, nextMeetings } = assignmentInstance;
    const [setOncompliteStatus] = useMutation(SET_ASSIGNMENT_STATUS, {
        onCompleted: () => {
            selectEvent({
                ...selectedEvent,
                isCompleted: !isCompleted,
            } as AssignmentInstanceTyped);
        },
    });
    const history = useHistory();
    function handleShowAssogmentList(): void {
        setAssignmentInstances([...getAssignmentInstances()
            .map((item) => {
                if (item.assignmentInstance.id === id) {
                    return { ...item, isCompleted };
                }
                return item;
            }),
        ]);
        showAssignmentsList(true);
        history.push('/schedule/assignments');
    }
    const isAssignmentEvent = isAssignment(selectedEvent);
    const nexMeetingTopics = nextMeetings
        .map(({ topic }, index) => ((nextMeetings.length !== (index + 1)) ? `"${topic}", ` : `"${topic}"`));
    return (
        <div
            className={classes.assignment__container}
        >
            <div
                className={classes.assignment__header}
                onClick={() => handleShowAssogmentList()}
            >
                <HorizontalArrowIcon
                    firstColor="black"
                    secondColor="black"
                    thirdColor="black"
                    horizontalVector={HorizontalVector.LEFT}
                    className={classes.assignment__header_arrow}
                />
                назад
            </div>
            {isAssignmentEvent && (
                <div
                    className={classes.assignment}
                >
                    <div className={classes.assignment__status}>
                        {isCompleted ? 'выполнена' : 'не выполнена'}
                    </div>
                    <div className={classes.assignment__topic}>
                        {assignment.topic}
                    </div>
                    <div className={classes.assignment__module}>
                        {assignment.module.name}
                    </div>
                    <div className={classes.assignment__nextMeeting}>
                        {nextMeetingInstance
                            ? `К встрече: "${nextMeetingInstance.meeting.topic}"`
                            : getNextMeetingTopicsText(nexMeetingTopics)}
                    </div>
                    <div className={classes.assignment__duration}>
                        {`Продолжительность - ${assignment.duration.name}мин.`}
                    </div>
                    <div className={classes.assignment__deadline}>
                        {`Срок сдачи ${deadline ? format(new Date(deadline), 'dd.MM') : 'не определен'}`}
                    </div>
                    <div className={classes.assignment__description}>
                        <div
                            className={classes.assignment__description_title}
                        >
                            Описание от владельца модуля:
                        </div>
                        {assignment.description ?? 'Описание отсутствует'}
                    </div>
                    {/* TeacherComment */}
                    <ActionButton
                        actionType={isCompleted
                            ? ActionTypeOfButton.SECONDARY
                            : ActionTypeOfButton.PRIMARY}
                        onClick={() => setOncompliteStatus({
                            variables: {
                                changeCompleteStatusOfAssignmentInstanceInput: {
                                    assignmentInstanceId: id,
                                    isCompleted: !isCompleted,
                                },
                            },
                        })}
                    >
                        {isCompleted ? 'Сделать невыполненной' : 'Сделать выполненной'}
                    </ActionButton>
                </div>
            )}
        </div>
    );
});

function getNextMeetingTopicsText(topics: string[]) {
    if (topics.length > 1) {
        return `К одной из следующих встреч:${topics}`;
    }
    return `К встрече: ${topics}`;
}
