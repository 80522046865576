import React, { useEffect, useState } from 'react';

import cn from 'classnames';
import { Calendar } from '../Calendar';

import classes from './EquipmentPlan.module.scss';

import { EquipmentPlanTabs } from './EquipmentPlanTabs';
import { EquipmentContentRouter } from '../EquipmentContentRouter';
import { SelectedDate } from '../Calendar/Calendar';

export function EquipmentPlan(): JSX.Element {
    const [dateRange, setDateRange] = useState<any>([null, null]);
    const [isEquipment, setIsEquipment] = useState<boolean>(false);
    const [startDate, endDate] = dateRange;
    const onChangeCalendar = (update: SelectedDate) => setDateRange(update);
    useEffect(() => {
        if (dateRange[0] !== null && dateRange[1] !== null) {
            setIsEquipment(true);
        } else {
            setIsEquipment(false);
        }
    }, [dateRange]);
    return (
        <div className={classes.equipmentPlan}>
            <h2>План расходных материалов:</h2>
            <div className={classes.equipmentPlan__calendar}>
                <Calendar
                    startDate={startDate}
                    endDate={endDate}
                    onChange={onChangeCalendar}
                />
            </div>
            <div
                className={cn(classes.equipmentPlan__tabs, {
                    [classes.equipmentPlan__tabs_disable]: !isEquipment,
                })}
            >
                <EquipmentPlanTabs />
            </div>
            {isEquipment && (
                <div className={classes.equipmentPlan__equipmentRouteList}>
                    <EquipmentContentRouter
                        from={new Date(dateRange[0]).toISOString()}
                        to={new Date(dateRange[1]).toISOString()}
                    />
                </div>
            )}
        </div>
    );
}
