import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { Redirect, useHistory, useRouteMatch } from 'react-router-dom';

import { HeaderRedesign } from '@common/HeaderRedesign';
import { NotDistribAccordion } from '@admin/WaveSelectionPage/NotDistribAccordion';
import { WaveAccordion } from '@admin/WaveSelectionPage/WaveAccordion';
import { AuthRoute } from '@common/AuthRoute';
import { toJS } from 'mobx';
import cn from 'classnames';
import { waveSelectionDataStore } from '@admin/WaveSelectionPage/WaveSelectionDataStore';
import {
    Fork, GroupedWavesWithSelectedStudents,
    SortTypes,
    StudentWithAdditionalFields,
    StudentWithAFAndRating,
} from '@admin/WaveSelectionPage/Interfaces';
import { waveSelectionStore } from '@admin/WaveSelectionPage/WaveSelectionStore';
import { Loader } from '@common/Loader';
import { ButtonName } from '@common/SelectionPointButtonRedesign/buttonHooks';
import { ActionTypeOfButton } from '@common/ActionButton';
import { SelectionPointButtonRedesign } from '@common/SelectionPointButtonRedesign';
import { StudentsRatings_studentsRating as StudentWithRating } from '@admin/WaveSelectionPage/graphql-types';
import { sortBy } from 'lodash';
import { ViewType } from '../../deprecated-graphql-query-types';
import arrow from './Arrow.svg';

import classes from './WaveSelectionPage.module.scss';

interface Props {
    isWaveLoading: boolean;
}

export const WaveSelectionPage = observer((
    {
        isWaveLoading,
    }: Props,
): JSX.Element => {
    const { path } = useRouteMatch();
    const history = useHistory();

    const isSelectionButtonDisabled = !!waveSelectionStore.getWaveSelectedStudents().length
    || !!waveSelectionStore.getNotDistribStudents().length
        || !waveSelectionDataStore.getIsValidStudentWaveDistribution();

    const {
        baseTimeIntervalInstanceId,
        spaceId,
        isNotStudentChoice,
        baseTimeIntervalId,
        forkId,
        btiOrder,
    } = waveSelectionDataStore.getURLParameters();

    const tabsOptions = getTabsOptionsFromForks(
        baseTimeIntervalInstanceId,
        {
            btiId: baseTimeIntervalId,
            spaceId,
            btiOrder,
        },
        toJS(waveSelectionDataStore.getForks()),
    );

    useEffect(() => {
        waveSelectionDataStore.updateURLParameters();
        waveSelectionStore.setWaveSelectedStudents([]);
    }, [window.location.search]);

    useEffect(() => {
        waveSelectionDataStore.setCurrentForkModulesIds(
            getCurrentForkModulesIds(
                forkId,
                waveSelectionDataStore.getForks(),
            ),
        );
    }, [
        waveSelectionDataStore.getURLParameters(),
        waveSelectionDataStore.getForks(),
    ]);

    const waveAccordionsList = waveSelectionStore.getModuleWavesWithSelectedStudents()
        .map((module) => (
            <WaveAccordion
                key={module.moduleId}
                currentWaves={module.waves}
            />
        ));

    return (
        <div className={classes.waveSelection}>

            <div className={classes.waveSelection__header}>
                <HeaderRedesign
                    title={`Распределение по потокам (${btiOrder} семестр)`}
                    imageSrc={arrow}
                    withHiddenTabs
                    tabsOptions={tabsOptions}
                    imageOnClickEvent={() => history.push('/education-period')}
                />

                <SelectionPointButtonRedesign
                    buttonName={
                        ButtonName.APPROVE_STUDENT_MODULE_DISTRIBUTION
                    }
                    BTIId={baseTimeIntervalInstanceId}
                    buttonActionType={ActionTypeOfButton.PRIMARY}
                    disabled={isSelectionButtonDisabled}
                    buttonText="Завершить распределение"
                    navLink="/education-period"
                    beforeRequestAction={() => waveSelectionStore.setIsAllDisabled(true)}
                    afterRequestAction={() => waveSelectionStore.setIsAllDisabled(false)}
                />
            </div>

            {
                !isWaveLoading ? (
                    <div className={classes.waveSelection__content}>

                        <div className={classes.waveSelection__notDistrib}>

                            {
                                !isNotStudentChoice && (
                                    <NotDistribAccordion />
                                )
                            }

                        </div>

                        <div
                            className={cn(classes.waveSelection__waves, {
                                [classes.waveSelection__waves_required]: isNotStudentChoice,
                            })}
                        >

                            {
                                waveAccordionsList
                            }

                        </div>
                    </div>
                ) : (
                    <div className={classes.loader}>
                        <Loader />
                    </div>
                )
            }

            {
                !checkIfTabsHasCurrentURL(tabsOptions) && (
                    <AuthRoute
                        path={path}
                        requiredUserTypes={[ViewType.Admin]}
                    >
                        <Redirect to={`${path}/${tabsOptions[0]?.link}`} />
                    </AuthRoute>
                )
            }

        </div>
    );
});

export function getStudentRating(
    studentId: string,
    studentsWithRatings: StudentWithRating[],
): string | number {
    const studentRating = studentsWithRatings.find(
        (studentWithRating) => studentWithRating.student.id === studentId,
    )?.value;
    return studentRating ? studentRating.toFixed(1) : '-';
}

function getCurrentForkModulesIds(
    currentForkId: string,
    forks: Fork[],
): string[] {
    const currentFork = forks.find((fork) => fork.id === currentForkId);

    if (!currentFork || !currentFork.slots.length) return [];

    return currentFork.slots.map((slot) => (slot.module ? slot.module.id : ''));
}

function checkIfTabsHasCurrentURL(
    tabsOptions: {
        title: string,
        link: string,
    }[],
) {
    return tabsOptions.some((option) => {
        const splitOption = option.link.split('/');
        return splitOption[splitOption.length - 1] === `${window.location.search}`;
    });
}

function getTabsOptionsFromForks(
    btiInstanceId: string,
    queryStringVariables: {
        btiId: string | null,
        spaceId: string | null,
        btiOrder: string,
    },
    forks: Fork[],
) {
    if (forks.length === 0) {
        return [
            {
                title: 'noLink',
                link: `90900/?forkId=undefined&&btiId=${queryStringVariables.btiId}&&spaceId=${queryStringVariables.spaceId}&&btiIId=${btiInstanceId}&&isRequired=false&&btiOrder=${queryStringVariables.btiOrder}`,
            },
        ];
    }
    let forkIndex = 0;

    const sortedForksById = sortBy(forks, fork => fork.id);

    const sortedForks = sortBy(sortedForksById, fork => fork.isNotStudentChoice);

    return sortedForks.map((fork, index) => {
        const doesForkIndexIncreases = !fork.isNotStudentChoice
            && index !== 0 && fork.subspace.name === sortedForks[index - 1].subspace.name;

        if (doesForkIndexIncreases) {
            forkIndex += 1;
        } else {
            forkIndex = 1;
        }
        return {
            title: fork.isNotStudentChoice ? `Обязательные модули / ${fork.subspace.name}` : `Развилка ${forkIndex} / ${fork.subspace.name}`,
            link: `${index}${fork.id}/?forkId=${fork.id}&&btiId=${queryStringVariables.btiId}&&spaceId=${queryStringVariables.spaceId}&&btiIId=${btiInstanceId}&&isRequired=${fork.isNotStudentChoice}&&btiOrder=${queryStringVariables.btiOrder}`,
            additionalInfoOnHover: fork.isNotStudentChoice ? `Обязательные модули / ${fork.subspace.name}` : `${fork.subspace.name}`,
            isNotStudentChoiceFork: fork.isNotStudentChoice,
        };
    });
}

export function getStudentsRemovedCurrentStudent(
    currentStudentId: string,
    students: StudentWithAdditionalFields[],
) {
    return students.filter((student) => student.user.studentId !== currentStudentId);
}

export function getSortedStudents(
    sortTypes: SortTypes,
    studentsRatings: StudentWithRating[],
    students: StudentWithAdditionalFields[],
): StudentWithAdditionalFields[] {
    const sortedStudentsAz = sortBy(students, student => student.user.lastName);

    const studentsWithRating = getStudentsWithADAndRating(
        studentsRatings,
        students,
    );

    const sortedStudentsByRatingRemovedRating = getSortedStudentsByRating(studentsWithRating);

    if (sortTypes.isSortAz) {
        return sortedStudentsAz.reverse();
    }

    if (sortTypes.isSortZa) {
        return sortedStudentsByRatingRemovedRating;
    }

    return sortedStudentsAz;
}

function getSortedStudentsByRating(
    studentsWithRating: StudentWithAFAndRating[],
): StudentWithAdditionalFields[] {
    const sortedStudentsWithRating = sortBy(
        studentsWithRating, student => student.rating,
    ).reverse();

    return sortedStudentsWithRating.map((student) => (
        {
            __typename: 'Student',
            user: student.user,
            isSelected: student.isSelected,
            wasMoved: student.wasMoved,
        }
    ));
}

function getStudentsWithADAndRating(
    studentsRatings: StudentWithRating[],
    students: StudentWithAdditionalFields[],
): StudentWithAFAndRating[] {
    return students.map((student) => {
        const studentRating = studentsRatings.find(
            (studentWithRating) => studentWithRating.student.id === student.user.studentId,
        );

        return {
            ...student,
            rating: studentRating ? studentRating.value : null,
        };
    });
}

export function checkIfCanSortByRating(
    studentsRatings: StudentWithRating[],
    students: StudentWithAdditionalFields[],
) {
    let hasStudentsRating = false;

    students.forEach((student) => {
        const studentRating = studentsRatings.find(
            (studentWithRating) => studentWithRating.student.id === student.user.studentId,
        );
        if (studentRating) {
            hasStudentsRating = true;
        }
    });

    return hasStudentsRating;
}

export function checkIfStudentIsUnique(
    currentStudent: StudentWithAdditionalFields,
    notDistributedStudents: StudentWithAdditionalFields[],
    groupedWaves: GroupedWavesWithSelectedStudents[],
) {
    const isStudentUniqueInNotDistributedStudents = checkIfStudentIsUniqueInNotDistributedStudents(
        currentStudent,
        notDistributedStudents,
    );

    if (!isStudentUniqueInNotDistributedStudents) {
        return true;
    }

    const isStudentIsUniqueInGroupedWaves = checkIfStudentIsUniqueInGroupedWaves(
        currentStudent,
        groupedWaves,
    );

    return isStudentIsUniqueInGroupedWaves;
}

function checkIfStudentIsUniqueInNotDistributedStudents(
    currentStudent: StudentWithAdditionalFields,
    notDistributedStudents: StudentWithAdditionalFields[],
) {
    const { user } = currentStudent;
    return !notDistributedStudents.some((student) => (
        student.user.firstName === user.firstName
        && student.user.lastName === user.lastName
        && student.user.patronymic === user.patronymic
        && student.user.id !== user.id
    ));
}

function checkIfStudentIsUniqueInGroupedWaves(
    currentStudent: StudentWithAdditionalFields,
    groupedWaves: GroupedWavesWithSelectedStudents[],
) {
    const { user } = currentStudent;

    let isUnique = true;

    groupedWaves.forEach((groupedWave) => {
        groupedWave.waves.forEach((wave) => {
            const isStudentUnique = !wave.students.some((student) => (
                student.user.firstName === user.firstName
                && student.user.lastName === user.lastName
                && student.user.patronymic === user.patronymic
                && student.user.id !== user.id
            ));

            if (!isStudentUnique) {
                isUnique = false;
            }
        });
    });

    return isUnique;
}
