import { useHistory } from 'react-router-dom';

function encode(value: any) {
    return typeof value === 'string'
        ? encodeURIComponent(value)
        : encodeURIComponent(JSON.stringify(value));
}

export function useUrlQuery() {
    const history = useHistory();

    function setUrlQuery(
        parameters: { [index: string]: any },
        defaultPath: string = window.location.pathname,
    ): void {
        const UrlQueryParams = new URLSearchParams(history.location.search);
        Object.entries(parameters)
            .filter(([, value]) => value === undefined)
            .forEach(([key]) => UrlQueryParams.delete(key));
        Object.entries(parameters)
            .filter(([, value]) => value !== undefined)
            .forEach(([key, value]) => UrlQueryParams.set(key, encode(value)));
        const result = UrlQueryParams.toString();
        history.push(`${defaultPath}?${result}`);
    }

    function getUrlQuery(
        key: string,
    ): string | null {
        const UrlQueryParams = new URLSearchParams(history.location.search);
        return UrlQueryParams.get(key);
    }

    return {
        setUrlQuery,
        getUrlQuery,
        history,
    };
}
