import React from 'react';
import moment from 'moment';
import cn from 'classnames';
import { observer } from 'mobx-react';

import { timeStore, WEEKDAY_NAMES } from '../../../../Store';
import { EventFilterMenu } from '../../../EventFilterMenu';

import classes from './WeekScheduleHeader.module.scss';

export const WeekScheduleHeader = observer((): JSX.Element => (
    <div className={classes.weekScheduleHeader}>
        <div className={classes.weekScheduleHeader__eventFilterMenu}>
            <EventFilterMenu />
        </div>

        <div className={classes.weekScheduleHeader__dates}>
            {WEEKDAY_NAMES.map((weekdayName, weekdayIndex) => (
                <div className={classes.date} key={weekdayName}>
                    {weekdayName},&nbsp;
                    <span
                        className={cn(
                            classes.dayOfMonth, {
                                [classes.dayOfMonth_isToday]:
                                        moment().isSame(timeStore.selectedDate.clone().weekday(weekdayIndex), 'day'),
                            },
                        )}
                    >
                        {timeStore.selectedDate.clone().weekday(weekdayIndex).format('DD')}
                    </span>
                </div>
            ))}
        </div>
    </div>
));
