import React, { useEffect, useState } from 'react';

import { DatePickerWithTime, DateWithTime } from '@common/DatePickerWithTime';
import { Alert, PlusIconWithWhiteBorder } from '@common';
import { format as tzFormat, format } from 'date-fns-tz';
import { ZoneAvailableInterval } from '@admin/UniversityPage/types';
import { areIntervalsOverlapping as areIntervalsOverlappingDateFns, isAfter } from 'date-fns';
import { PopUpNedoshkovskiy } from '@common/PopUpNedoshkovskiy';
import { ConfirmPopUp } from '@admin/UniversityPage/UniversityZonesFormGroup/ZonesAddAvailableIntervals/ConfirmPopUp';

import { nanoid } from 'nanoid';
import classes from './ZonesAddAvailableIntervals.module.scss';

const defaultTime = {
    from: '00:00',
    to: '00:00',
};

interface ValidationError {
    message: string;
    isValid: boolean;
}

interface Props {
    zoneId: string;
    universityTimeZone: string;
    amountOfRoomsToCurrentZone: number;
    intervalName: string;
    serialNumber: number;
    availableIntervals: ZoneAvailableInterval[];
    handleAvailableIntervals: (
        fromDate: string,
        toDate: string,
    ) => void;
}

export function ZonesAddAvailableIntervals(
    {
        zoneId,
        universityTimeZone,
        amountOfRoomsToCurrentZone,
        intervalName,
        serialNumber,
        availableIntervals,
        handleAvailableIntervals,
    }: Props,
) {
    const {
        canAddInterval,
        dateValidationError,
        isPopUpVisible,
        validationErrors,
        dateWithTime,
        setDateWithTime,
        setCanAddInterval,
        setPopUpVisible,
        updateAvailableIntervals,
        addError,
    } = useZonesAddAvailableIntervals(
        universityTimeZone,
        availableIntervals,
        handleAvailableIntervals,
    );

    return (
        <div className={classes.zonesAvailableIntervals}>
            {
                canAddInterval && (
                    <>
                        <DatePickerWithTime
                            getDateWithTime={(newDateWithTime) => setDateWithTime(newDateWithTime)}
                        />

                        <button
                            type="button"
                            className={classes.zonesAvailableIntervals__button}
                            onClick={() => {
                                if (dateValidationError.isValid) {
                                    setPopUpVisible(true);
                                } else {
                                    addError(dateValidationError.message);
                                }
                            }}
                        >
                            <PlusIconWithWhiteBorder />
                        </button>
                    </>
                )
            }
            {validationErrors}
            {
                isPopUpVisible && (
                    <PopUpNedoshkovskiy>
                        <ConfirmPopUp
                            zoneId={zoneId}
                            intervalName={intervalName}
                            serialNumber={serialNumber}
                            dateWithTime={dateWithTime}
                            universityTimeZone={universityTimeZone}
                            amountOfRoomsToCurrentZone={amountOfRoomsToCurrentZone}
                            updateAvailableIntervals={updateAvailableIntervals}
                            setCanAddInterval={setCanAddInterval}
                            setPopUpVisible={setPopUpVisible}
                        />
                    </PopUpNedoshkovskiy>
                )
            }
        </div>
    );
}

function useZonesAddAvailableIntervals(
    universityTimeZone: string,
    availableIntervals: ZoneAvailableInterval[],
    handleAvailableTime: (
        fromDate: string,
        toDate: string,
    ) => void,
) {
    const [dateValidationError, setDateValidationError] = useState<ValidationError>({
        message: '',
        isValid: false,
    });
    const [canAddInterval, setCanAddInterval] = useState(true);
    const [isPopUpVisible, setPopUpVisible] = useState(false);
    const [validationErrors, setValidationErrors] = useState<JSX.Element[]>([]);
    const [dateWithTime, setDateWithTime] = useState<DateWithTime>(
        {
            fromTime: '00:00',
            fromDate: new Date(),
            toTime: '00:00',
            toDate: new Date(),
        },
    );

    const formattedTimeZone = tzFormat(new Date(), 'xxx', { timeZone: universityTimeZone });

    const normalizedFromTime = dateWithTime.fromTime.length
        ? dateWithTime.fromTime : defaultTime.from;

    const normalizedToTime = dateWithTime.toTime.length
        ? dateWithTime.toTime : defaultTime.to;

    const fromNormalizedDate = `${format(dateWithTime.fromDate ?? new Date(), 'yyyy-MM-dd')} ${normalizedFromTime}${formattedTimeZone}`;
    const toNormalizedDate = `${format(dateWithTime.toDate ?? new Date(), 'yyyy-MM-dd')} ${normalizedToTime}${formattedTimeZone}`;

    const updateAvailableIntervals = () => {
        handleAvailableTime(
            fromNormalizedDate,
            toNormalizedDate,
        );
    };

    const addError = (newMessage: string) => {
        setValidationErrors(
            (prevState) => [
                ...prevState,
                <Alert message={newMessage} time={6000} key={nanoid()} />,
            ],
        );
    };

    useValidateDate(
        dateWithTime,
        availableIntervals,
        setDateValidationError,
    );

    useEffect(() => {
        setCanAddInterval(true);
    }, [canAddInterval]);

    return {
        canAddInterval,
        isPopUpVisible,
        dateValidationError,
        dateWithTime,
        validationErrors,
        setCanAddInterval,
        setDateWithTime,
        setPopUpVisible,
        updateAvailableIntervals,
        addError,
    };
}

function useValidateDate(
    dateWithTime: DateWithTime,
    availableIntervals: ZoneAvailableInterval[],
    setIsDateValid: (validation: ValidationError) => void,
) {
    useEffect(() => {
        const canValidateOverlapping = isAfter(
            new Date(dateWithTime.toDate ?? new Date()),
            new Date(dateWithTime.fromDate ?? new Date()),
        );
        if (canValidateOverlapping) {
            let isDateValidTemp = true;
            availableIntervals.forEach((interval) => {
                const areIntervalsOverlapping = areIntervalsOverlappingDateFns(
                    {
                        start: new Date(dateWithTime.fromDate ?? new Date()),
                        end: new Date(dateWithTime.toDate ?? new Date()),
                    },
                    {
                        start: new Date(interval.from),
                        end: new Date(interval.to),
                    },
                );
                if (areIntervalsOverlapping) {
                    isDateValidTemp = false;
                }
            });

            setIsDateValid({
                message: 'Пересекаются даты ограничения территориальных зон',
                isValid: isDateValidTemp,
            });
        } else {
            setIsDateValid({
                message: 'Дата начала должна быть позже даты конца',
                isValid: false,
            });
        }
    }, [dateWithTime, availableIntervals]);
}
