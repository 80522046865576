import React from 'react';
import cn from 'classnames';

import classes from '../StudentProfile.module.scss';

export const ShowNestSkillArrow = ({ isOpen }: { isOpen: boolean }) => (
    <>
        <div
            className={cn(classes.student__skill_arrow, {
                [classes.student__skill_arrow_reverse]: isOpen,
            })}
        >
            <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M7.1271 5.57168L1.73076 0.17713C1.49397 -0.0590596 1.11034 -0.0590596 0.87295 0.17713C0.636162 0.413321 0.636162 0.796956 0.87295 1.03315L5.84126 5.99966L0.873549 10.9662C0.636761 11.2024 0.636761 11.586 0.873549 11.8228C1.11034 12.059 1.49457 12.059 1.73136 11.8228L7.1277 6.42829C7.36085 6.19455 7.36085 5.80488 7.1271 5.57168Z" fill="url(#paint0_linear_77_603)" />
                <defs>
                    <linearGradient id="paint0_linear_77_603" x1="0.217735" y1="6.68351" x2="8.12867" y2="4.98326" gradientUnits="userSpaceOnUse">
                        <stop offset="0.0416667" stopColor="#0B42DC" />
                        <stop offset="0.130208" stopColor="#0B42DC" />
                        <stop offset="0.734375" stopColor="#57B2C6" />
                        <stop offset="0.932292" stopColor="#EED660" />
                    </linearGradient>
                </defs>
            </svg>

        </div>
    </>
);
