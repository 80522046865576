import { gql } from '@apollo/client';

export const GET_SPACE_BTI = gql`
    query SpaceBaseTimeInterval (
        $btiId: String!, 
    ) {
        spaceBaseTimeInterval(id: $btiId) {
            id
            spaceBaseTimeIntervalSubspaces {
                subspace {
                    id
                    name
                }
                slots {
                    id
                    module {
                        id
                        name
                        minStudentCount
                        maxStudentCount
                        maxWaveCount
                    }
                }
                forks {
                    id
                    nextSlots {
                        id
                        module {
                            id
                            name
                            minStudentCount
                            maxStudentCount
                            maxWaveCount
                        }
                    }
                }
            }
        }
    }
`;

export const GET_DISTRIB_STUDENTS_GROUPED_BY_MODULE = gql`
    query DistributedStudentsGroupedByModule(
        $DistributedStudentsGroupedByModuleInput: DistributedStudentsGroupedByModuleInput!
    ) {
        distributedStudentsGroupedByModule(
            distributedStudentsGroupedByModuleInput: $DistributedStudentsGroupedByModuleInput
        ) {
            module {
                id
                name
                minStudentCount
                maxStudentCount
                maxWaveCount
            }
            students {
                user {
                    id
                    studentId
                    firstName
                    lastName
                    patronymic
                }
            }
        }
    }
`;

export const GET_FORK_UNDISTRIB_STUDENTS = gql`
    query getForkUndistributedStudents (
        $ForkUndistributedStudentsInput: ForkUndistributedStudentsInput!
    ) {
        forkUndistributedStudents (
            forkUndistributedStudentsInput:$ForkUndistributedStudentsInput
        ) {
            student {
                user {
                    id
                    studentId
                    firstName
                    lastName
                    patronymic
                }
            }
            remainingSelectionCount
        }     
    }
`;

export const UPDATE_STUDENT_MODULE = gql`
    mutation updateStudentModule(
        $UpdateStudentModuleInput: UpdateStudentModuleInput!
    ) {
        updateStudentModule(
            updateStudentModuleInput: $UpdateStudentModuleInput
        ) {
            id
        }
    }
`;

export const DELETE_STUDENT_MODULE = gql`
    mutation deleteStudentModule(
        $DeleteStudentModuleInput: DeleteStudentModuleInput!
    ) {
        deleteStudentModule (
            deleteStudentModuleInput: $DeleteStudentModuleInput
        )
    }
`;

export const GET_ALL_STUDENTS_RATING = gql`
    query StudentsRatings($studentsRatingInput: StudentsRatingInput!) {
        studentsRating(studentsRatingInput: $studentsRatingInput) {
            student {
                id
            }
            value
        }
    }
`;

export const GET_PREVIOUS_BTI = gql`
    query PreviousBaseTimeIntervalInstance(
        $baseTimeIntervalInstanceId: String!,
    ) {
        previousBaseTimeIntervalInstance(baseTimeIntervalInstanceId: $baseTimeIntervalInstanceId) {
            id
      }
    }
`;

export const GET_IS_VALID_STUDENTS_MODULE_DISTRIBUTION = gql`
    query GetIsValidStudentsModuleDistribution (
        $baseTimeIntervalInstanceId: String!,
    ) {
        isValidStudentsModuleDistribution (
            baseTimeIntervalInstanceId: $baseTimeIntervalInstanceId
        )
    }
`;

export const GET_BTI_INSTANCE = gql`
    query BaseTimeIntervalInstance(
        $id: String!
    ) {
        baseTimeIntervalInstance(
            id: $id
        ) {
            id      
            selectionStatus
        }
    }
`;
