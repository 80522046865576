import React from 'react';
import cn from 'classnames';
import { observer } from 'mobx-react';

import {
    VotedModuleStatisticAccordion,
} from '@admin/EducationPeriodStatistic/EducationPeriodStatisticVoted/VotedModuleStatisticAccordion';
import { educationPeriodStatisticStore } from '@admin/EducationPeriodStatistic/EducationPeriodStatisticStore';
import { getWordEnding } from '@admin/EducationPeriodStatistic/EducationPeriodStatistic';
import classes from './ModulesStatistics.module.scss';

interface Props {
    isChartVisible: boolean;
}

export const ModulesStatistics = observer((
    {
        isChartVisible,
    }: Props,
): JSX.Element => (
    <div className={classes.modulesStatistic}>

        <div className={classes.modulesStatistic__header}>

            <div className={classes.modulesStatistic__item}>
                { educationPeriodStatisticStore.votedStudentsVotes }
                {' '}
                {
                    getWordEnding(
                        educationPeriodStatisticStore.votedStudentsVotes,
                        {
                            one: 'человек',
                            oneTwoFive: 'человека',
                            tenToTwenty: 'человек',
                        },
                    )
                }
            </div>

            <div className={classes.modulesStatistic__item}>
                Голоса
            </div>

            <div
                className={cn(classes.modulesStatistic__item,
                    classes.modulesStatistic__item_bright)}
            >
                Ср. рейтинг
            </div>

        </div>

        <div
            className={cn(classes.modulesStatistic__accordions, {
                [classes.modulesStatistic__accordions_unScroll]: isChartVisible,
            })}
        >
            <VotedModuleStatisticAccordion
                moduleStatistics={educationPeriodStatisticStore.modulesPrioritiesGroups}
            />
        </div>

    </div>
));
