import { ApolloQueryResult, OperationVariables } from '@apollo/client';

export interface Space {
    id: string,
    name: string,
    baseTimeIntervalType: BaseTimeIntervalType,
    spaceParameters: SpaceParameters,
    spaceEducationPeriods: SpaceEducationPeriod[],
    spaceBaseTimeIntervals: SpaceBaseTimeInterval[],
}

export interface SpaceEducationPeriod {
    id: string,
    name: string,
    spaceBaseTimeIntervalParameters: SpaceBaseTimeIntervalParameters[],
}

export interface SpaceBaseTimeIntervalParameters {
    baseTimeIntervalInstanceId: string,
    spaceBaseTimeIntervalId: string,
    subspaceSelectionStartDate?: string,
    subspaceSelectionEndDate?: string,
    moduleSelectionStartDate?: string,
    moduleSelectionEndDate?: string,
    spaceBaseTimeIntervalStart?: string,
    spaceBaseTimeIntervalEnd?: string,
    moduleAssessment?: ModuleAssessment;
}

export interface ModuleAssessment {
    id: string;
    isDynamicStartDate: boolean;
    startDate?: string;
    endDate?: string;
}

export interface UpdateBTIParametersData {
    educationPeriodId: string;
    spaceId: string;
    name: string;
    spaceBaseTimeIntervalParameters: UpdateSpaceBaseTimeIntervalParameters[];
}

export interface CreateSpaceBaseTimeIntervalParameters {
    baseTimeIntervalInstanceId: string;
    spaceBaseTimeIntervalId: string;
    subspaceSelectionStartDate?: string;
    subspaceSelectionEndDate?: string;
    moduleSelectionStartDate?: string;
    moduleSelectionEndDate?: string;
    spaceBaseTimeIntervalStart?: string;
    spaceBaseTimeIntervalEnd?: string;
}

export interface UpdateSpaceBaseTimeIntervalParameters {
    baseTimeIntervalInstanceId: string;
    spaceBaseTimeIntervalId: string;
    subspaceSelectionStartDate?: string;
    subspaceSelectionEndDate?: string;
    moduleSelectionStartDate?: string;
    moduleSelectionEndDate?: string;
    spaceBaseTimeIntervalStart?: string;
    spaceBaseTimeIntervalEnd?: string;
}

export interface SpaceBaseTimeInterval {
    id: string;
    order: number;
    spaceBaseTimeIntervalSubspaces: SpaceBaseTimeIntervalSubspaces[];
    baseTimeIntervalInstances: BaseTimeIntervalInstance[];
}

export interface SpaceBaseTimeIntervalSubspaces {
    forks: SpaceBaseTimeIntervalSubspacesFork[];
}

export interface SpaceBaseTimeIntervalSubspacesFork {
    id: string;
    setting: {
        distributionType: DistributionType,
    };
}

export enum DistributionType {
    automatically = 'automatically',
    manually = 'manually',
    studentChoice = 'studentChoice',
}

export interface BaseTimeIntervalInstance {
    id: string;
    selectionStatus: SelectionStatusNew;
}

export interface BaseTimeIntervalType {
    id: string,
    name: string,
}

export interface SpaceParameters {
    daySchedules: DaySchedule[],
    studentWorkload: StudentWorkload,
    // selectionPointParameters: SelectionPointParameters,
}

export interface DaySchedule {
    weekDay: (keyof typeof WeekDaysRussian),
    workingIntervals: WorkingInterval[],
}

export interface WorkingInterval {
    id: string,
    start: string,
    end: string,
}

export interface StudentWorkload {
    maxHoursPerDay: number,
    maxDaysPerWeek: number,
    maxHoursPerSpaceBaseTimeInterval: number,
}

// export interface SelectionPointParameters {
//     daysForSubspaceSelection: number,
//     daysForModuleSelection: number,
// }

export enum WeekDaysRussian {
    Monday = 'ПН',
    Tuesday = 'ВТ',
    Wednesday = 'СР',
    Thursday = 'ЧТ',
    Friday = 'ПТ',
    Saturday = 'СБ',
    Sunday = 'ВС',
}

export interface IntervalWeekDay {
    weekDay: (keyof typeof WeekDaysRussian),
    isActive: boolean;
    workingIntervals?: WorkingInterval[];
    interval?: WorkingInterval;
}

export interface IntervalWithWeek {
    interval: WorkingInterval;
    week: IntervalWeekDay[];
}

export enum ParametersNamesRussian {
    subspace = 'Выбор подпространств',
    moduleSelection = 'Выбор модулей',
    module = 'СОП',
}

export type WeekDays = ([(keyof typeof WeekDaysRussian), WorkingInterval[]])[];

export type WeekDay = ([(keyof typeof WeekDaysRussian), WorkingInterval[]]);

export enum Mode {
    VIEW,
    CREATE,
    EDIT,
    DELETE,
}

export enum SelectionStatus {
    SELECTION_PENDING = 'selectionPending',
    SELECTION_ENABLED = 'selectionEnabled',
    SUBSPACE_SELECTION_ENABLED = 'subspaceSelectionEnabled',
    SUBSPACE_SELECTION_INPROCESS = 'subspaceSelectionInProcess',
    SUBSPACE_DISTRIBUTION_ENABLED = 'subspaceDistributionEnabled',
    SUBSPACE_DISTRIBUTION_INPROCESS = 'subspaceDistributioninProcess',
    SUBSPACE_DISTRIBUTION_PENDING = 'subspaceDistributionPending',
    MODULE_SELECTION_ENABLED = 'moduleSelectionEnabled',
    MODULE_SELECTION_INPROCESS = 'moduleSelectionInProcess',
    MODULE_DISTRIBUTION_ENABLED = 'moduleDistributionEnabled',
    MODULE_DISTRIBUTION_INPROCESS = 'moduleDistributioninProcess',
    MODULE_DISTRIBUTION_PENDING = 'moduleDistributionPending',
    SELECTION_FINISHED = 'selectionFinished',
    WAVE_DISTRIBUTION_INPROCESS = 'waveDistributionInProcess',
    WAVE_DISTRIBUTION_APPROVAL_PENDING = 'waveDistributionApprovalPending',
}

export enum SelectionStatusNew {
    moduleDistributionApprovalPending = 'moduleDistributionApprovalPending',
    moduleDistributionEnabled = 'moduleDistributionEnabled',
    moduleDistributionInProcess = 'moduleDistributionInProcess',
    moduleSelectionEnabled = 'moduleSelectionEnabled',
    moduleSelectionInProcess = 'moduleSelectionInProcess',
    selectionEnabled = 'selectionEnabled',
    selectionFinished = 'selectionFinished',
    selectionPending = 'selectionPending',
    subspaceDistributionApprovalPending = 'subspaceDistributionApprovalPending',
    subspaceDistributionEnabled = 'subspaceDistributionEnabled',
    subspaceDistributionInProcess = 'subspaceDistributionInProcess',
    subspaceSelectionEnabled = 'subspaceSelectionEnabled',
    subspaceSelectionInProcess = 'subspaceSelectionInProcess',
    waveDistributionApprovalPending = 'waveDistributionApprovalPending',
    waveDistributionInProcess = 'waveDistributionInProcess',
}

export const russianSelectionStatusNew = {
    [SelectionStatusNew.selectionEnabled]: 'выборность не запущена',
    [SelectionStatusNew.selectionPending]: 'выборность не запущена',
    [SelectionStatusNew.subspaceSelectionEnabled]: 'начало выборности подпространств',
    [SelectionStatusNew.subspaceSelectionInProcess]: 'идет процесс выборности подпространств',
    [SelectionStatusNew.subspaceDistributionEnabled]: 'завершен процесс выбора подпространств',
    [SelectionStatusNew.subspaceDistributionInProcess]: 'запущен процесс предварительного распределения по подпространствам',
    [SelectionStatusNew.subspaceDistributionApprovalPending]: 'завершен процесс предварительного распределения по подпространствам',
    [SelectionStatusNew.moduleSelectionEnabled]: 'начало выборности модулей',
    [SelectionStatusNew.moduleSelectionInProcess]: 'идет процесс выборности модулей',
    [SelectionStatusNew.moduleDistributionEnabled]: 'завершен процесс выборности модулей',
    [SelectionStatusNew.moduleDistributionInProcess]: 'идет процесс предварительного распределения по модулям',
    [SelectionStatusNew.moduleDistributionApprovalPending]: 'завершен процесс предварительного распределения по модулям',
    [SelectionStatusNew.selectionFinished]: 'выборность завершена',
    [SelectionStatusNew.waveDistributionInProcess]: 'идет процесс распределения по потокам',
    [SelectionStatusNew.waveDistributionApprovalPending]: 'завершен процесс распределения по потокам',
};

export const russianSelectionStatus = {
    [SelectionStatus.SELECTION_ENABLED]: 'выборность не запущена',
    [SelectionStatus.SELECTION_PENDING]: 'выборность не запущена',
    [SelectionStatus.SUBSPACE_SELECTION_ENABLED]: 'начало выборности подпространств',
    [SelectionStatus.SUBSPACE_SELECTION_INPROCESS]: 'идет процесс выбора подпространств',
    [SelectionStatus.SUBSPACE_DISTRIBUTION_ENABLED]: 'завершен процесс выбора подпространств',
    [SelectionStatus.SUBSPACE_DISTRIBUTION_INPROCESS]: 'запущен процесс распределения по подпространствам',
    [SelectionStatus.SUBSPACE_DISTRIBUTION_PENDING]: 'завершен процесс предварительного распределения по подпространствам',
    [SelectionStatus.MODULE_SELECTION_ENABLED]: 'начало выборности модулей',
    [SelectionStatus.MODULE_SELECTION_INPROCESS]: 'идет процесс выбора модулей',
    [SelectionStatus.MODULE_DISTRIBUTION_ENABLED]: 'завершен процесс выбора модулей',
    [SelectionStatus.MODULE_DISTRIBUTION_INPROCESS]: 'идет процесс распределения по модулям',
    [SelectionStatus.MODULE_DISTRIBUTION_PENDING]: 'завершен процесс предварительного распределения по модулям',
    [SelectionStatus.SELECTION_FINISHED]: 'выборность завершена',
    [SelectionStatus.WAVE_DISTRIBUTION_INPROCESS]: 'идет процесс распределения по потокам',
    [SelectionStatus.WAVE_DISTRIBUTION_APPROVAL_PENDING]: 'завершить распределения по потокам',
};

export type RefetchGetSpaces = (variables?:
Partial<OperationVariables> | undefined) => Promise<ApolloQueryResult<{
    spaces: Space[];
}>>;

// export type WeekDay = ([(keyof typeof WeekDaysRussian), WorkingInterval[]]);
