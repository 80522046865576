import React, { useState } from 'react';

import { QuestionMarkIcon } from '@common';
import { SwitcherRedesign } from '@common/SwitcherRedesign';
import { AddInterval } from '@admin/RoomsPage/RoomForm/RoomItems/RoomAvailableNew/AddInterval';
import { format } from 'date-fns-tz';
import classes from './RoomAvailableNew.module.scss';
import {
    RepeatingAvailableIntervalInput,
    RoomAvailableIntervalInput,
    RoomsPage_Rooms_rooms_location,
} from '../../../../../graphql-query-types';

interface Props {
    universityTimeZone: string;
    isAvailable: boolean;
    location?: RoomsPage_Rooms_rooms_location;
    availableIntervals: Omit<RoomAvailableIntervalInput, '__typename'>[];
    repeatingAvailableIntervals: RepeatingAvailableIntervalInput[],
    handlerAvailable: React.Dispatch<React.SetStateAction<boolean>>;
    handlerAvailableIntervals: React.Dispatch<React.SetStateAction<
    Omit<RoomAvailableIntervalInput, '__typename'>[]
    >>;
    handlerRepeatingAvailableIntervals: (
        newIntervals: RepeatingAvailableIntervalInput[]
    ) => void;
}

export function RoomAvailableNew(
    {
        universityTimeZone,
        isAvailable,
        location,
        availableIntervals,
        repeatingAvailableIntervals,
        handlerAvailable,
        handlerAvailableIntervals,
        handlerRepeatingAvailableIntervals,
    }: Props,
) {
    const [isAdditionalInfoVisible, setIsAdditionalInfoVisible] = useState(false);

    const headerText = isAvailable ? 'Помещение доступно всегда' : 'Помещение доступно только:';

    const territorialZoneAvailableInfoList = (location
        && location.territorialZone)
        ? location.territorialZone.availableIntervals.map(
            (interval) => {
                const intervalInfo = `${format(new Date(interval.from), 'dd.MM.yyyy')} — ${format(new Date(interval.to), 'dd.MM.yyyy')}`;
                return (
                    <div
                        className={classes.roomAvailable__additional_interval}
                        key={interval.id}
                    >
                        {intervalInfo}
                    </div>
                );
            },
        ) : [];

    const territorialZoneInfo = territorialZoneAvailableInfoList.length ? `Доступ помещения ограничен в соответствии с ограничениями  территориальной зоны «${location?.name}»:` : 'Доступ помещения не ограничен';

    return (
        <div className={classes.roomAvailable}>

            <div className={classes.roomAvailable__header}>

                <div className={classes.roomAvailable__header_text}>
                    <span className={classes.roomAvailable__header_title}>
                        {headerText}
                    </span>

                    <span
                        className={classes.roomAvailable__header_questionMark}
                        onMouseOver={() => setIsAdditionalInfoVisible(true)}
                        onMouseLeave={() => setIsAdditionalInfoVisible(false)}
                        onFocus={() => setIsAdditionalInfoVisible(true)}
                    >
                        <QuestionMarkIcon
                            isHover={isAdditionalInfoVisible}
                        />

                        {
                            isAdditionalInfoVisible && (
                                <div className={classes.roomAvailable__additional}>
                                    <div className={classes.roomAvailable__additional_bold}>
                                        {territorialZoneInfo}
                                    </div>

                                    {territorialZoneAvailableInfoList}
                                </div>
                            )
                        }

                    </span>

                </div>

                <div className={classes.roomAvailable__header_switch}>

                    <SwitcherRedesign
                        onClick={() => {
                            handlerAvailable(!isAvailable);
                        }}
                        checked={!isAvailable}
                    />

                    <span className={classes.roomAvailable__header_switchText}>
                        Ограничить доступ
                    </span>

                </div>

            </div>

            {
                !isAvailable && (
                    <div className={classes.roomAvailable__intervals}>
                        <AddInterval
                            universityTimeZone={universityTimeZone}
                            location={location}
                            defaultAvailableIntervals={availableIntervals}
                            defaultRepeatingAvailableIntervals={repeatingAvailableIntervals}
                            handlerAvailableIntervals={handlerAvailableIntervals}
                            handlerRepeatingAvailableIntervals={handlerRepeatingAvailableIntervals}
                        />
                    </div>
                )
            }

        </div>
    );
}
