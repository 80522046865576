import { useQuery } from '@apollo/client';
import { ScheduleGenerationProcessesSchedulePage } from 'src/graphql-query-types';
import { SCHEDULE_GENERATION_PROCESSES } from '../queries';

export function useScheduleGenerationsQuery(baseTimeIntervalInstanceId: string) {
    const { data, loading, refetch } = useQuery<ScheduleGenerationProcessesSchedulePage>(
        SCHEDULE_GENERATION_PROCESSES,
        {
            variables: { baseTimeIntervalInstanceIds: [baseTimeIntervalInstanceId] },
            fetchPolicy: 'network-only',
            pollInterval: 4500,
        },
    );
    return { scheduleGenerationProcesses: data?.scheduleGenerationProcesses, loading, refetch };
}
