import React from 'react';
import { EducationPageMinMax } from '../EducationPageMinMax';

interface SubspaceTypeLayout {
    id: string;
    spaceBaseTimeIntervalId: string;
    minCreditCount: number;
    maxCreditCount: number;
}

interface IntervalTypes {
    id: string;
    name: string;
}

interface Props {
    spaceBaseTimeIntervals: IntervalTypes[];
    subspaceTypeLayouts: SubspaceTypeLayout[];
    setSubspaceTypeLayouts(arg0: SubspaceTypeLayout[]): void;
}

export const EducationSubspaceTypeFormIntervals = ({
    spaceBaseTimeIntervals,
    subspaceTypeLayouts,
    setSubspaceTypeLayouts,
}: Props) => {
    const onChangeMinMax = (value: number, type: string, index: number) => {
        const interval: SubspaceTypeLayout = {
            id: subspaceTypeLayouts[index].id,
            spaceBaseTimeIntervalId: subspaceTypeLayouts[index].spaceBaseTimeIntervalId,
            minCreditCount: subspaceTypeLayouts[index].minCreditCount,
            maxCreditCount: subspaceTypeLayouts[index].maxCreditCount,
        };

        if (type === 'min') {
            interval.minCreditCount = value;
        } else {
            interval.maxCreditCount = value;
        }
        setSubspaceTypeLayouts([
            ...subspaceTypeLayouts.slice(0, index),
            interval,
            ...subspaceTypeLayouts.slice(index + 1),
        ]);
    };

    const getSpaceBTIName = (index: number) => (
        spaceBaseTimeIntervals.find(
            ({ id }) => id === subspaceTypeLayouts[index].spaceBaseTimeIntervalId,
        )?.name
    );

    const generateLabels = () => {
        const labels: string[] = [];
        let i = 0;
        while (i < subspaceTypeLayouts.length) {
            labels[i] = `Укажите количество ЗЕТ для данного типа подпространств для ${getSpaceBTIName(i)}:`;
            i += 1;
        }
        return labels;
    };

    return (
        <div>
            <EducationPageMinMax
                labels={generateLabels()}
                quantity={subspaceTypeLayouts.length}
                onChangeMinMax={onChangeMinMax}
                values={subspaceTypeLayouts}
            />
        </div>
    );
};
