import React, { useEffect } from 'react';
import { Switcher } from '@common/Switcher';
import { userCardStore } from '@admin/Users/store/userCardStore';

import classes from './AdminInfo.module.scss';
import { AdminRole } from '../models';

interface Props {
    adminRoles: AdminRole[];
    edit?: boolean;
}

export const AdminInfo = ({ adminRoles, edit }: Props): JSX.Element => {
    useEffect(() => {
        userCardStore.adminRoles = adminRoles;
    }, []);
    const sortedRoles = [...adminRoles].sort((a, b) => (a.text > b.text ? 1 : -1));
    const previewRoles = sortedRoles.filter(item => item.enabled).map(item => item.text);
    const switchClick = (adminRole: AdminRole) => {
        userCardStore.adminRoles = userCardStore.adminRoles.map((el: AdminRole) => {
            if (el.name === adminRole.name) {
                return {
                    roleId: el.roleId,
                    name: el.name,
                    text: el.text,
                    enabled: !el.enabled,
                };
            }
            return el;
        });
    };
    return (
        <form className={classes.adminInfo__form}>
            <h2 className={classes.adminInfo__title}>
                Параметры роли Администратор:
            </h2>
            {previewRoles.length === 0 && !edit
                ? <span className={classes.adminInfo__title_normal}>еще не заданы</span> : ''}
            {!edit ? previewRoles.map((item: string) => (
                <div className={classes.adminInfo__roles} key={item}>
                    <p className={classes.adminInfo__role}>{item}</p>
                </div>
            ))
                : sortedRoles.map((adminRole: AdminRole) => (
                    <div key={adminRole.name} className={classes.adminInfo__item}>
                        <Switcher
                            checked={adminRole.enabled}
                            label={adminRole.text}
                            onClick={() => switchClick(adminRole)}
                        />
                    </div>
                ))
            }
            {}
        </form>
    );
};
