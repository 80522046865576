import React from 'react';
/* eslint-disable import/no-cycle */
import { ModuleTabsContent } from '../ModuleTabsContent';

import { Module as ModuleType } from '../../../subSpaceAdmin/subSpaceTypes';

import classes from './ModuleContent.module.scss';

interface Props {
    currentModule: ModuleType;
}

export function ModuleContent({
    currentModule,
}: Props): JSX.Element {
    return (
        <div className={classes.moduleContent}>
            <ModuleTabsContent
                currentModule={currentModule}
            />
        </div>
    );
}
