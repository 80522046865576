type Wave = {
    id: string;
    index: number;
    baseTimeIntervalInstances: {
        id: string;
    }[];
};

export function filterWavesByBaseTimeInterval(
    waves: Wave[],
    baseTimeIntervalInstanceId: string,
) {
    return waves.filter(wave => wave.baseTimeIntervalInstances
        .some((bti) => bti.id === baseTimeIntervalInstanceId));
}
