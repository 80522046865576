import React from 'react';

import { SearchForm, SearchType } from '@admin/Users/Tables/Table/SearchForm';
import { SearchParams } from '../util';

interface Props {
    columnConfigs: {
        name: string;
        searchType: SearchType;
        selectOptions?: {
            name: string,
            id: string;
        }[];
        searchName?: string;
    }[];

    isAllSelected: boolean;
    setSearchParams?: React.Dispatch<React.SetStateAction<SearchParams>>;
    onSelectAll(): void;
    clearPreviousSelected(): void;
}

export const TableHeader = ({
    columnConfigs,
    isAllSelected,
    setSearchParams,
    onSelectAll,
    clearPreviousSelected,
}: Props) => (
    <thead>
        <tr>
            <th rowSpan={2} align="center" style={{ width: '50px' }}>
                <input
                    type="checkbox"
                    checked={isAllSelected}
                    onChange={isAllSelected ? clearPreviousSelected : onSelectAll}
                />
            </th>

            {columnConfigs.map(item => (
                <th key={item.name}>
                    {item.name}
                </th>
            ))}
        </tr>

        <tr>
            {columnConfigs.map(item => (
                <th key={item.name}>
                    <SearchForm
                        setSearchParams={setSearchParams}
                        name={item.searchName}
                        searchType={item.searchType}
                        selectOptions={item.selectOptions}
                    />
                </th>
            ))}
        </tr>
    </thead>
);
