import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { SortUpIcon } from '@common/svg/SortUpIcon';
import { SortDownIcon } from '@common/svg/SortDownIcon';
import { assignmentsStore, OrderBy } from '../../store/AssignmentsStore';
import classes from './Sort.module.scss';

export const Sort = observer(() => {
    const [sort, setSort] = useState(false);

    return (
        <div className={classes.Sort}>
            {
                sort
                    ? <SortDownIcon className={classes.Sort__icon} width={24} height={24} />
                    : <SortUpIcon className={classes.Sort__icon} width={24} height={24} />
            }

            <input
                className={classes.Sort__button}
                type="button"
                value={`По дате окончания: По ${sort ? 'убыванию' : 'возрастанию'}`}
                onClick={() => {
                    setSort(!sort);
                    assignmentsStore.setAssignmentOrder(
                        sort ? OrderBy.deadlineAsc : OrderBy.deadlineDesc,
                    );
                }}
            />
        </div>
    );
});
