/* eslint-disable class-methods-use-this */
import groupBy from 'lodash/groupBy';
import { makeAutoObservable } from 'mobx';

import {
    Teacher,
    Equipment,
    ConsumableEquipment,
    MeetingFormat,
    Duration,
    ConfigSkill,
    Skill,
    SkillType,
    StaticOptions,
    EquipmentRequestMessage,
} from './moduleStoreTypes';

class DictionaryStore {
    teachers: Teacher[] = [];

    equipment: { list: Equipment[] } = { list: [] };

    consumableEquipment: ConsumableEquipment[] = [];

    meetingFormats: MeetingFormat[] = [];

    durations: Duration[] = [];

    moduleSettings: {
        moduleSkillTypes: ConfigSkill[];
        meetingSkillTypes: ConfigSkill[];
    } = { moduleSkillTypes: [], meetingSkillTypes: [] };

    skills: Skill[] = [];

    skillTypes: SkillType[] = [];

    skillsForType: { id: string; skills: Skill[] }[] = [];

    equipmentRequestMessage?: EquipmentRequestMessage;

    constructor() {
        makeAutoObservable(this);
    }

    updateStaticOptions = (data: Partial<StaticOptions>): void => {
        Object.assign(this, this.getRefactorStaticOptionsFromApollo(data));
    };

    private getRefactorStaticTeachers = (teachers: Teacher[]): Teacher[] => teachers
        ?.map(({ id, user }) => ({
            id, name: `${user?.lastName} ${user?.firstName} ${user?.patronymic}`,
        }));

    private getRefactorConfigSkills = (
        skills: ConfigSkill[], isLevelOutput: boolean,
    ): ConfigSkill[] => skills.map(({ id, isLevelIncreased }) => ({
        id, isLevelIncreased, isLevelRequired: isLevelIncreased, isLevelOutput,
    }));

    private getRefactorSkillsForType = (
        skills: Skill[], skillTypes: SkillType[],
    ): { id: string; skills: Skill[] }[] => skillTypes.map(skillType => {
        const finalSkillTypeId = this.getFinalSkillTypeId(skillType.id);

        return {
            id: skillType.id,
            skills: skills.filter(skill => skill.typeId === finalSkillTypeId),
        };
    });

    public getRefactorStaticOptionsFromApollo = (
        data: Partial<StaticOptions>,
    ): Partial<StaticOptions> => ({
        ...data,
        teachers: this.getRefactorStaticTeachers(data?.teachers ?? []),
        moduleSettings: {
            ...data?.moduleSettings,
            moduleSkillTypes: this.getRefactorConfigSkills(
                data?.moduleSettings?.moduleSkillTypes ?? [], true,
            ),
            meetingSkillTypes: this.getRefactorConfigSkills(
                data?.moduleSettings?.meetingSkillTypes ?? [], false,
            ),
        },
        skillsForType: this.getRefactorSkillsForType(data?.skills ?? [], data?.skillTypes ?? []),
    });

    getSkillType = (typeId: string) => ({
        ...[...this.moduleSettings.moduleSkillTypes, ...this.moduleSettings.meetingSkillTypes]
            .find(skillType => skillType.id === typeId),
        ...this.skillTypes.find(skillType => skillType.id === typeId),
    });

    getSkillTypeIdBySkillId = (skillId: string): string | undefined => this.skills
        .find(skill => skill.id === skillId)?.typeId;

    getSkillsForType = (typeId: string): Skill[] => {
        const finalSkillTypeId = this.getFinalSkillTypeId(typeId);
        return this.skillsForType.find(type => type.id === finalSkillTypeId)?.skills ?? [];
    };

    getInitSkillTypeId(typeId: string): string {
        const skillTypeGroups = groupBy(this.skillTypes, 'id');
        let parent = typeId;
        let initId = typeId;
        while (skillTypeGroups[parent]) {
            const [child] = skillTypeGroups[parent];
            parent = child.parentId || '';
            initId = child.id;
        }

        return initId;
    }

    getFinalSkillTypeId = (typeId: string): string => {
        const skillTypeGroups = groupBy(this.skillTypes, 'parentId');
        let finalSkillTypeId = typeId;
        while (skillTypeGroups[finalSkillTypeId]) {
            const [child] = skillTypeGroups[finalSkillTypeId];
            finalSkillTypeId = child.id;
        }

        return finalSkillTypeId;
    };
}

export const dictionaryStore = new DictionaryStore();
