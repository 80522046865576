import React, { useEffect } from 'react';
import { Select } from '../Select';

type Option = {
    id: string;
    name: string;
};

export const SelectFormInitialData = {
    id: '',
    name: '',
};

interface Props {
    label: string;
    mainOptions: Option[];
    data: Option;
    isNumber: boolean;
    placeholder?: string;

    setData(data: Option): void;

    onChange?(): void;
}

export function SelectForm({
    mainOptions,
    label,
    isNumber,
    data,
    setData,
    placeholder,
    onChange = () => {},
}: Props) {
    useEffect(() => {
        onChange();
    }, [data]);
    return (
        <Select
            name={data.id}
            label={label}
            value={data.id ?? ''}
            onChange={({ target }) => {
                setData({
                    ...data,
                    name: target.options[target.selectedIndex].text,
                    id: target.value,
                });
            }}
            options={mainOptions}
            isNumber={isNumber}
            placeholder={placeholder}
        />
    );
}
