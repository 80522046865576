import React from 'react';

export function PlusIconWithWhiteBorder() {
    return (
        <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13 13H8.2Z" fill="#0030B7" />
            <path d="M13 25C19.6274 25 25 19.6274 25 13C25 6.37258 19.6274 1 13 1C6.37258 1 1 6.37258 1 13C1 19.6274 6.37258 25 13 25Z" fill="#0030B7" />
            <path d="M13 8.2V13M13 13V17.8M13 13H17.8M13 13H8.2M25 13C25 19.6274 19.6274 25 13 25C6.37258 25 1 19.6274 1 13C1 6.37258 6.37258 1 13 1C19.6274 1 25 6.37258 25 13Z" stroke="white" strokeWidth="2" strokeLinecap="round" />
        </svg>
    );
}
