import React, { ReactNode } from 'react';
import { SpriteSvg } from '@common/SpriteSvgDeprecated';
import cn from 'classnames';
import classes from './AddRemoveButton.module.scss';

interface Props {
    className?: string;
    type?: 'add' | 'remove';
    disabled?: boolean;
    children?: ReactNode;

    onClick(): void;
}

export function AddRemoveButton({
    className,
    type = 'add',
    disabled = false,
    children,
    onClick,
}: Props): JSX.Element {
    return (
        <button
            disabled={disabled}
            className={cn(classes.addRemoveButton, {
                [`${className}`]: className,
                [`${classes.addRemoveButton_disabled}`]: disabled,
            })}
            type="button"
            onClick={onClick}
        >
            <SpriteSvg
                disabled={disabled}
                className={classes.addRemoveButton__icon}
                name={type === 'add' ? 'circlePlus' : 'circleCross'}
            />
            <p className={classes.addRemoveButton__title}>{children}</p>
        </button>
    );
}
