import React, { useState } from 'react';

import { VotedChartAccordion } from '@admin/EducationPeriodStatistic/EducationPeriodStatisticVoted/VotedChartAccordion';
import { ModulesStatistics } from '@admin/EducationPeriodStatistic/EducationPeriodStatisticVoted/ModulesStatistics';

import { SelectionStatus } from '@admin/EducationPeriodStatistic/EducationPeriodStatisticInterfaces';
import classes from './EducationPeriodStatisticVoted.module.scss';

export interface ModuleStudent {
    studentName: string;
    rating: string;
    studentPriorities: string[];
}

export interface ModulePriorities {
    name: string;
    votes: string;
    rating: string;
    students: ModuleStudent[];
}

export interface ModuleStatistic {
    moduleName: string,
    votes: string;
    rating: string;
    priorities: ModulePriorities[];
}

interface Props {
    dateDiff: number;
    dateEnd: string;
    selectionStatus?: SelectionStatus;
}

export function EducationPeriodStatisticVoted(
    {
        dateEnd,
        dateDiff,
        selectionStatus,
    } : Props,
) {
    const [isChartOpen, setChartOpen] = useState(true);
    return (
        <div className={classes.voted}>
            <div className={classes.voted__overflow}>

                <VotedChartAccordion
                    isChartOpen={isChartOpen}
                    dateDiff={dateDiff}
                    dateEnd={dateEnd}
                    setChartOpen={setChartOpen}
                    selectionStatus={selectionStatus}
                />

                {
                    !isChartOpen && (
                        <ModulesStatistics
                            isChartVisible={isChartOpen}
                        />
                    )
                }

            </div>
        </div>
    );
}
