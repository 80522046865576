import { Subspace, Trajectory } from '@admin/Trajectories/types';
import { makeAutoObservable } from 'mobx';

class TrajectoryStore {
    name: string = '';

    description: string | undefined;

    selectedSubspaces: Subspace[] = [];

    trajectories: Trajectory[] = [];

    allTrajectoriesSelected: boolean = false;

    selectedTrajectoriesIds: string[] = [];

    spaceId: string = '';

    constructor() {
        makeAutoObservable(this);
    }

    setName(name: string) {
        this.name = name;
    }

    setDescription(description?: string) {
        if (description === '') {
            this.description = undefined;
        } else {
            this.description = description;
        }
    }

    updateSubspaces = (subspace: Subspace) => {
        this.selectedSubspaces = [...this.selectedSubspaces, subspace];
    };

    removeSubspaces = (supspace: Subspace) => {
        const newSelectedSubspaces = this.selectedSubspaces
            .filter(item => item!.id !== supspace.id);
        this.selectedSubspaces = newSelectedSubspaces;
        if (!this.selectedSubspaces.length) {
            this.description = undefined;
            this.name = '';
        }
    };

    clearInputs = () => {
        this.description = undefined;
        this.name = '';
        this.selectedSubspaces = [];
    };

    updateTrajectories = (trajectoies: Trajectory[]) => {
        this.trajectories = trajectoies;
    };

    toggleAllTrajectoriesSelected = () => {
        this.allTrajectoriesSelected = !this.allTrajectoriesSelected;
        if (this.allTrajectoriesSelected) {
            this.selectAll();
        } else {
            this.unselectAll();
        }
    };

    selectAll = () => {
        const allTrajectoriesIds = this.trajectories.map(item => item.id);
        this.selectedTrajectoriesIds = allTrajectoriesIds;
    };

    unselectAll = () => {
        this.selectedTrajectoriesIds = [];
        this.allTrajectoriesSelected = false;
    };

    updateSelectedIds = (id: string) => {
        const isSelected = this.selectedTrajectoriesIds.includes(id);
        if (isSelected) {
            this.unselectTrajectory(id);
        } else {
            this.selectTrajectory(id);
        }
        this.changeSelectAllIcon();
    };

    selectTrajectory = (id: string) => {
        this.selectedTrajectoriesIds = [...this.selectedTrajectoriesIds, id];
    };

    unselectTrajectory = (id: string) => {
        const newIds = this.selectedTrajectoriesIds.filter(item => item !== id);
        this.selectedTrajectoriesIds = newIds;
    };

    changeSelectAllIcon = () => {
        if (this.selectedTrajectoriesIds.length === this.trajectories.length) {
            this.allTrajectoriesSelected = true;
        } else {
            this.allTrajectoriesSelected = false;
        }
    };

    updateSpaceId(spaceId: string) {
        this.spaceId = spaceId;
    }
}

export const trajectoryStore = new TrajectoryStore();
