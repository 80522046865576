import React from 'react';
import { Equipment } from '../equipmentTypes';

import classes from './EquipmentTableItem.module.scss';

interface Props {
    item: Equipment;
}

export function EquipmentTableItem({ item }: Props): JSX.Element {
    return (
        <div className={classes.equipmentTableItem}>
            <div className={classes.equipmentTableItem__name}>
                {item.consumableEquipment.name}
            </div>
            <div className={classes.equipmentTableItem__unit}>
                {item.consumableEquipment.unit}
            </div>
            <div className={classes.equipmentTableItem__count}>
                {item.count}
            </div>
        </div>
    );
}
