import React from 'react';
import cn from 'classnames';

import { CheckboxRedesign } from '@common/CheckboxRedesign';
import {
    ApolloPromise, IntervalInfo,
    ModuleGroupWithAdditionalFields, RefetchIsModuleDistributionValid,
    StudentWithAdditionalFields,
} from '@admin/ModuleSelectionPage/Interfaces';

import {
    checkIfStudentIsUnique,
    getStudentCurrentModuleId,
    getStudentRating,
} from '@admin/ModuleSelectionPage/ModuleSelectionPage';
import { observer } from 'mobx-react';
import { UpdateStudentModuleInput } from '@admin/ModuleSelectionPage/graphql-types';
import { moduleSelectionStore } from '@admin/ModuleSelectionPage/ModuleSelectionStore';

import { moduleSelectionDataStore } from '@admin/ModuleSelectionPage/ModuleSelectionDataStore';
import { MinInterval } from '@admin/ModuleSelectionPage/MinInterval';
import { MaxInterval } from '@admin/ModuleSelectionPage/MaxInterval';
import classes from '../ModuleStudentItem/ModuleStudentItem.module.scss';

interface Props {
    order: number;
    disabled: boolean;
    minIntervalInfo: IntervalInfo;
    maxIntervalInfo: IntervalInfo;
    currentModuleGroup: ModuleGroupWithAdditionalFields;
    currentStudent: StudentWithAdditionalFields;
    students: StudentWithAdditionalFields[];
    updateStudentModule(input: UpdateStudentModuleInput): ApolloPromise;
    refetchIsValidStudentsModuleDistribution: RefetchIsModuleDistributionValid;
}

export const ModuleAdditionalItem = observer((
    {
        order,
        disabled,
        minIntervalInfo,
        maxIntervalInfo,
        currentModuleGroup,
        currentStudent,
        students,
        updateStudentModule,
        refetchIsValidStudentsModuleDistribution,
    } : Props,
): JSX.Element => {
    const {
        baseTimeIntervalInstanceId,
        forkId,
    } = moduleSelectionDataStore.getURLParameters();

    const { user } = currentStudent;

    const studentRating = getStudentRating(
        user.studentId ?? '',
        moduleSelectionStore.getStudentsWithRating(),
    );

    const isStudentFromModules = checkIfStudentFromModules(
        currentStudent.user.studentId ?? '',
        moduleSelectionStore.getModuleGroupsWithAdditionalFields(),
    );

    const isStudentUnique = checkIfStudentIsUnique(
        currentStudent,
        moduleSelectionStore.getNotDistribStudentsWithAdditionalFields(),
        moduleSelectionStore.getModuleGroupsWithAdditionalFields(),
    );

    const isMinIntervalNotValid = students.length < currentModuleGroup.module.minStudentCount;

    const studentInfoWithOrder = `${order} ${user.lastName} ${user.firstName} ${user.patronymic ?? ''} ${isStudentUnique ? '' : user.studentId}`;
    const studentInfo = `${order} ${user.lastName} ${user.firstName} ${user.patronymic ?? ''} ${isStudentUnique ? '' : user.studentId}`;

    const handleChange = () => {
        const movedStudentsNoCurrentStudent = moduleSelectionStore.getMovedStudents().filter(
            (student) => student.user.studentId !== currentStudent.user.studentId,
        );

        const notDistributedStudentsNoCurrentStudent = moduleSelectionStore
            .getNotDistribStudentsWithAdditionalFields().filter(
                (student) => student.user.studentId !== currentStudent.user.studentId,
            );

        const studentPreviousModuleId = getStudentCurrentModuleId(
            currentStudent.user.studentId ?? '',
            moduleSelectionStore
                .getModuleGroupsWithAdditionalFields(),
        );

        updateStudentModule(
            {
                baseTimeIntervalInstanceId,
                forkId,
                moduleId: currentModuleGroup.module.id,
                studentId: currentStudent.user.studentId ?? '',
                previousModuleId: studentPreviousModuleId ?? null,
            },
        )
            .then((result) => {
                if (result && !result.errors) {
                    moduleSelectionStore.setMovedStudents(movedStudentsNoCurrentStudent);

                    moduleSelectionStore.setModuleGroupsWithAdditionalFields(
                        getModulesStudentsWithAddedNewStudents(
                            currentModuleGroup.module.id,
                            currentStudent,
                            moduleSelectionStore.getModuleGroupsWithAdditionalFields(),
                        ),
                    );

                    moduleSelectionStore.setNotDistribStudentsWithAdditionalFields(
                        notDistributedStudentsNoCurrentStudent,
                    );
                }

                refetchIsValidStudentsModuleDistribution()
                    .then((refetchResult) => {
                        if (refetchResult && !refetchResult.errors) {
                            moduleSelectionDataStore
                                .setIsModuleDistributionValid(
                                    refetchResult.data.isValidStudentsModuleDistribution,
                                );
                        }
                    });
            });
    };

    return (
        <>
            <div
                className={cn(classes.item, {
                    [classes.item_fromModule]: isStudentFromModules,
                    [classes.item_notFromModule]: !isStudentFromModules,
                    [classes.item_disabled]: disabled,
                })}
            >
                {
                    disabled ? (
                        <div
                            className={cn(
                                classes.item__static_red,
                                classes.item__static_disabled,
                            )}
                        >

                            <div
                                className={cn(classes.item__name, classes.noOpacity)}
                            >
                                { studentInfoWithOrder }
                            </div>

                            <div className={classes.item__rating}>
                                { studentRating }
                            </div>

                        </div>
                    ) : (
                        <>
                            <div
                                className={cn(classes.item__active, classes.displayBlock, {
                                    [classes.item_borderBlue]:
                                        currentModuleGroup.module.maxStudentCount === order
                                    && !isMinIntervalNotValid,
                                    [classes.item_borderRed]:
                                        currentModuleGroup.module.minStudentCount === order,
                                    [classes.item_borderTop]:
                                        (order === 1 && students.length === 0),
                                })}
                            >
                                <CheckboxRedesign
                                    id={`${currentStudent.user.id}${currentModuleGroup.module.id}_${order}_${currentStudent.user.studentId}`}
                                    gap="7px"
                                    handleChangeCheckbox={() => {
                                        handleChange();
                                    }}
                                    checked={currentStudent.isSelected}
                                >
                                    <div className={classes.item__label}>

                                        <div
                                            className={cn(classes.item__name, classes.noOpacity)}
                                        >
                                            {studentInfo}
                                        </div>

                                        <div className={classes.item__rating}>
                                            { studentRating }
                                        </div>

                                    </div>
                                </CheckboxRedesign>
                            </div>
                        </>
                    )
                }

            </div>

            <MinInterval
                interval={currentModuleGroup.module.minStudentCount}
                isRed
                isVisible={currentModuleGroup.module.minStudentCount === order}
                studentInfo={minIntervalInfo.studentInfo}
                wavesInfo={minIntervalInfo.wavesInfo}
            />

            <MaxInterval
                interval={currentModuleGroup.module.maxStudentCount}
                isRed={false}
                isVisible={currentModuleGroup.module.maxStudentCount === order
                    && !isMinIntervalNotValid}
                studentInfo={maxIntervalInfo.studentInfo}
                wavesInfo={maxIntervalInfo.wavesInfo}
            />
        </>
    );
});

function getModulesStudentsWithAddedNewStudents(
    moduleId: string,
    newStudent: StudentWithAdditionalFields,
    moduleGroups: ModuleGroupWithAdditionalFields[],
) {
    return moduleGroups.map((moduleGroup) => {
        if (moduleGroup.module.id !== moduleId) {
            return {
                ...moduleGroup,
                students: moduleGroup.students.filter(
                    (student) => student.user.studentId !== newStudent.user.studentId,
                ),
                additionalStudents: removeAdditionalStudent(
                    newStudent.user.studentId ?? '',
                    moduleGroup.additionalStudents,
                ),
            };
        }
        return {
            ...moduleGroup,
            students: [
                ...moduleGroup.students,
                { ...newStudent, isSelected: false, wasMoved: true },
            ],
            additionalStudents: removeAdditionalStudent(newStudent.user.studentId ?? '', moduleGroup.additionalStudents),
        };
    });
}

function checkIfStudentFromModules(
    currentStudentId: string,
    moduleGroups: ModuleGroupWithAdditionalFields[],
) {
    return moduleGroups.find((group) => (
        group.students.some((student) => (
            student.user.studentId === currentStudentId
        ))
    ));
}

function removeAdditionalStudent(
    studentId: string,
    additionalStudents: StudentWithAdditionalFields[],
) {
    return additionalStudents.filter((student) => student.user.studentId !== studentId);
}
