import React, { useState } from 'react';
import { ApolloError, gql, useMutation, useQuery } from '@apollo/client';
import { Redirect } from 'react-router-dom';
import { nanoid } from 'nanoid';
import { Alert } from '@common/Alert';
import { Loader } from '@common/Loader';
import { EducationPeriodPage } from './EducationPeriodPage';
import {
    CreateSpaceBaseTimeIntervalParameters,
    DaySchedule,
    Mode,
    Space,
    StudentWorkload,
    UpdateSpaceBaseTimeIntervalParameters,
} from './EducationPeriodPageInterfaces';

const GET_SPACES = gql`
    query getSpaces {
        spaces {
          id
          name
          baseTimeIntervalType {
            id
            name
          }
          spaceBaseTimeIntervals {
            id
            order
            spaceBaseTimeIntervalSubspaces {
                forks {
                    id
                }
            }
            baseTimeIntervalInstances {
                id
                selectionStatus
            }
          }
          spaceParameters {
            daySchedules {
              weekDay
              workingIntervals {
                id
                start
                end
              }
            }
            studentWorkload {
              maxHoursPerDay
              maxDaysPerWeek
              maxHoursPerSpaceBaseTimeInterval
            }
          }
          spaceEducationPeriods {
            id
            name
            spaceBaseTimeIntervalParameters {
              spaceBaseTimeIntervalId
              baseTimeIntervalInstanceId
              subspaceSelectionStartDate
              subspaceSelectionEndDate
              moduleSelectionStartDate
              moduleSelectionEndDate
              spaceBaseTimeIntervalStart
              spaceBaseTimeIntervalEnd
              moduleAssessment {
                  id
                  isDynamicStartDate
                  startDate
                  endDate
              }
            }
          }
        }
      }
`;

const UPDATE_PARAMETERS = gql`
    mutation updateSpaceParameters($updateSpaceParametersInput: UpdateSpaceParametersInput!) {
        updateSpaceParameters(updateSpaceParametersInput: $updateSpaceParametersInput) {
            daySchedules {
                weekDay
            }
        }
    }
`;

const UPDATE_PERIOD = gql`
    mutation updateSpaceEducationPeriod($updateSpaceEducationPeriodInput: UpdateSpaceEducationPeriodInput!) {
        updateSpaceEducationPeriod(updateSpaceEducationPeriodInput: $updateSpaceEducationPeriodInput) {
            id,
            name
        }
    }
`;
const CREATE_PERIOD = gql`
    mutation createSpaceEducationPeriod($createSpaceEducationPeriodInput: CreateSpaceEducationPeriodInput!) {
        createSpaceEducationPeriod(createSpaceEducationPeriodInput: $createSpaceEducationPeriodInput) {
            id,
            name
        }
    }
`;

export function EducationPeriodPageApollo() {
    const [datesMode, setDatesMode] = useState<Mode>(Mode.EDIT);
    const [redirect, setRedirect] = useState<JSX.Element | null>(null);

    const [
        spaceParametersErrorAlerts,
        setSpaceParametersErrorAlerts,
    ] = useState<JSX.Element[]>([]);
    const addError = (message: string) => setSpaceParametersErrorAlerts((arr) => [...arr, (<Alert
        key={nanoid()}
        message={message}
        time={7000}
    />)]);

    const redirectToSpaces = () => setTimeout(
        () => setRedirect((<Redirect to="/educational-space/new" />)),
        2500,
    );

    const isGetSpacesCompleted = () => {
        if (!(data?.spaces?.length)) {
            addError('Нужно создать пространства');
            redirectToSpaces();
        }
    };

    const {
        data,
        loading: isSpaceLoading,
        refetch: refetchGetSpaces,
    } = useQuery<{
        spaces: Space[];
    }>(GET_SPACES, {
        fetchPolicy: 'cache-and-network',
        onError: (error) => addError(error.message),
        onCompleted: isGetSpacesCompleted,
    });

    const apolloParametersMutationProps = {
        refetchQueries: [{ query: GET_SPACES }],
        onError: (error: ApolloError) => addError(error.message),
    };

    const apolloDatesMutationProps = {
        ...apolloParametersMutationProps,
    };

    const [updateParametersMutation] = useMutation(
        UPDATE_PARAMETERS,
        apolloParametersMutationProps,
    );

    const [updatePeriodMutation] = useMutation(UPDATE_PERIOD, apolloDatesMutationProps);
    const [createPeriodMutation] = useMutation(CREATE_PERIOD, apolloDatesMutationProps);

    const updateParameters = (
        spaceId: string,
        daySchedules: DaySchedule[],
        studentWorkload: StudentWorkload,
    ) => updateParametersMutation({
        variables: {
            updateSpaceParametersInput: {
                spaceId,
                daySchedules,
                studentWorkload,
            },
        },
    }).catch((error) => addError(JSON.stringify(error)));

    const updatePeriod = (
        id: string,
        spaceId: string,
        name: string,
        spaceBaseTimeIntervalParameters: UpdateSpaceBaseTimeIntervalParameters[],
    ) => updatePeriodMutation({
        variables: {
            updateSpaceEducationPeriodInput: {
                id,
                spaceId,
                name,
                spaceBaseTimeIntervalParameters,
            },
        },
    }).catch((error) => addError(JSON.stringify(error)));

    const createPeriod = (
        id: string,
        spaceId: string,
        name: string,
        spaceBaseTimeIntervalParameters: CreateSpaceBaseTimeIntervalParameters[],
    ) => createPeriodMutation({
        variables: {
            createSpaceEducationPeriodInput: {
                id,
                spaceId,
                name,
                spaceBaseTimeIntervalParameters,
            },
        },
    }).catch((error) => {
        addError(JSON.stringify(error));
        setDatesMode(Mode.CREATE);
    });

    return (
        <>
            {
                isSpaceLoading ? (
                    <Loader />
                ) : (
                    <>
                        {
                            (data && data.spaces.length) ? (
                                <EducationPeriodPage
                                    spaces={data.spaces}
                                    datesMode={datesMode}
                                    updateParameters={updateParameters}
                                    createPeriod={createPeriod}
                                    updatePeriod={updatePeriod}
                                    setDatesMode={setDatesMode}
                                    addError={addError}
                                    refetchGetSpaces={refetchGetSpaces}
                                />
                            ) : 'Пространства не заданы'
                        }
                    </>
                )
            }

            {spaceParametersErrorAlerts}
            {redirect}
        </>
    );
}
