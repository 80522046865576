import { Type } from '../ToolBar/Tools/apollo-types';

export const userRoles = [
    {
        type: 'userRoles',
        name: 'Студент',
        value: Type.student,
        id: '1',
    },
    {
        type: 'userRoles',
        name: 'Преподаватель',
        value: Type.teacher,
        id: '2',
    },
    {
        type: 'userRoles',
        name: 'Администратор',
        value: Type.admin,
        id: '3',
    },
];
