import React from 'react';
import cn from 'classnames';

import classes from './GoButton.module.scss';

interface Props {
    isRotated?: boolean,
    isDisabled?: boolean,
}

export function GoButton({ isRotated, isDisabled }: Props): JSX.Element | null {
    if (isDisabled) {
        return (
            <div
                className={
                    cn(
                        classes.goButton,
                        { [`${classes.goButton_rotated}`]: isRotated },
                    )
                }
            >
                <svg width="18" height="12" viewBox="0 0 18 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M17.75 6.00079C17.75 6.16655 17.6842 6.32552 17.5669 6.44273C17.4497 6.55994 17.2908 6.62579 17.125 6.62579L2.38375 6.62579L6.3175 10.5583C6.43486 10.6756 6.50079 10.8348 6.50079 11.0008C6.50079 11.1668 6.43486 11.3259 6.3175 11.4433C6.20014 11.5606 6.04097 11.6266 5.875 11.6266C5.70903 11.6266 5.54986 11.5606 5.4325 11.4433L0.432499 6.44329C0.374296 6.38523 0.328117 6.31626 0.29661 6.24033C0.265102 6.1644 0.248882 6.083 0.248882 6.00079C0.248882 5.91858 0.265102 5.83718 0.29661 5.76125C0.328117 5.68532 0.374296 5.61635 0.432499 5.55829L5.4325 0.558289C5.54986 0.440931 5.70903 0.375 5.875 0.375C6.04097 0.375 6.20014 0.440931 6.3175 0.558289C6.43486 0.675648 6.50079 0.83482 6.50079 1.00079C6.50079 1.16676 6.43486 1.32593 6.3175 1.44329L2.38375 5.37579L17.125 5.37579C17.2908 5.37579 17.4497 5.44164 17.5669 5.55885C17.6842 5.67606 17.75 5.83503 17.75 6.00079Z" fill="url(#paint0_linear_1846:876)" />
                    <defs>
                        <linearGradient id="paint0_linear_1846:876" x1="10.384" y1="-0.506148" x2="10.3218" y2="12.8471" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#C4C4C4" stopOpacity="0.04" />
                            <stop offset="0.453381" stopColor="#BEBEBE" stopOpacity="0.85" />
                            <stop offset="0.901043" stopColor="#98A4A5" stopOpacity="0.43" />
                        </linearGradient>
                    </defs>
                </svg>
            </div>
        );
    }
    return (
        <div
            className={
                cn(
                    classes.goButton,
                    { [`${classes.goButton_rotated}`]: isRotated },
                )
            }
        >
            <svg width="18" height="12" viewBox="0 0 18 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M17.75 6.00079C17.75 6.16655 17.6842 6.32552 17.5669 6.44273C17.4497 6.55994 17.2908 6.62579 17.125 6.62579L2.38375 6.62579L6.3175 10.5583C6.43486 10.6756 6.50079 10.8348 6.50079 11.0008C6.50079 11.1668 6.43486 11.3259 6.3175 11.4433C6.20014 11.5606 6.04097 11.6266 5.875 11.6266C5.70903 11.6266 5.54986 11.5606 5.4325 11.4433L0.432499 6.44329C0.374296 6.38523 0.328117 6.31626 0.29661 6.24033C0.265102 6.1644 0.248882 6.083 0.248882 6.00079C0.248882 5.91858 0.265102 5.83718 0.29661 5.76125C0.328117 5.68532 0.374296 5.61635 0.432499 5.55829L5.4325 0.558289C5.54986 0.440931 5.70903 0.375 5.875 0.375C6.04097 0.375 6.20014 0.440931 6.3175 0.558289C6.43486 0.675648 6.50079 0.83482 6.50079 1.00079C6.50079 1.16676 6.43486 1.32593 6.3175 1.44329L2.38375 5.37579L17.125 5.37579C17.2908 5.37579 17.4497 5.44164 17.5669 5.55885C17.6842 5.67606 17.75 5.83503 17.75 6.00079Z" fill="url(#paint0_linear_1846:778)" />
                <defs>
                    <linearGradient id="paint0_linear_1846:778" x1="9.99634" y1="12.4399" x2="6.66887" y2="-0.819177" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#0B42DC" />
                        <stop offset="0.0416667" stopColor="#0B42DC" />
                        <stop offset="0.130208" stopColor="#0B42DC" />
                        <stop offset="0.734375" stopColor="#57B2C6" />
                        <stop offset="0.932292" stopColor="#EED660" />
                    </linearGradient>
                </defs>
            </svg>
        </div>
    );
}
