import React from 'react';
import {
    Module as ModuleInterface,
} from '../../RoleConflictsDashboardInterfaces';
import classes from './Module.module.scss';
import { RolesList } from './RolesList';

interface Props {
    module: ModuleInterface,
    index: number,
}

const MODULE_NAME_START_COLUMN_LINE = 1;
const MODULE_NAME_END_COLUMN_LINE = 2;
const DIFF_BETWEEN_MODULE_INDEX_AND_START_ROW_LINE = 1;
const DIFF_BETWEEN_MODULE_INDEX_AND_END_ROW_LINE = 1 + DIFF_BETWEEN_MODULE_INDEX_AND_START_ROW_LINE;

export const Module = ({ module, index }: Props): JSX.Element => (
    <>
        <div
            className={classes.module__name}
            style={{
                gridColumnStart: MODULE_NAME_START_COLUMN_LINE,
                gridColumnEnd: MODULE_NAME_END_COLUMN_LINE,
                gridRowStart: index + DIFF_BETWEEN_MODULE_INDEX_AND_START_ROW_LINE,
                gridRowEnd: index + DIFF_BETWEEN_MODULE_INDEX_AND_END_ROW_LINE,
                height: `${module.teachersRoles.length * 19}px`,
            }}
        >
            {module.name}
        </div>
        <RolesList roles={module.teachersRoles} index={index} />
    </>
);
