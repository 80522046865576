import React from 'react';

import { StudentWorkload as WorkLoad } from '@admin/EducationPeriodPage/EducationPeriodPageInterfaces';

import classes from './StudentWorkload.module.scss';

interface Props {
    workLoad: WorkLoad;
}

export function StudentWorkload({ workLoad }: Props) {
    return (
        <div className={classes.studentWorkload}>

            <div className={classes.studentWorkload__title}>
                Занятость студента
            </div>

            <div>
                Часы в день: {workLoad.maxHoursPerDay}
                <br />
                Дни в неделю: {workLoad.maxDaysPerWeek}
                <br />
                Часы в семестр: {workLoad.maxHoursPerSpaceBaseTimeInterval}
            </div>

        </div>
    );
}
