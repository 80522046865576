import { createPortal } from 'react-dom';

import { usePortal } from './usePortal';

interface Props {
    id: string;
    dom: JSX.Element;
}

export const Dialog = ({ id, dom }: Props) => {
    const target = usePortal(id);

    return createPortal(
        dom,
        target,
    );
};
