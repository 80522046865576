import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { UniversityPage } from '@admin/UniversityPage';
import { ModulesPage } from '@admin/NewModule/ModulesPage';
import { UserCard } from '@admin/Users/UserCard';
import { Users } from '@admin/Users/Users';
import { observer } from 'mobx-react-lite';
import { AuthRoute } from '@common/AuthRoute';
import { AdminTrajectories } from '@admin/Trajectories/AdminTrajectories';
import { SubspacesConnectionsDiagram } from '@admin/SubspacesConnectionsDiagram';
import { EducationSpacePageApollo } from '@admin/EducationSpacePage/EducationSpacePageApollo';
import { EducationSpacePage } from '@admin/EducationSpacePage';
import { ScheduleGenerationPage } from '@admin/ScheduleGenerationPage';
import { SpacesModuleList } from '@admin/SpacesModuleList';
import { ModuleStatistics } from '@admin/ModuleStatistics';
import { EducationPeriodPage } from '@admin/EducationPeriodPage';
import { ModuleSelectionPage } from '@admin/ModuleSelectionPage';
import { WaveSelectionPage } from '@admin/WaveSelectionPage';
import { EducationPeriodStatisticPage } from '@admin/EducationPeriodStatistic';
import { Attendance } from '@admin/Attendance';
import { ViewType } from '../../deprecated-graphql-query-types';
import { AuthPage } from '../../AuthPage';
import { OAuthRedirectPage } from '../../AuthPage/OAuthRedirectPage';
import UserView from '../../store/UserView';
import { SchedulePageApollo } from '../../account/schedule/SchedulePageApollo';
import { SchedulePage } from '../../account/schedule/SchedulePage';
import { SubSpacePage } from '../../subSpaceAdmin';
import { ExitPage } from '../Menu/ExitPage';
import { StudentModulesPage } from '../../student/StudentModulesPage';
import { PrivacyPolicyPage } from '../../PrivacyPolicyPage';
import { ProfileApollo } from '../../Profile';
import { AssignmentsApollo } from '../../student/Assignments';
import { TeacherAssignmentsApollo } from '../../teacher/Assignments';
import { ExternalModule } from '../../ExternalModule';

const HomePage = observer(() => {
    const { userViewType } = UserView;
    let path = '/auth';

    if (userViewType?.name === ViewType.Admin) path = '/university';
    if (userViewType?.name === ViewType.Teacher) path = '/schedule';
    if (userViewType?.name === ViewType.Student) path = '/schedule';

    return <Redirect to={path} />;
});

export const AuthRouter = observer((): JSX.Element => {
    const sections = UserView.user?.availableInterfaceSections;

    return (
        <Switch>
            <AuthRoute
                exact
                path="/"
                requiredUserTypes={[ViewType.Admin, ViewType.Teacher, ViewType.Student]}
            >
                <HomePage />
            </AuthRoute>

            <Route
                exact
                path="/auth"
            >
                <AuthPage />
            </Route>

            <Route
                exact
                path="/privacy-policy"
            >
                <PrivacyPolicyPage />
            </Route>

            <Route
                exact
                path="/exit"
            >
                <ExitPage />
            </Route>

            <Route
                path="/auth_result"
            >
                <OAuthRedirectPage />
            </Route>

            <Route path="/profile">
                <ProfileApollo />
            </Route>

            <Route exact path="/invitation/:invitationId">
                <ExternalModule />
            </Route>

            <Route exact path="/invitation/:invitationId/module/:externalModuleId">
                <ExternalModule />
            </Route>

            <AuthRoute
                exact
                path="/educational-space"
                requiredUserTypes={[ViewType.Admin]}
            >
                <Redirect to="/educational-space/space" />
            </AuthRoute>

            <AuthRoute
                path="/educational-space/space"
                requiredUserTypes={[ViewType.Admin]}
            >
                <EducationSpacePage routerPath="/educational-space" />
            </AuthRoute>

            <AuthRoute
                path="/educational-space/subspace/:spaceId/:subspaceTypeId/:subspaceId"
                requiredUserTypes={[ViewType.Admin]}
            >
                <SubSpacePage routerPath="/educational-space" />
            </AuthRoute>

            <AuthRoute
                path="educational-space/space/:spaceId/connections"
                requiredUserTypes={[ViewType.Admin]}
            >
                <SubspacesConnectionsDiagram />
            </AuthRoute>

            <AuthRoute
                path="/space"
                requiredUserTypes={[ViewType.Admin]}
            >
                <EducationSpacePageApollo routerPath="/space" />
            </AuthRoute>

            <AuthRoute
                path="/university"
                requiredUserTypes={[ViewType.Admin]}
            >
                <UniversityPage />
            </AuthRoute>

            <AuthRoute
                path="/module"
                requiredUserTypes={[ViewType.Admin]}
            >
                <ModulesPage />
            </AuthRoute>

            <AuthRoute
                path="/module-statistics/space/:spaceId/base-time-interval/:btiId/module/:moduleId"
                requiredUserTypes={[ViewType.Admin]}
            >
                <ModuleStatistics />
            </AuthRoute>

            <AuthRoute
                exact
                path="/module-statistics"
                requiredUserTypes={[ViewType.Admin]}
            >
                <Redirect to="/module-statistics/space" />
            </AuthRoute>

            <AuthRoute
                path="/module-statistics/space"
                requiredUserTypes={[ViewType.Admin]}
            >
                <SpacesModuleList />
            </AuthRoute>

            <AuthRoute
                path="/users/user/:id/edit"
                requiredUserTypes={[ViewType.Admin]}
            >
                <UserCard edit />
            </AuthRoute>

            <AuthRoute
                path="/users/user/:id"
                requiredUserTypes={[ViewType.Admin]}
            >
                <UserCard />
            </AuthRoute>

            <AuthRoute
                path="/users"
                requiredUserTypes={[ViewType.Admin]}
            >
                <Users path="/users" />
            </AuthRoute>

            <AuthRoute
                path="/education-period"
                requiredUserTypes={[ViewType.Admin]}
                requiredSections={sections}
            >
                <EducationPeriodPage />
            </AuthRoute>
            <AuthRoute
                path="/secret/education-period"
                requiredUserTypes={[ViewType.Admin]}
                requiredSections={sections}
            >
                <EducationPeriodPage />
            </AuthRoute>

            <AuthRoute
                path="/education-period-module-priorities"
                requiredUserTypes={[ViewType.Admin]}
            >
                <EducationPeriodStatisticPage />
            </AuthRoute>

            <AuthRoute
                path="/base-time-interval/:id/schedule/:selectEventId?"
                requiredUserTypes={[ViewType.Teacher, ViewType.Admin]}
            >
                <ScheduleGenerationPage />
            </AuthRoute>

            <AuthRoute
                path="/base-time-interval/:id/schedule?:queryParams?"
                requiredUserTypes={[ViewType.Teacher, ViewType.Admin]}
            >
                <ScheduleGenerationPage />
            </AuthRoute>

            <AuthRoute
                path="/secret/base-time-interval/:id/schedule?:queryParams?"
                requiredUserTypes={[ViewType.Teacher, ViewType.Admin]}
            >
                <ScheduleGenerationPage isSecret />
            </AuthRoute>

            <AuthRoute
                path="/secret/base-time-interval/:id/schedule"
                requiredUserTypes={[ViewType.Teacher, ViewType.Admin]}
            >
                <ScheduleGenerationPage isSecret />
            </AuthRoute>

            <AuthRoute
                path="/trajectories"
                requiredUserTypes={[ViewType.Admin]}
            >
                <AdminTrajectories />
            </AuthRoute>

            <AuthRoute
                path="/schedule/:unitOfTime?/:selectedDate?/:selectedEventId?"
                requiredUserTypes={[ViewType.Teacher, ViewType.Student]}
            >
                <>
                    <SchedulePageApollo />
                    <SchedulePage />
                </>
            </AuthRoute>

            <AuthRoute
                path="/modules"
                requiredUserTypes={[ViewType.Student]}
            >
                <StudentModulesPage />
            </AuthRoute>

            <AuthRoute
                path="/student-assignments"
                requiredUserTypes={[ViewType.Student]}
            >
                <AssignmentsApollo />
            </AuthRoute>

            <AuthRoute
                path="/teacher-assignments"
                requiredUserTypes={[ViewType.Teacher]}
            >
                <TeacherAssignmentsApollo />
            </AuthRoute>

            <AuthRoute
                path="/profile"
                requiredUserTypes={[ViewType.Student, ViewType.Teacher]}
            >
                <ProfileApollo />
            </AuthRoute>

            <AuthRoute
                path="/attendance"
                requiredUserTypes={[ViewType.Admin]}
            >
                <Attendance />

            </AuthRoute>

            <AuthRoute
                path="/education-period-new"
                requiredUserTypes={[ViewType.Admin]}
            >
                <EducationPeriodPage />
            </AuthRoute>

            <AuthRoute
                path="/module-selection"
                requiredUserTypes={[ViewType.Admin]}
            >
                <ModuleSelectionPage />
            </AuthRoute>

            <AuthRoute
                path="/wave-selection"
                requiredUserTypes={[ViewType.Admin]}
            >
                <WaveSelectionPage />
            </AuthRoute>

            <Route path="*">
                <HomePage />
            </Route>
        </Switch>
    );
});
