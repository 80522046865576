import React, { useState } from 'react';
import { OperationVariables, QueryLazyOptions } from '@apollo/client';
import { ActionButton } from '@common/ActionButton';

import cn from 'classnames';
import classes from './AuthPage.module.scss';

import { AuthIcon } from './AuthIcon';

interface Props {
    basicLogin: (options?: QueryLazyOptions<OperationVariables> | undefined) => void;
    basicLoginData: { basicLogin: string; } | undefined;
}

export function LoginPassword({ basicLogin, basicLoginData }: Props): JSX.Element {
    const [login, setLogin] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [invalidData, setInvalidData] = useState(true);

    const logIn = () => {
        basicLogin({ variables: { login, password } });
    };
    return (
        <form className={classes.loginPassword} onSubmit={logIn}>
            <div className={classes.loginPassword__icon}>
                <AuthIcon />
            </div>
            {basicLoginData?.basicLogin === null && invalidData && (
                <div className={classes.loginPassword__warningText}>
                    неверный логин или пароль
                </div>
            )}
            <input
                className={cn(classes.loginPassword__input, {
                    [classes.loginPassword__input_border_red]:
                        basicLoginData?.basicLogin === null && invalidData,
                })}
                placeholder="Укажите логин"
                onChange={({ currentTarget }) => {
                    setLogin(currentTarget.value);
                    setInvalidData(false);
                }}
                onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                        logIn();
                    }
                }}
            />
            <input
                className={cn(classes.loginPassword__input, {
                    [classes.loginPassword__input_border_red]:
                        basicLoginData?.basicLogin === null && invalidData,
                })}
                placeholder="Укажите пароль"
                type="password"
                autoComplete="on"
                onChange={({ currentTarget }) => {
                    setPassword(currentTarget.value);
                    setInvalidData(false);
                }}
            />
            <div
                className={classes.loginPassword__button}
            >
                <ActionButton
                    type="submit"
                    className={classes.auth__button}
                >
                    Войти в систему
                </ActionButton>
            </div>
        </form>
    );
}
