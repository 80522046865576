import React from 'react';
import { observer } from 'mobx-react';
import cn from 'classnames';

import { IconDeprecated } from '@common';
import { multipleEvaluationStore } from '../../../Store';

import classes from './AllMultipleStudentsCheckbox.module.scss';

export const AllMultipleStudentsCheckbox = observer((): JSX.Element => (
    <div className={classes.checkbox__container}>

        <button
            type="button"
            className={classes.checkbox__button}
            onClick={multipleEvaluationStore.toggleAllMultipleStudents}
            id="All"
        >
            <span
                className={cn(classes.checkbox__button_none, {
                    [classes.checkbox__button_line]:
                        multipleEvaluationStore.isMultipleStudentsSelectedButNotAll(),
                })}
            />
            {multipleEvaluationStore.isAllMultipleStudentsSelected() && (
                <IconDeprecated
                    id="checkmark"
                    className={classes.evaluation__checkmark}
                />
            )}
        </button>

        <span className={classes.checkbox__text}>
            Всех
        </span>
    </div>
));
