import React, { useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { nanoid } from 'nanoid';

import { Loader } from '@common/Loader';
import { Alert } from '@common/Alert';

import {
    addExternalModuleReview,
    addExternalModuleReviewVariables,
    getExternalModuleReviews,
    getExternalModuleReviewsVariables,
    removeExternalModuleReview,
    removeExternalModuleReviewVariables,
} from './apollo-types';
import { ExternalModuleReview } from './ExternalModuleReview';
import { ADD_EXTERNAL_MODULE_REVIEW, GET_EXTERNAL_MODULE_REVIEWS, REMOVE_EXTERNAL_MODULE_REVIEW } from './gql';

interface Props {
    isCurrentUserAvailbleToAddReview?: boolean;
}

interface RouteParams {
    invitationId?: string;
    externalModuleId?: string;
}

export function ExternalModuleReviewApollo({ isCurrentUserAvailbleToAddReview }: Props) {
    const { externalModuleId, invitationId } = useParams<RouteParams>();
    const [isReviewFormOpened, setIsReviewFormOpened] = useState<boolean>(false);
    const [reviewMessage, setReviewMessage] = useState<string>();
    const [errorMessage, setErrorMessage] = useState<string>();
    const [alerts, setAlerts] = useState<JSX.Element[]>([]);

    const {
        data: getExternalModuleReviewsData,
        loading: getExternalModuleReviewsLoading,
        error: getExternalModuleReviewsError,
        refetch,
    } = useQuery<getExternalModuleReviews, getExternalModuleReviewsVariables>(
        GET_EXTERNAL_MODULE_REVIEWS,
        {
            skip: !externalModuleId,
            variables: {
                externalModuleInput:
                    { externalModuleId: externalModuleId!, invitationId },
            },
            fetchPolicy: 'no-cache',
        },
    );

    const [
        addReview,
        {
            loading: addExternalModuleReviewLoading,
        },
    ] = useMutation<addExternalModuleReview, addExternalModuleReviewVariables>(
        ADD_EXTERNAL_MODULE_REVIEW,
        {
            onCompleted: () => {
                refetch();
                setIsReviewFormOpened(false);
                setReviewMessage(undefined);
            },
            onError: err => {
                const message = err.graphQLErrors[0]?.message ?? err.message;
                addAlertMessage(`Произошла ошибка. Не удалось добавить рецензию. ${message}`);
            },
        },
    );

    const [
        removeReview,
    ] = useMutation<removeExternalModuleReview, removeExternalModuleReviewVariables>(
        REMOVE_EXTERNAL_MODULE_REVIEW,
        {
            onCompleted: () => {
                refetch();
            },
            onError: err => {
                const message = err.graphQLErrors[0]?.message ?? err.message;
                addAlertMessage(`Произошла ошибка. Не удалось удалить рецензию. ${message}`);
            },
        },
    );

    function addAlertMessage(message: string) {
        setAlerts((arr) => [
            ...arr,
            <Alert key={nanoid()} message={message} time={5000} />,
        ]);
    }

    function onReviewSave() {
        if (!reviewMessage?.trim()) {
            setErrorMessage('Поле рецензии не может быть пустым.');
            return;
        }
        addReview({
            variables: {
                createExternalModuleReviewInput: {
                    message: reviewMessage!.trim(),
                    externalModuleId: externalModuleId!,
                },
            },
        });
    }

    function onReviewDelete(reviewId: string) {
        removeReview({ variables: { id: reviewId } });
    }

    if (getExternalModuleReviewsError) {
        return (
            <div>Произошла ошибка:{' '}
                {getExternalModuleReviewsError.graphQLErrors[0]?.message
                    || getExternalModuleReviewsError.message}
            </div>
        );
    }

    if (getExternalModuleReviewsLoading) {
        return <Loader />;
    }

    return (
        <>
            <ExternalModuleReview
                isCurrentUserAvailbleToAddReview={isCurrentUserAvailbleToAddReview}
                onReviewDelete={onReviewDelete}
                onReviewChange={setReviewMessage}
                onReviewSave={onReviewSave}
                addExternalModuleReviewLoading={addExternalModuleReviewLoading}
                errorMessage={errorMessage}
                reviews={getExternalModuleReviewsData?.externalModule.reviews}
                isReviewFormOpened={isReviewFormOpened}
                reviewMessage={reviewMessage}
                onReviewFormToggle={setIsReviewFormOpened}
            />
            {alerts}
        </>
    );
}
