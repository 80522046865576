import React from 'react';

import { isEqual, isMatch } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';
import { BlackCrossIcon } from '@common';
import classes from './AvailableInterval.module.scss';
import { RoomAvailableIntervalInput } from '../../../../../../graphql-query-types';

interface Props {
    universityTimeZone: string;
    availableInterval: RoomAvailableIntervalInput;
    availableIntervals: RoomAvailableIntervalInput[];
    setAvailableIntervals: (
        newAvailableIntervals: RoomAvailableIntervalInput[],
    ) => void;
}

export function AvailableInterval(
    {
        universityTimeZone,
        availableInterval,
        availableIntervals,
        setAvailableIntervals,
    }: Props,
) {
    const {
        isEveryMonth,
    } = availableInterval;

    const {
        formattedFrom,
        formattedFromTime,
        formattedToTime,
        formattedTo,
    } = getAvailableInterval(availableInterval, universityTimeZone);

    const hasTo = !isEqual(new Date(formattedFrom), new Date(formattedTo));

    const removeAvailableIntervals = () => {
        const filteredIntervals = new Array(...availableIntervals).filter(
            (interval) => interval.id !== availableInterval.id,
        );

        setAvailableIntervals(filteredIntervals);
    };

    const intervalInfo = (
        <>
            {hasTo && 'c '}
            <span className={classes.availableInterval__text_bold}>
                {formattedFrom}
            </span>
            {!hasTo && ' c'}
            {' '} {formattedFromTime} {' '} до
            {hasTo && (
                <span className={classes.availableInterval__text_bold}>
                    {' '}
                    {formattedTo}
                </span>
            )}
            {' '}
            {formattedToTime}

            {isEveryMonth ? (
                <span className={classes.availableInterval__text_bold}>
                    {' '}
                    каждого месяца
                </span>
            ) : null}
        </>
    );

    return (
        <div className={classes.availableInterval}>
            <div className={classes.availableInterval__text}>
                {intervalInfo}
            </div>

            <button
                type="button"
                onClick={() => removeAvailableIntervals()}
            >
                <BlackCrossIcon />
            </button>
        </div>
    );
}

function getAvailableInterval(
    interval: RoomAvailableIntervalInput,
    universityTimeZone: string,
) {
    let formattedFrom = '';
    let formattedTo = '';
    let formattedFromTime = '';
    let formattedToTime = '';

    const isDateIntervalFromUi = isMatch(`${interval.from}`, 'yyyy-MM-dd HH:mmXXX');

    if (isDateIntervalFromUi) {
        formattedFrom = formatInTimeZone(new Date(interval.from), universityTimeZone, 'dd.MM.yyyy');
        formattedTo = interval.to.split(' ').length > 1 ? formatInTimeZone(new Date(interval.to), universityTimeZone, 'dd.MM.yyyy') : '';
        [formattedFromTime] = `${interval.from}`.split(' ')[1].split('+');
        [formattedToTime] = interval.to.split(' ').length > 1 ? `${interval.to}`.split(' ')[1].split('+') : `${interval.to}`.split('+');
    } else {
        formattedFromTime = formatInTimeZone(new Date(interval.from), universityTimeZone, 'HH:mm');
        formattedToTime = formatInTimeZone(new Date(interval.to), universityTimeZone, 'HH:mm');
        formattedFrom = formatInTimeZone(new Date(interval.from), universityTimeZone, 'dd.MM.yyyy');
        formattedTo = formatInTimeZone(new Date(interval.to), universityTimeZone, 'dd.MM.yyyy');
    }

    return {
        formattedFrom,
        formattedTo,
        formattedFromTime,
        formattedToTime,
    };
}
