import React, { useState } from 'react';
import { groupBy } from 'lodash';
import { EditIcon, FilledEditIcon } from '@common/svg';
import { EquipmentCard } from '../EquipmentCard';
import classes from './EquipmentList.module.scss';

interface Equipment {
    id: string;
    name: string;
    unit: string;
}

interface Props {
    consumableEquipment: Equipment[];
    updateConsumableEquipment: (
        id: string,
        name: string,
        unit: string,
    ) => void;
    removeConsumableEquipment: (id: string) => void;
}

export function EquipmentList({
    consumableEquipment,
    updateConsumableEquipment,
    removeConsumableEquipment,
}: Props): JSX.Element {
    const [edit, toggleEdit] = useState(false);
    const letterArray = groupBy(consumableEquipment,
        (item: Equipment) => item.name.replace(/^[ '"]+|[ '"]+$|( ){2,}/g, '$1').slice(0, 1).toUpperCase());
    const itemArray = Object.entries(letterArray)
        .sort((a, b) => (a[0] > b[0] ? 1 : -1));
    return (
        <>
            <div className={classes.equipmentList__header}>
                <h2>Список расходных материалов:</h2>

                <div className={classes.equipmentList__icon} onClick={() => toggleEdit(!edit)}>
                    {edit ? <FilledEditIcon size={33} /> : <EditIcon size={33} />}
                </div>
            </div>

            <div className={classes.equipmentList}>
                <ul className={classes.equipment__cardList}>
                    {
                        itemArray.map((item) => (
                            <li className={classes.equipment__cardItem} key={item[0]}>
                                <EquipmentCard
                                    cardEquipments={item}
                                    editState={edit}
                                    removeConsumableEquipment={removeConsumableEquipment}
                                    updateConsumableEquipment={updateConsumableEquipment}
                                />
                            </li>
                        ))
                    }
                </ul>
            </div>
        </>
    );
}
