import { gql } from '@apollo/client';

import { useMutationWithErrorHandling } from './useMutationWithErrorHandling';

interface CreateSchedulePeriodInput {
    baseTimeIntervalInstanceId: string;
    from: string;
    to: string;
}

interface CreateSchedulePeriodParams {
    baseTimeIntervalInstanceId: string;
    from: string;
    to: string;
    partsCount?: number;
}

const CREATE_SCHEDULE_PERIOD = gql`
    mutation createSchedulePeriod($createSchedulePeriodInput: CreateSchedulePeriodInput!) {
        createSchedulePeriod(createSchedulePeriodInput: $createSchedulePeriodInput) {
            id
        }
    }
`;

export function useCreateSchedulePeriodMutation() {
    const [createSchedulePeriod] = useMutationWithErrorHandling(CREATE_SCHEDULE_PERIOD);

    return ({
        baseTimeIntervalInstanceId,
        from,
        to,
    }: CreateSchedulePeriodParams) => {
        const createSchedulePeriodInput: CreateSchedulePeriodInput = {
            baseTimeIntervalInstanceId,
            from,
            to,
        };

        return createSchedulePeriod({
            variables: { createSchedulePeriodInput },
        });
    };
}
