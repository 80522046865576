import { HatIcon } from '@common/svg';
import React from 'react';

import classes from './AuthPage.module.scss';

export function AuthIcon(): JSX.Element {
    return (
        <div className={classes.authIcon}>
            <HatIcon />
        </div>
    );
}
