import React from 'react';

import { observer } from 'mobx-react';
import { ArrowIcon } from '@common/svg/ArrowIcon';

import { PrioritySetMode } from '../../store';
import { Priorities } from './Priorities';

import classes from './ModuleCardHeader.module.scss';

interface Props {
    moduleName: string;
    moduleId: string;
    moduleMaxStudentCount: number;
    contentMode: boolean;
    isElectiveFork: boolean;
    slot?: {
        slotId: string;
        priority: number;
    };
    studentsModulePriorityCount?: number;
    priorities: number[]
    forkId?: string;
    setContentMode: (bool: boolean) => void;
    setStudentPriorities?: (
        setMode: PrioritySetMode,
        forkId: string,
        moduleId: string,
        priority?: number,
    ) => void;
}

export const ModuleCardHeader = observer(({
    moduleName,
    contentMode,
    moduleId,
    moduleMaxStudentCount,
    slot,
    isElectiveFork,
    priorities,
    studentsModulePriorityCount,
    forkId,
    setContentMode,
    setStudentPriorities,
}: Props) => (
    <div>
        <div className={classes.moduleCardHeader__container}>
            <div
                className={classes.moduleCardHeader}
                onClick={() => {
                    setContentMode(!contentMode);
                }}
            >
                <ArrowIcon
                    isOpen={contentMode}
                    className={classes.eventView_arrow}
                    isNewDesing
                />
                <div className={classes.moduleCardHeader__content}>
                    <div className={classes.moduleCardHeader__title}>
                        {moduleName}
                    </div>
                </div>
            </div>
            {isElectiveFork && !contentMode && !!slot?.priority && (
                <div className={classes.priority__container}>
                    <Priorities
                        priorities={[slot?.priority]}
                        slot={slot}
                        forkId={forkId}
                        moduleId={moduleId}
                        setStudentPriorities={setStudentPriorities}
                    />
                </div>
            )}
            {isElectiveFork && (
                <div className={classes.moduleCardHeader__count}>
                    {!!slot?.priority && !contentMode && (
                        <div className={classes.priority__container_mobile}>
                            <Priorities
                                priorities={[slot?.priority]}
                                slot={slot}
                                forkId={forkId}
                                moduleId={moduleId}
                                setStudentPriorities={setStudentPriorities}
                            />
                        </div>
                    )}
                    {studentsModulePriorityCount ?? '0'}
                    /
                    {moduleMaxStudentCount}
                </div>
            )}
        </div>
        {isElectiveFork && contentMode && (
            <div style={{ background: '#F8F9FE', padding: '10px 10px 0' }}>
                <Priorities
                    priorities={priorities}
                    forkId={forkId}
                    slot={slot}
                    moduleId={moduleId}
                    setStudentPriorities={setStudentPriorities}
                />
            </div>
        )}
    </div>
));
