import React, { useRef, useState } from 'react';
import moment, { Moment } from 'moment';
import { observer } from 'mobx-react';
import cn from 'classnames';

import { IconDeprecated } from '@common';
import { TimeReservingEvent } from '../../../../../../Interfaces';
import { MonthScheduleEvent } from '../MonthScheduleEvent';
import { VISIBLE_SCHEDULE_EVENTS_IN_MONTH_DAY } from '../helpers';

import classes from './MoreEvents.module.scss';

const BOTTOM_LINE_START_CELL_ID = 28;
const CELL_COLUMNS = 7;
const RIGHT_LINE_MULTIPLE_ID = 6;

interface Props {
    cellId: number;
    date: Moment | null;
    dateEvents: TimeReservingEvent[];
}

export const MoreEvents = observer(({
    cellId,
    date,
    dateEvents,
}: Props) => {
    const menuRef = useRef<null | HTMLDivElement>(null);
    const [isOpen, setIsOpen] = useState<boolean>(false);

    function openMenu(): void {
        setIsOpen(true);
    }

    function closeMenu(): void {
        setIsOpen(false);
    }

    function isCellRight(cellIdToCheck: number): boolean {
        return cellIdToCheck % CELL_COLUMNS === RIGHT_LINE_MULTIPLE_ID;
    }

    // useOnClickOutside(menuRef, closeMenu);

    return (
        <div>
            <div className={classes.button} onClick={openMenu}>
                еще {dateEvents.length - VISIBLE_SCHEDULE_EVENTS_IN_MONTH_DAY}
            </div>

            {isOpen && (
                <div className={classes.moreEvents}>
                    <div
                        className={cn(classes.menu, {
                            [classes.menu_isBottom]: cellId >= BOTTOM_LINE_START_CELL_ID,
                            [classes.menu_isRight]: isCellRight(cellId),
                        })}
                        ref={menuRef}
                    >
                        <IconDeprecated
                            id="Cross"
                            className={classes.menu__closeButton}
                            click={closeMenu}
                        />

                        <div className={classes.menu__date}>{date?.format('ddd')}, {date?.format('DD')}</div>

                        <div className={classes.menu__events}>
                            {dateEvents.map((scheduleEvent) => (
                                <div className={classes.eventLine} key={scheduleEvent.id}>
                                    <div className={classes.eventLine__startTime}>
                                        {moment(scheduleEvent.startDate).format('HH[:]mm')}
                                    </div>

                                    <div className={classes.event}>
                                        <MonthScheduleEvent
                                            scheduleEvent={scheduleEvent}
                                            moreEvents
                                        />
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
});
