import React from 'react';
import { FetchResult } from '@apollo/client';
import { ExtraDays } from './UniversitySpecialDaysInterfaces';
import classes from './UniversitySpecialDays.module.scss';
import { UniversitySpecialDaysEdit } from './UniversitySpecialDaysEdit';
import { UniversitySpecialDaysView } from './UniversitySpecialDaysView';

type ApolloPromise = Promise<void | FetchResult<any, Record<string, any>, Record<string, any>>>;

interface Props {
    extraDays: ExtraDays,
    update(data:ExtraDays):ApolloPromise,
    mode:number,
    setMode: React.Dispatch<React.SetStateAction<number>>;
}

const VIEW_MODE = 1;
const EDIT_MODE = 0;

export function UniversitySpecialDays(
    {
        extraDays,
        update,
        mode,
        setMode,
    }: Props,
): JSX.Element {
    const changeMode = () => setMode(
        (oldMode:number) => (oldMode ? EDIT_MODE : VIEW_MODE),
    );
    let modComponent = (
        <UniversitySpecialDaysEdit
            extraDays={extraDays}
            update={update}
            changeMode={changeMode}
        />
    );

    if (mode) {
        modComponent = (
            <UniversitySpecialDaysView
                extraDays={extraDays}
                changeMode={changeMode}
            />
        );
    }

    return (
        <div className={classes.specialDays}>
            <h2>Праздничные и внеплановые рабочие дни:</h2>
            {modComponent}
        </div>
    );
}
