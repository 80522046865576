import React, { useEffect, useState } from 'react';

import { CalendarNedoshkovskiy, ExcludedDateInterval } from '@common/CalendarNedoshkovskiy';

import { useGetShortMonthName } from '@common/hooks';
import cn from 'classnames';
import { format } from 'date-fns';
import classes from './DatePickerWithMonthName.module.scss';

interface Props {
    isDisabled?: boolean;
    showMonthName?: boolean;
    className?: string;
    selectedDate?: Date;
    excludedDateIntervals?: ExcludedDateInterval[];
    includedDateIntervals?: ExcludedDateInterval[];
    getDate?: (newDate: Date) => void;
    onCalendarOpen?: () => void;
    onBlur?: () => void;
}

export function DatePickerWithMonthName(
    {
        isDisabled,
        showMonthName,
        selectedDate,
        className,
        excludedDateIntervals,
        includedDateIntervals,
        getDate,
        onCalendarOpen,
        onBlur,
    }: Props,
) {
    const [newSelectedDate, setNewSelectedDate] = useState<Date>(
        selectedDate || new Date(),
    );
    const [isDateSelecting, setDateSelecting] = useState(false);

    const monthName = useGetShortMonthName(newSelectedDate);
    const dateInfoWithMonthName = `${newSelectedDate.getDate()} ${monthName} ${newSelectedDate.getFullYear()}`;

    const dateInfo = format(newSelectedDate, 'dd.MM.yyyy');

    useEffect(() => {
        if (getDate) {
            getDate(newSelectedDate);
        }
    }, [
        newSelectedDate,
    ]);

    return (
        <div
            className={cn(classes.datePickerWithMontName, {
                [classes.datePickerWithMontName_disabled]: isDisabled,
            })}
            onClick={() => setDateSelecting(true)}
        >

            <div
                className={cn(classes.datePickerWithMontName__date, className)}
            >
                { showMonthName ? dateInfoWithMonthName : dateInfo }
            </div>

            <div
                className={classes.datePickerWithMontName__calendar}
            >
                <CalendarNedoshkovskiy
                    onChange={(date) => setNewSelectedDate(date)}
                    isOpen={isDateSelecting}
                    onClickOutside={() => setDateSelecting(false)}
                    excludedDateIntervals={excludedDateIntervals}
                    includedDateIntervals={includedDateIntervals}
                    onCalendarOpen={onCalendarOpen}
                    onBlur={onBlur}
                />
            </div>

        </div>
    );
}
