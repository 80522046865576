import React from 'react';
import {
    TeacherRolesConflict,
} from '../RoleConflictsDashboardInterfaces';
import classes from './TimeSlotsList.module.scss';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { TimeSlots } from './TimeSlots';
import { Ruler } from './Ruler';

interface Props {
    teacherTimeSlotsList: TeacherRolesConflict[] | undefined,
}

export const TimeSlotsList = ({ teacherTimeSlotsList }: Props): JSX.Element | null => {
    if (teacherTimeSlotsList) {
        return (
            <div className={classes.timeSlotsList}>
                <table className={classes.timeSlotsList__table}>
                    <thead>
                        <tr key="ruler-row">
                            <Ruler
                                timeSlots={teacherTimeSlotsList[0]}
                            />
                        </tr>
                    </thead>
                    <tbody>
                        <>
                            {
                                teacherTimeSlotsList.map(
                                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                                    (teacherTimeSlots, index) => (
                                        // eslint-disable-next-line react/no-array-index-key
                                        <tr key={index}>
                                            <TimeSlots
                                                teacherTimeSlots={teacherTimeSlots}
                                            />
                                        </tr>
                                    ),
                                )
                            }
                        </>
                    </tbody>
                </table>
            </div>
        );
    }

    return null;
};
