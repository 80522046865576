import React, { useRef, useState } from 'react';
import { userCardStore } from '@admin/Users/store/userCardStore';
import { Switcher } from '@common';
import { useResetEscapeMouseClick } from '@admin/Users/utils/hooks';
import classnames from 'classnames';

import { EditSpoiler } from '../../Spoiler';
import { TimePicker } from '../../TimePicker';
import { DaySchedule, WeekType } from '../../TimePicker/modelTimePicker';
import { PreviewTimeIntervals } from './PreviewTimeIntervals';

import classes from './workingHours.module.scss';

export const EditWorkingHours = () => {
    const wrapperRef = useRef<HTMLDivElement>(null);
    const [showTimePicker, setShowTimePicker] = useState({ oddEvery: false, even: false });
    const [isEveryWeek, setIsEverWeek] = useState(userCardStore.isEveryWeek);
    useResetEscapeMouseClick(wrapperRef, setShowTimePicker, { oddEvery: false, even: false });
    const updateTimePicker = (typeWeek: WeekType, daySchedules: DaySchedule[]) => {
        setShowTimePicker({ oddEvery: false, even: false });
        userCardStore.updateWorkingInterval(typeWeek, daySchedules);
    };
    userCardStore.setSelectWeekType(isEveryWeek);
    return (
        <div className={classes.workingHours}>
            <h3 className={
                classnames(classes.workingHours__title, classes.workingHours__title_bold)
            }
            >
                Рабочая занятость:
            </h3>
            <div className={classes.workingHours__switcher}>
                <Switcher
                    checked={!isEveryWeek}
                    onClick={() => setIsEverWeek(!isEveryWeek)}
                    label="Разделение на четные и нечетные недели"
                />
            </div>
            <div ref={wrapperRef}>
                <>
                    {!isEveryWeek && (
                        <h3 className={classes.workingHours__title}>
                            Запланировать рабочую занятость четной недели:
                        </h3>
                    )
                    }
                    <EditSpoiler
                        title="Редактировать занятость"
                        isShow={showTimePicker.even}
                        setIsShow={() => setShowTimePicker({
                            oddEvery: false,
                            even: !showTimePicker.even,
                        })}
                    >
                        <>
                            <TimePicker
                                update={updateTimePicker}
                                daySchedules={(
                                    isEveryWeek ? userCardStore.workingIntervals.every
                                        : userCardStore.workingIntervals.even)
                                    ?? []}
                                typeWeek={isEveryWeek ? WeekType.every : WeekType.even}
                            />
                        </>
                    </EditSpoiler>
                    <div className={classes.workingHours__intervals}>
                        <PreviewTimeIntervals
                            intervals={isEveryWeek ? userCardStore.workingIntervals.every!
                                : userCardStore.workingIntervals.even!}
                            title={isEveryWeek ? 'Неделя' : 'Четная неделя'}
                        />
                    </div>
                    {!isEveryWeek && (
                        <>
                            <h3 className={classes.workingHours__title}>
                                Запланировать рабочую занятость нечетной недели:
                            </h3>
                            <EditSpoiler
                                title="Редактировать занятость"
                                isShow={showTimePicker.oddEvery}
                                setIsShow={() => setShowTimePicker({
                                    oddEvery: !showTimePicker.oddEvery,
                                    even: false,
                                })}
                            >
                                <TimePicker
                                    update={updateTimePicker}
                                    daySchedules={userCardStore.workingIntervals.odd ?? []}
                                    typeWeek={WeekType.odd}
                                />
                            </EditSpoiler>
                            <div className={classes.workingHours__intervals}>
                                <PreviewTimeIntervals
                                    intervals={userCardStore.workingIntervals.odd!}
                                    title="Нечетная неделя"
                                />
                            </div>
                        </>
                    )}
                </>
            </div>
        </div>
    );
};
