import React from 'react';
import { gql, useQuery, useMutation } from '@apollo/client';
import { Alert } from '@common/Alert';
import { EducationSubspaceForm } from './EducationSubspaceForm';
import { Space } from '../../../../subSpaceAdmin/subSpaceTypes';
import {
    subspacesVariables,
    subspaces,
    createSubspaceVariables,
    createSubspace,
    removeSubspaceVariables,
    removeSubspace,
} from './apollo-types';

interface SubspaceTypeLayout {
    id: string;
    spaceBaseTimeIntervalId: string;
    minCreditCount: number;
    maxCreditCount: number;
}

interface SubspaceType {
    id: string;
    name: string;
    isSelective: boolean;
    isDescriptionEnabled: boolean;
    subspaceTypeLayouts: SubspaceTypeLayout[];
    subspaces: { id: string }[]
}

interface Props {
    subspaceTypeName: string;
    subspaceTypeId: string;
    subspaceType: SubspaceType;
    typeIndex: number;
    space: Space;
    refetchSubspaceTypes: any;
    routerPath: string;

    setActiveIndexType(arg0: number): void;
}

const GET_SUBSPACES = gql`
  query subspaces($typeId: String!) {
    subspaces(typeId: $typeId) {
        id
        name
        owner {
            id
            lastName
            firstName
            patronymic
        }
        type {
            id
        }
    }
}
`;

const CREATE_SUBSPACE = gql`
    mutation createSubspace(
        $id: String!
        $name: String!
        $ownerId: String!
        $typeId: String!
    ) {
        createSubspace (
            createSubspaceInput: {
                id: $id
                name: $name
                ownerId: $ownerId
                typeId: $typeId
            }
        ) {
            id
        }
    }
`;

const REMOVE_SUBSPACE = gql`
    mutation removeSubspace($id: String!) {
        removeSubspace(id: $id) {
            result
        }
    }
`;

export const EducationSubspaceFormApollo = ({
    subspaceTypeName,
    typeIndex,
    subspaceType,
    setActiveIndexType,
    subspaceTypeId,
    space,
    routerPath,
    refetchSubspaceTypes,
}: Props) => {
    const {
        error,
        loading,
        data,
        refetch,
    } = useQuery<subspaces, subspacesVariables>(
        GET_SUBSPACES,
        { variables: { typeId: subspaceTypeId } },
    );

    const [create] = useMutation<createSubspace, createSubspaceVariables>(CREATE_SUBSPACE, {
        refetchQueries: [{ query: GET_SUBSPACES, variables: { typeId: subspaceTypeId } }],
        onCompleted: () => refetchSubspaceTypes(),
    });

    const [
        remove,
        { error: removeError },
    ] = useMutation<removeSubspace, removeSubspaceVariables>(REMOVE_SUBSPACE, {
        onCompleted: () => {
            refetch();
            refetchSubspaceTypes();
        },
        onError: () => null,
    });

    if (loading) return <></>;
    if (error) return <>`Error! ${error.message}`</>;
    if (removeError) return <Alert message={removeError?.message} time={5000} />;

    return (
        <EducationSubspaceForm
            name={subspaceTypeName}
            subspaceList={data!.subspaces}
            subspaceType={subspaceType}
            typeId={subspaceTypeId}
            typeIndex={typeIndex}
            setActiveIndexType={setActiveIndexType}
            createSubspace={(
                id,
                name,
                ownerId,
                typeId,
            ) => create({
                variables: {
                    id,
                    name,
                    ownerId,
                    typeId,
                },
            })}
            removeSubspace={(
                id,
            ) => remove({
                variables: {
                    id,
                },
            })}
            space={space}
            routerPath={routerPath}
        />
    );
};
