import React from 'react';
import { Moment } from 'moment';
import { observer } from 'mobx-react';

import { MonthScheduleEvent } from './MonthScheduleEvent';
import { MoreEvents } from './MoreEvents';
import { VISIBLE_SCHEDULE_EVENTS_IN_MONTH_DAY } from './helpers';
import { TimeReservingEvent } from '../../../../../Interfaces';

import classes from './DateEvents.module.scss';

interface Props {
    cellId: number;
    date: Moment | null;
    dateEvents: TimeReservingEvent[];
}

export const DateEvents = observer(({
    cellId,
    date,
    dateEvents,
}: Props): JSX.Element => {
    function getVisibleScheduleEvents(): TimeReservingEvent[] {
        return dateEvents
            .slice(0, VISIBLE_SCHEDULE_EVENTS_IN_MONTH_DAY);
    }

    function sortEventsByDate(timeReservingEvents: TimeReservingEvent[]): TimeReservingEvent[] {
        return [...timeReservingEvents].sort((event1, event2) => (
            +event1.startDate - +event2.startDate
        ));
    }

    return (
        <>
            <div className={classes.visibleScheduleEvents}>
                {sortEventsByDate(getVisibleScheduleEvents()).map((scheduleEvent) => (
                    <MonthScheduleEvent
                        scheduleEvent={scheduleEvent}
                        key={scheduleEvent.id}
                    />
                ))}
            </div>

            {dateEvents.length > VISIBLE_SCHEDULE_EVENTS_IN_MONTH_DAY && (
                <MoreEvents
                    cellId={cellId}
                    date={date}
                    dateEvents={sortEventsByDate(dateEvents)}
                />
            )}
        </>
    );
});
