import React from 'react';
import cn from 'classnames';

import {
    SpaceBaseTimeInterval,
} from '@admin/EducationPeriodPage/EducationPeriodPageInterfaces';

import classes from './AccordionHeader.module.scss';

interface Props {
    baseTimeInterval: SpaceBaseTimeInterval;
    name: string;
}

export function AccordionHeader(
    {
        baseTimeInterval,
        name,
    }: Props,
) {
    return (
        <div className={classes.header}>

            <div
                className={cn(
                    classes.header__item, classes.header__item_title,
                )}
            >
                Настройки {baseTimeInterval.order} {name}
            </div>

            {/* <div */}
            {/*    className={cn( */}
            {/*        classes.header__item, classes.header__item_date, */}
            {/*    )} */}
            {/* > */}
            {/*    { */}
            {/*        !parameters?.spaceBaseTimeIntervalStart */}
            {/*        && !parameters?.spaceBaseTimeIntervalEnd ? ( */}
            {/*                'не задано' */}
            {/*            ) : ( */}
            {/*                <> */}
            {/*                    { */}
            {/*                        formatDate(parameters?.spaceBaseTimeIntervalStart) */}
            {/*                    } */}
            {/*                    -*/}
            {/*                    { */}
            {/*                        formatDate(parameters?.spaceBaseTimeIntervalEnd) */}
            {/*                    } */}
            {/*                </> */}
            {/*            ) */}
            {/*    } */}
            {/* </div> */}

            {/* <div className={classes.header__item}> */}
            {/*    header action */}
            {/* </div> */}

        </div>
    );
}
