import { makeAutoObservable } from 'mobx';
import moment, { Moment } from 'moment';

/* eslint import/no-cycle: "off" */
import { eventsStore } from './eventsStore';

export const DAYS_IN_WEEK = 7;

export enum UnitOfTime {
    MONTH = 'month',
    WEEK = 'week',
    DAY = 'day',
}

export const unitOfTimeTranslation = {
    month: 'Месяц',
    week: 'Неделя',
    day: 'День',
};

export enum Weekday {
    MONDAY,
    TUESDAY,
    WEDNESDAY,
    THURSDAY,
    FRIDAY,
    SATURDAY,
    SUNDAY,
}

export const WEEKDAY_NAMES = ['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс'];
class TimeStore {
    selectedDate: Moment = moment();

    unitOfTime: UnitOfTime = UnitOfTime.MONTH;

    dayStarts: number = 0;

    dayEnds: number = 24;

    constructor() {
        makeAutoObservable(this);
    }

    public setSelectedDate = (selectedDate: Moment): void => {
        this.selectedDate = selectedDate;
    };

    public selectEventDateIfItInSameTimePeriod = (scheduleEventDate: Date): void => {
        if (moment(scheduleEventDate).isSame(this.selectedDate, this.unitOfTime)) {
            this.setSelectedDate((moment(scheduleEventDate)));
        }
    };

    public isTimePeriodChanged = (prevSelectedDate: Moment): boolean => (
        !prevSelectedDate.isSame(this.selectedDate, this.unitOfTime)
    );

    public prevSelectedDate = (): void => {
        if (this.unitOfTime === UnitOfTime.MONTH) {
            this.setSelectedDate(
                this.selectedDate
                    .clone()
                    .startOf('month')
                    .subtract(1, 'months'),
            );
        } else if (this.unitOfTime === UnitOfTime.WEEK) {
            this.setSelectedDate(
                this.selectedDate
                    .clone()
                    .startOf('week')
                    .subtract(1, 'weeks'),
            );
        } else if (this.unitOfTime === UnitOfTime.DAY) {
            this.setSelectedDate(
                this.selectedDate
                    .clone()
                    .startOf('day')
                    .subtract(1, 'days'),
            );
        }
    };

    public nextSelectedDate = (): void => {
        if (this.unitOfTime === UnitOfTime.MONTH) {
            this.setSelectedDate(
                this.selectedDate
                    .clone()
                    .startOf('month')
                    .add(1, 'months'),
            );
        } else if (this.unitOfTime === UnitOfTime.WEEK) {
            this.setSelectedDate(
                this.selectedDate
                    .clone()
                    .startOf('week')
                    .add(1, 'weeks'),
            );
        } else if (this.unitOfTime === UnitOfTime.DAY) {
            this.setSelectedDate(
                this.selectedDate
                    .clone()
                    .startOf('day')
                    .add(1, 'days'),
            );
        }
    };

    public getDateSelectView = (): string => {
        if (this.unitOfTime === UnitOfTime.MONTH) {
            return this.selectedDate.format('MMMM[,] YYYY');
        }
        if (this.unitOfTime === UnitOfTime.WEEK) {
            return this.selectedDate.format('MMMM[,] W [нед]');
        }
        if (this.unitOfTime === UnitOfTime.DAY) {
            return this.selectedDate.format('dddd[,] D MMM');
        }

        return '';
    };

    public setUnitOfTime = (unitOfTime: UnitOfTime) => {
        this.unitOfTime = unitOfTime;
    };

    public setSelectedEventDateIfSelected = (): void => {
        if (eventsStore.selectedEvent && !eventsStore.isAssignment(eventsStore.selectedEvent)) {
            this.setSelectedDate(moment(eventsStore.selectedEvent.startDate));
        }
        if (eventsStore.selectedEvent && eventsStore.isAssignment(eventsStore.selectedEvent)) {
            this.setSelectedDate(moment(eventsStore.selectedEvent.assignmentInstance.start));
        }
    };

    public isSelectedDateIsCurrentWeek = (): boolean => (
        this.selectedDate.clone().isSame(moment(), 'week')
    );

    public isSelectedDateIsCurrentDay = (): boolean => (
        this.selectedDate.clone().isSame(moment(), 'day')
    );

    public getScheduleStart = (): Date => {
        const selectedDate = this.selectedDate.clone();

        if (this.unitOfTime === UnitOfTime.MONTH) {
            return selectedDate
                .startOf('month')
                .startOf('isoWeek')
                .toDate();
        }

        if (this.unitOfTime === UnitOfTime.WEEK) {
            return selectedDate
                .startOf('isoWeek')
                .toDate();
        }

        return selectedDate
            .startOf('day')
            .toDate();
    };

    public getScheduleEnd = (): Date => {
        const selectedDate = this.selectedDate.clone();

        if (this.unitOfTime === UnitOfTime.MONTH) {
            return selectedDate
                .endOf('month')
                .endOf('isoWeek')
                .toDate();
        }

        if (this.unitOfTime === UnitOfTime.WEEK) {
            return selectedDate
                .endOf('isoWeek')
                .toDate();
        }

        return selectedDate
            .endOf('day')
            .toDate();
    };
}

export const timeStore = new TimeStore();
