import React from 'react';

import classes from './EventItem.module.scss';

interface Props {
    eventType: string;
}

export const SVGEvent = ({ eventType }: Props) => {
    const isMeeting = eventType === 'meetings';
    return (
        <>
            {isMeeting ? (
                <div className={classes.svgRect} />

            ) : (
                <div className={classes.svgCircle}>
                    <svg
                        width="6"
                        height="6"
                        viewBox="0 0 6 6"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <circle cx="3" cy="3" r="2.5" stroke="#0B42DC" />
                    </svg>
                </div>
            )
            }
        </>
    );
};
