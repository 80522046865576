import React from 'react';
import cn from 'classnames';

import { MinInterval } from '@admin/WaveSelectionPage/MinInterval';
import { IntervalAdditionalInfo } from '@admin/WaveSelectionPage/Interfaces';
import classes from '../WaveStudentItem/WaveStudentItem.module.scss';

interface Props {
    order: number;
    isIntervalInvalid: boolean;
    minIntervalStudentsAmount: number;
    intervalAdditionalInfo: IntervalAdditionalInfo;
}

export function EmptyWaveStudentItem(
    {
        order,
        isIntervalInvalid,
        minIntervalStudentsAmount,
        intervalAdditionalInfo,
    } : Props,
) {
    const isIntervalVisible = isIntervalInvalid && minIntervalStudentsAmount === order;
    const isItemRed = isIntervalInvalid && minIntervalStudentsAmount >= order;
    return (
        <>
            <div
                className={classes.item}
            >

                <div
                    className={cn(classes.displayBlock, classes.item_empty, {
                        [classes.borderBottomRed]: isIntervalVisible,
                    })}
                >

                    <div
                        className={cn(classes.item__name, {
                            [classes.interval_red]: isItemRed,
                        })}
                    >
                        {order}
                    </div>

                    <div className={classes.item__rating} />

                </div>

            </div>

            <MinInterval
                interval={minIntervalStudentsAmount}
                isRed
                isVisible={isIntervalVisible}
                wavesInfo={intervalAdditionalInfo.wavesInfo}
                studentInfo={intervalAdditionalInfo.studentInfo}
            />
        </>
    );
}
