import React, { useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react';
import {
    useCreateEquipmentMutation,
    useUpdateEquipmentCategoryMutation,
} from '@admin/EquipmentPage/graphqlHooks';
import {
    removeErrorStringFromTypeORM,
} from '@admin/EquipmentPage/RegularEquipmentList/RegularEquipmentListApollo';

import {
    GetEquipmentsEquipmentPage_equipment_list,
    GetEquipmentsEquipmentPage_equipment_list_category,
} from 'src/deprecated-graphql-query-types';
import { Tooltip } from '@common';

import { Input } from '../../Input';
import { equipmentStore } from '../../store/EquipmentStore';

import classes from '../RegularEquipment.module.scss';

interface Props {
    id: string;
    count: number;
    currentCategory: GetEquipmentsEquipmentPage_equipment_list_category;
    setCurrentEquipment: React.Dispatch<React
        .SetStateAction<GetEquipmentsEquipmentPage_equipment_list>>;
    setIsEquipmentEditing: React.Dispatch<React.SetStateAction<boolean>>;
    removeEquipmentFromState: (id: string) => void;
}

interface EditingInputProps extends Partial<Props> {
    isNameEditing: boolean;
    categoryName: string;
    setCategoryName: React.Dispatch<React.SetStateAction<string>>;
    setIsNameEditing: (flag: boolean) => void;
}

export const NameContainer = observer(({
    id,
    count,
    currentCategory,
    removeEquipmentFromState,
    setCurrentEquipment,
    setIsEquipmentEditing,
}: Props) => {
    const [categoryName, setCategoryName] = useState(currentCategory.name);
    const [isNameEditing, setIsNameEditing] = useState(false);

    const setNameEditingHandler = (isNameEditingFlag: boolean) => {
        setIsNameEditing(isNameEditingFlag);
        setIsEquipmentEditing(isNameEditingFlag);
        equipmentStore.setSelectedEquipmentId(isNameEditingFlag ? id : '');
    };
    return (
        <div
            className={classes.regularEquipment__equipmentNameContainer}
        >
            <EditingInput
                id={id}
                currentCategory={currentCategory}
                isNameEditing={isNameEditing}
                categoryName={categoryName}
                setCategoryName={setCategoryName}
                setIsNameEditing={setNameEditingHandler}
                setIsEquipmentEditing={setIsEquipmentEditing}
                setCurrentEquipment={setCurrentEquipment}
                removeEquipmentFromState={removeEquipmentFromState}
            />
            {
                !isNameEditing && (
                    <div
                        className={classes.regularEquipment__equipmentName}
                        onClick={() => setNameEditingHandler(true)}
                    >
                        {categoryName}
                        <div className={classes.regularEquipment__equipmentNameCount}>
                            [{count}]
                        </div>
                    </div>
                )
            }
        </div>
    );
});

const EditingInput = observer(({
    id,
    currentCategory,
    isNameEditing,
    categoryName,
    setCategoryName,
    setIsNameEditing,
    setIsEquipmentEditing,
    removeEquipmentFromState,
    setCurrentEquipment,
}: EditingInputProps) => {
    const nameContainerRef = useRef<HTMLInputElement>(null);

    const [equipmentId, setEquipmentId] = useState(id);
    const [validationError, setValidationError] = useState('');

    const {
        updateEquipmentCategory,
        error: errorUpdateEquipmentCategory,
    } = useUpdateEquipmentCategoryMutation();

    const {
        createEquipment,
        error: errorCreate,
    } = useCreateEquipmentMutation();

    const edibleEquipmentId = equipmentStore.getSelectedEquipmentId();
    const newEquipmentViewParameters = equipmentStore.getNewEquipmentViewParameters();
    const isNewEquipment = equipmentStore.isNewEquipment(equipmentId!);
    const isEdibleEquipment = edibleEquipmentId === equipmentId;

    useEffect(() => {
        if (errorUpdateEquipmentCategory || errorCreate) {
            const currentError = errorUpdateEquipmentCategory?.message || errorCreate?.message;
            const customError = removeErrorStringFromTypeORM(currentError);
            equipmentStore.setNoValidEquipmentId(id ?? '');
            setValidationError(customError);
        } else {
            equipmentStore.setNoValidEquipmentId('');
            setValidationError('');
        }
    }, [errorUpdateEquipmentCategory, errorCreate]);

    useEffect(() => {
        if (isNewEquipment) setIsNameEditing(isNewEquipment || isEdibleEquipment);
        setIsEquipmentEditing!(isNewEquipment || isNameEditing);
    }, [isNameEditing, newEquipmentViewParameters.id]);

    async function unFocus(value?: string) {
        if (!value && equipmentId === equipmentStore.newEquipmentId) {
            removeEquipmentFromState!(equipmentId);
        } else if (equipmentId === equipmentStore.newEquipmentId) {
            await createEquipment(
                {
                    isComputer: false,
                    category: {
                        name: value,
                    },
                },
            ).then((request) => {
                const requestedEquipment = request.data?.createEquipment;
                setCategoryName(requestedEquipment?.category.name ?? '');
                setEquipmentId(requestedEquipment?.category.id ?? '');
                setCurrentEquipment!((pre) => ({
                    ...pre,
                    id: requestedEquipment?.id ?? '',
                    category: {
                        __typename: 'EquipmentCategory',
                        id: requestedEquipment?.category.id ?? '',
                        name: requestedEquipment?.category.name ?? '',
                    },
                }));
                resetSelectedEquipment();
            });
        } else {
            await updateEquipmentCategory!({
                id: currentCategory?.id ?? '',
                name: value ?? '',
            }).then((request) => {
                const requestEquipmentCategory = request.data?.updateEquipmentCategory;
                setCategoryName(requestEquipmentCategory?.name ?? '');
                setCurrentEquipment!((pre) => ({
                    ...pre,
                    category: {
                        __typename: 'EquipmentCategory',
                        id: requestEquipmentCategory?.id ?? '',
                        name: requestEquipmentCategory?.name ?? '',
                    },
                }));
                resetSelectedEquipment();
            });
        }
    }

    function resetSelectedEquipment() {
        equipmentStore.setNewEquipmentViewParameters(false, '');
        equipmentStore.setSelectedEquipmentId('');
        setIsNameEditing(false);
    }
    return isNameEditing ? (
        <Tooltip
            hasExternalState
            text={validationError}
            isShow={Boolean(validationError)}
            isWarning
        >
            <div
                className={classes.regularEquipment__equipmentNameEdit}
                ref={nameContainerRef}
            >
                <Input
                    startingValue={categoryName ?? ''}
                    autoFocus
                    hasFocus={Boolean(validationError)}
                    isOnChangeActivateOnType
                    placeholder="Название"
                    onBlur={(value) => unFocus(value)}
                    onEnterHandler={(value) => unFocus(value)}
                    onChange={() => { }}
                />
            </div>
        </Tooltip>
    ) : null;
});
