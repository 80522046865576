import { gql } from '@apollo/client';

export const GET_BASE_TIME_INTERVAL_INSTANCES = gql`
query baseTimeIntervalInstances {
    baseTimeIntervalInstances {
        id
        startDate
        endDate
        spaceBaseTimeInterval {
            order
            space {
                id
                name
                baseTimeIntervalType{
                    name
                }
            }
        }
    }
}
`;

export const GET_SPACES = gql`
    query spaces {
        spaces {
            id
            name
        }
    }
`;
