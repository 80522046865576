import React from 'react';
import { useHistory } from 'react-router-dom';
import { observer } from 'mobx-react';
import cn from 'classnames';
import moment from 'moment';

import { FilterType, TimeReservingEvent } from '../../../../../../Interfaces';
import { eventsStore, timeStore } from '../../../../../../Store';

import classes from './MonthScheduleEvent.module.scss';

interface Props {
    scheduleEvent: TimeReservingEvent;
    moreEvents?: boolean;
}

export const MonthScheduleEvent = observer(({
    scheduleEvent, moreEvents,
}: Props) => {
    const history = useHistory();

    function handleOnSelectScheduleEvent(): void {
        if (eventsStore.getSelectedEventId() === scheduleEvent.id) {
            eventsStore.unselectEvent();
            history.push(`/schedule/${timeStore.unitOfTime}/${moment(timeStore.selectedDate).format('MM-DD-YYYY')}`);
        } else {
            eventsStore.selectEvent(scheduleEvent);
            timeStore.selectEventDateIfItInSameTimePeriod(scheduleEvent.startDate);
            history.push(`/schedule/${timeStore.unitOfTime}/${moment(timeStore.selectedDate).format('MM-DD-YYYY')}/${eventsStore.getSelectedEventId()}`);
        }
    }

    return (
        <div
            className={cn(classes.eventWrapper, {
                [classes.event_selected]:
                    eventsStore.getSelectedEventId() === scheduleEvent.id,
            })}
            onClick={handleOnSelectScheduleEvent}
        >
            <div
                className={cn(
                    classes.event,
                    { [classes.event_moreEvents]: moreEvents },
                    classes[`event_filter_${scheduleEvent.type}`],
                )}
            >
                {scheduleEvent.type === FilterType.MEETING
                    ? `${scheduleEvent.meeting.module.name} ${scheduleEvent.room ? scheduleEvent.room.title : ''}`
                    : scheduleEvent.evaluationPoint.topic
                }
            </div>
        </div>
    );
});
