import { ApolloQueryResult } from '@apollo/client';
import React, { useState } from 'react';

import {
    TeacherAssignmentsModules,
    TeacherAssignmentsContent,
    TeacherAssignmentPageApollo,
    TeacherAssignmentsTabs,
} from './components';

import {
    filteredTeacherAssignmentInstances,
    filteredTeacherAssignmentInstancesVariables,
    filteredTeacherAssignmentInstances_filteredTeacherAssignmentInstances,
    GetTeacher_teacher_baseTimeIntervalInstancesModules,
} from './TeacherAssignmentsApolloTypes';

import classes from './TeacherAssignmentsPage.module.scss';
import { SpaceTab } from './TeacherAssignmentsTypes';

interface Props {
    spaceTabs: SpaceTab[];
    btiTabs: GetTeacher_teacher_baseTimeIntervalInstancesModules[];
    activeSpaceId?: string;
    activeBTIId?: string;
}

// eslint-disable-next-line max-len
type LazyQueryRefetchFunction = ((variables?: Partial<filteredTeacherAssignmentInstancesVariables> | undefined) => Promise<ApolloQueryResult<filteredTeacherAssignmentInstances>>) | undefined;

export function TeacherAssignmentsPage({
    activeSpaceId,
    activeBTIId,
    spaceTabs,
    btiTabs,
}: Props): JSX.Element {
    const [assignmentOpen, setAssignmentOpen] = useState(false);
    // eslint-disable-next-line max-len
    const [refetchFilteredAssignments, setRefetchFilteredAssignments] = useState<LazyQueryRefetchFunction>();
    // eslint-disable-next-line max-len
    const [assignment, setAssignment] = useState<filteredTeacherAssignmentInstances_filteredTeacherAssignmentInstances>();

    const activeBTI = btiTabs.find(({ baseTimeIntervalInstance }) => (
        baseTimeIntervalInstance.id === activeBTIId
    ));

    return (
        <section className={classes.page}>
            <h1 className={classes.page__title}>Мои модули</h1>

            {spaceTabs.length === 0
                ? 'Нет активных модулей'
                : (
                    <>
                        {
                            assignmentOpen && (
                                <TeacherAssignmentPageApollo
                                    setAssignmentOpen={() => setAssignmentOpen(!assignmentOpen)}
                                    refetchFilteredAssignments={refetchFilteredAssignments}
                                    assignment={assignment}
                                />
                            )
                        }

                        <TeacherAssignmentsContent assignmentOpen={assignmentOpen}>
                            <div className={classes.content}>
                                <TeacherAssignmentsTabs
                                    btiTabs={btiTabs}
                                    spaces={spaceTabs}
                                    activeSpaceId={activeSpaceId}
                                    activeBTIId={activeBTIId}
                                />

                                {
                                    activeBTI?.modules?.map(module => (
                                        <TeacherAssignmentsModules
                                            key={module.id}
                                            waves={module.waves
                                                .filter(wave => wave.baseTimeIntervalInstances
                                                    .some((bti) => bti.id === activeBTIId))}
                                            moduleId={module.id}
                                            moduleName={module.name}
                                            setAssignment={setAssignment}
                                            assigments={module.assignments}
                                            onClick={() => setAssignmentOpen(!assignmentOpen)}
                                            // eslint-disable-next-line max-len
                                            setRefetchFilteredAssignments={setRefetchFilteredAssignments}
                                            // eslint-disable-next-line max-len
                                            baseTimeIntervalInstanceId={activeBTI!.baseTimeIntervalInstance.id}
                                        />
                                    ))
                                }
                            </div>
                        </TeacherAssignmentsContent>
                    </>
                )
            }
        </section>
    );
}
