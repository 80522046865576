import React, { useRef } from 'react';
import cn from 'classnames';

import { Icon } from '@admin/NewModule/CustomIcon';
import { useResetEscapeMouseClick } from '../utils/hooks';

import classes from './Popup.module.scss';

interface Props {
    title?: string;
    isOpen?: boolean;
    isDisabled?: boolean | undefined;
    children?: React.ReactNode;
    submitText?: string;
    withRemove?: boolean;
    onClose(): void;
    onSubmit?(): void;
    onRemove?(): void;
}
export const Popup = ({
    title,
    isOpen,
    isDisabled,
    children,
    submitText = 'Применить',
    withRemove,
    onClose,
    onSubmit,
    onRemove,
}: Props): JSX.Element => {
    const refPopup = useRef<null | HTMLDivElement>(null);

    useResetEscapeMouseClick(refPopup, onClose, 0);

    return (
        <section
            className={cn(classes.popup, { [classes.popup_opened]: isOpen })}
            ref={refPopup}
        >
            <div className={classes.popup__container}>
                <Icon onClick={onClose} id="blackCross" modifier="close" />

                <form className={classes.popup__formContainer} noValidate>
                    <h2 className={classes.popup__title}>{title}</h2>

                    <fieldset className={classes.popup__fieldset}>
                        {children}
                    </fieldset>

                    <div className={classes.controls}>
                        {withRemove && (
                            <div
                                className={cn(classes.button, classes.deleteButton, {
                                    [classes.deleteButton_disabled]: isDisabled,
                                })}
                                onClick={isDisabled ? () => {} : onRemove}
                            >
                                Удалить
                            </div>
                        )}

                        <div
                            className={cn(classes.button, classes.submitButton, {
                                [classes.submitButton_disabled]: isDisabled,
                            })}
                            onClick={isDisabled ? () => {} : onSubmit}
                        >
                            {submitText}
                        </div>
                    </div>
                </form>
            </div>
        </section>
    );
};
