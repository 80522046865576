import React, { useState, useEffect } from 'react';
import { useLazyQuery, OperationVariables, LazyQueryHookOptions } from '@apollo/client';

import { Alert } from '@common/Alert';

import { SourceTypeInterface, ApolloQuery, AutosuggestApolloProps as Props } from './AutosuggestInterfaces';
import { Autosuggest } from './Autosuggest';

export function AutosuggestApollo<SourceType extends SourceTypeInterface>(
    {
        selectedOptions = [],
        placeholder,
        query,
        options,
        isSuggestableOnFocus = true,
        className,
        onChange,
        formatOption = (option) => option.id,
        formatSendingValue = (value) => value,
        transformResponse = (items) => items,
    } : Props<SourceType>,
): JSX.Element | null {
    const [errorAlerts, setErrorAlerts] = useState<JSX.Element[] | []>([]);
    const [isOptionsLoaded, setIsOptionsLoaded] = useState(false);
    const [
        loadOptionsCallback,
        {
            data: remoteData,
            loading,
        },
    ] = useLazyQuery<{ options: SourceType[] }>(
        query as ApolloQuery<SourceType>,
        {
            skip: !!(options),
            onError: (error) => {
                setErrorAlerts((arr) => [...arr, (<Alert
                    key={new Date().toISOString()}
                    message={error.message}
                />)]);
            },
            onCompleted: () => {
                setIsOptionsLoaded(true);
            },
            fetchPolicy: 'no-cache',
        } as LazyQueryHookOptions<{ options: SourceType[]; }, OperationVariables>,
    );

    useEffect(() => {
        if (loading) {
            setIsOptionsLoaded(false);
        }
    }, [loading]);

    function loadOptions(value: string) {
        loadOptionsCallback({
            variables: {
                value: formatSendingValue(value),
            },
        });
    }

    return (
        <div>
            <Autosuggest
                placeholder={placeholder}
                className={className}
                options={options ?? (
                    remoteData ? transformResponse(remoteData) : undefined
                )}
                formatOption={formatOption}
                onChange={onChange}
                loadOptions={options ? undefined : loadOptions}
                isOptionsLoaded={options ? undefined : isOptionsLoaded}
                selectedOptions={selectedOptions}
                isSuggestableOnFocus={isSuggestableOnFocus}
            />
            {errorAlerts}
        </div>
    );
}
