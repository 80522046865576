import React, { useState } from 'react';
import { nanoid } from 'nanoid';
import map from 'lodash/map';
import forEach from 'lodash/forEach';
import { CreateBuildingInput, TerritorialZone } from '@admin/UniversityPage/types';
import { UniversityBuildingForm } from '../UniversityBuildingForm';
import classes from './UniversityBuildingAdd.module.scss';
import { addZoneIdToBuildings, trimStateBuildingsValues } from '../UniversityBuildings/utilities';

interface Props {
    territorialZones: TerritorialZone[];

    createBuilding(building: CreateBuildingInput): void;

    handleChangeListMode(): void;
}

export function UniversityBuildingAdd({
    territorialZones,
    createBuilding,
    handleChangeListMode,
}: Props): JSX.Element {
    const [stateBuildings, setStateBuildings] = useState<CreateBuildingInput[]>([
        {
            id: nanoid(),
            name: '',
            location: '',
            zoneId: territorialZones[0].name,
        },
    ]);

    const addEmptyBuilding = (): void => {
        const nanoId = nanoid();
        const emptyBuilding = {
            id: nanoId,
            name: '',
            location: '',
            zoneId: territorialZones[0].name,
        };
        setStateBuildings([...stateBuildings, emptyBuilding]);
    };

    const handleSubmit = (event: React.SyntheticEvent): void => {
        event.preventDefault();
        setStateBuildings(trimStateBuildingsValues(stateBuildings));

        forEach(
            addZoneIdToBuildings(territorialZones, stateBuildings),
            (building) => createBuilding(building),
        );

        handleChangeListMode();
    };

    const handleInputChange = ({
        target,
    }:
    | React.ChangeEvent<HTMLInputElement>
    | React.ChangeEvent<HTMLSelectElement>): void => {
        const { id, name, value } = target;

        setStateBuildings(
            map(stateBuildings, (building) => (building.id === id ? {
                ...building,
                [name]: value,
            } : building)),
        );
    };

    return (
        <div className={classes.buildingAdd}>
            <p className={classes.buildingAdd__title}>Добавить корпус:</p>
            <UniversityBuildingForm
                handleInputChange={handleInputChange}
                territorialZones={territorialZones}
                buildings={stateBuildings}
                handleChangeListMode={handleChangeListMode}
                addRemoveBtnLabel="добавить корпус"
                handleAddRemoveBtn={addEmptyBuilding}
                onSubmit={handleSubmit}

            />
        </div>
    );
}
