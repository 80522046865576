import React, { useState } from 'react';
import cn from 'classnames';

import { CalendarWithTime } from '@admin/EducationPeriodPage/CalendarWithTime';
import {
    formatModuleAssessmentDate,
} from '@admin/EducationPeriodPage/EducationPeriodDates/EducationPeriodDatesEdit/EducationPeriodDatesAccordion';

import {
    ParametersTransform,
} from '@admin/EducationPeriodPage/EducationPeriodDates/EducationPeriodDatesEdit/EducationPeriodDatesAccordion/AccordionItems';
import {
    getTransformedParameters,
} from '@admin/EducationPeriodPage/EducationPeriodDates/EducationPeriodDatesEdit/EducationPeriodDatesAccordion/AccordionItems/AccordionItem';

import classes from '../../AccordionItems.module.scss';

interface Props {
    name: string;
    dateEnd?: string;
    isDynamicStartDateActive: boolean;
    parametersArray: ParametersTransform[];
    setDynamicStartDateActive: (isActive: boolean) => void;
    setModuleAssessDynamicStart: (isDynamic: boolean) => void;
    setBtiParameters: (newParams: ParametersTransform[]) => void;
}

export function DynamicModuleItem(
    {
        name,
        dateEnd,
        isDynamicStartDateActive,
        parametersArray,
        setDynamicStartDateActive,
        setModuleAssessDynamicStart,
        setBtiParameters,
    }: Props,
) {
    const [isEndEditing, setEndEditing] = useState(false);
    return (
        <div
            className={
                cn(classes.items__container, classes.items__container_double, {
                    [classes.items__container_selected]: isEndEditing,
                })
            }
        >
            <div
                className={cn(classes.items__item, classes.items__item_label, {
                    [classes.items__item_disabled]: !isDynamicStartDateActive,
                })}
                onClick={() => {
                    setDynamicStartDateActive(true);
                    setModuleAssessDynamicStart(true);
                }}
            >
                <div
                    className={classes.items__dot}
                >
                    <div
                        className={cn(
                            {
                                [classes.items__dot_notActive]: !isDynamicStartDateActive,
                                [classes.items__dot_active]: isDynamicStartDateActive,
                            },
                        )}
                    />
                </div>
                Оценивать сразу после окончания модуля с общей датой завершения
            </div>

            <div
                className={cn(classes.items__item, {
                    [classes.items__item_active]:
                    isEndEditing,
                    [classes.items__item_s]: !isEndEditing,
                    [classes.items__item_disabled]: !isDynamicStartDateActive,
                })}
                onClick={() => isDynamicStartDateActive && setEndEditing(true)}
            >
                {
                    isEndEditing ? (
                        <CalendarWithTime
                            currentDate={new Date(dateEnd || new Date())}
                            autoFocus
                            onChangeCalendar={(date) => {
                                setBtiParameters(
                                    getTransformedParameters(
                                        parametersArray,
                                        name,
                                        '',
                                        date.toISOString(),
                                        true,
                                    ),
                                );
                            }}
                            onCloseCalendar={() => setEndEditing(false)}
                        />
                    ) : (
                        <>
                            {
                                dateEnd && isDynamicStartDateActive ? (
                                    <>
                                        {formatModuleAssessmentDate(dateEnd)}
                                    </>
                                ) : 'Дата завершения'
                            }
                        </>
                    )
                }
            </div>
        </div>
    );
}
