import React from 'react';
import { useQuery } from '@apollo/client';

import { GET_SKILL_TYPES_WITH_MODULE_SETTINGS, SkillTypesWithModuleSettings } from '../../gql/skillTypesWithModuleSettings';
import { StudentInfo } from './StudentInfo';
import { getPlainModuleSettings } from '../../ToolBar/Tools/SkillsButton/PopupWithSkills/mapping/moduleSettings';
import { getSkillTypesExtended } from '../../ToolBar/Tools/SkillsButton/PopupWithSkills/mapping/skillTypes';
import { Student } from '../models';
import { getSkillTypeTree } from './mapping/skillTypes';

interface Props {
    student: Student;
    edit?: boolean,
}

export function StudentInfoApollo({ student, edit }: Props): JSX.Element {
    const {
        data: skillTypesWithModuleSettings,
        loading: loadingSkillTypes,
    } = useQuery<SkillTypesWithModuleSettings>(
        GET_SKILL_TYPES_WITH_MODULE_SETTINGS, {
            fetchPolicy: 'cache-and-network',
        },
    );

    return (
        <StudentInfo
            student={student}
            edit={edit}
            loadingSkillTypes={loadingSkillTypes}
            skillTypeTree={
                getSkillTypeTree(
                    student.maxSkills,
                    getSkillTypesExtended(
                        getPlainModuleSettings(
                            skillTypesWithModuleSettings?.moduleSettings,
                        ),
                        skillTypesWithModuleSettings?.skillTypes,
                    ),
                )
            }
        />
    );
}
