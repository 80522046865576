import { makeAutoObservable } from 'mobx';
import {
    GroupedWaves,
    GroupedWavesWithSelectedStudents, StudentWithAdditionalFields,
    WaveWithSelectedStudents,
} from '@admin/WaveSelectionPage/Interfaces';
import {
    Waves_waves,
    ForkUndistributedStudents_forkUndistributedStudents_student
    as Student,
    StudentsRatings_studentsRating
    as StudentWithRating,
} from '@admin/WaveSelectionPage/graphql-types';
import { sortBy } from 'lodash';

class WaveSelectionStore {
    private notDistribStudents: StudentWithAdditionalFields[] = [];

    private moduleWavesWithSelectedStudents: GroupedWavesWithSelectedStudents[] = [];

    private isRequiredStudentMoved: boolean = false;

    private activeCurrentModuleId: string = '';

    private wavesSelectedStudent: StudentWithAdditionalFields[] = [];

    private studentsWithRating: StudentWithRating[] = [];

    private isAllDisabled: boolean = false;

    constructor() {
        makeAutoObservable(this);
    }

    setNotDistribStudentsFromForkStudents = (
        newStudents: Student[],
    ) => {
        this.notDistribStudents = getStudentsWithSelectedField(newStudents);
    };

    setNotDistribStudents = (
        notDistribStudents: StudentWithAdditionalFields[],
    ) => {
        this.notDistribStudents = notDistribStudents;
    };

    setModuleWavesWithSelectedStudents = (
        newWaves: GroupedWavesWithSelectedStudents[],
    ) => {
        this.moduleWavesWithSelectedStudents = newWaves;
    };

    setModuleWavesWithSelectedStudentsFromGroupedWaves = (
        newGroupedWaves: GroupedWaves,
    ) => {
        this.moduleWavesWithSelectedStudents = getGroupedWavesWithSelectedStudents(newGroupedWaves);
    };

    setIsRequiredStudentMoved = (
        isMoved: boolean,
    ) => {
        this.isRequiredStudentMoved = isMoved;
    };

    setActiveCurrentModuleId = (
        moduleId: string,
    ) => {
        this.activeCurrentModuleId = moduleId;
    };

    setWaveSelectedStudents = (
        newSelectedStudents: StudentWithAdditionalFields[],
    ) => {
        this.wavesSelectedStudent = newSelectedStudents;
    };

    setStudentsWithRatings = (
        studentsWithRatings: StudentWithRating[],
    ) => {
        this.studentsWithRating = studentsWithRatings;
    };

    setIsAllDisabled = (
        isDisabled: boolean,
    ) => {
        this.isAllDisabled = isDisabled;
    };

    getNotDistribStudents = () => this.notDistribStudents;

    getModuleWavesWithSelectedStudents = () => this.moduleWavesWithSelectedStudents;

    getIsRequiredStudentMoved = () => this.isRequiredStudentMoved;

    getActiveCurrentModuleId = () => this.activeCurrentModuleId;

    getWaveSelectedStudents = () => this.wavesSelectedStudent;

    getStudentsWithRatings = () => this.studentsWithRating;

    getIsAllDisabled = () => this.isAllDisabled;
}

export const waveSelectionStore = new WaveSelectionStore();

function getGroupedWavesWithSelectedStudents(
    groupedWaves: GroupedWaves,
): GroupedWavesWithSelectedStudents[] {
    return Object.entries(groupedWaves).map(([key, waves]) => (
        {
            moduleId: key,
            waves: getWavesWithSelectedStudents(waves),
        }
    ));
}

function getWavesWithSelectedStudents(
    waves: Waves_waves[],
): WaveWithSelectedStudents[] {
    return waves.map((wave) => (
        {
            ...wave,
            additionalStudents: [],
            students: getStudentsWithSelectedField(wave.students),
        }
    ));
}

function getStudentsWithSelectedField(
    students: Student[],
): StudentWithAdditionalFields[] {
    const studentsWithSF = students.map((student) => (
        {
            ...student,
            isSelected: false,
            wasMoved: false,
        }
    ));

    return sortBy(studentsWithSF, student => student.user.lastName);
}
