import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { sortBy } from 'lodash';

import { HeaderRedesign } from '@common/HeaderRedesign';
import { NotDistribStudentsAccordion } from '@admin/ModuleSelectionPage/NotDistribStudentsAccordion';
import { ModuleStudentsAccordion } from '@admin/ModuleSelectionPage/ModuleStudentsAccordion';

import {
    Fork,
    ModuleGroupWithAdditionalFields,
    SortTypes,
    StudentWithAdditionalFields,
    StudentWithAFAndRating,
} from '@admin/ModuleSelectionPage/Interfaces';
import { AuthRoute } from '@common/AuthRoute';
import { Redirect, useHistory, useRouteMatch } from 'react-router-dom';
import { Loader } from '@common/Loader';
import { moduleSelectionDataStore } from '@admin/ModuleSelectionPage/ModuleSelectionDataStore';
import { moduleSelectionStore } from '@admin/ModuleSelectionPage/ModuleSelectionStore';

import cn from 'classnames';
import { SelectionPointButtonRedesign } from '@common/SelectionPointButtonRedesign';
import { ButtonName } from '@common/SelectionPointButtonRedesign/buttonHooks';
import { ActionTypeOfButton } from '@common/ActionButton';
import { toJS } from 'mobx';
import { TabsOption } from '@common/TabsRedesign/TabsRedesign';
import {
    // eslint-disable-next-line max-len
    SpaceBaseTimeInterval_spaceBaseTimeInterval_spaceBaseTimeIntervalSubspaces_forks_nextSlots_module
    as Module, StudentsRatings_studentsRating
    as StudentWithRating,
} from './graphql-types';
import { ViewType } from '../../deprecated-graphql-query-types';

import arrow from './Arrow.svg';

import classes from './ModuleSelectionPage.module.scss';

interface Props {
    isLoading: boolean;
}

export const ModuleSelectionPage = observer((
    {
        isLoading,
    } : Props,
) : JSX.Element => {
    const { path } = useRouteMatch();

    const history = useHistory();

    const isSelectionButtonDisabled = !!moduleSelectionStore.getMovedStudents().length
    || !!moduleSelectionStore.getNotDistribStudentsWithAdditionalFields().length
        || !moduleSelectionDataStore.getIsModuleDistributionValid();

    const {
        forkId,
        spaceId,
        baseTimeIntervalId,
        baseTimeIntervalInstanceId,
        isNotStudentChoice,
        btiOrder,
    } = moduleSelectionDataStore.getURLParameters();

    const tabsOptions = getTabsOptionsFromForks(
        baseTimeIntervalInstanceId,
        baseTimeIntervalId,
        btiOrder,
        spaceId,
        toJS(moduleSelectionDataStore.getForks()),
    );

    useEffect(() => {
        moduleSelectionDataStore.updateURLParameters();
        moduleSelectionStore.setMovedStudents([]);
    }, [window.location.search]);

    useEffect(() => {
        moduleSelectionDataStore.setCurrentModuleIds(
            getCurrentForkModulesIds(
                forkId,
                moduleSelectionDataStore.getForks(),
            ),
        );
        moduleSelectionStore.setModuleGroupsFromForkModules(
            getCurrentForkModules(
                forkId,
                moduleSelectionDataStore.getForks(),
            ),
        );
    }, [
        moduleSelectionDataStore.getURLParameters(),
        moduleSelectionDataStore.getForks(),
    ]);

    return (
        <div className={classes.moduleSelection}>
            <div className={classes.moduleSelection__header}>
                <HeaderRedesign
                    imageSrc={arrow}
                    title={`Распределение по модулям (${btiOrder} семестр)`}
                    tabsOptions={tabsOptions}
                    withHiddenTabs
                    imageOnClickEvent={() => {
                        history.push('/education-period');
                    }}
                />
                <SelectionPointButtonRedesign
                    buttonName={
                        ButtonName.START_WAVE_DISTRIBUTION
                    }
                    disabled={isSelectionButtonDisabled}
                    BTIId={baseTimeIntervalInstanceId}
                    buttonActionType={ActionTypeOfButton.PRIMARY}
                    beforeRequestAction={() => moduleSelectionStore.setIsAllDisabled(true)}
                    afterRequestAction={() => moduleSelectionStore.setIsAllDisabled(false)}
                    buttonText="Начать распределение по потокам"
                    navLink="/education-period"
                />
            </div>

            {
                !isLoading ? (
                    <div className={classes.moduleSelection__content}>

                        <div className={classes.moduleSelection__students}>
                            {
                                !isNotStudentChoice ? (
                                    <NotDistribStudentsAccordion />
                                ) : null
                            }

                        </div>

                        <div
                            className={cn(classes.moduleSelection__modules, {
                                [classes.moduleSelection__modules_required]: isNotStudentChoice,
                            })}
                        >
                            {
                                moduleSelectionStore.getModuleGroupsWithAdditionalFields()
                                    .map((moduleGroup) => (
                                        <ModuleStudentsAccordion
                                            key={moduleGroup.module.id}
                                            currentModuleGroup={
                                                changeModuleMinMaxStudents(moduleGroup)
                                            }
                                        />
                                    ))
                            }
                        </div>
                    </div>
                ) : (
                    <div className={classes.wrapper}>
                        <Loader />
                    </div>
                )
            }
            {
                !checkIfTabsHasCurrentURL(tabsOptions) && (
                    <AuthRoute
                        path={path}
                        requiredUserTypes={[ViewType.Admin]}
                    >
                        <Redirect to={`${path}/${tabsOptions[0].link}`} />
                    </AuthRoute>
                )
            }
        </div>
    );
});

export function getStudentRating(
    studentId: string,
    studentsWithRatings: StudentWithRating[],
): string | number {
    const studentRating = studentsWithRatings.find(
        (studentWithRating) => studentWithRating.student.id === studentId,
    )?.value;
    return studentRating || studentRating === 0 ? studentRating.toFixed(1) : '-';
}

function getCurrentForkModulesIds(
    currentForkId: string,
    forks: Fork[],
): string[] {
    const currentFork = forks.find((fork) => fork.id === currentForkId);

    if (!currentFork || !currentFork.slots.length) return [];

    return currentFork.slots.map((slot) => (slot.module ? slot.module.id : ''));
}

function getCurrentForkModules(
    currentForkId: string,
    forks: Fork[],
): Module[] {
    const currentFork = forks.find((fork) => fork.id === currentForkId);

    if (!currentFork || !currentFork.slots.length) {
        return [];
    }

    const modules = currentFork.slots.map((slot) => slot.module);

    return modules.flatMap((module) => (module ? [module] : []));
}

function checkIfTabsHasCurrentURL(
    tabsOptions: {
        title: string,
        link: string,
    }[],
) {
    return tabsOptions.some((option) => {
        const splitOption = option.link.split('/');
        return splitOption[splitOption.length - 1] === `${window.location.search}`;
    });
}

function getTabsOptionsFromForks(
    btiInstanceId: string,
    btiId: string,
    btiOrder: string,
    spaceId: string,
    forks: Fork[],
): TabsOption[] {
    if (forks.length === 0) {
        return [
            {
                title: 'noLink',
                link: `90900/?forkId=undefined&&btiId=${btiId}&&spaceId=${spaceId}&&btiIId=${btiInstanceId}&&isRequired=false&&btiOrder=${btiOrder}`,
            },
        ];
    }

    let forkIndex = 0;

    const sortedForksById = sortBy(forks, fork => fork.id);

    const sortedForks = sortBy(sortedForksById, fork => fork.isNotStudentChoice);

    return sortedForks.map((fork, index) => {
        const doesForkIndexIncreases = !fork.isNotStudentChoice
            && index !== 0 && fork.subspace.name === sortedForks[index - 1].subspace.name;

        if (doesForkIndexIncreases) {
            forkIndex += 1;
        } else {
            forkIndex = 1;
        }

        return {
            title: fork.isNotStudentChoice ? `Обязательные модули / ${fork.subspace.name}` : `Развилка ${forkIndex} / ${fork.subspace.name}`,
            link: `${index}${fork.id}/?forkId=${fork.id}&&btiId=${btiId}&&spaceId=${spaceId}&&btiIId=${btiInstanceId}&&isRequired=${fork.isNotStudentChoice}&&btiOrder=${btiOrder}`,
            additionalInfoOnHover: fork.isNotStudentChoice ? `Обязательные модули / ${fork.subspace.name}` : `${fork.subspace.name}`,
            isNotStudentChoiceFork: fork.isNotStudentChoice,
        };
    });
}

export function getStudentCurrentModuleId(
    studentId: string,
    moduleGroups: ModuleGroupWithAdditionalFields[],
) {
    return moduleGroups.find((group) => (
        group.students.find((student) => student.user.studentId === studentId)
    ))?.module.id ?? '';
}

export function changeModuleMinMaxStudents(
    moduleGroup: ModuleGroupWithAdditionalFields,
): ModuleGroupWithAdditionalFields {
    const currentWaveIndex = Math.ceil(
        moduleGroup.students.length / moduleGroup.module.maxStudentCount,
    );
    const moduleWaveStudents = getWaveStudents(
        moduleGroup.students.length,
        moduleGroup.module.minStudentCount,
        moduleGroup.module.maxStudentCount,
        moduleGroup.module.maxWaveCount,
        currentWaveIndex,
    );
    return (
        {
            ...moduleGroup,
            module: {
                ...moduleGroup.module,
                minStudentCount: moduleWaveStudents.minStudentsCount,
                maxStudentCount: moduleWaveStudents.maxStudentsCount,
                currentWaveIndex: moduleWaveStudents.currentWaveIndex,
            },
        }
    );
}

export function getWaveStudents(
    studentsLength: number,
    minStudentsCount: number,
    maxStudentsCount: number,
    maxWaveCount: number,
    currentWaveIndex: number,
) {
    const studentsDiff = maxStudentsCount - studentsLength;
    const maxMinDiff = maxStudentsCount - minStudentsCount;
    if (studentsDiff >= 0) {
        return (
            {
                minStudentsCount,
                maxStudentsCount,
                currentWaveIndex,
            }
        );
    }
    if (currentWaveIndex > maxWaveCount) {
        if (maxMinDiff < 5) {
            return (
                {
                    minStudentsCount: minStudentsCount * maxWaveCount,
                    maxStudentsCount: maxStudentsCount * maxWaveCount,
                    currentWaveIndex: maxWaveCount,
                }
            );
        }
        return (
            {
                minStudentsCount: (maxStudentsCount * (maxWaveCount - 1)) + 1,
                maxStudentsCount: maxStudentsCount * maxWaveCount,
                currentWaveIndex: maxWaveCount,
            }
        );
    }
    if (maxMinDiff < 5) {
        if (studentsLength > maxStudentsCount
            && studentsLength < minStudentsCount * currentWaveIndex) {
            return (
                {
                    minStudentsCount: minStudentsCount * currentWaveIndex,
                    maxStudentsCount: currentWaveIndex === 1 ? maxStudentsCount
                        : maxStudentsCount * (currentWaveIndex - 1),
                    currentWaveIndex,
                }
            );
        }
        return (
            {
                minStudentsCount: minStudentsCount * currentWaveIndex,
                maxStudentsCount: maxStudentsCount * currentWaveIndex,
                currentWaveIndex,
            }
        );
    }
    return (
        {
            minStudentsCount: (maxStudentsCount * (currentWaveIndex - 1)) + 1,
            maxStudentsCount: maxStudentsCount * currentWaveIndex,
            currentWaveIndex,
        }
    );
}

export function resetStudentsWithAdditionalFields(
    oldStudents: StudentWithAdditionalFields[],
) {
    return oldStudents.map((student) => ({ ...student, isSelected: false }));
}

export function getStudentsWithCurrentActive(
    allStudents: StudentWithAdditionalFields[],
    currentStudent: StudentWithAdditionalFields,
) {
    return allStudents.map((student) => {
        if (student.user.id === currentStudent.user.id) {
            return {
                ...student,
                isSelected: !student.isSelected,
            };
        }
        return student;
    });
}

export function getSortedStudents(
    sortTypes: SortTypes,
    studentsRatings: StudentWithRating[],
    students: StudentWithAdditionalFields[],
): StudentWithAdditionalFields[] {
    const sortedStudentsAz = sortBy(students, student => student.user.lastName);

    const studentsWithRating = getStudentsWithADAndRating(
        studentsRatings,
        students,
    );

    const sortedStudentsByRatingRemovedRating = getSortedStudentsByRating(studentsWithRating);

    if (sortTypes.isSortAz) {
        return sortedStudentsAz.reverse();
    }

    if (sortTypes.isSortZa) {
        return sortedStudentsByRatingRemovedRating;
    }

    return sortedStudentsAz;
}

function getSortedStudentsByRating(
    studentsWithRating: StudentWithAFAndRating[],
): StudentWithAdditionalFields[] {
    const sortedStudentsWithRating = sortBy(
        studentsWithRating, student => student.rating,
    ).reverse();

    return sortedStudentsWithRating.map((student) => (
        {
            __typename: 'Student',
            user: student.user,
            isSelected: student.isSelected,
            wasMoved: student.wasMoved,
        }
    ));
}

function getStudentsWithADAndRating(
    studentsRatings: StudentWithRating[],
    students: StudentWithAdditionalFields[],
): StudentWithAFAndRating[] {
    return students.map((student) => {
        const studentRating = studentsRatings.find(
            (studentWithRating) => studentWithRating.student.id === student.user.studentId,
        );

        return {
            ...student,
            rating: studentRating ? studentRating.value : null,
        };
    });
}

export function checkIfCanSortByRating(
    studentsRatings: StudentWithRating[],
    students: StudentWithAdditionalFields[],
) {
    let hasStudentsRating = false;

    students.forEach((student) => {
        const studentRating = studentsRatings.find(
            (studentWithRating) => studentWithRating.student.id === student.user.studentId,
        );
        if (studentRating) {
            hasStudentsRating = true;
        }
    });

    return hasStudentsRating;
}

export function checkIfStudentIsUnique(
    currentStudent: StudentWithAdditionalFields,
    notDistributedStudents: StudentWithAdditionalFields[],
    moduleGroups: ModuleGroupWithAdditionalFields[],
) {
    const isStudentUniqueInNotDistributedStudents = checkIfStudentIsUniqueInNotDistributedStudents(
        currentStudent,
        notDistributedStudents,
    );

    if (!isStudentUniqueInNotDistributedStudents) {
        return true;
    }

    const isStudentUniqueInModuleGroups = checkIfStudentUniqueInModuleGroups(
        currentStudent,
        moduleGroups,
    );

    return isStudentUniqueInModuleGroups;
}

function checkIfStudentIsUniqueInNotDistributedStudents(
    currentStudent: StudentWithAdditionalFields,
    notDistributedStudents: StudentWithAdditionalFields[],
) {
    const { user } = currentStudent;
    return !notDistributedStudents.some((student) => (
        student.user.firstName === user.firstName
        && student.user.lastName === user.lastName
        && student.user.patronymic === user.patronymic
        && student.user.id !== user.id
    ));
}

function checkIfStudentUniqueInModuleGroups(
    currentStudent: StudentWithAdditionalFields,
    moduleGroups: ModuleGroupWithAdditionalFields[],
) {
    const { user } = currentStudent;

    let isStudentUnique = true;
    moduleGroups.forEach((group) => {
        const isStudentInGroups = group.students.some((student) => (
            student.user.firstName === user.firstName
            && student.user.lastName === user.lastName
            && student.user.patronymic === user.patronymic
            && student.user.id !== user.id));
        if (isStudentInGroups) {
            isStudentUnique = !isStudentInGroups;
        }
    });

    return isStudentUnique;
}
