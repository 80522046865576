import React from 'react';
import {
    TeachersMap,
} from '../../../../../OccupationDashboardInterfaces';
import classes from './TeachersList.module.scss';
import { Teacher } from './Teacher';

interface Props {
    ids: string[];
    teachersMap: TeachersMap;
    index: number;
    isTUniversityMeetingsVisible: boolean;
}

const ROLE_TEACHERS_START_COLUMN_LINE = 2;
const ROLE_TEACHERS_END_COLUMN_LINE = 3;
const DIFF_BETWEEN_ROLE_INDEX_AND_START_ROW_LINE = 1;
const DIFF_BETWEEN_ROLE_INDEX_AND_END_ROW_LINE = 1 + DIFF_BETWEEN_ROLE_INDEX_AND_START_ROW_LINE;

// здесь задается третья грид сетка для списка преподавателей в роле
// состоит из двух колонок: название имя препода в первой колонке
// и сетка/флексбокс со списком таймслотов
export const TeachersList = ({
    ids,
    teachersMap,
    index,
    isTUniversityMeetingsVisible,
}: Props): JSX.Element => (
    <div
        className={classes.teachersList}
        style={{
            gridColumnStart: ROLE_TEACHERS_START_COLUMN_LINE,
            gridColumnEnd: ROLE_TEACHERS_END_COLUMN_LINE,
            gridRowStart: index + DIFF_BETWEEN_ROLE_INDEX_AND_START_ROW_LINE,
            gridRowEnd: index + DIFF_BETWEEN_ROLE_INDEX_AND_END_ROW_LINE,
            display: 'grid',
            gridTemplateColumns: '380px auto',
            gridTemplateRows: `repeat(${ids.length}, auto)`,
        }}
    >
        {ids.map((id, teacherIndex) => (
            <Teacher
                key={id}
                teacher={teachersMap[id]}
                index={teacherIndex}
                isTUniversityMeetingsVisible={isTUniversityMeetingsVisible}
            />
        ))}
    </div>
);
