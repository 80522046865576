import { observer } from 'mobx-react';
import React from 'react';

import colors from '../../store/ColorConstans';

interface Props {
    size?: number;
    firstColor?: string;
    secondColor?: string;
    thirdColor?: string;
    className?: string;
    handler?: () => void;
}

export const ExclamationPoint = observer(({
    size = 42,
    firstColor = colors.firstColor,
    secondColor = colors.secondColor,
    thirdColor = colors.thirdColor,
    className,
    handler,
}: Props): JSX.Element => (
    <div className={className} style={{ height: `${size}px`, width: `${size}px`, cursor: 'default' }} onClick={handler}>
        <svg width={size} height={size} viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M1.1177 11.2398C0.5 13.3251 0.5 15.8834 0.5 21C0.5 26.1166 0.5 28.6749 1.1177 30.7602C2.56032 35.6304 6.36959 39.4397 11.2398 40.8823C13.3251 41.5 15.8834 41.5 21 41.5C26.1166 41.5 28.6749 41.5 30.7602 40.8823C35.6304 39.4397 39.4397 35.6304 40.8823 30.7602C41.5 28.6749 41.5 26.1166 41.5 21C41.5 15.8834 41.5 13.3251 40.8823 11.2398C39.4397 6.36959 35.6304 2.56032 30.7602 1.1177C28.6749 0.5 26.1166 0.5 21 0.5C15.8834 0.5 13.3251 0.5 11.2398 1.1177C6.36959 2.56032 2.56032 6.36959 1.1177 11.2398ZM23.22 10.65C23.22 10.0977 22.7723 9.65 22.22 9.65H19.81C19.2577 9.65 18.81 10.0977 18.81 10.65V17.75L19.2183 23.0074C19.2588 23.5281 19.6931 23.93 20.2153 23.93H21.8402C22.3643 23.93 22.7995 23.5253 22.8375 23.0026L23.22 17.75V10.65ZM22.74 26.3C22.52 26.06 22.12 25.94 21.54 25.94H20.52C19.92 25.94 19.51 26.06 19.29 26.3C19.07 26.52 18.96 26.92 18.96 27.5V28.94C18.96 29.54 19.07 29.95 19.29 30.17C19.51 30.39 19.92 30.5 20.52 30.5H21.54C22.12 30.5 22.52 30.39 22.74 30.17C22.98 29.95 23.1 29.54 23.1 28.94V27.5C23.1 26.92 22.98 26.52 22.74 26.3Z" fill="url(#paint0_linear_3780_311)" />
            <defs>
                <linearGradient id="paint0_linear_3780_311" x1="44.4639" y1="18.6646" x2="-0.103571" y2="36.0614" gradientUnits="userSpaceOnUse">
                    <stop stopColor={firstColor} />
                    <stop offset="0.0416667" stopColor={firstColor} />
                    <stop offset="0.130208" stopColor={firstColor} />
                    <stop offset="0.734375" stopColor={secondColor} />
                    <stop offset="0.932292" stopColor={thirdColor} />
                </linearGradient>
            </defs>
        </svg>

    </div>
));
