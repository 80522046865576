import React, { useEffect, useState } from 'react';

import { WeekDaysPickerWithTime, WeekDayWithTime } from '@common/WeekDaysPickerWithTime';
import { MainDropDown, TMainDropDownOption } from '@common/MainDropDown';
import { PlusIconWithWhiteBorder } from '@common';
import { nanoid } from 'nanoid';
import classes from './IntervalByWeekDays.module.scss';
import { Day, RepeatingAvailableIntervalInput, WeekType } from '../../../../../../../graphql-query-types';

const dropDownOptions: TMainDropDownOption[] = [
    {
        id: WeekType.every,
        name: 'Каждую неделю',
    },
    {
        id: WeekType.even,
        name: 'Каждую четную неделю',
    },
    {
        id: WeekType.odd,
        name: 'Каждую нечетную неделю',
    },
];

interface Props {
    universityTimeZone: string;
    repeatingAvailableIntervals: RepeatingAvailableIntervalInput[];
    setRepeatingAvailableIntervals: (
        newRepeatingIntervals: RepeatingAvailableIntervalInput[],
    ) => void;
}

export function IntervalByWeekDays(
    {
        universityTimeZone,
        repeatingAvailableIntervals,
        setRepeatingAvailableIntervals,
    }: Props,
) {
    const {
        canAddInterval,
        activeOption,
        setActiveOption,
        setWeekDaysWithTime,
        updateRepeatingAvailableIntervals,
    } = useGetIntervalByWeekDays(
        universityTimeZone,
        repeatingAvailableIntervals,
        setRepeatingAvailableIntervals,
    );

    return (
        <div className={classes.intervalByWeekDays}>

            {
                canAddInterval && (
                    <div className={classes.intervalByWeekDays__picker}>
                        <WeekDaysPickerWithTime
                            getWeekDaysWithTime={(newWeekDaysWithTime) => {
                                setWeekDaysWithTime(newWeekDaysWithTime);
                            }}
                        />

                        <button
                            type="button"
                            onClick={() => updateRepeatingAvailableIntervals()}
                        >
                            <PlusIconWithWhiteBorder />
                        </button>
                    </div>
                )
            }

            <div className={classes.intervalByWeekDays__options}>
                <MainDropDown
                    defaultOption={activeOption}
                    options={dropDownOptions}
                    optionsListClassname={classes.intervalByWeekDays__options_list}
                    additionalOptionHandleCLick={(option) => setActiveOption(option)}
                />
            </div>

        </div>
    );
}

function useGetIntervalByWeekDays(
    universityTimeZone: string,
    repeatingAvailableIntervals: RepeatingAvailableIntervalInput[],
    setRepeatingAvailableIntervals: (
        newRepeatingIntervals: RepeatingAvailableIntervalInput[],
    ) => void,
) {
    const [canAddInterval, setCanAddInterval] = useState(true);
    const [weekDaysWithTime, setWeekDaysWithTime] = useState<WeekDayWithTime[]>([]);
    const [activeOption, setActiveOption] = useState<TMainDropDownOption>(
        dropDownOptions[0],
    );

    const normalizedWeekDaysWithTime: RepeatingAvailableIntervalInput[] = weekDaysWithTime.map(
        (weekDayWithTime) => {
            const fromNormalized = weekDayWithTime.fromTime.length ? weekDayWithTime.fromTime : '00:00';
            const toNormalized = weekDayWithTime.toTime.length ? weekDayWithTime.toTime : '00:00';

            const from = `${fromNormalized}${universityTimeZone}`;
            const to = `${toNormalized}${universityTimeZone}`;
            return (
                {
                    id: nanoid(),
                    from,
                    to,
                    weekType: WeekType[activeOption.id as WeekType],
                    weekDay: Day[weekDayWithTime.weekDay],
                }
            );
        },
    );

    const updateRepeatingAvailableIntervals = () => {
        const uniqIntervals = getUniqRepeatingAvailableIntervals(
            [...repeatingAvailableIntervals, ...normalizedWeekDaysWithTime],
        );

        if (normalizedWeekDaysWithTime.length) {
            setCanAddInterval(false);
        }

        setRepeatingAvailableIntervals(
            uniqIntervals,
        );
    };

    useEffect(() => {
        setCanAddInterval(true);
    }, [
        canAddInterval,
    ]);

    return {
        canAddInterval,
        activeOption,
        setActiveOption,
        setWeekDaysWithTime,
        updateRepeatingAvailableIntervals,
    };
}

function getUniqRepeatingAvailableIntervals(
    repeatingAvailableIntervals: RepeatingAvailableIntervalInput[],
) {
    const uniqIntervals: RepeatingAvailableIntervalInput[] = [];

    repeatingAvailableIntervals.forEach((interval) => {
        const isIntervalNotUniq = uniqIntervals.some(
            ({
                from,
                to,
                weekType,
                weekDay,
            }) => (
                from === interval.from && to === interval.to
                && weekType === interval.weekType && weekDay === interval.weekDay
            ),
        );

        if (!isIntervalNotUniq) {
            uniqIntervals.push(interval);
        }
    });

    return uniqIntervals;
}
