import React from 'react';
import { observer } from 'mobx-react';

import { formatWaves } from '@common/formatters';

import { FilterType } from '../../../../Interfaces';
import { eventsStore, filterDescription } from '../../../../Store';

import classes from './EventInfo.module.scss';

export const EventInfo = observer((): JSX.Element => (
    <div className={classes.eventHeader__info}>
        <div className={classes.eventHeader__infoTop}>
            <p className={classes.eventHeader__infoTop_type}>
                {eventsStore.selectedEvent
                    && filterDescription[eventsStore.selectedEvent?.type]}
            </p>
            <div className={classes.eventHeader__infoTop_name}>
                {eventsStore.selectedEvent?.type === FilterType.MEETING
                    && `«${eventsStore.selectedEvent.meeting.topic}»`
                }
                {eventsStore.selectedEvent?.type === FilterType.EVALUATION_POINT
                    && [
                        `«${eventsStore.selectedEvent.evaluationPoint.topic}»`,
                        formatWaves(eventsStore.selectedEvent.waves),
                    ].join(', ')
                }
                {eventsStore.selectedEvent?.type === FilterType.ASSIGNMENT
                    && `«${eventsStore.selectedEvent.assignmentInstance.assignment.topic}»`
                }
            </div>
        </div>
        <div className={classes.eventHeader__moduleName}>
            {eventsStore.selectedEvent?.type === FilterType.MEETING
                && [
                    `«${eventsStore.selectedEvent.meeting.module.name}»`,
                    formatWaves(eventsStore.selectedEvent.waves),
                ].join(', ')
            }
        </div>
        {eventsStore.selectedEvent?.type !== FilterType.ASSIGNMENT && (
            <div className={classes.eventHeader__infoBottom_format}>
                {eventsStore.selectedEvent?.type === FilterType.MEETING
                    && eventsStore.selectedEvent?.meeting.format.name}
            </div>
        )}
    </div>
));
