import React from 'react';
import classes from './ProgressBar.module.scss';

interface Props {
    progressPercents: number
}

export const ProgressBar = ({
    progressPercents,
}: Props): JSX.Element => (
    <div className={classes.progressBar}>
        <div
            style={{
                width: `${progressPercents}%`,
            }}
            className={classes.progressBar__percentageScale}
        />
    </div>
);
