import React from 'react';
import { observer } from 'mobx-react';

import { getHoursRange } from '../../../TimeRuler/helpers';
import { getWeekColumns } from '../helpers';
import { DayMeeting } from '../../../DateEvent';
import { TimePastLine } from '../../../TimePastLine';
import { eventsStore, timeStore } from '../../../../Store';

import classes from './WeekScheduleGrid.module.scss';

export const WeekScheduleGrid = observer((): JSX.Element => (
    <div className={classes.weekScheduleGrid}>
        {getWeekColumns(
            timeStore.selectedDate,
            eventsStore.getTimeReservingEvents(),
        ).map((weekColumn) => (
            <div className={classes.column} key={weekColumn.id}>
                {getHoursRange().map((hour) => (
                    <div
                        className={classes.row}
                        key={hour}
                    />
                ))}

                {weekColumn.dateEvents.map((scheduleEvent) => (
                    <DayMeeting
                        dateEvent={scheduleEvent}
                        allDateEvents={weekColumn.dateEvents}
                        key={scheduleEvent.id}
                    />
                ))}
            </div>
        ))}

        <TimePastLine />
    </div>
));
