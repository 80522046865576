import React, { SyntheticEvent } from 'react';
import cn from 'classnames';
import { IconDeprecated } from '../../../../../../../common';

import classes from './AllStudentsToggler.module.scss';

interface AllStudentsTogglerProps {
    notAllStudentsToggled: boolean;
    toggleAllStudents: boolean;
    editStudentMode: boolean;
    toggleAttendStudent({ currentTarget }: SyntheticEvent<HTMLDivElement>): void;
}

export function AllStudentsToggler({
    notAllStudentsToggled,
    toggleAllStudents,
    editStudentMode,
    toggleAttendStudent,
}: AllStudentsTogglerProps): JSX.Element {
    return (
        <>
            {
                !editStudentMode && (
                    <div
                        className={classes.schedulePage_eventsSection_checkboxContainer}
                    >
                        <div
                            className={classes.schedulePage_eventsSection_customCheckbox}
                            onClick={(e) => toggleAttendStudent(e)}
                            id="All"
                        >
                            <span
                                className={
                                    cn(classes.schedulePage_eventsSection_checkboxNone,
                                        {
                                            [classes.schedulePage_eventsSection_checkboxLine]:
                                                !toggleAllStudents && notAllStudentsToggled,
                                        })
                                }
                            />
                            {
                                toggleAllStudents && notAllStudentsToggled
                                && (
                                    <IconDeprecated
                                        id="checkmark"
                                        className={
                                            classes.schedulePage_eventsSection_checkboxFill
                                        }
                                    />
                                )
                            }
                        </div>
                        <span
                            className={classes.schedulePage_eventsSection_checkboxText}
                        >Все
                        </span>
                    </div>
                )
            }
        </>
    );
}
