import { makeAutoObservable, runInAction } from 'mobx';

export enum UserType {
    STUDENT = 'student',
    TEACHER = 'teacher',
}

class AccountStore {
    userType!: UserType;

    userId: string | null = null;

    teacherId: string | null = null; // при авторизации добавить id

    studentId: string | null = null; // при авторизации добавить id

    constructor() {
        makeAutoObservable(this);
    }

    setUserId = (userId: string): void => {
        this.userId = userId;
    };

    setTeacherId = (teacherId: string): void => {
        this.teacherId = teacherId;
    };

    setStudentId = (studentId: string): void => {
        this.studentId = studentId;
    };

    changeUserType = (userType: UserType): void => {
        runInAction(() => {
            this.userType = userType;
        });
    };

    isTeacher = (): boolean => (
        this.userType === UserType.TEACHER
    );

    isStudent = (): boolean => (
        this.userType === UserType.STUDENT
    );

    getUserType = (): UserType => this.userType;
}

export const accountStore = new AccountStore();
