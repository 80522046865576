import React, { useRef, useState } from 'react';
import { observer } from 'mobx-react';
import cn from 'classnames';

import { CommonTargetName, useSendMetriks } from '@common/hooks';
import useOnClickOutside from '../../Hooks/useOnClickOutside';
import { eventsStore, timeStore, UnitOfTime, unitOfTimeTranslation } from '../../Store';
import { UnitOfTimeSelectMobile } from './UnitOfTimeSelectMobile';
import { ViewType } from '../../../../deprecated-graphql-query-types';

import classes from './UnitOfTimeSelect.module.scss';

export const UnitOfTimeSelect = observer(() => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const refUnitOfTimeSelect = useRef<null | HTMLDivElement>(null);

    const { sendYandexMetrika } = useSendMetriks([ViewType.Student, ViewType.Teacher]);

    function toggle(): void {
        setIsOpen((prevIsOpen) => !prevIsOpen);
    }

    function close(): void {
        setIsOpen(false);
    }

    function changeUnitOfTimeAndClose(unitOfTime: UnitOfTime): void {
        timeStore.setUnitOfTime(unitOfTime);
        close();
        sendYandexMetrika(
            CommonTargetName.schedule_view,
            { [CommonTargetName.schedule_view]: unitOfTime },
        );
    }

    useOnClickOutside(refUnitOfTimeSelect, close);

    return (
        <>
            <div className={classes.unitOfTimeSelect} ref={refUnitOfTimeSelect}>
                <div
                    className={cn(classes.unitOfTimeSelect__select, {
                        [classes.unitOfTimeSelect__select_isOpen]: isOpen,
                    })}
                    onClick={toggle}
                >
                    <div className={classes.currentUnitOfTime}>
                        {unitOfTimeTranslation[timeStore.unitOfTime]}
                    </div>
                    <svg className={cn(classes.arrowIcon, { [classes.arrowIcon_isOpen]: isOpen })} width="16" height="10" viewBox="0 0 16 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M8.57101 9.1685L15.7637 1.97337C16.0787 1.65765 16.0787 1.14614 15.7637 0.829623C15.4488 0.513905 14.9373 0.513905 14.6224 0.829623L8.00037 7.45404L1.37834 0.83042C1.06342 0.514704 0.551907 0.514704 0.23619 0.83042C-0.0787304 1.14614 -0.0787304 1.65845 0.23619 1.97417L7.42886 9.16929C7.74052 9.48016 8.26008 9.48016 8.57101 9.1685Z" fill="url(#paint0_linear)" />
                        <defs>
                            <linearGradient id="paint0_linear" x1="7.08858" y1="-0.0439975" x2="9.35558" y2="10.5039" gradientUnits="userSpaceOnUse">
                                <stop offset="0.130208" stopColor="#0B42DC" />
                                <stop offset="0.734375" stopColor="#57B2C6" />
                                <stop offset="0.932292" stopColor="#EED660" />
                            </linearGradient>
                        </defs>
                    </svg>
                </div>

                {isOpen && (
                    <div className={classes.unitOfTimeSelect__optionsList}>
                        {timeStore.unitOfTime !== UnitOfTime.MONTH && (
                            <div
                                className={classes.unitOfTimeSelect__option}
                                onClick={() => changeUnitOfTimeAndClose(UnitOfTime.MONTH)}
                            >
                                Месяц
                            </div>
                        )}
                        {timeStore.unitOfTime !== UnitOfTime.WEEK && (
                            <div
                                className={classes.unitOfTimeSelect__option}
                                onClick={() => changeUnitOfTimeAndClose(UnitOfTime.WEEK)}
                            >
                                Неделя
                            </div>
                        )}
                        {timeStore.unitOfTime !== UnitOfTime.DAY && (
                            <div
                                className={classes.unitOfTimeSelect__option}
                                onClick={() => changeUnitOfTimeAndClose(UnitOfTime.DAY)}
                            >
                                День
                            </div>
                        )}
                    </div>
                )}

            </div>
            {!eventsStore.selectedEvent && (
                <UnitOfTimeSelectMobile
                    unitOfTime={timeStore.unitOfTime}
                    changeUnitOfTimeAndClose={changeUnitOfTimeAndClose}
                />
            )}
        </>
    );
});
