import { gql } from '@apollo/client';

import { useMutationWithErrorHandling } from './useMutationWithErrorHandling';

const FINISH_SCHEDULE_GENERATION = gql`
mutation finishScheduleGeneration($finishScheduleGenerationInput: FinishScheduleGeneration!) {
        finishScheduleGeneration(finishScheduleGenerationInput: $finishScheduleGenerationInput) {
            id
            name
            from
            to
            status
            constraintMatch {
                constraintMatchCountList {
                    constraintName
                    scoreType
                    matchCount
                }
            }
        }
    }
`;

export function useFinishScheduleGenerationMutation(onCompleted?: () => void) {
    const [finishScheduleGeneration] = useMutationWithErrorHandling(
        FINISH_SCHEDULE_GENERATION, { onCompleted },
    );
    return (
        generationProcessId: string,
        doImport: boolean,
    ) => finishScheduleGeneration({
        variables: {
            finishScheduleGenerationInput: {
                generationProcessId,
                doImport,
            },
        },
    });
}
