import React, { useMemo } from 'react';
import cln from 'classnames';
import { SubspacesTypesRectangles, SubspaceType } from '../interfaces';
import { Rectangle } from '../services/rectangle';
import { ConnectionStatus, ConnectionsStatuses } from '../services/connectionsView';
import { getSubspaceType } from '../services/subspaceType';
import { getSourceId, getTargetId, getConnectionId } from '../services/subspacesConnection';

interface Props {
    subspacesTypesRects: SubspacesTypesRectangles;
    subspaceTypes: SubspaceType[];
    connectionsStatuses: ConnectionsStatuses;
    tempConnectionsStatuses: ConnectionsStatuses;
}

interface SubspacesLine {
    x1: number;
    y1: number;
    x2: number;
    y2: number;
}

interface SubspacesLines {
    [id: string]: SubspacesLine;
}

export function DiagramConnections({
    subspacesTypesRects: rects,
    subspaceTypes: types,
    connectionsStatuses,
    tempConnectionsStatuses,
}: Props): JSX.Element {
    const contentLinks: JSX.Element[] = useMemo(getContentLinks,
        [rects, types, tempConnectionsStatuses]);

    // получение геометрических характеристик подпространста
    function getSubspaceRect(subspaceId: string): Rectangle | undefined {
        const element = document.getElementById(subspaceId);

        if (!element) return undefined;

        const topLeftX = element.offsetLeft;
        const topLeftY = element.offsetTop;
        const width = element.offsetWidth;
        const height = element.offsetHeight;

        return new Rectangle(topLeftX, topLeftY, width, height);
    }

    // построение связей
    function getSubspacesLines(): SubspacesLines {
        const res: SubspacesLines = {};

        types.forEach(sourceType => {
            sourceType.subspaces.forEach(source => {
                source.targetSubspaces.forEach(target => {
                    const targetType = getSubspaceType(target.id, types);

                    if (targetType) {
                        const sourceTypeRect: Rectangle = rects[sourceType.id];
                        const targetTypeRect: Rectangle = rects[targetType.id];
                        const sourceRect = getSubspaceRect(source.id);
                        const targetRect = getSubspaceRect(target.id);

                        if (sourceRect && targetRect && targetTypeRect) {
                            const connectionId = getConnectionId(source.id, target.id);

                            res[connectionId] = {
                                x1: sourceTypeRect.getBottomRightX(),
                                y1: sourceTypeRect.getTopLeftY() + sourceRect.getCenterY(),
                                x2: targetTypeRect.getTopLeftX(),
                                y2: targetTypeRect.getTopLeftY() + targetRect.getCenterY(),
                            };
                        }
                    }
                });
            });
        });

        // добавляем временно-добавленные связи со статусом
        Object.keys(tempConnectionsStatuses).forEach((connectionId: string) => {
            const sourceId = getSourceId(connectionId);
            const targetId = getTargetId(connectionId);

            const sourceType = getSubspaceType(sourceId, types);
            const targetType = getSubspaceType(targetId, types);

            if (sourceType && targetType) {
                const sourceTypeRect: Rectangle = rects[sourceType.id];
                const targetTypeRect: Rectangle = rects[targetType.id];
                const sourceRect = getSubspaceRect(sourceId);
                const targetRect = getSubspaceRect(targetId);

                if (sourceRect && targetRect) {
                    res[connectionId] = {
                        x1: sourceTypeRect.getBottomRightX(),
                        y1: sourceTypeRect.getTopLeftY() + sourceRect.getCenterY(),
                        x2: targetTypeRect.getTopLeftX(),
                        y2: targetTypeRect.getTopLeftY() + targetRect.getCenterY(),
                    };
                }
            }
        });

        return res;
    }

    function getContentLinks(): JSX.Element[] {
        let res: JSX.Element[] = [];

        if (Object.keys(rects).length && types) {
            const subspacesLines: SubspacesLines = getSubspacesLines();

            res = Object.keys(subspacesLines).map((connectionId: string) => {
                const addedClass: any = {};

                if (tempConnectionsStatuses[connectionId]) {
                    if (tempConnectionsStatuses[connectionId]
                        === ConnectionStatus.Add) {
                        addedClass.temp = true;
                    } else if (tempConnectionsStatuses[connectionId]
                        === ConnectionStatus.Muted) {
                        addedClass.temp = true;
                        addedClass.muted = true;
                    } else if (tempConnectionsStatuses[connectionId]
                        === ConnectionStatus.Delete) {
                        addedClass.disable = true;
                    }
                } else {
                    const isMutedLink = connectionsStatuses[connectionId]
                        === ConnectionStatus.Muted;
                    addedClass.muted = isMutedLink;
                }

                const linkClass = cln('subspaces-link', addedClass);

                return (
                    <line
                        className={linkClass}
                        key={connectionId}
                        id={connectionId}
                        x1={subspacesLines[connectionId].x1}
                        y1={subspacesLines[connectionId].y1}
                        x2={subspacesLines[connectionId].x2}
                        y2={subspacesLines[connectionId].y2}
                    />
                );
            });
        }

        return res;
    }

    return (
        <div className="diagram-connections">
            <svg className="connections" xmlns="http://www.w3.org/2000/svg">
                {contentLinks}
            </svg>
        </div>
    );
}
