import React, { useEffect, useState } from 'react';

import { TimeInput } from '@common/TimeInput';
import { DatePickerWithMonthName } from '@common/DatePickerWithMonthName';

import { ExcludedDateInterval } from '@common/CalendarNedoshkovskiy';
import classes from './DatePickerWithTime.module.scss';

export interface DateWithTime {
    fromTime: string;
    toTime: string;
    fromDate?: Date;
    toDate?: Date;
}

interface SelectedDates {
    start: Date;
    end: Date;
}

interface Props {
    isEndDateDisabled?: boolean;
    showMonthName?: boolean;
    datePickerClassName?: string;
    selectedDates?: SelectedDates,
    excludedDateIntervals?: ExcludedDateInterval[];
    includedDateIntervals?: ExcludedDateInterval[];
    getDateWithTime?: (
        newDateWithTime: DateWithTime,
    ) => void;
    onEndDateCalendarOpen?: () => void;
    onBlur?: () => void;
}

export function DatePickerWithTime(
    {
        isEndDateDisabled,
        showMonthName,
        datePickerClassName,
        selectedDates,
        excludedDateIntervals,
        includedDateIntervals,
        getDateWithTime,
        onEndDateCalendarOpen,
        onBlur,
    }: Props,
) {
    const {
        getFromDate,
        getToDate,
        getFromTime,
        getToTime,
        dateWithTime,
    } = useGetDateWithTime();

    useEffect(() => {
        if (getDateWithTime) {
            getDateWithTime(dateWithTime);
        }
    }, [dateWithTime]);

    return (
        <div className={classes.datePickerWithTime}>

            <div className={classes.datePickerWithTime__dateWithTime}>
                <DatePickerWithMonthName
                    showMonthName={showMonthName}
                    excludedDateIntervals={excludedDateIntervals}
                    includedDateIntervals={includedDateIntervals}
                    selectedDate={selectedDates?.start}
                    getDate={(date) => getFromDate(date)}
                    onBlur={onBlur}
                />

                <TimeInput
                    onChange={(event) => getFromTime(event.target.value)}
                />
            </div>

            <div className={classes.datePickerWithTime__separator}>
                —
            </div>

            <div className={classes.datePickerWithTime__dateWithTime}>
                <DatePickerWithMonthName
                    className={datePickerClassName}
                    showMonthName={showMonthName}
                    excludedDateIntervals={excludedDateIntervals}
                    includedDateIntervals={includedDateIntervals}
                    isDisabled={isEndDateDisabled}
                    getDate={(date) => getToDate(date)}
                    selectedDate={selectedDates?.end}
                    onCalendarOpen={onEndDateCalendarOpen}
                    onBlur={onBlur}
                />

                <TimeInput
                    onChange={(event) => getToTime(event.target.value)}
                />
            </div>

        </div>
    );
}

function useGetDateWithTime() {
    const [fromDate, setFromDate] = useState<Date>();
    const [toDate, setToDate] = useState<Date>();
    const [fromTime, setFromTime] = useState('00:00');
    const [toTime, setToTime] = useState('00:00');

    const [dateWithTime, setDateWithTime] = useState<DateWithTime>(
        {
            fromDate,
            toDate,
            fromTime,
            toTime,
        },
    );

    const getFromDate = (
        newDate: Date,
    ) => {
        setFromDate(newDate);
    };

    const getToDate = (
        newDate: Date,
    ) => {
        setToDate(newDate);
    };

    const getFromTime = (
        newTime: string,
    ) => {
        setFromTime(newTime);
    };

    const getToTime = (
        newTime: string,
    ) => {
        setToTime(newTime);
    };

    useEffect(() => {
        setDateWithTime(
            {
                fromDate,
                toDate,
                fromTime,
                toTime,
            },
        );
    }, [
        fromDate,
        toDate,
        fromTime,
        toTime,
    ]);

    return {
        getFromDate,
        getToDate,
        getFromTime,
        getToTime,
        dateWithTime,
    };
}
