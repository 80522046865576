import React from 'react';

import { TeacherOccupationRow } from '../OccupationDashboardInterfaces';
import classes from './TimeSlotsList.module.scss';
import { Ruler } from './Ruler';
import { TimeslotCell } from './TimeslotCell';

interface Props {
    occupationDashboardRows?: TeacherOccupationRow[];
    isTUniversityMeetingsVisible: boolean;
}

export const TimeSlotsList = ({ occupationDashboardRows, isTUniversityMeetingsVisible }: Props) => {
    if (occupationDashboardRows) {
        return (
            <div className={classes.timeSlotsList}>
                <table className={classes.timeSlotsList__table}>
                    <thead>
                        <tr key="ruler-row">
                            <Ruler
                                timeSlots={occupationDashboardRows[0].timeslots}
                            />
                        </tr>
                    </thead>
                    <tbody>
                        {occupationDashboardRows.map(({
                            teacher, moduleId, timeslots, teacherRoleId,
                        }) => (
                            <tr key={`${teacher.id}-${teacherRoleId}`}>
                                {timeslots.map(timeslot => (
                                    <TimeslotCell
                                        key={`${moduleId}-${teacherRoleId}-${teacher.id}-${timeslot.date}-${timeslot.startTime}-${timeslot.endTime}`}
                                        isTUniversityMeetingsVisible={isTUniversityMeetingsVisible}
                                        moduleId={moduleId}
                                        teacherRoleId={teacherRoleId}
                                        teacher={teacher}
                                        timeslot={timeslot}
                                    />
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        );
    }

    return null;
};
