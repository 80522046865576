import React from 'react';

import { gql, useQuery } from '@apollo/client';
import { Loader } from '@common/Loader';

import { EquipmentContentRouter } from './EquipmentContentRouter';

const GET_GROUPED_CONSUMABLE_EQUIPMENT = gql`
    query groupedConsumableEquipment(
        $from: String!
        $to: String!
    ) {
        groupedConsumableEquipment(consumableEquipmentParams: {
            from: $from
            to: $to
        }) {
            all {
                consumableEquipment {
                    id
                    name
                    unit
                }
                count
            }
            byTerritorialZone {
                territorialZone {
                    id
                    name
                }
                consumableEquipment {
                    consumableEquipment {
                        id
                        name
                        unit
                    }
                    count
                }
                byRoom {
                    room {
                        id
                        title
                    }
                    consumableEquipment {
                        consumableEquipment {
                            id
                            name
                            unit
                        }
                        count
                    }
                }
            }
        }
    }
`;

interface Props {
    from: string;
    to: string;
}

export function EquipmentContentRouterApollo({ from, to }: Props): JSX.Element {
    const { data, error, loading } = useQuery(GET_GROUPED_CONSUMABLE_EQUIPMENT, {
        fetchPolicy: 'cache-and-network',
        variables: { from, to },
    });
    if (loading) {
        return <Loader />;
    }
    if (error) return <><p>Что-то пошло не так</p></>;
    return (
        <EquipmentContentRouter
            groupedConsumableEquipment={data.groupedConsumableEquipment}
        />
    );
}
