import React from 'react';

import { ActionButton, ActionTypeOfButton } from '@common/ActionButton';
import { Loader, LoaderSizes } from '@common/Loader';

import { ExternalModuleStatus } from './apollo-types';
import classes from './ExternalModule.module.scss';
import { ExternalModuleForm } from '../common/ExternalModuleForm/ExternalModuleForm';
import { ExternalModuleState, ValidationErrors } from './helpers';

type Status = ExternalModuleStatus.draft
| ExternalModuleStatus.verification
| ExternalModuleStatus.revision;

interface Props {
    externalModule: ExternalModuleState;
    validationErrors?: ValidationErrors;
    loadingOnSave?: boolean;
    onSaveButtonClick(status: Status): void;
    onExternalModuleChange?(updatedModuleFields: Partial<ExternalModuleState>): void;
}

const DISABLE_EDIT_MODE_STATUS_LIST = [
    ExternalModuleStatus.verification,
    ExternalModuleStatus.approved,
    ExternalModuleStatus.deleted,
];

export function ExternalModule({
    externalModule,
    validationErrors,
    loadingOnSave,
    onSaveButtonClick,
    onExternalModuleChange,
}: Props) {
    const disabled = externalModule.status
        && DISABLE_EDIT_MODE_STATUS_LIST.includes(externalModule.status);

    return (
        <div className={classes['external-module']}>
            <div className={classes['external-module__title']}>
                {externalModule.status
                    ? 'Редактирование модуля'
                    : 'Создание модуля'
                }
            </div>
            <div className={classes['external-module__form-container']}>
                <ExternalModuleForm
                    externalModule={externalModule}
                    validationErrors={validationErrors}
                    isEditModeDisabled={disabled}
                    onExternalModuleChange={onExternalModuleChange}
                >
                    {!disabled && (
                        <div className={classes['external-module__action-panel']}>
                            <ActionButton
                                actionType={ActionTypeOfButton.SECONDARY}
                                onClick={
                                    () => onSaveButtonClick(!externalModule.status
                                        ? ExternalModuleStatus.draft
                                        : ExternalModuleStatus.revision)}
                                disabled={loadingOnSave}
                            >
                                {externalModule.status
                                    ? 'Сохранить изменения'
                                    : 'Сохранить как черновик'
                                }
                            </ActionButton>
                            <ActionButton
                                actionType={ActionTypeOfButton.PRIMARY}
                                onClick={() => onSaveButtonClick(ExternalModuleStatus.verification)}
                                disabled={loadingOnSave}
                            >
                                Отправить на проверку
                            </ActionButton>
                            {loadingOnSave && (
                                <div className={classes['external-module__action-panel-loader']}>
                                    <Loader size={LoaderSizes.default} />
                                </div>
                            )}
                        </div>
                    )}
                </ExternalModuleForm>
            </div>
        </div>
    );
}
