import { observer } from 'mobx-react';
import React from 'react';

import classes from './Tasks.module.scss';

interface Props {
    deadline?: Date;
    children?: JSX.Element | JSX.Element[];
}

export const Tasks = observer(({ children }: Props) => (
    <section className={classes.Tasks}>
        {children}
    </section>
));
