import { gql } from '@apollo/client';

export const GET_SELECTION_INSTRUCTION = gql`
  query SelectionInstruction {
    selectionInstruction
  }
`;

const SKILL = gql`
fragment Skill on ModuleSkill {
  skill {
    id
    name
    fullName
    typeId
  }
  level
}
`;

const MODULE = gql`
  ${SKILL}
  fragment ModuleFragment on Module {
    id
    name
    description
    maxWaveCount
    maxStudentCount
    moduleTeachers {
      teacher {
          user {
              firstName
              lastName
              patronymic
          }
      }
      teacherRoles {
          name
      }
    }
    prerequisiteSkills {
        skill {
            id
        }
        level
    }
    outputSkills {
        skill {
            id
        }
        level
    }
    meetings {
      id
      topic
      format {
        name
      }
      duration {
        id
        name
        academicHours
      }
      prerequisiteSkills {
        ...Skill
      }
      outputSkills {
        ...Skill
      }
      order
    }
    evaluationPoints {
        id
        topic
        description
        order
        duration {
          id
          name
          academicHours
        }
        evaluationPointSkills {
          ...Skill
        }
    }
    assignments {
      id
      topic
      duration {
        id
        name
        academicHours
      }
      prerequisiteSkills {
        ...Skill
      }
      outputSkills {
        ...Skill
      }
      order
    }
  }
`;

export const GET_ALL_SKILLS = gql`
  query GetAllSkills {
    skills {
      id
      name
      typeId
      parent {
        id
      }
    }
  }
`;
export const GET_STUDENTS_MODULE_PRIORITY_COUNT = gql`
  query StudentsModulePriorityCount($baseTimeIntervalInstanceId: String!)  {
    studentsModulePriorityCount(baseTimeIntervalInstanceId: $baseTimeIntervalInstanceId) {
      moduleId
      count
    }}
  `;

export const GET_STUDENT_INFO = gql`
  ${MODULE}
  query GetStudentInfo($studentId: String!) {
    studentSchedule {
      meetingInstances {
        id
        startDate
        endDate
        meeting {
          id
        }
        meetingInstanceToStudents {
          attendance
          student {
            id
          }
        }
      }

      evaluationPointInstances {
        id
        startDate
        endDate
        evaluationPoint {
          id
        }
      }

      assignmentInstances {
        id
        startDate
        endDate
        assignment {
          id
        }
      }
    }

    student(id: $studentId) {
      subspaces {
        subspace {
          id
        }
      }
      modules {
        module {
          ...ModuleFragment
        }

        subspace {
          id
          name
          type {
            id
            name
          }
        }
      }

      activeBaseTimeIntervalInstance {
        baseTimeIntervalInstance {
          id
        }
      }

      baseTimeIntervalInstances {
        baseTimeIntervalInstance {
          id
          selectionStatus
          startDate
          endDate

          spaceBaseTimeInterval {
            order

            space {
              baseTimeIntervalType {
                name
              }
            }
          }
        }
      }
    }

    moduleSettings {
      meetingSkillTypes {
        id
        isLevelIncreased
      }

      moduleSkillTypes {
        id
        isLevelIncreased
      }
    }
  }
`;

export const GET_PAST_OR_CURRENT_BTI = gql`
  ${MODULE}
  query GetCurrentOrPastBTI(
    $studentId: String!
    $baseTimeIntervalInstanceId: String!
  ) {
    student(
      id: $studentId
      baseTimeIntervalInstanceId: $baseTimeIntervalInstanceId
    ) {
      id
      requestedBaseTimeIntervalInstance {
        baseTimeIntervalInstance {
          id
          startDate
          moduleAssessment {
            id
            startDate
            isDynamicStartDate
            endDate
          }
        }
        studentSubspaces {
          subspace {
            id
            name
            type {
              name
            }
          }
          studentModules {
            id
            isStudentModuleAssessmentActive
            module {
              ...ModuleFragment
            }
            subspace {
              type {
                name
              }
              id
              name
            }
          }
        }
      }
    }
  }
`;

export const GET_FUTURE_BTI = gql`
  ${MODULE}
  query GetFutureBTI($baseTimeIntervalInstanceId: String!) {
    baseTimeIntervalInstance(id: $baseTimeIntervalInstanceId) {
      id
      selectionPoint {
        moduleSelectionStartDate
        moduleSelectionEndDate
      }
      spaceBaseTimeInterval {
        id
        subspaceTypeLayouts {
          subspaceType {
            isSelective
            subspaces {
              id
              name
              type {
                name
              }
              slotDiagram {
                forks {
                  id
                  spaceBaseTimeIntervalId
                  setting {
                    distributionType
                    studentPickingType
                    studentPriorityCount
                    moduleSelectionCount
                  }
                  nextSlots {
                    id
                    spaceBaseTimeIntervalId
                    module {
                      ...ModuleFragment
                    }
                  }
                }
                slots {
                  id
                  spaceBaseTimeIntervalId
                  module {
                    ...ModuleFragment
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_STUDENT_PRIORITIES = gql`
    query GetStudentPriorities($baseTimeIntervalInstanceId: String!) {
        studentPriorities(baseTimeIntervalInstanceId: $baseTimeIntervalInstanceId) {
            studentId
            slotId
            moduleId
            forkId
            selectionIterationId
            priority
        }
}
`;

export const UPDATE_STUDENT_PRIORITY = gql`
    mutation UpdateStudentPriority($updateStudentPriorityInput: UpdateStudentPriorityInput!){
      updateStudentPriority(updateStudentPriorityInput: $updateStudentPriorityInput){
            studentId
        }
}
`;

export const DELETE_STUDENT_PRIORITY = gql`
    mutation DeleteStudentPriority($deleteStudentPriorityInput: DeleteStudentPriorityInput!){
      deleteStudentPriority(deleteStudentPriorityInput: $deleteStudentPriorityInput)
}
`;

export const GET_MODULE_ASSESSMENT_CONFIG = gql`
    query GetModuleAssessmentConfig {
        moduleAssessmentConfig {
            assessmentOptions {
                id
                title
                description
            }
            moduleAssessmentQuestions {
                id
                title
                answerType
            }
            teacherAssessmentQuestions {
                id
                title
                answerType
            }
        }
    }
`;
