import React from 'react';

import cn from 'classnames';
import classes from './EventsList.module.scss';

interface Props {
    viewMode: string;
    setViewMode: (type: string) => void;
}

export function EventListTabs({ viewMode, setViewMode }: Props): JSX.Element {
    return (
        <ul className={classes.eventListTabs}>
            <li
                className={cn(classes.eventListTabs__item, {
                    [classes.eventListTabs__item_active]: viewMode === 'list' })}
            >
                <div
                    className={classes.eventListTabs__text}
                    onClick={() => setViewMode('list')}
                >
                    Список
                </div>
            </li>
            <li
                className={cn(classes.eventListTabs__item, {
                    [classes.eventListTabs__item_active]: viewMode === 'graph' })}
            >
                <div
                    className={classes.eventListTabs__text}
                    onClick={() => setViewMode('graph')}
                >
                    Граф
                </div>
            </li>
        </ul>
    );
}
