import React, { Fragment, useEffect, createElement, useState } from 'react';

import { OperationVariables, QueryLazyOptions } from '@apollo/client';
import { useHistory } from 'react-router-dom';

import { Alert } from '@common/Alert';
import classes from './AuthPage.module.scss';

import { LoginPassword } from './LoginPassword';
import { OAuthButton } from './OAuthButton';

interface Props {
    loginTypes: string[];
    oAuthLink: string;
    basicLogin: (options?: QueryLazyOptions<OperationVariables> | undefined) => void;
    basicLoginData: { basicLogin: string; } | undefined;
    oAuthLoginData?: { oAuthLogin: string; } | undefined;
    redirect?: string | null;
}

export function AuthPage({
    loginTypes, oAuthLink, basicLogin, basicLoginData, oAuthLoginData, redirect,
}: Props): JSX.Element {
    const history = useHistory();
    const params = `scrollbars=no,status=no,location=no,toolbar=no,menubar=no,
        width=700px,height=700px`;
    const openOAuthWindow = () => {
        window.open(`${oAuthLink}`, 'oAuth', params);
        // window.open('http://localhost:3000/auth_result?code=4563bad179918826a2f566169a785139f56bc5cd', 'oAuth', params);
    };
    useEffect(() => {
        if (basicLoginData && basicLoginData.basicLogin !== null) {
            localStorage.setItem('token', basicLoginData.basicLogin);

            // TODO: кажется хорошобы сразу тут получать пользователя и класть его в UserView
            // а то сейчас мы как бы на половину авторизируем пользователя: сохраняем токен, но
            // не сохраняем пользователя. И далее надеемся, что AuthRouteApollo отработает нормально
            // , и кажется что он отрабатывает нормально, но если кто-то вставит мидлвеер между
            //  текущим кодом и AuthRouteApollo, то там пользователя не будет.

            if (redirect) {
                // TODO: Почепму то history.push не работает корректно :(
                window.location.href = redirect;
                // history.push(`/${redirect}`);
            } else {
                history.push('/');
            }
        }
    }, [basicLoginData]);

    useEffect(() => {
        if (oAuthLoginData && oAuthLoginData.oAuthLogin !== null) {
            localStorage.setItem('token', oAuthLoginData.oAuthLogin);

            // TODO: кажется хорошобы сразу тут получать пользователя и класть его в UserView
            // а то сейчас мы как бы на половину авторизируем пользователя: сохраняем токен, но
            // не сохраняем пользователя. И далее надеемся, что AuthRouteApollo отработает нормально
            // , и кажется что он отрабатывает нормально, но если кто-то вставит мидлвеер между
            //  текущим кодом и AuthRouteApollo, то там пользователя не будет.

            if (redirect) {
                // TODO: Почепму то history.push не работает корректно :(
                window.location.href = redirect;
                // history.push(`/${redirect}`);
            } else {
                history.push('/');
            }
        }
    }, [oAuthLoginData]);

    const [isOauthAlertVisible, setOuthAlertVisibility] = useState<boolean>(false);

    const renderComponents: { [key: string]: React.ReactElement; } = {
        basic: createElement(LoginPassword, { basicLogin, basicLoginData }),
        oAuth: createElement(OAuthButton, { handler: openOAuthWindow, title: 'Войти в систему через ДГТУ ID' }),
        oAuthPlug: createElement(OAuthButton, { handler: () => setOuthAlertVisibility(true), title: 'Войти в систему через РХТУ ID' }),
    };

    return (
        <div className={classes.authPage}>
            {loginTypes.map(item => (
                <Fragment key={item}>
                    {renderComponents[item]}
                </Fragment>
            ))}
            {isOauthAlertVisible && (
                <Alert
                    message="Интеграция в Вашем университете не настроена"
                    time={5000}
                    onTimeout={() => setOuthAlertVisibility(false)}
                />
            )}

            {/* И это спрятал до лучших времени */}
            {/* <p className={classes.auth__text}>
                Нажимая на кнопку, вы даете согласие
                на обработку персональных данных
                и соглашаетесь с политикой конфиденциальности
            </p> */}
        </div>
    );
}
