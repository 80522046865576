import React from 'react';
import { format } from 'date-fns';

import { KeyValue } from '@common/KeyValue';
import { UserAbsencesApollo } from '@admin/Users/UserCard/UserCommonInfo/UserAbsences/UserAbsencesApollo';
import { RolesPicker } from '../RolesPicker';
import { Role } from '../models';

import classes from './UserCommonInfo.module.scss';

interface Props {
    userId: string,
    edit: boolean,
    roles: Role[],
    email?: string,
    birthday?: string,
}

export const UserCommonInfo = ({
    userId, birthday, email, roles, edit,
}: Props): JSX.Element => {
    const isStudent = hasUserRoleStudent(roles);

    return (
        <div className={classes.userCommonInfo__block}>
            <div className={classes.userCommonInfo__block}>
                <RolesPicker roles={roles} edit={edit} />
            </div>
            <div className={classes.userCommonInfo__info}>
                <div>
                    <KeyValue
                        title="Дата рождения: "
                    >
                        {birthday && format(Number(birthday), 'dd.MM.yyyy')}
                    </KeyValue>
                    <KeyValue
                        title="e-mail: "
                    >
                        {email}
                    </KeyValue>
                </div>

                <div>
                    {
                        isStudent && (
                            <UserAbsencesApollo
                                id={userId}
                            />
                        )
                    }
                </div>
            </div>
        </div>
    );
};

function hasUserRoleStudent(
    userRoles: Role[],
) {
    return userRoles.some((role) => role.name === 'Student' && role.enabled);
}
