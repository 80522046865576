import React from 'react';
import { observer } from 'mobx-react';

import { Title } from '@common';

import { moduleStore, dictionaryStore, Assignment } from '../Store';
import { Select } from '../Select';
import { Textarea } from '../Textarea';

import style from './Assignment.module.scss';

interface Props {
    assignment: Assignment;
}

export const AssignmentOptions = observer(({ assignment }: Props) => {
    const module = moduleStore.moduleModel;

    return (
        <>
            <Title>{`Самостоятельная работа ${assignment?.assignmentNumber}`}</Title>
            <div className={style.form__theme}>
                <Textarea
                    name="topic"
                    label="Тема:"
                    placeholder="Введите тему самостоятельной работы"
                    value={assignment?.topic ?? ''}
                    onChange={({ target }) => module.updateAssignment(
                        assignment.id, { topic: target.value },
                    )}
                />
            </div>
            <div className={style.form__theme}>
                <Textarea
                    name="description"
                    label="Описание:"
                    placeholder="Введите описание самостоятельной работы"
                    modifier="description"
                    value={assignment?.description}
                    onChange={({ target }) => module.updateAssignment(
                        assignment.id, { [target.name]: target.value },
                    )}
                />
            </div>
            <div className={style.form__container}>
                <Select
                    value={assignment?.duration?.id}
                    name="duration"
                    label="Длительность самостоятельной работы для студента:"
                    onChange={({ target }) => module.updateAssignment(assignment.id, {
                        duration: {
                            id: target.value,
                            name: target.options[target.options.selectedIndex].text,
                            academicHours: dictionaryStore.durations
                                .find(({ id }) => id === target.value)?.academicHours,
                        },
                    })}
                    options={dictionaryStore.durations
                        .map(item => ({ ...item, name: String(item.name) }))}
                    placeholder="Укажите длительность"
                />
            </div>
        </>
    );
});
