import React, { useState } from 'react';
import { Redirect, Switch, useRouteMatch } from 'react-router-dom';
import { FetchResult } from '@apollo/client';

import { AuthRoute } from '@common/AuthRoute';
import { HeaderRedesign } from '@common/HeaderRedesign';
import { ActionButtonRedesign, ActionTypeOfButton } from '@common/ActionButtonRedesign';
import { EducationPeriodDates } from '@admin/EducationPeriodPage/EducationPeriodDates';
import { observer } from 'mobx-react';
import { educationPeriodStore } from '@admin/EducationPeriodPage/EducationPeriodStore';
import { sortBy } from 'lodash';
import { ViewType } from '../../deprecated-graphql-query-types';
import {
    CreateSpaceBaseTimeIntervalParameters,
    DaySchedule,
    Mode,
    Space,
    StudentWorkload,
    UpdateSpaceBaseTimeIntervalParameters,
    RefetchGetSpaces,
} from './EducationPeriodPageInterfaces';

import classes from './EducationPage.module.scss';

type ApolloPromise = Promise<void | FetchResult<any, Record<string, any>, Record<string, any>>>;

export type SetModeFunction = React.Dispatch<React.SetStateAction<Mode>>;

interface Props {
    datesMode: Mode,
    spaces: Space[],
    setDatesMode: SetModeFunction,

    updateParameters(
        spaceId: string,
        daySchedules: DaySchedule[],
        studentWorkload: StudentWorkload,
    ): ApolloPromise,

    updatePeriod(
        id: string,
        spaceId: string,
        name: string,
        spaceBaseTimeIntervalParameters: UpdateSpaceBaseTimeIntervalParameters[],
    ): ApolloPromise,

    createPeriod(
        id: string,
        spaceId: string,
        name: string,
        spaceBaseTimeIntervalParameters: CreateSpaceBaseTimeIntervalParameters[],
    ): ApolloPromise,

    addError: (message: string) => void;

    refetchGetSpaces: RefetchGetSpaces;
}

export const EducationPeriodPage = observer(({
    spaces,
    datesMode,
    updatePeriod,
    createPeriod,
    setDatesMode,
    updateParameters,
    addError,
    refetchGetSpaces,
}: Props): JSX.Element => {
    const [tabsOptions] = useState(() => generateTabsOptions(spaces));
    const [canCancelCreation, setCanCancelCreation] = useState(false);
    const { path } = useRouteMatch();

    const updateParametersData = () => {
        if (educationPeriodStore.updateStudentWorkLoad) {
            updateParameters(
                educationPeriodStore.educationSpace?.id ?? '',
                educationPeriodStore.updateDaysSchedule,
                educationPeriodStore.updateStudentWorkLoad,
            )
                .then((data) => {
                    if (data && data.data) {
                        setDatesMode(Mode.VIEW);
                        educationPeriodStore.clearUpdateParameters();
                    }
                });
        }
    };

    const updatePeriodData = () => {
        if (datesMode === Mode.CREATE && educationPeriodStore.updateBTIParametersData) {
            createPeriod(
                educationPeriodStore.updateBTIParametersData.educationPeriodId,
                educationPeriodStore.updateBTIParametersData.spaceId,
                educationPeriodStore.updateBTIParametersData.name,
                educationPeriodStore.updateSpaceBaseTimeIntervalParameters,
            )
                .then((data) => {
                    if (data && data.data !== null && !data.errors) {
                        updateParametersData();
                    }
                });
        }
        if (educationPeriodStore.updateBTIParametersData && datesMode !== Mode.CREATE) {
            updatePeriod(
                educationPeriodStore
                    .updateBTIParametersData.educationPeriodId,
                educationPeriodStore.updateBTIParametersData.spaceId,
                educationPeriodStore.updateBTIParametersData.name,
                educationPeriodStore.updateSpaceBaseTimeIntervalParameters,
            )
                .then((data) => {
                    if (data && data.data !== null && !data.errors) {
                        updateParametersData();
                    }
                });
        }
    };

    return (
        <div className={classes['education-period']}>
            <div className={classes['education-period__header']}>
                <HeaderRedesign
                    title="Учебный период"
                    tabsOptions={tabsOptions}
                />
            </div>

            <div className={classes['education-period__content']}>

                {
                    datesMode > 0 && (
                        <div className={classes['education-period__settings']}>

                            <ActionButtonRedesign
                                name="edit"
                                type="button"
                                actionType={ActionTypeOfButton.SECONDARY}
                                onClick={() => {
                                    if (!educationPeriodStore.isUpdateError) {
                                        updatePeriodData();
                                    } else {
                                        addError(
                                            'Год начала учебного периода должен быть меньше года конца',
                                        );
                                    }
                                }}
                            >
                                Сохранить
                            </ActionButtonRedesign>

                            <ActionButtonRedesign
                                name="cancel"
                                type="button"
                                actionType={ActionTypeOfButton.GREY}
                                disabled={!canCancelCreation}
                                onClick={() => {
                                    setDatesMode(Mode.VIEW);
                                    educationPeriodStore.clearUpdateParameters();
                                }}
                            >
                                Отменить {datesMode === Mode.EDIT ? 'редактирование' : 'создание'}
                            </ActionButtonRedesign>

                        </div>
                    )
                }

                <Switch>
                    {
                        tabsOptions.map((option, index) => (
                            <AuthRoute
                                exact
                                path={`${path}/${option.link}`}
                                key={`${option.title}`}
                                requiredUserTypes={[ViewType.Admin]}
                            >
                                <>
                                    <EducationPeriodDates
                                        space={spaces[index]}
                                        dateMode={datesMode}
                                        setDatesMode={setDatesMode}
                                        setCanCancelCreation={setCanCancelCreation}
                                        refetchGetSpaces={refetchGetSpaces}
                                    />
                                </>
                            </AuthRoute>
                        ))}
                    <AuthRoute
                        exact
                        path={`${path}`}
                        requiredUserTypes={[ViewType.Admin]}
                    >
                        <Redirect to={`${path}/${tabsOptions[0].link}`} />
                    </AuthRoute>
                </Switch>

            </div>

        </div>
    );
});

function generateTabsOptions(spaces: Space[]) {
    const sortedSpaces = sortBy(spaces, (space) => !space.spaceEducationPeriods.length);

    return sortedSpaces
        .map((space) => ({ title: space.name, link: space.id, additionalInfoOnHover: space.name }));
}
