import { gql } from '@apollo/client';

import { useMutationWithErrorHandling } from './useMutationWithErrorHandling';

const BEGIN_CUSTOM_SCHEDULE_GENERATION = gql`
    mutation startCustomScheduleGeneration($startCustomScheduleGenerationInput: StartCustomScheduleGenerationInput!) {
        startCustomScheduleGeneration(startCustomScheduleGenerationInput: $startCustomScheduleGenerationInput) {
            id
        }
    }
`;

export enum GenerationPhase {
    phase1 = 'phase1',
    phase2 = 'phase2',
    singlePhase = 'singlePhase',
}

export function useBeginCustomScheduleGeneration(onCompleted?: () => void) {
    const [beginScheduleGeneration, { error, loading }] = useMutationWithErrorHandling(
        BEGIN_CUSTOM_SCHEDULE_GENERATION, { onCompleted },
    );

    return {
        startCustomScheduleGeneration: (
            generationProcessId: string,
            ignoreFlags: string[] = [],
            generationConfig: JSON,
            generationPhase: GenerationPhase,
            priority = 3,
            isForceCreateMeetingInstancesIfNotExist = false,
        ) => beginScheduleGeneration({
            variables: {
                startCustomScheduleGenerationInput: {
                    generationProcessId,
                    ignoreFlags,
                    generationConfig,
                    generationPhase,
                    priority,
                    isForceCreateMeetingInstancesIfNotExist,
                },
            },
        }),
        loading,
        error,
    };
}
