import { useQuery } from '@apollo/client';
import { GetBaseTimeIntervalInstanceSchedulePage } from 'src/graphql-query-types';
import { BTI_SPACE_EDUCATION_PERIOD } from '../queries';

export function useBTISpaceEducationPeriodQuery(id: string) {
    const { data, error, loading } = useQuery<GetBaseTimeIntervalInstanceSchedulePage>(
        BTI_SPACE_EDUCATION_PERIOD,
        {
            variables: { id },
        },
    );

    return {
        error,
        loading,
        baseTimeIntervalInstance: data?.baseTimeIntervalInstance,
    };
}
