import React from 'react';
import { observer } from 'mobx-react';

import { IconDeprecated } from '@common';
import { AvatarIcon } from '@common/svg';
import { multipleEvaluationStore } from '../../../Store';

import classes from './MultipleStudent.module.scss';

interface Props {
    id: string;
    firstName: string;
    lastName: string;
    patronymic: string;
}

export const MultipleStudent = observer(({
    id,
    firstName,
    lastName,
    patronymic,
}: Props): JSX.Element => (
    <li className={classes.evaluation__studentList_container}>
        <div className={classes.evaluation__studentsList}>
            <div className={classes.evaluation__buttonContainer}>
                <button
                    type="button"
                    id={id}
                    className={classes.evaluation__button}
                    onClick={() => multipleEvaluationStore.toggleStudentMultipleEvaluation(id)}
                >
                    {multipleEvaluationStore.isStudentAddedToMultipleEvaluation(id) && (
                        <IconDeprecated
                            id="checkmark"
                            className={classes.evaluation__checkmark}
                        />
                    )}
                </button>
            </div>

            <div className={classes.evaluation__studentData}>
                <div className={classes.evaluation__studentAvatar}>
                    <div className={classes.evaluation__studentAvatar_image}>
                        <AvatarIcon size={50} />
                    </div>
                </div>
                <div>
                    <div>{`${lastName} ${firstName}`}</div>
                    <div>{patronymic}</div>
                </div>
            </div>

        </div>
    </li>
));
