import { useQuery } from '@apollo/client';
import {
    EquipmentFilterParams,
    EquipmentPaginationParams,
    GetEquipmentsEquipmentPage,
    GetEquipmentsEquipmentPageVariables,
} from 'src/deprecated-graphql-query-types';
import { GET_EQUIPMENTS } from '../queries';

export const useEquipmentQuery = (
    filterParams: EquipmentFilterParams,
    paginationParams: EquipmentPaginationParams,
) => {
    const {
        data,
        loading,
        error,
        refetch,
    } = useQuery<GetEquipmentsEquipmentPage, GetEquipmentsEquipmentPageVariables>(
        GET_EQUIPMENTS,
        {
            variables: {
                filterParams,
                paginationParams,
            },
        },
    );
    return { data, loading, error, refetch };
};
