import React from 'react';

import { AvatarIcon } from '@common/svg';
import classes from './ModuleMainCharactersTeacher.module.scss';

import {
    Module as ModuleType,
} from '../../subSpaceTypes';

interface Props {
    currentModule: ModuleType;
}

export function ModuleMainCharactersTeacher({ currentModule }: Props): JSX.Element {
    return (
        <div className={classes.moduleMainCharactersTeacher}>
            <div className={classes.moduleMainCharactersTeacher__title}>
                Преподаватели:
            </div>
            {!currentModule.moduleTeachers?.length && (
                <div className={classes.moduleMainCharactersTeacher__empty}>
                    В модуле не указаны преподаватели
                </div>
            )}
            <ul className={classes.moduleMainCharactersTeacher__list}>
                {currentModule.moduleTeachers?.map(teacher => (
                    <li
                        key={teacher.teacher.id}
                        className={classes.moduleMainCharactersTeacher__item}
                    >
                        <div className={classes.moduleMainCharactersTeacher__photo}>
                            <AvatarIcon size={40} />
                        </div>
                        <div className={classes.moduleMainCharactersTeacher__teacherInformation}>
                            <div className={classes.moduleMainCharactersTeacher__teacherName}>
                                {`${teacher.teacher.user.lastName} ${teacher.teacher.user.firstName} ${teacher.teacher.user.patronymic}`}
                            </div>
                            <div className={classes.moduleMainCharactersTeacher__teacherRole}>
                                {teacher.teacherRoles?.map(role => role.name).join(', ')}
                            </div>
                        </div>
                    </li>
                ))}
            </ul>
        </div>
    );
}
