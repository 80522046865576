import { cloneDeep } from 'lodash';
import { SaveExternalModuleInput } from './apollo-types';

export type ExternalModuleState = Pick<SaveExternalModuleInput, 'id'|'invitationId'> & Partial<{
    [K in keyof Omit<SaveExternalModuleInput, 'id'|'invitationId'>]: NonNullable<SaveExternalModuleInput[K]>;
}>;

export type ValidationErrors = Partial<{
    [K in keyof ExternalModuleState]: string;
}>;

export function removeNulls(obj: any): any {
    const objCopy = cloneDeep(obj);

    if (objCopy === null) {
        return undefined;
    }

    if (typeof objCopy === 'object') {
        Object.keys(objCopy).forEach(key => {
            objCopy[key] = removeNulls(obj[key]);
        });
    }

    return objCopy;
}
