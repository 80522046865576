import React from 'react';

import {
    ApolloPromise,
    GroupedWavesWithSelectedStudents,
    StudentWithAdditionalFields,
} from '@admin/WaveSelectionPage/Interfaces';
import { CheckboxRedesign } from '@common/CheckboxRedesign';
import { waveSelectionStore } from '@admin/WaveSelectionPage/WaveSelectionStore';
import { DeleteStudentWaveInput } from '@admin/WaveSelectionPage/graphql-types';
import { waveSelectionDataStore } from '@admin/WaveSelectionPage/WaveSelectionDataStore';

import { getStudentsRemovedCurrentStudent, checkIfStudentIsUnique } from '@admin/WaveSelectionPage/WaveSelectionPage';
import { getStudentRating } from '@admin/ModuleSelectionPage/ModuleSelectionPage';
import cn from 'classnames';
import classes from './NotDistribItem.module.scss';

interface Props {
    order: number;
    currentStudent: StudentWithAdditionalFields;
    deleteStudentWave(input: DeleteStudentWaveInput): ApolloPromise;

}

export function NotDistribItem(
    {
        order,
        currentStudent,
        deleteStudentWave,
    } : Props,
) {
    const {
        forkId,
        baseTimeIntervalInstanceId,
    } = waveSelectionDataStore.getURLParameters();

    const {
        user,
    } = currentStudent;

    const isStudentUnique = checkIfStudentIsUnique(
        currentStudent,
        waveSelectionStore.getNotDistribStudents(),
        waveSelectionStore.getModuleWavesWithSelectedStudents(),
    );

    const studentRating = getStudentRating(
        user.studentId ?? '',
        waveSelectionStore.getStudentsWithRatings(),
    );

    const studentInfo = `${user.lastName} ${user.firstName} ${user.patronymic ?? ''} ${isStudentUnique ? '' : user.studentId}`;

    const currentStudentInSelected = waveSelectionStore.getWaveSelectedStudents()
        .find((student) => student.user.studentId === currentStudent.user.studentId);

    const handleChangeIfStudentIsSelected = () => {
        const wavesRemovedCurrentStudentFromAdditional = getWavesRemovedCurrentFromAdditional(
            currentStudent.user.studentId ?? '',
            waveSelectionStore.getModuleWavesWithSelectedStudents(),
        );

        // eslint-disable-next-line max-len
        const notDistributedStudentsChangedCurrentIsSelected = getStudentsCurrentStudentChangedSelected(
            currentStudent.user.studentId ?? '',
            waveSelectionStore.getNotDistribStudents(),
        );

        waveSelectionStore.setModuleWavesWithSelectedStudents(
            wavesRemovedCurrentStudentFromAdditional,
        );

        waveSelectionStore.setNotDistribStudents(
            notDistributedStudentsChangedCurrentIsSelected,
        );
    };

    const handleChangeIfStudentInWaveSelected = () => {
        deleteStudentWave(
            {
                studentId: currentStudent.user.studentId ?? '',
                baseTimeIntervalInstanceId,
                forkId,
                waveId: currentStudentInSelected?.currentWaveId ?? '',
            },
        )
            .then((result) => {
                if (result && !result.errors) {
                    // eslint-disable-next-line max-len
                    const wavesRemovedCurrentStudentFromPreviousModule = getWavesRemoveCurrentFromPreviousModule(
                        currentStudent.user.studentId ?? '',
                        currentStudentInSelected?.currentModuleId ?? '',
                        waveSelectionStore.getModuleWavesWithSelectedStudents(),
                    );

                    waveSelectionStore.setModuleWavesWithSelectedStudents(
                        wavesRemovedCurrentStudentFromPreviousModule,
                    );
                }
            });
    };

    const handleChangeIfStudentNotInWaveSelected = () => {
        const wavesAddedCurrentStudentToAdditional = getWavesAddedCurrentToAdditional(
            currentStudent,
            waveSelectionStore.getModuleWavesWithSelectedStudents(),
        );

        // eslint-disable-next-line max-len
        const notDistributedStudentsChangedCurrentStudentIsSelected = getStudentsCurrentStudentChangedSelected(
            currentStudent.user.studentId ?? '',
            waveSelectionStore.getNotDistribStudents(),
        );

        waveSelectionStore.setModuleWavesWithSelectedStudents(
            wavesAddedCurrentStudentToAdditional,
        );

        waveSelectionStore.setNotDistribStudents(
            notDistributedStudentsChangedCurrentStudentIsSelected,
        );
    };

    const handleChangeIfStudentNotSelected = () => {
        const waveSelectedStudentsRemovedCurrentStudent = getStudentsRemovedCurrentStudent(
            user.studentId ?? '',
            waveSelectionStore.getWaveSelectedStudents(),
        );

        waveSelectionStore.setWaveSelectedStudents(
            waveSelectedStudentsRemovedCurrentStudent,
        );

        waveSelectionStore.setActiveCurrentModuleId('');

        if (currentStudentInSelected) {
            handleChangeIfStudentInWaveSelected();
        } else {
            handleChangeIfStudentNotInWaveSelected();
        }
    };

    const handleChange = () => {
        if (currentStudent.isSelected) {
            handleChangeIfStudentIsSelected();
        } else {
            handleChangeIfStudentNotSelected();
        }
    };
    return (
        <div className={classes.item_wrapper}>
            {
                currentStudentInSelected || currentStudent.isSelected ? (
                    <div
                        className={cn(classes.item, {
                            [classes.item_fromWave]: currentStudentInSelected,
                            [classes.item_selected]: currentStudent.isSelected,
                            [classes.item_wasMoved]: currentStudent.wasMoved,
                        })}
                    >
                        <CheckboxRedesign
                            id={currentStudent.user.id}
                            gap="7px"
                            checked={currentStudent.isSelected}
                            handleChangeCheckbox={() => handleChange()}
                        >
                            <div
                                className={classes.item__checkbox}
                                style={{
                                    borderTop: order === 1 ? '1px solid #ECEAE9' : '',
                                }}
                            >
                                <div className={classes.item__title}>
                                    {
                                        studentInfo
                                    }
                                </div>
                                <div className={classes.item__rating}>
                                    {
                                        studentRating
                                    }
                                </div>
                            </div>
                        </CheckboxRedesign>
                    </div>
                ) : (
                    <>
                        <div
                            className={cn(classes.item, classes.item_active, {
                                [classes.item_fromWave]: currentStudentInSelected,
                                [classes.item_selected]: currentStudent.isSelected,
                                [classes.item_wasMoved]: currentStudent.wasMoved,
                            })}
                        >
                            <CheckboxRedesign
                                id={currentStudent.user.id}
                                gap="7px"
                                checked={currentStudent.isSelected}
                                handleChangeCheckbox={() => handleChange()}
                            >
                                <div
                                    className={classes.item__checkbox}
                                    style={{
                                        borderTop: order === 1 ? '1px solid #ECEAE9' : '',
                                    }}
                                >
                                    <div className={classes.item__title}>
                                        {
                                            studentInfo
                                        }
                                    </div>
                                    <div className={classes.item__rating}>
                                        {
                                            studentRating
                                        }
                                    </div>
                                </div>
                            </CheckboxRedesign>
                        </div>

                        <div
                            className={cn(classes.item, classes.item_static, {
                                [classes.item_fromWave]: currentStudentInSelected,
                                [classes.item_selected]: currentStudent.isSelected,
                                [classes.item_wasMoved]: currentStudent.wasMoved,
                            })}
                        >
                            <div
                                className={classes.item__checkbox}
                                style={{
                                    borderTop: order === 1 ? '1px solid #ECEAE9' : '',
                                }}
                            >
                                <div className={classes.item__title}>
                                    {
                                        studentInfo
                                    }
                                </div>
                                <div className={classes.item__rating}>
                                    {
                                        studentRating
                                    }
                                </div>
                            </div>
                        </div>
                    </>
                )
            }
        </div>
    );
}

function getWavesRemovedCurrentFromAdditional(
    currentStudentId: string,
    groups: GroupedWavesWithSelectedStudents[],
): GroupedWavesWithSelectedStudents[] {
    return groups.map((group) => {
        if (checkIfCurrentStudentInModuleGroupStudents(
            currentStudentId,
            group,
        )) return group;

        return {
            ...group,
            waves: group.waves.map((wave) => (
                {
                    ...wave,
                    additionalStudents: wave.additionalStudents.filter(
                        (student) => student.user.studentId !== currentStudentId,
                    ),
                }
            )),
        };
    });
}

function getWavesAddedCurrentToAdditional(
    currentStudent: StudentWithAdditionalFields,
    groups: GroupedWavesWithSelectedStudents[],
): GroupedWavesWithSelectedStudents[] {
    return groups.map((group) => {
        if (checkIfCurrentStudentInModuleGroupStudents(
            currentStudent.user.studentId ?? '',
            group,
        )) return group;

        return {
            ...group,
            waves: group.waves.map((wave) => (
                {
                    ...wave,
                    additionalStudents: [
                        ...wave.additionalStudents,
                        {
                            ...currentStudent,
                            wasMoved: true,
                        },
                    ],
                }
            )),
        };
    });
}

function getWavesRemoveCurrentFromPreviousModule(
    currentStudentId: string,
    previousModuleId: string,
    groups: GroupedWavesWithSelectedStudents[],
): GroupedWavesWithSelectedStudents[] {
    return groups.map((group) => {
        if (group.moduleId !== previousModuleId) {
            return {
                ...group,
                waves: group.waves.map((wave) => (
                    {
                        ...wave,
                        additionalStudents:
                            getStudentsRemoveCurrent(currentStudentId, wave.additionalStudents),
                    }
                )),
            };
        }

        return {
            ...group,
            waves: group.waves.map((wave) => (
                {
                    ...wave,
                    additionalStudents:
                        getStudentsRemoveCurrent(currentStudentId, wave.additionalStudents),
                    students: getStudentsRemoveCurrent(currentStudentId, wave.students),
                }
            )),
        };
    });
}

function getStudentsCurrentStudentChangedSelected(
    currentStudentId: string,
    students: StudentWithAdditionalFields[],
) {
    return students.map((student) => {
        if (student.user.studentId !== currentStudentId) return student;

        return {
            ...student,
            isSelected: !student.isSelected,
        };
    });
}

function getStudentsRemoveCurrent(
    currentStudentId: string,
    students: StudentWithAdditionalFields[],
) {
    return students.filter((student) => student.user.studentId !== currentStudentId);
}

function checkIfCurrentStudentInModuleGroupStudents(
    currentStudentId: string,
    moduleGroup: GroupedWavesWithSelectedStudents,
) {
    return moduleGroup.waves.some((wave) => (
        wave.students.some((student) => student.user.studentId === currentStudentId)
    ));
}
