import React from 'react';
import { observer } from 'mobx-react-lite';

import NestedSkill from '@admin/SkillPage/SkillAddPage/store/NestedSkill';

import { checkChild, getSkillTypeById } from '@admin/SkillPage/SkillAddPage/SkillAddWithNest/SkillAddFunctions';
import { SkillLevelEditing } from '@admin/SkillPage/SkillLevelEditing';
import { EditForm } from './EditForm';

import classes from './EditForm.module.scss';

import { skillTypesData_skillTypes } from '../../../../deprecated-graphql-query-types';

interface Props {
    skillTypes: skillTypesData_skillTypes[];
}

export const SkillCardWithNestChildrenRoot = observer(({ skillTypes }: Props) => {
    const { hasChild, skillChild } = checkChild(skillTypes, NestedSkill.skills.typeId);
    const { skillType } = getSkillTypeById(skillTypes, NestedSkill.skills.typeId);
    const nameError = 'Напишите название образовательного результата';
    const hasLevel = NestedSkill.skills.maxLevel !== null;

    const blurHandler = () => {
        if (NestedSkill.skills.name === '') {
            NestedSkill.notValid.set(NestedSkill.skills.id, true);
        }
    };

    const onChangeName = (currentTarget: any) => {
        if (!currentTarget.value) {
            NestedSkill.notValid.set(NestedSkill.skills.id, true);
        } else {
            NestedSkill.notValid.set(NestedSkill.skills.id, false);
        }
        NestedSkill.update(NestedSkill.skills.id, {
            name: currentTarget.value,
            typeId: skillType.id,
        });
    };

    return (
        <div className={classes['skill-add-with-nest']}>
            <form className={classes['skill-add-with-nest__form']}>
                <label className={classes['skill-add-with-nest__label']}>
                    {!NestedSkill.notValid.get(NestedSkill.skills.id)
                        ? <div>Название {(skillType.name.genitiveSingular)?.toLowerCase()}:</div>
                        : <div style={{ color: 'red', width: '130%' }}>{nameError}</div>
                    }
                    <input
                        type="text"
                        className={!NestedSkill.notValid.get(NestedSkill.skills.id)
                            ? classes['skill-add-with-nest__input']
                            : classes['skill-add-with-nest__input--red-border']
                        }
                        id="skill-nest-add__input"
                        value={NestedSkill.skills.name}
                        onBlur={() => blurHandler()}
                        onChange={({ currentTarget }) => onChangeName(currentTarget)}
                    />
                </label>
            </form>
            {hasLevel && (
                <SkillLevelEditing
                    currentLevel={NestedSkill.skills.maxLevel!}
                    maxLevel={Number(skillType.properties!.maxLevel)}
                    onChange={maxLevel => NestedSkill
                        .update(NestedSkill.skills.id, { maxLevel })}
                />
            )}
            {NestedSkill.skills.children && hasChild && (
                <EditForm
                    currentSkill={NestedSkill.skills}
                    currentSkillType={skillChild}
                    skillTypes={skillTypes}
                />
            )}
        </div>
    );
});
