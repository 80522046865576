import React from 'react';
import { eventsStore } from '../../Store';
import { AssignmentEvent } from './AssignmentEvent';

import { ScheduleEventHeader } from './ScheduleEventHeader';
import { ScheduleEventSection } from './ScheduleEventSection';

export function ScheduleEvent(): JSX.Element {
    const isAssignment = eventsStore.isAssignment(eventsStore.selectedEvent);
    return (
        <>
            {!isAssignment ? (
                <>
                    <ScheduleEventHeader />
                    <ScheduleEventSection />
                </>
            ) : (
                <AssignmentEvent />
            )}

        </>
    );
}
