import React from 'react';
import { useQuery } from '@apollo/client';
import { MultiAutosuggest } from '@common/MultiAutosuggest';
import { GET_STUDENT_MODULES } from '../../queries';
import { assignmentsStore } from '../../store/AssignmentsStore';
import UserView from '../../../../store/UserView';
import { Module, StudentActiveBTIModules, StudentActiveBTIModulesVariables } from '../../interfaces';
import classes from './Search.module.scss';

function moduleTitleFormat(item: Module): string {
    return `${item.name}`;
}

export const Search = () => {
    const {
        data: studentModules,
    } = useQuery<StudentActiveBTIModules, StudentActiveBTIModulesVariables>(
        GET_STUDENT_MODULES,
        {
            variables: {
                id: UserView.studentId!,
                baseTimeIntervalInstanceId: assignmentsStore.getActiveBaseTimeIntervalInstanseId(),
            },
            skip: !assignmentsStore.getActiveBaseTimeIntervalInstanseId(),
        },
    );

    function onChange(options: Module[]) {
        const selectedOption = options.slice(options.length - 1)[0];
        assignmentsStore.setFilterModuleId(selectedOption?.id);
    }

    const suggestOptions = studentModules?.student
        .requestedBaseTimeIntervalInstance.studentSubspaces
        .flatMap(subspace => subspace.studentModules
            .map(({ module }) => module));

    const selectedOptions = assignmentsStore.getFilterModuleId()
        ? suggestOptions?.filter(({ id }) => id === assignmentsStore.getFilterModuleId())
        : [];

    return (
        <section className={classes.Search}>
            <h2 className={classes.Search__title}>Поиск по модулю</h2>

            <MultiAutosuggest
                placeholder="Введите название модуля"
                formatOption={moduleTitleFormat}
                onChange={onChange}
                options={suggestOptions}
                selectedOptions={selectedOptions}
            />
        </section>
    );
};
