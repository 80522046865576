import React from 'react';
import { EvaluationStudentsTabs } from './EvaluationStudentsTabs';

import classes from './EvaluationHeader.module.scss';

export function EvaluationHeader(): JSX.Element {
    return (
        <header className={classes.evaluationHeader}>
            <div className={classes.evaluationHeader__text}>
                Оценить результаты
            </div>
            <EvaluationStudentsTabs />
        </header>
    );
}
