import React from 'react';
import { SelectedItem, SelectedLevelItem } from '../SelectedItem';

interface BaseItem {
    id?: string;
}

interface Option {
    id: string;
    name: string;
}

interface ListProps<T extends BaseItem> {
    list: T[];

    formatItem?(item: T): JSX.Element;

    onRemove?: (id: string) => void;
    hasRemove?: boolean;
    itemWithLevel?: boolean;
    options?: {
        name: string;
        options: Option[];
    }[];
    onChange?: any;
    values?: string[];
}

export function SectionList<T extends BaseItem>({
    list,
    formatItem,
    onRemove,
    hasRemove = true,
    itemWithLevel = false,
    options,
    onChange,
    values,
}: ListProps<T>): JSX.Element {
    return (
        <>
            {list?.map((item, index) => (
                itemWithLevel && list.length === options?.length
                    ? (
                        <SelectedLevelItem
                            key={item.id}
                            text={formatItem && formatItem(item)}
                            onRemoveItem={onRemove}
                            id={item.id}
                            options={options![index].options}
                            onChange={onChange}
                            value={values![index]}
                            name={options![index].name}
                        />
                    )
                    : (
                        <SelectedItem
                            key={item.id}
                            text={formatItem && formatItem(item)}
                            onRemoveItem={onRemove}
                            id={item.id}
                            hasRemove={hasRemove}
                        />
                    )
            ))}
        </>
    );
}
