import React from 'react';

import { countTeachersRolesHours } from '@admin/NewModule/Preview';
import classes from './ModuleMainCharactersParams.module.scss';

import {
    Module as ModuleType,
} from '../../../subSpaceAdmin/subSpaceTypes';

interface Props {
    currentModule: ModuleType;
}

export function ModuleMainCharactersParams({ currentModule }: Props): JSX.Element {
    const { minStudentCount, maxStudentCount, maxWaveCount } = currentModule;
    const countOfHours = [...currentModule.meetings, ...currentModule.assignments]
        .reduce((acc, cur) => acc + (cur?.duration?.academicHours ?? 0), 0);
    const teacherRolesWithHours = countTeachersRolesHours(currentModule.meetings);

    return (
        <div className={classes.moduleMainCharactersParams}>
            <ul className={classes.moduleMainCharactersParams__list}>
                <li className={classes.moduleMainCharactersParams__listItems}>

                    <div className={classes.moduleMainCharactersParams__numberItem}>
                        {minStudentCount !== maxStudentCount
                            ? `${minStudentCount}-${maxStudentCount}`
                            : `${minStudentCount}`
                        }
                    </div>

                    <div className={classes.moduleMainCharactersParams__textItem}>
                        Количество студентов
                    </div>

                </li>
                <li className={classes.moduleMainCharactersParams__listItems}>

                    <div className={classes.moduleMainCharactersParams__numberItem}>
                        {maxWaveCount}
                    </div>

                    <div className={classes.moduleMainCharactersParams__textItem}>
                        Потоков модуля в семестр
                    </div>

                </li>
                <li className={classes.moduleMainCharactersParams__listItems}>

                    <div className={classes.moduleMainCharactersParams__numberItem}>
                        {(countOfHours / 36).toFixed(3)}
                    </div>

                    <div className={classes.moduleMainCharactersParams__textItem}>
                        ЗЕТ
                    </div>

                </li>

                <li className={classes.moduleMainCharactersParams__listItems}>

                    <div className={classes.moduleMainCharactersParams__numberItem}>
                        {countOfHours.toFixed(3)}
                    </div>

                    <div className={classes.moduleMainCharactersParams__textItem}>
                        Количество академических часов студента
                    </div>

                </li>

                {
                    teacherRolesWithHours.map((role) => (
                        <div
                            key={`${role.role}${role.duration}`}
                            className={classes.moduleMainCharactersParams__listItems}
                        >

                            <div className={classes.moduleMainCharactersParams__numberItem}>
                                {role.duration.toFixed(3)}
                            </div>

                            <div className={classes.moduleMainCharactersParams__textItem}>
                                <li className={classes.moduleMainCharactersParams__textItem}>
                                    Количество академических часов
                                    {' '}
                                    {role.role}
                                </li>
                            </div>

                        </div>
                    ))
                }
            </ul>
        </div>
    );
}
