import React, { useState, useEffect } from 'react';
import { Title, TitleTypes } from '@common/Title';
import { Suggest } from '@common/Suggest/Suggest';
import { Section } from './Section';
import { SelectForm, SelectFormInitialData } from './SelectForm';

import styles from './Section.module.scss';

type OptionSuggest = {
    id: string;
    name: string;
};

type OptionSelect = {
    id: string;
    name: string;
};

type OptionData = {
    id: string;
    name: string;
    optionsSelect: OptionSelect[];
};

export const SuggestSectionFormInitialData = {
    id: '',
    name: '',
    optionsSelect: [{
        id: '',
        name: '',
    },
    ],
};

interface Props {
    list: OptionSelect[];
    labelSuggest: string;
    labelSelect: string;
    optionsSelect?: OptionSelect[];
    placeholderSuggest: string;
    placeholderSelect: string;
    mainOptions?: OptionSuggest[];
    data: OptionData;

    setData(data: OptionData): void;
}

export function SuggestSectionForm({
    list,
    labelSuggest,
    labelSelect,
    mainOptions,
    optionsSelect,
    placeholderSuggest,
    placeholderSelect,
    data,
    setData,
}: Props) {
    const [dataList, setDataList] = useState(list);

    useEffect(() => {
        if (!data?.id) {
            setDataList([]);
        }
    }, [data?.id]);

    return (
        <>
            <div className={styles.row}>
                <div className={styles.column}>
                    <Title
                        type={TitleTypes.Subtitle}
                    >
                        {labelSuggest}
                    </Title>
                    <div className={styles.suggestSection__sectionLabel}>
                        <Title
                            type={TitleTypes.Subtitle}
                        >
                            {labelSelect}
                        </Title>
                    </div>
                </div>
                <div className={styles.column}>
                    <Suggest
                        value={data.id ? data : null}
                        options={mainOptions}
                        label=""
                        placeholder={placeholderSuggest}
                        onChange={value => setData(
                            { ...data, ...value, optionsSelect: dataList },
                        )}
                        size="size-l"
                        customOptions={mainOptions?.map(option => ({ ...option, label: `${option.name} (${option.id.slice(0, 4).toLowerCase()})` }))}
                    />
                    <div className={styles.suggestSection__section}>
                        <Section
                            list={dataList}
                            formatItem={({ name }) => <>{name}</>}
                            initialData={SelectFormInitialData}
                            form={SelectForm}
                            formProps={{
                                mainOptions: optionsSelect, placeholder: placeholderSelect,
                            }}
                            onChange={items => {
                                setDataList(items);
                                setData({ ...data, optionsSelect: items });
                            }}
                            buttonInfo={{ name: '', down: false, noButton: true }}
                        />
                    </div>
                </div>
            </div>
        </>
    );
}
