import React, { useState } from 'react';
import range from 'lodash/range';

import { Select } from '@admin/NewModule/Select';
import {
    moduleStore, Validator, TeacherRole, EvaluationPoint, Meeting,
} from '@admin/NewModule/Store';

type OptionSelect = {
    id: string;
    name: string;
    maxCount?: number;
};

type OptionData = {
    id: string;
    name: string;
    inputValue: string;
};

export const RolesFormInitialData = {
    id: '',
    name: '',
    inputValue: '',
};

interface Props {
    mainOptions: OptionSelect[];
    labelInput?: string;
    data: OptionData;
    selectName: string;
    inputName: string;
    event: Meeting | EvaluationPoint;

    setData(data: OptionData): void;
}

function getMaxCountOfRole(event: EvaluationPoint | Meeting, role?: TeacherRole): number {
    const maxCount = role?.maxCount ?? 0;
    if (!role?.teacherRole.id || !event) return maxCount;
    const realMaxCount = range(1, maxCount + 1).reduce((acc, count) => {
        if (Validator.validateEventTeacherRoles(event, [{ ...role, count }]).valid) {
            return count;
        }
        return acc;
    }, 0);
    return realMaxCount;
}

export const RolesForm = ({
    mainOptions,
    labelInput,
    data,
    setData,
    selectName,
    inputName,
    event,
}: Props) => {
    const [role, setRole] = useState<TeacherRole>();
    const maxCount = getMaxCountOfRole(event, role);
    const countSelect = range(1, maxCount + 1).map(count => ({
        name: String(count),
        id: String(count),
    }));
    const module = moduleStore.moduleModel;
    const sortMainOptopns = [...mainOptions].sort((a, b) => {
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
        return 0;
    });
    return (
        <>
            <Select
                name={selectName}
                value={data.id ?? ''}
                options={sortMainOptopns}
                onChange={({ target }) => {
                    const selectedRole = module.options.getRole(target.value);
                    if (!selectedRole) return;
                    setRole(selectedRole);
                    setData({
                        ...data,
                        id: selectedRole.teacherRole.id,
                        name: selectedRole.teacherRole.name,
                    });
                }}
                placeholder="Укажите роль преподавателя"
            />
            <Select
                name={inputName}
                value={data.inputValue}
                options={countSelect}
                label={labelInput}
                onChange={({ target }) => setData({ ...data, inputValue: target.value })}
                isNumber
            />
        </>
    );
};
