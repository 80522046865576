import { gql } from '@apollo/client';

export const GET_SPACE_BTI = gql`
    query SpaceBaseTimeInterval (
        $btiId: String!, 
    ) {
        spaceBaseTimeInterval(id: $btiId) {
            id
            spaceBaseTimeIntervalSubspaces {
                subspace {
                    id
                    name
                }
                slots {
                    id
                    module {
                        id
                        name
                        minStudentCount
                        maxStudentCount
                    }
                }
                forks {
                    id
                    nextSlots {
                        id
                        module {
                            id
                            name
                            minStudentCount
                            maxStudentCount
                        }
                    }
                }
            }
        }
    }
`;

export const GET_WAVES = gql`
    query Waves(
        $WaveInput: WavesInput!
    ) {
        waves (
            input: $WaveInput
        ) {
            id
            index
            students {
                user {
                    id
                    studentId
                    firstName
                    lastName
                    patronymic
                }
            }
            module {
                id
                name
                maxStudentCount
                minStudentCount
            }
        }
    }
`;

export const GET_FORK_UNDISTRIB_STUDENTS = gql`
    query ForkUndistributedStudents (
        $ForkUndistributedStudentsInput: ForkUndistributedStudentsInput!
    ) {
        forkUndistributedStudents (
            forkUndistributedStudentsInput:$ForkUndistributedStudentsInput
        ) {
            student {
                user {
                    id
                    studentId
                    firstName
                    lastName
                    patronymic
                }
            }
            remainingSelectionCount
        }     
    }
`;

export const DELETE_STUDENT_WAVE = gql`
    mutation DeleteStudentWave(
        $DeleteStudentWaveInput: DeleteStudentWaveInput!
    ) {
        deleteStudentWave (
            deleteStudentWaveInput: $DeleteStudentWaveInput
        )
    }
`;

export const UPDATE_STUDENT_WAVE = gql`
    mutation UpdateStudentWave (
        $UpdateStudentWaveInput: UpdateStudentWaveInput!
    ) {
        updateStudentWave (
            updateStudentWaveInput: $UpdateStudentWaveInput
        ) {
            id
        }
    }
`;

export const GET_ALL_STUDENTS_RATING = gql`
    query StudentsRatings($studentsRatingInput: StudentsRatingInput!) {
        studentsRating(studentsRatingInput: $studentsRatingInput) {
            student {
                id
            }
            value
        }
    }
`;

export const GET_PREVIOUS_BTI = gql`
    query PreviousBaseTimeIntervalInstance(
        $baseTimeIntervalInstanceId: String!,
    ) {
        previousBaseTimeIntervalInstance(baseTimeIntervalInstanceId: $baseTimeIntervalInstanceId) {
            id
      }
    }
`;

export const GET_IS_VALID_STUDENTS_WAVE_DISTRIBUTION = gql`
    query GetIsValidStudentsWaveDistribution (
        $baseTimeIntervalInstanceId: String!,
    ) {
        isValidStudentsWaveDistribution (
            baseTimeIntervalInstanceId: $baseTimeIntervalInstanceId
        )
    }
`;

export const GET_BTI_INSTANCE = gql`
    query BaseTimeIntervalInstance(
        $id: String!
    ) {
        baseTimeIntervalInstance(
            id: $id
        ) {
            id      
            selectionStatus
        }
    }
`;
