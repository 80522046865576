import React from 'react';

import cn from 'classnames';
import { ActionButtonRedesign, ActionTypeOfButton } from '@common/ActionButtonRedesign';
import { UseGetWordEnding } from '@common/hooks';
import classes from './UsersImportPopUpContent.module.scss';

interface Props {
    amountOfUsers: number;
    closeButtonOnClick: () => void;
}

export function UsersImportPopUpContent(
    {
        amountOfUsers,
        closeButtonOnClick,
    }: Props,
) {
    const userWordWithNormalEnding = UseGetWordEnding(
        amountOfUsers,
        {
            one: 'пользователь',
            oneTwoFive: 'пользователя',
            tenToTwenty: 'пользователей',
        },
    );

    const downloadWordWithNormalEnding = UseGetWordEnding(
        amountOfUsers,
        {
            one: 'загружен',
            oneTwoFive: 'загружены',
            tenToTwenty: 'загружены',
        },
    );
    return (
        <div className={classes.usersImportPopUpContent}>

            <div
                className={cn(classes.usersImportPopUpContent__text,
                    classes.usersImportPopUpContent__text_bold)}
            >
                {amountOfUsers} {userWordWithNormalEnding} успешно {downloadWordWithNormalEnding}
            </div>

            <div
                className={classes.usersImportPopUpContent__text}
            >
                Не забудьте присвоить роли новым пользователям
            </div>

            <ActionButtonRedesign
                actionType={ActionTypeOfButton.GREY}
                onClick={closeButtonOnClick}
            >
                Закрыть
            </ActionButtonRedesign>

        </div>
    );
}
