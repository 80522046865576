import React from 'react';
import { observer } from 'mobx-react';

import { EventDate } from './EventDate';
import { EventRoom } from './EventRoom';
import { EventInfo } from './EventInfo';
import { EventPeriod } from './EventPeriod';

import classes from './ScheduleEventHeader.module.scss';

export const ScheduleEventHeader = observer((): JSX.Element => (
    <header className={classes.schedulePage_meetingHeader}>
        <div className={classes.schedulePage__eventHeader_wrapper}>
            <EventDate />
            <EventPeriod />
            <EventRoom />
            <EventInfo />
        </div>
    </header>
));
