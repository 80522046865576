import React from 'react';

import { NotVotedAccordion } from '@admin/EducationPeriodStatistic/EducationPeriodStatisticNotVoted/NotVotedAccordion';

import classes from './EducationPeriodStatisticNotVoted.module.scss';

export function EducationPeriodStatisticNotVoted() {
    return (
        <div className={classes.notVoted}>
            <NotVotedAccordion />
        </div>
    );
}
