import React, { useEffect, useRef, useState } from 'react';
import cn from 'classnames';
import { observer } from 'mobx-react';
import { useParams } from 'react-router-dom';

import { DaysNextEvent } from '@admin/NewModule/Meeting/DaysNextEvent';
import { moduleStore, Validator, Assignment as AssignmentModel } from '@admin/NewModule/Store';
import { ActionButton, ActionTypeOfButton } from '@common/ActionButton';
import { gql, useLazyQuery } from '@apollo/client';

import { AssignmentOptions } from './AssignmentOptions';
import { AssignmentSkills } from './AssignmentSkills';
import { SavedEvent } from '../SavedEvent';

import style from './Assignment.module.scss';

interface RouteParams {
    id: string
    eventId: string
}

interface Props {
    assignment: AssignmentModel;
    opened: boolean;

    setOpened(id: string): void;
}

const IS_REMOVABLE_ASSIGNMENT = gql`
    query isRemovableAssignment($id: String!) {
        isRemovableAssignment(id: $id)
    }
`;

export const Assignment = observer(({
    assignment,
    opened,
    setOpened,
}: Props) => {
    const { id: moduleId, eventId } = useParams<RouteParams>();
    const [errorMessage, setErrorMessage] = useState('');

    const module = moduleStore.moduleModel;

    const topRef = useRef<null | HTMLDivElement>(null);
    const scrollToTop = () => topRef.current?.scrollIntoView({ behavior: 'smooth' });

    const [isRemovableAssignment, { data, error }] = useLazyQuery(IS_REMOVABLE_ASSIGNMENT, {
        variables: {
            id: assignment.id,
        },
    });

    useEffect(() => {
        if (opened) scrollToTop();
    }, [opened]);

    useEffect(() => {
        if (assignment.id === eventId) {
            topRef.current?.scrollIntoView({ behavior: 'auto' });
        }
    }, []);

    useEffect(
        () => module.updateOrderOfDependentEvaluationPoints(assignment.id), [assignment.order],
    );

    const handleChangeSavedAssignment = () => {
        setOpened(assignment.id);
        scrollToTop();
    };

    useEffect(() => {
        if (data?.isRemovableAssignment) {
            module.removeAssignment(assignment.id);
        }
        if (error) {
            setErrorMessage(error?.graphQLErrors[0]?.message);
        }
    }, [data, error]);

    const handleChangeNewAssignment = () => {
        setOpened(assignment.id);
        scrollToTop();
    };

    return (
        <>
            {!opened ? (
                <div className={style.form__saved} ref={topRef}>
                    <SavedEvent
                        numberOfEvent={assignment.assignmentNumber}
                        title="Сам. работа"
                        topic={assignment.topic ?? ''}
                        handleEditEvent={
                            moduleId
                                ? handleChangeSavedAssignment
                                : handleChangeNewAssignment
                        }
                        valid={Validator.validateAssignment(assignment).valid}
                    />
                </div>
            ) : (
                <div className={cn(style.form, style.form_meeting)} ref={topRef}>
                    <AssignmentOptions assignment={assignment} />
                    <AssignmentSkills assignmentData={assignment} />
                    <DaysNextEvent
                        updateEvent={module.updateAssignment}
                        event={assignment}
                        disableCheckbox
                    />
                    <div className={style.form__buttons}>
                        <ActionButton
                            actionType={ActionTypeOfButton.SECONDARY}
                            className={style.form__buttons_margin_right}
                            onClick={isRemovableAssignment}
                        >
                            Удалить сам. работу
                        </ActionButton>
                        <ActionButton
                            onClick={() => {
                                setOpened('');
                                scrollToTop();
                            }}
                        >
                            Сохранить сам. работу
                        </ActionButton>
                    </div>
                    <p className={style.form__error}>
                        {errorMessage}
                    </p>
                </div>
            )}
        </>
    );
});
