import React from 'react';
import { observer } from 'mobx-react-lite';
import classes from './ForkCard.module.scss';
import Diagram from '../store/Diagram';
import { StudentPickingType } from '../subSpaceTypes';

interface Props {
    studentPickingType: StudentPickingType | string
}

export const AutomaticallyChoice = observer(({
    studentPickingType,
}: Props): JSX.Element => (
    <div>
        <div className={classes.forkCard__subtitle}>
            Как отбирать студентов
        </div>
        <select
            className={classes.forkCard__select}
            name="studentPickingType"
            defaultValue={studentPickingType}
            onChange={({ target }) => {
                Diagram.setForkSettingsForm<string>(target.value, 'studentPickingType');
            }}
        >
            <option value="randomly">Случайно</option>
            <option value="byRating">По рейтингу</option>
            <option value="bySkill">По уровню образовательных результатов</option>
        </select>
    </div>
));
