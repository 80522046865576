import React from 'react';
import { gql, useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';

import { Loader } from '@common/Loader';

import { ScheduleTable } from './ScheduleTable';
import { GET_FILTERED_SCHEDULE_FOR_TABLE_VIEW } from '../queries';

const GET_DATA_FOR_SCHEDULE_TABLE = gql`
    query getDataForScheduleTable($baseTimeIntervalInstanceId: String!) {
        plannedMeetingInstances(baseTimeIntervalInstanceId: $baseTimeIntervalInstanceId) {
            id
            meetingId
            waveId
            scheduleGenerationProcessId
        }
        modulesByBaseTimeIntervalInstanceIds(baseTimeIntervalInstanceIds: [$baseTimeIntervalInstanceId]) {
            id
            name
            waves {
                id
                index
                baseTimeIntervalInstances {
                    id
                }
            }
            meetings {
                id
                topic
                order
            }
        }
    }
`;

export const ScheduleTableApollo = React.memo((
    { isSecret }: { isSecret?: boolean },
): JSX.Element => {
    const { id } = useParams<{ id: string }>();

    const { data, loading } = useQuery(GET_DATA_FOR_SCHEDULE_TABLE, {
        fetchPolicy: 'cache-first',
        variables: { baseTimeIntervalInstanceId: id },
    });

    const {
        data: filteredSchedule,
        loading: meetingsLoading,
    } = useQuery(GET_FILTERED_SCHEDULE_FOR_TABLE_VIEW, {
        fetchPolicy: 'cache-first',
        variables: {
            scheduleFilterInput: {
                baseTimeIntervalInstanceId: id,
            },
        },
    });

    if (loading || meetingsLoading) {
        return <Loader />;
    }

    return (
        <ScheduleTable
            baseTimeIntervalInstanceId={id}
            eventInstances={filteredSchedule?.filteredSchedule?.events}
            planingMeetingInstances={data.plannedMeetingInstances}
            modules={data.modulesByBaseTimeIntervalInstanceIds}
            isSecret={isSecret}
        />
    );
});
