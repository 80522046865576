import { makeAutoObservable } from 'mobx';
import { format } from 'date-fns';

export enum FilterValue {
    true = 'true',
    false = 'false',
    undefined = 'undefined',
}

export enum OrderBy {
    deadlineAsc = 'deadlineAsc',
    deadlineDesc = 'deadlineDesc',
}

class AssignmentsStore {
    activeBaseTimeIntervalInstanceId?: string;

    filterModuleId?: string;

    isCompleted?: boolean;

    fromDate?: string = format(new Date(), 'yyyy-MM-dd');

    toDate?: string = format(new Date(), 'yyyy-MM-dd');

    assignmentStatus: string = 'actual';

    assignmentOrder: OrderBy = OrderBy.deadlineAsc;

    constructor() {
        makeAutoObservable(this);
    }

    public setCompleted(isCompleted: FilterValue) {
        const completedValue = {
            [FilterValue.true]: true,
            [FilterValue.false]: false,
            [FilterValue.undefined]: undefined,
        };
        this.isCompleted = completedValue[isCompleted];
    }

    public setAssignmentStatus = (status: string) => {
        this.assignmentStatus = status.toLowerCase();
    };

    public getAssignmentStatus = () => this.assignmentStatus;

    public setAssignmentOrder = (orderBy: OrderBy) => {
        this.assignmentOrder = orderBy;
    };

    public getAssignmentOrder = () => this.assignmentOrder;

    public setActiveBaseTimeIntervalInstanseId(activeBaseTimeIntervalInstanseId: string) {
        this.activeBaseTimeIntervalInstanceId = activeBaseTimeIntervalInstanseId;
    }

    public getActiveBaseTimeIntervalInstanseId() {
        return this.activeBaseTimeIntervalInstanceId;
    }

    public setFilterModuleId(moduleId?: string) {
        this.filterModuleId = moduleId;
    }

    public getFilterModuleId() {
        return this.filterModuleId;
    }
}

export const assignmentsStore = new AssignmentsStore();
