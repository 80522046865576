import React, { useEffect, useState } from 'react';

import { EventStatus, EventType } from '@admin/ScheduleGenerationPage/utils';
import { UpdateEventStatusFunc } from '@admin/ScheduleGenerationPage/types';
import {
    EventActionButtons,
} from '@admin/ScheduleGenerationPage/MeetingDetails/ChangeStatusButtonsContainer/EventActionButtons';

interface Props {
    id: string;
    status: string;
    getUpdateStatusFunction: (type: string) => UpdateEventStatusFunc;
    type: EventType;
}

interface StatusState {
    mainStatus: EventStatus,
    smallStatus: EventStatus,
}

export function ChangeStatusButtonsContainer({
    id,
    status,
    getUpdateStatusFunction,
    type,
}: Props) {
    const activeState = { mainStatus: EventStatus.GENERATION, smallStatus: EventStatus.DELETED };
    const deletedState = { mainStatus: EventStatus.ACTIVE, smallStatus: EventStatus.GENERATION };
    const generationState = { mainStatus: EventStatus.ACTIVE, smallStatus: EventStatus.DELETED };
    const [buttonsStatus, setButtonsStatus] = useState<StatusState>();

    useEffect(() => {
        if (status === EventStatus.ACTIVE) {
            setButtonsStatus(activeState);
        }
        if (status === EventStatus.DELETED) {
            setButtonsStatus(deletedState);
        }
        if (status === EventStatus.GENERATION) {
            setButtonsStatus(generationState);
        }
    }, [status]);

    return (
        <EventActionButtons
            textMainButton={buttonsStatus?.mainStatus! === EventStatus.GENERATION ? 'Сделать неопубликованной' : 'Опубликовать'}
            textSmallButton={buttonsStatus?.smallStatus! === EventStatus.GENERATION ? 'Сделать неопубликованной' : 'Удалить'}
            changeMainButton={() => getUpdateStatusFunction(type)(id, buttonsStatus?.mainStatus!)}
            changeSmallButton={() => getUpdateStatusFunction(type)(id, buttonsStatus?.smallStatus!)}
        />
    );
}
