import React from 'react';
import { gql, useQuery } from '@apollo/client';

import { Loader } from '@common/Loader';
import { ActionButton } from '@common/ActionButton';

import classes from './InvitationList.module.scss';
import { GetExternalInvitationList } from '../apollo-types';

const GET_EXTERNAL_INVITATIONS = gql`
    query GetExternalInvitationList {
        externalModuleInvitations {
            id
            subspace {
                id
                name
            }
        }
    }
`;

interface InvitationListProps {
    onCreateInviteClick(): void;
}

export function InvitationList({ onCreateInviteClick }: InvitationListProps) {
    const { data, loading, error } = useQuery<GetExternalInvitationList>(
        GET_EXTERNAL_INVITATIONS,
        { fetchPolicy: 'network-only' },
    );

    if (error) {
        return (
            <div>Произошла ошибка: {error.graphQLErrors[0]?.message || error.message}</div>
        );
    }

    if (loading) {
        return <Loader />;
    }

    return (
        <>
            <ActionButton
                className={classes['create-invitation-button']}
                onClick={onCreateInviteClick}
            >
                Создать приглашение
            </ActionButton>
            {data?.externalModuleInvitations.length === 0
                ? (
                    <div>Здесь появится список Ваших приглашений</div>
                ) : (
                    <div className={classes['invitation-list-container']}>
                        <table className={classes['invitation-list']}>
                            <thead className={classes['invitation-list__header']}>
                                <tr>
                                    <td>Подпространство</td>
                                    <td>Ссылка-приглашение</td>
                                </tr>
                            </thead>
                            <tbody className={classes['invitation-list__body']}>
                                {data?.externalModuleInvitations.map(({ id, subspace }) => (
                                    <tr key={id}>
                                        <td>{subspace.name}</td>
                                        <td>{window.location.origin}/invitation/{id}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                )
            }
        </>
    );
}
