import React from 'react';

export function ArrowGradientIcon() {
    return (
        <svg width="18" height="12">
            <path
                d="M17.75 6a.625.625 0 0 1-.625.626H2.384l3.933 3.932a.626.626 0
                1 1-.885.885l-5-5a.625.625 0 0 1 0-.885l5-5a.626.626 0 0 1
                .885.885L2.385 5.376h14.741A.625.625 0 0 1 17.75 6Z"
                fill="var(--main-gradient)"
            />
            <defs>
                <linearGradient
                    id="a"
                    x1="9.996"
                    y1="12.44"
                    x2="6.669"
                    y2="-.819"
                    gradientUnits="userSpaceOnUse"
                />
            </defs>
        </svg>
    );
}
