import React from 'react';
import cn from 'classnames';

import { Wysiwyg } from '@common/Wysiwyg';

import { ExternalModuleState, ValidationErrors } from '../../ExternalModule/helpers';
import { ExternalModuleStatus } from '../../ExternalModule/apollo-types';
import { useCopyToClipboard } from './useCopyToClipboard';
import { ExternalModuleReview } from './ExternalModuleReview';

import classes from './ExternalModuleForm.module.scss';

interface Props {
    externalModule: ExternalModuleState;
    validationErrors?: ValidationErrors | undefined;
    isEditModeDisabled?: boolean;
    isCurrentUserAvailbleToAddReview?: boolean;
    onExternalModuleChange?(updatedModuleFields: Partial<ExternalModuleState>): void;
    children?: React.ReactNode;
}

const ExternalModuleStatusMap = {
    [ExternalModuleStatus.draft]: 'в работе',
    [ExternalModuleStatus.verification]: 'ожидает проверки',
    [ExternalModuleStatus.revision]: 'требует доработки',
    [ExternalModuleStatus.approved]: 'принят',
    [ExternalModuleStatus.deleted]: 'удален',
};

export const ExternalModuleForm = ({
    externalModule,
    validationErrors,
    isEditModeDisabled,
    children,
    isCurrentUserAvailbleToAddReview,
    onExternalModuleChange,
}: Props) => (
    <div className={classes['external-module__form']}>
        {externalModule.status && (
            <div className={classes['external-module-form__status']}>
                Статус модуля:{' '}
                <span className={classes[`external-module-status_${externalModule.status}`]}>
                    {ExternalModuleStatusMap[externalModule.status]}
                </span>
            </div>
        )}

        <ExternalModuleReview isCurrentUserAvailbleToAddReview={isCurrentUserAvailbleToAddReview} />

        <ExternalModuleLink
            link={`${window.location.origin}/invitation/${externalModule.invitationId}/module/${externalModule.id}`}
        />

        <div className={classes['external-module-form__row-container']}>
            <div className={classes['external-module-form__input-container_column']}>
                <label
                    className={classes['external-module-form__label']}
                    htmlFor="owner-full-name"
                >
                    ФИО Владельца модуля
                </label>
                <input
                    id="owner-full-name"
                    className={classes['external-module-form__input']}
                    type="text"
                    placeholder="Введите ФИО"
                    value={externalModule.ownerFullName ?? ''}
                    onChange={e => onExternalModuleChange
                        && onExternalModuleChange({ ownerFullName: e.target.value })
                    }
                    disabled={isEditModeDisabled}
                />
                {validationErrors?.ownerFullName && (
                    <span className={classes['external-module-form__field-error']}>
                        {validationErrors.ownerFullName}
                    </span>
                )}
            </div>
            <div className={classes['external-module-form__input-container_column']}>
                <label
                    className={classes['external-module-form__label']}
                    htmlFor="owner-email"
                >
                    Email Владельца модуля
                </label>
                <input
                    id="owner-email"
                    className={classes['external-module-form__input']}
                    type="email"
                    placeholder="Введите email"
                    value={externalModule.ownerEmail ?? ''}
                    onChange={e => onExternalModuleChange
                        && onExternalModuleChange({ ownerEmail: e.target.value })
                    }
                    disabled={isEditModeDisabled}
                />
                {validationErrors?.ownerEmail && (
                    <span className={classes['external-module-form__field-error']}>
                        {validationErrors.ownerEmail}
                    </span>
                )}
            </div>
        </div>

        <div className={classes['external-module-form__label']}>
            Информация о владельце модуля:
        </div>
        <div className={classes['external-module-form__row-container']}>
            <Wysiwyg
                placeholder="Вставьте ссылку на резюме, опишите опыт работы, публикации и другую необходимую информацию"
                value={externalModule.ownerInfo ?? ''}
                onChange={ownerInfo => onExternalModuleChange
                    && onExternalModuleChange({ ownerInfo })}
                disabled={isEditModeDisabled}
            />
            {validationErrors?.ownerInfo && (
                <span className={classes['external-module-form__field-error']}>
                    {validationErrors.ownerInfo}
                </span>
            )}
        </div>

        <ExternalModuleTextareaCharacteristics
            id="module-name"
            label="Название модуля:"
            value={externalModule.name}
            disabled={isEditModeDisabled}
            onValueChange={e => onExternalModuleChange
                && onExternalModuleChange({ name: e.target.value })
            }
            validationError={validationErrors?.name}
        />

        <div className={classes['external-module-form__label']}>
            Описание модуля:
        </div>
        <div className={classes['external-module-form__row-container']}>
            <Wysiwyg
                placeholder="Цель модуля. Задачи модуля. Описание итогового проекта модуля."
                value={externalModule.description ?? ''}
                onChange={description => onExternalModuleChange
                    && onExternalModuleChange({ description })}
                disabled={isEditModeDisabled}
            />
            {validationErrors?.description && (
                <span className={classes['external-module-form__field-error']}>
                    {validationErrors.description}
                </span>
            )}
        </div>

        <div className={classes['external-module-form__label']}>
            Укажите количество студентов на одном потоке модуля:
        </div>
        <div className={classes['external-module-form__row-container']}>
            <ExternalModuleNumericalCharacteristics
                id={classes['min-student-count']}
                label="min:"
                value={externalModule.minStudentCount}
                disabled={isEditModeDisabled}
                onValueChange={e => onExternalModuleChange && onExternalModuleChange({
                    minStudentCount: Number(e.target.value),
                })}
                validationError={validationErrors?.minStudentCount}
            />

            <ExternalModuleNumericalCharacteristics
                id={classes['max-student-count']}
                label="max:"
                value={externalModule.maxStudentCount}
                disabled={isEditModeDisabled}
                onValueChange={e => onExternalModuleChange && onExternalModuleChange({
                    maxStudentCount: Number(e.target.value),
                })}
                validationError={validationErrors?.maxStudentCount}
            />
        </div>

        <div className={classes['external-module-form__row-container']}>
            <ExternalModuleNumericalCharacteristics
                id={classes['max-wave-count']}
                label="Укажите максимально возможное количество потоков модуля в семестр:"
                value={externalModule.maxWaveCount}
                disabled={isEditModeDisabled}
                onValueChange={e => onExternalModuleChange && onExternalModuleChange({
                    maxWaveCount: Number(e.target.value),
                })}
                validationError={validationErrors?.maxWaveCount}
            />
        </div>

        <div className={classes['external-module-form__row-container']}>
            <ExternalModuleNumericalCharacteristics
                id={classes['credit-count']}
                label="Укажите количество ЗЕТ в модуле:"
                value={externalModule.creditCount}
                disabled={isEditModeDisabled}
                onValueChange={e => onExternalModuleChange && onExternalModuleChange({
                    creditCount: Number(e.target.value),
                })}
                validationError={validationErrors?.creditCount}
            />
        </div>

        <ExternalModuleTextareaCharacteristics
            id="syllabus-reference"
            label="Ссылка на программу модуля:"
            value={externalModule.syllabusReference}
            disabled={isEditModeDisabled}
            onValueChange={e => onExternalModuleChange && onExternalModuleChange({
                syllabusReference: e.target.value,
            })}
            validationError={validationErrors?.syllabusReference}
        />
        {children}
    </div>
);

interface NumericalExternalModuleCharacteristicsProps {
    id: string;
    value?: number;
    label: string;
    disabled?: boolean;
    validationError?: string;
    onValueChange?(e: React.ChangeEvent<HTMLInputElement>): void;
}

const ExternalModuleNumericalCharacteristics = ({
    id,
    value,
    label,
    disabled,
    validationError,
    onValueChange,
}: NumericalExternalModuleCharacteristicsProps) => (
    <div className={classes['external-module-form__input-container_row']}>
        <label
            className={cn(
                classes['external-module-form__label'],
                classes['external-module-form__label_inline'],
            )}
            htmlFor={id}
        >
            {label}
        </label>
        <input
            id={id}
            className={classes['external-module-form__input']}
            type="number"
            value={value || ''}
            onChange={onValueChange}
            disabled={disabled}
        />
        {validationError && (
            <span className={classes['external-module-form__field-error']}>
                {validationError}
            </span>
        )}
    </div>
);

interface ExternalModuleTextareaCharacteristicsProps {
    id: string;
    value?: string;
    label: string;
    disabled?: boolean;
    validationError?: string;
    onValueChange?(e: React.ChangeEvent<HTMLTextAreaElement>): void;
}

const ExternalModuleTextareaCharacteristics = ({
    id,
    value,
    label,
    disabled,
    validationError,
    onValueChange,
}: ExternalModuleTextareaCharacteristicsProps) => (
    <>
        <label
            className={classes['external-module-form__label']}
            htmlFor={id}
        >
            {label}
        </label>
        <div className={classes['external-module-form__row-container']}>
            <textarea
                id={id}
                className={classes['external-module-form__textarea']}
                placeholder="Введение в специальность “Химический аналитик”"
                value={value || ''}
                onChange={onValueChange}
                disabled={disabled}
            />
            {validationError && (
                <span className={classes['external-module-form__field-error']}>
                    {validationError}
                </span>
            )}
        </div>
    </>
);

interface ExternalModuleLinkProps {
    link: string;
}

function ExternalModuleLink({
    link,
}: ExternalModuleLinkProps) {
    const { isLinkCopied, onCopyToClipboard } = useCopyToClipboard();

    return (
        <>
            <label
                className={classes['external-module-form__label']}
                htmlFor="external-module-link"
            >
                Персональная ссылка на модуль
            </label>
            <div className={classes['external-module-form__row-container']}>
                <input
                    className={cn(
                        classes['external-module-form__link'],
                        classes['external-module-form__input'],
                    )}
                    type="text"
                    id="external-module-link"
                    defaultValue={link}
                    disabled
                />
                <div
                    className={classes['external-module-form__copy-button']}
                    onClick={() => onCopyToClipboard(link)}
                >
                    <div className={classes['copy-button__item']} />
                    <div className={classes['copy-button__item']} />
                    {isLinkCopied && (
                        <div className={classes['copy-button__notification']}>
                            Copied!
                        </div>
                    )}
                </div>
            </div>
        </>
    );
}
