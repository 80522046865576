import { useEffect, useState } from 'react';

export function useWindowSize() {
    const [windowSize, setWindowSize] = useState({
        windowWidth: window.innerWidth,
        windowHeight: window.innerHeight,
    });

    useEffect(() => {
        function updateWindowSize() {
            setWindowSize({
                windowWidth: window.innerWidth,
                windowHeight: window.innerHeight,
            });
        }

        window.addEventListener('resize', updateWindowSize);

        updateWindowSize();

        return () => {
            window.removeEventListener('resize', updateWindowSize);
        };
    }, []);

    return windowSize;
}
