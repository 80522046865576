import React from 'react';

import { IconDeprecated } from '@common';
import { Link } from 'react-router-dom';
import { Meeting } from '@admin/ScheduleGenerationPage/types';

import { EventStatus } from '@admin/ScheduleGenerationPage/utils';
import classes from './MeetingView.module.scss';

interface Props {
    status: string;
    meeting: Meeting;
    moduleId: string;

    setEditable(editable: boolean): void;
}

export const MeetingView = ({
    status, meeting, moduleId, setEditable,
}: Props): JSX.Element => (
    <div className={classes.meetingView}>
        <Link to={`/module/public/${moduleId}/${meeting.id}`}>{meeting.topic}</Link>
        {status !== EventStatus.DELETED && (
            <IconDeprecated
                id="editPenBlack"
                click={() => setEditable(true)}
                className={classes.meetingView__pen}
            />
        )}
    </div>
);
