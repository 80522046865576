import React from 'react';
import cn from 'classnames';
import classes from './Textarea.module.scss';

interface Props {
    name?: string;
    value?: string;
    label?: string;
    placeholder?: string;
    onChange?(event: any): void;
    modifier?: string;
    labelModifier?: string;
}
export const Textarea: React.FC<Props> = ({
    name,
    value,
    label,
    placeholder,
    onChange = () => {},
    modifier,
    labelModifier,
}: Props) => (
    <div className={classes.textarea}>
        <label className={
            cn(classes.textarea__label,
                { [classes[`textarea__label_${labelModifier}`]]: labelModifier })}
        >
            {label}
        </label>
        <div className={classes.textarea__container}>
            <textarea
                className={
                    cn(classes.textarea__item,
                        {
                            [classes[`textarea__item_${modifier}`]]: modifier,
                            [classes.textarea__item_autosize]: (value?.length || 0) >= 68
                                && !modifier,
                        })}
                name={name}
                value={value}
                placeholder={placeholder}
                onChange={onChange}
                autoComplete="off"
            />
        </div>
    </div>
);
