import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { Redirect } from 'react-router-dom';
import classes from './Warning.module.scss';

interface Props {
    className?: string;
    message?: string;
    time: number;
}

export function Warning({
    message, className, time,
}: Props): JSX.Element {
    const [styles, setStyles] = useState({
    });
    const [value, setValue] = useState<boolean>(false);
    useEffect(() => {
        setTimeout(() => {
            setStyles({ display: 'none' });
            setValue(true);
        }, time);
    }, [message]);

    return (
        !value ? ReactDOM.createPortal(
            <div
                className={className ?? classes.alert}
                style={styles}
            >
                <svg width="29" height="26" viewBox="0 0 29 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M14.5 1L28 25H1L14.5 1Z"
                        stroke="#FFCC4D"
                        strokeWidth="1.7"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        d="M14.5 16V8.5"
                        stroke="#FFCC4D"
                        strokeWidth="1.7"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        d="M14.4998 22C15.3282 22 15.9998 21.3284 15.9998 20.5C15.9998 19.6716 15.3282 19 14.4998 19C13.6713 19 12.9998 19.6716 12.9998 20.5C12.9998 21.3284 13.6713 22 14.4998 22Z"
                        fill="#FFCC4D"
                    />
                </svg>
                <p className={classes.alert__message}>{message || 'Произошла ошибка'}</p>
            </div>,
            document.getElementById('root') as Element,
        )
            : <Redirect to="/university" />
    );
}
