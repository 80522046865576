import React, { useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';

import { Suggest } from '@common';
import { Loader } from '@common/Loader';
import { Alert } from '@common/Alert';
import { Select } from '@admin/NewModule/Select';
import { Icon } from '../../../../../NewModule/CustomIcon';
import { SkillTypeExtended } from './interfaces/skillType';
import { SkillOption, Skill } from './interfaces/skill';
import { EvaluationResult, StudentSkillsInput } from './interfaces/evaluationResult';
import { ApolloAlert } from '../../../../../../account/schedule/Interfaces';
import { getOptionsSkillTypes } from './mapping/skillTypes';
import { getOptionsSkills } from './mapping/skills';
import { Popup } from '../../../../Popup/Popup';
import { LevelSelect } from './LevelSelect';
import { DELETE_STUDENTS_SKILLS, SET_STUDENTS_SKILLS } from '../../gql';

import classes from './PopupWithSkills.module.scss';
import { ToolBarButtonProps } from '../../interface';

interface Props extends ToolBarButtonProps {
    loadingSkillTypes: boolean;
    skillTypes?: SkillTypeExtended[];
    selectedSkillType: null | SkillTypeExtended;
    loadingSkills: boolean;
    skills?: Skill[];
    isOpen: boolean;
    onClose(): void;
    setSelectedSkillType(skillType: null | SkillTypeExtended): void;
}

export const PopupWithSkills = ({
    loadingSkillTypes,
    skillTypes,
    selectedSkillType,
    loadingSkills,
    skills,
    isOpen,
    onClose,
    setSelectedSkillType,
    selectedUsers,
    refetchUserList,
}: Props): JSX.Element => {
    const [alert, setAlert] = useState<ApolloAlert>({
        alert: false,
        message: '',
    });

    const [evaluationResults, setEvaluationResults] = useState<EvaluationResult[]>([]);

    const [selectedSkillOption, setSelectedSkillOption] = useState<null | SkillOption>(null);
    const [selectedSkill, setSelectedSkill] = useState<null | Skill>(null);
    const [selectedLevel, setSelectedLevel] = useState<number>(0);

    const [isDisabled, setIsDisabled] = useState(true);

    const enable = (): void => setIsDisabled(false);
    const disable = (): void => setIsDisabled(true);

    const handleSelectSkillType = ({ target }: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedSkillTypeId = target.value;

        const foundSkillType = skillTypes?.find(({ id }) => id === selectedSkillTypeId);
        setSelectedSkillType(foundSkillType || null);
    };

    const handleSelectSkillOption = (skillOption: null | SkillOption): void => {
        setSelectedSkillOption(skillOption);

        const foundSkill = skills?.find(({ id }) => id === skillOption?.id);
        setSelectedSkill(foundSkill || null);
    };

    const handleSelectSkillLevel = (
        event: React.ChangeEvent<HTMLSelectElement> | React.ChangeEvent<HTMLInputElement>,
    ): void => {
        const { value } = event.target;

        if (value === 'on') {
            setSelectedLevel((prevLevel) => Number(!prevLevel));
        } else {
            setSelectedLevel(Number(value));
        }
    };

    const getEvaluationLevel = (): number | null => {
        if (selectedSkillType?.isLevelIncreased) {
            return selectedLevel;
        }

        return null;
    };

    const getEvaluationIsCredited = (): boolean => {
        if (selectedSkillType?.isLevelIncreased) {
            return true;
        }

        return !!selectedLevel;
    };

    const resetSelectedSkill = (): void => {
        setSelectedSkillOption(null);
        setSelectedSkill(null);
    };

    const resetSelectedLevel = (): void => {
        setSelectedLevel(0);
    };

    const handleAddEvaluationResult = (): void => {
        if (selectedSkill) {
            setEvaluationResults((prevResults) => [
                {
                    skill: selectedSkill,
                    level: getEvaluationLevel(),
                    isCredited: getEvaluationIsCredited(),
                },
                ...prevResults,
            ]);
            resetSelectedSkill();
            resetSelectedLevel();
        }
    };

    const handleRemoveEvaluationResult = (skillId: string): void => {
        setEvaluationResults((prevResults) => prevResults.filter(
            (prevResult) => prevResult.skill.id !== skillId,
        ));
    };

    const filterSkillOptions = (skillOptions: SkillOption[]): SkillOption[] => (
        skillOptions.filter(
            (skillOption) => !evaluationResults.some(
                (evaluationResult) => evaluationResult.skill.id === skillOption.id,
            ),
        )
    );

    const selectedStudentsIds = selectedUsers
        .filter(({ student }) => !!student)
        .map(({ student }) => student!.id);

    const getStudentsSkillsInput = (): StudentSkillsInput[] => {
        const studentsSkillsInput: StudentSkillsInput[] = [];

        selectedStudentsIds.forEach((studentId) => {
            const studentSkills = evaluationResults.map((evaluationResult) => ({
                studentId,
                level: evaluationResult.level,
                skillId: evaluationResult.skill.id,
                isCredited: evaluationResult.isCredited,
            }));

            studentsSkillsInput.push(...studentSkills);
        });

        return studentsSkillsInput;
    };

    const getSelectedSkillsIds = (): string[] => (
        evaluationResults.map((evaluationResult) => evaluationResult.skill.id)
    );

    const errorHandling = (error: string): void => {
        setAlert({ alert: true, message: error.toString() });
        const clearId = setTimeout(() => setAlert({ alert: false, message: '' }), 2500);
        clearTimeout(clearId);
    };

    const [setStudentsSkills] = useMutation(
        SET_STUDENTS_SKILLS, {
            onError: (error) => errorHandling(error.toString()),
            variables: {
                data: {
                    status: 'ADMIN',
                    studentsSkills: getStudentsSkillsInput(),
                },
            },
            fetchPolicy: 'no-cache',
        },
    );

    const [deleteStudentsSkills] = useMutation(
        DELETE_STUDENTS_SKILLS, {
            onError: (error) => errorHandling(error.toString()),
            variables: {
                data: {
                    studentIds: selectedStudentsIds,
                    skillIds: getSelectedSkillsIds(),
                },
            },
            fetchPolicy: 'no-cache',
        },
    );

    const handleOnSubmitEvaluation = (): void => {
        setStudentsSkills();
        onClose();
        refetchUserList();
    };

    const handleOnRemoveEvaluation = (): void => {
        deleteStudentsSkills();
        onClose();
        refetchUserList();
    };

    useEffect(() => {
        resetSelectedSkill();
    }, [selectedSkillType]);

    useEffect(() => {
        resetSelectedLevel();
    }, [selectedSkill]);

    useEffect(() => {
        if (evaluationResults.length) {
            enable();
        } else {
            disable();
        }
    }, [evaluationResults]);

    return (
        <Popup
            isOpen={isOpen}
            title="Добавить образовательные результаты"
            isDisabled={isDisabled}
            submitText="Добавить"
            withRemove
            onClose={onClose}
            onSubmit={handleOnSubmitEvaluation}
            onRemove={handleOnRemoveEvaluation}
        >
            {loadingSkillTypes ? <Loader /> : (
                skillTypes && (
                    <Select
                        label="Выберите тип образовательного результата"
                        name="userSkills"
                        value={selectedSkillType?.id ?? ''}
                        onChange={handleSelectSkillType}
                        options={getOptionsSkillTypes(skillTypes)}
                        placeholder="Тип результата"
                    />
                )
            )}

            {loadingSkills ? <Loader /> : (
                skills && (
                    <div className={classes.evaluationResultsAdd}>
                        <Suggest
                            options={
                                filterSkillOptions(
                                    getOptionsSkills(skills),
                                )
                            }
                            size="size-l"
                            value={selectedSkillOption}
                            onChange={handleSelectSkillOption}
                            placeholder="Введите название"
                        />

                        {selectedSkill && (
                            <LevelSelect
                                isLevelIncreased={selectedSkillType?.isLevelIncreased!}
                                maxLevel={selectedSkill.maxLevel}
                                selectedLevel={selectedLevel}
                                handleSelectSkillLevel={handleSelectSkillLevel}
                            />
                        )}

                        {selectedSkill && (
                            <div className={classes.buttonAddEvaluationResult}>
                                <Icon
                                    id="yellowPlus"
                                    onClick={handleAddEvaluationResult}
                                />
                            </div>
                        )}
                    </div>
                )
            )}

            <div className={classes.addedEvaluationResults}>
                {evaluationResults.map((evaluationResult) => (
                    <div
                        className={classes.evaluationResult__skill}
                        key={evaluationResult.skill.id}
                    >
                        <div className={classes.skillInfo}>
                            <div className={classes.skillInfo__text}>
                                {evaluationResult.skill.fullName}&nbsp;

                                <span className={classes.skillLevel}>{evaluationResult.level}</span>
                            </div>
                            {evaluationResult.level === null && evaluationResult.isCredited && (
                                <div className={classes.iconCheckMark}>
                                    <Icon id="checkMark" />
                                </div>
                            )}
                        </div>

                        <div className={classes.evaluationResult__skillRemove}>
                            <Icon
                                id="remove"
                                onClick={
                                    () => handleRemoveEvaluationResult(evaluationResult.skill.id)
                                }
                            />
                        </div>
                    </div>
                ))}
            </div>

            {alert.alert && <Alert message={alert.message} time={2500} />}
        </Popup>
    );
};
