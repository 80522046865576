import React, { useState, useMemo } from 'react';
import {
    Space, SubspaceType, SubspaceLinkAction, SubspaceLinkActionType,
    PromptTexts, EditorStatus,
} from './interfaces';
import { ControlPanel } from './controlPanel/ControlPanel';
import { Diagram } from './diagram/Diagram';
import { DiagramNavigation } from './DiagramNavigation';
import { ConnectionStatus, ConnectionsView } from './services/connectionsView';
import { getSubspaces } from './services/subspaces';
import { DiagramView } from './services/diagramView';
import { getTargetId, getSourceId } from './services/subspacesConnection';
import { Prompt } from './Prompt';

interface Props {
    space: Space;
    subspaceTypes: SubspaceType[],
    updateSubspacesLinks(param: any): void;
}

export function SubspacesConnectionsDiagram({
    space,
    subspaceTypes,
    updateSubspacesLinks,
}: Props): JSX.Element {
    const subspaces = useMemo(() => getSubspaces(subspaceTypes), [subspaceTypes]);

    const [diagramView, setDiagramView] = useState(new DiagramView(subspaces));
    const [isEditMode, setIsEditMode] = useState(false);
    const [editorStatus, setEditorStatus] = useState(EditorStatus.Disable);
    const [tempConnections, setTempConnections] = useState(new ConnectionsView());

    const promptText = useMemo(() => {
        let res = PromptTexts.viewer;

        if (isEditMode && editorStatus !== EditorStatus.Disable) {
            res = PromptTexts.editor[editorStatus];
        }

        return res;
    }, [isEditMode, editorStatus]);

    function enterEditor(): void {
        setIsEditMode(true);
    }

    function quitEditor(): void {
        setDiagramView(new DiagramView(subspaces));
        setTempConnections(new ConnectionsView());
        setIsEditMode(false);
    }

    function cancelEditing(): void {
        quitEditor();
    }

    function saveChanges(): void {
        const statuses = tempConnections.getValue();

        const actions: SubspaceLinkAction[] = Object.keys(statuses).map(
            (connectionId: string) => {
                const actionType = tempConnections.hasStatus(connectionId, ConnectionStatus.Delete)
                    ? SubspaceLinkActionType.Delete : SubspaceLinkActionType.Add;

                return {
                    actionType,
                    sourceId: getSourceId(connectionId),
                    targetId: getTargetId(connectionId),
                };
            },
        );

        updateSubspacesLinks(actions);
        quitEditor();
    }

    return (
        <>
            <DiagramNavigation spaceName={space.name} />

            <ControlPanel
                isEditMode={isEditMode}
                enterEditor={enterEditor}
                cancelEditing={cancelEditing}
                saveChanges={saveChanges}
                editorStatus={editorStatus}
            />

            <Prompt text={promptText} />

            <Diagram
                space={space}
                subspaceTypes={subspaceTypes}
                isEditMode={isEditMode}
                tempConnections={tempConnections}
                setTempConnections={setTempConnections}
                diagramView={diagramView}
                setDiagramView={setDiagramView}
                subspaces={subspaces}
                setEditorStatus={setEditorStatus}
            />
        </>
    );
}
