import React from 'react';
import { observer } from 'mobx-react';
import moment from 'moment';
import { format } from 'date-fns';
import { eventsStore } from '../../../../Store';

import classes from './EventPeriod.module.scss';

export const EventPeriod = observer((): JSX.Element => {
    const { isAssignment, selectedEvent } = eventsStore;
    let startDate;
    let endDate;
    if (isAssignment(selectedEvent)) {
        const { assignmentInstance } = selectedEvent;
        const { deadline, start } = assignmentInstance;
        startDate = format(new Date(start), 'dd.MM');
        endDate = deadline ? format(new Date(deadline), 'dd.MM') : 'Дата сдачи не задана';
    } else {
        startDate = moment(selectedEvent?.startDate)
            .format('HH[:]mm');
        endDate = moment(selectedEvent?.endDate)
            .format('HH[:]mm');
    }
    return (
        <div
            className={classes.event__period}
        >
            {startDate}
            -
            {endDate}
        </div>
    );
});
