import React from 'react';

import cn from 'classnames';
import classes from './TimeInput.module.scss';

interface Props {
    className?: string;
    max?: string;
    min?: string;
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;
}

export function TimeInput(
    {
        className,
        onChange,
        max,
        min,
        onFocus,
    }: Props,
) {
    return (
        <input
            type="time"
            defaultValue="00:00"
            className={cn(classes.timeInput, className)}
            onChange={(event) => {
                if (onChange) {
                    onChange(event);
                }
            }}
            onFocus={(event) => {
                if (onFocus) {
                    onFocus(event);
                }
            }}
            onBlur={(event) => {
                if (onFocus) {
                    onFocus(event);
                }
            }}
            max={max}
            min={min}
        />
    );
}
