import React from 'react';

import { HeaderRedesign } from '@common/HeaderRedesign';
import { observer } from 'mobx-react';
import { AttendanceTable } from '@admin/Attendance/AttendanceTable';
import { attendanceStore } from '@admin/Attendance/AttendanceStore';
import cn from 'classnames';
import { TabsOption } from '@common/TabsRedesign/TabsRedesign';
import { useHistory } from 'react-router-dom';
import { MainDropDown, TMainDropDownOption } from '@common/MainDropDown';
import { AttendanceMode } from '@admin/Attendance/AttendanceIterfaces';
import { LoadAttendanceReportButton } from '@common/LoadAttendanceReportButton';
import { Loader } from '@common';
import { TableConventions } from '@admin/Attendance/TableConventions';
import arrowBack from './arrow.svg';

import classes from './Attendance.module.scss';

const dropDownOptions = [
    {
        id: AttendanceMode.byMeetings,
        name: AttendanceMode.byMeetings,
    },
    {
        id: AttendanceMode.byDates,
        name: AttendanceMode.byDates,
    },
];

export const Attendance = observer(():JSX.Element => {
    const history = useHistory();

    const {
        spaceId,
        btiInstanceId,
        moduleId,
        skillTypeId,
    } = attendanceStore.queryParams;

    const attendanceNavLink = `?btiIId=${btiInstanceId}&&moduleId=${moduleId}&&spaceId=${spaceId}&&skillTypeId=${skillTypeId}`;
    const journalNavLink = `/module-statistics/space/${spaceId}/base-time-interval/${btiInstanceId}/module/${moduleId}?skillTypeId=${skillTypeId}`;
    const moduleLink = `/module/public/${moduleId}`;

    const tabsOptions: TabsOption[] = [
        {
            title: 'Журнал оценок',
            link: journalNavLink,
            shouldReplaceLink: true,
        },
        {
            title: 'Посещаемость',
            link: attendanceNavLink,
        },
    ];

    const attendanceModeInfo = attendanceStore.getIsAttendanceByDates() ? 'По датам' : 'По встречам';

    const tablesAreReady = !!attendanceStore.tables.length;

    const tablesList = attendanceStore.tables.map((table) => (
        <AttendanceTable
            key={table.id}
            table={table}
        />
    ));

    const headerImageHandleClick = () => history.push(`/module-statistics/space/${spaceId}`);
    const titleOnClick = () => history.push(moduleLink);

    const dropDownOptionHandleClick = (option: TMainDropDownOption) => {
        switch (option.name) {
            case AttendanceMode.byMeetings:
                attendanceStore.setIsAttendanceByDates(false);
                break;

            default:
                attendanceStore.setIsAttendanceByDates(true);
        }
    };

    return (
        <>
            {
                tablesAreReady ? (
                    <div className={classes.attendance}>

                        <div className={classes.attendance__header}>
                            <HeaderRedesign
                                title={attendanceStore.tables[0]?.wave.module.name ?? 'Название модуля'}
                                titleClassname={classes.attendance__header_title}
                                tabsOptions={tabsOptions}
                                imageSrc={arrowBack}
                                imageOnClickEvent={headerImageHandleClick}
                                titleOnClickEvent={titleOnClick}
                            />
                        </div>

                        <div className={classes.attendance__options}>

                            <div className={classes.attendance__options_dropDown}>
                                <MainDropDown
                                    optionsListClassname={classes.attendance__options_mainDropDown}
                                    closeOnOptionSelect
                                    options={dropDownOptions}
                                    defaultOption={dropDownOptions[0]}
                                    additionalOptionHandleCLick={dropDownOptionHandleClick}
                                />
                            </div>

                            <div className={classes.attendance__options_download}>
                                <LoadAttendanceReportButton
                                    moduleId={moduleId}
                                    baseTimeIntervalInstanceId={btiInstanceId}
                                />
                            </div>

                        </div>

                        <div className={classes.attendance__tables}>

                            <div className={classes.attendance__tableHeader}>

                                <div className={classes.attendance__tableHeader_item}>
                                    Поток
                                </div>

                                <div className={classes.attendance__tableHeader_item}>
                                    ФИО
                                </div>

                                <div
                                    className={cn(
                                        classes.attendance__tableHeader_item,
                                        classes.attendance__tableHeader_black,
                                    )}
                                >
                                    Пропуски
                                </div>

                                <div className={classes.attendance__tableHeader_item}>
                                    {attendanceModeInfo}
                                </div>

                                <TableConventions />

                            </div>

                            {
                                tablesList
                            }

                        </div>

                    </div>
                ) : (
                    <Loader />
                )
            }
        </>

    );
});
