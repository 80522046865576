import { gql } from '@apollo/client';

export const GET_ASSIGNMENTS = gql`
    query filteredStudentAssignmentInstances($assignmentInstancesInput: StudentAssignmentInstancesInput!) {
        filteredStudentAssignmentInstances(assignmentInstancesInput: $assignmentInstancesInput) {
            assignmentInstance {
                id
                deadline
                assignment {
                    id
                    topic
                    description
                    module {
                        name
                    }
                    duration {
                        name
                    }
                }
            }
            note
            isCompleted
        }
    }
`;

export const GET_STUDENT_BTI = gql`
    query student($id: String!) {
        student(id: $id) {
            activeBaseTimeIntervalInstance {
                baseTimeIntervalInstance {
                    id
                }
            }
        }
    }
`;

export const GET_STUDENT_MODULES = gql`
    query getStudentBTIModules($id: String!, $baseTimeIntervalInstanceId: String) {
        student(id: $id, baseTimeIntervalInstanceId: $baseTimeIntervalInstanceId) {
            requestedBaseTimeIntervalInstance {
                studentSubspaces {
                    studentModules {
                        id
                        module {
                            name
                            id
                        }
                    }
                }
            }
        }
    }
`;
