/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: SpaceBaseTimeInterval
// ====================================================

export interface SpaceBaseTimeInterval_spaceBaseTimeInterval_spaceBaseTimeIntervalSubspaces_subspace {
  __typename: "Subspace";
  id: string;
  name: string;
}

export interface SpaceBaseTimeInterval_spaceBaseTimeInterval_spaceBaseTimeIntervalSubspaces_slots_module {
  __typename: "Module";
  id: string;
  name: string | null;
  minStudentCount: number;
  maxStudentCount: number;
  maxWaveCount: number;
}

export interface SpaceBaseTimeInterval_spaceBaseTimeInterval_spaceBaseTimeIntervalSubspaces_slots {
  __typename: "ModuleSlot";
  id: string;
  module: SpaceBaseTimeInterval_spaceBaseTimeInterval_spaceBaseTimeIntervalSubspaces_slots_module | null;
}

export interface SpaceBaseTimeInterval_spaceBaseTimeInterval_spaceBaseTimeIntervalSubspaces_forks_nextSlots_module {
  __typename: "Module";
  id: string;
  name: string | null;
  minStudentCount: number;
  maxStudentCount: number;
  maxWaveCount: number;
}

export interface SpaceBaseTimeInterval_spaceBaseTimeInterval_spaceBaseTimeIntervalSubspaces_forks_nextSlots {
  __typename: "ModuleSlot";
  id: string;
  module: SpaceBaseTimeInterval_spaceBaseTimeInterval_spaceBaseTimeIntervalSubspaces_forks_nextSlots_module | null;
}

export interface SpaceBaseTimeInterval_spaceBaseTimeInterval_spaceBaseTimeIntervalSubspaces_forks {
  __typename: "Fork";
  id: string;
  nextSlots: SpaceBaseTimeInterval_spaceBaseTimeInterval_spaceBaseTimeIntervalSubspaces_forks_nextSlots[];
}

export interface SpaceBaseTimeInterval_spaceBaseTimeInterval_spaceBaseTimeIntervalSubspaces {
  __typename: "SpaceBaseTimeIntervalSubspace";
  subspace: SpaceBaseTimeInterval_spaceBaseTimeInterval_spaceBaseTimeIntervalSubspaces_subspace;
  slots: SpaceBaseTimeInterval_spaceBaseTimeInterval_spaceBaseTimeIntervalSubspaces_slots[];
  forks: SpaceBaseTimeInterval_spaceBaseTimeInterval_spaceBaseTimeIntervalSubspaces_forks[];
}

export interface SpaceBaseTimeInterval_spaceBaseTimeInterval {
  __typename: "SpaceBaseTimeInterval";
  id: string;
  spaceBaseTimeIntervalSubspaces: SpaceBaseTimeInterval_spaceBaseTimeInterval_spaceBaseTimeIntervalSubspaces[];
}

export interface SpaceBaseTimeInterval {
  spaceBaseTimeInterval: SpaceBaseTimeInterval_spaceBaseTimeInterval;
}

export interface SpaceBaseTimeIntervalVariables {
  btiId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: DistributedStudentsGroupedByModule
// ====================================================

export interface DistributedStudentsGroupedByModule_distributedStudentsGroupedByModule_module {
  __typename: "Module";
  id: string;
  name: string | null;
  minStudentCount: number;
  maxStudentCount: number;
  maxWaveCount: number;
}

export interface DistributedStudentsGroupedByModule_distributedStudentsGroupedByModule_students_user {
  __typename: "User";
  id: string;
  studentId: string | null;
  firstName: string;
  lastName: string;
  patronymic: string | null;
}

export interface DistributedStudentsGroupedByModule_distributedStudentsGroupedByModule_students {
  __typename: "Student";
  user: DistributedStudentsGroupedByModule_distributedStudentsGroupedByModule_students_user;
}

export interface DistributedStudentsGroupedByModule_distributedStudentsGroupedByModule {
  __typename: "ModuleStudents";
  module: DistributedStudentsGroupedByModule_distributedStudentsGroupedByModule_module;
  students: DistributedStudentsGroupedByModule_distributedStudentsGroupedByModule_students[];
}

export interface DistributedStudentsGroupedByModule {
  distributedStudentsGroupedByModule: DistributedStudentsGroupedByModule_distributedStudentsGroupedByModule[];
}

export interface DistributedStudentsGroupedByModuleVariables {
  DistributedStudentsGroupedByModuleInput: DistributedStudentsGroupedByModuleInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getForkUndistributedStudents
// ====================================================

export interface getForkUndistributedStudents_forkUndistributedStudents_student_user {
  __typename: "User";
  id: string;
  studentId: string | null;
  firstName: string;
  lastName: string;
  patronymic: string | null;
}

export interface getForkUndistributedStudents_forkUndistributedStudents_student {
  __typename: "Student";
  user: getForkUndistributedStudents_forkUndistributedStudents_student_user;
}

export interface getForkUndistributedStudents_forkUndistributedStudents {
  __typename: "ForkUndistributedStudent";
  student: getForkUndistributedStudents_forkUndistributedStudents_student;
  remainingSelectionCount: number;
}

export interface getForkUndistributedStudents {
  forkUndistributedStudents: getForkUndistributedStudents_forkUndistributedStudents[];
}

export interface getForkUndistributedStudentsVariables {
  ForkUndistributedStudentsInput: ForkUndistributedStudentsInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateStudentModule
// ====================================================

export interface updateStudentModule_updateStudentModule {
  __typename: "StudentModule";
  id: string;
}

export interface updateStudentModule {
  updateStudentModule: updateStudentModule_updateStudentModule;
}

export interface updateStudentModuleVariables {
  UpdateStudentModuleInput: UpdateStudentModuleInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: deleteStudentModule
// ====================================================

export interface deleteStudentModule {
  deleteStudentModule: string;
}

export interface deleteStudentModuleVariables {
  DeleteStudentModuleInput: DeleteStudentModuleInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: StudentsRatings
// ====================================================

export interface StudentsRatings_studentsRating_student {
  __typename: "Student";
  id: string;
}

export interface StudentsRatings_studentsRating {
  __typename: "StudentRating";
  student: StudentsRatings_studentsRating_student;
  value: number;
}

export interface StudentsRatings {
  studentsRating: StudentsRatings_studentsRating[];
}

export interface StudentsRatingsVariables {
  studentsRatingInput: StudentsRatingInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: PreviousBaseTimeIntervalInstance
// ====================================================

export interface PreviousBaseTimeIntervalInstance_previousBaseTimeIntervalInstance {
  __typename: "BaseTimeIntervalInstance";
  id: string;
}

export interface PreviousBaseTimeIntervalInstance {
  previousBaseTimeIntervalInstance: PreviousBaseTimeIntervalInstance_previousBaseTimeIntervalInstance | null;
}

export interface PreviousBaseTimeIntervalInstanceVariables {
  baseTimeIntervalInstanceId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetIsValidStudentsModuleDistribution
// ====================================================

export interface GetIsValidStudentsModuleDistribution {
  isValidStudentsModuleDistribution: boolean;
}

export interface GetIsValidStudentsModuleDistributionVariables {
  baseTimeIntervalInstanceId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: BaseTimeIntervalInstance
// ====================================================

export interface BaseTimeIntervalInstance_baseTimeIntervalInstance {
  __typename: "BaseTimeIntervalInstance";
  id: string;
  selectionStatus: SelectionStatus;
}

export interface BaseTimeIntervalInstance {
  baseTimeIntervalInstance: BaseTimeIntervalInstance_baseTimeIntervalInstance;
}

export interface BaseTimeIntervalInstanceVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum SelectionStatus {
  moduleDistributionApprovalPending = "moduleDistributionApprovalPending",
  moduleDistributionEnabled = "moduleDistributionEnabled",
  moduleDistributionInProcess = "moduleDistributionInProcess",
  moduleSelectionEnabled = "moduleSelectionEnabled",
  moduleSelectionInProcess = "moduleSelectionInProcess",
  selectionEnabled = "selectionEnabled",
  selectionFinished = "selectionFinished",
  selectionPending = "selectionPending",
  subspaceDistributionApprovalPending = "subspaceDistributionApprovalPending",
  subspaceDistributionEnabled = "subspaceDistributionEnabled",
  subspaceDistributionInProcess = "subspaceDistributionInProcess",
  subspaceSelectionEnabled = "subspaceSelectionEnabled",
  subspaceSelectionInProcess = "subspaceSelectionInProcess",
  waveDistributionApprovalPending = "waveDistributionApprovalPending",
  waveDistributionInProcess = "waveDistributionInProcess",
}

export interface DeleteStudentModuleInput {
  studentId: string;
  baseTimeIntervalInstanceId: string;
  forkId: string;
  moduleId: string;
}

export interface DistributedStudentsGroupedByModuleInput {
  baseTimeIntervalInstanceId: string;
  moduleIds?: string[] | null;
}

export interface ForkUndistributedStudentsInput {
  baseTimeIntervalInstanceId: string;
  forkId: string;
}

export interface StudentsRatingInput {
  baseTimeIntervalInstanceId?: string | null;
  studentIds: string[];
}

export interface UpdateStudentModuleInput {
  studentId: string;
  baseTimeIntervalInstanceId: string;
  forkId: string;
  moduleId: string;
  previousModuleId?: string | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
