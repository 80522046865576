import React from 'react';
import { Redirect, Switch, useRouteMatch } from 'react-router-dom';

import { Tabs } from '@common/Tabs';
import { AuthRoute } from '@common/AuthRoute';
import { ViewType } from '../../deprecated-graphql-query-types';

import { SpaceModules } from './SpaceModules';
import { GetSpacesForModuleStatistics_spaces } from './graphql-types';
import classes from './SpacesModuleList.module.scss';

interface Props {
    spaces: GetSpacesForModuleStatistics_spaces[];
}

export function SpacesModuleList({ spaces }: Props) {
    if (!spaces.length) {
        return (
            <h2 className={classes['space-module-list_empty']}>
                Образовательные пространства не найдены
            </h2>
        );
    }

    const { path } = useRouteMatch();
    const tabsOptions = spaces.map(({ id, name }) => [name, id]);
    return (
        <>
            <Tabs tabsOptions={tabsOptions} />
            <Switch>
                {tabsOptions.map((tab) => (
                    <AuthRoute
                        key={tab[1]}
                        path={`${path}/${tab[1]}`}
                        requiredUserTypes={[ViewType.Admin]}
                        exact
                    >
                        <SpaceModules spaceId={tab[1]} />
                    </AuthRoute>
                ))}
                <AuthRoute
                    path={path}
                    requiredUserTypes={[ViewType.Admin]}
                    exact
                >
                    <Redirect to={`${path}/${tabsOptions[0][1]}`} />
                </AuthRoute>
            </Switch>
        </>
    );
}
