import React, { ReactNode } from 'react';
import cn from 'classnames';
import classes from './ActionButtonRedesign.module.scss';

export enum ActionTypeOfButton {
    PRIMARY = 'primary',
    SECONDARY = 'secondary',
    TRANSPARENT = 'transparent',
    GREY = 'grey',
}

interface Props {
    className?: string;
    type?: 'button' | 'submit';
    name?: string;
    actionType?: ActionTypeOfButton;
    children?: ReactNode;
    disabled?: boolean;
    onClick?(props: any): void;
}

export function ActionButtonRedesign({
    className = '',
    type = 'button',
    name,
    actionType = ActionTypeOfButton.PRIMARY,
    children,
    disabled = false,
    onClick,
}: Props): JSX.Element {
    return (
        <button
            name={name}
            className={cn(classes.actionButton, {
                [classes.actionButton__primary]: actionType === ActionTypeOfButton.PRIMARY,
                [classes.actionButton__transparent]: actionType === ActionTypeOfButton.TRANSPARENT,
                [classes.actionButton__grey]: actionType === ActionTypeOfButton.GREY,
                [classes.actionButton__grey_disabled]: actionType === ActionTypeOfButton.GREY
                && disabled,
                [className]: className,
                [classes.actionButton_disabled]: disabled,
            })}
            type={type === 'button' ? 'button' : 'submit'}
            onClick={onClick}
            disabled={disabled}
        >
            <div className={cn({
                [classes.actionButton__secondary]: actionType === ActionTypeOfButton.SECONDARY,
            })}
            >
                <div
                    className={cn({
                        [classes.actionButton__text]: actionType === ActionTypeOfButton.SECONDARY,
                        [classes.actionButton__transparent]:
                        actionType === ActionTypeOfButton.TRANSPARENT,
                        [classes.actionButton__text_disabled]: disabled
                        && actionType === ActionTypeOfButton.SECONDARY,
                    })}
                >
                    {children}
                </div>
            </div>
        </button>
    );
}
