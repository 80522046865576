import React from 'react';
import { range } from 'lodash';

type Props = {
    currentLevel: number;
    maxLevel: number;

    onChange(newLevel: number): void;
};
export const SkillLevelEditing = ({ currentLevel, maxLevel, onChange }: Props) => (
    <div>
        Укажите максимальный уровень развития:
        <select
            name="maxLevel"
            style={{ marginLeft: 20 }}
            value={currentLevel}
            onChange={({ target }) => onChange(Number(target.value))}
        >
            {range(1, maxLevel + 1)
                .map(value => (
                    <option key={value} value={value}>
                        {value}
                    </option>
                ))}
        </select>
    </div>
);
