import { makeAutoObservable } from 'mobx';
import { uniqBy } from 'lodash';
import {
    NotVotedStudent,
    StudentPriority,
    StudentsModulesPriorities, StudentRating, Student,
} from '@admin/EducationPeriodStatistic/EducationPeriodStatisticInterfaces';

class EducationPeriodStatisticStore {
    notVotedStudents: NotVotedStudent[] = [];

    modulesPrioritiesGroups: StudentsModulesPriorities[] = [];

    allStudents: NotVotedStudent[] = [];

    studentsPriorities: StudentPriority[] = [];

    votedStudentsVotes = 0;

    notVotedStudentsVotes = 0;

    studentsRatings: StudentRating[] = [];

    constructor() {
        makeAutoObservable(this);
    }

    setNotVotedStudents = (students: NotVotedStudent[]) => {
        this.notVotedStudents = students;
        this.setNotVotedStudentsVotes(students);
        this.setAllStudents(students, this.modulesPrioritiesGroups);
    };

    setModulesPrioritiesGroups = (newModulesPriorities: StudentsModulesPriorities[]) => {
        this.modulesPrioritiesGroups = newModulesPriorities;
        this.setVotedStudentsVotes(newModulesPriorities);
        this.setAllStudents(this.notVotedStudents, newModulesPriorities);
    };

    setVotedStudentsVotes = (newModulePriorities: StudentsModulesPriorities[]) => {
        this.votedStudentsVotes = getAllVotedStudentVotes(newModulePriorities);
    };

    setNotVotedStudentsVotes = (students: NotVotedStudent[]) => {
        this.notVotedStudentsVotes = students.length;
    };

    setAllStudents = (newNotVotedStudents: NotVotedStudent[],
        newModulesPriorities: StudentsModulesPriorities[]) => {
        this.allStudents = getAllStudents(newNotVotedStudents, newModulesPriorities);
    };

    setStudentPriorities = (newStudentPriorities: StudentPriority[]) => {
        this.studentsPriorities = newStudentPriorities;
    };

    setStudentsRatings = (newStudentsRatings: StudentRating[]) => {
        this.studentsRatings = newStudentsRatings;
    };
}

export const educationPeriodStatisticStore = new EducationPeriodStatisticStore();

function getAllVotedStudentVotes(priorities: StudentsModulesPriorities[]) {
    const votedStudents: Student[] = [];

    priorities.forEach((studentPriorities) => {
        studentPriorities.priorityGroups.forEach((priorityGroup) => {
            votedStudents.push(...priorityGroup.students);
        });
    });

    return uniqBy(votedStudents, student => student.id).length;
}

function getAllStudents(
    notVotedStudents: NotVotedStudent[],
    modulesPrioritiesGroups: StudentsModulesPriorities[],
) {
    const allStudents: NotVotedStudent[] = notVotedStudents.map(
        (notVotedStudent) => notVotedStudent,
    );

    modulesPrioritiesGroups.forEach((modulePriority) => {
        modulePriority.priorityGroups.forEach((priorityGroup) => {
            allStudents.push(
                ...priorityGroup.students,
            );
        });
    });

    return uniqBy(allStudents, student => student.id);
}
