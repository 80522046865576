import React from 'react';

import { Tool, ToolBar } from '../ToolBar';
import { Table, columnConfigs } from './Table';
import classes from './Table/table.module.scss';
import { Type } from './apollo-types';
import { useUserList } from './useUserList';
import { UserRow } from './Table/UserRow';
import { FullName } from './Table/userCellInfo';

export function NoRolesTable() {
    const {
        error,
        loading,
        users,
        isMoreButtonVisible,
        onMoreButtonClick,
        onChangeSearchParams,
        onSelect,
        isUserSelected,
        selectedUsers,
        refetchUserList,
        onSelectAll,
        clearPreviousSelected,
        isAllSelected,
    } = useUserList(Type.none);

    if (error) {
        return (
            <div>Произошла ошибка: {error.graphQLErrors[0]?.message || error.message}</div>
        );
    }

    return (
        <div>
            <div className={classes.toolBar}>
                <ToolBar
                    tools={[
                        Tool.STATUS_UPDATE,
                        Tool.GENERATE_SAMPLE_USERS_IMPORT,
                        Tool.USER_IMPORT,
                    ]}
                    selectedUsers={selectedUsers}
                    refetchUserList={refetchUserList}
                />
            </div>
            <Table
                setSearchParams={onChangeSearchParams}
                columnConfigs={[columnConfigs.fullName]}
                onShowMore={onMoreButtonClick}
                isMoreButtonVisible={isMoreButtonVisible}
                clearPreviousSelected={clearPreviousSelected}
                onSelectAll={onSelectAll}
                isAllSelected={isAllSelected}
                loading={loading}
            >
                {users.map(user => (
                    <UserRow
                        key={user.id}
                        userId={user.id}
                        onChange={() => onSelect(user)}
                        checked={isUserSelected(user)}
                    >
                        <FullName user={user} />
                    </UserRow>
                ))}
            </Table>
        </div>
    );
}
