import { gql } from '@apollo/client';

export const GET_EVALUATION_POINT_INSTANCES = gql`
query getEvaluationPointInstances($getEvaluationPointInstanceInput: EvaluationPointInstancesInput!) {
    evaluationPointInstances(evaluationPointInstancesInput: $getEvaluationPointInstanceInput) {
        id
        startDate
        endDate
        waves {
            index
        }
        evaluationResults {
            isEvaluated
            level
            skillId
            isCredited
            student {
                id
            }
            teacher {
                id
            }
        }
        evaluationPoint {
            id
            topic
            evaluationPointSkills {
                skill {
                    id
                    name
                    typeId
                    fullName
                    description
                    maxLevel
                }
                level
            }
        }
        evaluationPointInstanceToTeachers {
            teacher {
                id
                user {
                    id
                    lastName
                    firstName
                    patronymic
                }
            }
            isGraded
            note
        }
        evaluationPointInstanceToStudents {
            student {
                id
                user {
                    id
                    lastName
                    firstName
                    patronymic
                }
            }
            teacherNotes {
                note
                teacher {
                    id
                }
            }
        }
    }
}
`;

export const EDIT_EVALUATION_POINT_TEACHER_NOTE = gql`
mutation updateEvaluationPoint($data: UpdateEvaluationPointInstanceTeacherNoteInput!) {
    updateEvaluationPointInstanceTeacherNote(updateEvaluationPointInstanceTeacherNoteInput: $data) {
        id
    }
}
`;

export const CREATE_STUDENTS_EVALUATION = gql`
mutation createStudentEvaluation($data: UpdateStudentsSkillsInput!) {
    updateStudentsSkills(updateStudentsSkillsInput: $data) {
        id
    }
}
`;

export const EDIT_STUDENT_EVALUATION = gql`
mutation updateStudentEvaluation($data: UpdateStudentSkillsInput!) {
    updateStudentSkills(updateStudentSkillsInput: $data) {
        id
    }
}
`;

export const COMPLETE_EVALUATION_STUDENTS_BY_TEACHER = gql`
mutation updateEvaluationPointInstanceTeacherStatus($updateEvaluationPointInstanceTeacherStatusInput: UpdateEvaluationPointInstanceTeacherStatusInput!) {
    updateEvaluationPointInstanceTeacherStatus(updateEvaluationPointInstanceTeacherStatusInput: $updateEvaluationPointInstanceTeacherStatusInput) {
        id
    }
}
`;
