import React, { useRef } from 'react';
import cn from 'classnames';

import classes from './Meeting.module.scss';

interface Props {
    optionsRef?: React.MutableRefObject<HTMLDivElement | null>;
    equipmentRef?: React.MutableRefObject<HTMLDivElement | null>;
    teachersRef?: React.MutableRefObject<HTMLDivElement | null>;
    intervalsRef?: React.MutableRefObject<HTMLDivElement | null>;
    skillsRef?: React.MutableRefObject<HTMLDivElement | null>;
}

enum Points {
    options,
    equipment,
    teachers,
    intervals,
    skills,
}

const getBeginAndEnd = (ref?: React.MutableRefObject<HTMLDivElement | null>) => {
    const { y = 0, height = 0 } = ref?.current?.getBoundingClientRect() ?? {};
    const [begin, end] = [y, y + height];
    return [begin, end];
};

export const MeetingMenu = ({
    optionsRef, intervalsRef, teachersRef, skillsRef, equipmentRef,
}: Props) => {
    const [activeRef, setActiveRef] = React.useState<Points>(Points.options);
    const menuRef = useRef<null | HTMLDivElement>(null);
    const [menuY] = React.useState((menuRef?.current?.getBoundingClientRect().y ?? 0) + 5);
    const options = [{
        text: <span>Общие<br /> параметры</span>, ref: optionsRef, point: Points.options,
    }, {
        text: <span>Оборудование</span>, ref: equipmentRef, point: Points.equipment,
    }, {
        text: <span>Преподаватели</span>, ref: teachersRef, point: Points.teachers,
    }, {
        text: <span>Интервалы</span>, ref: intervalsRef, point: Points.intervals,
    }, {
        text: <span>Обр. результаты</span>, ref: skillsRef, point: Points.skills,
    }];

    const onScroll = () => {
        options.forEach(option => {
            const [begin, end] = getBeginAndEnd(option.ref);
            if (menuY >= begin && menuY <= end) {
                setActiveRef(option.point);
            }
        });
    };

    React.useEffect(() => {
        window.addEventListener('scroll', onScroll);
        return () => window.removeEventListener('scroll', onScroll);
    }, [menuRef.current]);

    return (
        <div className={classes.form__menu} ref={menuRef}>
            {options.map(option => (
                <div
                    className={cn({
                        [classes.form__menuItem]: activeRef !== option.point,
                        [classes.form__menuItem_selected]: activeRef === option.point,
                    })}
                    onClick={() => option.ref?.current?.scrollIntoView({ behavior: 'smooth' })}
                    key={option.point}
                >
                    {option.text}
                </div>
            ))}
        </div>
    );
};
