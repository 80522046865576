import React from 'react';
import {
    TeacherTimeslot,
} from '../../OccupationDashboardInterfaces';
import classes from './Ruler.module.scss';

interface Props {
    timeSlots: TeacherTimeslot[],
}

const WEEK_DAYS = ['ВС', 'ПН', 'ВТ', 'СР', 'ЧТ', 'ПТ', 'СБ'];

const GRAY_COLOR = '#E5E5E5';
const WHITE_COLOR = 'white';

export const Ruler = ({ timeSlots }: Props): JSX.Element => {
    if (timeSlots) {
        return (
            <>
                {
                    timeSlots.map(
                        timeSlot => (
                            <th
                                className={classes.ruler__timeSlot}
                                key={`${timeSlot.date}-${timeSlot.startTime}-${timeSlot.endTime}`}
                                style={{
                                    backgroundColor: getTimeSlotColor(timeSlot),
                                }}
                            >
                                <div className={classes.ruler__timeSlotContent}>
                                    <div className={classes.ruler__timeSlotContentText}>
                                        {`${timeSlot.startTime.split(':')[0]}:${timeSlot.startTime.split(':')[1]} ${WEEK_DAYS[timeSlot.dayOfWeek]} ${timeSlot.date.split('-')[2]}/${timeSlot.date.split('-')[1]}`}
                                    </div>
                                </div>
                            </th>
                        ),
                    )
                }
            </>
        );
    }
    return (<></>);
};

function getTimeSlotColor(timeSlot: TeacherTimeslot) {
    if (timeSlot.dayOfWeek % 2 === 0) {
        return GRAY_COLOR;
    }
    return WHITE_COLOR;
}
