import { ApolloError, useMutation } from '@apollo/client';
import { ReplaceMeetingInput } from '../../types';
import { REPLACE_MEETING, GET_EVENT_DATA } from '../../queries';

export const useUpdateMeetingMutation = (
    id: string,
    type: string,
    setErrorsFunction: (error: ApolloError) => void,
) => {
    const [
        replaceMeeting,
    ] = useMutation(REPLACE_MEETING, {
        refetchQueries: [{
            query: GET_EVENT_DATA,
            variables: {
                tmpMeetingInstanceInput: { id, type },
            },
        }],
        onError: (error) => setErrorsFunction(error),
    });

    return {
        updateMeeting: (replaceMeetingInput: ReplaceMeetingInput) => replaceMeeting({
            variables: {
                replaceMeetingInput,
            },
        }),
    };
};
