import React, { useEffect, useRef, useState } from 'react';
import { RoomCreationContainer } from '@admin/RoomsPage/RoomCreation';
import { GetEquipmentsOnRoomPage_equipment_list, UpdateRoomInput } from 'src/graphql-query-types';
import { Room } from './Room';

import {
    Building,
    RoomBackendData,
    RoomMeetingFormat,
    Status,
} from '../RoomTypes';

import classes from '../Rooms.module.scss';

interface RoomListProps {
    universityTimeZone: string;
    activeTab: string;
    roomID: boolean;
    buildings: Building[];
    roomInfo: RoomBackendData;
    meetingFormats: RoomMeetingFormat[];
    storeEquipment: GetEquipmentsOnRoomPage_equipment_list[];
    deleteRoomHandler(id: string): void;
    editRoomHandler(room: UpdateRoomInput): void;
    createRoomHandler(room: UpdateRoomInput): void;
    addRoomButton(): void;
    editRoomButton(id: string): void;
}

export function RoomContainer({
    universityTimeZone,
    activeTab,
    roomID,
    meetingFormats,
    storeEquipment,
    roomInfo,
    buildings,
    deleteRoomHandler,
    editRoomHandler,
    createRoomHandler,
    addRoomButton,
    editRoomButton,
}: RoomListProps): JSX.Element {
    const topRef = useRef<null | HTMLDivElement>(null);

    const [editMode, setEditMode] = useState<boolean>(false);
    const [roomMeetingFormat, setRoomMeetingFormat] = useState<RoomMeetingFormat[]>(meetingFormats);

    const { id }: RoomBackendData = roomInfo;
    const { meetingFormats: types } = roomInfo;

    function sortTypesForRoom() {
        const filteredArray = roomMeetingFormat
            .filter((checkType) => types.map((roomType) => {
                if (checkType.id === roomType.id) {
                    // eslint-disable-next-line no-param-reassign
                    checkType.status = Status.checked;
                    return checkType;
                }
                return checkType;
            }));
        setRoomMeetingFormat(filteredArray);
    }

    const editRoomData = () => {
        sortTypesForRoom();
        editRoomButton(id);
        setEditMode(!editMode);
    };

    const closeEditRoom = () => {
        editRoomButton(id);
        setEditMode(!editMode);
    };

    useEffect((): void => {
        if (meetingFormats) {
            const type = meetingFormats
                .map((el: RoomMeetingFormat) => ({ ...el, status: Status.check }));
            setRoomMeetingFormat(type);
        }
        if (!roomID) {
            setEditMode(false);
        }
    }, [meetingFormats, roomID]);

    return (
        <article className={classes.item} ref={topRef} id={roomInfo.id}>
            {!editMode
                && (
                    <Room
                        universityTimeZone={universityTimeZone}
                        roomData={roomInfo}
                        editMode={editMode}
                        editRoomData={editRoomData}
                        deleteRoomHandler={deleteRoomHandler}
                        closeEditRoom={closeEditRoom}
                    />
                )
            }
            {roomID && editMode
                && (
                    <RoomCreationContainer
                        universityTimeZone={universityTimeZone}
                        activeTab={activeTab}
                        buildings={buildings}
                        roomInfo={roomInfo}
                        storeEquipment={storeEquipment}
                        meetingFormats={roomMeetingFormat}
                        editRoomHandler={editRoomHandler}
                        createRoomHandler={createRoomHandler}
                        closeEditRoom={closeEditRoom}
                        addRoomButton={addRoomButton}
                        deleteRoomHandler={deleteRoomHandler}
                    />
                )}
        </article>
    );
}
