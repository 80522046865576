import React, { useEffect, useState } from 'react';

import { useLazyQuery, useQuery } from '@apollo/client';
import { Alert } from '@common/Alert/Alert';
import { RadioList } from '@admin/Users/RadioList';
import { Select } from '@admin/Users/Select';
import { saveAs } from 'file-saver';
import { ToolButton } from '../../ToolButton';
import {
    baseTimeIntervalInstances,
} from '../apollo-types';
import { GET_BASE_TIME_INTERVAL_INSTANCES, LOAD_STUDENTS_RATING_CSV } from '../gql';

export const ExportStudentsRatingButton = () => {
    const { data: btiData } = useQuery<baseTimeIntervalInstances>(GET_BASE_TIME_INTERVAL_INSTANCES);
    const [loadStudentRating, { data, error }] = useLazyQuery(LOAD_STUDENTS_RATING_CSV, {
        onCompleted: () => {
            setSpaceId(undefined);
            setBaseTimeIntervalInstanceId(undefined);
        },
        // eslint-disable-next-line no-console
        onError: (err) => { console.error(err); },
    });

    const [spaceId, setSpaceId] = useState<string>();
    const [baseTimeIntervalInstanceId, setBaseTimeIntervalInstanceId] = useState<string>();

    function onSpaceChange(event: React.ChangeEvent<HTMLInputElement>) {
        const { value } = event.target;
        setSpaceId(value);
        setBaseTimeIntervalInstanceId(undefined);
    }

    function onBaseTimeIntervalInstanceChange(event: React.ChangeEvent<HTMLInputElement>) {
        const { value } = event.target;
        setBaseTimeIntervalInstanceId(value);
    }

    function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
        if (baseTimeIntervalInstanceId === 'default') {
            setBaseTimeIntervalInstanceId(undefined);
        }
        event.preventDefault();
        loadStudentRating({
            variables: {
                exportStudentsRatingInput:
                {
                    baseTimeIntervalInstanceId: baseTimeIntervalInstanceId === 'default' ? undefined : baseTimeIntervalInstanceId,
                    educationPeriodId: spaceId,
                },
            },
        });
    }

    const spaces = btiData?.baseTimeIntervalInstances
        .map(({ spaceEducationPeriodId, spaceBaseTimeInterval: { space } }) => ({
            id: spaceEducationPeriodId,
            name: space.name,
            value: spaceEducationPeriodId,
        }))
        .filter((space, index, self) => (
            index === self.findIndex(s => s.id === space.id)
        ));

    const baseTimeIntervals = btiData?.baseTimeIntervalInstances
        .filter(({ spaceEducationPeriodId }) => spaceEducationPeriodId === spaceId)
        .map(
            ({ id, spaceBaseTimeInterval: { order, space } }) => ({
                id,
                name: `${order} ${space.baseTimeIntervalType?.name}`,
            }),
        );
    useEffect(() => {
        if (data) {
            const blob = new Blob([data.exportStudentsRatingCsv ?? ''], {
                type: 'text/csv;charset=utf-8',
            });
            saveAs(blob, `Рейтинг студентов ${baseTimeIntervals?.find(({ id }) => id === baseTimeIntervalInstanceId)?.name ?? 'исходный'}`);
        }
    }, [data]);
    return (
        <>
            <ToolButton
                text="Скачать шаблон рейтинга"
                icon="export"
                onSubmit={handleSubmit}
                contextMenu={baseTimeIntervals && (
                    <RadioList
                        listOptions={spaces!}
                        type="role"
                        checked={spaceId}
                        onChange={onSpaceChange}
                    >
                        <Select
                            placeholder="Сделайте выбор"
                            options={[{ id: 'default', name: 'Исходный' }, ...baseTimeIntervals]}
                            value={baseTimeIntervalInstanceId}
                            onChange={onBaseTimeIntervalInstanceChange}
                        />
                    </RadioList>
                )}
            />
            {error && <Alert message={error.message} time={3000} />}
        </>
    );
};
