import React from 'react';

import classes from './TableConventions.module.scss';

export function TableConventions() {
    return (
        <div className={classes.conventions}>

            <div className={classes.conventions__default}>

                <div className={classes.conventions__default_symbol}>
                    <svg width="5" height="3" viewBox="0 0 5 3" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <ellipse cx="2.5" cy="1.5" rx="2.5" ry="1.5" fill="#A7A8AA" />
                    </svg>
                </div>

                <div className={classes.conventions__default_text}>
                    присутствовал
                </div>

            </div>

            <div className={classes.conventions__notValid}>

                <div className={classes.conventions__notValid_symbol}>
                    н
                </div>

                <div className={classes.conventions__notValid_text}>
                    отсутствовал
                </div>

            </div>

            <div className={classes.conventions__valid}>

                <div className={classes.conventions__valid_symbol}>
                    ув
                </div>

                <div className={classes.conventions__valid_text}>
                    отсутствовал по уважительной причине
                </div>

            </div>

            <div className={classes.conventions__missing}>

                <div className={classes.conventions__missing_symbol}>
                    <svg width="11" height="15" viewBox="0 0 11 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect width="11" height="15" fill="#F9F9F9" />
                        <mask id="mask0_1108_10495" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="0" y="0" width="11" height="15">
                            <rect width="11" height="15" fill="#F3F3F3" />
                        </mask>
                        <g mask="url(#mask0_1108_10495)">
                            <line x1="-20.3536" y1="55.6464" x2="53.6464" y2="-18.3536" stroke="#C7C7C7" strokeDasharray="2 2" />
                            <line x1="-60.3536" y1="55.6464" x2="13.6464" y2="-18.3536" stroke="#C7C7C7" strokeDasharray="2 2" />
                            <line x1="-0.353553" y1="55.6464" x2="73.6464" y2="-18.3536" stroke="#C7C7C7" strokeDasharray="2 2" />
                            <line x1="-40.3536" y1="55.6464" x2="33.6464" y2="-18.3536" stroke="#C7C7C7" strokeDasharray="2 2" />
                            <line x1="-10.3536" y1="55.6464" x2="63.6464" y2="-18.3536" stroke="#C7C7C7" strokeDasharray="2 2" />
                            <line x1="-50.3536" y1="55.6464" x2="23.6464" y2="-18.3536" stroke="#C7C7C7" strokeDasharray="2 2" />
                            <line x1="-70.3536" y1="55.6464" x2="3.64644" y2="-18.3536" stroke="#C7C7C7" strokeDasharray="2 2" />
                            <line x1="9.64645" y1="55.6464" x2="83.6464" y2="-18.3536" stroke="#C7C7C7" strokeDasharray="2 2" />
                            <line x1="-30.3536" y1="55.6464" x2="43.6464" y2="-18.3536" stroke="#C7C7C7" strokeDasharray="2 2" />
                            <line x1="-15.3536" y1="55.6464" x2="58.6464" y2="-18.3536" stroke="#C7C7C7" strokeDasharray="2 2" />
                            <line x1="-55.3536" y1="55.6464" x2="18.6464" y2="-18.3536" stroke="#C7C7C7" strokeDasharray="2 2" />
                            <line x1="4.64645" y1="55.6464" x2="78.6464" y2="-18.3536" stroke="#C7C7C7" strokeDasharray="2 2" />
                            <line x1="-35.3536" y1="55.6464" x2="38.6464" y2="-18.3536" stroke="#C7C7C7" strokeDasharray="2 2" />
                            <line x1="-5.35355" y1="55.6464" x2="68.6464" y2="-18.3536" stroke="#C7C7C7" strokeDasharray="2 2" />
                            <line x1="-45.3536" y1="55.6464" x2="28.6464" y2="-18.3536" stroke="#C7C7C7" strokeDasharray="2 2" />
                            <line x1="-65.3536" y1="55.6464" x2="8.64644" y2="-18.3536" stroke="#C7C7C7" strokeDasharray="2 2" />
                            <line x1="-25.3536" y1="55.6464" x2="48.6464" y2="-18.3536" stroke="#C7C7C7" strokeDasharray="2 2" />
                        </g>
                    </svg>
                </div>

                <div className={classes.conventions__missing_text}>
                    другой поток
                </div>

            </div>
        </div>
    );
}
