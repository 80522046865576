import React from 'react';
import { format } from 'date-fns';

import { Button } from '@admin/Users/ToolBar/Tools/CustomButton';
import { Calendar } from './Calendar';

import classes from './FiltrationComponents.module.scss';
import { PeriodChooser } from './Calendar/PeriodChooser';

interface Props {
    startDate: null | Date;
    endDate: null | Date;
    onChange(update: any): void;
    inputValues: any;
    setInputValues: any;
}

export function CalendarComponent({
    startDate,
    endDate,
    onChange,
    inputValues,
    setInputValues,
}: Props): JSX.Element {
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value, name } = event.target;
        setInputValues({ ...inputValues, [name]: value });
    };
    const defaultTime = inputValues.fromTime === '00:00' && inputValues.toTime === '00:00';

    return (
        <div className={classes.filters__calendar}>
            <div className={classes.filters__calendarName}>
                Дата и время:
            </div>

            <Calendar
                onChange={onChange}
                startDate={startDate}
                endDate={endDate}
                showTimeInput
                customInput={(
                    <Button
                        modifier={!startDate ? 'filterCalendar' : 'filterCalendarBlack'}
                    >
                        {startDate ? `${format(startDate, 'dd.MM.yyyy')}
                        ${!defaultTime ? `${inputValues.fromTime} ` : ''}
                        ${endDate ? `${format(endDate, '- dd.MM.yyyy')} ` : ''}
                        ${!defaultTime ? `${inputValues.toTime} ` : ''} ` : 'Выбрать дату'}
                    </Button>
                )}
                customTimeInput={(
                    <PeriodChooser
                        fromTime={inputValues.fromTime ?? ''}
                        toTime={inputValues.toTime ?? ''}
                        onChangeFromTime={handleChange}
                        onChangeToTime={handleChange}
                    />
                )}
                shouldCloseOnSelect={false}
            />
        </div>
    );
}
