import React from 'react';
import cn from 'classnames';
import { observer } from 'mobx-react';

import { eventsStore } from '../../../Store';
import { accountStore } from '../../../../Store';

import classes from './FilterButton.module.scss';

interface Props {
    isOpen: boolean;
    handleToggleFilterMenu(): void;
}

export const FilterButton = observer(({
    isOpen,
    handleToggleFilterMenu,
}: Props): JSX.Element => (
    <div
        className={cn(
            classes.button,
            classes[`button_filter_${eventsStore.filterType}`],
            {
                [classes.button_isOpen]: isOpen,
                [classes.button_isTeacher]: accountStore.isTeacher(),
            },
        )}
        onClick={handleToggleFilterMenu}
    />
));
