import { observer } from 'mobx-react';
import React from 'react';

import colors from '../../store/ColorConstans';

interface Props {
    size?: number;
    firstColor?: string;
    secondColor?: string;
    thirdColor?: string;
    className?: string;
    handler?: () => void;
}

export const CrossIcon = observer(({
    size = 13,
    firstColor = colors.firstColor,
    secondColor = colors.secondColor,
    thirdColor = colors.thirdColor,
    className,
    handler,
}: Props): JSX.Element => (
    <div className={className} style={{ height: `${size}px`, width: `${size}px`, cursor: 'pointer' }} onClick={handler}>
        <svg width={size} height={size} viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.668496 13C0.49725 13 0.326004 12.9349 0.195896 12.8038C-0.0652987 12.5426 -0.0652987 12.1191 0.195896 11.858L11.8583 0.195891C12.1194 -0.0652971 12.5429 -0.0652971 12.8041 0.195891C13.0653 0.45708 13.0653 0.880531 12.8041 1.14188L1.14191 12.8038C1.01082 12.934 0.839579 13 0.668496 13Z" fill="url(#paint0_linear_3667_112719)" />
            <path d="M12.3317 13C12.1604 13 11.9893 12.9349 11.8591 12.8038L0.195896 1.14188C-0.0652987 0.880531 -0.0652987 0.45708 0.195896 0.195891C0.457091 -0.0652971 0.880554 -0.0652971 1.14191 0.195891L12.8041 11.858C13.0653 12.1191 13.0653 12.5426 12.8041 12.8038C12.673 12.934 12.5019 13 12.3317 13Z" fill="url(#paint1_linear_3667_112719)" />
            <defs>
                <linearGradient id="paint0_linear_3667_112719" x1="13.9398" y1="5.75949" x2="-0.191376" y2="11.2756" gradientUnits="userSpaceOnUse">
                    <stop stopColor={firstColor} />
                    <stop offset="0.0416667" stopColor={firstColor} />
                    <stop offset="0.130208" stopColor={firstColor} />
                    <stop offset="0.734375" stopColor={secondColor} />
                    <stop offset="0.932292" stopColor={thirdColor} />
                </linearGradient>
                <linearGradient id="paint1_linear_3667_112719" x1="13.9398" y1="5.75949" x2="-0.191376" y2="11.2756" gradientUnits="userSpaceOnUse">
                    <stop stopColor={firstColor} />
                    <stop offset="0.0416667" stopColor={firstColor} />
                    <stop offset="0.130208" stopColor={firstColor} />
                    <stop offset="0.734375" stopColor={secondColor} />
                    <stop offset="0.932292" stopColor={thirdColor} />
                </linearGradient>
            </defs>
        </svg>
    </div>
));
