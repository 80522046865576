import React from 'react';

import { ActionButton, ActionTypeOfButton } from '@common/ActionButton';
import { Loader } from '@common/Loader';
import { TableHeader } from './TableHeader';
import { SearchType } from './SearchForm';
import { SearchParams } from '../util';

import classes from './table.module.scss';

export interface ColumnConfigs {
    name: string,
    searchType: SearchType,
    selectOptions?: {
        name: string,
        id: string;
    }[];
    searchName?: string;
}
interface Props {
    columnConfigs: ColumnConfigs[];
    children: React.ReactChild[] | React.ReactChild;
    isMoreButtonVisible?: boolean;
    isAllSelected: boolean;
    loading?: boolean;
    onShowMore(): void;
    setSearchParams?: React.Dispatch<React.SetStateAction<SearchParams>>;
    onSelectAll(): void;
    clearPreviousSelected(): void;
}

export const Table = ({
    columnConfigs,
    children,
    isMoreButtonVisible,
    isAllSelected,
    loading,
    onShowMore,
    setSearchParams,
    onSelectAll,
    clearPreviousSelected,
}: Props) => (
    <table>
        <TableHeader
            setSearchParams={setSearchParams}
            columnConfigs={columnConfigs}
            clearPreviousSelected={clearPreviousSelected}
            onSelectAll={onSelectAll}
            isAllSelected={isAllSelected}
        />

        <tbody style={{ position: 'relative' }}>
            {children}
            {loading && (
                <tr>
                    <td className={classes.table__loader} colSpan={columnConfigs.length + 1}>
                        <Loader />
                    </td>
                </tr>
            )}
        </tbody>

        {
            isMoreButtonVisible && (
                <tfoot>
                    <tr>
                        <td colSpan={columnConfigs.length + 1} align="center">
                            <ActionButton
                                actionType={ActionTypeOfButton.SECONDARY}
                                onClick={onShowMore}
                            >
                                Показать еще
                            </ActionButton>

                        </td>
                    </tr>
                </tfoot>
            )
        }
    </table>
);
