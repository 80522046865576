import React from 'react';
import { Loader, LoaderSizes } from '@common/Loader';

import classes from './LoaderWithInfo.module.scss';

interface Props {
    text: string;
    additionalInfoOnHover?: string;
}

export function LoaderWithInfo(
    {
        text,
        additionalInfoOnHover,
    }: Props,
) {
    return (
        <div className={classes.info}>
            <Loader size={LoaderSizes.small} />

            <div className={classes.info__text}>
                { text }
            </div>

            {
                additionalInfoOnHover ? (
                    <div className={classes.additionalInfo}>

                        <div className={classes.additionalInfo__text}>
                            {additionalInfoOnHover}
                        </div>

                    </div>
                ) : null
            }

        </div>
    );
}
