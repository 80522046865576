import React from 'react';
import cn from 'classnames';

import classes from './Spoiler.module.scss';

interface Props {
    title: string;
    children?: React.ReactChild;
    setIsShow: () => void;
    isShow: boolean;
}

export const EditSpoiler = ({
    title, children: child, setIsShow, isShow,
}: Props): JSX.Element => (
    <div className={classes.spoiler}>
        <div
            onClick={() => setIsShow()}
            className={classes.spoiler__tittle}
        >
            <div className={cn(classes.spoiler__triangle, {
                [classes.spoiler__staticTriangle]: true,
                [classes.spoiler__triangle_active]: isShow,
            })}
            />
            {title}
        </div>
        <ul className={cn(classes.spoiler__list, {
            [classes.spoiler__list_height_null]: isShow,
        })}
        >
            {isShow && child}
        </ul>
    </div>
);
