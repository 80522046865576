import React from 'react';
import { observer } from 'mobx-react-lite';
import { DiagramOperationButton } from '../DiagramOperationButton';
import Diagram from '../store/Diagram';
import classes from './DiagramOperationPanel.module.scss';

export const DiagramOperationPanel = observer((): JSX.Element => {
    const { diagramSettings } = Diagram;
    const {
        createSlotMode,
        createGroupMode,
        createForkMode,
        relationDiagramEntityMode,
        saveRelationDiagramEntityMode,
        removeDiagramEntityMode,
    } = diagramSettings;
    return (
        <div className={classes.diagramOperationPanel}>
            <div className={classes.diagramOperationPanel__slotsButtons}>
                <div className={classes.buttonGroup}>
                    <DiagramOperationButton
                        active={createSlotMode}
                        onClick={() => Diagram.setCreateSlotMode()}
                    >
                        Режим создания слотов
                    </DiagramOperationButton>
                    <DiagramOperationButton
                        active={createGroupMode}
                        onClick={() => Diagram.setCreateGroupMode()}
                    >
                        Режим создания групп
                    </DiagramOperationButton>
                    <DiagramOperationButton
                        active={createForkMode}
                        onClick={() => Diagram.setCreateForkMode()}
                    >
                        Режим создания развилок
                    </DiagramOperationButton>
                    <DiagramOperationButton
                        className={classes.buttonGroup__relationButton}
                        active={relationDiagramEntityMode || saveRelationDiagramEntityMode}
                        onClick={() => {
                            if (!saveRelationDiagramEntityMode) {
                                Diagram.setRelationEntityMode();
                            }
                            if (saveRelationDiagramEntityMode) {
                                Diagram.saveRelatedDiagramEntities();
                            }
                        }}
                    >
                        {
                            (
                                saveRelationDiagramEntityMode
                            )
                                ? 'Сохранить связи' : 'Задать связи'
                        }
                    </DiagramOperationButton>
                </div>
                <div className={classes.removeButtonGroup}>
                    <DiagramOperationButton
                        active={removeDiagramEntityMode}
                        onClick={() => Diagram.setRemoveDiagramEntityMode()}
                    >
                        Режим удаления
                    </DiagramOperationButton>
                </div>
            </div>
            <div className={classes.diagramOperationPanel__editModeButton} />
        </div>
    );
});
