import React from 'react';
import { observer } from 'mobx-react';
import { eventsStore } from '../Store';

import { ScheduleAssignmentsList } from './ScheduleAssignmentsList';
import { ScheduleEvent } from './ScheduleEvent';

import classes from './ScheduleEventBox.module.scss';

export const ScheduleEventBox = observer((): JSX.Element => (
    <div className={classes.schedulePage_meetingBlock}>
        <div className={classes.schedulePage__wrapper}>
            {(!eventsStore.selectedEvent && !eventsStore.toggleAssignmentList) && (
                <div className={classes.schedulePage_meetingText}>
                    Выберите событие
                </div>
            )}

            {(eventsStore.selectedEvent && !eventsStore.toggleAssignmentList) && (
                <ScheduleEvent />
            )}

            {eventsStore.toggleAssignmentList && (
                <ScheduleAssignmentsList />
            )}
        </div>
    </div>
));
