import React, {
    useState,
    useEffect,
    useRef,
    ChangeEvent,
} from 'react';

import classes from './NoteArea.module.scss';

interface Props {
    eventNote: string;
    addNewNote(note: string): void;
}

export function NoteArea({ eventNote, addNewNote }: Props): JSX.Element {
    const textAreaRef = useRef<HTMLTextAreaElement>(null);
    const [textAreaHeight, setTextAreaHeight] = useState('auto');
    const [parentHeight, setParentHeight] = useState('auto');

    function onChangeHandler({ target }: ChangeEvent<HTMLTextAreaElement>) {
        const { value } = target;
        setTextAreaHeight('auto');
        setParentHeight(`${textAreaRef.current!.scrollHeight}px`);
        addNewNote(value);
    }

    useEffect(() => {
        setParentHeight(`${textAreaRef.current!.scrollHeight}px`);
        setTextAreaHeight(`${textAreaRef.current!.scrollHeight}px`);
    }, [eventNote]);

    return (
        <div
            style={{
                minHeight: parentHeight,
            }}
        >
            <textarea
                ref={textAreaRef}
                rows={1}
                style={{
                    height: textAreaHeight,
                }}
                defaultValue={eventNote}
                placeholder="Ввести заметку"
                onChange={onChangeHandler}
                className={classes.schedulePage_eventsSection_noteArea}
            />
        </div>
    );
}
