/* eslint-disable max-len */
import React from 'react';
import { format } from 'date-fns';
import { ProfileIcon } from '@common/svg/ProfileIcon';
import { ArrowRightIcon } from '@common/svg/ArrowRightIcon';
import { MessageIcon } from '@common/svg/MessageIcon';
import classes from './TeacherAssignment.module.scss';

import {
    filteredTeacherAssignmentInstances_filteredTeacherAssignmentInstances,
} from '../../TeacherAssignmentsApolloTypes';

interface Props {
    onClick: () => void;
    // eslint-disable-next-line max-len
    setAssignment: (assignment: filteredTeacherAssignmentInstances_filteredTeacherAssignmentInstances, isCurrentTeacher: boolean) => void,
    assignments?: filteredTeacherAssignmentInstances_filteredTeacherAssignmentInstances[],
}

// const { topic } = assignment.assignmentInstance.assignment;

// const {
//     id,
//     wave,
//     start,
//     deadline,
// } = assignment.assignmentInstance;

// const [
//     note,
//     isCurrentTeacher,
// ] = assignment.assignmentInstance.teacherAssignmentInstances;

export function TeacherAssignment({
    onClick,
    setAssignment,
    assignments,
}: Props) {
    return (
        <>
            {
                assignments?.map(({
                    assignmentInstance: {
                        id,
                        wave,
                        start,
                        deadline,
                        teacherAssignmentInstances,
                        assignment: {
                            topic,
                        },
                    },
                    isCurrentTeacher,
                }, index) => (
                    <div className={classes.assignment} key={id}>
                        <h2 className={classes.assignment__title} title={`${topic}`}>{topic}</h2>

                        <div className={classes.assignment__footer}>
                            <div className={classes.assignment__wrapper}>
                                <p>{wave.index !== null && wave.index + 1} поток</p>

                                <p>
                                    {start && `${format(new Date(start), 'dd.MM')} -`}
                                    {deadline ? format(new Date(deadline), 'dd.MM') : 'н/д'}
                                </p>

                                {isCurrentTeacher ? <ProfileIcon /> : null}
                                {teacherAssignmentInstances.some(({ note }) => !!note) ? <MessageIcon /> : null}
                            </div>

                            <button
                                className={classes.assignment__button}
                                type="button"
                                onClick={() => {
                                    onClick();
                                    setAssignment(
                                        assignments[index],
                                        assignments[index].isCurrentTeacher,
                                    );
                                }}
                            >
                                <span className={classes.assignment__buttonText}>Подробнее</span>
                                <ArrowRightIcon />
                            </button>
                        </div>
                    </div>
                ))
            }
        </>
    );
}
