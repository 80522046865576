const rolesInput = {
    formProps: {
        label: '',
        placeholder: 'Введите название роли',
    },
};

export function getRolesInputFormProps() {
    return rolesInput.formProps;
}

const professorsSectionData = {
    formProps: {
        list: [],
        labelSuggest: 'Преподаватель:',
        labelSelect: 'Роль:',
        placeholderSuggest: 'Введите имя преподавателя',
        placeholderSelect: 'Укажите роль преподавателя',
        isNumber: false,
        optionsSelect: [{
            id: '',
            name: '',
        },
        ],
        mainOptions: [{
            id: '',
            name: '',
        },
        ],
    },
};

interface BaseItem {
    id: string;
    name: string;
}

interface Options {
    teachers: BaseItem[];
    teachersRoles: BaseItem[];
}

export function getProfessors({ teachersRoles, teachers }: Options) {
    const sortByName = (a: BaseItem, b: BaseItem) => {
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
        return 0;
    };
    const sortTeachers = [...teachers].sort(sortByName);
    const sortTeachersRoles = [...teachersRoles].sort(sortByName);

    professorsSectionData.formProps.mainOptions = sortTeachers.length > 0
        ? sortTeachers
        : [{ id: '', name: '' }];

    professorsSectionData.formProps.optionsSelect = sortTeachersRoles.length > 0
        ? sortTeachersRoles
        : [{ id: '', name: '' }];

    return professorsSectionData;
}
