import { makeAutoObservable } from 'mobx';
import { Student } from '../../../../../Interfaces';
// eslint-disable-next-line import/no-cycle
import { evaluationStore } from './evaluationStore';

class MultipleEvaluationStore {
    students: Student[] = [];

    constructor() {
        makeAutoObservable(this);
    }

    addStudentToMultipleEvaluation = (studentId: string): void => {
        const studentToAdd = evaluationStore.notEvaluatedStudents.find(
            ({ student }) => student.id === studentId,
        );

        if (studentToAdd) {
            this.students.push(studentToAdd);
        }
    };

    addAllStudentsToMultipleEvaluation = (): void => {
        this.students = evaluationStore.notEvaluatedStudents;
    };

    removeStudentFromMultipleEvaluation = (studentId: string): void => {
        this.students = this.students.filter(({ student }) => student.id !== studentId);
    };

    removeAllStudentsFromMultipleEvaluation = (): void => {
        this.students = [];
    };

    toggleAllMultipleStudents = (): void => {
        if (this.isMultipleStudentsNotSelected() || this.isMultipleStudentsSelectedButNotAll()) {
            this.addAllStudentsToMultipleEvaluation();
        } else {
            this.removeAllStudentsFromMultipleEvaluation();
        }
    };

    toggleStudentMultipleEvaluation = (studentId: string): void => {
        if (this.students.some(
            ({ student }) => student.id === studentId,
        )) {
            this.removeStudentFromMultipleEvaluation(studentId);
        } else {
            this.addStudentToMultipleEvaluation(studentId);
        }
    };

    isMultipleStudentsNotSelected = (): boolean => (
        !this.students.length
    );

    isMultipleStudentsSelectedButNotAll = (): boolean => (
        !!this.students.length && (!this.isAllMultipleStudentsSelected())
    );

    isAllMultipleStudentsSelected = (): boolean => (
        this.students.length === evaluationStore.notEvaluatedStudents.length
    );

    isStudentAddedToMultipleEvaluation = (studentId: string): boolean => (
        this.students.some(({ student }) => student.id === studentId)
    );
}

export const multipleEvaluationStore = new MultipleEvaluationStore();
