import React from 'react';
import { observer } from 'mobx-react';
import cn from 'classnames';
import moment, { Moment } from 'moment';

import { DateEvents } from './DateEvents/DateEvents';
import { DayOfMonthCellMobile } from './DayOfMonthCellMobile';
import { TimeReservingEvent } from '../../../../Interfaces';
import { timeStore } from '../../../../Store';

import classes from './DayOfMonthCell.module.scss';

interface Props {
    cellId: number;
    date: Moment | null;
    dateEvents: TimeReservingEvent[];
}

export const DayOfMonthCell = observer(({
    cellId,
    date,
    dateEvents,
}: Props): JSX.Element => (
    <>
        <div className={classes.dayOfMonthCell}>
            <div
                className={cn(
                    classes.dayOfMonth,
                    {
                        [classes.dayOfMonth_isToday]: moment().isSame(date, 'day'),
                        [classes.dayOfMonth_isOutsideMonth]: !date?.isSame(timeStore.selectedDate, 'month'),
                    },
                )}
            >
                {date?.format('DD')}
            </div>

            <DateEvents cellId={cellId} date={date} dateEvents={dateEvents} />
        </div>

        <DayOfMonthCellMobile date={date} dateEvents={dateEvents} />
    </>
));
