import React, { useRef, useEffect, useState } from 'react';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { useQuery, gql } from '@apollo/client';

import { scheduleGenerationStore } from '@admin/ScheduleGenerationPage/store';
import { Loader } from '@common/Loader';

import { Alert } from '@common';
import { Vizualize } from './Visualize';

export const GET_CONFIGS = gql`
    query getScheduleScore($baseTimeIntervalInstanceIds: [String!]!) {
        scheduleScore(baseTimeIntervalInstanceIds: $baseTimeIntervalInstanceIds) {
          generationConfig
          scoreResult
        }
    }
`;

export const ConflictsReportApollo = (): JSX.Element => {
    const [queryErrors, setQueryErrors] = useState<JSX.Element[]>([]);

    const addError = (
        newMessage: string,
    ) => {
        setQueryErrors((prevState) => [...prevState, <Alert message={newMessage} time={6000} />]);
    };

    const {
        data,
        loading,
    } = useQuery(GET_CONFIGS, {
        fetchPolicy: 'network-only',
        variables: {
            baseTimeIntervalInstanceIds: [scheduleGenerationStore.baseTimeIntervalInstance?.id!],
        },
        onError: (error) => addError(error.message),
    });
    const canvasRef = useRef(null);
    useEffect(
        () => {
            if (data) {
                new Vizualize().vizualize(
                    data?.scheduleScore?.generationConfig,
                    data?.scheduleScore?.generationConfig,
                    data?.scheduleScore?.scoreResult,
                    canvasRef.current,
                );
            }
        },
        [data],
    );

    return (
        <div>
            { loading && <Loader />}
            <canvas ref={canvasRef} />
            {queryErrors}
        </div>
    );
};
