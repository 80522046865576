import { ApolloError, useMutation } from '@apollo/client';
import { UPDATE_DURATION, GET_EVENT_DATA } from '../../queries';

export const useUpdateMeetingDurationMutation = (
    id: string,
    type: string,
    setErrorsFunction: (error: ApolloError) => void,
) => {
    const [
        updateMeetingInstanceDuration,
    ] = useMutation(UPDATE_DURATION, {
        refetchQueries: [{
            query: GET_EVENT_DATA,
            variables: {
                tmpMeetingInstanceInput: { id, type },
            },
        }],
        onError: (error) => setErrorsFunction(error),
    });

    return {
        updateMeetingDuration: (newDuration: number) => updateMeetingInstanceDuration({
            variables: {
                updateMeetingInstanceDurationInput: {
                    meetingInstanceId: id,
                    newDuration,
                },
            },
        }),
    };
};
