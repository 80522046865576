import React, { useState } from 'react';

import { ToolButton } from '../../ToolButton';
import { PopupWithSkills } from './PopupWithSkills';
import { ToolBarButtonProps } from '../interface';

export const SkillsButton = ({
    selectedUsers,
    refetchUserList,
}: ToolBarButtonProps): JSX.Element => {
    const [isOpen, setIsOpen] = useState<boolean>(false);

    const open = (): void => {
        setIsOpen(true);
    };

    const close = (): void => {
        setIsOpen(false);
    };

    return (
        <>
            <ToolButton
                text="Образовательные результаты"
                icon="blackPlus"
                onClick={open}
            />
            {isOpen && (
                <PopupWithSkills
                    isOpen={isOpen}
                    onClose={close}
                    selectedUsers={selectedUsers}
                    refetchUserList={refetchUserList}
                />
            )}
        </>
    );
};
