import React from 'react';
import { DaySchedule } from '../../TimePicker/modelTimePicker';

import classes from './workingHours.module.scss';

interface Props {
    intervals: DaySchedule[];
    title: string;
}

export const PreviewTimeIntervals = ({ intervals, title }: Props) => {
    const normalizTime = (time: string) => time.slice(0, 5);
    const getDayIntervals = (day: string) => {
        const dayIntervals = intervals.filter(item => item.weekDay === day)
            .map(interval => interval.workingIntervals).flat();
        return dayIntervals.map((item, index) => {
            if (index === (dayIntervals.length - 1)) {
                return `${normalizTime(item.start)}-${normalizTime(item.end)} `;
            }
            return `${normalizTime(item.start)}-${normalizTime(item.end)}, `;
        });
    };
    return (
        <div className={classes.prewiew_timeintervals}>
            <div className={classes.prewiew_title}>{title}</div>

            <div className={classes.prewiew_iterval}>ПН: {
                getDayIntervals('Monday')[0] ? <div className={classes.prewiew__time}>{getDayIntervals('Monday')}</div>
                    : <span className={classes.prewiew_holiday}>Выходной</span>
            }
            </div>
            <div className={classes.prewiew_iterval}>ВТ: {
                getDayIntervals('Tuesday')[0] ? <div className={classes.prewiew__time}>{getDayIntervals('Tuesday')}</div>
                    : <span className={classes.prewiew_holiday}>Выходной</span>
            }
            </div>
            <div className={classes.prewiew_iterval}>СР: {
                getDayIntervals('Wednesday')[0] ? <div className={classes.prewiew__time}>{getDayIntervals('Wednesday')}</div>
                    : <span className={classes.prewiew_holiday}>Выходной</span>
            }
            </div>
            <div className={classes.prewiew_iterval}>ЧТ: {
                getDayIntervals('Thursday')[0] ? <div className={classes.prewiew__time}>{getDayIntervals('Thursday')}</div>
                    : <span className={classes.prewiew_holiday}>Выходной</span>
            }
            </div>
            <div className={classes.prewiew_iterval}>ПТ: {
                getDayIntervals('Friday')[0] ? <div className={classes.prewiew__time}>{getDayIntervals('Friday')}</div>
                    : <span className={classes.prewiew_holiday}>Выходной</span>
            }
            </div>
            <div className={classes.prewiew_iterval}>СБ: {
                getDayIntervals('Saturday')[0] ? <div className={classes.prewiew__time}>{getDayIntervals('Saturday')}</div>
                    : <span className={classes.prewiew_holiday}>Выходной</span>
            }
            </div>
            <div className={classes.prewiew_iterval}>ВС: {
                getDayIntervals('Sunday')[0] ? <div className={classes.prewiew__time}>{getDayIntervals('Sunday')}</div>
                    : <span className={classes.prewiew_holiday}>Выходной</span>
            }
            </div>
        </div>
    );
};
