import React from 'react';

import { WeekScheduleDay } from '@admin/EducationPeriodPage/EducationPeriodDaySHedule';

import classes from './WeekDayIntervals.module.scss';

interface Props {
    weekDay: WeekScheduleDay;
}

export function WeekDayIntervals({ weekDay }: Props) {
    return (
        <div className={classes.weekDayInterval}>
            <div className={classes.weekDayInterval__day}>
                {weekDay.day}:
            </div>
            <div className={classes.weekDayInterval__interval}>

                {
                    weekDay.schedule ? weekDay.schedule.map((schedule, index) => (
                        <div
                            className={classes.weekDayInterval__time}
                            key={schedule.id}
                        >
                            {formatScheduleTime(schedule.start)}
                            -
                            {formatScheduleTime(schedule.end)}
                            {
                                index + 1 !== weekDay.schedule?.length && ','
                            }
                        </div>
                    )) : 'Выходной'
                }

            </div>
        </div>
    );
}

function formatScheduleTime(time: string) {
    const timeNoPlus = time.split('+')[0].split(':');
    const hours = timeNoPlus[0];
    const minutes = timeNoPlus[1];

    return `${hours}:${minutes}`;
}
