import { gql } from '@apollo/client';

export const GET_SKILLS_TYPE = gql`
query skillTypes {
    skillTypes {
        id

        name {
            nominativeSingular
        }

        parentId
    }

    moduleSettings {
        moduleSkillTypes {
            id
            isLevelIncreased
        }

        meetingSkillTypes {
            id
            isLevelIncreased
        }
    }
}`;
