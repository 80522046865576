import React, { useEffect } from 'react';
import { gql, useLazyQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { sortBy } from 'lodash';

import { Loader } from '@common/Loader';
import { useUrlQuery } from '@common/hooks';
import { EvaluationPointResults, RouteParams, SearchParams } from '../ModuleStatisticsTypes';
import { GetModuleStatistics, GetModuleStatisticsVariables, GetModuleStatistics_evaluationPointResultsByFilters } from '../graphql-types';
import { ModuleStatisticsTable } from './ModuleStatisticsTable';

export const GET_MODULE_STATISTICS = gql`
    query GetModuleStatistics(
    $evaluationPointResultsByFiltersInput: EvaluationPointResultsByFiltersInput!,
    $moduleWavesByFiltersInput: ModuleWavesByFiltersInput!
    ) {
    evaluationPointResultsByFilters(evaluationPointResultsByFiltersInput: $evaluationPointResultsByFiltersInput) {
        id
        topic
        order
        skills {
        skill {
            id
            name
            typeId
        }
        level
        }
        evaluationPointToStudents {
        studentId
        isRated
        isCredited
        skillResults {
            skillId
            level
        }
        }
    }
    moduleWavesByFilters(moduleWavesByFiltersInput: $moduleWavesByFiltersInput) {
        id
        index
        students {
            id
            user {
                lastName
                firstName
                patronymic
                id
            }
        }
    }
}
`;

interface ModuleStatisticsTableApolloProps {
    isDetailedModeOn: boolean;
    moduleLink: string;
}

export function ModuleStatisticsTableApollo({
    isDetailedModeOn,
    moduleLink,
}: ModuleStatisticsTableApolloProps) {
    const { btiId: baseTimeIntervalInstanceId, moduleId } = useParams<RouteParams>();
    const { getUrlQuery, history } = useUrlQuery();
    const {
        skillTypeId,
        waveIds,
        studentIds,
        evaluationPointIds,
    } = useModuleStatisticsSearchParams();

    const [
        getModuleStatistics,
        { data, loading },
    ] = useLazyQuery<GetModuleStatistics, GetModuleStatisticsVariables>(
        GET_MODULE_STATISTICS, { fetchPolicy: 'network-only' },
    );

    useEffect(() => {
        const moduleWavesByFiltersInput = {
            moduleId,
            baseTimeIntervalInstanceId,
            waveIds,
            studentIds,
        };
        const evaluationPointResultsByFiltersInput = {
            ...moduleWavesByFiltersInput,
            skillTypeId,
            evaluationPointIds,
        };
        getModuleStatistics({ variables: {
            moduleWavesByFiltersInput,
            evaluationPointResultsByFiltersInput,
        } });
    }, [history.location.search]);

    function useModuleStatisticsSearchParams(): SearchParams {
        const rawSkillTypeId = getUrlQuery('skillTypeId');
        const rawEvaluationPointIds = getUrlQuery('evaluationPointIds');
        const rawWaveIds = getUrlQuery('waveIds');
        const rawStudentIds = getUrlQuery('studentIds');
        return {
            skillTypeId: rawSkillTypeId && decodeURIComponent(rawSkillTypeId),
            evaluationPointIds: rawEvaluationPointIds
              && JSON.parse(decodeURIComponent(rawEvaluationPointIds)),
            waveIds: rawWaveIds && JSON.parse(decodeURIComponent(rawWaveIds)),
            studentIds: rawStudentIds && JSON.parse(decodeURIComponent(rawStudentIds)),
        };
    }

    if (loading) {
        return (
            <Loader />
        );
    }

    if (data) {
        return (
            <ModuleStatisticsTable
                isDetailedModeOn={isDetailedModeOn}
                moduleLink={moduleLink}
                evaluationPoints={
                    formatEvaluationPoints(data.evaluationPointResultsByFilters)
                }
                waves={data.moduleWavesByFilters}
            />
        );
    }

    return null;
}

function formatEvaluationPoints(
    evaluationPointResultsByFilters: GetModuleStatistics_evaluationPointResultsByFilters[],
): EvaluationPointResults[] {
    const sortedEvaluationPoints = sortBy(evaluationPointResultsByFilters, 'order');
    return sortedEvaluationPoints.map(({
        evaluationPointToStudents, ...rest
    }) => {
        const studentResultsMap = new Map(
            evaluationPointToStudents?.map(evaluationPointToStudent => (
                [evaluationPointToStudent.studentId, evaluationPointToStudent]
            )),
        );
        return {
            ...rest,
            studentResultsMap,
        };
    });
}
