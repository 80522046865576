import React, { useState } from 'react';

import { Accordion as AccordionComponent } from '@common/Accordion';
import {
    AccordionItems,
} from '@admin/EducationPeriodStatistic/EducationPeriodStatisticVoted/VotedModuleStatisticAccordion/AccordionItems';

import {
    PrioritiesModule, StudentRating,
    StudentsModulesPriorityGroups,
} from '@admin/EducationPeriodStatistic/EducationPeriodStatisticInterfaces';

import { educationPeriodStatisticStore } from '@admin/EducationPeriodStatistic/EducationPeriodStatisticStore';
import classes from './Accordion.module.scss';

export interface PaginatedPriority {
    pageIndex: number,
    prioritiesArr: StudentsModulesPriorityGroups[],
}

interface Props {
    module: PrioritiesModule;
    priorityGroups: StudentsModulesPriorityGroups[];
}

export function Accordion(
    {
        module,
        priorityGroups,
    }: Props,
) {
    const [paginatedArray] = useState(getPaginatedModulePriorities(priorityGroups));

    const [activePriorityItems, setActivePriorityItems] = useState<
    StudentsModulesPriorityGroups[]
    >(priorityGroups.slice(0, 3));

    return (
        <div className={classes.accordion}>
            <AccordionComponent
                headerClassnames={classes.header}
                shouldClickOnHeaderOpen
                isDefaultOpen
            >
                <div className={classes.accordion__header}>

                    <div className={classes.accordion__title}>
                        {module.name}
                    </div>

                    <div className={classes.accordion__title}>
                        {getStudentPriorityAmount(priorityGroups)}
                    </div>

                    <div className={classes.accordion__title}>
                        {getStudentsAverageRating(
                            educationPeriodStatisticStore.studentsRatings,
                            priorityGroups,
                        )}
                    </div>
                </div>

                <div className={classes.accordion__items}>
                    {
                        activePriorityItems.map((priority, index) => (
                            <AccordionItems
                                key={`${priority.priority}${module.id}${module.name}`}
                                index={index}
                                moduleId={module.id}
                                modulePriorities={priority}
                            />
                        ))
                    }
                    {
                        paginatedArray.length ? (
                            <div
                                className={classes.accordion__additional}
                            >
                                <button
                                    className={classes.accordion__additional_btn}
                                    type="button"
                                    onClick={() => {
                                        const newItem = paginatedArray.shift();
                                        if (newItem && newItem.prioritiesArr.length) {
                                            setActivePriorityItems(
                                                [
                                                    ...activePriorityItems,
                                                    ...newItem.prioritiesArr,
                                                ],
                                            );
                                        }
                                    }}
                                >
                                    {getPaginationNextPageString(paginatedArray[0])}
                                </button>
                            </div>
                        ) : null
                    }
                </div>
            </AccordionComponent>
        </div>
    );
}

function getStudentsAverageRating(
    allRatings: StudentRating[],
    priorityGroups: StudentsModulesPriorityGroups[],
) {
    const allStudentsLength = getStudentPriorityAmount(priorityGroups);

    if (!allRatings.length || !priorityGroups.length || !allStudentsLength) {
        return '-';
    }

    let allRatingsCount = 0;

    priorityGroups.forEach((priorityGroup) => {
        allRatings.forEach((studentRating) => {
            const hasStudentRating = priorityGroup.students.find(
                (student) => student.id === studentRating.student.id,
            );
            if (hasStudentRating) {
                allRatingsCount += studentRating.value;
            }
        });
    });

    return `${(allRatingsCount / allStudentsLength).toFixed(1)}`;
}

function getPaginationNextPageString(nextPage: PaginatedPriority) {
    let nextPageString = '';

    nextPage.prioritiesArr.forEach((priority, index) => {
        if (index === nextPage.prioritiesArr.length - 1) {
            nextPageString = `${nextPageString + priority.priority}...`;
            return;
        }
        nextPageString = `${nextPageString + priority.priority},`;
    });

    return nextPageString;
}

function getStudentPriorityAmount(priorities: StudentsModulesPriorityGroups[]) {
    let studentsAmount = 0;
    priorities.forEach((priority) => {
        studentsAmount += priority.students.length;
    });
    return studentsAmount;
}

function getPaginatedModulePriorities(priorities: StudentsModulesPriorityGroups[]) {
    const prioritiesNew: PaginatedPriority[] = [];
    let pageIndex = 1;

    priorities.forEach((_, index) => {
        if ((index + 1) === priorities.length && (index + 1) % 3 !== 0) {
            prioritiesNew.push(
                {
                    pageIndex,
                    prioritiesArr: priorities.slice((pageIndex - 1) * 3, priorities.length),
                },
            );
        }
        if ((index + 1) % 3 === 0) {
            prioritiesNew.push(
                {
                    pageIndex,
                    prioritiesArr: priorities.slice(index - 2, index + 1),
                },
            );
            pageIndex += 1;
        }
    });
    prioritiesNew.shift();
    return prioritiesNew;
}
