import { observer } from 'mobx-react';
import React from 'react';

import colors from '../../store/ColorConstans';

interface Props {
    handler?: () => void;
    width?: number;
    height?: number;
    firstColor?: string;
    secondColor?: string;
    thirdColor?: string;
    className?: string;
}

export const HatIcon = observer(({
    width = 80,
    height = 80,
    firstColor = colors.firstColor,
    secondColor = colors.secondColor,
    thirdColor = colors.thirdColor,
    className,
    handler,
}: Props): JSX.Element => (
    <div className={className} style={{ height: `${height}px`, width: `${width}px` }} onClick={handler}>
        <svg viewBox="0 0 44 34" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ height, width, display: 'flex', justifyContent: 'center', paddingLeft: '12px', paddingTop: '7px' }}>
            <path
                style={{ display: 'flex', justifySelf: 'center', margin: '0 auto' }}
                d="M20.4646 0.970712C19.6945 0.594866 18.8529 0.399902 18.0007 0.399902C17.1486 0.399902 16.307 0.594866 15.5369 0.970712L1.24074 7.94754C0.97761 8.07542 0.75735 8.28103 0.607972 8.53821C0.458593 8.7954 0.386846 9.09255 0.401856 9.39186V9.47042V18.5352C0.401856 18.9358 0.556369 19.3201 0.831405 19.6035C1.10644 19.8868 1.47947 20.046 1.86843 20.046C2.25739 20.046 2.63042 19.8868 2.90545 19.6035C3.18049 19.3201 3.335 18.9358 3.335 18.5352V11.7487L6.26815 13.2474V21.5567C6.26819 21.7555 6.3063 21.9523 6.38028 22.1358C6.45427 22.3194 6.56269 22.4861 6.69932 22.6264L6.77265 22.6989L6.94864 22.8651C7.86791 23.709 8.86167 24.4626 9.91698 25.1162C11.8763 26.3308 14.7068 27.5999 18.0007 27.5999C21.2947 27.5999 24.1251 26.3308 26.0874 25.1162C27.1427 24.4626 28.1365 23.709 29.0558 22.8651L29.2317 22.6989L29.2992 22.6264H29.3051C29.4415 22.4855 29.5496 22.3182 29.6231 22.1341C29.6966 21.95 29.734 21.7528 29.7333 21.5537V13.2444L34.7842 10.6639C35.0311 10.538 35.2386 10.3429 35.3828 10.1011C35.5271 9.85927 35.6023 9.58048 35.5998 9.29666C35.5974 9.01283 35.5174 8.73546 35.369 8.49632C35.2206 8.25718 35.0098 8.06594 34.7607 7.94452L20.4646 0.967691V0.970712ZM20.5584 17.9369L26.8002 14.7461V20.886C26.1044 21.4939 25.3618 22.0426 24.5798 22.5267C22.8786 23.5782 20.5731 24.5783 18.0007 24.5783C15.4284 24.5783 13.1258 23.5812 11.4217 22.5267C10.6396 22.0426 9.89707 21.4939 9.20129 20.886V14.7461L15.443 17.9369C17.0563 18.7588 18.9452 18.7588 20.5584 17.9369ZM19.2092 3.70222L30.7716 9.34351L19.2532 15.2296C18.8638 15.4282 18.4352 15.5316 18.0007 15.5316C17.5662 15.5316 17.1376 15.4282 16.7483 15.2296L5.22981 9.34351L16.7923 3.70222C17.17 3.51781 17.5827 3.42215 18.0007 3.42215C18.4187 3.42215 18.8315 3.51781 19.2092 3.70222ZM6.70519 22.6324L6.70225 22.6294L6.70519 22.6324Z"
                fill="url(#paint0_linear_1714_8805)"
            />
            <defs>
                <linearGradient id="paint0_linear_1714_8805" x1="38.1445" y1="12.4505" x2="3.01579" y2="30.196" gradientUnits="userSpaceOnUse">
                    <stop offset="0.130208" stopColor={firstColor} />
                    <stop offset="0.734375" stopColor={secondColor} />
                    <stop offset="0.932292" stopColor={thirdColor} />
                </linearGradient>
            </defs>
        </svg>
    </div>
));
