export interface StudentAssignmentInstance {
    assignmentInstance: AssignmentInstance;
    note: string;
    isCompleted: boolean;
}

export interface AssignmentInstance {
    id: string;
    deadline: Date;
    assignment: Assignment;
}

export interface Assignment {
    topic: string;
    description: string;
    module: Module;
    duration: Duration;
}

export interface Module {
    id: string;
    name: string;
}

export interface Duration {
    name: string;
    academicHours: number;
}

export enum AssignmentInstanceStatusRU {
    ACTUAL = 'Актуальные',
    ARCHIVED = 'Архив',
    OVERDUE = 'Просроченные',
}

export enum AssignmentInstanceOrder {
    DEADLINEASC = 'deadlineAsc',
    DEADLINEDESC = 'deadlineDesc',
}

export interface GetStudentAssignments {
    filteredStudentAssignmentInstances: StudentAssignmentInstance[];
}

export interface GetStudentActiveBTI {
    student: {
        activeBaseTimeIntervalInstance: {
            baseTimeIntervalInstance: { id: string; };
        } | null;
    };
}

export interface GetStudentActiveBTIVariables {
    id: string;
}

export interface StudentActiveBTIModulesVariables {
    id: string;
    baseTimeIntervalInstanceId?: string | null;
}

export interface StudentActiveBTIModules {
    student: {
        requestedBaseTimeIntervalInstance: {
            studentSubspaces: {
                studentModules: {
                    id: string;
                    module: Module;
                }[];
            }[];
        };
    };
}
