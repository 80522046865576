import React, { ChangeEvent } from 'react';
import cn from 'classnames';

import { Warning } from './Warning';
import { RoomLocation, Building } from '../../RoomTypes';
import classes from './RoomZones.module.scss';

interface RoomZonesProps {
    buildings: Building[];
    defaultAddress: string;
    id?: string;
    chooseZone(value: string): void;
}

export function RoomZones({
    buildings,
    defaultAddress,
    id,
    chooseZone,
}: RoomZonesProps): JSX.Element {
    const sendZoneData = ({ target }: ChangeEvent<HTMLSelectElement>) => {
        const { value } = target;
        chooseZone(value);
    };

    return (
        <div className={classes.form_header}>
            <h2 className={classes.form_header_heading}>{id ? 'Редактировать помещение' : 'Добавить помещение'}</h2>
            <span className={classes.form_header_text}>
                <div>
                    <h2 className={classes.form_header_title}>Расположение:</h2>

                    {
                        buildings.length ? (
                            <select
                                value={defaultAddress}
                                className={cn(classes.form_header_select, {
                                    [classes.empty_option]: !defaultAddress,
                                })}
                                onChange={(event) => sendZoneData(event)}
                            >
                                <option className={classes.placeholder} value="" disabled>
                                    Укажите расположение
                                </option>
                                {
                                    buildings.map((building: RoomLocation) => (
                                        <option
                                            value={building.id}
                                            key={building.id}
                                            className={classes.option}
                                        >
                                            {building.name} {building.location}
                                        </option>
                                    ))
                                }
                            </select>
                        )
                            : (
                                <Warning message="Без корпусов нельзя создать помещение, переадресация для создания" time={3000} />
                            )
                    }
                </div>
            </span>
        </div>
    );
}
