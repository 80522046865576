import React from 'react';

import { gql, useMutation, useQuery } from '@apollo/client';

import { Loader } from '@common/Loader';
import { Alert } from '@common/Alert';
import { Equipment } from './Equipment';

const GET_CONSUMABLE_EQUIPMENT = gql`
    query getConsumableEquipment {
        consumableEquipment {
            id
            name
            unit
        }
    }
`;

const CREATE_CONSUMABLE_EQUIPMENT = gql`
    mutation createConsumableEquipment (
        $id: String!
        $name: String!
        $unit: String!
    ) {
        createConsumableEquipment(
            createConsumableEquipmentInput: {
                id: $id
                name: $name
                unit: $unit
            }
        ) {
            id
        }
    }
`;

const UPDATE_CONSUMABLE_EQUIPMENT = gql`
    mutation updateConsumableEquipment (
        $id: String!
        $name: String!
        $unit: String!
    ) {
        updateConsumableEquipment(
            updateConsumableEquipmentInput: {
                id: $id
                name: $name
                unit: $unit
            }
        ) {
            id
        }
    }
`;

const REMOVE_CONSUMABLE_EQUIPMENT = gql`
    mutation removeConsumableEquipment(
        $id: String!
    ) {
        removeConsumableEquipment(id: $id) {
            status
        }
    }
`;

export function EquipmentApollo(): JSX.Element {
    const { data, loading, error } = useQuery(GET_CONSUMABLE_EQUIPMENT, {
        fetchPolicy: 'cache-and-network',
    });

    const [
        createConsumableEquipment,
        { error: createError },
    ] = useMutation(CREATE_CONSUMABLE_EQUIPMENT, {
        refetchQueries: [{ query: GET_CONSUMABLE_EQUIPMENT }],
    });

    const [
        updateConsumableEquipment,
        { error: updateError },
    ] = useMutation(UPDATE_CONSUMABLE_EQUIPMENT, {
        refetchQueries: [{ query: GET_CONSUMABLE_EQUIPMENT }],
    });

    const [
        removeConsumableEquipment,
        { error: removeError },
    ] = useMutation(REMOVE_CONSUMABLE_EQUIPMENT, {
        refetchQueries: [{ query: GET_CONSUMABLE_EQUIPMENT }],
    });

    if (loading) return <><Loader /></>;
    if (error) return <>`Error! ${error.message}`</>;
    return (
        <>
            <Equipment
                consumableEquipment={data.consumableEquipment}
                createConsumableEquipment={(
                    id,
                    name,
                    unit,
                ) => createConsumableEquipment({
                    variables: {
                        id,
                        name,
                        unit,
                    },
                }).catch(() => { })}
                updateConsumableEquipment={(
                    id,
                    name,
                    unit,
                ) => updateConsumableEquipment({
                    variables: {
                        id,
                        name,
                        unit,
                    },
                }).catch(() => { })}
                removeConsumableEquipment={(
                    id,
                ) => removeConsumableEquipment({
                    variables: {
                        id,
                    },
                }).catch(() => { })}
            />
            {(createError || removeError || updateError) && (
                <Alert
                    message={
                        createError?.message
                        ?? removeError?.message
                        ?? updateError?.message
                    }
                    time={3000}
                />
            )}
        </>
    );
}
