import React, { useEffect, useState } from 'react';
import { differenceInDays, format, isBefore } from 'date-fns';
import { observer } from 'mobx-react';
import cn from 'classnames';

import {
    UserWithAbsences_user_student_excusedAbsences as ExcusedAbsence,
    UserWithAbsences_user_student_baseTimeIntervalInstances_baseTimeIntervalInstance
    as BaseTimeIntervalInstance,
} from '@admin/Users/UserCard/UserCommonInfo/UserAbsences/graphql-types';
import { DeleteIcon } from '@admin/Users/UserCard/UserCommonInfo/UserAbsences/AbsencesAccordion/Absence/DeleteIcon';
import { BaseTimeIntervalInstanceWithExcusedAbsences } from '@admin/Users/UsersInterfaces';
import { usersStore } from '@admin/Users/UsersStore';

import { Calendar } from '@admin/Users/UserCard/UserCommonInfo/UserAbsences/Calendar';
import { Alert } from '@common';
import classes from './Absence.module.scss';

interface Props {
    isFirstAbsence: boolean;
    baseTimeIntervalInstance: BaseTimeIntervalInstance;
    absence: ExcusedAbsence;
    setIsFirstAbsenceEdit: (isEdit: boolean) => void;
}

export const Absence = observer((
    {
        isFirstAbsence,
        baseTimeIntervalInstance,
        absence,
        setIsFirstAbsenceEdit,
    }: Props,
): JSX.Element => {
    const [isStartEdit, setIsStartEdit] = useState(false);
    const [isEndEdit, setIsEndEdit] = useState(false);
    const [validationErrors, setValidationErrors] = useState<JSX.Element[]>([]);

    const isNewAbsence = new Date(absence.startDate).getFullYear() === 1970
        || new Date(absence.endDate).getFullYear() === 1970;

    const isStartDateDefault = new Date(absence.startDate).getFullYear() === 1970;
    const isEndDateDefault = new Date(absence.endDate).getFullYear() === 1970;

    const dateStartInfo = isStartDateDefault ? 'Начало' : format(new Date(absence.startDate), 'dd.MM.yyyy');
    const dateEndInfo = isEndDateDefault ? 'Завершение' : format(new Date(absence.endDate), 'dd.MM.yyyy');

    const amountOfAbsenceDays = differenceInDays(
        new Date(absence.endDate),
        new Date(absence.startDate),
    );
    const amountOfAbsenceDaysInfo = isNewAbsence ? '' : `[${amountOfAbsenceDays} дн.]`;

    const isDateEdit = isEndEdit || isStartEdit;

    const startDateSelectedDate = ((isNewAbsence
            && !isStartDateDefault)
        || (!isNewAbsence && absence.startDate))
        ? new Date(absence.startDate) : new Date(baseTimeIntervalInstance.startDate);

    // eslint-disable-next-line no-nested-ternary
    const endDateSelectedDate = isNewAbsence && !isEndDateDefault
        ? new Date(absence.startDate) : !isNewAbsence && absence.endDate
            ? new Date(absence.endDate) : new Date(baseTimeIntervalInstance.startDate);

    useEffect(() => {
        usersStore.setAreAbsencesReadyToEdit(true);
        usersStore.setIsAbsenceCreating(isNewAbsence);
    }, [isNewAbsence]);

    const addValidationError = (message: string) => {
        setValidationErrors(
            (prevState) => [...prevState, <Alert message={message} time={5000} />],
        );
    };

    const deleteButtonHandleCLick = () => {
        const groupedAbsencesRemovedCurrentAbsence = getGroupedAbsencesRemovedCurrentAbsence(
            absence.id,
            baseTimeIntervalInstance.id,
            usersStore.getBaseTimeIntervalInstancesWithExcusedAbsences(),
        );

        usersStore.setBaseTimeIntervalInstancesWithExcusedAbsences(
            groupedAbsencesRemovedCurrentAbsence,
        );
    };

    const startEditHandleChange = (newDate: Date) => {
        const groupedAbsencesChangedCurrentAbsence = getGroupedAbsencesChangedCurrentAbsence(
            absence.id,
            baseTimeIntervalInstance.id,
            usersStore.getBaseTimeIntervalInstancesWithExcusedAbsences(),
            format(newDate, 'yyyy-MM-dd'),
        );

        const isStartDateBeforeBTIStart = isBefore(
            newDate, new Date(baseTimeIntervalInstance.startDate),
        );

        const isStartAfterBTIEnd = isBefore(
            new Date(baseTimeIntervalInstance.endDate),
            newDate,
        );

        const isEndDateBeforeStart = isBefore(new Date(absence.endDate), newDate) && !isNewAbsence;

        const isDateValid = !isStartDateBeforeBTIStart
            && !isStartAfterBTIEnd && !isEndDateBeforeStart;

        if (isStartDateBeforeBTIStart) {
            addValidationError('Дата начала должна быть позже даты начала учебного периода');
        }

        if (isEndDateBeforeStart) {
            addValidationError('Дата начала должна быть позже даты конца');
        }

        if (isStartAfterBTIEnd) {
            addValidationError('Дата начала должна быть не позже даты конца учебного периода');
        }

        if (isDateValid) {
            usersStore.setBaseTimeIntervalInstancesWithExcusedAbsences(
                groupedAbsencesChangedCurrentAbsence,
            );

            setIsFirstAbsenceEdit(false);
            setIsStartEdit(false);
        }
    };

    const endEditHandleChange = (newDate: Date) => {
        const groupedAbsencesChangedCurrentAbsence = getGroupedAbsencesChangedCurrentAbsence(
            absence.id,
            baseTimeIntervalInstance.id,
            usersStore.getBaseTimeIntervalInstancesWithExcusedAbsences(),
            '',
            format(newDate, 'yyyy-MM-dd'),
        );

        const isEndDateBeforeBTIStart = isBefore(
            newDate, new Date(baseTimeIntervalInstance.startDate),
        );

        const isEndDateBeforeStart = isBefore(newDate, new Date(absence.startDate))
            && !isNewAbsence;

        const isEndAfterBTIEnd = isBefore(
            new Date(baseTimeIntervalInstance.endDate),
            newDate,
        );

        const isDateValid = !isEndDateBeforeStart && !isEndAfterBTIEnd && !isEndDateBeforeBTIStart;

        if (isEndDateBeforeBTIStart) {
            addValidationError('Дата конца должна быть позже даты начала учебного периода');
        }

        if (isEndDateBeforeStart) {
            addValidationError('Дата конца должна быть позже даты начала');
        }

        if (isEndAfterBTIEnd) {
            addValidationError('Дата конца должна быть не позже даты конца учебного периода');
        }

        if (isDateValid) {
            usersStore.setBaseTimeIntervalInstancesWithExcusedAbsences(
                groupedAbsencesChangedCurrentAbsence,
            );

            setIsFirstAbsenceEdit(false);
            setIsEndEdit(false);
        }
    };

    return (
        <div
            className={cn({
                [classes.absence_notFirst]: !isFirstAbsence,
                [classes.absence_first]: isFirstAbsence,
                [classes.absence_notFirstEdit]: isDateEdit && !isFirstAbsence,
                [classes.absence_first_edit]: isDateEdit && isFirstAbsence,
            })}
        >

            {
                !isFirstAbsence && (
                    <div />
                )
            }

            <div className={classes.absence}>

                <div
                    onClick={() => {
                        setIsStartEdit(true);
                        if (isFirstAbsence) {
                            setIsFirstAbsenceEdit(true);
                        }
                    }}
                    className={cn(classes.absence__date, {
                        [classes.absence__date_edit]: isDateEdit,
                    })}
                >
                    {
                        isStartEdit ? (
                            <div className={classes.absence__start_edit}>
                                <Calendar
                                    autoFocus
                                    selectedDate={startDateSelectedDate}
                                    onChange={(date) => {
                                        startEditHandleChange(date);
                                    }}
                                    onCalendarClose={() => {
                                        setIsStartEdit(false);
                                        setIsFirstAbsenceEdit(false);
                                    }}
                                />
                            </div>
                        ) : (
                            <div className={classes.absence__start}>
                                {dateStartInfo}
                            </div>
                        )
                    }

                </div>

                <div className={classes.absence__hyphen}>
                    —
                </div>

                <div
                    onClick={() => {
                        setIsEndEdit(true);
                        if (isFirstAbsence) {
                            setIsFirstAbsenceEdit(true);
                        }
                    }}
                    className={cn(classes.absence__date, {
                        [classes.absence__date_edit]: isDateEdit,
                    })}
                >
                    {
                        isEndEdit ? (
                            <div className={classes.absence__end_edit}>
                                <Calendar
                                    autoFocus
                                    selectedDate={endDateSelectedDate}
                                    onChange={(date) => {
                                        endEditHandleChange(date);
                                    }}
                                    onCalendarClose={() => {
                                        setIsEndEdit(false);
                                        setIsFirstAbsenceEdit(false);
                                    }}
                                />
                            </div>
                        ) : (
                            <div className={classes.absence__end}>
                                {dateEndInfo}
                            </div>
                        )
                    }

                </div>

                <div className={classes.absence__daysAmount}>
                    {
                        amountOfAbsenceDaysInfo
                    }
                </div>

                <button
                    type="button"
                    className={classes.absence__delete}
                    onClick={() => deleteButtonHandleCLick()}
                >
                    <DeleteIcon />
                </button>

            </div>

            {
                validationErrors
            }

        </div>
    );
});

function getGroupedAbsencesChangedCurrentAbsence(
    absenceId: string,
    baseTimeIntervalInstanceId: string,
    groupedAbsences: BaseTimeIntervalInstanceWithExcusedAbsences[],
    newStartDate?: string,
    newEndDate?: string,
) {
    return groupedAbsences.map((group) => {
        if (group.baseTimeIntervalInstance.id !== baseTimeIntervalInstanceId) {
            return group;
        }

        const newAbsences = group.excusedAbsences.map((absence) => {
            if (absence.id !== absenceId) {
                return absence;
            }

            return {
                ...absence,
                startDate: newStartDate || absence.startDate,
                endDate: newEndDate || absence.endDate,
            };
        });

        return {
            ...group,
            excusedAbsences: newAbsences,
        };
    });
}

function getGroupedAbsencesRemovedCurrentAbsence(
    absenceId: string,
    baseTimeIntervalInstanceId: string,
    groupedAbsences: BaseTimeIntervalInstanceWithExcusedAbsences[],
): BaseTimeIntervalInstanceWithExcusedAbsences[] {
    return groupedAbsences.map((group) => {
        if (group.baseTimeIntervalInstance.id !== baseTimeIntervalInstanceId) {
            return group;
        }

        const filteredAbsences = group.excusedAbsences.filter(
            (absence) => absence.id !== absenceId,
        );

        return {
            ...group,
            excusedAbsences: filteredAbsences,
        };
    });
}
