import React from 'react';
import { ActionButton } from '@common/ActionButton';
import { ExtraDays } from './UniversitySpecialDaysInterfaces';
import { UniversitySpecialDaysList } from './UniversitySpecialDaysList';
import classes from './UniversitySpecialDaysView.module.scss';

interface Props {
    extraDays: ExtraDays,
    changeMode(): void,
}

export function UniversitySpecialDaysView(
    { extraDays, changeMode }: Props,
): JSX.Element {
    return (
        <>
            <div className={classes.universitySpecialDaysView__extraType}>
                <div className={classes.universitySpecialDaysView__title}>
                    Внеплановые рабочие дни университета:
                </div>
                <UniversitySpecialDaysList dates={extraDays.extraWorkdays} />
            </div>
            <div className={classes.universitySpecialDaysView__extraType}>
                <div className={classes.universitySpecialDaysView__title}>
                    Праздничные дни университета:
                </div>
                <UniversitySpecialDaysList dates={extraDays.holidays} />
            </div>
            <div className={classes.universitySpecialDaysView__buttonPanel}>
                <ActionButton onClick={changeMode}>
                    Редактировать
                </ActionButton>
            </div>
        </>
    );
}
