import React, { useState } from 'react';
import { format } from 'date-fns';

import {
    Table,
} from '@admin/Attendance/AttendanceIterfaces';
import { TableRow } from '@admin/Attendance/AttendanceTable/TableRow';
import { TableDates } from '@admin/Attendance/AttendanceTable/TableDates';

import { Accordion } from '@common/Accordion';
import { EmptyTable } from '@admin/Attendance/AttendanceTable/EmptyTable';
import cn from 'classnames';
import classes from './AttendanceTable.module.scss';

interface Props {
    table: Table;
}

export function AttendanceTable(
    {
        table,
    }: Props,
) {
    const [isAccordionOpen, setAccordionOpen] = useState(true);

    const tableWaveIndex = table.wave.index ? table.wave.index + 1 : 1;

    const tableDates = table.columns.map((column, index) => {
        const currentMeetingMonth = format(new Date(column.meetingInstance.startDate), 'M');
        const previousMeetingMonth = format(new Date(table.columns[index - 1]?.meetingInstance?.startDate ?? new Date(column.meetingInstance.startDate)), 'M');

        const isMeetingNewMonth = currentMeetingMonth !== previousMeetingMonth;
        let shouldAdditionalBeRight = index + 1 > table.columns.length - 10;

        if (table.columns.length < 20) {
            shouldAdditionalBeRight = index + 1 > table.columns.length - 8;
        }

        if (table.columns.length < 15) {
            shouldAdditionalBeRight = false;
        }

        return (
            <TableDates
                key={column.columnId}
                order={index + 1}
                isAccordionOpen={isAccordionOpen}
                shouldAdditionalBeRight={shouldAdditionalBeRight}
                isNewMonth={isMeetingNewMonth}
                tableColumn={column}
            />
        );
    });

    const tableRows = table.rows.map((row, index) => {
        const shouldAdditionalBeTop = table.rows.length === index + 1
            || table.rows.length === index + 2 || table.rows.length === index + 3;

        return (
            <TableRow
                key={row.rowId}
                isFirstRow={index === 0}
                isLastRow={index + 1 === table.rows.length}
                shouldAdditionalBeTop={shouldAdditionalBeTop}
                tableColumns={table.columns}
                tableRow={row}
            />
        );
    });

    return (
        <div className={classes.tables}>

            <div
                className={cn(classes.table__container, {
                    [classes.table__container_noBorder]: !isAccordionOpen,
                })}
            >

                <Accordion
                    isDefaultOpen
                    headerClassnames={classes.header}
                    shouldClickOnHeaderOpen={false}
                    additionalSetState={() => setAccordionOpen(!isAccordionOpen)}
                >

                    <div className={classes.table__dates_container}>

                        <div className={classes.table__wave}>
                            { tableWaveIndex } поток
                        </div>

                        <div className={classes.table__empty} />

                        <div className={classes.table__dates}>

                            {
                                tableDates
                            }

                        </div>

                    </div>

                    <div className={classes.table}>

                        <div className={classes.table__rows}>

                            {
                                tableRows
                            }

                        </div>

                    </div>

                </Accordion>

            </div>

            <EmptyTable
                isTableVisible={isAccordionOpen}
                table={table}
            />

        </div>

    );
}

export function getTablesColumnsWithCurrentColumnChangedBackground(
    meetingInstanceId: string,
    tableId: string,
    background: string,
    tables: Table[],
) {
    return tables.map((table) => {
        if (table.id !== tableId) return table;

        return {
            ...table,
            columns: table.columns.map((column) => {
                if (column.meetingInstance.id !== meetingInstanceId) return column;

                return {
                    ...column,
                    background,
                };
            }),
        };
    });
}
