import React, { useState } from 'react';

import { GraphOptions } from './GraphOptions';
import { GraphView } from './GraphView';

interface Options {
    layout: string;
    orientation: string;
    height: number | null;
    eventType: boolean;
}

const LAYOUT_HEIGHT = 750;

export function GraphContainer(): JSX.Element {
    const [options, setOptions] = useState<Options>({
        layout: 'dagre',
        orientation: 'TD',
        height: null,
        eventType: false,
    });
    function changeGraphLayout(layout: string) {
        let height = null;
        if (options.orientation === 'LR') height = LAYOUT_HEIGHT;
        if (layout !== 'dagre') {
            setOptions({
                ...options,
                layout,
                height: LAYOUT_HEIGHT,
            });
        } else {
            setOptions({
                ...options,
                layout,
                height,
            });
        }
    }
    function changeGraphOrientation(orientation: string) {
        let height = null;
        if (orientation === 'LR' || options.layout !== 'dagre') height = LAYOUT_HEIGHT;
        setOptions({
            ...options,
            orientation,
            height,
        });
    }

    function toggleEventTypeButton() {
        setOptions({
            ...options,
            eventType: !options.eventType,
        });
    }

    return (
        <div style={{ position: 'relative', marginLeft: '182px', width: '606px' }}>
            <GraphOptions
                changeGraphLayout={changeGraphLayout}
                changeGraphOrientation={changeGraphOrientation}
                toggleEventTypeButton={toggleEventTypeButton}
            />
            <GraphView
                options={options}
            />
        </div>
    );
}
