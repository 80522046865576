import React, { FunctionComponent, useState } from 'react';
import { FetchResult } from '@apollo/client';
import { cloneDeep } from 'lodash';
import { nanoid } from 'nanoid';
import { ActionButton } from '@common/ActionButton';
import { DateSelector } from './DateSelector';
import { ExtraDays, ExtraWorkday, Holiday } from './UniversitySpecialDaysInterfaces';
import { UniversitySpecialDaysList } from './UniversitySpecialDaysList';
import classes from './UniversitySpecialDaysEdit.module.scss';

type ApolloPromise = Promise<void | FetchResult<any, Record<string, any>, Record<string, any>>>;

interface UniversitySpecialDaysEditProps {
    extraDays: ExtraDays,
    update(data:ExtraDays):ApolloPromise,
    changeMode(): void,
}

type GetElementIndexFunction = {
    (
        array: any[],
        targetElement: any,
    ): number;
};
type DeleteHandlerFunction = {
    (
        date: ExtraWorkday|Holiday,
    ): void;
};
type DeleteDateFromStateCallback = {
    (prevState:ExtraDays): ExtraDays;
};
type DeleteDateFunction = {
    (
        position:number,
        property?: string,
    ): DeleteDateFromStateCallback;
};
type AddHandlerFunction = {
    (): void;
};
type AddDateFunction = {
    (
        date:Date,
        extraDaysType:string,
    ): AddDateToStateCallback;
};
type AddDateToStateCallback = {
    (prevState:ExtraDays): ExtraDays;
};

const WEEKDAYS = 'extraWorkdays';
const HOLIDAYS = 'holidays';

const getElementIndex: GetElementIndexFunction = (array, targetElement) => array.findIndex(
    (element) => element === targetElement,
);

const deleteDate: DeleteDateFunction = (position, property) => {
    const callBack: DeleteDateFromStateCallback = (
        oldExtraDays,
    ) => {
        const oldExtraDaysCopy = cloneDeep(oldExtraDays);

        if (property) {
            oldExtraDaysCopy[property].splice(position, 1);
        }

        return oldExtraDaysCopy;
    };

    return callBack;
};

const addDate: AddDateFunction = (date, extraDaysType) => {
    const callBack: AddDateToStateCallback = (
        oldExtraDays,
    ) => {
        const oldExtraDaysCopy = cloneDeep(oldExtraDays);
        let finalDate = `${date.getFullYear()}-`;

        finalDate += `${date.getMonth() + 1 < 10 ? '0' : ''}${date.getMonth() + 1}-`;
        finalDate += `${date.getDate() < 10 ? '0' : ''}${date.getDate()}`;
        oldExtraDaysCopy[extraDaysType].push({ id: nanoid(), date: finalDate });

        return oldExtraDaysCopy;
    };

    return callBack;
};

export const UniversitySpecialDaysEdit: FunctionComponent<UniversitySpecialDaysEditProps> = (
    { extraDays, update: updateMutation, changeMode }: UniversitySpecialDaysEditProps,
) => {
    const [currentHoliday, setCurrentHoliday] = useState<(Date|null)>(null);
    const [currentWeekday, setCurrentWeekday] = useState<Date|null>(null);
    const [extraDaysCopy, setExtraDaysCopy] = useState({ ...extraDays });
    const handleHolidayChange = (newDate: Date) => {
        setCurrentHoliday(newDate);
    };
    const handleWeekdayChange = (newDate: Date) => {
        setCurrentWeekday(newDate);
    };
    const deleteHandler: DeleteHandlerFunction = (date) => {
        let position = getElementIndex(extraDaysCopy[WEEKDAYS], date);

        if (position >= 0) {
            setExtraDaysCopy(deleteDate(position, WEEKDAYS));
        }

        position = getElementIndex(extraDaysCopy[HOLIDAYS], date);

        if (position >= 0) {
            setExtraDaysCopy(deleteDate(position, HOLIDAYS));
        }
    };
    const addWeekdayHandler: AddHandlerFunction = () => {
        if (currentWeekday) {
            setExtraDaysCopy(addDate(currentWeekday, WEEKDAYS));
        }

        setCurrentWeekday(null);
    };
    const addHolidayHandler: AddHandlerFunction = () => {
        if (currentHoliday) {
            setExtraDaysCopy(addDate(currentHoliday, HOLIDAYS));
        }

        setCurrentHoliday(null);
    };
    const update = (data:ExtraDays) => updateMutation(data);

    return (
        <>
            <div className={classes.universitySpecialDaysEdit}>
                <div className={classes.universitySpecialDaysEdit__extraType}>
                    <div className={classes.universitySpecialDaysEdit__title}>
                        Внеплановые рабочие дни университета:
                    </div>
                    <DateSelector
                        onChange={handleWeekdayChange}
                        addHandler={addWeekdayHandler}
                        currentDate={currentWeekday}
                    />

                    <UniversitySpecialDaysList
                        dates={extraDaysCopy.extraWorkdays}
                        deleteHandler={deleteHandler}
                    />
                </div>
                <div className={classes.universitySpecialDaysEdit__extraType}>
                    <div className={classes.universitySpecialDaysEdit__title}>
                        Праздничные дни университета:
                    </div>
                    <DateSelector
                        onChange={handleHolidayChange}
                        addHandler={addHolidayHandler}
                        currentDate={currentHoliday}
                    />
                    <UniversitySpecialDaysList
                        dates={extraDaysCopy.holidays}
                        deleteHandler={deleteHandler}
                    />
                </div>
            </div>
            <div className={classes.universitySpecialDaysEdit__buttonPanel}>
                {/* <Button text="Отменить" onClick={changeMode} />
                <Button
                    className={classes.universitySpecialDaysEdit__saveButton}
                    text="Сохранить"
                    onClick={() => update(extraDaysCopy)}
                /> */}
                <ActionButton onClick={changeMode}>
                    Отменить
                </ActionButton>
                <ActionButton
                    onClick={() => update(extraDaysCopy)}
                    className={classes.universitySpecialDaysEdit__saveButton}
                >
                    Сохранить
                </ActionButton>
            </div>
        </>
    );
};
