import React, { useState } from 'react';
import { ApolloError } from '@apollo/client';

import { Loader } from '@common/Loader';
import { Alert } from '@common/Alert';

import { Occupation, OccupationParams } from './OccupationDashboardInterfaces';
import classes from './OccupationDashboard.module.scss';
import { ModulesList } from './ModulesList';
import { Filters } from './Filters';
import { TimeSlotsList } from './TimeSlotsList';
import { Legend } from './Legend';

interface Props {
    occupation?: Occupation | null;
    getOccupation(params: OccupationParams): void;
    loading?: boolean;
    occupationError?: ApolloError;
}

export const OccupationDashboard = (
    {
        occupation,
        getOccupation,
        loading,
        occupationError,
    }: Props,
): JSX.Element => {
    const [
        isTUniversityMeetingsVisible,
        setTUniversityMeetingsVisibility,
    ] = useState<boolean>(false);

    return (
        <div className={classes.occupationDashboard}>
            <Filters
                getOccupation={getOccupation}
                isTUniversityMeetingsVisible={isTUniversityMeetingsVisible}
                setTUniversityMeetingsVisibility={setTUniversityMeetingsVisibility}
            />
            {loading
                ? <Loader />
                : occupation && (
                    <div className={classes.occupationDashboard__table}>
                        <div className={classes.occupationDashboard__modules}>
                            <Legend />
                            <ModulesList
                                occupation={occupation}
                                isTUniversityMeetingsVisible={isTUniversityMeetingsVisible}
                            />
                        </div>
                        <TimeSlotsList
                            occupationDashboardRows={occupation?.occupationDashboardRows}
                            isTUniversityMeetingsVisible={isTUniversityMeetingsVisible}
                        />
                    </div>
                )
            }
            {occupation === null && (
                <Alert
                    message="Нет данных за выбранный период"
                    time={3000}
                />
            )}
            {occupationError && (
                <Alert
                    message={occupationError?.graphQLErrors?.[0].message
                        || occupationError?.message}
                    time={3000}
                />
            )}
        </div>
    );
};
