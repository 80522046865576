import React from 'react';

import { GetTeacher_teacher_baseTimeIntervalInstancesModules } from '../../TeacherAssignmentsApolloTypes';
import { TeacherAssignmentsPeriods } from '../TeacherAssignmentsPeriods/TeacherAssignmentsPeriods';
import { TeacherAssignmentsSpaces } from '../TeacherAssignmentsSpaces/TeacherAssignmentsSpaces';

interface Props {
    btiTabs: GetTeacher_teacher_baseTimeIntervalInstancesModules[];
    spaces: {
        id: string;
        name: string;
    }[];
    activeSpaceId?: string;
    activeBTIId?: string;
}

export const TeacherAssignmentsTabs = ({
    btiTabs,
    spaces,
    activeSpaceId,
    activeBTIId,
}: Props) => (
    <>
        <TeacherAssignmentsSpaces
            spaces={spaces}
            activeSpaceId={activeSpaceId}
        />

        <TeacherAssignmentsPeriods
            activeBTIId={activeBTIId}
            btiTabs={btiTabs}
        />
    </>
);
