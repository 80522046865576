import React from 'react';

import EventItem from '../EventItem/EventItem';
import {
    EventType,
} from '../Config/interfaces';

import classes from './EventsList.module.scss';

interface Props {
    sortedEvents: (EventType)[];
}

export function EventList({
    sortedEvents,
}: Props): JSX.Element {
    return (
        <div className={classes.eventList}>
            {sortedEvents.map((event: EventType) => (
                <div key={event.id}>
                    <EventItem
                        event={event}
                    />
                </div>
            ))}
        </div>
    );
}
