import { gql } from '@apollo/client';

export const EDIT_STATUS = gql`
    mutation updateUsersRoleTypes($data: UpdateUsersRoleTypesInput!) {
        updateUsersRoleTypes(updateUsersRoleTypesInput: $data) {
            id
        }
    }
`;

export const GET_SKILLS = gql`
    query getSkills($options: SkillListInput!) {
        skills(options: $options) {
            id
            fullName
            maxLevel
        }
    }
`;

export const SET_STUDENTS_SKILLS = gql`
    mutation setStudentsSkills($data: SetStudentsSkillsInput!) {
        setStudentsSkills(setStudentsSkillsInput: $data) {
            id
        }
    }
`;

export const DELETE_STUDENTS_SKILLS = gql`
    mutation deleteStudentsSkills($data: DeleteStudentsSkillsInput!) {
        deleteStudentsSkills(deleteStudentsSkillsInput: $data) {
            status
        }
    }
`;

export const ADD_USERS_TYPES = gql`
    mutation addUsersTypes($addType: AddUserTypeInput!) {
        addUsersTypes(addUserType: $addType) {
            id
            roleId
        }
    }
`;

export const IMPORT_USERS = gql`
    mutation importUsers {
        importUsers
    }
`;

export const UPDATE_STUDENTS_BTI = gql`
    mutation updateStudentsBTI($input: UpdateStudentsBaseTimeIntervalInstanceInput!) {
        updateStudentsBaseTimeIntervalInstance(updateStudentsBaseTimeIntervalInstanceInput: $input) {
            id
            space {
                id
                name
            }
            activeBaseTimeIntervalInstance {
                baseTimeIntervalInstance {
                    id
                    spaceBaseTimeInterval {
                        order
                        space {
                            id
                            name
                            baseTimeIntervalType{
                                name
                            }
                        }
                    }
                }
            }
            skills {
                level
                skill{
                    fullName
                }
            }
            user {
                id
            }
        }
    }
`;

export const GET_BASE_TIME_INTERVAL_INSTANCES = gql`
    query baseTimeIntervalInstances {
        baseTimeIntervalInstances {
            id
            startDate
            endDate
            spaceEducationPeriodId
            spaceBaseTimeInterval {
                order
                space {
                    id
                    name
                    baseTimeIntervalType{
                        name
                    }
                }
            }
        }
    }
`;

export const LOAD_STUDENTS_RATING_CSV = gql`
query exportStudentsRatingCsv($exportStudentsRatingInput: ExportStudentsRatingInput!){
    exportStudentsRatingCsv(exportStudentsRatingInput: $exportStudentsRatingInput)
}
`;

export const UPDATE_STUDENTS_RATING_CSV = gql`
mutation importStudentsRating($importStudentsRatingInput: ImportStudentsRatingInput!){
    importStudentsRating(importStudentsRatingInput: $importStudentsRatingInput) {
        value
    }
}
`;

export const LOAD_USER_CSV = gql`
query generateUserCreationCsv{
    generateUserCreationCsv
}
`;

export const IMPORT_STUDENTS_CSV = gql`
mutation importStudents($importStudentsInput: ImportStudentsInput!) {
    importStudents(importStudentsInput: $importStudentsInput) {
        id
    }
}
`;

export const IMPORT_USERS_CSV = gql`
    mutation ImportUsers(
        $usersCsvInput: String!
    ) {
        importUsers(
            usersCsvInput:$usersCsvInput
        ) {
            id
        }
    }
`;
