import React from 'react';
import { Link } from 'react-router-dom';
import cn from 'classnames';

import {
    MeetingInstanceAttendance,
    TableColumn,
    TableRow as ITableRow,
} from '@admin/Attendance/AttendanceIterfaces';
import { TableRowCell } from '@admin/Attendance/AttendanceTable/TableRow/TableRowCell';
import { MissingMeetingRowCell } from '@admin/Attendance/AttendanceTable/TableRow/EmptyTableRowCell';
import { observer } from 'mobx-react';
import { attendanceStore } from '@admin/Attendance/AttendanceStore';
import { NavIcon } from '@admin/Attendance/AttendanceTable/TableRow/NavIcon';

import classes from './TableRow.module.scss';
import cellClasses from './TableRowCell/TableRowCell.module.scss';

interface Props {
    isLastRow: boolean;
    isFirstRow: boolean;
    shouldAdditionalBeTop: boolean;
    tableRow: ITableRow;
    tableColumns: TableColumn[];
}

export const TableRow = observer((
    {
        isLastRow,
        isFirstRow,
        shouldAdditionalBeTop,
        tableRow,
        tableColumns,
    }: Props,
): JSX.Element => {
    const { student } = tableRow.studentWithAbsencesCount;

    const userProfileNavLink = `/users/user/${student.user.id}`;

    const studentInfo = `${student.user.lastName} ${student.user.firstName} ${student.user.patronymic ?? ''}`;

    const tableCells = tableColumns.map((column, index) => {
        const currentRowMeeting = getCurrentRowMeeting(
            column.meetingInstance.id,
            tableRow.meetingInstancesAttendance,
        );

        let shouldAdditionalBeRight = index + 1 > (tableColumns.length - 10);

        if (tableColumns.length < 20) {
            shouldAdditionalBeRight = index + 1 > tableColumns.length - 8;
        }

        if (tableColumns.length < 15) {
            shouldAdditionalBeRight = false;
        }

        if (!currentRowMeeting) {
            return (
                <MissingMeetingRowCell
                    key={`${column.meetingInstance.id}${student.user.studentId}`}
                    isLastRow={isLastRow}
                    shouldAdditionalBeTop={shouldAdditionalBeTop}
                    shouldAdditionalBeRight={shouldAdditionalBeRight}
                    student={student}
                />
            );
        }

        return (
            <TableRowCell
                key={`${column.meetingInstance.id}${student.user.studentId}`}
                isLastRow={isLastRow}
                isFirstRow={isFirstRow}
                order={index + 1}
                shouldAdditionalBeTop={shouldAdditionalBeTop}
                student={student}
                shouldAdditionalBeRight={shouldAdditionalBeRight}
                meetingInstanceAttendance={currentRowMeeting}
                currentColumn={column}
            />
        );
    });

    return (
        <div className={cn(classes.row, cellClasses.cellRow)}>

            <div
                className={cn(classes.row__student, {
                    [classes.row__student_active]:
                    attendanceStore.activeTableRowId === tableRow.rowId,
                })}
            >
                <div className={classes.row__student_icon}>

                    <Link to={userProfileNavLink} target="_blank">
                        <NavIcon />
                    </Link>

                </div>

                <Link className={classes.row__student_link} to={userProfileNavLink} target="_blank">

                    <div
                        className={cn(classes.row__student_name, {
                            [classes.borderBottom_none]: isLastRow,
                        })}
                    >

                        {
                            studentInfo
                        }

                    </div>

                </Link>

            </div>

            <div
                className={cn(classes.row__absences, {
                    [classes.row__absences_active]:
                    attendanceStore.activeTableRowId === tableRow.rowId,
                    [classes.borderBottom_none]: isLastRow,
                })}
            >
                {
                    tableRow.studentWithAbsencesCount.absencesCount
                }
            </div>

            <div className={classes.row__attendance}>
                { tableCells }
            </div>

        </div>
    );
});

// function getCurrentTableColumn(
//     meetingInstanceId: string,
//     tableColumns: TableColumn[],
// ) {
//     return tableColumns.find(
//         (tableColumn) => tableColumn.meetingInstance.id === meetingInstanceId,
//     );
// }

function getCurrentRowMeeting(
    meetingInstanceId: string,
    meetingInstancesAttendances: MeetingInstanceAttendance[],
) {
    return meetingInstancesAttendances.find(
        (meeting) => meeting.meetingInstanceId === meetingInstanceId,
    );
}
