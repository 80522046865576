import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { sortBy } from 'lodash';

import { CrossIcon } from '@common/svg';
import store from '../../module-assessment-config-store';
import classes from './ModuleAssessment.module.scss';
import { AnswerType, StudentAssessmentStatus } from '../../graphql-query-types';
import { OpenEndedQuestion } from './OpenEndedQuestion';
import { ClosedTypeQuestionTable, MobileClosedTypeQuestionTable } from './ClosedTypeQuestionTable';
import { Answer, TeacherMeetingInstance, TeacherAssessment, TeacherAssessmentError } from './interface';

interface TeacherAssessmentPops {
    teacherMeetingInstance: TeacherMeetingInstance;
    moduleAssessmentStatus?: StudentAssessmentStatus;
    teacherAssessment?: TeacherAssessment | null;
    teacherAssessmentErrors?: TeacherAssessmentError;
    onTeacherAssessmentChange(teacherId: string, answer: Answer): void;
}

export const StudentTeacherAssessment = observer(({
    teacherMeetingInstance: { teacher: { id, user }, meetingInstances },
    moduleAssessmentStatus,
    teacherAssessment,
    teacherAssessmentErrors,
    onTeacherAssessmentChange,
}: TeacherAssessmentPops) => {
    const {
        teacherClosedTypeAssessmentQuestions,
        teacherOpenEndedAssessmentQuestions,
    } = store;
    const [isPopupOpened, setIsPopupOpened] = useState<boolean>(false);

    function onChange(answer: Answer) {
        onTeacherAssessmentChange(id, answer);
    }

    const sortedMeetingInstances = sortBy(meetingInstances, 'startDate');
    return (
        <>
            <div className={classes['module-assessment-tab__teacher']}>
                {user.lastName} {user.firstName} {user.patronymic}
            </div>
            <div
                className={classes['module-assessment-tab__teacher-meetings']}
                onClick={() => setIsPopupOpened(!isPopupOpened)}
            >
                Просмотр списка встреч с этим преподавателем {'->'}
            </div>
            {teacherClosedTypeAssessmentQuestions && (
                <ClosedTypeQuestionTable
                    assessmentAnswers={teacherAssessment?.answers}
                    assessmentStatus={moduleAssessmentStatus}
                    onChange={onChange}
                    assessmentQuestions={teacherClosedTypeAssessmentQuestions}
                    errors={teacherAssessmentErrors?.errors}
                />
            )}
            {teacherClosedTypeAssessmentQuestions && (
                <MobileClosedTypeQuestionTable
                    assessmentAnswers={teacherAssessment?.answers}
                    assessmentStatus={moduleAssessmentStatus}
                    onChange={onChange}
                    assessmentQuestions={teacherClosedTypeAssessmentQuestions}
                    errors={teacherAssessmentErrors?.errors}
                />
            )}
            {teacherOpenEndedAssessmentQuestions?.map(question => {
                const answer = teacherAssessment?.answers?.find(
                    ({ questionId }) => question.id === questionId,
                );
                const error = teacherAssessmentErrors?.errors?.find(
                    ({ questionId }) => question.id === questionId,
                );
                return (
                    <OpenEndedQuestion
                        key={question.id}
                        question={question}
                        answer={answer?.value}
                        assessmentStatus={moduleAssessmentStatus}
                        onChange={onChange}
                        error={error}
                    />
                );
            })}
            {isPopupOpened && (
                <div
                    className={classes['module-assessment-tab__popup-container']}
                    onClick={() => setIsPopupOpened(!isPopupOpened)}
                >
                    <div
                        className={classes['module-assessment-popup']}
                        onClick={e => e.stopPropagation()}
                    >
                        <div className={classes['module-assessment-popup__title']}>
                            Список встреч с преподавателем:{' '}
                            <span>
                                {user.lastName} {user.firstName} {user.patronymic}
                            </span>
                        </div>
                        <div className={classes['module-assessment-popup__meeting-list']}>
                            {sortedMeetingInstances.map(({ id: instanceId, meeting }, index) => (
                                <div
                                    className={classes['module-assessment-popup__meeting']}
                                    key={instanceId}
                                >
                                    Встреча {index + 1}: {meeting.topic}
                                </div>
                            ))}
                        </div>
                        <CrossIcon
                            className={classes['module-assessment-popup__close-icon']}
                            handler={() => setIsPopupOpened(!isPopupOpened)}
                        />
                    </div>
                </div>
            )}
            {teacherAssessmentErrors && (
                <div className={classes['module-assessment-tab__errors-container']}>
                    {teacherAssessmentErrors.message && (
                        <div className={classes['module-assessment-tab__error']}>
                            <span className={classes['module-assessment-tab__error-message']}>
                                {teacherAssessmentErrors.message}
                            </span>
                        </div>
                    )}
                    {teacherAssessmentErrors?.errors?.map(({ questionId, message }) => {
                        const question = store.getAssessmentQuestion(questionId);
                        return question && (
                            <div
                                key={questionId}
                                className={classes['module-assessment-tab__error']}
                            >
                                {question.answerType === AnswerType.option && (
                                    <>
                                        <span>
                                            Критерий
                                        </span> «{question.title}»:{' '}
                                    </>
                                )}
                                <span className={classes['module-assessment-tab__error-message']}>
                                    {message}
                                </span>
                            </div>
                        );
                    })}
                </div>
            )}
        </>
    );
});
