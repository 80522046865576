import React from 'react';
import cn from 'classnames';

import classes from './Tabs.module.scss';

type Primitive = string | number;
interface Props<T extends Primitive> {
    tabs: T[],
    activeTab: T,
    setActiveTab: React.Dispatch<React.SetStateAction<T>>,
}

export function Tabs<T extends Primitive>({
    tabs,
    activeTab,
    setActiveTab,
}: Props<T>): JSX.Element {
    return (
        <div className={classes.tabs}>
            <ul className={classes.tabs__list}>
                {tabs.map(tab => (
                    <li
                        key={tab}
                        className={classes.tabs__item}
                    >
                        <div
                            onClick={() => setActiveTab(tab)}
                            className={
                                cn(classes.tabs__tab, {
                                    [classes.tabs__tab_selected]:
                                    tab === activeTab,
                                })
                            }
                        >
                            {tab}
                        </div>
                    </li>
                ))}
            </ul>
        </div>
    );
}
