import { makeAutoObservable } from 'mobx';

import { BaseTimeIntervalInstanceModel, BaseTimeIntervalInstanceParams } from './BaseTimeIntervalInstanceModel';

import { Durations } from '../types';

export class ScheduleGenerationStore {
    public baseTimeIntervalInstance?: BaseTimeIntervalInstanceModel;

    public durationList: Durations[] = [];

    public studentLoadStore: number = 0;

    constructor() {
        makeAutoObservable(this);
    }

    setBaseTimeIntervalInstance(params?: BaseTimeIntervalInstanceParams): void {
        if (params) {
            this.baseTimeIntervalInstance = new BaseTimeIntervalInstanceModel(params);
        }
    }

    setDurationList(list: Durations[] | undefined): void {
        if (list) this.durationList = list;
    }

    setStudentLoadStore(): void {
        this.studentLoadStore += 1;
    }
}

export const scheduleGenerationStore = new ScheduleGenerationStore();
