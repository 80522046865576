import React from 'react';

import classes from './ModuleMainCharactersParams.module.scss';

import {
    Module as ModuleType,
} from '../../subSpaceTypes';

interface Props {
    currentModule: ModuleType;
}

export function ModuleMainCharactersParams({ currentModule }: Props):JSX.Element {
    const { minStudentCount, maxStudentCount, maxWaveCount } = currentModule;
    return (
        <div className={classes.moduleMainCharactersParams}>
            <ul className={classes.moduleMainCharactersParams__numberList}>
                <li className={classes.moduleMainCharactersParams__numberItem}>
                    {minStudentCount !== maxStudentCount
                        ? `${minStudentCount}-${maxStudentCount}`
                        : `${minStudentCount}`
                    }
                </li>
                <li className={classes.moduleMainCharactersParams__numberItem}>
                    {maxWaveCount}
                </li>
                <li className={classes.moduleMainCharactersParams__numberItem}>
                    {Math.round(currentModule.creditCount)}
                </li>
            </ul>
            <ul className={classes.moduleMainCharactersParams__textList}>
                <li className={classes.moduleMainCharactersParams__textItem}>
                    Количество студентов
                </li>
                <li className={classes.moduleMainCharactersParams__textItem}>
                    Потоков модуля в семестр
                </li>
                <li className={classes.moduleMainCharactersParams__textItem}>
                    ЗЕТ
                </li>
            </ul>
        </div>
    );
}
