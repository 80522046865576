import React from 'react';

export function DeleteIcon() {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="3" y="3" width="18" height="19" rx="2" fill="#F8F9FE" />
            <path fillRule="evenodd" clipRule="evenodd" d="M16.2426 7.75729C15.8521 7.36676 15.219 7.36676 14.8284 7.75729L12 10.5857L9.17157 7.75729C8.78105 7.36676 8.14788 7.36676 7.75736 7.75729C7.36684 8.14781 7.36684 8.78098 7.75736 9.1715L10.5858 11.9999L7.75736 14.8284C7.36684 15.2189 7.36684 15.852 7.75736 16.2426C8.14788 16.6331 8.78105 16.6331 9.17157 16.2426L12 13.4141L14.8284 16.2426C15.219 16.6331 15.8521 16.6331 16.2426 16.2426C16.6332 15.852 16.6332 15.2189 16.2426 14.8284L13.4142 11.9999L16.2426 9.1715C16.6332 8.78098 16.6332 8.14781 16.2426 7.75729Z" fill="#D9D9D9" />
        </svg>
    );
}
