import { useEffect } from 'react';

export const useResetEscapeMouseClick = (
    ref: React.RefObject<HTMLDivElement>,
    update: (closeData: any) => void,
    closeData: any,
) => {
    useEffect(() => {
        const handleEscClose = (event: KeyboardEvent) => {
            if (event.key === 'Escape') {
                update(closeData);
            }
        };
        document.addEventListener('keydown', handleEscClose);
        return () => {
            document.removeEventListener('keydown', handleEscClose);
        };
    }, []);
    useEffect(() => {
        const handleClickOutside = (event: any) => {
            if (ref.current && !ref.current.contains(event.target)) {
                update(closeData);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [ref]);
};
