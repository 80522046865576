import React, { useEffect, useState } from 'react';
import { nanoid } from 'nanoid';

import {
    SpaceBaseTimeInterval,
    SpaceBaseTimeIntervalParameters,
} from '@admin/EducationPeriodPage/EducationPeriodPageInterfaces';
import {
    AccordionItem,
} from '@admin/EducationPeriodPage/EducationPeriodDates/EducationPeriodDatesEdit/EducationPeriodDatesAccordion/AccordionItems/AccordionItem';
import { educationPeriodStore } from '@admin/EducationPeriodPage/EducationPeriodStore';

import classes from './AccordionItems.module.scss';

interface Props {
    name: string;
    parameters?: SpaceBaseTimeIntervalParameters;
    baseTimeInterval: SpaceBaseTimeInterval;
}

const initialValues: SpaceBaseTimeIntervalParameters = {
    baseTimeIntervalInstanceId: nanoid(21),
    spaceBaseTimeIntervalId: '',
    moduleSelectionStartDate: '',
    moduleSelectionEndDate: '',
    subspaceSelectionStartDate: '',
    subspaceSelectionEndDate: '',
    spaceBaseTimeIntervalStart: '',
    spaceBaseTimeIntervalEnd: '',
    moduleAssessment: {
        id: '',
        isDynamicStartDate: false,
        startDate: '',
        endDate: '',
    },
};

enum BtiParametersNames {
    baseTimeInterval = 'Выбор семестра',
    subSpaceSelection = 'Выбор подпространств',
    moduleSelection = 'Выбор модулей',
    moduleAssessment = 'Студенческая оценка преподавателей',
}

export interface ParametersTransform {
    name: string;
    btiParameterName: BtiParametersNames;
    start?: string;
    end?: string;
    isDynamic?: boolean;
    isModuleActive?: boolean;
}

export function AccordionItems(
    {
        name,
        parameters,
        baseTimeInterval,
    }
    : Props,
) {
    const [btiParametersTransformed, setBtiParametersTransformed] = useState<
    ParametersTransform[]
    >(getParametersArray(parameters ?? initialValues, baseTimeInterval, name));

    const [btiParameters, setBtiParameters] = useState<SpaceBaseTimeIntervalParameters>(
        parameters ?? initialValues,
    );

    const [isModuleAssessDynamicStart, setModuleAssessDynamicStart] = useState(
        !!btiParameters.moduleAssessment?.isDynamicStartDate,
    );

    useEffect(() => {
        setBtiParameters({
            ...btiParameters,
            ...getParametersCopy(
                baseTimeInterval.id,
                isModuleAssessDynamicStart,
                btiParametersTransformed,
                btiParameters,
            ),
        });
    }, [btiParametersTransformed]);

    useEffect(() => {
        if (!checkIfEmptyParameters(btiParameters)) {
            educationPeriodStore.removeCurrentSpaceBaseTimeIntervalParameters(
                btiParameters.spaceBaseTimeIntervalId,
            );
            educationPeriodStore.setUpdateSpaceBaseTimeIntervalParameters(btiParameters);
        }
    }, [btiParameters]);

    return (
        <div className={classes.items}>

            {
                btiParametersTransformed.map((parameter, index) => (
                    <AccordionItem
                        key={JSON.stringify(`${parameter.end}${baseTimeInterval.id}${index}`)}
                        name={parameter.name}
                        dateStart={parameter.start}
                        dateEnd={parameter.end}
                        parametersArray={btiParametersTransformed}
                        isModuleAssessDynamicStart={isModuleAssessDynamicStart}
                        setModuleAssessDynamicStart={setModuleAssessDynamicStart}
                        setBtiParameters={setBtiParametersTransformed}
                    />
                ))
            }

        </div>
    );
}

function checkIfEmptyParameters(
    parameters: SpaceBaseTimeIntervalParameters,
) {
    let parametersValues = '';
    Object.entries(parameters).forEach(([key, value]) => {
        if (key !== 'baseTimeIntervalInstanceId' && key !== 'spaceBaseTimeIntervalId' && key !== 'moduleAssessment') {
            parametersValues += value;
        }
    });
    return parametersValues === '';
}

function getParametersArray(
    parameters: SpaceBaseTimeIntervalParameters,
    interval: SpaceBaseTimeInterval,
    name: string,
) {
    const parametersArray: ParametersTransform[] = [
        {
            start: parameters.spaceBaseTimeIntervalStart,
            end: parameters.spaceBaseTimeIntervalEnd,
            name: `${interval.order} ${name}`,
            btiParameterName: BtiParametersNames.baseTimeInterval,
        },
        {
            start: parameters.subspaceSelectionStartDate,
            end: parameters.subspaceSelectionEndDate,
            name: 'Выбор подпространств',
            btiParameterName: BtiParametersNames.subSpaceSelection,
        },
        {
            start: parameters.moduleSelectionStartDate,
            end: parameters.moduleSelectionEndDate,
            name: 'Выбор модулей',
            btiParameterName: BtiParametersNames.moduleSelection,
        },
        {
            start: parameters.moduleAssessment?.startDate,
            end: parameters.moduleAssessment?.endDate,
            isDynamic: parameters.moduleAssessment?.isDynamicStartDate,
            isModuleActive: !!(
                (parameters.moduleAssessment?.startDate
                    || parameters.moduleAssessment?.isDynamicStartDate)
                && parameters.moduleAssessment.endDate),
            name: 'Студенческая оценка преподавателей',
            btiParameterName: BtiParametersNames.moduleAssessment,
        },
    ];

    return parametersArray;
}

function getParametersCopy(
    btiId: string,
    isDynamicModuleStart: boolean,
    newParameters: ParametersTransform[],
    oldParameters: SpaceBaseTimeIntervalParameters,
) {
    let parametersCopy: SpaceBaseTimeIntervalParameters = {
        ...oldParameters,
        spaceBaseTimeIntervalId: btiId,
    };
    newParameters.forEach((parameter) => {
        switch (parameter.btiParameterName) {
            case BtiParametersNames.baseTimeInterval:
                parametersCopy = {
                    ...parametersCopy,
                    spaceBaseTimeIntervalStart: parameter.start,
                    spaceBaseTimeIntervalEnd: parameter.end,
                };
                break;
            case BtiParametersNames.subSpaceSelection:
                parametersCopy = {
                    ...parametersCopy,
                    subspaceSelectionStartDate: parameter.start,
                    subspaceSelectionEndDate: parameter.end,
                };
                break;
            case BtiParametersNames.moduleSelection:
                parametersCopy = {
                    ...parametersCopy,
                    moduleSelectionStartDate: parameter.start,
                    moduleSelectionEndDate: parameter.end,
                };
                break;
            case BtiParametersNames.moduleAssessment:
                if (parameter.isModuleActive) {
                    parametersCopy = {
                        ...parametersCopy,
                        moduleAssessment: {
                            id: oldParameters.moduleAssessment?.id ?? nanoid(21),
                            startDate: isDynamicModuleStart ? undefined : parameter.start,
                            endDate: parameter.end,
                            isDynamicStartDate: isDynamicModuleStart,
                        },
                    };
                } else {
                    parametersCopy = {
                        ...parametersCopy,
                        moduleAssessment: undefined,
                    };
                }
                break;
            default:
                break;
        }
    });

    return parametersCopy;
}
