/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useRef, useEffect } from 'react';
import { ActionButtonRedesign, ActionTypeOfButton } from '@common/ActionButtonRedesign';

import classes from './AddEquipments.module.scss';

interface AddEquipmentsProps {
    addNewRegularEquipment: Function,
}

export function AddEquipmentsDropDown(
    {
        addNewRegularEquipment,
    }: AddEquipmentsProps,
): JSX.Element {
    const [showOptions, setShowOptions] = useState(false);
    const blockWithOptionsRef = useRef<HTMLDivElement>(null);

    useEffect(
        outsideClickEffect,
        [blockWithOptionsRef],
    );

    function outsideClickEffect() {
        document.addEventListener('mousedown', outsideBlockClickHandler);

        return () => document.removeEventListener('mousedown', outsideBlockClickHandler);
    }

    function outsideBlockClickHandler({ target }: MouseEvent) {
        if (
            target && ('nodeType' in target)
            && blockWithOptionsRef.current && !blockWithOptionsRef.current?.contains(target as Node)
            && showOptions
        ) {
            setShowOptions(false);
        }
    }
    return (
        <div className={classes.addEquipments}>
            <ActionButtonRedesign
                actionType={ActionTypeOfButton.GREY}
                onClick={() => addNewRegularEquipment()}
            >
                <div className={classes.addEquipments__button}>
                    <div>
                        <svg width="9" height="9" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M8.405 5.003H4.909V8.309H3.522V5.003H0.026V3.635H3.503V0.309999H4.909V3.635H8.405V5.003Z" fill="#0B42DC" />
                        </svg>
                    </div>
                    <div>
                        Добавить...
                    </div>
                </div>
            </ActionButtonRedesign>
            {
                showOptions
                && (
                    <div
                        ref={blockWithOptionsRef}
                        className={classes.addEquipments__optionsContainer}
                    >
                        <div
                            className={classes.addEquipments__option}
                            onClick={() => addNewRegularEquipment()}
                        >
                            Оборудование
                        </div>
                        {/* <div
                            className={classes.addEquipments__option}
                        >
                            Компьютеры/ Софт
                        </div> */}
                    </div>
                )
            }
        </div>
    );
}
