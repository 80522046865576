import React from 'react';

import classes from './PrivacyPolicyPage.module.scss';

export function PrivacyPolicyPage() {
    return (
        <div className={classes.privacyPolicyPage}>
            <h1>
                Политика ООО «Лаборатория индивидуальных траекторий»
                в отношении обработки персональных данных
            </h1>

            <h2>1. Общие положения</h2>

            <p>
                Политика обработки персональных данных (далее — «Политика» или «настоящая Политика»)
                разработана в соответствии с Федеральным законом от 27.07.2006. №152-ФЗ
                «О персональных данных» (далее — ФЗ-152).
                Настоящая Политика определяет порядок обработки персональных данных и меры
                по обеспечению безопасности персональных данных в Обществе с ограниченной
                ответственностью «Лаборатория индивидуальных траекторий»» ОГРН 1217700187110
                (далее — «Оператор», адрес интернет-сайта, платформы https://edhoc.ru)
                с целью защиты прав и свобод человека и гражданина при обработке
                его персональных данных, в том числе защиты прав на
                неприкосновенность частной жизни, личную и семейную тайну.
                В Политике используются следующие основные понятия:
            </p>

            <p>
                <b>автоматизированная обработка персональных данных</b>
                — обработка персональных данных с помощью средств вычислительной техники;
            </p>

            <p>
                <b>блокирование персональных данных</b>
                — временное прекращение обработки персональных данных
                (за исключением случаев,
                если обработка необходима для уточнения персональных данных);
            </p>

            <p>
                <b>информационная система персональных данных</b>
                — совокупность содержащихся в базах данных персональных данных
                и обеспечивающих их обработку информационных технологий и технических средств;
            </p>

            <p>
                <b>обезличивание персональных данных</b>
                — действия, в результате которых невозможно определить
                без использования дополнительной информации принадлежность
                персональных данных конкретному субъекту персональных данных;
            </p>

            <p>
                <b>обработка персональных данных</b>
                — любое действие (операция) или совокупность действий (операций),
                совершаемых с использованием средств автоматизации или без использования
                таких средств, с персональными данными, включая сбор, запись, систематизацию,
                накопление, хранение, уточнение (обновление, изменение), извлечение,
                использование, передачу (распространение, предоставление, доступ),
                обезличивание, блокирование, удаление, уничтожение персональных данных;
                персональные данные — любая информация, относящаяся к прямо или косвенно
                определенному или определяемому физическому лицу (субъекту персональных данных);
            </p>

            <p>
                <b>пользователь</b>
                – любой посетитель интернет-сайта, платформы https://edhoc.ru.
                Физические лица (субъекты), чьи персональные данные обрабатываются
                по поручению Оператора (переданы Оператору по договору
                с образовательными организациями в составе реестра);
            </p>

            <p>
                <b>предоставление персональных данных</b>
                — действия, направленные на раскрытие персональных данных
                определенному лицу или определенному кругу лиц;
            </p>

            <p>
                <b>распространение персональных данных</b>
                — действия, направленные на раскрытие персональных данных
                неопределенному кругу лиц (передача персональных данных)
                или на ознакомление с персональными данными неограниченного
                круга лиц, в том числе обнародование персональных данных
                в средствах массовой информации, размещение в
                информационно-телекоммуникационных сетях или предоставление
                доступа к персональным данным каким-либо иным способом;
            </p>

            <p>
                <b>трансграничная передача персональных данных</b>
                — передача персональных данных на территорию иностранного
                государства органу власти иностранного государства,
                иностранному физическому или иностранному юридическому лицу;
            </p>

            <p>
                <b>уничтожение персональных данных</b>
                — действия, в результате которых невозможно восстановить
                содержание персональных данных в информационной системе
                персональных данных и (или) результате которых уничтожаются
                материальные носители персональных данных;
            </p>

            <p>
                <b>Cookies</b>
                — небольшой фрагмент данных, отправленный веб-сервером
                и хранимый на компьютере субъекта персональных данных
                (пользователя), который веб-клиент или веб-браузер
                каждый раз пересылает веб-серверу в HTTP-запросе при
                попытке открыть страницу сайта, платформы;
            </p>

            <p>
                <b>IP-адрес</b>
                — уникальный сетевой адрес узла в компьютерной сети, построенной по протоколу IP.
            </p>

            <h2>2. Принципы и условия обработки персональных данных</h2>

            <h3>2.1. Состав персональных данных</h3>

            <p>
                Персональные данные, разрешенные к обработке
                в рамках настоящей Политики, предоставляются
                субъектом персональных данных путем заполнения
                регистрационной формы на сайте Оператора или
                переданы Оператору по договору с Образовательными
                организациями в составе реестра на платформе
                Оператора и включают в себя следующую информацию
                о субъекте персональных данных:
            </p>

            <ul>
                <li>— фамилию, имя, отчество;</li>

                <li>— контактный телефон;</li>

                <li>— адрес электронной почты;</li>

                <li>— год, месяц, дата рождения:</li>

                <li>
                    — а также иную персональную информацию,
                    которую субъект предоставляет о себе
                    самостоятельно в процессе пользования
                    сайтом, платформой Оператора.
                    Оператор защищает данные, которые
                    автоматически передаются в процессе
                    просмотра субъектом персональных данных
                    рекламных блоков, в том числе информацию
                    Cookies. Оператор осуществляет сбор
                    статистики об IP-адресах своих посетителей.
                    Данная информация используется с целью
                    выявления технических проблем.
                    Оператор не проверяет достоверность
                    персональных данных, предоставленных
                    субъектом, и не имеет возможности оценить
                    его дееспособность. Однако Оператор исходит
                    из того, что субъект персональных данных
                    предоставляет достоверные и достаточные
                    данные и поддерживает эту информацию в
                    актуальном состоянии.
                </li>
            </ul>

            <h3>2.2. Принципы обработки персональных данных</h3>

            <p>
                Обработка персональных данных у Оператора
                осуществляется на основе следующих принципов:
            </p>

            <ul>
                <li>
                    — законности и справедливой основы;
                </li>

                <li>
                    — ограничения обработки персональных
                    данных достижением конкретных, заранее определенных и законных целей;
                </li>

                <li>
                    — недопущения обработки персональных данных, несовместимой с целями
                    сбора персональных данных;
                </li>

                <li>
                    — недопущения объединения баз данных, содержащих персональные данные,
                    обработка которых осуществляется в целях, несовместимых между собой;
                </li>

                <li>
                    — обработки только тех персональных данных,
                    которые отвечают целям их обработки;
                </li>

                <li>
                    — соответствия содержания и объема обрабатываемых
                    персональных данных заявленным целям обработки;
                </li>

                <li>
                    — недопущения обработки персональных данных,
                    избыточных по отношению к заявленным целям их обработки;
                </li>

                <li>
                    — обеспечения точности, достаточности и
                    актуальности персональных данных по отношению
                    к целям обработки персональных данных;
                </li>

                <li>
                    — уничтожения либо обезличивания персональных
                    данных по достижении целей их обработки или в случае
                    утраты необходимости в достижении этих целей,
                    при невозможности устранения Оператором допущенных
                    нарушений персональных данных, если иное
                    не предусмотрено федеральным законом.
                </li>
            </ul>

            <h3>2.3. Условия обработки персональных данных</h3>

            <p>
                Оператор производит обработку персональных
                данных при наличии хотя бы одного из следующих условий:
            </p>

            <ul>
                <li>
                    — обработка персональных данных осуществляется с согласия
                    субъекта персональных данных на обработку его персональных данных;
                </li>

                <li>
                    — обработка персональных данных необходима для достижения целей,
                    предусмотренных международным договором Российской Федерации
                    или законом, для осуществления и выполнения возложенных
                    законодательством Российской Федерации на Оператора функций,
                    полномочий и обязанностей;
                </li>

                <li>
                    — обработка персональных данных необходима для осуществления
                    правосудия, исполнения судебного акта, акта другого органа или
                    должностного лица, подлежащих исполнению в соответствии с
                    законодательством Российской Федерации об исполнительном производстве;
                </li>

                <li>
                    — обработка персональных данных необходима для исполнения договора,
                    стороной которого либо выгодоприобретателем или поручителем,
                    по которому является субъект персональных данных, а также для
                    заключения договора по инициативе субъекта персональных данных
                    или договора, по которому субъект персональных данных будет
                    являться выгодоприобретателем или поручителем;
                </li>

                <li>
                    — обработка персональных данных необходима для осуществления прав
                    и законных интересов Оператора или третьих лиц либо для достижения
                    общественно значимых целей при условии, что при этом не нарушаются
                    права и свободы субъекта персональных данных;
                </li>

                <li>
                    — осуществляется обработка персональных данных, доступ неограниченного
                    круга лиц к которым предоставлен субъектом персональных данных либо
                    по его просьбе (далее — общедоступные персональные данные);
                </li>

                <li>
                    — осуществляется обработка персональных данных, подлежащих опубликованию
                    или обязательному раскрытию в соответствии с федеральным законом.
                </li>
            </ul>

            <h3>2.4. Конфиденциальность персональных данных</h3>

            <p>
                Оператор и иные лица, получившие доступ к персональным данным, обязаны
                не раскрывать третьим лицам и не распространять персональные данные без
                согласия субъекта персональных данных, если иное не предусмотрено
                федеральным законом.
            </p>

            <h3>2.5. Поручение обработки персональных данных другому лицу</h3>

            <p>
                Оператор вправе поручить обработку персональных данных другому лицу с согласия
                субъекта персональных данных, если иное не предусмотрено федеральным законом,
                на основании заключаемого с этим лицом договора. Лицо, осуществляющее обработку
                персональных данных по поручению Оператора, обязано соблюдать принципы и правила
                обработки персональных данных, предусмотренные ФЗ-152.
            </p>

            <h3>2.6. Трансграничная передача персональных данных</h3>

            <p>
                Оператор обязан убедиться в том, что иностранным государством, на территорию
                которого предполагается осуществлять передачу персональных данных, обеспечивается
                адекватная защита прав субъектов персональных данных, до начала осуществления
                такой передачи.
                Трансграничная передача персональных данных на территории иностранных
                государств, не обеспечивающих адекватной защиты прав субъектов персональных
                данных, может осуществляться в случаях:
            </p>

            <ul>
                <li>
                    — наличия согласия в письменной форме субъекта персональных данных на
                    трансграничную передачу его персональных данных;
                </li>

                <li>
                    — исполнения договора, стороной которого является субъект персональных данных.
                </li>
            </ul>

            <h3>2.7. Сookies</h3>

            <p>
                Мы используем файлы cookie, чтобы улучшить работу и повысить эффективность сайта,
                платформы. Продолжая пользование данным сайтом, платформой вы соглашаетесь с
                обработкой файлов cookie, пользовательских данных (сведения о местоположении;
                тип и версия ОС; тип и версия Браузера; тип устройства и разрешение его экрана;
                источник откуда пришел на сайт, платформу пользователь; с какого сайта или по
                какой рекламе; язык ОС и Браузера; какие страницы открывает и на какие кнопки
                нажимает пользователь; ip-адрес) в целях функционирования сайта, платформы
                проведения ретаргетинга и проведения статистических исследований и обзоров.
                Если вы не хотите, чтобы ваши данные обрабатывались,вы можете покинуть сайт,
                платформу или можете отключить их в настройках безопасности вашего браузера.
                Важно понимать, что настройки нужно применить во всех браузерах, которыми вы
                пользуетесь на устройстве. В случае если вырешите отключить cookiе, имейте в виду,
                что часть функций сайта, платформы могут перестать быть вам доступны или могут
                работать непредсказуемо.
            </p>

            <h2>3. Цели обработки персональных данных</h2>

            <p>Оператор осуществляется обработку персональных данных в следующих целях:</p>

            <ul>
                <li>
                    — Идентификации Пользователя, зарегистрированного на Платформе, сайте Оператора,
                </li>

                <li>
                    — Предоставления Пользователю доступа к персонализированным
                    ресурсам Платформы и сайта Оператора.

                </li>

                <li>
                    — Установления с Пользователем обратной связи,
                    включая направление уведомлений, запросов,
                    касающихся использования Платформы, сайта,
                    оказания услуг, обработку запросов и заявок от Пользователя.
                </li>

                <li>
                    — Уведомления Пользователя Платформы о внесении изменений
                    в Политику Конфиденциальности, Общие условия и Пользовательское соглашение.
                </li>

                <li>
                    — Предоставления Пользователю эффективной клиентской и
                    технической поддержки при возникновении проблем, связанных
                    с использованием Платформы и сайта Оператора.
                </li>
            </ul>

            <p>Осуществления рекламной деятельности с согласия Пользователя.</p>

            <h2>4. Права субъекта персональных данных</h2>

            <h3>
                4.1. Согласие субъекта персональных данных на обработку его персональных данных
            </h3>

            <p>
                Субъект персональных данных принимает решение о предоставлении его персональных
                данных и дает согласие на их обработку свободно, своей волей и в своем интересе.
                Согласие на обработку персональных данных может быть дано субъектом персональных
                данных или его представителем в любой позволяющей подтвердить факт его получения
                форме, если иное не установлено федеральным законом.
                Обязанность предоставить доказательство получения согласия субъекта персональных
                данных на обработку его персональных данных или доказательство наличия оснований,
                указанных в ФЗ-152, возлагается на Оператора.
            </p>

            <h3>4.2. Права субъекта персональных данных</h3>

            <p>
                Субъект персональных данных имеет право на получение у Оператора информации,
                касающейся обработки его персональных данных, если такое право не ограничено в
                соответствии с федеральными законами. Субъект персональных данных вправе требовать
                от Оператора уточнения его персональных данных, их блокирования или уничтожения,
                а также принимать предусмотренные законом меры по защите своих прав.
                Обработка персональных данных в целях продвижения товаров, работ, услуг на рынке
                путем осуществления прямых контактов с потенциальным потребителем с помощью средств
                связи, а также в целях политической агитации допускается только при
                условии предварительного согласия субъекта персональных данных.
                Указанная обработка персональных данных признается осуществляемой без
                предварительного согласия субъекта персональных данных, если Оператор
                не докажет, что такое согласие было получено.
                Оператор обязан немедленно прекратить по требованию субъекта персональных данных
                обработку его персональных данных.
                Запрещается принятие на основании исключительно автоматизированной обработки
                персональных данных решений, порождающих юридические последствия в отношении
                субъекта персональных данных или иным образом затрагивающих его права и законные
                интересы, за исключением случаев, предусмотренных федеральными законами, или при
                наличии согласия в письменной форме субъекта персональных данных.
                Если субъект персональных данных считает, что Оператор осуществляет обработку его
                персональных данных с нарушением требований ФЗ-152 или иным образом нарушает его
                права и свободы, субъект персональных данных вправе обжаловать действия или
                бездействие Оператора в уполномоченный орган по защите прав субъектов персональных
                данных или в судебном порядке.
                Субъект персональных данных имеет право на защиту своих прав и законных интересов,
                в том числе на возмещение убытков и (или) компенсацию морального вреда
                в судебном порядке.
            </p>

            <h3>5. Обеспечение безопасности персональных данных</h3>

            <p>
                Безопасность персональных данных, обрабатываемых Оператором, обеспечивается
                реализацией правовых, организационных и технических мер, необходимых для
                обеспечения требований федерального законодательства в области защиты
                персональных данных. Для предотвращения несанкционированного доступа
                к персональным данным Оператором применяются следующие
                организационно-технические меры:
            </p>

            <ul>
                <li>
                    — назначение должностных лиц, ответственных
                    за организацию обработки и защиты персональных данных;
                </li>

                <li>
                    — ограничение состава лиц, имеющих доступ к персональным данным;
                </li>

                <li>
                    — ознакомление субъектов с требованиями
                    федерального законодательства и нормативных документов
                    Оператора по обработке и защите персональных данных;
                </li>

                <li>
                    — организация учета, хранения и обращения носителей информации;
                </li>

                <li>
                    — определение угроз безопасности персональных
                    данных при их обработке, формирование на их основе моделей угроз;
                </li>
                <li>
                    — разработка на основе модели угроз системы защиты персональных данных;
                </li>

                <li>
                    — проверка готовности и эффективности использования средств защиты информации;
                </li>

                <li>
                    — разграничение доступа пользователей к информационным
                    ресурсам и программно-аппаратным средствам обработки информации;
                </li>

                <li>
                    — регистрация и учет действий пользователей информационных
                    систем персональных данных;
                </li>

                <li>
                    — использование антивирусных средств и средств восстановления
                    системы защиты персональных данных;
                </li>

                <li>
                    — применение в необходимых случаях средств межсетевого экранирования,
                    обнаружения вторжений, анализа защищенности и средств
                    криптографической защиты информации;
                </li>

                <li>
                    — организация пропускного режима на территорию Оператора,
                    охраны помещений с техническими средствами обработки персональных данных.
                </li>
            </ul>

            <h3>6. Заключительные положения</h3>

            <p>
                Иные права и обязанности Оператора как Оператора персональных данных
                определяются законодательством Российской Федерации в области персональных данных.
                Должностные лица Оператора, виновные в нарушении норм, регулирующих
                обработку и защиту персональных данных, несут материальную, дисциплинарную,
                административную, гражданско-правовую или уголовную ответственность в порядке,
                установленном федеральными законами.
                Оператор вправе вносить изменения в настоящую Политику обработки персональных
                данных без согласия субъекта персональных данных.
                Новая редакция Политики обработки персональных данных вступает в силу с момента
                ее размещения на сайте Оператора, если иная, более поздняя дата не предусмотрена
                новой редакцией Политики обработки персональных данных.
                Действующая редакция Политики всегда находится на странице по адресу <a href="//edhoc.ru">https://edhoc.ru</a>
            </p>
        </div>
    );
}
