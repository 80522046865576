import { makeAutoObservable } from 'mobx';
import {
    Attendance,
    MeetingInstance, MeetingInstanceAttendance,
    QueryParams,
    StudentWithAbsencesCount, Table,
    TableColumn,
    TableRow,
} from '@admin/Attendance/AttendanceIterfaces';
import { sortBy } from 'lodash';
import { nanoid } from 'nanoid';

class AttendanceStore {
    queryParams: QueryParams = {
        btiInstanceId: '',
        moduleId: '',
        spaceId: '',
        skillTypeId: '',
    };

    attendances: Attendance[] = [];

    tables: Table[] = [];

    private isAttendanceByDates: boolean = false;

    activeTableColumnId: string = '';

    activeTableRowId: string = '';

    constructor() {
        makeAutoObservable(this);
    }

    setAttendances = (
        newAttendances: Attendance[],
    ) => {
        this.attendances = newAttendances;
    };

    setTablesFromAttendance = (
        attendances: Attendance[],
    ) => {
        this.tables = getTables(attendances);
    };

    setTables = (
        newTables: Table[],
    ) => {
        this.tables = newTables;
    };

    setIsAttendanceByDates = (
        isByDates: boolean,
    ) => {
        this.isAttendanceByDates = isByDates;
    };

    setActiveTableColumnId = (columnId: string) => {
        this.activeTableColumnId = columnId;
    };

    updateQueryParams = () => {
        const params = new URLSearchParams(window.location.search);
        this.queryParams = {
            btiInstanceId: params.get('btiIId') ?? '',
            moduleId: params.get('moduleId') ?? '',
            spaceId: params.get('spaceId') ?? '',
            skillTypeId: params.get('skillTypeId') ?? '',
        };
    };

    getIsAttendanceByDates = () => this.isAttendanceByDates;
}

export const attendanceStore = new AttendanceStore();

function getSortedMeetingInstancesByDates(
    meetings: MeetingInstance[],
) {
    return sortBy(meetings, (meeting) => meeting.startDate);
}

function getSortedStudentsWithAbsencesCount(
    studentsWithAbsencesCount: StudentWithAbsencesCount[],
) {
    return sortBy(
        studentsWithAbsencesCount,
        (studentWithAbsenceCount) => studentWithAbsenceCount.student.user.lastName,
    );
}

function getTables(
    attendances: Attendance[],
): Table[] {
    return attendances.map((attendance) => (
        {
            id: nanoid(),
            wave: attendance.wave,
            rows: getTableRows(
                getSortedStudentsWithAbsencesCount(attendance.studentsWithAbsencesCount),
                attendance.meetingInstancesAttendance,
            ),
            columns: getTableColumns(
                getSortedMeetingInstancesByDates(attendance.meetingInstances),
                attendance.meetingInstancesAttendance,
            ),
        }
    ));
}

function getTableColumns(
    sortedMeetingInstances: MeetingInstance[],
    meetingInstancesAttendance: MeetingInstanceAttendance[],
): TableColumn[] {
    return sortedMeetingInstances.map((meetingInstance) => (
        {
            columnId: nanoid(),
            meetingInstance,
            meetingInstancesAttendance: meetingInstancesAttendance.filter(
                (
                    meetingInstanceAttendance,
                ) => meetingInstanceAttendance.meetingInstanceId === meetingInstance.id,
            ),
        }
    ));
}

function getTableRows(
    sortedStudentsWithAbsencesCount: StudentWithAbsencesCount[],
    meetingInstancesAttendance: MeetingInstanceAttendance[],
): TableRow[] {
    return sortedStudentsWithAbsencesCount.map((studentWithAbsencesCount) => (
        {
            rowId: nanoid(),
            studentWithAbsencesCount,
            meetingInstancesAttendance: meetingInstancesAttendance.filter(
                (meeting) => meeting.studentId === studentWithAbsencesCount.student.user.studentId,
            ),
        }
    ));
}
