import React from 'react';
import { Title, TitleTypes } from '@common/Title';
import { observer } from 'mobx-react';
import { ConfigSkill, ModuleSkill, dictionaryStore } from '../Store';
import { SectionList } from '../Section';
import { normalBoldJSX } from '../utils';

import styles from './Preview.module.scss';

interface Props {
    skillTypes: ConfigSkill[];

    getSkills(id: string): ModuleSkill[];
}

export const PreviewSkills = observer(({
    skillTypes,
    getSkills,
}: Props) => (
    <>
        {skillTypes.map(({ id }) => ({
            typeID: id,
            skills: getSkills(id) ?? [],
            typeName: dictionaryStore.getSkillType(id)?.name,
        })).map(({
            typeID,
            skills,
            typeName,
        }) => (
            <div key={typeID} className={styles.preview__prerequisites}>
                <Title
                    type={TitleTypes.Subtitle}
                >{`${typeName!.nominativePlural}, которые необходимы для попадания на модуль:`}
                </Title>
                <SectionList
                    list={skills.map(skill => ({ ...skill.skill, level: skill.level }))}
                    formatItem={({ name, level }) => (Number.isInteger(level)
                        ? normalBoldJSX(name, String(level))
                        : <>{name}</>)}
                    hasRemove={false}
                />
            </div>
        ))}
    </>
));
