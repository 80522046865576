import React, { useState } from 'react';
import { ModuleStudentsAccordion } from '@admin/ModuleSelectionPage/ModuleStudentsAccordion/ModuleStudentsAccordion';
import {
    ModuleGroupWithAdditionalFields,
} from '@admin/ModuleSelectionPage/Interfaces';
import { Alert } from '@common/Alert';
import { nanoid } from 'nanoid';
import { useMutation, useQuery } from '@apollo/client';
import {
    GET_IS_VALID_STUDENTS_MODULE_DISTRIBUTION,
    UPDATE_STUDENT_MODULE,
} from '@admin/ModuleSelectionPage/ModuleSelectionQueries';
import {
    GetIsValidStudentsModuleDistribution,
    GetIsValidStudentsModuleDistributionVariables,
    UpdateStudentModuleInput,
} from '@admin/ModuleSelectionPage/graphql-types';
import { moduleSelectionDataStore } from '@admin/ModuleSelectionPage/ModuleSelectionDataStore';
import { observer } from 'mobx-react';

interface Props {
    currentModuleGroup: ModuleGroupWithAdditionalFields;
}

export const ModuleStudentsAccordionApollo = observer((
    {
        currentModuleGroup,
    } : Props,
): JSX.Element => {
    const [
        updateStudentModuleError,
        setUpdateStudentModuleError,
    ] = useState<JSX.Element[]>([]);
    const addError = (message: string) => setUpdateStudentModuleError((arr) => [...arr, (<Alert
        key={nanoid()}
        message={message}
        time={7000}
    />)]);

    const [updateStudentModuleMutation] = useMutation(UPDATE_STUDENT_MODULE, {
        onError: (error) => addError(error.message),
    });

    const {
        refetch: refetchIsValidStudentsModuleDistribution,
    } = useQuery<
    GetIsValidStudentsModuleDistribution,
    GetIsValidStudentsModuleDistributionVariables
    >(GET_IS_VALID_STUDENTS_MODULE_DISTRIBUTION, {
        fetchPolicy: 'no-cache',
        variables: {
            baseTimeIntervalInstanceId:
            moduleSelectionDataStore.getURLParameters().baseTimeIntervalInstanceId,
        },
        onCompleted: (data) => moduleSelectionDataStore
            .setIsModuleDistributionValid(data.isValidStudentsModuleDistribution),
        onError: (error) => addError(error.message),
    });

    const updateStudentModule = (input: UpdateStudentModuleInput) => updateStudentModuleMutation({
        variables: {
            UpdateStudentModuleInput: {
                ...input,
            },
        },
    });

    return (
        <>
            <ModuleStudentsAccordion
                currentModuleGroup={currentModuleGroup}
                updateStudentModule={updateStudentModule}
                refetchIsValidStudentsModuleDistribution={refetchIsValidStudentsModuleDistribution}
            />
            {
                updateStudentModuleError
            }
        </>
    );
});
