import { ApolloError, FetchResult } from '@apollo/client';
import {
    useMutationWithErrorHandling,
} from '@admin/ScheduleGenerationPage/graphql/useMutationWithErrorHandling';
import { RoomType } from '@admin/ScheduleGenerationPage/types';
import { GET_EVENT_DATA, UPDATE_MEETING_ROOM } from '../../queries';

interface UpdateRoomInput {
    roomId: string;
    meetingInstanceId: string;
    roomType: RoomType;
}
export type UpdateRoomFunc = (input: UpdateRoomInput) => Promise<
FetchResult<any, Record<string, any>, Record<string, any>>>;

export const useUpdateMeetingRoomMutation = (
    id: string,
    type: string,
    setErrorsFunction: (error: ApolloError) => void,
): { updateMeetingRoom: UpdateRoomFunc, updateMeetingRoomError: ApolloError | undefined } => {
    const [
        updateMeetingRoomMutation,
        { error: updateMeetingRoomError },
    ] = useMutationWithErrorHandling(UPDATE_MEETING_ROOM, {
        refetchQueries: [{
            query: GET_EVENT_DATA,
            variables: {
                tmpMeetingInstanceInput: { id, type },
            },
        }],
        onError: (error) => setErrorsFunction(error),
    });

    return {
        updateMeetingRoom: (input: UpdateRoomInput) => updateMeetingRoomMutation({
            variables: {
                updateMeetingInstanceRoomInput: input,
            },
        }),
        updateMeetingRoomError,
    };
};
