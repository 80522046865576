import { gql } from '@apollo/client';

export const GET_EQUIPMENTS = gql`
  query GetEquipmentsEquipmentPage($filterParams: EquipmentFilterParams!, $paginationParams: EquipmentPaginationParams!) {
    equipment(filterParams: $filterParams, paginationParams: $paginationParams) {
      list {
        id
        category {
          id
          name
        }
        features {
          id
          name
        }
        movability {
          id
          isMovable
          count
          territorialZoneId
        }
        rooms {
          countEquipments
          room {
            id
            title
          }
        }
      }
      totalCount
    }
  }
`;

export const GET_ALL_ROOMS = gql`
  query GetRoomsEquipmentPage($roomsInput: RoomsInput) {
    rooms(roomsInput: $roomsInput) {
      id
      title
    }
  }
`;

export const REMOVE_EQUIPMENT = gql`
  mutation RemoveEquipmentEquipmentPage($id: String!) {
    removeEquipment(id: $id)
  }
`;

export const GET_TERRITORIAL_ZONE = gql`
    query TerritorialZonesEquipmentPage {
        territorialZones {
            id
            name
        }
    }
`;

export const UPDATE_EQUIPMENT = gql`
  mutation UpdateEquipmentEquipmentPage ($updateEquipmentInput: UpdateEquipmentInput!) {
    updateEquipment(updateEquipmentInput: $updateEquipmentInput) {
      id
        category {
          id
          name
        }
        features {
          id
          name
        }
        movability {
          id
          isMovable
          count
          territorialZoneId
        }
        rooms {
          countEquipments
          room {
            id
            title
          }
        }
    }
  }
`;

export const UPDATE_EQUIPMENT_CATEGORY = gql`
mutation UpdateEquipmentCategoryEquipmentPage ($updateEquipmentCategoryInput: UpdateEquipmentCategoryInput!) {
  updateEquipmentCategory(updateEquipmentCategoryInput: $updateEquipmentCategoryInput) {
    id
    name
  }
}
`;

export const CREATE_EQUIPMENT = gql`
mutation CreateEquipmentEquipmentPage ($createEquipmentInput: CreateEquipmentInput!) {
  createEquipment(createEquipmentInput: $createEquipmentInput) {
      id
        category {
          id
          name
        }
        features {
          id
          name
        }
        movability {
          id
          isMovable
          count
          territorialZoneId
        }
        rooms {
          countEquipments
          room {
            id
            title
          }
        }
    }
}
`;
