import React from 'react';
import { observer } from 'mobx-react';
import cn from 'classnames';

import { FilterType, filterDescription } from '../filterType';
import { accountStore } from '../../../../Store';

import classes from './FilterOption.module.scss';

interface Props {
    filterType: FilterType;
    handleChangeFilterType(filterType: FilterType): void;
}

export const FilterOption = observer(({
    filterType,
    handleChangeFilterType,
}: Props): JSX.Element => (
    <div
        className={classes.filterOption}
        onClick={() => handleChangeFilterType(filterType)}
    >
        <div
            className={cn(
                classes.filterOption__circle,
                classes[`filterOption__circle_filter_${filterType}`],
                { [classes.filterOption__circle_isTeacher]: accountStore.isTeacher() },
            )}
        />

        <div className={classes.filterOption__description}>
            {filterDescription[filterType]}
        </div>
    </div>
));
