import { useEffect, useState } from 'react';
import { TabsOption } from '@common/TabsRedesign/TabsRedesign';

interface SeparatedTabs {
    visibleTabs: TabsOption[],
    hiddenTabs: TabsOption[],
}

interface Props {
    tabWidth: number;
    maxRightPosition: number;
    tabs: TabsOption[];
}

export function useSeparateTabs(
    {
        tabWidth,
        maxRightPosition,
        tabs,
    }: Props,
) {
    const [separatedTabs, setSeparatedTabs] = useState<SeparatedTabs>({
        visibleTabs: [],
        hiddenTabs: [],
    });

    useEffect(() => {
        const visibleTabs: TabsOption[] = [];
        const hiddenTabs: TabsOption[] = [];

        let tabsWidth = 0;

        tabs.forEach((tab) => {
            tabsWidth += tabWidth;
            if (tabsWidth > maxRightPosition) {
                hiddenTabs.push(tab);
            } else {
                visibleTabs.push(tab);
            }
        });

        setSeparatedTabs({
            visibleTabs,
            hiddenTabs,
        });
    }, [maxRightPosition, tabs]);

    return separatedTabs;
}
