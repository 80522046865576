import { ApolloError, useMutation } from '@apollo/client';
import {
    GET_EVENT_DATA,
    PIN_MEETING_INSTANCE,
} from '../../queries';

export const usePinMeetingInstanceMutation = (
    id: string,
    type: string,
    setErrorsFunction: (error: ApolloError) => void,
) => {
    const [
        pinMeetingInstance,
    ] = useMutation(PIN_MEETING_INSTANCE, {
        refetchQueries: [{
            query: GET_EVENT_DATA,
            variables: {
                tmpMeetingInstanceInput: { id, type },
            },
        }],
        onError: (error) => setErrorsFunction(error),
    });

    return {
        pinEventInstance: (pin: boolean) => pinMeetingInstance({
            variables: { id, pin },
        }),
    };
};
