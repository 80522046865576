import React from 'react';
import { sortBy } from 'lodash';

import {
    StudentsModulesPriorities, StudentsModulesPriorityGroups,
} from '@admin/EducationPeriodStatistic/EducationPeriodStatisticInterfaces';
import classes from './VotedChart.module.scss';

interface Props {
    chartItems: StudentsModulesPriorities[];
    currentPriority: number;
}

export function VotedChart(
    {
        chartItems,
        currentPriority,
    }: Props,
) {
    const sortedChartItems = sortBy(chartItems,
        (chartItem) => chartItem.priorityGroups[currentPriority - 1]?.students.length);
    const gridColumnWidth = 100 / chartItems.length;

    const chart = sortedChartItems.reverse().map((chartItem, index) => {
        const allVotes = getAllStudentsVotes(chartItem.priorityGroups);
        const priorityVotes = chartItem.priorityGroups[currentPriority - 1]?.students.length;

        if (priorityVotes === 0 || allVotes === 0) {
            return null;
        }

        const chartItemHeight = (allVotes / 360) * 100;

        const chartItemPlenumHeight = (priorityVotes / allVotes) * 100;

        return (
            <div
                className={classes.chartItem}
                key={chartItem.module.id}
            >
                <div
                    className={classes.chartItem__info}
                    style={{
                        left: (index === chartItems.length - 1) ? `-${50}%` : `${120}å%`,
                    }}
                >
                    <div className={classes.chartItem__info_bold}>
                        { chartItem.module.name }
                    </div>
                    <div>
                        Всего {allVotes} голоса
                    </div>
                    <div>
                        {currentPriority}-й приоритет {priorityVotes} голоса
                    </div>
                </div>
                <div
                    className={classes.chartItem__item}
                >

                    <div className={classes.chartItem__title}>
                        {allVotes}
                    </div>

                    <div
                        className={classes.chartItem__full}
                        style={{
                            height: `${chartItemHeight}%`,
                        }}
                    >

                        <div
                            className={classes.chartItem__plenum}
                            style={{
                                height: `${chartItemPlenumHeight}%`,
                            }}
                        >
                            <div className={classes.chartItem__voted}>
                                {priorityVotes}
                            </div>
                        </div>

                    </div>

                </div>

            </div>
        );
    }).filter((element) => element !== null);

    return (
        <div className={classes.votedChart}>
            <div
                className={classes.votedChart__overflow}
                style={{
                    gap: '5px',
                    gridTemplateColumns: `repeat(${chart.length}, ${gridColumnWidth}%)`,
                }}
            >
                {chart}
            </div>
        </div>
    );
}

function getAllStudentsVotes(studentsPriorities: StudentsModulesPriorityGroups[]) {
    let allVotes = 0;

    studentsPriorities.forEach((studentPriority) => {
        allVotes += studentPriority.students.length;
    });
    return allVotes;
}
