import React, { SyntheticEvent } from 'react';

import { range } from 'lodash';

import { ActionButton, ActionTypeOfButton } from '@common/ActionButton';
import { BorderCrossIcon } from '@common/svg';

import classes from './SkillAddWithoutNest.module.scss';

import {
    skillTypesData_skillTypes,
    skills_skills,
} from '../../../../deprecated-graphql-query-types';

interface Props {
    skill: skills_skills;
    skillType: skillTypesData_skillTypes;
    closePortal(): void;
    updateSkill: (
        id: string,
        name: string,
        typeId: string,
        description: string,
        maxLevel: number
    ) => void;
}

export function PortalSkillAddWithoutNest({
    skill,
    skillType,
    closePortal,
    updateSkill,
}: Props): JSX.Element {
    const onSubmit = (e: SyntheticEvent) => {
        e.preventDefault();
        const { name, description, maxLevel }: any = e.target;
        updateSkill(skill.id, name.value, skillType.id, description.value, Number(maxLevel.value));
        closePortal();
    };
    return (
        <div className={classes.skillAddPortal}>
            <div className={classes.skillAddPortal__wrapper}>
                <div className={classes.skillAddPortal__container}>
                    <div className={classes.skillAddPortal__title}>
                        Редактирование навыка «{skill.name}»:
                    </div>
                    <form id="update-form" className={classes.skillAddWithoutNest__form} onSubmit={onSubmit}>
                        <label
                            className={classes.skillAddWithoutNest__label}
                        >
                            Название навыка:
                            <input
                                name="name"
                                defaultValue={skill.name}
                                type="text"
                                className={classes.skillAddWithoutNest__input}
                            />
                        </label>
                        <label
                            className={classes.skillAddWithoutNest__label}
                        >
                            Описание навыка:
                            <textarea
                                defaultValue={String(skill.description)}
                                name="description"
                                className={classes.skillAddWithoutNest__textarea}
                            />
                        </label>
                        <label
                            className={classes.skillAddWithoutNest__selectLabel}
                        >
                            Укажите максимальный уровень навыка «{skill.name}»:
                            <select
                                name="maxLevel"
                                defaultValue={Number(skill.maxLevel)}
                                className={classes.skillAddWithoutNest__select}
                            >
                                {range(1, Number(skillType.properties!.maxLevel) + 1)
                                    .map(value => (
                                        <option key={value} value={value}>
                                            {value}
                                        </option>
                                    ))}
                            </select>
                        </label>
                        <div className={classes.skillAddWithoutNest__updateButton}>
                            <ActionButton
                                actionType={ActionTypeOfButton.SECONDARY}
                                type="submit"
                            >
                                Сохранить изменения
                            </ActionButton>
                        </div>
                    </form>
                </div>
                <BorderCrossIcon
                    size={30}
                    handler={closePortal}
                    className={classes.blackCrossSkillModal}
                />
            </div>
        </div>
    );
}
