import React, { useState } from 'react';
import { observer } from 'mobx-react';

import { Accordion } from '@common/Accordion';
import { WaveStudents } from '@admin/WaveSelectionPage/WaveAccordion/WaveStudents';
import cn from 'classnames';
import { WaveWithSelectedStudents } from '@admin/WaveSelectionPage/Interfaces';
import { waveSelectionStore } from '@admin/WaveSelectionPage/WaveSelectionStore';
import { waveSelectionDataStore } from '@admin/WaveSelectionPage/WaveSelectionDataStore';
import classes from './WaveAccordion.module.scss';

interface Props {
    currentWaves: WaveWithSelectedStudents[];
}

export const WaveAccordion = observer((
    {
        currentWaves,
    }: Props,
) : JSX.Element => {
    const [isAccordionOpen, setAccordionOpen] = useState(true);

    const currentModule = currentWaves[0].module;

    const wavesInfo = `${currentModule.minStudentCount}-${currentModule.maxStudentCount} / макс.: ${currentWaves.length} потоков`;

    const isModuleInvalid = getIsModuleInvalid(
        currentModule.minStudentCount,
        currentWaves,
    );

    const waveStudentsList = currentWaves?.map((wave, index) => (
        <WaveStudents
            key={wave.id}
            waveIndex={index + 1}
            currentModule={wave.module}
            wave={wave}
        />
    ));

    return (
        <div
            className={cn(classes.accordion, {
                [classes.accordion_closed]: !isAccordionOpen,
            })}
        >
            <Accordion
                headerClassnames={classes.accordion__header_main}
                shouldClickOnHeaderOpen
                isDefaultOpen
                additionalSetState={() => setAccordionOpen(!isAccordionOpen)}
            >
                <div
                    className={classes.accordion__header}
                >

                    <span
                        className={cn(classes.accordion__header_ellipsis, {
                            [classes.accordion__header_ellipsis_closed]: !isAccordionOpen,
                            [classes.accordion__header_red]: isModuleInvalid,
                        })}
                    >
                        { currentWaves[0]?.module.name }
                    </span>

                    <div className={classes.accordion__header_additional}>
                        { currentWaves[0]?.module.name }
                    </div>

                    <div className={classes.accordion__header_wavesInfo}>
                        {
                            wavesInfo
                        }
                    </div>

                </div>

                <div
                    className={cn(classes.accordion__items, {
                        [classes.accordion__items_disabled]:
                        waveSelectionStore.getIsRequiredStudentMoved()
                        && waveSelectionStore.getActiveCurrentModuleId()
                        !== currentWaves[0].module.id
                        && waveSelectionDataStore.getURLParameters().isNotStudentChoice,
                    })}
                >
                    {
                        waveStudentsList
                    }
                </div>
            </Accordion>
        </div>
    );
});

function getIsModuleInvalid(
    minStudentCount: number,
    waves: WaveWithSelectedStudents[],
) {
    return waves.some((wave) => (
        wave.students.length < minStudentCount
    ));
}
