import React from 'react';
import { useLazyQuery } from '@apollo/client';

import { scheduleGenerationStore } from '@admin/ScheduleGenerationPage/store';
import {
    GetRoomLoadResponse,
    GetRoomLoadVariables,
    RoomLoadInput,
} from './RoomOccupationDashboardInterfaces';
import { RoomOccupationDashboard } from './RoomOccupationDashboard';
import { GET_ROOM_LOAD } from './RoomOccupationDashboardQueries';

export function RoomOccupationDashboardApollo() {
    const [getRoomLoad, {
        data: roomLoadData,
        loading: roomLoadLoading,
        error: roomLoadError,
    }] = useLazyQuery<GetRoomLoadResponse, GetRoomLoadVariables>(
        GET_ROOM_LOAD, { fetchPolicy: 'no-cache' },
    );

    function getRoomLoadForDateRange(input: Omit<RoomLoadInput, 'baseTimeIntervalInstanceIds'>) {
        getRoomLoad({
            variables: {
                roomLoadInput: {
                    baseTimeIntervalInstanceIds: [
                        scheduleGenerationStore.baseTimeIntervalInstance?.id!,
                    ],
                    ...input,
                },
            },
        });
    }

    return React.createElement(RoomOccupationDashboard, {
        roomLoadData: roomLoadData?.roomLoad,
        roomLoadLoading,
        roomLoadError,
        getRoomLoadForDateRange,
    });
}
