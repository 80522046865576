import React from 'react';

import { ToolBar } from '../ToolBar';
import { Table, columnConfigs } from './Table';
import { Type } from './apollo-types';
import { useUserList } from './useUserList';
import { UserRow } from './Table/UserRow';
import { FullName } from './Table/userCellInfo';
import classes from './Table/table.module.scss';

export function AdminsTable() {
    const {
        error,
        loading,
        users,
        isMoreButtonVisible,
        onMoreButtonClick,
        onChangeSearchParams,
        onSelect,
        isUserSelected,
        selectedUsers,
        refetchUserList,
        onSelectAll,
        clearPreviousSelected,
        isAllSelected,
    } = useUserList(Type.admin);

    if (error) {
        return (
            <div>Произошла ошибка: {error.graphQLErrors[0]?.message || error.message}</div>
        );
    }

    return (
        <div>
            <div className={classes.toolBar}>
                <ToolBar
                    tools={[]}
                    selectedUsers={selectedUsers}
                    refetchUserList={refetchUserList}
                />
            </div>
            <Table
                setSearchParams={onChangeSearchParams}
                columnConfigs={[columnConfigs.fullName, columnConfigs.status]}
                onShowMore={onMoreButtonClick}
                isMoreButtonVisible={isMoreButtonVisible}
                clearPreviousSelected={clearPreviousSelected}
                onSelectAll={onSelectAll}
                isAllSelected={isAllSelected}
                loading={loading}
            >
                {users.map(user => {
                    const isEnabled = user.roles?.find(role => role?.name === 'Admin')?.enabled;
                    return (
                        <UserRow
                            key={user.id}
                            userId={user.id}
                            onChange={() => onSelect(user)}
                            checked={isUserSelected(user)}
                        >
                            <FullName user={user} />
                            <td>{isEnabled ? 'Активен' : 'Неактивен'}</td>
                        </UserRow>
                    );
                })}
            </Table>
        </div>
    );
}
