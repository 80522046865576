/* eslint-disable no-sequences */
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect, useMemo } from 'react';
import cn from 'classnames';
import { debounce, sortBy } from 'lodash';

import {
    GetEquipmentsEquipmentPage_equipment_list,
    TerritorialZonesEquipmentPage_territorialZones,
    UpdateEquipmentCategoryInput,
    UpdateEquipmentEquipmentPage_updateEquipment,
    UpdateEquipmentInput,
} from 'src/deprecated-graphql-query-types';

import { DeleteCrossIcon } from '../DeleteCrossIcon';
import { equipmentStore } from '../store/EquipmentStore';
import { Mobility, NameContainer, Rooms } from './EquipmentParams';

import classes from './RegularEquipment.module.scss';

interface RegularEquipmentProps {
    zones?: TerritorialZonesEquipmentPage_territorialZones[],
    equipment: GetEquipmentsEquipmentPage_equipment_list,
    removeEquipmentFromState: (id: string) => void,
    deleteNewRegularEquipment: (id: string) => void,
    updateEquipmentHandler(
        input: UpdateEquipmentInput,
        callBack: (params: UpdateEquipmentEquipmentPage_updateEquipment) => void,
    ): void;
}
const DEBOUNCE_INTERVAL = 500;

export function RegularEquipment(
    {
        zones,
        equipment,
        deleteNewRegularEquipment,
        removeEquipmentFromState,
        updateEquipmentHandler,
    }: RegularEquipmentProps,
): JSX.Element {
    const [currentEquipment, setCurrentEquipment] = useState(equipment);
    const [isEquipmentEditing, setIsEquipmentEditing] = useState(false);

    const [equipmentParams, setEquipmentParams] = useState<UpdateEquipmentInput>({
        id: equipment.id,
        isComputer: false,
        features: equipment.features,
        movability: equipment.movability,
    });

    const debouncedUpdateEquipment = useMemo(
        () => debounce(updateEquipmentHandler, DEBOUNCE_INTERVAL),
        [],
    );

    useEffect(() => {
        if (isEquipmentEditing) {
            debouncedUpdateEquipment({ ...equipmentParams, id: currentEquipment.id }, setCurrentEquipment);
        }
    }, [equipmentParams]);

    const allCount = currentEquipment.movability.reduce((acc, { count: eqCount }) => eqCount + acc, 0);
    const equipmentInRoomsCount = currentEquipment.rooms.reduce((acc, room) => room.countEquipments + acc, 0);
    function getSortRooms() {
        return sortBy(equipment.rooms, ({ room }) => room?.title);
    }
    function onChangeMovabilityCount(
        equipmentCount: number,
        isMovable: boolean,
        zoneId: string | null,
        setIsValidCount: React.Dispatch<React.SetStateAction<boolean>>,
    ) {
        setEquipmentParams((preParams) => {
            const newParams = { ...preParams };
            const currentMovability = preParams.movability;
            const isNotMovableMovability = isMovable === false;
            const isEquipmentRoomsCountMoreEquipmentCount = equipmentInRoomsCount > equipmentCount;
            if (isNotMovableMovability && isEquipmentRoomsCountMoreEquipmentCount) {
                setIsValidCount(false);
                return preParams;
            }
            setIsValidCount(true);
            const changeMovability = currentMovability
                ?.find(({
                    territorialZoneId,
                    isMovable: flag,
                }) => zoneId === territorialZoneId && flag === isMovable);
            const newMovability = currentMovability?.filter(({
                territorialZoneId,
                id,
                isMovable: flag,
            }) => {
                if (zoneId === territorialZoneId) {
                    return isMovable !== flag;
                }
                return zoneId !== territorialZoneId || changeMovability?.id !== id;
            });
            if (equipmentCount > 0) {
                newMovability?.push({
                    id: changeMovability?.id || undefined,
                    territorialZoneId: zoneId,
                    count: equipmentCount,
                    isMovable,
                });
            }
            newParams.movability = newMovability;
            return newParams;
        });
    }
    const equipmentRow = (
        <div
            className={
                cn(classes.regularEquipment__form,
                    { [classes.regularEquipment__form_editing]: isEquipmentEditing })
            }
            onClick={() => equipmentStore.setSelectedEquipmentId(currentEquipment.id)}
        >
            <NameContainer
                id={equipment.id}
                currentCategory={equipment.category}
                count={allCount}
                removeEquipmentFromState={removeEquipmentFromState}
                setCurrentEquipment={setCurrentEquipment}
                setIsEquipmentEditing={setIsEquipmentEditing}
            />
            <Mobility
                zones={zones}
                count={allCount}
                movability={currentEquipment.movability}
                equipmentInRoomsCount={equipmentInRoomsCount}
                setIsEquipmentEditing={setIsEquipmentEditing}
                onChangeMovabilityCount={onChangeMovabilityCount}
            />
            <Rooms rooms={getSortRooms()} />
        </div>
    );
    return (
        <div className={classes.regularEquipment}>
            <div className={classes.regularEquipment__crossDeleteContainer}>
                <button
                    type="button"
                    onClick={() => deleteNewRegularEquipment(currentEquipment.id)}
                    className={
                        cn(
                            classes.regularEquipment__crossDelete,
                        )
                    }
                >
                    <DeleteCrossIcon />
                </button>
            </div>
            {equipmentRow}
        </div>
    );
}
