import React, { useMemo } from 'react';
import { observer } from 'mobx-react-lite';

import { NavigationItem } from './NavigationItem';
import { NavigationChangeRole } from './NavigationChangeRole';
import { ViewType, InterfaceSection } from '../../../deprecated-graphql-query-types';
import UserView from '../../../store/UserView';
import classes from './NavigationItems.module.scss';

interface Props {
    toggleBurgerMenu(): void;
}

interface NavigationItemInfo {
    text: string;
    to: string;
    id: string;
    hasNotification?: boolean;
}

export const NavigationItems = observer(({ toggleBurgerMenu }: Props): JSX.Element => {
    const viewType = UserView.userViewType?.name;

    const navigationItems: JSX.Element[] = useMemo(getNavigationItems, [viewType]);

    function getNavigationItemsInfo(): NavigationItemInfo[] {
        let res: NavigationItemInfo[] = [];
        const sections = UserView.user?.availableInterfaceSections;

        if (viewType === ViewType.Admin) {
            sections?.forEach(section => {
                if (section === InterfaceSection.University) {
                    res.push({ text: 'Университет', to: '/university', id: 'university' });
                }

                if (section === InterfaceSection.Space) {
                    res.push({ text: 'Образовательное пространство', to: '/educational-space', id: 'space' });
                }

                if (section === InterfaceSection.Users) {
                    res.push({ text: 'Пользователи', to: '/users', id: 'users' });
                }

                if (section === InterfaceSection.EducationalPeriod) {
                    res.push({ text: 'Учебный период', to: '/education-period', id: 'education-period' });
                }

                if (section === InterfaceSection.Modules) {
                    res.push({ text: 'Модули', to: '/module', id: 'module' });
                }

                if (section === InterfaceSection.ModuleStatistics) {
                    res.push({ text: 'Статистика модулей', to: '/module-statistics', id: 'module-statistics' });
                }

                if (section === InterfaceSection.Trajectories) {
                    res.push({ text: 'Образовательные траектории', to: '/trajectories', id: 'trajectories' });
                }
            });

            res.push({ text: 'Выход', to: '/exit', id: 'schedule' });
        } else if (viewType === ViewType.Teacher) {
            res = [
                { text: 'Профиль', to: '/profile', id: 'profile' },
                { text: 'Расписание', to: '/schedule', id: 'schedule' },
                { text: 'Мои модули', to: '/teacher-assignments', id: 'teacher-assignments' },
                { text: 'Выход', to: '/exit', id: 'schedule' },
            ];
        } else if (viewType === ViewType.Student) {
            res = [
                { text: 'Профиль', to: '/profile', id: 'profile' },
                {
                    text: 'Мои модули',
                    to: '/modules',
                    id: 'modules',
                    hasNotification: (UserView.user?.student?.notifications?.length ?? 0) > 0,
                },
                { text: 'Расписание', to: '/schedule', id: 'schedule' },
                { text: 'Самостоятельные работы', to: '/student-assignments', id: 'assignmets' },
                { text: 'Выход', to: '/exit', id: 'schedule' },
                // { text: 'Траектории', to: '/trajectories-sandbox', id: 'trajectories-sandbox' },
            ];
        }

        return res;
    }

    function getNavigationItems(): JSX.Element[] {
        let res: JSX.Element[] = [];

        const itemsInfo: NavigationItemInfo[] = getNavigationItemsInfo();

        if (itemsInfo.length) {
            res = itemsInfo.map((itemInfo: NavigationItemInfo) => (
                <NavigationItem
                    key={itemInfo.text}
                    text={itemInfo.text}
                    to={itemInfo.to}
                    onClick={toggleBurgerMenu}
                    hasNotification={itemInfo.hasNotification}
                />
            ));

            if (UserView.userViewTypes && UserView.userViewTypes?.length > 1) {
                res.splice(
                    (res.length - 1),
                    0,
                    <NavigationChangeRole
                        key={res.length - 1}
                        availableUserRoles={UserView.userViewTypes}
                    />,
                );
            }
        }

        return res;
    }

    return (
        <nav className={classes.menuNavigation}>
            {navigationItems.length && navigationItems}
        </nav>
    );
});
