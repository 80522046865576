import React from 'react';

import classes from './AttendanceHeader.module.scss';

interface AttendanceHeaderProps {
    editStudentMode: boolean;
}

export function AttendanceHeader({ editStudentMode }: AttendanceHeaderProps): JSX.Element {
    return (
        <div className={classes.schedulePage_meetingSection_attendance}>
            <p className={classes.schedulePage_meetingSection_attendanceText}>
                {!editStudentMode ? 'Отметить посещаемость:' : 'Посещаемость:'}
            </p>
        </div>
    );
}
