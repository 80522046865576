import React from 'react';
import { useMutation, useQuery } from '@apollo/client';
import {
    GET_USER_ABSENCES,
    UPDATE_USER_ABSENCES,
} from '@admin/Users/UserCard/UserCommonInfo/UserAbsences/UserAbsencesQueries';
import {
    UserWithAbsencesVariables,
    UserWithAbsences,
    UserWithAbsences_user_student_baseTimeIntervalInstances_baseTimeIntervalInstance
    as BaseTimeIntervalInstance,
    UserWithAbsences_user_student_baseTimeIntervalInstances
    as BaseTimeIntervalInstances,
    UserWithAbsences_user_student_excusedAbsences as ExcusedAbsences,
    UpdateStudentExcusedAbsencesVariables,
} from '@admin/Users/UserCard/UserCommonInfo/UserAbsences/graphql-types';
import { Loader } from '@common';
import { UserAbsences } from '@admin/Users/UserCard/UserCommonInfo/UserAbsences/UserAbsences';
import { isAfter, isBefore, isEqual } from 'date-fns';
import { BaseTimeIntervalInstanceWithExcusedAbsences } from '@admin/Users/UsersInterfaces';
import { usersStore } from '@admin/Users/UsersStore';
import { observer } from 'mobx-react';
import { sortBy } from 'lodash';

interface Props {
    id: string;
}

export const UserAbsencesApollo = observer((
    {
        id,
    }: Props,
): JSX.Element => {
    const userWithAbsences = usersStore.getUserWithAbsences();

    const {
        loading: isUserAbsencesLoading,
    } = useQuery<UserWithAbsences, UserWithAbsencesVariables>(GET_USER_ABSENCES, {
        variables: {
            id,
        },
        onCompleted: (data) => {
            // eslint-disable-next-line max-len
            const baseTimeIntervalInstancesWithExcusedAbsences = getBaseTimeIntervalInstancesWithExcusedAbsences(
                data.user.student?.baseTimeIntervalInstances ?? [],
                data.user.student?.excusedAbsences ?? [],
            );

            const sortedIntervals = sortBy(
                baseTimeIntervalInstancesWithExcusedAbsences,
                (interval) => interval.baseTimeIntervalInstance.spaceBaseTimeInterval.order,
            ).reverse();

            usersStore.setBaseTimeIntervalInstancesWithExcusedAbsences(
                sortedIntervals,
            );
            usersStore.setUserWithAbsences(data);
        },
    });

    const areAbsencesVisible = userWithAbsences
        && userWithAbsences?.user.student?.baseTimeIntervalInstances.length !== 0;

    const [updateUserAbsencesMutation] = useMutation<
    UpdateStudentExcusedAbsencesVariables
    >(UPDATE_USER_ABSENCES);

    const updateUserAbsences = (
        input: UpdateStudentExcusedAbsencesVariables,
    ) => updateUserAbsencesMutation(
        {
            variables: {
                ...input,
            },
        },
    );

    return (
        <>
            {
                !isUserAbsencesLoading ? (
                    <>
                        {
                            areAbsencesVisible ? (
                                <UserAbsences
                                    updateStudentAbsences={updateUserAbsences}
                                />
                            ) : null
                        }
                    </>
                ) : <Loader />
            }
        </>
    );
});

function getBaseTimeIntervalInstancesWithExcusedAbsences(
    baseTimeIntervalInstances: BaseTimeIntervalInstances[],
    allExcusedAbsences: ExcusedAbsences[],
): BaseTimeIntervalInstanceWithExcusedAbsences[] {
    const baseTimeIntervalInstancesWithExcusedAbsences:
    BaseTimeIntervalInstanceWithExcusedAbsences[] = baseTimeIntervalInstances
        .map(({ baseTimeIntervalInstance }) => {
            // eslint-disable-next-line max-len
            const currentBaseTimeIntervalInstanceAbsences = getCurrentBaseTimeIntervalInstanceExcusedAbsences(
                baseTimeIntervalInstance,
                allExcusedAbsences,
            );

            return {
                baseTimeIntervalInstance,
                excusedAbsences: currentBaseTimeIntervalInstanceAbsences,
            };
        });

    return baseTimeIntervalInstancesWithExcusedAbsences;
}

function getCurrentBaseTimeIntervalInstanceExcusedAbsences(
    baseTimeIntervalInstance: BaseTimeIntervalInstance,
    excusedAbsences: ExcusedAbsences[],
) {
    const {
        startDate,
        endDate,
    } = baseTimeIntervalInstance;

    const baseTimeIntervalInstanceStartDate = getDateFromDateString(startDate);
    const baseTimeIntervalInstanceEndDate = getDateFromDateString(endDate);

    const currentBaseTimeIntervalInstanceExcusedAbsences = excusedAbsences.filter(
        (excusedAbsence) => {
            const excusedAbsenceStartDate = getDateFromDateString(excusedAbsence.startDate);
            const excusedAbsenceEndDate = getDateFromDateString(excusedAbsence.endDate);

            const isStartDateBefore = isBefore(
                baseTimeIntervalInstanceStartDate,
                excusedAbsenceStartDate,
            );

            const isStartDateEqual = isEqual(
                baseTimeIntervalInstanceStartDate,
                excusedAbsenceStartDate,
            );

            const isEndDateAfter = isAfter(
                baseTimeIntervalInstanceEndDate,
                excusedAbsenceEndDate,
            );

            const isEndDateEqual = isEqual(
                baseTimeIntervalInstanceEndDate,
                excusedAbsenceEndDate,
            );

            return (isStartDateBefore || isStartDateEqual) && (isEndDateAfter || isEndDateEqual);
        },
    );

    return currentBaseTimeIntervalInstanceExcusedAbsences;
}

function getDateFromDateString(
    date: string,
): Date {
    return new Date(date);
}
