import React, { ChangeEvent, Dispatch, SetStateAction } from 'react';
import { cloneDeep } from 'lodash';
import { nanoid } from 'nanoid';

import { userCardStore } from '@admin/Users/store/userCardStore';
import { IconDeprecated } from '@common';
import { Interval } from './Interval';
import {
    DaySchedule, getFormatedDate, WeekType, WorkingInterval,
} from '../modelTimePicker';

import classes from './WorkingIntervals.module.scss';

interface Props {
    daySchedule: DaySchedule,
    daySchedules: DaySchedule[],
    typeWeek: WeekType,
    setDaySchedules: Dispatch<SetStateAction<DaySchedule[]>>,
}

function getDayScheduleIndex(targetDaySchedule: DaySchedule, daySchedules: DaySchedule[]) {
    return daySchedules.findIndex(
        (daySchedule) => daySchedule.weekDay === targetDaySchedule.weekDay,
    );
}

export function WorkingIntervals({
    daySchedule, daySchedules, setDaySchedules, typeWeek,
}: Props) {
    const EXAMPLE_INTERVAL = {
        id: '',
        start: '08:30:00.000Z',
        end: '18:00:00.000Z',
        weekType: typeWeek,
    };
    const getDataForManipulation = (): [number, DaySchedule[], DaySchedule] => {
        const dayScheduleIndex = getDayScheduleIndex(daySchedule, daySchedules);
        const copyDaySchedules = cloneDeep(daySchedules);
        const copyDaySchedule = cloneDeep(daySchedule);
        return [dayScheduleIndex, copyDaySchedules, copyDaySchedule];
    };
    const updateDaySchedules = (dayScheduleIndex: number,
        copyDaySchedules: DaySchedule[],
        copyDaySchedule: DaySchedule) => {
        if (dayScheduleIndex === -1) {
            copyDaySchedules.push(copyDaySchedule);
        } else {
            // eslint-disable-next-line no-param-reassign
            copyDaySchedules[dayScheduleIndex] = copyDaySchedule;
        }
        setDaySchedules(copyDaySchedules);
    };

    const addInterval = (interval: WorkingInterval) => {
        const [dayScheduleIndex, copyDaySchedules, copyDaySchedule] = getDataForManipulation();
        copyDaySchedule.workingIntervals.push(interval);
        updateDaySchedules(dayScheduleIndex, copyDaySchedules, copyDaySchedule);
    };

    const deleteInterval = (intervalIndex: number) => {
        const [dayScheduleIndex, copyDaySchedules, copyDaySchedule] = getDataForManipulation();
        copyDaySchedule.workingIntervals.splice(intervalIndex, 1);
        updateDaySchedules(dayScheduleIndex, copyDaySchedules, copyDaySchedule);
        userCardStore.updateWorkingInterval(typeWeek, copyDaySchedules);
    };

    const updateInterval = (interval: WorkingInterval, intervalIndex: number) => {
        const [dayScheduleIndex, copyDaySchedules, copyDaySchedule] = getDataForManipulation();
        copyDaySchedule.workingIntervals[intervalIndex] = interval;
        updateDaySchedules(dayScheduleIndex, copyDaySchedules, copyDaySchedule);
    };
    return (
        <div className={classes.workingIntervals}>
            <div className={classes.workingIntervals__title}>
                Рабочее время
            </div>
            {daySchedule.workingIntervals.map((interval, index) => (
                <div className={classes.workingIntervals__interval} key={interval.id}>
                    <Interval
                        interval={interval}
                        onChangeStart={(e: ChangeEvent<HTMLInputElement>) => {
                            const copyInterval = cloneDeep(interval);
                            let inputData = e.target.value;
                            if (inputData.length === 2) {
                                inputData += ':';
                            }
                            copyInterval.start = getFormatedDate(inputData);
                            updateInterval(copyInterval, index);
                        }}
                        onChangeEnd={(e: ChangeEvent<HTMLInputElement>) => {
                            const copyInterval = cloneDeep(interval);
                            let inputData = e.target.value;
                            if (inputData.length === 2) {
                                inputData += ':';
                            }
                            copyInterval.end = getFormatedDate(inputData);
                            updateInterval(copyInterval, index);
                        }}
                        deleteInterval={() => deleteInterval(index)}
                    />
                </div>
            ))}
            <div
                className={classes.workingIntervals__addInterval}
                onClick={() => {
                    const interval = { ...EXAMPLE_INTERVAL };
                    interval.id = nanoid();
                    addInterval(interval);
                }}
            >
                Добавить рабочий промежуток
                <IconDeprecated
                    id="yellowPlus"
                    className={classes.workingIntervals__addIntervalIcon}
                />
            </div>
        </div>
    );
}
