import React from 'react';
import { observer } from 'mobx-react';
import cn from 'classnames';

import { evaluationStore, StudentsTab } from '../../Store';

import classes from './EvaluationStudentsTabs.module.scss';

type StudentsTabName = [StudentsTab, string];

export const EvaluationStudentsTabs = observer((): JSX.Element => {
    const studentsTabNames: StudentsTabName[] = [
        [StudentsTab.NOT_EVALUATED, `Неоцененных (${evaluationStore.notEvaluatedStudents.length})`],
        [StudentsTab.EVALUATED, `Оцененных (${evaluationStore.evaluatedStudents.length})`],
    ];

    return (
        <div className={classes.evaluationStudentsTabs}>
            {studentsTabNames.map(([studentsTab, tabName]) => (
                <div
                    onClick={() => evaluationStore.selectStudentsTab(studentsTab)}
                    className={cn(classes.tab, {
                        [classes.tab_selected]: evaluationStore.isTabSelected(studentsTab),
                    })}
                    key={studentsTab}
                >
                    {tabName}
                </div>
            ))}
        </div>
    );
});
