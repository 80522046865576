import { makeAutoObservable } from 'mobx';
import { isAfter, isEqual, isBefore } from 'date-fns';
import { maxBy, minBy, sortBy } from 'lodash';

export enum GenerationProcessStatus {
    Creation = 'creation',
    Correction = 'correction',
    Preparing = 'preparing',
    Generation = 'generation',
    GenerationReady = 'generationReady',
    Finalize = 'finalize',
    Generated = 'generated',
}

export enum ScheduleGenerationProcessStatus {
    Correction = 'correction',
    Preparing = 'preparing',
    Generation = 'generation',
    GenerationReady = 'generationReady',
    GenerationFailed = 'generationFailed',
    Uploading = 'uploading',
    Uploaded = 'uploaded',
    Generated = 'generated',
}

export const GENERATION_STATUSES = [
    ScheduleGenerationProcessStatus.Preparing,
    ScheduleGenerationProcessStatus.Generation,
    ScheduleGenerationProcessStatus.GenerationReady,
    ScheduleGenerationProcessStatus.GenerationFailed,
];
export const GENERATED_STATUSES = [
    GenerationProcessStatus.Generated,
    ScheduleGenerationProcessStatus.GenerationFailed,
];

export const PREPARING_STATUSES = [
    ScheduleGenerationProcessStatus.Correction,
    ScheduleGenerationProcessStatus.Preparing,
];

export interface ConstraintMatchCount {
    constraintName: string,
    scoreType: string,
    matchCount: number,
}

export interface ConstraintMatch {
    constraintMatchCountList: ConstraintMatchCount[],
}

export interface ScheduleGenerationProcess {
    id: string;
    name: string;
    from: Date;
    to: Date;
    status: ScheduleGenerationProcessStatus;
    validationErrors?: string[];
    scheduleGeneratorErrors?: ScheduleGeneratorErrors;
    constraintMatch: ConstraintMatch;
}

export interface ScheduleGeneratorErrors {
    scoreLog: SolutionScoreLog[];
    status: string;
    errorMessage: string;
}

interface SolutionScoreLog {
    time: Date;
    score: string;
}

export interface BaseTimeIntervalInstanceParams {
    id: string;
    startDate: string;
    endDate: string;
    scheduleGenerationProcesses: ScheduleGenerationProcess[];
}

export class BaseTimeIntervalInstanceModel {
    public readonly id!: string;

    public readonly from!: Date;

    public readonly to!: Date;

    public scheduleGenerationProcesses!: ScheduleGenerationProcess[];

    public selectedProcess?: ScheduleGenerationProcess;

    private startOfFirstProcess!: Date;

    private startOfLastProcess!: Date;

    constructor({
        id,
        startDate,
        endDate,
        scheduleGenerationProcesses,
    }: BaseTimeIntervalInstanceParams) {
        this.id = id;
        this.from = new Date(startDate);
        this.to = new Date(endDate);
        this.updateProcesses(scheduleGenerationProcesses);

        makeAutoObservable(this);
    }

    public updateProcesses(scheduleGenerationProcesses: ScheduleGenerationProcess[]) {
        this.scheduleGenerationProcesses = sortBy(scheduleGenerationProcesses, 'from')
            .map(item => ({
                ...item, from: new Date(item.from), to: new Date(item.to),
            }));

        this.startOfFirstProcess = minBy(scheduleGenerationProcesses, 'from')?.from ?? this.from;
        this.startOfLastProcess = maxBy(scheduleGenerationProcesses, 'from')?.from ?? this.to;

        this.selectedProcess = this.currentProcess;
    }

    public get processesCount(): number {
        return this.scheduleGenerationProcesses.length;
    }

    public get currentProcess(): ScheduleGenerationProcess | undefined {
        const currentProcess = this.scheduleGenerationProcesses.find(this.isCurrentInterval);
        if (!currentProcess) {
            const earliestProcess = minBy(this.scheduleGenerationProcesses, 'from');
            const latestProcess = maxBy(this.scheduleGenerationProcesses, 'to');
            if (latestProcess && isAfter(Date.now(), latestProcess.to)) {
                return latestProcess;
            }
            return earliestProcess;
        }
        const nextProcess = minBy(
            this.scheduleGenerationProcesses
                .filter(({ from }) => isAfter(from, currentProcess.from)),
            'from',
        );

        return nextProcess ?? currentProcess;
    }

    private isCurrentInterval({ from, to }: { from: Date, to: Date }): boolean {
        const now = Date.now();
        return (isAfter(now, from) || isEqual(now, from))
            && (isBefore(now, to) || isEqual(now, to));
    }

    public get selectedProcessStartDate(): Date | undefined {
        return this.selectedProcess ? new Date(this.selectedProcess.from) : undefined;
    }

    public get selectedProcessEndDate(): Date | undefined {
        return this.selectedProcess ? new Date(this.selectedProcess.to) : undefined;
    }

    public setSelectedProcess(process: ScheduleGenerationProcess): void {
        this.selectedProcess = process;
    }

    public getNumOfProcess(processId: string): number {
        const process = this.scheduleGenerationProcesses.find(({ id }) => processId === id);
        return process ? this.scheduleGenerationProcesses.indexOf(process) + 1 : 0;
    }
}
