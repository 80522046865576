import { observer } from 'mobx-react';
import React from 'react';

import colors from '../../store/ColorConstans';

interface Props {
    width?: number;
    height?: number;
    firstColor?: string;
    secondColor?: string;
    thirdColor?: string;
    className?: string;
    handler?: () => void;
}

export const NoteSheetIcon = observer(({
    width = 33,
    height = 25,
    firstColor = colors.firstColor,
    secondColor = colors.secondColor,
    thirdColor = colors.thirdColor,
    className,
    handler,
}: Props): JSX.Element => (
    <div className={className} style={{ height: `${height}px`, width: `${width}px`, cursor: 'pointer' }} onClick={handler}>
        <svg width="33" height="25" viewBox="0 0 33 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.19572 6.25C6.92309 6.25 6.66163 6.35975 6.46885 6.5551C6.27607 6.75045 6.16776 7.0154 6.16776 7.29167C6.16776 7.56793 6.27607 7.83289 6.46885 8.02824C6.66163 8.22359 6.92309 8.33333 7.19572 8.33333H15.4194C15.692 8.33333 15.9535 8.22359 16.1463 8.02824C16.3391 7.83289 16.4474 7.56793 16.4474 7.29167C16.4474 7.0154 16.3391 6.75045 16.1463 6.5551C15.9535 6.35975 15.692 6.25 15.4194 6.25H7.19572Z" fill="url(#paint0_linear_386_2383)" />
            <path d="M7.19572 11.4583C6.92309 11.4583 6.66163 11.5681 6.46885 11.7634C6.27607 11.9588 6.16776 12.2237 6.16776 12.5C6.16776 12.7763 6.27607 13.0412 6.46885 13.2366C6.66163 13.4319 6.92309 13.5417 7.19572 13.5417H21.5872C21.8598 13.5417 22.1213 13.4319 22.3141 13.2366C22.5068 13.0412 22.6151 12.7763 22.6151 12.5C22.6151 12.2237 22.5068 11.9588 22.3141 11.7634C22.1213 11.5681 21.8598 11.4583 21.5872 11.4583H7.19572Z" fill="url(#paint1_linear_386_2383)" />
            <path d="M6.16776 17.7083C6.16776 17.4321 6.27607 17.1671 6.46885 16.9718C6.66163 16.7764 6.92309 16.6667 7.19572 16.6667H17.4753C17.748 16.6667 18.0094 16.7764 18.2022 16.9718C18.395 17.1671 18.5033 17.4321 18.5033 17.7083C18.5033 17.9846 18.395 18.2496 18.2022 18.4449C18.0094 18.6403 17.748 18.75 17.4753 18.75H7.19572C6.92309 18.75 6.66163 18.6403 6.46885 18.4449C6.27607 18.2496 6.16776 17.9846 6.16776 17.7083Z" fill="url(#paint2_linear_386_2383)" />
            <path d="M5.1398 0C3.77664 0 2.46931 0.548733 1.50541 1.52549C0.541513 2.50224 0 3.827 0 5.20833V19.7917C0 21.173 0.541513 22.4978 1.50541 23.4745C2.46931 24.4513 3.77664 25 5.1398 25H27.7549C29.1181 25 30.4254 24.4513 31.3893 23.4745C32.3532 22.4978 32.8947 21.173 32.8947 19.7917V5.20833C32.8947 3.827 32.3532 2.50224 31.3893 1.52549C30.4254 0.548733 29.1181 0 27.7549 0H5.1398ZM2.05592 5.20833C2.05592 4.37953 2.38083 3.58468 2.95917 2.99862C3.53751 2.41257 4.32191 2.08333 5.1398 2.08333H27.7549C28.5728 2.08333 29.3572 2.41257 29.9356 2.99862C30.5139 3.58468 30.8388 4.37953 30.8388 5.20833V19.7917C30.8388 20.6205 30.5139 21.4153 29.9356 22.0014C29.3572 22.5874 28.5728 22.9167 27.7549 22.9167H5.1398C4.32191 22.9167 3.53751 22.5874 2.95917 22.0014C2.38083 21.4153 2.05592 20.6205 2.05592 19.7917V5.20833Z" fill="url(#paint3_linear_386_2383)" />
            <defs>
                <linearGradient id="paint0_linear_386_2383" x1="35.2727" y1="11.0759" x2="2.66844" y2="27.822" gradientUnits="userSpaceOnUse">
                    <stop offset="0.130208" stopColor={firstColor} />
                    <stop offset="0.734375" stopColor={secondColor} />
                    <stop offset="0.932292" stopColor={thirdColor} />
                </linearGradient>
                <linearGradient id="paint1_linear_386_2383" x1="35.2727" y1="11.0759" x2="2.66844" y2="27.822" gradientUnits="userSpaceOnUse">
                    <stop offset="0.130208" stopColor={firstColor} />
                    <stop offset="0.734375" stopColor={secondColor} />
                    <stop offset="0.932292" stopColor={thirdColor} />
                </linearGradient>
                <linearGradient id="paint2_linear_386_2383" x1="35.2727" y1="11.0759" x2="2.66844" y2="27.822" gradientUnits="userSpaceOnUse">
                    <stop offset="0.130208" stopColor={firstColor} />
                    <stop offset="0.734375" stopColor={secondColor} />
                    <stop offset="0.932292" stopColor={thirdColor} />
                </linearGradient>
                <linearGradient id="paint3_linear_386_2383" x1="35.2727" y1="11.0759" x2="2.66844" y2="27.822" gradientUnits="userSpaceOnUse">
                    <stop offset="0.130208" stopColor={firstColor} />
                    <stop offset="0.734375" stopColor={secondColor} />
                    <stop offset="0.932292" stopColor={thirdColor} />
                </linearGradient>
            </defs>
        </svg>
    </div>
));
