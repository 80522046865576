import React from 'react';

// import cn from 'classnames';
import { observer } from 'mobx-react-lite';

import { RadioIcon } from '@common/svg';
import { DistributionType, DistributionModeRussian } from '../subSpaceTypes';
import Diagram from '../store/Diagram';

import classes from './ForkCard.module.scss';

export const ForkCardDistribution = observer((): JSX.Element => {
    const distributionActiveMode = Diagram.forkSettingForm.distributionType;
    return (
        <>
            <div className={classes.forkCard__subtitle}>
                Способ распределения по модулям:
            </div>
            <div className={classes.dialogFork__distribution_list}>
                {Object.entries(DistributionType).map(item => (
                    <div
                        key={item[0]}
                        className={classes.radio_container}
                    >
                        <label className={classes.custom_radio}>
                            <input
                                type="radio"
                                value={item[1]}
                                onChange={({ target }) => {
                                    const value = Object.entries(DistributionType)
                                        .find(mode => mode[1] === target.value)![1];
                                    Diagram.setDistributionType(value);
                                }}
                                id={item[1]}
                                checked={item[1] === distributionActiveMode}
                                className={classes.custom_radios}
                            />
                            <div>
                                <RadioIcon
                                    isChecked={item[1] === distributionActiveMode}
                                />
                            </div>
                        </label>
                        <div
                            className={classes.radio_title}
                        >
                            {Object.entries(DistributionModeRussian)
                                .find(mode => mode[0] === item[0])![1]}
                        </div>
                    </div>
                ))}
            </div>
        </>
    );
});
