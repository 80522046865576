import { useQuery } from '@apollo/client';
import { TerritorialZonesEquipmentPage } from 'src/deprecated-graphql-query-types';
import { GET_TERRITORIAL_ZONE } from '../queries';

export const useTerritorialZonesQuery = () => {
    const {
        data,
        loading,
        error,
        refetch,
    } = useQuery<TerritorialZonesEquipmentPage>(
        GET_TERRITORIAL_ZONE,
    );
    return { data, loading, error, refetch };
};
