import React from 'react';
import { observer } from 'mobx-react';

import { timeStore } from '../../Store';

import classes from './DateSelect.module.scss';

export const DateSelect = observer(() => (
    <div className={classes.dateSelect}>
        <button
            className={classes.dateSelect__button}
            type="button"
            name="left"
            onClick={timeStore.prevSelectedDate}
        >
            <svg width="18" height="12" viewBox="0 0 18 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M17.75 6.00079C17.75 6.16655 17.6842 6.32552 17.5669 6.44273C17.4497 6.55994 17.2908 6.62579 17.125 6.62579L2.38375 6.62579L6.3175 10.5583C6.43486 10.6756 6.50079 10.8348 6.50079 11.0008C6.50079 11.1668 6.43486 11.3259 6.3175 11.4433C6.20014 11.5606 6.04097 11.6266 5.875 11.6266C5.70903 11.6266 5.54986 11.5606 5.4325 11.4433L0.432499 6.44329C0.374296 6.38523 0.328117 6.31626 0.29661 6.24033C0.265102 6.1644 0.248882 6.083 0.248882 6.00079C0.248882 5.91858 0.265102 5.83718 0.29661 5.76125C0.328117 5.68532 0.374296 5.61635 0.432499 5.55829L5.4325 0.558289C5.54986 0.440931 5.70903 0.375 5.875 0.375C6.04097 0.375 6.20014 0.440931 6.3175 0.558289C6.43486 0.675648 6.50079 0.83482 6.50079 1.00079C6.50079 1.16676 6.43486 1.32593 6.3175 1.44329L2.38375 5.37579L17.125 5.37579C17.2908 5.37579 17.4497 5.44164 17.5669 5.55885C17.6842 5.67606 17.75 5.83503 17.75 6.00079Z" fill="url(#left-arrow)" />
                <defs>
                    <linearGradient id="left-arrow" x1="9.99634" y1="12.4399" x2="6.66887" y2="-0.819177" gradientUnits="userSpaceOnUse">
                        <stop offset="0.130208" stopColor="#0B42DC" />
                        <stop offset="0.734375" stopColor="#57B2C6" />
                        <stop offset="0.932292" stopColor="#EED660" />
                    </linearGradient>
                </defs>
            </svg>
        </button>

        <div className={classes.dateSelect__view}>
            {timeStore.getDateSelectView()}
        </div>

        <button
            className={classes.dateSelect__button}
            type="button"
            name="right"
            onClick={timeStore.nextSelectedDate}
        >
            <svg width="19" height="13" viewBox="0 0 19 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M0.25 6.625C0.25 6.44821 0.317725 6.27867 0.438276 6.15366C0.558828 6.02865 0.722331 5.95843 0.892816 5.95843L16.0543 5.95843L12.0084 1.76435C11.8877 1.63918 11.8199 1.46942 11.8199 1.29241C11.8199 1.1154 11.8877 0.945645 12.0084 0.82048C12.1291 0.695316 12.2928 0.625 12.4635 0.625C12.6342 0.625 12.7979 0.695316 12.9186 0.82048L18.0611 6.15307C18.121 6.21498 18.1685 6.28854 18.2009 6.36952C18.2333 6.45051 18.25 6.53732 18.25 6.625C18.25 6.71268 18.2333 6.79949 18.2009 6.88047C18.1685 6.96146 18.121 7.03501 18.0611 7.09693L12.9186 12.4295C12.7979 12.5547 12.6342 12.625 12.4635 12.625C12.2928 12.625 12.1291 12.5547 12.0084 12.4295C11.8877 12.3044 11.8199 12.1346 11.8199 11.9576C11.8199 11.7806 11.8877 11.6108 12.0084 11.4857L16.0543 7.29157L0.892816 7.29157C0.722331 7.29157 0.558828 7.22134 0.438276 7.09634C0.317725 6.97133 0.25 6.80179 0.25 6.625Z" fill="url(#right-arrow)" />
                <defs>
                    <linearGradient id="right-arrow" x1="8.22468" y1="-0.24247" x2="11.8883" y2="13.8358" gradientUnits="userSpaceOnUse">
                        <stop offset="0.130208" stopColor="#0B42DC" />
                        <stop offset="0.734375" stopColor="#57B2C6" />
                        <stop offset="0.932292" stopColor="#EED660" />
                    </linearGradient>
                </defs>
            </svg>
        </button>
    </div>
));
