import React from 'react';
import { observer } from 'mobx-react';
import cn from 'classnames';

import { EvaluatedLocker } from '../EvaluatedLocker';
// eslint-disable-next-line import/no-cycle
import { eventsStore } from '../../../../../../../../Store';

import classes from './GradeRange.module.scss';
import { InputRange } from './InputRange';

interface Props {
    status: boolean;
    isEditing: boolean;
    skillId: string;
    skillName: string;
    isEvaluated: boolean;
    level: number;
    maxLevel: number;
    creditedLevel: number;
    changeGrade(skillId: string, level: number, isCredit: boolean): void;
    toggleGradeEvaluating(skillId: string): void;
}

export const GradeRange = observer(({
    status,
    isEditing,
    skillId,
    skillName,
    isEvaluated,
    level,
    maxLevel,
    creditedLevel,
    changeGrade,
    toggleGradeEvaluating,
}: Props): JSX.Element => {
    function handleChangeGrade(event: React.ChangeEvent<HTMLInputElement>): void {
        const newLevel = Number(event.target.value);
        if (newLevel >= 0 && newLevel <= maxLevel) {
            changeGrade(skillId, newLevel, isLevelCredited(newLevel));
        }
    }

    function isLevelCredited(newLevel: number): boolean {
        return newLevel >= creditedLevel;
    }

    const rangeViewStyles = {
        width: `${(level / maxLevel) * 100}%`,
    };

    return (
        <div
            className={cn(classes.gradeRange, {
                [classes.gradeRange_isEvaluated]: isEvaluated,
            })}
        >
            <div className={classes.gradeRange__header}>
                <div
                    className={cn(classes.skillName, {
                        [classes.skillName_disabled]: !isEvaluated,
                    })}
                >
                    {skillName}
                </div>
                {(isEditing && isEvaluated && !status) && (
                    <div className={classes.gradeRange__evaluationPointLevel}>
                        (предполагаемая оценка {creditedLevel})
                    </div>
                )}
                {!isEvaluated && (
                    <div className={classes.gradeRange__notEvaluatedText}>
                        (не оценивается)
                    </div>
                )}
            </div>

            {isEvaluated && (
                <div className={classes.range}>
                    {(isEditing && !status) && (
                        <input
                            type="text"
                            min="0"
                            max={maxLevel}
                            step="1"
                            className={classes.numberInput}
                            value={level.toString()}
                            onChange={handleChangeGrade}
                        />
                    )}

                    <div
                        className={cn(classes.range__container, {
                            [classes.range__container_isEditing]: isEditing && !status,
                        })}
                    >
                        <div className={cn(classes.rangeBlock, {
                            [classes.rangeBlock__isStatus]: status,
                            [classes.rangeBlock__isEvaluated]: isEvaluated && !isEditing,
                        })}
                        >
                            <div className={classes.range__header}>
                                <div className={classes.range__label}>Уровень</div>
                                <div className={classes.range__quantity}>{level}
                                    <span className={
                                        classes.range__quantityLevel
                                    }
                                    >/{maxLevel}
                                    </span>
                                </div>
                            </div>

                            {(isEditing && !status)
                                ? (
                                    <InputRange
                                        maxLevel={maxLevel}
                                        level={level}
                                        handleChangeGrade={handleChangeGrade}
                                    />
                                )
                                : (
                                    <div className={classes.rangeView}>
                                        <div
                                            className={classes.rangeView__progress}
                                            style={rangeViewStyles}
                                        />
                                    </div>
                                )}
                        </div>

                        {eventsStore.isMultipleTeachersEvaluate() && (isEditing && !status) && (
                            <EvaluatedLocker
                                isEvaluated={isEvaluated}
                                toggleGradeEvaluating={() => toggleGradeEvaluating(skillId)}
                            />
                        )}
                    </div>
                </div>
            )}

            {eventsStore.isMultipleTeachersEvaluate() && isEditing && !isEvaluated && (
                <EvaluatedLocker
                    isEvaluated={isEvaluated}
                    toggleGradeEvaluating={() => toggleGradeEvaluating(skillId)}
                />
            )}
        </div>
    );
});
