import React, { useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react';

import { gql, useLazyQuery } from '@apollo/client';
import { Title, TitleTypes } from '@common/Title';
import { Select } from '@admin/NewModule/Select';
import { ActionButton, ActionTypeOfButton } from '@common/ActionButton';
import { SavedEvent } from '@admin/NewModule/SavedEvent';
import { dictionaryStore, moduleStore, Validator } from '@admin/NewModule/Store';
import { Section } from '@admin/NewModule/Section';
import {
    RolesForm,
    RolesFormInitialData,
} from '@admin/NewModule/Meeting/MeetingTeachersRoles/RolesForm';
import { normalBoldJSX } from '@admin/NewModule/utils';
import { Textarea } from '@admin/NewModule/Textarea';
import { useParams } from 'react-router-dom';
import { EvaluationPointSkills } from './EvaluationPointSkills';

import style from './EvaluationPoint.module.scss';

interface RouteParams {
    id: string
    eventId: string
}

interface Props {
    id: string;
    opened: boolean;

    setOpened(id: string): void;

    setEventRef({
        ref,
        id,
    }: { ref: React.MutableRefObject<HTMLDivElement | null>, id: string }): void;
}

const IS_REMOVABLE_EVALUATION_POINT = gql`
    query isRemovableEvaluationPoint($id: String!) {
        isRemovableEvaluationPoint(id: $id)
    }
`;

export const EvaluationPoint = observer(({
    id, setEventRef, opened, setOpened,
}: Props): JSX.Element => {
    const { id: moduleId, eventId } = useParams<RouteParams>();

    const [errorMessage, setErrorMessage] = useState('');

    const module = moduleStore.moduleModel;

    const evaluationPoint = module.getEvaluationPoint(id)!;

    const topRef = useRef<null | HTMLDivElement>(null);
    const scrollToTop = () => topRef.current?.scrollIntoView({ behavior: 'smooth' });

    const [isRemovableEvaluationPoint, { data, error }] = useLazyQuery(
        IS_REMOVABLE_EVALUATION_POINT, { variables: { id } },
    );

    useEffect(() => {
        if (data?.isRemovableEvaluationPoint) {
            module.removeEvaluationPoint(id);
        }
        if (error) {
            setErrorMessage(error?.graphQLErrors[0]?.message);
        }
    }, [data, error]);

    useEffect(() => {
        if (opened) scrollToTop();
    }, [opened, evaluationPoint.previousEvent?.id]);

    useEffect(() => {
        if (id === eventId) {
            topRef.current?.scrollIntoView({ behavior: 'auto' });
        }
    }, []);

    const pointTeacherRoles = evaluationPoint.evaluationPointTeacherRoles?.map(role => ({
        id: role.teacherRole.id, name: role.teacherRole.name, inputValue: String(role.count),
    }));
    const possibleTeachers = moduleStore.moduleModel.options.teachersRoles
        ?.filter(item => (item.maxCount ?? 0) > 0)
        .map(role => ({ ...role, ...role.teacherRole }));

    const eventRef = useRef<null | HTMLDivElement>(null);
    setEventRef({ ref: eventRef, id });

    const handleChangeSavedEvaluationPoint = () => {
        setOpened(id);
    };

    const handleChangeNewEvaluationPoint = () => {
        setOpened(id);
    };

    return (
        <div className={style.evaluationPoint__block} ref={eventRef}>
            {evaluationPoint.previousEvent?.id && <Arrow />}
            {!opened
                ? (
                    <div className={style.evaluationPoint__saved} ref={topRef}>
                        <SavedEvent
                            numberOfEvent={evaluationPoint.evaluationPointNumber}
                            title="Точка оценки"
                            topic={evaluationPoint.topic ?? ''}
                            handleEditEvent={
                                moduleId
                                    ? handleChangeSavedEvaluationPoint
                                    : handleChangeNewEvaluationPoint
                            }
                            valid={Validator.validateEvaluationPoint(evaluationPoint).valid}
                        />
                    </div>
                )
                : (
                    <div className={style.evaluationPoint} ref={topRef}>
                        <Title>{`Точка оценки ${evaluationPoint.evaluationPointNumber}`}</Title>
                        <div className={style.evaluationPoint__input}>
                            <Textarea
                                name="name"
                                label="Название:"
                                placeholder="Введите название точки оценки"
                                value={evaluationPoint.topic}
                                onChange={({ target }) => module.updateEvaluationPoint(
                                    id, { topic: target.value },
                                )}
                            />
                        </div>
                        <div className={style.evaluationPoint__container}>
                            <Textarea
                                modifier="description"
                                name="description"
                                label="Описание способа оценки:"
                                placeholder="Введите описание способа оценки"
                                value={evaluationPoint.description ?? ''}
                                onChange={({ target }) => module.updateEvaluationPoint(
                                    id, { description: target.value },
                                )}
                            />
                        </div>
                        <div className={style.evaluationPoint__container}>
                            <Title type={TitleTypes.Subtitle}>
                                Укажите роли преподавателей для этой точки оценки:
                            </Title>
                            {possibleTeachers.length === 0 ? (
                                <p className={style.evaluationPoint__warning}>
                                    В модуль не добавлен ни один преподаватель.
                                </p>
                            ) : (
                                <Section
                                    initialData={RolesFormInitialData}
                                    list={pointTeacherRoles ?? []}
                                    form={RolesForm}
                                    formProps={{
                                        mainOptions: possibleTeachers,
                                        placeholderInput: 'Кол-во',
                                        selectName: 'teacherRoleId',
                                        inputName: 'count',
                                        eventId: id,
                                    }}
                                    onChange={roles => module
                                        .updateEvaluationPoint(
                                            id,
                                            {
                                                evaluationPointTeacherRoles: roles.map(role => ({
                                                    teacherRole: {
                                                        id: role.id,
                                                        name: role.name,
                                                    },
                                                    count: Number(role.inputValue),
                                                })),
                                            },
                                        )}
                                    formatItem={({
                                        inputValue, name,
                                    }) => normalBoldJSX(name, inputValue)}
                                    validate={(item) => item.id !== '' && item.inputValue !== ''}
                                />
                            )}
                            {!Validator.validateEventTeacherRoles(evaluationPoint, []).valid && (
                                <p className={style.evaluationPoint__warning}>
                                    Вы указываете больше людей с определенными ролями, чем есть в
                                    модуле
                                </p>
                            )}
                        </div>
                        <EvaluationPointSkills id={id} />
                        <div className={style.evaluationPoint__container}>
                            <Select
                                value={evaluationPoint.duration?.id}
                                options={dictionaryStore.durations}
                                name="duration"
                                label="Длительность точки оценки для преподавателя:"
                                onChange={({ target }) => module
                                    .updateEvaluationPoint(
                                        id,
                                        {
                                            duration: {
                                                id: target.value,
                                                name: target.options[target.options.selectedIndex]
                                                    .text,
                                            },
                                        },
                                    )}
                                placeholder="Укажите длительность"
                            />
                        </div>
                        <div className={style.evaluationPoint__buttons}>
                            <ActionButton
                                actionType={ActionTypeOfButton.SECONDARY}
                                className={style.evaluationPoint__buttons_margin_right}
                                onClick={isRemovableEvaluationPoint}
                            >
                                Удалить точку оценки
                            </ActionButton>
                            <ActionButton
                                onClick={() => {
                                    setOpened('');
                                    scrollToTop();
                                }}
                            >
                                Сохранить точку оценки
                            </ActionButton>
                        </div>
                        <p className={style.evaluationPoint__error}>
                            {errorMessage}
                        </p>
                    </div>
                )}
        </div>
    );
});

function Arrow() {
    const arrowWidth = 4;
    const arrowHeight = 6.5;
    const dArrow = `M 15 60 m -${arrowHeight} -${arrowWidth} l ${arrowHeight} ${arrowWidth} l ${arrowHeight} -${arrowWidth}`;
    return (
        <div className={style.evaluationPoint__arrow}>
            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="62">
                <g>
                    <path d="M 15 0 v 60" stroke="black" strokeWidth="1" />
                    <path d={dArrow} stroke="black" strokeWidth="2" fillOpacity="0" />
                </g>
            </svg>
        </div>
    );
}
