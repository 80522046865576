import React from 'react';
import { observer } from 'mobx-react';

import { EvaluationStudent } from './EvaluationStudent';
import { evaluationStore } from '../../Store';

import classes from './EvaluationStudents.module.scss';

export const EvaluationStudents = observer((): JSX.Element => (
    <>
        <ul className={classes.evaluationStudents__list}>
            {
                evaluationStore.getFilteredStudentsBySearchQuery()
                    .map((
                        {
                            student: { id,
                                user: {
                                    firstName,
                                    lastName,
                                    patronymic,
                                },
                            },
                            status,
                            note,
                        },
                    ) => (
                        <EvaluationStudent
                            key={id}
                            id={id}
                            firstName={firstName}
                            lastName={lastName}
                            patronymic={patronymic}
                            status={status}
                            note={note}
                        />
                    ))
            }
        </ul>
    </>
));
