import React from 'react';
import { ActionButton, ActionTypeOfButton } from '@common/ActionButton';

import classes from './AuthPage.module.scss';

interface Props {
    handler: () => void;
    title: string;
}

export function OAuthButton({ handler, title }: Props): JSX.Element {
    return (
        <ActionButton
            onClick={handler}
            actionType={ActionTypeOfButton.SECONDARY}
            className={classes.authPage__fullForm_buttonMargin}
        >
            {title}
        </ActionButton>
    );
}
