import React from 'react';

// import { IconDeprecated } from '@common/IconDeprecated';

import cn from 'classnames';
import classes from './ModuleSpecificMeeting.module.scss';

import { ModulePrerequisiteSkillsBlock } from './ModulePrerequisiteSkillsBlock';
import { ModuleOutputSkillsBlock } from './ModuleOutputSkillsBlock';
import { ModuleEvaluationsPointSkillsBlock } from './ModuleEvaluationsPointSkillsBlock';

interface Props {
    openedMeeting: any;
    setOpenedMeeting: any;
    meetingIndex: number;
    meeting: any;
    skillTypes: any;
    allSkillsList: any;
}

export function ModuleSpecificMeeting({
    openedMeeting,
    setOpenedMeeting,
    meetingIndex,
    meeting,
    skillTypes,
    allSkillsList,
}: Props):JSX.Element {
    return (
        <div className={classes.moduleSpecificMeeting}>
            <div
                className={classes.moduleSpecificMeeting__title}
                onClick={() => setOpenedMeeting(
                    openedMeeting === meetingIndex ? null : meetingIndex,
                )}
            >
                <div className={classes.moduleSpecificMeeting__name}>
                    {meeting.topic ? (
                        <p>
                            <b>Встреча {meeting.meetingNumber}: </b>
                            {`${meeting.topic}`}
                        </p>
                    ) : (
                        <p>Точка оценки</p>
                    )}
                </div>
                {/* <div className={cn(classes.moduleSpecificMeeting__iconContainer, {
                    [classes.moduleSpecificMeeting__iconContainer_rotate]:
                        openedMeeting === meetingIndex,
                })}
                >
                    <IconDeprecated
                        id="arrow"
                        className={classes.moduleSpecificMeeting__icon}
                    />
                </div> */}
            </div>
            <div className={cn(classes.moduleSpecificMeeting__options, {
                [classes.moduleSpecificMeeting__options_height_null]:
                openedMeeting !== meetingIndex,
            })}
            >
                <div className={classes.moduleSpecificMeeting__formatAndDuration}>
                    <div className={classes.moduleSpecificMeeting__format}>
                        {meeting.topic ? (
                            <>
                                <span><b>Формат:</b></span>
                                <span>{meeting.format.name}</span>
                            </>
                        ) : (
                            <>
                                <span><b>Название:</b></span>
                                <span>{meeting.name}</span>
                            </>
                        )}
                    </div>
                    <div className={classes.moduleSpecificMeeting__duration}>
                        <span><b>Продолжительность:</b></span>
                        <span>{meeting.duration.name} мин.</span>
                    </div>
                </div>
                <div className={classes.moduleSpecificMeeting__skillsList}>
                    {meeting.topic ? (
                        <>
                            <ModulePrerequisiteSkillsBlock
                                prerequisiteSkills={meeting.prerequisiteSkills}
                                skillTypes={skillTypes}
                                allSkillsList={allSkillsList}
                            />
                            <ModuleOutputSkillsBlock
                                outputSkills={meeting.outputSkills}
                                skillTypes={skillTypes}
                                allSkillsList={allSkillsList}
                            />
                        </>
                    ) : (
                        <ModuleEvaluationsPointSkillsBlock
                            evaluationPointSkills={meeting.evaluationPointSkills}
                            skillTypes={skillTypes}
                            allSkillsList={allSkillsList}
                        />
                    )}
                </div>
            </div>
        </div>
    );
}
