import React, { useEffect, useState } from 'react';

import {
    ScheduleGenerationProcessesSchedulePage_scheduleGenerationProcesses,
} from '../../../graphql-query-types';
import { CreateScheduleGenerationForm } from './CreateScheduleGenerationForm';
import { ScheduleGenerationList } from './ScheduleGenerationList';
import { ScheduleGeneration } from './ScheduleGeneration';
import { CustomScheduleGeneration } from './CustomScheduleGeneration';

interface Props {
    isSecret: boolean;
    baseTimeIntervalInstanceId: string;
    processes: ScheduleGenerationProcessesSchedulePage_scheduleGenerationProcesses[];
    refetchProcesses(): void;
}

export function ScheduleGenerationPage({
    processes,
    baseTimeIntervalInstanceId,
    isSecret,
    refetchProcesses,
}: Props) {
    const [process, setProcess] = useState<
    ScheduleGenerationProcessesSchedulePage_scheduleGenerationProcesses
    | undefined
    >();
    useEffect(() => {
        if (process) {
            setProcess(processes.find(({ id }) => id === process.id));
        }
    }, [processes]);

    return (
        <>
            {process ? (
                <ScheduleGeneration
                    isSecret={isSecret}
                    baseTimeIntervalInstanceId={baseTimeIntervalInstanceId}
                    process={process}
                    goBack={() => setProcess(undefined)}
                    refetchProcesses={refetchProcesses}
                />
            ) : (
                <>
                    <ScheduleGenerationList
                        processes={processes}
                        setProcess={setProcess}
                    />
                    <CreateScheduleGenerationForm
                        baseTimeIntervalInstanceId={baseTimeIntervalInstanceId}
                        refetchProcesses={refetchProcesses}
                    />
                </>
            )}
            {isSecret && process && (
                <CustomScheduleGeneration
                    process={process}
                    refetchProcesses={refetchProcesses}
                />
            )}
        </>
    );
}
