import React from 'react';
import { nanoid } from 'nanoid';
import { observer } from 'mobx-react';
import cn from 'classnames';

import { CheckboxRedesign } from '@common/CheckboxRedesign';

import {
    GroupedWavesWithSelectedStudents,
    StudentWithAdditionalFields,
    WaveWithSelectedStudents,
} from '@admin/WaveSelectionPage/Interfaces';
import { waveSelectionStore } from '@admin/WaveSelectionPage/WaveSelectionStore';
import { waveSelectionDataStore } from '@admin/WaveSelectionPage/WaveSelectionDataStore';
import { checkIfStudentIsUnique, getStudentsRemovedCurrentStudent } from '@admin/WaveSelectionPage/WaveSelectionPage';
import { getStudentRating } from '@admin/ModuleSelectionPage/ModuleSelectionPage';
import classes from './WaveStudentItem.module.scss';

interface Props {
    order: number;
    currentModuleId: string;
    wave: WaveWithSelectedStudents;
    currentStudent: StudentWithAdditionalFields;
}

export const WaveStudentItem = observer((
    {
        order,
        currentModuleId,
        currentStudent,
        wave,
    }: Props,
): JSX.Element => {
    const {
        user,
    } = currentStudent;

    const studentRating = getStudentRating(
        user.studentId ?? '',
        waveSelectionStore.getStudentsWithRatings(),
    );

    const isStudentUnique = checkIfStudentIsUnique(
        currentStudent,
        waveSelectionStore.getNotDistribStudents(),
        waveSelectionStore.getModuleWavesWithSelectedStudents(),
    );

    const studentInfo = `${user.lastName} ${user.firstName} ${user.patronymic ?? ''} ${isStudentUnique ? '' : user.studentId}`;
    const studentInfoWithOrder = `${order} ${user.lastName} ${user.firstName} ${user.patronymic ?? ''} ${isStudentUnique ? '' : user.studentId}`;

    const currentStudentInSelected = waveSelectionStore.getWaveSelectedStudents()
        .find((student) => student.user.studentId === currentStudent.user.studentId);

    const handleChangeIfStudentSelected = () => {
        const notDistributedStudentsRemovedCurrentStudent = getStudentsRemovedCurrentStudent(
            user.studentId ?? '',
            waveSelectionStore.getNotDistribStudents(),
        );

        const waveSelectedStudentsRemovedCurrentStudent = getStudentsRemovedCurrentStudent(
            user.studentId ?? '',
            waveSelectionStore.getWaveSelectedStudents(),
        );

        waveSelectionStore.setModuleWavesWithSelectedStudents(
            getWavesRemoveCurrentFromAdditional(
                currentStudent.user.studentId ?? '',
                currentModuleId,
                waveSelectionStore.getModuleWavesWithSelectedStudents(),
            ),
        );

        waveSelectionStore.setNotDistribStudents(
            notDistributedStudentsRemovedCurrentStudent,
        );

        waveSelectionStore.setIsRequiredStudentMoved(false);

        waveSelectionStore.setActiveCurrentModuleId('');

        waveSelectionStore.setWaveSelectedStudents(
            waveSelectedStudentsRemovedCurrentStudent,
        );
    };

    const handleChangeIfRequiredModule = () => {
        waveSelectionStore.setModuleWavesWithSelectedStudents(
            getCurrentModuleWavesAddedStudentsToAdditional(
                currentModuleId,
                wave.id,
                currentStudent,
                waveSelectionStore.getModuleWavesWithSelectedStudents(),
            ),
        );

        waveSelectionStore.setIsRequiredStudentMoved(true);
    };

    const handleChangeIfNotRequiredModule = () => {
        waveSelectionStore.setModuleWavesWithSelectedStudents(
            getModuleWavesAddedStudentToAdditional(
                wave.id,
                currentModuleId,
                currentStudent,
                waveSelectionStore.getModuleWavesWithSelectedStudents(),
            ),
        );

        waveSelectionStore.setNotDistribStudents(
            [
                {
                    ...currentStudent,
                    wasMoved: true,
                },
                ...waveSelectionStore.getNotDistribStudents(),
            ],
        );
    };

    const handleChangeIfStudentNotSelected = () => {
        waveSelectionStore.setWaveSelectedStudents(
            [
                ...waveSelectionStore.getWaveSelectedStudents(),
                {
                    ...currentStudent,
                    currentWaveId: wave.id,
                    currentModuleId,
                },
            ],
        );

        waveSelectionStore.setActiveCurrentModuleId(currentModuleId);

        if (waveSelectionDataStore.getURLParameters().isNotStudentChoice) {
            handleChangeIfRequiredModule();
        } else {
            handleChangeIfNotRequiredModule();
        }
    };

    const handleChange = () => {
        if (currentStudent.isSelected) {
            handleChangeIfStudentSelected();
        } else {
            handleChangeIfStudentNotSelected();
        }
    };

    return (
        <>
            <div
                className={cn(classes.item, {
                    [classes.item_borderTop]: order === 1,
                    [classes.item_selected]: currentStudent.wasMoved || currentStudent.isSelected,
                    [classes.item_fromWaves]: currentStudentInSelected,
                    [classes.item_disabled]: waveSelectionStore.getIsAllDisabled(),
                })}
            >

                {
                    !currentStudent.isSelected && (
                        <div className={classes.item__static}>

                            <div className={classes.item__name}>
                                {
                                    studentInfoWithOrder
                                }
                            </div>

                            <div className={classes.item__rating}>
                                {
                                    studentRating
                                }
                            </div>

                        </div>
                    )
                }

                <div
                    className={cn(classes.item__active, {
                        [classes.item_selected]: currentStudent.wasMoved
                            || currentStudent.isSelected,
                        [classes.displayBlock]: currentStudent.isSelected,
                        [classes.item_fromWaves]: currentStudentInSelected,
                    })}
                >
                    <CheckboxRedesign
                        id={`${currentStudent.user.id}_module_${nanoid(9)}`}
                        handleChangeCheckbox={() => handleChange()}
                        gap="7px"
                    >
                        <div className={classes.item__label}>

                            <div className={classes.item__name}>
                                {
                                    studentInfo
                                }
                            </div>

                            <div className={classes.item__rating}>
                                {
                                    studentRating
                                }
                            </div>

                        </div>
                    </CheckboxRedesign>
                </div>

            </div>
        </>
    );
});

function getWavesRemoveCurrentFromAdditional(
    currentStudentId: string,
    currentModuleId: string,
    modules: GroupedWavesWithSelectedStudents[],
) {
    return modules.map((module) => {
        if (checkIfCurrentStudentInModule(
            currentStudentId,
            module,
        ) && currentModuleId !== module.moduleId) return module;

        return {
            ...module,
            waves: module.waves.map((wave) => (
                {
                    ...wave,
                    students: getStudentsWithChangedSelectedCurrent(
                        currentStudentId,
                        wave.students,
                    ),
                    additionalStudents: wave.additionalStudents
                        .filter((student) => student.user.studentId !== currentStudentId),
                }
            )),
        };
    });
}

function getCurrentModuleWavesAddedStudentsToAdditional(
    currentModuleId: string,
    currentWaveId: string,
    currentStudent: StudentWithAdditionalFields,
    modules: GroupedWavesWithSelectedStudents[],
): GroupedWavesWithSelectedStudents[] {
    return modules.map((module) => {
        if (module.moduleId !== currentModuleId) return module;

        return {
            ...module,
            waves: module.waves.map((wave) => {
                if (wave.id === currentWaveId) {
                    return getWaveWithCurrentChangedSelectedStudent(
                        currentStudent.user.studentId ?? '',
                        wave,
                    );
                }

                return {
                    ...wave,
                    additionalStudents: [...wave.additionalStudents, currentStudent],
                };
            }),
        };
    });
}

function getModuleWavesAddedStudentToAdditional(
    currentWaveId: string,
    currentModuleId: string,
    currentStudent: StudentWithAdditionalFields,
    modules: GroupedWavesWithSelectedStudents[],
): GroupedWavesWithSelectedStudents[] {
    return modules.map((module) => {
        if (module.moduleId !== currentModuleId) {
            if (checkIfCurrentStudentInModule(
                currentStudent.user.studentId ?? '',
                module,
            )) return module;

            return {
                ...module,
                waves: module.waves.map((wave) => (
                    {
                        ...wave,
                        additionalStudents: [...wave.additionalStudents, currentStudent],
                    }
                )),
            };
        }

        return {
            ...module,
            waves: module.waves.map((wave) => {
                if (wave.id === currentWaveId) {
                    return getWaveWithCurrentChangedSelectedStudent(
                        currentStudent.user.studentId ?? '',
                        wave,
                    );
                }

                return {
                    ...wave,
                    additionalStudents: [...wave.additionalStudents, currentStudent],
                };
            }),
        };
    });
}

function getWaveWithCurrentChangedSelectedStudent(
    currentStudentId: string,
    wave: WaveWithSelectedStudents,
): WaveWithSelectedStudents {
    return {
        ...wave,
        students: getStudentsWithChangedSelectedCurrent(
            currentStudentId,
            wave.students,
        ),
    };
}

function checkIfCurrentStudentInModule(
    currentStudentId: string,
    module: GroupedWavesWithSelectedStudents,
) {
    return module.waves.some((wave) => (
        wave.students.some((student) => student.user.studentId === currentStudentId)
    ));
}

function getStudentsWithChangedSelectedCurrent(
    currentStudentId: string,
    students: StudentWithAdditionalFields[],
) {
    return students.map((student) => {
        if (student.user.studentId !== currentStudentId) return student;

        return {
            ...student,
            isSelected: !student.isSelected,
        };
    });
}
