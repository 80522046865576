import React from 'react';
import { observer } from 'mobx-react-lite';
import { useQuery } from '@apollo/client';
import { Loader } from '@common/Loader';
import { GET_USER_INFO } from '@common/qraphql/queries';

import UserView from '../store/UserView';
import { User } from './profileModels';
import { Profile } from './Profile';

export const ProfileApollo = observer(() => {
    const { data, loading } = useQuery<{ user: User; }>(GET_USER_INFO, {
        fetchPolicy: 'no-cache',
        variables: {
            id: UserView.user?.id ?? '',
        },
    });

    if (loading) return <Loader />;
    if (data) return <Profile userData={data.user} />;

    return null;
});
