import React from 'react';
import cln from 'classnames';
import CSS from 'csstype';
import { Subspace } from '../../interfaces';
import { SubspaceStatus } from '../../services/subspacesView';

interface Props {
    subspace: Subspace;
    subspaceTypeName: string;
    subspaceTypeColor: string;
    isElectiveSubspaceType: boolean,
    subspaceStatus: string;
    hoverSubspace(subspaceId: string): void;
    clearDiagramView(): void;
    showTrajectory(subspaceId: string): void;
    isEditMode: boolean;
    updateTempConnections(subspaceId: string): void;
}

export function DiagramSubspace({
    subspace: {
        id: subspaceId,
        name: subspaceName,
    },
    subspaceTypeName,
    subspaceTypeColor,
    isElectiveSubspaceType,
    subspaceStatus,
    hoverSubspace,
    clearDiagramView,
    showTrajectory,
    isEditMode,
    updateTempConnections,
}: Props): JSX.Element {
    const subspaceStyles: CSS.Properties = { border: `3px solid ${subspaceTypeColor}` };
    const subspaceText = `${subspaceTypeName}: ${subspaceName}`;
    const isMuted = isElectiveSubspaceType && subspaceStatus === SubspaceStatus.Muted;
    const subspaceClass = cln('subspace', { muted: isMuted });

    function handleSubspaceMouseEnter() {
        if (isElectiveSubspaceType) {
            hoverSubspace(subspaceId);
        }
    }

    function handleSubspaceMouseLeave() {
        if (isElectiveSubspaceType) {
            clearDiagramView();
        }
    }

    function handleSubspaceClick() {
        if (isElectiveSubspaceType) {
            if (!isEditMode) {
                showTrajectory(subspaceId);
            } else {
                updateTempConnections(subspaceId);
            }
        }
    }

    if (!isElectiveSubspaceType || subspaceStatus === SubspaceStatus.Active) {
        subspaceStyles.backgroundColor = subspaceTypeColor;
    }

    return (
        <div
            id={subspaceId}
            className={subspaceClass}
            style={subspaceStyles}
            onMouseEnter={handleSubspaceMouseEnter}
            onMouseLeave={handleSubspaceMouseLeave}
            onClick={handleSubspaceClick}
        >
            {subspaceText}
        </div>
    );
}
