import React, { useEffect, useRef, useState } from 'react';

import cn from 'classnames';
import { MainDropDownOption } from '@common/MainDropDown/MainDropDownOptions';
import classes from './MainDropDown.module.scss';

export type TMainDropDownOption = {
    id: string;
    name: string;
};

interface Props {
    optionsListClassname?: string;
    closeOnOptionSelect?: boolean;
    options?: TMainDropDownOption[];
    defaultOption?: TMainDropDownOption;
    additionalOptionHandleCLick?: (option: TMainDropDownOption) => void;
}

export function MainDropDown(
    {
        optionsListClassname,
        closeOnOptionSelect,
        options,
        defaultOption,
        additionalOptionHandleCLick,
    }: Props,
) {
    const [currentOption, setCurrentOption] = useState(defaultOption);
    const [isDropDownOpen, setIsDropDownOpen] = useState(false);
    const button = useRef(null);

    useEffect(() => {
        const bodyHandleCLick = (elem: MouseEvent) => {
            if (elem.target !== button.current) {
                setIsDropDownOpen(false);
            }
        };

        document.body.addEventListener('click', (e) => bodyHandleCLick(e));

        return () => {
            document.body.removeEventListener('click', (e) => bodyHandleCLick(e));
        };
    });

    const dropDownHandleCLick = () => {
        setIsDropDownOpen(!isDropDownOpen);
    };

    const optionHandleClick = (
        option: TMainDropDownOption,
    ) => {
        if (closeOnOptionSelect) {
            setIsDropDownOpen(false);
        }
        if (additionalOptionHandleCLick) {
            additionalOptionHandleCLick(option);
        }

        setCurrentOption(option);
    };

    const optionsList = options?.map((option) => (
        <MainDropDownOption
            key={option.id}
            option={option}
            optionHandleClick={optionHandleClick}
        />
    ));

    return (
        <div className={classes.dropDown}>

            <button
                className={cn(classes.dropDown__body, {
                    [classes.dropDown__body_default]: !isDropDownOpen,
                    [classes.dropDown__body_gradient]: isDropDownOpen,
                })}
                type="button"
                onClick={() => dropDownHandleCLick()}
                ref={button}
            >
                <div className={classes.dropDown__body_container}>

                    <div className={classes.dropDown__currentOption}>
                        {
                            currentOption?.name
                        }
                    </div>

                    <div className={classes.dropDown__arrow}>

                        <svg width="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect y="0.5" width="19" height="19" rx="2" fill="#FFF" />
                            <path d="M6 8.5L9.5 11.5L13 8.5" stroke="#6D6E6F" strokeWidth="1.5" strokeLinecap="round" />
                        </svg>

                    </div>

                </div>

            </button>

            {
                isDropDownOpen ? (
                    <div className={cn(classes.dropDown__options, optionsListClassname)}>
                        {
                            optionsList
                        }
                    </div>
                ) : null
            }

        </div>
    );
}
