import React from 'react';
import { SwitcherTemp } from '../../../SwitcherTemp';

import classes from './EvaluatedLocker.module.scss';

interface Props {
    isEvaluated: boolean;
    toggleGradeEvaluating(): void;
}

export function EvaluatedLocker({
    isEvaluated,
    toggleGradeEvaluating,
}: Props): JSX.Element {
    return (
        <div className={classes.evaluatedLocker}>
            <SwitcherTemp
                checked={isEvaluated}
                onClick={() => toggleGradeEvaluating()}
            />
            <span className={classes.evaluatedLocker__toolTipText}>
                {isEvaluated && 'Оценивается'}
                {!isEvaluated && 'Не оценивается'}
            </span>
        </div>
    );
}
