import React from 'react';
import { Meeting } from '@admin/ScheduleGenerationPage/types';
import { MeetingView } from '@admin/ScheduleGenerationPage/MeetingDetails/MeetingView';
import { MeetingEdit } from '@admin/ScheduleGenerationPage/MeetingDetails/MeetingEdit';

interface Props {
    status: string;
    meeting: Meeting;
    moduleId: string;
    waveIds: string[];
    meetingInstanceId: string;
    date: string;
}

export const MeetingEvent = ({
    status,
    meeting,
    moduleId,
    waveIds,
    meetingInstanceId,
    date,
}: Props) => {
    const [editable, setEditable] = React.useState(false);
    const [meetingState, setMeetingState] = React.useState(meeting);

    return (
        <>
            {editable ? (
                <MeetingEdit
                    waveIds={waveIds}
                    moduleId={moduleId}
                    meeting={meetingState}
                    meetingInstanceId={meetingInstanceId}
                    date={date}
                    setEditable={setEditable}
                    setMeeting={setMeetingState}
                />
            )
                : (
                    <MeetingView
                        status={status}
                        meeting={meetingState}
                        moduleId={moduleId}
                        setEditable={setEditable}
                    />
                )}
        </>
    );
};
