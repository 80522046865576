import React, { useEffect, useState } from 'react';
import cn from 'classnames';

import classes from './Tooltip.module.scss';

interface Props {
    title?: string;
    isShow?: boolean;
    text: string;
    isWarning?: boolean;
    hasExternalState?: boolean;
    children: string | JSX.Element | JSX.Element[] | (() => JSX.Element);
}

export const Tooltip = (
    {
        title,
        text,
        isWarning,
        children,
        isShow = false,
        hasExternalState = false,
    }: Props,
) => {
    const [showToolTip, setShowToolTip] = useState(false);
    useEffect(() => {
        if (hasExternalState) {
            setShowToolTip(isShow);
        }
    }, [isShow]);

    const onMouseEnterHandler = () => {
        setShowToolTip(true);
    };

    const onMouseLeaveHandler = () => {
        setShowToolTip(false);
    };
    return (
        <div
            className={classes.tooltip__container}
            onMouseEnter={hasExternalState ? () => { } : onMouseEnterHandler}
            onMouseLeave={hasExternalState ? () => { } : onMouseLeaveHandler}
        >
            {children}
            {showToolTip && (
                <div className={cn(classes.tooltip, {
                    [classes.tooltip_error]: isWarning,
                })}
                >
                    {title && <div className={classes.tooltip__title}>{title}</div>}
                    <div className={classes.tooltip__text}>{text}</div>
                </div>
            )}
        </div>
    );
};
