import React, { useState } from 'react';
import { ApolloError, useQuery } from '@apollo/client';
import { useUrlQuery } from '@common/hooks';
import { Loader } from '@common/Loader';
import { Alert } from '@common/Alert';

import { UpdateEventDateFunc, UpdateEventDurationFunc, UpdateEventStatusFunc, UpdateEventTeacherFunc } from '../types';
import { GET_EVENT_DATA } from '../queries';
import { EventType } from '../utils';

import { MeetingDetails } from './MeetingDetails';

import {
    useUpdateMeetingDurationMutation,
    useUpdateAssignmentDurationMutation,
    useEvaluationPointDurationMutation,
    useUpdateMeetingStatusMutation,
    useUpdateAssignmentStatusMutation,
    useUpdateEvaluationPointStatusMutation,
    useUpdateMeetingDateMutation,
    useUpdateAssignmentDateMutation,
    useUpdateMeetingTeacherMutation,
    useUpdateEvaluationPointTeacherMutation, usePinMeetingInstanceMutation,
} from './apolloHooks';

import classes from './MeetingDetails.module.scss';

interface IProps {
    setDialog(): void;
    baseTimeIntervalInstanceId: string;
}

export const MeetingDetailsApollo = React.memo(({
    setDialog,
    baseTimeIntervalInstanceId,
}: IProps) => {
    const [errors, setErrors] = useState<JSX.Element[] | []>([]);
    const { getUrlQuery } = useUrlQuery();
    const detailedEventId = getUrlQuery('detailedEventId');
    const detailedEventType = getUrlQuery('detailedEventType');

    const { data, loading } = useQuery(GET_EVENT_DATA, {
        variables: { tmpMeetingInstanceInput: { id: detailedEventId, type: detailedEventType } },
    });

    function setErrorsFunction(error: ApolloError) {
        const normalError = error.message.split('Cannot return null');
        setErrors([...errors, (<Alert message={normalError[0]} />)]);
    }

    // Teachers
    const getUpdateTeachersFunction = (type: string): UpdateEventTeacherFunc => {
        if (type === EventType.Meeting) return updateMeetingTeacher;
        if (type === EventType.EvaluationPoint) return updateEvaluationPointTeacher;
        return () => null;
    };

    const { updateMeetingTeacher } = useUpdateMeetingTeacherMutation(
        detailedEventId as string, detailedEventType as string, setErrorsFunction,
    );

    const { updateEvaluationPointTeacher } = useUpdateEvaluationPointTeacherMutation(
        detailedEventId as string, detailedEventType as string, setErrorsFunction,
    );

    // TIME
    const getUpdateStartDateFunction = (type: string): UpdateEventDateFunc => {
        if (type === EventType.Meeting) return updateMeetingDate;
        if (type === EventType.Assignment) return updateAssignmentDate;
        return () => null;
    };

    const { updateMeetingDate } = useUpdateMeetingDateMutation(
        detailedEventId as string, detailedEventType as string, setErrorsFunction,
    );

    const { updateAssignmentDate } = useUpdateAssignmentDateMutation(
        detailedEventId as string, detailedEventType as string, setErrorsFunction,
    );

    // DURATION
    const getUpdateDurationFunction = (type: string): UpdateEventDurationFunc => {
        if (type === EventType.Meeting) return updateMeetingDuration;
        if (type === EventType.Assignment) return updateAssignmentDuration;
        if (type === EventType.EvaluationPoint) return updateEvaluationPointDuration;
        return () => null;
    };

    const { updateMeetingDuration } = useUpdateMeetingDurationMutation(
        detailedEventId as string, detailedEventType as string, setErrorsFunction,
    );

    const { updateAssignmentDuration } = useUpdateAssignmentDurationMutation(
        detailedEventId as string, detailedEventType as string, setErrorsFunction,
    );

    const { updateEvaluationPointDuration } = useEvaluationPointDurationMutation(
        detailedEventId as string, detailedEventType as string, setErrorsFunction,
    );

    // STATUS
    const getUpdateStatusFunction = (type: string): UpdateEventStatusFunc => {
        if (type === EventType.Meeting) return updateMeetingStatus;
        if (type === EventType.Assignment) return updateAssignmentStatus;
        if (type === EventType.EvaluationPoint) return updateEvaluationPointStatus;
        return () => null;
    };

    const { updateMeetingStatus } = useUpdateMeetingStatusMutation(
        detailedEventId as string, detailedEventType as string, setErrorsFunction,
    );

    const { updateAssignmentStatus } = useUpdateAssignmentStatusMutation(
        detailedEventId as string, detailedEventType as string, setErrorsFunction,
    );

    const { updateEvaluationPointStatus } = useUpdateEvaluationPointStatusMutation(
        detailedEventId as string, detailedEventType as string, setErrorsFunction,
    );

    // PIN
    const { pinEventInstance } = usePinMeetingInstanceMutation(
        detailedEventId as string, detailedEventType as string, setErrorsFunction,
    );

    const getPinEventFunction = (type: string) => {
        if (type === EventType.Meeting) return pinEventInstance;
        return () => null;
    };

    if (loading) {
        return (
            <div className={classes.meetingDetails__loader}>
                <Loader />
            </div>
        );
    }
    return (
        <>
            <MeetingDetails
                event={data?.TMPMeetingInstanceExcludingStatus}
                baseTimeIntervalInstanceId={baseTimeIntervalInstanceId}
                setDialog={setDialog}
                getUpdateStartDateFunction={getUpdateStartDateFunction}
                getUpdateTeachersFunction={getUpdateTeachersFunction}
                getUpdateStatusFunction={getUpdateStatusFunction}
                getUpdateDurationFunction={getUpdateDurationFunction}
                pinEventInstance={getPinEventFunction(detailedEventType as string)}
            />
            {errors}
        </>
    );
});
