import React from 'react';
import { ActionButton, ActionTypeOfButton } from '@common/ActionButton';
import { IconDeprecated } from '@common/IconDeprecated';
import classes from './EducationSpaceInfo.module.scss';
import { SubspaceTypeSelector } from './SubspaceTypeSelector';
import { SubspaceOptions } from './SubspaceOptions';

interface Props {
    closeDialog: () => void;
}

export function EducationSpaceDialog({ closeDialog }: Props): JSX.Element {
    return (
        <div className={classes.educationSpaceDialog} onClick={closeDialog}>
            <div
                className={classes.educationSpaceDialog__container}
                onClick={(e) => e.stopPropagation()}
            >
                <div className={classes.educationSpaceDialog__title}>
                    Настройки точки выбора
                </div>

                <SubspaceTypeSelector />

                <SubspaceOptions />

                <IconDeprecated
                    id="Cross"
                    className={classes.educationSpaceDialog__icon}
                    click={closeDialog}
                />

                <div className={classes.educationSpaceDialog__button}>
                    <ActionButton actionType={ActionTypeOfButton.SECONDARY}>
                        Сохранить точку выбора
                    </ActionButton>
                </div>
            </div>
        </div>
    );
}
