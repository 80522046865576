import { makeAutoObservable } from 'mobx';

export enum NewEquipmentPosition {
    TOP = 'top',
    BOTTOM = 'bottom',
}

interface EquipmentViewParameters {
    isAddNewClassicEquipment: boolean;
    id?: string;
}

class EquipmentStore {
    private selectedEquipmentId = '';

    private noValidEquipmentId = '';

    private newEquipmentViewParameters: EquipmentViewParameters = {
        isAddNewClassicEquipment: false,
        id: this.selectedEquipmentId,
    };

    constructor() {
        makeAutoObservable(this);
    }

    public setSelectedEquipmentId(id: string) {
        if (!this.noValidEquipmentId) {
            this.selectedEquipmentId = id;
        } else {
            this.selectedEquipmentId = this.noValidEquipmentId;
        }
    }

    public setNoValidEquipmentId(id: string) {
        this.noValidEquipmentId = id;
    }

    public setNewEquipmentViewParameters(
        isNewClassicEquipment: boolean,
        id: string,
    ) {
        this.newEquipmentViewParameters.isAddNewClassicEquipment = isNewClassicEquipment;
        this.newEquipmentViewParameters.id = id;
        this.setSelectedEquipmentId(id);
    }

    public getSelectedEquipmentId(): string {
        return this.selectedEquipmentId;
    }

    public getNewEquipmentViewParameters() {
        return this.newEquipmentViewParameters;
    }

    public isNewEquipment(id: string) {
        return id === this.newEquipmentViewParameters.id;
    }

    get newEquipmentId() {
        return 'newEquipmentId';
    }
}

export const equipmentStore = new EquipmentStore();
