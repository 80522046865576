import React, { useEffect, useState } from 'react';
import { nanoid } from 'nanoid';
import { observer } from 'mobx-react';

import { Accordion } from '@common/Accordion';

import { ModuleAdditionalItem } from '@admin/ModuleSelectionPage/ModuleStudentsAccordion/ModuleAdditionalItem';
import {
    ApolloPromise,
    EmptyStudentSlot,
    ModuleGroupWithAdditionalFields,
    StudentWithAdditionalFields,
    SortTypes, StudentWithRating, RefetchIsModuleDistributionValid,
} from '@admin/ModuleSelectionPage/Interfaces';
import { ModuleStudentItem } from '@admin/ModuleSelectionPage/ModuleStudentsAccordion/ModuleStudentItem';
import { EmptyStudentModuleItem } from '@admin/ModuleSelectionPage/ModuleStudentsAccordion/EmptyStudentModuleItem';

import cn from 'classnames';
import { UpdateStudentModuleInput } from '@admin/ModuleSelectionPage/graphql-types';
import { moduleSelectionStore } from '@admin/ModuleSelectionPage/ModuleSelectionStore';
import { useGetSortButtonText } from '@admin/ModuleSelectionPage/UseGetSortButtonText';

import { checkIfCanSortByRating, getSortedStudents } from '@admin/ModuleSelectionPage/ModuleSelectionPage';
import classes from './ModuleStudentsAccordion.module.scss';

interface Props {
    currentModuleGroup: ModuleGroupWithAdditionalFields;
    updateStudentModule(input: UpdateStudentModuleInput): ApolloPromise;
    refetchIsValidStudentsModuleDistribution: RefetchIsModuleDistributionValid;
}

export const ModuleStudentsAccordion = observer((
    {
        currentModuleGroup,
        updateStudentModule,
        refetchIsValidStudentsModuleDistribution,
    }: Props,
): JSX.Element => {
    const {
        students,
        additionalStudents,
        module,
    } = currentModuleGroup;
    const [sortTypes, setSortTypes] = useState<SortTypes>(
        {
            isSortAz: true,
            isSortZa: false,
            isSortByRating: false,
        },
    );
    const [isAccordionOpen, setAccordionOpen] = useState(true);
    const [emptyStudentsSlots, setEmptyStudentsSlots] = useState<EmptyStudentSlot[]>(
        createEmptyStudentsSlots(
            module.defMaxStudentsCount,
            module.maxWaveCount,
            students,
            additionalStudents,
        ),
    );

    const wavesInfo = `${module.defMinStudentsCount}-${module.defMaxStudentsCount} / макс.: ${module.maxWaveCount} потока`;

    const isAccordionHeaderRed = currentModuleGroup.students.length
        < currentModuleGroup.module.minStudentCount
        || currentModuleGroup.students.length > currentModuleGroup.module.maxStudentCount;

    const minAmountToValidMinInterval = module.minStudentCount
        - students.length;

    const minIntervalInfo = {
        wavesInfo: `Объем потока ${module.defMinStudentsCount} - ${module.defMaxStudentsCount} студентов.`,
        studentInfo: `${module.minStudentCount} студентов для ${module.currentWaveIndex} потоков, добавьте еще мин ${minAmountToValidMinInterval} студентов`,
    };

    const maxIntervalInfo = {
        wavesInfo: `Объем потока ${module.defMinStudentsCount} - ${module.defMaxStudentsCount} студентов.`,
        studentInfo: `${module.maxStudentCount} студентов для ${module.currentWaveIndex} потока`,
    };

    const { sortButtonText } = useGetSortButtonText(sortTypes);

    useEffect(() => {
        setEmptyStudentsSlots(
            createEmptyStudentsSlots(
                module.defMaxStudentsCount,
                module.maxWaveCount,
                students,
                additionalStudents,
            ),
        );
    }, [moduleSelectionStore.getModuleGroupsWithAdditionalFields()]);

    const changeSortType = () => {
        const initialSortTypes: SortTypes = {
            isSortAz: false,
            isSortZa: false,
            isSortByRating: false,
        };

        const canSortByRating = checkIfCanSortByRating(
            moduleSelectionStore.getStudentsWithRating(),
            currentModuleGroup.students,
        );
        if (sortTypes.isSortAz) {
            setSortTypes({
                ...initialSortTypes,
                isSortZa: true,
            });
        }

        if (sortTypes.isSortZa && canSortByRating) {
            setSortTypes({
                ...initialSortTypes,
                isSortByRating: true,
            });
        }

        if (sortTypes.isSortZa && !canSortByRating) {
            setSortTypes({
                ...initialSortTypes,
                isSortAz: true,
            });
        }

        if (sortTypes.isSortByRating) {
            setSortTypes({
                ...initialSortTypes,
                isSortAz: true,
            });
        }
    };

    const sortButtonHandleClick = () => {
        moduleSelectionStore.setModuleGroupsWithAdditionalFields(
            getSortedCurrentModuleGroupStudents(
                module.id,
                sortTypes,
                moduleSelectionStore.getStudentsWithRating(),
                moduleSelectionStore.getModuleGroupsWithAdditionalFields(),
            ),
        );
        changeSortType();
    };

    const moduleStudentsList = students.map((student, index) => (
        <ModuleStudentItem
            key={student.user.id}
            minIntervalInfo={minIntervalInfo}
            maxIntervalInfo={maxIntervalInfo}
            currentModuleGroup={currentModuleGroup}
            order={index + 1}
            currentStudent={student}
        />
    ));

    const moduleAdditionalStudentsList = additionalStudents.map((student, index) => {
        const isItemDisabled = (students.length === module.maxStudentCount)
            && (module.currentWaveIndex === module.maxWaveCount);
        return (
            <ModuleAdditionalItem
                key={student.user.id}
                order={index + 1 + currentModuleGroup.students.length}
                disabled={
                    isItemDisabled
                }
                minIntervalInfo={minIntervalInfo}
                maxIntervalInfo={maxIntervalInfo}
                currentStudent={student}
                currentModuleGroup={currentModuleGroup}
                students={currentModuleGroup.students}
                updateStudentModule={updateStudentModule}
                refetchIsValidStudentsModuleDistribution={refetchIsValidStudentsModuleDistribution}
            />
        );
    });

    const emptySlotsList = emptyStudentsSlots.map((student, index) => {
        const order = index + 1
            + currentModuleGroup.students.length
            + currentModuleGroup.additionalStudents.length;

        return (
            <EmptyStudentModuleItem
                key={student.user.id}
                order={
                    order
                }
                minIntervalInfo={minIntervalInfo}
                maxIntervalInfo={maxIntervalInfo}
                currentModuleGroup={currentModuleGroup}
            />
        );
    });

    return (
        <div
            className={cn(classes.accordion, {
                [classes.accordion_closed]: !isAccordionOpen,
            })}
        >
            <Accordion
                headerClassnames={classes.accordion__header_main}
                shouldClickOnHeaderOpen
                isDefaultOpen
                additionalSetState={() => setAccordionOpen(!isAccordionOpen)}
            >
                <div
                    className={cn(classes.accordion__header, {
                        [classes.accordion__header_red]: isAccordionHeaderRed,
                    })}
                >

                    <span
                        className={cn(classes.accordion__header_ellipsis, {
                            [classes.accordion__header_ellipsis_closed]: !isAccordionOpen,
                        })}
                    >
                        { module.name }
                    </span>

                    <div className={classes.accordion__header_additional}>
                        { module.name }
                    </div>

                    <div className={classes.accordion__header_wavesInfo}>
                        { wavesInfo }
                    </div>
                </div>

                <div className={classes.accordion__item}>

                    <div>
                        { students.length} студентов
                    </div>

                    <div className={classes.accordion__sort}>

                        <span className={classes.accordion__sort_txt}>
                            { sortButtonText }
                        </span>

                        <button
                            type="button"
                            className={classes.accordion__sort_btn}
                            onClick={() => {
                                sortButtonHandleClick();
                            }}
                        >
                            <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect width="19" height="19" fill="#FFF" />
                                <path d="M5.35059 4H7.34551V10.254H5.35059V4Z" fill="#4E5258" />
                                <path d="M9.34049 4H11.3354V11.8174H14.3631L10.3734 15L6.3836 11.8174H9.34049V4Z" fill="#4E5258" />
                            </svg>
                        </button>
                    </div>
                </div>

                <div className={classes.accordion__items}>

                    {
                        moduleStudentsList
                    }

                    {
                        moduleAdditionalStudentsList
                    }

                    {
                        emptySlotsList
                    }

                </div>
            </Accordion>
        </div>
    );
});

function getSortedCurrentModuleGroupStudents(
    currentModuleGroupId: string,
    sortTypes: SortTypes,
    studentsRating: StudentWithRating[],
    modules: ModuleGroupWithAdditionalFields[],
) {
    return modules.map((moduleGroup) => {
        if (moduleGroup.module.id !== currentModuleGroupId) {
            return moduleGroup;
        }

        const sortedStudents = getSortedStudents(
            sortTypes,
            studentsRating,
            moduleGroup.students,
        );

        return {
            ...moduleGroup,
            students: sortedStudents,
        };
    });
}

function createEmptyStudentsSlots(
    maxStudentsCount: number,
    maxWavesCount: number,
    students: StudentWithAdditionalFields[],
    additionalStudents: StudentWithAdditionalFields[],
): EmptyStudentSlot[] {
    const emptyStudentsSlots: StudentWithAdditionalFields[] = [];
    const loopCondition = (maxStudentsCount * maxWavesCount)
        - (additionalStudents.length + students.length);

    for (let i = 0; i < loopCondition; i += 1) {
        emptyStudentsSlots.push({
            __typename: 'Student',
            user: {
                __typename: 'User',
                firstName: '',
                lastName: '',
                patronymic: '',
                studentId: nanoid(9),
                id: nanoid(9),
            },
            isSelected: false,
            wasMoved: false,
        });
    }

    return emptyStudentsSlots;
}
