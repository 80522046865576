import React from 'react';

import classes from './PopUpNedoshkovskiy.module.scss';

interface Props {
    children: React.ReactNode;
}

export function PopUpNedoshkovskiy({ children }: Props) {
    return (
        <div className={classes.popUpNedoshkovskiy}>

            {children}

        </div>
    );
}
