import { gql } from '@apollo/client';

import { useMutationWithErrorHandling } from './useMutationWithErrorHandling';

const BEGIN_SCHEDULE_GENERATION = gql`
    mutation startScheduleGeneration($startScheduleGenerationInput: StartScheduleGenerationInput!) {
        startScheduleGeneration(startScheduleGenerationInput: $startScheduleGenerationInput) {
            id
        }
    }
`;

export function useBeginScheduleGeneration(onCompleted?: () => void) {
    const [beginScheduleGeneration, { error, loading }] = useMutationWithErrorHandling(
        BEGIN_SCHEDULE_GENERATION, { onCompleted },
    );

    return { startScheduleGeneration: (
        generationProcessId: string,
        ignoreFlags: string[] = [],
    ) => beginScheduleGeneration({
        variables: {
            startScheduleGenerationInput: {
                generationProcessId,
                ignoreFlags,
                doMeetingsRemoval: true,
            },
        },
    }),
    loading,
    error };
}
