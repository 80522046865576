import React from 'react';
import {
    filteredTeacherAssignmentInstances_filteredTeacherAssignmentInstances,
} from '../../TeacherAssignmentsApolloTypes';
import { TeacherAssignmentMessage } from '../TeacherAssignmentMessage/TeacherAssignmentMessage';
import classes from './TeacherAssignmentNotes.module.scss';

interface Props {
    // eslint-disable-next-line max-len
    assignment?: filteredTeacherAssignmentInstances_filteredTeacherAssignmentInstances;
}

export function TeacherAssignmentNotes({
    assignment,
}: Props) {
    const checkNotes = assignment?.assignmentInstance.teacherAssignmentInstances
        .some((teacher: { note: string | null; }) => (
            teacher.note !== null
        ));

    return (
        <div className={classes.teacherAssignment__comments}>
            <div className={classes.teacherAssignment__subtitle}>
                Комментарии других преподавателей для студентов {
                    assignment
                    && assignment.assignmentInstance.wave.index !== null
                    && assignment.assignmentInstance.wave.index + 1
                } потока:
            </div>

            {
                checkNotes
                    // eslint-disable-next-line max-len
                    ? <TeacherAssignmentMessage assignmentInstance={assignment?.assignmentInstance} />
                    : 'Комментариев нет'
            }
        </div>
    );
}
