import React from 'react';
import { nanoid } from 'nanoid';

import { Table } from '@admin/Attendance/AttendanceIterfaces';

import cn from 'classnames';
import classes from './EmptyTable.module.scss';

interface Props {
    isTableVisible: boolean;
    table: Table;
}

export function EmptyTable(
    {
        isTableVisible,
        table,
    }: Props,
) {
    const emptyTableRows = table.rows.map(() => (
        <div
            className={classes.emptyTable__row}
            key={nanoid()}
        />
    ));

    return (
        <div className={cn(classes.emptyTable, {
            [classes.emptyTable_notVisible]: !isTableVisible,
        })}
        >
            <div className={classes.emptyTable__header} />

            <div>
                {
                    emptyTableRows
                }
            </div>

        </div>
    );
}
