import React from 'react';

import { IconDeprecated, Input } from '@common';
import classes from './EquipmentFormInput.module.scss';

interface Equipment {
    id: string;
    name: string;
    unit: string;
}

interface Props {
    index: number,
    deleteForm: (id: number) => void;
    formList: Equipment[];
    setFormList: (array: Equipment[]) => void;
}

export function EquipmentFormInput({
    index,
    deleteForm,
    formList,
    setFormList,
}: Props): JSX.Element {
    const changeName = (e: React.FormEvent<HTMLInputElement>) => {
        e.preventDefault();
        const equipmentForms = formList;
        equipmentForms[index].name = e.currentTarget.value.trim();
        setFormList([...equipmentForms]);
    };

    const changeUnit = (e: React.FormEvent<HTMLInputElement>) => {
        e.preventDefault();
        const equipmentForms = formList;
        equipmentForms[index].unit = e.currentTarget.value.trim();
        setFormList([...equipmentForms]);
    };
    return (
        <div className={classes.equipmentFormInput}>
            <div className={classes.equipmentFormInput__inputContainer}>
                <Input
                    placeholder="Введите название расходного материала"
                    onChange={changeName}
                    label="Название:"
                />
            </div>
            <div className={classes.equipmentFormInput__unitInputContainer}>
                <Input
                    placeholder="шт"
                    label="Ед. измерения:"
                    onChange={changeUnit}
                />
            </div>
            {index > 0 && (
                <div
                    className={classes.equipmentFormInput__deleteIcon}
                    onClick={() => {
                        deleteForm(index);
                    }}
                >
                    <IconDeprecated
                        id="Cross"
                        className={classes.equipmentFormInput__icon}
                    />
                </div>
            )}
        </div>
    );
}
