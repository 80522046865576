import React, { useState } from 'react';
import { Switcher } from '@common/Switcher';
import { Title, TitleTypes } from '@common/Title';

import classes from './Section.module.scss';

interface Props {
    checkboxLabel?: string;
    children?: React.ReactNode;
    title?: string;
    uncheckedView?: JSX.Element;
    checked?: boolean;
    onChangeCheck?: () => void;
}

export const CheckboxForm = ({
    checkboxLabel,
    children,
    title,
    uncheckedView,
    checked = true,
    onChangeCheck = () => { },
}: Props): JSX.Element => {
    const [check, setCheck] = useState(checked);

    const handleChangeCheckBox = () => {
        setCheck(!check);
        onChangeCheck();
    };
    return (
        <>
            <Switcher
                checked={check}
                label={checkboxLabel}
                onClick={handleChangeCheckBox}
            />
            <div className={classes.checkBoxForm__form}>
                {check && (
                    <>
                        {title && (
                            <Title type={TitleTypes.Subtitle}>
                                {title ?? ''}
                            </Title>
                        )}
                        <>
                            {children}
                        </>
                    </>
                )
                }
                {!check && uncheckedView}
            </div>
        </>
    );
};
