import React from 'react';
import { ActionButton } from '@common/ActionButton';

import classes from './TrajectoriesList.module.scss';

interface Props {
    handleFormOpen(): void;
}
export const TrajectoriesListHeader = ({ handleFormOpen }: Props): JSX.Element => (
    <div className={classes.trajectoriesListHeader}>
        <div className={classes.trajectoriesListHeader__title}>
            Образовательные траектории
        </div>
        <div className={classes.trajectoriesListHeader__buttons}>
            <ActionButton
                onClick={handleFormOpen}
                className={classes.trajectoriesListHeader__createButton}
            >
                Создать траекторию
            </ActionButton>
        </div>
    </div>
);
