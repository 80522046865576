import React from 'react';

import styles from './Title.module.scss';

export enum TitleTypes {
    Title = 'title',
    Subtitle = 'subtitle',
}

interface Props {
    children: string;
    type?: TitleTypes;
}

export const Title: React.FC<Props> = ({ children, type = TitleTypes.Title }: Props) => (
    <div className={styles[type]}>
        {children}
    </div>
);
