import React, { useState } from 'react';
import moment from 'moment';
import cn from 'classnames';
import { ArrowIcon } from '@common/svg/ArrowIcon';
import { ActionButton, ActionTypeOfButton } from '@common/ActionButton';
import { observer } from 'mobx-react';
import { useMutation } from '@apollo/client';

import { SET_ASSIGNMENT_STATUS } from '../../../../account/schedule/ScheduleEventBox/ScheduleEvent/AssignmentEvent/mutations';
import { StudentAssignmentInstance as StudentAssignmentInstanceInterface } from '../../interfaces';

import classes from './Assignment.module.scss';

interface Props {
    studentAssignmentInstance: StudentAssignmentInstanceInterface;
}

export const StudentAssignmentInstance = observer(({ studentAssignmentInstance }: Props) => {
    const { assignmentInstance, note, isCompleted } = studentAssignmentInstance;
    const { assignment } = assignmentInstance;
    const [completed, setCompleted] = useState(isCompleted);

    const [setOnCompleteStatus] = useMutation(SET_ASSIGNMENT_STATUS, {
        onCompleted: () => setCompleted((item) => !item),
    });

    return (
        <article
            className={cn(classes.Assignment, {
                [classes.Assignment_active]: completed,
            })}
        >
            <input className={classes.Assignment__input} type="checkbox" id={assignmentInstance.id} />

            <label
                className={classes.Assignment__header}
                htmlFor={assignmentInstance.id}
            >
                <div className={classes.Assignment__wrapper}>
                    <h3 className={classes.Assignment__subtitle}>
                        {assignment.module.name}
                    </h3>

                    <div className={classes.Assignment__status}>
                        {
                            completed
                                ? 'Выполнена.'
                                : `Не выполнена. Дата окончания ${assignmentInstance.deadline
                                    ? (moment(assignmentInstance.deadline).format('DD[.]MM'))
                                    : 'не установлена'
                                }.`
                        }
                    </div>
                </div>

                <div className={classes.Assignment__wrap}>
                    <label className={classes.Assignment__title} htmlFor={assignmentInstance.id}>
                        {assignment.topic}
                    </label>

                    <ArrowIcon className={classes.Assignment__arrow} isOpen={false} />
                </div>
            </label>

            <label className={classes.Assignment__content} htmlFor={assignmentInstance.id}>
                <p className={classes.Assignment__text}>
                    {note ?? 'Описание отсутствует'}
                </p>

                <div className={classes.Assignment__wrapper}>
                    <div className={classes.Assignment__info}>
                        <p className={classes.Assignment__text}>
                            Продолжительность — {assignment.duration.name} минут
                        </p>
                        <p className={classes.Assignment__text}>
                            {assignment.description ?? 'Описание от владельца модуля отсутствует'}
                        </p>
                    </div>

                    <ActionButton
                        actionType={
                            completed
                                ? ActionTypeOfButton.SECONDARY
                                : ActionTypeOfButton.PRIMARY
                        }
                        onClick={() => {
                            setOnCompleteStatus({
                                variables: {
                                    changeCompleteStatusOfAssignmentInstanceInput: {
                                        assignmentInstanceId: assignmentInstance.id,
                                        isCompleted: !isCompleted,
                                    },
                                },
                            });
                        }}
                    >
                        {completed ? 'Сделать невыполненной' : 'Сделать выполненной'}
                    </ActionButton>
                </div>
            </label>
        </article>
    );
});
