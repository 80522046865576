export enum FilterType {
    ALL = 'all',
    MEETING = 'meeting',
    EVALUATION_POINT = 'evaluationPoint',
    ASSIGNMENT = 'assignment',
}

interface FilterDescription {
    [key: string]: string;
}

export const filterDescription: FilterDescription = {
    [FilterType.ALL]: 'Все',
    [FilterType.MEETING]: 'Встреча',
    [FilterType.EVALUATION_POINT]: 'Оценка',
    [FilterType.ASSIGNMENT]: 'Сам. работа',
};
