import React from 'react';
import cn from 'classnames';
import styles from './Textarea.module.scss';

interface Props {
    name?: string;
    value?: string;
    label?: string;
    placeholder?: string;
    modifier?: string;
    labelModifier?: string;
    onChange?(event: any): void;
}
export const Textarea: React.FC<Props> = ({
    name,
    value,
    label,
    placeholder,
    modifier,
    labelModifier,
    onChange = () => {},
}: Props) => (
    <div className={styles.textarea}>
        <label className={
            cn(styles.textarea__label,
                { [styles[`textarea__label_${labelModifier}`]]: labelModifier })}
        >
            {label}
        </label>

        <textarea
            className={
                cn(styles.textarea__item,
                    {
                        [styles[`textarea__item_${modifier}`]]: modifier,
                        [styles.textarea__item_autosize]: (value?.length || 0) >= 68 && !modifier,
                    })}
            name={name}
            value={value}
            placeholder={placeholder}
            onChange={onChange}
            autoComplete="off"
        />
    </div>
);
