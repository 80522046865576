import { useMutation } from '@apollo/client';
import { REMOVE_TRAJECTORY } from '@common/qraphql/mutations';
import { GET_TRAJECTORIES } from '@common/qraphql/queries';

export function useRemoveTrajectoryMutation(spaceId: string) {
    const [removeTrajectory, { error: removeError }] = useMutation(REMOVE_TRAJECTORY, {
        refetchQueries: [{ query: GET_TRAJECTORIES, variables: { spaceId } }],
    });
    return {
        removeTrajectory:
        (id: string) => removeTrajectory({ variables: { id } }),
        removeError,
    };
}
