import { gql } from '@apollo/client';

export const IS_INVITE_LINK_VALID = gql`
    query isInviteLinkValid($invitationId: String!) {
        isValidExternalModuleInvitation(invitationId: $invitationId)
    }
`;

export const SAVE_EXTERNAL_MODULE = gql`
    mutation saveExternalModule($saveExternalModuleInput: SaveExternalModuleInput!) {
        saveExternalModule(saveExternalModuleInput: $saveExternalModuleInput) {
            id
            status
            ownerFullName
            ownerEmail
            ownerInfo
            name
            description
            minStudentCount
            maxStudentCount
            maxWaveCount
            creditCount
            syllabusReference
            reviews {
                id
                message
                reviewer {
                    id
                    firstName
                    lastName
                    patronymic
                }
            }
        }
    }
`;

export const GET_EXTERNAL_MODULE = gql`
    query getExternalModule($externalModuleInput: ExternalModuleInput!) {
        externalModule(externalModuleInput: $externalModuleInput) {
            id
            status
            ownerFullName
            ownerEmail
            ownerInfo
            name
            description
            minStudentCount
            maxStudentCount
            maxWaveCount
            creditCount
            syllabusReference
        }
    }
`;
