import React from 'react';
import classes
    from '@admin/WaveSelectionPage/WaveAccordion/WaveStudents/WaveStudentItem/WaveStudentItem.module.scss';
import cn from 'classnames';
import { IntervalAdditional } from '@admin/WaveSelectionPage/IntervalAdditional';

interface Props {
    interval: number;
    isRed: boolean;
    isVisible: boolean;
    wavesInfo: string;
    studentInfo: string;
}

export function MinInterval(
    {
        isRed,
        interval,
        isVisible,
        wavesInfo,
        studentInfo,
    }: Props,
) {
    return (
        <>
            {
                isVisible ? (
                    <div
                        className={cn(classes.interval, {
                            [classes.interval_red]: isRed,
                        })}
                    >
                        <div className={classes.interval__txt}>
                            {interval} min
                        </div>

                        <IntervalAdditional
                            wavesInfo={wavesInfo}
                            studentsInfo={studentInfo}
                            className={classes.interval__additional}
                        />

                        <span
                            className={cn(classes.interval__line, {
                                [classes.interval__line_red]: isRed,
                            })}
                        />
                    </div>
                ) : null
            }
        </>
    );
}
