import React from 'react';
import { observer } from 'mobx-react';
import cn from 'classnames';

import colors from '../../store/ColorConstans';

import classes from './SVG.module.scss';

interface Props {
    width?: number;
    horizontalVector?: HorizontalVector;
    firstColor?: string;
    secondColor?: string;
    thirdColor?: string;
    className?: string;
    onClick?: () => void;
}

export enum HorizontalVector {
    LEFT = 'left',
    RIGHT = 'right',
}

export const HorizontalArrowIcon = observer(({
    width = 13,
    horizontalVector = HorizontalVector.RIGHT,
    className = '',
    firstColor = colors.firstColor,
    secondColor = colors.secondColor,
    thirdColor = colors.thirdColor,
    onClick = () => { },
}: Props) => {
    const height = (width / 13) * 8;
    return (
        <div
            className={cn({
                [classes.arrow_reverse]: horizontalVector === HorizontalVector.LEFT,
                [className]: className,
            })
            }
            style={{ cursor: 'pointer' }}
            onClick={onClick}
        >
            <svg width={width} height={height} viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M-4.85582e-08 4C-4.9989e-08 3.88214 0.0489125 3.76911 0.135977 3.68577C0.223042 3.60244 0.341128 3.55562 0.464256 3.55562L11.4142 3.55562L8.49217 0.759566C8.405 0.676123 8.35602 0.56295 8.35602 0.444943C8.35602 0.326937 8.405 0.213764 8.49217 0.13032C8.57935 0.0468773 8.69758 -1.01883e-07 8.82087 -1.03328e-07C8.94415 -1.04772e-07 9.06238 0.0468773 9.14956 0.13032L12.8636 3.68538C12.9068 3.72666 12.9411 3.7757 12.9645 3.82968C12.988 3.88367 13 3.94155 13 4C13 4.05845 12.988 4.11633 12.9645 4.17032C12.9411 4.2243 12.9068 4.27334 12.8636 4.31462L9.14956 7.86968C9.06238 7.95312 8.94415 8 8.82087 8C8.69758 8 8.57935 7.95312 8.49217 7.86968C8.405 7.78624 8.35602 7.67306 8.35602 7.55506C8.35602 7.43705 8.405 7.32388 8.49217 7.24043L11.4142 4.44438L0.464256 4.44438C0.341128 4.44438 0.223042 4.39756 0.135977 4.31422C0.0489125 4.23089 -4.71275e-08 4.11786 -4.85582e-08 4Z" fill="url(#paint0_linear_5334_95912)" />
                <defs>
                    <linearGradient id="paint0_linear_5334_95912" x1="5.75949" y1="-0.578313" x2="8.03538" y2="8.8961" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#0B42DC" />
                        <stop offset="0.0416667" stopColor={firstColor} />
                        <stop offset="0.130208" stopColor={firstColor} />
                        <stop offset="0.734375" stopColor={secondColor} />
                        <stop offset="0.932292" stopColor={thirdColor} />
                    </linearGradient>
                </defs>
            </svg>

        </div>
    );
});
