import React from 'react';
import cn from 'classnames';
import * as Types from '../../profileModels';

import classes from '../StudentProfile.module.scss';

interface Props {
    studentSkill: Types.StudentSkill | Types.ModuleSkill;
    allModuleSkills: {
        skill: Types.Skill;
        level: number;
    }[];
    isStudentSkill?: boolean
}

export function StudentSkill({ studentSkill, allModuleSkills, isStudentSkill = false }: Props) {
    const moduleSkill = allModuleSkills
        .find(item => item.skill.id === studentSkill.skill.id);
    return (
        <>
            {isStudentSkill ? (
                <div className={classes.student__skill}>
                    {studentSkill.skill.fullName}
                    {studentSkill.skill.maxLevel ? (
                        <div className={classes.student__skill_level}>
                            Уровень:
                            <div className={classes.student__skill_level_num}>
                                {studentSkill.level}
                                /
                                <div className={classes.student__module_skill_level}>
                                    {moduleSkill?.level ?? 0}
                                </div>
                            </div>
                        </div>
                    ) : ''}
                    <progress
                        id="file"
                        max={moduleSkill && moduleSkill.level}
                        value={studentSkill.level}
                        className={classes.progress}
                    />
                </div>
            ) : (
                <div className={cn(classes.student__skill_disable, classes.student__skill)}>
                    {studentSkill.skill.name}
                    {studentSkill.level ? (
                        <div className={classes.student__skill_level}>
                            Уровень:
                            <div className={classes.student__skill_level_num}>
                                <div className={classes.student__module_skill_level}>
                                    0/
                                    {moduleSkill?.level ?? 0}
                                </div>
                            </div>
                        </div>
                    ) : ''}
                    <progress
                        id="file"
                        max={moduleSkill && moduleSkill.level}
                        value={0}
                        className={classes.progress}
                    />
                </div>
            )}
        </>
    );
}
