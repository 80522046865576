import React, { Fragment } from 'react';
import { Title, TitleTypes } from '@common/Title';
import { observer } from 'mobx-react';
import { SkillSection, SuggestSelectElement } from './SkillSection';
import { dictionaryStore, ModuleSkill, ConfigSkill } from '../Store';

import styles from './Section.module.scss';

interface Props {
    requiredTitle: string;
    outputTitle: string;
    skillTypes: ConfigSkill[];
    requiredSkills: ModuleSkill[];
    outputSkills: ModuleSkill[];

    onChangeRequired(items: SuggestSelectElement[], typeID: string): void;

    onChangeOutput(items: SuggestSelectElement[], typeID: string): void;
}

export const SkillsSection = observer(({
    requiredTitle,
    outputTitle,
    skillTypes,
    requiredSkills,
    outputSkills,
    onChangeOutput,
    onChangeRequired,
}: Props) => (
    <>
        {skillTypes
            .map(({ id, isLevelRequired, isLevelOutput }) => ({
                id,
                isLevelRequired,
                isLevelOutput,
                skills: dictionaryStore.getSkillsForType(id),
                ...dictionaryStore.getSkillType(id),
            }))
            .map(({
                id, isLevelRequired, isLevelOutput, name, skills,
            }, index) => (
                <Fragment key={id}>
                    <Title
                        type={TitleTypes.Subtitle}
                    >{`${name?.nominativePlural}, ${requiredTitle}:`}
                    </Title>
                    <SkillSection
                        isLevelNeeded={isLevelRequired!}
                        typeSkills={requiredSkills ?? []}
                        skills={skills}
                        typeID={id}
                        onChange={onChangeRequired}
                    />
                    <Title
                        type={TitleTypes.Subtitle}
                    >{`${name?.nominativePlural}, ${outputTitle}:`}
                    </Title>
                    <SkillSection
                        isLevelNeeded={isLevelOutput!}
                        typeSkills={outputSkills ?? []}
                        skills={skills}
                        typeID={id}
                        onChange={onChangeOutput}
                    />
                    {index < skillTypes.length - 1 ? <div className={styles.hr} /> : <></>}
                </Fragment>
            ))}
    </>
));
