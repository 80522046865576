import React from 'react';
import { EquipmentFilterParams, EquipmentPaginationParams } from 'src/deprecated-graphql-query-types';
import { InputFilter } from '../InputFilter';

import classes from './Filters.module.scss';

interface Props {
    filterOrPaginationEquipment: (
        filterParams?: EquipmentFilterParams,
        paginationParams?: EquipmentPaginationParams,
    ) => void
}

export function Filters({ filterOrPaginationEquipment }: Props) {
    return (
        <div
            className={classes.filters}
        >
            <div
                className={classes.filters__item}
                style={{
                    flex: '1 150px',
                }}
            >
                <InputFilter
                    placeholder="Название"
                    style={{ padding: '10px 10px 10px 0' }}
                    onChange={(value: string) => filterOrPaginationEquipment(
                        { namePattern: value, isComputer: false },
                        { offset: 0 },
                    )}
                />
            </div>

            <div
                className={classes.filters__item_noFocus}
                style={{
                    flex: '0 69px',
                    padding: '10px',
                }}
            >
                Кол-во
            </div>
            <div
                className={classes.filters__item_noFocus}
                style={{
                    flex: '1 150px',
                    padding: '10px',
                }}
            >
                Помещения
            </div>
        </div>
    );
}
