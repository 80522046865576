import React from 'react';
import styles from './RadioButton.module.scss';

interface Props {
    name: string;
    label: string;
    value: string;
    checked: boolean;
    children?: React.ReactNode;
    onChange?(event: React.ChangeEvent<HTMLInputElement>): void;
}

export const RadioButton: React.FC<Props> = ({
    name, label, value, children, checked, onChange,
}: Props) => (
    <>
        <li className={styles.radioButton}>
            <label className={styles.radioButton__label}>
                <input
                    className={styles.radioButton__input}
                    type="radio"
                    name={name}
                    value={value}
                    onChange={onChange}
                    checked={checked}
                />
                {label}
            </label>
            <>{children}</>
        </li>
    </>
);
