import { useMutation } from '@apollo/client';
import {
    UpdateEquipmentEquipmentPage,
    UpdateEquipmentInput,
    UpdateEquipmentEquipmentPageVariables,
} from 'src/deprecated-graphql-query-types';
import { UPDATE_EQUIPMENT } from '../queries';

export const useUpdateEquipmentMutation = () => {
    const [updateEquipment, {
        data,
        error,
        loading,
    }] = useMutation<UpdateEquipmentEquipmentPage, UpdateEquipmentEquipmentPageVariables>(
        UPDATE_EQUIPMENT,
    );
    return {
        data,
        loading,
        error,
        updateEquipment: (input: UpdateEquipmentInput) => updateEquipment({
            variables: {
                updateEquipmentInput: input,
            },
        }),
    };
};
