import React from 'react';

import cn from 'classnames';
import classes from './NoteButton.module.scss';
// import { Icon } from '../../../../../../../admin/NewModule/CustomIcon';

interface NoteButtonProps {
    onClick(): void;
    text: string;
    note?: string;
    showNote: boolean;
}

export function NoteButton({
    onClick,
    text,
    note,
    showNote,
}: NoteButtonProps): JSX.Element {
    return (
        <div
            className={
                cn(classes.schedulePage_meetingSection_addNote, {
                    [classes.schedulePage_meetingSection_saveNote]:
                        note?.length,
                    [classes.schedulePage_eventsSection_saveNote]:
                        showNote,
                })
            }
        >
            <button
                type="button"
                onClick={onClick}
                className={cn(classes.schedulePage__meetingSection_textNote, {
                    [classes.textNote__btn_edit]: !showNote && note?.length,
                })}
            >
                {text}
                {
                    !showNote && !note?.length
                    && (
                        <svg
                            width="15"
                            height="15"
                            viewBox="0 0 15 15"
                            fill="none"
                            className={classes.svg__Image}
                        >
                            <path d="M14.25 6.75H8.25005V0.749954C8.25005 0.336044 7.914 0 7.49996 0C7.08605 0 6.75 0.336044 6.75 0.749954V6.75H0.749954C0.336044 6.75 0 7.08605 0 7.49996C0 7.914 0.336044 8.25005 0.749954 8.25005H6.75V14.25C6.75 14.664 7.08605 15 7.49996 15C7.914 15 8.25005 14.664 8.25005 14.25V8.25005H14.25C14.664 8.25005 15 7.914 15 7.49996C15 7.08605 14.664 6.75 14.25 6.75Z" fill="url(#add-note-button)" />
                            <defs>
                                <linearGradient id="add-note-button" x1="16.0844" y1="6.64559" x2="-0.220819" y2="13.0103" gradientUnits="userSpaceOnUse">
                                    <stop offset="13%" stopColor="#0B42DC" />
                                    <stop offset="73%" stopColor="#57B2C6" />
                                    <stop offset="93%" stopColor="#EED660" />
                                </linearGradient>
                            </defs>
                        </svg>
                    )
                }
            </button>
        </div>
    );
}
