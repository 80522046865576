import React from 'react';
import { Redirect, Switch, useRouteMatch } from 'react-router-dom';
import { AuthRoute } from '@common/AuthRoute';
import { GradientTabs } from '@common/Menu/GradientTabs';
import { EquipmentList } from './EquipmentList';
import { EquipmentForm } from './EquipmentForm';
import { EquipmentPlan } from './EquipmentPlan';
import { ViewType } from '../../deprecated-graphql-query-types';

interface IEquipment {
    id: string;
    name: string;
    unit: string;
}

interface Props {
    consumableEquipment: IEquipment[];
    createConsumableEquipment: (
        id: string,
        name: string,
        unit: string,
    ) => void;
    updateConsumableEquipment: (
        id: string,
        name: string,
        unit: string,
    ) => void;
    removeConsumableEquipment: (id: string) => void;
}

export function Equipment({
    consumableEquipment,
    createConsumableEquipment,
    updateConsumableEquipment,
    removeConsumableEquipment,
}: Props): JSX.Element {
    const { path } = useRouteMatch();
    return (
        <>
            <GradientTabs
                tabsOptions={[
                    [
                        'Общий список',
                        'list',
                    ],
                    [
                        'План',
                        'plan',
                    ],
                    [
                        'Добавление расходных материалов',
                        'add',
                    ],
                ]}
            />
            <Switch>
                <AuthRoute exact path={`${path}`} requiredUserTypes={[ViewType.Admin]}>
                    {consumableEquipment.length > 0
                        ? <Redirect to={`${path}/list`} />
                        : <Redirect to={`${path}/add`} />
                    }
                </AuthRoute>
                <AuthRoute path={`${path}/list`} requiredUserTypes={[ViewType.Admin]}>
                    <EquipmentList
                        consumableEquipment={consumableEquipment}
                        updateConsumableEquipment={updateConsumableEquipment}
                        removeConsumableEquipment={removeConsumableEquipment}
                    />
                </AuthRoute>
                <AuthRoute path={`${path}/plan`} requiredUserTypes={[ViewType.Admin]}>
                    <EquipmentPlan />
                </AuthRoute>
                <AuthRoute path={`${path}/add`} requiredUserTypes={[ViewType.Admin]}>
                    <EquipmentForm
                        createConsumableEquipment={createConsumableEquipment}
                    />
                </AuthRoute>
            </Switch>
        </>
    );
}
