import React from 'react';

import {
    ScheduleGenerationProcess,
} from '@admin/ScheduleGenerationPage/store/BaseTimeIntervalInstanceModel';

import { formatDate } from './AdditionalGeneration';

import classes from './AdditionalGeneration.module.scss';

type Props = {
    processes: ScheduleGenerationProcess[];
    setProcess(process: ScheduleGenerationProcess): void;
};

export function AdditionalGenerationList({ processes, setProcess }: Props) {
    return (
        <ul>
            {processes.map(process => (
                <div
                    key={process.id}
                    onClick={() => setProcess(process)}
                    className={classes.additionalGeneration_item}
                >
                    <span className={classes.additionalGeneration_itemName}>
                        {process.name}
                    </span>
                    <span>
                        {formatDate(process.from)} - {formatDate(process.to)}
                    </span>
                </div>
            ))}
        </ul>
    );
}
