import React from 'react';
import { TeacherRole, TeachersMap } from '../../../../OccupationDashboardInterfaces';
import classes from './Role.module.scss';
import { TeachersList } from './TeachersList';

interface Props {
    role: TeacherRole;
    teachersMap: TeachersMap;
    index: number;
    isTUniversityMeetingsVisible: boolean;
}

const ROLE_NAME_START_COLUMN_LINE = 1;
const ROLE_NAME_END_COLUMN_LINE = 2;
const DIFF_BETWEEN_ROLE_INDEX_AND_START_ROW_LINE = 1;
const DIFF_BETWEEN_ROLE_INDEX_AND_END_ROW_LINE = 1 + DIFF_BETWEEN_ROLE_INDEX_AND_START_ROW_LINE;

export const Role = ({
    role,
    teachersMap,
    index,
    isTUniversityMeetingsVisible,
}: Props): JSX.Element | null => {
    const teacherIdsForRole = role.teachers.map(({ id }) => id);
    const teacherRoleAvailableAcademinHours = isTUniversityMeetingsVisible
        ? teacherIdsForRole
            .reduce((acc, id) => acc + teachersMap[id].availableHoursCountWithoutReservations, 0)
        : teacherIdsForRole
            .reduce((acc, id) => acc + teachersMap[id].availableHoursCountInTUniversity, 0);

    if (role.teachers.length) {
        return (
            <>
                <div
                    className={classes.role__name}
                    style={{
                        gridColumnStart: ROLE_NAME_START_COLUMN_LINE,
                        gridColumnEnd: ROLE_NAME_END_COLUMN_LINE,
                        gridRowStart: index + DIFF_BETWEEN_ROLE_INDEX_AND_START_ROW_LINE,
                        gridRowEnd: index + DIFF_BETWEEN_ROLE_INDEX_AND_END_ROW_LINE,
                        height: `${role.teachers.length * 19}px`,
                    }}
                >
                    <div className={classes.role__tooltip}>
                        {role.name} ({teacherRoleAvailableAcademinHours}&#160;ак.ч.)
                    </div>
                </div>
                <TeachersList
                    ids={teacherIdsForRole}
                    teachersMap={teachersMap}
                    index={index}
                    isTUniversityMeetingsVisible={isTUniversityMeetingsVisible}
                />
            </>
        );
    }
    return null;
};
