import React, { useState } from 'react';

import { userRoles } from '@admin/Users/utils/constants';
import { RadioList } from '@admin/Users/RadioList';
import { ToolButton } from '../../ToolButton';
import { Type } from '../apollo-types';

interface Props {
    onSubmit(role: Type) : void;
}

export const RoleButtonToolBar = ({ onSubmit }: Props): JSX.Element => {
    const [role, setRole] = useState<Type>();
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        setRole(value as Type);
    };

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (role) {
            onSubmit(role);
        }
        setRole(undefined);
        (event.target as HTMLFormElement).reset();
    };

    return (
        <ToolButton
            text="Роль в системе"
            icon="blackPlus"
            onSubmit={handleSubmit}
            contextMenu={(
                <RadioList
                    listOptions={userRoles}
                    type="role"
                    checked={role}
                    onChange={handleChange}
                />
            )}
            modifier="add_border_fixSize"
        />
    );
};
