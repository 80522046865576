import React from 'react';
import { observer } from 'mobx-react';

import {
    ApolloPromise,
    ModuleGroupWithAdditionalFields,
    StudentWithAdditionalFields,
} from '@admin/ModuleSelectionPage/Interfaces';
import { CheckboxRedesign } from '@common/CheckboxRedesign';
import {
    checkIfStudentIsUnique,
    getStudentCurrentModuleId, getStudentRating,
    getStudentsWithCurrentActive,
} from '@admin/ModuleSelectionPage/ModuleSelectionPage';
import { moduleSelectionStore } from '@admin/ModuleSelectionPage/ModuleSelectionStore';
import { DeleteStudentModuleInput } from '@admin/ModuleSelectionPage/graphql-types';
import { moduleSelectionDataStore } from '@admin/ModuleSelectionPage/ModuleSelectionDataStore';
import cn from 'classnames';
import classes from './NotDistribItem.module.scss';

interface Props {
    order: number;
    currentStudent: StudentWithAdditionalFields;
    deleteStudentModule(input: DeleteStudentModuleInput): ApolloPromise;
}

export const NotDistributedItem = observer((
    {
        order,
        currentStudent,
        deleteStudentModule,
    } : Props,
): JSX.Element => {
    const {
        baseTimeIntervalInstanceId,
        forkId,
    } = moduleSelectionDataStore.getURLParameters();

    const {
        user,
    } = currentStudent;

    const isStudentFromModule = checkIfCurrentStudentFromModule(
        currentStudent,
        moduleSelectionStore.getModuleGroupsWithAdditionalFields(),
    );

    const studentRating = getStudentRating(
        user.studentId ?? '',
        moduleSelectionStore.getStudentsWithRating(),
    );

    const isStudentUnique = checkIfStudentIsUnique(
        currentStudent,
        moduleSelectionStore.getNotDistribStudentsWithAdditionalFields(),
        moduleSelectionStore.getModuleGroupsWithAdditionalFields(),
    );

    const studentInfo = `${user.lastName} ${user.firstName} ${user.patronymic ?? ''} ${isStudentUnique ? '' : user.studentId}`;

    const handleChangeWetherStudentIsSelected = () => {
        if (currentStudent.isSelected) {
            moduleSelectionStore.setModuleGroupsWithAdditionalFields(
                getModuleStsWithRemovedFromAdditional(
                    currentStudent,
                    moduleSelectionStore.getModuleGroupsWithAdditionalFields(),
                ),
            );
        } else {
            moduleSelectionStore.setModuleGroupsWithAdditionalFields(
                getAllModulesWithNewAdditionalStudent(
                    currentStudent,
                    moduleSelectionStore.getModuleGroupsWithAdditionalFields(),
                ),
            );
        }
    };

    const handleChangeIfStudentFromModule = () => {
        const movedStudentsRemovedCurrentStudent = moduleSelectionStore.getMovedStudents().filter(
            (student) => student.user.studentId !== currentStudent.user.studentId,
        );

        deleteStudentModule(
            {
                baseTimeIntervalInstanceId,
                forkId,
                studentId: currentStudent.user.studentId ?? '',
                moduleId: getStudentCurrentModuleId(
                    currentStudent.user.studentId ?? '',
                    moduleSelectionStore.getModuleGroupsWithAdditionalFields(),
                ),
            },
        )
            .then((result) => {
                if (result && !result.errors) {
                    moduleSelectionStore.setModuleGroupsWithAdditionalFields(
                        getModulesStudentsWithRemovedCurrent(
                            currentStudent,
                            moduleSelectionStore.getModuleGroupsWithAdditionalFields(),
                        ),
                    );
                    moduleSelectionStore.setMovedStudents(
                        movedStudentsRemovedCurrentStudent,
                    );
                }
            });
    };

    const handleChangeIfStudentNotFromModule = () => {
        handleChangeWetherStudentIsSelected();

        moduleSelectionStore.setNotDistribStudentsWithAdditionalFields(
            getStudentsWithCurrentActive(
                moduleSelectionStore.getNotDistribStudentsWithAdditionalFields(),
                currentStudent,
            ),
        );
    };

    const handleChange = () => {
        if (
            isStudentFromModule
        ) {
            handleChangeIfStudentFromModule();
        } else {
            handleChangeIfStudentNotFromModule();
        }
    };
    return (
        <div
            className={cn(classes.item, {
                [classes.item_wasMoved]: currentStudent.wasMoved,
                [classes.item_fromModule]: isStudentFromModule,
                [classes.item_selected]: currentStudent.isSelected,
            })}
        >

            <div
                className={cn(classes.item__active, {
                    [classes.item__active_selected]:
                    currentStudent.isSelected || isStudentFromModule,
                })}
            >

                <CheckboxRedesign
                    id={user.id}
                    gap="7px"
                    checked={currentStudent.isSelected}
                    handleChangeCheckbox={() => handleChange()}
                >
                    <div
                        className={cn(classes.item__checkbox, {
                            [classes.item__checkbox_borderTop]: order === 0,
                        })}
                    >
                        <div className={classes.item__title}>
                            {studentInfo}
                        </div>
                        <div className={classes.item__rating}>
                            { studentRating }
                        </div>
                    </div>
                </CheckboxRedesign>

            </div>

            <div
                className={cn(classes.item__static, {
                    [classes.displayNone]:
                    currentStudent.isSelected || isStudentFromModule,
                })}
            >
                <div
                    className={cn(classes.item__checkbox, {
                        [classes.item__checkbox_borderTop]: order === 0,
                    })}
                >
                    <div className={classes.item__title}>
                        {studentInfo}
                    </div>
                    <div className={classes.item__rating}>
                        { studentRating }
                    </div>
                </div>
            </div>

        </div>
    );
});

function getModuleStsWithRemovedFromAdditional(
    currentStudent: StudentWithAdditionalFields,
    modules: ModuleGroupWithAdditionalFields[],
) {
    return modules.map((module) => (
        {
            ...module,
            additionalStudents: module.additionalStudents.filter(
                (student) => student.user.id !== currentStudent.user.id,
            ),
        }
    ));
}

function getModulesStudentsWithRemovedCurrent(
    currentStudent: StudentWithAdditionalFields,
    modules: ModuleGroupWithAdditionalFields[],
) {
    return modules.map((module) => ({
        ...module,
        students: module.students.filter((student) => student.user.id !== currentStudent.user.id),
        additionalStudents: module.additionalStudents.filter(
            (student) => student.user.id !== currentStudent.user.id,
        ),
    }));
}

function getAllModulesWithNewAdditionalStudent(
    newAdditionalStudent: StudentWithAdditionalFields,
    modules: ModuleGroupWithAdditionalFields[],
) {
    return modules.map((module) => (
        {
            ...module,
            additionalStudents: [...module.additionalStudents, newAdditionalStudent],
        }
    ));
}

function checkIfCurrentStudentFromModule(
    currentStudent: StudentWithAdditionalFields,
    modules: ModuleGroupWithAdditionalFields[],
) {
    return modules.some((module) => module.students.some(
        (student) => student.user.id === currentStudent.user.id,
    ));
}
