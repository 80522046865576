import React from 'react';
import { Priority } from './Priority';
import { PrioritySetMode } from '../../store';

import classes from './ModuleCardHeader.module.scss';

interface Props {
    priorities: number[]
    forkId?: string;
    slot?: {
        slotId: string;
        priority: number;
    };
    moduleId: string;
    setStudentPriorities?: (
        setMode: PrioritySetMode,
        forkId: string,
        moduleId: string,
        priority?: number,
    ) => void;
}

export const Priorities = ({
    priorities,
    forkId,
    slot,
    moduleId,
    setStudentPriorities,
}: Props) => (
    <div className={classes.moduleContent__priorities}>
        <div className={classes.moduleContent__priorities_list}>
            {priorities.map((priority) => (
                <Priority
                    key={`${priority}${forkId}`}
                    priority={priority}
                    slot={slot}
                    forkId={forkId}
                    moduleId={moduleId}
                    setStudentPriorities={setStudentPriorities}
                />
            ))
            }
        </div>
        {priorities.length > 1 && (
            <div className={classes.moduleContent__priorities_legend}>
                <div
                    className={classes.moduleContent__priorities_description}
                >
                    Выберите приоритет, где 1-й это наиболее приоритетный для вас модуль
                </div>
            </div>
        )}
    </div>
);
