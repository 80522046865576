import React from 'react';

import classes from './Preview.module.scss';

interface Props {
    previewText: string;
    label?: string;
    onEdit?(): void;
}
export const Preview = ({ previewText, label, onEdit }: Props): JSX.Element => (
    <div className={classes.preview}>
        <h3 className={classes.preview__label}>{label}</h3>
        <div className={classes.preview__container}>
            <div className={classes.preview__text}>{previewText}</div>
            {onEdit && (
                <button type="button" className={classes.preview__button} onClick={onEdit}>
                    Редактировать
                </button>
            )}
        </div>
    </div>
);
