import React from 'react';

import { Icon } from '../../../../NewModule/CustomIcon';
import { Skill } from '../../models';

import classes from './SkillResult.module.scss';

interface Props {
    isLevelIncreased: boolean;
    skill: Skill;
}

export function SkillResult({
    isLevelIncreased,
    skill,
}: Props): JSX.Element {
    return (
        <div className={classes.skill}>
            {skill.skill.fullName}&nbsp;

            {isLevelIncreased
                ? (
                    <span className={classes.skillLevel}>
                        {skill.level}
                    </span>
                ) : skill.isCredited && (
                    <div className={classes.iconCheckMark}>
                        <Icon id="checkMark" />
                    </div>
                )
            }
        </div>
    );
}
