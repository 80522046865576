import React from 'react';
import moment from 'moment';
import { observer } from 'mobx-react';

import { eventsStore } from '../../../../Store';

import classes from './EventDate.module.scss';
import { EvaluationPointInstanceTyped, FilterType, MeetingInstanceTyped } from '../../../../Interfaces';

const MINS_IN_HOUR = 45;

function hoursToMins(hours: number): number {
    return hours * MINS_IN_HOUR;
}

export const EventDate = observer((): JSX.Element => (
    <div
        className={classes.schedulePage_eventsSection_eventTime}
    >
        {eventsStore.selectedEvent?.type === FilterType.ASSIGNMENT
            ? (
                <>
                    <div className={classes.duration}>
                        Продолжительность:&nbsp;
                        {hoursToMins(eventsStore.selectedEvent
                            .assignmentInstance.assignment.duration.academicHours)}
                        мин
                    </div>
                    <div className={classes.endDate}>
                        до&nbsp;
                        {moment(eventsStore.selectedEvent.assignmentInstance.deadline).format('DD[.]MM')}
                    </div>
                </>
            ) : (
                <div className={classes.data}>
                    {moment((eventsStore.selectedEvent as (
                        MeetingInstanceTyped | EvaluationPointInstanceTyped
                    ))?.startDate)
                        .format('dddd[,] D MMM')}
                    {/* -
                    {moment(eventsStore.selectedEvent?.endDate)
                        .format('HH[:]mm')} */}
                </div>
            )}
    </div>
));
