import React, { useEffect, useState } from 'react';

import { EducationPeriodDatesView } from '@admin/EducationPeriodPage/EducationPeriodDates/EducationPeriodDatesView';
import {
    DaySchedule,
    IntervalWeekDay,
    IntervalWithWeek,
    Mode,
    RefetchGetSpaces,
    Space,
    SpaceEducationPeriod,
    WeekDaysRussian,
    WorkingInterval,
} from '@admin/EducationPeriodPage/EducationPeriodPageInterfaces';
import { EducationPeriodDatesEdit } from '@admin/EducationPeriodPage/EducationPeriodDates/EducationPeriodDatesEdit';
import { educationPeriodStore } from '@admin/EducationPeriodPage/EducationPeriodStore';
import { TabsWithoutRouteRedesign } from '@common/TabsWhithoutRouteRedesign';

import { ActionButtonRedesign, ActionTypeOfButton } from '@common/ActionButtonRedesign';

import classes from './EducationPeriodDates.module.scss';

interface Props {
    space: Space;
    dateMode: Mode;
    setDatesMode: (mode: Mode) => void;
    setCanCancelCreation: (canCancel: boolean) => void;
    refetchGetSpaces: RefetchGetSpaces;
}

type WeekDayInRussian = (keyof typeof WeekDaysRussian);
const allWeekDays: WeekDayInRussian[] = Object.keys(WeekDaysRussian)
    .map((key) => key as (keyof typeof WeekDaysRussian));

export function EducationPeriodDates(
    {
        space,
        dateMode,
        setDatesMode,
        setCanCancelCreation,
        refetchGetSpaces,
    }: Props,
) {
    const [currentPeriod,
        setCurrentPeriod] = useState<SpaceEducationPeriod>(space.spaceEducationPeriods[0]);

    const periodTabs = space.spaceEducationPeriods.map(
        (period) => ({ name: period?.name ?? '', id: period?.id ?? '' }),
    );

    useEffect(() => {
        if (space.spaceEducationPeriods.length) {
            setDatesMode(Mode.VIEW);
            setCanCancelCreation(true);
        } else {
            setCanCancelCreation(false);
            setDatesMode(Mode.CREATE);
        }
    }, [space]);

    useEffect(() => {
        educationPeriodStore.setEducationSpace(space);
        educationPeriodStore.clearUpdateParameters();
    }, []);

    return (
        <>
            {
                dateMode === Mode.VIEW && (
                    <div
                        className={classes.EducationPeriodDates__tabs}
                        style={{
                            padding: space.spaceEducationPeriods.length === 1 ? '20px 0' : '',
                        }}
                    >

                        {
                            space.spaceEducationPeriods.length === 1 ? null : (
                                <TabsWithoutRouteRedesign
                                    hasBottomBorder={false}
                                    tabs={periodTabs}
                                    activeTabId={currentPeriod?.id ?? ''}
                                    setActiveTabId={(id) => setCurrentPeriod(
                                        getActivePeriodById(
                                            id,
                                            space.spaceEducationPeriods,
                                        ) ?? space.spaceEducationPeriods[0],
                                    )}
                                />
                            )
                        }
                        <ActionButtonRedesign
                            name="edit"
                            type="button"
                            actionType={ActionTypeOfButton.GREY}
                            onClick={() => setDatesMode(Mode.EDIT)}
                            className={classes.educationPeriodSchedule__edit}
                        >
                            Редактировать учебный период
                        </ActionButtonRedesign>

                        <ActionButtonRedesign
                            actionType={ActionTypeOfButton.GREY}
                            onClick={() => setDatesMode(Mode.CREATE)}
                        >
                            Добавить учебный период
                        </ActionButtonRedesign>
                    </div>

                )
            }
            {
                dateMode ? (
                    <EducationPeriodDatesEdit
                        dateMode={dateMode}
                        currentPeriod={currentPeriod}
                        space={space}
                        intervalsWithWeek={
                            getAllIntervalsWithWeek(
                                space.spaceParameters.daySchedules,
                                allWeekDays,
                            )
                        }
                    />
                ) : (
                    <EducationPeriodDatesView
                        currentPeriod={currentPeriod}
                        space={space}
                        refetchGetSpaces={refetchGetSpaces}
                    />
                )
            }
        </>
    );
}

function getActivePeriodById(periodId: string, educationPeriods: SpaceEducationPeriod[]) {
    return educationPeriods.find((period) => period.id === periodId);
}

export function getBTIParameters(
    intervalId: string,
    currentPeriodId: string,
    periods: SpaceEducationPeriod[],
) {
    const parameters = periods.find(
        (period) => period.id === currentPeriodId,
    )?.spaceBaseTimeIntervalParameters;
    return parameters?.find((parameter) => parameter.spaceBaseTimeIntervalId === intervalId);
}

function getAllIntervalsWithWeek(weekSchedule: DaySchedule[], weekRussian: WeekDayInRussian[]) {
    const allUniqIntervals = getUniqIntervals(weekSchedule);
    const intervalsWithWeek: IntervalWithWeek[] = allUniqIntervals.map((interval) => ({
        interval,
        week: getIntervalDays(
            weekRussian,
            weekSchedule,
            interval,
        ),
    }));
    return intervalsWithWeek;
}

function getIntervalDays(
    weekRussian: WeekDayInRussian[],
    weekSchedule: DaySchedule[],
    interval: WorkingInterval,
) {
    const intervalDays: IntervalWeekDay[] = weekRussian.map((dayInWeek) => {
        const newDay = weekSchedule.find(
            (dayInSchedule) => dayInSchedule.weekDay === dayInWeek,
        );
        if (!newDay) {
            return {
                weekDay: dayInWeek,
                isActive: false,
            };
        }
        const dayWithCurrentInterval = newDay.workingIntervals.find(
            (workingInterval) => workingInterval.start === interval.start
                && workingInterval.end === interval.end,
        );

        return {
            ...newDay,
            isActive: !!dayWithCurrentInterval,
        };
    });

    return intervalDays;
}

function getUniqIntervals(weekSchedule: DaySchedule[]) {
    const allIntervals: WorkingInterval[] = getAllWeekDaysIntervals(weekSchedule);
    const uniqIntervals: WorkingInterval[] = [];

    allIntervals.forEach((workingInterval) => {
        if (!isIntervalUniq(uniqIntervals, workingInterval)) {
            uniqIntervals.push(workingInterval);
        }
    });
    return uniqIntervals;
}

function isIntervalUniq(uniqIntervals: WorkingInterval[], newInterval: WorkingInterval) {
    return uniqIntervals.find(
        (uniqInterval) => uniqInterval.start === newInterval.start
            && uniqInterval.end === newInterval.end,
    );
}

function getAllWeekDaysIntervals(weekSchedule: DaySchedule[]) {
    const allIntervals: WorkingInterval[] = [];
    weekSchedule.forEach((dayInWeek) => allIntervals.push(...dayInWeek.workingIntervals));
    return allIntervals;
}
