import React, { useState } from 'react';

import { groupBy } from 'lodash';

import { EditIcon, FilledEditIcon } from '@common/svg';

import classes from './FormatsList.module.scss';

import { FormatsCard } from '../FormatsCard';

interface Format {
    id: string;
    name: string;
}

interface Props {
    meetingFormats: Format[];
    updateMeetingFormat: (id: string, name: string) => void;
    deleteMeetingFormat: (id: string) => void;
}

export function FormatsList({
    meetingFormats,
    updateMeetingFormat,
    deleteMeetingFormat,
}: Props): JSX.Element {
    const [edit, toggleEdit] = useState(false);
    const letterArray = groupBy(meetingFormats,
        (item: Format) => item.name.slice(0, 1).toUpperCase());
    const itemArray = Object.entries(letterArray)
        .map((item: any) => item)
        .sort((a: any, b: any) => (a[0] > b[0] ? 1 : -1));
    return (
        <div className={classes.formatsList}>
            <div className={classes.formatsList__container}>
                <div className={classes.formatsList__title}>
                    Список форматов встреч:

                    <div className={classes.formatsList__icon} onClick={() => toggleEdit(!edit)}>
                        {!edit ? <EditIcon size={33} /> : <FilledEditIcon size={33} />}
                    </div>
                </div>
                <ul className={classes.formatsList__cardList}>
                    {itemArray.map((item: any, index: number) => (
                        <li
                            // eslint-disable-next-line
                            key={index}
                            className={classes.formatsList__cardItem}
                        >
                            <FormatsCard
                                cardFormats={item}
                                editState={edit}
                                updateMeetingFormat={updateMeetingFormat}
                                deleteMeetingFormat={deleteMeetingFormat}
                            />
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
}
