import { gql, useLazyQuery, useMutation } from '@apollo/client';

export enum ButtonName {
    START_SELECTION = 'Запустить выборность',
    START_SUBSPACE_SELECTION = 'Запустить выборность подпространств',
    START_SUBSPACE_DISTRIBUTION = 'Запустить предварительное распределение по подпространствам',
    START_MODULE_SELECTION = 'Запустить выборность модулей',
    START_MODULE_DISTRIBUTION = 'Запустить предварительное распределение по модулям',
    START_WAVE_DISTRIBUTION = 'Начать распределение по потокам',
    END_SUBSECTION_SELECTION = 'Завершить выборность подпространств',
    END_MODULE_SELECTION = 'Завершить выборность модулей',
    LOAD_SUBSPACE_DISTRIBUTION = 'Скачать предварительное распределение по подпространствам',
    LOAD_STUDENT_PRIORITY = 'Скачать приоритеты студентов',
    LOAD_MODULE_DISTRIBUTION = 'Скачать предварительное распределение по модулям',
    APPROVE_STUDENT_SUBSPACE_DISTRIBUTION = 'Подтвердить распределение студентов по подпространствам',
    APPROVE_STUDENT_MODULE_DISTRIBUTION = 'Подтвердить распределение студентов по модулям',
    NOT_ACTIVE = 'not active',
}

enum HandlersName {
    activateBaseTimeIntervalInstanceSelection = 'activateBaseTimeIntervalInstanceSelection',
    startSubspaceSelectionIteration = 'startSubspaceSelectionIteration',
    finishSubspaceSelectionIteration = 'finishSubspaceSelectionIteration',
    distributeStudentsIntoSubspaces = 'distributeStudentsIntoSubspaces',
    startModuleSelectionIteration = 'startModuleSelectionIteration',
    startStudentWaveDistribution = 'startStudentWaveDistribution',
    finishModuleSelectionIteration = 'finishModuleSelectionIteration',
    distributeStudentsIntoModules = 'distributeStudentsIntoModules',
    exportStudentsModulePrioritiesCsv = 'exportStudentsModulePrioritiesCsv',
    exportStudentsSubspaceDistributionCsv = 'exportStudentsSubspaceDistributionCsv',
    exportStudentsModuleDistributionCsv = 'exportStudentsModuleDistributionCsv',
    approveStudentSubspaceDistributionResults = 'approveStudentSubspaceDistributionResults',
    approveStudentModuleDistributionResults = 'approveStudentModuleDistributionResults',
}

const getMutationGraphQlSchema = (heandlerName: HandlersName) => gql`
mutation ${heandlerName}($baseTimeIntervalInstanceId: String!) {
    ${heandlerName}(baseTimeIntervalInstanceId:$baseTimeIntervalInstanceId) {
        baseTimeIntervalInstance {
            selectionStatus
        }
    }
}
`;

const getMutationGraphQlSchemaActivate = (heandlerName: HandlersName) => gql`
mutation ${heandlerName}($baseTimeIntervalInstanceId: String!) {
    ${heandlerName}(baseTimeIntervalInstanceId:$baseTimeIntervalInstanceId) {
        id
    }
}
`;

const getMutationGraphQlSchemaApprove = (heandlerName: HandlersName) => gql`
mutation ${heandlerName}($baseTimeIntervalInstanceId: String!) {
    ${heandlerName}(baseTimeIntervalInstanceId:$baseTimeIntervalInstanceId)
}`;

const getQueryGraphQlSchema = (heandlerName: HandlersName) => gql`
query ${heandlerName}($baseTimeIntervalInstanceId: String!){
    ${heandlerName}(baseTimeIntervalInstanceId:$baseTimeIntervalInstanceId)
}`;

export const buttonHooks = (
    id: string,
    setFile: React.Dispatch<React.SetStateAction<{
        value: string;
        name: string;
    }>>,
) => {
    const [activateSelection, {
        error: activateSelectionError,
        loading: activateSelectionLoading,
    }] = useMutation(
        getMutationGraphQlSchemaActivate(HandlersName.activateBaseTimeIntervalInstanceSelection),
        { variables: { baseTimeIntervalInstanceId: id } },
    );

    const [startSubsectionSelectionMutation, {
        error: startSubsectionSelectionError,
        loading: startSubsectionSelectionLoading,
    }] = useMutation(
        getMutationGraphQlSchema(HandlersName.startSubspaceSelectionIteration),
        { variables: { baseTimeIntervalInstanceId: id } },
    );

    const [startWaveDistributionMutation, {
        error: startWaveDistributionError,
        loading: startWaveDistributionLoading,
    }] = useMutation(
        getMutationGraphQlSchemaActivate(HandlersName.startStudentWaveDistribution),
        { variables: { baseTimeIntervalInstanceId: id } },
    );

    const [endSubsectionSelectionMutation, {
        error: endSubsectionSelectionError,
        loading: endSubsectionSelectionLoading,
    }] = useMutation(
        getMutationGraphQlSchema(HandlersName.finishSubspaceSelectionIteration),
        { variables: { baseTimeIntervalInstanceId: id } },
    );

    const [startSubspaceDistributionMutation, {
        error: startSubspaceDistributionError,
        loading: startSubspaceDistributionLoading,
    }] = useMutation(
        getMutationGraphQlSchema(HandlersName.distributeStudentsIntoSubspaces),
        { variables: { baseTimeIntervalInstanceId: id } },
    );

    const [startModuleSelectionMutation, {
        error: startModuleSelectionError,
        loading: startModuleSelectionLoading,
    }] = useMutation(
        getMutationGraphQlSchema(HandlersName.startModuleSelectionIteration),
        { variables: { baseTimeIntervalInstanceId: id } },
    );

    const [endModuleSelectionMutation, {
        error: endModuleSelectionError,
        loading: endModuleSelectionLoading,
    }] = useMutation(
        getMutationGraphQlSchema(HandlersName.finishModuleSelectionIteration),
        { variables: { baseTimeIntervalInstanceId: id } },
    );

    const [startModuleDistributionMutation, {
        error: startModuleDistributionError,
        loading: startModuleDistributionLoading,
    }] = useMutation(
        getMutationGraphQlSchema(HandlersName.distributeStudentsIntoModules),
        { variables: { baseTimeIntervalInstanceId: id } },
    );

    const [loadSubspaceDistribution, {
        error: loadSubspaceDistributionError,
        loading: loadSubspaceDistributionLoading,
    }] = useLazyQuery(
        getQueryGraphQlSchema(HandlersName.exportStudentsSubspaceDistributionCsv),
        {
            variables: { baseTimeIntervalInstanceId: id },
            fetchPolicy: 'no-cache',
            onCompleted: (data) => {
                setFile({
                    value: data
                        .exportStudentsSubspaceDistributionCsv,
                    name: 'Распределение студентов по подпространствам',
                });
            },
        },
    );

    const [loadStudentPriority, {
        error: loadStudentPriorityError,
        loading: loadStudentPriorityLoading,
    }] = useLazyQuery(
        getQueryGraphQlSchema(HandlersName.exportStudentsModulePrioritiesCsv),
        {
            variables: { baseTimeIntervalInstanceId: id },
            fetchPolicy: 'no-cache',
            onCompleted: (data) => {
                setFile({
                    value: data
                        .exportStudentsModulePrioritiesCsv,
                    name: 'Приоритеты студентов',
                });
            },
        },
    );

    const [
        loadModuleDistribution,
        {
            error: loadModuleDistributionError,
            loading: loadModuleDistributionLoading,
        },
    ] = useLazyQuery(
        getQueryGraphQlSchema(HandlersName.exportStudentsModuleDistributionCsv),
        {
            variables: { baseTimeIntervalInstanceId: id },
            fetchPolicy: 'no-cache',
            onCompleted: (data) => {
                setFile({
                    value: data
                        ?.exportStudentsModuleDistributionCsv,
                    name: 'Распределение студентов по модулям',
                });
            },
        },
    );

    const [
        approveStudentSubspaceDistribution,
        {
            error: approveStudentSubspaceDistributionError,
            loading: approveStudentSubspaceDistributionLoading,
        },
    ] = useMutation(
        getMutationGraphQlSchemaApprove(HandlersName.approveStudentSubspaceDistributionResults),
        { variables: { baseTimeIntervalInstanceId: id } },
    );

    const [
        approveStudentModuleDistribution,
        {
            error: approveStudentModuleDistributionError,
            loading: approveStudentModuleDistributionLoading,
        },
    ] = useMutation(
        getMutationGraphQlSchemaApprove(HandlersName.approveStudentModuleDistributionResults),
        { variables: { baseTimeIntervalInstanceId: id } },
    );

    return {
        [ButtonName.START_SUBSPACE_SELECTION]: startSubsectionSelectionMutation,
        [ButtonName.END_SUBSECTION_SELECTION]: endSubsectionSelectionMutation,
        [ButtonName.START_SUBSPACE_DISTRIBUTION]: startSubspaceDistributionMutation,
        [ButtonName.START_MODULE_SELECTION]: startModuleSelectionMutation,
        [ButtonName.END_MODULE_SELECTION]: endModuleSelectionMutation,
        [ButtonName.START_MODULE_DISTRIBUTION]: startModuleDistributionMutation,
        [ButtonName.START_SELECTION]: activateSelection,
        [ButtonName.LOAD_SUBSPACE_DISTRIBUTION]: async () => {
            await loadSubspaceDistribution();
        },
        [ButtonName.LOAD_MODULE_DISTRIBUTION]: async () => {
            await loadModuleDistribution();
        },
        [ButtonName.LOAD_STUDENT_PRIORITY]: async () => {
            await loadStudentPriority();
        },
        [ButtonName.APPROVE_STUDENT_SUBSPACE_DISTRIBUTION]: approveStudentSubspaceDistribution,
        [ButtonName.APPROVE_STUDENT_MODULE_DISTRIBUTION]: approveStudentModuleDistribution,
        [ButtonName.START_WAVE_DISTRIBUTION]: startWaveDistributionMutation,
        error: startModuleDistributionError
            || activateSelectionError
            || endModuleSelectionError
            || startModuleSelectionError
            || startSubspaceDistributionError
            || startSubsectionSelectionError
            || endSubsectionSelectionError
            || loadStudentPriorityError
            || loadSubspaceDistributionError
            || loadModuleDistributionError
            || approveStudentSubspaceDistributionError
            || startWaveDistributionError
            || approveStudentModuleDistributionError,
        loading: startSubsectionSelectionLoading
            || endSubsectionSelectionLoading
            || startSubspaceDistributionLoading
            || startModuleSelectionLoading
            || endModuleSelectionLoading
            || startModuleDistributionLoading
            || loadSubspaceDistributionLoading
            || loadStudentPriorityLoading
            || loadModuleDistributionLoading
            || approveStudentSubspaceDistributionLoading
            || approveStudentModuleDistributionLoading
            || startWaveDistributionLoading
            || activateSelectionLoading,
    };
};
