import React, { useEffect, useState } from 'react';
import { Select } from '../Select';

import classes from './EquipmentForZones.module.scss';

import { EquipmentTable } from '../EquipmentTable';

import { ByTerritorialZone, Equipment } from '../equipmentTypes';

interface Props {
    equipmentByTerritorialZone: ByTerritorialZone[];
}

export function EquipmentForZones({ equipmentByTerritorialZone }: Props): JSX.Element {
    const [selectedZone, setSelectedZone] = useState<string | null>(null);
    const [equipmentForTable, setEquipmentForTable] = useState<Equipment[]>([]);
    const territorialZone = equipmentByTerritorialZone
        .map((item: ByTerritorialZone) => item.territorialZone);
    useEffect(() => {
        if (selectedZone) {
            const currentZone = equipmentByTerritorialZone
                .filter((item: ByTerritorialZone) => item.territorialZone.id === selectedZone);
            const allEquipments = currentZone[0].consumableEquipment.map((item: Equipment) => item);
            setEquipmentForTable([...allEquipments]);
        }
    }, [selectedZone]);
    return (
        <div className={classes.equipmentForZones}>
            <div className={classes.equipmentForZones__select}>
                <Select
                    zones={territorialZone}
                    setSelectedZone={setSelectedZone}
                />
            </div>
            {equipmentForTable.length > 0 && (
                <div className={classes.equipmentForZones__table}>
                    <EquipmentTable
                        equipmentArray={equipmentForTable}
                    />
                </div>
            )}
        </div>
    );
}
