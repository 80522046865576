import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';

import { CrossIcon } from '@common/svg';
import { ActionButton } from '@common/ActionButton';

import { ForkCardDistribution } from './ForkCardDistribution';
import { AutomaticallyChoice } from './AutomaticallyChoice';
import {
    DistributionType,
    Fork,
    ForkSettingInput,
    UpdateForkSettingInput,
} from '../subSpaceTypes';
import classes from './ForkCard.module.scss';
import Diagram from '../store/Diagram';
import { StudentChoice } from './StudentChoice';

interface Props {
    fork: Fork
    updateForkSetting(updateForkSettingInput: UpdateForkSettingInput): void
}

export const ForkCard = observer(({ fork, updateForkSetting }: Props): JSX.Element => {
    const { id, spaceBaseTimeIntervalId } = fork;
    const { forkSettingForm } = Diagram;
    const {
        distributionType,
        moduleSelectionCount,
        studentPickingType,
        overbooking,
        transitionalEmptyModule,
        transitionalUnderfilledModule,
        studentPriorityCount,
    } = forkSettingForm;

    const selectionCount = fork?.setting?.moduleSelectionCount ?? 1;
    useEffect(() => {
        Diagram.setForkSettingsForm<string>(String(selectionCount), 'moduleSelectionCount');
        Diagram.setDistributionType(fork.setting?.distributionType ?? distributionType);
        Diagram.setForkSettingsForm<number>(fork.setting?.studentPriorityCount ?? 1, 'studentPriorityCount');
        Diagram.setForkSettingsForm(fork.setting?.studentPickingType ?? 'randomly', 'studentPickingType');
    }, []);
    const numberOfPossibleModules: number = fork.nextForks.length
        + fork.nextSlots.length
        + fork.nextGroupSlots.length || 0;

    const getCurrentForkSettings = (distributionTypeInput: DistributionType): ForkSettingInput => {
        if (distributionType === DistributionType.AUTOMATICALLY) {
            return {
                distributionType: distributionTypeInput,
                moduleSelectionCount,
                studentPickingType,
            };
        }

        if (distributionType === DistributionType.STUDENTCHOICE) {
            return {
                distributionType: distributionTypeInput,
                moduleSelectionCount,
                transitionalUnderfilledModule,
                studentPickingType,
                transitionalEmptyModule,
                overbooking,
                distributionModuleId: undefined,
                studentPriorityCount,
            };
        }

        if (distributionType === DistributionType.MANUALLY) {
            return {
                distributionType: distributionTypeInput,
                moduleSelectionCount,
            };
        }

        return {
            distributionType: distributionTypeInput,
            moduleSelectionCount,
        };
    };

    return (
        <div
            className={classes.forkCard}
            onClick={() => Diagram.setShowForkDialog()}
        >
            <div
                className={classes.forkCard__container}
                onClick={(e) => e.stopPropagation()}
            >
                <div className={classes.forCard__title}>
                    Настройки развилки прохождения подпространства
                </div>
                <div className={classes.forkCard__moduleCount}>
                    <div className={classes.forkCard__subtitle}>
                        Сколько модулей нужно выбрать в этой развилке:
                    </div>
                    <input
                        className={classes.forkCard__moduleInput}
                        placeholder="Введите количество"
                        onChange={({ target }) => {
                            const value = Number(target.value);
                            if (Number.isNaN(value)) {
                                Diagram.setForkSettingsForm<string>('0',
                                    'moduleSelectionCount');
                            }
                            if (numberOfPossibleModules >= value) {
                                Diagram.setForkSettingsForm<string>(target.value,
                                    'moduleSelectionCount');
                            } else {
                                Diagram.setForkSettingsForm<string>(String(numberOfPossibleModules),
                                    'moduleSelectionCount');
                            }
                        }}
                        value={Diagram.forkSettingForm.moduleSelectionCount}
                        type="number"
                        min={0}
                        max={numberOfPossibleModules}
                    />
                </div>
                <ForkCardDistribution />
                {distributionType === DistributionType.AUTOMATICALLY && (
                    <AutomaticallyChoice
                        studentPickingType={fork?.setting?.studentPickingType || studentPickingType}
                    />
                )}
                {distributionType === DistributionType.STUDENTCHOICE && (
                    <StudentChoice
                        nextSlotscount={numberOfPossibleModules}
                        studentPickingType={fork?.setting?.studentPickingType || studentPickingType}
                    />
                )}
                <div className={classes.forkCard__button}>
                    <ActionButton
                        onClick={() => {
                            Diagram.saveForkToBtiSettings(id, spaceBaseTimeIntervalId);
                            updateForkSetting({
                                forkId: fork.id,
                                setting: getCurrentForkSettings(
                                    distributionType,
                                ),
                            });
                        }}
                    >
                        Сохранить
                    </ActionButton>
                </div>
                <CrossIcon
                    className={classes.forkCard__icon}
                    handler={() => Diagram.setShowForkDialog()}
                />
            </div>
        </div>
    );
});
