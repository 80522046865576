import { gql } from '@apollo/client';

export const GET_ASSIGNMENT_INSTANCES = gql`
query filteredStudentAssignmentInstances ($assignmentInstanceInput: StudentAssignmentInstancesInput!){
    filteredStudentAssignmentInstances(assignmentInstancesInput: $assignmentInstanceInput) {
        assignmentInstance{
            id
            start
            deadline
            nextMeetingInstance {
                meeting {
                    topic
                }
            }
            nextMeetings {
                topic
            }
            assignment {
                topic
                description
                module {
                    name
                }
                duration {
                    id
                    name
                    academicHours
                }
            }
        }
        note
        isCompleted
    }
}
`;

export const EDIT_ASSIGNMENT_INSTANCE_NOTE = gql`
mutation updateAssignmentInstanceNote($data: UpdateAssignmentInstanceStudentNoteInput!) {
    updateAssignmentInstanceStudentNote(updateAssignmentInstanceStudentNoteInput: $data) {
        id
    }
}
`;
