import React from 'react';
import cn from 'classnames';

import style from './Button.module.scss';

interface Props {
    onClick?(): void;
    children?: any;
    modifier?: string;
    title?: string;
    disabled?: boolean;
}

export const Button = ({
    onClick = () => { },
    children,
    modifier,
    title = '',
    disabled = false,
}: Props): JSX.Element => (
    <button
        aria-label="button"
        type="submit"
        className={cn(style.button, { [style[`button_${modifier}`]]: modifier })}
        onClick={onClick}
        title={title}
        disabled={disabled}
    >
        {children}
    </button>
);
