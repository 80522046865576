import React from 'react';

interface Props {
    size?: number;
    className?: string;
}

export function WhitePointIcon({
    size = 6,
    className,
}: Props) {
    return (
        <>
            <div className={className} style={{ height: `${size}px`, width: `${size}px`, cursor: 'default' }}>
                <svg width={size} height={size} viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="3" cy="3" r="3" fill="#FAFAFA" />
                </svg>

            </div>
        </>
    );
}
