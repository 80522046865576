/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateStudentsBTI
// ====================================================

export interface updateStudentsBTI_updateStudentsBaseTimeIntervalInstance_space {
  __typename: "Space";
  id: string;
  name: string;
}

export interface updateStudentsBTI_updateStudentsBaseTimeIntervalInstance_activeBaseTimeIntervalInstance_baseTimeIntervalInstance_spaceBaseTimeInterval_space_baseTimeIntervalType {
  __typename: "BaseTimeIntervalType";
  name: string;
}

export interface updateStudentsBTI_updateStudentsBaseTimeIntervalInstance_activeBaseTimeIntervalInstance_baseTimeIntervalInstance_spaceBaseTimeInterval_space {
  __typename: "Space";
  id: string;
  name: string;
  baseTimeIntervalType: updateStudentsBTI_updateStudentsBaseTimeIntervalInstance_activeBaseTimeIntervalInstance_baseTimeIntervalInstance_spaceBaseTimeInterval_space_baseTimeIntervalType;
}

export interface updateStudentsBTI_updateStudentsBaseTimeIntervalInstance_activeBaseTimeIntervalInstance_baseTimeIntervalInstance_spaceBaseTimeInterval {
  __typename: "SpaceBaseTimeInterval";
  order: number;
  space: updateStudentsBTI_updateStudentsBaseTimeIntervalInstance_activeBaseTimeIntervalInstance_baseTimeIntervalInstance_spaceBaseTimeInterval_space;
}

export interface updateStudentsBTI_updateStudentsBaseTimeIntervalInstance_activeBaseTimeIntervalInstance_baseTimeIntervalInstance {
  __typename: "BaseTimeIntervalInstance";
  id: string;
  spaceBaseTimeInterval: updateStudentsBTI_updateStudentsBaseTimeIntervalInstance_activeBaseTimeIntervalInstance_baseTimeIntervalInstance_spaceBaseTimeInterval;
}

export interface updateStudentsBTI_updateStudentsBaseTimeIntervalInstance_activeBaseTimeIntervalInstance {
  __typename: "StudentBaseTimeIntervalInstance";
  baseTimeIntervalInstance: updateStudentsBTI_updateStudentsBaseTimeIntervalInstance_activeBaseTimeIntervalInstance_baseTimeIntervalInstance;
}

export interface updateStudentsBTI_updateStudentsBaseTimeIntervalInstance_skills_skill {
  __typename: "Skill";
  fullName: string;
}

export interface updateStudentsBTI_updateStudentsBaseTimeIntervalInstance_skills {
  __typename: "StudentSkill";
  level: number | null;
  skill: updateStudentsBTI_updateStudentsBaseTimeIntervalInstance_skills_skill;
}

export interface updateStudentsBTI_updateStudentsBaseTimeIntervalInstance_user {
  __typename: "User";
  id: string;
}

export interface updateStudentsBTI_updateStudentsBaseTimeIntervalInstance {
  __typename: "Student";
  id: string;
  space: updateStudentsBTI_updateStudentsBaseTimeIntervalInstance_space | null;
  activeBaseTimeIntervalInstance: updateStudentsBTI_updateStudentsBaseTimeIntervalInstance_activeBaseTimeIntervalInstance | null;
  skills: updateStudentsBTI_updateStudentsBaseTimeIntervalInstance_skills[];
  user: updateStudentsBTI_updateStudentsBaseTimeIntervalInstance_user;
}

export interface updateStudentsBTI {
  updateStudentsBaseTimeIntervalInstance: updateStudentsBTI_updateStudentsBaseTimeIntervalInstance[];
}

export interface updateStudentsBTIVariables {
  input: UpdateStudentsBaseTimeIntervalInstanceInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: baseTimeIntervalInstances
// ====================================================

export interface baseTimeIntervalInstances_baseTimeIntervalInstances_spaceBaseTimeInterval_space_baseTimeIntervalType {
  __typename: "BaseTimeIntervalType";
  name: string;
}

export interface baseTimeIntervalInstances_baseTimeIntervalInstances_spaceBaseTimeInterval_space {
  __typename: "Space";
  id: string;
  name: string;
  baseTimeIntervalType: baseTimeIntervalInstances_baseTimeIntervalInstances_spaceBaseTimeInterval_space_baseTimeIntervalType;
}

export interface baseTimeIntervalInstances_baseTimeIntervalInstances_spaceBaseTimeInterval {
  __typename: "SpaceBaseTimeInterval";
  order: number;
  space: baseTimeIntervalInstances_baseTimeIntervalInstances_spaceBaseTimeInterval_space;
}

export interface baseTimeIntervalInstances_baseTimeIntervalInstances {
  __typename: "BaseTimeIntervalInstance";
  id: string;
  startDate: string;
  endDate: string;
  spaceEducationPeriodId: string;
  spaceBaseTimeInterval: baseTimeIntervalInstances_baseTimeIntervalInstances_spaceBaseTimeInterval;
}

export interface baseTimeIntervalInstances {
  baseTimeIntervalInstances: baseTimeIntervalInstances_baseTimeIntervalInstances[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: importUsers
// ====================================================

export interface importUsers {
  importUsers: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: addUsersTypes
// ====================================================

export interface addUsersTypes_addUsersTypes {
  __typename: "RoleToUser";
  id: string;
  roleId: string;
}

export interface addUsersTypes {
  addUsersTypes: addUsersTypes_addUsersTypes[] | null;
}

export interface addUsersTypesVariables {
  addType: AddUserTypeInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: editStatus
// ====================================================

export interface editStatus_editStatus {
  __typename: "User";
  id: string;
}

export interface editStatus {
  // editStatus: (editStatus_editStatus | null)[] | null;
  ids: string[] | void | null;
  types: string[];
  status: string;
}

export interface editStatusVariables {
  editStatusByType: EditStatusInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum Type {
  admin = "admin",
  all = "all",
  none = "none",
  student = "student",
  teacher = "teacher",
}

export enum UserStatus {
  active = "active",
  deleted = "deleted",
  inactive = "inactive",
}

export interface AddUserTypeInput {
  ids: string[];
  type: Type[];
}

export interface EditStatusInput {
  ids: string[];
  types: Type[];
  status: UserStatus;
}

export interface UpdateStudentsBaseTimeIntervalInstanceInput {
  ids: string[];
  baseTimeIntervalInstanceId?: string | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
