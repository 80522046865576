import React from 'react';
import { NavLink, useRouteMatch } from 'react-router-dom';

import classes from './GradientTabs.module.scss';

interface Props {
    tabsOptions: string[][];
}

export function GradientTabs({ tabsOptions }: Props): JSX.Element {
    const { path } = useRouteMatch();
    return (
        <div className={classes.tabs}>
            <ul className={classes.tabs__list}>
                {[...Array(tabsOptions.length)].map((_, index: number) => (
                    <li
                        key={tabsOptions[index][1]}
                        className={classes.tabs__item}
                    >
                        <NavLink
                            className={classes.tabs__link}
                            activeClassName={classes.tabs__link_selected}
                            to={`${path}/${tabsOptions[index][1]}`}
                        >
                            {tabsOptions[index][0]}
                        </NavLink>
                    </li>
                ))}
            </ul>
        </div>
    );
}
