import { Subspace } from '../interfaces';

export enum SubspaceStatus {
    Muted = 'muted',
    Normal = 'normal',
    Active = 'active',
}

export enum SubspaceAction {
    Active = 'active',
    Deactive = 'deactive',
}

export interface SubspacesStatuses {
    [subspaceId: string]: SubspaceStatus
}

export class SubspacesView {
    private value: SubspacesStatuses;

    constructor(subspaces: Subspace[]) {
        this.value = this.init(subspaces);
    }

    private init(subspaces: Subspace[]) {
        return subspaces.reduce((acc: SubspacesStatuses, subspace) => {
            const subspaceStatus = { [subspace.id]: SubspaceStatus.Normal };
            return { ...acc, ...subspaceStatus };
        }, {});
    }

    public setSubspacesStatus(status: SubspaceStatus): void {
        Object.keys(this.value).forEach(key => { this.value[key] = status; });
    }

    public setSubspaceStatus(subspaceId: string, status: SubspaceStatus): void {
        this.value[subspaceId] = status;
    }

    public getValue(): SubspacesStatuses {
        return Object.keys(this.value).reduce((acc: SubspacesStatuses, key) => ({
            ...acc,
            [key]: this.value[key],
        }), {});
    }

    public setValue(statuses: SubspacesStatuses): void {
        this.value = {};
        Object.keys(statuses).forEach(key => { this.value[key] = statuses[key]; });
    }

    public hasSubspaceStatus(subspaceId: string, status: SubspaceStatus): boolean {
        return this.value[subspaceId] === status;
    }
}
