import React, { useState } from 'react';
import classnames from 'classnames';

import classes from '../table.module.scss';
import { ListUser_users_list_student_skills } from '../../apollo-types';

interface Props {
    userSkills?: ListUser_users_list_student_skills[];
}

export const Skills = ({ userSkills }: Props) => {
    const [isHovered, setIsHovered] = useState<boolean>(false);
    const onHover = () => {
        setIsHovered(!isHovered);
    };
    const studentSkills = userSkills?.map(({ skill, level }, index) => {
        const isLastSkill = index === (userSkills?.length! - 1);
        const skillRender = `${skill?.fullName} ${level ?? ''}`;
        if (isLastSkill) {
            return `${skillRender ?? ''} `;
        }
        return `${skillRender ?? ''}, `;
    });
    return (
        <>
            <td
                className={classes.table__cell}
                onMouseEnter={onHover}
                onMouseLeave={onHover}
            >
                <p className={classnames(classes.table__name, classes.table__name_text)}>
                    {studentSkills}
                </p>
                {studentSkills?.length
                    ? (
                        <div className={classnames(classes.table__showMoreSkills,
                            { [classes.table__showMoreSkills_visible]: isHovered })}
                        >
                            <div className={classes.table__showMoreSkillsText}>
                                {studentSkills}
                            </div>
                        </div>
                    )
                    : ''
                }
            </td>
        </>
    );
};
