import { SubspaceType, Subspace } from '../interfaces';

export function getSubspaces(types: SubspaceType[]): Subspace[] {
    return types.reduce((acc: Subspace[], type) => [...acc, ...type.subspaces], []);
}

export function copySubspaces(subspaces: Subspace[]): Subspace[] {
    return subspaces.reduce((acc: Subspace[], subspace) => {
        const copiedSubspace: Subspace = {
            id: subspace.id,
            name: subspace.name,
            sourceSubspaces: [...subspace.sourceSubspaces],
            targetSubspaces: [...subspace.targetSubspaces],
        };

        return [...acc, copiedSubspace];
    }, []);
}
