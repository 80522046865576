import { ApolloError, useMutation } from '@apollo/client';
import { EventTeacher } from '@admin/ScheduleGenerationPage/types';
import { GET_EVENT_DATA, UPDATE_MEETING_TEACHER } from '../../queries';

export const useUpdateMeetingTeacherMutation = (
    id: string,
    type: string,
    setErrorsFunction: (error: ApolloError) => void,
) => {
    const [
        updateMeetingTeacherMutation,
    ] = useMutation(UPDATE_MEETING_TEACHER, {
        refetchQueries: [{
            query: GET_EVENT_DATA,
            variables: {
                tmpMeetingInstanceInput: { id, type },
            },
        }],
        onError: (error) => setErrorsFunction(error),
    });

    return {
        updateMeetingTeacher(
            currentTeacherId: string,
            eventTeachers: EventTeacher[],
        ) {
            const currentTeacher = eventTeachers
                .find((item: EventTeacher) => item.currentTeacherId === currentTeacherId);
            updateMeetingTeacherMutation({
                variables: {
                    updateMeetingInstanceTeacherInput: {
                        currentTeacherId,
                        newTeacherId: currentTeacher?.newTeacherId,
                        meetingInstanceId: currentTeacher?.meetingInstanceId,
                    },
                },
            });
        },
    };
};
