import React, { useState } from 'react';

import { Calendar } from '@admin/EducationPeriodPage/CalendarWithTime/Calendar';
import { Time } from '@admin/EducationPeriodPage/CalendarWithTime/Time';
import classes from './CalendarWithTime.module.scss';

interface Props {
    currentDate?: Date;
    showTime?: boolean;
    autoFocus?: boolean;
    dateFormat?: string;
    showYearPicker?: boolean;
    onChangeCalendar(date: Date): void;
    onCloseCalendar?: () => void;
    onOpenCalendar?: () => void;
}

export function CalendarWithTime(
    {
        currentDate,
        showTime,
        autoFocus,
        showYearPicker,
        dateFormat,
        onChangeCalendar,
        onCloseCalendar,
        onOpenCalendar,
    }: Props,
) {
    const [isTimeVisible, setTimeVisible] = useState(false);
    const [date, setDate] = useState<Date>(currentDate || new Date());

    return (
        <div className={classes.calendarWithTime}>
            <Calendar
                currentDate={date}
                onChange={(newDate) => {
                    setDate(newDate);
                    onChangeCalendar(newDate);
                }}
                onOpenCalendar={onOpenCalendar}
                onCloseCalendar={onCloseCalendar}
                // timeSwitcher={<Time />}
                autoFocus={autoFocus}
                dateFormat={dateFormat}
                showYearPicker={showYearPicker}
                shouldCloseOnSelect
                showTime={showTime}
                setTimeInputVisible={setTimeVisible}
                timeInputWithSwitcher={(
                    <Time
                        setTimeVisible={setTimeVisible}
                        isTimeVisible={isTimeVisible}
                        currentDate={
                            formatDayForTime(date)
                        }
                    />
                )}
            />
        </div>
    );
}

function formatDayForTime(date?: Date) {
    if (date) {
        return JSON.stringify(date.toLocaleDateString().split('"').join(' '));
    }
    return JSON.stringify(new Date().toLocaleDateString().split('"').join(' '));
}

// function getDate(isRange: boolean, date: Date[]) {
//     if (isRange) {
//         const [startDate, endDate] = date;
//         return [startDate, endDate];
//     }
//
//     return date;
// }
