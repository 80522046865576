import React, { useState } from 'react';

import classes from './Comment.module.scss';
import { NoteArea } from '../../../../Note/NoteArea';
import { SwitcherTemp } from '../../SwitcherTemp';

interface Props {
    status: boolean;
    isEditing: boolean;
    label: string;
    comment: string;
    setComment(comment: string): void;
}

export function Comment({
    status,
    isEditing,
    label,
    comment,
    setComment,
}: Props): JSX.Element {
    const [isShow, setIsShow] = useState<boolean>(true);

    function handleToggle() {
        setIsShow((prevIsShow) => !prevIsShow);
        setComment('');
    }

    function addNewNote(note: string): void {
        setComment(note);
    }

    return (
        <div className={classes.comment}>
            {isEditing && !status
                ? (
                    <SwitcherTemp
                        label={label}
                        checked={isShow}
                        onClick={handleToggle}
                    />
                )
                : comment && (
                    <div className={classes.comment__label}>
                        {label}
                    </div>
                )
            }

            {(isEditing && !status) && isShow && (
                <div className={classes.comment__container}>
                    <NoteArea
                        eventNote={comment || ''}
                        addNewNote={addNewNote}
                    />
                </div>
            )}

            {!isEditing && comment && (
                <div className={classes.comment__text}>
                    «{comment}»
                </div>
            )}
        </div>
    );
}
