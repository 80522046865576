import { ApolloError, gql } from '@apollo/client';

import { useMutationWithErrorHandling } from './useMutationWithErrorHandling';

const CREATE_SCHEDULE_GENERATION = gql`
    mutation createScheduleGeneration($input: CreateScheduleGenerationProcessInput!) {
        createScheduleGenerationProcess(createScheduleGenerationProcessInput: $input) {
            id
        }
    }
`;

interface CreateScheduleGenerationProcessInput {
    name: string;
    from?: Date;
    to?: Date;
    baseTimeIntervalInstanceIds: string[];
}

export type CreateScheduleGenerationFunc = [(
    input: CreateScheduleGenerationProcessInput,
) => void, ApolloError | undefined];

export function useCreateScheduleGenerationMutation(
    onCompleted?: () => void,
): CreateScheduleGenerationFunc {
    const [createScheduleGeneration, { error }] = useMutationWithErrorHandling(
        CREATE_SCHEDULE_GENERATION,
        { onCompleted },
    );

    return [(input: CreateScheduleGenerationProcessInput) => createScheduleGeneration({
        variables: { input },
    }), error];
}
