import React from 'react';
import { Dialog } from '@common/Dialog';
import { IconDeprecated } from '@common/IconDeprecated';
import classes from './modal.module.scss';

interface Props {
    children: React.ReactNode
    isOpen: boolean
    onCancel: (...args: any[]) => any
}

const Modal = ({
    children,
    isOpen,
    onCancel,
}: Props): JSX.Element | null => {
    const dom = (
        <div className={classes.modalContainer}>
            <IconDeprecated
                id="Cross"
                className={classes.crossIcon}
                click={onCancel}
            />
            {children}
        </div>
    );

    return isOpen ? (
        <Dialog
            id="portal-edit-root"
            dom={dom}
        />
    ) : null;
};

export default Modal;
