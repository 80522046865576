import { DocumentNode, TypedDocumentNode, OperationVariables, gql } from '@apollo/client';

export type ApolloQuery<SourceType> = DocumentNode | TypedDocumentNode<{
    options: SourceType[];
}, OperationVariables>;

export interface SourceTypeInterface {
    id: string,
}

export interface SelectApolloProps<SourceType extends SourceTypeInterface> {
    className?: string,
    query?: ApolloQuery<SourceType>,
    options?: SourceType[],
    selectedOption?: SourceType,
    urlParameterName?: string,
    onChange?(item: SourceType): void,
    formatOption?: (item: SourceType) => string,
    transformResponse?: (response:any) => SourceType[],
}

export interface SelectProps <SourceType extends SourceTypeInterface> {
    className?: string,
    options: SourceType[],
    selectedOption?: SourceType,
    isOptionsLoaded?: boolean,
    urlParameterName?: string,
    addError(message: string): void,
    formatOption(item: SourceType): string,
    onChange(items: SourceType): void,
}

export const defaultQuery = gql`query {defaultQuery {defaultValue}}`;
