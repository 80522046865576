/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetEquipmentsEquipmentPage
// ====================================================

export interface GetEquipmentsEquipmentPage_equipment_list_category {
  __typename: "EquipmentCategory";
  id: string;
  name: string;
}

export interface GetEquipmentsEquipmentPage_equipment_list_features {
  __typename: "EquipmentFeature";
  id: string;
  name: string;
}

export interface GetEquipmentsEquipmentPage_equipment_list_movability {
  __typename: "EquipmentMovability";
  id: string;
  isMovable: boolean;
  count: number;
  territorialZoneId: string | null;
}

export interface GetEquipmentsEquipmentPage_equipment_list_rooms_room {
  __typename: "Room";
  id: string;
  title: string;
}

export interface GetEquipmentsEquipmentPage_equipment_list_rooms {
  __typename: "EquipmentToRoom";
  countEquipments: number;
  room: GetEquipmentsEquipmentPage_equipment_list_rooms_room | null;
}

export interface GetEquipmentsEquipmentPage_equipment_list {
  __typename: "Equipment";
  id: string;
  category: GetEquipmentsEquipmentPage_equipment_list_category;
  features: GetEquipmentsEquipmentPage_equipment_list_features[];
  movability: GetEquipmentsEquipmentPage_equipment_list_movability[];
  rooms: GetEquipmentsEquipmentPage_equipment_list_rooms[];
}

export interface GetEquipmentsEquipmentPage_equipment {
  __typename: "EquipmentResponse";
  list: GetEquipmentsEquipmentPage_equipment_list[];
  totalCount: number;
}

export interface GetEquipmentsEquipmentPage {
  equipment: GetEquipmentsEquipmentPage_equipment;
}

export interface GetEquipmentsEquipmentPageVariables {
  filterParams: EquipmentFilterParams;
  paginationParams: EquipmentPaginationParams;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: RemoveEquipmentEquipmentPage
// ====================================================

export interface RemoveEquipmentEquipmentPage {
  removeEquipment: boolean;
}

export interface RemoveEquipmentEquipmentPageVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: TerritorialZonesEquipmentPage
// ====================================================

export interface TerritorialZonesEquipmentPage_territorialZones {
  __typename: "TerritorialZone";
  id: string;
  name: string;
}

export interface TerritorialZonesEquipmentPage {
  territorialZones: TerritorialZonesEquipmentPage_territorialZones[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateEquipmentEquipmentPage
// ====================================================

export interface UpdateEquipmentEquipmentPage_updateEquipment_category {
  __typename: "EquipmentCategory";
  id: string;
  name: string;
}

export interface UpdateEquipmentEquipmentPage_updateEquipment_features {
  __typename: "EquipmentFeature";
  id: string;
  name: string;
}

export interface UpdateEquipmentEquipmentPage_updateEquipment_movability {
  __typename: "EquipmentMovability";
  id: string;
  isMovable: boolean;
  count: number;
  territorialZoneId: string | null;
}

export interface UpdateEquipmentEquipmentPage_updateEquipment_rooms_room {
  __typename: "Room";
  id: string;
  title: string;
}

export interface UpdateEquipmentEquipmentPage_updateEquipment_rooms {
  __typename: "EquipmentToRoom";
  countEquipments: number;
  room: UpdateEquipmentEquipmentPage_updateEquipment_rooms_room | null;
}

export interface UpdateEquipmentEquipmentPage_updateEquipment {
  __typename: "Equipment";
  id: string;
  category: UpdateEquipmentEquipmentPage_updateEquipment_category;
  features: UpdateEquipmentEquipmentPage_updateEquipment_features[];
  movability: UpdateEquipmentEquipmentPage_updateEquipment_movability[];
  rooms: UpdateEquipmentEquipmentPage_updateEquipment_rooms[];
}

export interface UpdateEquipmentEquipmentPage {
  updateEquipment: UpdateEquipmentEquipmentPage_updateEquipment;
}

export interface UpdateEquipmentEquipmentPageVariables {
  updateEquipmentInput: UpdateEquipmentInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateEquipmentCategoryEquipmentPage
// ====================================================

export interface UpdateEquipmentCategoryEquipmentPage_updateEquipmentCategory {
  __typename: "EquipmentCategory";
  id: string;
  name: string;
}

export interface UpdateEquipmentCategoryEquipmentPage {
  updateEquipmentCategory: UpdateEquipmentCategoryEquipmentPage_updateEquipmentCategory;
}

export interface UpdateEquipmentCategoryEquipmentPageVariables {
  updateEquipmentCategoryInput: UpdateEquipmentCategoryInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export interface EquipmentFilterParams {
  namePattern?: string | null;
  isComputer: boolean;
  roomId?: string | null;
  movability?: EquipmentMovabilityParam[] | null;
}

export interface EquipmentMovabilityParam {
  isMovable: boolean;
  territorialZoneId?: string | null;
}

export interface EquipmentPaginationParams {
  limit?: number | null;
  offset?: number | null;
}

export interface UpdateEquipmentCategoryInput {
  id: string;
  name: string;
}

export interface UpdateEquipmentFeatureInput {
  id: string;
  name?: string | null;
}

export interface UpdateEquipmentInput {
  id: string;
  isComputer: boolean;
  features?: UpdateEquipmentFeatureInput[] | null;
  movability?: UpdateEquipmentMovabilityInput[] | null;
}

export interface UpdateEquipmentMovabilityInput {
  id?: string | null;
  isMovable: boolean;
  count: number;
  territorialZoneId?: string | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: createEq
// ====================================================

export interface createEq_createEquipment {
  __typename: "Equipment";
  id: string;
}

export interface createEq {
  createEquipment: createEq_createEquipment;
}

export interface createEqVariables {
  equipment: CreateEquipmentInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: RemoveEquipment
// ====================================================

export interface RemoveEquipment_removeEquipment {
  __typename: "RemoveStatus";
  status: boolean | null;
}

export interface RemoveEquipment {
  removeEquipment: RemoveEquipment_removeEquipment;
}

export interface RemoveEquipmentVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getEquipment
// ====================================================

export interface getEquipment_equipments {
  __typename: "Equipment";
  id: string;
  name: string;
  count: number;
}

export interface getEquipment {
  equipments: getEquipment_equipments[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetModules
// ====================================================

export interface GetModules_modules {
  __typename: "Module";
  id: string;
  name: string;
}

export interface GetModules {
  modules: GetModules_modules[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: RemoveModule
// ====================================================

export interface RemoveModule_removeModule {
  __typename: "Module";
  id: string;
}

export interface RemoveModule {
  removeModule: RemoveModule_removeModule;
}

export interface RemoveModuleVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetModuleAndSettings
// ====================================================

export interface GetModuleAndSettings_module_teachersRoles {
  __typename: "TeacherRole";
  id: string;
  name: string | null;
}

export interface GetModuleAndSettings_module_moduleTeachers_teacherRoles {
  __typename: "TeacherRole";
  id: string;
  name: string | null;
}

export interface GetModuleAndSettings_module_moduleTeachers {
  __typename: "ModuleTeacher";
  id: string;
  name: string;
  teacherRoles: GetModuleAndSettings_module_moduleTeachers_teacherRoles[] | null;
}

export interface GetModuleAndSettings_module_prerequisiteSkills {
  __typename: "ModuleSkill";
  id: string;
  name: string | null;
  typeId: string;
  level: number | null;
  childrenIds: string[] | null;
}

export interface GetModuleAndSettings_module_outputSkills {
  __typename: "ModuleSkill";
  id: string;
  name: string | null;
  typeId: string;
  level: number | null;
  childrenIds: string[] | null;
}

export interface GetModuleAndSettings_module_meetings_format {
  __typename: "MeetingFormat";
  id: string;
  name: string;
}

export interface GetModuleAndSettings_module_meetings_duration {
  __typename: "MeetingDuration";
  id: string | null;
  name: string | null;
}

export interface GetModuleAndSettings_module_meetings_equipmentPerMeeting {
  __typename: "EquipmentPerMeeting";
  id: string;
  name: string | null;
  count: number;
}

export interface GetModuleAndSettings_module_meetings_equipmentPerStudent {
  __typename: "EquipmentPerStudent";
  id: string;
  name: string | null;
  count: number;
}

export interface GetModuleAndSettings_module_meetings_prerequisiteSkills {
  __typename: "ModuleSkill";
  id: string;
  name: string | null;
  typeId: string;
  level: number | null;
  childrenIds: string[] | null;
}

export interface GetModuleAndSettings_module_meetings_outputoutputSkills {
  __typename: "ModuleSkill";
  id: string;
  name: string | null;
  typeId: string;
  level: number | null;
  childrenIds: string[] | null;
}

export interface GetModuleAndSettings_module_meetings_meetingTeacherRoles {
  __typename: "MeetingTeacherRole";
  id: string;
  name: string | null;
  count: number;
}

export interface GetModuleAndSettings_module_meetings {
  __typename: "Meeting";
  id: string;
  meetingNumber: number;
  theme: string;
  format: GetModuleAndSettings_module_meetings_format;
  duration: GetModuleAndSettings_module_meetings_duration;
  minStudentCount: number;
  maxStudentCount: number;
  equipmentPerMeeting: GetModuleAndSettings_module_meetings_equipmentPerMeeting[] | null;
  equipmentPerStudent: GetModuleAndSettings_module_meetings_equipmentPerStudent[] | null;
  prerequisiteSkills: GetModuleAndSettings_module_meetings_prerequisiteSkills[] | null;
  outputoutputSkills: GetModuleAndSettings_module_meetings_outputoutputSkills[] | null;
  meetingTeacherRoles: GetModuleAndSettings_module_meetings_meetingTeacherRoles[] | null;
  minDaysToNextEvent: number | null;
  maxDaysToNextEvent: number | null;
  order: number;
  meetingIdForTeacherStaff: string | null;
}

export interface GetModuleAndSettings_module_evaluationPoints_duration {
  __typename: "MeetingDuration";
  id: string | null;
  name: string | null;
}

export interface GetModuleAndSettings_module_evaluationPoints_evaluationPointSkills {
  __typename: "ModuleSkill";
  id: string;
  name: string | null;
  typeId: string;
  level: number | null;
  childrenIds: string[] | null;
}

export interface GetModuleAndSettings_module_evaluationPoints_evaluationPointTeacherRoles {
  __typename: "EvaluationPointTeacherRole";
  id: string;
  name: string | null;
  count: number;
}

export interface GetModuleAndSettings_module_evaluationPoints {
  __typename: "EvaluationPoint";
  id: string;
  evaluationPointNumber: number;
  name: string;
  description: string | null;
  previousEventId: string;
  duration: GetModuleAndSettings_module_evaluationPoints_duration;
  evaluationPointSkills: GetModuleAndSettings_module_evaluationPoints_evaluationPointSkills[] | null;
  evaluationPointTeacherRoles: GetModuleAndSettings_module_evaluationPoints_evaluationPointTeacherRoles[] | null;
  order: number;
}

export interface GetModuleAndSettings_module {
  __typename: "Module";
  id: string;
  name: string;
  description: string | null;
  minStudentCount: number;
  maxStudentCount: number;
  maxWaveCount: number;
  minCreditCount: number;
  maxCreditCount: number;
  teachersRoles: GetModuleAndSettings_module_teachersRoles[] | null;
  moduleTeachers: GetModuleAndSettings_module_moduleTeachers[] | null;
  prerequisiteSkills: GetModuleAndSettings_module_prerequisiteSkills[] | null;
  outputoutputSkills: GetModuleAndSettings_module_outputSkills[] | null;
  meetings: GetModuleAndSettings_module_meetings[] | null;
  evaluationPoints: GetModuleAndSettings_module_evaluationPoints[] | null;
}

export interface GetModuleAndSettings_moduleSettings_moduleSkillTypes {
  __typename: "SkillTypeSettings";
  id: string;
  isLevelIncreased: boolean;
}

export interface GetModuleAndSettings_moduleSettings_meetingSkillTypes {
  __typename: "SkillTypeSettings";
  id: string;
  isLevelIncreased: boolean;
}

export interface GetModuleAndSettings_moduleSettings {
  __typename: "ModuleSettings";
  moduleSkillTypes: GetModuleAndSettings_moduleSettings_moduleSkillTypes[] | null;
  meetingSkillTypes: GetModuleAndSettings_moduleSettings_meetingSkillTypes[] | null;
}

export interface GetModuleAndSettings_skillTypes_name {
  __typename: "SkillTypeName";
  nominativeSingular: string;
  genitiveSingular: string;
  nominativePlural: string;
  genitivePlural: string;
  english: string;
}

export interface GetModuleAndSettings_skillTypes_properties {
  __typename: "SkillTypeProperties";
  maxLevel: number | null;
}

export interface GetModuleAndSettings_skillTypes {
  __typename: "SkillType";
  id: string;
  name: GetModuleAndSettings_skillTypes_name;
  properties: GetModuleAndSettings_skillTypes_properties | null;
  parentId: string | null;
}

export interface GetModuleAndSettings_skills_children {
  __typename: "Skill";
  id: string;
}

export interface GetModuleAndSettings_skills {
  __typename: "Skill";
  id: string;
  name: string;
  maxLevel: number | null;
  typeId: string;
  children: GetModuleAndSettings_skills_children[] | null;
}

export interface GetModuleAndSettings_teachers {
  __typename: "Teacher";
  id: string;
  name: string;
}

export interface GetModuleAndSettings_equipments {
  __typename: "Equipment";
  id: string;
  name: string;
  count: number;
}

export interface GetModuleAndSettings_durations {
  __typename: "MeetingDuration";
  id: string | null;
  name: string | null;
  hours: number | null;
}

export interface GetModuleAndSettings_meetingFormats {
  __typename: "MeetingFormat";
  id: string;
  name: string;
}

export interface GetModuleAndSettings {
  module: GetModuleAndSettings_module;
  moduleSettings: GetModuleAndSettings_moduleSettings;
  skillTypes: GetModuleAndSettings_skillTypes[];
  skills: GetModuleAndSettings_skills[];
  teachers: GetModuleAndSettings_teachers[];
  equipments: GetModuleAndSettings_equipments[];
  durations: GetModuleAndSettings_durations[];
  meetingFormats: GetModuleAndSettings_meetingFormats[];
}

export interface GetModuleAndSettingsVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetModuleSettings
// ====================================================

export interface GetModuleSettings_moduleSettings_moduleSkillTypes {
  __typename: "SkillTypeSettings";
  id: string;
  isLevelIncreased: boolean;
}

export interface GetModuleSettings_moduleSettings_meetingSkillTypes {
  __typename: "SkillTypeSettings";
  id: string;
  isLevelIncreased: boolean;
}

export interface GetModuleSettings_moduleSettings {
  __typename: "ModuleSettings";
  moduleSkillTypes: GetModuleSettings_moduleSettings_moduleSkillTypes[] | null;
  meetingSkillTypes: GetModuleSettings_moduleSettings_meetingSkillTypes[] | null;
}

export interface GetModuleSettings_skillTypes_name {
  __typename: "SkillTypeName";
  nominativeSingular: string;
  genitiveSingular: string;
  nominativePlural: string;
  genitivePlural: string;
  english: string;
}

export interface GetModuleSettings_skillTypes_properties {
  __typename: "SkillTypeProperties";
  maxLevel: number | null;
}

export interface GetModuleSettings_skillTypes {
  __typename: "SkillType";
  id: string;
  name: GetModuleSettings_skillTypes_name;
  properties: GetModuleSettings_skillTypes_properties | null;
  parentId: string | null;
}

export interface GetModuleSettings_skills_children {
  __typename: "Skill";
  id: string;
}

export interface GetModuleSettings_skills {
  __typename: "Skill";
  id: string;
  name: string;
  maxLevel: number | null;
  typeId: string;
  children: GetModuleSettings_skills_children[] | null;
}

export interface GetModuleSettings_teachers {
  __typename: "Teacher";
  id: string;
  name: string;
}

export interface GetModuleSettings_equipments {
  __typename: "Equipment";
  id: string;
  name: string;
  count: number;
}

export interface GetModuleSettings_durations {
  __typename: "MeetingDuration";
  id: string | null;
  name: string | null;
  hours: number | null;
}

export interface GetModuleSettings_meetingFormats {
  __typename: "MeetingFormat";
  id: string;
  name: string;
}

export interface GetModuleSettings {
  moduleSettings: GetModuleSettings_moduleSettings;
  skillTypes: GetModuleSettings_skillTypes[];
  skills: GetModuleSettings_skills[];
  teachers: GetModuleSettings_teachers[];
  equipments: GetModuleSettings_equipments[];
  durations: GetModuleSettings_durations[];
  meetingFormats: GetModuleSettings_meetingFormats[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateModule
// ====================================================

export interface CreateModule_createModule_teachersRoles {
  __typename: "TeacherRole";
  id: string;
  name: string | null;
}

export interface CreateModule_createModule_moduleTeachers_teacherRoles {
  __typename: "TeacherRole";
  id: string;
  name: string | null;
}

export interface CreateModule_createModule_moduleTeachers {
  __typename: "ModuleTeacher";
  id: string;
  name: string;
  teacherRoles: CreateModule_createModule_moduleTeachers_teacherRoles[] | null;
}

export interface CreateModule_createModule_prerequisiteSkills {
  __typename: "ModuleSkill";
  id: string;
  name: string | null;
  typeId: string;
  level: number | null;
  childrenIds: string[] | null;
}

export interface CreateModule_createModule_outputoutputSkills {
  __typename: "ModuleSkill";
  id: string;
  name: string | null;
  typeId: string;
  level: number | null;
  childrenIds: string[] | null;
}

export interface CreateModule_createModule_meetings_format {
  __typename: "MeetingFormat";
  id: string;
  name: string;
}

export interface CreateModule_createModule_meetings_duration {
  __typename: "MeetingDuration";
  id: string | null;
  name: string | null;
}

export interface CreateModule_createModule_meetings_equipmentPerMeeting {
  __typename: "EquipmentPerMeeting";
  id: string;
  name: string | null;
  count: number;
}

export interface CreateModule_createModule_meetings_equipmentPerStudent {
  __typename: "EquipmentPerStudent";
  id: string;
  name: string | null;
  count: number;
}

export interface CreateModule_createModule_meetings_prerequisiteSkills {
  __typename: "ModuleSkill";
  id: string;
  name: string | null;
  typeId: string;
  level: number | null;
  childrenIds: string[] | null;
}

export interface CreateModule_createModule_meetings_outputoutputSkills {
  __typename: "ModuleSkill";
  id: string;
  name: string | null;
  typeId: string;
  level: number | null;
  childrenIds: string[] | null;
}

export interface CreateModule_createModule_meetings_meetingTeacherRoles {
  __typename: "MeetingTeacherRole";
  id: string;
  name: string | null;
  count: number;
}

export interface CreateModule_createModule_meetings {
  __typename: "Meeting";
  id: string;
  meetingNumber: number;
  theme: string;
  format: CreateModule_createModule_meetings_format;
  duration: CreateModule_createModule_meetings_duration;
  minStudentCount: number;
  maxStudentCount: number;
  equipmentPerMeeting: CreateModule_createModule_meetings_equipmentPerMeeting[] | null;
  equipmentPerStudent: CreateModule_createModule_meetings_equipmentPerStudent[] | null;
  prerequisiteSkills: CreateModule_createModule_meetings_prerequisiteSkills[] | null;
  outputoutputSkills: CreateModule_createModule_meetings_outputoutputSkills[] | null;
  meetingTeacherRoles: CreateModule_createModule_meetings_meetingTeacherRoles[] | null;
  minDaysToNextEvent: number | null;
  maxDaysToNextEvent: number | null;
  order: number;
  meetingIdForTeacherStaff: string | null;
}

export interface CreateModule_createModule_evaluationPoints_duration {
  __typename: "MeetingDuration";
  id: string | null;
  name: string | null;
}

export interface CreateModule_createModule_evaluationPoints_evaluationPointSkills {
  __typename: "ModuleSkill";
  id: string;
  name: string | null;
  typeId: string;
  level: number | null;
  childrenIds: string[] | null;
}

export interface CreateModule_createModule_evaluationPoints_evaluationPointTeacherRoles {
  __typename: "EvaluationPointTeacherRole";
  id: string;
  name: string | null;
  count: number;
}

export interface CreateModule_createModule_evaluationPoints {
  __typename: "EvaluationPoint";
  id: string;
  evaluationPointNumber: number;
  name: string;
  description: string | null;
  previousEventId: string;
  duration: CreateModule_createModule_evaluationPoints_duration;
  evaluationPointSkills: CreateModule_createModule_evaluationPoints_evaluationPointSkills[] | null;
  evaluationPointTeacherRoles: CreateModule_createModule_evaluationPoints_evaluationPointTeacherRoles[] | null;
  order: number;
}

export interface CreateModule_createModule {
  __typename: "Module";
  id: string;
  name: string;
  description: string | null;
  minStudentCount: number;
  maxStudentCount: number;
  maxWaveCount: number;
  minCreditCount: number;
  maxCreditCount: number;
  teachersRoles: CreateModule_createModule_teachersRoles[] | null;
  moduleTeachers: CreateModule_createModule_moduleTeachers[] | null;
  prerequisiteSkills: CreateModule_createModule_prerequisiteSkills[] | null;
  outputoutputSkills: CreateModule_createModule_outputoutputSkills[] | null;
  meetings: CreateModule_createModule_meetings[] | null;
  evaluationPoints: CreateModule_createModule_evaluationPoints[] | null;
}

export interface CreateModule {
  createModule: CreateModule_createModule;
}

export interface CreateModuleVariables {
  newModule: CreateModuleInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateModule
// ====================================================

export interface UpdateModule_updateModule_teachersRoles {
  __typename: "TeacherRole";
  id: string;
  name: string | null;
}

export interface UpdateModule_updateModule_moduleTeachers_teacherRoles {
  __typename: "TeacherRole";
  id: string;
  name: string | null;
}

export interface UpdateModule_updateModule_moduleTeachers {
  __typename: "ModuleTeacher";
  id: string;
  name: string;
  teacherRoles: UpdateModule_updateModule_moduleTeachers_teacherRoles[] | null;
}

export interface UpdateModule_updateModule_prerequisiteSkills {
  __typename: "ModuleSkill";
  id: string;
  name: string | null;
  typeId: string;
  level: number | null;
  childrenIds: string[] | null;
}

export interface UpdateModule_updateModule_outputoutputSkills {
  __typename: "ModuleSkill";
  id: string;
  name: string | null;
  typeId: string;
  level: number | null;
  childrenIds: string[] | null;
}

export interface UpdateModule_updateModule_meetings_format {
  __typename: "MeetingFormat";
  id: string;
  name: string;
}

export interface UpdateModule_updateModule_meetings_duration {
  __typename: "MeetingDuration";
  id: string | null;
  name: string | null;
}

export interface UpdateModule_updateModule_meetings_equipmentPerMeeting {
  __typename: "EquipmentPerMeeting";
  id: string;
  name: string | null;
  count: number;
}

export interface UpdateModule_updateModule_meetings_equipmentPerStudent {
  __typename: "EquipmentPerStudent";
  id: string;
  name: string | null;
  count: number;
}

export interface UpdateModule_updateModule_meetings_prerequisiteSkills {
  __typename: "ModuleSkill";
  id: string;
  name: string | null;
  typeId: string;
  level: number | null;
  childrenIds: string[] | null;
}

export interface UpdateModule_updateModule_meetings_outputoutputSkills {
  __typename: "ModuleSkill";
  id: string;
  name: string | null;
  typeId: string;
  level: number | null;
  childrenIds: string[] | null;
}

export interface UpdateModule_updateModule_meetings_meetingTeacherRoles {
  __typename: "MeetingTeacherRole";
  id: string;
  name: string | null;
  count: number;
}

export interface UpdateModule_updateModule_meetings {
  __typename: "Meeting";
  id: string;
  meetingNumber: number;
  theme: string;
  format: UpdateModule_updateModule_meetings_format;
  duration: UpdateModule_updateModule_meetings_duration;
  minStudentCount: number;
  maxStudentCount: number;
  equipmentPerMeeting: UpdateModule_updateModule_meetings_equipmentPerMeeting[] | null;
  equipmentPerStudent: UpdateModule_updateModule_meetings_equipmentPerStudent[] | null;
  prerequisiteSkills: UpdateModule_updateModule_meetings_prerequisiteSkills[] | null;
  outputoutputSkills: UpdateModule_updateModule_meetings_outputoutputSkills[] | null;
  meetingTeacherRoles: UpdateModule_updateModule_meetings_meetingTeacherRoles[] | null;
  minDaysToNextEvent: number | null;
  maxDaysToNextEvent: number | null;
  order: number;
  meetingIdForTeacherStaff: string | null;
}

export interface UpdateModule_updateModule_evaluationPoints_duration {
  __typename: "MeetingDuration";
  id: string | null;
  name: string | null;
}

export interface UpdateModule_updateModule_evaluationPoints_evaluationPointSkills {
  __typename: "ModuleSkill";
  id: string;
  name: string | null;
  typeId: string;
  level: number | null;
  childrenIds: string[] | null;
}

export interface UpdateModule_updateModule_evaluationPoints_evaluationPointTeacherRoles {
  __typename: "EvaluationPointTeacherRole";
  id: string;
  name: string | null;
  count: number;
}

export interface UpdateModule_updateModule_evaluationPoints {
  __typename: "EvaluationPoint";
  id: string;
  evaluationPointNumber: number;
  name: string;
  description: string | null;
  previousEventId: string;
  duration: UpdateModule_updateModule_evaluationPoints_duration;
  evaluationPointSkills: UpdateModule_updateModule_evaluationPoints_evaluationPointSkills[] | null;
  evaluationPointTeacherRoles: UpdateModule_updateModule_evaluationPoints_evaluationPointTeacherRoles[] | null;
  order: number;
}

export interface UpdateModule_updateModule {
  __typename: "Module";
  id: string;
  name: string;
  description: string | null;
  minStudentCount: number;
  maxStudentCount: number;
  maxWaveCount: number;
  minCreditCount: number;
  maxCreditCount: number;
  teachersRoles: UpdateModule_updateModule_teachersRoles[] | null;
  moduleTeachers: UpdateModule_updateModule_moduleTeachers[] | null;
  prerequisiteSkills: UpdateModule_updateModule_prerequisiteSkills[] | null;
  outputoutputSkills: UpdateModule_updateModule_outputoutputSkills[] | null;
  meetings: UpdateModule_updateModule_meetings[] | null;
  evaluationPoints: UpdateModule_updateModule_evaluationPoints[] | null;
}

export interface UpdateModule {
  updateModule: UpdateModule_updateModule;
}

export interface UpdateModuleVariables {
  newModule: UpdateModuleInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getBuilding
// ====================================================

export interface getBuilding_buildings {
  __typename: "Building";
  id: string;
  name: string;
  location: string;
}

export interface getBuilding {
  buildings: getBuilding_buildings[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getRooms
// ====================================================

export interface getRooms_rooms_meetingFormats {
  __typename: "MeetingFormat";
  id: string;
  name: string;
}

export interface getRooms_rooms_location {
  __typename: "Building";
  id: string;
  name: string;
  location: string;
}

export interface getRooms_rooms_equipmentToRoom_equipment {
  __typename: "Equipment";
  name: string;
}

export interface getRooms_rooms_equipmentToRoom {
  __typename: "EquipmentToRoom";
  roomId: string;
  equipmentId: string;
  equipment: getRooms_rooms_equipmentToRoom_equipment | null;
  countEquipments: number;
}

export interface getRooms_rooms {
  __typename: "Room";
  id: string;
  meetingFormats: getRooms_rooms_meetingFormats[];
  title: string;
  capacity: number;
  location: getRooms_rooms_location;
  equipmentToRoom: getRooms_rooms_equipmentToRoom[];
}

export interface getRooms {
  rooms: getRooms_rooms[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: deleteRoom
// ====================================================

export interface deleteRoom_removeRoom {
  __typename: "RemoveStatus";
  status: boolean | null;
}

export interface deleteRoom {
  removeRoom: deleteRoom_removeRoom;
}

export interface deleteRoomVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateRoom
// ====================================================

export interface updateRoom_updateRoom {
  __typename: "Room";
  id: string;
}

export interface updateRoom {
  updateRoom: updateRoom_updateRoom;
}

export interface updateRoomVariables {
  editRoomData: UpdateRoomInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: createRoom
// ====================================================

export interface createRoom_createRoom {
  __typename: "Room";
  id: string;
}

export interface createRoom {
  createRoom: createRoom_createRoom;
}

export interface createRoomVariables {
  createRoomData: CreateRoomInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getRoomsTypes
// ====================================================

export interface getRoomsTypes_meetingFormats {
  __typename: "MeetingFormat";
  id: string;
  name: string;
}

export interface getRoomsTypes {
  meetingFormats: getRoomsTypes_meetingFormats[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getEquipmentRooms
// ====================================================

export interface getEquipmentRooms_equipments {
  __typename: "Equipment";
  id: string;
  name: string;
  count: number;
}

export interface getEquipmentRooms {
  equipments: getEquipmentRooms_equipments[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: skillsDependencies
// ====================================================

export interface skillsDependencies_skills {
  __typename: "Skill";
  id: string;
  name: string;
  typeId: string;
}

export interface skillsDependencies {
  skills: skillsDependencies_skills[];
}

export interface skillsDependenciesVariables {
  typeIds?: string[] | null;
  excludeRootId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: skills
// ====================================================

export interface skills_skills_children_children_dependencies {
  __typename: "Skill";
  id: string;
  name: string;
  typeId: string;
}

export interface skills_skills_children_children {
  __typename: "Skill";
  id: string;
  name: string;
  typeId: string;
  description: string | null;
  dependencies: skills_skills_children_children_dependencies[] | null;
}

export interface skills_skills_children_dependencies {
  __typename: "Skill";
  id: string;
  name: string;
  typeId: string;
}

export interface skills_skills_children {
  __typename: "Skill";
  id: string;
  name: string;
  typeId: string;
  maxLevel: number | null;
  description: string | null;
  children: skills_skills_children_children[] | null;
  dependencies: skills_skills_children_dependencies[] | null;
}

export interface skills_skills_dependencies {
  __typename: "Skill";
  id: string;
  name: string;
  typeId: string;
}

export interface skills_skills {
  __typename: "Skill";
  id: string;
  name: string;
  fullName: string;
  typeId: string;
  maxLevel: number | null;
  description: string | null;
  children: skills_skills_children[] | null;
  dependencies: skills_skills_dependencies[] | null;
}

export interface skills {
  skills: skills_skills[];
}

export interface skillsVariables {
  typeIds?: string[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: createSkill
// ====================================================

export interface createSkill_createSkill {
  __typename: "Skill";
  name: string;
  description: string | null;
}

export interface createSkill {
  createSkill: createSkill_createSkill;
}

export interface createSkillVariables {
  id: string;
  name: string;
  typeId: string;
  description: string;
  maxLevel: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: createNestedSkill
// ====================================================

export interface createNestedSkill_createSkill {
  __typename: "Skill";
  id: string;
  name: string;
  typeId: string;
}

export interface createNestedSkill {
  createSkill: createNestedSkill_createSkill;
}

export interface createNestedSkillVariables {
  id: string;
  name: string;
  typeId: string;
  children?: CreateSkillInput[] | null;
  dependencies?: CreateSkillDependencyInput[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: removeSkill
// ====================================================

export interface removeSkill_removeSkill {
  __typename: "RemoveSkill";
  result: boolean | null;
}

export interface removeSkill {
  removeSkill: removeSkill_removeSkill;
}

export interface removeSkillVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateSkill
// ====================================================

export interface updateSkill_updateSkill {
  __typename: "Skill";
  id: string;
}

export interface updateSkill {
  updateSkill: updateSkill_updateSkill;
}

export interface updateSkillVariables {
  id: string;
  name: string;
  typeId: string;
  maxLevel?: number | null;
  description?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateNestSkill
// ====================================================

export interface updateNestSkill_updateSkill {
  __typename: "Skill";
  id: string;
  name: string;
  typeId: string;
}

export interface updateNestSkill {
  updateSkill: updateNestSkill_updateSkill;
}

export interface updateNestSkillVariables {
  id: string;
  name: string;
  typeId: string;
  children?: UpdateSkillInput[] | null;
  dependencies?: UpdateSkillDependencyInput[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getTerritorialZones
// ====================================================

export interface getTerritorialZones_territorialZones_buildings {
  __typename: "Building";
  id: string;
  name: string;
  location: string;
}

export interface getTerritorialZones_territorialZones_travelTimesFromZone {
  __typename: "ZoneToZone";
  fromZoneId: string;
  toZoneId: string;
  travelTime: number;
}

export interface getTerritorialZones_territorialZones {
  __typename: "TerritorialZone";
  id: string;
  name: string;
  buildings: getTerritorialZones_territorialZones_buildings[];
  travelTimesFromZone: getTerritorialZones_territorialZones_travelTimesFromZone[];
}

export interface getTerritorialZones {
  territorialZones: getTerritorialZones_territorialZones[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateTerritorialZones
// ====================================================

export interface updateTerritorialZones_updateTerritorialZones {
  __typename: "TerritorialZone";
  id: string;
  name: string;
}

export interface updateTerritorialZones {
  updateTerritorialZones: updateTerritorialZones_updateTerritorialZones[] | null;
}

export interface updateTerritorialZonesVariables {
  updateZonesInput: UpdateTerritorialZoneInput[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: createBuilding
// ====================================================

export interface createBuilding_createBuilding {
  __typename: "Building";
  id: string;
  name: string;
}

export interface createBuilding {
  createBuilding: createBuilding_createBuilding;
}

export interface createBuildingVariables {
  building: CreateBuildingInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateBuilding
// ====================================================

export interface updateBuilding_updateBuilding {
  __typename: "Building";
  id: string;
  name: string;
}

export interface updateBuilding {
  updateBuilding: updateBuilding_updateBuilding;
}

export interface updateBuildingVariables {
  building: UpdateBuildingInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: removeBuilding
// ====================================================

export interface removeBuilding_removeBuilding {
  __typename: "RemoveStatus";
  status: boolean | null;
}

export interface removeBuilding {
  removeBuilding: removeBuilding_removeBuilding;
}

export interface removeBuildingVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: skillTypesData
// ====================================================

export interface skillTypesData_skillTypes_name {
  __typename: "SkillTypeName";
  english: string;
  nominativeSingular: string;
  nominativePlural: string;
  genitiveSingular: string;
  genitivePlural: string;
}

export interface skillTypesData_skillTypes_properties {
  __typename: "SkillTypeProperties";
  maxLevel: number | null;
  description: boolean | null;
}

export interface skillTypesData_skillTypes {
  __typename: "SkillType";
  id: string;
  name: skillTypesData_skillTypes_name;
  parentId: string | null;
  properties: skillTypesData_skillTypes_properties | null;
  possibleDependencies: string[] | null;
}

export interface skillTypesData {
  skillTypes: skillTypesData_skillTypes[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetAllSkills
// ====================================================

export interface GetAllSkills_skills_children_children_dependencies {
  __typename: "Skill";
  id: string;
  name: string;
  typeId: string;
}

export interface GetAllSkills_skills_children_children {
  __typename: "Skill";
  id: string;
  name: string;
  typeId: string;
  dependencies: GetAllSkills_skills_children_children_dependencies[] | null;
}

export interface GetAllSkills_skills_children_dependencies {
  __typename: "Skill";
  id: string;
  name: string;
  typeId: string;
}

export interface GetAllSkills_skills_children {
  __typename: "Skill";
  id: string;
  name: string;
  typeId: string;
  maxLevel: number | null;
  children: GetAllSkills_skills_children_children[] | null;
  dependencies: GetAllSkills_skills_children_dependencies[] | null;
}

export interface GetAllSkills_skills_dependencies {
  __typename: "Skill";
  id: string;
  name: string;
  typeId: string;
}

export interface GetAllSkills_skills {
  __typename: "Skill";
  id: string;
  name: string;
  typeId: string;
  maxLevel: number | null;
  children: GetAllSkills_skills_children[] | null;
  dependencies: GetAllSkills_skills_dependencies[] | null;
}

export interface GetAllSkills {
  skills: GetAllSkills_skills[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: subspace
// ====================================================

export interface subspace_subspace_owner {
  __typename: "User";
  id: string;
}

export interface subspace_subspace_type {
  __typename: "SubspaceType";
  id: string;
}

export interface subspace_subspace_prerequisiteSpecializedSkills {
  __typename?: "SubspaceSkill";
  id: string;
  name: string;
  typeId: string;
  level: number | null;
}

export interface subspace_subspace_outputSpecializedSkills {
  __typename?: "SubspaceSkill";
  id: string;
  name: string;
  typeId: string;
  level: number | null;
}

export interface subspace_subspace {
  __typename: "Subspace";
  id: string;
  name: string;
  description: string | null;
  minStudentCount: number;
  maxStudentCount: number;
  owner: subspace_subspace_owner;
  type: subspace_subspace_type;
  prerequisiteSpecializedSkills: subspace_subspace_prerequisiteSpecializedSkills[] | null;
  outputSpecializedSkills: subspace_subspace_outputSpecializedSkills[] | null;
}

export interface subspace {
  subspace: subspace_subspace;
}

export interface subspaceVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateSubspace
// ====================================================

export interface updateSubspace_updateSubspace {
  __typename: "Subspace";
  name: string;
  id: string;
}

export interface updateSubspace {
  updateSubspace: updateSubspace_updateSubspace;
}

export interface updateSubspaceVariables {
  id: string;
  minStudentCount: number;
  maxStudentCount: number;
  prerequisiteSpecializedSkills?: UpdateSubspaceSkillInput[] | null;
  outputSpecializedSkills?: UpdateSubspaceSkillInput[] | null;
  description?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: subspaceSlots
// ====================================================

export interface subspaceSlots_subspace_slots_prerequisiteSkills {
  __typename: "SubspaceSkill";
  id: string;
  name: string;
  typeId: string;
  level: number | null;
}

export interface subspaceSlots_subspace_slots_outputSkills {
  __typename: "SubspaceSkill";
  id: string;
  name: string;
  typeId: string;
  level: number | null;
}

export interface subspaceSlots_subspace_slots_nextSlots_prerequisiteSkills {
  __typename: "SubspaceSkill";
  id: string;
  name: string;
  typeId: string;
  level: number | null;
}

export interface subspaceSlots_subspace_slots_nextSlots_outputSkills {
  __typename: "SubspaceSkill";
  id: string;
  name: string;
  typeId: string;
  level: number | null;
}

export interface subspaceSlots_subspace_slots_nextSlots {
  __typename: "ModuleSlot";
  id: string;
  subspaceTypeBaseTimeIntervalId: string;
  row: number;
  column: number;
  // fromSelectionPointId: string | null;
  moduleId: string | null;
  prerequisiteSkills: subspaceSlots_subspace_slots_nextSlots_prerequisiteSkills[] | null;
  outputSkills: subspaceSlots_subspace_slots_nextSlots_outputSkills[] | null;
}

export interface subspaceSlots_subspace_slots {
  __typename: "ModuleSlot";
  id: string;
  subspaceTypeBaseTimeIntervalId: string;
  row: number;
  column: number;
  // fromSelectionPointId: string | null;
  moduleId: string | null;
  prerequisiteSkills: subspaceSlots_subspace_slots_prerequisiteSkills[] | null;
  outputSkills: subspaceSlots_subspace_slots_outputSkills[] | null;
  nextSlots: subspaceSlots_subspace_slots_nextSlots[] | null;
}

export interface subspaceSlots_subspace_selectionPoints_studentPeekingType {
  __typename: "StudentSelectionType";
  id: string;
  name: string;
}

export interface subspaceSlots_subspace_selectionPoints_subspace {
  __typename: "Subspace";
  id: string;
  name: string;
}

export interface subspaceSlots_subspace_selectionPoints {
  __typename: "ModuleSelectionPoint";
  subspaceTypeBaseTimeIntervalId: string;
  id: string;
  overbooking: boolean;
  studentPeekingType: subspaceSlots_subspace_selectionPoints_studentPeekingType;
  transitionalEmptyModule: boolean;
  transitionalUnderfilledModule: boolean;
  status: string;
  subspace: subspaceSlots_subspace_selectionPoints_subspace;
}

export interface subspaceSlots_subspace {
  __typename: "Subspace";
  id: string;
  slots: subspaceSlots_subspace_slots[] | null;
  selectionPoints: subspaceSlots_subspace_selectionPoints[];
}

export interface subspaceSlots {
  subspace: subspaceSlots_subspace;
}

export interface subspaceSlotsVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: moduleSlot
// ====================================================

export interface moduleSlot_moduleSlot_nextSlots_prerequisiteSkills {
  __typename: "SubspaceSkill";
  id: string;
  name: string;
  typeId: string;
  level: number | null;
}

export interface moduleSlot_moduleSlot_nextSlots_outputSkills {
  __typename: "SubspaceSkill";
  id: string;
  name: string;
  typeId: string;
  level: number | null;
}

export interface moduleSlot_moduleSlot_nextSlots {
  __typename: "ModuleSlot";
  id: string;
  subspaceTypeBaseTimeIntervalId: string;
  row: number;
  column: number;
  // fromSelectionPointId: string | null;
  moduleId: string | null;
  prerequisiteSkills: moduleSlot_moduleSlot_nextSlots_prerequisiteSkills[] | null;
  outputSkills: moduleSlot_moduleSlot_nextSlots_outputSkills[] | null;
}

export interface moduleSlot_moduleSlot_prerequisiteSkills {
  __typename: "SubspaceSkill";
  id: string;
  name: string;
  typeId: string;
  level: number | null;
}

export interface moduleSlot_moduleSlot_outputSkills {
  __typename: "SubspaceSkill";
  id: string;
  name: string;
  typeId: string;
  level: number | null;
}

export interface moduleSlot_moduleSlot {
  __typename: "ModuleSlot";
  id: string;
  subspaceTypeBaseTimeIntervalId: string;
  row: number;
  column: number;
  // fromSelectionPointId: string | null;
  nextSlots: moduleSlot_moduleSlot_nextSlots[] | null;
  moduleId: string | null;
  prerequisiteSkills: moduleSlot_moduleSlot_prerequisiteSkills[] | null;
  outputSkills: moduleSlot_moduleSlot_outputSkills[] | null;
}

export interface moduleSlot {
  moduleSlot: moduleSlot_moduleSlot;
}

export interface moduleSlotVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: subspacePrerequisiteSpecializedSkills
// ====================================================

export interface subspacePrerequisiteSpecializedSkills_subspacePrerequisiteSpecializedSkills {
  __typename: "SubspaceSkill";
  id: string;
  name: string;
  typeId: string;
  level: number | null;
}

export interface subspacePrerequisiteSpecializedSkills {
  subspacePrerequisiteSpecializedSkills: subspacePrerequisiteSpecializedSkills_subspacePrerequisiteSpecializedSkills[];
}

export interface subspacePrerequisiteSpecializedSkillsVariables {
  subspaceId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: subspaceOutputSpecializedSkills
// ====================================================

export interface subspaceOutputSpecializedSkills_subspaceOutputSpecializedSkills {
  __typename: "SubspaceSkill";
  id: string;
  name: string;
  typeId: string;
  level: number | null;
}

export interface subspaceOutputSpecializedSkills {
  subspaceOutputSpecializedSkills: subspaceOutputSpecializedSkills_subspaceOutputSpecializedSkills[];
}

export interface subspaceOutputSpecializedSkillsVariables {
  subspaceId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateModuleSlotSkills
// ====================================================

export interface updateModuleSlotSkills_updateModuleSlotSkills_prerequisiteSkills {
  __typename: "SubspaceSkill";
  id: string;
  name: string;
  typeId: string;
  level: number | null;
}

export interface updateModuleSlotSkills_updateModuleSlotSkills_outputSkills {
  __typename: "SubspaceSkill";
  id: string;
  name: string;
  typeId: string;
  level: number | null;
}

export interface updateModuleSlotSkills_updateModuleSlotSkills {
  __typename: "ModuleSlot";
  id: string;
  prerequisiteSkills: updateModuleSlotSkills_updateModuleSlotSkills_prerequisiteSkills[] | null;
  outputSkills: updateModuleSlotSkills_updateModuleSlotSkills_outputSkills[] | null;
}

export interface updateModuleSlotSkills {
  updateModuleSlotSkills: updateModuleSlotSkills_updateModuleSlotSkills;
}

export interface updateModuleSlotSkillsVariables {
  updateModuleSlotSkillInput: UpdateModuleSlotSkillInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateModuleSlots
// ====================================================

export interface updateModuleSlots_updateModuleSlots {
  __typename: "ModuleSlot";
  id: string;
  row: number;
  column: number;
}

export interface updateModuleSlots {
  updateModuleSlots: updateModuleSlots_updateModuleSlots[];
}

export interface updateModuleSlotsVariables {
  updateModuleSlotInput: UpdateModuleSlotInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export interface CreateBuildingInput {
  id: string;
  name: string;
  location: string;
  zoneId: string;
}

export interface CreateDurationInput {
  id: string;
  name?: string | null;
}

export interface CreateEquipmentInput {
  id: string;
  name: string;
  count: number;
}

export interface CreateEquipmentPerMeetingInput {
  id: string;
  name?: string | null;
  count: number;
}

export interface CreateEquipmentPerStudentInput {
  id: string;
  name?: string | null;
  count: number;
}

export interface CreateEquipmentToRoomInput {
  id: string;
  count: number;
}

export interface CreateEvaluationPointInput {
  id: string;
  name: string;
  evaluationPointNumber: number;
  description?: string | null;
  previousEventId: string;
  evaluationPointSkills?: CreateEvaluationPointSkillInput[] | null;
  evaluationPointTeacherRoles?: CreateEvaluationPointTeacherRoleInput[] | null;
  duration: CreateDurationInput;
  order: number;
}

export interface CreateEvaluationPointSkillInput {
  id: string;
  name?: string | null;
  level?: number | null;
}

export interface CreateEvaluationPointTeacherRoleInput {
  id: string;
  name?: string | null;
  count: number;
}

export interface CreateMeetingFormatInput {
  id: string;
  name?: string | null;
}

export interface CreateMeetingInput {
  id: string;
  meetingNumber: number;
  theme: string;
  format: CreateMeetingFormatInput;
  duration: CreateDurationInput;
  minStudentCount: number;
  maxStudentCount: number;
  equipmentPerMeeting?: CreateEquipmentPerMeetingInput[] | null;
  equipmentPerStudent?: CreateEquipmentPerStudentInput[] | null;
  prerequisiteSkills?: CreateModuleSkillInput[] | null;
  outputoutputSkills?: CreateModuleSkillInput[] | null;
  meetingTeacherRoles?: CreateMeetingTeacherRoleInput[] | null;
  meetingIdForTeacherStaff?: string | null;
  minDaysToNextEvent?: number | null;
  maxDaysToNextEvent?: number | null;
  order: number;
}

export interface CreateMeetingTeacherRoleInput {
  id: string;
  name?: string | null;
  count: number;
}

export interface CreateModuleInput {
  id: string;
  name: string;
  description?: string | null;
  minStudentCount: number;
  maxStudentCount: number;
  maxWaveCount: number;
  teachersRoles?: CreateTeacherRoleInput[] | null;
  moduleTeachers?: CreateModuleTeacherInput[] | null;
  prerequisiteSkills?: CreateModuleSkillInput[] | null;
  outputoutputSkills?: CreateModuleSkillInput[] | null;
  meetings?: CreateMeetingInput[] | null;
  evaluationPoints?: CreateEvaluationPointInput[] | null;
  minCreditCount: number;
  maxCreditCount: number;
  imageUrl: string;
}

export interface CreateModuleSkillInput {
  id: string;
  name?: string | null;
  typeId?: string | null;
  level?: number | null;
}

export interface CreateModuleTeacherInput {
  id: string;
  name?: string | null;
  teacherRoles?: CreateTeacherRoleInput[] | null;
}

export interface CreateRoomInput {
  id: string;
  meetingFormatIds: string[];
  title: string;
  capacity: number;
  buildingId: string;
  equipments: CreateEquipmentToRoomInput[];
}

export interface CreateSkillDependencyInput {
  id: string;
  name?: string | null;
  typeId?: string | null;
}

export interface CreateSkillInput {
  id: string;
  name: string;
  typeId: string;
  maxLevel?: number | null;
  description?: string | null;
  children?: CreateSkillInput[] | null;
  dependencies?: CreateSkillDependencyInput[] | null;
}

export interface CreateTeacherRoleInput {
  id: string;
  name?: string | null;
}

export interface CreateZoneToZoneInput {
  id: string;
  travelTime: number;
}

export interface UpdateBuildingInput {
  id: string;
  name: string;
  location: string;
  zoneId: string;
}

export interface UpdateEquipmentToRoomInput {
  id: string;
  count: number;
}

export interface UpdateModuleInput {
  id: string;
  name: string;
  description?: string | null;
  minStudentCount: number;
  maxStudentCount: number;
  maxWaveCount: number;
  teachersRoles?: CreateTeacherRoleInput[] | null;
  moduleTeachers?: CreateModuleTeacherInput[] | null;
  prerequisiteSkills?: CreateModuleSkillInput[] | null;
  outputoutputSkills?: CreateModuleSkillInput[] | null;
  meetings?: CreateMeetingInput[] | null;
  evaluationPoints?: CreateEvaluationPointInput[] | null;
  minCreditCount: number;
  maxCreditCount: number;
  imageUrl: string;
}

export interface UpdateModuleSlotInput {
  subspaceId: string;
  slots: UpdateSlotInput[];
}

export interface UpdateModuleSlotSkillInput {
  slotId: string;
  prerequisiteSkills?: UpdateSubspaceSkillInput[] | null;
  outputSkills?: UpdateSubspaceSkillInput[] | null;
}

export interface UpdateNextSlotInput {
  id: string;
  subspaceTypeBaseTimeIntervalId?: string | null;
  row?: number | null;
  column?: number | null;
  // fromSelectionPointId?: string | null;
}

export interface UpdateRoomInput {
  id: string;
  meetingFormatIds: string[];
  title: string;
  capacity: number;
  buildingId: string;
  equipments: UpdateEquipmentToRoomInput[];
}

export interface UpdateSkillDependencyInput {
  id: string;
  name?: string | null;
  typeId?: string | null;
}

export interface UpdateSkillInput {
  id: string;
  name: string;
  typeId: string;
  maxLevel?: number | null;
  description?: string | null;
  children?: UpdateSkillInput[] | null;
  dependencies?: UpdateSkillDependencyInput[] | null;
}

export interface UpdateSlotInput {
  id: string;
  subspaceTypeBaseTimeIntervalId: string;
  row: number;
  column: number;
  // fromSelectionPointId?: string | null;
  nextSlots?: UpdateNextSlotInput[] | null;
}

export interface UpdateSubspaceSkillInput {
  id: string;
  name?: string | null;
  typeId?: string | null;
  level?: number | null;
}

export interface UpdateTerritorialZoneInput {
  id: string;
  name: string;
  travelTimeBetweenZones: CreateZoneToZoneInput[];
}

//==============================================================
// END Enums and Input Objects
//==============================================================

export enum ViewType {
  Admin = 'Admin',
  Teacher = 'Teacher',
  Student = 'Student',
}

export interface UserRole {
  roleId: string;
  name: ViewType;
  text: string;
}

export interface CurrentUser {
  id: string;
  firstName: string;
  lastName: string;
  availableInterfaceSections: [InterfaceSection];
  roles: UserRole[];
  student?: {
    notifications?: string[];
  };
}

export enum InterfaceSection {
  University = 'University',

  MainParameters = 'MainParameters',
  Equipment = 'Equipment',
  Rooms = 'Rooms',
  ConsumableEquipment = 'ConsumableEquipment',
  OnlineEducation = 'OnlineEducation',
  MeetingFormats = 'MeetingFormats',
  Skills = 'Skills',

  Space = 'Space',

  Users = 'Users',

  EducationalPeriod = 'EducationalPeriod',

  Schedule = 'Schedule',
  ScheduleTable = 'ScheduleTable',
  ScheduleGeneration = 'ScheduleGeneration',
  ScheduleAdditionalGeneration = 'ScheduleAdditionalGeneration',
  ScheduleStatistics = 'ScheduleStatistics',
  StudentAttendance = 'StudentAttendance',
  TeacherLoad = 'TeacherLoad',
  TeacherRoleConflicts = 'TeacherRoleConflicts',
  RoomLoad = 'RoomLoad',
  ConflictsReport = 'ConflictsReport',

  Modules = 'Modules',

  ActiveModules = 'ActiveModules',
  ModuleDrafts = 'ModuleDrafts',

  ModuleStatistics = 'ModuleStatistics',

  Trajectories = 'Trajectories',

  ExternalModules = 'ExternalModules',
}

export interface ViewTypeDate {
  roleId: string;
  name: ViewType;
  text: string;
}

