/* eslint-disable consistent-return */
/* eslint-disable no-console */
import { useEffect, useState } from 'react';

export function useCopyToClipboard() {
    const [isLinkCopied, setIsLinkCopied] = useState<boolean>(false);

    useEffect(() => {
        if (isLinkCopied) {
            const timer1 = setTimeout(() => setIsLinkCopied(false), 4000);
            return () => {
                clearTimeout(timer1);
            };
        }
    }, [isLinkCopied]);

    async function onCopyToClipboard(link: string) {
        await navigator.clipboard.writeText(link);
        setIsLinkCopied(true);
    }

    return {
        isLinkCopied,
        onCopyToClipboard,
    };
}
