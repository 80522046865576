import React from 'react';
import { observer } from 'mobx-react';
import classNames from 'classnames';
import { getISOWeek } from 'date-fns';

import { scheduleGenerationStore } from '@admin/ScheduleGenerationPage/store';
import { useUrlQuery } from '@common/hooks';

import { ScheduleEvent } from '../../types';
import { CellData } from '../ScheduleTableInterfaces';
import { getColor } from '../Legend';

import classes from './TableCell.module.scss';

interface Props {
    isSecret?: boolean;
    data: CellData;
}

export const TableCell = observer(({
    isSecret,
    data,
}: Props): JSX.Element => {
    const { baseTimeIntervalInstance } = scheduleGenerationStore;
    const { setUrlQuery } = useUrlQuery();
    const getProcessNum = (processId?: string): number => {
        if (!baseTimeIntervalInstance || !processId) return 0;
        return baseTimeIntervalInstance.getNumOfProcess(processId);
    };

    const isEmpty = !data;
    const isMeetingInstance = !isEmpty && (data as ScheduleEvent).type === 'meeting';
    const isPlannedMeetingInstance = !isEmpty && !isMeetingInstance;
    const { from } = baseTimeIntervalInstance!;
    let weekNumber;
    if (isMeetingInstance) {
        weekNumber = getNumOfWeek(new Date((data as ScheduleEvent).startTime), from);
    } else if (isPlannedMeetingInstance) {
        weekNumber = (data as any).weekNumber;
    }

    const processNumber = getProcessNum(data?.scheduleGenerationProcessId);

    const currentNumber = isSecret ? processNumber : weekNumber;

    const onCellClick = () => {
        setUrlQuery({ detailedEventId: data?.id, detailedEventType: 'meeting' });
    };

    return (
        <div
            className={classNames(classes.cell, {
                [classes.cell_planned]: isPlannedMeetingInstance,
            })}
            style={{ background: getColor(currentNumber) }}
            onClick={() => isMeetingInstance && data && onCellClick()}
        >
            <label
                className={classes.cell__label}
                style={{ cursor: data && isMeetingInstance ? 'pointer' : 'default' }}
            >
                {isMeetingInstance && (
                    <span>{currentNumber}</span>
                )}
            </label>
        </div>
    );
});

function getNumOfWeek(date: Date | string, from?: Date): number {
    const startWeek = getISOWeek(new Date(from!)) - 1;
    const currentWeek = getISOWeek(new Date(date));
    const weekNumber = currentWeek - startWeek;
    if (weekNumber < 0) {
        const startYear = new Date(from!).getFullYear();
        const lastYearWeek = getISOWeek(new Date(startYear, 11, 31));
        const totalYearWeeks = lastYearWeek - startWeek;
        return totalYearWeeks + currentWeek;
    }

    return weekNumber;
}
