import React from 'react';

export function BorderCrossBlueIcon() {
    return (
        <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.5 18.5C4.52353 18.5 0.5 14.4765 0.5 9.5C0.5 4.52353 4.52353 0.5 9.5 0.5C14.4765 0.5 18.5 4.52353 18.5 9.5C18.5 14.4765 14.4765 18.5 9.5 18.5ZM9.5 1.55882C5.10588 1.55882 1.55882 5.10588 1.55882 9.5C1.55882 13.8941 5.10588 17.4412 9.5 17.4412C13.8941 17.4412 17.4412 13.8941 17.4412 9.5C17.4412 5.10588 13.8941 1.55882 9.5 1.55882Z" fill="url(#paint0_linear_2184_7758)" />
            <path d="M12.9812 5.26975C13.188 5.0629 13.5234 5.06296 13.7301 5.26987C13.9368 5.47668 13.9367 5.81185 13.73 6.01858L6.01859 13.73C5.81187 13.9367 5.47671 13.9367 5.26999 13.73C5.06328 13.5233 5.06327 13.1881 5.26998 12.9814L12.9812 5.26975Z" fill="url(#paint1_linear_2184_7758)" />
            <path d="M5.26987 5.26987C5.47664 5.06295 5.81201 5.06289 6.01885 5.26973L9.87429 9.12518L13.73 12.9809C13.9367 13.1876 13.9368 13.5228 13.7301 13.7296C13.5234 13.9365 13.188 13.9366 12.9811 13.7297L9.1257 9.87429L5.27 6.01859C5.06326 5.81185 5.0632 5.47668 5.26987 5.26987Z" fill="url(#paint2_linear_2184_7758)" />
            <defs>
                <linearGradient id="paint0_linear_2184_7758" x1="1.19231" y1="9.5" x2="19.1923" y2="6.73077" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#6341B6" />
                    <stop offset="1" stopColor="#0D21D7" />
                </linearGradient>
                <linearGradient id="paint1_linear_2184_7758" x1="1.19231" y1="9.5" x2="19.1923" y2="6.73077" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#6341B6" />
                    <stop offset="1" stopColor="#0D21D7" />
                </linearGradient>
                <linearGradient id="paint2_linear_2184_7758" x1="1.19231" y1="9.5" x2="19.1923" y2="6.73077" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#6341B6" />
                    <stop offset="1" stopColor="#0D21D7" />
                </linearGradient>
            </defs>
        </svg>
    );
}
