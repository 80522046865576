import React from 'react';

import { Occupation } from '../OccupationDashboardInterfaces';
import classes from './ModulesList.module.scss';
import { Module } from './Module';

interface Props {
    occupation: Occupation;
    isTUniversityMeetingsVisible: boolean;
}
// здесь задается первая грид сетка для списка модулей
// состоит из двух колонок: название модуля в первой колонке
// и сетка со списком ролей во второй колонке
export function ModulesList({
    occupation,
    isTUniversityMeetingsVisible,
}: Props): JSX.Element | null {
    if (occupation.modules.length) {
        return (
            <div
                className={classes.modulesList}
                style={{
                    display: 'grid',
                    gridTemplateColumns: '150px auto',
                    gridTemplateRows: `repeat(${occupation?.modules.length || 0}, auto)`,
                }}
            >
                {
                    occupation?.modules.map(
                        (module, index) => (
                            <Module
                                key={module.id}
                                teachersMap={occupation.teachersMap}
                                module={module}
                                index={index}
                                isTUniversityMeetingsVisible={isTUniversityMeetingsVisible}
                            />
                        ),
                    )
                }
            </div>
        );
    }
    return null;
}
