import React, { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import { nanoid } from 'nanoid';
import { useHistory } from 'react-router-dom';

import { Alert } from '@common/Alert';
import { Confirm } from '@common/Confirm';
import { BorderCrossIcon } from '@common/svg';
import { ActionButton, ActionTypeOfButton } from '@common/ActionButton';
import {
    GetEquipmentsOnRoomPage_equipment_list, RepeatingAvailableIntervalInput,
    RoomAvailableIntervalInput, RoomsPage_Rooms_rooms_location, UpdateRoomInput,
} from 'src/graphql-query-types';

import { RoomCreation } from './RoomCreation';
import {
    AlertMessage,
    Building,
    RoomBackendData,
    RoomCreationEquipment,
    RoomEquipment,
    RoomMeetingFormat,
    Status,
} from '../RoomTypes';

import classes from './RoomCreationNew.module.scss';

export interface RoomCreationProps {
    universityTimeZone: string;
    activeTab: string;
    buildings: Building[];
    meetingFormats: RoomMeetingFormat[];
    roomInfo?: RoomBackendData;
    roomEquipment?: RoomEquipment;
    storeEquipment: GetEquipmentsOnRoomPage_equipment_list[];
    createRoomHandler({ equipments, ...rest }: UpdateRoomInput): void;
    editRoomHandler({ equipments, ...rest }: UpdateRoomInput): void;
    addRoomButton(): void;
    closeEditRoom?(): void;
    deleteRoomHandler?: (id: string) => void
}
export function RoomCreationContainer({
    universityTimeZone,
    activeTab,
    meetingFormats,
    storeEquipment,
    roomInfo,
    buildings,
    editRoomHandler,
    createRoomHandler,
    addRoomButton,
    closeEditRoom,
    deleteRoomHandler,
}: RoomCreationProps): JSX.Element {
    const defaultIntervals :Omit<RoomAvailableIntervalInput, '__typename'>[] = roomInfo ? roomInfo.availableIntervals.map((interval) => (
        {
            id: interval.id,
            from: interval.from,
            to: interval.to,
            isEveryMonth: interval.isEveryMonth,
        }
    )) : [];

    const defaultRepeatingIntervals: RepeatingAvailableIntervalInput[] = (roomInfo
        && roomInfo.repeatingAvailableIntervals && roomInfo.repeatingAvailableIntervals.length)
        ? roomInfo.repeatingAvailableIntervals.map(
            ({ id, from, to, weekType, weekDay }) => ({
                id,
                from,
                to,
                weekType,
                weekDay,
            }),
        ) : [];
    const [id, setID] = useState<string>(nanoid());
    const [title, setTitle] = useState<string>('');
    const [capacity, setCapacity] = useState<number>(0);
    const [meetingFormat, setMeetingFormat] = useState<RoomMeetingFormat[]>([]);
    const [buildingId, setBuildingId] = useState<string>('');
    const [equipments, setEquipments] = useState<RoomCreationEquipment[]>([]);
    const [isAvailable, setAvailable] = useState(roomInfo?.isAvailable ?? true);
    const [availableIntervals, setAvailableIntervals] = useState(
        defaultIntervals,
    );
    const [location,
        setLocation] = useState<
    RoomsPage_Rooms_rooms_location | undefined
    >(
        roomInfo?.location,
    );
    const [repeatingAvailableIntervals,
        setRepeatingAvailableIntervals] = useState<
    RepeatingAvailableIntervalInput[]
    >(defaultRepeatingIntervals);
    const [isdelete, setIsdelete] = useState(false);

    const [roomState, setRoomState] = useState<UpdateRoomInput>({
        id,
        title,
        capacity,
        meetingFormatIds: [],
        buildingId,
        equipments,
        isAvailable,
        availableIntervals,
        repeatingAvailableIntervals,
    });

    const [alert, setAlert] = useState<AlertMessage>({
        alert: false,
        message: '',
    });

    const [
        suggestionList, setSuggestionList,
    ] = useState<GetEquipmentsOnRoomPage_equipment_list[]>(storeEquipment);

    const [, toggleModal] = useState<boolean>(false);

    const history = useHistory();

    const chooseZone = (value: string) => {
        setBuildingId(value);
    };

    const handleRoomTitle = ({ target }: ChangeEvent<HTMLInputElement>) => {
        const { value } = target;
        setTitle(value.trim());
    };

    const handleRoomCapacity = ({ target }: ChangeEvent<HTMLInputElement>) => {
        const { value } = target;
        setCapacity(Number(value.trim()));
    };

    const handleRoomType = (rooms: RoomMeetingFormat[] | []) => {
        const filteredTypes = filterTypes(rooms, meetingFormat);
        setMeetingFormat(filteredTypes);
    };

    function filterTypes(chosenRooms: RoomMeetingFormat[] | [], allRooms: RoomMeetingFormat[]) {
        return allRooms.map((room) => getCheckedElementType(chosenRooms, room));
    }

    function getCheckedElementType(
        chosenRooms: RoomMeetingFormat[],
        elementType: RoomMeetingFormat,
    ) {
        if (isRoomAdded(chosenRooms, elementType)) {
            return { ...elementType, status: Status.checked };
        }
        return { ...elementType, status: Status.check };
    }

    function isRoomAdded(chosenRooms: RoomMeetingFormat[], elementType: RoomMeetingFormat) {
        return chosenRooms.some((room) => room.id === elementType.id);
    }

    const updateSuggestionList = (suggestId: string) => {
        const filteredSuggestion = suggestionList.filter((el) => el.id !== suggestId);
        setSuggestionList(filteredSuggestion);
    };

    const addRoomItems = ({ id: itemID, count, name }: RoomCreationEquipment) => {
        const foundInEquipmentArr = equipments
            .find((el: { id: string; name: string; }) => el.id === itemID);
        if (!foundInEquipmentArr) {
            setEquipments([...equipments, { id: itemID, count, name }]);
            updateSuggestionList(itemID);
        } else {
            setAlert({ alert: true, message: 'Извините, но такой предмет уже добавлен!' });
            setTimeout(() => {
                setAlert({ alert: false, message: '' });
            }, 3000);
        }
    };

    const removeRoomItems = (itemId: string) => {
        const newItems = equipments.filter(equip => equip.id !== itemId);
        setEquipments(newItems);
    };

    const toggleModalWindow = () => {
        toggleModal(false);
    };

    const modalWindowCheck = () => {
        const validTypes = meetingFormat.filter((types) => types.status === Status.checked);
        if (validTypes.length === 0) {
            setAlert({ alert: true, message: 'Тип помещения не указан' });
            setTimeout(() => {
                toggleModalWindow();
                setAlert({ alert: false, message: '' });
            }, 2600);
        } else if (!buildingId) {
            setAlert({ alert: true, message: 'Расположение не указано' });
            setTimeout(() => {
                toggleModalWindow();
                setAlert({ alert: false, message: '' });
            }, 2600);
        } else if (!roomInfo?.id) {
            createRoomHandler(roomState);
            addRoomButton();
            toggleModalWindow();
        } else {
            if (closeEditRoom) {
                closeEditRoom();
            }
            history.push(`/university/room/${roomInfo.id}/zone/${activeTab}`);
            editRoomHandler(roomState);
            toggleModalWindow();
        }
    };

    function handleForm(event: FormEvent<HTMLFormElement>) {
        event.preventDefault();
        toggleModal(true);
    }
    useEffect(() => {
        const roomTypeID = meetingFormat.filter((item) => {
            if (item.status === 'checked') {
                return item.id;
            }
            return false;
        });

        // eslint-disable-next-line @typescript-eslint/no-shadow
        const equipmentsForSend = equipments.map(({ id, count }) => ({ id, count }));
        const finalSendType = roomTypeID.map((item) => item.id);
        const objectForSend: UpdateRoomInput = {
            id,
            title,
            capacity,
            buildingId,
            meetingFormatIds: finalSendType,
            equipments: equipmentsForSend,
            isAvailable,
            availableIntervals,
            repeatingAvailableIntervals,
        };
        setRoomState(objectForSend);
    }, [
        meetingFormat,
        equipments,
        title,
        capacity,
        buildingId,
        availableIntervals,
        isAvailable,
        repeatingAvailableIntervals,
    ]);

    useEffect(() => {
        if (roomInfo) {
            const {
                id: existID,
                title: existTitle,
                capacity: existCapacity,
                equipmentToRoom: existEquipmentToRoom,
                isAvailable: isAvailableRoom,
                location: roomsLocation,
                availableIntervals: availableTimes,
                repeatingAvailableIntervals: repeatingAvailableTimes,
            } = roomInfo;
            const { id: existBuildingId } = roomsLocation;
            setID(existID);
            setBuildingId(existBuildingId);
            setTitle(existTitle);
            setCapacity(existCapacity);
            setMeetingFormat(meetingFormats);
            const result = existEquipmentToRoom.map((itemEquip) => ({
                id: itemEquip.equipmentId,
                name: itemEquip.equipment?.category.name ?? '',
                count: itemEquip.countEquipments,
            }));
            setEquipments(result);
            setAvailableIntervals(availableTimes ?? []);
            setRepeatingAvailableIntervals(repeatingAvailableTimes ?? []);
            setLocation(roomsLocation);
            setAvailable(isAvailableRoom);
        } else {
            const newTypes = meetingFormats
                .map((typeItem: RoomMeetingFormat) => ({ ...typeItem, status: Status.check }));
            setMeetingFormat(newTypes);
        }
    }, [roomInfo]);

    const AddOrEditFormButton = !roomInfo ? 'Добавить' : 'Изменить';
    const AddOdEditButton = !roomInfo ? addRoomButton : closeEditRoom;

    return (
        <>
            <header className={classes.header}>
                <BorderCrossIcon handler={AddOdEditButton} />
            </header>

            <form className={classes.form}>
                <RoomCreation
                    id={roomInfo?.id}
                    universityTimeZone={universityTimeZone}
                    buildings={buildings}
                    defaultAddress={buildingId}
                    isAvailable={isAvailable}
                    title={title}
                    capacity={capacity}
                    types={meetingFormat}
                    equipmentsRoom={equipments}
                    storeEquipment={suggestionList}
                    availableIntervals={availableIntervals}
                    repeatingAvailableIntervals={repeatingAvailableIntervals}
                    location={location}
                    handleRoomTitle={handleRoomTitle}
                    handleRoomCapacity={handleRoomCapacity}
                    handleRoomType={handleRoomType}
                    addRoomItems={addRoomItems}
                    removeRoomItems={removeRoomItems}
                    handlerAvailableIntervals={setAvailableIntervals}
                    handlerRepeatingAvailableIntervals={setRepeatingAvailableIntervals}
                    handlerAvailable={setAvailable}
                    chooseZone={chooseZone}
                />

                <section className={classes.section}>
                    <ActionButton
                        name="delete"
                        type="button"
                        actionType={ActionTypeOfButton.SECONDARY}
                        onClick={(e) => {
                            setIsdelete(!isdelete);
                            handleForm(e);
                        }}
                    >
                        Удалить
                    </ActionButton>

                    <ActionButton
                        name="add"
                        type="button"
                        className={classes.button__remove}
                        onClick={modalWindowCheck}
                    >
                        {AddOrEditFormButton}
                    </ActionButton>
                </section>

                {
                    alert.alert && <Alert message={alert.message} time={6000} />
                }
                {
                    isdelete && (
                        <Confirm
                            headerText="Вы действительно хотите удалить помещение?"
                            descriptionText=""
                            secondaryBtnText="Отменить"
                            primaryBtnText="Удалить"
                            onSecondaryBtnClick={() => setIsdelete(!isdelete)}
                            onPrimaryBtnClick={() => roomInfo?.id
                                && deleteRoomHandler
                                && deleteRoomHandler(roomInfo?.id)}
                        />
                    )
                }
            </form>
        </>
    );
}
