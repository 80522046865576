import React from 'react';
import { observer } from 'mobx-react';
import { Section } from './Section';
import { SuggestSelectForm, SuggestSelectFormInitialData } from './SuggestSelectForm';
import { SuggestForm, SuggestFormInitialData } from './SuggestForm';
import { normalBoldJSX } from '../utils';
import { BaseItem, dictionaryStore, ModuleSkill, Skill } from '../Store';

export interface SuggestSelectElement {
    id: string;
    name: string;
    selectOption?: BaseItem;
}

interface Props {
    isLevelNeeded: boolean;
    typeSkills: ModuleSkill[];
    skills: Skill[];
    typeID: string;

    onChange(items: SuggestSelectElement[], typeID: string): void;
}

function getSortSkills(skills: Skill[]): Skill[] {
    const sortSkills = [...skills].map(skill => ({ ...skill, name: skill.fullName }))
        .sort((a: Skill, b: Skill) => {
            if (a.name < b.name) return -1;
            if (a.name > b.name) return 1;
            return 0;
        });
    return sortSkills;
}

export const getSuggestFormProps = (skills: Skill[]) => ({
    mainOptions: getSortSkills(skills),
    placeholder: 'Введите значение',
});

const getSuggestSelectFormProps = (skills: Skill[]) => {
    const sortSkills = getSortSkills(skills);
    const optionsSelect = sortSkills.map(skill => ({
        id: skill.id,
        options: Array.from(Array(skill.maxLevel).keys())
            .map(level => level + 1)
            .map(level => ({ id: String(level), name: String(level) })),
    }));
    return {
        mainOptions: sortSkills,
        optionsSelect,
        labelSelect: 'Уровень',
        placeholderSuggest: 'Введите значение',
    };
};

export const SkillSection = observer(({
    isLevelNeeded,
    typeSkills,
    skills,
    typeID,
    onChange,
}: Props): JSX.Element => {
    const finalTypeID = dictionaryStore.getFinalSkillTypeId(typeID);
    const list = typeSkills
        ?.filter(typeSkill => typeSkill.skill.typeId === finalTypeID)
        .map(typeSkill => ({
            ...typeSkill.skill,
            selectOption: {
                id: String(typeSkill.level),
                name: String(typeSkill.level),
            },
        }));
    return isLevelNeeded
        ? (
            <Section
                list={list}
                formatItem={item => normalBoldJSX(item.name, item.selectOption?.name)}
                initialData={SuggestSelectFormInitialData}
                form={SuggestSelectForm}
                formProps={getSuggestSelectFormProps(skills)}
                validate={({ selectOption }) => selectOption?.id !== ''}
                onChange={items => onChange(items, typeID)}
            />
        )
        : (
            <Section
                list={list}
                formatItem={item => <>{item.name}</>}
                initialData={SuggestFormInitialData}
                form={SuggestForm}
                formProps={getSuggestFormProps(skills)}
                onChange={items => onChange(items, typeID)}
            />
        );
});
