import React, { useState } from 'react';

import classes from './ModuleMeetingAndEvalPoint.module.scss';
import { ModuleSpecificMeeting } from './ModuleSpecificMeeting';

interface Props {
    currentModule: any;
    skillTypes: any;
    allSkillsList: any;
}

export function ModuleMeetingAndEvalPoint({
    currentModule,
    skillTypes,
    allSkillsList,
}: Props):JSX.Element {
    const [openedMeeting, setOpenedMeeting] = useState(null);
    const meetingAndEvaluationPoints = currentModule.meetings.map((item: any) => item);
    currentModule.evaluationPoints.forEach((evaluationPoint: any) => {
        meetingAndEvaluationPoints.forEach((currentMeeting: any, index: number) => {
            if (currentMeeting.id === evaluationPoint.previousEventId) {
                meetingAndEvaluationPoints.splice(index + 1, 0, evaluationPoint);
            }
        });
    });
    return (
        <div className={classes.moduleMeetingAndEvalPoint}>
            <ul className={classes.moduleMeetingAndEvalPoint__list}>
                {meetingAndEvaluationPoints.map((item: any, index: number) => (
                    <li
                        key={item.id}
                        className={classes.moduleMeetingAndEvalPoint__item}
                    >
                        <ModuleSpecificMeeting
                            openedMeeting={openedMeeting}
                            setOpenedMeeting={setOpenedMeeting}
                            meetingIndex={index}
                            meeting={item}
                            skillTypes={skillTypes}
                            allSkillsList={allSkillsList}
                        />
                    </li>
                ))}
            </ul>
        </div>
    );
}
