import { useLocation, useParams } from 'react-router-dom';

export const useBasePathAndParams = <Params>() => {
    const location = useLocation();
    const params = useParams<Params>();

    return {
        basePath: Object.values(params).reduce(
            (path, param) => path.replace(`/${param}`, ''),
            location.pathname,
        ),
        params,
    };
};
