import React from 'react';
import { observer } from 'mobx-react';

import { Note } from './Note';
import { Users } from './Users';
import { Attendance } from './Attendance';
import { FilterType } from '../../../ScheduleTable/EventFilterMenu/filterType';
import { Evaluation } from './Evaluation';
import { eventsStore } from '../../../Store';
import { accountStore } from '../../../../Store';

import classes from './ScheduleEventSection.module.scss';

export const ScheduleEventSection = observer((): JSX.Element => (
    <div className={classes.schedulePage_meetingSection}>
        <div className={classes.schedulePage_meetingSection_meetingData}>
            <Note />
        </div>

        {eventsStore.selectedEvent?.type !== FilterType.ASSIGNMENT && (
            <Users
                userList={eventsStore.getSortedTeachersList()}
                listName="Преподаватели"
            />
        )}

        {accountStore.isTeacher() && (
            <>
                {(eventsStore.selectedEvent?.type === FilterType.MEETING
                    && eventsStore.selectedEvent.meetingInstanceToStudents)
                    && (
                        <Attendance
                            userList={eventsStore.selectedEvent.meetingInstanceToStudents}
                        />
                    )}
                {eventsStore.selectedEvent?.type === FilterType.EVALUATION_POINT && (
                    <Evaluation />
                )}
            </>
        )}

        {accountStore.isStudent()
            && !eventsStore.isAssignment(eventsStore.selectedEvent)
            && (
                <Users
                    userList={eventsStore.getSortedStudentsList()}
                    listName="Студенты"
                />
            )}
    </div>
));
