import React from 'react';
import { observer } from 'mobx-react-lite';
import cn from 'classnames';
import { GroupSlot } from '../subSpaceTypes';
import classes from './SlotGroup.module.scss';
import Diagram from '../store/Diagram';
import { findBtiIndex } from '../SubSpaceSlotDiagram/utilities';

interface Props {
    groupSlot: GroupSlot
}

export const SlotGroup = observer(({
    groupSlot,
}: Props): JSX.Element => {
    const {
        groupSlotSettings,
        diagramSettings,
        slotSettings,
        forkSettings,
        diagramEntityRelationSettings,
        btiSettings,
    } = Diagram;
    const {
        id: groupSlotId,
        nextSlots = [],
        nextGroupSlots = [],
        nextForks = [],
        spaceBaseTimeIntervalId,
    } = groupSlot;
    const {
        editMode,
        saveRelationDiagramEntityMode,
        createGroupMode,
        relationDiagramEntityMode,
        removeDiagramEntityMode,
        createSlotMode,
        createForkMode,
    } = diagramSettings;
    const {
        id: groupSlotSettingsId, isGroupSlotCanMove,
    } = groupSlotSettings;
    const { isForkCanMove } = forkSettings;
    const { isSlotCanMove } = slotSettings;
    const {
        nextEntityId,
        previousEntityId,
    } = diagramEntityRelationSettings;
    const numberOfRelations = nextGroupSlots.length + nextSlots.length + nextForks.length;

    const currentBtiIndex: number = findBtiIndex(
        btiSettings,
        groupSlot.spaceBaseTimeIntervalId,
    );

    const selectedEntityBtiIndex: number = findBtiIndex(
        btiSettings,
        diagramEntityRelationSettings.spaceBaseTimeIntervalId,
    );
    return (
        <div
            className={classes.slotGroup}
            onClick={(event) => {
                if (createGroupMode || createSlotMode) {
                    event.stopPropagation();
                }
            }}
        >
            <div
                className={cn(classes.slotGroup__figure, {
                    [classes.slotGroup__figure_active]: (groupSlotSettingsId === groupSlotId
                        && isGroupSlotCanMove),
                    [classes.slotGroup__figure_dashed]: numberOfRelations < 1,
                    [classes.slotGroup__figure_withoutPointer]:
                    createSlotMode
                    || !editMode
                    || isGroupSlotCanMove
                    || createGroupMode
                    || createForkMode
                    || isForkCanMove
                    || (
                        diagramEntityRelationSettings.entityType === 'GroupSlot'
                        && diagramEntityRelationSettings.spaceBaseTimeIntervalId
                        !== groupSlot.spaceBaseTimeIntervalId
                    ) || (
                        diagramEntityRelationSettings.entityType === 'ModuleSlot'
                        && currentBtiIndex < selectedEntityBtiIndex
                    ) || (
                        diagramEntityRelationSettings.entityType === 'Fork'
                        && currentBtiIndex !== selectedEntityBtiIndex
                    ),
                    [classes.slotGroup__figure_readyToRemove]: removeDiagramEntityMode,
                    [classes.slotGroup__figure_readyToConnect]: (
                        relationDiagramEntityMode
                        && previousEntityId !== groupSlot.id
                        && spaceBaseTimeIntervalId
                        === diagramEntityRelationSettings.spaceBaseTimeIntervalId
                    ),
                    [classes.slotGroup__figure_setPrevious]: relationDiagramEntityMode
                    && groupSlot.id === previousEntityId
                    && !nextEntityId,
                    [classes.slotGroup__figure__entity_warning]:
                    (
                        !editMode && numberOfRelations < 1
                    ) || (
                        !editMode && numberOfRelations === 1
                    ),
                })}
                onClick={(event) => {
                    if (createGroupMode || createSlotMode) {
                        event.stopPropagation();
                    }

                    if (editMode
                        && !createGroupMode
                        && !createSlotMode
                        && !createForkMode
                        && !relationDiagramEntityMode
                        && !removeDiagramEntityMode
                        && !isSlotCanMove
                        && !isForkCanMove
                    ) {
                        Diagram.setGroupSlotCanMove(
                            event,
                            {
                                id: groupSlotId,
                                spaceBaseTimeIntervalId,
                            },
                        );
                    }
                    if (removeDiagramEntityMode) {
                        Diagram.removeEntity(
                            groupSlot.id,
                            groupSlot.spaceBaseTimeIntervalId,
                        );
                    }

                    if (
                        (
                            relationDiagramEntityMode
                            && diagramEntityRelationSettings.entityType === 'ModuleSlot'
                            && !saveRelationDiagramEntityMode
                            && previousEntityId !== groupSlot.id
                            && currentBtiIndex >= selectedEntityBtiIndex
                        ) || (
                            relationDiagramEntityMode
                            && diagramEntityRelationSettings.entityType === 'GroupSlot'
                            && groupSlot.spaceBaseTimeIntervalId
                            === diagramEntityRelationSettings.spaceBaseTimeIntervalId
                            && !saveRelationDiagramEntityMode
                            && previousEntityId !== groupSlot.id
                        ) || (
                            relationDiagramEntityMode
                            && diagramEntityRelationSettings.entityType === 'Fork'
                            && groupSlot.spaceBaseTimeIntervalId
                            === diagramEntityRelationSettings.spaceBaseTimeIntervalId
                            && !saveRelationDiagramEntityMode
                            && previousEntityId !== groupSlot.id
                        ) || (
                            !diagramEntityRelationSettings.entityType
                        )
                    ) {
                        Diagram.setDiagramEntityRelationSettings(
                            groupSlot.id,
                            groupSlot.spaceBaseTimeIntervalId,
                            'GroupSlot',
                        );
                    }
                }}
            />
            {
                (!editMode && numberOfRelations < 1) && (
                    <span className={classes.slotGroup__toolTipText}>
                        Группа должна содержать слоты
                    </span>
                )
            }
            {
                (!editMode && numberOfRelations === 1) && (
                    <span className={classes.slotGroup__toolTipText}>
                        Группа не может содержать только одну связь
                    </span>
                )
            }
        </div>

    );
});
