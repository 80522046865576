import React from 'react';
import { observer } from 'mobx-react';

import { eventsStore } from '../../../../Store';

import classes from './EventRoom.module.scss';
import { FilterType } from '../../../../Interfaces';

export const EventRoom = observer((): JSX.Element | null => {
    const event = eventsStore.selectedEvent;
    if (event?.type === FilterType.MEETING) {
        return (
            <div className={classes.eventHeader__roomLocation}>
                {event.roomType === 'indoor'
                    ? <>Аудитория: {event.room?.title ?? '-'} &nbsp; {event.room?.location.location ?? '-'}</>
                    : (
                        <>
                            Ссылка: {isCorrectUrl(event.onlineRoom?.url)
                                ? <a href={event.onlineRoom?.url}>{event.onlineRoom?.url}</a>
                                : <>{event.onlineRoom?.url}</>
                            }
                        </>
                    )
                }
            </div>
        );
    }
    return null;
});

function isCorrectUrl(url?: string): boolean {
    if (!url) return false;
    return url.indexOf('http') > -1;
}
