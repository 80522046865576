import React from 'react';

import { BlackCrossIcon } from '@common';
import { WeekTypes } from '@admin/RoomsPage/RoomTypes';
import { getWeekDaysInfoString } from '@admin/RoomsPage';
import classes from './RepeatingAvailableIntervals.module.scss';
import { RepeatingAvailableIntervalInput } from '../../../../../../graphql-query-types';

interface Props {
    repeatingIntervals: RepeatingAvailableIntervalInput[];
    repeatingAvailableIntervals: RepeatingAvailableIntervalInput[];
    setRepeatingAvailableIntervals: (
        newRepeatingIntervals: RepeatingAvailableIntervalInput[],
    ) => void;
}

export function RepeatingAvailableIntervals(
    {
        repeatingIntervals,
        repeatingAvailableIntervals,
        setRepeatingAvailableIntervals,
    }: Props,
) {
    const weekDaysInfoString = getWeekDaysInfoString(repeatingIntervals);

    const timeFrom = repeatingIntervals[0].from.split('+')[0];
    const timeTo = repeatingIntervals[0].to.split('+')[0];

    const repeatingIntervalsTimeInfo = ` с ${timeFrom} до ${timeTo}`;

    const removeRepeatingAvailableIntervals = () => {
        const filteredIntervals = new Array(...repeatingAvailableIntervals).filter(
            (interval) => (
                !repeatingIntervals.some((currentInterval) => (
                    currentInterval.id === interval.id
                ))
            ),
        );

        setRepeatingAvailableIntervals(filteredIntervals);
    };

    return (
        <div className={classes.repeatingAvailableIntervals}>

            <div className={classes.repeatingAvailableIntervals__text}>

                по
                <span className={classes.repeatingAvailableIntervals__text_bold}>
                    {weekDaysInfoString}
                </span>
                {repeatingIntervalsTimeInfo}
                {' '}
                <span className={classes.repeatingAvailableIntervals__text_bold}>
                    {WeekTypes[repeatingIntervals[0].weekType]}
                </span>

            </div>

            <button
                type="button"
                onClick={() => removeRepeatingAvailableIntervals()}
            >
                <BlackCrossIcon />
            </button>
        </div>
    );
}
