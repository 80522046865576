import React from 'react';

import { groupBy } from 'lodash';
import { ModuleSkillsBlock } from './ModuleSkillsBlock';

import classes from './ModuleSkillsBlock.module.scss';

interface Props {
    evaluationPointSkills: any;
    skillTypes: any;
    allSkillsList: any;
}

export function ModuleEvaluationsPointSkillsBlock({
    evaluationPointSkills,
    skillTypes,
    allSkillsList,
}: Props):JSX.Element {
    const arr = Object.entries(groupBy(evaluationPointSkills, 'skill.typeId'));
    return (
        <ul>
            {arr.map((item: any, index: number) => (
                <li
                    // eslint-disable-next-line
                    key={index}
                    className={classes.moduleSkillsBlock__item_list}
                >
                    <ModuleSkillsBlock
                        text="оценивающиеся в этой точке:"
                        skillList={item}
                        skillTypes={skillTypes}
                        allSkillsList={allSkillsList}
                    />
                </li>
            ))}
        </ul>
    );
}
