import React, { Fragment } from 'react';
import { userRoles } from '@admin/Users/utils/constants';
import classes from './StatusCheckboxes.module.scss';

interface Props {
    checkboxChecked?: boolean;
    onChangeType?(value: string): void;
}
export const StatusCheckboxes = ({
    onChangeType, checkboxChecked,
}: Props): JSX.Element => (
    <div className={classes.statusCheckList}>
        {userRoles.map((item) => (
            <Fragment key={item.id}>
                <label className={classes.statusCheckList__label}>
                    <input
                        type="checkbox"
                        id={item.id}
                        value={item.value}
                        onChange={() => onChangeType && onChangeType(item.value)}
                        className={classes.statusCheckList__input}
                        checked={checkboxChecked}
                    />

                    {item.name.toLowerCase()}
                </label>
            </Fragment>
        ))}
    </div>
);
