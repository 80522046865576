import React from 'react';
import cn from 'classnames';

import { Arrow } from '@common/Select/Arrow';
import classes from '../ModuleStatistics.module.scss';
import { SkillTypeOption } from '../ModuleStatisticsTypes';

interface Props {
    wrapperRef: React.MutableRefObject<null | HTMLDivElement>;
    isMenuOpen: boolean;
    selectedSkillType: SkillTypeOption | undefined;
    skillTypeOptions: SkillTypeOption[];
    setIsMenuOpen: React.Dispatch<React.SetStateAction<boolean>>;
    onSkillTypeChange(skillType: SkillTypeOption): void;
}

export function SkillTypeSelect({
    wrapperRef,
    isMenuOpen,
    selectedSkillType,
    skillTypeOptions,
    setIsMenuOpen,
    onSkillTypeChange,
}: Props) {
    return (
        <div className={classes['module-statistics__skill-type-filter']}>
            <span className={classes['module-statistics__filter-label']}>
                Тип образовательного результата
            </span>
            <div
                ref={wrapperRef}
                className={cn(
                    classes['module-skill-type-filter'],
                    { [classes['module-skill-type-filter_active']]: isMenuOpen },
                )}
                onClick={() => setIsMenuOpen(!isMenuOpen)}
            >
                <span className={classes['module-skill-type-filter__selected-option']}>
                    {selectedSkillType?.name}
                </span>
                <Arrow />
                {isMenuOpen && (
                    <div className={classes['module-skill-type-filter__option-list']}>
                        {skillTypeOptions.map(skillType => (
                            <div
                                key={skillType.id}
                                onClick={() => onSkillTypeChange(skillType)}
                            >
                                {skillType.name}
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
}
