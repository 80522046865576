import { SearchType } from './SearchForm';
import { Type, UserStatus } from '../apollo-types';

const mockData = {
    status: [
        {
            id: 'none',
            name: 'Любой статус',
        },
        {
            id: UserStatus.active,
            name: 'Активен',
        },
        {
            id: UserStatus.inactive,
            name: 'Неактивен',
        }],
    role: [
        {
            id: 'none',
            name: 'Любая роль',
        },
        {
            id: Type.admin,
            name: 'Админ',
        },
        {
            id: Type.student,
            name: 'Студент',
        },
        {
            id: Type.teacher,
            name: 'Преподаватель',
        },
    ],
};

export const columnConfigs = {
    fullName: { name: 'ФИО', searchType: SearchType.input, searchName: 'fullName' },
    studentTimeInterval: {
        name: 'Временной интервал',
        searchType: SearchType.baseTimeInterval,
        searchName: 'baseTimeIntervalInstanceId',
    },
    space: {
        name: 'Пространство',
        searchType: SearchType.selectSpace,
        searchName: 'spaceId',
    },
    status: {
        name: 'Статус',
        searchType: SearchType.select,
        selectOptions: mockData.status,
        searchName: 'status',
    },
    email: {
        name: 'e-mail', searchType: SearchType.input, searchName: 'email',
    },
    birthday: {
        name: 'Дата рождения', searchType: SearchType.calendar,
    },
    role: {
        name: 'Роль в системе',
        searchType: SearchType.select,
        selectOptions: mockData.role,
        searchName: 'userType',
    },
    skills: {
        name: 'Образовательные результаты', searchType: SearchType.input, searchName: 'skillName',
    },
};
