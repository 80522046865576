import React, { useEffect } from 'react';

import { format } from 'date-fns';
import { ArrowGradientIcon } from '@common/svg/ArrowGradientIcon';
import { TeacherAssignmentSidebar } from '../TeacherAssignmentSidebar/TeacherAssignmentSidebar';

import {
    filteredTeacherAssignmentInstances_filteredTeacherAssignmentInstances,
} from '../../TeacherAssignmentsApolloTypes';

import classes from './TeacherAssignment.module.scss';

interface Props {
    // eslint-disable-next-line max-len
    assignment?: filteredTeacherAssignmentInstances_filteredTeacherAssignmentInstances;
    updateNote: (teacherNote: string) => void;
    onClick: () => void;
}

export function TeacherAssignmentPage({
    onClick,
    updateNote,
    assignment,
}: Props) {
    const scrollPositionX = 0;
    const scrollPositionY = 0;

    useEffect(() => window.scrollTo(scrollPositionX, scrollPositionY), []);

    return (
        <section className={classes.teacherAssignment}>
            <div className={classes.teacherAssignment__buttonBack}>
                <button
                    type="button"
                    className={classes.teacherAssignment__textGradient}
                    onClick={onClick}
                >
                    <ArrowGradientIcon />
                    Назад
                </button>
            </div>

            <h1 className={classes.teacherAssignment__title}>
                {assignment?.assignmentInstance.assignment.topic}
            </h1>

            <div className={classes.teacherAssignment__wrapper}>
                <div className={classes.teacherAssignment__content}>
                    <article className={classes.teacherAssignment__article}>
                        <h2 className={classes.teacherAssignment__subtitle}>
                            {
                                assignment
                                && assignment.assignmentInstance.wave.index !== null
                                && assignment.assignmentInstance.wave.index + 1
                            } поток

                            {`, ${format(new Date(assignment?.assignmentInstance.start), 'dd.MM')} -`}

                            {
                                assignment?.assignmentInstance.deadline
                                    ? format(new Date(assignment?.assignmentInstance.deadline), 'dd.MM')
                                    : 'н/д'
                            }
                        </h2>

                        <p className={classes.teacherAssignment__text}>
                            {
                                assignment?.assignmentInstance.nextMeetingInstance?.meeting.topic
                                ?? 'Описание отсутствует'
                            }
                        </p>

                        {
                            `Продолжительность — ${assignment?.assignmentInstance.assignment.duration?.name} минут`
                        }
                    </article>

                    <article className={classes.teacherAssignment__article}>
                        <h2 className={classes.teacherAssignment__subtitle}>
                            Описание от владельца модуля:
                        </h2>

                        <p className={classes.teacherAssignment__text}>
                            {
                                assignment?.assignmentInstance.assignment.description
                                ?? 'Описание отсутствует'
                            }
                        </p>
                    </article>
                </div>

                <TeacherAssignmentSidebar
                    assignment={assignment}
                    updateNote={updateNote}
                />
            </div>
        </section>
    );
}
