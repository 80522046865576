import React from 'react';

// import { CommonTargetName, useSendMetriks } from '@common/hooks';
import { DateSelect } from './DateSelect';
import { UnitOfTimeSelect } from './UnitOfTimeSelect/UnitOfTimeSelect';
// import { ViewType } from '../../../graphql-query-types';

import classes from './ScheduleHeader.module.scss';

export function ScheduleHeader(): JSX.Element {
    // const { sendYandexMetrika } = useSendMetriks([ViewType.Student, ViewType.Teacher]);

    // function printPage(): void {
    //     window.print();
    //     sendYandexMetrika(CommonTargetName.schedule_print);
    // }

    return (
        <header className={classes.headerSchedule}>
            <DateSelect />

            <UnitOfTimeSelect />

            {/* <button
                type="button"
                className={classes.printerButton}
                onClick={printPage}
            >
                <svg width="28" height="26" viewBox="0 0 28 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M8.61518 19.3868V21.5377H19.384V19.3868H8.61518ZM8.61518
                    15.7972V17.9481H19.384V15.7972H8.61518ZM2.15386 17.9481H4.30763V23.6929C4.30045
                    25.4375 4.70105 25.8453 6.4614 25.8453H21.5378C23.2982 25.8453 23.6916 25.5208
                    23.6916 23.6929V17.9481H25.8454C27.6761 17.9768 28.02 17.5698 27.9991
                    15.7958V7.8986C27.9991 6.21148 27.7263 5.74339 25.8454
                    5.74339H23.6916V2.15521C23.7124 0.381936 23.3685 0 21.5378 0H6.4614C4.73839
                    0 4.30045 0.410653 4.30763 2.15521V5.74339H2.15386C0.301615 5.74339 8.64101e-05
                    6.14687 8.64101e-05 7.8986V15.7958C-0.00709283 17.5403 0.430841 17.9768 2.15386
                    17.9481ZM21.5378 23.6915H6.4614V14.3585H21.5378V23.6915ZM22.8875 10.4113C22.8875
                    9.76521 23.4044 9.24902 24.0506 9.24902C24.6967 9.24902 25.2136 9.76521 25.2136
                    10.4113C25.2136 11.0575 24.6967 11.5744 24.0506 11.5744C23.4044 11.5744 22.8875
                    11.0575 22.8875 10.4113ZM6.4614 2.15377H21.5378V5.74627H6.4614V2.15377Z"
                    fill="url(#paint0_linear)" />
                    <defs>
                        <linearGradient
                        id="paint0_linear"
                        x1="30.0241"
                        y1="11.4504"
                        x2="0.271117"
                        y2="24.0327"
                        gradientUnits="userSpaceOnUse">
                            <stop stopColor="#0B42DC" />
                            <stop offset="0.0416667" stopColor="#0B42DC" />
                            <stop offset="0.130208" stopColor="#0B42DC" />
                            <stop offset="0.734375" stopColor="#57B2C6" />
                            <stop offset="0.932292" stopColor="#EED660" />
                        </linearGradient>
                    </defs>
                </svg>
            </button> */}
        </header>
    );
}
