import React, { useState } from 'react';
import cn from 'classnames';

import classes from './Spoiler.module.scss';

interface Props {
    isModuleitems?: boolean;
    title: string;
    list: (string | JSX.Element)[];
}

export const Spoiler = ({ title, list, isModuleitems = false }: Props): JSX.Element => {
    const [height, setHeight] = useState(true);

    return (
        <div className={classes.spoiler}>
            <div
                onClick={list.length > 0
                    ? () => setHeight(!height)
                    : () => null
                }
                className={classes.spoiler__tittle}
            >
                <div
                    className={cn(classes.spoiler__triangle, {
                        [classes.spoiler__staticTriangle]:
                            list.length > 0,
                        [classes.spoiler__triangle_active]: !height,
                    })}
                />
                {title}
            </div>
            <ul className={cn(classes.spoiler__list, {
                [classes.spoiler__module_list]: !isModuleitems,
                [classes.spoiler__list_height_null]: height,
            })}
            >
                {list.map((item: string | JSX.Element, index: number) => (
                    <li
                        // eslint-disable-next-line
                        key={index}
                        className={isModuleitems
                            ? classes.spoiler__item : classes.spoiler__item_module}
                    >
                        {item}
                    </li>
                ))}
            </ul>
        </div>
    );
};
