import React, {
    useEffect,
    useRef,
    useState,
} from 'react';
import { observer } from 'mobx-react';
import { ScheduleEventSectionApollo } from '../ScheduleEventSectionApollo';
import { NoteButton } from './NoteButton';
import { eventsStore } from '../../../../Store';
import { NoteArea } from './NoteArea';

import classes from './Note.module.scss';

export const Note = observer((): JSX.Element => {
    const refNoteForm = useRef<null | HTMLDivElement>(null);

    const { selectedEvent } = eventsStore;
    const [showNote, setShowNote] = useState<boolean>(false);
    const [eventNote, setEventNote] = useState<string | undefined>(undefined);

    const noteHeaderText = selectedEvent?.note === '' && !showNote ? 'Добавить заметку' : 'Редактировать заметку';
    const noteSaveText = showNote ? 'Сохранить заметку' : 'Редактировать заметку';
    const textAreaNoteText = showNote && selectedEvent?.note ? 'Добавить заметку' : 'Заметка';

    function addNewNote(note: string): void {
        setEventNote(note);
    }

    function toggleNoteForm(): void {
        setShowNote((prevShowNote) => !prevShowNote);
        if (selectedEvent) {
            setEventNote(selectedEvent.note);
        }
    }

    useEffect(() => {
        if (selectedEvent) {
            setEventNote(selectedEvent.note);
        }
    }, [selectedEvent]);

    return (
        <div className={classes.schedulePage_eventsSection_noteContainer} ref={refNoteForm}>
            {
                (selectedEvent && selectedEvent?.note && !showNote)
                && (
                    <>
                        <p className={classes.schedulePage_eventsSection_noteText}>Заметка</p>
                        <p className={classes.schedulePage_eventsSection_note}>
                            {selectedEvent?.note}
                        </p>
                    </>
                )
            }
            {
                (!showNote)
                && (
                    <NoteButton
                        onClick={toggleNoteForm}
                        text={noteHeaderText}
                        note={eventNote}
                        showNote={showNote}
                    />
                )
            }
            {
                (showNote)
                && (
                    <>
                        <div className={classes.schedulePage_eventsSection_addNote}>
                            <div className={classes.schedulePage_eventsSection_noteHeader}>
                                {
                                    showNote
                                    && (
                                        <button
                                            type="button"
                                            onClick={toggleNoteForm}
                                            className={classes.eventNote__btn}
                                        >
                                            {textAreaNoteText}
                                            <svg
                                                width="15"
                                                height="15"
                                                viewBox="0 0 15 15"
                                                fill="none"
                                                className={classes.svg__Image_active}
                                            >
                                                <path d="M14.25 6.75H8.25005V0.749954C8.25005 0.336044 7.914 0 7.49996 0C7.08605 0 6.75 0.336044 6.75 0.749954V6.75H0.749954C0.336044 6.75 0 7.08605 0 7.49996C0 7.914 0.336044 8.25005 0.749954 8.25005H6.75V14.25C6.75 14.664 7.08605 15 7.49996 15C7.914 15 8.25005 14.664 8.25005 14.25V8.25005H14.25C14.664 8.25005 15 7.914 15 7.49996C15 7.08605 14.664 6.75 14.25 6.75Z" fill="url(#close-note-button)" />
                                                <defs>
                                                    <linearGradient id="close-note-button" x1="16.0844" y1="6.64559" x2="-0.220819" y2="13.0103" gradientUnits="userSpaceOnUse">
                                                        <stop offset="13%" stopColor="#0B42DC" />
                                                        <stop offset="73%" stopColor="#57B2C6" />
                                                        <stop offset="93%" stopColor="#EED660" />
                                                    </linearGradient>
                                                </defs>
                                            </svg>
                                        </button>
                                    )
                                }
                            </div>
                            <NoteArea
                                eventNote={eventNote || ''}
                                addNewNote={addNewNote}
                            />
                            {
                                (selectedEvent) && (
                                    <ScheduleEventSectionApollo
                                        apolloData={eventNote?.trim() ?? ''}
                                        showNote={showNote}
                                        click={toggleNoteForm}
                                    >
                                        {noteSaveText}
                                    </ScheduleEventSectionApollo>
                                )
                            }
                        </div>
                    </>
                )
            }
        </div>
    );
});
