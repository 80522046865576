import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';

import cn from 'classnames';
import classes from './NavigationItems.module.scss';

import { UserRole } from '../../../deprecated-graphql-query-types';
import UserView from '../../../store/UserView';

interface Props {
    availableUserRoles: UserRole[];
}

export function NavigationChangeRole({ availableUserRoles }: Props): JSX.Element {
    const [viewRoles, setViewRoles] = useState(false);
    return (
        <>
            <div
                className={cn(
                    classes.menuNavigation__item,
                    classes.menuNavigation__item_margin_zero,
                )}
                onClick={() => setViewRoles(!viewRoles)}
            >
                Сменить роль
            </div>
            {viewRoles && (
                <ul className={classes.menuNavigation__roleList}>
                    {availableUserRoles.map((viewType: UserRole) => (
                        <li
                            key={viewType.roleId}
                            className={cn(classes.menuNavigation__roleItem, {
                                [classes.menuNavigation__roleItem_color_black]:
                                UserView.userViewType?.name === viewType.name,
                            })}
                        >
                            <NavLink
                                to="/"
                                onClick={() => UserView.setUserViewType(viewType)}
                            >
                                {viewType.text}
                            </NavLink>
                        </li>
                    ))}
                </ul>
            )}
        </>
    );
}
