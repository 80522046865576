import { makeAutoObservable } from 'mobx';
import { accountStore } from '../../Store';

import {
    ScheduleEvent,
    TimeReservingEvent,
    MeetingInstance,
    EvaluationPointInstance,
    StudentAssignmentInstance,
    AssignmentInstanceTyped,
    TeacherFlat,
    StudentFlat,
    FilterType,
    MeetingInstanceTyped,
    EvaluationPointInstanceTyped,
} from '../Interfaces';
// eslint-disable-next-line import/no-cycle
import { evaluationStore } from '../ScheduleEventBox/ScheduleEvent/ScheduleEventSection/Evaluation/Store';

interface FilterDescription {
    [key: string]: string;
}

export const filterDescription: FilterDescription = {
    [FilterType.ALL]: 'Все',
    [FilterType.MEETING]: 'Встреча',
    [FilterType.EVALUATION_POINT]: 'Оценка',
    [FilterType.ASSIGNMENT]: 'Сам. работа',
};

class EventsStore {
    meetingInstances: MeetingInstance[] = [];

    evaluationPointInstances: EvaluationPointInstance[] = [];

    assignmentInstances: StudentAssignmentInstance[] = [];

    selectedEvent?: ScheduleEvent;

    filterType: FilterType = FilterType.ALL;

    toggleAssignmentList: boolean = false;

    fillterComplitedAssignmets = true;

    constructor() {
        makeAutoObservable(this);
    }

    setMeetingInstances = (meetingInstances: MeetingInstance[]): void => {
        this.meetingInstances = meetingInstances;
    };

    setEvaluationPointInstances = (
        evaluationPointInstances: EvaluationPointInstance[],
    ): void => {
        // TODO undo this shitty fix
        const { teacherId } = accountStore;
        this.evaluationPointInstances = evaluationPointInstances.map(evaluationPointInstance => {
            const evaluationPointToTeacher = evaluationPointInstance
                .evaluationPointInstanceToTeachers.find(({ teacher }) => teacher.id === teacherId);
            return {
                ...evaluationPointInstance,
                note: evaluationPointToTeacher?.note ?? '',
                isGraded: evaluationPointToTeacher?.isGraded ?? false,
                evaluationPointInstanceToStudents: evaluationPointInstance
                    .evaluationPointInstanceToStudents.map(studentRelation => {
                        const teacherNote = studentRelation.teacherNotes
                            .find(({ teacher }) => teacher.id === teacherId);
                        const status = evaluationPointInstance.evaluationResults.some(
                            ({ student, teacher }) => student.id === studentRelation.student.id
                                && teacher.id === evaluationPointToTeacher?.teacher.id,
                        ) || !!teacherNote;
                        return {
                            ...studentRelation,
                            status,
                            note: teacherNote?.note ?? '',
                        };
                    }),
            };
        });
    };

    setAssignmentInstances = (assignmentInstances: StudentAssignmentInstance[]): void => {
        this.assignmentInstances = assignmentInstances
            ?.map(assignmentInstance => ({
                ...assignmentInstance,
                note: assignmentInstance.note,
            })) ?? [];
    };

    selectEvent = (selectedEvent?: ScheduleEvent): void => {
        this.unselectEvent();
        this.selectedEvent = selectedEvent;
        evaluationStore.setupStudents();
    };

    selectEventById = (selectedEventId: string): void => {
        this.selectedEvent = this.getScheduleEvents()
            .find(
                (scheduleEvent) => {
                    if (this.isAssignment(scheduleEvent)) {
                        return scheduleEvent.assignmentInstance.id === selectedEventId;
                    }
                    return scheduleEvent.id === selectedEventId;
                },
            );
        evaluationStore.setupStudents();
    };

    unselectEvent = (): void => {
        this.selectedEvent = undefined;
    };

    isEventSelected = (scheduleEvent: ScheduleEvent): boolean => {
        if (this.isAssignment(scheduleEvent) && (this.isAssignment(this.selectedEvent))) {
            return scheduleEvent.assignmentInstance.id === this.selectedEvent.assignmentInstance.id;
        }
        if (!this.isAssignment(scheduleEvent) && (!this.isAssignment(this.selectedEvent))) {
            return scheduleEvent.id === this.selectedEvent?.id;
        }
        return false;
    };

    isAssignment = (
        scheduleEventInstance?: ScheduleEvent,
    ): scheduleEventInstance is AssignmentInstanceTyped => {
        if (!scheduleEventInstance) {
            return false;
        }
        return scheduleEventInstance?.type === FilterType.ASSIGNMENT;
    };

    setFilterType = (filterType: FilterType): void => {
        this.filterType = filterType;
    };

    setFillterCompletedAssignments = () => {
        this.fillterComplitedAssignmets = !this.fillterComplitedAssignmets;
    };

    getFillterCompletedAssignments = () => this.fillterComplitedAssignmets;

    private getScheduleEvents = (): ScheduleEvent[] => (
        [
            ...this.getTimeReservingEvents(),
            ...this.getAssignmentInstances(),
        ]
    );

    getTimeReservingEvents = () => this.filterEvents([
        ...this.meetingInstances.map(
            (meetingInstance) => ({
                ...meetingInstance,
                startDate: new Date(meetingInstance.startDate),
                endDate: new Date(meetingInstance.endDate),
                type: FilterType.MEETING,
            }),
        ) as MeetingInstanceTyped[],
        ...this.evaluationPointInstances.map(
            (evaluationPointInstance) => ({
                ...evaluationPointInstance,
                startDate: new Date(evaluationPointInstance.startDate),
                endDate: new Date(evaluationPointInstance.endDate),
                type: FilterType.EVALUATION_POINT,
            }),
        ) as EvaluationPointInstanceTyped[],
    ]) as TimeReservingEvent[];

    getAssignmentInstances = () => (
        this.filterEvents(this.assignmentInstances.map(
            (studentAssignmentInstance) => ({
                ...studentAssignmentInstance,
                start: new Date(studentAssignmentInstance.assignmentInstance.start),
                deadline: new Date(studentAssignmentInstance.assignmentInstance.deadline),
                type: FilterType.ASSIGNMENT,
            }),
        ))
    ) as AssignmentInstanceTyped[];

    private filterEvents = (
        scheduleEvents: ScheduleEvent[],
    ): ScheduleEvent[] => {
        if (this.filterType === FilterType.ALL) {
            return scheduleEvents;
        }

        return scheduleEvents.filter((scheduleEvent) => (
            scheduleEvent.type === this.filterType
        ));
    };

    getSortedTeachersList = (): TeacherFlat[] => {
        if (this.selectedEvent
            && this.selectedEvent.type === FilterType.MEETING) {
            return this.selectedEvent.meetingInstanceToTeachers
                .map((
                    {
                        teacher: {
                            id: userId,
                            user: {
                                avatar,
                                id,
                                firstName,
                                lastName,
                                patronymic,
                            },
                        },
                    },
                ) => ({
                    id,
                    userId,
                    avatar,
                    firstName,
                    lastName,
                    patronymic,
                }))
                .sort((a, b) => (b.id === accountStore.userId ? 1 : -1));
        }
        if (this.selectedEvent
            && this.selectedEvent.type === FilterType.EVALUATION_POINT) {
            return this.selectedEvent.evaluationPointInstanceToTeachers
                .map((
                    {
                        teacher: {
                            id: userId,
                            user: {
                                avatar,
                                id,
                                firstName,
                                lastName,
                                patronymic,
                            },
                        },
                    },
                ) => ({
                    id,
                    userId,
                    avatar,
                    firstName,
                    lastName,
                    patronymic,
                }))
                .sort((a, b) => (b.id === accountStore.userId ? 1 : -1));
        }
        return [];
    };

    getSortedStudentsList = (): StudentFlat[] => {
        if (!accountStore.isTeacher()
            && this.selectedEvent
            && this.selectedEvent.type === FilterType.MEETING
            && this.selectedEvent.meetingInstanceToStudents) {
            return (
                this.selectedEvent.meetingInstanceToStudents
                    ?.map(({ student: {
                        id,
                        user: {
                            id: userId,
                            firstName,
                            lastName,
                            patronymic,
                        },
                    } }) => ({
                        id,
                        userId,
                        firstName,
                        lastName,
                        patronymic,
                    }))
            );
        }
        return [];
    };

    showAssignmentsList = (toggle: boolean) => {
        this.toggleAssignmentList = toggle;
    };

    isMultipleTeachersEvaluate = (): boolean => (
        (this.selectedEvent?.type === FilterType.EVALUATION_POINT)
        && (this.selectedEvent.evaluationPointInstanceToTeachers.length > 1)
    );

    getSelectedEventId = () => {
        if (this.isAssignment(this.selectedEvent)) {
            return this.selectedEvent?.assignmentInstance.id;
        }
        return this.selectedEvent?.id;
    };
}

// function isAssignment(event: ScheduleEvent): event is AssignmentInstanceTyped {
//     return event.type === FilterType.ASSIGNMENT;
// }

export const eventsStore = new EventsStore();
