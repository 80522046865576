import React, { useEffect } from 'react';
import { useQuery } from '@apollo/client';
import 'moment/locale/ru';

import { GET_CURRENT_USER, GET_SKILLS_TYPE } from '@common/qraphql/queries';
import { Loader } from '@common/Loader';
import { getCurrentUser } from '@common/qraphql/graphql-types';
import './normalize.css';
import './App.module.scss';
import App from './App';
import SkillType from './store/SkillType';
import UserView from './store/UserView';

function AppApollo(): JSX.Element {
    const isAuth = !!localStorage.getItem('token');

    const {
        data: userData,
        error: userError,
        loading: userLoading,
    } = useQuery<getCurrentUser>(GET_CURRENT_USER);

    const {
        data: skillTypesData,
        loading: skillTypesLoading,
        error: skillTypesError,
    } = useQuery(GET_SKILLS_TYPE, {
        skip: !isAuth,
        fetchPolicy: 'cache-first',
    });

    useEffect(() => {
        if (isAuth && userData?.getCurrentUser) {
            UserView.checkAndUpdateCurrentViewType(userData.getCurrentUser);
            UserView.setStudentId(userData.getCurrentUser.studentId);
            UserView.setAcceptedAgreement(userData.getCurrentUser.acceptedAgreement);

            if (UserView.setTeacherId) {
                UserView.setTeacherId(userData.getCurrentUser.teacherId);
            }
        }
    }, [userData, isAuth]);

    if (skillTypesData && !SkillType.skillTypes.length) {
        SkillType.setSkillTypes(skillTypesData.skillTypes);
    }

    if (skillTypesLoading || userLoading) return <Loader />;
    if (skillTypesError) return <>`Error! ${skillTypesError.message}`</>;

    if (userError) return <>`Error! ${userError.message}`</>;

    return <App />;
}

export default AppApollo;
