import React from 'react';
import {
    TeacherRole,
    TeachersMap,
} from '../../../OccupationDashboardInterfaces';
import classes from './RolesList.module.scss';
import { Role } from './Role';

interface Props {
    roles?: TeacherRole[];
    teachersMap: TeachersMap;
    index: number;
    isTUniversityMeetingsVisible: boolean;
}

const MODULE_ROLES_START_COLUMN_LINE = 2;
const MODULE_ROLES_END_COLUMN_LINE = 3;
const DIFF_BETWEEN_MODULE_INDEX_AND_START_ROW_LINE = 1;
const DIFF_BETWEEN_MODULE_INDEX_AND_END_ROW_LINE = 1 + DIFF_BETWEEN_MODULE_INDEX_AND_START_ROW_LINE;

// здесь задается вторая грид сетка для списка ролей в модуле
// состоит из двух колонок: название название роли в первой колонке
// и сетка со списком преподавателей во второй
export const RolesList = ({
    roles,
    teachersMap,
    index,
    isTUniversityMeetingsVisible,
}: Props): JSX.Element => (
    <div
        className={classes.rolesList}
        style={{
            gridColumnStart: MODULE_ROLES_START_COLUMN_LINE,
            gridColumnEnd: MODULE_ROLES_END_COLUMN_LINE,
            gridRowStart: index + DIFF_BETWEEN_MODULE_INDEX_AND_START_ROW_LINE,
            gridRowEnd: index + DIFF_BETWEEN_MODULE_INDEX_AND_END_ROW_LINE,
            display: 'grid',
            gridTemplateColumns: '180px 250px',
            gridTemplateRows: `repeat(${roles?.length || 0}, auto)`,
        }}
    >
        {
            roles?.map(
                (role, roleIndex) => (
                    <Role
                        key={role.id}
                        role={role}
                        teachersMap={teachersMap}
                        index={roleIndex}
                        isTUniversityMeetingsVisible={isTUniversityMeetingsVisible}
                    />
                ),
            )
        }
    </div>
);
