import React, { ReactNode } from 'react';
import cn from 'classnames';
import classes from './DiagramOperationButton.module.scss';

interface Props {
    className?: string;
    disabled?: boolean;
    children?: ReactNode;
    active?: boolean
    onClick(): void;
}

export function DiagramOperationButton({
    className,
    disabled = false,
    active = true,
    children,
    onClick,
}: Props): JSX.Element {
    return (
        <button
            disabled={disabled}
            className={cn(classes.diagramOperationButton, {
                [`${className}`]: className,
                [classes.diagramOperationButton_active]: active,
                [classes.diagramOperationButton_disabled]: disabled,
            })}
            type="button"
            onClick={onClick}
        >
            {children}
        </button>
    );
}
