import React, { useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { observer } from 'mobx-react';
import { Moment } from 'moment';

import { Loader } from '@common/Loader';
import { Alert } from '@common/Alert';
import {
    MeetingInstance,
    EvaluationPointInstance,
    StudentAssignmentInstance,
} from './Interfaces';
import { GET_MEETING_INSTANCES_BY_STUDENT, GET_MEETING_INSTANCES_BY_TEACHER } from './gql/meetingInstance';
import { GET_EVALUATION_POINT_INSTANCES } from './gql/evaluationPointInstance';
import { GET_ASSIGNMENT_INSTANCES } from './gql/assignmentInstance';
import { GET_CURRENT_USER } from '../gql/currentUser';
import { usePrevious } from './Hooks/usePrevious';
import { accountStore, UserType } from '../Store';
import { eventsStore, timeStore } from './Store';

import classes from './SchedulePage.module.scss';
import UserView from '../../store/UserView';
import { ViewType } from '../../deprecated-graphql-query-types';

enum AssignmentInstanceOrder {
    DEADLINEASC = 'deadlineAsc',
    DEADLINEDESC = 'deadlineDesc',
}

export const SchedulePageApollo = observer(() => {
    const { userViewType } = UserView;

    if (userViewType?.name === ViewType.Student) {
        accountStore.changeUserType(UserType.STUDENT);
    }

    if (userViewType?.name === ViewType.Teacher) {
        accountStore.changeUserType(UserType.TEACHER);
    }

    const {
        data: dataCurrentUser,
        loading: loadingCurrentUser,
        error: errorCurrentUser,
    } = useQuery(GET_CURRENT_USER, {
        fetchPolicy: 'cache-and-network',
        onCompleted: () => {
            accountStore.setUserId(dataCurrentUser.getCurrentUser.id);
            accountStore.setTeacherId(dataCurrentUser.getCurrentUser.teacherId);
            accountStore.setStudentId(dataCurrentUser.getCurrentUser.studentId);
        },
    });

    const prevSelectedDate = usePrevious<Moment>(timeStore.selectedDate);

    const {
        data: meetingInstancesTeacher,
        loading: loadingMeetingInstancesTeacher,
        error: errorMeetingInstancesTeacher,
        refetch: refetchMeetingInstancesTeacher,
    } = useQuery<{
        teacherMeetingInstances: { meetingInstance: MeetingInstance; note?: string; }[];
    }>(GET_MEETING_INSTANCES_BY_TEACHER, {
        skip: accountStore.isStudent() || !accountStore.userId,
        variables: {
            getMeetingInstanceInput: {
                // teacherId: accountStore.teacherId,
                fromDate: timeStore.getScheduleStart(),
                toDate: timeStore.getScheduleEnd(),
            },
        },
        fetchPolicy: 'cache-and-network',
        onCompleted: () => {
            if (meetingInstancesTeacher) {
                eventsStore.setMeetingInstances(
                    meetingInstancesTeacher.teacherMeetingInstances.map(item => ({
                        ...item.meetingInstance, note: item.note,
                    })),
                );
            }
        },
    });

    const {
        data: meetingInstancesStudent,
        loading: loadingMeetingInstancesStudent,
        error: errorMeetingInstancesStudent,
        refetch: refetchMeetingInstancesStudent,
    } = useQuery<{
        studentMeetingInstances: { meetingInstance: MeetingInstance; note?: string; }[];
    }>(GET_MEETING_INSTANCES_BY_STUDENT, {
        skip: accountStore.isTeacher() || !accountStore.userId,
        fetchPolicy: 'cache-and-network',
        variables: {
            getMeetingInstanceInput: {
                // studentId: accountStore.studentId,
                fromDate: timeStore.getScheduleStart(),
                toDate: timeStore.getScheduleEnd(),
            },
        },
        onCompleted: () => {
            if (meetingInstancesStudent) {
                eventsStore.setMeetingInstances(
                    meetingInstancesStudent.studentMeetingInstances.map(item => ({
                        ...item.meetingInstance, note: item.note,
                    })),
                );
            }
        },
    });

    const {
        data: evaluationPointInstancesData,
        loading: loadingEvaluationPointInstances,
        error: errorEvaluationPointInstances,
        refetch: refetchEvaluationPointInstances,
    } = useQuery<{
        evaluationPointInstances: EvaluationPointInstance[];
    }>(GET_EVALUATION_POINT_INSTANCES, {
        skip: accountStore.isStudent() || !accountStore.userId,
        fetchPolicy: 'cache-and-network',
        variables: {
            getEvaluationPointInstanceInput: {
                teacherId: accountStore.teacherId,
                fromDate: timeStore.getScheduleStart(),
                toDate: timeStore.getScheduleEnd(),
            },
        },
        onCompleted: () => {
            if (evaluationPointInstancesData) {
                eventsStore.setEvaluationPointInstances(
                    evaluationPointInstancesData.evaluationPointInstances,
                );
            }
        },
    });

    const {
        data: assignmentInstancesData,
        loading: loadingAssignmentInstances,
        error: errorAssignmentInstances,
        refetch: refetchAssignmentInstances,
    } = useQuery<{
        filteredStudentAssignmentInstances: StudentAssignmentInstance[];
    }>(GET_ASSIGNMENT_INSTANCES, {
        skip: accountStore.isTeacher() || !accountStore.userId,
        fetchPolicy: 'network-only',
        variables: {
            assignmentInstanceInput: {
                // studentId: accountStore.studentId,
                fromDate: timeStore.getScheduleStart(),
                toDate: timeStore.getScheduleEnd(),
                isCompleted: eventsStore.getFillterCompletedAssignments() && null,
                orderBy: AssignmentInstanceOrder.DEADLINEASC,
            },
        },
        onCompleted: () => {
            if (assignmentInstancesData) {
                const { filteredStudentAssignmentInstances } = assignmentInstancesData;
                eventsStore.setAssignmentInstances(filteredStudentAssignmentInstances);
            }
        },
    });

    function requestScheduleEventsAsTeacher(): void {
        refetchMeetingInstancesTeacher();
        refetchEvaluationPointInstances();
    }

    function requestScheduleEventsAsStudent(): void {
        refetchMeetingInstancesStudent();
        refetchAssignmentInstances();
    }

    function requestScheduleEventsByUserType(): void {
        if (accountStore.isTeacher()) {
            requestScheduleEventsAsTeacher();
        } else {
            requestScheduleEventsAsStudent();
        }
    }

    function isEventsLoading(): boolean {
        return (
            loadingMeetingInstancesTeacher
            || loadingMeetingInstancesStudent
            || loadingEvaluationPointInstances
            || loadingAssignmentInstances
            || loadingCurrentUser
        );
    }

    useEffect(() => {
        if (accountStore.userId && timeStore.isTimePeriodChanged(prevSelectedDate)) {
            requestScheduleEventsByUserType();
        }
    }, [timeStore.selectedDate]);

    useEffect(() => {
        if (accountStore.userId) {
            requestScheduleEventsByUserType();
        }
    }, [timeStore.unitOfTime]);

    useEffect(() => {
        if (accountStore.userId) {
            requestScheduleEventsByUserType();
        }
    }, [accountStore.userType, accountStore.userId]);

    if (isEventsLoading()) {
        return (
            <div className={classes.loader}>
                <div className={classes.loader__wrapper}>
                    <Loader />
                </div>
            </div>
        );
    }

    if (errorMeetingInstancesTeacher || errorMeetingInstancesStudent) {
        return (
            <Alert
                message={errorMeetingInstancesTeacher?.message}
                time={2500}
            />
        );
    }
    if (errorEvaluationPointInstances) {
        return (
            <Alert
                message={errorEvaluationPointInstances?.message}
                time={2500}
            />
        );
    }
    if (errorAssignmentInstances) {
        return (
            <Alert
                message={errorAssignmentInstances?.message}
                time={2500}
            />
        );
    }
    if (errorCurrentUser) {
        return (
            <Alert
                message={errorCurrentUser?.message}
                time={2500}
            />
        );
    }

    return null;
});
