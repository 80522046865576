function getEllipseArcLength(radiusX: number, radiusY: number): number {
    return ((Math.PI * radiusX * radiusY + (radiusX - radiusY) * (radiusX - radiusY))
        / (radiusX + radiusY));
}

export const getArrowParams = ({
    eventListParams, mainElementParams, dependentElementParams, maxDays, inRow,
}: {
    eventListParams: DOMRect;
    mainElementParams: DOMRect;
    dependentElementParams: DOMRect;
    maxDays: number;
    inRow: boolean;
}) => {
    const widthArrows = 400;
    const arrowWidth = 4;
    const arrowHeight = 6.5;

    const { y: eventListOffset, height: eventListHeight } = eventListParams;
    const { y: mainYCoord, height: mainHeight } = mainElementParams;
    const { y: dependentYCoord, height: dependentHeight } = dependentElementParams;
    const mainYOffset = mainHeight / 2 + mainYCoord;
    const dependentYOffset = dependentHeight / 2 + dependentYCoord;
    const relativeMainOffset = mainYOffset - eventListOffset;
    const relativeDependentOffset = dependentYOffset - eventListOffset;
    const radius = Math.abs(dependentYOffset - mainYOffset) / 2
        + (dependentHeight / 8) * Math.sign(relativeMainOffset - relativeDependentOffset);
    const radiusX = Math.min(radius / 2, widthArrows - 15);
    const radiusY = radius;

    const yStart = Math.max(relativeMainOffset, relativeDependentOffset)
        + (dependentHeight / 8) * Math.sign(relativeMainOffset - relativeDependentOffset);
    const getLine = (xOffset: number) => `M ${widthArrows - xOffset} ${yStart} `
        + `a ${radiusX} ${radiusY} 0 1 1 0 -${2 * radiusY}`;
    const dLine = getLine(0);
    const dLineText = getLine(2);

    const lineLength = getEllipseArcLength(radiusX, radiusY) * 2;

    const dArrow = `M ${widthArrows - 1} ${relativeDependentOffset - dependentHeight / 8} `
        + `m -${arrowWidth} -${arrowHeight} `
        + `l ${arrowWidth} ${arrowHeight} `
        + `l -${arrowWidth} ${arrowHeight} `;

    const dText = inRow ? 'встык' : `${maxDays} `
        + `${maxDays % 10 === 1 && (maxDays > 20 || maxDays < 10) ? 'день' : ''}`
        + `${maxDays % 10 >= 2 && maxDays % 10 <= 4 && (maxDays > 20 || maxDays < 10) ? 'дня' : ''}`
        + `${maxDays % 10 >= 5 || maxDays % 10 === 0 || (maxDays <= 20 && maxDays >= 10) ? 'дней' : ''}`;

    return {
        widthArrows, eventListHeight, dLine, dArrow, dText, dLineText, lineLength,
    };
};
