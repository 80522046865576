import React, { useEffect, useState } from 'react';

import { EducationPeriodDayScheduleEdit } from '@admin/EducationPeriodPage/EducationPeriodDaySHeduleEdit';
import { educationPeriodStore } from '@admin/EducationPeriodPage/EducationPeriodStore';
import { CalendarWithTime } from '@admin/EducationPeriodPage/CalendarWithTime';
import cn from 'classnames';
import { getBTIParameters } from '@admin/EducationPeriodPage/EducationPeriodDates';
import { nanoid } from 'nanoid';
import { IntervalWithWeek, Mode, Space, SpaceEducationPeriod } from '../../EducationPeriodPageInterfaces';
import { EducationPeriodDatesAccordion } from './EducationPeriodDatesAccordion';

import classes from './EducationPeriodDatesEdit.module.scss';

interface Props {
    currentPeriod?: SpaceEducationPeriod;
    space: Space;
    intervalsWithWeek: IntervalWithWeek[];
    dateMode: Mode;
}

export function EducationPeriodDatesEdit({
    currentPeriod,
    space,
    intervalsWithWeek,
    dateMode,
}: Props) {
    const currentPeriodDates = getCurrentPeriodDates(currentPeriod?.name ?? '', dateMode);

    const [isPeriodStartEditing, setPeriodStartEditing] = useState(false);
    const [isPeriodEndEditing, setPeriodEndEditing] = useState(false);
    const [newPeriodDates, setNewPeriodDates] = useState<{ start: string, end: string }>({
        start: currentPeriodDates[0] ?? `${new Date().getFullYear()}`,
        end: currentPeriodDates[1] ?? `${new Date().getFullYear()}`,
    });

    useEffect(() => {
        educationPeriodStore.setUpdateBTIParametersData(
            {
                educationPeriodId: dateMode === Mode.CREATE ? nanoid(21) : currentPeriod?.id ?? '',
                spaceId: space.id,
                name: `${newPeriodDates.start}-${newPeriodDates.end}`,
                spaceBaseTimeIntervalParameters: dateMode === Mode.CREATE
                    ? []
                    : currentPeriod?.spaceBaseTimeIntervalParameters ?? [],
            },
        );
    }, [newPeriodDates, dateMode]);

    return (
        <div className={classes.EducationPeriodDates}>

            <div className={classes.EducationPeriodDates__header}>

                <div className={classes.EducationPeriodDates__header__item}>
                    Название
                </div>

                <div className={classes.EducationPeriodDates__header__item}>
                    Дата начала
                </div>

                <div className={classes.EducationPeriodDates__header__item}>
                    Дата завершения
                </div>

            </div>

            <div className={classes.EducationPeriodDates__dates}>
                <div className={classes.EducationPeriodDates__accordions}>

                    <div
                        className={cn(classes.EducationPeriodDates__period, {
                            [classes.EducationPeriodDates__period_active]:
                            isPeriodStartEditing || isPeriodEndEditing,
                        })}
                    >

                        <div
                            className={cn(classes.EducationPeriodDates__period_title, {
                                [classes.noBorder]:
                                isPeriodEndEditing || isPeriodStartEditing,
                            })}
                        >
                            Укажите года учебного периода
                        </div>

                        <div
                            className={cn({
                                [classes.EducationPeriodDates__start_active]:
                                isPeriodStartEditing,
                                [classes.noBorder]:
                                isPeriodStartEditing || isPeriodEndEditing,
                                [classes.EducationPeriodDates__start]: !isPeriodStartEditing,
                            })}
                            onClick={() => setPeriodStartEditing(true)}
                        >
                            {
                                // eslint-disable-next-line no-nested-ternary
                                isPeriodStartEditing ? (
                                    <CalendarWithTime
                                        onChangeCalendar={(date) => {
                                            if (date.getFullYear()
                                                > new Date(newPeriodDates.end).getFullYear()) {
                                                educationPeriodStore.setIsUpdateError(true);
                                            } else {
                                                educationPeriodStore.setIsUpdateError(false);
                                            }
                                            setNewPeriodDates({
                                                ...newPeriodDates,
                                                start: `${date.getFullYear()}`,
                                            });
                                        }}
                                        currentDate={
                                            newPeriodDates.start
                                                ? new Date(newPeriodDates.start) : new Date()
                                        }
                                        autoFocus
                                        onCloseCalendar={() => setPeriodStartEditing(false)}
                                        showYearPicker
                                        dateFormat="yyyy"
                                    />
                                ) : newPeriodDates.start
                                    ? newPeriodDates.start
                                    : new Date().getFullYear()
                            }
                        </div>

                        <div
                            className={cn({
                                [classes.EducationPeriodDates__end_active]: isPeriodEndEditing,
                                [classes.EducationPeriodDates__end]: !isPeriodEndEditing,
                                [classes.EducationPeriodDates__start_noBorder]:
                                isPeriodEndEditing || isPeriodStartEditing,
                            })}
                            onClick={() => setPeriodEndEditing(true)}
                        >
                            {
                                // eslint-disable-next-line no-nested-ternary
                                isPeriodEndEditing ? (
                                    <CalendarWithTime
                                        onChangeCalendar={(date) => {
                                            if (date.getFullYear()
                                                < new Date(newPeriodDates.start).getFullYear()) {
                                                educationPeriodStore.setIsUpdateError(true);
                                            } else {
                                                educationPeriodStore.setIsUpdateError(false);
                                            }
                                            setNewPeriodDates({
                                                ...newPeriodDates,
                                                end: `${date.getFullYear()}`,
                                            });
                                        }}
                                        currentDate={
                                            newPeriodDates.end
                                                ? new Date(newPeriodDates.end) : new Date()
                                        }
                                        autoFocus
                                        onCloseCalendar={() => setPeriodEndEditing(false)}
                                        showYearPicker
                                        dateFormat="yyyy"
                                    />
                                ) : newPeriodDates.end
                                    ? newPeriodDates.end
                                    : new Date().getFullYear()
                            }
                        </div>

                    </div>

                    {
                        space.spaceBaseTimeIntervals.map((interval) => (
                            <EducationPeriodDatesAccordion
                                key={interval.id}
                                name={space.baseTimeIntervalType.name}
                                baseTimeInterval={interval}
                                parameters={
                                    dateMode === Mode.CREATE ? undefined
                                        : getBTIParameters(
                                            interval.id,
                                            currentPeriod?.id ?? '',
                                            space.spaceEducationPeriods,
                                        )
                                }
                            />
                        ))
                    }
                </div>

                <EducationPeriodDayScheduleEdit
                    spaceParameters={space.spaceParameters}
                    intervalsWithWeek={intervalsWithWeek}
                />
            </div>

        </div>
    );
}

function getCurrentPeriodDates(oldName: string, dateMode: Mode) {
    const currentPeriodDates = oldName.split('-');
    // not equal to 9 for old university bti names
    if (currentPeriodDates.length !== 2 || dateMode === Mode.CREATE || oldName.length !== 9) {
        return [];
    }
    return currentPeriodDates;
}
