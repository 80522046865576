/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { MouseEvent } from 'react';

import classes from './Option.module.scss';

interface Props {
    name: string,
    onClick(event: MouseEvent<HTMLDivElement>): void,
}

export const Option = ({ name, onClick } : Props): JSX.Element => (
    <div
        className={classes.option}
        onClick={onClick}
    >
        {name}
    </div>
);
