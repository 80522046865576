import React from 'react';
import cn from 'classnames';

import { UnitOfTime } from '../../../../Store';

import classes from './UnitOfTimeButtonMobile.module.scss';

interface Props {
    selectedUnitOfTime: UnitOfTime;
    unitOfTime: UnitOfTime;
    date: string;
    changeUnitOfTimeAndClose(unitOfTime: UnitOfTime): void
}

export function UnitOfTimeButtonMobile({
    selectedUnitOfTime,
    unitOfTime,
    date,
    changeUnitOfTimeAndClose,
}: Props): JSX.Element {
    return (
        <div className={classes.unitOfTimeButtonMobileContainer}>
            <button
                className={cn(
                    classes.unitOfTimeButtonMobile, {
                        [classes.unitOfTimeButtonMobile_selected]:
                            selectedUnitOfTime === unitOfTime,
                    },
                )}
                type="button"
                value={unitOfTime}
                onClick={() => changeUnitOfTimeAndClose(unitOfTime)}
            >
                {date}
            </button>
        </div>
    );
}
