import React, { ReactNode, useEffect, useState } from 'react';
import cn from 'classnames';
import { saveAs } from 'file-saver';
import { Alert } from '@common/Alert';
import { Loader, LoaderSizes } from '@common/Loader';
import { ActionTypeOfButton } from '@common/ActionButton';
/* eslint-disable import/no-cycle */
import { ActionButtonRedesign } from '@common/ActionButtonRedesign';
import { RefetchGetSpaces } from '@admin/EducationPeriodPage/EducationPeriodPageInterfaces';
import { useHistory } from 'react-router-dom';
import { buttonHooks, ButtonName } from './buttonHooks';

import classes from './SelectionPointButtonRedesign.module.scss';

interface Props {
    buttonName: ButtonName;
    BTIId: string;
    buttonActionType?: ActionTypeOfButton;
    selectionClassName?: string;
    disabled?: boolean;
    buttonText?: string;
    children?: ReactNode;
    navLink?: string;
    refetchGetSpaces?: RefetchGetSpaces;
    beforeRequestAction?: () => void;
    afterRequestAction?: () => void;
}

export const SelectionPointButtonRedesign = ({
    buttonName,
    buttonActionType,
    buttonText,
    BTIId,
    navLink,
    children,
    refetchGetSpaces,
    selectionClassName,
    beforeRequestAction,
    afterRequestAction,
    disabled,
}: Props) => {
    const [file, setFile] = useState<{ value: string, name: string }>({ value: '', name: '' });
    const {
        error,
        loading,
        ...handlers
    } = buttonHooks(BTIId, setFile);

    const history = useHistory();

    useEffect(() => {
        if (file.value) {
            const blob = new Blob([file.value], {
                type: 'text/csv;charset=utf-8',
            });
            saveAs(blob, `${file.name}`);
        }
    }, [file.value, file.name]);

    return (
        <div
            className={classes.section_button}
        >
            {!loading ? (
                <>
                    {
                        buttonName !== ButtonName.NOT_ACTIVE && (
                            <ActionButtonRedesign
                                actionType={buttonActionType || ActionTypeOfButton.PRIMARY}
                                onClick={async () => {
                                    await handlers[buttonName]();
                                    if (beforeRequestAction) {
                                        beforeRequestAction();
                                    }
                                    if (refetchGetSpaces) {
                                        await refetchGetSpaces();
                                    }
                                    if (navLink) {
                                        history.push(navLink);
                                    }
                                    if (afterRequestAction) {
                                        await afterRequestAction();
                                    }
                                }}
                                className={cn(classes.selection_button, selectionClassName)}
                                disabled={disabled}
                            >
                                {
                                    children || (buttonText || buttonName)
                                }
                            </ActionButtonRedesign>
                        )
                    }
                </>
            ) : (<Loader size={LoaderSizes.small} />)}
            {error && (
                <Alert
                    message={error.message}
                    time={8000}
                />
            )}
        </div>
    );
};
