import React, { useEffect, useState } from 'react';
import moment, { Moment } from 'moment';
import { observer } from 'mobx-react';

import { timeStore, UnitOfTime } from '../../Store';

import classes from './TimePastLine.module.scss';

const MINS_IN_DAY = 24 * 60;
const TIME_PAST_LINE_UPDATE_TIME_IN_MS = 1000;

export const TimePastLine = observer((): JSX.Element | null => {
    const [
        offsetTopTimePastLineInPercents,
        setOffsetTopTimePastLineInPercents,
    ] = useState<number>(getNewOffsetTopTimePastLineInPercents());

    useEffect(() => {
        const timePastLineTimer = setInterval(() => {
            setOffsetTopTimePastLineInPercents(getNewOffsetTopTimePastLineInPercents());
        }, TIME_PAST_LINE_UPDATE_TIME_IN_MS);

        return function clearTimer(): void {
            clearInterval(timePastLineTimer);
        };
    });

    function getNewOffsetTopTimePastLineInPercents(): number {
        const now: Moment = moment();
        const minsElapsedFromMidnight = now.hours() * 60 + now.minutes();

        return (minsElapsedFromMidnight / MINS_IN_DAY) * 100;
    }

    function getWeekTimePastLineWidthInPercents(): number {
        return ((moment().weekday() + 1) / 7) * 100;
    }

    if (timeStore.unitOfTime === UnitOfTime.WEEK && timeStore.isSelectedDateIsCurrentWeek()) {
        return (
            <div
                className={classes.timePastLine}
                style={{
                    width: `${getWeekTimePastLineWidthInPercents()}%`,
                    top: `${offsetTopTimePastLineInPercents}%`,
                }}
            />
        );
    }

    if (timeStore.unitOfTime === UnitOfTime.DAY && timeStore.isSelectedDateIsCurrentDay()) {
        return (
            <div
                className={classes.timePastLine}
                style={{
                    top: `${offsetTopTimePastLineInPercents}%`,
                }}
            />
        );
    }

    return null;
});
