import React from 'react';
import { observer } from 'mobx-react-lite';
import cn from 'classnames';
import Diagram from '../../store/Diagram';
import classes from './SVGArrow.module.scss';

interface Props {
    isRemoveSlotsArrow?: boolean
    id: string
    startPoint: [number, number]
    endPoint: [number, number]
    color?: string
}

export const SVGArrow = observer(({
    isRemoveSlotsArrow,
    id,
    startPoint,
    endPoint,
    color,
}: Props): JSX.Element => {
    const { diagramSettings } = Diagram;
    const { removeDiagramEntityMode } = diagramSettings;
    const [x1, y1] = startPoint;
    const [x2, y2] = endPoint;
    return (
        <svg
            className={cn(classes.SVGArrow, {
                [classes.SVGArrow_active]: removeDiagramEntityMode,
            })}
            fill={removeDiagramEntityMode ? '#FF0000' : `${color || '#000000'}`}
            cursor={removeDiagramEntityMode ? 'pointer' : 'auto'}
            onClick={() => {
                if (removeDiagramEntityMode && isRemoveSlotsArrow) {
                    Diagram.removeEntityRelation(id);
                }
            }}
        >
            <defs>
                <marker
                    id={id}
                    orient="auto"
                    markerWidth="3"
                    markerHeight="4"
                    refX="1.5"
                    refY="2"
                >
                    <path
                        d="M0,0 V4 L2,2 Z"

                    />
                </marker>
            </defs>

            <line
                x1={x1}
                y1={y1}
                x2={x2}
                y2={y2}
                id="arrow-line"
                markerEnd={`url(#${id})`}
                strokeWidth="2"
                fill="none"
                stroke={removeDiagramEntityMode ? '#FF0000' : `${color || '#000000'}`}
            />
        </svg>
    );
});
