export function downloadJson(filename: string, data: Object): void {
    const blob = new Blob([JSON.stringify(data)], { type: 'text/json' });
    const link = document.createElement('a');

    link.download = filename;
    link.href = window.URL.createObjectURL(blob);
    link.dataset.downloadurl = ['text/json', link.download, link.href].join(':');

    const event = new MouseEvent('click', {
        view: window,
        bubbles: true,
        cancelable: true,
    });

    link.dispatchEvent(event);
    link.remove();
}

export const makeUrl = (id: string): string => {
    const pathname = document.location.pathname.replace(id, '');
    return pathname.endsWith('/') ? `${pathname}${id}` : `${pathname}/${id}`;
};

export enum EventStatus {
    DELETED = 'deleted',
    ACTIVE = 'active',
    GENERATION = 'generation',
}

export enum EventType {
    Meeting = 'meeting',
    Assignment = 'assignment',
    EvaluationPoint = 'evaluationPoint',
}
