import { gql } from '@apollo/client';

export const GET_MEETING_INSTANCES_BY_TEACHER = gql`
query getMeetingInstancesByTeacher($getMeetingInstanceInput: TeacherMeetingInstancesInput!) {
    teacherMeetingInstances(teacherMeetingInstancesInput: $getMeetingInstanceInput) {
        meetingInstance {
            id
            startDate
            endDate
            waves {
                index
            }
            meeting {
                id
                topic
                format {
                    name
                }
                module {
                    name
                }
            }
            room {
                id
                title
                location {
                    location
                }
            }
            onlineRoom {
                id
                url
            }
            roomType
            meetingInstanceToTeachers {
                teacher {
                    id
                    user {
                        id
                        lastName
                        firstName
                        patronymic
                    }
                }
            }
            meetingInstanceToStudents {
                student {
                    id
                    user {
                        id
                        lastName
                        firstName
                        patronymic
                        isAdmin
                    }
                }
                attendance
            }
        }
        note
    }
}
`;

export const GET_MEETING_INSTANCES_BY_STUDENT = gql`
query getMeetingInstancesByStudent($getMeetingInstanceInput: StudentMeetingInstancesInput!) {
    studentMeetingInstances(studentMeetingInstancesInput: $getMeetingInstanceInput) {
        meetingInstance {
            id
            startDate
            endDate
            meeting {
                id
                topic
                format {
                    name
                }
                module {
                    name
                }
            }
            room {
                id
                title
                location {
                    location
                }
            }
            onlineRoom {
                id
                url
            }
            roomType
            meetingInstanceToTeachers {
                teacher {
                    id
                    user {
                        id
                        lastName
                        firstName
                        patronymic
                    }
                }
            }
            meetingInstanceToStudents {
                student {
                    id
                    user {
                        firstName
                        lastName
                        patronymic
                    }
                }
            }
            waves {
                index
            }
        }
        note
    }
}
`;

export const EDIT_MEETING_INSTANCE_NOTE_BY_STUDENT = gql`
mutation updateMeetingInstanceByStudent($data: UpdateMeetingInstanceStudentNoteInput!) {
    updateMeetingInstanceStudentNote(updateMeetingInstanceStudentNoteInput: $data) {
        id
    }
}
`;

export const EDIT_MEETING_INSTANCE_NOTE_BY_TEACHER = gql`
    mutation updateTeacherAssignmentInstanceNoteInput($data: UpdateMeetingInstanceTeacherNoteInput!) {
        updateMeetingInstanceTeacherNote(updateMeetingInstanceTeacherNoteInput: $data) {
            id
        }
    }
`;

export const EDIT_STUDENT_MEETING_ATTENDANCE = gql`
mutation updateStudentMeetingAttendance($data: UpdateStudentAttendancesInput!) {
    updateMeetingInstanceStudentAttendances(updateStudentAttendancesInput: $data) {
        id
    }
}
`;
