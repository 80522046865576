import React from 'react';
import { range } from 'lodash';

import classes from './LevelSelect.module.scss';

interface Props {
    isLevelIncreased: boolean;
    maxLevel: number;
    selectedLevel: number;
    handleSelectSkillLevel(
        event: React.ChangeEvent<HTMLSelectElement> | React.ChangeEvent<HTMLInputElement>,
    ): void;
}

export function LevelSelect({
    isLevelIncreased,
    maxLevel,
    selectedLevel,
    handleSelectSkillLevel,
}: Props): JSX.Element {
    return (
        <div className={classes.levelSelect}>
            {isLevelIncreased
                ? (
                    <label className={classes.skillLevel}>
                        Уровень:
                        <select
                            name="level"
                            className={classes.skillLevel__select}
                            value={selectedLevel}
                            onChange={handleSelectSkillLevel}
                        >
                            {range(0, maxLevel + 1)
                                .map(value => (
                                    <option key={value} value={value}>
                                        {value}
                                    </option>
                                ))
                            }
                        </select>
                    </label>
                ) : (
                    <label className={classes.skillLevel}>
                        Уровень:
                        <input
                            type="checkbox"
                            name="level"
                            className={classes.skillLevel__checkbox}
                            checked={!!selectedLevel}
                            onChange={handleSelectSkillLevel}
                        />
                    </label>
                )}
        </div>
    );
}
