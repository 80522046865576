import React from 'react';
import { BorderPlusIcon, BorderCrossIcon } from '@common/svg';
import { GradientText } from '@common/GradientText';

import classes from './UniversityPage.module.scss';

interface Props {
    text: string;
    removeButton?: boolean;
    marginTop?: number;
    handler: () => void;
}

export function AddNewBuild({ text, removeButton = false, marginTop, handler }: Props) {
    return (
        <div
            onClick={handler}
            className={classes.addNewBuild}
            style={{ marginTop: `${marginTop}px` }}
        >
            {removeButton ? <BorderCrossIcon /> : <BorderPlusIcon />}
            {/* <div className={classes.addNewBuild__text}>
                {text}
            </div> */}
            <GradientText
                text={text}
                className={classes.addNewBuild__text}
            />
        </div>
    );
}
