import React from 'react';
import { Link, useLocation } from 'react-router-dom';

import { MultiAutosuggest } from '@common/MultiAutosuggest';
import { ArrowSvg } from '@admin/ScheduleGenerationPage/FiltrationComponents/ArrowSvg';
import {
    GetSpaceActiveBTIs_spaceBaseTimeIntervalInstances_modules,
    GetSpaceActiveBTIs_spaceBaseTimeIntervalInstances_spaceBaseTimeInterval,
    GetSpaceActiveBTIs_spaceBaseTimeIntervalInstances_spaceEducationPeriod,
} from '../graphql-types';

import classes from './SpaceModules.module.scss';

interface Option {
    id: string;
    name: string;
}

interface Module {
    spaceBaseTimeInterval:
    GetSpaceActiveBTIs_spaceBaseTimeIntervalInstances_spaceBaseTimeInterval;
    spaceEducationPeriod: GetSpaceActiveBTIs_spaceBaseTimeIntervalInstances_spaceEducationPeriod;
    module: GetSpaceActiveBTIs_spaceBaseTimeIntervalInstances_modules;
    baseTimeIntervalInstanceId: string;
}

interface Props {
    modules: Module[];
    moduleFilterOptions: Option[];
    btiFilterOptions: Option[];
    selectedModules: Option[];
    selectedBTIs: Option[];
    formatOption(option: Option): string;
    onModuleFilterChange(selectrdOptions: Option[]): void;
    onBTIFilterChange(selectrdOptions: Option[]): void;
    resetFilters(): void;
}

export function SpaceModules({
    modules,
    moduleFilterOptions,
    btiFilterOptions,
    selectedModules,
    selectedBTIs,
    formatOption,
    onModuleFilterChange,
    onBTIFilterChange,
    resetFilters,
}: Props) {
    const { pathname } = useLocation();
    const isModulesFiltered = selectedBTIs.length !== 0 || selectedModules.length !== 0;

    return (
        <div className={classes['space-modules__container']}>
            <div className={classes['space-modules__filters']}>
                <div className={classes['space-modules-filter']}>
                    <MultiAutosuggest
                        placeholder="Выберите модуль"
                        onChange={onModuleFilterChange}
                        options={moduleFilterOptions}
                        selectedOptions={selectedModules}
                        formatOption={formatOption}
                        isSuggestableOnFocus
                    />
                </div>
                <div className={classes['space-modules-filter']}>
                    <MultiAutosuggest
                        placeholder="Выберите учебный период"
                        onChange={onBTIFilterChange}
                        options={btiFilterOptions}
                        selectedOptions={selectedBTIs}
                        formatOption={formatOption}
                        isSuggestableOnFocus
                    />
                </div>
                {isModulesFiltered && (
                    <div className={classes['space-modules-filter-reset']} onClick={resetFilters}>
                        <ArrowSvg />
                        <p className={classes['space-modules-filter-reset__title']}>
                            Сбросить фильтры
                        </p>
                    </div>
                )}
            </div>
            {modules.length === 0 && (
                <span>
                    Нет активных модулей в текущих учебных периодах для выбранного пространства
                </span>
            )}
            {modules.map(({
                module,
                baseTimeIntervalInstanceId,
                spaceBaseTimeInterval,
                spaceEducationPeriod,
            }) => (
                <Link
                    key={`${baseTimeIntervalInstanceId}-${module.id}`}
                    to={`${pathname}/base-time-interval/${baseTimeIntervalInstanceId}/module/${module.id}`}
                    className={classes['space-module']}
                >
                    <div className={classes['space-module__semester']}>
                        {`${spaceBaseTimeInterval.order} ${spaceBaseTimeInterval.space.baseTimeIntervalType.name} ${spaceEducationPeriod.name}`}
                    </div>
                    <div className={classes['space-module__name']}>
                        {module.name}
                    </div>
                    <div className={classes['space-module__more-button']}>Подробнее</div>
                </Link>
            ))}
        </div>
    );
}
