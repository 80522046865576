import React, { useEffect, useState } from 'react';

import { useCreateScheduleGenerationMutation } from '@admin/ScheduleGenerationPage/graphql';
import { Tooltip } from '@common';
import { ActionButton } from '@common/ActionButton';
import { Alert } from '@common/Alert';
import { useBTISpaceEducationPeriodQuery } from '../graphql/useBTISpaceEducationPeriodQuery';
import { useBaseTimeIntervalInstancesLazyQuery } from '../graphql/useBaseTimeIntervalInstancesQuery';

import classes from './ScheduleGeneration.module.scss';

type Props = {
    baseTimeIntervalInstanceId: string;
    refetchProcesses(): void;
};

export function CreateScheduleGenerationForm({
    baseTimeIntervalInstanceId, refetchProcesses,
}: Props) {
    const [
        selectedBTIInstancesIds,
        setSelectedBTIInstancesIds,
    ] = useState([baseTimeIntervalInstanceId]);

    const {
        baseTimeIntervalInstance,
        error: getBTIError,
    } = useBTISpaceEducationPeriodQuery(baseTimeIntervalInstanceId);

    const {
        handler: getBTIInstancesForPeriod,
        baseTimeIntervalInstances,
        error: getBTIInstancesForPeriodError,
    } = useBaseTimeIntervalInstancesLazyQuery();

    useEffect(() => {
        if (baseTimeIntervalInstance) {
            const { endDate, startDate } = baseTimeIntervalInstance;
            getBTIInstancesForPeriod({
                variables: {
                    input: {
                        toDate: endDate,
                        fromDate: startDate,
                    },
                },
            });
        }
    }, [baseTimeIntervalInstance]);

    const [
        createScheduleGeneration,
        mutationError,
    ] = useCreateScheduleGenerationMutation(onCompleted);
    function onCompleted() {
        refetchProcesses();
        setOpen(false);
    }
    const [name, setName] = useState('');
    const [isOpen, setOpen] = useState(false);

    const hasBTIId = (id: string) => selectedBTIInstancesIds.includes(id);

    const onChangeSelectedBTI = (id: string) => {
        if (hasBTIId(id)) {
            setSelectedBTIInstancesIds((pre) => pre.filter((item) => item !== id));
        } else {
            setSelectedBTIInstancesIds((pre) => ([...pre, id]));
        }
    };

    const isNoCheckBTI = selectedBTIInstancesIds.length < 1;

    const error = getBTIError || getBTIInstancesForPeriodError || mutationError;
    return (
        <>
            {
                isOpen ? (
                    <>
                        <div className={classes.scheduleGenerationInfo__btiList_title}>
                            Выберите один или несколько учебных периодов,
                            <br />
                            входящих в процесс генерации расписания:
                            <br />
                        </div>
                        {baseTimeIntervalInstances?.map((BTIInstance) => (
                            <div
                                key={BTIInstance.id}
                                className={classes.filters__checkbox}
                                onClick={() => onChangeSelectedBTI(BTIInstance.id)}
                            >
                                <input
                                    type="checkbox"
                                    id={BTIInstance.id}
                                    name={BTIInstance.id}
                                    value={BTIInstance.id}
                                    checked={hasBTIId(BTIInstance.id)}
                                />
                                <div>
                                    {BTIInstance.longName}
                                </div>
                            </div>
                        ))}
                        <div className={classes.scheduleGenerationInfo__addPeriod}>
                            Название периода:
                            <input
                                type="text"
                                value={name}
                                onChange={({ target }) => setName(target.value)}
                                style={{ margin: '0 10px' }}
                                className={classes.scheduleGenerationInfo__addPeriod_input}
                            />
                            <Tooltip
                                isWarning
                                hasExternalState
                                isShow={isNoCheckBTI}
                                text="Необходимо выбрать хотя бы один учебный период"
                            >
                                <ActionButton
                                    onClick={() => {
                                        createScheduleGeneration({
                                            baseTimeIntervalInstanceIds: selectedBTIInstancesIds,
                                            name,
                                        });
                                    }}
                                    disabled={isNoCheckBTI}
                                >
                                    Создать
                                </ActionButton>
                            </Tooltip>
                        </div>
                    </>
                ) : (
                    <ActionButton onClick={() => setOpen(true)}>Создать период</ActionButton>
                )
            }
            {
                error
                && (<Alert message={error.message} time={15000} />)
            }
        </>
    );
}
