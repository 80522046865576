import React from 'react';

import { ActionButton, ActionTypeOfButton } from '@common/ActionButton';

interface Props {
    textMainButton: string;
    textSmallButton: string;
    changeMainButton: () => void;
    changeSmallButton: () => void;
}

export const EventActionButtons = ({
    textMainButton,
    textSmallButton,
    changeMainButton,
    changeSmallButton,
}: Props) => (
    <>
        <ActionButton onClick={changeMainButton!}>
            {textMainButton}
        </ActionButton>
        <ActionButton actionType={ActionTypeOfButton.SECONDARY} onClick={changeSmallButton}>
            {textSmallButton}
        </ActionButton>
    </>
);
