import React from 'react';

import { ActionButton, ActionTypeOfButton } from '@common/ActionButton';
import { IconDeprecated } from '@common/IconDeprecated';

import classes from './PopUp.module.scss';

interface Props {
    text?: string;

    closePortal(): void;
    deleteAction(): void;
}

export const PopUp = ({ text, closePortal, deleteAction }: Props) => {
    const ref = React.useRef<HTMLDivElement | null>(null);
    const [disabledAction, setDisabledAction] = React.useState(false);
    React.useEffect(() => {
        const closeOfBackground = (e: MouseEvent) => {
            if (ref.current && !ref.current.contains(e.target as Node)) {
                closePortal();
            }
        };
        document.addEventListener('click', closeOfBackground);
        return () => document.removeEventListener('click', closeOfBackground);
    }, []);
    return (
        <div className={classes.popUp}>
            <div className={classes.popUp__wrapper} ref={ref}>
                <IconDeprecated id="Cross" className={classes.blackCrossAddSkillPopUp} click={closePortal} />
                <div className={classes.popUp__container}>
                    <span className={classes.popUp__text}>{text}</span>
                    <div className={classes.popUp__buttons}>
                        <div className={classes.popUp__button}>
                            <ActionButton
                                type="button"
                                actionType={ActionTypeOfButton.SECONDARY}
                                onClick={() => {
                                    deleteAction();
                                    setDisabledAction(true);
                                }}
                                disabled={disabledAction}
                            >
                                Да, удалить
                            </ActionButton>
                        </div>
                        <div className={classes.popUp__button} onClick={closePortal}>
                            <ActionButton type="button">
                                Нет, отменить
                            </ActionButton>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
