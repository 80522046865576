import React from 'react';
import cn from 'classnames';

import {
    IntervalWeekDay,
    WeekDaysRussian,
    WorkingInterval,
} from '@admin/EducationPeriodPage/EducationPeriodPageInterfaces';

import classes from './WeekDay.module.scss';

interface Props {
    weekDay: IntervalWeekDay;
    interval: WorkingInterval;
    changeDayIsActive: (
        weekDayName: (keyof typeof WeekDaysRussian),
        intervalId: string,
    ) => void;
}

export function WeekDay(
    {
        weekDay,
        interval,
        changeDayIsActive,
    }: Props,
) {
    return (
        <div
            className={cn(classes.weekDay, {
                [classes.weekDay_active]: weekDay.isActive,
            })}
            onClick={() => changeDayIsActive(weekDay.weekDay, interval.id)}
        >
            {
                WeekDaysRussian[weekDay.weekDay]
            }
        </div>
    );
}
