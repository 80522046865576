import React from 'react';
import { observer } from 'mobx-react-lite';
import cn from 'classnames';
import { Dialog } from '@common/Dialog';
import { Fork, UpdateForkSettingInput } from '../subSpaceTypes';
import Diagram from '../store/Diagram';
import { ForkCard } from './ForkCard';
import { findBtiIndex } from '../SubSpaceSlotDiagram/utilities';

import classes from './DiagramFork.module.scss';

interface Props {
    fork: Fork
    updateForkSetting(updateForkSettingInput: UpdateForkSettingInput): void
}

export const DiagramFork = observer(({
    fork,
    updateForkSetting,
}: Props): JSX.Element => {
    const {
        id: forkId,
        setting,
        nextSlots = [],
        nextGroupSlots = [],
        nextForks = [],
        spaceBaseTimeIntervalId,
    } = fork;
    const {
        groupSlotSettings,
        diagramSettings,
        slotSettings,
        forkSettings,
        diagramEntityRelationSettings,
        btiSettings,
    } = Diagram;
    const {
        isGroupSlotCanMove,
    } = groupSlotSettings;
    const {
        editMode,
        saveRelationDiagramEntityMode,
        createGroupMode,
        createForkMode,
        relationDiagramEntityMode,
        removeDiagramEntityMode,
        createSlotMode,
        showForkDialog,
    } = diagramSettings;
    const {
        id: diagramForkSettingsId, isForkCanMove,
    } = forkSettings;
    const { isSlotCanMove } = slotSettings;
    const {
        nextEntityId,
        previousEntityId,
    } = diagramEntityRelationSettings;
    const numberOfRelations = nextGroupSlots.length + nextSlots.length + nextForks.length;

    const currentBtiIndex: number = findBtiIndex(
        btiSettings,
        fork.spaceBaseTimeIntervalId,
    );

    const selectedEntityBtiIndex: number = findBtiIndex(
        btiSettings,
        diagramEntityRelationSettings.spaceBaseTimeIntervalId,
    );

    return (
        <>
            <div className={classes.diagramFork}>
                <div
                    className={cn(classes.diagramFork__entity, {
                        [classes.diagramFork__entity_dashed]: numberOfRelations < 1,
                        [classes.diagramFork__entity_active]: (diagramForkSettingsId === forkId
                            && isForkCanMove),
                        [classes.diagramFork__entity_withoutPointer]:
                        createSlotMode
                        || isGroupSlotCanMove
                        || createGroupMode
                        || createForkMode
                        || isForkCanMove
                        || (
                            diagramEntityRelationSettings.entityType === 'GroupSlot'
                            && currentBtiIndex !== selectedEntityBtiIndex
                        ) || (
                            diagramEntityRelationSettings.entityType === 'Fork'
                            && diagramEntityRelationSettings.spaceBaseTimeIntervalId
                            !== fork.spaceBaseTimeIntervalId
                        ) || (
                            diagramEntityRelationSettings.entityType === 'ModuleSlot'
                            && currentBtiIndex < selectedEntityBtiIndex
                        ),
                        [classes.diagramFork__entity_readyToRemove]: removeDiagramEntityMode,
                        [classes.diagramFork__entity_readyToConnect]: (
                            relationDiagramEntityMode
                            && previousEntityId !== fork.id
                            && spaceBaseTimeIntervalId
                            === diagramEntityRelationSettings.spaceBaseTimeIntervalId
                        ),
                        [classes.diagramFork__entity_setPrevious]: relationDiagramEntityMode
                        && fork.id === previousEntityId
                        && !nextEntityId,
                        [classes.diagramFork__entity_warning]:
                        (
                            !editMode && numberOfRelations < 1
                        )
                        || (
                            !editMode
                            && numberOfRelations > 0
                            && numberOfRelations === fork.setting?.moduleSelectionCount
                        ),
                    })}
                    onClick={(event) => {
                        if (!editMode) {
                            Diagram.setForkSettings({
                                id: forkId,
                                spaceBaseTimeIntervalId,
                            });
                            Diagram.setShowForkDialog();
                        }

                        if (editMode
                            && !createSlotMode
                            && !createGroupMode
                            && !createForkMode
                            && !relationDiagramEntityMode
                            && !removeDiagramEntityMode
                            && !isSlotCanMove
                            && !isGroupSlotCanMove
                        ) {
                            Diagram.setForkCanMove(
                                event,
                                {
                                    id: forkId,
                                    spaceBaseTimeIntervalId,
                                },
                            );
                        }
                        if (removeDiagramEntityMode) {
                            Diagram.removeEntity(
                                fork.id,
                                fork.spaceBaseTimeIntervalId,
                            );
                        }

                        if (
                            (
                                relationDiagramEntityMode
                                && diagramEntityRelationSettings.entityType === 'ModuleSlot'
                                && !saveRelationDiagramEntityMode
                                && previousEntityId !== fork.id
                                && currentBtiIndex >= selectedEntityBtiIndex
                            ) || (
                                relationDiagramEntityMode
                                && diagramEntityRelationSettings.entityType === 'GroupSlot'
                                && fork.spaceBaseTimeIntervalId
                                === diagramEntityRelationSettings.spaceBaseTimeIntervalId
                                && !saveRelationDiagramEntityMode
                                && previousEntityId !== fork.id
                            ) || (
                                relationDiagramEntityMode
                                && diagramEntityRelationSettings.entityType === 'Fork'
                                && fork.spaceBaseTimeIntervalId
                                === diagramEntityRelationSettings.spaceBaseTimeIntervalId
                                && !saveRelationDiagramEntityMode
                                && previousEntityId !== fork.id
                            ) || (
                                !diagramEntityRelationSettings.entityType
                            )
                        ) {
                            Diagram.setDiagramEntityRelationSettings(
                                fork.id,
                                fork.spaceBaseTimeIntervalId,
                                'Fork',
                            );
                        }
                    }}
                >
                    <span className={classes.diagramFork__numberOfRelations}>
                        {
                            setting?.moduleSelectionCount
                                ? `${setting?.moduleSelectionCount}/`
                                : '?/'
                        }
                    </span>
                    <span className={classes.diagramFork__moduleSelectionCount}>
                        {numberOfRelations || '?'}
                    </span>

                </div>
                {
                    (!editMode && numberOfRelations < 1) && (
                        <span className={classes.diagramFork__toolTipText}>
                            Количество связей не может быть равно нулю
                        </span>
                    )
                }
                {
                    (
                        !editMode
                        && numberOfRelations > 0
                        && numberOfRelations === fork.setting?.moduleSelectionCount
                    ) && (
                        <span className={classes.diagramFork__toolTipText}>
                            Количество слотов не может быть равно количеству
                            выбранных модулей в настройках развилки
                        </span>
                    )
                }
            </div>
            {(showForkDialog && forkId === forkSettings.id && numberOfRelations > 1) && (
                <Dialog
                    id="fork-dialog"
                    dom={(
                        <ForkCard
                            fork={fork}
                            updateForkSetting={updateForkSetting}
                        />
                    )}
                />
            )}
        </>
    );
});
