import React from 'react';
import map from 'lodash/map';
import { CreateBuildingInput, TerritorialZone } from '@admin/UniversityPage/types';
import { ActionButton, ActionTypeOfButton } from '@common/ActionButton';
import { UniversityBuildingFormGroup } from '../UniversityBuildingFormGroup';
import { AddNewBuild } from '../AddNewBuild';
import classes from './UniversityBuildingForm.module.scss';

interface Props {
    handleInputChange({
        target,
    }:
    | React.ChangeEvent<HTMLInputElement>
    | React.ChangeEvent<HTMLSelectElement>): void;

    territorialZones: TerritorialZone[];
    buildings: CreateBuildingInput[];

    handleChangeListMode(): void

    addRemoveBtnLabel: string,

    handleAddRemoveBtn(): void,

    onSubmit(event: React.SyntheticEvent): void;
}

export function UniversityBuildingForm({
    handleInputChange,
    territorialZones,
    buildings,
    handleChangeListMode,
    addRemoveBtnLabel,
    handleAddRemoveBtn,
    onSubmit,
}: Props): JSX.Element {
    return (
        <form className={classes.buildingForm} onSubmit={onSubmit}>
            {map(buildings, (building) => (
                <UniversityBuildingFormGroup
                    handleInputChange={handleInputChange}
                    territorialZones={territorialZones}
                    building={building}
                    key={building.id}
                />
            ))}

            <AddNewBuild
                text={addRemoveBtnLabel}
                removeButton={addRemoveBtnLabel === 'Удалить корпус'}
                marginTop={20}
                handler={handleAddRemoveBtn}
            />

            <div className={classes.buildingEdit__buttonsBlock}>
                <ActionButton
                    type="button"
                    actionType={ActionTypeOfButton.SECONDARY}
                    onClick={handleChangeListMode}
                >
                    Отменить
                </ActionButton>

                <ActionButton type="submit">Сохранить</ActionButton>
            </div>
        </form>
    );
}
