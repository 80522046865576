import { ModuleSettings, PlainModuleSettings } from '../interfaces/moduleSettings';

export const getPlainModuleSettings = (
    moduleSettings?: ModuleSettings,
): PlainModuleSettings | undefined => {
    if (moduleSettings) {
        return [
            ...moduleSettings.moduleSkillTypes,
            ...moduleSettings.meetingSkillTypes,
        ];
    }

    return undefined;
};
