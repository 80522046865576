import React from 'react';
import { Title, TitleTypes } from '@common/Title';
import { Suggest } from '@common';
import { Select } from '../Select';

import styles from './Section.module.scss';

type Option = {
    id: string;
    name: string;
};

type OptionSelect = {
    id: string;
    options: Option[];
};

type OptionData = {
    id: string;
    name: string;
    selectOption: Option;
};

export const SuggestSelectFormInitialData = {
    id: '',
    name: '',
    selectOption: {
        id: '',
        name: '',
    },
};

interface Props {
    mainOptions: Option[];
    optionsSelect: OptionSelect[];
    label: string;
    labelSelect: string;
    placeholderSuggest: string;
    data: OptionData;

    setData(data: OptionData): void;
}

export function SuggestSelectForm({
    mainOptions,
    optionsSelect,
    label,
    labelSelect,
    placeholderSuggest,
    data,
    setData,
}: Props) {
    return (
        <div>
            {label !== '' ? <Title type={TitleTypes.Subtitle}>{label}</Title> : <></>}
            <div className={styles.row}>
                <Suggest
                    value={data.id ? data : null}
                    options={mainOptions}
                    placeholder={placeholderSuggest}
                    onChange={value => {
                        setData({ ...data, ...value, selectOption: { id: '', name: '' } });
                    }}
                />
                <div className={styles.suggestSelect__select}>
                    <Select
                        name={data.selectOption.id}
                        label={labelSelect}
                        value={data.selectOption.id ?? ''}
                        onChange={({ target }) => {
                            setData({
                                ...data,
                                selectOption: {
                                    name: target.options[target.selectedIndex].text,
                                    id: target.value,
                                },
                            });
                        }}
                        options={optionsSelect
                            .find(selectOptions => selectOptions.id === data.id)?.options || []}
                        isNumber
                    />
                </div>
            </div>
        </div>
    );
}
