import React from 'react';
import { nanoid } from 'nanoid';
import { QuestionIcon } from '@common/svg';
import classes from './SpaceSubspaceDiagram.module.scss';

export interface SubspaceType {
    id: string,
    name: string,
    subspaceTypeLayout?: SubspaceTypeLayout[],
}
export interface Space {
    id: string
    name: string
    minCreditCount: number
    maxCreditCount: number
    isDifferentSpaceBaseTimeIntervals: boolean
    spaceBaseTimeIntervals: SpaceBaseTimeInterval[]
    baseTimeIntervalType: BaseTimeIntervalType
}

export interface SpaceBaseTimeInterval {
    id: string
    order: number
    minCreditCount: number
    maxCreditCount: number
    subspaceTypeLayouts: SubspaceTypeLayout[]
    space: Space
}

export interface SubspaceTypeLayout {
    id: string
    spaceBaseTimeInterval: SpaceBaseTimeInterval
    minCreditCount: number
    maxCreditCount: number
    subspaceType: SubspaceType
}

export interface BaseTimeIntervalType {
    id: string,
    name: string,
}

const colorsPalette = ['#D0FEED', '#F1D8F5', '#F5D7E3', '#FFF5CF', '#B49EFF',
    '#FFC7F5', '#C7FFD1', '#EDCECE', '#F0B4B3', '#7FB3B3', '#BDDFD2',
    '#DFEEFA', '#C9C6DC', '#89B6D0', '#A19BC2', '#EEAFE3'];
const intervalGap = 2;
const billetHeight = 12;

export function SpaceSubspaceDiagram(props:{
    space:Space,
    targetSubspaceType?:SubspaceType,
    callBacks?:(()=>void)[],
}) {
    const space = { ...props.space };
    const vars:{ [index:string]:any } = {};

    vars.subspaceTypes = getSubspaceTypes(space);
    vars.targetSubspaceType = props.targetSubspaceType
        ? { ...props.targetSubspaceType }
        : undefined;
    vars.callBacks = props.callBacks ? [...props.callBacks] : undefined;
    vars.baseTimeIntervalName = space.baseTimeIntervalType.name;
    vars.colors = [...colorsPalette];
    vars.billet = createBillet(space, billetHeight);
    vars.elements = [];
    vars.xPosition = 0;
    vars.subtypeColors = new Map();
    defineSubtypeColors(vars);

    space.spaceBaseTimeIntervals.forEach((_element, i) => {
        vars.baseTimeInterval = space.spaceBaseTimeIntervals[i];
        vars.index = i;
        vars.optionalPointsCount = getSpaceOptionPointsCount(vars);
        addSectionPoint(vars);
        vars.xPosition += i === 0 ? intervalGap + 2 : intervalGap + 1;
        addCreditInfo(vars);
        addIntervalLabel(vars);
        vars.yPosition = billetHeight - 1;
        vars.subtypeSizes = getSubtypeSizes(vars);
        vars.subtypesOptionPoints = getSubtypesOptionPointsCount(vars);
        vars.subtypeNames = getSubtypeNames(vars);

        for (let j = 0; j < space.spaceBaseTimeIntervals[i].maxCreditCount; j += 1) {
            vars.currentPoint = j;
            vars.subtypeIndex = getIndexOfFirstNonFullRenderSubtype(vars);
            vars.color = getSybtypeColor(vars);
            vars.pointStyle = getPointStyle(vars);

            if (vars.subtypeSizes && vars.subtypesOptionPoints) {
                [
                    vars.subtypeSizes[vars.subtypeIndex],
                    vars.pointStyle,
                    vars.optionalPointsCount,
                ] = setSubspaceTypePointStyle(vars);
            }

            addPoint(vars);
            [vars.xPosition, vars.yPosition] = getNextPointPosition(vars);
        }
    });

    const diagramContainer = getContainer(vars);
    return diagramContainer;
}

function getSubspaceTypesWithColors(
    subTypeColorsMap: Map<string, string>,
    subSpaceTypes: SubspaceType[],
) {
    const typesWithColors: {
        name: string,
        color: string,
    }[] = [];

    subSpaceTypes.forEach((subSpaceType) => {
        const color = subTypeColorsMap.get(subSpaceType.name);
        if (color) {
            typesWithColors.push(
                {
                    name: subSpaceType.name,
                    color,
                },
            );
        }
    });

    return typesWithColors;
}

function getSubspaceTypes(space: Space): SubspaceType[] {
    const subspaceTypes:SubspaceType[] = [];
    space.spaceBaseTimeIntervals.forEach(

        (interval) => {
            interval.subspaceTypeLayouts?.forEach(
                (layout: SubspaceTypeLayout) => {
                    if (!isExistentType(subspaceTypes, layout.subspaceType as SubspaceType)) {
                        subspaceTypes.push(layout.subspaceType as SubspaceType);
                    }
                },
            );
        },

    );
    return subspaceTypes;
}

function isExistentType(subspaceTypes:SubspaceType[], type:SubspaceType):boolean {
    return !!(subspaceTypes.find(
        (element) => element.name === type.name,
    ));
}

function createBillet(space: Space, heightBillet:number) {
    const intervalsCount = space.spaceBaseTimeIntervals.length;
    let widthBillet = 0;

    for (let i = 0; i < intervalsCount; i += 1) {
        const width = intervalWidth(space.maxCreditCount, heightBillet)
            + (i === 0 ? intervalGap + 1 : intervalGap);
        widthBillet += width;
    }

    return Array(widthBillet).fill(0).map(() => Array(heightBillet).fill(0));
}

function intervalWidth(maxInterval:number, heightBillet:number) {
    return Math.floor(maxInterval / heightBillet) + ((maxInterval % heightBillet) > 0 ? 1 : 0);
}

function defineSubtypeColors(
    {
        subtypeColors,
        subspaceTypes,
        colors,
    }:{ [index:string]:any },
) {
    subspaceTypes?.forEach((element:SubspaceType) => {
        if (!subtypeColors.has(element.name)) {
            subtypeColors.set(element.name, colors.pop() as string);
        }
    });
}

function getSpaceOptionPointsCount(
    { baseTimeInterval }:{ [index:string]:any },
) {
    return baseTimeInterval.maxCreditCount - baseTimeInterval.minCreditCount;
}

function addSectionPoint(
    {
        elements,
        xPosition,
        targetSubspaceType,
        baseTimeInterval,
        callBacks,
    }:{ [index:string]:any },
) {
    let sectionPointClassName = classes.diagram__sectionPoint;
    let sectionPoint = (
        <QuestionIcon className={sectionPointClassName} handler={callBacks?.shift()} />
    );
    if (targetSubspaceType && !hasTargetSubspaceType(targetSubspaceType, baseTimeInterval)) {
        sectionPointClassName = `${classes.diagram__sectionPoint} ${classes.diagram__sectionPoint_inactive}`;
        sectionPoint = (
            <QuestionIcon className={sectionPointClassName} />
        );
    }
    if (targetSubspaceType && hasTargetSubspaceType(targetSubspaceType, baseTimeInterval)) {
        sectionPoint = (
            <QuestionIcon className={sectionPointClassName} handler={callBacks?.shift()} />
        );
    }
    elements.push((
        <div className={classes.sectionPoint} key={nanoid()} style={{ gridRow: Math.floor(billetHeight / 2).toString(), gridColumn: `${xPosition + 2}` }}>
            {sectionPoint}
        </div>));
}

function hasTargetSubspaceType(
    targetSubspaceType:SubspaceType,
    baseTimeInterval:SpaceBaseTimeInterval,
) {
    return !!baseTimeInterval?.subspaceTypeLayouts?.find(
        (element: SubspaceTypeLayout) => element?.subspaceType?.name === targetSubspaceType?.name,
    );
}

function addCreditInfo(
    {
        elements,
        baseTimeInterval,
        xPosition,
    }:{ [index:string]:any },
) {
    elements.push((
        <div key={nanoid()} style={{ gridRow: '0', gridColumn: `${xPosition}`, overflow: 'visible' }}>
            <div style={{ zIndex: 2, width: '200px' }}>
                {`${baseTimeInterval.minCreditCount}-${baseTimeInterval.maxCreditCount} ЗЕТ`}
            </div>
        </div>
    ));
}

function addIntervalLabel(
    {
        elements,
        baseTimeIntervalName,
        xPosition,
        index,
    }:{ [index:string]:any },
) {
    elements.push((
        <div key={nanoid()} style={{ gridRow: billetHeight.toString(), gridColumn: `${xPosition}`, overflow: 'visible' }}>
            <div style={{ zIndex: 2, marginTop: '10px', width: '200px' }}>
                {`${index + 1} ${baseTimeIntervalName}`}
            </div>
        </div>
    ));
}

function getSubtypeSizes({ baseTimeInterval }:{ [index:string]:any }) {
    const result: number[] = [];
    baseTimeInterval.subspaceTypeLayouts?.forEach(
        (layout:SubspaceTypeLayout) => {
            result.push(layout.maxCreditCount);
        },
    );
    return result;
}

function getSubtypesOptionPointsCount({ baseTimeInterval }:{ [index:string]:any }) {
    const result: number[] = [];
    baseTimeInterval.subspaceTypeLayouts?.forEach(
        (layout:SubspaceTypeLayout) => {
            result.push(layout.maxCreditCount - layout.minCreditCount);
        },
    );
    return result;
}

function getSubtypeNames({ baseTimeInterval }:{ [index:string]:any }) {
    const result: string[] = [];
    baseTimeInterval.subspaceTypeLayouts?.forEach(
        (layout:SubspaceTypeLayout) => {
            result.push(layout.subspaceType?.name as string);
        },
    );
    return result;
}

function getIndexOfFirstNonFullRenderSubtype({ subtypeSizes }:{ [index:string]:any }) {
    return subtypeSizes?.findIndex((element:number) => element > 0) ?? -1;
}

function getSybtypeColor(
    {
        subtypeColors,
        subtypeIndex,
        subtypeNames,
    }:{ [index:string]:any },
) {
    return subtypeColors.get(
        subtypeNames[subtypeIndex],
    ) ?? 'none';
}

function getPointStyle(
    {
        currentPoint,
        baseTimeInterval,
        optionalPointsCount,
        targetSubspaceType,
    }:{ [index:string]:any },
): { [index:string]:string } {
    const defaultStyle = {
        borderRadius: '100px',
        borderWidth: '2px',
        opacity: targetSubspaceType ? '0.3' : '1',
    };
    return currentPoint < getSpaceOptionPointsCountRemains(baseTimeInterval, optionalPointsCount) ? { ...defaultStyle } : { borderStyle: 'dotted', ...defaultStyle };
}

function getSpaceOptionPointsCountRemains(
    baseTimeInterval: SpaceBaseTimeInterval,
    spaceOptionalPointCount: number,
) {
    return baseTimeInterval.maxCreditCount - spaceOptionalPointCount;
}

function setSubspaceTypePointStyle(
    {
        subtypeSizes,
        subtypesOptionPoints,
        optionalPointsCount,
        pointStyle,
        color,
        subtypeIndex,
        subtypeNames,
        targetSubspaceType,
    }:{ [index:string]:any },
) {
    const subtypeSizesCopy = subtypeSizes ? [...subtypeSizes] : undefined;
    let pointStyleCopy = pointStyle ? { ...pointStyle } : undefined;
    let optionalPointsCountCopy = optionalPointsCount;
    if (subtypeSizesCopy && subtypesOptionPoints) {
        subtypeSizesCopy[subtypeIndex] -= 1;
        pointStyleCopy = { ...pointStyleCopy, backgroundColor: color };
        if (
            subtypeSizesCopy[subtypeIndex]
            < subtypesOptionPoints[subtypeIndex]
            && optionalPointsCountCopy > 0
        ) {
            pointStyleCopy = { ...pointStyleCopy };
            optionalPointsCountCopy -= 1;
        } else if (subtypeSizesCopy[subtypeIndex] > 0) {
            pointStyleCopy = { ...pointStyleCopy };
        }
    }
    if (targetSubspaceType && targetSubspaceType.name === subtypeNames[subtypeIndex]) {
        pointStyleCopy = { ...pointStyleCopy, opacity: '1' };
    }
    return [
        subtypeSizesCopy ? subtypeSizesCopy[subtypeIndex] : undefined,
        pointStyleCopy,
        optionalPointsCountCopy,
    ];
}

function addPoint(
    {
        elements,
        yPosition,
        xPosition,
        pointStyle,
    }:{ [index:string]:any },
) {
    elements.push(
        (
            <div key={nanoid()} style={{ gridRow: `${yPosition}`, gridColumn: `${xPosition}` }}>
                <div style={{ ...pointStyle, width: '20px', height: '20px' }} />
            </div>
        ),
    );
}

function getContainer(
    {
        billet,
        elements,
        subspaceTypes,
        subtypeColors,
    }:{ [index:string]:any },
) {
    return (
        <div className={classes.diagram__container}>

            <div className={classes.legend}>
                {
                    getSubspaceTypesWithColors(subtypeColors, subspaceTypes).map((legend) => (
                        <div
                            key={legend.name}
                            className={classes.legend__item}
                            style={{
                                backgroundColor: legend.color,
                            }}
                        >
                            {legend.name}
                        </div>
                    ))
                }
            </div>

            <div
                className={classes.diagram}
                style={
                    {
                        display: 'grid',
                        gridTemplateColumns: `repeat( ${billet.length}, 22px)`,
                        gridTemplateRows: `repeat( ${billet[0].length / 2 - 1}, 22px) 26px repeat( ${billet[0].length / 2}, 22px)`,
                        // overflow: 'scroll',
                        width: '706px',
                        height: '320px',
                    }
                }
            >
                {elements}
            </div>
            <div className={classes.diagram__axesContainer}>
                <svg className={classes.diagram__axes} width="1142" height="268" viewBox="0 0 1142 268" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M13.0606 0.939346C12.4749 0.353546 11.5251 0.353546 10.9393 0.939346L1.39339 10.4853C0.807601 11.0711 0.807601 12.0208 1.39339 12.6066C1.97917 13.1924 2.92892 13.1924 3.51471 12.6066L12 4.12132L20.4853 12.6066C21.0711 13.1924 22.0208 13.1924 22.6066 12.6066C23.1924 12.0208 23.1924 11.0711 22.6066 10.4853L13.0606 0.939346ZM13.5 256L13.5 2L10.5 2L10.5 256L13.5 256Z" fill="black" />
                    <line x1="11" y1="255" x2="1142" y2="255" stroke="black" strokeWidth="0.4%" />
                </svg>
            </div>
        </div>
    );
}

function getNextPointPosition(
    {
        yPosition,
        xPosition,
    }:{ [index:string]:any },
) {
    let yPositionCopy = yPosition - 1;
    let xPositionCopy = xPosition;
    if (yPositionCopy === 1) {
        yPositionCopy = billetHeight - 1;
        xPositionCopy += 1;
    }
    return [xPositionCopy, yPositionCopy];
}
