import React from 'react';
import { ApolloError } from '@apollo/client';

import { PublishEvents } from '@admin/ScheduleGenerationPage/PublishEvents';

import classes from './Attendance.module.scss';

interface Props {
    studentsAttendanceLoading: boolean,
    studentsAttendanceError: ApolloError | undefined,
    getLazyStudentsAttendance: (fromDate: Date, toDate: Date) => void,
}

export function Attendance({
    studentsAttendanceLoading,
    studentsAttendanceError,
    getLazyStudentsAttendance,
}: Props): JSX.Element {
    return (
        <div className={classes.attendance}>
            <h3 className={classes.attendance_title}>
                Отчет о посещаемости:
            </h3>

            <ul className={classes.attendance__secretList}>
                <li className={classes.attendance__secretItem}>
                    <div className={classes.attendance__secretDescription}>
                        Укажите временные интервалы,
                        за которые необходимо скачать статистику
                    </div>

                    <PublishEvents
                        title="Скачать"
                        onButtonClick={getLazyStudentsAttendance}
                        loading={studentsAttendanceLoading}
                        error={studentsAttendanceError}
                    />
                </li>
            </ul>
        </div>
    );
}
