import React from 'react';
import { observer } from 'mobx-react';

import { EventFilterMenu } from '../../EventFilterMenu';
import { TimeRuler } from '../../TimeRuler';
import { getHoursRange } from '../../TimeRuler/helpers';
import { DayMeeting } from '../../DateEvent';
import { TimePastLine } from '../../TimePastLine';
import { Assignments } from '../../Assignments';
import { accountStore } from '../../../../Store';
import { eventsStore } from '../../../Store';

import classes from './DaySchedule.module.scss';

export const DaySchedule = observer(() => {
    document.getElementById('startTime')?.scrollIntoView();
    return (
        <div className={classes.daySchedule}>
            <div className={classes.dayScheduleHeader}>
                <div className={classes.dayScheduleHeader__eventFilterMenu}>
                    <EventFilterMenu />
                </div>
            </div>

            <div className={classes.daySchedule__block}>
                <div className={classes.daySchedule__gridWrapper}>
                    <TimeRuler startTime={7} />

                    <div className={classes.daySchedule__grid}>
                        <div className={classes.daySchedule__rows}>
                            {getHoursRange().map((hour) => (
                                <div
                                    className={classes.daySchedule__row}
                                    key={hour}
                                />
                            ))}

                            {eventsStore.getTimeReservingEvents().map(
                                (dateEvent, index, allDateEvents) => (
                                    <DayMeeting
                                        dateEvent={dateEvent}
                                        allDateEvents={allDateEvents}
                                        key={dateEvent.id}
                                    />
                                ),
                            )}

                            <TimePastLine />
                        </div>
                    </div>
                </div>

                {(accountStore.isStudent() && !!eventsStore.getAssignmentInstances().length) && (
                    <Assignments className={classes.daySchedule__assignments} />
                )}
            </div>
        </div>
    );
});
