/* eslint react/no-danger: 0 */
import React from 'react';
import cn from 'classnames';

import { HatIcon, PlusIcon, CrossIcon } from '@common/svg';
import { Loader, LoaderSizes } from '@common/Loader';
import { Wysiwyg } from '@common/Wysiwyg';

import classes from './ExternalModuleReview.module.scss';
import { getExternalModuleReviews_externalModule_reviews } from './apollo-types';

interface Props {
    isCurrentUserAvailbleToAddReview?: boolean;
    addExternalModuleReviewLoading?: boolean;
    isReviewFormOpened: boolean;
    reviewMessage?: string;
    reviews: getExternalModuleReviews_externalModule_reviews[] | undefined;
    errorMessage: string | undefined;
    onReviewFormToggle(isOpened: boolean): void;
    onReviewDelete(reviewId: string): void;
    onReviewSave(): void;
    onReviewChange: React.Dispatch<React.SetStateAction<string | undefined>>;
}

export function ExternalModuleReview({
    isCurrentUserAvailbleToAddReview,
    addExternalModuleReviewLoading,
    isReviewFormOpened,
    reviews,
    reviewMessage,
    errorMessage,
    onReviewFormToggle,
    onReviewDelete,
    onReviewSave,
    onReviewChange,
}: Props) {
    const iconClassName = cn(
        classes['external-module-review__icon'], {
            [classes['external-module-review__icon_active']]: isReviewFormOpened,
        },
    );

    return (
        <>
            {isCurrentUserAvailbleToAddReview && (
                addExternalModuleReviewLoading
                    ? <Loader size={LoaderSizes.default} />
                    : (
                        <div
                            className={classes['external-module-review']}
                        >
                            <div
                                className={classes['external-module-review__header']}
                                onClick={() => onReviewFormToggle(!isReviewFormOpened)}
                            >
                                <PlusIcon className={iconClassName} />
                                <span className={classes['external-module-review__title']}>
                                    Оставить рецензию
                                </span>
                            </div>
                            {isReviewFormOpened && (
                                <div>
                                    <Wysiwyg
                                        placeholder="Введите текст"
                                        value={reviewMessage || ''}
                                        onChange={onReviewChange}
                                    />
                                    <div
                                        className={classes['external-module-review__save-button']}
                                        onClick={onReviewSave}
                                    >
                                        <span>Сохранить</span>
                                    </div>
                                    {errorMessage && (
                                        <span className={classes['external-module-review__error']}>
                                            {errorMessage}
                                        </span>
                                    )}
                                </div>
                            )}
                        </div>
                    )
            )}
            {!!reviews?.length && (
                <div className={classes['external-module-review-list']}>
                    <div className={classes['external-module-review-list__title']}>
                        Рецензии:
                    </div>
                    {reviews.map(({
                        message,
                        reviewer,
                        id,
                    }) => (
                        <div
                            key={id}
                            className={cn(
                                classes['external-module-review-list__card'],
                                classes['review-card'],
                            )}
                        >
                            <div className={classes['review-card__reviewer']}>
                                <HatIcon
                                    className={classes.reviewer__avatar}
                                    height={32}
                                    width={32}
                                />
                                <div className={classes['reviewer__full-name']}>
                                    {reviewer.lastName} {reviewer.firstName} {reviewer.patronymic}
                                </div>
                            </div>
                            <div
                                dangerouslySetInnerHTML={{ __html: message }}
                                className={classes['review-card__message']}
                            />
                            {isCurrentUserAvailbleToAddReview && (
                                <CrossIcon
                                    className={classes['review-card__remove']}
                                    handler={() => onReviewDelete(id)}
                                    size={23}
                                />
                            )}
                        </div>
                    ))}
                </div>
            )}
        </>
    );
}
