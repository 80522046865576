import React from 'react';
import { useMutation } from '@apollo/client';

import { Alert } from '@common/Alert/Alert';
import { Loader, LoaderSizes } from '@common/Loader';
import { RoleButtonToolBar } from './RoleButtonToolBar';
import { addUsersTypes, addUsersTypesVariables, Type } from '../apollo-types';
import { ToolBarButtonProps } from '../interface';
import { ADD_USERS_TYPES } from '../gql';

export const RoleButtonApollo = ({
    selectedUsers,
    refetchUserList,
}: ToolBarButtonProps) => {
    const [
        addUsersType,
        { loading, error },
    ] = useMutation<addUsersTypes, addUsersTypesVariables>(
        ADD_USERS_TYPES,
        {
            onCompleted: () => { refetchUserList(); },
            // eslint-disable-next-line no-console
            onError: (err) => console.error(err),
        },
    );
    if (loading) {
        return (
            <Loader size={LoaderSizes.default} />
        );
    }

    function handleSubmit(role: Type) {
        const ids = selectedUsers.map(({ id }) => id);
        addUsersType({
            variables: {
                addType: {
                    ids,
                    type: [role],
                },
            },
        });
    }

    return (
        <>
            <RoleButtonToolBar onSubmit={handleSubmit} />
            {error && <Alert message={error.message} time={3000} />}
        </>
    );
};
