/* eslint-disable class-methods-use-this */
import { makeAutoObservable } from 'mobx';
/* eslint import/no-cycle: "off" */
import { ModuleModel } from './ModuleModel';
import { TeacherRole, ModuleSkill, EventType } from './moduleStoreTypes';
import { Meeting } from './Meeting';
import { Assignment } from './Assignment';
import { EvaluationPoint } from './EvaluationPoint';

class ModuleStore {
    moduleModel = new ModuleModel();

    constructor() {
        makeAutoObservable(this);
    }

    getTotalSkills = (
        moduleSkills: ModuleSkill[], newSkills: ModuleSkill[], typeId: string,
    ): ModuleSkill[] => ([
        ...moduleSkills.filter(skill => skill.skill.typeId !== typeId),
        ...newSkills.map(item => ({ ...item, skill: { ...item.skill, typeId } })),
    ]);

    removeNonexistentRoles(roles: TeacherRole[]): TeacherRole[] {
        // ?? { maxCount: 0 } это костыль. до него там было
        // this.moduleModel.options.getRole(item.teacherRole.id)! - в конце ! assertion
        // который говорит, что там никогда не будет undefined, хотя сам getRole может и
        // возвращает undefined.
        return roles
            ?.map(item => {
                const {
                    maxCount,
                } = this.moduleModel.options.getRole(item.teacherRole.id) ?? { maxCount: 0 };
                return { ...item, maxCount, count: item.count! };
            });
    }

    getNumberOfEvent(events: { order: number }[], eventOrder: number): number {
        return events.reduce((acc, event) => (event.order < eventOrder ? acc + 1 : acc), 1);
    }

    isMeeting = (event: { type: EventType }): event is Meeting => event.type === EventType.Meeting;

    isAssignment = (
        event: { type: EventType },
    ): event is Assignment => event.type === EventType.Assignment;

    isEvaluationPoint = (
        event: { type: EventType },
    ): event is EvaluationPoint => event.type === EventType.EvaluationPoint;

    restore = () => {
        this.moduleModel.restore();
    };
}

export const moduleStore = new ModuleStore();
