import React, { useState } from 'react';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { GetEquipmentsOnRoomPage, RoomsPage_Rooms, UpdateRoomInput } from 'src/graphql-query-types';

import { Alert } from '@common';
import { useParams } from 'react-router-dom';
import {
    AlertMessage,
    Building,
    RoomMeetingFormat,
    TerritorialZone,
} from './RoomTypes';

import {
    CREATE_ROOM,
    DELETE_ROOM,
    EDIT_ROOM,
    GET_BUILDINGS,
    GET_EQUIPMENT,
    GET_ROOMS,
    GET_ROOM_TYPES,
    GET_TERRITORIAL_ZONE,
    GET_TERRITORIAL_ZONES, GET_UNIVERSITY_TIME_ZONE,
} from './queries';
import { RoomsPage } from './RoomsPage';

interface QueryParams {
    territorialZoneId?: string;
}

export function RoomsPageApollo(): JSX.Element {
    const [alert, setAlert] = useState<AlertMessage>({
        alert: false,
        message: '',
    });

    const { territorialZoneId } = useParams<QueryParams>();

    const [activeTab, setActiveTab] = useState<string>(territorialZoneId ?? 'all');

    const {
        data: getBuildings,
        loading: loadingBuildings,
        error: errorBuildings,
    } = useQuery<{ buildings: Building[] }>(GET_BUILDINGS, {
        fetchPolicy: 'cache-and-network',
    });

    const [getRooms, {
        data: getRoomData,
        loading: loadingRooms,
    }] = useLazyQuery<RoomsPage_Rooms>(GET_ROOMS, {
        fetchPolicy: 'cache-and-network',
    });

    const {
        data: territorialZonesData,
        loading: territorialZonesLoading,
    } = useQuery<{ territorialZones: TerritorialZone[] }>(GET_TERRITORIAL_ZONES, {
        fetchPolicy: 'cache-first',
    });

    const {
        data: getRoomTypes,
        loading: loadingRoomTypes,
    } = useQuery<{ meetingFormats: RoomMeetingFormat[] }>(GET_ROOM_TYPES, {
        fetchPolicy: 'cache-and-network',
    });

    const {
        data: getStoreEquipment,
        refetch: refetchStoreEquipment,
    } = useQuery<GetEquipmentsOnRoomPage>(GET_EQUIPMENT, {
        fetchPolicy: 'network-only',
        variables: {
            filterParams: {
                isComputer: false,
                movability: [{
                    isMovable: false,
                    territorialZoneId: null,
                }],
            },
            paginationParams: {},
        },
    });

    const {
        data: universityTZ,
    } = useQuery<{ universityTimezone: string }>(GET_UNIVERSITY_TIME_ZONE);

    const [
        getTerritorialZone,
        {
            data: territorialZoneData,
            loading: territorialZoneLoading,
        },
    ] = useLazyQuery<{ territorialZone: TerritorialZone }>(
        GET_TERRITORIAL_ZONE,
        { fetchPolicy: 'no-cache' },
    );

    const [deleteRoom] = useMutation(DELETE_ROOM,
        {
            onError: error => setAlert({ alert: true, message: error.message }),
            onCompleted: async () => {
                getRoomsHandler();
                await refetchStoreEquipment();
            },
        });

    const [editRoom, { error: editRoomError }] = useMutation(EDIT_ROOM,
        {
            onError: error => setAlert({ alert: true, message: error.message }),
            onCompleted: async () => {
                getRoomsHandler();
                await refetchStoreEquipment();
            },
        });

    const [createRoom] = useMutation(CREATE_ROOM,
        {
            onError: error => setAlert({ alert: true, message: error.message }),
            onCompleted: () => {
                getRoomsHandler();
            },
        });

    const createRoomHandler = async (createRoomData: UpdateRoomInput) => {
        await createRoom({
            variables: {
                createRoomData,
            },
        });
    };

    const editRoomHandler = (editRoomData: UpdateRoomInput) => {
        editRoom({
            variables: {
                editRoomData,
            },
        });
    };

    const deleteRoomHandler = (id: string) => {
        deleteRoom({
            variables: {
                id,
            },
        });
    };

    const isLoading = loadingBuildings
        || loadingRooms
        || loadingRoomTypes
        || territorialZonesLoading
        || territorialZoneLoading;

    const error = editRoomError || errorBuildings;

    function getRoomsHandler() {
        if (activeTab === 'all') {
            getRooms();
        } else {
            getTerritorialZone({ variables: { id: activeTab } });
        }
    }
    return (
        <>
            <RoomsPage
                universityTimeZone={universityTZ?.universityTimezone ?? ''}
                alert={alert}
                buildings={getBuildings?.buildings}
                allRooms={getRoomData?.rooms}
                territorialZoneRooms={territorialZoneData?.territorialZone.rooms}
                meetingFormats={getRoomTypes?.meetingFormats}
                storeEquipment={getStoreEquipment?.equipment.list}
                territorialZones={territorialZonesData?.territorialZones}
                isLoading={isLoading}
                tabsState={[activeTab, setActiveTab]}
                getRoomsHandler={getRoomsHandler}
                createRoomHandler={createRoomHandler}
                editRoomHandler={editRoomHandler}
                deleteRoomHandler={deleteRoomHandler}
            />
            {error && <Alert message={error.message} time={6000} />}
        </>
    );
}
