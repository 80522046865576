import React from 'react';

import { ITableModule, Meeting, PlannedMeetingInstance, Wave } from '../ScheduleTableInterfaces';
import { TableCell } from '../TableCell';

import classes from './TableModule.module.scss';

export interface UpdatePannedMeetingInstancesParams {
    toAdd: PlannedMeetingInstance[];
    toDelete: string[];
}

interface Props {
    isSecret?: boolean;
    tableModule: ITableModule;
}
export const TableModule = React.memo(({
    isSecret,
    tableModule,
}: Props): JSX.Element => {
    const { meetings, waves, waveMeetingMatrix } = tableModule;

    return (
        <div className={classes.tableModule}>
            <h3 className={classes.tableModule__name}>{tableModule.name}</h3>
            <h4 className={classes.tableModule__title}>Потоки</h4>
            <div className={classes.tableModule__container}>
                <div className={classes.tableModule__colomn}>
                    <div className={classes.tableModule__colomnItem} />
                    {meetings.map((meeting: Meeting) => (
                        <div key={meeting.id} className={classes.tableModule__colomnItem}>
                            <p className={classes.tableModule__colomnText}>{meeting.topic}</p>
                        </div>
                    ))}
                </div>
                <div className={classes.tableModule__waves}>
                    <div className={classes.tableModule__row}>
                        {waves.map((wave: Wave) => (
                            <div key={wave.id} className={classes.tableModule__item}>
                                {wave.index + 1}
                            </div>
                        ))}
                    </div>
                    <div className={classes.tableModule__matrix}>
                        {waveMeetingMatrix.map((row, meetingIndex) => (
                            <div
                                className={classes.tableModule__row}
                                key={getKey(tableModule, meetingIndex)}
                            >
                                {row.map((cell, waveIndex) => (
                                    <TableCell
                                        isSecret={isSecret}
                                        key={getKey(tableModule, meetingIndex, waveIndex)}
                                        data={cell}
                                    />
                                ))}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
});

function getKey(tableModule: ITableModule, meetingIndex: number, waveIndex?: number): string {
    const meeting = tableModule.meetings[meetingIndex];
    let key = `${tableModule.id}_${meeting.id}`;

    if (typeof waveIndex !== 'undefined') {
        const wave = tableModule.waves[waveIndex];
        key = `${key}_${wave.id}`;
    }

    return key;
}
