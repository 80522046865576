import React, { useEffect, useState } from 'react';
import { gql, useMutation, useQuery } from '@apollo/client';
import { nanoid } from 'nanoid';

import { Loader } from '@common/Loader';
import { Alert } from '@common/Alert';

import {
    createExternalModuleInvitation,
    createExternalModuleInvitationVariables,
    GetSubspacesForInviteLink,
    GetSubspacesForInviteLink_ownSubspaces,
} from '../apollo-types';
import { CreateInvitationForm } from './CreateInvitationForm';

interface CreateInvitationProps {
    onClose(): void;
}

const GET_SUBSPACES_FOR_INVITE_LINK = gql`
    query GetSubspacesForInviteLink {
        ownSubspaces {
            id
            name
        }
    }
`;

const CREATE_INVITATION = gql`
    mutation createExternalModuleInvitation($createExternalModuleInvitationInput: CreateExternalModuleInvitationInput!) {
        createExternalModuleInvitation(createExternalModuleInvitationInput: $createExternalModuleInvitationInput) {
            id
        }
    }
`;

export function CreateInvitationFormApollo({ onClose }: CreateInvitationProps) {
    const {
        data,
        error,
        loading,
    } = useQuery<GetSubspacesForInviteLink>(GET_SUBSPACES_FOR_INVITE_LINK);

    const [createInvitation] = useMutation<
    createExternalModuleInvitation,
    createExternalModuleInvitationVariables
    >(
        CREATE_INVITATION,
        {
            onError: (er) => {
                setAlerts((arr) => [...arr, (<Alert
                    key={nanoid()}
                    message={er.graphQLErrors[0]?.message || er.message}
                    time={4000}
                />)]);
            },
            onCompleted: () => onClose(),
        },
    );

    const [
        selectedSubspace,
        setSelectedSubspace,
    ] = useState<GetSubspacesForInviteLink_ownSubspaces>();
    const [linkId] = useState<string>(nanoid());
    const [isLinkCopied, setIsLinkCopied] = useState<boolean>(false);
    const [alerts, setAlerts] = useState<JSX.Element[] | []>([]);

    const inviteLink = `${window.location.origin}/invitation/${linkId}`;

    async function onCopyToClipboard() {
        try {
            await navigator.clipboard.writeText(inviteLink);
            setIsLinkCopied(true);
        } catch {
            setAlerts((arr) => [...arr, (<Alert
                key={nanoid()}
                message="Произошла ошибка. Ссылка не была скопирована."
                time={4000}
            />)]);
        }
    }

    function onCreateInviteClick() {
        if (!selectedSubspace) {
            setAlerts((arr) => [...arr, (<Alert
                key={nanoid()}
                message="Необходимо выбрать подпространство."
                time={4000}
            />)]);
            return;
        }
        createInvitation({
            variables: {
                createExternalModuleInvitationInput: {
                    subspaceId: selectedSubspace.id,
                    id: linkId,
                },
            },
        });
    }

    // eslint-disable-next-line consistent-return
    useEffect(() => {
        if (isLinkCopied) {
            const timer1 = setTimeout(() => setIsLinkCopied(false), 4000);
            return () => {
                clearTimeout(timer1);
            };
        }
    }, [isLinkCopied]);

    useEffect(() => {
        if (data?.ownSubspaces.length) {
            setSelectedSubspace(data?.ownSubspaces[0]);
        }
    }, [data]);

    if (loading) {
        return <Loader />;
    }

    if (error) {
        return (
            <div>Произошла ошибка: {error.graphQLErrors[0]?.message || error.message}</div>
        );
    }

    if (!data?.ownSubspaces.length) {
        return (
            <div>Недостаточно прав доступа для создания ссылки-приглашения</div>
        );
    }

    return (
        <CreateInvitationForm
            selectedSubspace={selectedSubspace}
            setSelectedSubspace={setSelectedSubspace}
            isLinkCopied={isLinkCopied}
            inviteLink={inviteLink}
            subspaces={data.ownSubspaces}
            onCreateInviteClick={onCreateInviteClick}
            onCopyToClipboard={onCopyToClipboard}
            onClose={onClose}
            alerts={alerts}
        />
    );
}
