import React, { useState } from 'react';
import { forEach, map } from 'lodash';

import { CreateBuildingInput, TerritorialZone } from '@admin/UniversityPage/types';
import { Confirm } from '@common/Confirm';
import { UniversityBuildingForm } from '../UniversityBuildingForm';
import { addZoneIdToBuildings, trimStateBuildingsValues } from '../UniversityBuildings/utilities';
import classes from './UniversityBuildingEdit.module.scss';

interface Props {
    territorialZones: TerritorialZone[];
    editedBuilding: CreateBuildingInput;

    updateBuilding(building: CreateBuildingInput): void;

    removeBuilding(id: string): void;

    handleChangeListMode(): void;
}

export function UniversityBuildingEdit({
    territorialZones,
    editedBuilding,
    updateBuilding,
    removeBuilding,
    handleChangeListMode,
}: Props): JSX.Element {
    const [stateBuildings, setStateBuildings] = useState<CreateBuildingInput[]>([
        editedBuilding,
    ]);

    const [showConfirm, setShowConfirm] = useState(false);

    const handleSetShowConfirm = (): void => {
        setShowConfirm(!showConfirm);
    };

    const handleInputChange = ({
        target,
    }:
    | React.ChangeEvent<HTMLInputElement>
    | React.ChangeEvent<HTMLSelectElement>): void => {
        const { id, name, value } = target;

        setStateBuildings(
            map(stateBuildings, (building) => (building.id === id ? {
                ...building,
                [name]: value,
            } : building)),
        );
    };

    const handleRemove = (id: string): void => {
        handleSetShowConfirm();
        removeBuilding(id);
        handleChangeListMode();
    };

    const handleSubmit = (event: React.SyntheticEvent): void => {
        event.preventDefault();

        setStateBuildings(trimStateBuildingsValues(stateBuildings));

        forEach(
            addZoneIdToBuildings(territorialZones, stateBuildings),
            (building) => {
                updateBuilding(building);
            },
        );

        handleChangeListMode();
    };

    return (
        <>
            {showConfirm && (
                <Confirm
                    headerText="Внимание"
                    descriptionText="Вы действительно хотите удалить корпус?"
                    secondaryBtnText="Нет"
                    primaryBtnText="Да"
                    onSecondaryBtnClick={handleSetShowConfirm}
                    onPrimaryBtnClick={() => handleRemove(editedBuilding.id)}
                    onOutClick={handleSetShowConfirm}
                />
            )}
            <div className={classes.buildingEdit}>
                <p className={classes.buildingEdit__title}>Редактировать корпус:</p>
                <UniversityBuildingForm
                    handleInputChange={handleInputChange}
                    territorialZones={territorialZones}
                    buildings={stateBuildings}
                    handleChangeListMode={handleChangeListMode}
                    addRemoveBtnLabel="удалить корпус"
                    handleAddRemoveBtn={handleSetShowConfirm}
                    onSubmit={handleSubmit}
                />
            </div>
        </>

    );
}
