import React, { useRef } from 'react';
import cn from 'classnames';

import { useResetEscapeMouseClick } from '@common/hooks/hooks';
import { Icon } from '@admin/NewModule/CustomIcon';

import classes from './validationPopup.module.scss';

interface Props {
    title?: string;
    isOpen?: boolean;
    children?: React.ReactNode;
    onClose(): void;
}
export const ValidationPopup = ({
    title,
    isOpen,
    children,
    onClose,
}: Props): JSX.Element => {
    const refPopup = useRef<null | HTMLDivElement>(null);

    useResetEscapeMouseClick(refPopup, onClose, 0);

    return (
        <section
            className={cn(classes.popup, { [classes.popup_opened]: isOpen })}
            ref={refPopup}
        >
            <div className={classes.popup__container}>
                <Icon onClick={onClose} id="blackCross" modifier="close" />

                <form className={classes.popup__formContainer} noValidate>
                    <h2 className={classes.popup__title}>{title}</h2>
                    {children}
                </form>
            </div>
        </section>
    );
};
