import { gql } from '@apollo/client';
import { useMutationWithErrorHandling } from '@admin/ScheduleGenerationPage/graphql/useMutationWithErrorHandling';

export interface ApproveGeneratedEventsInput {
    from: Date;
    to: Date;
}

export const APPROVE_GENERATED_EVENTS = gql`
    mutation ApproveGeneratedEvents ($approveGeneratedEventsInput: ApproveGeneratedEventsInput!) {
        approveGeneratedEvents(approveGeneratedEventsInput: $approveGeneratedEventsInput) {
            meetingInstances {
                id
            }
        }
    }
`;

export const useApproveGeneratedEventsMutation = (baseTimeIntervalInstanceId: string) => {
    const [approveGeneratedEvents, {
        loading,
        error,
    }] = useMutationWithErrorHandling(APPROVE_GENERATED_EVENTS);

    return {
        approveGeneratedEvents: (
            from: Date,
            to: Date,
            types: string[],
        ) => approveGeneratedEvents({ variables: {
            approveGeneratedEventsInput: { from, to, baseTimeIntervalInstanceId, types },
        } }),
        loading,
        error,
    };
};
