import React from 'react';
import { Icon } from '@admin/NewModule/CustomIcon';
import { Select } from '@admin/NewModule/Select';
import styles from './SelectedItem.module.scss';

interface Option {
    id: string;
    name: string;
}

interface SelectedItemProps {
    text?: JSX.Element;
    id?: string;
    onRemoveItem: any;
    options: Option[];

    onChange?(event: any): void;

    value?: string;
    name: string;
}

export const SelectedLevelItem = ({
    text,
    onRemoveItem,
    id,
    options,
    onChange,
    value,
    name,
}: SelectedItemProps): JSX.Element => {
    const onClick = () => {
        onRemoveItem(id);
    };
    return (
        <li className={styles.selectedItem}>
            <div className={styles.selectedItem__item}>
                <div className={styles.selectedItem__block}>
                    {text}
                    <Select
                        name={name}
                        value={value}
                        options={options}
                        onChange={onChange}
                        isNumber
                    />
                </div>
            </div>
            <Icon id="remove" onClick={onClick} />
        </li>
    );
};
