import map from 'lodash/map';
import React from 'react';
import { SubspaceSkill } from '../subSpaceTypes';
import { skillTypesData_skillTypes } from '../../deprecated-graphql-query-types';
import classes from './SlotParametersListItem.module.scss';

interface Props {
    skillType: skillTypesData_skillTypes
    skillsList: SubspaceSkill[]
}

export function SlotParametersListItem({ skillType, skillsList }: Props): JSX.Element {
    return (
        <div className={classes.item}>
            <p className={classes.item__typeName}>
                {skillType.name.genitiveSingular}:
            </p>
            <ul className={classes.skillsList}>
                {map(skillsList, skillListItem => (
                    <li className={classes.skillsList__item}>
                        <span className={
                            classes.skillsList__name
                        }
                        >
                            {
                                skillListItem.skill.name === skillListItem.skill.fullName
                                    ? skillListItem.skill.fullName
                                    : skillListItem.skill.name
                            }
                        </span>
                        <span className={
                            classes.skillsList__level
                        }
                        >
                            {
                                skillListItem.skill.name === skillListItem.skill.fullName
                                    ? null
                                    : skillListItem.level
                            }
                        </span>
                    </li>
                ))}

            </ul>
        </div>
    );
}
