import React from 'react';

import { Redirect, Switch, useRouteMatch } from 'react-router-dom';

import { Tabs } from '@common/Tabs';
import { AuthRoute } from '@common/AuthRoute';
import { FormatsList } from './FormatsList';
import { FormatsForm } from './FormatsForm';
import { ViewType } from '../../deprecated-graphql-query-types';

interface Format {
    id: string;
    name: string;
}

interface Props {
    meetingFormats: Format[];
    createMeetingFormat: (id: string, name: string) => void;
    updateMeetingFormat: (id: string, name: string) => void;
    deleteMeetingFormat: (id: string) => void;
}

export function Formats({
    meetingFormats,
    createMeetingFormat,
    updateMeetingFormat,
    deleteMeetingFormat,
}: Props): JSX.Element {
    const { path } = useRouteMatch();
    return (
        <>
            <Tabs
                tabsOptions={[
                    [
                        'Список форматов встреч',
                        'list',
                    ],
                    [
                        'Добавление форматов встреч',
                        'add',
                    ],
                ]}
            />
            <Switch>
                <AuthRoute exact path={`${path}`} requiredUserTypes={[ViewType.Admin]}>
                    {meetingFormats.length > 0
                        ? <Redirect to={`${path}/list`} />
                        : <Redirect to={`${path}/add`} />
                    }
                </AuthRoute>
                <AuthRoute exact path={`${path}/list`} requiredUserTypes={[ViewType.Admin]}>
                    <FormatsList
                        meetingFormats={meetingFormats}
                        updateMeetingFormat={updateMeetingFormat}
                        deleteMeetingFormat={deleteMeetingFormat}
                    />
                </AuthRoute>
                <AuthRoute exact path={`${path}/add`} requiredUserTypes={[ViewType.Admin]}>
                    <FormatsForm
                        createMeetingFormat={createMeetingFormat}
                    />
                </AuthRoute>
            </Switch>
        </>
    );
}
