import React from 'react';

import { Select } from '@common/Select';

import classes from './Graph.module.scss';

interface Props {
    changeGraphLayout: (layout: string) => void;
    changeGraphOrientation: (orientation: string) => void;
    toggleEventTypeButton: () => void;
}

export function GraphOptions({
    changeGraphLayout,
    changeGraphOrientation,
    toggleEventTypeButton,
}: Props): JSX.Element {
    return (
        <div className={classes.graphOptions}>
            <div className={classes.graphOptions__selectContainer}>
                <div className={classes.graphOptions__label}>
                    Ориентация
                </div>
                <Select
                    options={[
                        { id: 'TB', name: 'Вертикально' },
                        { id: 'LR', name: 'Горизонтально' },
                    ]}
                    formatOption={(item: { id: string, name:string }) => item.name}
                    onChange={
                        (item: { id: string, name:string }) => changeGraphOrientation(item.id)}
                    className={classes.graphOptions__select}
                />
            </div>
            <div className={classes.graphOptions__selectContainer}>
                <div className={classes.graphOptions__label}>
                    Вид графа
                </div>
                <Select
                    options={[
                        { id: 'dagre', name: 'Ориентированный ацикличный' },
                        { id: 'circular', name: 'Круговой' },
                        { id: 'radial', name: 'Радиальный' },
                        { id: 'concentric', name: 'Концентрированный' },
                        { id: 'grid', name: 'Сеточный' },
                        { id: 'comboForce', name: 'Силовой комбинированный' },
                        { id: 'force', name: 'Силовой (force)' },
                        { id: 'gForce', name: 'Силовой (gForce)' },
                        { id: 'mds', name: 'Многомерно масштабированный' },
                        { id: 'random', name: 'Сформированный случайно' },
                    ]}
                    formatOption={(item: { id: string, name:string }) => item.name}
                    onChange={(item: { id: string, name:string }) => changeGraphLayout(item.id)}
                    className={classes.graphOptions__select}
                />
            </div>
            {/* <div
                className={classes.graphOptions__eventCircle}
                onClick={toggleEventTypeButton}
            /> */}
            <div className={classes.graphOptions__chartContainer}>
                <div
                    className={classes.graphOptions__chart}
                    onClick={toggleEventTypeButton}
                >
                    <div
                        className={classes.graphOptions__chartTriangle}
                        style={{ transform: 'rotate(0deg)' }}
                    >
                        <div
                            className={classes.graphOptions__chartCircle}
                            style={{ background: '#CDF3A2' }}
                        />
                    </div>
                    <div
                        className={classes.graphOptions__chartTriangle}
                        style={{ transform: 'rotate(120deg)' }}
                    >
                        <div
                            className={classes.graphOptions__chartCircle}
                            style={{ background: '#FADE5C' }}
                        />
                    </div>
                    <div
                        className={classes.graphOptions__chartTriangle}
                        style={{ transform: 'rotate(240deg)' }}
                    >
                        <div
                            className={classes.graphOptions__chartCircle}
                            style={{ background: '#A5FAF4' }}
                        />
                    </div>
                </div>
            </div>
            {/* <div className={classes.graphOptions__selectContainer}>
                <div className={classes.graphOptions__label}>
                    Вписывать граф в контейнер
                </div>
                <Select
                    options={[
                        { id: 'true', name: 'Да' },
                        { id: '', name: 'Нет' },
                    ]}
                    formatOption={(item: { id: string, name:string }) => item.name}
                    onChange={(item: { id: string, name:string }) => changeGraphFitView(item.id)}
                    className={classes.graphOptions__select}
                />
            </div> */}
        </div>
    );
}
