import React, { JSXElementConstructor, useState, useEffect } from 'react';
import { BorderPlusIcon } from '@common/svg';
import { Button } from '@admin/NewModule/CustomButton';
import cn from 'classnames';
import { SectionList } from './SectionList';

import styles from './Section.module.scss';

interface Option {
    id: string;
    name: string;
}

interface ButtonInfo {
    name: string;
    down: boolean;
    isBorder?: boolean;
    noButton?: boolean;
    itemWithLevel?: boolean;
    selectedOptions?: {
        name: string;
        options: Option[]
    }[];
    onChangeSelected?: any;
    values?: string[];
}

interface Props<T extends BaseItem, C extends FormChild<T>> {
    list: T[];
    initialData: T;
    form?: JSXElementConstructor<C>;
    formProps: any;
    buttonInfo?: ButtonInfo;

    onChange?(items: T[]): void;

    formatItem?(item: T): JSX.Element;

    validate?(item: T): boolean;
}

interface BaseItem {
    id: string;
}

interface FormChild<T extends BaseItem> {
    data: T,

    setData(data: T): void;
}

export function Section<T extends BaseItem, C extends FormChild<T>>({
    initialData,
    list,
    formProps,
    buttonInfo,
    form,
    formatItem,
    validate,
    onChange,
}: Props<T, C>): JSX.Element {
    const [data, setData] = useState<T>(initialData);
    const [dataList, setDataList] = useState<T[]>(list);

    useEffect(() => {
        setDataList(list);
    }, [list]);

    const onRemove = (id: string) => {
        const newDataList = dataList.filter((item) => item.id !== id);
        setDataList(newDataList);
        return onChange && onChange(newDataList);
    };

    const validateNewItem = () => {
        if (!data || !data?.id) {
            return false;
        }
        const hasData = dataList.some(knownData => knownData.id === data.id);
        if (hasData) {
            setData({ ...initialData, id: null });
            return false;
        }
        return validate ? validate(data) : true;
    };

    const addItem = () => {
        if (!validateNewItem()) {
            return {};
        }
        const newDataList = [...dataList, data];
        setDataList(newDataList);
        setData({ ...initialData, id: null });
        return onChange && onChange(newDataList);
    };
    const customFormProps = formProps.mainOptions ? {
        ...formProps,
        mainOptions: formProps.mainOptions.filter((option: { id: string }) => {
            const haveOptionInList = dataList.some(({ id }) => id === option.id);
            return !haveOptionInList;
        }),
    } : formProps;
    const child = form && React.createElement(form, {
        data,
        onChange: buttonInfo?.noButton ? addItem : () => { },
        ...customFormProps,
        setData,
        onEnter: addItem,
    } as any);

    return (
        <>
            <div className={cn({
                [styles.sectionForm]: !buttonInfo?.down,
                [styles.sectionForm_down]: buttonInfo?.down,
                [styles.sectionForm_border]: buttonInfo?.isBorder,
            })}
            >{child}
                {!buttonInfo?.noButton
                    ? (
                        <div className={buttonInfo?.down
                            ? styles.sectionForm__button_down : styles.sectionForm__button}
                        >
                            <Button modifier="add" onClick={addItem}>
                                <div className={styles.sectionForm__icon}>
                                    <BorderPlusIcon size={20} />
                                    <span className={styles.sectionForm__iconText}>
                                        {buttonInfo?.name}
                                    </span>
                                </div>
                            </Button>
                        </div>
                    )
                    : <></>
                }
            </div>
            <ul className={styles.sectionList}>
                <SectionList
                    list={dataList}
                    formatItem={formatItem}
                    onRemove={onRemove}
                    options={buttonInfo?.selectedOptions}
                    itemWithLevel={buttonInfo?.itemWithLevel}
                    onChange={buttonInfo?.onChangeSelected}
                    values={buttonInfo?.values}
                />
            </ul>
        </>
    );
}
