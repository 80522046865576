import React from 'react';
import cn from 'classnames';
import { observer } from 'mobx-react';

import store, { PrioritySetMode } from '../../store';

import classes from './ModuleCardHeader.module.scss';

interface Props {
    priority: number;
    forkId?: string;
    slot?: {
        slotId: string;
        priority: number;
    };
    moduleId: string;
    setStudentPriorities?: (
        setMode: PrioritySetMode,
        forkId: string,
        moduleId: string,
        priority?: number,
    ) => void;
}

export const Priority = observer(({
    priority,
    forkId = '',
    slot,
    moduleId,
    setStudentPriorities,
}: Props) => {
    const activePriority = classes
        .moduleContent__priorities_priorityActive;
    const disablePriority = classes
        .moduleContent__priorities_priorityDisable;
    const isSelected = slot?.priority === priority;
    const isDisable = store
        .isPrioritySelected(forkId, slot?.slotId, priority);
    if (priority === 1) {
        return (
            <div
                key={priority}
                style={{ cursor: 'pointer' }}
                onClick={() => {
                    if (isSelected) {
                        setStudentPriorities!(PrioritySetMode.DELETE, forkId, moduleId);
                    } else {
                        setStudentPriorities!(
                            PrioritySetMode.UPDATE,
                            forkId,
                            moduleId,
                            priority,
                        );
                    }
                }}
            >
                {!isDisable && (isSelected ? (
                    <svg width="44" height="42" viewBox="0 0 44 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M20.2718 1.96718C21.0432 0.642642 22.9568 0.642642 23.7282 1.96718L29.3152 11.5594C29.5978 12.0446 30.0714 12.3887 30.6201 12.5075L41.4694 14.8569C42.9675 15.1813 43.5588 17.0011 42.5375 18.1441L35.1412 26.4218C34.767 26.8405 34.5862 27.3972 34.6427 27.9559L35.7609 39.0001C35.9154 40.5251 34.3673 41.6498 32.9646 41.0317L22.8065 36.5554C22.2927 36.329 21.7073 36.329 21.1935 36.5554L11.0354 41.0317C9.6327 41.6498 8.08465 40.5251 8.23905 39.0001L9.35727 27.9559C9.41384 27.3972 9.23295 26.8405 8.85883 26.4218L1.46255 18.1441C0.441237 17.0011 1.03254 15.1813 2.53065 14.8569L13.3799 12.5075C13.9287 12.3887 14.4022 12.0446 14.6848 11.5594L20.2718 1.96718Z" fill="#0B42DC" />
                        <path d="M22.2856 29H20.8856V21.524C20.8856 21.188 20.9089 20.8987 20.9556 20.656L18.6316 21.37L18.4636 20.32L20.8856 19.34H22.2856V29Z" fill="white" />
                    </svg>
                ) : (
                    <svg width="44" height="42" viewBox="0 0 44 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M21.2962 2.21883L26.2366 10.701C26.5898 11.3075 27.1818 11.7375 27.8677 11.8861L37.4614 13.9636C38.585 14.2069 39.0285 15.5718 38.2625 16.429L31.7222 23.7488C31.2545 24.2721 31.0284 24.968 31.0991 25.6663L32.0879 35.4324C32.2037 36.5762 31.0427 37.4197 29.9907 36.9562L21.0081 32.9979C20.3658 32.7148 19.6342 32.7148 18.9919 32.9978L10.0093 36.9562C8.95732 37.4197 7.79628 36.5762 7.91208 35.4324L8.90089 25.6663C8.9716 24.968 8.74549 24.2721 8.27784 23.7488L1.73751 16.429C0.971525 15.5718 1.415 14.2069 2.53858 13.9636L12.1322 11.8861C12.8182 11.7375 13.4102 11.3075 13.7634 10.701L18.7038 2.21883C19.2824 1.22543 20.7176 1.22543 21.2962 2.21883Z" fill="white" stroke="#BCBCBC" />
                        <path d="M20.9346 27H19.5346V19.524C19.5346 19.188 19.5579 18.8987 19.6046 18.656L17.2806 19.37L17.1126 18.32L19.5346 17.34H20.9346V27Z" fill="black" />
                    </svg>
                ))}
                {isDisable && (
                    <svg width="40" height="38" viewBox="0 0 40 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M21.2962 2.21883L26.2366 10.701C26.5898 11.3075 27.1818 11.7375 27.8677 11.8861L37.4614 13.9636C38.585 14.2069 39.0285 15.5718 38.2625 16.429L31.7222 23.7488C31.2545 24.2721 31.0284 24.968 31.0991 25.6663L32.0879 35.4324C32.2037 36.5762 31.0427 37.4197 29.9907 36.9562L21.0081 32.9979C20.3658 32.7148 19.6342 32.7148 18.9919 32.9978L10.0093 36.9562C8.95732 37.4197 7.79628 36.5762 7.91208 35.4324L8.90089 25.6663C8.9716 24.968 8.74549 24.2721 8.27784 23.7488L1.73751 16.429C0.971525 15.5718 1.415 14.2069 2.53858 13.9636L12.1322 11.8861C12.8182 11.7375 13.4102 11.3075 13.7634 10.701L18.7038 2.21883C19.2824 1.22543 20.7176 1.22543 21.2962 2.21883Z" fill="white" stroke="#BCBCBC" />
                        <path d="M20.8639 27H19.6319V19.342C19.6319 19.0153 19.6553 18.7353 19.7019 18.502L17.3359 19.244L17.1679 18.334L19.6319 17.34H20.8639V27Z" fill="#0B42DC" />
                    </svg>
                )}
            </div>
        );
    }
    return (
        <div
            key={priority}
            className={cn(
                classes.moduleContent__priorities_priority,
                {
                    [activePriority]: isSelected,
                    [disablePriority]: isDisable,
                },
            )
            }
            onClick={() => {
                if (isSelected) {
                    setStudentPriorities!(PrioritySetMode.DELETE, forkId, moduleId);
                } else {
                    setStudentPriorities!(
                        PrioritySetMode.UPDATE,
                        forkId,
                        moduleId,
                        priority,
                    );
                }
            }}
        >
            {priority}
        </div>
    );
});
