/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { GetEquipmentsOnRoomPage_equipment_list } from 'src/graphql-query-types';
import { Alert } from '@common/Alert';
import { AddItems } from './AddItems';
import { RoomCreationEquipment, AlertMessage } from '../../RoomTypes';

import classes from './AddItems.module.scss';

interface AddItemsProps {
    storeEquipment?: GetEquipmentsOnRoomPage_equipment_list[];
    equipmentsRoom: RoomCreationEquipment[];
    addRoomItems(obj: RoomCreationEquipment): void;
    removeRoomItems(id: string): void;
}

interface EquipmentWithBalanceCount extends GetEquipmentsOnRoomPage_equipment_list {
    count: number;
}

interface Suggestion {
    activeSuggestion: number;
    filteredSuggestions: EquipmentWithBalanceCount[];
    showSuggestions: boolean;
}

export const AddItemsContainer = (
    {
        addRoomItems,
        removeRoomItems,
        equipmentsRoom,
        storeEquipment,
    }: AddItemsProps,
) => {
    const [equipmentWithBalanceCount] = useState(storeEquipment
        ?.map(equip => ({
            ...equip,
            count: getEquipmentBalanceForRooms(equip),
        })) ?? []);

    const [suggestion, setSuggestion] = useState<Suggestion>({
        activeSuggestion: 0,
        filteredSuggestions: equipmentWithBalanceCount ?? [],
        showSuggestions: false,
    });

    const [item, setItem] = useState<RoomCreationEquipment>({ id: '', name: '', count: 0 });

    const [alert, setAlert] = useState<AlertMessage>({
        alert: false,
        message: '',
    });

    const addItemsInRoom = () => {
        if (item.name.length > 0 && item.count > 0 && equipmentWithBalanceCount !== undefined) {
            const equipment = equipmentWithBalanceCount
                .find(element => element.category.name === item.name && element.id === item.id);
            if (equipment && equipment.count >= item.count) {
                addRoomItems(item);
                setItem({
                    ...item,
                    id: '',
                    name: '',
                    count: 0,
                });
                setSuggestion({
                    ...suggestion, showSuggestions: false,
                });
            } else if (!equipment) {
                setAlert({ alert: true, message: 'Выберите оборудование из списка' });
            } else if (equipment && equipment.count < item.count) {
                setAlert({ alert: true, message: 'Извините, оборудование уже добавлено или такого количества не хватает в остатках' });
            } else {
                setAlert({ alert: true, message: 'Извините, но такого оборудования не хватает в остатках' });
            }
        }
    };

    const handleChangeEquip = ({ target }: React.ChangeEvent<HTMLInputElement>): void => {
        const { value } = target;

        setItem({
            ...item,
            name: `${value.charAt(0).toUpperCase()}${value.slice(1)}`,
        });

        if (equipmentWithBalanceCount !== undefined) {
            const filteredSuggestionsArray = equipmentWithBalanceCount
                .filter(({ category: { name } }) => name.toLowerCase().includes(value.toLowerCase()));
            setSuggestion({
                ...suggestion,
                activeSuggestion: 0,
                filteredSuggestions: filteredSuggestionsArray,
                showSuggestions: (Boolean(value)),
            });
        }
    };

    const handleChangeCount = ({ currentTarget }: React.ChangeEvent<HTMLInputElement>): void => {
        const { value } = currentTarget;

        setItem({
            ...item,
            count: Math.round(Number(value)),
            id: item.id,
        });
    };

    const addItemOnClick = ({ currentTarget }: any) => {
        setSuggestion({
            ...suggestion,
            filteredSuggestions: [],
            showSuggestions: false,
        });
        setItem({
            ...item,
            name: currentTarget.firstChild.textContent,
            id: currentTarget.id,
        });
    };

    const removeItemsFromList = (id: string) => {
        removeRoomItems(id);
    };

    const closeSuggestionList = () => {
        setSuggestion({ ...suggestion, showSuggestions: false });
    };

    useEffect(() => {
        document.addEventListener('click', closeSuggestionList);
        return () => document.removeEventListener('click', closeSuggestionList);
    }, []);

    useEffect(() => {
        const alertTimeout = setTimeout(() => setAlert({ alert: false, message: '' }), 2000);
        return () => {
            clearTimeout(alertTimeout);
        };
    }, [alert]);
    function getEquipmentBalanceForRooms(equipment: GetEquipmentsOnRoomPage_equipment_list) {
        const equipmentCountInRooms = equipment.rooms
            .reduce((acc, { countEquipments }) => acc + countEquipments, 0);
        const noMovableEquipmentCount = equipment.movability.find((movability) => !movability.isMovable)?.count ?? 0;
        return noMovableEquipmentCount - equipmentCountInRooms;
    }
    // eslint-disable-next-line consistent-return
    const showListItems = () => {
        const { showSuggestions, activeSuggestion, filteredSuggestions } = suggestion;

        if (showSuggestions) {
            if (filteredSuggestions.length > 0) {
                let className: string;
                return (
                    <ul
                        className={classes.suggestions}
                    >
                        {
                            filteredSuggestions.map((element, index) => {
                                if (index === activeSuggestion) {
                                    className = `${classes.suggestionActive}`;
                                }
                                return (
                                    // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
                                    <li
                                        className={className}
                                        key={element.id}
                                        onClick={(e) => addItemOnClick(e)}
                                        id={element.id}
                                    >
                                        <span>{element.category.name}</span>
                                        <span className={classes.item__count}>{element.count}</span>
                                    </li>
                                );
                            })
                        }
                    </ul>
                );
            } if (filteredSuggestions.length === 0 && item.name.length > 0) {
                return (
                    <ul className={classes.noSuggestions}>
                        <li>нет такого оборудования, но <a href="/university/equipment/all">можно создать!</a></li>
                    </ul>
                );
            }
        }
    };
    return (
        <>
            <AddItems
                item={item}
                handleChangeEquip={handleChangeEquip}
                handleChangeCount={handleChangeCount}
                // handleKeyDown={handleKeyDown}
                addItemsInRoom={addItemsInRoom}
                showListItems={showListItems}
                removeItems={removeItemsFromList}
                equipmentList={equipmentsRoom}
            />
            {
                alert.alert && <Alert message={alert.message} time={6000} />
            }
        </>
    );
};
