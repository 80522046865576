import { TimeReservingEvent } from '../../Interfaces';

const MINS_IN_DAY = 24 * 60;

interface ScheduleEventSizesAndPositioningStyle {
    width: string;
    height: string;
    left: string;
    top: string;
}

export function getScheduleEventSizesAndPositioningStyle(
    dateEvent: TimeReservingEvent,
    allDateEvents: TimeReservingEvent[],
): ScheduleEventSizesAndPositioningStyle {
    return {
        width: `${getScheduleEventWidthInPercents(dateEvent, allDateEvents)}%`,
        height: `${getScheduleEventHeightInPercents(dateEvent)}%`,
        left: `${getScheduleEventOffsetLeftInPercents(dateEvent, allDateEvents)}%`,
        top: `${getScheduleEventOffsetTopInPercents(dateEvent)}%`,
    };
}

function getScheduleEventWidthInPercents(
    dateEvent: TimeReservingEvent,
    allDayEvents: TimeReservingEvent[],
): number {
    const intersections: number = getScheduleEventIntersections(dateEvent, allDayEvents).length;

    if (intersections) {
        return 100 / intersections;
    }

    return 100;
}

function getScheduleEventHeightInPercents(dateEvent: TimeReservingEvent): number {
    const startMeetingTimestamp = Number(dateEvent.startDate);
    const endMeetingTimestamp = Number(dateEvent.endDate);
    const meetingDurationInMins = Math.round(
        (endMeetingTimestamp - startMeetingTimestamp) / 1000 / 60,
    );
    return (meetingDurationInMins / MINS_IN_DAY) * 100;
}

function getScheduleEventOffsetLeftInPercents(
    dateEvent: TimeReservingEvent,
    allDayEvents: TimeReservingEvent[],
): number {
    const intersections = getScheduleEventIntersections(dateEvent, allDayEvents);

    const intersectionIndex = intersections
        .findIndex((scheduleEvent: TimeReservingEvent) => (
            scheduleEvent.id === dateEvent.id
        ));

    return (intersectionIndex / intersections.length) * 100;
}

function getScheduleEventOffsetTopInPercents(dateEvent: TimeReservingEvent): number {
    return ((dateEvent.startDate.getHours() * 60 + dateEvent.startDate.getMinutes())
        / MINS_IN_DAY) * 100;
}

function getScheduleEventIntersections(
    dateEvent: TimeReservingEvent,
    allDayEvents: TimeReservingEvent[],
): TimeReservingEvent[] {
    const intersections: TimeReservingEvent[] = [];

    const dateEventTimePeriod = {
        start: dateEvent.startDate,
        end: dateEvent.endDate,
    };

    allDayEvents.forEach(
        (checkEvent) => {
            const checkEventTimePeriod = {
                start: checkEvent.startDate,
                end: checkEvent.endDate,
            };

            if (
                (isTimePointInTimePeriod(checkEvent.startDate, dateEventTimePeriod)
                || isTimePointInTimePeriod(checkEvent.endDate, dateEventTimePeriod)
                || isTimePeriodInsideTimePeriod(dateEventTimePeriod, checkEventTimePeriod))
                && !(isTimePeriodConnectTimePeriod(dateEventTimePeriod, checkEventTimePeriod))
            ) {
                intersections.push(checkEvent);
            }
        },
    );

    return intersections;
}

function isTimePointInTimePeriod(timePoint: Date, timePeriod: TimePeriod): boolean {
    return timePoint >= timePeriod.start && timePoint <= timePeriod.end;
}

function isTimePeriodInsideTimePeriod(
    timePeriodInside: TimePeriod,
    timePeriodWrapper: TimePeriod,
): boolean {
    return (
        timePeriodInside.start >= timePeriodWrapper.start
        && timePeriodInside.end <= timePeriodWrapper.end
    );
}

function isTimePeriodConnectTimePeriod(
    timePeriodConnecting: TimePeriod,
    timePeriod: TimePeriod,
): boolean {
    return (
        Number(timePeriodConnecting.end) === Number(timePeriod.start)
        || Number(timePeriodConnecting.start) === Number(timePeriod.end)
    );
}

interface TimePeriod {
    start: Date;
    end: Date;
}
