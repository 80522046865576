import { gql } from '@apollo/client';
import { ModuleSettings } from '../ToolBar/Tools/SkillsButton/PopupWithSkills/interfaces/moduleSettings';
import { SkillType } from '../ToolBar/Tools/SkillsButton/PopupWithSkills/interfaces/skillType';

export interface SkillTypesWithModuleSettings {
    skillTypes: SkillType[];
    moduleSettings: ModuleSettings;
}

export const GET_SKILL_TYPES_WITH_MODULE_SETTINGS = gql`
    query {
        skillTypes {
            id
            parentId
            name {
                nominativePlural
            }
        }

        moduleSettings {
            moduleSkillTypes {
                id
                isLevelIncreased
            }
            meetingSkillTypes {
                id
                isLevelIncreased
            }
        }
    }
`;
