import React from 'react';
import cn from 'classnames';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import classes from './Legend.module.scss';

export const Legend = (): JSX.Element => (
    <div className={classes.legend}>
        <div className={classes.legend__firstColumnCell}>
            <div className={classes.legend__item}>
                <div
                    className={
                        cn(classes.legend_color, classes.legend_color_red)
                    }
                />
                <div className={classes.legend__description}>
                    - больше 5 конфликтов
                </div>
            </div>
            <div className={classes.legend__item}>
                <div
                    className={
                        cn(classes.legend_color, classes.legend_color_orange)
                    }
                />
                <div className={classes.legend__description}>
                    - от 2 до 5 конфликтов
                </div>
            </div>
        </div>
        <div className={classes.legend__secondColumnCell}>
            <div className={classes.legend__item}>
                <div
                    className={
                        cn(classes.legend_color, classes.legend_color_gray)
                    }
                />
                <div className={classes.legend__description}>
                    - от 1 до 2 конфликтов
                </div>
            </div>
            <div className={classes.legend__item}>
                <div
                    className={
                        cn(classes.legend_color, classes.legend_color_white)
                    }
                />
                <div className={classes.legend__description}>
                    - нет конфликтов
                </div>
            </div>
        </div>
        <div className={classes.legend__rowCell}>
            <div className={classes.legend__columnName} style={{ width: 149 }}>
                Модули
            </div>
            <div className={classes.legend__columnName} style={{ width: 182 }}>
                Роли
            </div>
        </div>
    </div>
);
