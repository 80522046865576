import React from 'react';

import { IconDeprecated, Switcher } from '@common';

import classes from './EducationSpaceInfo.module.scss';

import { StudentPickingSelect } from './StudentPickingSelect';
import { SubspaceSelect } from './SubspaceSelect';

export function SubspaceOptions(): JSX.Element {
    return (
        <div className={classes.subspaceOptions}>
            <div className={classes.subspaceOptions__typeContainer}>
                <div className={classes.subspaceOptions__typeText}>
                    Выбор подпространства из типа MAJOR
                </div>
                <IconDeprecated
                    id="Cross"
                    className={classes.subspaceOptions__typeIcon}
                />
            </div>
            <Switcher
                label="Можно записаться больше вместимости подпространства"
            />
            <StudentPickingSelect />
            <Switcher
                label="Автоматически распределять неопределившихся студентов"
            />
            <SubspaceSelect />
        </div>
    );
}
