import { nanoid } from 'nanoid';
import { moduleStore } from './moduleStore';
import { dictionaryStore } from './dictionaryStore';
import {
    CreateModuleInput, SendSkill, SendAssignment, SendMeeting, SendEvaluationPoint,
} from './sendTypes';
import { ModuleEquipment, ModuleSkill } from './moduleStoreTypes';

const getNormalizeSkills = (skills: ModuleSkill[]): SendSkill[] => skills
    .map(skill => (Number.isInteger(skill.level)
        ? { id: skill.skill.id, level: skill.level }
        : { id: skill.skill.id }));

const getMutationMeetingsState = (): SendMeeting[] => moduleStore.moduleModel.meetings
    .map(meeting => {
        const {
            id,
            topic,
            order,
            meetingIdForTeacherStaff,
            dependentEvents,
            isPreviousEventInRow,
            isNextEventInRow,
            isManyWaves,
            isOnline,
            isOutside,
        } = meeting;
        const { options } = moduleStore.moduleModel;
        function getNormalizeEquipmentForMutation(equipment: ModuleEquipment) {
            const currentEquipment = dictionaryStore.equipment
                .list.find((item) => item.id === equipment.id);
            return ({
                categoryId: equipment.category.id ?? currentEquipment?.category.id ?? equipment.id,
                count: equipment.count ?? 0,
            });
        }
        return {
            id,
            topic,
            order,
            meetingIdForTeacherStaff,
            dependentEvents,
            isManyWaves,
            minStudentCount: Number(options.minStudentCount),
            maxStudentCount: isManyWaves
                ? Number(meeting.maxStudentCount)
                : Number(options.maxStudentCount),
            isPreviousEventInRow,
            isNextEventInRow,
            minDaysToPreviousEvent: Number(meeting.minDaysToPreviousEvent ?? undefined) ?? null,
            maxDaysToPreviousEvent: Number(meeting.maxDaysToPreviousEvent ?? undefined) ?? null,
            minDaysToNextEvent: Number(meeting.minDaysToNextEvent ?? undefined) ?? null,
            maxDaysToNextEvent: Number(meeting.maxDaysToNextEvent ?? undefined) ?? null,
            formatId: meeting.format?.id ?? null,
            isOnline,
            isOutside,
            durationId: meeting.duration?.id ?? null,
            equipmentPerMeeting: meeting.equipmentPerMeeting
                ?.map(equip => (getNormalizeEquipmentForMutation(equip))) ?? [],
            equipmentPerStudent: meeting.equipmentPerStudent
                ?.map(equip => (getNormalizeEquipmentForMutation(equip))) ?? [],
            consumableEquipmentPerMeeting: meeting.consumableEquipmentPerMeeting?.map(equip => ({
                id: equip.consumableEquipment.id, count: equip.count!,
            })) ?? [],
            consumableEquipmentPerStudent: meeting.consumableEquipmentPerStudent?.map(equip => ({
                id: equip.consumableEquipment.id, count: equip.count!,
            })) ?? [],
            prerequisiteSkills: getNormalizeSkills(meeting.prerequisiteSkills ?? []),
            outputSkills: getNormalizeSkills(meeting.outputSkills ?? []),
            meetingTeacherRoles: moduleStore.removeNonexistentRoles(
                meeting.meetingTeacherRoles ?? [],
            ).map(role => ({ id: role.teacherRole.id, count: role.count })),
        };
    });

const getMutationAssignmentsState = (): SendAssignment[] => moduleStore.moduleModel.assignments
    .map(assignment => {
        const {
            id, topic, order, dependentEvents, description,
        } = assignment;

        return {
            id,
            topic: topic ?? '',
            description,
            dependentEvents,
            durationId: assignment.duration?.id ?? null,
            order,
            prerequisiteSkills: getNormalizeSkills(assignment.prerequisiteSkills ?? []),
            outputSkills: getNormalizeSkills(assignment.outputSkills ?? []),
            isPreviousEventInRow: assignment.isPreviousEventInRow ?? false,
            isNextEventInRow: assignment.isNextEventInRow ?? false,
            minDaysToPreviousEvent: Number(assignment.minDaysToPreviousEvent ?? undefined) ?? null,
            maxDaysToPreviousEvent: Number(assignment.maxDaysToPreviousEvent ?? undefined) ?? null,
            minDaysToNextEvent: Number(assignment.minDaysToNextEvent ?? undefined) ?? null,
            maxDaysToNextEvent: Number(assignment.maxDaysToNextEvent ?? undefined) ?? null,
        };
    });

const getMutationEvaluationPointsState = (): SendEvaluationPoint[] => moduleStore.moduleModel
    .evaluationPoints.map(point => {
        const {
            id, topic, description, previousEvent, order,
        } = point;

        return {
            id,
            topic: topic ?? '',
            description: description ?? '',
            previousEvent: previousEvent ?? null,
            order,
            evaluationPointTeacherRoles: moduleStore
                .removeNonexistentRoles(point.evaluationPointTeacherRoles ?? [])
                .map(role => ({ id: role.teacherRole.id, count: role.count })),
            evaluationPointSkills: getNormalizeSkills(point.evaluationPointSkills ?? []) ?? [],
            durationId: point.duration?.id ?? null,
        };
    });

const validateNumber = (num?: number): number => ((num && num > 0) ? num : 0);

export const getMutationModuleState = (): CreateModuleInput => {
    const {
        name, description, imageUrl, minStudentCount, maxStudentCount, maxWaveCount,
    } = moduleStore.moduleModel.options;

    const filterModuleSkills = (skills: ModuleSkill[]) => skills.filter(skill => {
        const { moduleSkillTypes } = dictionaryStore.moduleSettings;
        return moduleSkillTypes.some(({ id }) => id === skill.skill.typeId);
    });

    const id = moduleStore.moduleModel.options.id || nanoid();
    const prerequisiteSkills = getNormalizeSkills(
        filterModuleSkills(moduleStore.moduleModel.options.prerequisiteSkills),
    );
    const outputSkills = getNormalizeSkills(
        filterModuleSkills(moduleStore.moduleModel.options.outputSkills),
    );
    const teachersRoles = moduleStore.moduleModel.options.teachersRoles
        .map(role => ({ id: role.teacherRole.id, name: role.teacherRole.name }));
    const moduleTeachers = moduleStore.moduleModel.options.moduleTeachers.map(teacher => ({
        id: teacher.teacher.id, teacherRoleIds: teacher.teacherRoles.map(role => role.id),
    })) || [];
    const meetings = getMutationMeetingsState();
    const evaluationPoints = getMutationEvaluationPointsState();
    const assignments = getMutationAssignmentsState();

    return {
        id,
        name,
        imageUrl,
        description,
        maxWaveCount,
        prerequisiteSkills,
        outputSkills,
        teachersRoles,
        moduleTeachers,
        meetings,
        evaluationPoints,
        assignments,
        minStudentCount: validateNumber(minStudentCount),
        maxStudentCount: validateNumber(maxStudentCount),
    };
};
