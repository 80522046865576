import { gql } from '@apollo/client';

export const TEACHER_LOAD = gql`
  query getTeacherLoad($teacherLoadInput: TeacherLoadInput!) {
    teacherLoad(teacherLoadInput: $teacherLoadInput) {
      modules {
        id
        name
        teachersRoles {
          id
          name
          teachers {
            id
          }
        }
      }
      timeslots {
        date
        startTime
        endTime
        index
        week
        dayOfWeek
      }
      teachers {
        id
        availableHoursCountWithoutReservations
        availableHoursCountInTUniversity
        user {
          id
          lastName
          firstName
          patronymic
        }
        timeslots {
          index
          date
          week
          dayOfWeek
          startTime
          endTime
          isWorking
          isNonWorking
          isReservedByClassicUniversity
          isReservedByTUniversity
          meetingInstances {
            meeting {
              module {
                id
              }
            }
            meetingInstanceToTeachers {
              meetingTeacherRole {
                id
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_SUGGESTED_MODULES = gql`
  query getModulesByNamePattern($value: ModulesByFiltersInput!) {
    modulesByFilters(modulesByFiltersInput: $value) {
      id
      name
    }
  }
`;

export const GET_SUGGESTED_TEACHERS = gql`
  query getModulesTeachers($value: ModulesTeachersInput!) {
    modulesTeachers(modulesTeachersInput: $value) {
      id
      user {
        lastName
        firstName
        patronymic
      }
    }
  }
`;

export const GET_TEACHER_ROLES = gql`
  query getTeacherRoles($value: TeacherRolesInput!) {
    teacherRoles(teacherRolesInput: $value) {
      name
      id
    }
  }
`;
