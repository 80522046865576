import React from 'react';
import DatePicker from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';
import ru from 'date-fns/locale/ru';
import './Calendar.scss';

interface Props {
    currentDate?: Date;
    showTime?: boolean;
    timeInputWithSwitcher?: JSX.Element;
    shouldCloseOnSelect?: boolean;
    modifier?: string;
    autoFocus?: boolean;
    dateFormat?: string;
    showYearPicker?: boolean;
    setTimeInputVisible?: (isVisible: boolean) => void;
    onChange(update: any): void;
    onCloseCalendar?: () => void;
    onOpenCalendar?: () => void;
}

export function Calendar({
    currentDate,
    timeInputWithSwitcher,
    shouldCloseOnSelect,
    modifier,
    autoFocus,
    showTime,
    dateFormat,
    showYearPicker,
    onChange,
    setTimeInputVisible,
    onCloseCalendar,
    onOpenCalendar,
}: Props): JSX.Element {
    return (
        <div className="calendar">
            <DatePicker
                autoFocus={autoFocus}
                selected={currentDate}
                onChange={onChange}
                onCalendarOpen={() => onOpenCalendar && onOpenCalendar()}
                onCalendarClose={() => {
                    if (setTimeInputVisible) {
                        setTimeInputVisible(false);
                    }
                    if (onCloseCalendar) {
                        onCloseCalendar();
                    }
                }}
                isClearable
                placeholderText="Выбрать дату"
                dateFormat={dateFormat ?? 'dd.MM.yyyy'}
                locale={ru}
                className={`calendar__input calendar__input_${modifier}`}
                autoComplete="on"
                // showYearDropdown
                showYearPicker={showYearPicker}
                dropdownMode="scroll"
                customTimeInput={timeInputWithSwitcher}
                showTimeInput={showTime}
                shouldCloseOnSelect={shouldCloseOnSelect}
                timeInputLabel=""
                calendarStartDay={1}
                popperClassName="calendar__popperEducationPeriod"
                wrapperClassName="calendar__wrapperEducationPeriod"
            />
        </div>
    );
}
