import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
// @ts-ignore
import ReactHtmlParser from 'react-html-parser';
import { Alert } from '@common/Alert';
import { observer } from 'mobx-react';
import ConfirmAlert from '@common/ui-elements/confirm-alert';
import { Trajectory } from '@admin/Trajectories/types';
import { Checkbox } from '@admin/Trajectories/Checkbox';
import { useRemoveTrajectoryMutation } from '@admin/Trajectories/hooks/useRemoveTrajectoryMutation';
import { useSubspaceTypesQuery } from '@admin/Trajectories/hooks/useSubspaceTypesQuery';
import { ForwardIcon } from '../../assets/ForwardIcon';
import classes from './TrajectoryCard.module.scss';
import { Subspaces } from './Subspaces';
import { trajectoryStore } from '../../TrajectoryForm/Store/TrajectoryStore';
import { TrajectoryForm } from '../../TrajectoryForm';
import { CloseIconGradient } from '../../assets/CloseIconGradient';

interface Props {
    trajectory: Trajectory;
    spaceId: string;
    handleUpdateSave(id: string): void;
    handleUpdatePublish(id: string): void;
    trajectoryId: boolean;
    editTrajectory(id: string): void;
}

export const TrajectoryCard = observer(({
    trajectory, spaceId, handleUpdateSave, handleUpdatePublish, trajectoryId, editTrajectory,
}: Props): JSX.Element => {
    const { id, name, description, status, subspaces } = trajectory;
    const sortedSubspaces = subspaces.slice().sort((a, b) => (
        (a.type!.isSelective === true) > (b.type!.isSelective) === false ? -1 : 1
    ));
    const [isEdit, setIsEdit] = useState(false);
    const [isConfirm, setIsConfirm] = useState(false);
    const { removeTrajectory, removeError } = useRemoveTrajectoryMutation(spaceId);
    const handleDelete = () => {
        removeTrajectory(id).catch(() => {});
        setIsEdit(!isEdit);
    };
    const handleSave = () => {
        handleUpdateSave(id);
        setIsEdit(!isEdit);
        trajectoryStore.clearInputs();
    };
    const handlePublish = () => {
        handleUpdatePublish(id);
        setIsEdit(!isEdit);
        trajectoryStore.clearInputs();
    };
    const subspaceTypes = useSubspaceTypesQuery(spaceId!);
    const notSelectiveTypes = subspaceTypes?.filter(item => !item.isSelective);

    const handleOpenEditForm = () => {
        trajectoryStore.clearInputs();
        editTrajectory(id);
        setIsEdit(!isEdit);
        trajectoryStore.setName(name);
        trajectoryStore.setDescription(description);
        notSelectiveTypes?.map(item => (
            trajectoryStore.updateSubspaces(item.subspaces[0])
        ));
        subspaces.map(item => (
            item.type?.isSelective && trajectoryStore.updateSubspaces(item)
        ));
    };

    useEffect((): void => {
        if (!trajectoryId) {
            setIsEdit(false);
        }
    }, [trajectoryId]);
    return (
        <>
            {!isEdit
                && (
                    <section className={classes.trajectoryCard}>
                        <div className={classes.trajectoryCard__header}>
                            <Checkbox
                                checked={trajectoryStore.selectedTrajectoriesIds.includes(id)}
                                onChange={() => { trajectoryStore.updateSelectedIds(id); }}
                            />
                            <div className={classes.trajectoryCard__status}>
                                <div
                                    className={classnames(classes.trajectoryCard__colorSign, {
                                        [classes.trajectoryCard__colorSign_generation]: status === 'inactive',
                                    })}
                                />
                                <span className={classes.trajectoryCard__statusText}>
                                    {status === 'active' && 'Опубликованная'}
                                    {status === 'inactive' && 'Неопубликованная'}
                                </span>
                            </div>
                        </div>
                        <div className={classes.trajectoryCard__container}>
                            <div className={classes.trajectoryCard__content}>
                                <h4 className={classes.trajectoryCard__label}>
                                    Название траектории:
                                </h4>
                                <h2 className={classes.trajectoryCard__title}>{name}</h2>
                                <h4 className={classes.trajectoryCard__label}>
                                    Описание всей траектории:
                                </h4>
                                <div className={classes.trajectoryCard__wrapper}>
                                    <div className={classes.trajectoryCard__text}>
                                        {description ? ReactHtmlParser(description) : ''}
                                    </div>
                                </div>
                            </div>
                            <Subspaces subspaces={sortedSubspaces} />
                        </div>
                        <div
                            className={classes.trajectoryCard__forwardIcon}
                            onClick={handleOpenEditForm}
                        >
                            <ForwardIcon />
                        </div>
                    </section>
                )
            }
            {isEdit && trajectoryId
                && (
                    <div className={classes.trajectoryCard__edit}>
                        <div
                            className={classes.trajectoryCard__icon}
                            onClick={() => {
                                setIsEdit(false);
                                trajectoryStore.clearInputs();
                            }}
                        >
                            <CloseIconGradient />
                        </div>
                        <TrajectoryForm
                            handleDelete={() => { setIsConfirm(true); }}
                            handleSave={handleSave}
                            handlePublish={handlePublish}
                            text="Удалить траекторию"
                            isEdit={isEdit}
                            saveText={status === 'active' ? 'Снять с публикации' : 'Сохранить'}
                            actionText={status === 'active' ? 'Обновить' : 'Опубликовать'}
                            spaceId={spaceId}
                        />
                        <ConfirmAlert
                            title="Вы уверены, что хотите удалить траекторию?"
                            isOpen={isConfirm}
                            onAccept={handleDelete}
                            onAcceptButtonText="Нет, оставить"
                            onReject={() => setIsConfirm(false)}
                            onRejectButtonText="Да, удалить"
                        />
                    </div>
                )
            }
            {removeError && <Alert message={removeError.message} time={3000} />}
        </>
    );
});
