import React from 'react';

import cn from 'classnames';

import classes from './ChoicePoint.module.scss';

interface Props {
    children?: React.ReactNode;
    classNames?: string;
    title?: string;
}

export function InfoCard({
    children,
    classNames = '',
    title,
}: Props) {
    return (
        <div
            className={cn(classes.infoCard, {
                [classNames]: classNames,
            })}
        >
            <div className={classes.infocard__title}>
                {title}
            </div>
            <div className={classes.infoCard__text}>
                {children}
            </div>
        </div>
    );
}
