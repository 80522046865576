import React, { useEffect, useState } from 'react';
import { useUrlQuery } from '@common/hooks';
import { IconDeprecated } from '@common';
import { EventStatus } from '@admin/ScheduleGenerationPage/utils';
import { Select } from '@admin/NewModule';

import classes from './Duration.module.scss';

import { Durations, UpdateEventDurationFunc } from '../../types';

import { scheduleGenerationStore } from '../../store/scheduleGenerationStore';

interface IProps {
    status: string;
    minutesDuration: number;
    getUpdateDurationFunction: (type: string) => UpdateEventDurationFunc;
}

const MIN_CUSTOM_TIME_DURATION = 1;
const MAX_CUSTOM_TIME_DURATION = 390;

export function Duration({
    status,
    minutesDuration,
    getUpdateDurationFunction,
}: IProps) {
    const { getUrlQuery } = useUrlQuery();
    const { durationList }: any = scheduleGenerationStore;
    const DURATION = [
        ...durationList.durations
            .map((item: Durations) => ({ id: item.id, name: `${item.name}${' минут'}`, academicHours: item.academicHours })),
        {
            id: String(Math.random()),
            name: 'Ручной выбор',
        },
    ];

    const [isSelectChosen, setIsSelectChosen] = useState(
        !!durationList.durations
            .find((item: Durations) => item.name === String(minutesDuration))?.id,
    );
    const [currentChosenTimeId, setCurrentChosenTimeId] = useState(
        minutesDuration ? durationList.durations.find((item: Durations) => item.name === String(minutesDuration))?.id : '1',
    );
    const [customTime, setCustomTime] = useState(
        durationList.durations
            .find((item: Durations) => item.name === String(minutesDuration))?.name
        || minutesDuration.toString(),
    );
    const [isEditDurationOpen, setIsEditDurationOpen] = useState(false);

    useEffect(() => {
        if (Number(customTime) < MIN_CUSTOM_TIME_DURATION) {
            setCustomTime(MIN_CUSTOM_TIME_DURATION.toString());
        }
        if (Number(customTime) > MAX_CUSTOM_TIME_DURATION) {
            setCustomTime(MAX_CUSTOM_TIME_DURATION.toString());
        }
    }, [customTime]);

    function onChangeSelect(event: any) {
        if (event.target.value === DURATION[DURATION.length - 1].id) {
            setIsSelectChosen(false);
        }
        setCurrentChosenTimeId(event.target.value);
    }

    function updateDuration() {
        const newDuration = isSelectChosen
            ? Number(durationList.durations
                .find((item: Durations) => item.id === currentChosenTimeId)?.name)
            : Number(customTime);
        getUpdateDurationFunction(getUrlQuery('detailedEventType')!)(newDuration);
        setIsEditDurationOpen(false);
    }

    return (
        <div className={classes.wrapper}>
            {isEditDurationOpen && (
                <>
                    {isSelectChosen ? (
                        <Select
                            options={DURATION}
                            onChange={onChangeSelect}
                            value={currentChosenTimeId}
                        />
                    ) : (
                        <>
                            <input
                                className={classes.input}
                                type="number"
                                value={customTime}
                                onChange={(e) => setCustomTime(e.target.value)}
                            />
                            <button
                                className={classes.button}
                                type="button"
                                onClick={() => {
                                    setIsSelectChosen(true);
                                    setCurrentChosenTimeId('1');
                                }}
                            >
                                Вернуться к списку
                            </button>
                        </>
                    )}
                    <IconDeprecated
                        id="check"
                        className={classes.icon}
                        click={() => updateDuration()}
                    />
                </>
            )}
            {!isEditDurationOpen && (
                <>
                    <div>{minutesDuration} мин.</div>
                    {status !== EventStatus.DELETED && (
                        <IconDeprecated
                            id="editPenBlack"
                            click={() => setIsEditDurationOpen(true)}
                            className={classes.icon}
                        />
                    )}
                </>
            )}
        </div>
    );
}
