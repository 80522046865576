import React from 'react';

import {
    EducationPeriodDaySchedule,
} from '@admin/EducationPeriodPage/EducationPeriodDaySHedule';
import { getBTIParameters } from '@admin/EducationPeriodPage/EducationPeriodDates';
import {
    Space, SpaceEducationPeriod, RefetchGetSpaces,
} from '../../EducationPeriodPageInterfaces';
import {
    EducationPeriodDatesAccordion,
} from './EducationPeriodDatesAccordion';

import classes from './EducationPeriodDatesView.module.scss';

interface Props {
    currentPeriod?: SpaceEducationPeriod;
    space: Space;
    refetchGetSpaces: RefetchGetSpaces;
}

export function EducationPeriodDatesView({
    currentPeriod,
    space,
    refetchGetSpaces,
}: Props) {
    return (
        <div className={classes.EductionPeriodDates}>

            <div className={classes.EductionPeriodDates__header}>

                <div className={classes.EductionPeriodDates__header__item}>
                    Название
                </div>

                <div className={classes.EductionPeriodDates__header__item}>
                    Дата
                </div>

                <div className={classes.EductionPeriodDates__header__item}>
                    Действия
                </div>

            </div>

            <div className={classes.EductionPeriodDates__dates}>
                <div className={classes.EductionPeriodDates__accordions}>
                    {
                        space.spaceBaseTimeIntervals.map((interval) => (
                            <EducationPeriodDatesAccordion
                                key={`${interval.order}${currentPeriod?.name ?? ''}${interval.id}`}
                                name={space.baseTimeIntervalType.name}
                                spaceId={space.id}
                                baseTimeInterval={interval}
                                parameters={
                                    getBTIParameters(
                                        interval.id,
                                        currentPeriod?.id ?? '',
                                        space.spaceEducationPeriods,
                                    )
                                }
                                refetchGetSpaces={refetchGetSpaces}
                            />
                        ))
                    }
                </div>

                <EducationPeriodDaySchedule
                    spaceParameters={space.spaceParameters}
                />
            </div>

        </div>
    );
}
