import React, { useMemo } from 'react';
import CSS from 'csstype';
import { SubspaceType, SubspaceTypeLayout } from '../../interfaces';
import { DiagramSubspaces } from '../diagramSubspaces/DiagramSubspaces';
import { SubspacesStatuses } from '../../services/subspacesView';

interface SubspaceTypeProps {
    subspaceType: SubspaceType;
    subspaceTypeStyles: CSS.Properties;
    subspaceTypeColor: string;
    subspacesStatuses: SubspacesStatuses;
    hoverSubspace(subspaceId: string): void;
    clearDiagramView(): void;
    showTrajectory(subspaceId: string): void;
    isEditMode: boolean;
    updateTempConnections(subspaceId: string): void;
}

export function DiagramType({
    subspaceType: {
        id,
        name,
        subspaces,
        subspaceTypeLayouts: layouts,
    },
    subspaceTypeStyles,
    subspaceTypeColor,
    subspacesStatuses,
    hoverSubspace,
    clearDiagramView,
    showTrajectory,
    isEditMode,
    updateTempConnections,
}: SubspaceTypeProps): JSX.Element {
    const spaceTypeOrdersNumber = getSpaceTypeOrdersNumber(layouts);
    const typeWidth = useMemo(() => `${154 * spaceTypeOrdersNumber
        + 26 * (spaceTypeOrdersNumber - 1)}px`, [layouts]);
    const typeStyles: CSS.Properties = { ...subspaceTypeStyles, width: typeWidth };
    const isElectiveSubspaceType: boolean = subspaces.length > 1;

    function getSpaceTypeOrdersNumber(typeLayouts: SubspaceTypeLayout[]): number {
        let minOrder = typeLayouts[0].spaceBaseTimeInterval.order;
        let maxOrder = typeLayouts[0].spaceBaseTimeInterval.order;

        for (let i = 1; i < typeLayouts.length; i += 1) {
            const curOrder = typeLayouts[i].spaceBaseTimeInterval.order;

            if (curOrder < minOrder) {
                minOrder = curOrder;
            }

            if (curOrder > maxOrder) {
                maxOrder = curOrder;
            }
        }

        return maxOrder - minOrder + 1;
    }

    return (
        <li id={id} className="subspace-type" style={typeStyles}>
            <DiagramSubspaces
                subspaces={subspaces}
                subspaceTypeName={name}
                subspaceTypeColor={subspaceTypeColor}
                isElectiveSubspaceType={isElectiveSubspaceType}
                subspacesStatuses={subspacesStatuses}
                hoverSubspace={hoverSubspace}
                clearDiagramView={clearDiagramView}
                showTrajectory={showTrajectory}
                isEditMode={isEditMode}
                updateTempConnections={updateTempConnections}
            />
        </li>
    );
}
