import { gql } from '@apollo/client';

import { useMutationWithErrorHandling } from './useMutationWithErrorHandling';

const GENERATE_SCHEDULE = gql`
    mutation generateSchedule($baseTimeIntervalInstanceId: String!) {
        generateSchedule(baseTimeIntervalInstanceId: $baseTimeIntervalInstanceId)
    }
`;

export function useGenerateScheduleMutation(baseTimeIntervalInstanceId: string) {
    const [generateSchedule] = useMutationWithErrorHandling(GENERATE_SCHEDULE);

    return () => generateSchedule({
        variables: { baseTimeIntervalInstanceId },
    });
}
