import React from 'react';

import classes from './ShowAllStudentBtn.module.scss';

interface ShowAllStudentBtnProps {
    showAllStudents: boolean;
    toggleShowAllStudents(): void;
}

export function ShowAllStudentBtn({
    showAllStudents,
    toggleShowAllStudents,
}: ShowAllStudentBtnProps): JSX.Element {
    return (
        <div className={classes.attendance__seeAllStudents_wrapper}>
            <button
                type="button"
                onClick={toggleShowAllStudents}
                className={classes.attendance__seeAllStudents}
            >
                {showAllStudents ? 'Скрыть' : 'Смотреть всех'}
            </button>
        </div>
    );
}
