import React from 'react';
import cn from 'classnames';
import { observer } from 'mobx-react';

import { timeStore, WEEKDAY_NAMES } from '../../../../Store';
import { EventFilterMenu } from '../../../EventFilterMenu';
import { isDaysFit } from '../helpers';

import classes from './MonthScheduleHeader.module.scss';

export const MonthScheduleHeader = observer(() => (
    <div className={classes.monthScheduleHeader}>
        <div className={classes.monthScheduleHeader__eventFilterMenu}>
            <EventFilterMenu />
        </div>

        <div
            className={cn(
                classes.monthScheduleHeader__dates,
                {
                    [classes.monthScheduleHeader__dates_isScrollVisible]:
                            !isDaysFit(timeStore.selectedDate),
                },
            )}
        >
            {WEEKDAY_NAMES.map((weekdayName) => (
                <div className={classes.date} key={weekdayName}>
                    {weekdayName}
                </div>
            ))}
        </div>
    </div>
));
