import { observer } from 'mobx-react';
import React from 'react';

import colors from '../../store/ColorConstans';

interface Props {
    size?: number;
    firstColor?: string;
    secondColor?: string;
    thirdColor?: string;
    className?: string;
}

export const PointIcon = observer(({
    size = 6,
    firstColor = colors.firstColor,
    secondColor = colors.secondColor,
    thirdColor = colors.thirdColor,
    className,
}: Props) => (
    <div className={className} style={{ height: `${size}px`, width: `${size}px`, cursor: 'default' }}>
        <svg width={size} height={size} viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="3" cy="3" r="3" fill="url(#paint0_linear_77_612)" />
            <defs>
                <linearGradient id="paint0_linear_77_612" x1="4.28916" y1="1.77215" x2="-0.058885" y2="3.4694" gradientUnits="userSpaceOnUse">
                    <stop stopColor={firstColor} />
                    <stop offset="0.0416667" stopColor={firstColor} />
                    <stop offset="0.130208" stopColor={firstColor} />
                    <stop offset="0.734375" stopColor={secondColor} />
                    <stop offset="0.932292" stopColor={thirdColor} />
                </linearGradient>
            </defs>
        </svg>

    </div>
));
