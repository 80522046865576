import React, { useEffect } from 'react';
import { Redirect, useHistory, useRouteMatch } from 'react-router-dom';
import { observer } from 'mobx-react';

import { EducationPeriodStatisticNotVoted } from '@admin/EducationPeriodStatistic/EducationPeriodStatisticNotVoted';
import { HeaderRedesign } from '@common/HeaderRedesign';
import { EducationPeriodStatisticVoted } from '@admin/EducationPeriodStatistic/EducationPeriodStatisticVoted';
import { AuthRoute } from '@common/AuthRoute';
import { educationPeriodStatisticStore } from '@admin/EducationPeriodStatistic/EducationPeriodStatisticStore';
import { XlsIcon } from '@common/XlsIcon';
import { ActionTypeOfButton } from '@common/ActionButton';
import { Loader } from '@common/Loader';
import { SelectionPointButtonRedesign } from '@common/SelectionPointButtonRedesign';
import { ButtonName } from '@common/SelectionPointButtonRedesign/buttonHooks';
import { ViewType } from '../../deprecated-graphql-query-types';
import {
    BaseTimeIntervalInstance,
    DistributionType,
    NotVotedQueryVariables,
    SelectionStatus,
    Space,
    SpaceBaseTimeInterval,
    SpaceEducationPeriod,
    SubspaceWithFork,
} from './EducationPeriodStatisticInterfaces';

import arrow from './Arrow.svg';

import classes from './EducationPeriodStatistic.module.scss';

interface Props {
    hasError: boolean;
    setAllStudentsIds: (allStudentsIds: string[]) => void;
    queryVariables: NotVotedQueryVariables;
    isLoading: boolean;
    spaces?: Space[];
}

export const EducationPeriodStatistic = observer((
    {
        spaces,
        hasError,
        queryVariables,
        isLoading,
        setAllStudentsIds,
    }: Props,
): JSX.Element => {
    const { path } = useRouteMatch();
    const params = new URLSearchParams(window.location.search);
    const btiId = params.get('btiId') ?? '';
    const spaceId = params.get('spaceId') ?? '';
    const btiInstanceId = params.get('btiIId') ?? '';

    const currentSpace = getCurrentSpace(spaceId, spaces);
    const currentBti = getCurrentSpaceBti(btiId, currentSpace);
    const spaceEducationPeriod = getSpaceEducationPeriod(btiId, currentSpace);

    const btiVariables = getBTIVariables(currentBti);
    const tabsOptions = getTabsOptions({
        btiId,
        spaceId,
    }, queryVariables.baseTimeIntervalInstanceId, btiVariables?.forks);

    const history = useHistory();

    useEffect(() => {
        setAllStudentsIds(
            educationPeriodStatisticStore.allStudents.map((student) => student.id),
        );
    }, [
        window.location.pathname,
        educationPeriodStatisticStore.notVotedStudents,
        educationPeriodStatisticStore.modulesPrioritiesGroups,
    ]);

    return (
        <>
            {
                !hasError ? (
                    <div className={classes.educationPeriodStatistic}>
                        <div className={classes.educationPeriodStatistic__header}>
                            <HeaderRedesign
                                imageSrc={arrow}
                                title={`Выборность модулей [${currentBti?.order} семестр, ${spaceEducationPeriod?.name}]`}
                                tabsOptions={tabsOptions}
                                imageOnClickEvent={() => history.push('/education-period')}
                            />

                            <div className={classes.educationPeriodStatistic__header_buttons}>

                                <SelectionPointButtonRedesign
                                    buttonName={ButtonName.LOAD_STUDENT_PRIORITY}
                                    BTIId={btiInstanceId}
                                    buttonActionType={ActionTypeOfButton.TRANSPARENT}
                                >
                                    <XlsIcon
                                        text="Выгрузить в отчёт [csv]"
                                    />
                                </SelectionPointButtonRedesign>

                                {
                                    btiVariables?.instance.selectionStatus
                                    === SelectionStatus.moduleSelectionInProcess
                                        ? (
                                            <SelectionPointButtonRedesign
                                                buttonName={
                                                    ButtonName.END_MODULE_SELECTION
                                                }
                                                BTIId={btiInstanceId ?? ''}
                                                buttonActionType={ActionTypeOfButton.PRIMARY}
                                                buttonText="Завершить выборность"
                                                selectionClassName={classes.selectionButton}
                                                navLink="/education-period"
                                            />
                                        ) : null
                                }

                            </div>
                        </div>

                        {
                            !isLoading ? (
                                <div className={classes.educationPeriodStatistic__content}>
                                    <div className={classes.educationPeriodStatistic__notVoted}>

                                        <EducationPeriodStatisticNotVoted />

                                    </div>

                                    <div className={classes.educationPeriodStatistic__voted}>

                                        <EducationPeriodStatisticVoted
                                            dateDiff={getDiffDate(getModuleSelectionEndDate(currentBti?.id ?? '', spaceEducationPeriod))}
                                            dateEnd={getModuleSelectionEndDate(currentBti?.id ?? '', spaceEducationPeriod)}
                                            selectionStatus={btiVariables?.instance.selectionStatus}
                                        />

                                    </div>
                                </div>
                            ) : (
                                <div className={classes.loader}>
                                    <Loader />
                                </div>
                            )
                        }

                        {
                            !checkIfTabsHasCurrentURL(tabsOptions) && (
                                <AuthRoute
                                    path={path}
                                    requiredUserTypes={[ViewType.Admin]}
                                >
                                    <Redirect to={`${path}/${tabsOptions[0]?.link}`} />
                                </AuthRoute>
                            )
                        }
                    </div>
                ) : (
                    <>Мы не можем показать статистику</>
                )
            }

        </>
    );
});

export function getWordEnding(
    amount: number,
    wordForms: {
        one: string,
        oneTwoFive: string,
        tenToTwenty: string,
    },
) {
    const n = Math.abs(amount) % 100;
    const n1 = n % 10;
    if (n > 10 && n < 20) { return wordForms.tenToTwenty; }
    if (n1 > 1 && n1 < 5) { return wordForms.oneTwoFive; }
    if (n1 === 1) { return wordForms.one; }
    return wordForms.tenToTwenty;
}

function getSpaceEducationPeriod(
    btiId: string,
    currentSpace?: Space,
) {
    const newSpaceEducationPeriod = currentSpace?.spaceEducationPeriods.find(
        (period) => period.spaceBaseTimeIntervalParameters.find(
            (parameter) => parameter.spaceBaseTimeIntervalId === btiId,
        ),
    );
    return newSpaceEducationPeriod;
}

function getModuleSelectionEndDate(
    currentBtiId: string,
    spaceEducationPeriod?: SpaceEducationPeriod,
) {
    const moduleSelectionEndDate = spaceEducationPeriod?.spaceBaseTimeIntervalParameters.find(
        (spaceBTI) => spaceBTI.spaceBaseTimeIntervalId === currentBtiId,
    );
    const splitDate = moduleSelectionEndDate?.moduleSelectionEndDate?.split('-');
    return splitDate ? `${splitDate[1]}/${splitDate[2]}/${splitDate[0]}` : '';
}

function getDiffDate(
    moduleSelectionEndDate: string,
) {
    const diffTime = new Date(moduleSelectionEndDate).getTime() - new Date().getTime();
    return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
}

function getCurrentSpace(spaceId: string, spaces?: Space[]) {
    return spaces?.find((space) => space.id === spaceId);
}

function getCurrentSpaceBti(btiId: string, space?: Space) {
    return space?.spaceBaseTimeIntervals.find((bti) => bti.id === btiId);
}

function getTabsOptions(
    queryStringVariables: {
        btiId: string | null,
        spaceId: string | null,
    },
    btiInstanceId: string,
    forks?: SubspaceWithFork[],
) {
    if (!forks || !forks.length) {
        return [{ title: 'Развилка 1', link: `?btiId=${queryStringVariables.btiId}&&spaceId=${queryStringVariables.spaceId}&&btiIId=${btiInstanceId}` }];
    }
    let forkIndex = 0;

    const filteredForks = forks.filter(
        (forkWithSubspace) => forkWithSubspace.fork.setting.distributionType
            === DistributionType.studentChoice,
    );

    return filteredForks.map((forkWithSubspace, index) => {
        const doesForkIndexIncreases = index !== 0
            && forkWithSubspace.subspaceName === filteredForks[index - 1].subspaceName;

        if (doesForkIndexIncreases) {
            forkIndex += 1;
        } else {
            forkIndex = 1;
        }
        return {
            title: `Развилка ${forkIndex} / ${forkWithSubspace.subspaceName}`,
            link: `${forkWithSubspace.fork.id}?btiId=${queryStringVariables.btiId}&&spaceId=${queryStringVariables.spaceId}&&btiIId=${btiInstanceId}`,
            additionalInfoOnHover: `Подпространство ${forkWithSubspace.subspaceName}`,
        };
    });
}

function checkIfTabsHasCurrentURL(
    tabsOptions: {
        title: string,
        link: string,
    }[],
) {
    return tabsOptions.some((option) => option.link === `${window.location.pathname.split('/')[2]}${window.location.search}`);
}

function getBTIVariables(
    currentSpaceBti?: SpaceBaseTimeInterval,
): {
        forks: SubspaceWithFork[],
        instance: BaseTimeIntervalInstance,
    } | undefined {
    if (!currentSpaceBti) {
        return undefined;
    }

    const allForks: SubspaceWithFork[] = [];

    currentSpaceBti.spaceBaseTimeIntervalSubspaces.forEach((btiSubspace) => {
        if (!btiSubspace.forks.length) {
            return;
        }
        btiSubspace.forks.forEach((fork) => {
            allForks.push({
                subspaceName: btiSubspace.subspace.name,
                fork,
            });
        });
    });

    return {
        forks: allForks,
        instance: currentSpaceBti.baseTimeIntervalInstances[0],
    };
}
