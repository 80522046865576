import React from 'react';
import cn from 'classnames';
import groupBy from 'lodash/groupBy';

import { Accordion } from '@common/Accordion';
import {
    User,
    StudentPriority, StudentRating, StudentsModulesPriorities,
    StudentsModulesPriorityGroups,
} from '@admin/EducationPeriodStatistic/EducationPeriodStatisticInterfaces';

import { educationPeriodStatisticStore } from '@admin/EducationPeriodStatistic/EducationPeriodStatisticStore';
import { observer } from 'mobx-react';
import classes from './AccordionItems.module.scss';

interface StudentWithPriorities {
    studentId: string;
    user: User;
    priorities: StudentPriority[];
}

interface Props {
    modulePriorities: StudentsModulesPriorityGroups;
    moduleId: string;
    index: number;
}

export const AccordionItems = observer((
    {
        modulePriorities,
        moduleId,
        index,
    }: Props,
): JSX.Element => {
    const studentsPriorities = getStudentsPriorities(
        modulePriorities,
        educationPeriodStatisticStore.studentsPriorities,
        educationPeriodStatisticStore.modulesPrioritiesGroups,
    );
    return (
        <div className={classes.accordionItems}>

            <Accordion
                headerClassnames={classes.header}
                shouldClickOnHeaderOpen
                disabled={modulePriorities.students.length === 0}
            >
                <div
                    className={cn(classes.accordionItems__header, {
                        [classes.accordionItems__header_bright]: index === 0,
                    })}
                >

                    <div className={classes.accordionItems__title}>
                        { modulePriorities.priority }-й приоритет
                    </div>

                    <div className={classes.accordionItems__title}>
                        { modulePriorities.students.length }
                    </div>

                    <div className={classes.accordionItems__title}>
                        { getStudentsAverageRating(
                            modulePriorities.students.length,
                            educationPeriodStatisticStore.studentsRatings,
                            modulePriorities,
                        ) }
                    </div>

                </div>

                <div className={classes.accordionItems__items}>

                    {
                        modulePriorities.students.map((student) => (
                            <div
                                key={`${student.user.id}${student.id}`}
                                className={classes.accordionItems__item}
                            >

                                <div className={classes.accordionItems__info}>
                                    {student.user.lastName}
                                    {' '}
                                    {student.user.firstName}
                                    {' '}
                                    {student.user.patronymic}
                                    {' '}
                                    { student.user.id.slice(0, 4) }
                                </div>

                                <div className={classes.accordionItems__info}>
                                    {
                                        getStudentRating(
                                            student.id,
                                            educationPeriodStatisticStore.studentsRatings,
                                        )
                                    }
                                </div>

                                <div className={classes.accordionItems__priorities}>
                                    {
                                        getStudentWithPriority(
                                            studentsPriorities,
                                            student.id,
                                        )?.priorities.map((priority) => (
                                            <div
                                                key={`${priority.studentId}${priority.moduleId}`}
                                                className={cn({
                                                    [classes.accordionItems__priorities_bold]:
                                                    priority.moduleId === moduleId,
                                                })}
                                            >
                                                {priority.priority} {priority.moduleName}
                                            </div>
                                        ))
                                    }
                                </div>

                            </div>
                        ))
                    }

                </div>

            </Accordion>

        </div>
    );
});

function getStudentsAverageRating(
    studentsAmount: number,
    allRatings: StudentRating[],
    priorityGroups: StudentsModulesPriorityGroups,
) {
    if (!allRatings.length || !priorityGroups.students.length || !studentsAmount) return '-';

    let allRatingsCount = 0;

    priorityGroups.students.forEach((student) => {
        const hasStudentRating = allRatings.find(
            (studentWithRating) => studentWithRating.student.id === student.id,
        );
        if (hasStudentRating) {
            allRatingsCount += hasStudentRating.value;
        }
    });

    return `${(allRatingsCount / studentsAmount).toFixed(1)}`;
}

function getStudentRating(studentId: string, studentRatings: StudentRating[]) {
    if (!studentRatings.length) return '-';
    const newStudentRating = studentRatings.find(
        (studentRating) => studentRating.student.id === studentId,
    )?.value;
    return newStudentRating || newStudentRating === 0 ? newStudentRating : '-';
}

function getPriorityModulesWithNames(
    priorities: StudentPriority[],
    studentModulePriorities: StudentsModulesPriorities[],
) {
    return priorities.map((priority) => {
        const moduleInStudentsModules = studentModulePriorities.find(
            (studentModule) => studentModule.module.id === priority.moduleId,
        );
        return {
            ...priority,
            moduleName: moduleInStudentsModules ? moduleInStudentsModules.module.name : '',
        };
    });
}

function getStudentWithPriority(
    studentsWithPriorities: StudentWithPriorities[],
    studentId: string,
) {
    return studentsWithPriorities.find((student) => student.studentId === studentId);
}

function getStudentsPriorities(
    modulePriorities: StudentsModulesPriorityGroups,
    studentPriorities: StudentPriority[],
    studentModulePriorities: StudentsModulesPriorities[] | null,
) {
    const groupedPriorities = groupBy(studentPriorities, 'studentId');

    const studentsWithPriorities: StudentWithPriorities[] = [];

    Object.entries(groupedPriorities).forEach(([studentId, priorities]) => {
        const priority = modulePriorities.students.find((student) => student.id === studentId);
        if (priority) {
            studentsWithPriorities.push({
                user: priority.user,
                studentId,
                priorities: studentModulePriorities
                    ? getPriorityModulesWithNames(priorities, studentModulePriorities)
                    : priorities,
            });
        }
    });

    return studentsWithPriorities;
}
