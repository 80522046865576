import React, { useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import UserView from '../../store/UserView';

export function ExitPage(): JSX.Element {
    useEffect(() => {
        localStorage.removeItem('token');
        UserView.clearViewType();
    }, []);
    return (
        <Redirect to="/auth" />
    );
}
