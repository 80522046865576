export interface Space {
    id: string,
    name: string,
    spaceEducationPeriods: SpaceEducationPeriod[],
    spaceBaseTimeIntervals: SpaceBaseTimeInterval[],
}

export interface SpaceEducationPeriod {
    id: string,
    name: string,
    spaceBaseTimeIntervalParameters: SpaceBaseTimeIntervalParameters[],
}

export interface SpaceBaseTimeIntervalParameters {
    baseTimeIntervalInstanceId: string,
    spaceBaseTimeIntervalId: string,
    subspaceSelectionStartDate?: string,
    subspaceSelectionEndDate?: string,
    moduleSelectionStartDate?: string,
    moduleSelectionEndDate?: string,
    spaceBaseTimeIntervalStart?: string,
    spaceBaseTimeIntervalEnd?: string,
    moduleAssessment?: ModuleAssessment;
}

export interface ModuleAssessment {
    id: string;
    isDynamicStartDate: boolean;
    startDate?: string;
    endDate?: string;
}

export interface SpaceBaseTimeInterval {
    id: string;
    order: number;
    spaceBaseTimeIntervalSubspaces: SpaceBaseTimeIntervalSubspaces[];
    baseTimeIntervalInstances: BaseTimeIntervalInstance[];
}

export enum SelectionStatus {
    moduleDistributionApprovalPending = 'moduleDistributionApprovalPending',
    moduleDistributionEnabled = 'moduleDistributionEnabled',
    moduleDistributionInProcess = 'moduleDistributionInProcess',
    moduleSelectionEnabled = 'moduleSelectionEnabled',
    moduleSelectionInProcess = 'moduleSelectionInProcess',
    selectionEnabled = 'selectionEnabled',
    selectionFinished = 'selectionFinished',
    selectionPending = 'selectionPending',
    subspaceDistributionApprovalPending = 'subspaceDistributionApprovalPending',
    subspaceDistributionEnabled = 'subspaceDistributionEnabled',
    subspaceDistributionInProcess = 'subspaceDistributionInProcess',
    subspaceSelectionEnabled = 'subspaceSelectionEnabled',
    subspaceSelectionInProcess = 'subspaceSelectionInProcess',
    waveDistributionApprovalPending = 'waveDistributionApprovalPending',
    waveDistributionInProcess = 'waveDistributionInProcess',
}

export interface SubspaceWithFork {
    fork: SpaceBaseTimeIntervalSubspacesFork,
    subspaceName: string;
}

export interface SpaceBaseTimeIntervalSubspaces {
    subspace: SpaceBaseTimeIntervalSubspace;
    forks: SpaceBaseTimeIntervalSubspacesFork[];
}

export interface SpaceBaseTimeIntervalSubspace {
    id: string;
    name: string;
}

export interface SpaceBaseTimeIntervalSubspacesFork {
    id: string;
    setting: {
        distributionType: DistributionType,
    };
}

export interface BaseTimeIntervalType {
    id: string,
    name: string,
}

export interface BaseTimeIntervalInstance {
    id: string;
    selectionStatus: SelectionStatus;
}

export interface NotVotedStudent {
    id: string;
    user: User;
}

export interface User {
    id: string;
    firstName: string;
    lastName: string;
    patronymic: string;
}

export enum DistributionType {
    automatically = 'automatically',
    manually = 'manually',
    studentChoice = 'studentChoice',
}

export interface NotVotedQueryVariables {
    baseTimeIntervalInstanceId: string;
    forkId: string;
}

export interface StudentsModulesPriorities {
    module: PrioritiesModule;
    priorityGroups: StudentsModulesPriorityGroups[];
}

export interface PrioritiesModule {
    id: string;
    name: string;
}

export interface Student {
    id: string;
    user: User;
}

export interface StudentsModulesPriorityGroups {
    priority: number;
    students: Student[];
}

export interface StudentRating {
    student: {
        id: string;
    }
    value: number;
}

export interface StudentPriority {
    studentId: string;
    moduleId: string;
    priority: number;
    moduleName?: string;
}
