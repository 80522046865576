import React, { useState } from 'react';
import { nanoid } from 'nanoid';
import cn from 'classnames';

import { CrossIcon, FilledCheckIcon } from '@common/svg';
import { Loader, LoaderSizes } from '@common/Loader';
import { OnlineRoom } from './interfaces';

import classes from './OnlineRoom.module.scss';

interface Props {
    onlineRoom?: OnlineRoom;
    loading: boolean;
    onSubmit(onlineRoom: OnlineRoom): void;
    onCancel(): void;
}

export function EdibleOnlineRoom({ onlineRoom, onSubmit, onCancel, loading }: Props) {
    const [url, setUrl] = useState(onlineRoom?.url ?? '');
    const [capacity, setCapacity] = useState(onlineRoom?.capacity ?? null);
    return (
        <>
            <div className={cn(classes.onlineRoom, classes.edibleOnlineRoom__input__container)}>
                <input
                    value={url}
                    placeholder="Ссылка"
                    className={classes.edibleOnlineRoom__input}
                    onChange={({ target }) => setUrl(target.value)}
                />
            </div>
            <div className={cn(classes.onlineRoom, classes.edibleOnlineRoom__input__container)}>
                <input
                    value={capacity || ''}
                    placeholder="Не ограничена"
                    className={cn(
                        classes.edibleOnlineRoom__input,
                        classes.edibleOnlineRoom__capacityInput,
                    )}
                    onChange={({ target }) => setCapacity(getNumber(target.value, capacity))}
                />
            </div>
            <div className={cn(classes.onlineRoom, classes.onlineRoom__icons)}>
                {loading
                    ? <Loader size={LoaderSizes.small} />
                    : (
                        <>
                            <FilledCheckIcon
                                handler={() => {
                                    const trimUrl = url.trim();
                                    if (trimUrl) {
                                        onSubmit({
                                            url: trimUrl,
                                            capacity: getCapacity(capacity),
                                            id: onlineRoom?.id ?? nanoid(8),
                                        });
                                    }
                                }}
                            />
                            <CrossIcon
                                className={classes.onlineRoom__crossIcon}
                                handler={onCancel}
                            />
                        </>
                    )}
            </div>
        </>
    );
}

function getNumber(value: string, previousNum: number | null): number | null {
    const num = Number(value);
    return Number.isNaN(num) ? previousNum : num;
}

function getCapacity(capacity: number | null) {
    return capacity !== null && capacity > 0
        ? capacity
        : null;
}
