import React, { useMemo } from 'react';
import { ActionButton, ActionTypeOfButton } from '@common/ActionButton';
import { EditorStatus } from '../interfaces';

enum ButtonType {
    Normal = 'normal',
    Active = 'active',
    Disable = 'disable',
}

interface Props {
    isEditMode: boolean;
    enterEditor(): void;
    cancelEditing(): void;
    saveChanges(): void;
    editorStatus: EditorStatus;
}

export function ControlPanel({
    isEditMode,
    enterEditor,
    cancelEditing,
    saveChanges,
    editorStatus,
}: Props): JSX.Element {
    const SaveBtnType = useMemo(getSaveButtonType, [editorStatus]);

    function getSaveButtonType() {
        const hasConnections = editorStatus === EditorStatus.HasFirstSelectedConnection;
        return hasConnections ? ButtonType.Active : ButtonType.Disable;
    }

    return isEditMode
        ? (
            <div className="control-panel">
                <ActionButton
                    actionType={ActionTypeOfButton.SECONDARY}
                    className="control-panel__btn"
                    onClick={() => cancelEditing()}
                >
                    Отменить
                </ActionButton>

                <ActionButton
                    actionType={ActionTypeOfButton.SECONDARY}
                    disabled={SaveBtnType === ButtonType.Disable}
                    onClick={() => saveChanges()}
                >
                    Сохранить
                </ActionButton>
            </div>
        )
        : (
            <ActionButton
                actionType={ActionTypeOfButton.SECONDARY}
                onClick={() => enterEditor()}
            >
                Редактировать
            </ActionButton>
        );
}
