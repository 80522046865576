import { makeAutoObservable } from 'mobx';

import {
    Duration, ModuleSkill, DependentEvent, EventType,
} from './moduleStoreTypes';
/* eslint import/no-cycle: "off" */
import { moduleStore } from './moduleStore';
import { dictionaryStore } from './dictionaryStore';

export class Assignment {
    public id!: string;

    public type = EventType.Assignment;

    public topic?: string;

    public description?: string;

    public duration?: Duration;

    public prerequisiteSkills?: ModuleSkill[];

    public outputSkills?: ModuleSkill[];

    public isPreviousEventInRow = false;

    public isNextEventInRow = false;

    public minDaysToPreviousEvent?: number;

    public maxDaysToPreviousEvent?: number;

    public minDaysToNextEvent?: number;

    public maxDaysToNextEvent?: number;

    public order!: number;

    public isEditable = false;

    public isChosen = false;

    public dependentEvents: DependentEvent[] = [];

    constructor() {
        makeAutoObservable(this);
    }

    public get assignmentNumber(): number {
        const { assignments } = moduleStore.moduleModel;
        return moduleStore.getNumberOfEvent(assignments, this.order);
    }

    public updatePrerequisitesSkills(newSkills: ModuleSkill[], typeID: string): void {
        this.prerequisiteSkills = moduleStore.getTotalSkills(
            this.prerequisiteSkills ?? [], newSkills, dictionaryStore.getFinalSkillTypeId(typeID),
        );
    }

    public updateOutputSkills(newSkills: ModuleSkill[], typeID: string): void {
        this.outputSkills = moduleStore.getTotalSkills(
            this.outputSkills ?? [], newSkills, dictionaryStore.getFinalSkillTypeId(typeID),
        );
    }
}
