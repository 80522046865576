import React, { ReactNode } from 'react';
import { observer } from 'mobx-react-lite';
import cn from 'classnames';
import Diagram from '../store/Diagram';
import classes from './EmptySlot.module.scss';

interface Props {
    emptySlotPosition: [number, number]
    baseTimeIntervalId: string
    children?: ReactNode
}

export const EmptySlot = observer(({
    emptySlotPosition,
    baseTimeIntervalId,
    children,
}: Props): JSX.Element => {
    const {
        slotSettings, diagramSettings, groupSlotSettings, forkSettings,
    } = Diagram;
    const { isSlotCanMove } = slotSettings;
    const { isGroupSlotCanMove } = groupSlotSettings;
    const { isForkCanMove } = forkSettings;
    const { editMode, relationDiagramEntityMode, removeDiagramEntityMode } = diagramSettings;
    const { createSlotMode, createGroupMode, createForkMode } = diagramSettings;
    return (
        <div
            className={cn(classes.emptySlot, {
                [classes.emptySlot_waiting]: (
                    createSlotMode
                    || createGroupMode
                    || createForkMode
                )
                || (
                    isSlotCanMove
                || isGroupSlotCanMove
                || isForkCanMove
                ),
                [classes.emptySlot_showParametersMode]: !editMode,
                [classes.emptySlot_hideEmptySlot]:
                !editMode
                || relationDiagramEntityMode
                || removeDiagramEntityMode
                || (
                    editMode
                && baseTimeIntervalId !== slotSettings.spaceBaseTimeIntervalId
                && slotSettings.isSlotCanMove
                )
                || (
                    editMode
                && baseTimeIntervalId !== groupSlotSettings.spaceBaseTimeIntervalId
                && isGroupSlotCanMove
                ) || (
                    editMode
                    && baseTimeIntervalId !== forkSettings.spaceBaseTimeIntervalId
                    && isForkCanMove
                ),
            })}
            onClick={() => {
                if (createSlotMode && !children) {
                    Diagram.createSlot(emptySlotPosition, baseTimeIntervalId);
                }

                if (createGroupMode && !children) {
                    Diagram.createGroupSlot(emptySlotPosition, baseTimeIntervalId);
                }

                if (createForkMode && !children) {
                    Diagram.createFork(emptySlotPosition, baseTimeIntervalId);
                }

                if (isSlotCanMove || isGroupSlotCanMove || isForkCanMove) {
                    Diagram.moveEntityTo(emptySlotPosition, baseTimeIntervalId);
                }
            }}
        >
            {children}
        </div>
    );
});
