import { useQuery } from '@apollo/client';
import { GET_SPACES } from '@common/qraphql/queries';
import { Space } from '../types';

interface UseSpacesQueryResult {
    spaces: Space[];
    loading: boolean;
}

export function useSpacesQuery(): UseSpacesQueryResult {
    const { data, loading } = useQuery(GET_SPACES, {
        fetchPolicy: 'cache-and-network',
    });
    return {
        spaces: data?.spaces,
        loading,
    };
}
