import { gql } from '@apollo/client';

export const UPDATE_NOTES = gql`
    mutation updateTeacherAssignmentInstanceNoteInput($updateTeacherAssignmentInstanceNoteInput: UpdateTeacherAssignmentInstanceNoteInput!) {
        updateTeacherAssignmentInstanceNote(updateTeacherAssignmentInstanceNoteInput: $updateTeacherAssignmentInstanceNoteInput) {
            assignmentInstance {
                id
                startDate
                endDate
                status
                deadline
                start

                nextMeetings {
                    topic
                }

                wave {
                    id
                    index
                }

                teacherAssignmentInstances {
                    note
                    isCurrentTeacher

                    teacher {
                        user {
                            lastName
                            firstName
                            patronymic
                            status
                        }
                    }
                }

                assignment {
                    id
                    topic
                    description
                    duration {
                        name
                    }

                    module {
                        id
                        name
                    }
                }
            }

            note
        }
    }
`;
