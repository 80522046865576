import { ApolloError, gql } from '@apollo/client';

import { useMutationWithErrorHandling } from './useMutationWithErrorHandling';

const CREATE_ADDITIONAL_GENERATION = gql`
    mutation createAdditionalGeneration($input: CreateAdditionalScheduleGenerationProcessInput!) {
        createAdditionalScheduleGenerationProcess(createAdditionalScheduleGenerationProcessInput: $input) {
            id
        }
    }
`;

interface CreateAdditionalScheduleGenerationProcessInput {
    from: string;
    to: string;
    name: string;
    baseTimeIntervalInstanceIds: string[];
}

export type CreateAdditionalGenerationFunc = [(
    input: CreateAdditionalScheduleGenerationProcessInput,
) => void, ApolloError | undefined];

export function useCreateAdditionalGenerationMutation(
    onCompleted?: () => void,
): CreateAdditionalGenerationFunc {
    const [createAdditionalGeneration, { error }] = useMutationWithErrorHandling(
        CREATE_ADDITIONAL_GENERATION,
        { onCompleted },
    );

    return [(input: CreateAdditionalScheduleGenerationProcessInput) => createAdditionalGeneration({
        variables: { input },
    }), error];
}
