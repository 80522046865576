import { makeAutoObservable } from 'mobx';

import {
    BaseItem,
    Duration,
    ModuleEquipment,
    TeacherRole,
    ModuleSkill,
    DependentEvent,
    EventType,
    ModuleConsumableEquipment,
} from './moduleStoreTypes';
/* eslint import/no-cycle: "off" */
import { moduleStore } from './moduleStore';
import { dictionaryStore } from './dictionaryStore';

export class Meeting {
    public id!: string;

    public type = EventType.Meeting;

    public topic?: string;

    public format?: BaseItem;

    public isOnline: boolean = false;

    public isOutside: boolean = false;

    public duration?: Duration;

    public isManyWaves = false;

    public minStudentCount?: number;

    public maxStudentCount?: number;

    public equipmentPerMeeting?: ModuleEquipment[];

    public equipmentPerStudent?: ModuleEquipment[];

    public consumableEquipmentPerMeeting?: ModuleConsumableEquipment[];

    public consumableEquipmentPerStudent?: ModuleConsumableEquipment[];

    public prerequisiteSkills?: ModuleSkill[];

    public outputSkills?: ModuleSkill[];

    public meetingIdForTeacherStaff?: string;

    public checkedRoles?: boolean;

    public isPreviousEventInRow = false;

    public isNextEventInRow = false;

    public minDaysToPreviousEvent?: number;

    public maxDaysToPreviousEvent?: number;

    public minDaysToNextEvent?: number;

    public maxDaysToNextEvent?: number;

    public order!: number;

    protected teacherRoles?: TeacherRole[];

    public isEditable = false;

    public isChosen = false;

    public dependentEvents: DependentEvent[] = [];

    constructor() {
        makeAutoObservable(this);
    }

    public get meetingNumber(): number {
        const { meetings } = moduleStore.moduleModel;
        return moduleStore.getNumberOfEvent(meetings, this.order);
    }

    public get meetingTeacherRoles(): TeacherRole[] {
        let id = this.meetingIdForTeacherStaff;
        let roles;
        const ids: string[] = [];
        let meeting: Meeting | undefined = this;
        const module = moduleStore.moduleModel;

        while (id && !ids.includes(id)) {
            ids.push(id);
            meeting = module.getMeeting(id);
            roles = meeting?.teacherRoles;
            id = meeting?.meetingIdForTeacherStaff ?? '';
        }

        return moduleStore.removeNonexistentRoles(roles ?? this.teacherRoles ?? []);
    }

    public set meetingTeacherRoles(teacherRoles: TeacherRole[]) {
        this.teacherRoles = moduleStore.removeNonexistentRoles(teacherRoles);
    }

    public updatePrerequisitesSkills(newSkills: ModuleSkill[], typeID: string): void {
        this.prerequisiteSkills = moduleStore.getTotalSkills(
            this.prerequisiteSkills ?? [], newSkills, dictionaryStore.getFinalSkillTypeId(typeID),
        );
    }

    public updateOutputSkills(newSkills: ModuleSkill[], typeID: string): void {
        this.outputSkills = moduleStore.getTotalSkills(
            this.outputSkills ?? [], newSkills, dictionaryStore.getFinalSkillTypeId(typeID),
        );
    }
}
