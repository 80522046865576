import React from 'react';
import { UnitOfTime } from '../../../Store';

import { UnitOfTimeButtonMobile } from './UnitOfTimeButtonMobile';

import classes from './UnitOfTimeSelectMobile.module.scss';

interface Props {
    unitOfTime: UnitOfTime;
    changeUnitOfTimeAndClose(unitOfTime: UnitOfTime): void;
}

export function UnitOfTimeSelectMobile({
    unitOfTime,
    changeUnitOfTimeAndClose,
}: Props): JSX.Element {
    return (
        <div className={classes.unitOfTimeSelectMobile}>
            <UnitOfTimeButtonMobile
                selectedUnitOfTime={unitOfTime}
                changeUnitOfTimeAndClose={changeUnitOfTimeAndClose}
                unitOfTime={UnitOfTime.DAY}
                date="День"
            />
            <UnitOfTimeButtonMobile
                selectedUnitOfTime={unitOfTime}
                changeUnitOfTimeAndClose={changeUnitOfTimeAndClose}
                unitOfTime={UnitOfTime.WEEK}
                date="Неделя"
            />
            <UnitOfTimeButtonMobile
                selectedUnitOfTime={unitOfTime}
                changeUnitOfTimeAndClose={changeUnitOfTimeAndClose}
                unitOfTime={UnitOfTime.MONTH}
                date="Месяц"
            />
        </div>
    );
}
