import React from 'react';

import classes from './ModuleMainCharacters.module.scss';
import { ModuleMainCharactersDescription } from './ModuleMainCharactersDescription';
import { ModuleMainCharactersParams } from './ModuleMainCharactersParams';
import { ModuleMainCharactersTeacher } from './ModuleMainCharactersTeacher';

import {
    Module as ModuleType,
} from '../../subSpaceTypes';

interface Props {
    currentModule: ModuleType;
}

export function ModuleMainCharacters({ currentModule }: Props):JSX.Element {
    return (
        <div className={classes.moduleMainCharacters}>
            <ModuleMainCharactersDescription
                currentModule={currentModule}
            />
            <ModuleMainCharactersParams
                currentModule={currentModule}
            />
            <ModuleMainCharactersTeacher
                currentModule={currentModule}
            />
        </div>
    );
}
