import { GetEquipmentsEquipmentPage_equipment_list } from 'src/deprecated-graphql-query-types';

export interface BaseItem {
    id: string;
    name: string;
}

export interface TeacherRole {
    maxCount?: number;
    count?: number
    teacherRole: BaseItem;
    id?: string;
    name?: string;
}

export interface Teacher extends BaseItem {
    user?: {
        firstName: string;
        lastName: string;
        patronymic: string;
    }
}

export interface Equipment extends GetEquipmentsEquipmentPage_equipment_list {}

export interface ConsumableEquipment extends BaseItem {
    unit: string;
}

export interface ModuleEquipment {
    id: string;
    category: {
        id: string;
        name: string;
    }
    count?: number;
}

export interface ModuleConsumableEquipment {
    consumableEquipment: ConsumableEquipment;
    count?: number;
}

export interface Duration extends BaseItem {
    academicHours?: number;
}

export interface MeetingFormat extends BaseItem {}

export enum EventType {
    Meeting = 'meeting',
    Assignment = 'assignment',
    EvaluationPoint = 'evaluationPoint',
}

export interface DependentEvent {
    id: string;
    type: EventType;
}

export interface ModuleTeacher {
    teacher: Teacher;
    teacherRoles: BaseItem[];
}

export interface SkillType {
    id: string;
    properties: {
        maxLevel: number;
        description: boolean;
    };
    parentId: string | null;
    name: {
        nominativeSingular: string;
        genitiveSingular: string;
        nominativePlural: string;
        genitivePlural: string;
        english: string;
    };
}

export interface EquipmentRequestMessage {
    message: string;
}

export interface Skill extends BaseItem {
    typeId?: string;
    fullName: string;
    maxLevel?: number;
}

export interface ModuleSkill {
    skill: Skill;
    level: number;
}

export interface PointSkill extends ModuleSkill {
    pointId?: string;
}

export interface ConfigSkill {
    id: string;
    isLevelIncreased: boolean;
    isLevelRequired?: boolean;
    isLevelOutput?: boolean;
}

export interface Options extends BaseItem {
    description: string;
    minStudentCount: number;
    maxStudentCount: number;
    maxWaveCount: number;
    imageUrl: string;
    teachersRoles: TeacherRole[];
    moduleTeachers: ModuleTeacher[];
    prerequisiteSkills: ModuleSkill[];
    outputSkills: ModuleSkill[];
}

export interface StaticOptions {
    teachers: Teacher[];
    equipment: {
        list: Equipment[]
    };
    meetingFormats: MeetingFormat[];
    durations: Duration[];
    moduleSettings: {
        moduleSkillTypes: ConfigSkill[];
        meetingSkillTypes: ConfigSkill[];
    };
    skills: Skill[];
    skillTypes: SkillType[];
    equipmentRequestMessage: EquipmentRequestMessage;
    skillsForType: { id: string; skills: Skill[] }[];
}

export interface MeetingInterface {
    id: string;
    topic?: string;
    format?: BaseItem;
    duration?: Duration;
    isManyWaves: boolean;
    minStudentCount?: number;
    maxStudentCount?: number;
    equipmentPerMeeting?: ModuleEquipment[];
    equipmentPerStudent?: ModuleEquipment[];
    consumableEquipmentPerMeeting?: ModuleConsumableEquipment[];
    consumableEquipmentPerStudent?: ModuleConsumableEquipment[];
    prerequisiteSkills?: ModuleSkill[];
    outputSkills?: ModuleSkill[];
    meetingTeacherRoles?: TeacherRole[];
    meetingIdForTeacherStaff?: string | null;
    isPreviousEventInRow: boolean;
    isNextEventInRow: boolean;
    minDaysToPreviousEvent?: number;
    maxDaysToPreviousEvent?: number;
    minDaysToNextEvent?: number;
    maxDaysToNextEvent?: number;
    order: number;
    isSaved?: boolean;
}

export interface AssignmentInterface {
    id: string;
    topic?: string;
    duration?: Duration;
    prerequisiteSkills?: ModuleSkill[];
    outputSkills?: ModuleSkill[];
    isPreviousEventInRow: boolean;
    isNextEventInRow: boolean;
    minDaysToPreviousEvent?: number;
    maxDaysToPreviousEvent?: number;
    minDaysToNextEvent?: number;
    maxDaysToNextEvent?: number;
    order: number;
    isSaved?: boolean;
}

export interface EvaluationPointInterface {
    id: string;
    name?: string;
    description?: string;
    previousEventId?: string;
    evaluationPointSkills?: PointSkill[],
    evaluationPointTeacherRoles: TeacherRole[],
    duration?: Duration;
    order: number;
    isSaved?: boolean;
}
