import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import ru from 'date-fns/locale/ru';

// import 'react-datepicker/dist/react-datepicker.css';

import './Calendar.scss';

interface Props {
    onChange: (date: Date) => void;
    modifier?: string;
    autoFocus?: boolean;
    selectedDate?: Date;
    onCalendarClose?: () => void;
}

export function Calendar(
    {
        onChange,
        modifier,
        autoFocus,
        selectedDate,
        onCalendarClose,
    }: Props,
) {
    const [date, setDate] = useState<Date>(selectedDate || new Date());

    return (
        <div className="absenceCalendar">

            <DatePicker
                onChange={(newDate: Date) => {
                    setDate(newDate);
                    onChange(newDate);
                }}
                shouldCloseOnSelect={false}
                locale={ru}
                selected={date}
                showYearDropdown
                onCalendarClose={onCalendarClose}
                dateFormatCalendar="LLLL"
                autoFocus={autoFocus}
                dateFormat="dd.MM.yyyy"
                className={`calendar__input calendar__input_${modifier}`}
                popperClassName="calendar__popperAbsence"
                wrapperClassName="calendar__wrapperAbsence"
            />

        </div>
    );
}
