import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import { AvatarIcon } from '@common/svg';
import { eventsStore } from '../../../../../../../Store';
import { GradingMenu } from '../../GradingMenu';
import { IconDeprecated } from '../../../../../../../../../common';

import classes from './EvaluationStudent.module.scss';

interface Props {
    id: string;
    firstName: string;
    lastName: string;
    patronymic: string;
    status: boolean;
    note: string;
}
export function EvaluationStudent({
    id,
    firstName,
    lastName,
    patronymic,
    status,
    note,
}: Props): JSX.Element {
    const [isGradingMenuOpen, setIsGradingMenuOpen] = useState<boolean>(false);

    function toggleGradingMenu(): void {
        setIsGradingMenuOpen((prevIsGradingMenuOpen) => !prevIsGradingMenuOpen);
    }

    useEffect(() => {
        setIsGradingMenuOpen(false);
    }, [eventsStore.selectedEvent]);
    return (
        <>
            <li className={classes.evaluationStudent__container}>
                <div
                    className={classes.evaluationStudent}
                    onClick={toggleGradingMenu}
                >
                    <svg
                        viewBox="0 0 16 10"
                        className={cn(classes.arrow, {
                            [classes.arrow_isOpen]: isGradingMenuOpen,
                        })}
                    >
                        <IconDeprecated
                            id="arrow-block-up"
                        />
                        <linearGradient
                            id="arrow-block-up-linear"
                            x1="7.08858"
                            y1="-0.0439975"
                            x2="9.35558"
                            y2="10.5039"
                            gradientUnits="userSpaceOnUse"
                        >
                            <stop offset="13%" stopColor="#0B42DC" />
                            <stop offset="73%" stopColor="#57B2C6" />
                            <stop offset="93%" stopColor="#EED660" />
                        </linearGradient>
                    </svg>

                    <div className={classes.evaluationStudent__data}>
                        <div className={classes.evaluationStudent__avatar}>
                            <div>
                                <AvatarIcon size={50} />
                            </div>
                            {!status && (
                                <div className={classes.evaluationStudent__dot} />
                            )}
                        </div>
                        <div>
                            <div>{`${lastName} ${firstName}`}</div>
                            <div>{patronymic}</div>
                        </div>
                    </div>
                </div>
            </li>

            {isGradingMenuOpen && <GradingMenu id={id} note={note} />}
        </>
    );
}
