import React from 'react';
import { observer } from 'mobx-react';

import { Title } from '@common/Title';
import { CheckboxForm } from '@admin/NewModule/Section';
import { moduleStore, Meeting } from '@admin/NewModule/Store';
import { TeachersRolesForm } from './TeachersRolesForm';
import { RolesFromMeeting } from './RolesFromMeeting';
import styles from '../Meeting.module.scss';

interface Props {
    meeting: Meeting;
    setRef(ref: React.MutableRefObject<HTMLDivElement | null>): void;
}

export const MeetingTeachersRoles = observer(({ meeting, setRef }: Props) => {
    const module = moduleStore.moduleModel;
    const clearRoles = () => {
        module.updateMeeting(meeting.id, {
            meetingTeacherRoles: [],
            meetingIdForTeacherStaff: '',
        });
    };
    const hasSelectMeeting = !!meeting?.meetingIdForTeacherStaff;
    const ref = React.useRef<HTMLDivElement | null>(null);
    React.useEffect(() => setRef(ref), []);
    return (
        <div className={styles.form__professors} ref={ref}>
            <Title>Преподавательский состав встречи:</Title>
            <CheckboxForm
                checkboxLabel="Использовать преподавательский состав другой встречи"
                onChangeCheck={clearRoles}
                uncheckedView={<TeachersRolesForm meeting={meeting} />}
                checked={hasSelectMeeting}
            >
                <RolesFromMeeting meeting={meeting} />
            </CheckboxForm>
        </div>
    );
});
