import React from 'react';
import { observer } from 'mobx-react';

import { Title, TitleTypes } from '@common/Title';
import { Switcher } from '@common/Switcher';
import { Pendant, PendantType } from '@admin/NewModule/Pendant';
import { moduleStore, dictionaryStore, Meeting } from '@admin/NewModule/Store';
import { Select } from '@admin/NewModule/Select';
import { Textarea } from '@admin/NewModule/Textarea';
import { CheckboxForm } from '@admin/NewModule/Section';
import { Input, InputTypes } from '@admin/NewModule/CustomInput';

import style from './Meeting.module.scss';

interface Props {
    meeting: Meeting;

    setRef(ref: React.MutableRefObject<HTMLDivElement | null>): void;
}

const validateNumber = (value: string) => {
    const num = Number(value);
    return Math.max(Math.min(num, 100000), 0);
};

export const MeetingOptions = observer(({ meeting, setRef }: Props) => {
    const module = moduleStore.moduleModel;

    const sortedFormats = [...dictionaryStore.meetingFormats].sort((a, b) => {
        if (a.name > b.name) return 1;
        if (a.name < b.name) return -1;
        return 0;
    });
    const sortedDurations = [...dictionaryStore.durations]
        .sort((a, b) => (a.academicHours ?? 0) - (b.academicHours ?? 0));

    const ref = React.useRef<HTMLDivElement | null>(null);
    React.useEffect(() => setRef(ref), []);

    return (
        <div className={style.form__options} ref={ref}>
            <Title>{`Встреча ${meeting?.meetingNumber}`}</Title>
            <Title>Общие параметры встречи:</Title>
            <div className={style.form__theme}>
                <Textarea
                    name="topic"
                    label="Название:"
                    placeholder="Введите тему встречи"
                    value={meeting?.topic ?? ''}
                    onChange={({ target }) => module.updateMeeting(
                        meeting.id, { topic: target.value },
                    )}
                />
            </div>
            <div className={style.form__container}>
                <Select
                    value={meeting?.format?.id}
                    name="format"
                    label="Выберите формат встречи:"
                    onChange={({ target }) => module.updateMeeting(meeting.id, {
                        format: {
                            id: target.value,
                            name: target.options[target.options.selectedIndex].text,
                        },
                    })}
                    options={sortedFormats}
                    placeholder="Укажите формат"
                />
            </div>
            <div className={style.form__container}>
                <Switcher
                    onClick={
                        () => module.updateMeeting(meeting.id, { isOnline: !meeting.isOnline })
                    }
                    checked={meeting.isOnline}
                    label="Онлайн-встреча"
                />
            </div>
            <div className={style.form__container}>
                <Switcher
                    onClick={
                        () => module.updateMeeting(meeting.id, { isOutside: !meeting.isOutside })
                    }
                    checked={meeting.isOutside}
                    label="Выездная встреча"
                />
            </div>
            <div className={style.form__container}>
                <Select
                    value={meeting?.duration?.id}
                    name="duration"
                    label="Длительность встречи:"
                    onChange={({ target }) => module.updateMeeting(meeting.id, {
                        duration: {
                            id: target.value,
                            name: target.options[target.options.selectedIndex].text,
                            academicHours: dictionaryStore.durations
                                .find(({ id }) => id === target.value)?.academicHours,
                        },
                    })}
                    options={sortedDurations}
                    placeholder="Укажите длительность"
                />
            </div>
            <Title type={TitleTypes.Subtitle}>
                Укажите на какое количество студентов рассчитана встреча:
            </Title>
            <div className={style.form__minMaxInputs}>
                <CheckboxForm
                    checked={meeting?.isManyWaves}
                    onChangeCheck={() => module.updateMeeting(meeting.id, {
                        maxStudentCount: module.options.maxStudentCount,
                        isManyWaves: !meeting?.isManyWaves,
                    })}
                    checkboxLabel="Встреча для нескольких потоков"
                    uncheckedView={(
                        <div className={style.form__minMaxStudentCount}>
                            <span className={style.form__minStudentCount}>
                                min: {module.options.minStudentCount}
                            </span>
                            <span>
                                max: {module.options.maxStudentCount}
                            </span>
                        </div>
                    )}
                >
                    <div className={style.form__minMaxStudentCount_input}>
                        <span className={style.form__minStudentCount}>
                            min: {module.options.minStudentCount}
                        </span>
                        <Input
                            name="maxStudentCount"
                            label="max: "
                            placeholder="Кол-во"
                            type={InputTypes.Number}
                            value={meeting?.maxStudentCount}
                            onChange={({ target }: React.ChangeEvent<HTMLInputElement>) => {
                                const maxStudentCount = validateNumber(target.value);
                                module.updateMeeting(meeting.id, { maxStudentCount });
                            }}
                        />
                    </div>
                    {(meeting?.maxStudentCount ?? 0) < (module.options.maxStudentCount ?? 0)
                    && meeting?.isManyWaves
                    && (
                        <div className={style.form__options__warning}>
                            <Pendant type={PendantType.Warning}>
                                <div className={style.form__options__warningText}>
                                    Максимальное количество студентов на данной встрече меньше
                                    максимального количества студентов на модуле.
                                    <br />
                                    Пожалуйста проверьте это.
                                </div>
                            </Pendant>
                        </div>
                    )}
                </CheckboxForm>
            </div>
        </div>
    );
});
