import React, { useEffect, useState } from 'react';
import { gql, useQuery } from '@apollo/client';
import { sortBy, uniqBy } from 'lodash';
import { format } from 'date-fns';
import { useHistory } from 'react-router-dom';

import { Loader } from '@common/Loader';
import {
    GetSpaceActiveBTIs,
    GetSpaceActiveBTIsVariables,
    GetSpaceActiveBTIs_spaceBaseTimeIntervalInstances,
} from '../graphql-types';

import classes from './SpaceModules.module.scss';
import { SpaceModules } from './SpaceModules';

interface Props {
    spaceId: string;
}

export interface Option {
    id: string;
    name: string;
}

const GET_SPACE_ACTIVE_BTIS = gql`
  query GetSpaceActiveBTIs($input: SpaceBaseTimeIntervalInstancesInput!) {
    spaceBaseTimeIntervalInstances(spaceBaseTimeIntervalInstancesInput: $input) {
      id
      spaceBaseTimeInterval {
        id
        order
        space {
          baseTimeIntervalType {
            id
            name
          }
        }
      }
      spaceEducationPeriod {
        id
        name
      }
      modules {
        id
        name
      }
    }
  }
`;

export function SpaceModulesApollo({ spaceId }: Props) {
    const history = useHistory();
    const [selectedModules, setSelectedModules] = useState<Option[]>([]);
    const [selectedBTIs, setSelectedBTIs] = useState<Option[]>([]);

    const { data, loading, error } = useQuery<GetSpaceActiveBTIs, GetSpaceActiveBTIsVariables>(
        GET_SPACE_ACTIVE_BTIS,
        {
            variables: { input: { date: format(new Date(), 'yyyy-MM-dd'), spaceId } },
            fetchPolicy: 'network-only',
        },
    );

    useEffect(() => {
        if (history.location.search && data) {
            const urlQueryParams = new URLSearchParams(history.location.search);

            const btiIdsURLString = urlQueryParams.get('btiIds');

            if (btiIdsURLString) {
                const btiIds = JSON.parse(decodeURIComponent(btiIdsURLString));
                const btis = data.spaceBaseTimeIntervalInstances
                    .filter(({ id }) => btiIds.includes(id))
                    .map(bti => ({ id: bti.id, name: formatBTIName(bti) }));

                setSelectedBTIs(btis);
            }
            const moduleIdsURLString = urlQueryParams.get('moduleIds');
            if (moduleIdsURLString) {
                const moduleIds = JSON.parse(decodeURIComponent(moduleIdsURLString));
                const modules = data.spaceBaseTimeIntervalInstances
                    .flatMap(bti => bti.modules)
                    .filter(module => moduleIds.includes(module.id)) as Option[];

                setSelectedModules(modules);
            }
        }
    }, [data]);

    function resetFilters() {
        setSelectedModules([]);
        setSelectedBTIs([]);
        history.push(history.location.pathname);
    }

    function onModuleFilterChange(selectedOptions: Option[]) {
        setSelectedModules(selectedOptions);
        const urlQueryParams = new URLSearchParams(history.location.search);
        const moduleIds = selectedOptions.map(({ id }) => id);
        if (moduleIds.length) {
            urlQueryParams.set('moduleIds', encodeURIComponent(JSON.stringify(moduleIds)));
        } else {
            urlQueryParams.delete('moduleIds');
        }
        history.push(`${history.location.pathname}?${urlQueryParams.toString()}`);
    }

    function onBTIFilterChange(selectedOptions: Option[]) {
        setSelectedBTIs(selectedOptions);
        const urlQueryParams = new URLSearchParams(history.location.search);
        const btiIds = selectedOptions.map(({ id }) => id);
        if (btiIds.length) {
            urlQueryParams.set('btiIds', encodeURIComponent(JSON.stringify(btiIds)));
        } else {
            urlQueryParams.delete('btiIds');
        }
        history.push(`${history.location.pathname}?${urlQueryParams.toString()}`);
    }

    if (loading) {
        return <Loader />;
    }

    if (error) {
        return (
            <div className={classes['space-modules__container']}>
                Произошла ошибка: {error.graphQLErrors[0]?.message || error.message}
            </div>
        );
    }
    if (data) {
        const { spaceBaseTimeIntervalInstances: btis } = data;

        return (
            <SpaceModules
                selectedModules={selectedModules}
                selectedBTIs={selectedBTIs}
                modules={formatModules(btis, selectedModules, selectedBTIs)}
                moduleFilterOptions={getModulesFilterOptions(btis, selectedBTIs)}
                btiFilterOptions={getBTIFilterOptions(btis, selectedModules)}
                onModuleFilterChange={onModuleFilterChange}
                onBTIFilterChange={onBTIFilterChange}
                formatOption={formatOption}
                resetFilters={resetFilters}
            />
        );
    }

    return null;
}

function getModulesFilterOptions(
    baseTimeIntervalInstances: GetSpaceActiveBTIs_spaceBaseTimeIntervalInstances[],
    targetBTIs: Option[],
) {
    let btis = baseTimeIntervalInstances;
    if (targetBTIs.length) {
        const targetBTIIds = targetBTIs.map(({ id }) => id);
        btis = baseTimeIntervalInstances.filter(({ id }) => targetBTIIds.includes(id));
    }
    return uniqBy(btis.flatMap(({ modules }) => modules), 'id') as Option[];
}

function getBTIFilterOptions(
    baseTimeIntervalInstances: GetSpaceActiveBTIs_spaceBaseTimeIntervalInstances[],
    targetModules: Option[],
) {
    let btis = baseTimeIntervalInstances;
    if (targetModules.length) {
        const moduleIds = targetModules.map(({ id }) => id);
        btis = baseTimeIntervalInstances
            .filter(({ modules }) => modules.find(({ id }) => moduleIds.includes(id)));
    }
    return btis.map(bti => ({
        id: bti.id,
        name: formatBTIName(bti),
    }));
}

function formatBTIName({
    spaceBaseTimeInterval,
    spaceEducationPeriod,
}: GetSpaceActiveBTIs_spaceBaseTimeIntervalInstances) {
    return `${spaceBaseTimeInterval.order} ${spaceBaseTimeInterval.space.baseTimeIntervalType.name} ${spaceEducationPeriod.name}`;
}

function formatModules(
    baseTimeIntervalInstances: GetSpaceActiveBTIs_spaceBaseTimeIntervalInstances[],
    targetModules: Option[],
    targetBTIs: Option[],
) {
    const targetModuleIds = targetModules.map(({ id }) => id);
    const targetBTIIds = targetBTIs.map(({ id }) => id);

    let btis = baseTimeIntervalInstances;
    if (targetBTIIds.length) {
        btis = baseTimeIntervalInstances.filter(({ id }) => targetBTIIds.includes(id));
    }

    const moduleCards = btis.flatMap(({ id, modules, ...rest }) => {
        let btiModules = modules;
        if (targetModuleIds.length) {
            btiModules = modules.filter(({ id: moduleId }) => (
                targetModuleIds.includes(moduleId)
            ));
        }

        return btiModules.map(module => ({
            module,
            baseTimeIntervalInstanceId: id,
            ...rest,
        }));
    });

    return sortBy(moduleCards, ({ module }) => module.name);
}

function formatOption(option: Option) {
    return option.name;
}
