import { useLazyQuery } from '@apollo/client';

import {
    ValidateScheduleSchedulePage,
    ValidateScheduleSchedulePageVariables,
} from 'src/graphql-query-types';
import { GET_VALIDATE_SCHEDULE } from '../queries';

export function useValidationForProcessQuery() {
    const [
        getValidateScheduleQuery,
        {
            data: validateScheduleData,
            loading: validateScheduleDataLoading,
        },
    ] = useLazyQuery<ValidateScheduleSchedulePage, ValidateScheduleSchedulePageVariables>(
        GET_VALIDATE_SCHEDULE,
        {
            fetchPolicy: 'no-cache',
        },
    );

    return {
        getValidateScheduleQuery: (
            generationProcessId: string,
        ) => {
            getValidateScheduleQuery({
                variables: { validateScheduleInput: { generationProcessId, ignoreFlags: [] } },
            });
        },
        validateScheduleData,
        validateScheduleDataLoading,
    };
}
