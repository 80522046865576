import React from 'react';

import {
    DaySchedule,
    SpaceParameters,
    WeekDaysRussian,
    WorkingInterval,
} from '@admin/EducationPeriodPage/EducationPeriodPageInterfaces';

import { WeekDayIntervals } from '@admin/EducationPeriodPage/EducationPeriodDaySHedule/WeekDayIntervals';
import { StudentWorkload } from '@admin/EducationPeriodPage/EducationPeriodDaySHedule/StudentWorkLoad';

import classes from './EducationPeriodDaySchedule.module.scss';

export interface WeekScheduleDay {
    day: WeekDaysRussian;
    schedule?: WorkingInterval[];
}

interface Props {
    spaceParameters: SpaceParameters;
}

const week: (keyof typeof WeekDaysRussian)[] = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

export function EducationPeriodDaySchedule({
    spaceParameters,
}: Props) {
    return (
        <div className={classes.educationPeriodSchedule}>

            <div className={classes.educationPeriodSchedule__items}>

                <div className={classes.educationPeriodSchedule__schedule}>

                    <div className={classes.educationPeriodSchedule__schedule__title}>
                        График работы:
                    </div>
                    {
                        getWeekDaysFromParameters(
                            week, spaceParameters.daySchedules,
                        ).map((day) => (
                            <WeekDayIntervals
                                key={day.day}
                                weekDay={day}
                            />
                        ))
                    }
                </div>

                <div className={classes.educationPeriodSchedule__studentWorkLoad}>
                    <StudentWorkload workLoad={spaceParameters.studentWorkload} />
                </div>

            </div>
        </div>
    );
}

function getWeekDaysFromParameters(
    weekDays: (keyof typeof WeekDaysRussian)[],
    daysSchedule: DaySchedule[],
) {
    const allWeekSchedule: WeekScheduleDay[] = [];

    weekDays.forEach((weekDay) => {
        const currentDayInDaysSchedule = getDayFromDaySchedules(weekDay, daysSchedule);

        if (!currentDayInDaysSchedule) {
            allWeekSchedule.push({
                day: WeekDaysRussian[weekDay],
            });
        }

        if (currentDayInDaysSchedule) {
            allWeekSchedule.push({
                day: WeekDaysRussian[weekDay],
                schedule: currentDayInDaysSchedule.workingIntervals,
            });
        }
    });

    return allWeekSchedule;
}

function getDayFromDaySchedules(day: string, daysSchedule: DaySchedule[]) {
    return daysSchedule.find((daySchedule) => daySchedule.weekDay === day);
}
