import React from 'react';
import { ActionButton, ActionTypeOfButton } from '@common/ActionButton';
import { Icon } from '@admin/NewModule/CustomIcon';
import { Meeting } from '@admin/ScheduleGenerationPage/types';

import classes from './MeetingConfirm.module.scss';

type NewMeeting = {
    id: string;
    name: string;
    meetingId: string;
    meetingInstanceId?: string;
};

interface Props {
    newMeeting: NewMeeting;
    currentMeeting: Meeting;
    currentMeetingDate: string;
    onSwapBtnClick(): void;
    onOutClick(): void;
}

export function MeetingConfirm({
    newMeeting,
    currentMeeting,
    currentMeetingDate,
    onSwapBtnClick,
    onOutClick,
}: Props): JSX.Element {
    return (
        <div className={classes.transparentLayer}>
            <section className={classes.meetingConfirm}>
                <div className={classes.meetingConfirm__close}>
                    <Icon id="blackCross" onClick={onOutClick} />
                </div>
                <h4 className={classes.meetingConfirm__header}>Предупреждение</h4>
                <p
                    className={classes.meetingConfirm__description}
                >
                    <div className={classes.meetingConfirm__meeting}>Встреча 1: {`${currentMeeting.topic} (формат: ${currentMeeting.format.name}) ${currentMeetingDate}`}</div>
                    <div className={classes.meetingConfirm__meeting}>Встреча 2: {`${newMeeting.name}`}</div>
                    <div className={classes.meetingConfirm__question}>
                        Поменять встречи местами?
                    </div>
                </p>
                <div className={classes.meetingConfirm__button}>
                    <ActionButton
                        onClick={onOutClick}
                        actionType={ActionTypeOfButton.SECONDARY}
                    >
                        Отменить
                    </ActionButton>
                    <ActionButton
                        onClick={onSwapBtnClick}
                    >
                        Поменять местами
                    </ActionButton>
                </div>
            </section>
        </div>
    );
}
