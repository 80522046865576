import { useLazyQuery } from '@apollo/client';

import {
    ValidateScheduleSchedulePage,
    ValidateScheduleSchedulePageVariables,
} from 'src/graphql-query-types';
import { GET_VALIDATE_SCHEDULE } from '../queries';

export function useValidateScheduleLazyQuery() {
    const [
        validate,
        { data, loading, error },
    ] = useLazyQuery<ValidateScheduleSchedulePage, ValidateScheduleSchedulePageVariables>(
        GET_VALIDATE_SCHEDULE,
        {
            fetchPolicy: 'cache-and-network',
        },
    );

    return {
        validate,
        loading,
        validationResult: data?.validateSchedule,
        error,
    };
}
