import React, { useState } from 'react';

import { ToolButton } from '@admin/Users/ToolBar/ToolButton';
import { useMutation } from '@apollo/client';
import { IMPORT_USERS_CSV } from '@admin/Users/ToolBar/Tools/gql';
import { Alert } from '@common';
import { PopUpNedoshkovskiy } from '@common/PopUpNedoshkovskiy';
import { UsersImportPopUpContent } from '@admin/Users/UsersImportPopUpContent/UsersImportPopUpContent';
import { ToolBarButtonProps } from '@admin/Users/ToolBar/Tools/interface';

export function ImportUsersButton(
    {
        refetchUserList,
    }: ToolBarButtonProps,
) {
    const [importUsers, { data: usersImportData, error }] = useMutation(IMPORT_USERS_CSV, {
        onCompleted: () => { refetchUserList(); },
    });
    const [fileData, setFileData] = useState<{
        file: string | ArrayBuffer | null;
    }>({ file: '' });
    const [isPopUpVisible, setPopUpVisible] = useState(false);

    const handlerLoad = (
        event: React.ChangeEvent<HTMLInputElement>,
    ) => {
        event.preventDefault();
        const reader = new FileReader();
        const file = event.target.files![0] ?? '';
        reader.onloadend = () => {
            setFileData({
                file: reader.result,
            });
        };
        return file && reader.readAsText(file);
    };

    const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        await importUsers({
            variables: {
                usersCsvInput: fileData.file,
            },
        })
            .then((data) => {
                if (data && !data.errors) {
                    setPopUpVisible(true);
                }
            });
    };

    return (
        <>
            <ToolButton
                text="Загрузить пользователей"
                icon="export"
                isLoader
                contextMenu={(
                    <></>
                )}
                handlerLoad={handlerLoad}
                onSubmit={onSubmit}
            />
            {
                isPopUpVisible && (
                    <PopUpNedoshkovskiy>
                        <UsersImportPopUpContent
                            amountOfUsers={usersImportData.importUsers?.length}
                            closeButtonOnClick={() => setPopUpVisible(false)}
                        />
                    </PopUpNedoshkovskiy>
                )
            }
            {
                error && (<Alert message={error.message} time={5000} />)
            }
        </>
    );
}
