import React from 'react';
import { NavLink, useRouteMatch } from 'react-router-dom';

interface Props {
    spaceName: string;
}

export function DiagramNavigation({ spaceName }: Props): JSX.Element {
    const routeMatch = useRouteMatch();
    const to: string = routeMatch.path.replace('/connections', '');

    return (
        <div className="diagram-navigation">
            <NavLink to={to} exact className="space-info">{spaceName}</NavLink>
            <span className="diagram-navigation-divider">\</span>
            <p className="connections-info">Связи подпространства</p>
        </div>
    );
}
