import { Subspace } from '../interfaces';
import { getConnectionId } from './subspacesConnection';

export enum ConnectionStatus {
    Muted = 'muted',
    Normal = 'normal',
    Add = 'add',
    Delete = 'delete',
}

export interface ConnectionsStatuses {
    [connectionId: string]: ConnectionStatus,
}

export class ConnectionsView {
    private value: ConnectionsStatuses;

    constructor(subspaces: Subspace[] = []) {
        this.value = this.init(subspaces);
    }

    private init(subspaces: Subspace[]): ConnectionsStatuses {
        return subspaces.reduce((accumulator: ConnectionsStatuses, subspace) => {
            const status = subspace.targetSubspaces
                .reduce((acc: ConnectionsStatuses, target) => {
                    const id = getConnectionId(subspace.id, target.id);
                    return { ...acc, [id]: ConnectionStatus.Normal };
                }, {});

            return { ...accumulator, ...status };
        }, {});
    }

    public getValue(): ConnectionsStatuses {
        return Object.keys(this.value).reduce((acc: ConnectionsStatuses, key) => ({
            ...acc,
            [key]: this.value[key],
        }), {});
    }

    public setValue(statuses: ConnectionsStatuses): void {
        this.value = {};
        Object.keys(statuses).forEach(key => { this.value[key] = statuses[key]; });
    }

    public setConnectionsStatus(status: ConnectionStatus): void {
        Object.keys(this.value).forEach(key => { this.value[key] = status; });
    }

    public setConnectionStatus(
        sourceId: string, targetId: string, status: ConnectionStatus,
    ): void {
        const connectionId = getConnectionId(sourceId, targetId);
        this.value[connectionId] = status;
    }

    public has(connectionId: string): boolean {
        return !!this.value[connectionId];
    }

    public hasStatus(connectionId: string, status: ConnectionStatus): boolean {
        return this.value[connectionId] === status;
    }

    public deleteConnectionId(connectionId: string): ConnectionsView {
        const connectionsView: ConnectionsView = new ConnectionsView();
        connectionsView.setValue(this.getValue());
        delete connectionsView.value[connectionId];
        return connectionsView;
    }

    public setConnectionIdStatus(connectionId: string, status: ConnectionStatus): ConnectionsView {
        const connectionsView: ConnectionsView = new ConnectionsView();
        connectionsView.setValue(this.getValue());
        connectionsView.value[connectionId] = status;
        return connectionsView;
    }

    public showSubspaceLinks(subspaceId: string): ConnectionsView {
        const connectionsView: ConnectionsView = new ConnectionsView();
        connectionsView.setValue(this.getValue());
        Object.keys(connectionsView.value).forEach((connectionId: string) => {
            const isPartOfConnection = connectionId.includes(subspaceId);
            const hasAddStatus = connectionsView.hasStatus(connectionId, ConnectionStatus.Add);

            if (!isPartOfConnection && hasAddStatus) {
                connectionsView.value[connectionId] = ConnectionStatus.Muted;
            }
        });
        return connectionsView;
    }

    public replaceStatuses(from: ConnectionStatus, to: ConnectionStatus): ConnectionsView {
        const connectionsView: ConnectionsView = new ConnectionsView();
        connectionsView.setValue(this.getValue());
        Object.keys(connectionsView.value).forEach((connectionId: string) => {
            if (connectionsView.hasStatus(connectionId, from)) {
                connectionsView.value[connectionId] = to;
            }
        });
        return connectionsView;
    }

    public hasConnections(): boolean {
        return Object.keys(this.value).length > 0;
    }
}
