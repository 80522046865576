import { makeAutoObservable } from 'mobx';

import { MarkFilters, Mark } from '../TrajectoriesListTypes';

class MarksStore {
    showMarksList: boolean = false;

    markFilters: MarkFilters = {
        publicTrajectories: false,
        privateTrajectories: false,
        personTrajectories: false,
        presetTrajectories: false,
    };

    constructor() {
        makeAutoObservable(this);
    }

    changeShowMarksList(): void {
        this.showMarksList = !this.showMarksList;
    }

    toggleMarksOnFilter(mark: Mark): void {
        this.markFilters = {
            ...this.markFilters,
            [mark]: !this.markFilters[mark],
        };
    }
}

export default new MarksStore();
