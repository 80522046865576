import React from 'react';
import { NavLink, useRouteMatch } from 'react-router-dom';

import { StudentTargetName, useSendMetriks } from '@common/hooks';

import { useWindowSize } from '@common/HeaderRedesign/useWindowSize';
import { useSeparateTabs } from '@common/HeaderRedesign/useSeparateTabs';
import { HiddenTabs } from '@common/TabsRedesignWithHiddenTabs/HiddenTabs';
import { TabsOption } from '@common/TabsRedesign/TabsRedesign';
import { ViewType } from '../../deprecated-graphql-query-types';

import classes from './TabsRedesignWithHiddenTabs.module.scss';

interface Props {
    tabsOptions: TabsOption[];
}

export function TabsRedesignWithHiddenTabs(
    {
        tabsOptions,
    }: Props,
) {
    const { path } = useRouteMatch();

    const { windowWidth } = useWindowSize();

    // tab max-width + gap
    const tabWidth = 257;

    // windowWidth - allPaddings - buttonWidth - moreWidth
    const maxRightPosition = windowWidth - 500;

    const {
        visibleTabs,
        hiddenTabs,
    } = useSeparateTabs({
        maxRightPosition,
        tabWidth,
        tabs: tabsOptions ?? [],
    });

    const { sendYandexMetrika } = useSendMetriks([ViewType.Student]);

    return (
        <div className={classes.tabs}>

            <ul className={classes.tabs__list}>

                {
                    visibleTabs.map((option, index) => (
                        <li
                            key={option.link}
                            className={classes.tabs__item}
                            style={{
                                zIndex: 100 + tabsOptions.length - index,
                            }}
                        >

                            <NavLink
                                className={classes.tabs__link}
                                activeClassName={classes.tabs__link_selected}
                                to={`${path}/${option.link}`}
                                onClick={() => {
                                    if (path === '/modules') {
                                        sendYandexMetrika(StudentTargetName.modules, {
                                            statusModule: option.selectionStatus,
                                        });
                                    }
                                }}
                            >
                                {
                                    option.title
                                }
                            </NavLink>

                            {
                                option.additionalInfoOnHover?.length ? (
                                    <div className={classes.tabs__additional}>
                                        {option.additionalInfoOnHover}
                                    </div>
                                ) : null
                            }

                        </li>
                    ))
                }

                {
                    hiddenTabs.length && (
                        <HiddenTabs
                            visibleTabsAmount={visibleTabs.length}
                            tabsOptions={hiddenTabs}
                        />
                    )
                }

            </ul>

        </div>
    );
}
