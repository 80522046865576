import React from 'react';
import cn from 'classnames';
import classes from './TeacherAssignmentsContent.module.scss';

interface Props {
    children?: JSX.Element | JSX.Element[];
    assignmentOpen: boolean;
}

export function TeacherAssignmentsContent({ children, assignmentOpen }: Props) {
    return (
        <div className={cn(classes.page__content,
            {
                [classes.hide]: assignmentOpen,
            })}
        >
            {children}
        </div>
    );
}
