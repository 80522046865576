import React from 'react';
import cn from 'classnames';

import css from './TeacherAssignmentsPeriods.module.scss';
import { GetTeacher_teacher_baseTimeIntervalInstancesModules } from '../../TeacherAssignmentsApolloTypes';

interface Props {
    btiTabs: GetTeacher_teacher_baseTimeIntervalInstancesModules[];
    activeBTIId?: string;
}

export const TeacherAssignmentsPeriods = ({ btiTabs, activeBTIId }: Props) => (
    <div className={css.tabs}>
        {btiTabs.map(({ baseTimeIntervalInstance }) => (
            <div
                className={cn(css.tab, {
                    [css.tab_active]: activeBTIId === baseTimeIntervalInstance.id,
                })}
                key={baseTimeIntervalInstance.spaceEducationPeriod.id}
            >
                {
                    `
                        ${baseTimeIntervalInstance.spaceBaseTimeInterval.order}
                        ${baseTimeIntervalInstance.spaceBaseTimeInterval.space.baseTimeIntervalType.name},
                        ${baseTimeIntervalInstance.spaceEducationPeriod.name}
                    `
                }
            </div>
        ))}
    </div>
);
