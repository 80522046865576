import React, { Fragment } from 'react';
import cn from 'classnames';
import SkillHierarchy, { Skill, SkillNode } from '@store/SkillHierarchy';
import { SkillWithLevel } from '../../Config/interfaces';
import SkillType from '../../../../store/SkillType';
import { SkillTypeTemplate } from '../../EventItem/SkillType';

import classes from './ModuleSkills.module.scss';

interface Props {
    skillsTree: SkillNode[];
    title: string;
    skills: SkillWithLevel[];
    isPrerequisiteSkills: boolean;
}

export function ModuleSkills({ skillsTree, title, skills, isPrerequisiteSkills }: Props) {
    function getFilteredSkillsByType(typeId: string) {
        return skillsTree.filter(({ skill }) => skill.typeId === typeId);
    }
    const skillFilteredHierarchy = SkillHierarchy
        .filterBySkillIds(skills.map(({ skill }) => skill.id));
    const getSkillList = (skill: SkillNode, list: SkillNode[]): SkillNode[] => {
        list.push(skill);
        if (skill.children.length === 0) {
            return list;
        }
        return skill.children.map((item) => getSkillList(item, list)).flat();
    };
    const skillList = skillFilteredHierarchy.map((key) => getSkillList(key, [])).flat();
    const getParentIds = (skillId: string, list: Skill[]): Skill[] => {
        const skill = skillList.find((item) => item.skill.id === skillId)?.skill;
        const newList = [...list, skill!];
        if (!skill?.parent?.id) {
            return newList;
        }
        return getParentIds(skill?.parent.id, newList);
    };
    const skillListWithParents = skills.map(({ skill }) => getParentIds(skill.id, []));

    function getStyle(style: string) {
        return isPrerequisiteSkills ? classes[`${style}_prerequisite`] : classes[`${style}_output`];
    }
    const defaultText = isPrerequisiteSkills ? 'Попадание на модуль возможно с любыми образовательными результатами' : 'Не указаны';
    return (
        <div className={cn(classes.moduleSkills__container, getStyle('moduleSkills__container'))}>
            <div className={cn(classes.moduleSkills__title, getStyle('moduleSkills__title'))}>
                {title}
            </div>
            {skillsTree.length === 0 && (
                <div
                    className={classes.moduleSkills__defaultText}
                >
                    {defaultText}
                </div>
            )}
            <div className={classes.skills__container}>
                {SkillType.skillTypes.map(type => (
                    <Fragment key={type.id}>
                        {getFilteredSkillsByType(type.id).length > 0 && (

                            <SkillTypeTemplate
                                key={type.id}
                                skillListWithParents={skillListWithParents}
                                type={type}
                            />
                        )}
                    </Fragment>
                ))}
            </div>
        </div>
    );
}
