import React from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import ru from 'date-fns/locale/ru';
import './Calendar.scss';

interface Props {
    startDate: null | Date;
    endDate: null | Date;
    customTimeInput?: JSX.Element;
    customInput?: JSX.Element;
    showTimeInput?: boolean;
    shouldCloseOnSelect?: boolean;
    modifier?: string;
    onChange(update: any): void;
}
export const Calendar = ({
    startDate,
    endDate,
    customTimeInput,
    showTimeInput,
    shouldCloseOnSelect,
    modifier,
    customInput,
    onChange,
}: Props): JSX.Element => (

    <div className="calendar">
        <DatePicker
            selectsRange
            startDate={startDate}
            endDate={endDate}
            onChange={onChange}
            isClearable
            placeholderText="Выбрать даты"
            dateFormat="dd.MM.yyyy"
            locale={ru}
            className={`calendar__input calendar__input_${modifier}`}
            autoComplete="on"
            popperClassName="calendar__popperUsers"
            wrapperClassName="calendar__wrapperUsers"
            showYearDropdown
            dropdownMode="scroll"
            customTimeInput={customTimeInput}
            showTimeInput={showTimeInput}
            shouldCloseOnSelect={shouldCloseOnSelect}
            timeInputLabel="Время:"
            customInput={customInput}
            calendarStartDay={1}
        />
    </div>
);
