import CSS from 'csstype';
import { Rectangle } from './services/rectangle';

export interface Subspace {
    id: string;
    name: string;
    targetSubspaces: Subspace[];
    sourceSubspaces: Subspace[];
}

export interface SpaceBaseTimeInterval {
    id: string;
    order: number;
}

export interface SubspaceTypeLayout {
    spaceBaseTimeInterval: SpaceBaseTimeInterval;
}

export interface SubspaceType {
    id: string;
    name: string;
    subspaceTypeLayouts: SubspaceTypeLayout[];
    subspaces: Subspace[];
}

export interface BaseTimeIntervalType {
    name: string;
}
export interface Space {
    spaceBaseTimeIntervals: SpaceBaseTimeInterval[];
    baseTimeIntervalType: BaseTimeIntervalType;
    name: string;
}

export interface TypesStyles {
    [id: string]: CSS.Properties;
}

export interface SubspacesTypesRectangles {
    [id: string]: Rectangle;
}

export enum SubspaceLinkActionType {
    Add = 'add',
    Delete = 'delete',
}

export interface SubspaceLinkAction {
    actionType: SubspaceLinkActionType;
    sourceId: string;
    targetId: string;
}

export enum EditorStatus {
    Disable = 'Disable',
    BeginningOfWork = 'BeginningOfWork',
    HasFirstSelectedSubspace = 'HasFirstSelectedSubspace',
    HasFirstSelectedConnection = 'HasFirstSelectedConnection',
}

export const PromptTexts = {
    viewer: 'Нажмите “Редактировать”, чтобы задать связи',
    editor: {
        [EditorStatus.BeginningOfWork]: 'Выберите подпространство, от которого хотите провести связь',
        [EditorStatus.HasFirstSelectedSubspace]: 'Отметьте подпространство, следующее по связи за ним',
        [EditorStatus.HasFirstSelectedConnection]: 'Сохраните проведенные связи',
    },
};
