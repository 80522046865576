import React from 'react';
import './prompt.scss';

interface Props {
    text: string;
}

export function Prompt({ text }: Props): JSX.Element {
    return (
        <p className="prompt">{text}</p>
    );
}
