import React from 'react';

import { IconDeprecated } from '@common';

import classes from './EducationSpaceInfo.module.scss';

export function SubspaceTypeSelector(): JSX.Element {
    return (
        <div className={classes.subspaceTypeSelector}>
            <div className={classes.subspaceTypeSelector__label}>
                Укажите тип подпространств для выбора
            </div>

            <select
                className={classes.subspaceTypeSelector__select}
                defaultValue="123"
            >
                <option value="123" data-default disabled>Выберите тип подпространства</option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
            </select>

            <IconDeprecated className={classes.subspaceTypeSelector__icon} id="yellowPlus" />
        </div>
    );
}
