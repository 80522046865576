import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import { NavLink, useLocation } from 'react-router-dom';
import { WhitePointIcon, PointIcon } from '@common/svg';

import classes from './NavigationItems.module.scss';

interface Props {
    text: string;
    to: string;
    hasNotification?: boolean;
    onClick(): void;
}

export function NavigationItem({ text, to, hasNotification = false, onClick }: Props): JSX.Element {
    const location = useLocation();
    // очень спорное решение, надо потом подумать
    const [isActive, setIsActive] = useState(location.pathname.slice(0, to.length) === to);

    useEffect(() => {
        setIsActive(location.pathname.slice(0, to.length) === to);
    }, [location.pathname]);

    return (
        <NavLink
            className={(cn(classes.menuNavigation__item, {
                [classes.menuNavigation__item_exit]: text === 'Выход',
            }))}
            activeClassName={classes.menuNavigation__itemSelected}
            to={to}
            onClick={onClick}
        >
            {text}
            {hasNotification && (!isActive ? (
                <PointIcon
                    className={classes.menuNavigation__item_point}
                />
            ) : (
                <WhitePointIcon
                    className={classes.menuNavigation__item_point}
                />
            )
            )}
        </NavLink>
    );
}
