import React, { useState } from 'react';
import { format, isAfter } from 'date-fns';

import { Accordion } from '@common/Accordion';
import {
    SpaceBaseTimeInterval,
    SpaceBaseTimeIntervalParameters,
    ModuleAssessment,
    RefetchGetSpaces,
} from '@admin/EducationPeriodPage/EducationPeriodPageInterfaces';
import {
    AccordionHeader,
} from '@admin/EducationPeriodPage/EducationPeriodDates/EducationPeriodDatesView/EducationPeriodDatesAccordion/AccordionHeader';

import {
    AccordionItems,
} from './AccordionItems';

interface Props {
    name: string;
    spaceId: string;
    baseTimeInterval: SpaceBaseTimeInterval;
    parameters?: SpaceBaseTimeIntervalParameters;
    refetchGetSpaces?: RefetchGetSpaces;
}

export function EducationPeriodDatesAccordion(
    {
        name,
        spaceId,
        baseTimeInterval,
        parameters,
        refetchGetSpaces,
    }: Props,
) {
    const [isAccordionOpen, setAccordionOpen] = useState(isAfter(new Date(parameters?.spaceBaseTimeIntervalEnd ?? ''), new Date()));

    return (
        <Accordion
            shouldClickOnHeaderOpen
            isDefaultOpen={isAccordionOpen}
            additionalSetState={() => setAccordionOpen(!isAccordionOpen)}
        >

            <AccordionHeader
                name={name}
                isAccorionOpen={isAccordionOpen}
                baseTimeInterval={baseTimeInterval}
                parameters={parameters}
                refetchGetSpaces={refetchGetSpaces}
            />

            <AccordionItems
                spaceId={spaceId}
                baseTimeInterval={baseTimeInterval}
                parameters={parameters}
                refetchGetSpaces={refetchGetSpaces}
            />

        </Accordion>
    );
}

export function getBtiInstance(
    baseTimeInterval: SpaceBaseTimeInterval,
    parameters?: SpaceBaseTimeIntervalParameters,
) {
    return baseTimeInterval.baseTimeIntervalInstances.find(
        (instance) => instance.id === parameters?.baseTimeIntervalInstanceId,
    );
}

export function formatDate(date: (string | undefined)) {
    const dateParts = date && date?.split('-');
    if (dateParts) {
        return `${dateParts[2]}.${dateParts[1]}.${dateParts[0]}`;
    }
    return 'не задано';
}

export function formatModuleAssessmentDates(
    { startDate, isDynamicStartDate, endDate }: ModuleAssessment,
) {
    const endDateWithoutTime = format(new Date(endDate!), 'yyyy-MM-dd');
    if (isDynamicStartDate) {
        return <>{formatDate(endDateWithoutTime)}</>;
    }
    const startDateWithoutTime = format(new Date(startDate!), 'yyyy-MM-dd');
    return (
        <>
            {formatDate(startDateWithoutTime)}&nbsp;-&nbsp;{formatDate(endDateWithoutTime)}
        </>
    );
}
