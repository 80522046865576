import React from 'react';
import { format } from 'date-fns';
import cn from 'classnames';

import { useUrlQuery } from '@common/hooks';
import { formatWaves } from '@common/formatters';
import { EventStatus } from '@admin/ScheduleGenerationPage/utils';
import { DraftIcon, PinIcon } from '@common/svg';

import { RoomType, ScheduleEvent } from './types';

import classes from './ScheduleGenerationPage.module.scss';

interface Props {
    event: ScheduleEvent,
    setDialog(prev: boolean): void;
}

export function ScheduleCard({
    event,
    setDialog,
}: Props): JSX.Element {
    const { setUrlQuery } = useUrlQuery();
    const roomTitle = event.roomType === RoomType.Indoor
        ? event.room?.title
        : event.onlineRoom?.url;
    return (
        <div
            onClick={() => {
                setUrlQuery({ detailedEventId: event.id, detailedEventType: event.type });
                setDialog(true);
            }}
            className={cn(classes.schedule__event, {
                [classes.schedule__event_assignment]: event.type === 'assignment',
                [classes.schedule__event_evaluationPoint]: event.type === 'evaluationPoint',
                [classes.schedule__event_deleted]: event.status === 'deleted',
            })}
        >
            <div className={cn(classes.schedule__p, classes.schedule__p_module)}>
                {event.module?.name}
            </div>
            <div className={cn(classes.schedule__p, classes.schedule__p_wave)}>
                {event.waves && event.waves.length > 0 && (
                    <span className={classes.schedule__infoItem}>
                        {formatWaves(event.waves)}
                    </span>
                )}
            </div>
            <div className={cn(classes.schedule__p, classes.schedule__p_type)}>
                {event.type === 'meeting' && 'Встреча:'}
                {event.type === 'assignment' && 'Сам работа:'}
                {event.type === 'evaluationPoint' && 'Точка оценки:'}
            </div>
            <div className={cn(classes.schedule__p, classes.schedule__p_meeting)}>
                {event.meeting?.topic ?? event.assignment?.topic ?? event.evaluationPoint?.topic ?? ''}
            </div>
            <div className={cn(classes.schedule__p, classes.schedule__p_format)}>
                {event.meeting?.format?.name}
            </div>
            <div className={cn(classes.schedule__p, classes.schedule__p_endTime)}>
                {`До ${format(new Date(event.endTime), 'HH:mm')}`}
            </div>
            <div className={cn(classes.schedule__p, classes.schedule__p_info)}>
                {roomTitle && (
                    <span className={classes.schedule__infoItem}>
                        {roomTitle}
                    </span>
                )}
                {event.teachers && (
                    <span
                        className={cn(
                            classes.schedule__infoItem,
                            classes.schedule__infoItem_teachers,
                        )}
                    >
                        {event.teachers.map(({ id, user }) => (
                            <span key={id} className={classes.schedule__infoTeacher}>
                                {user.lastName} {user.firstName[0]}.
                            </span>
                        ))}
                    </span>
                )}
                {event.students && (
                    <span className={classes.schedule__infoItem}>
                        {event.students.length} студентов
                    </span>
                )}
            </div>
            {event.status === EventStatus.GENERATION && (
                <DraftIcon className={classes.schedule__eventStatus} />
            )}
            {event.pinned && <PinIcon className={classes.schedule__eventPinned} />}
        </div>
    );
}
