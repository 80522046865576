import { observer } from 'mobx-react';
import React from 'react';

import colors from '../../store/ColorConstans';

interface Props {
    size?: number;
    firstColor?: string;
    secondColor?: string;
    thirdColor?: string;
    className?: string;
}

export const PlusIcon = observer(({
    size = 16,
    firstColor = colors.firstColor,
    secondColor = colors.secondColor,
    thirdColor = colors.thirdColor,
    className,
}: Props): JSX.Element => (
    <div className={className} style={{ height: `${size}px`, width: `${size}px`, cursor: 'pointer' }}>
        <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.75 7.75H8.75005V1.74995C8.75005 1.33604 8.414 1 7.99996 1C7.58605 1 7.25 1.33604 7.25 1.74995V7.75H1.24995C0.836044 7.75 0.5 8.08605 0.5 8.49996C0.5 8.914 0.836044 9.25005 1.24995 9.25005H7.25V15.25C7.25 15.664 7.58605 16 7.99996 16C8.414 16 8.75005 15.664 8.75005 15.25V9.25005H14.75C15.164 9.25005 15.5 8.914 15.5 8.49996C15.5 8.08605 15.164 7.75 14.75 7.75Z" fill="url(#plusIcon)" />
            <defs>
                <linearGradient id="plusIcon" x1="16.5844" y1="7.64559" x2="0.279181" y2="14.0103" gradientUnits="userSpaceOnUse">

                    <stop offset="0.0416667" stopColor={firstColor} />
                    <stop offset="0.130208" stopColor={firstColor} />
                    <stop offset="0.734375" stopColor={secondColor} />
                    <stop offset="0.932292" stopColor={thirdColor} />
                </linearGradient>
            </defs>
        </svg>
    </div>
));
