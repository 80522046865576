import React, { useEffect, useState } from 'react';

import { MainDropDown, TMainDropDownOption } from '@common/MainDropDown';
import { Alert, PlusIconWithWhiteBorder } from '@common';
import { DatePickerWithTime, DateWithTime } from '@common/DatePickerWithTime';

import { nanoid } from 'nanoid';
import { format, areIntervalsOverlapping as areIntervalsOverlappingDateFns, isAfter } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';

import classes from './IntervalByDates.module.scss';
import { RoomAvailableIntervalInput, RoomsPage_Rooms_rooms_location } from '../../../../../../../graphql-query-types';

interface Props {
    universityTimeZone: string;
    isEndDateDisabled: boolean;
    availableIntervals: RoomAvailableIntervalInput[];
    setAvailableIntervals: (
        newAvailableIntervals: RoomAvailableIntervalInput[],
    ) => void;
    location?: RoomsPage_Rooms_rooms_location;
}

interface ValidationError {
    message: string;
    isValid: boolean;
}

const defaultTime = {
    from: '00:00',
    to: '23:59',
};

const dropDownOptions: TMainDropDownOption[] = [
    {
        id: 'false',
        name: 'Не повторять',
    },
    {
        id: 'true',
        name: 'Каждый месяц',
    },
];

export function IntervalByDates(
    {
        universityTimeZone,
        isEndDateDisabled,
        location,
        availableIntervals,
        setAvailableIntervals,
    }: Props,
) {
    const doesLocationHasTerritorialZone = (location
        && location.territorialZone && location.territorialZone.availableIntervals.length);

    const {
        validationError,
        activeOption,
        canAddInterval,
        validationErrors,
        updateAvailableIntervals,
        setDateWithTime,
        setActiveOption,
        addError,
    } = useGetIntervalByDates(
        universityTimeZone,
        isEndDateDisabled,
        availableIntervals,
        setAvailableIntervals,
    );

    const initialDates = {
        start: doesLocationHasTerritorialZone
            ? new Date(location?.territorialZone?.availableIntervals[0].from) : new Date(),
        end: doesLocationHasTerritorialZone
            ? new Date(location?.territorialZone?.availableIntervals[0].to) : new Date(),
    };

    const includedDateIntervals = doesLocationHasTerritorialZone
        ? location.territorialZone.availableIntervals.map(
            (interval) => ({
                start: new Date(format(new Date(interval.from), 'MM/dd/yyyy')),
                end: new Date(format(new Date(interval.to), 'MM/dd/yyyy')),
            }),
        ) : [];

    return (
        <div className={classes.intervalByDates}>

            {
                canAddInterval && (
                    <div className={classes.intervalByDates__picker}>
                        <DatePickerWithTime
                            isEndDateDisabled={isEndDateDisabled}
                            selectedDates={initialDates}
                            getDateWithTime={(date) => setDateWithTime(date)}
                            includedDateIntervals={includedDateIntervals.length
                                ? includedDateIntervals : undefined
                            }
                        />

                        <button
                            type="button"
                            onClick={() => {
                                if (validationError.isValid) {
                                    updateAvailableIntervals();
                                } else {
                                    addError(
                                        validationError.message,
                                    );
                                }
                            }}
                        >
                            <PlusIconWithWhiteBorder />
                        </button>
                    </div>
                )
            }

            <div className={classes.intervalByDates__options}>
                <MainDropDown
                    defaultOption={activeOption}
                    options={dropDownOptions}
                    optionsListClassname={classes.intervalByDates__options_list}
                    additionalOptionHandleCLick={(option) => setActiveOption(option)}
                />

            </div>

            {validationErrors}

        </div>
    );
}

function useGetIntervalByDates(
    universityTimeZone: string,
    isEndDateDisabled: boolean,
    availableIntervals: RoomAvailableIntervalInput[],
    setAvailableIntervals: (
        newAvailableIntervals: RoomAvailableIntervalInput[],
    ) => void,
) {
    const [validationError, setValidationError] = useState<ValidationError>({
        message: '',
        isValid: false,
    });
    const [canAddInterval, setCanAddInterval] = useState(true);
    const [activeOption, setActiveOption] = useState<TMainDropDownOption>(dropDownOptions[0]);
    const [dateWithTime, setDateWithTime] = useState<DateWithTime>({
        toDate: new Date(),
        fromDate: new Date(),
        fromTime: '00:00',
        toTime: '23:59',
    });
    const [validationErrors, setValidationErrors] = useState<JSX.Element[]>([]);

    const addError = (newMessage: string) => {
        setValidationErrors(
            (prevState) => [
                ...prevState,
                <Alert message={newMessage} time={6000} key={nanoid()} />,
            ],
        );
    };

    const updateAvailableIntervals = () => {
        const from = `${format(dateWithTime.fromDate ?? new Date(), 'yyyy-MM-dd')} ${dateWithTime.fromTime.length ? dateWithTime.fromTime : defaultTime.from}${universityTimeZone}`;
        const to = isEndDateDisabled ? `${format(dateWithTime.fromDate ?? new Date(), 'yyyy-MM-dd')} ${dateWithTime.toTime.length ? dateWithTime.toTime : defaultTime.to}${universityTimeZone}` : `${format(dateWithTime.toDate ?? new Date(), 'yyyy-MM-dd')} ${dateWithTime.toTime.length ? dateWithTime.toTime : defaultTime.to}${universityTimeZone}`;

        const normalizedDateWithTime: RoomAvailableIntervalInput = {
            id: nanoid(),
            isEveryMonth: activeOption.id === 'true',
            from,
            to,
        };

        setCanAddInterval(false);

        setAvailableIntervals([...availableIntervals, normalizedDateWithTime]);
    };

    useEffect(() => {
        setCanAddInterval(true);
    }, [
        canAddInterval,
    ]);

    useValidateDate(
        universityTimeZone,
        isEndDateDisabled,
        dateWithTime,
        availableIntervals,
        setValidationError,
    );

    return {
        validationError,
        canAddInterval,
        activeOption,
        validationErrors,
        setActiveOption,
        setCanAddInterval,
        setDateWithTime,
        updateAvailableIntervals,
        addError,
    };
}

function useValidateDate(
    universityTimeZone: string,
    isEndDateDisabled: boolean,
    dateWithTime: DateWithTime,
    availableIntervals: RoomAvailableIntervalInput[],
    setIsDateValid: (validationError: ValidationError) => void,
) {
    useEffect(() => {
        const canValidateOverlapping = isAfter(
            new Date(dateWithTime.toDate ?? new Date()),
            new Date(dateWithTime.fromDate ?? new Date()),
        );

        if (!canValidateOverlapping) {
            setIsDateValid({
                message: '',
                isValid: true,
            });
        }

        if (!canValidateOverlapping && !isEndDateDisabled) {
            setIsDateValid({
                message: 'Дата начала должна быть позже даты конца',
                isValid: false,
            });
        }

        if (canValidateOverlapping) {
            let isDateValidTemp = true;
            availableIntervals.forEach((interval) => {
                const startDateToValidate = new Date(formatInTimeZone(dateWithTime.fromDate ?? new Date(), universityTimeZone, 'yyyy-MM-dd'));
                const endDateToValidate = isEndDateDisabled ? startDateToValidate : new Date(formatInTimeZone(dateWithTime.toDate ?? new Date(), universityTimeZone, 'yyyy-MM-dd'));

                const areIntervalsOverlapping = areIntervalsOverlappingDateFns(
                    {
                        start: startDateToValidate,
                        end: endDateToValidate,
                    },
                    {
                        start: new Date(formatInTimeZone(interval.from, universityTimeZone, 'yyyy-MM-dd')),
                        end: new Date(formatInTimeZone(interval.to, universityTimeZone, 'yyyy-MM-dd')),
                    },
                );
                if (areIntervalsOverlapping) {
                    isDateValidTemp = false;
                }
            });

            setIsDateValid({
                message: 'Пересекаются даты ограничения помещения',
                isValid: isDateValidTemp,
            });
        }
    }, [dateWithTime, availableIntervals, isEndDateDisabled]);
}
