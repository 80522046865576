import React from 'react';

export const selectedCountSVG = (isSelectedAll: boolean) => (
    <>
        {isSelectedAll ? (
            <svg width="24" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="10" cy="10" r="8" fill="#0B42DC" />
                <path fillRule="evenodd" clipRule="evenodd" d="M9.70711 12.293L14 8.00008L13.2929 7.29297L9 11.5859L6.70711 9.29297L6 10.0001L8.29289 12.293L9 13.0001L9.70711 12.293Z" fill="white" />
            </svg>
        ) : (
            <svg width="24" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="10" cy="10" r="7.5" stroke="#C7C7C7" />
                <path fillRule="evenodd" clipRule="evenodd" d="M9.70711 12.293L14 8.00008L13.2929 7.29297L9 11.5859L6.70711 9.29297L6 10.0001L8.29289 12.293L9 13.0001L9.70711 12.293Z" fill="#C7C7C7" />
            </svg>
        )}
    </>
);
