import React, { useEffect, useState } from 'react';

import classes from './EquipmentForRooms.module.scss';

import { EquipmentSuggestion } from '../EquipmentSuggestion';

import { ByTerritorialZone, ByRoom, Equipment } from '../equipmentTypes';
import { EquipmentTable } from '../EquipmentTable';

interface Props {
    equipmentByTerritorialZone: ByTerritorialZone[];
}

export function EquipmentForRooms({ equipmentByTerritorialZone }: Props): JSX.Element {
    const [selectedRooms, setSelectedRooms] = useState<ByRoom[]>([]);
    const [selectedRoomsEquipments, setSelectedRoomsEquipments] = useState<Equipment[]>([]);
    const roomsWithEquipments = equipmentByTerritorialZone
        .map((zone: ByTerritorialZone) => zone.byRoom);
    const rooms: ByRoom[] = [];
    roomsWithEquipments.forEach((allRooms: any) => {
        allRooms.map((room: ByRoom) => rooms.push(room));
    });
    useEffect(() => {
        const equipmentBySelectedRooms = selectedRooms.map((item: ByRoom) => (
            item.consumableEquipment
        )).flat();
        setSelectedRoomsEquipments([...equipmentBySelectedRooms]);
    }, [selectedRooms]);
    return (
        <div className={classes.equipmentForRooms}>
            <EquipmentSuggestion
                selectedRooms={selectedRooms}
                setSelectedRooms={setSelectedRooms}
                rooms={rooms}
            />
            {selectedRoomsEquipments.length > 0 && (
                <div className={classes.equipmentForRooms__table}>
                    <EquipmentTable
                        equipmentArray={selectedRoomsEquipments}
                    />
                </div>
            )}
        </div>
    );
}
