import { gql } from '@apollo/client';

export const GET_USER_ABSENCES = gql`
    query UserWithAbsences($id: String!) {
        user(id: $id) {
            id
            student {
                id
                space {
                    baseTimeIntervalType {
                        name
                    }
                }
                baseTimeIntervalInstances {
                    baseTimeIntervalInstance {
                        id
                        startDate
                        endDate
                        spaceBaseTimeInterval {
                            id
                            order
                        }
                    }
                }
                excusedAbsences {
                    id
                    startDate
                    endDate
                }
            }
        }
    }
`;

export const UPDATE_USER_ABSENCES = gql`
    mutation UpdateStudentExcusedAbsences(
        $input: UpdateStudentExcusedAbsencesInput
    ) {
        updateStudentExcusedAbsences(
            input: $input
        ) {
            id
        }
    }
`;
