import { gql } from '@apollo/client';

export const GET_TERRITORIAL_ZONES = gql`
query TerritorialZones {
  territorialZones {
    id
    name
  }
}`;

export const GET_TERRITORIAL_ZONE = gql`
query TerritorialZone($id: String!){
  territorialZone(id: $id) {
    rooms {
      id
      isAvailable
      availableIntervals {
        id
        from
        to
      }
      meetingFormats {
        id
        name
      }
      title
      capacity
      location {
        id
        name
        location
      }
      equipmentToRoom {
        roomId
        equipmentId
        equipment {
          category {
            name
          }
        }
        countEquipments
    }
    }
  }
}
`;

export const GET_BUILDINGS = gql`
query Buildings{
    buildings {
      id
      name
      location
    }
  }
`;

export const GET_ROOMS = gql`
query RoomsPage_Rooms {
    rooms {
      id
      isAvailable
      location {
        territorialZone {
          availableIntervals {
            from
            to
            id
          }
        }
      }
      availableIntervals {
        id
        from
        to
        isEveryMonth
      }
      repeatingAvailableIntervals {
        from
        to
        id
        weekDay
        weekType
      }
      meetingFormats {
        id
        name
      }
      title
      capacity
      location {
        id
        name
        location
      }
      equipmentToRoom {
        roomId
        equipmentId
        equipment {
          category {
            name
          }
        }
        countEquipments
      }
    }
  }
`;

export const DELETE_ROOM = gql`
mutation DeleteRoom ($id: String!) {
    removeRoom(id: $id) {
      status
    }
  }
`;

export const EDIT_ROOM = gql`
mutation UpdateRoom ($editRoomData: UpdateRoomInput!) {
  updateRoom(updateRoomInput:$editRoomData) {
    id
    availableIntervals {
      from
      to
      id
    }
  }
}
`;
export const CREATE_ROOM = gql`
mutation CreateRoom ($createRoomData: CreateRoomInput!) {
  createRoom(createRoomInput: $createRoomData) {
    id
  }
}
`;
export const GET_ROOM_TYPES = gql`
query MeetingFormats{
    meetingFormats{
    id
    name
  }
}
`;

export const GET_EQUIPMENT = gql`
  query GetEquipmentsOnRoomPage($filterParams: EquipmentFilterParams!, $paginationParams: EquipmentPaginationParams!) {
    equipment(filterParams: $filterParams, paginationParams: $paginationParams) {
      list {
          id
          category {
            id
            name
          }
          movability {
            isMovable
            count
          }
          rooms {
            countEquipments
          }
      }
    }
  }
`;

export const GET_UNIVERSITY_TIME_ZONE = gql`
    query RoomsPageGetUniversityTimeZone {
        universityTimezone
    }
`;
