import React from 'react';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import ru from 'date-fns/locale/ru';

import './Calendar.scss';

export type SelectedDate = Date | [(Date | null), (Date | null)];
interface Props {
    startDate: null | Date;
    endDate: null | Date;
    onChange(update: SelectedDate): void;
}

export function Calendar({
    startDate, endDate, onChange,
}: Props): JSX.Element {
    return (
        <div className="calendar">
            <DatePicker
                selectsRange
                startDate={startDate}
                endDate={endDate}
                onChange={onChange}
                isClearable
                placeholderText="Выбрать даты"
                dateFormat="dd.MM.yyyy"
                locale={ru}
                className="calendar__input"
                autoComplete="on"
                popperClassName="calendar__popperEquipment"
                wrapperClassName="calendar__wrapperEquipment"
                showYearDropdown
                dropdownMode="scroll"
                calendarStartDay={1}
            />
        </div>
    );
}
