import React from 'react';
import { NavLink, useRouteMatch } from 'react-router-dom';

import { StudentTargetName, useSendMetriks } from '@common/hooks';
import { SelectionStatus } from 'src/student/StudentModulesPage/graphql-query-types';

import cn from 'classnames';
import { ViewType } from '../../deprecated-graphql-query-types';

import classes from './TabsRedesign.module.scss';

export interface TabsOption {
    title: string;
    link: string;
    shouldReplaceLink?: boolean;
    selectionStatus? : SelectionStatus;
    additionalInfoOnHover?: string;
    isNotStudentChoiceFork?: boolean;
    wordInLinkToHighlight?: string;
}

interface Props {
    tabsOptions: TabsOption[];
}

export function TabsRedesign(
    {
        tabsOptions,
    }: Props,
) {
    const { path } = useRouteMatch();

    const { sendYandexMetrika } = useSendMetriks([ViewType.Student]);

    return (
        <div className={classes.tabs}>

            <ul className={classes.tabs__list}>

                {
                    tabsOptions.map((option, index) => (
                        <li
                            key={option.link}
                            className={classes.tabs__item}
                            style={{
                                zIndex: 100 + tabsOptions.length - index,
                            }}
                        >

                            <NavLink
                                className={cn(classes.tabs__link, {
                                    [classes.tabs__link_selected]: window.location.href.includes(option.wordInLinkToHighlight ?? 'noSuchWord'),
                                })}
                                activeClassName={classes.tabs__link_selected}
                                to={option.shouldReplaceLink ? option.link : `${path}/${option.link}`}
                                onClick={() => {
                                    if (path === '/modules') {
                                        sendYandexMetrika(StudentTargetName.modules, {
                                            statusModule: option.selectionStatus,
                                        });
                                    }
                                }}
                            >
                                {option.title}
                            </NavLink>

                            {
                                option.additionalInfoOnHover?.length ? (
                                    <div className={classes.tabs__additional}>
                                        {option.additionalInfoOnHover}
                                    </div>
                                ) : null
                            }

                        </li>
                    ))
                }

            </ul>

        </div>
    );
}
