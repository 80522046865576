import React, { useState } from 'react';
import filter from 'lodash/filter';
import map from 'lodash/map';
import join from 'lodash/join';
import { CreateBuildingInput, TerritorialZone } from '@admin/UniversityPage/types';
import findIndex from 'lodash/findIndex';
import { UniversityBuildingsList } from '@admin/UniversityPage/UniversityBuildingsList';
import { UniversityBuildingEdit } from '@admin/UniversityPage/UniversityBuildingEdit';
import { Alert } from '@common/Alert';
import { BuildingsErrors } from '@admin/UniversityPage/UniversityPageApollo';
import { Confirm } from '@common/Confirm';
import { UniversityBuildingAdd } from '../UniversityBuildingAdd';
import { AddNewBuild } from '../AddNewBuild';

interface Props {
    universityTimeZone: string;
    editZonesMode: boolean;
    listBuildingsMode: boolean;
    editBuildingsMode: boolean;
    addBuildingsMode: boolean;
    territorialZones?: TerritorialZone[];
    buildingsErrors: BuildingsErrors;
    handleChangeListMode(): void;
    handleChangeEditMode(): void;
    handleChangeAddMode(): void;
    createBuilding(building: CreateBuildingInput): void;
    updateBuilding(building: CreateBuildingInput): void;
    removeBuilding(id: string): void;
}

export function UniversityBuildings({
    universityTimeZone,
    editZonesMode,
    listBuildingsMode,
    editBuildingsMode, addBuildingsMode,
    territorialZones = [],
    buildingsErrors,
    createBuilding,
    updateBuilding,
    handleChangeListMode,
    handleChangeEditMode, handleChangeAddMode,
    removeBuilding,
}: Props): JSX.Element {
    const [editedBuilding, setEditedBuilding] = useState<CreateBuildingInput>({
        id: '',
        name: '',
        location: '',
        zoneId: '',
    });

    const [showWarning, setShowWarning] = useState(false);

    const handleSetShowWarning = (): void => {
        setShowWarning(!showWarning);
    };

    const checkBuildingsInZone = (
        zonesWithBuilds: TerritorialZone[],
    ): Boolean => findIndex(zonesWithBuilds, (zone) => zone.buildings.length >= 0) >= 0;

    const handleSetEditedBuilding = (build: CreateBuildingInput): void => {
        setEditedBuilding(build);
        handleChangeEditMode();
    };

    return (
        <>
            {showWarning && (
                <Confirm
                    headerText="Предупреждение"
                    descriptionText="Завершите редактирование зон либо нажмите кнопку 'Отменить'"
                    primaryBtnText="Ок"
                    onPrimaryBtnClick={handleSetShowWarning}
                    onOutClick={handleSetShowWarning}
                    onSecondaryBtnClick={handleSetShowWarning}
                />
            )}

            {!!filter(buildingsErrors, error => error?.message !== undefined).length
                && <Alert message={join(map(buildingsErrors, error => error?.message), '_____')} />}

            {checkBuildingsInZone(territorialZones) && listBuildingsMode && (
                <>
                    <UniversityBuildingsList
                        universityTimeZone={universityTimeZone}
                        territorialZones={territorialZones}
                        handleSetEditedBuilding={handleSetEditedBuilding}
                    />
                    <AddNewBuild
                        text="добавить корпус"
                        handler={editZonesMode ? handleSetShowWarning : handleChangeAddMode}
                    />
                </>
            )}

            {addBuildingsMode && (
                <UniversityBuildingAdd
                    territorialZones={territorialZones}
                    createBuilding={createBuilding}
                    handleChangeListMode={handleChangeListMode}
                />
            )}

            {editBuildingsMode && (
                <UniversityBuildingEdit
                    territorialZones={territorialZones}
                    editedBuilding={editedBuilding}
                    updateBuilding={updateBuilding}
                    removeBuilding={removeBuilding}
                    handleChangeListMode={handleChangeListMode}
                />
            )}

            {!checkBuildingsInZone(territorialZones) && !addBuildingsMode && (
                <AddNewBuild
                    text="Добавить корпус"
                    handler={handleChangeAddMode}
                />
            )}
        </>
    );
}
