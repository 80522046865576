import React, { Fragment } from 'react';

import classes from './ModuleCard.module.scss';

import { ModuleContent } from '../ModuleContent';
import { PrioritySetMode } from '../store';
import { StudentModule, StudentSchedule } from '../Config/interfaces';

interface Props {
    isElectiveFork?: boolean;
    modules?: StudentModule[];
    studentSchedule?: StudentSchedule;
    forkId?: string;
    setStudentPriorities?: (
        setMode: PrioritySetMode,
        forkId: string,
        moduleId: string,
        priority?: number,
    ) => void;
}

export function ModuleCards({
    isElectiveFork = false,
    forkId,
    modules,
    studentSchedule,
    setStudentPriorities,
}: Props): JSX.Element {
    const sortedStudentModules = modules?.sort((a, b) => (
        Number(b.isStudentModuleAssessmentActive) - Number(a.isStudentModuleAssessmentActive)
    ));
    return (
        <ul className={classes.moduleCards__list}>
            {sortedStudentModules?.map(({
                module,
                slotId,
                isStudentModuleAssessmentActive,
                id,
                studentsModulePriorityCount,
            }) => (
                <Fragment key={module.id}>
                    {module && (
                        <ModuleContent
                            slotId={slotId}
                            forkId={forkId}
                            isElectiveFork={isElectiveFork}
                            module={module}
                            studentSchedule={studentSchedule}
                            isStudentModuleAssessmentActive={isStudentModuleAssessmentActive}
                            studentModuleId={id}
                            studentsModulePriorityCount={studentsModulePriorityCount}
                            setStudentPriorities={setStudentPriorities}
                        />
                    )}
                </Fragment>
            ))}
        </ul>
    );
}
