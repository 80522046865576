import React from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import ru from 'date-fns/locale/ru';
import './Calendar.scss';

interface Props {
    currentDate: null | Date;
    onChange(update: any): void;
}
export const Calendar = ({
    currentDate, onChange,
}: Props): JSX.Element => (
    <div className={`calendar ${currentDate}`}>
        <DatePicker
            onChange={onChange}
            placeholderText="Выбрать даты"
            dateFormat="dd.MM.yyyy"
            shouldCloseOnSelect
            selected={currentDate}
            locale={ru}
            popperClassName="calendar__popperSpecialDays"
            wrapperClassName="calendar__wrapperSpecialDays"
            calendarStartDay={1}
        />
    </div>
);
