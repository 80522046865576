import React, { useState } from 'react';
import { observer } from 'mobx-react';

import { Accordion } from '@common/Accordion';
import { NotDistribItem } from '@admin/WaveSelectionPage/NotDistribAccordion/NotDistribItem';
import { waveSelectionStore } from '@admin/WaveSelectionPage/WaveSelectionStore';
import { DeleteStudentWaveInput } from '@admin/WaveSelectionPage/graphql-types';

import { ApolloPromise, SortTypes } from '@admin/WaveSelectionPage/Interfaces';
import cn from 'classnames';
import { getSortedStudents } from '@admin/WaveSelectionPage/WaveSelectionPage';
import { useGetSortButtonText } from '@admin/ModuleSelectionPage/UseGetSortButtonText';
import { checkIfCanSortByRating } from '@admin/ModuleSelectionPage/ModuleSelectionPage';
import classes from './NotDistribAccordion.module.scss';

interface Props {
    deleteStudentWave(input: DeleteStudentWaveInput): ApolloPromise;
}

export const NotDistributedAccordion = observer((
    {
        deleteStudentWave,
    } : Props,
) : JSX.Element => {
    const [sortTypes, setSortTypes] = useState<SortTypes>(
        {
            isSortAz: true,
            isSortZa: false,
            isSortByRating: false,
        },
    );
    const isAccordionDisabled = waveSelectionStore.getNotDistribStudents().length === 0;
    const notDistributedStudentsLength = waveSelectionStore.getNotDistribStudents().length
        - waveSelectionStore.getWaveSelectedStudents().length;

    const changeSortType = () => {
        const initialSortTypes: SortTypes = {
            isSortAz: false,
            isSortZa: false,
            isSortByRating: false,
        };
        const canSortByRating = checkIfCanSortByRating(
            waveSelectionStore.getStudentsWithRatings(),
            waveSelectionStore.getNotDistribStudents(),
        );
        if (sortTypes.isSortAz) {
            setSortTypes({
                ...initialSortTypes,
                isSortZa: true,
            });
        }

        if (sortTypes.isSortZa && canSortByRating) {
            setSortTypes({
                ...initialSortTypes,
                isSortByRating: true,
            });
        }

        if (sortTypes.isSortZa && !canSortByRating) {
            setSortTypes({
                ...initialSortTypes,
                isSortAz: true,
            });
        }

        if (sortTypes.isSortByRating) {
            setSortTypes({
                ...initialSortTypes,
                isSortAz: true,
            });
        }
    };

    const { sortButtonText } = useGetSortButtonText(sortTypes);

    const sortButtonHandleClick = () => {
        const sortedStudents = getSortedStudents(
            sortTypes,
            waveSelectionStore.getStudentsWithRatings(),
            waveSelectionStore.getNotDistribStudents(),
        );

        waveSelectionStore.setNotDistribStudents(sortedStudents);

        changeSortType();
    };

    return (
        <div className={classes.notDistributed}>

            <Accordion
                headerClassnames={classes.notDistributed__accordionHeader}
                isDefaultOpen
                shouldClickOnHeaderOpen
                disabled={isAccordionDisabled}
            >

                <div className={cn(classes.notDistributed__header, {
                    [classes.notDistributed__header_valid]: !notDistributedStudentsLength,
                })}
                >
                    Не распределены
                </div>

                <div className={classes.notDistributed__items}>

                    <div className={classes.notDistributed__item}>

                        <div className={cn(classes.notDistributed__item_title, {
                            [classes.notDistributed__item_valid]: !notDistributedStudentsLength,
                        })}
                        >
                            {
                                notDistributedStudentsLength
                            } студентов
                        </div>

                        <div className={classes.notDistributed__sort}>

                            <span className={classes.notDistributed__sort_txt}>
                                { sortButtonText }
                            </span>

                            <button
                                type="button"
                                className={classes.notDistributed__sort_btn}
                                onClick={() => {
                                    sortButtonHandleClick();
                                }}
                            >
                                <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect width="19" height="19" fill="#FFF" />
                                    <path d="M5.35059 4H7.34551V10.254H5.35059V4Z" fill="#4E5258" />
                                    <path d="M9.34049 4H11.3354V11.8174H14.3631L10.3734 15L6.3836 11.8174H9.34049V4Z" fill="#4E5258" />
                                </svg>
                            </button>

                        </div>

                    </div>

                    <div className={classes.notDistributed__scroll}>
                        {
                            waveSelectionStore.getNotDistribStudents().map((student, index) => (
                                <NotDistribItem
                                    key={`${student.user.studentId}-index-notDistributed`}
                                    order={index + 1}
                                    currentStudent={student}
                                    deleteStudentWave={deleteStudentWave}
                                />
                            ))
                        }
                    </div>

                </div>

            </Accordion>

        </div>
    );
});
