import { makeAutoObservable } from 'mobx';
import {
    DistributedStudentsGroupedByModule_distributedStudentsGroupedByModule
    as ModuleGroup,
    getForkUndistributedStudents_forkUndistributedStudents
    as UndistributedStudent,
} from '@admin/ModuleSelectionPage/graphql-types';
import { Fork, URLParameters } from '@admin/ModuleSelectionPage/Interfaces';
import { differenceBy } from 'lodash';
import {
    SpaceBaseTimeInterval_spaceBaseTimeInterval_spaceBaseTimeIntervalSubspaces
    as SpaceBaseTimeIntervalSubspace,
    SpaceBaseTimeInterval_spaceBaseTimeInterval_spaceBaseTimeIntervalSubspaces_forks_nextSlots
    as Slot,
} from './graphql-types';

class ModuleSelectionDataStore {
    private moduleGroups: ModuleGroup[] = [];

    private forks: Fork[] = [];

    private currentModuleIds: string[] = [];

    private previousBTIInstanceId: string = '';

    private allStudentsIds: string[] = [];

    private isModuleDistributionValid: boolean = false;

    private URLParameters: URLParameters = {
        baseTimeIntervalInstanceId: '',
        forkId: '',
        baseTimeIntervalId: '',
        spaceId: '',
        btiOrder: '',
        isNotStudentChoice: false,
    };

    constructor() {
        makeAutoObservable(this);
    }

    setPreviousBTIInstanceId = (
        id: string,
    ) => {
        this.previousBTIInstanceId = id;
    };

    setCurrentModuleIds = (
        newModuleIds: string[],
    ) => {
        this.currentModuleIds = newModuleIds;
    };

    setForks = (
        newForks: Fork[],
    ) => {
        this.forks = newForks;
    };

    setForksFromSubspaceSpaceBaseTimeIntervalSubspaces = (
        subspaces: SpaceBaseTimeIntervalSubspace[],
    ) => {
        this.setForks(
            getForksFromSubspaceSpaceBaseTimeIntervalSubspaces(
                subspaces,
            ),
        );
    };

    setAllStudentsIds = (
        studentsIds: string[],
    ) => {
        this.allStudentsIds = studentsIds;
    };

    setAllStudentsIdsFromUndistributedStudents = (
        undistributedStudents: UndistributedStudent[],
    ) => {
        const studentsIds = getStudentsIdsFromNotDistributedStudents(undistributedStudents);

        this.setAllStudentsIds([...this.allStudentsIds, ...studentsIds]);
    };

    setAllStudentsIdsFromModuleGroups = (
        moduleGroups: ModuleGroup[],
    ) => {
        const studentsIds = getStudentsIdsFromModuleGroups(moduleGroups);

        this.setAllStudentsIds([...this.allStudentsIds, ...studentsIds]);
    };

    setIsModuleDistributionValid = (
        isValid: boolean,
    ) => {
        this.isModuleDistributionValid = isValid;
    };

    updateURLParameters = () => {
        const params = new URLSearchParams(window.location.search);

        this.URLParameters = {
            baseTimeIntervalInstanceId: params.get('btiIId') ?? '',
            forkId: params.get('forkId') ?? '',
            isNotStudentChoice: params.get('isRequired') === 'true',
            baseTimeIntervalId: params.get('btiId') ?? '',
            spaceId: params.get('spaceId') ?? '',
            btiOrder: params.get('btiOrder') ?? '',
        };
    };

    getCurrentModuleIds = () => this.currentModuleIds;

    getForks = () => this.forks;

    getPreviousBTIInstanceId = () => this.previousBTIInstanceId;

    getAllStudentsIds = () => this.allStudentsIds;

    getURLParameters = () => this.URLParameters;

    getIsModuleDistributionValid = () => this.isModuleDistributionValid;
}

export const moduleSelectionDataStore = new ModuleSelectionDataStore();

function getForksFromSubspaceSpaceBaseTimeIntervalSubspaces(
    spaceBaseTimeIntervalSubspaces: SpaceBaseTimeIntervalSubspace[],
): Fork[] {
    const forks: Fork[] = [];

    spaceBaseTimeIntervalSubspaces.forEach((subspace) => {
        const notRequiredSlots: Slot[] = [];

        subspace.forks.forEach((fork) => {
            forks.push(
                {
                    id: fork.id,
                    subspace: subspace.subspace,
                    isNotStudentChoice: false,
                    slots: fork.nextSlots,
                },
            );

            notRequiredSlots.push(...fork.nextSlots);
        });
        const requiredSlots = differenceBy(subspace.slots, notRequiredSlots, (slot) => slot.id);
        if (requiredSlots.length) {
            forks.push(
                {
                    id: subspace.subspace.id,
                    subspace: subspace.subspace,
                    isNotStudentChoice: true,
                    slots: requiredSlots,
                },
            );
        }
    });

    return forks;
}

function getStudentsIdsFromNotDistributedStudents(
    students: UndistributedStudent[],
): string[] {
    return students.map((student) => student.student.user.studentId)
        .flatMap((id) => (id ? [id] : []));
}

function getStudentsIdsFromModuleGroups(
    moduleGroups: ModuleGroup[],
): string[] {
    return moduleGroups.flatMap((group) => (
        group.students.flatMap((stuent) => stuent.user.studentId)
    )).flatMap((id) => (id ? [id] : []));
}
