/* eslint react/no-danger: 0 */
import React, { Fragment, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react';
import { groupBy } from 'lodash';
import { format } from 'date-fns';
import cn from 'classnames';

import { useQuery } from '@apollo/client';
import { TabsWithoutRouteRedesign } from '@common';

import { SelectionStatus } from '../graphql-query-types';
import { GET_STUDENTS_MODULE_PRIORITY_COUNT } from '../queries';
import { FutureSubspace } from './FutureSubspace';
import { DistributionType } from '../../../subSpaceAdmin/subSpaceTypes';
import { FutureBTI, ModuleWithPriorityCount } from '../Config/interfaces';
import { ElectivePoint, DistributionPoint } from '../ChoicePoint';
import { InfoCard } from '../ChoicePoint/InfoCard';
import UserView from '../../../store/UserView';
import store, { PrioritySetMode } from '../store';

import classes from './Subspaces.module.scss';

enum Tabs {
    ALL = 'Все',
    OBLIGATORY = 'Обязательные',
    DISTRIBUTION = 'По распределению',
    ELECTIVE = 'Выборные',
}

interface Props {
    studentSubspacesIds?: string[];
    futureBtiData?: FutureBTI;
    instruction: string;
    formatDateBTIToString: string;
    setStudentPriorities: (
        setMode: PrioritySetMode,
        forkId: string,
        moduleId: string,
        priority?: number,
    ) => void;
}
const slotsAfterForkIds: string[] = [];

export const FutureSubspaceList = observer(({
    studentSubspacesIds,
    futureBtiData,
    instruction,
    formatDateBTIToString,
    setStudentPriorities,
}: Props) => {
    const { id } = useParams<{ id: string; }>();
    const { data, refetch } = useQuery<{
        studentsModulePriorityCount: ModuleWithPriorityCount[];
    }>(GET_STUDENTS_MODULE_PRIORITY_COUNT, {
        variables: { baseTimeIntervalInstanceId: id },
    });

    useEffect(() => {
        refetch();
    }, [store.studentPriorities]);
    const isModuleSelectionTime = store.baseTimeIntervalSelectionStatus
        === SelectionStatus.moduleSelectionInProcess;
    const [activeTab, setActiveTab] = useState<Tabs>(
        isModuleSelectionTime ? Tabs.ELECTIVE : Tabs.ALL,
    );
    const [isOpenInstruction, setIsOpenInstruction] = useState(false);
    const defaultSubtitleText = `Учебный период: ${formatDateBTIToString}`;
    const [distancesFork, setDistancesFork] = useState([{ text: defaultSubtitleText, top: 130 }]);

    const getSubtitle = () => {
        const subtitle = distancesFork.reduce((result, item) => {
            if (item.top <= 130) {
                return item.text;
            }
            return result;
        }, defaultSubtitleText);
        return subtitle;
    };

    const { user } = UserView;

    const allOrObligatory = activeTab === Tabs.ALL || activeTab === Tabs.OBLIGATORY;

    const allModules = getNoDistributionModules(Tabs.ALL);

    const electiveForks = getDistributionForks(DistributionType.STUDENTCHOICE);

    const distributionForks = [
        ...getDistributionForks(DistributionType.MANUALLY),
        ...getDistributionForks(DistributionType.AUTOMATICALLY),
    ];

    const groupElectiveForksBySubspace = groupBy(electiveForks, 'subspace.name');
    const spaceElectiveForkNames = Object.keys(groupElectiveForksBySubspace);

    const groupDistributionForksBySubspace = groupBy(distributionForks, 'subspace.name');
    const spaceDistributionForkNames = Object.keys(groupDistributionForksBySubspace);

    const obligatoryModules = getNoDistributionModules(Tabs.OBLIGATORY);

    const defaultView = (
        <div className={classes.subspace__subtitle}>В данном разделе нет модулей</div>
    );

    const showModules = {
        [Tabs.ALL]: allModules,
        [Tabs.OBLIGATORY]: obligatoryModules,
        [Tabs.ELECTIVE]: allModules,
        [Tabs.DISTRIBUTION]: allModules,
    };

    function getNoDistributionModules(filterType: Tabs) {
        return futureBtiData?.subspaceTypeLayouts
            ?.flatMap(item => item.subspaceType.subspaces
                .map(subspace => {
                    const isObligatoryType = filterType === Tabs.OBLIGATORY;
                    const slots = subspace.slotDiagram.slots
                        ?.filter(slot => slot.spaceBaseTimeIntervalId === futureBtiData.id);
                    const filtredSlots = isObligatoryType ? slots
                        .filter(slot => isNoDistributionSlot(slot.id)) : slots;
                    return ({
                        subspace,
                        studentModules: filtredSlots
                            .map(slot => ({ module: slot.module, subspace }))
                            .filter(studModule => studModule.module !== null),
                    });
                    // eslint-disable-next-line max-len
                })).filter(item => !!studentSubspacesIds?.find(subspaceId => subspaceId === item.subspace.id)) ?? [];
    }

    function getDistributionForks(distributionType: DistributionType) {
        return futureBtiData?.subspaceTypeLayouts
            ?.flatMap(item => item.subspaceType.subspaces
                .flatMap((subspace) => {
                    subspace.slotDiagram.forks
                        .forEach(fork => fork.nextSlots
                            .forEach(slot => slotsAfterForkIds.push(slot.id)));
                    return subspace.slotDiagram.forks
                        .filter(fork => (fork.setting?.distributionType === distributionType)
                            && fork.spaceBaseTimeIntervalId === futureBtiData.id)
                        .map(fork => ({ fork, subspace }));
                }))
            .filter(item => !!studentSubspacesIds
                ?.find(subspaceId => subspaceId === item.subspace.id))
            ?? [];
    }
    const formatDateSelectionPointToString = ` с ${format(new Date(futureBtiData?.selectionPoint?.moduleSelectionStartDate ?? 0), 'dd.MM.yy')} по ${format(new Date(futureBtiData?.selectionPoint?.moduleSelectionEndDate ?? 0), 'dd.MM.yy ')}`;

    const normalizedTabs = Object.values(Tabs).map((tab) => ({
        id: tab,
        name: tab,
        hasNotification: tab === Tabs.ELECTIVE
            && (user?.student?.notifications?.length ?? 0) > 0,
    }));
    return (
        <>
            <TabsWithoutRouteRedesign
                tabs={normalizedTabs}
                setActiveTabId={(tabId) => setActiveTab(tabId as Tabs)}
                activeTabId={activeTab}
            />
            <div className={classes.header__subtitle}>
                {getSubtitle()}
            </div>
            <div className={classes.subspace__container}>
                {isModuleSelectionTime && (activeTab === Tabs.ELECTIVE) && (
                    <InfoCard
                        title="Дорогие студенты!"
                        classNames={classes.subspace__infoCard}
                    >
                        {instruction !== '' ? (
                            <div
                                onClick={() => setIsOpenInstruction(!isOpenInstruction)}
                                className={cn(
                                    classes.instruction,
                                    {
                                        [classes.instruction_close]: !isOpenInstruction,
                                    },
                                )}
                                dangerouslySetInnerHTML={{ __html: instruction }}
                            />
                        ) : (
                            <>
                                <p>
                                    В период
                                    {formatDateSelectionPointToString}
                                    каждому студенту
                                    необходимо осуществить выбор модулей.
                                </p>
                                <p>
                                    Итоги распределения будут доступны
                                    каждому студенту в личном профиле, в разделе “Мои модули”.
                                </p>
                            </>
                        )}
                    </InfoCard>
                )}
                <div>
                    {
                        allOrObligatory
                        && showModules[activeTab].length > 0
                        && showModules[activeTab]?.map(module => (
                            <Fragment key={module.subspace.id}>
                                {
                                    module.studentModules?.length > 0 && (
                                        <FutureSubspace
                                            studentSubspace={module}
                                        />
                                    )
                                }
                            </Fragment>
                        ))
                    }

                    {
                        activeTab === Tabs.DISTRIBUTION && spaceDistributionForkNames
                            .map(spaceName => (
                                <Fragment key={spaceName}>
                                    <div className={classes.subspace__subtitle}>
                                        {spaceName}
                                    </div>

                                    {
                                        groupDistributionForksBySubspace[spaceName]
                                            .map((item, index) => (
                                                <DistributionPoint
                                                    key={item.fork.id}
                                                    fork={item.fork}
                                                    order={index + 1}
                                                    subspace={item.subspace}
                                                    isModuleSelectionTime={isModuleSelectionTime}
                                                />
                                            ))
                                    }
                                </Fragment>
                            ))
                    }
                    {
                        activeTab === Tabs.ELECTIVE && (
                            <div className={classes.subspace__electivePoint}>
                                {
                                    spaceElectiveForkNames.map(spaceName => (
                                        <Fragment key={spaceName}>
                                            <div className={classes.subspace__subtitle}>
                                                {spaceName}
                                            </div>
                                            {
                                                groupElectiveForksBySubspace[spaceName]
                                                    .map((item, index) => (
                                                        <ElectivePoint
                                                            key={item.fork.id}
                                                            setDistancesFork={setDistancesFork}
                                                            // eslint-disable-next-line max-len
                                                            studentsModulePriorityCount={data?.studentsModulePriorityCount}
                                                            // eslint-disable-next-line max-len
                                                            isModuleSelectionTime={isModuleSelectionTime}
                                                            fork={item.fork}
                                                            order={index + 1}
                                                            subspace={item.subspace}
                                                            // eslint-disable-next-line max-len
                                                            setStudentPriorities={setStudentPriorities}
                                                        />
                                                    ))
                                            }
                                        </Fragment>
                                    ))
                                }
                            </div>
                        )
                    }
                </div>
            </div>
            {
                activeTab === Tabs.DISTRIBUTION
                && spaceDistributionForkNames.length === 0
                && defaultView
            }

            {
                activeTab === Tabs.ELECTIVE
                && spaceElectiveForkNames.length === 0
                && defaultView
            }

            {
                showModules[activeTab as keyof typeof showModules].length === 0 && defaultView
            }
        </>
    );
});

function isNoDistributionSlot(id: string) {
    return !slotsAfterForkIds.includes(id);
}
