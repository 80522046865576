import React, { Fragment } from 'react';
import { format } from 'date-fns';
import { groupBy, map } from 'lodash';
import { Loader } from '@common/Loader';
import { StudentAssignmentInstance } from './interfaces';
import {
    Tasks,
    StudentAssignmentInstance as AssignmentComponent,
    Search,
    Tabs,
    Controls,
    Sort,
    Filter,
} from './components';
import classes from './Assignments.module.scss';
import { assignmentsStore } from './store/AssignmentsStore';

interface Props {
    assignmentsInstances?: StudentAssignmentInstance[];
    isLoading: boolean;
}

export function AssignmentsPage({
    assignmentsInstances,
    isLoading,
}: Props): JSX.Element {
    const formatDeadlines = assignmentsInstances?.map(studentAssignmentInstance => ({
        ...studentAssignmentInstance,
        deadline: studentAssignmentInstance.assignmentInstance.deadline
            ? format(new Date(studentAssignmentInstance.assignmentInstance.deadline), 'dd.MM')
            : 'Не определен',
    }));

    const deadlineDict = groupBy(formatDeadlines, 'deadline');

    return (
        <div className={classes.assignments}>
            <h1 className={classes.title}>
                Самостоятельные работы
            </h1>

            <Search />
            <Tabs />

            <Controls>
                <Sort />
                {assignmentsStore.getAssignmentStatus() === 'actual' && <Filter />}
            </Controls>

            <Tasks>
                {isLoading
                    ? <Loader />
                    : (
                        <>
                            {map(deadlineDict, (items, deadline) => (
                                <Fragment key={deadline}>
                                    <div className={classes.tasks__date}>
                                        {deadline}
                                    </div>

                                    {items.map(studentAssignmentInstance => (
                                        <AssignmentComponent
                                            key={studentAssignmentInstance.assignmentInstance.id}
                                            studentAssignmentInstance={studentAssignmentInstance}
                                        />
                                    ))}
                                </Fragment>
                            ))}
                        </>
                    )
                }
            </Tasks>
        </div>
    );
}
