import React from 'react';
import { observer } from 'mobx-react';
import cn from 'classnames';

import { IconDeprecated } from '@common';
import { EvaluatedLocker } from '../EvaluatedLocker';
// eslint-disable-next-line import/no-cycle
import { eventsStore } from '../../../../../../../../Store';

import classes from './GradeCheckbox.module.scss';

interface Props {
    status: boolean;
    isEditing: boolean;
    skillId: string;
    skillName: string;
    isEvaluated: boolean;
    isCredited: boolean;
    changeGrade(skillId: string, level: number | null, isCredit: boolean): void;
    toggleGradeEvaluating(skillId: string): void;
}

export const GradeCheckbox = observer(({
    status,
    isEditing,
    skillId,
    skillName,
    isEvaluated,
    isCredited,
    changeGrade,
    toggleGradeEvaluating,
}: Props): JSX.Element => {
    function handleChangeGrade(): void {
        if (isEvaluated) {
            changeGrade(skillId, null, !isCredited);
        }
    }

    return (
        <div className={classes.gradeCheckbox}>
            <div className={classes.gradeWithLabel}>
                {isEditing && !status
                    ? (
                        <div
                            className={cn(classes.gradeCheckbox__checkbox, {
                                [classes.gradeCheckbox__checkbox_disabled]: !isEvaluated,
                            })}
                            onClick={handleChangeGrade}
                        >
                            {isCredited && (
                                <IconDeprecated
                                    id="checkmark"
                                    className={cn(classes.gradeCheckbox__icon, {
                                        [classes.gradeCheckbox__icon_disabled]: !isEvaluated,
                                    })}
                                />
                            )}
                        </div>
                    )
                    : (
                        <>
                            {isEvaluated && isCredited && (
                                <IconDeprecated
                                    id="checkmark"
                                    className={classes.gradeCheckbox__icon}
                                />
                            )}
                        </>
                    )}

                <div
                    className={cn(classes.gradeCheckbox__textBlock, {
                        [classes.gradeCheckbox__textBlock_isViewMode]: !isEditing && !isEvaluated,
                    })}
                >
                    <div
                        className={cn(classes.gradeCheckbox__label, {
                            [classes.gradeCheckbox__label_disabled]: !isEvaluated,
                        })}
                    >
                        {skillName}
                    </div>
                    {!isEvaluated && (
                        <div className={classes.gradeCheckbox__notEvaluatedText}>
                            (не оценивается)
                        </div>
                    )}
                </div>
            </div>

            {eventsStore.isMultipleTeachersEvaluate() && isEditing && !status && (
                <EvaluatedLocker
                    isEvaluated={isEvaluated}
                    toggleGradeEvaluating={() => toggleGradeEvaluating(skillId)}
                />
            )}
        </div>
    );
});
