import { Skill as ModuleSkillType } from 'src/student/StudentModulesPage/graphql-query-types';

export interface SkillTypeName {
    nominativeSingular: string
    genitiveSingular: string
    nominativePlural: string
    genitivePlural: string
    english: string
}

export interface SkillTypeProperties {
    maxLevel: number
    description: boolean
}

export interface SkillType {
    id: string
    name: SkillTypeName
    properties: SkillTypeProperties
    parentId: string
    possibleDependencies: [string]
}

export interface University {
    id: string
    name: string
    address: string
    students: Student[]
}

export interface Student {
    id: string
    user: User
    university: University
    studentTicketId: string
    status: string
}

export interface Teacher {
    id: string
    hours: number
    status: string
    contractId: string
    user: User
}

export interface Role {
    id: string
    name: string
    permissions: String[]
}

export interface User {
    id: string
    lastName: string
    firstName: string
    patronymic: string
    birthday?: string
    email: string
    roles: Role[]
    isAdmin: boolean
    teacher: Teacher
    student: Student
}

export interface SubspaceTypeLayout {
    id: string
    spaceBaseTimeInterval: SpaceBaseTimeInterval
    minCreditCount: number
    maxCreditCount: number
    subspaceType: SubspaceType
}

export interface SubspaceType {
    id: string
    name: string
    isDescriptionEnabled: boolean
    subspaceTypeLayouts: SubspaceTypeLayout[]
    subspaces: Subspace[]
}

export interface Skill {
    id: string
    name: string
    fullName: string
    typeId: string
    maxLevel?: number
    description?: string
    children?: Skill[]
    dependencies?: Skill[]
}

export interface SubspaceSkill {
    skill: Skill
    level: number
}

export interface TeacherRole {
    id: string
    name: string
}

export interface ModuleTeacher {
    teacher: {
        id: string;
        user: {
            id: string;
            lastName: string;
            firstName: string;
            patronymic: string
        };
    };
    teacherRoles: TeacherRole[]
}

export interface ModuleSkill extends ModuleSkillType { }

export interface MeetingFormat {
    id: string
    name: string
}

export interface MeetingDuration {
    id: string
    name: string
    academicHours: number
}

export interface Equipment {
    id: string
    name: string
    count: number
    remainder: number
}

export interface EquipmentPerMeeting {
    equipment: Equipment
    count: number
}

export interface EquipmentPerStudent {
    equipment: Equipment
    count: number
}

export interface MeetingTeacherRole {
    teacherRole: TeacherRole
    count: number
}

export interface Meeting {
    id: string
    topic: string
    format: MeetingFormat
    duration: MeetingDuration
    isManyWaves: boolean
    minStudentCount: number
    maxStudentCount: number
    equipmentPerMeeting: EquipmentPerMeeting[]
    equipmentPerStudent: EquipmentPerStudent[]
    prerequisiteSkills: ModuleSkill[]
    outputSkills: ModuleSkill[]
    meetingTeacherRoles: MeetingTeacherRole[]
    meetingIdForTeacherStaff: string
    isPreviousEventInRow: boolean,
    isNextEventInRow: boolean,
    minDaysToPreviousEvent: number
    maxDaysToPreviousEvent: number
    minDaysToNextEvent: number
    maxDaysToNextEvent: number
    order: number
    creationTime?: string
    updateTime?: string
}

export interface Assignment {
    id: string
    topic: string
    duration: MeetingDuration
    prerequisiteSkills: ModuleSkill[]
    outputSkills: ModuleSkill[]
    minDaysToNextMeeting: number
    maxDaysToNextMeeting: number
    order: number
    creationTime?: string
    updateTime?: string
}

export interface EvaluationPointTeacherRole {
    teacherRole: TeacherRole
    count: number
}

export interface EvaluationPoint {
    id: string
    topic: string
    description?: string
    evaluationPointTeacherRoles: EvaluationPointTeacherRole[]
    previousEventId: string
    evaluationPointSkills: ModuleSkill[]
    duration: MeetingDuration
    order: number
    creationTime?: string
    updateTime?: string
}

export interface Module {
    id: string
    name: string
    description?: string
    minStudentCount: number
    maxStudentCount: number
    maxWaveCount: number
    teachersRoles: TeacherRole[]
    moduleTeachers: ModuleTeacher[]
    prerequisiteSkills: ModuleSkill[]
    outputSkills: ModuleSkill[]
    meetings: Meeting[]
    assignments: Assignment[]
    evaluationPoints: EvaluationPoint[]
    creditCount: number
    imageUrl: string
}

export interface SkillDifference {
    skill: Skill
    level: number
    difference: string
}

export interface DifferingModule {
    module: Module
    differenceCount: number
    prerequisiteSkillsDifference: SkillDifference[]
    outputSkillsDifference: SkillDifference[]
}

export interface UpdateModuleInput {
    slotId: string
    moduleId: string
}

export interface ForkSetting {
    distributionType: DistributionType
    moduleSelectionCount: number
    overbooking?: boolean
    studentPickingType?: string
    transitionalEmptyModule?: boolean
    transitionalUnderfilledModule?: boolean
    distributionModule?: Module
    studentPriorityCount?: number
}

export interface Fork {
    id: string
    spaceBaseTimeIntervalId: string
    setting: ForkSetting | null
    row: number
    column: number
    nextSlots: ModuleSlot[]
    nextForks: Fork[]
    nextGroupSlots: GroupSlot[]
}

export interface GroupSlot {
    id: string
    spaceBaseTimeIntervalId: string
    row: number
    column: number
    nextSlots: ModuleSlot[]
    nextForks: Fork[]
    nextGroupSlots: GroupSlot[]
}

export interface ModuleSlot {
    id: string
    spaceBaseTimeIntervalId: string
    row: number
    column: number
    nextSlots: ModuleSlot[]
    nextForks: Fork[]
    nextGroupSlots: GroupSlot[]
    module?: Module
    prerequisiteSkills: SubspaceSkill[]
    outputSkills: SubspaceSkill[]
}

export interface SlotDiagram {
    slots: ModuleSlot[]
    forks: Fork[]
    groupSlots: GroupSlot[]
}

export interface Subspace {
    id: string
    name: string
    description?: string
    owner: User
    type: SubspaceType
    maxStudentCount: number
    minStudentCount: number
    prerequisiteSpecializedSkills: SubspaceSkill[]
    outputSpecializedSkills: SubspaceSkill[]
    slotDiagram: SlotDiagram
}

export interface UpdateNextSlotInput {
    id: string
    spaceBaseTimeIntervalId: string
    row: number
    column: number
}

export interface UpdateNextForkInput {
    id: string
    spaceBaseTimeIntervalId: string
    row: number
    column: number
}

export interface UpdateNextGroupSlotInput {
    id: string
    spaceBaseTimeIntervalId: string
    row: number
    column: number
}

export interface UpdateSlotInput {
    id: string
    spaceBaseTimeIntervalId: string
    row: number
    column: number
    nextSlots: UpdateNextSlotInput[]
    nextForks: UpdateNextForkInput[]
    nextGroupSlots: UpdateNextGroupSlotInput[]
}

export interface UpdateForkInput {
    id: string
    spaceBaseTimeIntervalId: string
    setting?: ForkSettingInput | null
    row: number
    column: number
    nextSlots: UpdateNextSlotInput[]
    nextForks: UpdateNextForkInput[]
    nextGroupSlots: UpdateNextGroupSlotInput[]
}

export interface ForkSettingInput {
    distributionType: string;
    moduleSelectionCount: number;
    studentPriorityCount?: number;
    overbooking?: boolean;
    studentPickingType?: string;
    transitionalEmptyModule?: boolean;
    transitionalUnderfilledModule?: boolean;
    distributionModuleId?: string;
}

export interface UpdateForkSettingInput {
    forkId: string;
    setting: ForkSettingInput;
}

export interface UpdateGroupSlotInput {
    id: string
    spaceBaseTimeIntervalId: string
    row: number
    column: number
    nextSlots: UpdateNextSlotInput[]
    nextForks: UpdateNextForkInput[]
    nextGroupSlots: UpdateNextGroupSlotInput[]
}

export interface UpdateSlotDiagramInput {
    subspaceId: string
    slots: UpdateSlotInput[]
    forks: UpdateForkInput[]
    groupSlots: UpdateGroupSlotInput[]
}

export interface UpdateSubspaceSkillInput {
    skill: SubspaceSkillInput
    level: number
}

export interface SubspaceSkillInput {
    id: string
    name: string
    typeId: string
}

export interface UpdateModuleSlotSkillInput {
    slotId: string
    prerequisiteSkills: UpdateSubspaceSkillInput[]
    outputSkills: UpdateSubspaceSkillInput[]
}

export interface StudentSelectionType {
    id: string
    name: string
}

export interface StudentPeekingType {
    id: string
    name: string
}

export interface Space {
    id: string
    name: string
    minCreditCount: number
    maxCreditCount: number
    isDifferentSpaceBaseTimeIntervals: boolean
    spaceBaseTimeIntervals: SpaceBaseTimeInterval[]
    baseTimeIntervalType: BaseTimeIntervalType
}

export interface SpaceBaseTimeInterval {
    id: string
    order: number
    minCreditCount: number
    maxCreditCount: number
    subspaceTypeLayouts: SubspaceTypeLayout[]
    space: Space
}

export interface BaseTimeIntervalType {
    id: string
    name: string
    minBaseTimeInterval: number
    maxBaseTimeInterval: number
}

// mock interfaces
export interface DiagramSettings {
    editMode: boolean
    createSlotMode: boolean
    createGroupMode: boolean
    createForkMode: boolean
    relationDiagramEntityMode: boolean
    saveRelationDiagramEntityMode: boolean
    removeDiagramEntityMode: boolean
    showSlotParametersMode: boolean
    showSuitableModules: boolean
    showForkDialog: boolean
    editSlotParametersMode: boolean
    rowsRange: number[]
    columnsRange: number[]
}

export interface SlotSettings {
    id?: string
    spaceBaseTimeIntervalId?: string
    moveSlotTo?: [number, number]
    isSlotCanMove?: boolean
    hasModule?: boolean
    hasPrerequisiteSkills?: boolean
    hasOutputSkills?: boolean
}

export interface GroupSlotSettings {
    id?: string
    spaceBaseTimeIntervalId?: string
    isGroupSlotCanMove?: boolean,
    moveGroupSlotTo?: [number, number],
}

export interface ForkSettings {
    id?: string
    spaceBaseTimeIntervalId?: string
    isForkCanMove?: boolean,
    moveForkTo?: [number, number],
    setting?: ForkSetting
}

export interface BTISettings {
    id: string
    subspaceId: string
    order: number
    name: string
    maxCreditCount: string
    rowsRange: number[]
    columnsRange: number[]
    moduleSlots: ModuleSlot[]
    forks: Fork[]
    groupSlots: GroupSlot[]
}

export interface BTICheck {
    diagramEntities: DiagramEntity[]
    rowsRange: number[]
    columnsRange: number[]
}

export interface DiagramEntityRelationSettings {
    previousEntityId?: string
    nextEntityId?: string
    spaceBaseTimeIntervalId?: string
    entityType?: EntityType
}

export type DiagramEntityCoordinate = [number, number];

export interface ArrowEntityCoordinates {
    startPoint: DiagramEntityCoordinate
    endPoint: DiagramEntityCoordinate
}

export interface SpecializedSkillsInput {
    [name: string]: string[]
}

export interface SpecializedSkillInputValue {
    [name: string]: string
}

export interface DiagramCoordinates {
    x: number
    y: number
}

export interface DiagramEntities {
    moduleSlots: ModuleSlot[]
    forks: Fork[]
    groupSlots: GroupSlot[]
}

export interface StudentSelectionTypes {
    id: string
    name: string
}

export interface DiagramEntity {
    id: string
    spaceBaseTimeIntervalId: string
    row: number
    column: number
    nextSlots: ModuleSlot[]
    nextForks: Fork[]
    nextGroupSlots: GroupSlot[]
}

export enum DistributionModeRussian {
    AUTOMATICALLY = 'Автоматически',
    MANUALLY = 'Вручную',
    STUDENTCHOICE = 'Выбор для студента',
}

export enum DistributionType {
    AUTOMATICALLY = 'automatically',
    MANUALLY = 'manually',
    STUDENTCHOICE = 'studentChoice',
}

export type StudentPickingType = 'randomly' | 'bySkill' | 'byRating';

export interface ForkSettingFormInput {
    distributionType: DistributionType;
    moduleSelectionCount: number;
    studentPriorityCount: number;
    overbooking: boolean;
    studentPickingType: string;
    distributionModuleId?: string;
    transitionalEmptyModule: boolean;
    transitionalUnderfilledModule: boolean;
    automaticallyDistributeRemainingStudents: boolean;

}

export type EntityType = 'ModuleSlot' | 'GroupSlot' | 'Fork';
