import React from 'react';
import { NonWorkingHours } from '@admin/Users/UserCard/TeacherInfo/NonWorkingHours/NonWorkingHours';
import { PreviewWorkingHours } from '@admin/Users/UserCard/TeacherInfo/WorkingHours';
import { userCardStore } from '@admin/Users/store/userCardStore';
import { normalizWorkingInterval } from '@admin/Users/UserCard/TeacherInfo/TeacherInfo';
import { WeekType } from '@admin/Users/UserCard/TimePicker/modelTimePicker';
import { Teacher } from '../profileModels';
import classes from './TeacherProfile.module.scss';

interface Props {
    teacherData: Teacher;
}

export function TeacherProfile({ teacherData }: Props) {
    const nonWorkingHours = teacherData?.nonWorkingHours;
    userCardStore.resetWorkingIntervals();

    teacherData.workingHours?.forEach((item) => {
        if (item.weekType !== WeekType.every) {
            userCardStore.updateIsEveryWeek(false);
        } else {
            userCardStore.updateIsEveryWeek(true);
        }

        userCardStore.createDefaultWorkingInterval(normalizWorkingInterval(item));
    });

    return (
        <div className={classes.teacher}>
            <PreviewWorkingHours
                isEveryWeek={userCardStore.isEveryWeek}
                workingIntervals={userCardStore.workingIntervals}
            />

            <NonWorkingHours
                nonWorkingHours={nonWorkingHours ?? []}
            />
        </div>
    );
}
