import React, { useEffect, useState } from 'react';
import { Redirect, Switch, useRouteMatch } from 'react-router-dom';
import { observer } from 'mobx-react';
import { nanoid } from 'nanoid';
import { AuthRoute } from '@common/AuthRoute';
import { Alert } from '@common/Alert';
import { Tabs } from '@common/Tabs';
import { ViewType } from '../../../deprecated-graphql-query-types';
import { Space } from '../types';
import classes from './AdminTrajectories.module.scss';
import { TrajectoriesList } from './TrajectoriesList';
import { TrajectoryForm } from './TrajectoryForm';
import { trajectoryStore } from './TrajectoryForm/Store/TrajectoryStore';
import { useSpacesQuery } from '../hooks/useSpacesQuery';
import { useUpdateTrajectoryDraftMutation } from '../hooks/useUpdateTrajectoryDraftMutation';
import { useUpdateTrajectoryMutation } from '../hooks/useUpdateTrajectoryMutation';
import { useTrajectoriesQuery } from '../hooks/useTrajectoriesQuery';
import MarksStore from './TrajectoriesList/Store/MarksStore';

export const AdminTrajectories = observer((): JSX.Element => {
    const { path } = useRouteMatch();
    const [isFormOpen, setIsFormOpen] = useState(false);
    const { selectedSubspaces, name, description, clearInputs } = trajectoryStore;
    const trajectories = useTrajectoriesQuery(trajectoryStore.spaceId);
    trajectoryStore.updateTrajectories(trajectories);
    const { spaces, loading } = useSpacesQuery();
    const tabsOptions = spaces?.map(space => [space.name, space.id]);

    const {
        updateTrajectoryDraft, updateTrajectoryDraftError,
    } = useUpdateTrajectoryDraftMutation(trajectoryStore.spaceId);
    const {
        updateTrajectory, updateTrajectoryError,
    } = useUpdateTrajectoryMutation(trajectoryStore.spaceId);

    const handleFormOpen = () => {
        setIsFormOpen(true);
        clearInputs();
    };
    const handleCloseForm = () => {
        trajectoryStore.selectedSubspaces = [];
        setIsFormOpen(false);
    };
    const handleSave = (spaceId: string, id: string): void => {
        updateTrajectoryDraft({
            id,
            spaceId,
            name,
            description,
            subspaceIds: selectedSubspaces.map(item => item.id),
        }).catch(() => {});
    };

    const handlePublish = (spaceId: string, id: string): void => {
        updateTrajectory({
            id,
            spaceId,
            name,
            description,
            subspaceIds: selectedSubspaces.map(item => item.id),
        }).catch(() => {});
    };

    const { markFilters } = MarksStore;

    useEffect(() => {
        const publishedTrajectories = trajectoryStore.trajectories?.filter(item => item.status === 'active');
        const savedTrajectories = trajectoryStore.trajectories.filter(item => item.status === 'inactive');
        if (markFilters.publicTrajectories && !markFilters.privateTrajectories) {
            trajectoryStore.updateTrajectories(publishedTrajectories);
        }
        if (!markFilters.publicTrajectories && markFilters.privateTrajectories) {
            trajectoryStore.updateTrajectories(savedTrajectories);
        }
    }, [markFilters]);
    return (
        <>
            <div className={classes.trajectories}>
                <div className={classes.trajectories__tabs}>
                    {spaces?.length > 1 && <Tabs tabsOptions={tabsOptions} />}
                </div>
                {!spaces?.length && !loading && (
                    <h2 className={classes.trajectories__disabled}>
                        Для создания траекторий необходимо создать образовательное пространство
                    </h2>
                )}
                {spaces?.length !== 0 && (
                    <Switch>
                        {spaces?.map((space: Space) => (
                            <AuthRoute
                                exact
                                key={space.id}
                                path={`${path}/${space.id}`}
                                requiredUserTypes={[ViewType.Admin]}
                            >
                                {!isFormOpen
                                    ? (
                                        <TrajectoriesList
                                            handleFormOpen={handleFormOpen}
                                            spaceId={space.id}
                                            handleUpdateSave={
                                                (id: string) => handleSave(space.id, id)
                                            }
                                            handleUpdatePublish={
                                                (id: string) => handlePublish(space.id, id)
                                            }
                                        />
                                    )
                                    : (
                                        <TrajectoryForm
                                            handleDelete={handleCloseForm}
                                            handleSave={() => {
                                                handleSave(space.id, nanoid());
                                                setIsFormOpen(false);
                                                clearInputs();
                                            }}
                                            handlePublish={() => {
                                                handlePublish(space.id, nanoid());
                                                setIsFormOpen(false);
                                                clearInputs();
                                            }}
                                            text="К траекториям"
                                            actionText="Сохранить и опубликовать"
                                            saveText="Сохранить"
                                            spaceId={space.id}
                                            isFormOpen={isFormOpen}
                                        />
                                    )
                                }
                            </AuthRoute>
                        ))}
                        <AuthRoute
                            exact
                            path={`${path}`}
                            requiredUserTypes={[ViewType.Admin]}
                        >
                            <Redirect to={`${path}/${tabsOptions ? tabsOptions[0][1] : ''}`} />
                        </AuthRoute>
                    </Switch>
                )}
            </div>
            {updateTrajectoryError && <Alert message={updateTrajectoryError.message} time={5000} />}
            {updateTrajectoryDraftError
                && <Alert message={updateTrajectoryDraftError.message} time={5000} />
            }
        </>
    );
});
