import {
    ListUser_users_list_student_activeBaseTimeIntervalInstance_baseTimeIntervalInstance,
    Type,
    UserStatus,
} from './apollo-types';

type BTI = ListUser_users_list_student_activeBaseTimeIntervalInstance_baseTimeIntervalInstance;

export function formatBTIName(bti?: BTI) {
    if (!bti) {
        return '';
    }
    const { spaceBaseTimeInterval: { order, space: { name, baseTimeIntervalType } } } = bti;
    return `${order} ${baseTimeIntervalType.name} ${name}`;
}

export type SearchParams = {
    userType: Type;
    skillName?: string;
    baseTimeIntervalInstanceId?: string;
    fullName?: string;
    status?: UserStatus;
    spaceId?: string;
};
