import React, { useEffect, useRef, useState } from 'react';
import cn from 'classnames';
import { format } from 'date-fns';
import { useUrlQuery } from '@common/hooks';
import { filterWavesByBaseTimeInterval, IconDeprecated } from '@common';
import { Link } from 'react-router-dom';
import { MeetingEventApollo } from '@admin/ScheduleGenerationPage/MeetingDetails/MeetingEvent';
import { ActionButton, ActionTypeOfButton } from '@common/ActionButton';
import { DraftIcon, PinIcon } from '@common/svg';

import {
    RoomType,
    ScheduleEvent,
    UpdateEventDateFunc,
    UpdateEventDurationFunc,
    UpdateEventStatusFunc,
    UpdateEventTeacherFunc,
} from '../types';

import { ChangeStatusButtonsContainer } from './ChangeStatusButtonsContainer';
import { Duration } from './Duration';
import { Time } from './Time';
import { Waves } from './Waves';
import { Room } from './Rooms/Room';
import { Teacher } from './Teacher/Teacher';

import { EventStatus, EventType } from '../utils';

import classes from './MeetingDetails.module.scss';

const ROOM_TITLE = 'Аудитория';
const ONLINE_ROOM_TITLE = 'Онлайн-аудитория';

interface Props {
    event: ScheduleEvent;
    baseTimeIntervalInstanceId: string;
    setDialog(): void;
    getUpdateStartDateFunction(type: string): UpdateEventDateFunc,
    getUpdateTeachersFunction(type: string): UpdateEventTeacherFunc,
    getUpdateStatusFunction(type: string): UpdateEventStatusFunc;
    getUpdateDurationFunction(type: string): UpdateEventDurationFunc;
    pinEventInstance(pin: boolean): void;
}

export const MeetingDetails = React.memo(({
    event,
    baseTimeIntervalInstanceId,
    setDialog,
    getUpdateStartDateFunction,
    getUpdateTeachersFunction,
    getUpdateStatusFunction,
    getUpdateDurationFunction,
    pinEventInstance,
}: Props): JSX.Element | null => {
    if (!event) return null;
    const [edibleTeacher, setEdibleTeacher] = useState('-1');
    const [edibleRoom, setEdibleRoom] = useState(false);
    const [edibleTime, setEdibleTime] = useState({
        startTime: event?.startTime ?? '',
        endTime: event?.endTime ?? '',
    });
    const [isOnline, setOnline] = useState(event.roomType === RoomType.Online);
    useEffect(() => setOnline(event.roomType === RoomType.Online), [event]);

    const meetingDetailsRef = useRef<HTMLDivElement>(null);

    const { setUrlQuery } = useUrlQuery();

    useEffect(() => {
        setEdibleTime({
            startTime: event?.startTime ?? '',
            endTime: event?.endTime ?? '',
        });
    }, [event?.startTime, event?.endTime]);

    const eventId: string | undefined = event?.meeting?.id
        ?? event?.assignment?.id
        ?? event?.evaluationPoint?.id;
    const moduleLink = `/module/public/${event?.module.id}`;
    const eventLink = `${moduleLink}/${eventId}`;

    const getStatusIcon = () => {
        if (event.status === EventStatus.DELETED) {
            return (
                <div
                    className={cn(
                        classes.meetingDetails__colorSign,
                        classes.meetingDetails__colorSign_deleted,
                    )}
                />
            );
        }
        if (event.status === EventStatus.GENERATION) {
            return <DraftIcon />;
        }
        return null;
    };

    return (
        <div
            className={classes.meetingDetails}
            ref={meetingDetailsRef}
        >
            <IconDeprecated
                id="Cross"
                className={classes.meetingDetails__icon}
                click={() => {
                    setUrlQuery({ detailedEventId: null, detailedEventType: null });
                    setDialog();
                }}
            />
            <div className={classes.meetingDetails__status}>
                {getStatusIcon()}
                <span className={classes.meetingDetails__infoItem}>
                    {event?.status === EventStatus.ACTIVE && 'Опубликованная'}
                    {event?.status === EventStatus.GENERATION && 'Неопубликованная'}
                    {event?.status === EventStatus.DELETED && 'Удалённая'}
                </span>
                {event.pinned && (
                    <div className={classes.meetingDetails__pinned}>
                        <PinIcon />
                        <span className={classes.meetingDetails__infoItem}>Закреплена</span>
                    </div>
                )}
            </div>
            <div className={classes.meetingDetails__paragraph}>
                <div className={classes.meetingDetails__title}>
                    {event?.type === EventType.Meeting && 'Встреча'}
                    {event?.type === EventType.Assignment && 'Самостоятельная работа'}
                    {event?.type === EventType.EvaluationPoint && 'Точка оценки'}
                </div>
                {event?.type === EventType.Meeting && (
                    <MeetingEventApollo
                        status={event?.status!}
                        waveIds={filterWavesByBaseTimeInterval(
                            event?.waves,
                            baseTimeIntervalInstanceId,
                        ).map(wave => wave.id)}
                        moduleId={event?.module.id}
                        meeting={event?.meeting}
                        meetingInstanceId={event?.id}
                        date={`${format(new Date(event?.startTime), 'dd.MM.yyyy HH-mm')}
                        - ${format(new Date(event?.endTime), 'dd.MM.yyyy HH-mm')}`}
                    />
                )}
                {event?.type === EventType.Assignment && (
                    <Link to={eventLink} className={classes.link}>
                        {event?.assignment?.topic}
                    </Link>
                )}

                {event?.type === EventType.EvaluationPoint && (
                    <Link to={eventLink} className={classes.link}>
                        {event?.evaluationPoint?.topic}
                    </Link>
                )}
            </div>
            <div className={classes.meetingDetails__paragraph}>
                <div className={classes.meetingDetails__title}>Модуль</div>
                <div>
                    <Link to={moduleLink} className={classes.link}>
                        {event?.module.name}
                    </Link>
                </div>
            </div>
            <div className={classes.meetingDetails__paragraph}>
                <Waves
                    status={event?.status!}
                    waves={filterWavesByBaseTimeInterval(event?.waves, baseTimeIntervalInstanceId)}
                    moduleWaves={event?.meeting?.module?.waves}
                    meetingInstanceId={event?.id}
                    eventType={event?.type}
                />
            </div>
            <div className={classes.meetingDetails__paragraph}>
                <div className={classes.meetingDetails__title}>Длительность</div>
                <Duration
                    status={event?.status!}
                    minutesDuration={
                        (new Date(event?.endTime).getTime()
                            - new Date(event?.startTime).getTime())
                        / 60000
                    }
                    getUpdateDurationFunction={getUpdateDurationFunction}
                />
            </div>

            <div className={classes.meetingDetails__paragraph}>
                <div className={classes.meetingDetails__title}>Время</div>

                {event?.type !== EventType.EvaluationPoint
                    && edibleTime.startTime && edibleTime.endTime
                    ? (
                        <Time
                            status={event?.status!}
                            startDate={edibleTime.startTime}
                            endDate={edibleTime.endTime}
                            setNewTime={setEdibleTime}
                            getUpdateStartDateFunction={getUpdateStartDateFunction}
                        />
                    )
                    : (
                        <>
                            {event?.startTime && (format(new Date(event?.startTime), 'dd.MM.yyyy HH:mm'))} — {event?.endTime && (format(new Date(event?.endTime), 'HH:mm'))}
                        </>
                    )
                }
            </div>

            {event.type === 'meeting' && (
                <div className={classes.meetingDetails__paragraph}>
                    <div className={classes.meetingDetails__title}>
                        <RoomTabs
                            tabs={event.roomType === RoomType.Online
                                ? [ONLINE_ROOM_TITLE, ROOM_TITLE]
                                : [ROOM_TITLE, ONLINE_ROOM_TITLE]}
                            activeTab={isOnline ? ONLINE_ROOM_TITLE : ROOM_TITLE}
                            onClick={tab => setOnline(tab === ONLINE_ROOM_TITLE)}
                        />
                    </div>
                    <div>
                        <Room
                            event={event}
                            isOnline={isOnline}
                            edibleRoom={edibleRoom}
                            setEdibleRoom={setEdibleRoom}
                            setEdibleTeacher={setEdibleTeacher}
                        />
                    </div>
                </div>
            )}

            {event?.teachers && (
                <div className={classes.meetingDetails__paragraph}>
                    <div className={classes.meetingDetails__title}>Преподаватели</div>
                    <div>
                        {event?.teachers.map(
                            (user) => (
                                <Teacher
                                    key={user.id}
                                    data={event}
                                    getUpdateTeachersFunction={getUpdateTeachersFunction}
                                    setEdibleTeacher={setEdibleTeacher}
                                    setEdibleRoom={setEdibleRoom}
                                    edibleTeacher={edibleTeacher}
                                    user={user}
                                />
                            ),
                        )}
                    </div>
                </div>
            )}
            <div className={classes.meetingDetails__paragraph}>
                <div className={classes.meetingDetails__title}>Студенты</div>
                <div>
                    {
                        event?.students.map(
                            (student, index) => (
                                <div
                                    className={classes.student__link}
                                    key={student.id}
                                >
                                    <Link to={`/users/user/${student.user.id}`}>
                                        {student.user.lastName} {student.user.firstName}{index === event?.students.length - 1 ? ' ' : ', '}
                                    </Link>
                                    <span className={classes.student__toolTipText}>
                                        {/* eslint-disable-next-line max-len */}
                                        {student.user.lastName} {student.user.firstName} {student.user.patronymic}
                                    </span>
                                </div>
                            ),
                        )
                    }
                </div>
            </div>
            <div className={classes.meetingDetails__buttonContainer}>
                <ChangeStatusButtonsContainer
                    id={event?.id}
                    status={event?.status ?? ''}
                    getUpdateStatusFunction={getUpdateStatusFunction}
                    type={event.type! as EventType}
                />
                {event.type === EventType.Meeting && (
                    <ActionButton
                        actionType={event.pinned
                            ? ActionTypeOfButton.SECONDARY
                            : ActionTypeOfButton.PRIMARY}
                        onClick={() => pinEventInstance(!event.pinned)}
                    >
                        {event.pinned ? 'Открепить' : 'Закрепить'}
                    </ActionButton>
                )}
            </div>
        </div>
    );
});

interface TabsProps {
    tabs: string[];
    activeTab: string;
    onClick(tab: string): void;
}

function RoomTabs({ tabs, activeTab, onClick }: TabsProps) {
    return (
        <div className={classes.roomTitle__tabs}>
            {tabs.map((tab, index) => (
                <div className={cn(classes.roomTitle__tab)} key={tab}>
                    <span
                        className={cn({ [classes.roomTitle__tab_active]: tab === activeTab })}
                        onClick={() => onClick(tab)}
                    >
                        {tab}
                    </span>
                    {index !== tabs.length - 1 ? ' / ' : ''}
                </div>
            ))}
        </div>
    );
}
