import React, { useState } from 'react';
import ReactPaginate from 'react-paginate';
import cn from 'classnames';

import { useUrlQuery } from '@common/hooks';

import classes from './Pagination.module.scss';

import {
    PaginationProps as Props,
} from './PaginationInterfaces';
import { GoButton } from './GoButton';

export function Pagination(
    {
        className,
        pagesCount,
        urlParameterName,
        currentPage: currentPageProp,
        forcePage,
        goPage,
    } : Props,
): JSX.Element | null {
    const { setUrlQuery, getUrlQuery } = useUrlQuery();
    const [currentPage, setCurrenPage] = useState(validateInitialPage(getInitialPage()));

    function validateInitialPage(page: number) {
        if (page < 1) {
            return 1;
        }
        if (page > pagesCount) {
            return pagesCount;
        }
        return page;
    }

    function getInitialPage() {
        if (currentPageProp) {
            return currentPageProp;
        }
        if (urlParameterName) {
            const page = getUrlQuery(urlParameterName);
            if (page) return parseInt(page, 10);
        }
        return 1;
    }

    function onPageChange({ selected }: { selected: number }) {
        setCurrenPage(selected + 1);
        if (urlParameterName) {
            setUrlQuery({ [urlParameterName]: selected + 1 });
        }
        goPage(selected + 1);
    }

    return (
        <div
            className={
                cn(
                    classes.pagination,
                    {
                        [`${className}`]: !!className,
                        [`${classes.pagination_default}`]: !className,
                    },
                )
            }
        >
            <ReactPaginate
                forcePage={forcePage}
                disableInitialCallback
                initialPage={currentPage - 1}
                nextLabel={<GoButton isRotated isDisabled={currentPage === pagesCount} />}
                previousLabel={<GoButton isDisabled={currentPage === 1} />}
                breakLabel="..."
                activeLinkClassName={classes.pagination__currentPage}
                breakClassName={classes.pagination__breaker}
                pageCount={pagesCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={onPageChange}
                pageClassName={classes.pagination__page}
            />
        </div>
    );
}
