/* eslint-disable */
import 'source-map-support/register';

import { readFileSync, writeFileSync } from 'fs';
import {orderBy as lodashOrderBy, uniq as lodashUniq, groupBy as lodasGroupBy, flatten as lodasFlatten} from 'lodash';
import { compareAsc, parseISO, parse } from 'date-fns';

import { Meeting, Module, ScheduleConfig, Timeslot } from './config';
import { ScheduleResult, ScheduleResultMeeting } from './result';


const CAPTION_LEFT_PX = 2;
const CAPTION_TOP_PX = 10;
const CAPTION_FONT_SIZE_PX = 8;
const CAPTION_LINE_MARGIN_PX = 1;
const GUIDE_MARGIN_RIGHT_PX = 2;

const WAVEMEETING_LEFT_PX = 20;
const WAVEMEETING_WIDTH_PX = 3;
const WAVEMEETING_HEIGHT_PX = 3;
const WAVEMEETING_MARGIN_PX = 1;
const WAVEMEETING_MARGIN_BOTTOM_PX = 4;

const MARGIN_BOTTOM_PX = 3;
const MARGIN_RIGHT_PX = 2;

const LEGEND_HEIGHT_PX = 5;
const LEGEND_SPACE_PX = 7;
const LEGEND_CONSTRAINT_MAX_WIDTH_PX = 350;
const LEGEND_CONSTRAINT_TOP_PX = 7;
const LEGEND_COLOR_TOP_PX = 1;

const CAPTION_COLOR = '#777';
const GUIDE_COLOR = '#ccc';

// const HIGHLIGHT_CONSTRAINT_NAME = 'Встречи идут в неправильном порядке';
const HIGHLIGHT_CONSTRAINT_NAME = 'Пересекаются встречи студента';
// const HIGHLIGHT_CONSTRAINT_NAME = 'Только одна такая выездная встреча может проходить в одно время';
// const HIGHLIGHT_CONSTRAINT_NAME = 'Пересекаются встречи преподавателя';

// TODO
// const HIGHLIGHT_CONSTRAINT_NAME = 'Не хватает оборудования для всех встреч в это время';

enum LaunchMode {
    WithGradientMeetings = "gradient", // дефолтная градиентная раскраска встреч, нарушения констрейнтов подсвечиваются серой рамочкой вокруг встречи
    ColoredConstraintsWithEquipment = "colored", // все встречи окрашиваются сереньким, нарушения констрейнтов подсвечиваются рамочками соответствующего цвета вокруг встречи
    ColoredConstraintsWithoutEquipment = "colored_without_equipment", // тот же самый режим, что и предыдущий, просто не учитывает констрейнт по оборудованию
}

const colorConstraintMap = {
    // 'Не хватает оборудования для всех встреч в это время': '#00afff',
    // 'Встречи идут в неправильном порядке': '#7ea5ff',
    // 'Пересекаются встречи студента': '#c596ff',
    // 'Пересекаются встречи преподавателя/-ей': '#fb83e2',
    // 'Встреча запрещена календарём преподавателя': '#ff75b5',
    // 'Пересекаются встречи в помещении': '#ff7781',
    // 'Встречи студента за день проходят в пределах разных территориальных зон': '#ff8b4b',
    // 'Перерыва между встречами студента не хватает для перемещения между помещениями': '#ffa600',
    // 'Помещение забронировано классическим университетом в это время': '#000000',
    // 'Встреча запрещена бронированием времени преподавателя классическим университетом': '#000000',

    'Не хватает оборудования для всех встреч в это время': '#00afff',
    'Встречи идут в неправильном порядке': '#00ff00',
    'Пересекаются встречи студента': '#ff0000',
    'Пересекаются встречи преподавателя': '#0000ff',
    'Пересекаются встречи в помещении': '#ffff00',
    'Помещение забронировано классическим университетом в это время': '#ff00ff',
    'Встречи студента за день проходят в пределах разных территориальных зон': '#00ffff',
    'Перерыва между встречами студента не хватает для перемещения между помещениями': '#000000',
    'Встреча запрещена бронированием времени преподавателя классическим университетом': '#ffff00',    
    'Встреча запрещена календарём преподавателя': '#00ffff',
};

interface ScheduleScore {
    meetingViolations: ScheduleScoreMeetingViolations[];
    equipmentsByConstraint: ScheduleScoreEquipmentsByConstraint;
}

interface ScheduleScoreMeetingViolations {
    meeting: ScheduleScoreMeetingViolations;
    violations: {
        [constraintName: string]: ScheduleScoreMeetingViolations[]
    };
}

interface ScheduleScoreMeetingViolations {
    meetingId: string;
    waveIndexes: number[];
}

interface ScheduleScoreEquipmentsByConstraint {
    [index: string]: EquipmentViolation[][];
}

interface EquipmentViolation {
    equipmentId: string;
    count: number;
    date: string;
    startTime: string;
}

interface EquipmentViolationsDateMap {
    [index: string]: EquipmentViolationDate;
}

interface EquipmentViolationDate {
    [index: string]: Date;
}

interface MeetingsDateMap {
    [index: string]: MeetingDate;
}

interface MeetingDate {
    [index: string]: Date;
}

export class Vizualize {
    private config!: ScheduleConfig;
    private result!: ScheduleResult;
    private score!: ScheduleScore;
    private canvas!: any;
    private ctx!: any;
    private mode!: string;
    private equipmentViolationsDates: EquipmentViolationsDateMap = {};
    private meetingDates: MeetingsDateMap = {}; 

    public vizualize(
        config: any,
        result: any,
        score: any,
        canvas: any,
    ) {
        //this.loadSchedule(configFilePath, resultFilePath, scoreFilePath);
        this.config = config;
        this.result = result;
        this.score = score;
        this.canvas = canvas;
        this.ctx = this.canvas.getContext('2d');
        this.createCanvas();
        this.draw();
        // this.saveToFile(outputImageFilePath);
    }

    private loadSchedule(configFilePath: string, resultFilePath: string, scoreFilePath?: string) {
        this.config = JSON.parse(readFileSync(configFilePath, 'utf-8'));
        this.result = JSON.parse(readFileSync(resultFilePath, 'utf-8'));
        if (scoreFilePath && !scoreFilePath.includes('--mode=') && !scoreFilePath.includes('-m=')) {
            this.score = JSON.parse(readFileSync(scoreFilePath, 'utf-8'));
        }
    }

    private createCanvas() {
        const width = WAVEMEETING_LEFT_PX
            + this.config.timeslots.length
                * (WAVEMEETING_WIDTH_PX + WAVEMEETING_MARGIN_PX)
            + MARGIN_RIGHT_PX;
        const height = CAPTION_FONT_SIZE_PX + CAPTION_LINE_MARGIN_PX
            + this.config.modules.length * (CAPTION_FONT_SIZE_PX + CAPTION_LINE_MARGIN_PX)
            + this.config.modules.reduce(
                (sum, module) => sum + module.wavesCount * (CAPTION_FONT_SIZE_PX + CAPTION_LINE_MARGIN_PX),
                0
            )
            + MARGIN_BOTTOM_PX
            + LEGEND_HEIGHT_PX;
        // this.canvas = createCanvas(width, height);
        this.canvas.setAttribute('width', width.toString());
        this.canvas.setAttribute('height', height.toString());
        this.ctx.fillStyle = 'white';
        this.ctx.fillRect(0, 0, this.canvas.width, this.canvas.height);
        this.ctx.font = `${CAPTION_FONT_SIZE_PX}px Impact`;
    }

    private draw() {
        const modules = lodashOrderBy(this.config.modules, ['wavesCount'], ['desc']);
        // this.mode = process.argv.find(arg => (arg.includes('--mode=') || arg.includes('-m=')))?.split('=')[1] || LaunchMode.WithGradientMeetings;
        this.mode = LaunchMode.ColoredConstraintsWithoutEquipment;
        // if (!this.checkMode(LaunchMode.WithGradientMeetings)) {
        //     this.drawLegend();
        // }
        this.drawLegend();
        this.drawWeeksAndGuides(modules);
        this.drawModulesWavesMeetings(modules);
    }

    private drawLegend() {
        const constraintsAndColors = Object.entries(colorConstraintMap);
        constraintsAndColors.forEach(([constraint, color], index) => this.drawConstraintAndColor(constraint, color, this.getConstraintAndColorLeftPosition(index)));
    }

    private drawConstraintAndColor(constraint: string, color: string, leftPosition: number) {
        const topPosition = 0;
        this.ctx.fillStyle = CAPTION_COLOR;
        this.ctx.fillStyle = color;
        this.ctx.fillRect(leftPosition, topPosition + LEGEND_COLOR_TOP_PX, LEGEND_HEIGHT_PX, LEGEND_HEIGHT_PX);
        this.ctx.fillStyle = CAPTION_COLOR;
        this.ctx.fillText(constraint, leftPosition + LEGEND_SPACE_PX, topPosition + LEGEND_CONSTRAINT_TOP_PX);
    }

    private getConstraintAndColorLeftPosition(index: number): number {
        const constraintsAndColors = Object.entries(colorConstraintMap);
        if (index === 0) {
            return 0;
        }
        return (constraintsAndColors[index - 1][0].length * 6) + this.getConstraintAndColorLeftPosition(index - 1);
    }
    
    private drawWeeksAndGuides(modules: Module[]) {
        // Вынес в createCanvas, т.к. информарция о шрифтах будет нужна и в других методах
        // this.ctx.font = `${CAPTION_FONT_SIZE_PX}px Impact`;

        let top = CAPTION_TOP_PX + LEGEND_HEIGHT_PX;

        const timeslotsByWeek = lodasGroupBy(this.config.timeslots, 'week');
        const weeks = lodashUniq(this.config.timeslots.map(timeslot => timeslot.week));
        let weekLeft = WAVEMEETING_LEFT_PX;
        for (const week of weeks) {
            this.ctx.fillStyle = CAPTION_COLOR;
            this.ctx.fillText(`${week} неделя`, weekLeft, top);

            let weekGuideTop = top + CAPTION_FONT_SIZE_PX + CAPTION_LINE_MARGIN_PX;
            for (let moduleIndex = 0; moduleIndex < modules.length; moduleIndex += 1) {
                const module = modules[moduleIndex];
                const guideHeight = (CAPTION_FONT_SIZE_PX + CAPTION_LINE_MARGIN_PX) * module.wavesCount;
                this.ctx.fillStyle = GUIDE_COLOR;
                this.ctx.fillRect(
                    weekLeft - GUIDE_MARGIN_RIGHT_PX,
                    weekGuideTop + CAPTION_LINE_MARGIN_PX,
                    1,
                    guideHeight
                );

                weekGuideTop += guideHeight + CAPTION_FONT_SIZE_PX + CAPTION_LINE_MARGIN_PX;
            }

            const weekTimeslotsCount = timeslotsByWeek[week].length;
            weekLeft += weekTimeslotsCount * (WAVEMEETING_WIDTH_PX + WAVEMEETING_MARGIN_PX);
        }
    }

    private drawModulesWavesMeetings(modules: Module[]) {
        const modulesWavesMeetings = this.groupWaveMeetingsByModuleAndWave();

        if (!this.checkMode(LaunchMode.WithGradientMeetings)) {
            this.parseDatesForEquipmentViolationsAndMeettings(modules, modulesWavesMeetings);
        }

        const timeslotsWithPosition = this.config.timeslots
            .map((timeslot, index) => ({
                ...timeslot,
                position: index,
            }));
        const dateTimeTimeslotMap = timeslotsWithPosition
            .reduce(
                (map, timeslot) => {
                    map.set(this.timeslotKey(timeslot), timeslot);
                    return map;
                },
                new Map<string, Timeslot & { position: number }>()
            );
        const positionTimeslotMap = timeslotsWithPosition
            .reduce(
                (map, timeslot) => {
                    map.set(timeslot.position, timeslot);
                    return map;
                },
                new Map<number, Timeslot & { position: number }>()
            );
        
        let scoreMeetingWaveWithViolations: string[] | undefined = undefined;
        if (this.score) {
            scoreMeetingWaveWithViolations = lodasFlatten(
                this.score.meetingViolations
                    .map(mv => mv.meeting.waveIndexes.map(waveIndex => this.waveMeetingKey(mv.meeting.meetingId, waveIndex, Object.keys(mv.violations))))
            );
        }

        this.ctx.font = `${CAPTION_FONT_SIZE_PX}px Impact`;

        let top = CAPTION_TOP_PX + CAPTION_FONT_SIZE_PX + CAPTION_LINE_MARGIN_PX + LEGEND_HEIGHT_PX;

        const meetingsDependentChainLength = this.getMeetingsDependentChainLength();
        const meetingsColorMetricMap = meetingsDependentChainLength;

        for (let moduleIndex = 0; moduleIndex < modules.length; moduleIndex += 1) {
            const module = modules[moduleIndex];

            const moduleMaxColorMetric = Math.max.apply(
                null,
                module.meetings.map(meeting => meetingsColorMetricMap.get(meeting.id)!)
            );

            this.ctx.fillStyle = CAPTION_COLOR;
            this.ctx.fillText(module.name.trim(), CAPTION_LEFT_PX, top);
            top += CAPTION_FONT_SIZE_PX + CAPTION_LINE_MARGIN_PX;

            // let moduleMinTimeslotPosition = Number.MAX_VALUE;
            // let moduleMaxTimeslotPosition = 0;
            for (let waveIndex = 0; waveIndex < module.wavesCount; waveIndex += 1) {
                this.ctx.fillStyle = CAPTION_COLOR;
                this.ctx.fillText(`${waveIndex + 1}`, CAPTION_LEFT_PX, top);

                const waveMeetings = modulesWavesMeetings.get(this.moduleWaveKey(module, waveIndex));
                if (!waveMeetings) {
                    console.log(`Нет встреч для модуля "${module.name}" (${module.id}) потока ${waveIndex + 1}`);
                } else {
                    for (const waveMeeting of modulesWavesMeetings.get(this.moduleWaveKey(module, waveIndex))!) {
                        const timeslotStartPosition = dateTimeTimeslotMap.get(this.timeslotKey(waveMeeting))?.position;
                        if (!timeslotStartPosition) {
                            continue;
                        }
                        let timeslotEndPosition = timeslotStartPosition;
                        while (
                            timeslotEndPosition < this.config.timeslots.length
                                && 1 === this.compareTimeslotsEndTime(waveMeeting, positionTimeslotMap.get(timeslotEndPosition)!)
                        ) {
                            timeslotEndPosition += 1;
                        }
                        
                        // расскрашиваем сами встречи либо градиентом, либо дефолтным цветом
                        if (this.checkMode(LaunchMode.WithGradientMeetings)) {
                            const hue = 270 - 270 * meetingsColorMetricMap.get(waveMeeting.meetingId)! / moduleMaxColorMetric;
                            this.ctx.fillStyle = `hsl(${hue}, 100%, 50%)`;
                        } else {
                            this.ctx.fillStyle = GUIDE_COLOR;
                        }

                        // вычисление координат и размеров встречи
                        let rectTop = top - WAVEMEETING_MARGIN_BOTTOM_PX;
                        const left = WAVEMEETING_LEFT_PX
                        + timeslotStartPosition * (WAVEMEETING_WIDTH_PX + WAVEMEETING_MARGIN_PX);
                        let rectHeight = WAVEMEETING_HEIGHT_PX;
                        const waveMeetingTimeslots = timeslotEndPosition - timeslotStartPosition + 1;
                        let rectWidth = waveMeetingTimeslots * WAVEMEETING_WIDTH_PX + (waveMeetingTimeslots - 1) * WAVEMEETING_MARGIN_PX;
                        if (waveMeeting.waveIndexes.length > 1) {
                            rectTop -= 1;
                            rectHeight += 2;
                        }

                        // отрисовка самой встречи
                        this.ctx.fillRect(left, rectTop, rectWidth, rectHeight);

                        // отрисовка рамки вокруг встречи, которая показывает есть ли во встречи нарушение констрейнта
                        // отрисовка рамки для тех нарушений, в которых есть id встречи, в которое произошло нарушение констрейнта
                        // в if блоке отрисовываем цветные рамки для режимов ColoredConstraintsWithEquipment иColoredConstraintsWithoutEquipment
                        // в else просто сереньким
                        if (
                            (this.checkMode(LaunchMode.ColoredConstraintsWithEquipment) || this.checkMode(LaunchMode.ColoredConstraintsWithoutEquipment))
                            && scoreMeetingWaveWithViolations
                            && scoreMeetingWaveWithViolations.find(violation => violation.includes(this.waveMeetingKey(waveMeeting.meetingId, waveIndex)))
                        ) {
                            const colors = this.getMeetingViolationColors(scoreMeetingWaveWithViolations, this.waveMeetingKey(waveMeeting.meetingId, waveIndex));
                            if (colors.length) {
                                this.ctx.strokeStyle = colors[0];
                                this.ctx.strokeRect(left - 1, rectTop - 1, rectWidth + 2, rectHeight + 2);
                            }
                        } else if (
                            scoreMeetingWaveWithViolations
                            && scoreMeetingWaveWithViolations.find(violation => violation.includes(this.waveMeetingKey(waveMeeting.meetingId, waveIndex)))
                            && this.checkMode(LaunchMode.WithGradientMeetings)
                        ) {
                            this.ctx.strokeStyle = CAPTION_COLOR;
                            this.ctx.strokeRect(left - 1, rectTop - 1, rectWidth + 2, rectHeight + 2);
                        }

                        // отрисовка рамки для тех нарушений, в которых нет id встречи, в которых произошло нарушение констрейнта (например нехватка оборудования в таймслоте)
                        // эта рамка перекроет предыдущую рамку
                        if (
                            this.checkMode(LaunchMode.ColoredConstraintsWithEquipment)
                            && this.checkMeetingIntersectionWithEquipmentViolation(waveMeeting)
                        ) {
                            this.ctx.strokeStyle = colorConstraintMap['Не хватает оборудования для всех встреч в это время'];
                            this.ctx.strokeRect(left - 1, rectTop - 1, rectWidth + 2, rectHeight + 2);
                        }


                        // if (timeslotPosition < moduleMinTimeslotPosition) {
                        //     moduleMinTimeslotPosition = timeslotPosition;
                        // }
                        // if (timeslotPosition > moduleMaxTimeslotPosition) {
                        //     moduleMaxTimeslotPosition = timeslotPosition;
                        // }
                    }
                }

                top += CAPTION_FONT_SIZE_PX + CAPTION_LINE_MARGIN_PX;
            }

            // const dependencyChainGradient = this.ctx.createLinearGradient(0, 0, 200, 0);
            // dependencyChainGradient.addColorStop(0, `hsl(0, 100%, 50%)`);
            // dependencyChainGradient.addColorStop(1, `hsl(270, 100%, 50%)`);
            // this.ctx.fillStyle = dependencyChainGradient;
        }
    }

    private groupWaveMeetingsByModuleAndWave() {
        const meetingIdToModuleIdMap = this.config.modules.reduce(
            (map, module) => {
                return module.meetings.reduce(
                    (map, meeting) => {
                        map.set(meeting.id, module.id);
                        return map;
                    },
                    map
                );
            },
            new Map<string, string>()
        );
        return this.result.meetings.reduce(
            (moduleWaveMeetings, meeting) => {
                for (const waveIndex of meeting.waveIndexes) {
                    const moduleId = meetingIdToModuleIdMap.get(meeting.meetingId);
                    const key = `${moduleId} ${waveIndex}`;
                    if (!moduleWaveMeetings.has(key)) {
                        moduleWaveMeetings.set(key, []);
                    }
                    moduleWaveMeetings.get(key)!.push(meeting);
                }
                return moduleWaveMeetings;
            },
            new Map<string, ScheduleResultMeeting[]>()
        );
    }

    private timeslotKey(timeslot: { date: string, startTime: string }) {
        return `${timeslot.date} ${timeslot.startTime}`;
    }

    private moduleWaveKey(arg: Module | ScheduleResultMeeting, waveIndex: number) {
        return `${'moduleId' in arg ? arg.moduleId : arg.id} ${waveIndex}`;
    }

    private waveMeetingKey(meetingId: string, waveIndex: number, constraints?: string[]) {
        return `${meetingId} ${waveIndex} ${constraints?.join() || ''}`;
    }

    private saveToFile(outputImageFilePath: string) {
        const imageBuffer = this.canvas.toBuffer();
        writeFileSync(outputImageFilePath, imageBuffer);
    }

    private getMeetingsDependencyChainLength() {
        const meetingsDependencyChainLength = new Map<string, number>();

        for (const module of this.config.modules) {
            const meetingsMap = new Map<string, Meeting>(
                module.meetings.map(meeting => [meeting.id, meeting])
            );

            for (const meeting of module.meetings) {
                this.calcMeetingDependencyChainLength(meeting, meetingsMap, meetingsDependencyChainLength);
            }
        }

        return meetingsDependencyChainLength;
    }

    private getMeetingsDependentChainLength() {
        const meetingsDependentChainLength = new Map<string, number>();

        for (const module of this.config.modules) {
            const meetingsMap = new Map<string, Meeting>(
                module.meetings.map(meeting => [meeting.id, meeting])
            );

            for (const meeting of module.meetings) {
                this.calcMeetingDependentChainLength(meeting, meetingsMap, meetingsDependentChainLength);
            }
        }

        return meetingsDependentChainLength;
    }

    private calcMeetingDependencyChainLength(
        meeting: Meeting,
        meetingsMap: Map<string, Meeting>,
        meetingsDependencyChainLength: Map<string, number>
    ) {
        if (meetingsDependencyChainLength.has(meeting.id)) {
            return;
        }
        if (!meeting.dependencyMeetingIds || meeting.dependencyMeetingIds.length == 0) {
            meetingsDependencyChainLength.set(meeting.id, 0);
            return;
        }

        let maxDependencyChainLength = 0;
        for (const dependencyMeetingId of meeting.dependencyMeetingIds!) {
            if (!meetingsDependencyChainLength.has(dependencyMeetingId)) {
                const dependencyMeeting = meetingsMap.get(dependencyMeetingId)!;
                this.calcMeetingDependencyChainLength(
                    dependencyMeeting,
                    meetingsMap,
                    meetingsDependencyChainLength
                );
            }
            if (meetingsDependencyChainLength.get(dependencyMeetingId)! > maxDependencyChainLength) {
                maxDependencyChainLength = meetingsDependencyChainLength.get(dependencyMeetingId)!;
            }
        }

        meetingsDependencyChainLength.set(meeting.id, maxDependencyChainLength + 1);
    }

    private calcMeetingDependentChainLength(
        meeting: Meeting,
        meetingsMap: Map<string, Meeting>,
        meetingsDependentChainLength: Map<string, number>
    ) {
        if (meetingsDependentChainLength.has(meeting.id)) {
            return;
        }

        let maxDependentChainLength: number | undefined;
        // раньше тут не было Array.from(), было просто meetingsMap.values()
        // meetingsMap.values() возвращает итерируемый объект, а не массив
        // у тайпскрипт бугурт на итерируемые объекты в for of-е
        for (const dependentMeeting of Array.from(meetingsMap.values())) {
            if (!(dependentMeeting.dependencyMeetingIds
                && dependentMeeting.dependencyMeetingIds.length
                && dependentMeeting.dependencyMeetingIds.includes(meeting.id)
            )) {
                continue;
            }
            if (!meetingsDependentChainLength.has(dependentMeeting.id)) {
                this.calcMeetingDependentChainLength(
                    dependentMeeting,
                    meetingsMap,
                    meetingsDependentChainLength
                );
            }
            if (
                undefined === maxDependentChainLength
                || meetingsDependentChainLength.get(dependentMeeting.id)! > maxDependentChainLength
            ) {
                maxDependentChainLength = meetingsDependentChainLength.get(dependentMeeting.id)!;
            }
        }

        meetingsDependentChainLength.set(
            meeting.id,
            undefined !== maxDependentChainLength ? maxDependentChainLength + 1 : 0
        );
    }

    private compareTimeslotsEndTime(
        t1: { date: string, endTime: string },
        t2: { date: string, endTime: string }
    ) {
        return compareAsc(
            parseISO(`${t1.date}T${t1.endTime}+03:00`),
            parseISO(`${t2.date}T${t2.endTime}+03:00`)
        );
    }
    private parseDatesForEquipmentViolationsAndMeettings(modules: Module[], modulesWavesMeetings: Map<string, ScheduleResultMeeting[]>) {
        const equipmentsByConstraint = this.score.equipmentsByConstraint;
        for (const key in equipmentsByConstraint) {
            equipmentsByConstraint[key].forEach(
                (violations: EquipmentViolation[]) => {
                    violations.forEach(
                        (violation) => {
                            if (!this.equipmentViolationsDates[violation.equipmentId]) {
                                this.equipmentViolationsDates[violation.equipmentId] = {};
                            }
                            this.equipmentViolationsDates[violation.equipmentId][`${violation.date} ${violation.startTime}`] = parse(`${violation.date} ${violation.startTime}`, 'yyyy-MM-dd HH:mm:ss', new Date());
                        }
                    );
                }
            );
        }
        for (let moduleIndex = 0; moduleIndex < modules.length; moduleIndex += 1) {
            const module = modules[moduleIndex];
            for (let waveIndex = 0; waveIndex < module.wavesCount; waveIndex += 1) {
                const waveMeetings = modulesWavesMeetings.get(this.moduleWaveKey(module, waveIndex));
                if (!waveMeetings) {
                    console.log(`Нет встреч для модуля "${module.name}" (${module.id}) потока ${waveIndex + 1}`);
                    continue;
                }

                for (const waveMeeting of modulesWavesMeetings.get(this.moduleWaveKey(module, waveIndex))!) {
                    if (!this.meetingDates[waveMeeting.meetingId]) {
                        this.meetingDates[waveMeeting.meetingId] = {};
                    }
                    this.meetingDates[waveMeeting.meetingId][`${waveMeeting.date} ${waveMeeting.startTime}`] = parse(`${waveMeeting.date} ${waveMeeting.startTime}`, 'yyyy-MM-dd HH:mm:ss', new Date());
                    this.meetingDates[waveMeeting.meetingId][`${waveMeeting.date} ${waveMeeting.endTime}`] = parse(`${waveMeeting.date} ${waveMeeting.endTime}`, 'yyyy-MM-dd HH:mm:ss', new Date());
                }
            }
        }
    }
    private getMeetingViolationColors(scoreMeetingWaveWithViolations: string[], targetMeeting: string) {
        const violations = scoreMeetingWaveWithViolations.filter(violation => violation.includes(targetMeeting));
        const constraints = Object.entries(colorConstraintMap).filter(([constraint]) => violations.find(violation => violation.includes(constraint)));
        return constraints.map(([__constraint, color]) => color);
    }

    private checkMeetingIntersectionWithEquipmentViolation(meeting: ScheduleResultMeeting) {
        const equipmentsByConstraint = this.score.equipmentsByConstraint;
        for (const key in equipmentsByConstraint) {
            if (equipmentsByConstraint[key].find((violations: EquipmentViolation[]) => violations.find(violation => this.checkIntersection(violation, meeting)))) {
                return true;
            }
        }
        return false;
    }

    private checkIntersection(equipmentViolation: EquipmentViolation, meeting: ScheduleResultMeeting) {
        if( this.checkEquipmentIntersection(equipmentViolation, meeting) && this.checkDateIntersection(equipmentViolation, meeting)) {
            return true;
        }
        return false;
    }

    private checkDateIntersection(equipmentViolation: EquipmentViolation, meeting: ScheduleResultMeeting) {
        // захешированные распаршенный даты для каждого нарушения и встречи, считает быстро
        const violationDate = this.equipmentViolationsDates[equipmentViolation.equipmentId][`${equipmentViolation.date} ${equipmentViolation.startTime}`];
        const meetingStartDate = this.meetingDates[meeting.meetingId][`${meeting.date} ${meeting.startTime}`];
        const meetingEndDate = this.meetingDates[meeting.meetingId][`${meeting.date} ${meeting.endTime}`];
        // медленный парсинг, занимает в х12 раз больше времени
        // const violationDate = parse(`${equipmentViolation.date} ${equipmentViolation.startTime}`, 'yyyy-MM-dd HH:mm:ss', new Date());
        // const meetingStartDate = parse(`${meeting.date} ${meeting.startTime}`, 'yyyy-MM-dd HH:mm:ss', new Date());
        // const meetingEndDate = parse(`${meeting.date} ${meeting.endTime}`, 'yyyy-MM-dd HH:mm:ss', new Date());

        // if (violationDate >= meetingStartDate && violationDate < meetingEndDate) {
        //     console.log(`
        //     equipment: ${equipmentViolation.equipmentId}\n
        //     equipment date: ${equipmentViolation.date}\n
        //     equipment start time: ${equipmentViolation.startTime}\n
        //     \n
        //     meeting: ${meeting.meetingId}\n
        //     meeting date: ${meeting.date}\n
        //     meeting start time: ${meeting.startTime}\n
        //     meeting end time: ${meeting.endTime}\n
        //     \n\n\n
        //     `);
        // }
        return (violationDate >= meetingStartDate && violationDate < meetingEndDate);
    }

    private checkEquipmentIntersection(equipmentViolation: EquipmentViolation, meeting: ScheduleResultMeeting) {
        return !!meeting.equipment?.find(equipment => equipmentViolation.equipmentId == equipment.id);
    }

    private checkMode(mode: LaunchMode) {
        return this.mode === mode;
    }
}

// if (process.argv.length < 5) {
//     throw new Error("Usage: node dist/visualize-meetings-waves <config.json> <result.json> <output.png> [<score.json>] [-m=|--mode=<gradient|colored|colored_without_equipment>]");
// }
// new Vizualize().vizualize(process.argv[2], process.argv[3], process.argv[4], process.argv[5]);