import { gql, useQuery } from '@apollo/client';
import { OnlineRoom } from './interfaces';

const ONLINE_ROOMS = gql`
    query onlineRooms {
        onlineRooms {
            id
            url
            capacity
        }
    }
`;

export function useOnlineRoomsQuery() {
    const { data, loading, refetch } = useQuery<{ onlineRooms: OnlineRoom[] }>(
        ONLINE_ROOMS,
        { fetchPolicy: 'network-only' },
    );
    return { onlineRooms: data?.onlineRooms, loading, refetch };
}
