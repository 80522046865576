import React, { Fragment, useState } from 'react';

import { getBaseTimeIntervalInstanceById } from '@admin/ScheduleGenerationPage/ScheduleGenerationConflicts';
import { BaseTimeIntervalInstanceForProcess, StudentLimitViolation } from '../../scheduleViolationInterface';
import { ArrowInShowViolation } from '../ArrowInShowViolation';

import classes from '../scheduleViolation.module.scss';

interface Props {
    studentLimitViolation: StudentLimitViolation | null;
    baseTimeIntervalsInstances: BaseTimeIntervalInstanceForProcess[],
}

export const ShowStudentLimitViolation = ({
    studentLimitViolation,
    baseTimeIntervalsInstances,
}: Props) => {
    const [isOpen, setIsOpen] = useState(false);

    return studentLimitViolation && (
        <div className={classes.schedule__violation_container}>
            <div
                className={classes.schedule__violation_title}
                onClick={() => {
                    if (canOpenAccordion(studentLimitViolation)) {
                        setIsOpen(!isOpen);
                    }
                }}
            >
                <div className={classes.schedule__violation_content}>
                    {studentLimitViolation?.violationName}
                </div>

                <ArrowInShowViolation isOpen={isOpen} />
            </div>
            {isOpen && studentLimitViolation?.studentDurationLimitsByDate
                .map((violationsByDate, index) => (
                    <Fragment key={JSON.stringify(`${violationsByDate.date}${index}`)}>
                        {
                            violationsByDate.studentDurationLimits.length ? (
                                <>
                                    <div
                                        className={classes.schedule__violation_conflict_title}
                                    >
                                        Дата: {`${violationsByDate.date}`}
                                    </div>

                                    <div className={classes.schedule__violation_value}>
                                        <span>Учебный период: </span>
                                        {
                                            getBaseTimeIntervalInstanceById(
                                                violationsByDate.studentDurationLimits[0]?.baseTimeIntervalInstanceId ?? '',
                                                baseTimeIntervalsInstances,
                                            )?.longName ?? ''
                                        }
                                    </div>

                                    {violationsByDate.studentDurationLimits.map((limit) => {
                                        const { user } = limit.student;
                                        return (
                                            <div
                                                key={`${violationsByDate.date} ${user.id}`}
                                                className={classes.schedule__violation_conflict}
                                            >
                                                Студент: {`${user.lastName} ${user.firstName} ${user.patronymic} `}
                                                <div>Превышение: {`${limit.extraDurationMinutes} минут`}</div>
                                            </div>
                                        );
                                    })}
                                </>
                            ) : null
                        }
                    </Fragment>
                ))}
        </div>
    );
};

function canOpenAccordion(
    studentLimitViolation: StudentLimitViolation | null,
) {
    return studentLimitViolation ? studentLimitViolation.studentDurationLimitsByDate.some(
        (limits) => limits.studentDurationLimits.length,
    ) : false;
}
