import React from 'react';

import { BorderPlusIcon } from '@common/svg';
import { GradientText } from '@common/GradientText';
import classes from './AddRoomButton.module.scss';

interface AddRoomButtonProps {
    addRoomButton(): void;
}

export function AddRoomButton({ addRoomButton }: AddRoomButtonProps): JSX.Element {
    return (
        <button
            type="button"
            onClick={addRoomButton}
            className={classes.button_wrapper}
        >
            <BorderPlusIcon />
            <GradientText text="добавить помещение" className={classes.button__roomText} />
        </button>
    );
}
