import { useMutation } from '@apollo/client';
import {
    UpdateEquipmentCategoryEquipmentPage,
    UpdateEquipmentCategoryEquipmentPageVariables,
    UpdateEquipmentCategoryInput,
} from 'src/deprecated-graphql-query-types';
import { UPDATE_EQUIPMENT_CATEGORY } from '../queries';

export const useUpdateEquipmentCategoryMutation = () => {
    const [updateEquipmentCategory, {
        data,
        error,
        loading,
    }] = useMutation<
    UpdateEquipmentCategoryEquipmentPage,
    UpdateEquipmentCategoryEquipmentPageVariables
    >(
        UPDATE_EQUIPMENT_CATEGORY,
    );
    return {
        data,
        loading,
        error,
        updateEquipmentCategory: (input: UpdateEquipmentCategoryInput) => updateEquipmentCategory({
            variables: {
                updateEquipmentCategoryInput: input,
            },
        }),
    };
};
