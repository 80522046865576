import React from 'react';

import { Switcher } from '@common';

import classes from './Time.module.scss';

interface Props {
    currentDate: string;
    isTimeVisible: boolean;
    setTimeVisible: (isVisible: boolean) => void;
}

export function Time(
    {
        currentDate,
        isTimeVisible,
        setTimeVisible,
    }: Props,
) {
    return (
        <div className={classes.time}>

            <div className={classes.time__switcher}>
                <p className={classes.time__switcher__title}>Уточнить время:</p>
                <Switcher
                    onClick={() => setTimeVisible(!isTimeVisible)}
                />
            </div>

            {
                isTimeVisible && (
                    <div className={classes.time__datesWithTime}>
                        <div className={classes.time__dateWithTime}>
                            <p className={classes.time__date}>{currentDate}</p>
                            <input
                                type="time"
                                name="start"
                                className={classes.time__input}
                            />
                        </div>
                    </div>
                )
            }
        </div>
    );
}
