import React, { useState } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import { Options } from '@admin/NewModule/Store';
import { config } from '@admin/NewModule/Description/config';

import classes from './Description.module.scss';

interface Props {
    value: string;
    label: string;
    isDefault: boolean;
    onChangeEvent: (data: Partial<Options>) => void;
}

export function Description({ value, label, isDefault, onChangeEvent }: Props) {
    const [defaultEditorData, setDefaultEditorData] = useState<string>('<p>Введите описание модуля</p>');
    const [editorData, setEditorData] = useState<string>(config.template);
    const [isEditing, setEditing] = useState<boolean>(false);

    return (
        <div className={classes.description}>

            <h3 className={classes.description__label}>
                {label}
            </h3>

            <CKEditor
                config={config.config}
                editor={ClassicEditor}
                data={
                    isDefault ? defaultEditorData : editorData
                }
                onReady={(editor) => {
                    if (value) {
                        setDefaultEditorData(value);
                        editor.setData(value);
                    }
                }}
                onChange={(event, editor) => {
                    const data = editor.getData();

                    if (isDefault && isEditing) {
                        setDefaultEditorData(data);
                    } else if (!isDefault && isEditing) {
                        setEditorData(data);
                    }
                    onChangeEvent({ description: data });
                }}
                onFocus={() => {
                    setEditing(true);
                }}
                onBlur={() => {
                    setEditing(false);
                }}
            />

        </div>
    );
}
