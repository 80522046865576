import React from 'react';

import { SearchParams } from '../../util';

enum SearchType {
    input = 'input',
    select = 'select',
    selectSpace = 'selectSpace',
    baseTimeInterval = 'baseTimeInterval',
    calendar = 'calendar',
}

interface SelectOption {
    name: string;
    id: string;
}

interface Props {
    type: SearchType;
    selectOptions: SelectOption[];
    name?: string;
    setSearchParams?: React.Dispatch<React.SetStateAction<SearchParams>>;
}

export function SelectSearchForm({
    type,
    selectOptions,
    name,
    setSearchParams,
}: Props) {
    function onChange({ target }: React.ChangeEvent<HTMLSelectElement>) {
        if (!setSearchParams || !name) {
            return;
        }
        const selectedValue = target[target.selectedIndex].id === 'none'
            ? undefined
            : target[target.selectedIndex].id;
        setSearchParams(prev => ({ ...prev, [name]: selectedValue }));
    }

    return (
        <select
            onChange={onChange}
            name={name}
        >
            {type === SearchType.selectSpace
                && <option id="none">Любое пространство</option>}
            {type === SearchType.baseTimeInterval
                && <option id="none">Любой интервал</option>}

            {selectOptions.map(option => (
                <option id={option.id} key={option.id}>
                    {option.name}
                </option>
            ))}
        </select>
    );
}
