import { gql } from '@apollo/client';

import { useMutationWithErrorHandling } from './useMutationWithErrorHandling';

const BEGIN_ADDITIONAL_GENERATION = gql`
    mutation startAdditionalGeneration($startAdditionalGenerationInput: StartAdditionalGenerationInput!) {
        startAdditionalGeneration(startAdditionalGenerationInput: $startAdditionalGenerationInput) {
            id
        }
    }
`;

export function useBeginAdditionalGeneration(onCompleted?: () => void) {
    const [beginAdditionalGeneration, { loading, error }] = useMutationWithErrorHandling(
        BEGIN_ADDITIONAL_GENERATION, { onCompleted },
    );

    return ({
        beginAdditionalGeneration: (
            generationProcessId: string,
            ignoreFlags: string[] = [],
        ) => beginAdditionalGeneration({
            variables: {
                startAdditionalGenerationInput: {
                    generationProcessId,
                    ignoreFlags,
                },
            },
        }),
        loading,
        error,
    });
}
