import React from 'react';
import { Loader } from '@common/Loader';
import { useScheduleGenerationsQuery } from '../graphql';
import { ScheduleGenerationPage } from './ScheduleGenerationPage';

interface Props {
    baseTimeIntervalInstanceId: string;
    isSecret: boolean;
}

export const ScheduleGenerationApollo = ({ baseTimeIntervalInstanceId, isSecret }: Props) => {
    const {
        scheduleGenerationProcesses, refetch, loading,
    } = useScheduleGenerationsQuery(baseTimeIntervalInstanceId);
    return (
        <>
            {loading && <Loader />}
            {scheduleGenerationProcesses && (
                <ScheduleGenerationPage
                    isSecret={isSecret}
                    processes={scheduleGenerationProcesses ?? []}
                    baseTimeIntervalInstanceId={baseTimeIntervalInstanceId}
                    refetchProcesses={() => refetch({ baseTimeIntervalInstanceId })}
                />
            )}
        </>
    );
};
