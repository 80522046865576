import React, { useState } from 'react';
import { format } from 'date-fns';
import { isEmpty } from 'lodash';

import {
    PREPARING_STATUSES,
    GENERATED_STATUSES,
    ScheduleGenerationProcessStatus,
    ScheduleGenerationProcess,
    ConstraintMatchCount,
} from '@admin/ScheduleGenerationPage/store/BaseTimeIntervalInstanceModel';
import { Alert } from '@common';
import {
    useBeginAdditionalGeneration,
    useDownloadConfigForAdditionalGenerationQuery,
    useFinishScheduleGenerationMutation,
    useImportScheduleGenerationResult,
    useValidateScheduleLazyQuery,
} from '@admin/ScheduleGenerationPage/graphql';
import { HorizontalArrowIcon, HorizontalVector } from '@common/svg';
import { ActionButton, ActionTypeOfButton } from '@common/ActionButton';
import { Loader } from '@common/Loader';
import { UploadButton } from '@admin/ScheduleGenerationPage/UploadButton';
import { IgnoreErrorsFlag } from 'src/graphql-query-types';

import classes from './AdditionalGeneration.module.scss';

type Props = {
    process: ScheduleGenerationProcess;
    back(): void;
    refetchProcesses(): void;
    isSecret: boolean;
};

enum IgnoreFlag {
    ignoreTeachers = 'преподаватели',
    ignoreStudents = 'студенты',
    ignoreEquipments = 'оборудование',
    ignoreRooms = 'помещения',
}

type IgnoreFlagCheckbox = {
    [Key in keyof typeof IgnoreFlag]: boolean;
};

export function AdditionalGeneration({ process, isSecret, back, refetchProcesses }: Props) {
    const {
        beginAdditionalGeneration,
        loading: loadingBeginGeneration,
        error: errorBeginGeneration,
    } = useBeginAdditionalGeneration(refetchProcesses);
    const {
        downloadConfig, loading: configLoading,
    } = useDownloadConfigForAdditionalGenerationQuery(process.id);
    const {
        importResult, called: resultImported,
    } = useImportScheduleGenerationResult(process.id);
    const [ignoreFlags, setIgnoreFlags] = useState<IgnoreFlagCheckbox>(
        {
            ignoreTeachers: false,
            ignoreStudents: false,
            ignoreEquipments: false,
            ignoreRooms: false,
        },
    );
    const handleIgnoreFlagsChange = (ignoreFlag: keyof typeof IgnoreFlag) => () => setIgnoreFlags(
        (oldConflictTypes: IgnoreFlagCheckbox) => (
            { ...oldConflictTypes, [ignoreFlag]: !oldConflictTypes[ignoreFlag] }
        ),
    );
    const finishScheduleGeneration = useFinishScheduleGenerationMutation();
    function finishAdditionGeneration(doImport: boolean = false) {
        finishScheduleGeneration(process.id, doImport);
    }
    const { validate,
        loading: isValidationInProcess,
        validationResult,
    } = useValidateScheduleLazyQuery();
    function validateSchedule() {
        validate({
            variables: {
                validateScheduleInput: {
                    generationProcessId: process.id,
                    ignoreFlags: getTrueKeysFromObject(ignoreFlags) as IgnoreErrorsFlag[],
                },
            },
        });
    }
    function isAdditionalGenerationDisabled() {
        return !validationResult || !(
            process.status === ScheduleGenerationProcessStatus.Correction
            && isEmpty(validationResult.errors)
        );
    }
    return (
        <div>
            {errorBeginGeneration && <Alert message={errorBeginGeneration.message} />}
            <div onClick={back} className={classes.additionalGeneration_back}>
                <HorizontalArrowIcon horizontalVector={HorizontalVector.LEFT} width={20} />
                Список периодов догенерации
            </div>
            <div className={classes.additionalGeneration}>
                <div>
                    <span className={classes.additionalGeneration_name}>
                        {process.name}
                    </span>
                    <br />
                    {formatDate(process.from)} - {formatDate(process.to)}
                    <br />
                    Статус: {getStatusName(process.status)}
                </div>
                {
                    isSecret ? (
                        <div className={classes.additionalGeneration_actions}>
                            {loadingBeginGeneration ? <Loader /> : (
                                PREPARING_STATUSES.includes(process.status)
                                && (
                                    <>
                                        <ActionButton
                                            onClick={() => beginAdditionalGeneration(process.id)}
                                            className={classes.button}
                                        >
                                            Начать догенерацию
                                        </ActionButton>
                                        <br />
                                    </>
                                ))}
                            {!GENERATED_STATUSES.includes(process.status) && (
                                <>
                                    <ActionButton
                                        onClick={downloadConfig}
                                        disabled={configLoading}
                                        className={classes.button}
                                    >
                                        Скачать конфиг
                                    </ActionButton>
                                    <br />
                                    {!resultImported && (
                                        <UploadButton
                                            onLoad={importResult}
                                            className={classes.button}
                                        >
                                            Загрузить результат догенерации на сайт
                                        </UploadButton>
                                    )}
                                </>
                            )}
                        </div>
                    ) : (
                        <div className={classes.additionalGeneration_actions}>
                            {
                                isAdditionalGenerationDisabled() && (
                                    <div className={classes.filters__conflictTypes}>
                                        <div
                                            className={classes.filters__itemName}
                                        >
                                            <b>
                                                Процесс догенерации невозможен без решения
                                                текущих ошибок. <br />
                                                Пожалуйста, решите указанные ошибки
                                                и повторите запрос. <br />
                                            </b>
                                        </div>
                                        <ul className={classes.conflicts}>
                                            {
                                                (validationResult
                                                    ? [
                                                        ...validationResult.errors,
                                                        ...validationResult.warnings
                                                            .map(warning => ({
                                                                ...warning,
                                                                message: `(предупреждение) ${warning.message}`,
                                                            })),
                                                    ]
                                                    : []).map(
                                                    (item) => (
                                                        <li key={item.message}>
                                                            {item.message}
                                                        </li>
                                                    ),
                                                )
                                            }
                                        </ul>
                                        <div
                                            className={classes.filters__itemName}
                                        >
                                            Ресурсов недостаточно, это может привести
                                            к нарушениям в догенерации расписания.
                                            <br />
                                            Можно проигнорировать отсутствие следующих ресурсов:
                                        </div>
                                        <div className={classes.filters__checkbox}>
                                            <input
                                                type="checkbox"
                                                id="ignoreTeachers"
                                                name="ignoreTeachers"
                                                value="ignoreTeachers"
                                                checked={ignoreFlags.ignoreTeachers}
                                                onChange={handleIgnoreFlagsChange('ignoreTeachers')}
                                            />
                                            <div onClick={handleIgnoreFlagsChange('ignoreTeachers')}>
                                                {IgnoreFlag.ignoreTeachers}
                                            </div>
                                        </div>
                                        <div className={classes.filters__checkbox}>
                                            <input
                                                type="checkbox"
                                                id="ignoreStudents"
                                                name="ignoreStudents"
                                                value="ignoreStudents"
                                                checked={ignoreFlags.ignoreStudents}
                                                onChange={handleIgnoreFlagsChange('ignoreStudents')}
                                            />
                                            <div onClick={handleIgnoreFlagsChange('ignoreStudents')}>
                                                {IgnoreFlag.ignoreStudents}
                                            </div>
                                        </div>
                                        <div className={classes.filters__checkbox}>
                                            <input
                                                type="checkbox"
                                                id="ignoreEquipments"
                                                name="ignoreEquipments"
                                                value="ignoreEquipments"
                                                checked={ignoreFlags.ignoreEquipments}
                                                onChange={handleIgnoreFlagsChange('ignoreEquipments')}
                                            />
                                            <div onClick={handleIgnoreFlagsChange('ignoreEquipments')}>
                                                {IgnoreFlag.ignoreEquipments}
                                            </div>
                                        </div>
                                        <div className={classes.filters__checkbox}>
                                            <input
                                                type="checkbox"
                                                id="ignoreRooms"
                                                name="ignoreRooms"
                                                value="ignoreRooms"
                                                checked={ignoreFlags.ignoreRooms}
                                                onChange={handleIgnoreFlagsChange('ignoreRooms')}
                                            />
                                            <div onClick={handleIgnoreFlagsChange('ignoreRooms')}>
                                                {IgnoreFlag.ignoreRooms}
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                            {
                                process.status === ScheduleGenerationProcessStatus.Correction
                                && (
                                    <div>
                                        Перед запуском догенерации необходимо произвести валидацию
                                        ошибок. <br />
                                        Пожалуйста, нажмите на кнопку &quot;Валидация&quot;
                                        <br />
                                        {
                                            isValidationInProcess && (
                                                <>
                                                    <br />
                                                    <div>
                                                        Идёт процесс валидации...
                                                        <Loader />
                                                    </div>
                                                    <br />
                                                </>
                                            )
                                        }
                                        <br />
                                    </div>
                                )
                            }
                            {
                                getTrueKeysFromObject(ignoreFlags).length > 0
                                && process.status !== ScheduleGenerationProcessStatus.Generation
                                && process.status !== ScheduleGenerationProcessStatus.Preparing
                                && (
                                    <div className={classes.warning}>
                                        <div>
                                            Были проигнорированы следующие
                                            ресурсы при валидации расписания:
                                        </div>
                                        <ul>{
                                            getTrueKeysFromObject(ignoreFlags).map(
                                                (flag) => (
                                                    <li key={flag}>{
                                                        // eslint-disable-next-line max-len
                                                        Object.getOwnPropertyDescriptor(IgnoreFlag, flag)?.value
                                                    }
                                                    </li>
                                                ),
                                            )
                                        }
                                        </ul>
                                        <div>
                                            Игнорирование данных ресурсов может
                                            привести к конфликтам в догенерации расписания
                                        </div>
                                        <br />
                                    </div>
                                )
                            }
                            {
                                !isAdditionalGenerationDisabled() && (
                                    <>
                                        Валидация прошла успешно, вы можете начать
                                        процесс догенерации. Нажмите на кнопку «Начать догенерацию»
                                        <br />
                                        <br />
                                    </>
                                )
                            }
                            <div className={classes.additionalGeneration__mainActions}>
                                {
                                    process.status === ScheduleGenerationProcessStatus.Correction
                                    && (
                                        <>
                                            <ActionButton
                                                onClick={validateSchedule}
                                                className={classes.button}
                                            >
                                                Валидация
                                            </ActionButton>
                                            <br />
                                        </>
                                    )
                                }
                                {
                                    isSaveButtonVisible(process) && (
                                        <div className={classes.additionalGeneration__finish}>
                                            <ActionButton
                                                actionType={ActionTypeOfButton.SECONDARY}
                                                onClick={
                                                    () => finishAdditionGeneration(false)
                                                }
                                                className={classes.button}
                                            >
                                                Отменить догенерацию
                                            </ActionButton>
                                            <ActionButton
                                                actionType={ActionTypeOfButton.PRIMARY}
                                                onClick={
                                                    () => finishAdditionGeneration(true)
                                                }
                                                className={classes.button}
                                            >
                                                Загрузить расписание
                                            </ActionButton>
                                        </div>
                                    )
                                    // не рендерить кнопку когда статус preparing
                                    // eslint-disable-next-line max-len
                                }
                                {!isSaveButtonVisible(process) && (loadingBeginGeneration
                                    ? <Loader />
                                    : (process.status === ScheduleGenerationProcessStatus.Correction
                                        && (
                                            <>
                                                <ActionButton
                                                    onClick={
                                                        () => beginAdditionalGeneration(
                                                            process.id,
                                                            getTrueKeysFromObject(ignoreFlags),
                                                        )
                                                    }
                                                    disabled={isAdditionalGenerationDisabled()}
                                                    className={classes.button}
                                                >
                                                    Начать догенерацию
                                                </ActionButton>
                                                <br />
                                            </>
                                        )))}
                            </div>
                            <>
                                {
                                    (process.status === ScheduleGenerationProcessStatus.Uploading
                                        || process.status
                                            === ScheduleGenerationProcessStatus.Preparing)
                                    && (<Loader />)
                                }
                                {
                                    isScoreVisible(process) && (
                                        <>
                                            <br />
                                            <ul className={classes.score}>
                                                {
                                                    // eslint-disable-next-line max-len
                                                    process?.constraintMatch?.constraintMatchCountList.map(
                                                        (item: ConstraintMatchCount) => item.scoreType === 'HARD' && (
                                                            <li>
                                                                {item.constraintName}
                                                                &nbsp;&nbsp;&nbsp;
                                                                {item.matchCount}
                                                            </li>
                                                        ),
                                                    )
                                                }
                                            </ul>
                                            <br />
                                        </>
                                    )
                                }
                            </>
                        </div>
                    )
                }
            </div>
        </div>
    );
}

function getTrueKeysFromObject(obj: { [index: string]: boolean }) {
    return Object.entries(obj).filter(([, value]) => value).map(([key]) => key);
}

export function formatDate(date: Date | string | number) {
    return format(new Date(date), 'dd.MM.yyyy');
}

function getStatusName(status: ScheduleGenerationProcessStatus) {
    switch (status) {
        case ScheduleGenerationProcessStatus.Correction:
            return 'Запуск валидации';
        case ScheduleGenerationProcessStatus.Preparing:
            return 'Подготовка данных';
        case ScheduleGenerationProcessStatus.Generation:
            return 'Идет процесс догенерации';
        case ScheduleGenerationProcessStatus.GenerationReady:
            return 'Расписание сгенерировано';
        case ScheduleGenerationProcessStatus.GenerationFailed:
            return 'Ошибки догенерации';
        case ScheduleGenerationProcessStatus.Uploading:
            return 'Процесс загрузки расписания';
        case ScheduleGenerationProcessStatus.Uploaded:
            return 'Догенерации расписания завершена';
        case ScheduleGenerationProcessStatus.Generated:
            return 'Догенерации расписания завершена';
        default:
            return '';
    }
}

function isScoreVisible(process: ScheduleGenerationProcess) {
    return (process.status === ScheduleGenerationProcessStatus.Generation
        || process.status === ScheduleGenerationProcessStatus.GenerationReady)
        && !!process.constraintMatch?.constraintMatchCountList?.length;
}

function isSaveButtonVisible(process: ScheduleGenerationProcess) {
    return process.status === ScheduleGenerationProcessStatus.GenerationReady;
}
