import React, { useState } from 'react';
import cn from 'classnames';

import classes from '@admin/EducationPeriodPage/EducationPeriodDates/EducationPeriodDatesEdit/EducationPeriodDatesAccordion/AccordionItems/AccordionItems.module.scss';
// import { CalendarWithTime } from '@admin/EducationPeriodPage/CalendarWithTime';
import {
    formatDate,
} from '@admin/EducationPeriodPage/EducationPeriodDates/EducationPeriodDatesEdit/EducationPeriodDatesAccordion';
import { CalendarWithTime } from '@admin/EducationPeriodPage/CalendarWithTime';

import {
    ParametersTransform,
} from '@admin/EducationPeriodPage/EducationPeriodDates/EducationPeriodDatesEdit/EducationPeriodDatesAccordion/AccordionItems';
import {
    ModuleAssessmentAccordionItem,
} from '@admin/EducationPeriodPage/EducationPeriodDates/EducationPeriodDatesEdit/EducationPeriodDatesAccordion/AccordionItems/ModuleAssessmentAccordionItem';

interface Props {
    name: string;
    dateStart?: string;
    dateEnd?: string;
    parametersArray: ParametersTransform[];
    isModuleAssessDynamicStart: boolean;
    setModuleAssessDynamicStart: (isDynamic: boolean) => void;
    setBtiParameters: (newParams: ParametersTransform[]) => void;
}

export function AccordionItem(
    {
        name,
        dateStart,
        dateEnd,
        parametersArray,
        isModuleAssessDynamicStart,
        setModuleAssessDynamicStart,
        setBtiParameters,
    }: Props,
) {
    const [isStartEditing, setStartEditing] = useState(false);
    const [isEndEditing, setEndEditing] = useState(false);

    return (
        <>
            {
                name === 'Студенческая оценка преподавателей' ? (

                    <ModuleAssessmentAccordionItem
                        name={name}
                        dateStart={dateStart}
                        dateEnd={dateEnd}
                        parametersArray={parametersArray}
                        isModuleAssessDynamicStart={isModuleAssessDynamicStart}
                        setModuleAssessDynamicStart={setModuleAssessDynamicStart}
                        setBtiParameters={setBtiParameters}
                    />
                ) : (
                    <div
                        className={cn(classes.items__container, {
                            [classes.items__container_selected]: isEndEditing || isStartEditing,
                        })}
                    >

                        <div className={classes.items__item}>
                            {name}
                        </div>

                        <div
                            className={cn(classes.items__item, {
                                [classes.items__item_active]: isStartEditing,
                                [classes.items__item_s]: !isStartEditing,
                            })}
                            onClick={() => setStartEditing(true)}
                        >
                            {
                                isStartEditing ? (
                                    <CalendarWithTime
                                        currentDate={new Date(dateStart || dateEnd || new Date())}
                                        autoFocus
                                        onChangeCalendar={(date) => {
                                            setBtiParameters(
                                                getTransformedParameters(
                                                    parametersArray,
                                                    name,
                                                    date.toLocaleDateString('ru-Ru').split('.').reverse().join('-'),
                                                    dateEnd,
                                                ),
                                            );
                                            setStartEditing(false);
                                        }}
                                        onCloseCalendar={() => setStartEditing(false)}
                                        // currentDate={new Date(dateStart ?? new Date())}
                                    />
                                ) : (
                                    <>
                                        {
                                            dateStart ? (
                                                <>
                                                    {formatDate(dateStart)}
                                                </>
                                            ) : 'Дата начала'
                                        }
                                    </>
                                )
                            }
                        </div>

                        <div
                            className={cn(classes.items__item, {
                                [classes.items__item_s]: !isEndEditing,
                                [classes.items__item_active]: isEndEditing,
                            })}
                            onClick={() => setEndEditing(true)}
                        >
                            {
                                isEndEditing ? (
                                    <CalendarWithTime
                                        currentDate={new Date(dateEnd || dateStart || new Date())}
                                        autoFocus
                                        onChangeCalendar={(date) => {
                                            setBtiParameters(
                                                getTransformedParameters(
                                                    parametersArray,
                                                    name,
                                                    dateStart,
                                                    date.toLocaleDateString('ru-Ru').split('.').reverse().join('-'),
                                                ),
                                            );
                                        }}
                                        onCloseCalendar={() => setEndEditing(false)}
                                        // currentDate={new Date(dateStart ?? new Date())}
                                    />
                                ) : (
                                    <>
                                        {
                                            dateEnd ? (
                                                <>
                                                    {formatDate(dateEnd)}
                                                </>
                                            ) : 'Дата завершения'
                                        }
                                    </>
                                )
                            }
                        </div>

                    </div>
                )
            }
        </>
    );
}

export function getTransformedParameters(
    parameters: ParametersTransform[],
    paramName: string,
    start?: string,
    end?: string,
    isModuleAssessActive?: boolean,
) {
    return parameters.map((parameter) => {
        if (parameter.name === paramName) {
            return {
                ...parameter,
                start,
                end,
                isModuleActive: isModuleAssessActive,
            };
        }
        return parameter;
    });
}
