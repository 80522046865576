import React from 'react';

import { HatIcon } from '@common/svg';

import classes from './ModuleHeader.module.scss';

import {
    Module,
} from '../../subSpaceTypes';

interface Props {
    currentModule: Module;
}

export function ModuleHeader({ currentModule }: Props):JSX.Element {
    return (
        <div className={classes.moduleHeader}>
            <div className={classes.moduleHeader__iconContainer}>
                <HatIcon />
            </div>
            <div className={classes.moduleHeader__title}>
                {currentModule.name}
            </div>
        </div>
    );
}
