import React from 'react';
import { observer } from 'mobx-react';
import { Title } from '@common';
import styles from './Meeting.module.scss';

import { SkillsSection } from '../Section';
import { moduleStore, dictionaryStore, Meeting } from '../Store';

interface Props {
    meeting: Meeting;
    setRef(ref: React.MutableRefObject<HTMLDivElement | null>): void;
}

export const MeetingSkills = observer(({ meeting, setRef }: Props) => {
    const module = moduleStore.moduleModel;
    const ref = React.useRef<HTMLDivElement | null>(null);
    React.useEffect(() => setRef(ref), []);
    return (
        <div className={styles.form__skills} ref={ref}>
            <Title>Образовательные результаты встречи:</Title>
            <br />
            <SkillsSection
                requiredTitle="которые необходимы для попадания на встречу"
                outputTitle="которые дает встреча при прохождении"
                skillTypes={dictionaryStore.moduleSettings.meetingSkillTypes}
                requiredSkills={meeting.prerequisiteSkills ?? []}
                outputSkills={meeting.outputSkills ?? []}
                onChangeRequired={(items, typeID) => module.updateMeetingRequiredSkills({
                    meetingID: meeting.id,
                    newSkills: items.map(item => ({
                        skill: { ...item, fullName: item.name },
                        level: Number(item.selectOption?.name),
                    })),
                    typeID,
                })}
                onChangeOutput={(items, typeID) => module.updateMeetingOutputSkills({
                    meetingID: meeting.id,
                    newSkills: items.map(item => ({
                        skill: { ...item, fullName: item.name },
                        level: Number(item.selectOption?.name),
                    })),
                    typeID,
                })}
            />
        </div>
    );
});
