import React, { useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react';

import { gql, useLazyQuery } from '@apollo/client';
import { SavedEvent, WarningType } from '@admin/NewModule/SavedEvent';
import { ActionButton, ActionTypeOfButton } from '@common/ActionButton';
import { Meeting as MeetingModel, moduleStore, Validator } from '@admin/NewModule/Store';
import { useParams } from 'react-router-dom';
import { MeetingMenu } from './MeetingMenu';
import { MeetingOptions } from './MeetingOptions';
import { MeetingEquipments } from './MeetingEquipments';
import { MeetingSkills } from './MeetingSkills';
import { DaysNextEvent } from './DaysNextEvent';
import { MeetingTeachersRoles } from './MeetingTeachersRoles';

import style from './Meeting.module.scss';

interface RouteParams {
    id: string
    eventId: string
}

interface Props {
    meeting: MeetingModel;
    opened: boolean;

    setOpened(id: string): void;
}

const IS_REMOVABLE_MEETING = gql`
    query isRemovableMeeting($id: String!) {
        isRemovableMeeting(id: $id)
    }
`;

export const Meeting = observer(({ meeting, opened, setOpened }: Props) => {
    const { id: moduleId, eventId } = useParams<RouteParams>();
    const [errorMessage, setErrorMessage] = useState('');

    const module = moduleStore.moduleModel;

    const topRef = useRef<null | HTMLDivElement>(null);
    const scrollToTop = () => topRef.current?.scrollIntoView({ behavior: 'smooth' });

    const [isRemovableMeeting, { data, error }] = useLazyQuery(IS_REMOVABLE_MEETING, {
        variables: {
            id: meeting.id,
        },
    });

    useEffect(() => {
        if (opened) scrollToTop();
    }, [opened]);

    useEffect(() => {
        if (meeting.id === eventId) {
            topRef.current?.scrollIntoView({ behavior: 'auto' });
        }
    }, []);

    useEffect(() => module.updateOrderOfDependentEvaluationPoints(meeting.id), [meeting.order]);

    useEffect(() => {
        if (data?.isRemovableMeeting) {
            module.removeMeeting(meeting.id);
            module.meetingList.updateAllMeetingRoles();
        }
        if (error) {
            setErrorMessage(error?.graphQLErrors[0]?.message);
        }
    }, [data, error]);

    const handleChangeSavedMeeting = () => {
        setOpened(meeting.id);
        module.meetingList.updateAllMeetingRoles();
        scrollToTop();
    };

    const handleChangeNewMeeting = () => {
        setOpened(meeting.id);
        module.meetingList.updateAllMeetingRoles();
        scrollToTop();
    };

    const handleRemoveMeeting = () => {
        isRemovableMeeting();
    };

    const useStateOfRef = () => React.useState<React.MutableRefObject<HTMLDivElement | null>>();
    const [optionsRef, setOptionsRef] = useStateOfRef();
    const [equipmentRef, setEquipmentRef] = useStateOfRef();
    const [teachersRef, setTeachersRef] = useStateOfRef();
    const [intervalsRef, setIntervalsRef] = useStateOfRef();
    const [skillsRef, setSkillsRef] = useStateOfRef();

    return (
        <>
            {!opened ? (
                <div className={style.form__saved} ref={topRef}>
                    <SavedEvent
                        numberOfEvent={meeting.meetingNumber}
                        title="Встреча"
                        topic={meeting.topic ?? ''}
                        handleEditEvent={
                            moduleId
                                ? handleChangeSavedMeeting
                                : handleChangeNewMeeting
                        }
                        valid={Validator.validateMeeting(meeting).valid}
                        warning={
                            ((meeting.maxStudentCount ?? 0) < (module.options.maxStudentCount ?? 0)
                                && meeting.isManyWaves)
                                ? WarningType.Warning
                                : undefined
                        }
                    />
                </div>
            ) : (
                <div className={style.form} ref={topRef}>
                    <MeetingMenu
                        equipmentRef={equipmentRef}
                        optionsRef={optionsRef}
                        teachersRef={teachersRef}
                        intervalsRef={intervalsRef}
                        skillsRef={skillsRef}
                    />
                    <MeetingOptions meeting={meeting} setRef={setOptionsRef} />
                    <MeetingEquipments meeting={meeting} setRef={setEquipmentRef} />
                    <MeetingTeachersRoles meeting={meeting} setRef={setTeachersRef} />
                    <DaysNextEvent
                        updateEvent={module.updateMeeting}
                        event={meeting}
                        setRef={setIntervalsRef}
                    />
                    <MeetingSkills meeting={meeting} setRef={setSkillsRef} />
                    <div className={style.form__buttons}>
                        <ActionButton
                            actionType={ActionTypeOfButton.SECONDARY}
                            className={style.form__buttons_margin_right}
                            onClick={handleRemoveMeeting}
                        >
                            Удалить встречу
                        </ActionButton>
                        <ActionButton
                            onClick={() => {
                                setOpened('');
                                scrollToTop();
                            }}
                        >
                            Сохранить встречу
                        </ActionButton>
                    </div>
                    <p className={style.form__error}>
                        {errorMessage}
                    </p>
                </div>
            )}
        </>
    );
});
