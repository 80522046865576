import React from 'react';

import { Meeting } from '@admin/ScheduleGenerationPage/types';
import { MeetingEvent } from './MeetingEvent';

interface Props {
    status: string;
    meeting: Meeting;
    moduleId: string;
    waveIds: string[];
    meetingInstanceId: string;
    date: string;
}

export const MeetingEventApollo = ({
    status,
    meeting,
    moduleId,
    waveIds,
    meetingInstanceId,
    date,
}: Props) => (
    <>
        <MeetingEvent
            status={status}
            meeting={meeting}
            moduleId={moduleId}
            waveIds={waveIds}
            meetingInstanceId={meetingInstanceId}
            date={date}
        />
    </>
);
