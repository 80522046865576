import React from 'react';

import { Select } from '@common/Select';
import { ActionButton } from '@common/ActionButton';
import { BorderCrossIcon } from '@common/svg';

import classes from './CreateInvitationForm.module.scss';
import { GetSubspacesForInviteLink_ownSubspaces } from '../apollo-types';

interface CreateInvitationProps {
    subspaces: GetSubspacesForInviteLink_ownSubspaces[];
    selectedSubspace: GetSubspacesForInviteLink_ownSubspaces | undefined;
    setSelectedSubspace:
    React.Dispatch<React.SetStateAction<GetSubspacesForInviteLink_ownSubspaces | undefined>>;
    inviteLink: string;
    isLinkCopied: boolean;
    alerts: JSX.Element[];
    onCopyToClipboard(): Promise<void>;
    onCreateInviteClick(): void;
    onClose(): void;
}

export function CreateInvitationForm({
    subspaces,
    selectedSubspace,
    inviteLink,
    isLinkCopied,
    alerts,
    onCopyToClipboard,
    onCreateInviteClick,
    setSelectedSubspace,
    onClose,
}: CreateInvitationProps) {
    return (
        <div className={classes['create-invitation-form']}>
            <BorderCrossIcon
                className={classes['create-invitation-form__close-button']}
                size={32}
                handler={onClose}
            />
            <div className={classes['create-invitation-form__title']}>
                Создание приглашения для внешнего Владельца модуля
            </div>
            <div className={classes['create-invitation-form__label']}>
                Подпространство
            </div>
            <Select
                className={classes['create-invitation-form__subspace']}
                options={subspaces}
                formatOption={item => item.name}
                onChange={item => setSelectedSubspace(item)}
                selectedOption={selectedSubspace}
            />
            <div className={classes['create-invitation-form__label']}>
                Ссылка-приглашение для внешнего Владельца модуля
            </div>
            <div className={classes['create-invitation-form__link-container']}>
                <div className={classes['create-invitation-form__link']}>
                    {inviteLink}
                </div>
                <div
                    className={classes['create-invitation-form__copy-button']}
                    onClick={onCopyToClipboard}
                >
                    <div className={classes['copy-button__item']} />
                    <div className={classes['copy-button__item']} />
                    {isLinkCopied && (
                        <div className={classes['copy-button__notification']}>
                            Copied!
                        </div>
                    )}
                </div>
            </div>
            <ActionButton
                className={classes['create-invitation-form__button']}
                onClick={onCreateInviteClick}
            >
                Создать приглашение
            </ActionButton>
            {alerts}
        </div>
    );
}
