import React from 'react';
import { observer } from 'mobx-react';
import cn from 'classnames';

import { NotVotedStudent, StudentRating } from '@admin/EducationPeriodStatistic/EducationPeriodStatisticInterfaces';

import { educationPeriodStatisticStore } from '@admin/EducationPeriodStatistic/EducationPeriodStatisticStore';
import { getWordEnding } from '@admin/EducationPeriodStatistic/EducationPeriodStatistic';
import classes from './notVotedAccordionItems.module.scss';

interface Props {
    studentsWithRating: NotVotedStudent[] | null;
}

export const NotVotedAccordionItems = observer((
    {
        studentsWithRating,
    }: Props,
): JSX.Element => (
    <div className={classes.notVotedAccordionItems}>

        <div className={classes.notVotedAccordionItems__items}>

            <div
                className={
                    cn(classes.notVotedAccordionItems__item,
                        classes.notVotedAccordionItems__item_head)
                }
            >

                <div className={classes.notVotedAccordionItems__title_head}>
                    {studentsWithRating?.length}
                    {' '}
                    {
                        getWordEnding(
                            studentsWithRating?.length ?? 0,
                            {
                                one: 'человек',
                                oneTwoFive: 'человека',
                                tenToTwenty: 'человек',
                            },
                        )
                    }
                </div>

                <div className={classes.notVotedAccordionItems__rating_head}>
                    Рейтинг
                </div>

            </div>

            <div className={classes.notVotedAccordionItems__scroll}>
                {
                    studentsWithRating && studentsWithRating.map((student) => (
                        <div
                            className={classes.notVotedAccordionItems__item}
                            key={student.id}
                        >

                            <div className={classes.notVotedAccordionItems__title}>
                                {student.user.lastName} {student.user.firstName}
                                {' '}
                                {student.user.patronymic}
                                {' '}
                                { student.user.id.slice(0, 4) }
                            </div>

                            <div className={classes.notVotedAccordionItems__rating}>
                                {
                                    getStudentRating(
                                        student.id,
                                        educationPeriodStatisticStore.studentsRatings,
                                    )
                                }
                            </div>

                        </div>
                    ))
                }
            </div>

        </div>

    </div>
));

function getStudentRating(studentId: string, studentsRatings: StudentRating[]) {
    if (!studentsRatings.length) return '-';
    const newStudentRating = studentsRatings.find(
        (studentRating) => studentRating.student.id === studentId,
    )?.value;
    return newStudentRating || newStudentRating === 0 ? newStudentRating : '-';
}
