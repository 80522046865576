import React from 'react';

interface Props {
    handler?: () => void;
    width?: number;
    height?: number;
    className?: string;
}

export function SortDownIcon({
    width = 80,
    height = 80,
    className,
    handler,
}: Props): JSX.Element {
    return (
        <div className={className} style={{ height: `${height}px`, width: `${width}px` }} onClick={handler}>
            <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M5.402 21.3a.888.888 0 0 1-.6-.265L1.766 18a.9.9 0 1 1 1.272-1.2l2.4 2.4 2.4-2.4a.9.9 0 1 1 1.2 1.2l-3.036 3.036a.888.888 0 0 1-.6.264Z" fill="url(#a)" />
                <path d="M5.4 21.3a.912.912 0 0 1-.9-.9V3.6a.9.9 0 0 1 1.8 0v16.8a.912.912 0 0 1-.9.9Z" fill="url(#b)" />
                <path d="M21.602 7.5h-9.6a.9.9 0 1 1 0-1.8h9.6a.9.9 0 0 1 0 1.8Z" fill="url(#c)" />
                <path d="M16.802 14.902h-4.8a.9.9 0 0 1 0-1.8h4.8a.9.9 0 1 1 0 1.8Z" fill="url(#d)" />
                <path d="M14.402 18.3h-2.4a.9.9 0 1 1 0-1.8h2.4a.9.9 0 1 1 0 1.8Z" fill="url(#e)" />
                <path d="M19.202 11.1h-7.2a.9.9 0 1 1 0-1.8h7.2a.9.9 0 0 1 0 1.8Z" fill="url(#f)" />
                <defs>
                    <linearGradient id="a" x1="10.142" y1="18.508" x2="2.848" y2="23.163" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#0B42DC" />
                        <stop offset=".042" stopColor="#0B42DC" />
                        <stop offset=".13" stopColor="#0B42DC" />
                        <stop offset=".734" stopColor="#57B2C6" />
                        <stop offset=".932" stopColor="#EED660" />
                    </linearGradient>
                    <linearGradient id="b" x1="6.43" y1="10.94" x2="4.179" y2="11.025" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#0B42DC" />
                        <stop offset=".042" stopColor="#0B42DC" />
                        <stop offset=".13" stopColor="#0B42DC" />
                        <stop offset=".734" stopColor="#57B2C6" />
                        <stop offset=".932" stopColor="#EED660" />
                    </linearGradient>
                    <linearGradient id="c" x1="23.326" y1="6.497" x2="21.318" y2="11.461" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#0B42DC" />
                        <stop offset=".042" stopColor="#0B42DC" />
                        <stop offset=".13" stopColor="#0B42DC" />
                        <stop offset=".734" stopColor="#57B2C6" />
                        <stop offset=".932" stopColor="#EED660" />
                    </linearGradient>
                    <linearGradient id="d" x1="18.179" y1="13.899" x2="15.467" y2="17.78" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#0B42DC" />
                        <stop offset=".042" stopColor="#0B42DC" />
                        <stop offset=".13" stopColor="#0B42DC" />
                        <stop offset=".734" stopColor="#57B2C6" />
                        <stop offset=".932" stopColor="#EED660" />
                    </linearGradient>
                    <linearGradient id="e" x1="15.605" y1="17.297" x2="12.73" y2="19.917" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#0B42DC" />
                        <stop offset=".042" stopColor="#0B42DC" />
                        <stop offset=".13" stopColor="#0B42DC" />
                        <stop offset=".734" stopColor="#57B2C6" />
                        <stop offset=".932" stopColor="#EED660" />
                    </linearGradient>
                    <linearGradient id="f" x1="20.752" y1="10.098" x2="18.408" y2="14.673" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#0B42DC" />
                        <stop offset=".042" stopColor="#0B42DC" />
                        <stop offset=".13" stopColor="#0B42DC" />
                        <stop offset=".734" stopColor="#57B2C6" />
                        <stop offset=".932" stopColor="#EED660" />
                    </linearGradient>
                </defs>
            </svg>
        </div>
    );
}
