import React from 'react';
import { observer } from 'mobx-react';
import cn from 'classnames';

import { CheckboxRedesign } from '@common/CheckboxRedesign';
import {
    ApolloPromise,
    GroupedWavesWithSelectedStudents, IntervalAdditionalInfo, RefetchIsValidStudentWaveDistribution,
    StudentWithAdditionalFields,
    WaveWithSelectedStudents,
} from '@admin/WaveSelectionPage/Interfaces';
import { UpdateStudentWaveInput, Waves_waves_module } from '@admin/WaveSelectionPage/graphql-types';
import { waveSelectionStore } from '@admin/WaveSelectionPage/WaveSelectionStore';

import { waveSelectionDataStore } from '@admin/WaveSelectionPage/WaveSelectionDataStore';
import { checkIfStudentIsUnique, getStudentsRemovedCurrentStudent } from '@admin/WaveSelectionPage/WaveSelectionPage';
import { getStudentRating } from '@admin/ModuleSelectionPage/ModuleSelectionPage';
import { MinInterval } from '@admin/WaveSelectionPage/MinInterval';
import classes from '../WaveStudentItem/WaveStudentItem.module.scss';

interface Props {
    disabled: boolean;
    order: number;
    isIntervalInvalid: boolean;
    intervalAdditionalInfo: IntervalAdditionalInfo;
    currentStudent: StudentWithAdditionalFields;
    currentWave: WaveWithSelectedStudents,
    currentModule: Waves_waves_module;
    updateStudentWave(input: UpdateStudentWaveInput): ApolloPromise;
    refetchIsValidStudentWaveDistribution: RefetchIsValidStudentWaveDistribution;
}

export const AdditionalWaveStudentItem = observer((
    {
        disabled,
        order,
        isIntervalInvalid,
        intervalAdditionalInfo,
        currentStudent,
        currentWave,
        currentModule,
        updateStudentWave,
        refetchIsValidStudentWaveDistribution,
    } : Props,
): JSX.Element => {
    const {
        forkId,
        baseTimeIntervalInstanceId,
        isNotStudentChoice,
    } = waveSelectionDataStore.getURLParameters();

    const {
        user,
    } = currentStudent;

    const studentRating = getStudentRating(
        user.studentId ?? '',
        waveSelectionStore.getStudentsWithRatings(),
    );

    const isStudentUnique = checkIfStudentIsUnique(
        currentStudent,
        waveSelectionStore.getNotDistribStudents(),
        waveSelectionStore.getModuleWavesWithSelectedStudents(),
    );

    const studentInfo = `${user.lastName} ${user.firstName} ${user.patronymic ?? ''} ${isStudentUnique ? '' : user.studentId}`;
    const studentInfoWithOrder = `${order} ${user.lastName} ${user.firstName} ${user.patronymic ?? ''} ${isStudentUnique ? '' : user.studentId}`;

    const isIntervalVisible = order === currentModule.minStudentCount
        && isIntervalInvalid;

    const isStudentFromNotDistributed = waveSelectionStore.getNotDistribStudents()
        .some((student) => student.user.studentId === currentStudent.user.studentId);

    const currentStudentInSelected = waveSelectionStore.getWaveSelectedStudents()
        .find((student) => student.user.studentId === currentStudent.user.studentId);

    const currentStudentPreviousWaveId = waveSelectionStore.getWaveSelectedStudents()
        .find((student) => student.user.studentId === currentStudent.user.studentId)?.currentWaveId;

    const handleChange = () => {
        const notDistributedStudentsRemovedCurrentStudent = getStudentsRemovedCurrentStudent(
            user.studentId ?? '',
            waveSelectionStore.getNotDistribStudents(),
        );

        const waveSelectedStudentsRemovedCurrentStudent = getStudentsRemovedCurrentStudent(
            user.studentId ?? '',
            waveSelectionStore.getWaveSelectedStudents(),
        );

        updateStudentWave(
            {
                forkId: isNotStudentChoice ? null : forkId,
                baseTimeIntervalInstanceId,
                studentId: currentStudent.user.studentId ?? '',
                waveId: currentWave.id,
                previousWaveId: currentStudentPreviousWaveId,
            },
        )
            .then((result) => {
                if (result && !result.errors) {
                    waveSelectionStore.setModuleWavesWithSelectedStudents(
                        getModulesAddedCurrentStudentToCurrentWave(
                            currentWave.id,
                            currentModule.id,
                            currentStudent,
                            waveSelectionStore.getModuleWavesWithSelectedStudents(),
                        ),
                    );

                    waveSelectionStore.setNotDistribStudents(
                        notDistributedStudentsRemovedCurrentStudent,
                    );

                    waveSelectionStore.setIsRequiredStudentMoved(false);

                    waveSelectionStore.setActiveCurrentModuleId('');

                    waveSelectionStore.setWaveSelectedStudents(
                        waveSelectedStudentsRemovedCurrentStudent,
                    );

                    refetchIsValidStudentWaveDistribution()
                        .then((refetchResult) => {
                            if (refetchResult && !refetchResult.errors) {
                                waveSelectionDataStore
                                    .setIsValidStudentWaveDistribution(
                                        refetchResult.data.isValidStudentsWaveDistribution,
                                    );
                            }
                        });
                }
            });
    };
    return (
        <>
            <div
                className={cn(classes.item, {
                    [classes.item_borderTop]: order === 1,
                    [classes.item_selected]: currentStudent.wasMoved
                    || currentStudent.isSelected,
                    [classes.item_fromWaves]: currentStudentInSelected,
                    [classes.item_fromNotDistributed]:
                    isStudentFromNotDistributed && !currentStudentInSelected,
                })}
            >
                {
                    disabled ? (
                        <div
                            className={cn(classes.item__static, classes.item__static_red)}
                        >

                            <div
                                className={cn(classes.item__name, classes.noOpacity)}
                            >
                                {
                                    studentInfoWithOrder
                                }
                            </div>

                            <div className={classes.item__rating}>
                                {
                                    studentRating
                                }
                            </div>

                        </div>
                    ) : (
                        <>
                            <div
                                className={cn(classes.item__active, classes.displayBlock, {
                                    [classes.borderBottomRed]: isIntervalVisible,
                                })}
                            >
                                <CheckboxRedesign
                                    id={`${currentStudent.user.id}${currentWave.id}_${order}_${currentStudent.user.studentId}`}
                                    handleChangeCheckbox={() => handleChange()}
                                    gap="7px"
                                >
                                    <div className={classes.item__label}>

                                        <div
                                            className={cn(classes.item__name, classes.noOpacity)}
                                        >
                                            {
                                                studentInfo
                                            }
                                        </div>

                                        <div className={classes.item__rating}>
                                            {
                                                studentRating
                                            }
                                        </div>

                                    </div>
                                </CheckboxRedesign>
                            </div>
                        </>
                    )
                }

            </div>
            <MinInterval
                interval={currentModule.minStudentCount}
                isRed
                isVisible={isIntervalVisible}
                wavesInfo={intervalAdditionalInfo.wavesInfo}
                studentInfo={intervalAdditionalInfo.studentInfo}
            />
        </>
    );
});

function getModulesAddedCurrentStudentToCurrentWave(
    currentWaveId: string,
    currentModuleId: string,
    currentStudent: StudentWithAdditionalFields,
    modules: GroupedWavesWithSelectedStudents[],
) {
    return modules.map((module) => (
        {
            ...module,
            waves: module.waves.map((wave) => {
                if (wave.id !== currentWaveId) {
                    return {
                        ...wave,
                        students:
                        checkIfCurrentStudentSelectedInStudents(
                            currentStudent.user.studentId ?? '',
                            wave.students,
                        ) ? removeCurrentStudentFromStudents(
                                currentStudent.user.studentId ?? '',
                                wave.students,
                            ) : wave.students,
                        additionalStudents: wave.additionalStudents.filter(
                            (student) => student.user.studentId !== currentStudent.user.studentId,
                        ),
                    };
                }

                return {
                    ...wave,
                    students: [...wave.students, {
                        ...currentStudent,
                        wasMoved: true,
                    }],
                    additionalStudents: wave.additionalStudents.filter(
                        (student) => student.user.studentId !== currentStudent.user.studentId,
                    ),
                };
            }),
        }
    ));
}

function checkIfCurrentStudentSelectedInStudents(
    currentStudentId: string,
    students: StudentWithAdditionalFields[],
) {
    return students.some(
        (student) => student.user.studentId === currentStudentId && student.isSelected,
    );
}

function removeCurrentStudentFromStudents(
    currentStudentId: string,
    students: StudentWithAdditionalFields[],
) {
    return students.filter((student) => student.user.studentId !== currentStudentId);
}
