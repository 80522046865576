import React from 'react';
import { observer } from 'mobx-react';
import { useQuery } from '@apollo/client';
import { Alert } from '@common/Alert';
import { AssignmentsPage } from './AssignmentsPage';
import UserView from '../../store/UserView';
import { GET_ASSIGNMENTS, GET_STUDENT_BTI } from './queries';
import { GetStudentActiveBTI, GetStudentActiveBTIVariables, GetStudentAssignments } from './interfaces';
import { assignmentsStore } from './store/AssignmentsStore';

export const AssignmentsApollo = observer(() => {
    useQuery<GetStudentActiveBTI, GetStudentActiveBTIVariables>(
        GET_STUDENT_BTI,
        {
            variables: { id: UserView.studentId! },
            onCompleted: onStudentActiveBTIQueryComplete,
        },
    );

    function onStudentActiveBTIQueryComplete(studentData: GetStudentActiveBTI) {
        if (studentData.student.activeBaseTimeIntervalInstance) {
            const activeBTI = studentData.student.activeBaseTimeIntervalInstance
                .baseTimeIntervalInstance.id;

            assignmentsStore.setActiveBaseTimeIntervalInstanseId(activeBTI);
        }
    }

    const assignmentInstanceStatus = assignmentsStore.getAssignmentStatus();

    const { data, loading, error } = useQuery<GetStudentAssignments>(
        GET_ASSIGNMENTS,
        {
            variables: {
                assignmentInstancesInput: {
                    // studentId: UserView.studentId ?? '',
                    isCompleted: assignmentInstanceStatus === 'actual'
                        ? assignmentsStore.isCompleted
                        : undefined,
                    baseTimeIntervalInstanceId: assignmentsStore
                        .getActiveBaseTimeIntervalInstanseId(),
                    assignmentInstanceStatus,
                    orderBy: assignmentsStore.getAssignmentOrder(),
                    moduleId: assignmentsStore.getFilterModuleId(),
                },
            },
            skip: !assignmentsStore.getActiveBaseTimeIntervalInstanseId(),
            fetchPolicy: 'network-only',
        },
    );

    return (
        <>
            {error && <Alert message={error.message} />}

            <AssignmentsPage
                assignmentsInstances={data?.filteredStudentAssignmentInstances}
                isLoading={loading}
            />
        </>
    );
});
