import React, { useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import { Alert } from '@common/Alert';
import { Fork } from '../subSpaceTypes';
import { DiagramFork } from './DiagramFork';

const GET_SLOT_DIAGRAM = gql`
    query slotDiagram($id: String!) {
        subspace(id: $id) {
            id
            slotDiagram {
      slots {
        id
        spaceBaseTimeIntervalId
        row
        column
        nextSlots {
          id
          spaceBaseTimeIntervalId
          row
          column
          prerequisiteSkills {
            skill {
              id
              name
              typeId
            }
            level
          }
          outputSkills {
            skill {
              id
              name
              typeId
            }
            level
          }
        }
        nextForks {
          id
          spaceBaseTimeIntervalId
          row
          column
        }
        nextGroupSlots {
          id
          spaceBaseTimeIntervalId
          row
          column
        }
        module {
          id
          name
        }
        prerequisiteSkills {
          skill {
            id
            name
            typeId
          }
          level
        }
        outputSkills {
          skill {
            id
            name
            typeId
          }
          level
        }
      }
      forks {
        id
        spaceBaseTimeIntervalId
        setting {
          distributionType
          moduleSelectionCount
          overbooking
          studentPickingType
          transitionalEmptyModule
          transitionalUnderfilledModule
          studentPriorityCount
          distributionModule {
            id
            name
          }
        }
        row
        column
        nextSlots {
          id
          spaceBaseTimeIntervalId
          row
          column
          prerequisiteSkills {
            skill {
              id
              name
              typeId
            }
            level
          }
          outputSkills {
            skill {
              id
              name
              typeId
            }
            level
          }
        }
        nextForks {
          id
          spaceBaseTimeIntervalId
          row
          column
        }
        nextGroupSlots {
          id
          spaceBaseTimeIntervalId
          row
          column
        }
      }
      groupSlots {
        id
        spaceBaseTimeIntervalId
        row
        column
        nextSlots {
          id
          spaceBaseTimeIntervalId
          row
          column
          prerequisiteSkills {
            skill {
              id
              name
              typeId
            }
            level
          }
          outputSkills {
            skill {
              id
              name
              typeId
            }
            level
          }
        }
        nextForks {
          id
          spaceBaseTimeIntervalId
          row
          column
        }
        nextGroupSlots {
          id
          spaceBaseTimeIntervalId
          row
          column
        }
      }
    }
            type {
              subspaceTypeLayouts {
                spaceBaseTimeInterval {
                  id
                  order
                  minCreditCount
                  maxCreditCount
                  space {
                    baseTimeIntervalType {
                      name
                    }
                  }
                }
              }
            }
     }
  }
`;

const UPDATE_FORK_SETTING = gql`
    mutation updateForkSetting($updateForkSettingInput: UpdateForkSettingInput!) {
        updateForkSetting(updateForkSettingInput: $updateForkSettingInput) {
          id
          spaceBaseTimeIntervalId
          setting {
            distributionType
            moduleSelectionCount
            overbooking
            studentPickingType
            transitionalEmptyModule
            transitionalUnderfilledModule
            distributionModule {
              id
              name
            }
            studentPriorityCount
          }
        }
    }
`;

interface Props {
    subspaceId: string
    fork: Fork,
}

export const DiagramForkApollo = ({
    subspaceId,
    fork,
}: Props): JSX.Element => {
    const [errors, setErrors] = useState(undefined);
    const [updateForkSetting, { error }] = useMutation(UPDATE_FORK_SETTING, {
        refetchQueries: [
            {
                query: GET_SLOT_DIAGRAM,
                variables: { id: subspaceId },
            },
        ],
        onError: (updateError: any) => {
            setErrors(updateError.message);
        },
    });

    if (error) return <Alert message={errors} time={5000} />;

    return (
        <DiagramFork
            fork={fork}
            updateForkSetting={(
                updateForkSettingInput,
            ) => updateForkSetting(
                {
                    variables: { updateForkSettingInput },
                },
            )
            }
        />
    );
};
