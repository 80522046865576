interface Dependency {
    name: string;
    typeId: string;
}

export const checkChild = (skills: any, id: string) => {
    let index: number = 0;
    while (index < skills.length && skills[index].parentId !== id) {
        index += 1;
    }

    if (index < skills.length) {
        return { hasChild: true, skillChild: skills[index] };
    }
    return { hasChild: false, skillChild: undefined };
};

export const getSkillTypeById = (skillTypes: any, id: string) => {
    let index: number = 0;
    while (index < skillTypes.length && skillTypes[index].id !== id) {
        index += 1;
    }

    if (index < skillTypes.length) {
        return { skillType: skillTypes[index] };
    }
    return { skillType: undefined };
};

export const getDependenciesName = (skills: any, skillsId: String[]) => {
    const names: Dependency[] = [];
    let index = 0;
    while (index < skills.length) {
        if (skillsId.includes(skills[index].id)) {
            names.push({ name: skills[index].name.genitivePlural, typeId: skills[index].id });
        }
        index += 1;
    }
    return names;
};
