import { makeAutoObservable } from 'mobx';
import { nanoid } from 'nanoid';

export interface SkillDependency {
    id: string;
    name: string;
    typeId: string;
}

export interface Skill {
    id: string;
    name: string;
    typeId: string;
    valid?: boolean;
    maxLevel?: number;
    children?: Skill[];
    dependencies?: SkillDependency[];
}

class NestedSkill {
    skillId = nanoid();

    skills: Skill = {
        id: this.skillId,
        name: '',
        typeId: '4',
        children: [{
            id: nanoid(),
            name: '',
            typeId: '5',
        }],
        dependencies: [],
    };

    rootId: string = '';

    notValid = new Map();

    constructor() {
        makeAutoObservable(this);
    }

    clear() {
        const childId = nanoid();
        this.skills = {
            id: nanoid(),
            name: '',
            typeId: '4',
            children: [{
                id: childId,
                name: '',
                typeId: '5',
            }],
            dependencies: [],
        };
        this.rootId = this.skills.id;
        this.notValid.set(this.skills.id, false);
        this.notValid.set(childId, false);
    }

    deleteNext(skillId: string, depId: string) {
        const skill = this.find(skillId);
        skill?.dependencies?.forEach((item: any, index) => (
            item.id === depId
                ? skill.dependencies?.splice(index, 1)
                : null
        ));
    }

    isUniqueValidator(skillsList: any, skill: Skill) {
        let index = 0;
        while (index < skillsList.length) {
            if (this.ifSameName(skillsList[index], skill.name, skill.id)) {
                return false;
            }
            index += 1;
        }
        if (this.ifSameName(skill, skill.name, skill.id)) {
            return false;
        }
        if (skill.children) {
            index = 0;
            while (index < skill.children?.length) {
                if (!this.isUniqueValidator(skillsList, skill.children[index])) {
                    return false;
                }
                index += 1;
            }
            return true;
        }
        return true;
    }

    ifSameName(skill: Skill, name: string, id: string) {
        if (skill.name === name && skill.id !== id) {
            return true;
        }
        if (skill.children) {
            let index = 0;
            while (index < skill.children?.length) {
                if (this.ifSameName(skill.children[index], name, id)) {
                    return true;
                }
                index += 1;
            }
            return false;
        }
        return false;
    }

    isEmptyNameValidator(skill: Skill) {
        if (skill.name === '') {
            this.notValid.set(skill.id, true);
            return false;
        }
        if (skill.children) {
            let index = 0;
            while (index < skill.children?.length) {
                if (!this.isEmptyNameValidator(skill.children[index])) {
                    return false;
                }
                index += 1;
            }
            return true;
        }
        this.notValid.set(skill.id, false);
        return true;
    }

    full(skill: any) {
        this.skills = skill;
        this.notValid.set(skill.id, false);
        if (skill.dependencies) {
            let indexTwo = 0;
            while (indexTwo < skill.dependencies?.length) {
                this.addDependency(skill.id, skill.dependencies[indexTwo]);
                indexTwo += 1;
            }
        }
    }

    addSkill(id: string, data: Skill) {
        const skill = this.find(id);

        if (!skill) {
            throw new Error(`Skill ${id} not found`);
        }

        skill.children = skill?.children ? [...skill.children, data] : [data];
        this.notValid.set(data.id, false);
    }

    addDependency(id: string, data: SkillDependency) {
        const skill = this.find(id);
        if (!skill) {
            throw new Error(`Skill ${id} not found`);
        }

        skill.dependencies = skill?.dependencies ? [...skill.dependencies, data] : [data];
    }

    deleteDependency(skillId: string, depId: string) {
        const skill = this.find(skillId);
        skill?.dependencies?.forEach((item: any, index) => (
            item.id === depId
                ? skill.dependencies?.splice(index, 1)
                : null
        ));
    }

    update(id: string, data: Partial<Skill>) {
        const skill = this.find(id);

        if (skill) {
            Object.assign(skill, data);
        }
    }

    find(id: string): Skill | undefined {
        return this.skills.id === id ? this.skills : this.findChild(id, this.skills.children);
    }

    findChild(id: string, children: Skill[] = []): Skill | undefined {
        const result = children.find(item => item.id === id);

        return result ?? this.findChild(id, children.flatMap(item => item.children ?? []));
    }

    removeSkill(id: string, parentId?: string) {
        const parent = parentId ? this.find(parentId) : undefined;

        if (parent) {
            parent.children = parent.children?.filter(skill => skill.id !== id);
        }
    }
}

export default new NestedSkill();
