import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';

import { Loader } from '@common/Loader';
import { Alert } from '@common/Alert';
import { GET_USER_INFO } from '@common/qraphql/queries';
import {
    LOGIN,
    SET_ROLES,
    SET_ADMIN_ROLES,
    UPDATE_TEACHER_WORKLOAD,
    SET_STUDENT_WAVES,
} from '@common/qraphql/mutations';

import { UserCard } from './UserCard';
import { userCardStore } from '../store/userCardStore';
import { editUser } from './editUser';

interface Props {
    edit?: boolean,
}

const loginUser = (id: string, login: any) => {
    login({
        variables: {
            id,
        },
    });
};

export const UserCardApollo = ({ edit }: Props): JSX.Element => {
    const { id } = useParams<{ id: string; }>();
    const history = useHistory();
    const [workLoadError, setWorkLoadError] = useState(undefined);
    // const { data: adminRoles } = useQuery(GET_ADMIN_ROLES, { variables: { userId: id } });
    const { error, loading, data, refetch } = useQuery(GET_USER_INFO, {
        fetchPolicy: 'no-cache',
        variables: {
            id,
        },
    });

    const [
        setAdminRoles, {
            error: errorAdminSet,
            loading: loadingAdminSet,
        },
    ] = useMutation(SET_ADMIN_ROLES);

    const [
        setStudentWaves, {
            data: studentWavesData,
            error: errWavesSet,
            loading: loadWavesSet,
        },
    ] = useMutation(SET_STUDENT_WAVES);

    const [
        setRoles, {
            data: isSetRoles,
            error: errorSet,
            loading: loadingSet,
        },
    ] = useMutation(SET_ROLES);

    const [
        setWorkingInterval, {
            error: errorSetInterval,
            loading: loadingSetInterval,
        },
    ] = useMutation(UPDATE_TEACHER_WORKLOAD, {
        onError: (loadError: any) => setWorkLoadError(loadError.message),
    });

    const newTeacherId = isSetRoles?.setUserRoles?.teacherId;
    const hasTeacherRole = !userCardStore.originalRoles.map(role => role.enabled && role.name).includes('Teacher');
    const hasAddTeacherRole = userCardStore.roles.map(role => role.enabled && role.name).includes('Teacher');

    if (hasTeacherRole && newTeacherId && hasAddTeacherRole) {
        setWorkingInterval({
            variables: {
                updateWorkload: {
                    teacherId: isSetRoles.setUserRoles.teacherId,
                    workingHours: userCardStore.DispatchedWorkingHours.flat() ?? [],
                    nonWorkingHours: userCardStore.nonWorkingHours ?? [],
                },
            },
        });

        userCardStore.resetWorkingIntervals();
    }

    const [
        login, {
            data: dataLogin,
            error: errorLogin,
            loading: loadingLogin,
        },
    ] = useMutation(LOGIN);

    if (dataLogin) {
        localStorage.setItem('token', `Bearer ${dataLogin.login}`);
        history.go(0);
    }

    if (
        loadingSet
        || loadingLogin
        || loadingAdminSet
        || loading
        || loadingSetInterval
        || loadWavesSet
    ) return <Loader />;

    if (errorSet || errorLogin || errorAdminSet || error || errWavesSet) {
        return (
            <>
                `Error! ${
                    errorSet?.message
                    || errorLogin?.message
                    || errorAdminSet?.message
                    || error?.message
                    || errWavesSet}`
            </>
        );
    }

    userCardStore.userId = id;
    userCardStore.originalRoles = data.user.roles;

    const { teacherId, studentId } = data.user;

    userCardStore.resetWorkingIntervals();
    userCardStore.resetStudentsModule();
    userCardStore.updateNonWorkingHours(data.user.teacher?.nonWorkingHours);
    userCardStore.updateStudenModules(data.user?.student?.activeWaves ?? []);
    userCardStore.updateStudentModule(studentWavesData?.module?.id ?? '', studentWavesData?.id ?? '');

    return (
        <>
            <UserCard
                userData={data.user}
                edit={!!edit}
                editUser={async () => {
                    await editUser(
                        id,
                        teacherId,
                        studentId,
                        userCardStore.DispatchedWorkingHours.flat(),
                        setRoles,
                        setAdminRoles,
                        setWorkingInterval,
                        setStudentWaves,
                    );

                    await refetch();
                }}
                loginUser={() => loginUser(id, login)}
            />

            {
                errorSetInterval
                && workLoadError
                && <Alert message={errorSetInterval.message} time={3000} />
            }
        </>
    );
};
