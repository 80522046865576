import React, { useEffect, useState } from 'react';
import { ApolloError } from '@apollo/client';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';

import UserView from '@store/UserView';
import { Card } from '@common/Card';
import {
    CreateBuildingInput,
    TerritorialZone,
    UpdateTerritorialZoneInput,
} from '@admin/UniversityPage/types';
import { TabsOption } from '@common/TabsRedesign/TabsRedesign';
import { HeaderRedesign } from '@common/HeaderRedesign';
import { AuthRoute } from '@common/AuthRoute';
import { GradientTabs } from '@common/Menu/GradientTabs';
import { Loader } from '@common/Loader';
// import { OccupationDashboard } from '@admin/OccupationDashboard';
import { EquipmentPage } from '@admin/EquipmentPage';
import { ConsumableEquipmentPage } from '@admin/ConsumableEquipment';
import { SkillPage } from '@admin/SkillPage';
import { RoomsPageApollo } from '@admin/RoomsPage/RoomsPageApollo';
import { BuildingsErrors } from '@admin/UniversityPage/UniversityPageApollo';
import { Formats } from '@admin/FormatsPage';
import { OnlinePage } from '@admin/OnlinePage';

import { UniversityBuildings } from './UniversityBuildings';
import { UniversityZones } from './UniversityZones';
import { UniversitySpecialDays } from './UniversitySpecialDays';
import { InterfaceSection, skillTypesData_skillTypes, ViewType } from '../../deprecated-graphql-query-types';
import SkillType from '../../store/SkillType';
import classes from './UniversityPage.module.scss';

interface Props {
    universityTimeZone: string;

    territorialZones?: TerritorialZone[];
    territorialZonesLoading: boolean;
    buildingsErrors: BuildingsErrors;
    updateTerritorialZonesError?: ApolloError;

    updateTerritorialZones(zones: UpdateTerritorialZoneInput[]): void;
    createBuilding(building: CreateBuildingInput): void;
    updateBuilding(building: CreateBuildingInput): void;
    removeBuilding(id: string): void;
}

export function UniversityPage({
    universityTimeZone,
    territorialZones = [],
    territorialZonesLoading,
    buildingsErrors,
    updateTerritorialZonesError,
    updateTerritorialZones,
    createBuilding,
    updateBuilding,
    removeBuilding,
}: Props): JSX.Element {
    const [editZonesMode, setEditZonesMode] = useState(false);
    const skillTypes = SkillType.getSkillTypesWithoutParentId();
    const { path } = useRouteMatch();
    const history = useHistory();
    const location = useLocation();

    useEffect(() => {
        if (location.pathname === '/university') {
            history.push(`${path}/main`);
        }

        if (location.pathname === '/university/educational-results') {
            history.push(`${path}/educational-results/literacy/list`);
        }
    }, [location]);

    const handleSetEditZonesMode = (): void => {
        setEditZonesMode(!editZonesMode);
    };

    const [buildingsMode, setBuildingsMode] = useState({
        listBuildingsMode: true,
        editBuildingsMode: false,
        addBuildingsMode: false,
    });

    const { listBuildingsMode, editBuildingsMode, addBuildingsMode } = buildingsMode;

    const handleChangeListMode = (): void => {
        setBuildingsMode({
            listBuildingsMode: true,
            editBuildingsMode: false,
            addBuildingsMode: false,
        });
    };

    const handleChangeEditMode = (): void => {
        setBuildingsMode({
            listBuildingsMode: false,
            editBuildingsMode: true,
            addBuildingsMode: false,
        });
    };

    const handleChangeAddMode = (): void => {
        setBuildingsMode({
            listBuildingsMode: false,
            editBuildingsMode: false,
            addBuildingsMode: true,
        });
    };

    const tabsArray: TabsOption[] = [];

    const tabs = UserView.user?.availableInterfaceSections;

    const addTabs = (tabName: string, tabType: string, wordInLinkToHighlight?: string) => tabsArray
        .push({ title: tabName, link: tabType, wordInLinkToHighlight });

    tabs?.forEach(tab => {
        switch (tab) {
            case InterfaceSection.MainParameters:
                addTabs('Общие параметры', 'main');
                break;
            case InterfaceSection.Equipment:
                addTabs('Оборудование', 'equipment');
                break;
            case InterfaceSection.Rooms:
                addTabs('Помещения', 'room/zone/', 'room');
                break;
            case InterfaceSection.ConsumableEquipment:
                addTabs('Расходники', 'consumable-equipment');
                break;
            case InterfaceSection.Skills:
                addTabs('Образовательные результаты', 'educational-results');
                break;
            case InterfaceSection.MeetingFormats:
                addTabs('Форматы встреч', 'formats');
                break;
            case InterfaceSection.OnlineEducation:
                addTabs('Дистанционное обучение', 'online');
                break;
            default:
                break;
        }
    });

    return (
        <div className={classes.universityPage}>
            <div className={classes.universityPage__header}>
                <HeaderRedesign title="Университет" tabsOptions={tabsArray} />
            </div>
            <AuthRoute exact path={`${path}/main`} requiredUserTypes={[ViewType.Admin]}>
                <>
                    <Card>
                        <div className={classes.universityPage__title}>
                            Территориальные зоны
                        </div>

                        {
                            territorialZonesLoading ? <Loader /> : (
                                <UniversityZones
                                    universityTimeZone={universityTimeZone}
                                    editBuildingsMode={editBuildingsMode}
                                    addBuildingsMode={addBuildingsMode}
                                    editZonesMode={editZonesMode}
                                    territorialZones={territorialZones}
                                    updateTerritorialZonesError={updateTerritorialZonesError}
                                    updateTerritorialZones={updateTerritorialZones}
                                    handleSetEditZonesMode={handleSetEditZonesMode}
                                />
                            )
                        }

                        {
                            territorialZonesLoading ? <Loader /> : (
                                <UniversityBuildings
                                    universityTimeZone={universityTimeZone}
                                    editZonesMode={editZonesMode}
                                    listBuildingsMode={listBuildingsMode}
                                    editBuildingsMode={editBuildingsMode}
                                    addBuildingsMode={addBuildingsMode}
                                    territorialZones={territorialZones}
                                    buildingsErrors={buildingsErrors}
                                    handleChangeListMode={handleChangeListMode}
                                    handleChangeEditMode={handleChangeEditMode}
                                    handleChangeAddMode={handleChangeAddMode}
                                    createBuilding={createBuilding}
                                    updateBuilding={updateBuilding}
                                    removeBuilding={removeBuilding}
                                />
                            )
                        }
                    </Card>

                    <UniversitySpecialDays />
                </>
            </AuthRoute>

            <AuthRoute path={`${path}/equipment`} requiredUserTypes={[ViewType.Admin]}>
                <EquipmentPage />
            </AuthRoute>
            <AuthRoute path={`${path}/room/:id?/zone/:territorialZoneId?`} requiredUserTypes={[ViewType.Admin]}>
                <RoomsPageApollo />
            </AuthRoute>
            <AuthRoute path={`${path}/consumable-equipment`} requiredUserTypes={[ViewType.Admin]}>
                <ConsumableEquipmentPage />
            </AuthRoute>

            <AuthRoute path={`${path}/educational-results`} requiredUserTypes={[ViewType.Admin]}>
                <>
                    <GradientTabs
                        tabsOptions={skillTypes
                            .map(skillType => {
                                const { name } = skillType;
                                const { nominativePlural, english } = name;
                                return [nominativePlural, `${english.toLowerCase()}/list`];
                            })}
                    />

                    {
                        skillTypes.map((skill: skillTypesData_skillTypes) => (
                            <AuthRoute
                                exact={false}
                                key={skill.id}
                                path={`${path}/educational-results/${skill.name.english?.toLowerCase()}`}
                                requiredUserTypes={[ViewType.Admin]}
                            >
                                <SkillPage skillType={skill} />
                            </AuthRoute>
                        ))
                    }
                </>
            </AuthRoute>

            <AuthRoute path={`${path}/formats`} requiredUserTypes={[ViewType.Admin]}>
                <Formats />
            </AuthRoute>

            <AuthRoute path={`${path}/online`} requiredUserTypes={[ViewType.Admin]}>
                <OnlinePage />
            </AuthRoute>

            {/* <AuthRoute path={`${path}/occupation`} requiredUserTypes={[ViewType.Admin]}>
                <OccupationDashboard />
            </AuthRoute> */}

        </div>
    );
}
