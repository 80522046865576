import React from 'react';
import { nanoid } from 'nanoid';
import { observer } from 'mobx-react';

import colors from '../../store/ColorConstans';

interface Props {
    size?: number;
    firstColor?: string;
    secondColor?: string;
    thirdColor?: string;
    className?: string;
    handler?: () => void;
}

export const BorderPlusIcon = observer(({
    size = 26,
    firstColor = colors.firstColor,
    secondColor = colors.secondColor,
    thirdColor = colors.thirdColor,
    className,
    handler,
}: Props): JSX.Element => {
    const id = nanoid();
    return (
        <div className={className} style={{ height: `${size}px`, width: `${size}px`, cursor: 'pointer' }} onClick={handler}>
            <svg width={size} height={size} viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M14 8.2C14 7.64772 13.5523 7.2 13 7.2C12.4477 7.2 12 7.64772 12 8.2H14ZM12 17.8C12 18.3523 12.4477 18.8 13 18.8C13.5523 18.8 14 18.3523 14 17.8H12ZM17.8 14C18.3523 14 18.8 13.5523 18.8 13C18.8 12.4477 18.3523 12 17.8 12V14ZM8.2 12C7.64772 12 7.2 12.4477 7.2 13C7.2 13.5523 7.64772 14 8.2 14V12ZM12 8.2V13H14V8.2H12ZM12 13V17.8H14V13H12ZM13 14H17.8V12H13V14ZM13 12H8.2V14H13V12ZM24 13C24 19.0751 19.0751 24 13 24V26C20.1797 26 26 20.1797 26 13H24ZM13 24C6.92487 24 2 19.0751 2 13H0C0 20.1797 5.8203 26 13 26V24ZM2 13C2 6.92487 6.92487 2 13 2V0C5.8203 0 0 5.8203 0 13H2ZM13 2C19.0751 2 24 6.92487 24 13H26C26 5.8203 20.1797 0 13 0V2Z" fill={`url(#${id})`} />
                <defs>
                    <linearGradient id={id} x1="26.7349" y1="11.6329" x2="0.64669" y2="21.8164" gradientUnits="userSpaceOnUse">
                        <stop offset="0.130208" stopColor={firstColor} />
                        <stop offset="0.734375" stopColor={secondColor} />
                        <stop offset="0.932292" stopColor={thirdColor} />
                    </linearGradient>
                </defs>
            </svg>
        </div>
    );
});
