import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import cn from 'classnames';
import { ActionButton, ActionTypeOfButton } from '@common/ActionButton';
import { Alert } from '@common/Alert';
import { BorderCrossIcon } from '@common/svg';

import { EducationPageMinMax } from '../EducationPageMinMax';

import { EducationSpaceTimeMesurement } from './EducationSpaceTimeMesurement';
import { createValidation, generateDefaultSpace } from './EducationSpaceFunctions';

import classes from '../EducationSpacePage.module.scss';

interface Interval {
    id: string;
    minCreditCount: number;
    maxCreditCount: number;
    order: number;
}

interface Space {
    id: string;
    name: string;
    minCreditCount: number;
    maxCreditCount: number;
    timeMesureId: string;
    quantity: number;
    isDifferentIntervals: boolean;
    spaceBaseTimeIntervals: Interval[];
}

interface BaseTimeIntervalType {
    id: string;
    name: string;
}

interface SpaceBaseTimeInterval {
    id: string;
    minCreditCount: number;
    maxCreditCount: number;
    order: number;
    subspaceTypeLayouts: any;
}

interface SpaceFromBack {
    id: string;
    name: string;
    minCreditCount: number;
    maxCreditCount: number;
    baseTimeIntervalType: BaseTimeIntervalType;
    spaceBaseTimeIntervals: SpaceBaseTimeInterval[];
}

interface TimeMesureTypes {
    id: string;
    name: string;
    minBaseTimeInterval: number;
    maxBaseTimeInterval: number;
}

interface Props {
    path: string;
    defaultSpace: SpaceFromBack | undefined;
    tabOptions: string[][];
    isEdit: string;
    timeMesureTypes: TimeMesureTypes[];
    createSpace: (
        id: string,
        name: string,
        minCreditCount: number,
        maxCreditCount: number,
        isDifferentSpaceBaseTimeIntervals: boolean,
        baseTimeIntervalTypeId: string,
        spaceBaseTimeIntervals: Interval[],
    ) => void;
    updateSpace?: (
        id: string,
        name: string,
        minCreditCount: number,
        maxCreditCount: number,
        isDifferentSpaceBaseTimeIntervals: boolean,
        baseTimeIntervalTypeId: string,
        spaceBaseTimeIntervals: Interval[],
    ) => void;
    removeSpace: (
        id: string,
    ) => void;

    setTabOptions(tabOptions: string[][]): void;

    setIsEdit(value: string): void;
}

export const EducationSpace = ({
    path,
    defaultSpace,
    tabOptions,
    setTabOptions,
    createSpace,
    updateSpace,
    removeSpace,
    timeMesureTypes,
    isEdit,
    setIsEdit,
}: Props) => {
    const history = useHistory();
    const [space, setSpace] = useState<Space>({
        ...generateDefaultSpace(defaultSpace, timeMesureTypes),
    });
    const [error, setError] = useState('');
    const [localQuantity, setLocalQuantity] = useState(
        defaultSpace?.spaceBaseTimeIntervals.length
        || 0,
    );
    const onChangeMinMax = (value: number, type: string) => {
        if (type === 'min') {
            setSpace({ ...space, minCreditCount: value });
        } else {
            setSpace({ ...space, maxCreditCount: value });
        }
    };

    const changeItem = (item: Interval) => {
        if (item.minCreditCount && item.maxCreditCount) {
            return item;
        }
        if (item.minCreditCount) {
            return { ...item, min: undefined };
        }
        if (item.maxCreditCount) {
            return { ...item, max: undefined };
        }
        return { ...item, min: undefined, max: undefined };
    };

    const addSpace = () => {
        const newSpace = {
            ...space,
            name: space.name.trim(),
            spaceBaseTimeIntervals: [...space.spaceBaseTimeIntervals.filter((item) => (
                changeItem(item)
            ))],
        };
        const message = createValidation(newSpace, tabOptions, localQuantity);
        setError(message);
        if (message === '') {
            if (defaultSpace) {
                update();
            } else {
                setSpace({
                    ...space,
                    name: space.name.trim(),
                    spaceBaseTimeIntervals: [...space.spaceBaseTimeIntervals.filter((item) => (
                        changeItem(item)
                    ))],
                });
                setTabOptions([[space.name, space.id], ...tabOptions]);
                createSpace(
                    space.id,
                    space.name,
                    space.minCreditCount,
                    space.maxCreditCount,
                    space.isDifferentIntervals,
                    space.timeMesureId,
                    space.spaceBaseTimeIntervals,
                );
                history.push(`${path}/${space.id}`);
            }
        }
    };

    const update = () => {
        updateSpace!(
            space.id,
            space.name,
            space.minCreditCount,
            space.maxCreditCount,
            space.isDifferentIntervals,
            space.timeMesureId,
            space.spaceBaseTimeIntervals,
        );
        const updatedTabs = tabOptions.map(tab => {
            if (tab[1] === space.id) {
                return [space.name, tab[1]];
            }
            return tab;
        });
        setTabOptions(updatedTabs);
        setIsEdit(path);
    };

    const closeSpace = () => {
        setIsEdit(path);
    };

    const deleteSpace = () => {
        if (defaultSpace) {
            removeSpace(space.id);
        } else {
            setSpace({
                ...generateDefaultSpace(undefined, timeMesureTypes),
            });
        }
        setIsEdit(path);
        history.push(`${path}/new`);
    };

    useEffect(() => {
        if (isEdit && isEdit.split('/')[isEdit.split('/').length - 1] !== space.id) {
            setIsEdit('');
        }
    });

    return (
        <div className={classes.educationSpaceForm}>
            <div className={classes.educationSpaceForm__container}>
                <div className={classes.educationSpaceForm__head}>
                    Задать новое образовательное пространство
                    {defaultSpace
                        && (
                            <BorderCrossIcon
                                size={26}
                                handler={() => closeSpace()}
                            />
                        )
                    }
                </div>
                <label className={classes.educationSpaceForm__label}>
                    Название пространства
                    <input
                        className={classes.educationSpaceForm__input}
                        value={space.name}
                        onChange={({
                            currentTarget,
                        }) => setSpace({ ...space, name: currentTarget.value })}
                    />
                </label>
                <EducationPageMinMax
                    labels={['Укажите общее количество ЗЕТ для пространства:']}
                    quantity={1}
                    onChangeMinMax={onChangeMinMax}
                    values={space}
                />
                <EducationSpaceTimeMesurement
                    space={space}
                    setSpace={setSpace}
                    timeMesureTypes={timeMesureTypes}
                    localQuantity={localQuantity}
                    setLocalQuantity={setLocalQuantity}
                />
            </div>
            <div className={classes.educationSubspaceTypeForm__buttonCreate}>
                <ActionButton
                    className={cn(classes.educationButton, {
                        [classes.educationButton__bgButton]: defaultSpace,
                    })}
                    onClick={() => addSpace()}
                >
                    {defaultSpace ? 'Обновить пространство' : 'Создать пространство'}
                </ActionButton>
                {defaultSpace
                    && (
                        <ActionButton
                            actionType={ActionTypeOfButton.SECONDARY}
                            className={classes.educationButton}
                            onClick={() => deleteSpace()}
                        >
                            Удалить пространство
                        </ActionButton>
                    )
                }
            </div>
            {error !== ''
                && <Alert message={error} onTimeout={() => setError('')} />
            }
        </div>
    );
};
