import React, { useState } from 'react';

import { ArrowIcon } from '@common/svg/ArrowIcon';
import { StudentTargetName, useSendMetriks } from '@common/hooks';

import { StudentSkill } from './Skill/StudentSkill';
import { StudentSkillsWhithNest } from './Skill/StudentSkillsWhithNest';
import * as Types from '../profileModels';
import { ViewType } from '../../deprecated-graphql-query-types';

import classes from './StudentProfile.module.scss';

interface SkillType {
    parentId: string;
    id: string;
    name: {
        nominativePlural: string;
    }
    children?: SkillType[];
}

interface SkillTypeWhithChildren extends SkillType {
    children: SkillType[]
}

interface Props {
    skillType: SkillTypeWhithChildren;
    maxSkills: Types.StudentSkill[];
    targetSkills: Types.ModuleSkill[];
    notEvaluatedModuleSkills: Types.ModuleSkill[];
    allStudentSkillsWhithParents: Types.Skill[];
    allModulesSkillWhithParent: Types.Skill[];
}

export function SkillTypeShow({
    skillType,
    maxSkills,
    targetSkills,
    notEvaluatedModuleSkills,
    allStudentSkillsWhithParents,
    allModulesSkillWhithParent,
}: Props) {
    const [isShowSkills, setIsShowskills] = useState(false);

    const { sendYandexMetrika } = useSendMetriks([ViewType.Student]);

    const studentSkilssNoNest = [...maxSkills, ...notEvaluatedModuleSkills]
        .filter((studentSkill) => studentSkill.skill.typeId === skillType.id);
    const studentSkillsWhithNest = getUniqueSkills([
        ...allStudentSkillsWhithParents,
        ...allModulesSkillWhithParent,
    ]).filter(skill => skill.typeId === skillType.id);
    function getUniqueSkills(allStudentsSkills: Types.Skill[]) {
        const parentIds: string[] = [];
        const uniqueParents: Types.Skill[] = [];
        allStudentsSkills.forEach(studentSkill => {
            if (!parentIds.find(parentId => parentId === studentSkill.id)) {
                uniqueParents.push(studentSkill);
                parentIds.push(studentSkill.id);
            }
        });

        return uniqueParents;
    }

    const DOMSkilssNoNest = studentSkilssNoNest.length > 0 ? (
        <div
            key={skillType.id}
            className={classes.student__skill_type}
        >
            <div className={classes.skill__title}>
                {skillType.name.nominativePlural}
            </div>
            {
                getSkillNoNestToShow(studentSkilssNoNest).map((studentSkill) => (
                    <StudentSkill
                        key={studentSkill.skill.id}
                        allModuleSkills={targetSkills}
                        studentSkill={studentSkill}
                        isStudentSkill={isStudentSkill(studentSkill)}
                    />
                ))}
            {(studentSkilssNoNest.length > 5) && (
                <ArrowIcon
                    isOpen={isShowSkills}
                    onClick={() => {
                        setIsShowskills(!isShowSkills);
                        sendYandexMetrika(StudentTargetName.disclosure_block);
                    }}
                />
            )}
        </div>
    ) : <></>;

    const DOMSkillsWhithNest = studentSkillsWhithNest.length > 0 ? (
        <div
            key={skillType.id}
            className={classes.student__skill_type}
        >
            <div className={classes.skill__title}>
                {skillType.name.nominativePlural}
            </div>
            {getSkillWhithNestToShow(studentSkillsWhithNest).map((skill) => (
                <StudentSkillsWhithNest
                    key={skill.id}
                    skill={skill}
                    maxSkills={maxSkills}
                    targetSkills={targetSkills}
                    allStudentSkills={
                        getUniqueSkills(allStudentSkillsWhithParents)
                    }
                    allModulesSkillWhithParent={
                        getUniqueSkills([
                            ...allStudentSkillsWhithParents,
                            ...allModulesSkillWhithParent,
                        ])
                    }
                />
            ))}
            {(studentSkillsWhithNest.length > 5) && (
                <ArrowIcon
                    isOpen={isShowSkills}
                    onClick={() => setIsShowskills(!isShowSkills)}
                />
            )}
        </div>
    ) : <></>;

    function getSkillNoNestToShow(skills: Types.ModuleSkill[]) {
        return skills.slice(0, isShowSkills ? skills.length : 4);
    }
    function getSkillWhithNestToShow(skills: Types.Skill[]) {
        return skills.slice(0, isShowSkills ? skills.length : 7);
    }
    return (skillType.children?.length < 1 ? (
        <>{DOMSkilssNoNest}</>
    ) : (
        <>{DOMSkillsWhithNest}</>
    ));
}

export function isStudentSkill(skill: Types.StudentSkill | Types.ModuleSkill)
    : skill is Types.StudentSkill {
    return 'id' in skill;
}
