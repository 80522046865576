import React, { useState } from 'react';

import { Suggestion } from '@common/SuggestionDeprecated';
import { IconDeprecated } from '@common';

import classes from './EquipmentSuggestion.module.scss';

import { EquipmentSuggestionItem } from './EquipmentSuggestionItem';

import { ByRoom } from '../equipmentTypes';

interface Props {
    selectedRooms: ByRoom[];
    setSelectedRooms: (array: ByRoom[]) => void;
    rooms: ByRoom[];
}

export function EquipmentSuggestion({
    selectedRooms,
    setSelectedRooms,
    rooms,
}: Props): JSX.Element {
    const [roomName, setRoomName] = useState('');
    const roomsNameArray = rooms.map((item: ByRoom) => item.room.title);
    const addedRoomsArray: string[] = selectedRooms
        .map((item: ByRoom) => item.room.title);
    const handlerAddRooms = () => {
        rooms.forEach((item: ByRoom) => {
            if (item.room.title === roomName.trim()) {
                setSelectedRooms([...selectedRooms, item]);
                setRoomName('');
            } else {
                setRoomName('');
            }
        });
    };
    const handlerRemoveRooms = (index: number) => {
        const newSelectedRooms = [...selectedRooms];
        newSelectedRooms.splice(index, 1);
        setSelectedRooms([...newSelectedRooms]);
    };
    return (
        <div className={classes.equipmentSuggestion}>
            <div className={classes.equipmentSuggestion__suggestion}>
                <Suggestion
                    array={roomsNameArray}
                    addedElementArray={addedRoomsArray}
                    stateValue={roomName}
                    setState={setRoomName}
                    cnInput={classes.equipmentSuggestion__input}
                    cnListContainer={classes.equipmentSuggestion__listContainer}
                />
                <IconDeprecated
                    id="yellowPlus"
                    className={classes.equipmentSuggestion__icon}
                    click={handlerAddRooms}
                />
            </div>
            <ul className={classes.equipmentSuggestion__equipmentList}>
                {selectedRooms.map((item: ByRoom, index: number) => (
                    <li
                        className={classes.equipmentSuggestion__equipmentItem}
                        key={item.room.id}
                    >
                        <EquipmentSuggestionItem
                            equipment={item}
                            index={index}
                            handlerRemoveRooms={handlerRemoveRooms}
                        />
                    </li>
                ))}
            </ul>
        </div>
    );
}
