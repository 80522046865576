import React from 'react';
import ReactDOM from 'react-dom';
import {
    ApolloClient,
    ApolloLink,
    ApolloProvider,
    from,
    HttpLink,
    InMemoryCache,
} from '@apollo/client';
import { Provider } from 'mobx-react';
import { BrowserRouter } from 'react-router-dom';

import './index.scss';
import reportWebVitals from './reportWebVitals';
import AppApollo from './AppApollo';

interface MergeObject<T> {
    merge: (_existing: T, incoming: T) => T
}

function getMergeObject<T>(): MergeObject<T> {
    return { merge: (_existing, incoming) => incoming };
}

const httpLink = new HttpLink({ uri: '/graphql' });

const authMiddleware = new ApolloLink((operation, forward) => {
    operation.setContext(({ headers = {} }) => ({
        headers: {
            ...headers,
            'x-authorization': localStorage.getItem('token') ?? '',
        },
    }));

    return forward(operation);
});

const versionAfterware = new ApolloLink(
    (operation, forward) => forward(operation).map(response => {
        const versionKey = 'version';
        const versionHeader = 'version';
        const context = operation.getContext();
        const newVersion = context?.response?.headers?.get(versionHeader);
        const currentVersion = localStorage.getItem(versionKey);
        if (newVersion) {
            if (!currentVersion) {
                localStorage.setItem(versionKey, newVersion);
            } else if (currentVersion !== newVersion) {
                localStorage.setItem(versionKey, newVersion);
                window.location.reload();
            }
        }
        return response;
    }),
);

const client = new ApolloClient({
    cache: new InMemoryCache({
        addTypename: false,
        typePolicies: {
            equipments: {
                queryType: true,
                fields: {
                    equipments: getMergeObject(),
                },
            },
        },
    }),
    link: from([
        authMiddleware,
        versionAfterware,
        httpLink,
    ]),
});

ReactDOM.render(
    <Provider>
        <ApolloProvider client={client}>
            <BrowserRouter>
                <AppApollo />
            </BrowserRouter>
        </ApolloProvider>
    </Provider>,
    document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
