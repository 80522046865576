export const typeColors = [
    '#FFE5A6',
    '#CDFFE4',
    '#AACCFF',
    '#E3CDFF',
    '#FFC9FC',
    '#FFF7A2',
    '#EEFFA2',
    '#B5FFF1',
    '#FFDCA1',
    '#FFBDAA',
];
