import React from 'react';

interface Props {
    children: React.ReactChild | React.ReactChild[];
    userId: string;
    onChange(): void;
    checked: boolean;
}
export const UserRow = ({
    children,
    userId,
    checked,
    onChange,
}: Props) => (
    <tr>
        <td align="center" width={50}>
            <input
                type="checkbox"
                id={userId}
                onChange={onChange}
                checked={checked}
            />
        </td>

        {children}
    </tr>
);
