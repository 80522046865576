import React from 'react';
import { Link } from 'react-router-dom';

import { Module as ModuleInterface, TeachersMap } from '../../OccupationDashboardInterfaces';
import classes from './Module.module.scss';
import { RolesList } from './RolesList';

interface Props {
    module: ModuleInterface,
    teachersMap: TeachersMap,
    index: number,
    isTUniversityMeetingsVisible: boolean;
}

const MODULE_NAME_START_COLUMN_LINE = 1;
const MODULE_NAME_END_COLUMN_LINE = 2;
const DIFF_BETWEEN_MODULE_INDEX_AND_START_ROW_LINE = 1;
const DIFF_BETWEEN_MODULE_INDEX_AND_END_ROW_LINE = 1 + DIFF_BETWEEN_MODULE_INDEX_AND_START_ROW_LINE;

export const Module = ({
    module,
    teachersMap,
    index,
    isTUniversityMeetingsVisible,
}: Props): JSX.Element => (
    <>
        <Link
            to={`/module/public/${module.id}`}
            target="_blank"
            className={classes.module__name}
            style={{
                gridColumnStart: MODULE_NAME_START_COLUMN_LINE,
                gridColumnEnd: MODULE_NAME_END_COLUMN_LINE,
                gridRowStart: index + DIFF_BETWEEN_MODULE_INDEX_AND_START_ROW_LINE,
                gridRowEnd: index + DIFF_BETWEEN_MODULE_INDEX_AND_END_ROW_LINE,
                height: `${getModuleTeachersCount(module) * 19}px`,
            }}
        >
            <div
                className={classes.module__tooltip}
            >
                {module.name}
            </div>
        </Link>
        <RolesList
            roles={module.teachersRoles}
            teachersMap={teachersMap}
            index={index}
            isTUniversityMeetingsVisible={isTUniversityMeetingsVisible}
        />
    </>
);

function getModuleTeachersCount(module: ModuleInterface) {
    return module.teachersRoles.flatMap(role => role.teachers).length;
}
