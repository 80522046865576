import React, { useState } from 'react';

import { MeetingInstanceAttendance, Student, TableColumn } from '@admin/Attendance/AttendanceIterfaces';
import cn from 'classnames';
import { attendanceStore } from '@admin/Attendance/AttendanceStore';
import { observer } from 'mobx-react';

import { format } from 'date-fns';
import { CellMeetingInfo } from '@admin/Attendance/AttendanceTable/CellMeetingInfo';
import classes from './TableRowCell.module.scss';

interface Props {
    order: number;
    isLastRow: boolean;
    shouldAdditionalBeTop: boolean;
    shouldAdditionalBeRight: boolean;
    isFirstRow: boolean;
    student: Student;
    meetingInstanceAttendance: MeetingInstanceAttendance;
    currentColumn?: TableColumn;
}

export const TableRowCell = observer((
    {
        order,
        isLastRow,
        isFirstRow,
        shouldAdditionalBeTop,
        shouldAdditionalBeRight,
        student,
        meetingInstanceAttendance,
        currentColumn,
    }: Props,
) : JSX.Element => {
    const [isAdditionalVisible, setIsAdditionalVisible] = useState(false);

    const meetingInstance = currentColumn?.meetingInstance;
    const meetingStartDate = new Date(meetingInstance?.startDate ?? '');
    const meetingEndDate = new Date(meetingInstance?.endDate ?? '');
    const teacher = meetingInstance?.meetingInstanceToTeachers[0].teacher;
    const teacherName = `${teacher?.user?.lastName} ${teacher?.user?.firstName} ${teacher?.user?.patronymic}`;

    const meetingStartDateInfo = format(meetingStartDate, 'dd.MM.yyyy');

    const startTime = format(meetingStartDate, 'HH:mm');
    const endTime = format(meetingEndDate, 'HH:mm');

    const isAbsenceNotValid = (meetingInstanceAttendance.wasAttended !== null
        && meetingInstanceAttendance.isHaveValidReason !== null
        && !meetingInstanceAttendance.isHaveValidReason && !meetingInstanceAttendance.wasAttended);

    const isAbsenceValid = (meetingInstanceAttendance.wasAttended !== null
        && meetingInstanceAttendance.isHaveValidReason !== null
        && meetingInstanceAttendance.isHaveValidReason && !meetingInstanceAttendance.wasAttended);

    const isAbsenceEmpty = meetingInstanceAttendance.wasAttended === null;

    const studentCredentials = `${student.user.lastName} ${student.user.firstName[0]}.`;

    // eslint-disable-next-line no-nested-ternary
    const statusInfo = isAbsenceValid ? 'Отсутствовал(а) по уважительной причине' : isAbsenceNotValid ? 'Отсутствовал(а)' : 'Присутствовал(а)';

    const meetingStatusInfo = isAbsenceEmpty ? 'Нет данных' : `${studentCredentials} - ${statusInfo}`;

    const additional = (
        <div
            className={cn(classes.cell__additional, {
                [classes.cell__additional_top]: shouldAdditionalBeTop,
                [classes.cell__additional_positionRight]: shouldAdditionalBeRight,
            })}
        >
            <CellMeetingInfo
                statusInfo={meetingStatusInfo}
                order={order}
                dateStart={meetingStartDateInfo}
                startTime={startTime}
                endTime={endTime}
                meetingName={meetingInstance?.meeting.topic ?? ''}
                teacherName={teacherName}
                className={classes.meetingInfo}
            />
        </div>
    );

    return (
        <div
            className={cn(classes.cell, {
                [classes.cell_valid]: isAbsenceValid,
                [classes.cell_notValid]: isAbsenceNotValid,
                [classes.cell_noData]: isAbsenceEmpty,
                [classes.cell_active]: attendanceStore.activeTableColumnId
                === currentColumn?.columnId,
                [classes.borderBottom_none]: isLastRow,
                [classes.cell_firstRow]: isFirstRow,
            })}
            onMouseOver={() => {
                setIsAdditionalVisible(true);
            }}
            onMouseLeave={() => {
                setIsAdditionalVisible(false);
            }}
            onFocus={() => {}}
        >

            {
                isAbsenceEmpty && (
                    ''
                )
            }
            {
                (isAbsenceValid || isAbsenceNotValid) && (
                    <div
                        className={classes.cell__attendance}
                    >
                        {
                            meetingInstanceAttendance.isHaveValidReason ? 'ув' : 'н'
                        }
                    </div>
                )
            }
            {
                meetingInstanceAttendance.wasAttended && (
                    <svg width="5" height="3" viewBox="0 0 5 3" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <ellipse cx="2.5" cy="1.5" rx="2.5" ry="1.5" fill="#A7A8AA" />
                    </svg>
                )
            }

            {
                isAdditionalVisible && (
                    additional
                )
            }

        </div>
    );
});
