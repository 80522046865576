import React from 'react';
import { BorderCrossIcon } from '@common/svg';

import classes from './FormatFormInput.module.scss';

interface Format {
    id: string;
    name: string;
}

interface Props {
    index: number;
    deleteForm: (id: number) => void;
    formList: Format[];
    setFormList: (array: Format[]) => void;
}

export function FormatFormInput({
    index,
    deleteForm,
    formList,
    setFormList,
}: Props): JSX.Element {
    const changeName = (e: any) => {
        e.preventDefault();
        const formatForms = formList;
        formatForms[index].name = e.currentTarget.value.trim();
        setFormList([...formatForms]);
    };
    return (
        <div className={classes.formatFromInput}>
            <input
                type="text"
                className={classes.formatFromInput__input}
                placeholder="Введите название формата встреч"
                onChange={changeName}
            />
            {index > 0 && (
                <BorderCrossIcon
                    size={26}
                    className={classes.formatFromInput__deleteIcon}
                    handler={() => deleteForm(index)}
                />
            )}
        </div>
    );
}
