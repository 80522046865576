import React from 'react';

import { groupBy } from 'lodash';

import classes from './ModuleSkillsBlock.module.scss';

interface Props {
    text: string;
    skillList: any;
    skillTypes: any;
    allSkillsList: any;
}

export function ModuleSkillsBlock({
    text,
    skillList,
    skillTypes,
    allSkillsList,
}: Props):JSX.Element {
    const currentSkillList = skillList[1];
    const skillType = skillList[0];

    const sortedSkills: any = skillTypes
        .filter((skill: any) => skill.parentId === null);

    const getFinalSkillTypeId = (typeId: string): string => {
        const skillTypeGroups = groupBy(skillTypes, 'parentId');
        let finalSkillTypeId = typeId;
        while (skillTypeGroups[finalSkillTypeId]) {
            const [child] = skillTypeGroups[finalSkillTypeId];
            finalSkillTypeId = child.id;
        }

        return finalSkillTypeId;
    };

    function isParentSkill(parent: any, child: any): boolean {
        return !!parent.children?.filter((item: any) => item.id !== parent.id)
            .some((item: any) => item.id === child.id);
    }

    function findParentSkill(skill: any): any {
        return allSkillsList.find((item: any) => isParentSkill(item, skill));
    }

    function formatSkillName(skill: any): string {
        let { name } = skill.skill;
        let parent = findParentSkill(skill.skill);

        while (parent) {
            name = `${name}. ${parent.name}`;
            parent = findParentSkill(parent);
        }

        return name;
    }

    const currenSkillTypeParams: any = [];
    const paramsType = currentSkillList;
    paramsType.forEach((item: any) => {
        if (skillType === item.skill.typeId && skillType < sortedSkills.length) {
            currenSkillTypeParams.push({
                id: item.skill.id,
                name: item.skill.name,
                level: item.level,
            });
        }
        if (getFinalSkillTypeId(skillType) > sortedSkills.length
            && getFinalSkillTypeId(skillType) <= item.skill.typeId) {
            currenSkillTypeParams.push({
                id: item.skill.id,
                name: formatSkillName(item),
                level: null,
            });
        }
    });
    const currentSkillType = sortedSkills.filter((item: any) => skillType === item.id);
    if (skillType > sortedSkills.length) {
        currentSkillType.push(sortedSkills[sortedSkills.length - 1]);
    }
    return (
        <div className={classes.moduleSkillsBlock}>
            <div className={classes.moduleSkillsBlock__text}>
                {`${currentSkillType[0].name.genitiveSingular}, ${text}`}
            </div>
            <ul className={classes.moduleSkillsBlock__list}>
                {currenSkillTypeParams.map((item: any, index: number) => (
                    <li
                        // eslint-disable-next-line
                        key={index}
                        className={classes.moduleSkillsBlock__item}
                    >
                        {item.name}
                    </li>
                ))}
            </ul>
        </div>
    );
}
