import { ApolloError, gql, useMutation } from '@apollo/client';

const REMOVE_ONLINE_ROOMS = gql`
    mutation removeOnlineRooms($id: String!) {
        removeOnlineRoom(id: $id) {
            id
            url
            capacity
        }
    }
`;

export function useRemoveOnlineRoomMutation(
    id: string,
    onCompleted?: () => void,
    onError?: (error: ApolloError) => void,
) {
    const [removeOnlineRoom, { loading }] = useMutation(
        REMOVE_ONLINE_ROOMS,
        {
            variables: { id },
            onCompleted,
            onError,
        },
    );
    return { removeOnlineRoom, loading };
}
