import React from 'react';
import { observer } from 'mobx-react';

import { IconDeprecated } from '@common';
import { evaluationStore } from '../../../../Store';

import classes from './EvaluationSearch.module.scss';

export const EvaluationSearch = observer((): JSX.Element => {
    function searchInput(event: React.ChangeEvent<HTMLInputElement>): void {
        evaluationStore.setSearchQuery(event.target.value as string);
    }

    return (
        <div className={classes.evaluation__wrapperSearch}>
            <div className={classes.evaluation__searchBlock}>
                <IconDeprecated
                    id="magnifier"
                    className={classes.evaluation__searchMagnifier}
                />
                <input
                    type="text"
                    value={evaluationStore.searchQuery}
                    onChange={searchInput}
                    className={classes.evaluation__searchInput}
                    placeholder="Поиск студента"
                />
            </div>
        </div>
    );
});
