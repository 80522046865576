import React, {
    useEffect,
    useRef,
    useState,
} from 'react';
import {
    filteredTeacherAssignmentInstances_filteredTeacherAssignmentInstances,
} from '../../TeacherAssignmentsApolloTypes';

import classes from './TeacherAssignmentAddNote.module.scss';

interface Props {
    // eslint-disable-next-line max-len
    assignment?: filteredTeacherAssignmentInstances_filteredTeacherAssignmentInstances;
    updateNote: (teacherNote: string) => void;
}

export function TeacherAssignmentAddNote({
    assignment,
    updateNote,
}: Props) {
    const [editable, setEditable] = useState(false);
    const [currentTeacher, setCurrentTeacher] = useState(false);
    const textarea = useRef<HTMLTextAreaElement>(null);
    const [currentNote, setCurrentNote] = useState<string | null>();

    useEffect(() => {
        if (assignment?.isCurrentTeacher) {
            setCurrentTeacher(assignment.isCurrentTeacher);
        }

        assignment?.assignmentInstance.teacherAssignmentInstances.forEach(teacher => {
            if (teacher.isCurrentTeacher && teacher.note !== null) {
                setEditable(true);
                setCurrentNote(teacher.note);
            }
        });
    }, [assignment]);

    return (
        <>
            {
                currentTeacher && (
                    <>
                        <div className={classes.teacherAssignment__comments}>
                            <div className={classes.teacherAssignment__subtitle}>
                                Ваш комментарий для студентов {
                                    assignment
                                    && assignment.assignmentInstance.wave.index !== null
                                    && assignment.assignmentInstance.wave.index + 1
                                } потока:
                            </div>

                            <textarea
                                ref={textarea}
                                className={classes.teacherAssignment__textarea}
                                placeholder="Оставьте свой комментарий"
                                defaultValue={currentNote ? `${currentNote}` : ''}
                            />

                            <button
                                className={classes.teacherAssignment__button}
                                type="button"
                            >
                                <div
                                    className={classes.teacherAssignment__textGradient}
                                    onClick={() => updateNote(textarea.current!.value)}
                                >
                                    {editable ? 'Редактировать' : 'Сохранить'}
                                </div>
                            </button>
                        </div>
                    </>
                )
            }
        </>
    );
}
