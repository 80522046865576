import React, { useState } from 'react';
import { WaveStudents } from '@admin/WaveSelectionPage/WaveAccordion/WaveStudents/WaveStudents';
import { Alert } from '@common/Alert';
import { nanoid } from 'nanoid';
import { useMutation, useQuery } from '@apollo/client';
import { WaveWithSelectedStudents } from '@admin/WaveSelectionPage/Interfaces';
import {
    GetIsValidStudentsWaveDistribution, GetIsValidStudentsWaveDistributionVariables,
    UpdateStudentWaveInput,
    Waves_waves_module,
} from '@admin/WaveSelectionPage/graphql-types';
import {
    GET_IS_VALID_STUDENTS_WAVE_DISTRIBUTION,
    UPDATE_STUDENT_WAVE,
} from '@admin/WaveSelectionPage/WaveSelectionQueries';
import { waveSelectionDataStore } from '@admin/WaveSelectionPage/WaveSelectionDataStore';

interface Props {
    waveIndex: number,
    wave: WaveWithSelectedStudents;
    currentModule: Waves_waves_module,
}

export function WaveStudensApollo(
    {
        waveIndex,
        wave,
        currentModule,
    } : Props,
) {
    const [
        updateStudentWaveError,
        setUpdateStudentWaveError,
    ] = useState<JSX.Element[]>([]);
    const addError = (message: string) => setUpdateStudentWaveError((arr) => [...arr, (<Alert
        key={nanoid()}
        message={message}
        time={7000}
    />)]);

    const [updateStudentModuleMutation] = useMutation(UPDATE_STUDENT_WAVE, {
        onError: (error) => addError(error.message),
    });

    const updateStudentWave = (input: UpdateStudentWaveInput) => updateStudentModuleMutation({
        variables: {
            UpdateStudentWaveInput: {
                ...input,
            },
        },
    });

    const {
        refetch: refetchIsValidStudentWaveDistribution,
    } = useQuery<
    GetIsValidStudentsWaveDistribution,
    GetIsValidStudentsWaveDistributionVariables
    >(GET_IS_VALID_STUDENTS_WAVE_DISTRIBUTION, {
        fetchPolicy: 'no-cache',
        variables: {
            baseTimeIntervalInstanceId: waveSelectionDataStore
                .getURLParameters().baseTimeIntervalInstanceId,
        },
        onCompleted: (data) => waveSelectionDataStore
            .setIsValidStudentWaveDistribution(data.isValidStudentsWaveDistribution),
        onError: (error) => addError(error.message),
    });

    return (
        <>
            <WaveStudents
                waveIndex={waveIndex}
                wave={wave}
                currentModule={currentModule}
                updateStudentWave={updateStudentWave}
                refetchIsValidStudentWaveDistribution={refetchIsValidStudentWaveDistribution}
            />
            {
                updateStudentWaveError
            }
        </>
    );
}
