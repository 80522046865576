import React, { useEffect, useState } from 'react';

import {
    ScheduleGenerationProcess,
} from '@admin/ScheduleGenerationPage/store/BaseTimeIntervalInstanceModel';

import { AdditionalGeneration } from './AdditionalGeneration';
import { AdditionalGenerationList } from './AdditionalGenerationList';
import { CreateAdditionalGenerationForm } from './CreateAdditionalGenerationForm';

interface Props {
    processes: ScheduleGenerationProcess[];
    baseTimeIntervalInstanceId: string;
    isSecret: boolean;
    refetchProcesses(): void;
}

export function AdditionalGenerationPage({
    processes, baseTimeIntervalInstanceId, isSecret, refetchProcesses,
}: Props) {
    const [process, setProcess] = useState<ScheduleGenerationProcess | undefined>(undefined);

    useEffect(() => {
        if (process) {
            setProcess(processes.find(({ id }) => id === process.id));
        }
    }, [processes]);

    return (
        <>
            {process ? (
                <AdditionalGeneration
                    process={process}
                    back={() => setProcess(undefined)}
                    refetchProcesses={refetchProcesses}
                    isSecret={isSecret}
                />
            ) : (
                <>
                    <AdditionalGenerationList processes={processes} setProcess={setProcess} />
                    <CreateAdditionalGenerationForm
                        baseTimeIntervalInstanceId={baseTimeIntervalInstanceId}
                        refetchProcesses={refetchProcesses}
                    />
                </>
            )}
        </>
    );
}
