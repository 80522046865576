import React, { useEffect, useState } from 'react';
import { FilterSelect } from '@admin/ScheduleGenerationPage/FiltrationComponents/FilterSelect';

import { RoomMeetingFormat, Status } from '../../RoomTypes';

import classes from './RoomTypes.module.scss';

export interface TypesProps {
    handleRoomType(event: RoomMeetingFormat[]): void;
    types?: RoomMeetingFormat[];
}

const additionalRoomTypes = {
    all: {
        id: 'All',
        name: 'Для всех типов',
        status: Status.check,
    },
    clear: {
        id: 'CLEAR',
        name: 'Удалить все типы',
        status: Status.check,
    },
};

export function RoomTypes(
    {
        types,
        handleRoomType,
    }: TypesProps,
): JSX.Element {
    const sortedTypes = types?.sort((a: any, b: any) => (a.name > b.name ? 1 : -1));
    const [selectedTypes, setSelectedTypes] = useState<RoomMeetingFormat[]>();
    const [canClear, setCanClear] = useState<boolean>(false);

    useEffect(() => {
        if (types) {
            setCanClear(false);
            updateSelectedTypesAndCanClear(types);
        }
    }, [types]);

    function updateSelectedTypesAndCanClear(allTypes: RoomMeetingFormat[]) {
        const editModeSelectedTypes = getEditModeSelectedTypes(allTypes);
        if (!editModeSelectedTypes) {
            return;
        }

        if (editModeSelectedTypes.length === allTypes.length) {
            setCanClear(true);
        }

        if (editModeSelectedTypes.length) {
            setSelectedTypes(editModeSelectedTypes);
        }
    }

    function getEditModeSelectedTypes(allTypes: RoomMeetingFormat[]) {
        return allTypes?.filter((type) => type.status === 'checked');
    }

    function updateSelectedTypes(
        roomTypes: RoomMeetingFormat[],
        sortedRoomTypes: RoomMeetingFormat[],
    ) {
        const allRoomsChosen = roomTypes.find(
            (roomType) => roomType.id === additionalRoomTypes.all.id,
        );

        if (allRoomsChosen) {
            setSelectedTypes([additionalRoomTypes.all, ...sortedRoomTypes]);
            handleRoomType(sortedRoomTypes);
        } else {
            setSelectedTypes(sortedRoomTypes);
            handleRoomType(roomTypes);
        }
    }

    return (
        <div>
            {sortedTypes ? (
                <div className={classes.roomTypes}>
                    <p className={classes.roomTypes__title}>
                        Укажите для какого типа занятий подходит аудитория:
                    </p>

                    <div className={classes.roomTypes__select}>

                        <FilterSelect
                            filterName=""
                            placeholder="Выберите тип занятий"
                            containerClassName={classes.roomTypes__select_container}
                            setState={(roomTypes) => {
                                if (canClear) {
                                    handleRoomType([]);
                                    setSelectedTypes([]);
                                    setCanClear(false);
                                } else {
                                    updateSelectedTypes(roomTypes, sortedTypes);
                                }
                            }}
                            selectedOptions={selectedTypes}
                            options={
                                canClear ? [additionalRoomTypes.clear] : [
                                    additionalRoomTypes.all,
                                    ...sortedTypes,
                                ]}
                            formatOption={(item) => item.name}
                        />

                    </div>

                </div>
            ) : false
            }
        </div>
    );
}
