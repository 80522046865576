import { observer } from 'mobx-react';
import React from 'react';

import colors from '../../store/ColorConstans';

interface Props {
    handler?: () => void;
    size?: number;
    firstColor?: string;
    secondColor?: string;
    thirdColor?: string;
    className?: string;
}

export const FilledEditIcon = observer(({
    size = 24,
    handler,
    firstColor = colors.firstColor,
    secondColor = colors.secondColor,
    thirdColor = colors.thirdColor,
    className,
}: Props): JSX.Element => (
    <div className={className} style={{ height: `${size}px`, width: `${size}px`, cursor: 'pointer' }} onClick={handler}>
        <svg width={size} height={size} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M20 40C31.0457 40 40 31.0457 40 20C40 8.9543 31.0457 0 20 0C8.9543 0 0 8.9543 0 20C0 31.0457 8.9543 40 20 40ZM27.6585 7.24705L32.1113 11.6999C32.2572 11.8445 32.3731 12.0164 32.4524 12.2059C32.5318 12.3953 32.573 12.5986 32.5737 12.804C32.5744 13.0094 32.5345 13.2129 32.4565 13.4029C32.3785 13.5929 32.2637 13.7656 32.1189 13.9112L29.6434 16.3867L22.9793 9.72252L25.4547 7.24705C25.7475 6.95578 26.1436 6.79227 26.5566 6.79227C26.9696 6.79227 27.3657 6.95578 27.6585 7.24705ZM8.33777 29.1188L9.77173 22.9301L21.6812 11.0056L28.3529 17.6622L16.3981 29.5716L10.1717 31.0056C10.0638 31.0165 9.95511 31.0165 9.84721 31.0056C9.61563 31.0042 9.3873 30.9511 9.17889 30.8501C8.97047 30.7492 8.78724 30.6029 8.64257 30.4221C8.49791 30.2413 8.39547 30.0304 8.34273 29.8049C8.29 29.5794 8.2883 29.345 8.33777 29.1188Z" fill="url(#paint0_linear_108_1018)" />
            <defs>
                <linearGradient id="paint0_linear_108_1018" x1="42.8916" y1="17.7215" x2="-0.58885" y2="34.694" gradientUnits="userSpaceOnUse">
                    <stop offset="0.130208" stopColor={firstColor} />
                    <stop offset="0.734375" stopColor={secondColor} />
                    <stop offset="0.932292" stopColor={thirdColor} />
                </linearGradient>
            </defs>
        </svg>
    </div>
));
