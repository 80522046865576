import React from 'react';

import { useAdditionalGenerationsQuery } from '@admin/ScheduleGenerationPage/graphql';
import { Loader } from '@common/Loader';

import { AdditionalGenerationPage } from './AdditionalGenerationPage';

interface Props {
    baseTimeIntervalInstanceId: string;
    isSecret: boolean;
}

function AdditionalGenerationApollo({ baseTimeIntervalInstanceId, isSecret }: Props) {
    const {
        additionalScheduleGenerationProcesses, refetch, loading,
    } = useAdditionalGenerationsQuery(baseTimeIntervalInstanceId);

    return (
        <>
            {loading && <Loader />}
            {additionalScheduleGenerationProcesses && (
                <AdditionalGenerationPage
                    isSecret={isSecret}
                    processes={additionalScheduleGenerationProcesses ?? []}
                    baseTimeIntervalInstanceId={baseTimeIntervalInstanceId}
                    refetchProcesses={() => refetch({ baseTimeIntervalInstanceId })}
                />
            )}
        </>
    );
}

export { AdditionalGenerationApollo as AdditionalGeneration };
