import { ApolloError, gql, useMutation } from '@apollo/client';

export interface DateRangeInput {
    from: string,
    to: string,
}

const UPDATE_RESERVATION = gql`
    mutation updateReservation($dateRangeInput: DateRangeInput!){
        updateReservation(dateRangeInput: $dateRangeInput)
}
`;

export function useUpdateReservation() {
    const [handler, { data, error }] = useMutation(UPDATE_RESERVATION);

    async function onReservationUpdate(dateRangeInput: DateRangeInput) {
        try {
            await handler({
                variables: { dateRangeInput },
            });
        } catch (e) {
            // eslint-disable-next-line no-console
            console.error((e as ApolloError).graphQLErrors?.[0].message);
        }
    }

    return [
        data,
        error,
        onReservationUpdate,
    ];
}
