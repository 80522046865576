import React from 'react';
import cn from 'classnames';
import { observer } from 'mobx-react';

import {
    getCells,
    Cell,
    isDaysFit,
} from './helpers';
import { MonthScheduleHeader } from './MonthScheduleHeader';
import { DayOfMonthCell } from './DayOfMonthCell';
import { Assignments } from '../../Assignments';
import { accountStore } from '../../../../Store';
import { eventsStore, timeStore } from '../../../Store';

import classes from './MonthSchedule.module.scss';

export const MonthSchedule = observer(() => (
    <div className={classes.monthSchedule}>
        <MonthScheduleHeader />

        <div className={cn(classes.monthSchedule__block,
            {
                [classes.monthSchedule__block_scrollable]:
                    !isDaysFit(timeStore.selectedDate),
            })}
        >
            <div
                className={cn(
                    classes.monthSchedule__grid,
                    {
                        [classes.monthSchedule__grid_scrollable]:
                            !isDaysFit(timeStore.selectedDate),
                    },
                )}
            >
                {getCells(
                    timeStore.selectedDate,
                    eventsStore.getTimeReservingEvents(),
                ).map((cell: Cell) => (
                    <DayOfMonthCell
                        cellId={cell.id}
                        date={cell.date}
                        dateEvents={cell.dateEvents}
                        key={cell.id}
                    />
                ))}
            </div>

            {(accountStore.isStudent() && !!eventsStore.getAssignmentInstances().length) && (
                <Assignments className={classes.monthSchedule__assignments} />
            )}
        </div>
    </div>
));
