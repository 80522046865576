import React, { useState } from 'react';
import { Title, TitleTypes } from '@common/Title';
import { observer } from 'mobx-react';
import { Description } from '@admin/NewModule/Description';
import { Select } from '@common/Select';
import { moduleStore, dictionaryStore } from '../Store';
import { Input, InputTypes } from '../CustomInput';
import {
    Section,
    InputForm,
    InputFormInitialData,
    SuggestSectionForm,
    SuggestSectionFormInitialData,
    SkillsSection,
    SuggestSelectElement,
} from '../Section';
import { getRolesInputFormProps, getProfessors } from './ModuleOptions.config';
import { Loader } from '../Loader';
import { MinMax } from '../MinMax';
import { Textarea } from '../Textarea';

import styles from './ModuleOptions.module.scss';

const validateNumber = (num: string): number => (Number(num)
    ? Math.min(Math.max(Number(num), 0), 100000) : 0);

const getNewSkills = (items: SuggestSelectElement[]) => items.map(item => ({
    skill: { ...item, fullName: item.name }, level: Number(item.selectOption?.name),
}));

enum CKEditorModeOptions {
    DEFAULT = 'Учебный модуль',
    PROJECT = 'Проектный модуль',
}

export const ModuleOptions = observer(() => {
    const module = moduleStore.moduleModel;
    const [isDefaultModule, setIsDefaultModule] = useState<boolean>(true);

    return (
        <div className={styles.moduleOptions}>
            <div className={styles.moduleOptions__avatarPreview}>
                <Loader disabled />
            </div>

            <div className={styles.moduleOptions__form}>

                <div className={styles.moduleOptions__mode}>

                    <Select
                        options={Object.values(CKEditorModeOptions).map((value) => ({
                            id: value,
                        }))}
                        onChange={(item) => setIsDefaultModule(
                            !(item.id === CKEditorModeOptions.PROJECT),
                        )}
                    />

                </div>

                <Textarea
                    name="name"
                    label="Название модуля"
                    placeholder="Введите название модуля"
                    labelModifier="accent"
                    value={module.options.name}
                    onChange={({ target }) => module.updateOptions({ name: target.value })}
                />
                <div className={styles.moduleOptions__description}>

                    <Description
                        label="Описание модуля"
                        isDefault={isDefaultModule}
                        value={module.options.description}
                        onChangeEvent={module.updateOptions}
                    />

                </div>
                <MinMax
                    title="Укажите количество студентов на одном потоке модуля:"
                    min={module.options.minStudentCount}
                    max={module.options.maxStudentCount}
                    onChangeMin={({ target }) => {
                        const minStudentCount = validateNumber(target.value);
                        module.updateOptions({ minStudentCount });
                        module.meetings.forEach(({ id }) => {
                            module.updateMeeting(id, { minStudentCount });
                        });
                    }}
                    onChangeMax={({ target }) => {
                        const maxStudentCount = validateNumber(target.value);
                        module.updateOptions({ maxStudentCount });
                        module.meetings
                            .filter(({ isManyWaves }) => !isManyWaves)
                            .forEach(({ id }) => {
                                module.updateMeeting(id, { maxStudentCount });
                            });
                    }}
                />
                <div className={styles.moduleOptions__wavesContainer}>
                    <div className={styles.moduleOptions__wavesContainerTitle}>
                        <Title type={TitleTypes.Subtitle}>
                            Укажите максимально возможное количество потоков модуля в семестр:
                        </Title>
                    </div>
                    <Input
                        name="maxWaveCount"
                        placeholder="Кол-во"
                        type={InputTypes.Number}
                        value={module.options.maxWaveCount > 0 ? module.options.maxWaveCount : ''}
                        onChange={({ target }) => {
                            module.updateOptions({ maxWaveCount: validateNumber(target.value) });
                        }}
                    />
                </div>
                <div className={styles.moduleOptions__rolesInput}>
                    <Title
                        type={TitleTypes.Subtitle}
                    >
                        Укажите роли для преподавателей модуля:
                    </Title>
                    <Section
                        list={module.options.teachersRoles.map(({ teacherRole }) => teacherRole)}
                        initialData={InputFormInitialData}
                        form={InputForm}
                        formProps={getRolesInputFormProps()}
                        formatItem={({ name }) => <>{name}</>}
                        validate={({ name }) => name.trim() !== ''}
                        onChange={items => module.updateOptions({
                            teachersRoles: items.map(item => ({
                                teacherRole: { id: item.id, name: item.name.trim() },
                            })),
                        })}
                    />
                </div>
                <div className={styles.moduleOptions__professorsTitle}>
                    <Title
                        type={TitleTypes.Subtitle}
                    >
                        Добавьте преподавателей модуля:
                    </Title>
                </div>
                <div className={styles.moduleOptions__professors}>
                    <Section
                        list={module.options.moduleTeachers
                            .map(teacher => ({
                                ...teacher.teacher,
                                optionsSelect: teacher.teacherRoles,
                                label: teacher.teacher.name,
                            }))}
                        formatItem={({ name, optionsSelect }) => (
                            <>
                                <b>{name}: </b>
                                <br />
                                {optionsSelect
                                    .map((option: { name: string; }) => option.name).join(', ')}
                            </>
                        )}
                        initialData={SuggestSectionFormInitialData}
                        form={SuggestSectionForm}
                        formProps={getProfessors({
                            teachers: dictionaryStore.teachers,
                            teachersRoles: module.options.teachersRoles.map(el => el.teacherRole),
                        }).formProps}
                        validate={({ optionsSelect }) => optionsSelect.length > 0}
                        buttonInfo={{ name: 'Добавить преподавателя', down: true, isBorder: true }}
                        onChange={items => module.updateOptions({
                            moduleTeachers: items.map(item => ({
                                teacher: { id: item.id, name: item.name },
                                teacherRoles: item.optionsSelect,
                            })),
                        })}
                    />
                </div>
                <SkillsSection
                    requiredTitle="которые необходимы для попадания на модуль"
                    outputTitle="которые дает модуль при прохождении"
                    skillTypes={dictionaryStore.moduleSettings.moduleSkillTypes}
                    requiredSkills={module.options.prerequisiteSkills}
                    outputSkills={module.options.outputSkills}
                    onChangeRequired={(items, typeID) => {
                        module.options.updateModuleRequiredSkills(getNewSkills(items), typeID);
                    }}
                    onChangeOutput={(items, typeID) => {
                        module.options.updateModuleOutputSkills(getNewSkills(items), typeID);
                    }}
                />
            </div>
        </div>
    );
});
