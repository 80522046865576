import React, { useState } from 'react';
import { observer } from 'mobx-react';

import {
    VotedChart,
} from '@admin/EducationPeriodStatistic/EducationPeriodStatisticVoted/VotedChartAccordion/VotedChart';

import { educationPeriodStatisticStore } from '@admin/EducationPeriodStatistic/EducationPeriodStatisticStore';
import { SuggestRedesign } from '@common/SuggestRedesign';

import { getWordEnding } from '@admin/EducationPeriodStatistic/EducationPeriodStatistic';
import classes from './VotedChartAccordionItems.module.scss';

interface Option {
    name: string;
    id: string;
}

export interface ChartItem {
    allVotes: number;
    priorityVotes: number;
    moduleName: string;
}

export const VotedChartAccordionItems = observer((): JSX.Element => {
    const suggestOptions:
    Option[] = educationPeriodStatisticStore
        .modulesPrioritiesGroups[0]?.priorityGroups.map((priorityGroup) => (
            {
                name: `${priorityGroup.priority}-й приоритет`,
                id: `${priorityGroup.priority}`,
            }
        ));
    const [activeDiagramOption,
        setActiveDiagramOption] = useState<
    Option | null
    >(suggestOptions ? suggestOptions[0] : null);

    return (
        <div className={classes.items}>

            <div className={classes.items__head}>

                <div className={classes.items__count}>
                    {
                        educationPeriodStatisticStore.votedStudentsVotes
                    }
                    {' '}
                    {
                        getWordEnding(
                            educationPeriodStatisticStore.votedStudentsVotes,
                            {
                                one: 'человек',
                                oneTwoFive: 'человека',
                                tenToTwenty: 'человек',
                            },
                        )
                    }
                </div>

                <div className={classes.items__filter}>
                    <SuggestRedesign
                        onChange={(option) => setActiveDiagramOption(option)}
                        options={suggestOptions}
                        value={activeDiagramOption}
                        placeholder="Приоритет"
                    />
                </div>

            </div>

            <div className={classes.items__chart}>
                <VotedChart
                    currentPriority={activeDiagramOption ? +activeDiagramOption.id : 1}
                    chartItems={educationPeriodStatisticStore.modulesPrioritiesGroups}
                />
            </div>

        </div>
    );
});
