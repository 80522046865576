import React, { useState } from 'react';
import cn from 'classnames';

import {
    IntervalWeekDay,
    IntervalWithWeek, WeekDaysRussian,
    WorkingInterval,
} from '@admin/EducationPeriodPage/EducationPeriodPageInterfaces';
import {
    WeekDay,
} from '@admin/EducationPeriodPage/EducationPeriodDaySHeduleEdit/WeekDayIntervals/WeekDayInterval/WeekDay';

import { InputWithFocusWithin } from '@common/InputWithFocusWithin';
import classes from './WeekDayInterval.module.scss';

interface Props {
    week: IntervalWeekDay[];
    weekIntervals: IntervalWithWeek[];
    interval: WorkingInterval;
    setWeekIntervals: (newWeekIntervals: IntervalWithWeek[]) => void;
}

export function WeekDayInterval(
    {
        week,
        weekIntervals,
        interval,
        setWeekIntervals,
    }: Props,
) {
    const [isStartActive, setStartActive] = useState(false);
    const [isEndActive, setEndActive] = useState(false);
    const changeDayIsActive = (
        weekDayName: (keyof typeof WeekDaysRussian),
        intervalId: string,
    ) => {
        const newWeekIntervals: IntervalWithWeek[] = weekIntervals.map((weekWithInterval) => {
            if (weekWithInterval.interval.id !== intervalId) {
                return weekWithInterval;
            }

            return {
                ...weekWithInterval,
                week: weekWithInterval.week.map((dayInWeek) => ({
                    ...dayInWeek,
                    isActive: dayInWeek.weekDay === weekDayName
                        ? !dayInWeek.isActive : dayInWeek.isActive,
                })),
            };
        });

        setWeekIntervals(newWeekIntervals);
    };

    const setNewWeekIntervalStartTime = (value: string) => {
        setWeekIntervals(
            getNewWeekIntervals(
                weekIntervals,
                true,
                value,
                interval.id,
            ),
        );
    };

    const setNewWeekIntervalEndTime = (value: string) => {
        setWeekIntervals(
            getNewWeekIntervals(
                weekIntervals,
                false,
                value,
                interval.id,
            ),
        );
    };

    return (
        <div
            className={cn(classes.weekDayInterval, {
                [classes.weekDayInterval_active]: isStartActive || isEndActive,
            })}
        >
            <div className={classes.weekDayInterval__timeInterval}>

                <InputWithFocusWithin
                    id={JSON.stringify(`start${interval.id}`)}
                    label={formatTime(interval.start)}
                    labelClassName={classes.weekDayInterval__label}
                    inputClassName={classes.weekDayInterval__input}
                    defaultValue={formatTime(interval.start)}
                    onBlur={(value) => {
                        setNewWeekIntervalStartTime(value);
                        setStartActive(false);
                    }}
                    onFocus={() => setStartActive(true)}
                />

                <InputWithFocusWithin
                    id={JSON.stringify(`end${interval?.id}`)}
                    label={formatTime(interval.end)}
                    labelClassName={classes.weekDayInterval__label}
                    inputClassName={cn(
                        classes.weekDayInterval__input,
                        classes.weekDayInterval__input_end,
                    )}
                    defaultValue={formatTime(interval.end)}
                    onBlur={(value) => {
                        setNewWeekIntervalEndTime(value);
                        setEndActive(false);
                    }}
                    onFocus={() => setEndActive(true)}
                />

            </div>

            <div className={classes.weekDayInterval__week}>
                {
                    week.map((day) => (
                        <WeekDay
                            key={JSON.stringify(day.interval?.id + day.weekDay)}
                            weekDay={day}
                            interval={interval}
                            changeDayIsActive={changeDayIsActive}
                        />
                    ))
                }
            </div>
        </div>
    );
}

function formatTime(time?: string) {
    if (time) {
        const timeSplit = time.split(':');
        return `${timeSplit[0]}:${timeSplit[1]}`;
    }
    return '';
}

function getNewWeekIntervals(
    oldWeekIntervals: IntervalWithWeek[],
    isStart: boolean,
    time: string,
    intervalId: string,
) {
    return oldWeekIntervals.map((intervalWithWeek) => {
        if (intervalWithWeek.interval.id !== intervalId) {
            return intervalWithWeek;
        }
        return {
            ...intervalWithWeek,
            interval: {
                ...intervalWithWeek.interval,
                start: isStart ? time : intervalWithWeek.interval.start,
                end: !isStart ? time : intervalWithWeek.interval.end,
            },
        };
    });
}
