import { observer } from 'mobx-react';
import React from 'react';

import colors from '../../store/ColorConstans';

interface Props {
    width?: number;
    height?: number;
    firstColor?: string;
    secondColor?: string;
    thirdColor?: string;
    className?: string;
    handler?: () => void;
}

export const PaintingIcon = observer(({
    width = 33,
    height = 25,
    firstColor = colors.firstColor,
    secondColor = colors.secondColor,
    thirdColor = colors.thirdColor,
    className,
    handler,
}: Props): JSX.Element => (
    <div className={className} style={{ height: `${height}px`, width: `${width}px`, cursor: 'pointer' }} onClick={handler}>
        <svg width="33" height="25" viewBox="0 0 33 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M27.6786 25H5.21824C2.34078 25 0 22.5633 0 19.5713V5.42868C0 2.43253 2.34284 0 5.21824 0H27.6786C30.554 0 32.8947 2.43253 32.8947 5.42868V19.5713C32.8947 22.5633 30.5519 25 27.6786 25ZM4.5577 2.02606C3.11278 2.02606 1.93207 3.31252 1.93207 4.89859V20.2313C1.93207 21.8174 3.11278 23.1038 4.5577 23.1038H28.3308C29.7799 23.1038 30.9585 21.8174 30.9585 20.2313V4.89859C30.9585 3.31252 29.7799 2.02606 28.3308 2.02606H4.5577Z" fill="url(#paint0_linear_391_2401)" />
            <path d="M11.2333 10.2875C12.9102 10.2875 14.2697 8.91696 14.2697 7.22637C14.2697 5.53577 12.9102 4.16527 11.2333 4.16527C9.5563 4.16527 8.19685 5.53577 8.19685 7.22637C8.19685 8.91696 9.5563 10.2875 11.2333 10.2875Z" fill="url(#paint1_linear_391_2401)" />
            <path fillRule="evenodd" clipRule="evenodd" d="M23.1291 6.338L28.8262 20.9018H4.11184L11.2869 12.5042L17.721 14.4821L23.1291 6.338Z" fill="url(#paint2_linear_391_2401)" />
            <defs>
                <linearGradient id="paint0_linear_391_2401" x1="35.2727" y1="11.0759" x2="2.66844" y2="27.822" gradientUnits="userSpaceOnUse">
                    <stop offset="0.130208" stopColor={firstColor} />
                    <stop offset="0.734375" stopColor={secondColor} />
                    <stop offset="0.932292" stopColor={thirdColor} />
                </linearGradient>
                <linearGradient id="paint1_linear_391_2401" x1="35.2727" y1="11.0759" x2="2.66844" y2="27.822" gradientUnits="userSpaceOnUse">
                    <stop offset="0.130208" stopColor={firstColor} />
                    <stop offset="0.734375" stopColor={secondColor} />
                    <stop offset="0.932292" stopColor={thirdColor} />
                </linearGradient>
                <linearGradient id="paint2_linear_391_2401" x1="35.2727" y1="11.0759" x2="2.66844" y2="27.822" gradientUnits="userSpaceOnUse">
                    <stop offset="0.130208" stopColor={firstColor} />
                    <stop offset="0.734375" stopColor={secondColor} />
                    <stop offset="0.932292" stopColor={thirdColor} />
                </linearGradient>
            </defs>
        </svg>
    </div>
));
