import React, { Fragment } from 'react';
import { observer } from 'mobx-react';

import { moduleStore } from '@admin/NewModule/Store';
import { Arrow } from './Arrow';

type EventRef = { ref: React.MutableRefObject<HTMLDivElement | null>, id: string };

interface ArrowContainerProps {
    eventListRef: React.MutableRefObject<HTMLDivElement | null>;
    eventRefs: EventRef[];
    opened: string;
}

const ArrowContainer = observer(({ eventListRef, eventRefs, opened }: ArrowContainerProps) => {
    const { eventsWithoutTest } = moduleStore.moduleModel;
    const isAllSaved = opened === '';
    if (!isAllSaved) {
        return null;
    }

    return (
        <div>
            {eventsWithoutTest.map(mainEvent => {
                const mainElement = eventRefs.find(item => item.id === mainEvent.id)?.ref;

                return (
                    <Fragment key={mainEvent.id}>
                        {mainEvent.dependentEvents.map(({ id }) => {
                            const dependentElement = eventRefs.find(item => item.id === id)?.ref;
                            const dependentEvent = moduleStore.moduleModel.getEventWithoutTest(id);
                            return (
                                <Arrow
                                    key={id}
                                    mainEvent={mainEvent}
                                    dependentEvent={dependentEvent}
                                    eventList={eventListRef.current}
                                    mainElement={mainElement?.current}
                                    dependentElement={dependentElement?.current}
                                />
                            );
                        })}
                    </Fragment>
                );
            })}
        </div>
    );
});

export { ArrowContainer as Arrows };
