import React, { useEffect, useState } from 'react';
import { cloneDeep } from 'lodash';

import { Autosuggest } from '@common/Autosuggest';
import { IconDeprecated } from '@common';

import classes from './MultiAutosuggest.module.scss';
import { SourceTypeInterface, MultiAutosuggestProps as Props } from './MultiAutosuggestInterfaces';

export function MultiAutosuggest<SourceType extends SourceTypeInterface>(
    {
        placeholder,
        query,
        options,
        isSuggestableOnFocus = true,
        isSingleQueryInManyAutosuggests = false,
        selectedOptions: selectedOptionsProp = [],
        className = classes.multiautosuggest_defaultWidth,
        onChange,
        formatOption = (option) => option.id,
        formatSendingValue = (value) => value,
        transformResponse = (items) => items,
    } : Props<SourceType>,
): JSX.Element | null {
    const [
        selectedOptions,
        setSelectedOptions,
    ] = useState<SourceType[]>(cloneDeep(selectedOptionsProp));

    useEffect(() => {
        setSelectedOptions(cloneDeep(selectedOptionsProp));
    }, [selectedOptionsProp]);
    function unselectOption(id: string) {
        const optionIndex = selectedOptions.findIndex(option => option.id === id);
        const newSelectedOptions = cloneDeep(selectedOptions);
        newSelectedOptions.splice(optionIndex, 1);
        setSelectedOptions(newSelectedOptions);
        onChange(newSelectedOptions);
    }

    function selectOption(option: SourceType) {
        const newSelectedOptions = cloneDeep(selectedOptions);
        newSelectedOptions.push(option);
        setSelectedOptions(newSelectedOptions);
        onChange(newSelectedOptions);
    }

    return (
        <div className={className}>
            <Autosuggest<SourceType>
                placeholder={placeholder}
                query={query}
                options={options}
                formatOption={formatOption}
                transformResponse={transformResponse}
                onChange={selectOption}
                selectedOptions={selectedOptions}
                isSingleQueryInManyAutosuggests={isSingleQueryInManyAutosuggests}
                isSuggestableOnFocus={isSuggestableOnFocus}
                formatSendingValue={formatSendingValue}
                className={className}
            />
            <div className={classes.multiautosuggest__optionList}>
                {
                    selectedOptions.map(
                        (option) => (
                            <div
                                className={classes.multiautosuggest__option}
                                key={option.id}
                            >
                                <div
                                    className={classes.multiautosuggest__optionText}
                                >
                                    {formatOption(option)}
                                </div>

                                <IconDeprecated
                                    id="whiteCross"
                                    click={() => unselectOption(option.id)}
                                    className={classes.multiautosuggest__unselect}
                                />
                            </div>
                        ),
                    )
                }
            </div>
        </div>
    );
}
