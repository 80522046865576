import React, { useEffect, useState } from 'react';
import CSS from 'csstype';
import { DiagramType } from './DiagramType';
import { typeColors } from '../../services/typeColors';
import { Rectangle } from '../../services/rectangle';
import {
    SubspaceType, TypesStyles, SubspacesTypesRectangles, SubspaceTypeLayout,
} from '../../interfaces';
import { SubspacesStatuses } from '../../services/subspacesView';

interface Props {
    subspaceTypes: SubspaceType[];
    setSubspacesTypesRects(rects: SubspacesTypesRectangles): void;
    subspacesStatuses: SubspacesStatuses;
    hoverSubspace(subspaceId: string): void;
    clearDiagramView(): void;
    showTrajectory(subspaceId: string): void;
    isEditMode: boolean;
    updateTempConnections(subspaceId: string): void;
    hasElectiveType(subspaceType: SubspaceType | undefined): boolean;
}

export function DiagramTypes({
    subspaceTypes,
    setSubspacesTypesRects,
    subspacesStatuses,
    hoverSubspace,
    clearDiagramView,
    showTrajectory,
    isEditMode,
    updateTempConnections,
    hasElectiveType,
}: Props): JSX.Element {
    const sortedTypes: SubspaceType[] = [];

    const [typesStyles, setTypesStyles] = useState<TypesStyles>({});

    useEffect(() => {
        if (sortedTypes.length > 0) {
            const rects: SubspacesTypesRectangles = initSubspacesTypesRects(sortedTypes);
            const packedRects: SubspacesTypesRectangles = packageSubspacesTypesRects(rects);
            const newTypesStyles = getTypesStyles(packedRects);
            setSubspacesTypesRects(packedRects);
            setTypesStyles(newTypesStyles);
        }
    }, []);

    function getSpaceTypeOrdersNumber(layouts: SubspaceTypeLayout[]): number {
        let minOrder = layouts[0].spaceBaseTimeInterval.order;
        let maxOrder = layouts[0].spaceBaseTimeInterval.order;

        for (let i = 1; i < layouts.length; i += 1) {
            const curOrder = layouts[i].spaceBaseTimeInterval.order;

            if (curOrder < minOrder) {
                minOrder = curOrder;
            }

            if (curOrder > maxOrder) {
                maxOrder = curOrder;
            }
        }

        return maxOrder - minOrder + 1;
    }

    // рассчет первоначальных координат
    function initSubspacesTypesRects(types: SubspaceType[]): SubspacesTypesRectangles {
        return types.reduce((acc, { id, subspaceTypeLayouts: layouts }) => {
            const spaceTypeOrdersNumber = getSpaceTypeOrdersNumber(layouts);
            const topLeftX: number = 13 + 180 * (layouts[0].spaceBaseTimeInterval.order - 1);
            const topLeftY: number = 48;
            const width: number = 154 * spaceTypeOrdersNumber + 26 * (spaceTypeOrdersNumber - 1);
            const height: number = document.getElementById(id)?.offsetHeight as number;

            if (!height) return {};

            return {
                ...acc,
                [id]: new Rectangle(topLeftX, topLeftY, width, height),
            };
        }, {});
    }

    // перерассчет координат по оси Y
    function packageSubspacesTypesRects(rects: SubspacesTypesRectangles): SubspacesTypesRectangles {
        const res: SubspacesTypesRectangles = Object.keys(rects).reduce((acc, id) => (
            { ...acc, [id]: rects[id].copy() }),
        {});
        const sortedTypesIds: string[] = sortedTypes.map(type => type.id);

        for (let i = 1; i < sortedTypesIds.length; i += 1) {
            const cur: Rectangle = res[sortedTypesIds[i]];

            for (let j = 0; j < i; j += 1) {
                const prev: Rectangle = res[sortedTypesIds[j]];

                if (prev && cur.intercectsWith(prev)) {
                    cur.setTopLeftY(prev.getBottomRightY() + 13);
                }
            }
        }

        return res;
    }

    function getTypesStyles(rects: SubspacesTypesRectangles): TypesStyles {
        return Object.keys(rects).reduce((acc, typeId) => {
            const properties: CSS.Properties = {
                left: `${rects[typeId].getTopLeftX()}px`,
                top: `${rects[typeId].getTopLeftY()}px`,
                width: `${rects[typeId].getWidth()}px`,
            };

            return {
                ...acc,
                [typeId]: properties,
            };
        }, {});
    }

    // сортировка типов подпространств по критерию выборности
    function sortTypes(): void {
        if (subspaceTypes) {
            const typesWithSubspaces = subspaceTypes.filter(type => type.subspaces.length > 0);
            const electiveTypes = typesWithSubspaces.filter(type => hasElectiveType(type));
            const nonElectiveTypes = typesWithSubspaces.filter(type => !hasElectiveType(type));
            sortedTypes.push(...nonElectiveTypes);
            sortedTypes.push(...electiveTypes);
        }
    }

    sortTypes();

    const contentSubspaceTypes = sortedTypes.map((sortedType, i) => (
        <DiagramType
            key={sortedType.id}
            subspaceType={sortedType}
            subspaceTypeStyles={typesStyles[sortedType.id]}
            subspaceTypeColor={typeColors[i]}
            subspacesStatuses={subspacesStatuses}
            hoverSubspace={hoverSubspace}
            clearDiagramView={clearDiagramView}
            showTrajectory={showTrajectory}
            isEditMode={isEditMode}
            updateTempConnections={updateTempConnections}
        />
    ));

    return <ul className="subspace-types">{contentSubspaceTypes}</ul>;
}
