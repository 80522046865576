import React from 'react';
import { AuthRoute } from '@common/AuthRoute';
import { Redirect, Switch } from 'react-router-dom';
import { GradientTabs } from '@common/Menu/GradientTabs';
import { AdminsTable, AllRolesTable, NoRolesTable, StudentsTable, TeachersTable } from './Tables';
import { ViewType } from '../../deprecated-graphql-query-types';

interface Props {
    path: string;
}

export const Users = ({ path }: Props): JSX.Element => (
    <>
        <GradientTabs
            tabsOptions={[
                ['Все роли', 'all'],
                ['Преподаватели', 'teachers'],
                ['Студенты', 'students'],
                ['Администрация', 'admins'],
                ['Без роли', 'no-role'],
            ]}
        />

        <Switch>
            <AuthRoute
                path={`${path}`}
                requiredUserTypes={[ViewType.Admin]}
                exact
            >
                <Redirect to={`${path}/all`} />
            </AuthRoute>

            <AuthRoute
                path={`${path}/all`}
                requiredUserTypes={[ViewType.Admin]}
            >
                <AllRolesTable />
            </AuthRoute>

            <AuthRoute
                path={`${path}/teachers`}
                requiredUserTypes={[ViewType.Admin]}
            >
                <TeachersTable />
            </AuthRoute>

            <AuthRoute
                path={`${path}/students`}
                requiredUserTypes={[ViewType.Admin]}
            >
                <StudentsTable />
            </AuthRoute>

            <AuthRoute
                path={`${path}/admins`}
                requiredUserTypes={[ViewType.Admin]}
            >
                <AdminsTable />
            </AuthRoute>

            <AuthRoute
                path={`${path}/no-role`}
                requiredUserTypes={[ViewType.Admin]}
            >
                <NoRolesTable />
            </AuthRoute>
        </Switch>
    </>
);
