import React, { useCallback, useState, useEffect, useRef } from 'react';
import { debounce, cloneDeep, differenceWith } from 'lodash';
import cn from 'classnames';

import { SourceTypeInterface, AutosuggestProps as Props } from './AutosuggestInterfaces';

import classes from './Autosuggest.module.scss';

const DEBOUNCE_INTERVAL = 400;

export function Autosuggest<SourceType extends SourceTypeInterface>(
    {
        options,
        placeholder = 'Введите текст',
        isOptionsLoaded = false,
        selectedOptions = [],
        isSuggestableOnFocus = true,
        className = classes.autosuggest_defaultWidth,
        formatOption,
        onChange,
        loadOptions,
    }: Props<SourceType>,
): JSX.Element | null {
    const [suggestionInputValue, setSuggestionInputValue] = useState<string | null>(null);
    const debouncedLoadOptions = useCallback(
        debounce(loadOptions || (() => { }), DEBOUNCE_INTERVAL),
        [loadOptions],
    );
    const [filteredOptions, setFilteredOptions] = useState<SourceType[]>(
        options ? cloneDeep(options) : [],
    );
    const [
        targetElementRef,
        isSuggestedOptionVisible,
        setIsSuggestedOptionVisible,
    ] = useOnBlurHook();

    useEffect(() => {
        if (isOptionsLoaded) {
            setIsSuggestedOptionVisible(true);
        }
    }, [isOptionsLoaded]);

    function getSuggestedOptions() {
        return differenceWith(
            loadOptions ? options : filteredOptions,
            selectedOptions,
            isOptionsEqual,
        );
    }

    function isOptionsEqual(firstOption: SourceType, secondOption: SourceType) {
        return firstOption.id === secondOption.id;
    }

    function onInput(e: React.ChangeEvent<HTMLInputElement>) {
        onFocus(e);
    }

    function onFocus(e: React.ChangeEvent<HTMLInputElement>) {
        const { value } = e.target;
        setSuggestionInputValue(value);
        if (!loadOptions && options?.length) {
            filterOptions(value);
        } else if (loadOptions) {
            debouncedLoadOptions(value);
        }
        // WARNING во время набора текста рендерятся все еще старые
        // варианты, но если их спрятать и показывать только когда
        // придут новые варианты, то событие появления новых
        // вариантов сработает сразу во всех саджестов, которые юзают
        // одну и ту же ручку
        if (!loadOptions) {
            setIsSuggestedOptionVisible(true);
        }
    }

    function filterOptions(value: string) {
        if (options) {
            setFilteredOptions(
                options.filter(
                    (option: SourceType) => formatOption(option).toLowerCase()
                        .indexOf(value.toLowerCase()) !== -1,
                ),
            );
        }
    }

    function selectOption(id: string) {
        setIsSuggestedOptionVisible(false);
        if (!loadOptions) {
            sendResult(id, filteredOptions);
        } else if (options?.length) {
            sendResult(id, options);
        }
    }

    function sendResult(id: string, array: SourceType[]) {
        onChange(getOptionById(id, array) as SourceType);
        setSuggestionInputValue('');
    }

    return (
        <div
            className={cn(classes.autosuggest, className)}
            ref={targetElementRef}
        >
            <input
                value={suggestionInputValue ?? ''}
                placeholder={placeholder}
                onFocus={isSuggestableOnFocus ? onFocus : undefined}
                className={cn(classes.autosuggest__input, {
                    [`${classes.autosuggest__input_withOptions}`]: isSuggestedOptionVisible,
                })}
                onChange={onInput}
            />
            <div
                className={
                    cn(
                        classes.autosuggest__listContainer,
                        { [`${classes.autosuggest__listContainer_hide}`]: !isSuggestedOptionVisible },
                    )
                }
            >
                {
                    getSuggestedOptions().map(
                        (option) => (
                            <div
                                className={cn(classes.autosuggest__item)}
                                onClick={() => selectOption(option.id)}
                                key={option.id}
                            >
                                {formatOption(option)}
                            </div>
                        ),
                    )
                }
            </div>
        </div>
    );
}

function getOptionById(id: string, array: SourceTypeInterface[]) {
    return array.find(
        (element) => element.id === id,
    );
}

function useOnBlurHook(): [
    React.MutableRefObject<HTMLDivElement>,
    boolean,
    React.Dispatch<React.SetStateAction<boolean>>] {
    const targetElementRef = useRef<HTMLDivElement | undefined>(
        undefined,
    ) as React.MutableRefObject<HTMLDivElement>;
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        function handleClickOutside(event: Event) {
            if (targetElementRef.current
                && !targetElementRef.current.contains(event.target as Node)) {
                setIsVisible(false);
            }
        }
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [targetElementRef]);

    return [targetElementRef, isVisible, setIsVisible];
}
