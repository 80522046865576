import React, { useEffect, useRef, useState } from 'react';

import G6, { Algorithm, G6GraphEvent } from '@antv/g6';
import { sortBy } from 'lodash';

import {
    moduleStore,
    Meeting,
    Assignment,
    EvaluationPoint,
} from '@admin/NewModule/Store';

import { NodeTooltips } from './NodeTooltips';

export type Event = Meeting | Assignment | EvaluationPoint;

interface Options {
    layout: string;
    orientation: string;
    height: number | null;
    eventType: boolean;
}

interface Props {
    options: Options;
}

const MEETING = 'meeting';
const ASSIGNMENT = 'assignment';
const EVALUATION_POINT = 'evaluationPoint';

const MEETING_COLOR = '#CDF3A2';
const ASSIGNMENT_COLOR = '#A5FAF4';
const EVALUATION_POINT_COLOR = '#FADE5C';

const MEETING_STROKE_COLOR = '#97f032';
const ASSIGNMENT_STROKE_COLOR = '#20e6d8';
const EVALUATION_POINT_STROKE_COLOR = '#e6c119';

export function GraphView({ options }: Props): JSX.Element {
    const ref = useRef<null | HTMLDivElement>(null);
    let graph: any = null;

    const [showNodeTooltip, setShowNodeTooltip] = useState(false);
    const [nodeTooltipX, setNodeTooltipX] = useState(0);
    const [nodeTooltipY, setNodeTooltipY] = useState(0);
    const [activeNodeName, setActiveNodeName] = useState<string | undefined>('');
    const [activeNodeType, setActiveNodeType] = useState<string | undefined>('');

    const module = moduleStore.moduleModel;
    const events = sortBy(module.events, 'order');

    const meetingsAndAssignments = module.eventsWithoutTest;
    const evaluationPointNodes = module.evaluationPoints;

    const nodes = events.map((item: Event) => {
        let color = MEETING_COLOR;
        let stroke = MEETING_STROKE_COLOR;
        if (item.type === ASSIGNMENT) {
            color = ASSIGNMENT_COLOR;
            stroke = ASSIGNMENT_STROKE_COLOR;
        }
        if (item.type === EVALUATION_POINT) {
            color = EVALUATION_POINT_COLOR;
            stroke = EVALUATION_POINT_STROKE_COLOR;
        }
        const node = {
            id: item.id,
            label: item.topic?.replace(/^[ '"]+|[ '"]+$|( ){2,}/g, '$1').slice(0, 1),
            style: {
                fill: color,
                stroke,
            },
            cluster: item.type,
        };
        return node;
    });

    const meetingAndAssignmentEdges = meetingsAndAssignments
        .map((event: any) => event.dependentEvents.map((eventDep: any) => ({
            source: event.id,
            target: eventDep.id,
            label: '',
        }))).flat();
    const evaluationPointEdges = evaluationPointNodes
        .filter(point => !!point.previousEvent?.id)
        .map(point => ({
            source: point.previousEvent!.id,
            target: point.id,
            label: '',
        }));
    const edges = [...meetingAndAssignmentEdges, ...evaluationPointEdges];
    const data = {
        nodes,
        edges,
    };

    const legendArray = [
        {
            id: MEETING,
            label: 'Встреча',
            style: {
                fill: MEETING_COLOR,
            },
        },
        {
            id: ASSIGNMENT,
            label: 'Сам. работа',
            style: {
                fill: ASSIGNMENT_COLOR,
            },
        },
        {
            id: EVALUATION_POINT,
            label: 'Точка оценки',
            style: {
                fill: EVALUATION_POINT_COLOR,
            },
        },
    ];

    const legendData = {
        nodes: [...legendArray],
    };

    useEffect(() => {
        if (!graph) {
            const legend = new G6.Legend({
                data: legendData,
                align: 'center',
                layout: 'vertical',
                position: 'top-right',
                vertiSep: 12,
                horiSep: 0,
                offsetX: 131,
                offsetY: options.eventType ? -14 : -9999,
                padding: [8, 16, 8, 16],
                containerStyle: {
                    fill: '#fafafa',
                    stroke: EVALUATION_POINT_COLOR,
                    opacity: 0.9,
                    lineWidth: 2,
                    radius: 10,
                },
                title: 'Тип ивента',
                titleConfig: {
                    position: 'center',
                    offsetX: 0,
                    offsetY: 5,
                },
                filter: {
                    enable: true,
                    multiple: true,
                    trigger: 'click',
                    graphActiveState: 'activeByLegend',
                    graphInactiveState: 'inactiveByLegend',
                    filterFunctions: {
                        meeting: (d) => {
                            if (d.cluster === 'meeting') return true;
                            return false;
                        },
                        assignment: (d) => {
                            if (d.cluster === 'assignment') return true;
                            return false;
                        },
                        evaluationPoint: (d) => {
                            if (d.cluster === 'evaluationPoint') return true;
                            return false;
                        },
                    },
                },
            });
            // находим все пути, чтобы выделить из них самый длинный
            const { findAllPath }: any = Algorithm;
            const allPath = findAllPath(data, nodes[0].id, nodes[nodes.length - 1].id);
            // TODO rewrite `?? nodes.length`
            //  this affect to large screen and small picture
            const longestPath = allPath[allPath.reduce((
                prev: number,
                item: string[],
                index: number,
                array: string[],
            ) => (array[prev].length > item.length ? prev : index), 0)]?.length ?? nodes.length;

            graph = new G6.Graph({
                container: ref.current || '',
                width: 606,
                height: options.height || (longestPath * 40 + 100),
                fitView: true,
                fitCenter: true,
                fitViewPadding: [50, 0, 0, 0],
                layout: {
                    type: options.layout,
                    rankdir: options.orientation,
                    align: 'UR',
                    ranksep: 10,
                    nodesep: 10,
                },
                plugins: [legend],
                modes: {
                    default: [
                        'drag-canvas',
                        // 'zoom-canvas',
                        // 'drag-node',
                    ],
                },
                defaultNode: {
                    size: 20,
                    type: 'circle',
                    style: {
                        stroke: '#666',
                        lineWidth: 1,
                    },
                    labelCfg: {
                        style: {
                            fill: '#000',
                        },
                    },
                },
                defaultEdge: {
                    style: {
                        endArrow: {
                            path: 'M 0,0 L 8,4 L 8,-4 Z',
                            fill: '#e2e2e2',
                        },
                    },
                    labelCfg: {
                        autoRotate: true,
                    },
                },
                nodeStateStyles: {
                    hover: {
                        fillOpacity: 0.6,
                    },
                    activeByLegend: {
                        lineWidth: 2,
                        strokeOpacity: 3,
                    },
                    inactiveByLegend: {
                        opacity: 0.3,
                    },
                },
                edgeStateStyles: {
                    hover: {
                        opacity: 1,
                    },
                },
            });

            graph.data(data);
            graph.render();

            // listeners для узлов
            graph.on('node:mouseenter', (e: G6GraphEvent) => {
                const { item } = e;
                const model = item.getModel();
                const { x, y } = model;
                const point = graph.getCanvasByPoint(x, y);

                const activeNode = events.find((event: Event) => event.id === model.id);
                setActiveNodeName(activeNode?.topic);
                setActiveNodeType(activeNode?.type);

                setNodeTooltipX(point.x);
                setNodeTooltipY(point.y);
                setShowNodeTooltip(true);
                graph.setItemState(item, 'hover', true);
            });
            graph.on('node:mouseleave', (e: G6GraphEvent) => {
                const { item } = e;

                setShowNodeTooltip(false);
                graph.setItemState(item, 'hover', false);
            });

            // listeners для ребер
            graph.on('edge:mouseenter', (e: G6GraphEvent) => {
                const nodeItem = e.item;
                graph.setItemState(nodeItem, 'hover', true);
            });
            graph.on('edge:mouseleave', (e: G6GraphEvent) => {
                const nodeItem = e.item;
                graph.setItemState(nodeItem, 'hover', false);
            });
        }
        return () => {
            graph.destroy();
        };
    }, [options]);

    return (
        <div ref={ref}>
            {showNodeTooltip && (
                <NodeTooltips
                    x={nodeTooltipX}
                    y={nodeTooltipY}
                    nodeName={activeNodeName}
                    nodeType={activeNodeType}
                />
            )}
        </div>
    );
}
