import { makeAutoObservable } from 'mobx';
import { nanoid } from 'nanoid';

import { TeacherRole } from './moduleStoreTypes';
/* eslint import/no-cycle: "off" */
import { moduleStore } from './moduleStore';
import { Meeting } from './Meeting';

export class MeetingListModel {
    meetings: Meeting[] = [];

    constructor() {
        makeAutoObservable(this);
    }

    getMeeting = (id: string): Meeting | undefined => this.meetings.find(item => item.id === id);

    updateAllMeetings = (meetings: Meeting[]): void => {
        this.meetings = meetings;
    };

    updateMeeting = (id: string, data: Partial<Meeting>): void => {
        const meeting = this.getMeeting(id)!;
        Object.keys(data).forEach(key => {
            // @ts-ignore
            meeting[key] = data[key];
        });
    };

    addMeeting = (): void => {
        const module = moduleStore.moduleModel;
        const lastOrder = module.events.reduce((acc, { order }) => Math.max(acc, order), 0);

        const newMeeting = new Meeting();
        newMeeting.id = nanoid();
        newMeeting.order = lastOrder + 1;

        this.meetings.push(newMeeting);
    };

    removeMeeting = (id: string): void => {
        const module = moduleStore.moduleModel;
        const meeting = module.getMeeting(id);
        if (!meeting) return;
        const newMeetingsData = this.meetings.filter(item => item.id !== id);
        this.meetings = newMeetingsData;
        module.changeOrder(
            module.events.find(({ order }) => order === meeting.order + 1)?.id ?? '',
            meeting?.order,
        );
        const dependentPoints = module.evaluationPoints
            .filter(point => point.previousEvent?.id === id);
        dependentPoints.forEach((point, index) => {
            dependentPoints[index].previousEvent = undefined;
        });
    };

    updateAllMeetingRoles = (): void => {
        this.meetings.forEach(meeting => {
            this.updateMeeting(meeting.id, {
                meetingTeacherRoles: this.getMeetingRoles(meeting.id),
                meetingIdForTeacherStaff: this.getMeetingIdForTeacherStaff(meeting),
            });
        });
    };

    getMeetingRoles = (id: string): TeacherRole[] => {
        const meeting = this.getMeeting(id);
        const meetingIdForTeacherStaff = meeting?.meetingIdForTeacherStaff ?? '';
        const hasCollision = this.hasRolesCollision(id, meetingIdForTeacherStaff);
        if (hasCollision) return [];
        if (meetingIdForTeacherStaff) return this.getMeetingRoles(meetingIdForTeacherStaff);

        return meeting?.meetingTeacherRoles!;
    };

    hasRolesCollision = (meetingId: string, selectMeetingId: string): boolean => {
        const selectMeeting = this.getMeeting(selectMeetingId);
        const isCollision = meetingId === selectMeeting?.meetingIdForTeacherStaff;
        if (isCollision) return true;
        if (!selectMeeting?.meetingIdForTeacherStaff) return false;

        return this.hasRolesCollision(meetingId, selectMeeting.meetingIdForTeacherStaff!);
    };

    updateMeetingTeacherRoles(id: string): void {
        const meeting = this.getMeeting(id);
        meeting!.meetingTeacherRoles = moduleStore
            .removeNonexistentRoles(meeting!.meetingTeacherRoles);
    }

    private getMeetingIdForTeacherStaff(meeting: Meeting): string | undefined {
        const hasCollision = this.hasRolesCollision(
            meeting.id, meeting.meetingIdForTeacherStaff ?? '',
        );
        return hasCollision ? '' : meeting.meetingIdForTeacherStaff;
    }
}
