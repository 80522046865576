import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { nanoid } from 'nanoid';

import { IconDeprecated } from '@common/IconDeprecated';
import { Alert } from '@common/Alert';
import { BorderPlusIcon } from '@common/svg';

import { SkillLevelEditing } from '@admin/SkillPage/SkillLevelEditing';

import NestedSkill from '../../SkillAddPage/store/NestedSkill';
import { checkChild, getSkillTypeById } from '../../SkillAddPage/SkillAddWithNest/SkillAddFunctions';
import { skillTypesData_skillTypes } from '../../../../deprecated-graphql-query-types';

import classes from './EditForm.module.scss';

interface Props {
    currentSkill: any;
    currentSkillType: skillTypesData_skillTypes;
    skillTypes: skillTypesData_skillTypes[];
}

export const EditForm = observer(({
    currentSkill,
    currentSkillType,
    skillTypes,
}: Props) => {
    const [active, setActive] = useState(0);
    const [error, setError] = useState('');

    const { skillType } = getSkillTypeById(skillTypes, currentSkill.typeId);
    const { hasChild, skillChild } = checkChild(skillTypes, currentSkillType.id);
    const hasLevel = currentSkillType.properties?.maxLevel !== null;
    const nameError = 'Напишите название образовательного результата';

    const blurHandler = (item: any) => {
        if (item.name === '') {
            NestedSkill.notValid.set(item.id, true);
        }
    };

    const onChangeName = (currentTarget: any, item: any) => {
        if (!currentTarget.value) {
            NestedSkill.notValid.set(item.id, true);
        } else {
            NestedSkill.notValid.set(item.id, false);
        }
        NestedSkill.update(item.id, {
            name: currentTarget.value,
            typeId: currentSkillType.id,
        });
    };

    const addChild = () => {
        if (!hasChild || currentSkill.children[active]?.name !== '') {
            NestedSkill.addSkill(currentSkill.id, {
                id: nanoid(),
                name: '',
                typeId: currentSkillType.id,
            });
            setActive(currentSkill.children?.length - 1);
        } else {
            setError(`Сначала введите имя текущего ${currentSkillType.name.english}!`);
        }
    };

    const deleteChild = (id: string) => {
        NestedSkill.removeSkill(id, currentSkill.id);
        if (active !== 0) {
            setActive(active - 1);
        }
    };
    let str = '';
    if (currentSkill.children?.length > 0) {
        str = `${currentSkillType.name.nominativePlural} ${skillType.name.genitiveSingular.toLowerCase()}:`;
    }
    return (
        <div className={classes['skill-add-with-nest']}>
            <div className={classes['skill-add-with-nest__thame']}>
                {str}
            </div>
            {currentSkill.children?.map((item: any, index: number) => (
                // eslint-disable-next-line react/no-array-index-key
                <div key={item.id} className={classes['skill-add-with-nest__item']}>
                    <div className={classes['skill-with-nest__head']}>
                        <div className={classes['skill-add-with-nest__head-number']}>
                            {index + 1}
                        </div>
                        <div
                            className={
                                active === index || !hasChild
                                    ? classes['skill-add-with-nest__head-title--hidden']
                                    : classes['skill-add-with-nest__head-title']
                            }
                            onClick={() => {
                                if (currentSkill.children?.[active].name === '') {
                                    setError(`Сначала введите имя текущего ${currentSkillType.name.english}!`);
                                } else {
                                    setActive(index);
                                }
                            }}
                        >
                            {item.name}
                        </div>
                        <div className={classes['skill-with-neat__button-delete']}>
                            <IconDeprecated
                                id="Cross"
                                className={classes['yellow-plus-26px']}
                                click={() => deleteChild(item.id)}
                            />
                        </div>
                    </div>
                    <div className={classes['skill-add-with-nest__input-block']}>
                        <div
                            className={
                                active === index || !hasChild
                                    ? classes.item__input__group
                                    : classes['skill-with-nest__hidden']
                            }
                        >
                            <div className={classes['skill-add-with-nest__section-wrapper']}>
                                <div>
                                    <div className={classes.item__input__group}>
                                        <label className={classes['skill-add-with-nest__label']}>
                                            {!NestedSkill.notValid.get(item.id) ? (
                                                <div>
                                                    Название {(
                                                        currentSkillType.name.genitiveSingular
                                                    )?.toLowerCase()}:
                                                </div>
                                            ) : (
                                                <div style={{ color: 'red', width: '130%' }}>
                                                    {nameError}
                                                </div>
                                            )}
                                            <input
                                                className={!NestedSkill.notValid.get(item.id)
                                                    ? classes['skill-add-with-nest__input']
                                                    : classes['skill-add-with-nest__input--red-border']
                                                }
                                                value={item.name}
                                                onBlur={() => blurHandler(item)}
                                                onChange={
                                                    ({
                                                        currentTarget,
                                                    }) => onChangeName(currentTarget, item)
                                                }
                                            />
                                        </label>
                                    </div>
                                </div>
                            </div>
                            {hasLevel && (
                                <SkillLevelEditing
                                    currentLevel={item.maxLevel}
                                    maxLevel={Number(currentSkillType.properties!.maxLevel)}
                                    onChange={maxLevel => NestedSkill
                                        .update(item.id, { maxLevel })}
                                />
                            )}
                            {hasChild ? (
                                <EditForm
                                    currentSkill={item}
                                    currentSkillType={skillChild}
                                    skillTypes={skillTypes}
                                />
                            ) : null}
                        </div>
                    </div>
                </div>
            ))}
            <div className={classes['skill-with-nest__bottom-icon-container']} onClick={() => addChild()}>
                <BorderPlusIcon />
                <div className={classes['skill-with-nest__icon-text']}>
                    {`${currentSkillType.name.nominativeSingular}`}
                </div>
            </div>

            {error && <Alert message={error} onTimeout={() => setError('')} />}
        </div>
    );
});
