import React, { useRef } from 'react';

import { Icon } from '@admin/NewModule/CustomIcon';
import { ActionButton, ActionTypeOfButton } from '@common/ActionButton';
import useOnClickOutside from '../../account/schedule/Hooks/useOnClickOutside';

import classes from './Confirm.module.scss';

interface Props {
    headerText: string;
    descriptionText: string;
    secondaryBtnText?: string;
    primaryBtnText: string;

    onSecondaryBtnClick(): void;

    onPrimaryBtnClick(): void;
    onOutClick?: () => void;
}

export function Confirm({
    headerText,
    descriptionText,
    secondaryBtnText,
    primaryBtnText,
    onSecondaryBtnClick,
    onPrimaryBtnClick,
    onOutClick,
}: Props): JSX.Element {
    const refModal = useRef<null | HTMLDivElement>(null);
    if (onOutClick) {
        useOnClickOutside(refModal, onOutClick);
    }
    return (
        <div className={classes['transparent-layer']}>
            <div className={classes.wrapper}>
                <section className={classes.dialog} ref={refModal}>
                    <div className={classes.header__button}>
                        <Icon id="blackCross" onClick={onSecondaryBtnClick} />
                    </div>
                    <h4 className={classes.dialog__header}>{headerText}</h4>
                    <p className={classes.dialog__description}>{descriptionText}</p>
                    <div className={classes.dialog__button}>
                        {secondaryBtnText && (
                            <ActionButton
                                onClick={onSecondaryBtnClick}
                                actionType={ActionTypeOfButton.SECONDARY}
                            >
                                {secondaryBtnText}
                            </ActionButton>
                        )}
                        <ActionButton
                            onClick={onPrimaryBtnClick}
                        >
                            {primaryBtnText}
                        </ActionButton>
                    </div>
                </section>
            </div>
        </div>
    );
}
