import React, { useState } from 'react';

import { RadioList } from '@admin/Users/RadioList';
import { ToolButton } from '../../ToolButton';
import { Type, UserStatus } from '../apollo-types';

interface Props {
    onSubmit(status: UserStatus, types: Type[]) : void;
}

const statusOptions = [
    {
        type: 'status',
        name: 'Активен',
        value: UserStatus.active,
    },
    {
        type: 'status',
        name: 'Неактивен',
        value: UserStatus.inactive,
    },
];

export const StatusButtonToolBar = ({ onSubmit }: Props): JSX.Element => {
    const [status, setStatus] = useState<UserStatus>();
    const [types, setTypes] = useState<Type[]>([]);
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        setStatus(value as UserStatus);
    };

    const handleChangeType = (value: Type) => {
        if (types.includes(value)) {
            const selectedTypes = types.filter((item) => item !== value);
            setTypes(selectedTypes);
        } else {
            setTypes([...types, value]);
        }
    };

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        if (status && types) onSubmit(status, types);
        setStatus(undefined);
        setTypes([]);

        (event.target as HTMLFormElement).reset();
    };

    return (
        <>
            <ToolButton
                text="Роль в системе"
                icon="editPenBlack"
                onSubmit={handleSubmit}
                contextMenu={(
                    <RadioList
                        listOptions={statusOptions}
                        type="status"
                        checked={status}
                        onChange={handleChange}
                        onChangeType={handleChangeType}
                    />
                )}
                modifier="add_border_fixSize"
            />
        </>
    );
};
