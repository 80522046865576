import { DocumentNode, TypedDocumentNode, OperationVariables, gql } from '@apollo/client';

export type ApolloQuery<SourceType> = DocumentNode | TypedDocumentNode<{
    options: SourceType[];
}, OperationVariables>;

export interface SourceTypeInterface {
    id: string,
}

export interface PaginationApolloProps {
    className?: string,
    pagesCount?: number,
    currentPage?: number,
    getPagesCountQuery?: DocumentNode,
    goPageQuery?: DocumentNode,
    urlParameterName?: string,
    forcePage?: number,
    goPage?(page: number): void,
}

export interface PaginationProps {
    className?: string,
    pagesCount: number,
    currentPage?: number,
    urlParameterName?: string,
    forcePage?: number,
    goPage(page: number): void,
}

export const defaultQuery = gql`query {defaultQuery {defaultValue}}`;
