import React, { ChangeEvent, useEffect, useState } from 'react';

import { BorderPlusIcon } from '@common/svg';
import { IconDeprecated } from '@common/IconDeprecated';
import { Suggestion } from '@common/SuggestionDeprecated';

import { groupBy, range } from 'lodash';

import classes from './CurrentProfileParams.module.scss';

import {
    skills_skills,
    skillTypesData_skillTypes,
} from '../../../deprecated-graphql-query-types';

interface Skill {
    id: string
    name: string
    fullName: string
    typeId: string
    maxLevel?: number
    description?: string
    children?: Skill[]
    dependencies?: Skill[]
}

interface SubspaceSkill {
    skill: Skill
    level: number
}

interface Props {
    currentSkillType: skillTypesData_skillTypes;
    forSug: number;
    notSortedSkillTypes: skillTypesData_skillTypes[];
    allSkillsList: skills_skills[];
    profileSkillsList: SubspaceSkill[];
    skillsParamsType: string;
    setProfileSkills: (arr: SubspaceSkill[]) => void;
}

export function CurrentProfileParams({
    currentSkillType,
    forSug,
    notSortedSkillTypes,
    allSkillsList,
    profileSkillsList,
    skillsParamsType,
    setProfileSkills,
}: Props): JSX.Element {
    const [skillName, setSkillName] = useState<string>('');
    const [skillId, setSkillId] = useState<string>('');
    const [skillTypeId, setSkillTypeId] = useState<string>('');
    const [skillLvl, setSkillLvl] = useState<number>(1);
    const getType = (typeId: string) => notSortedSkillTypes.find(({ id }) => id === typeId);
    const getFinalSkillTypeId = (typeId: string): string => {
        const skillTypeGroups = groupBy(notSortedSkillTypes, 'parentId');
        let finalSkillTypeId = typeId;
        while (skillTypeGroups[finalSkillTypeId]) {
            const [child] = skillTypeGroups[finalSkillTypeId];
            finalSkillTypeId = child.id;
        }

        return finalSkillTypeId;
    };
    const finalSkillType = getType(getFinalSkillTypeId(currentSkillType.id));
    const [currentSkillMaxLvl, setCurrentSkillMaxLvl] = useState<number>(
        finalSkillType?.properties!.maxLevel!,
    );
    const getSkillsForType = (typeId: string): any => {
        const finalSkillTypeId = getFinalSkillTypeId(typeId);

        return allSkillsList
            .filter(skill => skill.typeId === finalSkillTypeId)
            .map(skill => ({ ...skill, typeId }));
    };

    const filteredSkills = getSkillsForType(currentSkillType.id);

    const skillsFromBase: string[] = filteredSkills.map((item: any) => item.fullName);

    const currentTypeSkillsArray: any = [];
    profileSkillsList.forEach((item: any) => {
        if (getFinalSkillTypeId(item.skill.typeId) === currentSkillType.id
            && item.skill.typeId === currentSkillType.id) {
            currentTypeSkillsArray.push(item);
        }
        if (getFinalSkillTypeId(item.skill.typeId) === getFinalSkillTypeId(currentSkillType.id)
            && getFinalSkillTypeId(item.skill.typeId) !== currentSkillType.id
        ) {
            currentTypeSkillsArray.push({
                skill: {
                    id: item.skill.id,
                    name: item.skill.fullName,
                    fullName: item.skill.fullName,
                    typeId: item.skill.typeId,
                },
                level: item.level,
            });
        }
    });

    const arrayForSuggestion: string[] = currentTypeSkillsArray
        .map((item: any) => item.skill.name);
    const addingParams = () => {
        let flag = false;
        skillsFromBase.forEach((item) => {
            if (skillName.trim() === item.trim()) {
                flag = true;
            }
        });
        if (!flag) {
            setSkillName('');
            setSkillLvl(1);
            return;
        }
        const params: any = {
            skill: {
                id: skillId,
                name: skillName.trim(),
                fullName: skillName.trim(),
                typeId: skillTypeId,
            },
            level: skillLvl,
        };
        setProfileSkills([...profileSkillsList, params]);
        setSkillName('');
        setSkillLvl(1);
    };

    const deleteParams = (id: string) => {
        const array = profileSkillsList.slice();
        profileSkillsList
            .forEach((item: any, index: number) => {
                if (item.skill.id === id) {
                    array.splice(index, 1);
                }
            });
        setProfileSkills([...array]);
    };
    useEffect(() => {
        filteredSkills.forEach((item: any) => {
            if (item.fullName === skillName) {
                setCurrentSkillMaxLvl(item.maxLevel!);
                setSkillId(item.id);
                setSkillTypeId(item.typeId);
            }
        });
    }, [skillName]);
    const prerequisiteSkillsText = ', которые необходимы для попадания в подпространство:';
    const outputSkillsText = ', которые дает подпространство при прохождении:';
    const textForSkillType = skillsParamsType === 'prerequisite' ? prerequisiteSkillsText : outputSkillsText;
    return (
        <div className={classes.subSpaceCurrentProfileParams}>
            <div className={classes.subSpaceCurrentProfileParams__tittle}>
                {currentSkillType.name.genitiveSingular}
                {textForSkillType}
            </div>
            <div className={classes.subSpaceCurrentProfileParams__form}>
                <Suggestion
                    array={skillsFromBase}
                    addedElementArray={arrayForSuggestion}
                    stateValue={skillName}
                    setState={(name: string) => setSkillName(name)}
                    placeholder={`Название ${currentSkillType.name.genitiveSingular?.toLocaleLowerCase()}`}
                    forMany={forSug}
                />
                {finalSkillType?.properties!.maxLevel !== null ? (
                    <div className={classes.subSpaceCurrentProfileParams__formLvl}>
                        Уровень {currentSkillType.name.genitiveSingular?.toLocaleLowerCase()}:
                        <select
                            className={classes.subSpaceCurrentProfileParams__formSelect}
                            value={skillLvl}
                            onChange={
                                (e: ChangeEvent<HTMLSelectElement>) => setSkillLvl(
                                    Number(e.currentTarget.value),
                                )
                            }
                        >
                            {/* <option value={0} disabled>0</option> */}
                            {range(1, currentSkillMaxLvl + 1).map(value => (
                                <option key={value} value={value}>
                                    {value}
                                </option>
                            ))}
                        </select>
                        <BorderPlusIcon handler={addingParams} />
                    </div>
                ) : (
                    <div className={classes.subSpaceCurrentProfileParams__soloPlus}>
                        <BorderPlusIcon handler={addingParams} />
                    </div>
                )
                }
            </div>
            <ul className={classes.subSpaceCurrentProfileParams__addedParamsList}>
                {currentTypeSkillsArray
                    .map((item: any) => (
                        <li
                            key={item.skill.id}
                            className={classes.subSpaceCurrentProfileParams__addedParamsItem}
                        >
                            {item.skill.name}
                            {finalSkillType?.properties?.maxLevel !== null && (
                                <div className={
                                    classes.subSpaceCurrentProfileParams__addedParamsItemLvl}
                                >
                                    {item.level}
                                </div>
                            )}
                            <div
                                className={classes.subSpaceCurrentProfileParams__iconContainer}
                            >
                                <IconDeprecated
                                    id="Cross"
                                    className={classes.greyCross_18}
                                    click={() => deleteParams(item.skill.id)}
                                />
                            </div>
                        </li>
                    ))}
            </ul>
        </div>
    );
}
