import React, { Fragment } from 'react';
import { observer } from 'mobx-react';
import cn from 'classnames';
import { AssignmentInstanceStatusRU } from '../../interfaces';
import { assignmentsStore } from '../../store/AssignmentsStore';
import classes from './Tabs.module.scss';

export const Tabs = observer(() => {
    const assignmentStatusTabs = Object.entries(AssignmentInstanceStatusRU);
    const { getAssignmentStatus, setAssignmentStatus } = assignmentsStore;

    return (
        <section className={classes.Tabs}>
            {
                assignmentStatusTabs.map(tab => {
                    const [id, value] = tab;
                    const isActive = getAssignmentStatus() === id.toLowerCase();
                    return (
                        <Fragment key={id}>
                            <input
                                className={cn(classes.Tabs__input, {
                                    [classes.Tabs__input_active]: isActive,
                                })}
                                type="radio"
                                id={id}
                                name="assignments"
                                onChange={() => setAssignmentStatus(id)}
                            />
                            <label
                                className={classes.Tabs__title}
                                htmlFor={id}
                            >
                                {value}
                            </label>
                        </Fragment>

                    );
                })
            }
        </section>
    );
});
