import React from 'react';

import { HatIcon } from '@common/svg/HatIcon';

import {
    filteredTeacherAssignmentInstances_filteredTeacherAssignmentInstances_assignmentInstance,
} from '../../TeacherAssignmentsApolloTypes';

import classes from './TeacherAssignmentMessage.module.scss';

interface Props {
    // eslint-disable-next-line max-len
    assignmentInstance?: filteredTeacherAssignmentInstances_filteredTeacherAssignmentInstances_assignmentInstance,
}

const iconSizeInPX = 54;

export const TeacherAssignmentMessage = ({ assignmentInstance }: Props) => (
    <>
        {
            assignmentInstance?.teacherAssignmentInstances.map(({
                note,
                teacher: {
                    user: {
                        lastName,
                        firstName,
                        patronymic,
                    },
                },
            }) => note !== null && (
                <div className={classes.teacherAssignment__message} key={`${note} + ${lastName}`}>
                    <div className={classes.teacherAssignment__profile}>
                        <HatIcon width={iconSizeInPX} height={iconSizeInPX} />

                        <div className={classes.teacherAssignment__profileWrapper}>
                            <h3 className={classes.teacherAssignment__name}>
                                {lastName} {firstName} {patronymic}
                            </h3>
                        </div>
                    </div>

                    <div className={classes.teacherAssignment__text}>
                        {note}
                    </div>
                </div>
            ))
        }
    </>
);
