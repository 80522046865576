import React from 'react';
import cn from 'classnames';
import classes from './TeacherAssignmentsToggle.module.scss';

interface Props {
    label?: string;
    checked?: boolean;
    className?: string;
    onClick?(): void;
}

export function TeacherAssignmentsToggle({
    label,
    checked,
    className,
    onClick,
}: Props): JSX.Element {
    return (
        <label className={cn(classes.switcher, className)}>
            <input type="checkbox" checked={checked} readOnly />

            <div
                className={cn(classes.switcher__slider, {
                    [classes.switcher__slider_withLabel]: label,
                })}
                onClick={onClick}
            >
                <div className={classes.switcher__sliderBg} />
            </div>
            {
                label && (
                    <div className={classes.switcher__text} onClick={onClick}>
                        {label}
                    </div>
                )
            }
        </label>
    );
}
