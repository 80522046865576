import React, { SetStateAction } from 'react';
import { gql, useQuery } from '@apollo/client';

import { EducationSubspaceEdit } from './EducationSubspaceEdit';

interface UserBack {
    id: string;
    lastName: string;
    firstName: string;
    patronymic: string;
}

interface User {
    id: string;
    name: string;
}

interface Subspace {
    id: string;
    name: string;
    ownerId: string;
    ownerFullName: string;
    typeId: string;
}

interface Props {
    index: number;
    subspaces: Subspace[];
    typeId: string;
    setSubspaces: React.Dispatch<SetStateAction<Subspace[]>>;
    setActiveIndex(arg0: number): void;
    createSubspace: (
        id: string,
        name: string,
        ownerId: string,
        typeId: string,
    ) => void;
    removeSubspace: (
        id: string,
    ) => void;
}

const GET_USERS = gql`
    query users {
        users(findParams: {userType: all}) {
            list {
                id
                lastName
                firstName
                patronymic
            }
        }
    }
`;

export const EducationSubspaceEditApollo = ({
    index,
    subspaces,
    typeId,
    setSubspaces,
    setActiveIndex,
    createSubspace,
    removeSubspace,
}: Props) => {
    const {
        error,
        loading,
        data,
    } = useQuery(GET_USERS);

    if (loading) return <></>;
    if (error) return <>`Error! ${error.message}`</>;

    const users: User[] = data?.users.list.map((user: UserBack) => (
        {
            id: user.id,
            name: `${user.lastName ?? ''} ${user.firstName ?? ''} ${user.patronymic ?? ''}`,
        }
    ));

    return (
        <EducationSubspaceEdit
            index={index}
            users={users}
            typeId={typeId}
            subspaces={subspaces}
            setSubspaces={setSubspaces}
            setActiveIndex={setActiveIndex}
            createSubspace={createSubspace}
            removeSubspace={removeSubspace}
        />
    );
};
