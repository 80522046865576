import { makeAutoObservable } from 'mobx';

import { AnyEvent, StudentPriority } from './Config/interfaces';
import { SelectionStatus } from './graphql-query-types';

export interface InputFork {
    forkId: string;
    slots: {
        slotId: string;
        priority: number;
    }[];
}

export enum PrioritySetMode {
    UPDATE = 'update',
    DELETE = 'delete',
}

interface ForkPriority {
    forkId: string;
    priorityCount: number;
}

class EventStore {
    baseTimeIntervalSelectionStatus: SelectionStatus = SelectionStatus.selectionPending;

    selectedEvent: AnyEvent = null;

    forks: InputFork[] = [];

    forkPriorityCount: ForkPriority[] = [];

    studentPriorities: StudentPriority[] = [];

    constructor() {
        makeAutoObservable(this);
    }

    addFork(fork: InputFork) {
        if (!this.getFork(fork.forkId)) {
            this.forks.push(fork);
        }
    }

    sortForkSlotsByPriorities() {
        this.forks.forEach(({ forkId }) => this.getFork(forkId)?.slots.sort((a, b) => {
            if (a.priority === 0) {
                return 0;
            }
            if (b.priority === 0) {
                return -1;
            }
            return a.priority - b.priority;
        }));
    }

    getRemainingPriorities(id = '') {
        const forkSettingCount = this.getForkPriorityCount(id);
        const forkSelectedSlotsCount = this.getSelectedSlotsCount(id);
        return forkSettingCount - forkSelectedSlotsCount;
    }

    getForkPriorityCount(id = '') {
        const fork = this.forkPriorityCount.find(({ forkId }) => forkId === id);
        return fork?.priorityCount ?? 0;
    }

    getSelectedSlotsCount(id = '') {
        const forkSelectedSlotsCount = this.getFork(id)?.slots
            .filter(slot => slot.priority > 0).length ?? 0;
        return forkSelectedSlotsCount;
    }

    getFork(id: string) {
        return this.forks.find(({ forkId }) => forkId === id);
    }

    getFilteredForks() {
        return this.forks.map(item => ({
            ...item,
            slots: item.slots.filter(slot => slot.priority !== 0),
        }));
    }

    isChocePointFinished() {
        const forksWhithPriority = this.forks.map(fork => ({
            ...fork,
            slots: fork.slots.filter(slot => slot.priority > 0),
        }));
        return !forksWhithPriority
            .map(fork => fork.slots.length === this.getForkPriorityCount(fork.forkId))
            .includes(false);
    }

    setStudentPriorities(studentPriorities: StudentPriority[]) {
        this.studentPriorities = studentPriorities;
    }

    setSelectedEvent(event: AnyEvent): void {
        this.selectedEvent = event;
    }

    setPriority(forkId = '', slotId = '', priority: number) {
        const fork = this.getFork(forkId);
        const slot = this.getSlot(forkId, slotId);
        const currentSlot = fork?.slots.find((item) => item.priority === priority);
        if (currentSlot
            && currentSlot?.priority >= 0
            && currentSlot.slotId !== slotId) {
            currentSlot.priority = 0;
        }
        if (slot && slot?.priority >= 0) {
            slot.priority = priority;
        }
    }

    setBaseTimeIntervalSelectionStatus(status: SelectionStatus) {
        this.baseTimeIntervalSelectionStatus = status;
    }

    updateForkPriorityCount(fork: ForkPriority) {
        if (!this.forkPriorityCount.find(({ forkId }) => forkId === fork.forkId)) {
            this.forkPriorityCount.push(fork);
        }
    }

    updateForksOnStudentPriorities() {
        if (this.studentPriorities.length > 0) {
            this.studentPriorities
                .forEach(({ forkId, slotId, priority }) => {
                    this.setPriority(forkId, slotId, priority);
                });
        }
        const allSelectedSlots = this.studentPriorities.map(({ slotId }) => slotId);
        this.forks.forEach((fork) => {
            fork.slots.forEach((slot) => {
                if (!allSelectedSlots.includes(slot.slotId)) {
                    const removedSlotPriority = this.getSlot(fork.forkId, slot.slotId);
                    removedSlotPriority!.priority = 0;
                }
            });
        });
    }

    isPrioritySelected(forkId = '', slotId = '', priority: number) {
        return !!this.getFork(forkId)?.slots
            .find(slot => slot.slotId !== slotId && priority === slot.priority);
    }

    getSlot(forkId = '', slotId = '') {
        return this.getFork(forkId)?.slots.find(slot => slot.slotId === slotId);
    }
}

export default new EventStore();
