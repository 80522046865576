import React from 'react';
import { observer } from 'mobx-react';

import { FilledPlusIcon } from '@common/svg';
import { Button } from '@admin/NewModule/CustomButton';
import { moduleStore } from '@admin/NewModule/Store';

import classes from './ActionButton.module.scss';

export enum State {
    Start,
    Choose,
    ChooseEventForEditConnection,
    EditEventConnections,
}

const IconCancel = ({ title }: { title?: string }) => (
    <div className={classes.actionButton__circle}>
        <FilledPlusIcon />
        <span>{title}</span>
    </div>
);

interface Props {
    state: State;

    setOpened(id: string): void;
    setState(state: State): void;
}

export const ActionButton = observer(({
    state, setState, setOpened,
}: Props) => {
    const module = moduleStore.moduleModel;
    const { events, eventsWithoutTest, evaluationPoints } = module;

    const addMeeting = () => {
        module.addMeeting();
        setState(State.Start);
    };
    const addEvaluationPoint = () => {
        module.addEvaluationPoint();
        setState(State.Start);
    };
    const addAssignment = () => {
        module.addAssignment();
        setState(State.Start);
    };
    const chooseConnections = () => {
        setOpened('');
        setState(State.ChooseEventForEditConnection);
    };
    const refreshDefaultEvaluationPointSkills = () => {
        module.evaluationPointList.refreshDefaultEvaluationPointSkills();
        setState(State.Start);
    };

    if (state === State.Start) {
        return (
            <div className={classes.actionButton}>
                <Button modifier="add" onClick={() => setState(State.Choose)}>
                    {events.length === 0
                        ? (
                            <div className={classes.actionButton__circle}>
                                <FilledPlusIcon />
                                <span>Создайте встречи и самостоятельные работы</span>
                            </div>
                        ) : (
                            <div className={classes.actionButton__singleCircle}>
                                <FilledPlusIcon />
                            </div>
                        )}
                </Button>
            </div>
        );
    }
    if (state === State.Choose) {
        return (
            <div className={classes.actionButton__buttons}>
                <div className={classes.actionButton__buttons__removeButton}>
                    <Button modifier="add" onClick={() => setState(State.Start)}>
                        <IconCancel />
                    </Button>
                </div>
                <div className={classes.actionButton__eventsButtons}>
                    <Button modifier="add" onClick={addMeeting}>
                        <span className={classes.actionButton__eventButton}>Встреча</span>
                    </Button>
                    <Button modifier="add" onClick={addAssignment}>
                        <span className={classes.actionButton__eventButton}>
                            Сам. работа
                        </span>
                    </Button>
                </div>
                {events.length > 0 && (
                    <div className={classes.actionButton__connectionsButtons}>
                        <Button modifier="add" onClick={addEvaluationPoint}>
                            <span className={classes.actionButton__connectionButton}>
                                Точка оценки
                            </span>
                        </Button>
                        {eventsWithoutTest.length > 1 && (
                            <Button modifier="add" onClick={chooseConnections}>
                                <span className={classes.actionButton__connectionButton}>
                                    Установить связи
                                </span>
                            </Button>
                        )}
                        {!!evaluationPoints.length && (
                            <Button modifier="add" onClick={refreshDefaultEvaluationPointSkills}>
                                <span className={classes.actionButton__connectionButton}>
                                    Доставить неоцененные образовательные результаты
                                    в точки оценки
                                </span>
                            </Button>
                        )}
                    </div>
                )}
            </div>
        );
    }
    if (state === State.ChooseEventForEditConnection) {
        return (
            <div className={classes.actionButton}>
                <Button modifier="add" onClick={() => setState(State.Start)}>
                    <IconCancel title="Завершить установку связей" />
                </Button>
            </div>
        );
    }
    if (state === State.EditEventConnections) {
        return (
            <div className={classes.actionButton__saveConnection}>
                <Button
                    modifier="undesirable"
                    onClick={() => {
                        setState(State.ChooseEventForEditConnection);
                        module.updateDependentEventsInEditState();
                    }}
                >
                    Сохранить связь
                </Button>
            </div>
        );
    }

    return <></>;
});
