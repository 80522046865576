import React from 'react';

import classes from './EducationSpaceInfo.module.scss';

export function StudentPickingSelect(): JSX.Element {
    return (
        <div className={classes.studentPicking}>
            <div className={classes.studentPicking__label}>
                Как отбирать студентов
            </div>
            <select
                className={classes.studentPicking__select}
            >
                <option value="1">Случайно</option>
                <option value="2">По баллам</option>
                <option value="3">Другое</option>
            </select>
        </div>
    );
}
