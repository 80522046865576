import React, { useState } from 'react';
import { gql, useQuery } from '@apollo/client';

import { EducationPeriodStatistic } from '@admin/EducationPeriodStatistic/EducationPeriodStatistic';
import { Loader } from '@common/Loader';
import {
    NotVotedStudent, StudentPriority,
    StudentsModulesPriorities, StudentRating, NotVotedQueryVariables,
} from '@admin/EducationPeriodStatistic/EducationPeriodStatisticInterfaces';
import { educationPeriodStatisticStore } from '@admin/EducationPeriodStatistic/EducationPeriodStatisticStore';
import { Alert } from '@common/Alert';
import {
    Space,
} from './EducationPeriodStatisticInterfaces';

const GET_SPACES = gql`
    query getSpaces {
        spaces {
          id
          name
          spaceBaseTimeIntervals {
            id
            order
            spaceBaseTimeIntervalSubspaces {
                subspace {
                    id
                    name
                }
                forks {
                    id
                    setting {
                        distributionType
                    }
                }
            }
            baseTimeIntervalInstances {
                id
                selectionStatus
            }
          }
          spaceEducationPeriods {
            id
            name
            spaceBaseTimeIntervalParameters {
              spaceBaseTimeIntervalId
              baseTimeIntervalInstanceId
              subspaceSelectionStartDate
              subspaceSelectionEndDate
              moduleSelectionStartDate
              moduleSelectionEndDate
              spaceBaseTimeIntervalStart
              spaceBaseTimeIntervalEnd
              moduleAssessment {
                  id
                  isDynamicStartDate
                  startDate
                  endDate
              }
            }
          }
        }
      }
`;

const GET_NOT_VOTED_STUDENTS = gql`
    query getNotVotingForModuleSelectionStudents(
        $NotVotingForModuleSelectionStudentsInput: NotVotingForModuleSelectionStudentsInput!
    ) {
        notVotingForModuleSelectionStudents(
            notVotingStudentsInput: $NotVotingForModuleSelectionStudentsInput
        ) {
            id
            user {
                id
                firstName
                lastName
                patronymic
            }
        }
    }
`;

const GET_STUDENTS_MODULE_PRIORITIES = gql`
    query getStudentsModulePriorities(
        $StudentModulePriorityGroupsInput: StudentModulePriorityGroupsInput!
    ) {
        studentModulePriorityGroups(
            studentModulePriorityGroupsInput: $StudentModulePriorityGroupsInput
        ) {
            module {
                id
                name
            }
            priorityGroups {
                priority
                students {
                    id
                    user {
                        id
                        firstName
                        lastName
                        patronymic
                    }
                }
            }
        }
    }
`;

const GET_STUDENTS_PRIORITIES = gql`
    query getStudentPriorities($StudentsPrioritiesInput: StudentsPrioritiesInput!) {
        studentsPriorities(studentsPrioritiesInput: $StudentsPrioritiesInput) {
            studentId
            moduleId
            priority
        }
    }
`;

const GET_ALL_STUDENTS_RATING = gql`
    query getStudentsRatings($studentsRatingInput: StudentsRatingInput!) {
        studentsRating(studentsRatingInput: $studentsRatingInput) {
            student {
                id
            }
            value
        }
    }
`;

const GET_PREVIOUS_BTI_INSTANCE_ID = gql`
    query PreviousBaseTimeIntervalInstance(
        $baseTimeIntervalInstanceId: String!,
    ) {
        previousBaseTimeIntervalInstance(baseTimeIntervalInstanceId: $baseTimeIntervalInstanceId) {
            id
        }
    }
`;

export function EducationPeriodStatisticApollo() {
    const params = new URLSearchParams(window.location.search);
    const forkId = window.location.pathname.split('/')[2];
    const btiInstance = params.get('btiIId') ?? '';

    const statisticQueryVariables : NotVotedQueryVariables = {
        baseTimeIntervalInstanceId: btiInstance,
        forkId,
    };
    const [previousBTIInstanceId, setPreviousBTIInstanceId] = useState<string | null>('');
    const [allStudentsIds, setAllStudentsIds] = useState<string[]>([]);

    const {
        error,
        loading,
        data,
    } = useQuery<{ spaces: Space[] }>(GET_SPACES);

    const {
        loading: notVotedLoading,
        error: notVotedError,
    } = useQuery<
    { notVotingForModuleSelectionStudents: NotVotedStudent[] }
    >(GET_NOT_VOTED_STUDENTS, {
        variables: {
            NotVotingForModuleSelectionStudentsInput: statisticQueryVariables,
        },
        onCompleted: (
            notVotedStudentsData,
        ) => {
            educationPeriodStatisticStore.setNotVotedStudents(
                notVotedStudentsData.notVotingForModuleSelectionStudents,
            );
        },
    });

    const {
        loading: modulesPrioritiesLoading,
        error: modulesPrioritiesError,
    } = useQuery<
    { studentModulePriorityGroups: StudentsModulesPriorities[] }
    >(GET_STUDENTS_MODULE_PRIORITIES, {
        variables: {
            StudentModulePriorityGroupsInput: statisticQueryVariables,
        },
        onCompleted: (
            modulesPrioritiesData,
        ) => {
            educationPeriodStatisticStore.setModulesPrioritiesGroups(
                modulesPrioritiesData.studentModulePriorityGroups,
            );
        },
    });

    const {
        loading: previousBtiLoading,
        error: PreviousBtiError,
    } = useQuery<
    { previousBaseTimeIntervalInstance : { id: string } }
    >(
        GET_PREVIOUS_BTI_INSTANCE_ID,
        {
            variables: {
                baseTimeIntervalInstanceId: btiInstance,
            },
            onCompleted: (
                { previousBaseTimeIntervalInstance },
            ) => setPreviousBTIInstanceId(
                previousBaseTimeIntervalInstance?.id,
            ),
        },
    );

    const {
        loading: studentPrioritiesLoading,
        error: studentPrioritiesError,
    } = useQuery<{ studentsPriorities: StudentPriority[] }>(
        GET_STUDENTS_PRIORITIES,
        {
            variables: {
                StudentsPrioritiesInput: statisticQueryVariables,
            },
            onCompleted: (
                studentPrioritiesData,
            ) => educationPeriodStatisticStore.setStudentPriorities(
                studentPrioritiesData.studentsPriorities,
            ),
        },
    );

    const {
        loading: studentsRatingsLoading,
        error: studentsRatingsError,
    } = useQuery<{ studentsRating: StudentRating[] }>(
        GET_ALL_STUDENTS_RATING,
        {
            variables: {
                studentsRatingInput: {
                    baseTimeIntervalInstanceId: previousBTIInstanceId,
                    studentIds: allStudentsIds,
                },
            },
            onCompleted: (
                { studentsRating },
            ) => educationPeriodStatisticStore.setStudentsRatings(studentsRating),
        },
    );

    const isLoading = loading;

    const isPeriodStatisticLoading = notVotedLoading
        || modulesPrioritiesLoading
        || studentPrioritiesLoading
        || studentsRatingsLoading
        || previousBtiLoading;

    const isError = error
        || studentsRatingsError
        || PreviousBtiError;

    const isAdditionalError = !!(modulesPrioritiesError || studentPrioritiesError || notVotedError);

    return (
        <>
            {
                isLoading ? (
                    <Loader />
                ) : (
                    <EducationPeriodStatistic
                        spaces={data?.spaces}
                        hasError={isAdditionalError}
                        isLoading={isPeriodStatisticLoading}
                        setAllStudentsIds={setAllStudentsIds}
                        queryVariables={statisticQueryVariables}
                    />
                )
            }
            {
                isError && (
                    <Alert time={8000} />
                )
            }
        </>
    );
}
