import React, { useState } from 'react';

import { Accordion } from '@common/Accordion';

import { Fork, Subspace } from '../Config/interfaces';
import { ModuleCards } from '../ModuleCard';
import { InfoCard } from './InfoCard';

import classes from './ChoicePoint.module.scss';
import { getModuleDeclension } from '.';

interface Props {
    fork: Fork;
    subspace: Subspace;
    order: number;
    isModuleSelectionTime: boolean;
}

export function DistributionPoint({
    fork,
    subspace,
    order,
    isModuleSelectionTime,
}: Props) {
    const [isOpen, setIsOpen] = useState(true);
    const { moduleSelectionCount } = fork.setting;
    const modules = fork.nextSlots.map(slot => ({ module: slot.module!, subspace }));
    return (
        <Accordion
            additionalSetState={() => setIsOpen(!isOpen)}
            isOpen={isOpen}
            headerClassnames={isOpen ? classes.choicePoint__open : classes.choicePoint}
        >
            <div className={classes.choicePoint__title}>
                {`${order} распределение по модулям. Подпространство: ${subspace.name}, ${getModuleDeclension(modules.length)}`}
            </div>
            <>
                {isModuleSelectionTime && (
                    <InfoCard>
                        <div>
                            {`В этом разделе вы будете автоматически 
                                зачислены на ${moduleSelectionCount} из ${modules.length} модулей. `}
                        </div>
                        <div>
                            Распределение будет производится
                            на основе ваших образовательных результатов или рейтинга.
                        </div>
                    </InfoCard>
                )}
                <ModuleCards
                    modules={modules}
                />
            </>
        </Accordion>
    );
}
