import React, {
    useCallback, useEffect, useRef, useState,
} from 'react';

export function useInput<T>(initData: T) {
    const [data, setData] = React.useState(initData);

    const onInput = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const { name } = event.target;
        setData({ ...data, [name]: event.target.value });
    };

    return { data, onInput };
}

export const useStateCallback = (initialState: any) => {
    const [state, setState] = useState(initialState);
    const cbRef: any = useRef(null);

    const setStateCallback = useCallback((value, cb) => {
        cbRef.current = cb;
        setState(value);
    }, []);

    useEffect(() => {
        if (cbRef.current) {
            cbRef.current(state);
            cbRef.current = null;
        }
    }, [state]);

    return [state, setStateCallback];
};

export function normalBoldJSX(normal: string, bold = '') {
    return <>{normal} <b>{bold}</b></>;
}
