import React, { useState } from 'react';
import { toJS } from 'mobx';
import { SectionList } from '@admin/NewModule';
import { userCardStore } from '@admin/Users/store/userCardStore';

import classes from './RolesPicker.module.scss';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { RoleButtonToolBar, StatusButtonToolBar } from '../../ToolBar/Tools';
import { Role } from '../models';
import { defaultRoles } from './roles';

interface Props {
    edit: boolean;
    roles: Role[];
}

enum Roles {
    Teacher = 'Teacher',
    Student = 'Student',
    Admin = 'Admin',
}
export const RolesPicker = ({ edit, roles }: Props): JSX.Element => {
    const [rolesList, setRolesList] = useState(roles);

    const handleRemove = (name: string) => {
        const newRolesList = rolesList.filter((role) => role.name !== name);
        userCardStore.roles = newRolesList;
        setRolesList(newRolesList);
    };

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const handleRoleChange = (newRole: string) => {
        if (rolesList.map((role) => role.name.toLowerCase()).includes(newRole)) return;
        const newRoles = [defaultRoles[newRole], ...rolesList];
        userCardStore.roles = newRoles;
        setRolesList(newRoles);
    };

    const handleStatusChange = (status: string, types: string[]) => {
        let newRoles: Role[] = [...rolesList];
        types.forEach(
            type => {
                if (rolesList.map((role) => role.name.toLowerCase()).includes(type)) return;
                newRoles = [defaultRoles[type], ...newRoles];
            },
        );
        userCardStore.roles = newRoles;
        setRolesList(newRoles);
        const rolesToChange = toJS(userCardStore.roles)
            .filter(role => Object.keys(Roles).includes(role.name));
        newRoles = [];
        rolesToChange.forEach((role) => {
            if (types.includes(role.name.toLowerCase())) {
                newRoles.push({ ...role, enabled: status === 'active' });
            } else {
                newRoles.push(role);
            }
        });
        userCardStore.roles = newRoles;
        setRolesList(newRoles);
    };

    return (
        <>
            {
                edit
                    ? (
                        <div className={classes.rolesPicker}>
                            <div>
                                {/* <div className={classes.rolesPicker__button}>
                                    <RoleButtonToolBar onSubmit={handleRoleChange} />
                                </div> */}

                                <div className={classes.rolesPicker__button}>
                                    <StatusButtonToolBar onSubmit={handleStatusChange} />
                                </div>
                            </div>

                            <div>
                                <div className={classes.rolesPicker__roles_title}>
                                    Роли в системе:
                                </div>

                                <div className={classes.rolesPicker__roles_value}>
                                    <SectionList
                                        list={rolesList.map(role => ({ ...role, id: role.name }))}
                                        formatItem={({ text, enabled }) => (
                                            <>
                                                {text}
                                                <br />
                                                {enabled ? 'Активный' : 'Неактивный'}
                                            </>
                                        )}
                                        onRemove={(name: string) => (handleRemove(name))}
                                    />
                                </div>
                            </div>
                        </div>
                    )
                    : (
                        <div className={classes.userCard__roles_value}>
                            <SectionList
                                list={roles.map(role => ({ ...role, id: role.name }))}
                                formatItem={({ text, enabled }) => (
                                    <>
                                        {text}
                                        <br />
                                        {enabled ? 'Активный' : 'Неактивный'}
                                    </>
                                )}
                                hasRemove={false}
                            />
                        </div>
                    )
            }
        </>
    );
};
