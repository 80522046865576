import React, { useState } from 'react';

import {
    Loader,
    Alert,
    ActionButton,
    CheckBox,
    Input,
    InputTypes,
} from '@common';
import { Select } from '@common/Select';
import {
    IgnoreErrorsFlag,
    ScheduleGenerationProcessStatus,
    ScheduleGenerationProcessesSchedulePage_scheduleGenerationProcesses,
} from '../../../graphql-query-types';
import {
    GenerationPhase,
    useBeginCustomScheduleGeneration,
    useValidateScheduleLazyQuery,
} from '../graphql';
import { IgnoreFlagsForValidation } from './IgnoreFlagsForValidation';
import {
    IgnoreFlag,
    IgnoreFlagCheckbox,
} from './interfaces';
import { UploadButton } from '../UploadButton';

import classes from './ScheduleGeneration.module.scss';

interface Props {
    process: ScheduleGenerationProcessesSchedulePage_scheduleGenerationProcesses;
    refetchProcesses: () => void;
}

export const CustomScheduleGeneration = ({
    process,
    refetchProcesses,
}: Props) => {
    const [ignoreFlags, setIgnoreFlags] = useState<IgnoreFlagCheckbox>(
        {
            ignoreTeachers: false,
            ignoreStudents: false,
            ignoreEquipments: false,
            ignoreRooms: false,
            ignoreMinMaxIntervals: false,
        },
    );
    const [JSONConfig, setJSONConfig] = useState<JSON>();
    const [priority, setPriority] = useState('3');
    const [phase, setPhase] = useState({ id: GenerationPhase.phase1 });
    const [isForceCreateMeeting, setIsForceCreateMeeting] = useState(false);
    const {
        startCustomScheduleGeneration,
        error: errorStart,
        loading: loadingStart,
    } = useBeginCustomScheduleGeneration(refetchProcesses);
    const {
        validate,
        loading: loadingValidation,
        error: errorValidation,
        validationResult,
    } = useValidateScheduleLazyQuery();
    function isScheduleGeneration() {
        if (process.status === ScheduleGenerationProcessStatus.correction) {
            return false;
        }
        return true;
    }
    const handleIgnoreFlagsChange = (
        ignoreFlag: keyof typeof IgnoreFlag,
    ) => () => setIgnoreFlags(
        (oldConflictTypes: IgnoreFlagCheckbox) => (
            { ...oldConflictTypes, [ignoreFlag]: !oldConflictTypes[ignoreFlag] }
        ),
    );
    return (
        <>
            <h1>Кастомная генерация</h1>
            <div className={classes.additionalGeneration}>
                <IgnoreFlagsForValidation
                    isCustom
                    flags={Object.keys(IgnoreFlag)}
                    ignoreFlags={ignoreFlags}
                    isSecret
                    validationResult={(validationResult
                        ? [
                            ...validationResult.errors.map(({ message }) => message),
                            ...validationResult.warnings.map(({ message }) => `(предупреждение) ${message}`),
                        ]
                        : [])}
                    handleIgnoreFlagsChange={handleIgnoreFlagsChange}
                />
                <div>
                    <div className={classes.additionalGeneration_actions}>
                        <div className={classes.additionalGeneration__mainActions}>
                            <div style={{ maxWidth: '600px' }}>
                                приоритет:
                                <Input
                                    type={InputTypes.Number}
                                    value={priority}
                                    onChange={(element) => setPriority(element.target.value)}
                                />
                            </div>
                            <div style={{ maxWidth: '600px' }}>
                                фаза:
                                <Select
                                    selectedOption={phase}
                                    options={Object.values(GenerationPhase).map((id) => ({ id }))}
                                    onChange={(item) => setPhase(item)}
                                />
                            </div>
                            <div style={{ maxWidth: '600px' }}>
                                досоздание несгенерированных встреч:
                                <CheckBox
                                    id="123"
                                    checked={isForceCreateMeeting}
                                    handleChangeCheckBox={
                                        () => setIsForceCreateMeeting(!isForceCreateMeeting)
                                    }
                                />
                            </div>
                        </div>
                        <div className={classes.additionalGeneration__mainActions}>
                            <>
                                <UploadButton
                                    onLoad={(result) => setJSONConfig(JSON.parse(result))}
                                >
                                    Загрузить конфиг

                                </UploadButton>
                                {
                                    loadingValidation ? (
                                        <div>
                                            Процесс валидации займет около 3 минут
                                            <Loader />
                                        </div>
                                    ) : (
                                        <ActionButton
                                            onClick={() => validate({
                                                variables: {
                                                    validateScheduleInput: {
                                                        generationProcessId: process.id,
                                                        ignoreFlags: getTrueKeysFromObject(
                                                            ignoreFlags,
                                                        ) as IgnoreErrorsFlag[],
                                                        generationConfig: JSONConfig,
                                                    },
                                                },
                                            })}
                                            className={classes.button}
                                        >
                                            Валидация
                                        </ActionButton>
                                    )
                                }
                                {loadingStart ? <Loader /> : (
                                    <ActionButton
                                        onClick={() => startCustomScheduleGeneration(
                                            process.id,
                                            getTrueKeysFromObject(ignoreFlags),
                                            JSONConfig!,
                                            phase.id,
                                            Number(priority),
                                            isForceCreateMeeting,
                                        )}
                                        className={classes.button}
                                        disabled={isScheduleGeneration()}
                                    >
                                        Начать кастомную генерацию
                                    </ActionButton>
                                )}
                            </>
                        </div>
                    </div>
                    <div>
                        Ошибки валидации:
                        {(validationResult
                            ? [
                                ...validationResult.errors,
                                ...validationResult.warnings.map(warning => ({
                                    ...warning,
                                    message: `(предупреждение) ${warning.message}`,
                                })),
                            ]
                            : []
                        ).map(
                            (item) => (
                                <li key={item.message}>
                                    {item.message}
                                </li>
                            ),
                        )}
                    </div>
                </div>
                {errorStart && <Alert message={errorStart.message} />}
                {errorValidation && <Alert message={errorValidation.message} />}
            </div>
        </>
    );
};

function getTrueKeysFromObject(obj: { [index: string]: boolean; }) {
    return Object.entries(obj).filter(([, value]) => value).map(([key]) => key);
}
