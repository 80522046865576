import React, { useEffect } from 'react';

import { useLazyQuery } from '@apollo/client';
import { Alert } from '@common/Alert/Alert';
import { saveAs } from 'file-saver';
import { ToolButton } from '../../ToolButton';
import { LOAD_USER_CSV } from '../gql';

export const ExportStudentsFormButton = () => {
    const [loadStudentImportForm, { data, error }] = useLazyQuery(LOAD_USER_CSV, {
        // eslint-disable-next-line no-console
        onError: (err) => { console.error(err); },
    });

    function handleSubmit() {
        loadStudentImportForm();
    }

    useEffect(() => {
        if (data) {
            const blob = new Blob([data.generateUserCreationCsv ?? ''], {
                type: 'text/csv;charset=utf-8',
            });
            saveAs(blob, 'Шаблон импорта студентов');
        }
    }, [data]);
    return (
        <>
            <ToolButton
                text="Шаблон загрузки студентов"
                icon="export"
                onClick={() => handleSubmit()}
            />
            {error && <Alert message={error.message} time={3000} />}
        </>
    );
};
