import { gql } from '@apollo/client';

export const GET_ROOM_LOAD = gql`
  query GetRoomLoad($roomLoadInput: RoomLoadInput!) {
    roomLoad(roomLoadInput: $roomLoadInput) {
      territorialZones {
        id
        name
        buildings {
          id
          name
          rooms {
            id
            title
            capacity
          }
        }
      }
      timeslots {
        date
        startTime
        endTime
        week
        dayOfWeek
      }
      rooms {
        id
        timeslots {
          isAvailable
          isReservedByClassicUniversity
          isReservedByTUniversity
          date
          startTime
          endTime
          week
          dayOfWeek
        }
      }
    }
  }
`;

export const GET_SUGGESTED_ROOMS = gql`
  query GetSuggestedRooms($value: RoomsInput) {
    rooms(roomsInput: $value) {
      id
      title
    }
  }
`;

export const GET_SUGGESTED_MEETING_FORMATS = gql`
  query GetSuggestedMeetingFormats($value: MeetingFormatsByFiltersInput) {
    meetingFormatsByFilters(meetingFormatsByFiltersInput: $value) {
      id
      name
    }
  }
`;
