import React from 'react';

import { TMainDropDownOption } from '@common/MainDropDown';

import classes from './MainDropDownOption.module.scss';

interface Props {
    option: TMainDropDownOption,
    optionHandleClick: (option: TMainDropDownOption) => void;
}

export function MainDropDownOption(
    {
        option,
        optionHandleClick,
    }: Props,
) {
    return (
        <button
            type="button"
            className={classes.mainDropDownOption}
            onClick={() => optionHandleClick(option)}
        >
            {
                option.name
            }
        </button>
    );
}
