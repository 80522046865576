import { Input, InputTypes } from '@common/Input';
import React from 'react';
import classes from './RoomSpace.module.scss';

interface CapacityProps {
    handleRoomCapacity(event: React.ChangeEvent<HTMLInputElement>): void;
    capacity: number;
}
export function RoomSpace({
    handleRoomCapacity, capacity,
}: CapacityProps) {
    const result = capacity !== undefined ? capacity : '';
    return (
        <div className={classes.roomSpace}>
            <Input
                name="space"
                onChange={handleRoomCapacity}
                value={`${result}`}
                type={InputTypes.Number}
                placeholder="Кол-во"
                label="На сколько человек рассчитано помещение:"
                min={1}
                labelClassName={classes.roomSpace__label}
            />
        </div>
    );
}
