/* eslint import/no-cycle: "off" */
import { Meeting } from './Meeting';
import { EvaluationPoint } from './EvaluationPoint';
import { Options } from './Options';
import {
    ModuleSkill, MeetingInterface, EvaluationPointInterface, AssignmentInterface, PointSkill,
} from './moduleStoreTypes';
import { dictionaryStore } from './dictionaryStore';
import { Assignment } from './Assignment';

const getFormatNameSkill = (skill: ModuleSkill): ModuleSkill => ({
    ...skill, skill: { ...skill.skill, name: skill.skill.fullName },
});

export const getRefactorOptionsFromApollo = (options: Partial<Options>): Partial<Options> => {
    const refactorModuleSkills = ({ skill: { typeId } }: ModuleSkill) => dictionaryStore
        .moduleSettings.moduleSkillTypes.some(({ id }) => id === typeId);
    return {
        ...options,
        teachersRoles: options.teachersRoles?.map(role => ({
            teacherRole: { id: role.id!, name: role.name! },
        })),
        moduleTeachers: options.moduleTeachers?.map(teacher => ({
            ...teacher,
            teacher: {
                ...teacher.teacher,
                name: `${teacher.teacher.user?.lastName} ${teacher.teacher.user?.firstName} ${teacher.teacher.user?.patronymic}`,
            },
        })),
        prerequisiteSkills: options.prerequisiteSkills?.filter(refactorModuleSkills),
        outputSkills: options.outputSkills?.filter(refactorModuleSkills),
    };
};

export const getRefactorMeetingsFromApollo = (meetings: MeetingInterface[]): Meeting[] => meetings
    .map(meeting => {
        const newMeeting = new Meeting();
        const newMeetingProps = {
            ...meeting,
            meetingTeacherRoles: meeting.meetingTeacherRoles,
            prerequisiteSkills: meeting.prerequisiteSkills?.map(getFormatNameSkill),
            outputSkills: meeting.outputSkills?.map(getFormatNameSkill),
        };
        return Object.assign(newMeeting, newMeetingProps);
    });

export const getRefactorAssignmentsFromApollo = (
    assignments: AssignmentInterface[],
): Assignment[] => assignments
    .map(assignment => {
        const newAssignment = new Assignment();
        const newAssignmentProps = {
            ...assignment,
            prerequisiteSkills: assignment.prerequisiteSkills?.map(getFormatNameSkill),
            outputSkills: assignment.outputSkills?.map(getFormatNameSkill),
            isNextEventInRow: false,
            isPreviousEventInRow: false,
        };
        return Object.assign(newAssignment, newAssignmentProps);
    });

const getRefactorEvaluationPointSkills = (point: EvaluationPointInterface): PointSkill[] => {
    const pointSkills = point?.evaluationPointSkills?.map(skill => ({
        level: skill.level,
        pointId: point.id,
        skill: { ...skill.skill, name: skill.skill.fullName },
    }));

    return pointSkills ?? [];
};

export const getRefactorEvaluationPointsFromApollo = (
    points: EvaluationPointInterface[],
): EvaluationPoint[] => points.map(point => {
    const evaluationPointSkills = getRefactorEvaluationPointSkills(point);
    const newPoint = new EvaluationPoint();
    const newPointProps = { ...point, evaluationPointSkills };
    return Object.assign(newPoint, newPointProps);
});
