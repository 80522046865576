import { useEffect, useRef } from 'react';

function createRootElement(id: string) {
    const rootContainer = document.createElement('div');
    rootContainer.setAttribute('id', id);
    return rootContainer;
}

function addRootElement(rootElement: any) {
    document.body.insertBefore(
        rootElement,
        document.body.lastElementChild?.nextElementSibling!,
    );
}

export function usePortal(id: string) {
    const rootElemRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        const existingParent = document.querySelector(`#${id}`);
        const parentElem = existingParent || createRootElement(id);

        if (!existingParent) {
            addRootElement(parentElem);
        }

        parentElem.appendChild(rootElemRef.current!);

        return function removeElement() {
            rootElemRef.current!.remove();
            if (!parentElem.childElementCount) {
                parentElem.remove();
            }
        };
    }, [id]);

    function getRootElem() {
        if (!rootElemRef.current) {
            rootElemRef.current = document.createElement('div');
            rootElemRef.current.style.height = '100%';
            rootElemRef.current.style.width = '100%';
        }
        return rootElemRef.current;
    }

    return getRootElem();
}
