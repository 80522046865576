/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: baseTimeIntervalInstances
// ====================================================

export interface baseTimeIntervalInstances_baseTimeIntervalInstances_spaceBaseTimeInterval_space_baseTimeIntervalType {
  __typename: "BaseTimeIntervalType";
  name: string;
}

export interface baseTimeIntervalInstances_baseTimeIntervalInstances_spaceBaseTimeInterval_space {
  __typename: "Space";
  id: string;
  name: string;
  baseTimeIntervalType: baseTimeIntervalInstances_baseTimeIntervalInstances_spaceBaseTimeInterval_space_baseTimeIntervalType;
}

export interface baseTimeIntervalInstances_baseTimeIntervalInstances_spaceBaseTimeInterval {
  __typename: "SpaceBaseTimeInterval";
  order: number;
  space: baseTimeIntervalInstances_baseTimeIntervalInstances_spaceBaseTimeInterval_space;
}

export interface baseTimeIntervalInstances_baseTimeIntervalInstances {
  __typename: "BaseTimeIntervalInstance";
  id: string;
  startDate: string;
  endDate: string;
  spaceBaseTimeInterval: baseTimeIntervalInstances_baseTimeIntervalInstances_spaceBaseTimeInterval;
}

export interface baseTimeIntervalInstances {
  baseTimeIntervalInstances: baseTimeIntervalInstances_baseTimeIntervalInstances[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: spaces
// ====================================================

export interface spaces_spaces {
  __typename: "Space";
  id: string;
  name: string;
}

export interface spaces {
  spaces: spaces_spaces[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ListUser
// ====================================================

export interface ListUser_users_list_roles {
  __typename: "RoleSwitch";
  roleId: string;
  name: string;
  enabled: boolean;
  text: string;
}

export interface ListUser_users_list_student_space {
  __typename: "Space";
  id: string;
  name: string;
}

export interface ListUser_users_list_student_activeBaseTimeIntervalInstance_baseTimeIntervalInstance_spaceBaseTimeInterval_space_baseTimeIntervalType {
  __typename: "BaseTimeIntervalType";
  name: string;
}

export interface ListUser_users_list_student_activeBaseTimeIntervalInstance_baseTimeIntervalInstance_spaceBaseTimeInterval_space {
  __typename: "Space";
  id: string;
  name: string;
  baseTimeIntervalType: ListUser_users_list_student_activeBaseTimeIntervalInstance_baseTimeIntervalInstance_spaceBaseTimeInterval_space_baseTimeIntervalType;
}

export interface ListUser_users_list_student_activeBaseTimeIntervalInstance_baseTimeIntervalInstance_spaceBaseTimeInterval {
  __typename: "SpaceBaseTimeInterval";
  order: number;
  space: ListUser_users_list_student_activeBaseTimeIntervalInstance_baseTimeIntervalInstance_spaceBaseTimeInterval_space;
}

export interface ListUser_users_list_student_activeBaseTimeIntervalInstance_baseTimeIntervalInstance {
  __typename: "BaseTimeIntervalInstance";
  id: string;
  spaceBaseTimeInterval: ListUser_users_list_student_activeBaseTimeIntervalInstance_baseTimeIntervalInstance_spaceBaseTimeInterval;
}

export interface ListUser_users_list_student_activeBaseTimeIntervalInstance {
  __typename: "StudentBaseTimeIntervalInstance";
  baseTimeIntervalInstance: ListUser_users_list_student_activeBaseTimeIntervalInstance_baseTimeIntervalInstance;
}

export interface ListUser_users_list_student_skills_skill {
  __typename: "Skill";
  fullName: string;
}

export interface ListUser_users_list_student_skills {
  __typename: "StudentSkill";
  level: number | null;
  skill: ListUser_users_list_student_skills_skill;
}

export interface ListUser_users_list_student {
  __typename: "Student";
  id: string;
  space: ListUser_users_list_student_space | null;
  activeBaseTimeIntervalInstance: ListUser_users_list_student_activeBaseTimeIntervalInstance | null;
  skills: ListUser_users_list_student_skills[];
}

export interface ListUser_users_list {
  __typename: "User";
  id: string;
  firstName: string;
  patronymic: string | null;
  lastName: string;
  teacherId: string | null;
  email: string | null;
  birthday: string | null;
  roles: ListUser_users_list_roles[];
  student: ListUser_users_list_student | null;
}

export interface ListUser_users {
  __typename: "UserList";
  list: ListUser_users_list[];
  totalCount: number;
}

export interface ListUser {
  users: ListUser_users;
}

export interface ListUserVariables {
  findParams?: FindParameters | null;
  paginationParams?: PaginationParameters | null;
  includeStudent: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum Type {
  admin = "admin",
  all = "all",
  none = "none",
  student = "student",
  teacher = "teacher",
}

export enum UserStatus {
  active = "active",
  deleted = "deleted",
  inactive = "inactive",
}

export interface FindParameters {
  userType?: Type | null;
  fullName?: string | null;
  status?: UserStatus | null;
  studentFindParameters?: StudentFindParameters | null;
}

export interface PaginationParameters {
  offset: number;
  limit: number;
}

export interface StudentFindParameters {
  baseTimeIntervalInstanceId?: string | null;
  skillName?: string | null;
  spaceId?: string | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
