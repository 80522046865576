import React, { useState } from 'react';

import { WeekDay } from '@common/WeekDaysPicker/WeekDay';
import classes from './WeekDaysPicker.module.scss';

export enum WeekDaysRussian {
    monday = 'Пн',
    tuesday = 'Вт',
    wednesday = 'Ср',
    thursday = 'Чт',
    friday = 'Пт',
    saturday = 'Сб',
    sunday = 'Вс',
}

interface Props {
    getActiveWeekDays?: (newActiveWeekDays: WeekDaysRussian[]) => void;
}

export function WeekDaysPicker(
    {
        getActiveWeekDays,
    }: Props,
) {
    const [activeWeekDays, setActiveWeekDays] = useState<WeekDaysRussian[]>([]);

    const addActiveWeekDay = (
        currentDay: WeekDaysRussian,
    ) => {
        if (getActiveWeekDays) {
            getActiveWeekDays([...activeWeekDays, currentDay]);
        }

        setActiveWeekDays((prevState) => [...prevState, currentDay]);
    };

    const removeActiveWeekDay = (
        currentDay: WeekDaysRussian,
    ) => {
        const filteredWeekDays = new Array(...activeWeekDays).filter((day) => day !== currentDay);

        if (getActiveWeekDays) {
            getActiveWeekDays(filteredWeekDays);
        }
        setActiveWeekDays(filteredWeekDays);
    };

    const weekDays = Object.values(WeekDaysRussian).map((day) => (
        <WeekDay
            key={day}
            dayName={day}
            isActive={isWeekDayActive(
                day,
                activeWeekDays,
            )}
            addActiveWeekDay={addActiveWeekDay}
            removeActiveWeekDay={removeActiveWeekDay}
        />
    ));

    return (
        <div className={classes.weekDayPicker}>
            {weekDays}
        </div>
    );
}

function isWeekDayActive(
    currentDay: WeekDaysRussian,
    weekDays: WeekDaysRussian[],
) {
    return weekDays.some((day) => day === currentDay);
}
