import React from 'react';

import {
    Accordion,
} from '@admin/EducationPeriodStatistic/EducationPeriodStatisticVoted/VotedModuleStatisticAccordion/Accordion';
import {
    StudentsModulesPriorities,
} from '@admin/EducationPeriodStatistic/EducationPeriodStatisticInterfaces';

interface Props {
    moduleStatistics: StudentsModulesPriorities[] | null;
}

export function VotedModuleStatisticAccordion(
    {
        moduleStatistics,
    }: Props,
) {
    return (
        <>
            {
                moduleStatistics && moduleStatistics.map((moduleStatistic) => (
                    <Accordion
                        key={moduleStatistic.module.id}
                        module={moduleStatistic.module}
                        priorityGroups={moduleStatistic.priorityGroups}
                    />
                ))
            }
        </>
    );
}
