import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { ActionButton, ActionTypeOfButton } from '@common/ActionButton';
import { SubspaceType } from '@admin/Trajectories/types';

import { useSubspaceTypesQuery } from '@admin/Trajectories/hooks/useSubspaceTypesQuery';
import { colors } from '@admin/Trajectories/utils';
import classes from './TrajectoryForm.module.scss';
import { TrajectoryContent } from './TrajectoryContent';
import { trajectoryStore } from './Store/TrajectoryStore';
import { SubspaceChooser } from './SubspaceChooser';

export interface Props {
    handleDelete(): void;
    handleSave(): void;
    handlePublish(): void;
    text: string;
    isEdit?: boolean;
    actionText: string;
    saveText: string;
    spaceId: string | undefined;
    isFormOpen?: boolean;
}

export const TrajectoryForm = observer(({
    handleDelete, handleSave, handlePublish,
    text, isEdit, actionText, saveText, spaceId, isFormOpen,
}: Props): JSX.Element => {
    const { selectedSubspaces, name } = trajectoryStore;
    const subspaceTypes = useSubspaceTypesQuery(spaceId!);
    const sortedSubspaces = subspaceTypes?.slice().sort((a, b) => (
        (a.isSelective === true) > (b.isSelective) === false ? -1 : 1
    ));
    const notSelectiveTypes = subspaceTypes?.filter(item => !item.isSelective);

    const disabledCondition = (selectedSubspaces.length < subspaceTypes!.length) || !name;
    // const disabled = disabledCondition;

    useEffect(() => {
        if (subspaceTypes) {
            notSelectiveTypes?.map((item) => (
                isFormOpen && trajectoryStore.updateSubspaces(item.subspaces[0])
            ));
        }
    }, [subspaceTypes]);
    return (
        <section className={classes.trajectoryForm}>
            <div className={classes.trajectoryForm__buttons}>
                <ActionButton
                    onClick={handleDelete}
                    actionType={ActionTypeOfButton.SECONDARY}
                >
                    {text}
                </ActionButton>
                <div className={classes.trajectoryForm__saveButtons}>
                    <div className={classes.trajectoryForm__button}>
                        <ActionButton
                            onClick={() => { if (!disabledCondition) { handleSave(); } }}
                            actionType={ActionTypeOfButton.SECONDARY}
                            disabled={disabledCondition}
                        >
                            {saveText}
                        </ActionButton>
                    </div>
                    <ActionButton
                        onClick={() => { if (!disabledCondition) { handlePublish(); } }}
                        disabled={disabledCondition}
                    >
                        {actionText}
                    </ActionButton>
                </div>
            </div>
            <div className={classes.trajectoryForm__main}>
                {!subspaceTypes?.length
                    ? (
                        <h2 className={classes.trajectoryForm__disabled}>
                            Для создания траектории необходимо
                            создать образовательные подпространства
                        </h2>
                    )
                    : (
                        <>
                            <TrajectoryContent
                                selectedSubspaces={selectedSubspaces}
                                subspaceTypes={subspaceTypes}
                                isEdit={isEdit}
                            />
                            <div className={classes.trajectoryForm__list}>
                                {sortedSubspaces?.map((subspaceType: SubspaceType, idx) => (
                                    <SubspaceChooser
                                        key={subspaceType.id}
                                        idx={idx}
                                        subspaceType={subspaceType}
                                        colors={colors}
                                        isEdit={isEdit}
                                    />
                                ))}
                            </div>
                        </>
                    )
                }

            </div>
        </section>
    );
});
