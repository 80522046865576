import React from 'react';

import classes from './DeleteCrossIcon.module.scss';

export function DeleteCrossIcon() {
    return (
        <svg className={classes.deleteCrossIcon} width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M1.24173 8.31371C0.85121 8.70423 0.85121 9.3374 1.24173 9.72792C1.63226 10.1184 2.26542 10.1184 2.65595 9.72792L5.48437 6.8995L8.3128 9.72792C8.70333 10.1184 9.33649 10.1184 9.72701 9.72792C10.1175 9.3374 10.1175 8.70423 9.72701 8.31371L6.89859 5.48528L9.72702 2.65685C10.1175 2.26633 10.1175 1.63316 9.72702 1.24264C9.33649 0.852116 8.70333 0.852116 8.3128 1.24264L5.48437 4.07107L2.65595 1.24264C2.26542 0.852116 1.63226 0.852116 1.24173 1.24264C0.85121 1.63316 0.85121 2.26633 1.24173 2.65685L4.07016 5.48528L1.24173 8.31371Z" />
        </svg>
    );
}
