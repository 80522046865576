import React from 'react';

import { format } from 'date-fns';
import {
    ScheduleGenerationProcessesSchedulePage_scheduleGenerationProcesses,
} from '../../../graphql-query-types';

import classes from './ScheduleGeneration.module.scss';

type Props = {
    processes: ScheduleGenerationProcessesSchedulePage_scheduleGenerationProcesses[];
    setProcess(process: ScheduleGenerationProcessesSchedulePage_scheduleGenerationProcesses): void;
};

export function ScheduleGenerationList({ processes, setProcess }: Props) {
    return (
        <ul>
            {processes.map(process => (
                <div
                    key={process.id}
                    onClick={() => setProcess(process)}
                    className={classes.additionalGeneration_item}
                >
                    <span className={classes.additionalGeneration_itemName}>
                        {process.name}
                    </span>
                    <span>
                        {formatDate(process.from)} - {formatDate(process.to)}
                    </span>
                </div>
            ))}
        </ul>
    );
}

export function formatDate(date: Date | string | number) {
    return format(new Date(date), 'dd.MM.yyyy');
}
