import React from 'react';

import { userCardStore } from '@admin/Users/store/userCardStore';
import { Teacher, WorkingHours } from '../models';
import { NonWorkingHours } from './NonWorkingHours/NonWorkingHours';
import { EditWorkingHours, PreviewWorkingHours } from './WorkingHours';
import { EditNonWorkingHours } from './NonWorkingHours/EditNonWorkingHours';

import classes from './TeacherInfo.module.scss';
import { DaySchedule, WeekType } from '../TimePicker/modelTimePicker';

interface Props {
    teacher: Teacher,
    edit?: boolean,
}

export const toUpCaseFirstChar = (str: string) => str[0].toUpperCase() + str.slice(1);
export const toLowerCaseFirstChar = (str: string) => str[0].toLowerCase() + str.slice(1);

export const normalizWorkingInterval = (workingHours: WorkingHours | null): DaySchedule => ({
    weekDay: toUpCaseFirstChar(workingHours?.day ?? ''),
    workingIntervals: [{
        id: workingHours?.id ?? '',
        start: `${workingHours?.from.slice(0, -3)}.000Z` ?? '',
        end: `${workingHours?.to.slice(0, -3)}.000Z` ?? '',
        weekType: workingHours?.weekType!,
    }],
});
export const TeacherInfo = React.memo(({ teacher, edit }: Props): JSX.Element => {
    teacher?.workingHours?.forEach((item) => {
        if (item.weekType !== WeekType.every) {
            userCardStore.updateIsEveryWeek(false);
        } else {
            userCardStore.updateIsEveryWeek(true);
        }
        userCardStore.createDefaultWorkingInterval(normalizWorkingInterval(item));
    });
    if (teacher?.workingHours?.length === 0) {
        userCardStore.updateIsEveryWeek(true);
    }
    return (
        <div className={classes.TeacherInfo}>
            {userCardStore.roles.length > 1
                && (
                    <h2 className={classes.teacherInfo__title}>
                        Параметры роли Преподаватель:
                    </h2>
                )
            }
            {edit ? <EditWorkingHours /> : (
                <PreviewWorkingHours
                    workingIntervals={userCardStore.workingIntervals}
                    isEveryWeek={userCardStore.isEveryWeek}
                />
            )}
            {edit ? <EditNonWorkingHours /> : (
                <NonWorkingHours
                    nonWorkingHours={userCardStore.nonWorkingHours}
                />
            )}
        </div>
    );
});
