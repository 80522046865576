import React, { useState } from 'react';
import { nanoid } from 'nanoid';

import { WaveStudentItem } from '@admin/WaveSelectionPage/WaveAccordion/WaveStudents/WaveStudentItem';
import { EmptyWaveStudentItem } from '@admin/WaveSelectionPage/WaveAccordion/WaveStudents/EmptyWaveStudentItem';
import {
    AdditionalWaveStudentItem,
} from '@admin/WaveSelectionPage/WaveAccordion/WaveStudents/AdditionalWaveStudentItem';

import {
    ApolloPromise,
    GroupedWavesWithSelectedStudents,
    WaveWithSelectedStudents,
    SortTypes, StudentWithRatings, RefetchIsValidStudentWaveDistribution,
} from '@admin/WaveSelectionPage/Interfaces';
import { UpdateStudentWaveInput, Waves_waves_module } from '@admin/WaveSelectionPage/graphql-types';
import { waveSelectionStore } from '@admin/WaveSelectionPage/WaveSelectionStore';
import cn from 'classnames';
import { getSortedStudents } from '@admin/WaveSelectionPage/WaveSelectionPage';
import { useGetSortButtonText } from '@admin/ModuleSelectionPage/UseGetSortButtonText';
import { checkIfCanSortByRating } from '@admin/ModuleSelectionPage/ModuleSelectionPage';
import classes from './WaveStudents.module.scss';

interface Props {
    waveIndex: number;
    wave: WaveWithSelectedStudents;
    currentModule: Waves_waves_module;
    updateStudentWave(input: UpdateStudentWaveInput): ApolloPromise;
    refetchIsValidStudentWaveDistribution: RefetchIsValidStudentWaveDistribution,
}

export function WaveStudents(
    {
        waveIndex,
        currentModule,
        wave,
        updateStudentWave,
        refetchIsValidStudentWaveDistribution,
    } : Props,
) {
    const emptyStudents: {
        id: string,
    }[] = getEmptyStudents(
        wave.students.length + (wave.additionalStudents.length ?? 0),
        currentModule.maxStudentCount ?? 0,
    );
    const [sortTypes, setSortTypes] = useState<SortTypes>(
        {
            isSortAz: true,
            isSortZa: false,
            isSortByRating: false,
        },
    );

    const isMinIntervalInvalid = wave.students.length < wave.module.minStudentCount;

    const intervalAdditionalInfo = {
        wavesInfo: `Объем потока ${wave.module.minStudentCount}-${wave.module.maxStudentCount} студентов`,
        studentInfo: `${wave.module.minStudentCount} студентов для 1 потока, добавьте еще мин ${wave.module.minStudentCount - wave.students.length} студентов`,
    };

    const { sortButtonText } = useGetSortButtonText(sortTypes);

    const changeSortType = () => {
        const initialSortTypes: SortTypes = {
            isSortAz: false,
            isSortZa: false,
            isSortByRating: false,
        };
        const canSortByRating = checkIfCanSortByRating(
            waveSelectionStore.getStudentsWithRatings(),
            wave.students,
        );
        if (sortTypes.isSortAz) {
            setSortTypes({
                ...initialSortTypes,
                isSortZa: true,
            });
        }

        if (sortTypes.isSortZa && canSortByRating) {
            setSortTypes({
                ...initialSortTypes,
                isSortByRating: true,
            });
        }

        if (sortTypes.isSortZa && !canSortByRating) {
            setSortTypes({
                ...initialSortTypes,
                isSortAz: true,
            });
        }

        if (sortTypes.isSortByRating) {
            setSortTypes({
                ...initialSortTypes,
                isSortAz: true,
            });
        }
    };

    const sortButtonHandleClick = () => {
        const wavesSortedCurrentWaveStudent = getWavesSortedCurrentWaveStudents(
            wave.id,
            currentModule.id,
            sortTypes,
            waveSelectionStore.getStudentsWithRatings(),
            waveSelectionStore.getModuleWavesWithSelectedStudents(),
        );

        waveSelectionStore.setModuleWavesWithSelectedStudents(
            wavesSortedCurrentWaveStudent,
        );

        changeSortType();
    };

    return (
        <div className={classes.wave}>

            <div
                className={cn(classes.wave__header, {
                    [classes.wave__header_borderTop]: waveIndex === 1,
                })}
            >

                <div
                    className={cn(classes.wave__title, {
                        [classes.wave__title_red]: isMinIntervalInvalid,
                    })}
                >

                    <div className={classes.wave__title_default}>
                        {waveIndex}-й поток
                    </div>

                    <div
                        className={cn(classes.wave__title_gray, {
                            [classes.wave__title_red]: isMinIntervalInvalid,
                        })}
                    >
                        [{wave.students.length} из {currentModule.maxStudentCount} студентов]
                    </div>

                </div>

                <div className={classes.wave__sort}>

                    <span className={classes.wave__sort_txt}>
                        {sortButtonText}
                    </span>

                    <button
                        type="button"
                        className={classes.wave__sort_btn}
                        onClick={() => {
                            sortButtonHandleClick();
                        }}
                    >
                        <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect width="19" height="19" fill="#FFF" />
                            <path d="M5.35059 4H7.34551V10.254H5.35059V4Z" fill="#4E5258" />
                            <path d="M9.34049 4H11.3354V11.8174H14.3631L10.3734 15L6.3836 11.8174H9.34049V4Z" fill="#4E5258" />
                        </svg>

                    </button>

                </div>
            </div>

            {
                wave.students.map((student, index) => (
                    <WaveStudentItem
                        key={`${student.user.studentId}-normal`}
                        order={index + 1}
                        currentModuleId={currentModule?.id ?? ''}
                        currentStudent={student}
                        wave={wave}
                    />
                ))
            }

            {
                (currentModule && wave.students.length !== currentModule.maxStudentCount)
                && wave.additionalStudents.map((student, index) => (
                    <AdditionalWaveStudentItem
                        key={`${student.user.studentId}-additional`}
                        disabled={false}
                        order={
                            index
                            + wave.students.length
                            + 1
                        }
                        isIntervalInvalid={isMinIntervalInvalid}
                        intervalAdditionalInfo={intervalAdditionalInfo}
                        currentStudent={student}
                        currentWave={wave}
                        currentModule={currentModule}
                        updateStudentWave={updateStudentWave}
                        refetchIsValidStudentWaveDistribution={
                            refetchIsValidStudentWaveDistribution
                        }
                    />
                ))
            }

            {
                emptyStudents.map((empty, index) => (
                    <EmptyWaveStudentItem
                        key={empty.id}
                        order={
                            index
                            + wave.students.length
                            + 1
                            + (wave.additionalStudents.length ?? 0)
                        }
                        isIntervalInvalid={isMinIntervalInvalid}
                        minIntervalStudentsAmount={wave.module.minStudentCount}
                        intervalAdditionalInfo={intervalAdditionalInfo}
                    />
                ))
            }

        </div>
    );
}

function getWavesSortedCurrentWaveStudents(
    currentWaveId: string,
    currentModuleId: string,
    sortTypes: SortTypes,
    studentsRatings: StudentWithRatings[],
    groupedWaves: GroupedWavesWithSelectedStudents[],
) {
    return groupedWaves.map((group) => {
        if (group.moduleId !== currentModuleId) {
            return group;
        }

        const wavesSortedCurrentWaveStudents = group.waves.map(
            (wave) => {
                if (wave.id !== currentWaveId) {
                    return wave;
                }

                const sortedStudents = getSortedStudents(
                    sortTypes,
                    studentsRatings,
                    wave.students,
                );

                return {
                    ...wave,
                    students: sortedStudents,
                };
            },
        );

        return {
            ...group,
            waves: wavesSortedCurrentWaveStudents,
        };
    });
}

function getEmptyStudents(
    studentsAmount: number,
    maxStudents: number,
) {
    const emptyStudents: {
        id: string,
    }[] = [];

    for (let i = 0; i < maxStudents - studentsAmount; i += 1) {
        emptyStudents.push({
            id: nanoid(8),
        });
    }

    return emptyStudents;
}
