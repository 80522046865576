import React from 'react';
import cn from 'classnames';
import classes from './TeacherAssignmentsSpaces.module.scss';

interface Props {
    spaces: {
        id: string;
        name: string;
    }[];
    activeSpaceId?: string;
}

export const TeacherAssignmentsSpaces = ({ spaces, activeSpaceId }: Props) => (
    <div className={classes.tabs}>
        {spaces.map(({ name, id }) => (
            <div
                className={cn(classes.tab, {
                    [classes.tab_active]: id === activeSpaceId,
                })}
                key={id}
            >
                {name}
            </div>
        ))}
    </div>
);
