import React, { useState } from 'react';
import cn from 'classnames';

import { SwitcherRedesign } from '@common/SwitcherRedesign';

import {
    ParametersTransform,
} from '@admin/EducationPeriodPage/EducationPeriodDates/EducationPeriodDatesEdit/EducationPeriodDatesAccordion/AccordionItems';
import {
    getTransformedParameters,
} from '@admin/EducationPeriodPage/EducationPeriodDates/EducationPeriodDatesEdit/EducationPeriodDatesAccordion/AccordionItems/AccordionItem';
import {
    DynamicModuleItem,
} from '@admin/EducationPeriodPage/EducationPeriodDates/EducationPeriodDatesEdit/EducationPeriodDatesAccordion/AccordionItems/ModuleAssessmentAccordionItem/DynamicModuleItem/DynamicModuleItem';

import {
    NotDynamicModuleItem,
} from '@admin/EducationPeriodPage/EducationPeriodDates/EducationPeriodDatesEdit/EducationPeriodDatesAccordion/AccordionItems/ModuleAssessmentAccordionItem/NotDynamicModuleItem/NotDynamicModuleItem';
import classes from '../AccordionItems.module.scss';

interface Props {
    name: string;
    dateStart?: string;
    dateEnd?: string;
    parametersArray: ParametersTransform[];
    isModuleAssessDynamicStart: boolean;
    setModuleAssessDynamicStart: (isDynamic: boolean) => void;
    setBtiParameters: (newParams: ParametersTransform[]) => void;
}

export function ModuleAssessmentAccordionItem(
    {
        name,
        dateStart,
        dateEnd,
        parametersArray,
        isModuleAssessDynamicStart,
        setModuleAssessDynamicStart,
        setBtiParameters,
    }: Props,
) {
    const [isModuleAssessActive, setModuleAssessActive] = useState(
        !!(name === 'Студенческая оценка преподавателей' && (dateStart || dateEnd)),
    );
    const [isDynamicStartDateActive,
        setDynamicStartDateActive] = useState(isModuleAssessDynamicStart);

    return (
        <>
            <div className={classes.items__container}>
                <div className={classes.items__module}>
                    <div
                        className={cn(classes.items__item, {
                            [classes.items__item_disabled]: !isModuleAssessActive,
                        })}
                    >
                        {name}
                    </div>

                    <SwitcherRedesign
                        checked={isModuleAssessActive}
                        onClick={() => {
                            setBtiParameters(
                                getTransformedParameters(
                                    parametersArray,
                                    name,
                                    '',
                                    '',
                                    !isModuleAssessActive,
                                ),
                            );
                            setModuleAssessActive(!isModuleAssessActive);
                        }}
                    />
                </div>

                <div className={classes.items__item} />

                <div className={classes.items__item} />

            </div>

            {
                isModuleAssessActive && (
                    <>
                        <NotDynamicModuleItem
                            name={name}
                            dateEnd={dateEnd}
                            dateStart={dateStart}
                            isDynamicStartDateActive={isDynamicStartDateActive}
                            parametersArray={parametersArray}
                            setDynamicStartDateActive={setDynamicStartDateActive}
                            setModuleAssessDynamicStart={setModuleAssessDynamicStart}
                            setBtiParameters={setBtiParameters}
                        />

                        <DynamicModuleItem
                            name={name}
                            dateEnd={dateEnd}
                            isDynamicStartDateActive={isDynamicStartDateActive}
                            parametersArray={parametersArray}
                            setDynamicStartDateActive={setDynamicStartDateActive}
                            setModuleAssessDynamicStart={setModuleAssessDynamicStart}
                            setBtiParameters={setBtiParameters}
                        />
                    </>
                )
            }
        </>
    );
}
