import React from 'react';
import { ModuleMainCharacters } from '@common/ModuleCard/ModuleMainCharacters';

import { Module as ModuleType } from '../../../subSpaceAdmin/subSpaceTypes';

import classes from './ModuleTabsContent.module.scss';

interface Props {
    currentModule: ModuleType;
}

export function ModuleTabsContent({
    currentModule,
}: Props): JSX.Element {
    return (
        <div className={classes.moduleTabsContent}>
            <ModuleMainCharacters
                currentModule={currentModule}
            />
        </div>
    );
}
