import { gql } from '@apollo/client';

export const ADD_EXTERNAL_MODULE_REVIEW = gql`
    mutation addExternalModuleReview($createExternalModuleReviewInput: CreateExternalModuleReviewInput!) {
        createExternalModuleReview(createExternalModuleReviewInput: $createExternalModuleReviewInput) {
            id
                message
                reviewer {
                    id
                    firstName
                    lastName
                    patronymic
                }
        }
    }
`;

export const REMOVE_EXTERNAL_MODULE_REVIEW = gql`
    mutation removeExternalModuleReview($id: String!) {
        deleteExternalModuleReview(id: $id) {
            id
        }
    }
`;

export const GET_EXTERNAL_MODULE_REVIEWS = gql`
    query getExternalModuleReviews($externalModuleInput: ExternalModuleInput!) {
        externalModule(externalModuleInput: $externalModuleInput) {
            id
            reviews {
                id
                message
                reviewer {
                    id
                    firstName
                    lastName
                    patronymic
                }
            }
        }
    }
`;
